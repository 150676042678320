import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MentionButton.module.scss';

const cn = classnames.bind(styles);

const MentionButton = ({ className, onClick, isDisabled, isActive, children, ...otherProps }) => (
  <div
    onClick={!isDisabled ? onClick : null}
    className={cn('mention-btn', className, { isDisabled, isActive })}
    {...otherProps}
  >
    {children}
  </div>
);

MentionButton.propTypes = {
  onClick: T.func.isRequired,
  className: T.string,
  isDisabled: T.bool,
  isActive: T.bool,
  children: T.node,
};

export default MentionButton;
