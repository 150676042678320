import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// components
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// styles
import styles from './CommentBoxTransparent.module.scss';

const cn = classnames.bind(styles);

const CommentBoxTransparent = ({ initialValue, onSave }) => {
  const [value, setValue] = useState(initialValue || '');
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setValue(initialValue || '');
  }, [setValue, initialValue]);

  const handleSave = () => {
    setIsEdit(false);
    onSave(value);
  };

  const resetValues = () => {
    setValue(initialValue || '');
    setIsEdit(false);
  };

  return (
    <div className={cn('comment-box')}>
      {!value.length && !isEdit && (
        <div className={cn('add-note')} onClick={() => setIsEdit(true)}>
          <FormattedMessage id="general.notes.label" />
        </div>
      )}
      <div className={cn('textarea-wrapper')}>
        {!!value.length && !isEdit && (
          <div className={cn('display-text')} onClick={() => setIsEdit(true)}>
            {value}
          </div>
        )}
        {isEdit && (
          <>
            <textarea
              rows={5}
              value={value}
              onChange={({ target: { value } }) => setValue(value)}
              autoFocus
            />
            <div className="mv-10">
              <Button
                primary
                onClick={handleSave}
                disabled={initialValue === value}
              >
                <FormattedMessage id="general.button.save" />
              </Button>
              <Button transparent className="ml-15" onClick={resetValues}>
                <FormattedMessage id="general.button.cancel" />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

CommentBoxTransparent.propTypes = {
  initialValue: T.string,
  onSave: T.func,
};

CommentBoxTransparent.defaultProps = {
  initialValue: '',
};

export default CommentBoxTransparent;
