import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import onClickOutside from 'react-onclickoutside';
import throttle from 'lodash.throttle';
// styles
import './MainFooterPlusButton.scss';

function getWidth() {
  return Math.min(window.innerWidth - 20, 750);
}

const MainFooterPlusButton = ({ items }, { router }) => {
  const [{ isOpen, width }, setState] = useState({ isOpen: false, width: getWidth() });

  const toggleMenuVisibility = () => {
    setState((prevState) => ({ isOpen: !prevState.isOpen, width: getWidth() }));
  };

  const hideMenu = () => setState((prevState) => ({ ...prevState, isOpen: false }));

  const onItemClick = (path) => {
    router.push(path);
    hideMenu();
  };

  MainFooterPlusButton.handleClickOutside = hideMenu;

  useEffect(() => {
    if (!isOpen) return undefined;
    const throttleScreenResize = throttle(
      () => setState((prevState) => ({ ...prevState, width: getWidth() })),
      1500,
      { leading: false }
    );
    window.addEventListener('resize', throttleScreenResize);
    return () => {
      window.removeEventListener('resize', throttleScreenResize);
    };
  }, [isOpen, setState]);

  return (
    <div className={cn('plus-menu', { 'is-opened': isOpen })}>
      <div className={cn('menu-backdrop', { 'is-visible': isOpen })} onClick={hideMenu} />
      {isOpen && (
        <div className="menu" style={{ width }}>
          {items.map((item) => (
            <div key={item.id} className="menu-square-item" onClick={() => onItemClick(item.link)}>
              {item.isNewFeature && (
                <FormattedMessage id="general.new">
                  {(text) => <div className="new-badge">{text}</div>}
                </FormattedMessage>
              )}
              <i className={cn(item.icon, 'menu-square-icon')} />
              <span className="menu-square-label">{item.label}</span>
            </div>
          ))}
        </div>
      )}
      <div className="plus-btn" onClick={toggleMenuVisibility}>
        <i className="fa fa-plus icon" />
      </div>
    </div>
  );
};

MainFooterPlusButton.contextTypes = {
  router: T.object.isRequired,
};

MainFooterPlusButton.propTypes = {
  items: T.array.isRequired,
};

const clickOutsideConfig = {
  handleClickOutside: () => MainFooterPlusButton.handleClickOutside
};

export default onClickOutside(MainFooterPlusButton, clickOutsideConfig);
