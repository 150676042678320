import React, { Component } from 'react';
import T from 'prop-types';
// components
import SearchBox from 'components/SearchBox';
import FlexTable from 'components/FlexTable';
import Panel from 'components/Panel';
import FlexTableItem from 'components/FlexTable/FlexTableItem';
// utils
import cn from 'classnames';
import './RolesManageFixedHeader.scss';

class RolesManageFixedHeader extends Component {

  handleSort = (currentField) => {
    const { sort, onSortChange } = this.props;
    const [sortField, sortDirection] = (sort || '').split(' ');
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    const direction = (sortField === currentField) ? newSortDirection : 'asc';
    const currentSort = currentField + ' ' + direction;
    onSortChange(currentSort);
  };

  render() {
    const { panelTitle, headerColumns, search, onSearchChange, isEmptyTable, className } = this.props;

    return (
      <div className={cn('RolesManageFixedHeader', className, { 'empty-table': isEmptyTable })}>
        <div className="small-12 column header-bg">
          <Panel className="manage-header-panel">
            <Panel.Heading title={panelTitle}>
              {onSearchChange && <SearchBox initialValue={search} onChange={onSearchChange} />}
            </Panel.Heading>

            <Panel.Body noPadding className="manage-header-body">
              <FlexTable wrapperClassName="RolesManageTableHeader" isEmpty={isEmptyTable}>
                <div className="table-row">
                  {headerColumns.map((column, index) => (
                    <FlexTableItem key={index} flex={column.flex}>
                      <div className={`collapsible-name ${index ? 'text-center' : 'item-name'}`}>
                        <span>{column.name}</span>
                        {onSearchChange && (
                          <i onClick={() => this.handleSort(column.sortField)} className="fa fa-angle-down-btb" />
                        )}
                      </div>
                    </FlexTableItem>
                  ))}
                </div>
              </FlexTable>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    );
  }
}

RolesManageFixedHeader.propTypes = {
  onSortChange: T.func,
  onSearchChange: T.func,
  headerColumns: T.array.isRequired,
  panelTitle: T.oneOfType([T.string, T.object]).isRequired,
  isEmptyTable: T.bool,
  className: T.string,
  search: T.string,
  sort: T.string,
};

export default RolesManageFixedHeader;
