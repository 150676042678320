import moment from 'moment';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';

export function isPigGroupDeletable(pigGroup) {
  return ['draft', 'scheduled_delivery', 'awaiting_delivery'].includes(pigGroup.status);
}

export function getGroupsStatusForRequest(status) {
  switch (status) {
    case 'pending':
      return ['awaiting_delivery', 'scheduled_delivery'];
    case 'opened':
      return ['opened', 'request_close'];
    case 'action_required':
      return 'pending_close';
    default: return status;
  }
}

export function parseFasLinkGroups(groups) {
  const not_created = [];
  const created = [];
  for (const group of groups) {
    if (group.event_key === 'group_was_created') {
      created.push(group);
    }
    not_created.push(group);
  }

  return {
    created,
    not_created,
  };
}

export function arrangeGroupsByStatus(groups) {
  const scheduledGroups = [];
  const awaitingGroups = [];
  const openedGroups = [];
  const pendingGroups = [];
  groups.forEach((group) => {
    const { status } = group;
    if (status === 'opened') openedGroups.push(group);
    if (status === 'awaiting_delivery') awaitingGroups.push(group);
    if (['request_close', 'pending_close'].includes(status)) pendingGroups.push(group);
    if (status === 'scheduled_delivery') scheduledGroups.push(group);
  });
  return { scheduledGroups, openedGroups, pendingGroups: pendingGroups.concat(awaitingGroups) };
}

export function getPigGroupStartDate(date, defaultDate = moment()) {
  if (!date || moment(date).isAfter(moment())) return defaultDate;
  return moment(date);
}

export function getCommitmentPlacementDate(commitmentDate, groupStartedOnDate) {
  if (!commitmentDate && !groupStartedOnDate) {
    return moment();
  }
  const groupStartedOnMoment = moment(groupStartedOnDate);
  if (!commitmentDate) {
    return groupStartedOnMoment;
  }
  const commitmentDateMoment = moment(commitmentDate);
  if (commitmentDateMoment.isAfter(groupStartedOnMoment)) {
    return commitmentDateMoment;
  }
  return groupStartedOnMoment;
}

export function formatPigGroupSources(data) {
  return data.map((source) => ({
    ...source,
    farm: source.entity_type === 'internal' && source.source_pig_group?.farm ? {
      value: source.source_pig_group.farm.id,
      label: source.source_pig_group.farm.name,
    } : {},
    source_pig_group: source.entity_type === 'internal' && source.source_pig_group?.id ? {
      value: source.source_pig_group.id,
      label: source.source_pig_group.name,
      source: source.external_source || {},
    } : {},
    external_source: source.entity_type === 'external' && source.external_source?.id ? {
      label: source.external_source.name,
      value: source.external_source.id,
    } : {},
  }));
}

export function getDefaultPigGroupSources() {
  return [{ entity_type: 'external', external_source: {}, farm: {}, source_pig_group: {} }];
}

export function preparePigGroupSources(sources) {
  const filteredSources = sources.filter(({ id, external_source, source_pig_group }) => (
    id || external_source?.value || source_pig_group?.value));
  return filteredSources.map((source) => {
    if (source._destroy) {
      return { id: source.id, _destroy: true };
    }
    const external_source_id = source.entity_type === 'external' ? (source.external_source?.value || null) : null;
    const source_pig_group_id = source.entity_type === 'internal'
      ? (source.source_pig_group?.value || null)
      : null;
    return {
      external_source_id,
      source_pig_group_id,
      id: source.id,
      entity_type: source.entity_type,
      _destroy: !!source.id && !external_source_id && !source_pig_group_id,
    };
  });
}

export function isSameExternalSources(stateSources = [], savedSources = []) {
  if (!savedSources.length && stateSources.length === 1) {
    const [stateSource] = stateSources;
    return isEmpty(stateSource.external_source) && isEmpty(stateSource.source_pig_group);
  }
  if (stateSources.length !== savedSources.length) return false;
  return isEqual(formatPigGroupSources(savedSources), stateSources);
}
