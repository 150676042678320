import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import MatchGroupColumns from 'containers/ImportGroups/components/MatchGroupColumns';
import CSVImportPage from './CSVImportPage';
// requests
import { makeCSVImport } from 'endpoints/admin/csvImport';
// utils
import { getBackPathname } from 'utils/csvImportHelper';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// constants
import { csvColumnsByType, labelsByType } from 'constants/csvImport';

const CSVImportMapColumns = ({
  filesByType,
  importActionByType,
  parsedFiles,
  location,
}, { router }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [skipUnMatched, setSkipUnMatched] = useState(false);
  const [isAllSaved, setIsAllSaved] = useState(false);
  const [matchedColumnsByType, setMatchColumnsByType] = useState({});
  const [stats, setStats] = useState({});
  const isNoFile = !parsedFiles.length;
  const { query } = location;
  const backPathName = getBackPathname(query?.from, query?.entityId);

  const isNextDisabled = parsedFiles.some(({ type }) => isEmpty(matchedColumnsByType[type]));

  useEffect(() => {
    if (isNoFile) router.push({
      pathname: '/csv-import/select-mode',
      query: { from: query.from, entityId: query.entityId },
    });
  }, [isNoFile]);

  const getCardStatusesStats = (data, type) => {
    setStats((prevState) => ({
      ...prevState,
      [type]: data,
    }));
  };

  const setMatchColumns = (columns, type) => {
    setMatchColumnsByType((prevState) => ({
      ...prevState,
      [type]: columns,
    }));
  };

  const onBackBtnClick = () => {
    router.push({
      pathname: '/csv-import/upload',
      query: { from: query.from, entityId: query.entityId, cards: parsedFiles.map(({ type }) => type) },
    });
  };

  const onNextBtnClick = () => {
    if (isNextDisabled || isLoading) return;

    setIsLoading(true);
    const types = Object.keys(filesByType);
    const requests = types.map((type) => {
      const fd = new FormData();
      const matchedCols = matchedColumnsByType[type];
      Object.keys(matchedCols).forEach((item) => fd.append(`resource[csv_matchers][${item}]`, matchedCols[item]));
      fd.append('resource[csv_file]', filesByType[type]?.file);
      fd.append('resource[action]', importActionByType[type] || 'creating');
      return makeCSVImport(fd, type);
    });
    Promise.all(requests)
      .then((resources) => {
        router.push({
          pathname: '/csv-import/results',
          query: {
            from: query.from,
            entityId: query.entityId,
            ...resources.reduce((accumulator, resource) => ({ ...accumulator, [resource.type]: resource.id }), {}),
          },
        });
      })
      .catch((errors) => {
        setIsLoading(false);
        return toastResponseErrors(errors);
      });
  };

  const { unmatchedCount, canBeSaved } = Object.values(stats).reduce((prev, curr) => ({
    unmatchedCount: prev.unmatchedCount + curr.unmatchedCount,
    canBeSaved: prev.canBeSaved + curr.canBeSaved,
  }), { unmatchedCount: 0, canBeSaved: 0 });

  const footerContent = (
    <div className="csv-footer-content">
      {unmatchedCount > 0 && (
        <span>
          <FormattedMessage id="general.unmatchedColumnsCount" values={{ count: unmatchedCount }} tagName="b" />
          &nbsp;•&nbsp;
          <FormattedMessage id="general.csvImport.allSkip">
            {(text) => (
              <span onClick={() => setSkipUnMatched(true)} className="skip-unmatched-cards">{text}</span>
            )}
          </FormattedMessage>
        </span>
      )}
      {unmatchedCount === 0 && canBeSaved > 0 && (
        <span>
          <FormattedMessage id="general.autoMatchedColumnsCount" values={{ count: canBeSaved }} tagName="b" />
          &nbsp;•&nbsp;
          <FormattedMessage id="general.csvImport.saveMatched">
            {(text) => (
              <span onClick={() => setIsAllSaved(true)} className="skip-unmatched-cards">{text}</span>
            )}
          </FormattedMessage>
        </span>
      )}
      {!isNextDisabled && (
        <>
          <span className="success-text">
            <i className="fa fa-check" /> <FormattedMessage id="general.success" />!&nbsp;
          </span>
          <FormattedMessage id="general.matchingDone" />
        </>
      )}
    </div>
  );

  return (
    <CSVImportPage
      activeStep={2}
      onBackBtnClick={onBackBtnClick}
      onNextBtnClick={onNextBtnClick}
      isNextDisabled={isNextDisabled || isLoading}
      isBackDisabled={isLoading}
      closePathName={backPathName}
      footerContent={footerContent}
    >
      <div className="bodyCSV secondStep">
        <Preloader isActive={isLoading} />
        <div className="wrapperInfoBodyCSV">
          <FormattedMessage id="general.csvImport.mappingColumn.title">
            {(title) => <div className="step-title">{title}</div>}
          </FormattedMessage>
          <FormattedMessage id="general.csvImport.mappingColumn.info">
            {(desc) => <div className="step-description">{desc}</div>}
          </FormattedMessage>
        </div>
        <div className="wrapperCardsCSV">
          {parsedFiles.map((data) => (
            <MatchGroupColumns
              key={data.type}
              saveAllAutoMatched={isAllSaved}
              skipAllUnMatched={skipUnMatched}
              matched={matchedColumnsByType[data.type]}
              setMatchData={(matched) => setMatchColumns(matched, data.type)}
              parsedData={data.rows}
              matchColumns={csvColumnsByType[data.type].matchColumns}
              requiredColumns={csvColumnsByType[data.type].requiredColumns}
              mappingColumns={csvColumnsByType[data.type].mappingColumns}
              options={csvColumnsByType[data.type].options}
              customTitle={labelsByType[data.type]}
              getCardStatusesStats={(cardsStats) => getCardStatusesStats(cardsStats, data.type)}
            />
          ))}
        </div>
      </div>
    </CSVImportPage>
  );
};

CSVImportMapColumns.contextTypes = {
  router: T.object.isRequired,
};

CSVImportMapColumns.propTypes = {
  parsedFiles: T.array.isRequired,
  location: T.object.isRequired,
  importActionByType: T.object.isRequired,
  filesByType: T.object.isRequired,
};

export default connect(
  (state) => ({
    parsedFiles: state.importCSV.parsedFiles,
    filesByType: state.importCSV.filesByType,
    importActionByType: state.importCSV.importActionByType,
  })
)(CSVImportMapColumns);
