import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import { ReactComponent as CheckedIcon } from 'assets/svg/check-circle-bts.svg';
import { ReactComponent as UnCheckedIcon } from 'assets/svg/radio-unchecked.svg';
// utils
import cn from 'classnames';
// styles
import styles from './PickerRow.module.scss';

const PickerRow = ({ actionMode = 'select', onClick, children, isSelected = false }) => (
  <div
    className={cn(styles['picker-row'], { [styles['is-selected']]: isSelected && actionMode === 'select' })}
    onClick={onClick}
  >
    {children}
    {actionMode === 'select' && (
      <Button disabled={isSelected} className={styles['select-btn']} small primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    )}
    {actionMode === 'checkbox' && (
      <div className={cn(styles['icons-column'], { [styles['is-selected']]: isSelected })}>
        <CheckedIcon height={25} className={styles['checked-icon']} />
        <UnCheckedIcon height={25} className={styles['unchecked-icon']} />
      </div>
    )}
  </div>
);

PickerRow.propTypes = {
  onClick: T.func,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isSelected: T.bool,
  actionMode: T.oneOf(['select', 'checkbox']),
};

export default PickerRow;
