import React from 'react';
import T from 'prop-types';
// components
import styles from './CategoryCounterStatus.module.scss';
// utils
import classnames from 'classnames/bind';
// styles
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(styles);

const labelKeys = {
  pass: 'general.pass',
  fail: 'general.fail',
  not_available: 'general.notAvail'
};

const CategoryCounterStatus = ({ count, type, small }) => (
  <span className={cn('status', type, { empty: !count, small })}>
    <span className="mr-5">{count}</span>
    <FormattedMessage id={labelKeys[type]} />
  </span>
);

CategoryCounterStatus.propTypes = {
  count: T.number.isRequired,
  type: T.string.isRequired,
  small: T.bool,
};

export default CategoryCounterStatus;
