import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reset } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel/index';
import MessageBox from 'components/MessageBox';
import FormErrorsBox from 'components/FormErrorsBox';
import MetafarmForm from './components/MetafarmForm';
// utils
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';
import handleFormErrors from 'utils/handleFormErrors';

const initialState = {
  formValues: {
    username: '',
    password: '',
    reason_code: '',
  },
  isLoading: false,
  passwordHint: '',
};

class MetaFarmsIntegration extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    const { companyId } = this.props;
    this.setState({ isLoading: true });

    fetchFromAPI(`/admin/companies/${companyId}/meta_farms_credential`)
      .then(({ resource }) => {
        this.setState({
          isLoading: false,
          passwordHint: resource.password_hint,
          formValues: {
            username: resource.username,
            password: '',
            reason_code: resource.reason_code,
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  handleSubmit = (resource, change) => {
    const { companyId } = this.props;
    const reqParams = { method: 'PUT', body: JSON.stringify({ resource }) };
    this.setState({ isLoading: true });

    return fetchFromAPI(`/admin/companies/${companyId}/meta_farms_credential`, reqParams)
      .then(({ resource }) => {
        showToastrMessage('component.toastr.credentials.updated');
        change('password', '');
        this.setState({
          isLoading: false,
          passwordHint: resource.password_hint,
          formValues: {
            username: resource.username,
            reason_code: resource.reason_code,
          },
        });
      },
      (error) => {
        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  resetForm = () => {
    const { companyId, resetForm } = this.props;
    const resource = { username: '', password: '', reason_code: '' };
    const reqParams = { method: 'PUT', body: JSON.stringify({ resource }) };
    this.setState({ isLoading: true });

    return fetchFromAPI(`/admin/companies/${companyId}/meta_farms_credential`, reqParams)
      .then(() => {
        resetForm('metafarms-form');
        showToastrMessage('component.toastr.credentials.cleared');
        this.setState(initialState);
      },
      (error) => {
        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  render() {
    const { passwordHint, isLoading, formValues } = this.state;

    return (
      <Panel className="meta-farms-integration">
        <Panel.Heading title="MetaFarms" />
        <Panel.Body className="ph-15">
          <FormattedMessage tagName="b" id="component.metafarms.title" />
          <Preloader isActive={isLoading} />

          <div className="row clearfix">
            <section className="small-12 medium-10 large-8 column">

              <MessageBox type="default" className="message-box">
                <FormattedMessage id="component.metafarms.connectHelperText">
                  {(text) => (
                    <span>
                      {text} <FormattedMessage tagName="b" id="component.metafarms.connectHelperText.bolded" />
                    </span>
                  )}
                </FormattedMessage>
              </MessageBox>

              <MessageBox type="default" className="message-box">
                <FormattedMessage id="component.metafarms.disconnectHelperText" />
              </MessageBox>

              <FormErrorsBox formName="metafarms-form" className="animated fadeIn" />

              <MetafarmForm
                handleForm={this.handleSubmit}
                resetForm={this.resetForm}
                passwordHint={passwordHint}
                initialValues={formValues}
              />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

MetaFarmsIntegration.propTypes = {
  companyId: T.number.isRequired,
  resetForm: T.func.isRequired,
};

export default connect(
  (state) => ({
    companyId: state.auth.user.current_company.id,
  }), {
    resetForm: reset,
  }
)(MetaFarmsIntegration);
