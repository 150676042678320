import React from 'react';
import T from 'prop-types';
// components
import AnalyticsDropdown from './AnalyticsDropdown';
import TimeAgo from 'react-timeago';
import Preloader from 'components/Preloader';
// utils
import cn from 'classnames';
// translations
import { FormattedMessage } from 'react-intl';
// assets
import './AnalyticsPanel.scss';

export const AnalyticsPanel = (
  { titleKey, fullRow, panelData, getRangeData, date, rangeOptions, renderDropdownButton, children, className }
) => {
  const formatter = (value, unit) => {
    return <FormattedMessage id={`component.timeAgo.updated.${unit}`} values={{ value }} />;
  };
  const { isLoading, rangeType, symptom_id } = panelData;

  return (
    <div className={cn('AnalyticsPanel', { 'full-row': fullRow }, className)}>
      <div className="analytics-panel-body">
        <Preloader isActive={isLoading} />
        <div className="title-block">
          <div className="title-text">
            <FormattedMessage id={`component.analyticsPanel.title.${titleKey}`} />
          </div>
          {typeof renderDropdownButton === 'function'
            ? renderDropdownButton()
            : null}
        </div>
        {children}
      </div>
      <div className="analytics-panel-footer">
        {rangeType && (
          <AnalyticsDropdown
            symptom_id={symptom_id}
            rangeType={rangeType}
            getRangeData={getRangeData}
            options={rangeOptions}
          />
        )}
        {date && <TimeAgo className="timeago" date={date} formatter={formatter} />}
      </div>
    </div>
  );
};

AnalyticsPanel.propTypes = {
  titleKey: T.string.isRequired,
  children: T.any.isRequired,
  panelData: T.object.isRequired,
  getRangeData: T.func,
  date: T.string,
  fullRow: T.bool,
  rangeOptions: T.array,
  renderDropdownButton: T.func,
  className: T.string,
};

export default AnalyticsPanel;
