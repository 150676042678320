import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { fetchData } from 'reducers/dataTable';
import { openModal } from 'reducers/modals';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Button from 'components/Button';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Avatar from 'components/Avatar/Avatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

const tableFilters = [
  { label: <FormattedMessage id="general.allCompanies" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.incomplete" />, value: 'incomplete' },
];

class Companies extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  deleteCompany = (id) => {
    const { fetchData, reqParams } = this.props;
    fetchFromAPI(`/admin/companies/${id}`, { method: 'delete' })
      .then(() => {
        fetchData(reqParams);
        showToastrMessage('component.toastr.company.hasBeenDeleted');
      })
      .catch(toastResponseErrors);
  };

  deleteCompanyConfirm = (id) => {
    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteCompany" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteCompany.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.deleteCompany.warning" />}
        handleConfirm={() => this.deleteCompany(id)}
      >
        <FormattedMessage id="component.modal.deleteCompany.text" />
      </ConfirmationModal>
    );
  };

  renderNameColumn = ({ name }) => (
    <CustomColumn flexRow>
      <Avatar type="company" avatarSize={35} iconSize={16} className="mr-10" />
      <span className="semibold">{name}</span>
    </CustomColumn>
  );

  renderUsersCountColumn = ({ users_count }) => (
    <CustomColumn label={<FormattedMessage id="general.users" />}>
      {users_count}
    </CustomColumn>
  );

  renderFarmsCountColumn = ({ farms_count }) => (
    <CustomColumn label={<FormattedMessage id="general.farms" />}>
      {farms_count}
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.edit" />}
        buttonType="small light-gray"
        url={`/admin/companies/${id}/user-roles`}
        dropDownData={[
          { label: <FormattedMessage id="general.deleteCompany" />, onClick: () => this.deleteCompanyConfirm(id) },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/companies/${id}/user-roles`} className="button light hide-for-large">
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button darkGrey onClick={() => this.deleteCompanyConfirm(id)} className="hide-for-large">
        <FormattedMessage id="general.button.delete" />
      </Button>
    </div>
  );

  renderExpandable = ({ id }) => (
    <div>
      <Link to={`/admin/companies/${id}/user-roles`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button small light onClick={() => this.deleteCompanyConfirm(id)}>
        <i className="fa fa-trash-o mr-5" />
        <FormattedMessage id="general.button.delete" />
      </Button>
    </div>
  );

  render() {
    const { resources, isLoading, meta: { stats, total }, reqParams: { page, per_page, status, search, sort },
      onPageChange, onPerPageChange, onStatusChange, onSortChange, onSearchChange, isTenantUseLogistics } = this.props;
    const { isTablet } = this.context;

    const isEmptyTable = !resources.length && !status && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '2 1 150px',
        renderer: this.renderNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.users" />, flex: '1 1 100px', renderer: this.renderUsersCountColumn,
        sortKey: 'users_count' },
      { label: <FormattedMessage id="general.farms" />, flex: '1 1 100px', renderer: this.renderFarmsCountColumn,
        sortKey: 'farms_count' },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 100px',
        renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 135px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading
            title={<FormattedMessage id={`general.${isTenantUseLogistics ? 'managementCompanies' : 'companies'}`} />}
          >
            {!isEmptyTable &&
              <SearchBox initialValue={search} onChange={onSearchChange} />}
          </Panel.Heading>
          <Panel.Body noPadding>
            {!isEmptyTable && (
              <TableFilter
                filters={tableFilters}
                activeFilter={status}
                onFilterChange={onStatusChange}
                stats={stats}
                className="ph-10"
              >
                <Link to="/admin/companies/create" className="button small primary wider">
                  <FormattedMessage id="general.button.createCompany" />
                </Link>
              </TableFilter>
            )}

            <Preloader isActive={isLoading} />

            <DataTable
              data={resources}
              columns={columns}
              sort={sort}
              onSortChange={onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
              tableKey="adminCompanies"
            />

            <NothingBox
              itemsName="companies"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
              filter={status}
            >
              <FormattedMessage id="component.nothingBox.anyPartnersYet" tagName="h1" />
              <FormattedMessage id="component.nothingBox.createCompany" tagName="p" />
              <Link to="/admin/companies/create" className="button primary mt-10">
                <FormattedMessage id="general.button.createCompany" />
              </Link>
            </NothingBox>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

Companies.propTypes = {
  resources: T.array,
  fetchData: T.func.isRequired,
  openModal: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object,
  isTenantUseLogistics: T.bool.isRequired,
};

Companies.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.adminCompanies.resources,
      reqParams: state.dataTable.adminCompanies.params,
      meta: state.dataTable.adminCompanies.meta,
      isLoading: state.dataTable.adminCompanies.isLoading,
      isTenantUseLogistics: state.auth.user.current_company.logistics,
    }), {
      fetchData: (query) => fetchData('/admin/companies', 'adminCompanies', query),
      openModal,
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(Companies);
