import { handleActions, createAction } from 'redux-actions';
import { fetchAutoReportsStats } from 'endpoints/autoReports';
import pickBy from 'lodash.pickby';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  mortality: {
    stats: [],
    prev_period_meta: {},
    isLoading: false,
    params: {},
  },
  deaths: {
    stats: [],
    prev_period_meta: {},
    isLoading: false,
    params: {},
  },
};

// ------------------------------------
// Action Types
// ------------------------------------
const FETCH_DEATHS_STATS = 'autoReport/FETCH_DEATHS_STATS';
const FETCH_DEATHS_STATS_PENDING = 'autoReport/FETCH_DEATHS_STATS_PENDING';
const FETCH_DEATHS_STATS_FULFILLED = 'autoReport/FETCH_DEATHS_STATS_FULFILLED';
const FETCH_DEATHS_STATS_REJECTED = 'autoReport/FETCH_DEATHS_STATS_REJECTED';

const FETCH_MORTALITY_STATS = 'autoReport/FETCH_MORTALITY_STATS';
const FETCH_MORTALITY_STATS_PENDING = 'autoReport/FETCH_MORTALITY_STATS_PENDING';
const FETCH_MORTALITY_STATS_FULFILLED = 'autoReport/FETCH_MORTALITY_STATS_FULFILLED';
const FETCH_MORTALITY_STATS_REJECTED = 'autoReport/FETCH_MORTALITY_STATS_REJECTED';

const CLEAR_DATA = 'autoReport/CLEAR_DATA';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchDeathsStats = (params = {}) => createAction(FETCH_DEATHS_STATS)(
  fetchAutoReportsStats({ stats: ['total_deaths'], ...params })
    .then((response) => ({ ...response, params: pickBy(params) }))
);

export const fetchMortalityStats = (params = {}) => createAction(FETCH_MORTALITY_STATS)(
  fetchAutoReportsStats({ stats: ['avg_mortality_rate'], ...pickBy(params) })
    .then((response) => ({ ...response, params: pickBy(params) }))
);

export const clearData = createAction(CLEAR_DATA);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_DEATHS_STATS_PENDING]: (state) => ({
    ...state,
    deaths: {
      ...state.deaths,
      isLoading: true,
    },
  }),
  [FETCH_DEATHS_STATS_FULFILLED]: (state, { payload }) => ({
    ...state,
    deaths: {
      ...payload,
      isLoading: false,
    },
  }),
  [FETCH_DEATHS_STATS_REJECTED]: (state) => ({
    ...state,
    deaths: {
      ...state.deaths,
      isLoading: false,
    },
  }),
  [FETCH_MORTALITY_STATS_PENDING]: (state) => ({
    ...state,
    mortality: {
      ...state.mortality,
      isLoading: true,
    },
  }),
  [FETCH_MORTALITY_STATS_FULFILLED]: (state, { payload }) => ({
    ...state,
    mortality: {
      ...payload,
      isLoading: false,
    },
  }),
  [FETCH_MORTALITY_STATS_REJECTED]: (state) => ({
    ...state,
    mortality: {
      ...state.mortality,
      isLoading: false,
    },
  }),
  [CLEAR_DATA]: () => ({
    ...initialState,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
