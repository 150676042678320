import { farmTypes } from 'constants.js';

export const markMainCountries = (countries, main = ['mx', 'ca', 'us']) => {
  if (!countries.length) return [];
  const primary = [];
  const secondary = [];
  countries.forEach((country) => {
    return main.includes(country.iso.toLowerCase())
      ? primary.push(country)
      : secondary.push(country);
  });
  primary[main.length - 1].divider = true;
  return [...primary, ...secondary];
};

export const getFarmTypesValues = () => farmTypes.map((type) => type.value);

export const getCommentByRole = (comments = [], searched_role) => {
  return (comments.find(({ role }) => role === searched_role) || {});
};
