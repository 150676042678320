import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import NothingBox from 'components/NothingBox';
import CircleCheckbox from 'components/CircleCheckbox';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import cn from 'classnames';
import getValue from 'lodash.get';
import { FormattedMessage } from 'react-intl';
// styles
import './CompanyFarmsRolesManageTable.scss';

class CompanyFarmsRolesManageTable extends Component {

  onChangeManagementCompany = (e, farm) => {
    const { id, name, is_manager, manager } = farm;
    const { checked } = e.target;
    const { company, handleRoleChanged, openModal } = this.props;

    if (is_manager || !manager) {
      return handleRoleChanged(id, checked);
    }
    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.changeManagementCompany.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.changeManagementCompany.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.changeManagementCompany.warning" />}
        handleConfirm={() => handleRoleChanged(id, checked)}
      >
        <FormattedMessage
          id="component.modal.changeManagementCompany.text"
          values={{
            farmName: <strong>{name}</strong>,
            managerName: <strong>{getValue(farm, 'manager.name')}</strong>,
            newManageCompany: <strong>{company.name}</strong>,
          }}
          tagName="p"
        />
      </ConfirmationModal>
    );
  };

  render() {
    const { data, isLoading, search, filter, isCompanyTenant } = this.props;

    return (
      <div className="CompanyFarmsRolesManageTable">
        <div className="flex-data-table">

          {data.map((farm, idx) => {
            const { is_manager, name: farm_name, farm_type } = farm;
            return (
              <div className="table-item" key={idx}>
                {/* Farm Name */}
                <div className="table-item-block column-farm-name">
                  <div className="collapsible-value">
                    <div className="value-amount title">
                      <FarmTitleBox name={farm_name} type={farm_type} />
                    </div>
                  </div>
                </div>
                {/* Manager Role */}
                <div
                  className={cn('table-item-block column-roles', {
                    'disabled': is_manager && isCompanyTenant,
                    'checked': is_manager,
                  })}
                >
                  <div className="collapsible-value with-checkbox no-border">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        disabled={is_manager && isCompanyTenant}
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.managementCo" />
                          </span>
                        )}
                        name="manager"
                        onChange={(e) => this.onChangeManagementCompany(e, farm)}
                        checked={is_manager || false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <NothingBox
          itemsName="roles"
          display={!data.length}
          isLoading={isLoading}
          search={search}
          filter={filter}
        >
          <FormattedMessage tagName="h1" id="general.nothingBox.noRoles" />
        </NothingBox>
      </div>
    );
  }
}

CompanyFarmsRolesManageTable.propTypes = {
  data: T.array,
  isLoading: T.bool,
  isCompanyTenant: T.bool,
  search: T.string,
  filter: T.string,
  handleRoleChanged: T.func,
  company: T.object,
  openModal: T.func,
};

export default connect(null, { openModal })(CompanyFarmsRolesManageTable);
