import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// styles
import styles from './Dropdown.module.scss';

const cn = classnames.bind(styles);

const Dropdown = ({ idKey, className, iconClassName, options, ignoreScroll, isButton }) => {
  const { openDropdown } = useDropdownActions();
  if (!options.filter(({ hide }) => !hide).length) return null;

  if (isButton) {
    return (
      <button
        data-name={idKey}
        onClick={(e) => openDropdown(e, options, { isScrollIgnored: ignoreScroll })}
        className={cn('button light small', 'dropdown-button', { [className]: !!className })}
      >
        <i data-name={idKey} className={cn(iconClassName, 'icon')} />
      </button>
    );
  }

  return (
    <i
      data-name={idKey}
      className={cn(iconClassName, 'dropdown-icon', { [className]: !!className })}
      onClick={(e) => openDropdown(e, options, { isScrollIgnored: ignoreScroll })}
    />
  );
};

Dropdown.propTypes = {
  idKey: T.oneOfType([T.string, T.number]),
  options: T.array,
  ignoreScroll: T.bool,
  isButton: T.bool,
  className: T.string,
  iconClassName: T.string,
};

Dropdown.defaultProps = {
  idKey: Date.now(),
  options: [],
  ignoreScroll: false,
  iconClassName: 'fa fa-dots-three-horizontal',
};

export default Dropdown;
