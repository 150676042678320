import { combineActions, handleActions, createAction } from 'redux-actions';
import { fetchUser, putUserSetting } from 'endpoints/users';
import { fetchUserByToken } from 'endpoints/userInvites';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  user: null,
  isLoading: false,
};

const FETCH_INVITED_USER_PENDING = 'invitations/FETCH_INVITED_USER_PENDING';
const FETCH_INVITED_USER_FULFILLED = 'invitations/FETCH_INVITED_USER_FULFILLED';
const FETCH_INVITED_USER_REJECTED = 'invitations/FETCH_INVITED_USER_REJECTED';

const UPDATE_USER_LANGUAGE_PENDING = 'invitations/UPDATE_USER_LANGUAGE_PENDING';
const UPDATE_USER_LANGUAGE_FULFILLED = 'invitations/UPDATE_USER_LANGUAGE_FULFILLED';
const UPDATE_USER_LANGUAGE_REJECTED = 'invitations/UPDATE_USER_LANGUAGE_REJECTED';

// ------------------------------------
// Actions
// ------------------------------------
export const getInvitedUserByToken = (token) => (dispatch) => {
  dispatch(createAction(FETCH_INVITED_USER_PENDING)());
  return fetchUserByToken(token)
    .then((user) => {
      dispatch(createAction(FETCH_INVITED_USER_FULFILLED)(user));
      return user;
    })
    .catch((errors) => {
      dispatch(createAction(FETCH_INVITED_USER_REJECTED)());
      throw errors;
    });
};

export const getInvitedUserById = (userId) => (dispatch) => {
  dispatch(createAction(FETCH_INVITED_USER_PENDING)());
  return fetchUser(userId)
    .then((user) => {
      dispatch(createAction(FETCH_INVITED_USER_FULFILLED)(user));
      return user;
    })
    .catch((errors) => {
      dispatch(createAction(FETCH_INVITED_USER_REJECTED)());
      throw errors;
    });
};

export const updateInvitedUserLanguage = (userId, language) => (dispatch) => {
  dispatch(createAction(UPDATE_USER_LANGUAGE_PENDING)());
  return putUserSetting(userId, { language })
    .then((setting) => {
      dispatch(createAction(UPDATE_USER_LANGUAGE_FULFILLED)(setting.language));
      return setting;
    })
    .catch((errors) => {
      dispatch(createAction(UPDATE_USER_LANGUAGE_REJECTED)());
      throw errors;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [combineActions(FETCH_INVITED_USER_PENDING, UPDATE_USER_LANGUAGE_PENDING)]: (state) => ({
    ...state,
    isLoading: true
  }),
  [FETCH_INVITED_USER_FULFILLED]: (state, action) => ({
    ...state,
    user: action.payload,
    isLoading: false
  }),
  [UPDATE_USER_LANGUAGE_FULFILLED]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      language: action.payload,
    },
    isLoading: false
  }),
  [combineActions(FETCH_INVITED_USER_REJECTED, UPDATE_USER_LANGUAGE_REJECTED)]: (state) => ({
    ...state,
    isLoading: false
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
