import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// assets
import './FarmMarker.scss';

const MARKER_SIZE = 35;
const MARKER_SIZE_MEDIUM = 20;
const MARKER_SIZE_SMALL = 15;

const getMarkerSize = (zoom, inline) => {
  if (inline) return MARKER_SIZE;
  if (zoom <= 7) return MARKER_SIZE_SMALL;
  if (zoom <= 9) return MARKER_SIZE_MEDIUM;
  return MARKER_SIZE;
};

const getMarkerStyle = (zoom, inline) => {
  if (inline) return null;
  const markerSize = getMarkerSize(zoom);
  return {
    width: markerSize, height: markerSize, left: -markerSize / 2, top: -markerSize / 2
  };
};

const FarmMarker = ({
  farm,
  children,
  onClick,
  active,
  currentZoom,
  className = '',
  inline,
}) => (
  <div
    className={cn('FarmMarker', `farm-type-${farm.farm_type}`, className, {
      active,
      inline,
      'marker-small': currentZoom <= 9,
    })}
    style={getMarkerStyle(currentZoom, inline)}
    onClick={(event) => (onClick ? onClick({ id: farm.id, type: 'farm' }, event) : null)}
  >
    {children}
    {farm.farm_type || '?'}
  </div>
);

FarmMarker.propTypes = {
  farm: T.object.isRequired,
  children: T.node,
  onClick: T.func,
  active: T.bool,
  className: T.string,
  currentZoom: T.number,
  inline: T.bool,
};

export default FarmMarker;
