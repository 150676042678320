import React from 'react';
import T from 'prop-types';
// components
import Collapse from 'react-collapse';
import FlexTableItem from 'components/FlexTable/FlexTableItem';
// utils
import cn from 'classnames';

const DataTableRow = ({
  rowData,
  rowKey,
  rowIndex,
  columns,
  selectedRowID,
  getRowClass,
  isExpandable,
  isDraggable,
  currentSortKey,
  hasDesktopViewOnly,
  renderExpandable,
  setExpandableRef,
  setTabletSelectedRow,
}) => {
  const currentIndex = `row-${rowKey}`;
  const isSelected = currentIndex === selectedRowID;
  const rowClassName = getRowClass ? getRowClass(rowData) : '';
  return (
    <div
      className={cn('table-row', rowClassName, {
        'drag-row': isDraggable,
        selected: isSelected,
        expandable: isExpandable
      })}
      onClick={() => setTabletSelectedRow(currentIndex)}
    >
      {columns.map(({ flex, renderer, className = '', fixed, sortKey, hide }, colIndex) => !hide && (
        <FlexTableItem
          flex={flex}
          className={cn('table-row-item', className, { 'desktop-view': hasDesktopViewOnly })}
          key={`column-${rowKey}-${colIndex}`}
          sorted={currentSortKey === sortKey}
          fixed={fixed}
        >
          {renderer(rowData, { rowIndex, rowKey })}
        </FlexTableItem>
      ))}

      {isExpandable && (
        <Collapse className="show-for-large full-width" isOpened={isSelected}>
          <div className="expandable-content" ref={isSelected ? setExpandableRef : null}>
            {renderExpandable(rowData)}
          </div>
        </Collapse>
      )}
    </div>
  );
};

DataTableRow.propTypes = {
  currentSortKey: T.string,
  isExpandable: T.bool,
  hasDesktopViewOnly: T.bool,
  columns: T.array.isRequired,
  rowData: T.object.isRequired,
  rowKey: T.oneOfType([T.string, T.number]).isRequired,
  rowIndex: T.number.isRequired,
  selectedRowID: T.oneOfType([T.number, T.string]),
  getRowClass: T.func,
  renderExpandable: T.func,
  setExpandableRef: T.func.isRequired,
  setTabletSelectedRow: T.func.isRequired,
  isDraggable: T.bool,
};

export default DataTableRow;
