import { fetchFromAPI } from 'utils/api';
import { tableObjectStoreGetAll } from 'db/offline-db';
import {
  setFarmsListOffline,
  setGroupsListOffline,
  setProductsListOffline,
  setTempPointsListOffline,
  setMRListOffline,
  setSymptomsListOffline,
} from 'utils/offlineHelper';

export const getFarmsForOffline = () => {
  return fetchFromAPI('/daily_checkups/offline_sync/farms')
    .then((response) => response.resources)
    .catch(() => [])
    .then((farms) => {
      setFarmsListOffline(farms);
      return farms;
    });
};

export const getPigGroupsForOffline = () => {
  return fetchFromAPI('/daily_checkups/offline_sync/pig_groups')
    .then((response) => response.resources)
    .catch(() => [])
    .then((pigGroups) => {
      setGroupsListOffline(pigGroups);
      return pigGroups;
    });
};

export const getSymptoms = (params = {}) => {
  return fetchFromAPI('/symptoms', { params })
    .then((response) => response.resources)
    .catch(() => [])
    .then((symptoms) => {
      setSymptomsListOffline(symptoms);
      return symptoms;
    });
};

export const getSymptomsOffline = () => {
  return tableObjectStoreGetAll('symptoms').catch(() => []);
};

export const getMortalityReasons = () => {
  return fetchFromAPI('/mortality_reasons')
    .then((response) => response.resources)
    .catch(() => [])
    .then((reasons) => {
      setMRListOffline(reasons);
      return reasons;
    });
};

export const getMortalityReasonsOffline = () => {
  return tableObjectStoreGetAll('mortality_reasons').catch(() => []);
};

export const getTemperaturePoints = () => {
  return fetchFromAPI('/temperature_points')
    .then((response) => response.resources)
    .catch(() => [])
    .then((points) => {
      setTempPointsListOffline(points);
      return points;
    });
};

export const getTemperaturePointsOffline = () => {
  return tableObjectStoreGetAll('temperature_points').catch(() => []);
};

export const getTreatments = () => {
  return fetchFromAPI('/treatment_products')
    .then((response) => response.resources)
    .catch(() => [])
    .then((products) => {
      setProductsListOffline(products);
      return products;
    });
};

export const getTreatmentsOffline = () => {
  return tableObjectStoreGetAll('treatment_products').catch(() => []);
};
