import React, { useState } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './FileAttachmentProgress.module.scss';

const cn = classnames.bind(styles);

const FileAttachmentProgress = ({
  name,
  className,
  onRemove,
  isUploaded,
  progress,
}) => {
  const [isTransitionEnded, setIsTransitionEnded] = useState(progress === 1);
  const progressBarStyle = { width: (progress * 100) + '%' };
  const isDeleteAvailable = isUploaded && isTransitionEnded;
  return (
    <div
      onTransitionEnd={() => setIsTransitionEnded(progress === 1)}
      className={cn('progress-block', className, { 'completed': isDeleteAvailable })}
    >
      <span className={cn('item')}>{name}</span>
      <i
        onClick={onRemove}
        className={cn('fa', { 'fa-trash': isDeleteAvailable, 'fa-times': !isDeleteAvailable }, 'remove-icon')}
      />
      <span className={cn('progress-bar')} style={progressBarStyle} />
    </div>
  );
};

FileAttachmentProgress.propTypes = {
  className: T.string,
  name: T.string.isRequired,
  isUploaded: T.bool.isRequired,
  onRemove: T.func.isRequired,
  progress: T.number.isRequired,
};

export default FileAttachmentProgress;
