import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { resetResources } from 'reducers/dataTable';
// components
import { FormattedMessage } from 'react-intl';
import FlexTable from 'components/FlexTable/FlexTable';
import DataTableHeader from './DataTableHeader/DataTableHeader';
import DataTableRow from './DataTableRow/DataTableRow';
import DataTablePreloader from './DataTablePreloader/DataTablePreloader';
import Pagination from './Pagination/DataTablePagination';
import ScrollHint from 'components/ScrollHint';
import Sortable from 'sortablejs';
// utils
import cn from 'classnames';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { isEqualKeys } from 'utils';
// styles
import './DataTable.scss';

const bottomIndent = 70;

class DataTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRowID: null,
      isPinnedHeader: false,
      tableStyle: null,
      isScrollHintVisible: props.hasScrollHint,
    };
    this.sortableObject = null;
  }

  componentDidMount() {
    if (this.tableReference) {
      this.tableReference.addEventListener('scroll', this.handleTableScroll, { passive: true });
    }
    this.bodybag = document.getElementById('bodybag');
  }

  componentDidUpdate({ isDraggable: isDraggablePrev }) {
    const { isDraggable } = this.props;
    if (isDraggablePrev !== isDraggable && this.sortableObject) {
      this.sortableObject.options.disabled = !isDraggable;
    }
  }

  componentWillUnmount() {
    const { resetResources, tableKey } = this.props;
    if (tableKey) resetResources(tableKey);
    if (this.tableReference) {
      this.tableReference.removeEventListener('scroll', this.handleTableScroll);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { paginationProps, columns, sort, data } = nextProps;
    const { paginationProps: nextPaginationProps, columns: nextColumns, sort: nextSort, data: nextData } = this.props;
    const isPageChanged = !isEqualKeys(['currentPage', 'totalItems', 'perPage'], nextPaginationProps, paginationProps);
    const isColumnsChanged = columns !== nextColumns;
    const isSortChanged = sort !== nextSort;
    const isDataChanged = data !== nextData;
    const isStateChanged = this.state !== nextState;
    return isStateChanged || isPageChanged || isColumnsChanged || isSortChanged || isDataChanged;
  }

  handleTableScroll = () => {
    const _t = this.tableReference;
    const _e = this.expandableReference;
    if (!_t) return;
    if ((_t.scrollLeft > 0) && this.state.isScrollHintVisible) {
      this.setState({ isScrollHintVisible: false });
    }
    if (_e) {
      const overSize = _t.scrollWidth - _t.offsetWidth - _t.scrollLeft;
      _e.style.marginLeft = `-${overSize}px`;
    }
  };

  setExpandableRef = (ref) => {
    this.expandableReference = ref;
    this.handleTableScroll();
  };

  setTabletSelectedRow = (id) => {
    const { isExpandable } = this.props;
    if (isExpandable) this.setState((prevState) => ({ selectedRowID: id === prevState.selectedRowID ? null : id }));
  };

  onPinnedIconClick = () => {
    const tableCoords = this.tableReference.getBoundingClientRect();
    const maxHeight = window.screen.height - tableCoords.top - this.bodybag.scrollTop - bottomIndent;
    this.setState((prevState) => ({
      isPinnedHeader: !prevState.isPinnedHeader,
      tableStyle: {
        maxHeight: !prevState.isPinnedHeader ? `${maxHeight}px` : '',
        overflowY: !prevState.isPinnedHeader ? 'auto' : '',
      },
    }));
  };

  handleSort = (sortKey) => {
    if (!sortKey) return;
    const { onSortChange, sort } = this.props;
    const sortKeys = sort.split(' ') || [];
    const direction = sortKeys[sortKeys.length - 1];
    const sortDirection = (direction === 'asc') ? 'desc' : 'asc';
    onSortChange(`${sortKey} ${sortDirection}`);
  };

  getTableReference = (ref) => {
    const { isDraggable, onOrderUpdate } = this.props;
    this.tableReference = ref;
    const flexTableNode = isDraggable && ref && ref.querySelector('.FlexTable');
    if (flexTableNode) {
      this.sortableObject = Sortable.create(flexTableNode, {
        animation: 150,
        handle: '.handle', // must add this class to element which drags
        draggable: '.drag-row',
        onUpdate: onOrderUpdate,
      });
    }
  };

  getOffsetTop = (count) => (Math.min(count, 3) * 30); // centering scroll hint

  render() {
    const { data, columns, className, sort, paginationProps, isExpandable, renderExpandable, getRowClass, scrollable,
      hasDesktopViewOnly, hasScrollHint, rowKey, isDraggable, tableKey, isLoading } = this.props;
    const { selectedRowID, tableStyle, isPinnedHeader, isScrollHintVisible } = this.state;
    const [sortField] = sort.split(' ');
    const desktopClassName = { 'desktop-view': hasDesktopViewOnly };
    const hasData = !!data.length;
    return (
      <div className={cn('DataTable', className, desktopClassName)}>
        {hasScrollHint && (
          <ScrollHint
            offsetTop={this.getOffsetTop(data.length)}
            visible={isScrollHintVisible && hasData}
            label={<FormattedMessage id="general.button.scrollRight" />}
          />
        )}
        <FlexTable
          getRef={this.getTableReference}
          scrollable={scrollable}
          style={tableStyle}
          columnsCount={columns.length}
          className={cn(desktopClassName)}
        >

          {/* TABLE HEADER */}
          {hasData && (
            <DataTableHeader
              hasDesktopViewOnly={hasDesktopViewOnly}
              isPinnedHeader={isPinnedHeader}
              columns={columns}
              handleSort={this.handleSort}
              onPinnedIconClick={this.onPinnedIconClick}
            />
          )}

          {/* TABLE BODY */}
          {data.length > 0 && data.map((rowData, rowIndex) => {
            const tableRowKey = get(rowData, rowKey, rowIndex);
            return (
              <DataTableRow
                key={`row-${tableRowKey}`}
                rowIndex={rowIndex}
                rowKey={tableRowKey}
                rowData={rowData}
                columns={columns}
                currentSortKey={sortField}
                hasDesktopViewOnly={hasDesktopViewOnly}
                getRowClass={getRowClass}
                selectedRowID={selectedRowID}
                isExpandable={isExpandable}
                setTabletSelectedRow={this.setTabletSelectedRow}
                setExpandableRef={this.setExpandableRef}
                renderExpandable={renderExpandable}
                isDraggable={isDraggable}
              />
            );
          })}

          {/* TABLE PRELOADER */}
          {!hasData && (
            <DataTablePreloader
              isLoading={isLoading}
              tableKey={tableKey}
              columns={columns}
              linesCount={5}
              hasDesktopViewOnly={hasDesktopViewOnly}
            />
          )}
        </FlexTable>
        {!isEmpty(paginationProps) && !!data.length &&
          <Pagination {...paginationProps} />}
      </div>
    );
  }
}

DataTable.defaultProps = {
  data: [],
  className: '',
  sort: '',
  rowKey: 'id',
  paginationProps: {},
};

DataTable.propTypes = {
  data: T.array,
  columns: T.array.isRequired,
  className: T.string,
  sort: T.string,
  paginationProps: T.object,
  isExpandable: T.bool,
  renderExpandable: T.func,
  resetResources: T.func,
  onSortChange: T.func,
  getRowClass: T.func,
  scrollable: T.bool,
  isDraggable: T.bool,
  isLoading: T.bool,
  hasScrollHint: T.bool,
  hasDesktopViewOnly: T.bool,
  tableKey: T.string,
  rowKey: T.string,
  onOrderUpdate: T.func,
  success: T.bool,
  status: T.string,
};

export default connect(null, { resetResources })(DataTable);
