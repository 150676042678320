import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateInvitedUserLanguage } from 'reducers/invitations';
// component
import LayoutMinimal from 'components/LayoutMinimal';
import Button from 'components/Button';
import ReactSelect from 'components/Select/ReactSelect';
import StepDots from '../components/StepDots';
import { FormattedMessage } from 'react-intl';
// utils
import { getLSLanguage, setLSLanguage } from 'utils/localStorageHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './CompleteRegistration.scss';
// constants
import { langOptions } from 'utils/localizationHelper';

const CompleteRegistrationLanguage = ({
  isLoading,
  params: { id },
  router,
  user,
  updateInvitedUserLanguage,
}) => {
  const [lang, setLang] = useState(user?.language || getLSLanguage());

  useEffect(() => {
    if (user?.language && user.language !== lang) {
      setLang(user.language);
    }
  }, [user?.language]);

  const setLanguage = () => {
    updateInvitedUserLanguage(id, lang)
      .then(() => {
        setLSLanguage(lang);
        router.push(`/invitations/${id}/complete-registration`);
      })
      .catch((error) => {
        router.push('/login');
        return toastResponseErrors(error);
      });
  };

  return (
    <LayoutMinimal containerClass="choose-lang CompleteRegistration">
      <FormattedMessage id="container.invitations.chooseLang" tagName="h1" />
      <div className="choose-lang-box">
        <div className="choose-lang-label">
          <FormattedMessage id="container.invitations.chooseLang" />
        </div>
        <ReactSelect
          options={langOptions}
          value={lang}
          labelKey="label"
          onChange={(option) => setLang(option.value)}
          matchProp="value"
          clearable={false}
          searchable={false}
        />
      </div>
      <Button disabled={!lang || isLoading} className="primary lm-button" onClick={setLanguage}>
        <FormattedMessage id="general.button.next" />
      </Button>
      <StepDots dotsCount={6} activeStep={2} />
    </LayoutMinimal>
  );
};

CompleteRegistrationLanguage.propTypes = {
  params: T.object.isRequired,
  user: T.object,
  isLoading: T.bool.isRequired,
  router: T.object.isRequired,
  updateInvitedUserLanguage: T.func.isRequired,
};

export default connect(
  (state) => ({
    user: state.invitations.user,
    isLoading: state.invitations.isLoading,
  }), {
    updateInvitedUserLanguage,
  }
)(CompleteRegistrationLanguage);
