import React from 'react';
import T from 'prop-types';
import round from 'lodash.round';
import styles from './AIPercent.module.scss';

const AIPercent = ({ percent }) => (
  <span className={styles['ai-percent']}>
    {(round(percent || 0, 2)) + '%'}
  </span>
);

AIPercent.propTypes = {
  percent: T.number.isRequired,
};

export default AIPercent;
