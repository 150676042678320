import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchMobileCheckin } from 'reducers/checkins/mobileCheckin';
import { checkinUser } from 'reducers/auth';
import { openModal, closeModal } from 'reducers/modals';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import Waypoint from 'react-waypoint';
import SearchBox from 'components/SearchBox';
import FarmTypeLabel from 'components/FarmTypeLabel/FarmTypeLabel';
import Button from 'components/Button';
import NothingBox from 'components/NothingBox';
import CheckinSuccessModal from './modals/CheckinSuccessModal';
import Preloader from 'components/Preloader';
import Avatar from 'components/Avatar/Avatar';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
// assets
import './MobileCheckin.scss';

class MobileCheckin extends Component {

  constructor() {
    super();
    this.state = {
      isPending: false,
    };
  }

  componentDidMount() {
    const { fetchMobileCheckin, setPageOptions, params } = this.props;
    fetchMobileCheckin(params);
    setPageOptions({ title: 'checkins', backLink: '' });
  }

  getSearched = (search) => {
    const { fetchMobileCheckin, params } = this.props;
    fetchMobileCheckin({ ...params, page: 1, search });
  };

  loadMorePlaces = () => {
    const { params, fetchMobileCheckin } = this.props;
    fetchMobileCheckin({ ...params, page: params.page + 1 }, true);
  };

  checkIn = (farm) => {
    const { checkinUser, openModal, closeModal } = this.props;
    const { router } = this.context;

    this.setState({ isPending: true });
    checkinUser(farm.id)
      .then((checkin) => {
        this.setState({ isPending: false });
        router.goBack();
        openModal(
          <CheckinSuccessModal checkin={checkin} closeModal={closeModal} />, {
            position: 'bottom',
            backdropStyles: { opacity: 0.2 },
          }
        );
      })
      .catch((response) => {
        this.setState({ isPending: false });
        toastResponseErrors(response);
      });
  };

  render() {
    const { currentUser, farms, params: { search }, meta: { total }, isLoading } = this.props;
    const { isPending } = this.state;
    const hasMoreItems = farms.length < total;

    return (
      <div className="MobileCheckin">
        <Preloader isActive={isPending} />

        <div className="search-box-wrapper">
          <SearchBox initialValue={search} onChange={this.getSearched} className="full-width" autoFocus />
        </div>

        <div className="farms-list">
          {farms.map((farm) => {
            const isCheckedIn = currentUser.check_in ? currentUser.check_in.farm_id === farm.id : false;
            return (
              <div key={farm.id} className={cn('checkin-farm-row', { 'checked-in': isCheckedIn })}>
                <div className="checkin-farm">
                  <Avatar type="farm" avatarSize={35} iconSize={20} />
                  <div className="checkin-farm-info">
                    <div className="name black">
                      <div className="farm-name">
                        {farm.name || '-'}&nbsp;&nbsp;
                        <FarmTypeLabel farmType={farm.farm_type} className="farm-type" />
                      </div>
                    </div>
                    <div className="description">
                      <FormattedMessage
                        tagName="div"
                        id="general.farmFullAddress"
                        values={{ ...farm, external_link_id: null }}
                      />
                    </div>
                  </div>
                </div>
                {!isCheckedIn
                  ? (
                    <Button primary small onClick={() => this.checkIn(farm)}>
                      <FormattedMessage id="general.checkin" />
                    </Button>
                  )
                  : (
                    <span className="here-label">
                      <FormattedMessage id="general.youAreHere" />
                    </span>
                  )}
              </div>
            );
          })}
        </div>

        {hasMoreItems && !isLoading &&
          <Waypoint onEnter={this.loadMorePlaces} />}

        <Preloader isActive={isLoading} />

        <NothingBox
          itemsName="farms"
          display={farms && !farms.length}
          isLoading={isLoading}
          search={search}
        />
      </div>
    );
  }
}


MobileCheckin.contextTypes = {
  router: T.object.isRequired,
};

MobileCheckin.propTypes = {
  currentUser: T.object.isRequired,
  fetchMobileCheckin: T.func.isRequired,
  checkinUser: T.func.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  farms: T.array,
  isLoading: T.bool,
  params: T.object,
  meta: T.object,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    farms: state.checkins.mobileCheckin.resources,
    isLoading: state.checkins.mobileCheckin.isLoading,
    params: state.checkins.mobileCheckin.params,
    meta: state.checkins.mobileCheckin.meta,
    authUser: state.auth.user,
  }), {
    fetchMobileCheckin,
    checkinUser,
    openModal,
    closeModal,
    setPageOptions,
  }
)(MobileCheckin);
