import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchDiagnosesList } from 'reducers/staticData';
// components
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
// styles
import styles from './DiseasesList.module.scss';
// assets
import magicLoopSvg from '../../../../public/images/magic.svg';

class DiseasesList extends Component {

  state = {
    search: '',
  };

  componentDidMount() {
    const { diagnosesList, fetchDiagnosesList } = this.props;
    if (!diagnosesList.length) fetchDiagnosesList();
  }

  onDiagnosesSearch = (search) => (this.setState({ search }));

  filterDiseases = (list, search, ids) => {
    const preffered = [];
    const others = [];
    list.forEach((item) => {
      if (item.name.toLowerCase().indexOf(search.toLowerCase()) === -1) return;
      if (ids.includes(item.id)) {
        preffered.push({ ...item, hasIcon: true });
        return;
      }
      others.push(item);
    });
    return preffered.concat(others);
  };

  render() {
    const { diagnosesList, isLoading, onItemSelect, suggestedIds } = this.props;
    const { search } = this.state;
    const filteredList = this.filterDiseases(diagnosesList, search, suggestedIds);
    return (
      <div className={styles['diseases-list-wrapper']}>
        <div className={styles['search-disease-box']}>
          <SearchBox className={styles['search-disease']} onChange={this.onDiagnosesSearch} />
        </div>
        <div className={styles['diseases-list']}>
          <Preloader isActive={isLoading} />
          {filteredList.map(({ id, name, common_name, hasIcon }, key) => (
            <div className={styles.line} onClick={() => onItemSelect({ common_name, name, id })} key={key}>
              {hasIcon && <img src={magicLoopSvg} alt="" className={styles['magic-icon']} />}
              {common_name || name}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

DiseasesList.defaultProps = {
  suggestedIds: [],
};

DiseasesList.propTypes = {
  onItemSelect: T.func.isRequired,
  isLoading: T.bool.isRequired,
  diagnosesList: T.array.isRequired,
  fetchDiagnosesList: T.func.isRequired,
  suggestedIds: T.array,
};

export default connect(
  (state) => ({
    diagnosesList: state.staticData.diagnosesList.resources,
    isLoading: state.staticData.diagnosesList.isLoading,
  }), { fetchDiagnosesList }
)(DiseasesList);
