import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';
import pickBy from 'lodash.pickby';

// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  page: 1,
  per_page: 16,
};

const initialState = {
  resources: [],
  isLoading: false,
  params: {
    ...defaultParams,
    search: '',
  },
  meta: {
    total: 0,
  },
};

const FETCH_ASSETS = 'gallery/FETCH_ASSETS';
const [FETCH_ASSETS_PENDING, FETCH_ASSETS_FULFILLED, FETCH_ASSETS_REJECTED] = [
  `${FETCH_ASSETS}_PENDING`,
  `${FETCH_ASSETS}_FULFILLED`,
  `${FETCH_ASSETS}_REJECTED`,
];

const UPDATE_TAGS_COUNT = 'gallery/UPDATE_TAGS_COUNT';
const RESET_DATA = 'gallery/RESET_DATA';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAssets = (params = defaultParams) => createAction(FETCH_ASSETS)(
  fetchFromAPI('/dc_assets', { params: pickBy(params) })
    .then((response) => ({ ...response, params }))
);

export const updateTagsCount = (assetId, tagsCountData) => createAction(UPDATE_TAGS_COUNT)({ assetId, tagsCountData });

export const resetData = createAction(RESET_DATA);
// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_ASSETS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_ASSETS_FULFILLED]: (state, action) => {
    return action.payload.params.page > 1 ? {
      ...state,
      ...action.payload,
      isLoading: false,
      resources: [
        ...state.resources,
        ...action.payload.resources,
      ],
    } : {
      ...state,
      ...action.payload,
      isLoading: false,
    };
  },
  [FETCH_ASSETS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [RESET_DATA]: () => ({
    ...initialState,
  }),
  [UPDATE_TAGS_COUNT]: (state, { payload: { assetId, tagsCountData } }) => {
    const { resources } = state;
    let itemIndex = null;
    let assetIndex = null;

    resources.forEach((item, ind) => {
      item.assets.forEach((assetItem, index) => {
        if (assetItem.id === assetId) {
          itemIndex = ind;
          assetIndex = index;
        }
      });
    });

    if ((itemIndex === null) || (assetIndex === null)) return state;

    const imageItem = resources[itemIndex];
    const mediaAssets = [...resources[itemIndex].assets];
    const newAsset = { ...mediaAssets[assetIndex], ...tagsCountData };

    return ({
      ...state,
      resources: [
        ...state.resources.slice(0, itemIndex),
        {
          ...imageItem,
          assets: [
            ...imageItem.assets.slice(0, assetIndex),
            newAsset,
            ...imageItem.assets.slice(assetIndex + 1),
          ],
        },
        ...state.resources.slice(itemIndex + 1),
      ],
    });
  },

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
