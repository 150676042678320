import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserFilterSectionDropdown from '../UserFilterSectionDropdown';
import MultiSelectMenu from '../Menu/MultiSelectMenu';
// utils
import isEqual from 'lodash.isequal';
import { getSectionOptionValueKey, getConditionTranslationsBySection } from 'utils/userFiltersHelper';
import { multiEntityNameSections, sectionEntityKeys } from 'utils/constants/userFilters';

class MultiSelectSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.condition,
      entities: props.entities || [],
    };
  }

  handleConditionChange = ({ target: { name } }) => {
    this.setState({ value: name, entities: [] });
  };

  handleApply = () => {
    const { onOptionSelect, type } = this.props;
    const { value, entities } = this.state;
    if (['cont_all_of', 'cont_any_of', 'cont_none_of'].includes(value) && entities.length) {
      const options = [{
        key: getSectionOptionValueKey(type),
        value: entities.map((entity) => entity.id),
      }];

      onOptionSelect(value, options, { [sectionEntityKeys[type]]: entities });
      return;
    }

    onOptionSelect(value, [], { [sectionEntityKeys[type]]: [] });
  };

  resetData = () => {
    const { condition, entities } = this.props;
    const newState = { entities: entities || [], value: condition };
    const isEqualState = isEqual(newState, this.state);
    if (!isEqualState) this.setState(newState);
  };

  renderDropdownMenu = (isOpened) => {
    const { listOptions, type } = this.props;
    const { value, entities } = this.state;

    return (
      <MultiSelectMenu
        type={type}
        entities={entities}
        onSelect={this.onItemAdd}
        onRemove={this.onItemRemove}
        isOpened={isOpened}
        currentValue={value}
        options={listOptions}
        onConditionChange={this.handleConditionChange}
        onApply={this.handleApply}
      />
    );
  };

  onItemAdd = ({ item: entity }) => {
    this.setState((prevState) => ({
      ...prevState,
      entities: [...prevState.entities, entity],
    }));
  }

  onItemRemove = (removableEntity) => {
    this.setState((prevState) => ({
      ...prevState,
      entities: prevState.entities.filter((entity) => entity.id !== removableEntity.id),
    }));
  }

  getLabel = () => {
    const { placeholder, condition, isInvalid, options, type } = this.props;
    if (isInvalid || !condition) return placeholder;

    const valuesLength = options
      .reduce((prev, next) => prev + next.value.length, 0);

    return (
      <FormattedMessage id={getConditionTranslationsBySection(type)[condition]} values={{ count: valuesLength }} />
    );
  };

  render() {
    const { condition, icon, onSectionRemove, isInitialOpened, isRemovable, isDisabled, isInvalid } = this.props;
    return (
      <UserFilterSectionDropdown
        isInitialOpened={isInitialOpened}
        isDisabled={isDisabled}
        currentValue={condition}
        triggerLabel={this.getLabel()}
        menuRenderer={this.renderDropdownMenu}
        isRemovable={isRemovable}
        onRemove={onSectionRemove}
        triggerIcon={icon}
        onClose={this.resetData}
        isInvalid={isInvalid}
      />
    );
  }
}

MultiSelectSection.propTypes = {
  entities: T.array,
  condition: T.string.isRequired,
  icon: T.string.isRequired,
  listOptions: T.array.isRequired,
  isInvalid: T.bool,
  options: T.array,
  onOptionSelect: T.func,
  onSectionRemove: T.func,
  isRemovable: T.bool,
  isDisabled: T.bool,
  isInitialOpened: T.bool,
  placeholder: T.oneOfType([T.node, T.string]),
  type: T.oneOf(multiEntityNameSections).isRequired,
};

MultiSelectSection.defaultProps = {
  options: [],
};

export default MultiSelectSection;
