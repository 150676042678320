import { compose, defaultProps, withPropsOnChange } from 'recompose';
import supercluster from 'points-cluster';

export const Clusterify = (propName) => compose(
  defaultProps({
    clusterOptions: {
      radius: 45,
      minZoom: 3,
      maxZoom: 9,
    },
  }),
  // precalculate clusters if markers data has changed
  withPropsOnChange(
    [propName],
    (props) => {
      const { clusterOptions: { radius, minZoom, maxZoom } } = props;
      const markers = props[propName] || [];

      return {
        getCluster: supercluster(
          markers,
          {
            minZoom, // min zoom to generate clusters on
            maxZoom, // max zoom level to cluster the points on
            radius, // cluster radius in pixels
          }
        ),
      };
    },
  ),
  // get clusters specific for current bounds and zoom
  withPropsOnChange(
    (props, nextProps) => {
      const propsOnChange = ['mapParams', 'getCluster'].concat(nextProps.formatClusterDataOnChange || []);
      return propsOnChange.some((propName) => nextProps[propName] !== props[propName]);
    },
    (props) => {
      const { mapParams, getCluster, formatClusterData = () => ({}) } = props;
      return {
        clusters: mapParams.bounds
          ? getCluster(mapParams)
            .map(({ wx, wy, numPoints, points }) => ({
              lat: wy,
              lng: wx,
              numPoints,
              points,
              id: `${numPoints}_${points[0].id}`,
              ...formatClusterData(points, props),
            }))
          : [],
      };
    },
  ),
);

export default Clusterify;
