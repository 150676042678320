import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closePortal } from 'reducers/portal';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import MobileHeader from 'components/MobileHeader/MobileHeader';
import SingleDatePickerMobile from 'components/DatePicker/SingleDatePicker/SingleDatePickerMobile';
import CircleCheckbox from 'components/CircleCheckbox';
import LabeledInput from 'components/LabeledInput/LabeledInput';
import CommentBox from 'components/CommentBox';
import ConditionRadioButtons from 'components/ConditionRadioButtons/ConditionRadioButtons';
import Preloader from 'components/Preloader';
import TruckingRouteCard from 'components/TruckingRouteCard/TruckingRouteCard';
import FormattedWeight from 'components/FormattedWeight';
import WeightLabeledInput from 'components/Input/WeightLabeledInput';
// utils
import pick from 'lodash.pick';
import moment from 'moment';
import { showToastrMessage } from 'utils';
import { getPigGroupStartDate } from 'utils/pigGroupsHelper';
import { currentUtcOffsetTime } from 'utils/timeHelper';
import { formatBackEndWeightToSystem } from 'utils/weightHelper';
import { getAvgWeightBasedOnCommitment, getCommitmentDirection } from 'utils/commitmentsHelper';
// endpoints
import { getFarmBarns } from 'endpoints/farms';
// styles
import './CreateShipmentMobileWizard.scss';

class CreateShipmentMobileWizard extends Component {

  constructor(props) {
    super(props);
    this.wizardSteps = [
      { name: 'delivery', renderer: this.renderDeliveryStep },
      { name: 'amount', renderer: this.renderPigsAmountStep },
      { name: 'weight', renderer: this.renderEstimatedWeightStep },
      { name: 'condition', renderer: this.renderConditionStep },
      { name: 'note', renderer: this.renderNoteStep },
      { name: 'barns', renderer: this.renderBarnsStep },
      { name: 'summary', renderer: this.renderSummaryStep },
    ];

    this.state = {
      step: 0,
      barns: [],
      isSubmitting: false,
      isDatePickerOpen: false,
      data: this.getInitialState(props.commitment),
    };
  }

  componentDidMount() {
    const { group: { farm_id } } = this.props;
    this.fetchFarmBarns(farm_id);
  }

  getInitialState = (commitment) => {
    const { group, measurementSystem } = this.props;
    const utsOffset = group?.farm?.utc_offset;
    const defaultDeliveryDate = utsOffset ? currentUtcOffsetTime(utsOffset) : moment();
    const avgWeight = commitment
      ? formatBackEndWeightToSystem(getAvgWeightBasedOnCommitment(commitment), measurementSystem)
      : '';
    return {
      amount: commitment?.head_count || 0,
      delivery_date: getPigGroupStartDate(commitment?.arrive_at, defaultDeliveryDate),
      estimated_weight: avgWeight,
      condition: '',
      barn_ids: commitment?.barn_id ? [commitment.barn_id] : [],
      note: commitment?.comment || '',
    };
  }

  setDeliveryDate = (date) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        delivery_date: date, // date is a moment object
      },
    }));
  };

  setInputData = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      data: { ...prevState.data, [name]: Math.abs(parseInt(value || 0, 10)) },
    }));
  };

  setCondition = (value) => {
    this.setState((prevState) => ({
      data: { ...prevState.data, condition: value },
    }));
  };

  setNote = (note) => {
    this.setState((prevState) => ({
      data: { ...prevState.data, note },
    }));
  };

  setSubmitting = (isSubmitting) => {
    this.setState({ isSubmitting });
  };

  toggleBarn = (barnId, isSelected) => {
    const { data } = this.state;
    const barn_ids = isSelected
      ? data.barn_ids.filter((id) => id !== barnId)
      : [...data.barn_ids, barnId];
    this.setState({ data: { ...data, barn_ids } });
  };

  toggleDatePicker = () => {
    this.setState((prevState) => ({ ...prevState, isDatePickerOpen: !prevState.isDatePickerOpen }));
  };

  goNext = () => {
    const { step, data: { condition } } = this.state;
    const isNoteStepRequired = step === 3 && condition !== 'poor';
    this.setState({ step: step + (isNoteStepRequired ? 2 : 1) });
  };

  goBack = () => {
    const { step, data: { condition } } = this.state;
    const isNoteStepRequired = step === 5 && condition !== 'poor';
    this.setState({ step: step - (isNoteStepRequired ? 2 : 1) });
  };

  removeBarnsStep = () => {
    this.wizardSteps = this.wizardSteps.filter((step) => step.name !== 'barns');
  };

  fetchFarmBarns = (id) => {
    const { isOnline } = this.props;
    if (!isOnline) {
      this.removeBarnsStep();
      return;
    }

    getFarmBarns(id)
      .then((resources) => {
        if (!resources.length) this.removeBarnsStep();
        this.setState({ barns: resources });
      });
  };

  createPlacement = () => {
    const { onSubmit, closePortal, commitment } = this.props;
    const { data } = this.state;
    const resource = pick(data, ['amount', 'estimated_weight', 'condition', 'barn_ids']);
    resource.migration_type = 'placement';
    resource.comments_pig_migrations = { text: data.note, mentioned_user_ids: [] };
    resource.delivery_date = data.delivery_date?.format('YYYY-MM-DD') || '';

    if (commitment?.id) {
      resource.loading_commitment_id = commitment.id;
    }

    const { condition } = resource;
    if (condition === 'poor' && data.note.length === 0) {
      showToastrMessage('component.toastr.shipment.poorPigsNote', 'error', 'fa fa-ban');
      return;
    }

    this.setSubmitting(true);

    onSubmit(resource)
      .then(() => closePortal())
      .catch(() => this.setSubmitting(false));
  };

  closeWizard = () => {
    const { closePortal } = this.props;
    closePortal();
  };

  isOutsideRange = (day) => {
    const groupCreatedOn = moment(this.props.group.created_on);

    return day.isAfter(moment(), 'day') || day.isBefore(groupCreatedOn, 'day');
  };

  isNextDisabled = (step) => {
    const { data, barns } = this.state;
    switch (step.name) {
      case 'delivery': return !data.delivery_date;
      case 'amount': return !(data.amount > 0);
      case 'weight': return !Number(data.estimated_weight);
      case 'condition': return !data.condition;
      case 'note': return !data.note;
      case 'barns': return !!barns.length && !data.barn_ids.length;
      default: return false;
    }
  };

  renderHeaderTitle = () => {
    const { group } = this.props;
    const { step } = this.state;
    const lastStep = this.wizardSteps.length - 1;
    const progress = Math.floor(((step + 1) / (lastStep + 1)) * 100);

    return (
      <div className="header-title">
        <FormattedMessage tagName="div" id="general.startGroup" values={{ name: group.name }} />
        <div className="wizard-progress-bar">
          <div className="wizard-progress" style={{ width: progress + '%' }} />
        </div>
      </div>
    );
  };

  renderDeliveryStep = () => {
    const { data: { delivery_date }, isDatePickerOpen } = this.state;
    const formattedDate = delivery_date?.format('MM/DD/YYYY') || '';
    return (
      <div className="step step-0">
        <FormattedMessage id="component.createShipmentMobileWizard.confirmDelivery">
          {(text) => (<p className="step-description">{text}</p>)}
        </FormattedMessage>
        <LabeledInput
          key="delivery_date"
          type="text"
          label={<i className="fa fa-calendar calendar-icon" />}
          value={formattedDate}
          onChange={!isDatePickerOpen ? this.setDeliveryDate : null}
          onFocus={(e) => {
            e.target.blur();

            this.toggleDatePicker();
          }}
          className="labeled-input"
        />
      </div>
    );
  };

  renderPigsAmountStep = () => {
    const { data } = this.state;
    return (
      <div className="step step-2">
        <FormattedMessage id="component.createShipmentMobileWizard.howManyPigs">
          {(text) => (<p className="step-description">{text}</p>)}
        </FormattedMessage>
        <LabeledInput
          key="pigs"
          label="Pigs"
          name="amount"
          type="number"
          min="0"
          pattern="[0-9]*"
          value={data.amount}
          onChange={this.setInputData}
          className="labeled-input"
          onFocus={(e) => e.target.select()}
          autoFocus
        />
      </div>
    );
  };

  renderEstimatedWeightStep = () => {
    const { data } = this.state;
    return (
      <div className="step step-3">
        <FormattedMessage id="component.createShipmentMobileWizard.whatEstWeight">
          {(text) => (<p className="step-description">{text}</p>)}
        </FormattedMessage>
        <WeightLabeledInput
          key="lbs"
          value={data.estimated_weight}
          onChange={(estimated_weight) => {
            this.setState((prevState) => ({ ...prevState, data: { ...prevState.data, estimated_weight } }));
          }}
          hasAutoFocus
          className="labeled-input"
          onFocus={(e) => e.target.select()}
        />
      </div>
    );
  };

  renderConditionStep = () => {
    const { data: { condition } } = this.state;
    return (
      <div className="step step-4">
        <FormattedMessage id="component.createShipmentMobileWizard.describeCondition">
          {(text) => (<p className="step-description">{text}</p>)}
        </FormattedMessage>
        <ConditionRadioButtons value={condition} onChange={this.setCondition} className="condition-selector" />
      </div>
    );
  };

  renderNoteStep = () => {
    const { data: { note } } = this.state;
    return (
      <div className="step step-5">
        <FormattedMessage id="component.createShipmentMobileWizard.moreAboutCondition">
          {(text) => (<p className="step-description">{text}</p>)}
        </FormattedMessage>
        <CommentBox
          isOpen
          value={note}
          onValueChange={this.setNote}
        />
      </div>
    );
  };

  renderBarnsStep = () => {
    const { data, barns } = this.state;
    return (
      <div className="step step-1">
        <FormattedMessage id="component.createShipmentMobileWizard.whereIsGroup">
          {(text) => (<p className="step-description">{text}</p>)}
        </FormattedMessage>
        <div className="barns-selector">
          {barns.map(({ id, name }, index) => {
            const isChecked = data.barn_ids.includes(id);
            return (
              <CircleCheckbox
                key={index}
                checked={isChecked}
                onChange={() => this.toggleBarn(id, isChecked)}
                label={name}
                className="barn-checkbox"
              />
            );
          })}
        </div>
      </div>
    );
  };

  renderSummaryStep = () => {
    const { data, barns } = this.state;
    const summary = [
      { label: <FormattedMessage id="general.deliveryDate" />, value: data.delivery_date?.format('MM/DD/YYYY') || '',
        step: 0 },
      { label: <FormattedMessage id="general.totalHead" />, value: data.amount, step: 1 },
      { label: <FormattedMessage id="general.estAvgWeight" />,
        value: (
          <span>
            {data.estimated_weight}
            {' '}
            {!!data.estimated_weight && <FormattedWeight hasOnlyUnit />}
          </span>
        ),
        step: 2 },
      { label: <FormattedMessage id="general.condition" />, step: 3, value: (
        <span className={`condition-label ${data.condition}`}>
          <FormattedMessage id={`general.status.${data.condition}`} />
        </span>),
      },
      { label: <FormattedMessage id="general.location" />, step: 5,
        value: barns.filter(({ id }) => data.barn_ids.includes(id)).map(({ id, name }) => <div key={id}>{name}</div>),
        hide: !barns.length },
    ];

    return (
      <div className="step step-5">
        <FormattedMessage id="component.createShipmentMobileWizard.reviewInfo">
          {(text) => (<p className="step-description">{text}</p>)}
        </FormattedMessage>
        {summary.map(({ label, value, step, hide }) => !hide && (
          <div className="summary-row" key={step}>
            <div className="desc">{label}</div>
            <div className="value">{value}</div>
            <div className="link" onClick={() => this.setState({ step })}>
              <FormattedMessage id="general.button.edit" />
            </div>
          </div>
        ))}
        <CommentBox
          value={data.note}
          onValueChange={this.setNote}
          isOpen={!!data.note}
        />
      </div>
    );
  };

  render() {
    const { commitment } = this.props;
    const { data, step, isSubmitting, isDatePickerOpen } = this.state;
    const lastStep = this.wizardSteps.length - 1;
    const currentStep = this.wizardSteps[step];

    return (
      <div className="CreateShipmentMobileWizard">
        <MobileHeader
          title={this.renderHeaderTitle}
          backLink={this.closeWizard}
          backIcon="fa fa-times"
        />

        <div className="wizard-body">
          <Preloader isActive={isSubmitting} />
          {commitment && (
            <TruckingRouteCard
              className="mh-20 mb-20"
              direction={getCommitmentDirection(commitment)}
              commitments={commitment.loading.commitments}
              loadType={commitment.loading.load_type}
            />
          )}
          {currentStep.renderer()}
        </div>

        <div className="wizard-footer">
          <Button light onClick={this.goBack} disabled={step === 0}>
            <i className="fa fa-arrow-left mr-10" />
            <FormattedMessage id="general.button.back" />
          </Button>
          {step < lastStep && (
            <Button primary onClick={this.goNext} disabled={this.isNextDisabled(currentStep)}>
              <FormattedMessage id="general.button.next" />
              <i className="fa fa-arrow-right ml-10" />
            </Button>
          )}
          {step === lastStep && (
            <Button primary onClick={this.createPlacement} disabled={isSubmitting}>
              <FormattedMessage id="general.button.startGroup" />
            </Button>
          )}
        </div>

        {isDatePickerOpen && (
          <div className="datepicker-overlay">
            <SingleDatePickerMobile
              initialDate={data.delivery_date}
              onChange={this.setDeliveryDate}
              isOutsideRange={this.isOutsideRange}
              closePortal={this.toggleDatePicker}
            />
          </div>
        )}
      </div>
    );
  }
}

CreateShipmentMobileWizard.propTypes = {
  group: T.object.isRequired,
  commitment: T.object,
  onSubmit: T.func.isRequired,
  closePortal: T.func.isRequired,
  isOnline: T.bool.isRequired,
  measurementSystem: T.string.isRequired,
};

export default connect(
  ((state) => ({
    measurementSystem: state.auth.user.current_company.measurement_system,
    isOnline: state.network.isOnline,
  })),
  { closePortal }
)(CreateShipmentMobileWizard);
