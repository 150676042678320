import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import Link from 'react-router/lib/Link';
import Button from 'components/Button';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import EveryPigHeader from 'components/SuperAdmin/EveryPigHeader';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors, capitalize } from 'utils/responseErrorsHelper';
import { toastr } from 'react-redux-toastr';
import cn from 'classnames';

class SuperAdmins extends Component {

  componentDidMount() {
    const { fetchData, params } = this.props;
    fetchData(params).catch(toastResponseErrors);
  }

  toggleAdminUserRoleActivity = (id, isRoleEnabled) => () => {
    const { setDataItem } = this.props;
    const mode = isRoleEnabled ? 'disable' : 'enable';
    fetchFromAPI(`/admin/admin_users/${id}/${mode}`, { method: 'PUT' })
      .then(({ resource }) => {
        setDataItem(resource);
        toastr.success('', `Admin User was successfully ${mode}d!`, { icon: <i className="fa fa-thumbs-up" /> });
      })
      .catch(toastResponseErrors);
  };

  formatter = (value, unit, suffix) => (
    unit === 'second'
      ? 'Just Now'
      : `${value}${unit[0]} ${capitalize(suffix)}`
  );

  renderUserNameColumn = (user) => (
    <CustomColumn flexRow>
      <UserAvatar className="mr-10" user={user} size="35" />
      <div className="semibold">{user.full_name}</div>
    </CustomColumn>
  );

  renderEmailColumn = ({ email }) => (
    <CustomColumn label="Email">
      {email}
    </CustomColumn>
  );

  renderLastActivityColumn = ({ last_seen_at }) => (
    <CustomColumn label="Last Activity">
      {last_seen_at
        ? <TimeAgo date={last_seen_at} formatter={this.formatter} />
        : 'N/A'}
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label="Status">
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderActionsColumn = ({ is_super_admin, id }, { rowIndex }) => {
    const label = is_super_admin ? 'Disable Role' : 'Enable Role';
    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${id}-${rowIndex}`}
          wide
          buttonType="small light-gray"
          label={label}
          onClick={this.toggleAdminUserRoleActivity(id, is_super_admin)}
          customClass="show-for-large"
          dropDownData={[
            { label: 'Edit Admin', url: `/super-admin/admin-users/${id}/edit` },
          ]}
        />

        <Link to={`/super-admin/admin-users/${id}/edit`} className="button light hide-for-large">Edit</Link>
        <Button lightGrey onClick={this.toggleAdminUserRoleActivity(id, is_super_admin)} className="hide-for-large">
          {label}
        </Button>
      </div>
    );
  };

  renderExpandable = ({ is_super_admin, id }) => (
    <div>
      <Link to={`/super-admin/admin-users/${id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />Edit
      </Link>
      <Button small light onClick={this.toggleAdminUserRoleActivity(id, is_super_admin)}>
        <i className="fa fa-times-circle mr-5" />{is_super_admin ? 'Disable Role' : 'Enable Role'}
      </Button>
    </div>
  );

  render() {
    const { resources, meta: { total }, params: { page, per_page, sort, search }, isLoading, onSortChange,
      onSearchChange, onPageChange, onPerPageChange } = this.props;
    const { isTablet } = this.context;

    const columns = [
      { label: 'Name', flex: '2 1 150px', sortKey: 'first_name', renderer: this.renderUserNameColumn },
      { label: 'Email', flex: '2 1 150px', sortKey: 'email', renderer: this.renderEmailColumn },
      { label: 'Last Activity', flex: '1 1 120px', sortKey: 'last_seen_at', renderer: this.renderLastActivityColumn },
      { label: 'Status', flex: '1 1 130px', sortKey: 'tenant_admin_status', renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 175px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };

    return (
      <div className="SuperAdmins">
        <EveryPigHeader>
          <Link to="/super-admin/admin-users/create" className="button primary wider show-for-large">
            Create Super Admin
          </Link>
        </EveryPigHeader>

        <section className="small-12 column">
          <Panel>
            <Panel.Heading title="Super Admins">
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>

            <Panel.Body noPadding>
              <div className="ph-10 pv-10 hide-for-large">
                <Link to="/super-admin/admin-users/create" className="button small primary full-width wider">
                  Create Super Admin
                </Link>
              </div>

              <Preloader isActive={isLoading} />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderExpandable}
                tableKey="superAdmins"
              />

              <NothingBox
                itemsName="super_admins"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <h1>You don&#39;t have any super admins yet</h1>
              </NothingBox>
            </Panel.Body>

          </Panel>
        </section>
      </div>
    );
  }
}

SuperAdmins.contextTypes = {
  isTablet: T.bool.isRequired,
  router: T.object.isRequired,
};

SuperAdmins.propTypes = {
  resources: T.array,
  params: T.object,
  meta: T.object,
  isLoading: T.bool,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.superAdmins.resources,
      params: state.dataTable.superAdmins.params,
      meta: state.dataTable.superAdmins.meta,
      isLoading: state.dataTable.superAdmins.isLoading,
    }), {
      fetchData: (data) => fetchData('/admin/admin_users', 'superAdmins', data),
      setDataItem: (data) => setDataItem(data, 'superAdmins'),
    },
  ),
  withDataTableController(),
);

export default enhance(SuperAdmins);
