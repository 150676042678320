import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { validateToken } from 'reducers/auth';
// components
import Preloader from 'components/Preloader';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { setAuthData } from 'utils/authData';

class SwitchUserAuth extends Component {

  componentDidMount() {
    const { location: { query }, validateToken } = this.props;
    if (query.app === 'ios') {
      // localStorage.setItem('isNativeIOS', true);
    }
    const { router } = this.context;
    const headers = { uid: query.uid, 'access-token': query['access-token'], client: query.client };

    validateToken({ Accept: '*/*', ...headers })
      .then(() => {
        setAuthData(headers);
        router.push('/farmfeed');
      })
      .catch(toastResponseErrors);
  }

  render() {
    return <Preloader isActive />;
  }
}

SwitchUserAuth.contextTypes = {
  router: T.object,
};

SwitchUserAuth.propTypes = {
  location: T.object.isRequired,
  validateToken: T.func.isRequired,
};

export default connect(null, { validateToken })(SwitchUserAuth);
