import React from 'react';
import T from 'prop-types';
// components
import StickyFooter from 'components/StickyFooter';
import Button from 'components/Button';
// utils
import classnames from 'classnames/bind';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import styles from './FilterPanel.module.scss';

const cn = classnames.bind(styles);

const FilterPanel = ({ isMobile, onClose, onReset, onApply, children }) => {
  const childrenWithProps = React.Children.map(children,
    (child) => child && React.cloneElement(child, {
      className: cn('filter-item', child.props.className),
    }));
  return (
    <div className={cn('filter-panel')}>
      <section className={cn('filter-section')}>
        {!isMobile && (
          <div className={cn('title-box', 'filter-item')}>
            <span className="semibold"><FormattedMessage id="general.filters" /></span>
            <div className={cn('reset-btn')} onClick={onReset}>
              <FormattedMessage id="component.filterPanel.reset" />
            </div>
          </div>
        )}
        {isMobile && (
          <div className={cn('mobile-title-box')}>
            <div className={cn('title')}>
              <FormattedMessage id="general.filters" />
              <i className={cn('fa fa-times', 'close-btn')} onClick={onClose} />
            </div>
            <div className={cn('reset-btn')} onClick={onReset}>
              <FormattedMessage id="component.filterPanel.reset" />
            </div>
          </div>
        )}
        {childrenWithProps}
      </section>
      {isMobile && (
        <StickyFooter className={cn('apply-filter')}>
          <Button primary onClick={onApply}><FormattedMessage id="component.filterPanel.applyFilters" /></Button>
        </StickyFooter>
      )}
    </div>
  );
};

FilterPanel.propTypes = {
  children: T.any.isRequired,
  isMobile: T.bool,
  onClose: T.func,
  onReset: T.func.isRequired,
  onApply: T.func.isRequired,
};

export default FilterPanel;
