import React, { Component } from 'react';
import T from 'prop-types';
// components
import ReactSelect from 'components/Select/ReactSelect';
// utils
import cn from 'classnames';
// assets
import './ResponsiveSelect.scss';

class ResponsiveSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      direction: 'down',
    };
  }

  onOpen = () => {
    setTimeout(this.chooseDirection, 0);
  };

  composedOnChange = (option) => {
    if (!option) return;
    const { input, onChange, valueKey } = this.props;
    const value = option[valueKey];
    if (input) input.onChange(value);
    if (onChange) onChange(value);
  };

  chooseDirection = () => {
    const { direction } = this.state;

    const bodybagCoords = document.querySelector('#bodybag').getBoundingClientRect();
    const headerCorrection = bodybagCoords.top;
    const bodyHeight = bodybagCoords.height;
    const dropdownElement = document.querySelector('.Select-menu-outer');
    const responsiveSelect = this.responsiveSelect.getBoundingClientRect();
    const { height: dropdownHeight } = dropdownElement.getBoundingClientRect();

    const spaceAboveDropdown = responsiveSelect.top - headerCorrection;
    const spaceBelowDropdown = bodyHeight - spaceAboveDropdown - responsiveSelect.height;
    const isEnoughSpaceBelow = bodyHeight >= spaceAboveDropdown + responsiveSelect.height + dropdownHeight;
    const isEnoughSpaceAbove = bodyHeight >= spaceBelowDropdown + dropdownHeight;

    let newDirection;
    if (isEnoughSpaceBelow) {
      newDirection = 'down';
    } else if (isEnoughSpaceAbove) {
      newDirection = 'up';
    } else {
      newDirection = 'center';
    }

    if (newDirection !== direction) this.setState({ direction: newDirection });
  };

  render() {
    const { className, options, disabled, grey, input, meta: { touched, error }, ...props } = this.props;
    const { direction } = this.state;
    const { isMobile } = this.context;

    return (
      <div
        ref={(ref) => { this.responsiveSelect = ref; }}
        className={cn('ResponsiveSelect', className, direction, {
          disabled,
          'has-error': touched && error,
          isMobile,
          grey,
        })}
      >
        <ReactSelect
          {...input}
          clearable={false}
          searchable={false}
          {...props}
          options={options}
          onChange={this.composedOnChange}
          onOpen={this.onOpen}
        />
        <div className="backdrop" />
      </div>
    );
  }
}

ResponsiveSelect.contextTypes = {
  isMobile: T.bool,
};

ResponsiveSelect.propTypes = {
  className: T.string,
  valueKey: T.string,
  labelKey: T.string,
  options: T.array.isRequired,
  input: T.object,
  meta: T.object,
  onChange: T.func,
  disabled: T.bool,
  grey: T.bool,
};

ResponsiveSelect.defaultProps = {
  meta: {},
  valueKey: 'value',
  labelKey: 'label',
};

export default ResponsiveSelect;
