import { statsGraphBalloon } from './GraphItemBalloon/GraphItemBalloon';
import getValue from 'lodash.get';
import last from 'lodash.last';
import { calcAverageData } from 'utils';

export const defaultPieChartConfig = {
  type: 'pie',
  theme: 'light',
  fontFamily: ['Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
  dataProvider: [],
  startDuration: 0,
  addClassNames: true,
  classNameField: 'className',
  titleField: 'title',
  valueField: 'value',
  labelsEnabled: false,
  labelText: '',
  balloonText: '',
  labelRadius: 0,
  radius: '85',
  innerRadius: '40',
  pullOutRadius: 0,
  balloon: {
    borderThickness: 0,
    horizontalPadding: 0,
    verticalPadding: 0,
    pointerWidth: 0,
    shadowAlpha: 0,
    fillAlpha: 0,
    fixedPosition: true,
  },
};

export const defaultSerialChartConfig = {
  type: 'serial',
  theme: 'light',
  fontFamily: ['Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
  categoryField: 'date',
  sequencedAnimation: false,
  precision: 3,
  dataProvider: [],
  graphs: [],
  valueAxes: [{
    axisAlpha: 0,
    unit: '%',
    gridColor: '#A3A3A3',
    gridAlpha: 0.3,
    color: '#A3A3A3',
  }],
  categoryAxis: {
    gridPosition: 'start',
    gridAlpha: 0,
    color: '#A3A3A3',
    axisColor: '#A3A3A3',
    tickLength: 8,
  },
  chartCursor: {
    enabled: true,
    oneBalloonOnly: true,
    cursorColor: '#A3A3A3',
    graphBulletSize: 1,
    categoryBalloonText: ' ',
    categoryBalloonAlpha: 0,
    categoryBalloonColor: 'transparent',
    pan: true,
  },
  balloon: {
    borderThickness: 0,
    horizontalPadding: 0,
    verticalPadding: 0,
    pointerWidth: 0,
    shadowAlpha: 0,
    fillAlpha: 0,
    maxWidth: 300,
  },
  listeners: [
    {
      event: 'dataUpdated',
      method(event) {
        event.chart.zoomOut();
      },
    },
  ],
};

export const smoothedLineConfig = {
  ...defaultSerialChartConfig,
  precision: '',
  guides: [{ expand: true, fillColor: '#fff3bb', fillAlpha: 1, id: 'Guide-1' }],
  valueAxes: [{
    axisAlpha: 0,
    unit: '',
    gridColor: '#A3A3A3',
    gridAlpha: 0.3,
    color: '#A3A3A3',
    guides: [{
      dashLength: 4,
      inside: true,
      label: 'AVERAGE',
      boldLabel: true,
      lineAlpha: 1,
      lineColor: '#303030',
      color: '#303030',
      fontSize: 10,
    }],
  }],
  graphs: [{
    balloonText: statsGraphBalloon('Report period'),
    balloonColor: '#A3A3A3',
    bullet: 'round',
    lineColor: '#FF5E3A',
    lineThickness: 2,
    id: 'AmGraph-1',
    title: '',
    type: 'smoothedLine',
    valueField: 'value',
  }],
};

export const createSmoothedLine = (provider = [], catAxisTitle = '', graphsTitle = '', ballonTitle) => (
  {
    ...smoothedLineConfig,
    categoryAxis: {
      ...smoothedLineConfig.categoryAxis,
      title: catAxisTitle,
      titleFontSize: 11,
    },
    guides: [{
      ...smoothedLineConfig.guides[0],
      category: getValue(last(provider), 'date'),
      toCategory: getValue(last(provider), 'date'),
    }],
    valueAxes: [{
      ...smoothedLineConfig.valueAxes[0],
      guides: [{
        ...smoothedLineConfig.valueAxes[0].guides[0],
        value: calcAverageData(provider),
      }],
    }],
    dataProvider: provider,
    graphs: [{
      ...smoothedLineConfig.graphs[0],
      title: graphsTitle,
      balloonText: statsGraphBalloon(ballonTitle),
    }],
  }
);
