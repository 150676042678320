import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './CollapsesBox.module.scss';

const cn = classnames.bind(styles);

const CollapsesBox = ({ children, className }) => {
  const childrenWithProps = React.Children.map(children,
    (child) => child && React.cloneElement(child, {
      className: cn('collapses-box-item', child.props.className),
    }));
  return (
    <div className={cn('collapses-box', { [className]: !!className })}>
      {childrenWithProps}
    </div>
  );
};

CollapsesBox.propTypes = {
  children: T.node.isRequired,
  className: T.string,
};

export default CollapsesBox;
