import React from 'react';
import T from 'prop-types';
// components
import UserPanel from 'components/UserPanel/UserPanel';
import { PigMovementConditionBadge } from 'components/StatusBadge/StatusBadge';
import GroupFeedPanel from 'components/Groups/GroupFeedPanel/GroupFeedPanel';
import GroupActivityBox from 'components/Groups/GroupActivity/GroupActivityBox';
import GroupSourceTypeLabel from 'components/GroupSourceTypeLabel';
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// utils
import cn from 'classnames/bind';
import moment from 'moment';
import { getMigrationLabel } from 'utils';
// styles
import styles from './PigGroupMigrationPanel.module.scss';

const getURL = (url, redirectUrl) => (
  redirectUrl
    ? url + `?redirect_url=${redirectUrl}`
    : url
);

const renderCondition = (condition) => (
  condition !== null
    ? <PigMovementConditionBadge status={condition} />
    : <FormattedMessage id="general.notAvail" />
);

const renderLocation = (barns) => (
  <div className={styles.location}>
    {barns.length
      ? barns.map((location) => (<div key={location.id}>{location.name}</div>))
      : <FormattedMessage id="general.notAvail" />}
  </div>
);

const PigGroupMigrationPanel = ({
  className = '',
  currentUser,
  migration,
  pigGroupId,
  redirectUrl,
}) => {
  const { id, amount, barns, condition, delivery_date, estimated_weight, external_destinations, internal_destinations,
    modified_by, pig_group } = migration;
  const isOutgoingMigration = amount < 0;
  const isIncomingMigration = amount > 0;
  const headlineTitle = (
    <FormattedMessage
      id={`general.groupFeed.${isOutgoingMigration ? 'transferCompleted' : 'shipmentReceived'}`}
      values={{
        pgName: pig_group?.name || null,
        date: moment(delivery_date).format('MMM DD, YYYY'),
      }}
    />
  );
  const migrationModifier = modified_by || { full_name: currentUser.current_company.name };
  const isCurrentUserAdmin = currentUser.roles_map.admin;
  const groupId = pigGroupId || pig_group?.id;
  const isMigrationGroupClosed = pig_group?.status === 'closed';

  const renderTransferSource = () => (
    <div>
      {external_destinations.map((dest, index) => (
        <div key={index}>{dest.name}<GroupSourceTypeLabel sourceType="external" /></div>
      ))}
      {internal_destinations.map((dest, index) => (
        <div key={index}>{dest.name}<GroupSourceTypeLabel sourceType="internal" /></div>
      ))}
      {!external_destinations.length && !internal_destinations.length && '-'}
    </div>
  );

  const dropdownOptions = [
    { label: <FormattedMessage id="general.pageTitle.editShipment" />,
      url: getURL(`/groups/${groupId}/shipments/${id}/edit`, redirectUrl),
      hide: isOutgoingMigration || !isCurrentUserAdmin || !groupId || isMigrationGroupClosed,
    },
    { label: <FormattedMessage id="general.pageTitle.editTransfer" />,
      url: getURL(`/groups/${groupId}/transfers/${id}/edit`, redirectUrl),
      hide: isIncomingMigration || !isCurrentUserAdmin || !groupId || isMigrationGroupClosed,
    },
    { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${groupId}`,
      hide: !isCurrentUserAdmin || !pig_group },
  ];

  const migrationData = isIncomingMigration
    ? [
      { label: <FormattedMessage id="general.headPlaced" />, value: Math.abs(amount) },
      { label: <FormattedMessage id="general.estAvgWeight" />, value: <FormattedWeight value={estimated_weight} /> },
      { label: <FormattedMessage id="general.groupFeed.conditionAtArrival" />, value: renderCondition(condition) },
      { label: <FormattedMessage id="general.location" />, value: renderLocation(barns), hide: !barns.length },
    ]
    : [
      { label: <FormattedMessage id="general.headTransferred" />, value: Math.abs(amount) },
      { label: <FormattedMessage id="general.estAvgWeight" />, value: <FormattedWeight value={estimated_weight} /> },
      { label: <FormattedMessage id="general.destination" />, value: renderTransferSource() },
    ];

  return (
    <GroupFeedPanel
      className={cn({ [className]: !!className })}
      headlineTitle={headlineTitle}
      headlineIcon={<i className={`fa fa-arrow-${isOutgoingMigration ? 'left' : 'right'}`} />}
      type={isOutgoingMigration ? 'by_default' : 'shipment_received'}
    >
      <UserPanel
        className="no-margin no-border"
        user={migrationModifier}
        timestamp={delivery_date}
        label={getMigrationLabel(migration)}
        dropdownOptions={dropdownOptions}
      >
        <GroupActivityBox resources={migrationData} />
      </UserPanel>
    </GroupFeedPanel>
  );
};

PigGroupMigrationPanel.propTypes = {
  className: T.string,
  currentUser: T.object.isRequired,
  migration: T.shape({
    id: T.number.isRequired,
    amount: T.number.isRequired,
    barns: T.array,
    condition: T.string,
    delivery_date: T.string.isRequired,
    external_destinations: T.array,
    internal_destinations: T.array,
    estimated_weight: T.number,
    modified_by: T.object,
    pig_group: T.object,
  }).isRequired,
  pigGroupId: T.oneOfType([T.string, T.number]).isRequired,
  redirectUrl: T.string,
};

export default PigGroupMigrationPanel;
