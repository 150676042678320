import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import FlexTableItem from 'components/FlexTable/FlexTableItem';
// utils
import classnames from 'classnames/bind';
import renderIf from 'utils/renderIf';
import times from 'lodash.times';
// styles
import styles from './DataTablePreloader.module.scss';

const cn = classnames.bind(styles);

const DataTablePreloader = ({ columns, linesCount, isLoading, hasDesktopViewOnly }) => (
  renderIf(isLoading)(
    times(linesCount, (i) => (
      <div key={`preload-${i}`} className={cn('table-row', 'preload-row')}>
        {columns.map(({ flex, className = '', hide }, colIndex) => !hide && (
          <FlexTableItem
            key={`column-${i}-${colIndex}`}
            flex={flex}
            className={cn('table-row-item', 'preload-column', className, { 'desktop-view': hasDesktopViewOnly })}
          >
            <div className={cn('mobile-preload-label')} />
            <div className={cn('preloader-line')} />
          </FlexTableItem>
        ))}
      </div>
    ))
  )
);


DataTablePreloader.propTypes = {
  columns: T.array.isRequired,
  linesCount: T.number.isRequired,
  isLoading: T.bool.isRequired,
  hasDesktopViewOnly: T.bool,
};

export default connect(
  (state, { tableKey, isLoading = false }) => ({
    isLoading: state.dataTable[tableKey]?.isLoading || isLoading || false,
  }),
)(DataTablePreloader);
