import React from 'react';
import T from 'prop-types';
// components
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
// styles
import styles from './TenantSettingTogglePanel.module.scss';
import cn from 'classnames';

const TenantSettingTogglePanel = ({
  options,
  value,
  onChange,
  isUpdating,
  settingKey,
  titleKey,
  isDisabled,
  className,
  children,
  isBordered,
}) => {

  return (
    <div className={cn(styles['tenant-setting-panel'], { [styles.bordered]: isBordered }, className)}>
      <Preloader isActive={isUpdating} />
      {titleKey && <FormattedMessage tagName="b" id={titleKey} />}
      {children}
      <ButtonRadioGroup
        name={settingKey}
        disabled={isDisabled}
        className={styles['metric-radio-group']}
        options={options}
        value={value}
        onChange={({ target: { value, name } }) => onChange({ [name]: value })}
      />
    </div>
  );
};

TenantSettingTogglePanel.defaultProps = {
  isDisabled: false,
  isUpdating: false,
};

TenantSettingTogglePanel.propTypes = {
  isDisabled: T.bool,
  children: T.oneOfType([T.node, T.string]),
  options: T.array.isRequired,
  value: T.oneOfType([T.string, T.bool]).isRequired,
  titleKey: T.string,
  settingKey: T.string.isRequired,
  isUpdating: T.bool,
  onChange: T.func.isRequired,
  className: T.string,
  isBordered: T.bool,
};

export default TenantSettingTogglePanel;
