import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';

const SectionTargetSelector = ({
  children,
  headerRightContent,
  onBack,
  searchEntities,
  searchParams = {},
  title,
}) => {
  const [{ isLoading, resources }, setState] = useState({ isLoading: false, resources: [] });

  const fetchResources = (search = '') => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    return searchEntities({ search, ...searchParams })
      .then((response) => {
        setState({ resources: response.resources, isLoading: false });
        return response.resources;
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  useEffect(() => {
    fetchResources();
  }, []);

  return (
    <MobileListPickerComponent
      isLoading={isLoading}
      title={title}
      onBack={onBack}
      search=""
      onSearchChange={fetchResources}
      headerRightContent={headerRightContent}
    >
      {children(resources)}
    </MobileListPickerComponent>
  );
};

SectionTargetSelector.propTypes = {
  children: T.func.isRequired,
  onBack: T.func.isRequired,
  searchParams: T.object,
  searchEntities: T.func.isRequired,
  title: T.oneOfType([T.string, T.node]).isRequired,
  headerRightContent: T.node,
};

export default SectionTargetSelector;
