import { handleActions, createAction } from 'redux-actions';
import moment from 'moment';
// api
import { getActivity } from 'endpoints/farmfeed';
// farmfeed actions
import { POST_COMMENT, UPDATE_COMMENT, DELETE_COMMENT, SET_FLAGGED, UPDATE_ACTIVITY, MARK_AS_SEEN } from './farmfeed';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: {},
  isLoading: false,
};

const FETCH_ACTIVITY = 'activityShow/FETCH_ACTIVITY';
const [FETCH_ACTIVITY_PENDING, FETCH_ACTIVITY_FULFILLED, FETCH_ACTIVITY_REJECTED] = [
  `${FETCH_ACTIVITY}_PENDING`,
  `${FETCH_ACTIVITY}_FULFILLED`,
  `${FETCH_ACTIVITY}_REJECTED`,
];

const CLEAR_ACTIVITY = 'activityShow/CLEAR_ACTIVITY';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchActivity = (id) => (dispatch) => {
  dispatch(createAction(FETCH_ACTIVITY_PENDING)());
  return getActivity(id)
    .then((response) => {
      dispatch(createAction(FETCH_ACTIVITY_FULFILLED)(response));
      return response;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_ACTIVITY_REJECTED)(response));
      throw response;
    });
};

export const clearActivity = createAction(CLEAR_ACTIVITY);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_ACTIVITY_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_ACTIVITY_FULFILLED]: (state, { payload: { resource } }) => ({
    ...state,
    resource,
    isLoading: false,
  }),
  [FETCH_ACTIVITY_REJECTED]: (state) => ({
    ...state,
    resource: {},
    isLoading: false,
  }),
  [CLEAR_ACTIVITY]: () => ({
    ...initialState,
  }),
  [UPDATE_ACTIVITY]: (state, { payload }) => {
    if (state.resource.id !== payload.id) {
      return state;
    }

    return {
      ...state,
      resource: { ...payload },
    };
  },

  [POST_COMMENT]: (state, action) => {
    const newComment = action.payload.resource;
    if (newComment.commentable_id !== state.resource.id) {
      return state;
    }

    return {
      ...state,
      resource: {
        ...state.resource,
        updated_at: newComment.updated_at,
        comments: [
          newComment,
          ...state.resource.comments,
        ],
      },
    };
  },

  [DELETE_COMMENT]: (state, action) => {
    const { activityId, commentId } = action.payload;

    if (state.resource.id !== activityId) {
      return state;
    }

    const comment = state.resource.comments.find(({ id }) => id === commentId);
    const commentIndex = state.resource.comments.indexOf(comment);

    return {
      ...state,
      resource: {
        ...state.resource,
        updated_at: moment().toISOString(),
        comments: [
          ...state.resource.comments.slice(0, commentIndex),
          ...state.resource.comments.slice(commentIndex + 1),
        ],
      },
    };
  },

  [UPDATE_COMMENT]: (state, action) => {
    const { id: commentId, updated_at } = action.payload.resource;
    const { activityId } = action.payload;

    if (state.resource.id !== activityId) {
      return state;
    }

    const comment = state.resource.comments.find(({ id }) => id === commentId);
    const commentIndex = state.resource.comments.indexOf(comment);

    return {
      ...state,
      resource: {
        ...state.resource,
        updated_at,
        comments: [
          ...state.resource.comments.slice(0, commentIndex),
          action.payload.resource,
          ...state.resource.comments.slice(commentIndex + 1),
        ],
      },
    };
  },

  [SET_FLAGGED]: (state, action) => {
    const { value, activityId } = action.payload;
    return state.resource.id === activityId
      ? {
        ...state,
        resource: {
          ...state.resource,
          flagged: value,
        }
      }
      : state;
  },

  [MARK_AS_SEEN]: (state, action) => {
    if (action.payload !== state.resource.id) {
      return state;
    }

    return {
      ...state,
      resource: {
        ...state.resource,
        is_viewed: true,
      },
    };
  },

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
