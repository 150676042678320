import React from 'react';
import T from 'prop-types';
// redux
import { withState } from 'recompose';
// components
import ReactCollapse from 'react-collapse';
import PredictedTagDialog from 'components/MediaViewer/ImageTag/PredictedTagDialog';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SuggestedDiagnosesCollapse.module.scss';

const cn = classnames.bind(styles);

const SuggestedDiagnosesCollapse = ({
  isOpened,
  onToggleCollapse,
  percent,
  diagnosesList,
  label,
  suggestedDiagnosisIds,
  handleMouseEnter,
  handleMouseLeave,
  onSelect,
  onReject,
}) => (
  <>
    <div className="mb-10" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <PredictedTagDialog
        diagnosesCount={suggestedDiagnosisIds.length}
        label={label}
        percent={percent}
        isButtonsHidden={isOpened}
        buttonsData={[
          { onClick: () => (!isOpened ? onToggleCollapse(true) : null),
            label: <FormattedMessage id="component.imageTag.viewSuggestions" /> },
          { onClick: onReject, label: <FormattedMessage id="general.button.reject" /> },
        ]}
      />
    </div>

    <ReactCollapse isOpened={isOpened}>
      {suggestedDiagnosisIds.map((diagnosis_id) => {
        const { name, common_name } = diagnosesList.find(({ id }) => id === diagnosis_id) || {};
        return (
          <div key={diagnosis_id} className={cn('select-diagnosis-box')}>
            <div className={cn('name')}>{common_name || name}</div>
            <Button className={cn('select-btn')} light wide onClick={(e) => onSelect(e, diagnosis_id)}>
              <FormattedMessage id="general.button.select" />
            </Button>
          </div>
        );
      })}
      <Button className="mb-10" darkGrey wide onClick={(e) => { e.preventDefault(); onToggleCollapse(false); }}>
        <i className={cn('fa fa-times', 'remove-icon')} />
        <FormattedMessage id="general.close" />
      </Button>
    </ReactCollapse>
  </>
);

SuggestedDiagnosesCollapse.propTypes = {
  label: T.string.isRequired,
  percent: T.number.isRequired,
  diagnosesList: T.array.isRequired,
  suggestedDiagnosisIds: T.array.isRequired,
  handleMouseEnter: T.func.isRequired,
  handleMouseLeave: T.func.isRequired,
  onSelect: T.func.isRequired,
  onReject: T.func.isRequired,
  // recompose
  isOpened: T.bool.isRequired,
  onToggleCollapse: T.func.isRequired,
};

export default withState('isOpened', 'onToggleCollapse', false)(SuggestedDiagnosesCollapse);
