import React from 'react';
import T from 'prop-types';
// components
// utils
import cn from 'classnames';
// styles
import styles from './ActivityBodyCollapse.module.scss';

const ActivityBodyCollapse = ({ className, isClosed, children }) => (
  <div
    className={cn(styles['activity-body-collapse'], className, { [styles.closed]: isClosed })}
  >
    {children}
  </div>
);

ActivityBodyCollapse.propTypes = {
  className: T.string,
  isClosed: T.bool,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default ActivityBodyCollapse;
