import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { compose } from 'recompose';
import { fetchData } from 'reducers/dataTable';
// hoc
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import withDropdownActions from 'hoc/withDropdownActions';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import { CustomColumn } from 'components/DataTable/Columns';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import DataTable from 'components/DataTable/DataTable';
import MobileList from 'components/MobileList/MobileList';
import VerticalAligner from 'components/VerticalAligner';
import NothingBox from 'components/NothingBox';
import Button from 'components/Button';
import Panel from 'components/Panel';
// utils
import { isTablet } from 'react-device-detect';
import isNull from 'lodash.isnull';
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import download from 'downloadjs';
// styles
import './SourceTreatmentFeedback.scss';

class SourceTreatmentFeedback extends Component {

  componentDidMount() {
    const { fetchData, setPageOptions } = this.props;
    fetchData().catch(toastResponseErrors);
    setPageOptions({
      mobileTitle: 'sourceFeedback',
    });
  }

  componentWillUnmount() {
    this.props.setPageOptions({ mobileTitle: '' });
  }

  renderNameColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.productName" />}>
      <div className="product-name-column">
        <i className="fa fa-water-soluble icon" />
        <Link to={`/treatments/feedback/${rowData.id}`} className="color-primary">
          {rowData?.treatment_product?.name}
        </Link>
      </div>
    </CustomColumn>
  );

  renderTreatmentsColumn = ({ treatments_count }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.treatments" />}>
      <span>{treatments_count}</span>
    </CustomColumn>
  );

  renderFeedBackColumn = (key) => (item) => (
    <CustomColumn>
      {isNull(item[key])
        ? <span className="color-dark-gray"><FormattedMessage id="general.feedback.resultsPending" /></span>
        : <ProgressBar progress={item[key]} />}
    </CustomColumn>
  );

  renderExpandable = ({ id, treatment_product }) => (
    <div>
      <Link className="button light small" to={`/treatments/feedback/${id}`}>
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.roles.viewMore" />
      </Link>
      <Button light small onClick={() => this.downloadFeedbackCSV(id)}>
        <i className="fa fa-download mr-5" />
        <FormattedMessage id="general.button.downloadData" />
      </Button>
      <Link className="button light small" to={`/admin/health-variables/medications/${treatment_product.id}`}>
        <i className="fa fa-gear mr-5" />
        <FormattedMessage id="general.manageMedication" />
      </Link>
    </div>
  );

  downloadFeedbackCSV = (id) => {
    const filename = `treatment-feedback-${id}.csv`;
    return fetchFromAPI(`/treatment_feedbacks/${id}/csv`, { blob: true })
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  getDropdownOptions = ({ treatment_product, id }) => {
    return [
      { label: <FormattedMessage id="general.roles.viewMore" />, url: `/treatments/feedback/${id}` },
      { label: <FormattedMessage id="general.button.downloadCSV" />, onClick: () => this.downloadFeedbackCSV(id) },
      { label: <FormattedMessage id="general.manageMedication" />,
        url: `/admin/health-variables/medications/${treatment_product.id}` },
    ];
  };

  openDropdown = (e, feedback) => {
    this.props.openDropdown(e, this.getDropdownOptions(feedback));
  };

  renderActionsColumn = (feedback, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <i
        data-name={`${feedback.id}-${rowIndex}`}
        className="fa fa-dots-three-horizontal show-for-large"
        onClick={(e) => this.openDropdown(e, feedback)}
      />
    </div>
  );

  renderMobileRow = ({ treatment_product, id }) => (
    <VerticalAligner className="mobile-row">
      <i className="fa fa-water-soluble medication-icon" />
      <div className="mobile-treatment-name">
        <Link to={`/treatments/feedback/${id}`} className="color-primary">
          {treatment_product.name}
        </Link>
      </div>
    </VerticalAligner>
  );

  render() {
    const { resources, isLoading, meta: { total }, params: { page, per_page, search, sort }, fetchData, params,
      onSearchChange, onSortChange, onPageChange, onPerPageChange } = this.props;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.productName" />, flex: '1 1 160px',
        renderer: this.renderNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.treatments" />, flex: '1 1 100px', renderer: this.renderTreatmentsColumn,
        sortKey: 'treatments_count', textRight: true },
      { label: <FormattedMessage id="component.treatmentFeedbackHome.positiveFeedback" />, flex: '1 1 120px',
        renderer: this.renderFeedBackColumn('positive_feedback'), sortKey: 'positive_feedback' },
      { label: <FormattedMessage id="component.treatmentFeedbackHome.neutralFeedback" />, flex: '1 1 120px',
        renderer: this.renderFeedBackColumn('neutral_feedback'), sortKey: 'neutral_feedback' },
      { label: <FormattedMessage id="component.treatmentFeedbackHome.negativeFeedback" />, flex: '1 1 120px',
        renderer: this.renderFeedBackColumn('negative_feedback'), sortKey: 'negative_feedback' },
      { label: '', flex: '0 0 45px', renderer: this.renderActionsColumn, fixed: true, hide: isTablet },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };

    return (
      <div className="SourceTreatmentFeedback">
        <section className="small-12 column treatments-table-section">
          <Panel>
            <Panel.Heading className="source-treatment-header">
              <h1 className="show-for-large">
                <FormattedMessage id="general.pageTitle.treatmentFeedback" />
              </h1>
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>

            <Panel.Body noPadding>
              <Preloader isActive={isLoading} />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderExpandable}
                className="show-for-large"
                scrollable
                tableKey="sourceTreatmentFeedback"
              />

              <MobileList
                className="hide-for-large"
                data={resources}
                fetchData={fetchData}
                params={params}
                getDropdownOptions={this.getDropdownOptions}
                renderRow={this.renderMobileRow}
                total={total}
                isLoading={isLoading}
              />

              <NothingBox
                itemsName="medications"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <h2 className="lighter">
                  <FormattedMessage id="component.nothingBox.noMedications" />
                </h2>
              </NothingBox>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

SourceTreatmentFeedback.propTypes = {
  resources: T.array,
  isLoading: T.bool,
  params: T.object,
  meta: T.object,
  setPageOptions: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  openDropdown: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.sourceTreatmentFeedback.resources,
      isLoading: state.dataTable.sourceTreatmentFeedback.isLoading,
      params: state.dataTable.sourceTreatmentFeedback.params,
      meta: state.dataTable.sourceTreatmentFeedback.meta,
    }), (dispatch, { params: { id } }) => ({
      fetchData: (data) => dispatch(fetchData(`/admin/external_sources/${id}/treatment_feedbacks`,
        'sourceTreatmentFeedback', data)),
      setPageOptions,
    })
  ),
  withDropdownActions,
  withDataTableController(),
);
export default enhance(SourceTreatmentFeedback);
