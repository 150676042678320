import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import Preloader from 'components/Preloader';
import Subnavigation from 'components/Subnavigation';
import CSVImportDataTable from 'components/CSVImport/CSVImportDataTable';
import CSVImportPage from './CSVImportPage';
// requests
// utils
import { getBackPathname } from 'utils/csvImportHelper';
// constants
import { availImportCSVTypes, labelsByType } from 'constants/csvImport';

const CSVImportResults = ({
  location,
}, { router }) => {
  const { query } = location;
  const backPathName = getBackPathname(query?.from, query?.entityId);
  const importedTypes = availImportCSVTypes.map((type) => ({ type, id: query?.[type] })).filter((item) => item.id);
  const [importData, setImportData] = useState(importedTypes[0]);
  const isInvalidTypes = importedTypes.length < 1 && importedTypes.length > 2;

  useEffect(() => {
    if (isInvalidTypes) router.push(backPathName);
  }, [isInvalidTypes]);

  if (isInvalidTypes) return <Preloader isActive />;

  const navLinks = importedTypes.map(({ id, type }) => ({ name: labelsByType[type], id }));
  const { id, type } = importData;

  return (
    <CSVImportPage
      activeStep={3}
      onNextBtnClick={() => router.push(backPathName)}
      isBackDisabled
      closePathName={backPathName}
    >
      <div className="bodyCSV resultsStep">
        <div className="wrapperCardsCSV">
          <div className="wrapperResultsCsvImports">
            <div className="results clearfix">
              {importedTypes.length > 1 && (
                <Subnavigation
                  className="small-12 column"
                  isSticky
                  currentId={id}
                  onSelect={(item) => setImportData(importedTypes.find(({ id }) => id === item.id))}
                  items={navLinks}
                />
              )}
              <div className="small-12 column">
                <CSVImportDataTable
                  key={id}
                  requestUrl={`/admin/csv_imports/${id}`}
                  tableName={`${type}ImportResult`}
                  importId={id}
                  importType={type}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSVImportPage>
  );
};

CSVImportResults.contextTypes = {
  router: T.object.isRequired,
};

CSVImportResults.propTypes = {
  location: T.object.isRequired,
};

export default connect(
  (state) => ({
    parsedFiles: state.importCSV.parsedFiles,
    filesByType: state.importCSV.filesByType,
    importActionByType: state.importCSV.importActionByType,
  })
)(CSVImportResults);
