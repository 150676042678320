import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel/Panel';
import Papa from 'papaparse';
import MatchGroupColumns from './components/MatchGroupColumns';
import ImportRenameResult from './components/ImportRenameResult';
import InputFile from 'components/InputFile';
import Preloader from 'components/Preloader';
import ImportLayout from './components/ImportLayout';
// requests
import { importAdminPigGroups } from 'endpoints/admin/pigGroups';
// utils
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import download from 'downloadjs';
// styles
import './GroupsImport.scss';
// assets
import downloadFile from './EveryPig_Group_Rename_Template.csv';

const matchColumns = ['current_group_name', 'new_group_name'];

const requiredColumns = ['current_group_name', 'new_group_name'];

const mappingColumns = {
  'current group id': 'current_group_name',
  'current group name': 'current_group_name',
  'new group id': 'new_group_name',
  'new group name': 'new_group_name',
};

const options = [
  { value: 'current_group_name', label: <FormattedMessage id="component.dataTable.headers.current_group_name" /> },
  { value: 'new_group_name', label: <FormattedMessage id="component.dataTable.headers.new_group_name" /> },
];

class GroupsRenameImport extends Component {

  state = {
    selectedFile: null,
    step: 1,
    disabledNextButton: true,
    matched: {},
    isLoading: false,
    groupImportId: null,
    result: {},
    parsed: false,
    parsedData: [],
    errors: [],
  };

  downloadCSV = () => {
    return download(downloadFile, 'EveryPig_Group_Rename_Template.csv', 'text/csv');
  };

  fileSelectedHandler = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      this.setState({ selectedFile });
      this.fileParseHandler(selectedFile);
    }
  };

  fileParseHandler = (selectedFile) => {
    const self = this;
    Papa.parse(selectedFile, {
      header: true,
      preview: 5,
      trimHeaders: true,
      skipEmptyLines: true,
      complete({ data, errors }) {
        if (errors.length) {
          self.setState({ errors });
        } else {
          self.setState({ parsed: true, parsedData: data, disabledNextButton: false, errors: [] });
        }
      },
    });
  };

  handleBackButton = (e) => {
    const { step } = this.state;
    const { router } = this.context;
    e.preventDefault();
    if (step === 1) {
      router.push('/groups');
    } else {
      this.setState((prevState) => ({
        step: prevState.step - 1,
        selectedFile: null,
        disabledNextButton: true,
        result: {},
        matched: {},
        parsed: false,
        parsedData: [],
        isLoading: false,
        groupImportId: null,
        errors: [],
      }));
    }
  };

  handleNextButton = () => {
    const { selectedFile, matched, step } = this.state;
    const { router } = this.context;

    if (step === 1) {
      this.setState((prevState) => ({ step: prevState.step + 1, disabledNextButton: true }));
    }
    if (step === 2) {
      this.setState({ isLoading: true });
      const fd = new FormData();
      Object.keys(matched).forEach((item) => fd.append(`resource[csv_matchers][${item}]`, matched[item]));
      fd.append('resource[csv_file]', selectedFile);
      fd.append('resource[action]', 'renaming');
      importAdminPigGroups(fd)
        .then(({ result, id }) => {
          this.setState((prevState) => ({
            step: prevState.step + 1,
            disabledNextButton: false,
            result,
            isLoading: false,
            groupImportId: id,
          }));
        })
        .catch((resp) => {
          this.setState({ isLoading: false });
          return toastResponseErrors(resp);
        });
    }
    if (step === 3) {
      router.push('/groups');
    }
  };

  setMatchedData = (matched) => {
    this.setState({ matched, disabledNextButton: isEmpty(matched) });
  };

  closeButtonHeader = () => this.context.router.push('/groups');

  renderResultMessage = () => {
    const { invalid_rows } = this.state.result;
    const invalidRowsCount = (invalid_rows || []).length;

    if (invalidRowsCount > 0) return (
      <FormattedMessage
        tagName="div"
        id="component.groupsRenameImport.errors"
        values={{ errorsCount: invalidRowsCount, b: (msg) => <b>{msg}</b> }}
      />
    );

    return null;
  };

  render() {
    const { user } = this.props;
    const { step, isLoading, parsed, groupImportId, disabledNextButton, parsedData, matched, result,
      errors } = this.state;
    return (
      <ImportLayout
        title={<FormattedMessage tagName="b" id="general.replaceIds" />}
        user={user}
        closeButtonHeader={this.closeButtonHeader}
        handleNextButton={this.handleNextButton}
        handleBackButton={this.handleBackButton}
        disabledNextButton={disabledNextButton}
        step={step}
      >
        {step === 3 && (
          <div className="import-result-summary">
            <i className="check-icon fa fa-check-circle" />
            <FormattedMessage
              tagName="div"
              id="component.groupsRenameImport.complete"
              values={{
                count: result.renamed_count,
                b: (msg) => <b>{msg}</b>
              }}
            />
            {this.renderResultMessage()}
          </div>
        )}
        <Panel className="groupImportPanel">
          <Panel.Heading title={<FormattedMessage id="general.replaceFromCsvFile" />} />
          <Panel.Body noPadding className={cn('panelBody', { 'final-step': step === 3 })}>
            <div className="group-import-block">
              <Preloader isActive={isLoading} />
              {step === 1 && (
                <div className="upload-input-block">
                  <InputFile
                    label={<FormattedMessage id="general.uploadFile" />}
                    onChange={this.fileSelectedHandler}
                    accept=".csv"
                    explanatoryText={(
                      <FormattedMessage
                        id="general.uploadFile.hint"
                        values={{
                          link: (
                            <span className="download-link" onClick={this.downloadCSV}>
                              <FormattedMessage id="general.here" />
                            </span>),
                        }}
                      />
)}
                    explanatorySubText={<FormattedMessage tagName="i" id="general.errorWillRemove" />}
                  />
                  {parsed && (
                    <div className="success-message-block">
                      <span className="headline">
                        <i className="fa fa-check" /> <FormattedMessage id="general.success" />!&nbsp;
                      </span>
                      <FormattedMessage id="general.uploadSuccessMessage" />
                    </div>
                  )}
                  {errors.length > 0 && (
                    <div className="error-message">
                      <FormattedMessage id="general.invalidFileMessage" />
                    </div>
                  )}
                </div>
              )}
              {step === 2 && (
                <div>
                  <MatchGroupColumns
                    matched={matched}
                    setMatchData={this.setMatchedData}
                    parsedData={parsedData}
                    matchColumns={matchColumns}
                    requiredColumns={requiredColumns}
                    mappingColumns={mappingColumns}
                    options={options}
                  />
                  {!isEmpty(matched) && (
                    <div className="success-message-block ml-20">
                      <span className="headline">
                        <i className="fa fa-check" /> <FormattedMessage id="general.success" />!&nbsp;
                      </span>
                      <FormattedMessage id="general.matchingDone" />
                    </div>
                  )}
                </div>
              )}
              {step === 3 && groupImportId &&
                <ImportRenameResult groupImportId={groupImportId} />}
            </div>
          </Panel.Body>
        </Panel>
      </ImportLayout>
    );
  }
}

GroupsRenameImport.propTypes = {
  user: T.object,
};

GroupsRenameImport.contextTypes = {
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    user: state.auth.user,
  })
)(GroupsRenameImport);
