import React, { Component } from 'react';
import T from 'prop-types';
// recompose/redux
import { connect } from 'react-redux';
import { fetchActivities, setActivitiesFilter } from 'reducers/farmfeed';
import { setSearch, setSearchText } from 'reducers/farmFeedSearch';
import { setAdvancedSearchVisibility } from 'reducers/layout';
// components
import FarmFeedAutoSuggest from './FarmFeedAutoSuggest';
import { FormattedMessage } from 'react-intl';
// api
import { searchActivities } from 'endpoints/farmfeed';
// utils
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import withOutsideClick from 'react-onclickoutside';
// assets
import './FarmFeedSearch.scss';

const initialState = {
  searchValue: '',
  inputActive: false,
  searchInputVisible: false,
  showOverlay: false,
};

class FarmFeedSearch extends Component {

  state = initialState;

  onKeyPress = (e) => {
    const { searchParams: { search }, setSearch } = this.props;
    const { searchValue } = this.state;
    if ((searchValue !== search) && (e.key === 'Enter')) {

      const params = { search: encodeURIComponent(searchValue), filters: {} };
      this.getActivities(params);
      setSearch(params);
      this.setState({ showOverlay: false, inputActive: Boolean(searchValue) }, () => {
        if (!searchValue) {
          this.deactivateInputOnMobile();
        }
      });
    }
  };

  onFocus = () => {
    this.setState({
      inputActive: true,
      showOverlay: true,
    });
  };

  onSearchChange = (e, { newValue }) => {
    this.setState({ searchValue: newValue || '' });
  };

  getActivities = (params) => {
    const { fetchActivities, farmfeedParams, setActivitiesFilter } = this.props;
    fetchActivities({ ...farmfeedParams, ...params, page: 1 }).then(() => {
      setActivitiesFilter({});
    });
  };

  getSearchEntity = (value) => {
    const { setSearch } = this.props;
    const { setAdvancedSearchVisibility, showAdvancedSearch, setSearchText } = this.props;
    if (showAdvancedSearch) setAdvancedSearchVisibility(false);
    const params = value.type === 'search'
      ? { search: value.text, filters: {} }
      : { filters: { [`${value.type}_id`]: value.id }, search: '' };
    this.getActivities(params);
    setSearch(params);
    setSearchText(value.type === 'search' ? value.text : value.name);
    this.setState({ showOverlay: false }, this.deactivateInputOnMobile);
  };

  getInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  handleClickOutside = () => {
    const { farmfeedParams: { filters, search } } = this.props;
    if (this.state.showOverlay) {
      this.setState({
        inputActive: !isEmpty(filters) || search,
        showOverlay: false,
      });
    }
  };

  openSearch = () => {
    this.setState({ inputActive: true, searchInputVisible: true }, this.activateInputOnMobile);
  };

  activateInputOnMobile = () => {
    if (this.input) this.input.focus();
  };

  deactivateInputOnMobile = () => {
    if (this.input) this.input.blur();
  };

  onOverlayClick = () => {
    const { searchValue } = this.state;
    const { farmfeedParams: { filters, search } } = this.props;
    const isSearchNotSaved = searchValue && isEmpty(filters) && !search;
    if (isSearchNotSaved) this.getActivities();
    this.setState({
      searchValue: isSearchNotSaved ? '' : searchValue,
      inputActive: !isEmpty(filters) || search,
      showOverlay: false,
    });
  };

  clearSearchOnLargeScreen = () => {
    const { fetchActivities, setSearch, setSearchText } = this.props;
    this.setState({
      searchValue: '',
      inputActive: false,
      showOverlay: false,
    });
    if (this.isNotEmptyFilters()) {
      fetchActivities();
      setSearch({});
      setSearchText('');
    }
  };

  clearSearchOnSmallScreen = () => {
    const { fetchActivities, setSearch, setSearchText } = this.props;
    this.setState({
      searchValue: '',
      inputActive: false,
      showOverlay: false,
      searchInputVisible: false,
    });
    if (this.isNotEmptyFilters()) {
      fetchActivities();
      setSearch({});
      setSearchText('');
    }
  };

  isNotEmptyFilters = () => {
    const { farmfeedParams: { filters, search, date_start, date_end } } = this.props;
    return !isEmpty(filters) || search || date_start || date_end;
  };

  activateAdvancedSearch = () => {
    const { setAdvancedSearchVisibility, showAdvancedSearch } = this.props;
    setAdvancedSearchVisibility(!showAdvancedSearch);
    this.setState({
      inputActive: false,
      showOverlay: false,
    });
  };

  render() {
    const { className, isTenantNotificationShown } = this.props;
    const { searchValue, inputActive, searchInputVisible, showOverlay } = this.state;

    return (
      <div className={cn('FarmFeedSearch', className, { active: searchInputVisible })}>
        {!searchInputVisible &&
          <i className="fa fa-search init-search hide-for-large" onClick={this.openSearch} />}
        <div
          className={cn('autocomplete search', {
            'complete': inputActive,
            'show-for-large': !searchInputVisible,
          })}
        >
          <i className="fa fa-search search-label show-for-large" />
          <div className="input-wrapper">
            <FormattedMessage id="container.farmfeed.search">
              {(placeholderText) => (
                <FarmFeedAutoSuggest
                  onFocus={this.onFocus}
                  onKeyPress={this.onKeyPress}
                  placeholderText={placeholderText}
                  getSuggestions={(search) => searchActivities({ search })}
                  value={searchValue}
                  onChange={this.onSearchChange}
                  handleSelectedValue={(e, { suggestion }) => this.getSearchEntity(suggestion)}
                  getInputReference={this.getInputReference}
                />
              )}
            </FormattedMessage>
            {inputActive && !this.isNotEmptyFilters() && (
              <FormattedMessage id="general.advancedSearch">
                {(text) => (
                  <span className="advanced-search-link show-for-large" onClick={this.activateAdvancedSearch}>
                    {text}
                  </span>
                )}
              </FormattedMessage>
            )}
            <i className="fa fa-times clear show-for-large" onClick={this.clearSearchOnLargeScreen} />
          </div>
          {searchInputVisible && (
            <div className="cancel-search hide-for-large">
              <button className="primary" onClick={this.clearSearchOnSmallScreen}>
                <FormattedMessage id="general.button.cancel" />
              </button>
            </div>
          )}
        </div>
        <div
          className={cn('overlay', { 'visible': showOverlay, 'top-offset': isTenantNotificationShown })}
          onClick={this.onOverlayClick}
        />
      </div>
    );
  }
}

FarmFeedSearch.defaultProps = {
  className: '',
};

FarmFeedSearch.propTypes = {
  className: T.string,
  fetchActivities: T.func.isRequired,
  setSearch: T.func.isRequired,
  setSearchText: T.func.isRequired,
  farmfeedParams: T.object,
  searchParams: T.object,
  setActivitiesFilter: T.func.isRequired,
  setAdvancedSearchVisibility: T.func.isRequired,
  isTenantNotificationShown: T.bool,
  showAdvancedSearch: T.bool.isRequired,
};

export default connect(
  (state) => ({
    searchParams: state.farmFeedSearch.params,
    isTenantNotificationShown: state.layout.showHeaderNotification,
    farmfeedParams: state.farmfeed.params,
    showAdvancedSearch: state.layout.showAdvancedSearch,
  }), {
    fetchActivities,
    setSearch,
    setSearchText,
    setActivitiesFilter,
    setAdvancedSearchVisibility,
  }
)(withOutsideClick(FarmFeedSearch));
