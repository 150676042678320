import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

const DeleteTreatmentProtocolModal = ({ onDeleteTreatmentProtocol, treatmentProtocol }) => (
  <ConfirmationModal
    title={<FormattedMessage id="general.deleteTreatmentProtocol" />}
    actionBtnLabel={<FormattedMessage id="component.modal.deleteTreatmentProtocol.confirm" />}
    actionBtnProps={{ primary: true }}
    handleConfirm={onDeleteTreatmentProtocol}
  >
    <FormattedMessage
      id="component.modal.deleteTreatmentProtocol.text"
      tagName="p"
      values={{ treatmentProtocolName: treatmentProtocol.name, b: (msg) => <b>{msg}</b> }}
    />
  </ConfirmationModal>
);

DeleteTreatmentProtocolModal.propTypes = {
  onDeleteTreatmentProtocol: T.func.isRequired,
  treatmentProtocol: T.object.isRequired,
};

export default DeleteTreatmentProtocolModal;
