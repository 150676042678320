import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchDeathsStats, fetchMortalityStats } from 'reducers/autoReport';
// components
import { FormattedMessage } from 'react-intl';
import StatisticsPanel from 'components/StatisticsPanel/StatisticsPanel';
import StatIndicatorBlock from './StatIndicatorBlock';
import Amchart from 'components/Amchart';
import VerticalAligner from 'components/VerticalAligner';
// utils
import last from 'lodash.last';
import head from 'lodash.head';
import pick from 'lodash.pick';
import round from 'lodash.round';
import memoize from 'lodash.memoize';
import classnames from 'classnames/bind';
import moment from 'moment';
import { calcGrowthRate, calcAverageData, groupDataByWeek, groupDataByMonth, getArrowClass, getRateClass } from 'utils';
// styles
import styles from './AutoReportGraphs.module.scss';
// constants
import { createSmoothedLine } from 'components/Amchart/chartConfig';
import { rangeOptions } from 'utils/rangeBuilder';

const cn = classnames.bind(styles);

const reportPeriodLabels = { weekly: 'Week', daily: 'Day', monthly: 'Month' };
const amChartStyle = { height: '290px' };

class AutoReportGraphs extends Component {

  state = {
    currentDeathsOption: head(rangeOptions[this.props.reportPeriod]),
    currentMortalityOption: head(rangeOptions[this.props.reportPeriod]),
  };

  componentDidMount() {
    const { reportPeriod } = this.props;
    this.fetchDeathsData(head(rangeOptions[reportPeriod]));
    this.fetchMortalityData(head(rangeOptions[reportPeriod]));
  }

  fetchDeathsData = (option) => {
    const { fetchDeathsStats, deathsParams } = this.props;
    const otherParams = pick(option, ['date_start', 'date_end', 'company_id', 'shareholder_group_id']);
    this.setState({ currentDeathsOption: option });
    fetchDeathsStats({ ...deathsParams, ...otherParams });
  };

  fetchMortalityData = (option) => {
    const { fetchMortalityStats, mortalityParams } = this.props;
    const otherParams = pick(option, ['date_start', 'date_end', 'company_id', 'shareholder_group_id']);
    this.setState({ currentMortalityOption: option });
    fetchMortalityStats({ ...mortalityParams, ...otherParams });
  };

  prepareDeathsGraphData = memoize((graphData) => {
    const { reportPeriod } = this.props;
    const { formatMessage } = this.context;
    let groupedStats = graphData;
    if (reportPeriod === 'weekly') {
      groupedStats = groupDataByWeek(graphData, 'total_deaths');
    }
    if (reportPeriod === 'monthly') {
      groupedStats = groupDataByMonth(graphData, 'total_deaths');
    }
    return groupedStats.map(({ total_deaths, week_index, date }, index, data) => {
      const change = index ? calcGrowthRate(data[index].total_deaths, data[index - 1].total_deaths) : 0;
      const formattedDate = reportPeriod === 'daily' ? moment(date).format('DD MMM') : date;
      return {
        date: week_index || formattedDate,
        dateLabel: formatMessage({ id: `general.${reportPeriodLabels[reportPeriod].toLowerCase()}` }),
        change: Math.abs(change).toFixed(2) + '%',
        value: total_deaths,
        valueLabel: total_deaths,
        blockClass: getRateClass(change),
        iconClass: getArrowClass(change),
      };
    });
  });

  prepareMortalityGraphData = memoize((graphData) => {
    const { reportPeriod } = this.props;
    const { formatMessage } = this.context;
    let groupedStats = graphData;
    if (reportPeriod === 'weekly') {
      groupedStats = groupDataByWeek(graphData, 'avg_mortality_rate');
    }
    if (reportPeriod === 'monthly') {
      groupedStats = groupDataByMonth(graphData, 'avg_mortality_rate');
    }
    return groupedStats.map(({ avg_mortality_rate, week_index, date }, index, data) => {
      const change = index
        ? calcGrowthRate(data[index].avg_mortality_rate, data[index - 1].avg_mortality_rate)
        : 0;
      const formattedDate = reportPeriod === 'daily' ? moment(date).format('DD MMM') : date;

      return {
        date: week_index || formattedDate,
        dateLabel: formatMessage({ id: `general.${reportPeriodLabels[reportPeriod].toLowerCase()}` }),
        change: Math.abs(change).toFixed(2) + '%',
        value: avg_mortality_rate,
        valueLabel: avg_mortality_rate.toFixed(2) + '%',
        blockClass: getRateClass(change),
        iconClass: getArrowClass(change),
      };
    });
  });

  renderLegend = () => (
    <VerticalAligner>
      <div className={cn('legend')}>
        <FormattedMessage id="container.autoReport.reportPeriod" />
      </div>
      <div className={cn('square')} />
    </VerticalAligner>
  );

  renderDeathsStats = memoize((data) => {
    const { reportPeriod, deathsPrevPeriodMeta: { total_deaths } } = this.props;
    const lastDeaths = last(data)?.value || 0;
    const lastAndPrevStats = data.slice(-2);
    const change = lastAndPrevStats.length
      ? calcGrowthRate(lastAndPrevStats[1].value, lastAndPrevStats[0].value)
      : 0;
    const average = calcAverageData(data);
    const avgChange = calcGrowthRate(average, total_deaths);
    return (
      <VerticalAligner>
        <StatIndicatorBlock
          className="mr-20"
          value={lastDeaths}
          label={<FormattedMessage id={`general.current${reportPeriodLabels[reportPeriod]}`} />}
          growthRate={change}
        />
        <StatIndicatorBlock
          value={round(average)}
          label={<FormattedMessage id="general.average" />}
          growthRate={avgChange}
        />
      </VerticalAligner>
    );
  });

  renderMortalityStats = memoize((data) => {
    const { reportPeriod, mortalityPrevPeriodMeta: { avg_mortality_rate } } = this.props;
    const lastMortality = last(data)?.value || 0;
    const lastAndPrevStats = data.slice(-2);
    const change = lastAndPrevStats.length
      ? calcGrowthRate(lastAndPrevStats[1].value, lastAndPrevStats[0].value)
      : 0;
    const average = calcAverageData(data);
    const avgChange = calcGrowthRate(average, avg_mortality_rate);
    return (
      <VerticalAligner>
        <StatIndicatorBlock
          className="mr-20"
          value={lastMortality.toFixed(2) + '%'}
          label={<FormattedMessage id={`general.current${reportPeriodLabels[reportPeriod]}`} />}
          growthRate={change}
        />
        <StatIndicatorBlock
          value={average.toFixed(2) + '%'}
          label={<FormattedMessage id="general.average" />}
          growthRate={avgChange}
        />
      </VerticalAligner>
    );
  });

  render() {
    const { className, reportPeriod, isDeathsStatsLoading, isMortalityStatsLoading, deathsStats,
      mortalityStats } = this.props;
    const { currentDeathsOption, currentMortalityOption } = this.state;
    const { formatMessage } = this.context;

    const deathsData = this.prepareDeathsGraphData(deathsStats);
    const mortalityData = this.prepareMortalityGraphData(mortalityStats);

    const deathsChartConfig = createSmoothedLine(
      deathsData,
      formatMessage({ id: `general.${reportPeriodLabels[reportPeriod].toLowerCase()}` }),
      formatMessage({ id: 'component.analyticsPanel.title.deaths' }),
      formatMessage({ id: 'container.autoReport.reportPeriod' })
    );
    const mortalityChartConfig = createSmoothedLine(
      mortalityData,
      formatMessage({ id: `general.${reportPeriodLabels[reportPeriod].toLowerCase()}` }),
      formatMessage({ id: 'general.mortalityRate' }),
      formatMessage({ id: 'container.autoReport.reportPeriod' })
    );

    return (
      <div className={cn('small-12 column', 'stats-box', className)}>
        <StatisticsPanel
          title={<FormattedMessage id="general.allDeaths" />}
          renderLegend={this.renderLegend}
          renderTitleActions={() => this.renderDeathsStats(deathsData)}
          className={cn('stat-item')}
          rangeOptions={rangeOptions[reportPeriod]}
          onOptionChange={this.fetchDeathsData}
          currentOption={currentDeathsOption.value}
          isLoading={isDeathsStatsLoading}
        >
          {!!deathsData.length &&
            <Amchart style={amChartStyle} config={deathsChartConfig} />}
        </StatisticsPanel>
        <StatisticsPanel
          title={<FormattedMessage id="general.mortality" />}
          renderLegend={this.renderLegend}
          renderTitleActions={() => this.renderMortalityStats(mortalityData)}
          className={cn('stat-item')}
          rangeOptions={rangeOptions[reportPeriod]}
          onOptionChange={this.fetchMortalityData}
          currentOption={currentMortalityOption.value}
          isLoading={isMortalityStatsLoading}
        >
          {!!mortalityData.length &&
            <Amchart style={amChartStyle} config={mortalityChartConfig} />}
        </StatisticsPanel>
      </div>
    );
  }
}

AutoReportGraphs.contextTypes = {
  formatMessage: T.func.isRequired,
};

AutoReportGraphs.propTypes = {
  reportPeriod: T.string,
  className: T.string,
  fetchDeathsStats: T.func.isRequired,
  fetchMortalityStats: T.func.isRequired,
  deathsStats: T.array.isRequired,
  mortalityStats: T.array.isRequired,
  deathsParams: T.object.isRequired,
  mortalityParams: T.object.isRequired,
  deathsPrevPeriodMeta: T.object.isRequired,
  mortalityPrevPeriodMeta: T.object.isRequired,
  isDeathsStatsLoading: T.bool.isRequired,
  isMortalityStatsLoading: T.bool.isRequired,
};

export default connect(
  (state) => ({
    deathsStats: state.autoReport.deaths.stats,
    deathsPrevPeriodMeta: state.autoReport.deaths.prev_period_meta,
    isDeathsStatsLoading: state.autoReport.deaths.isLoading,
    deathsParams: state.autoReport.deaths.params,
    mortalityStats: state.autoReport.mortality.stats,
    mortalityPrevPeriodMeta: state.autoReport.mortality.prev_period_meta,
    isMortalityStatsLoading: state.autoReport.mortality.isLoading,
    mortalityParams: state.autoReport.mortality.params,
  }),
  { fetchDeathsStats, fetchMortalityStats },
)(AutoReportGraphs);
