import React, { Component } from 'react';
import T from 'prop-types';
// components
import Dropdown from '../Dropdown';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './StatisticsDropdown.module.scss';
// constants
const cn = classnames.bind(styles);

class StatisticsDropdown extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAllOptions: props.options.length < 6,
    };
  }

  showAllOptions = (e) => {
    e.stopPropagation();
    this.setState({ showAllOptions: true });
  };

  renderTrigger = () => {
    const { options, currentOption } = this.props;
    const optionObject = options.find((option) => (option.value === currentOption));
    const currentLabel = optionObject ? optionObject.label : options[0].label;
    return (
      <div className={cn('dropdown-trigger')}>
        <div>{currentLabel}</div>
        <i className="fa fa-angle-down" />
      </div>
    );
  };

  render() {
    const { currentOption, onOptionChange, options, trigger, mobileMode } = this.props;
    const { isMobile, isTablet } = this.context;
    const { showAllOptions } = this.state;
    const defaultOptions = showAllOptions ? options : options.slice(0, 4);
    return (
      <div className={cn('stat-dropdown-box')}>
        <Dropdown
          closeOnClick
          withBackdrop={isMobile && !isTablet}
          trigger={trigger || this.renderTrigger()}
          className={cn({ 'responsive-dropdown': isMobile && !isTablet })}
          dropdownClassName={cn('stat-dropdown', { 'responsive': isMobile && !isTablet, 'mobile-mode': mobileMode })}
        >
          <ul className={cn('dropdown-menu', 'stat-dropdown-menu')}>
            {defaultOptions.map((option, index) => (
              <li
                key={index}
                className={cn('menu-item', { 'active-option': currentOption === option.value })}
                onClick={() => onOptionChange(option)}
              >
                <span className={cn('option-label')}>{option.label}</span>
                {currentOption === option.value &&
                  <i className={cn('fa fa-check', 'selected')} />}
              </li>
            ))}
            {(defaultOptions.length !== options.length) && (
              <li onClick={this.showAllOptions} className={cn('menu-item')}>
                <span className="semibold">View More Preset Ranges</span>
                <i className={cn('fa fa-angle-down', 'view-more-icon')} />
              </li>
            )}
          </ul>
        </Dropdown>
      </div>
    );
  }
}

StatisticsDropdown.contextTypes = {
  isMobile: T.bool,
  isTablet: T.bool,
};

StatisticsDropdown.propTypes = {
  currentOption: T.string,
  onOptionChange: T.func,
  options: T.array.isRequired,
  trigger: T.node,
  mobileMode: T.bool,
};

export default StatisticsDropdown;
