import React, { Component } from 'react';
import T from 'prop-types';
// components
import GroupField from '../GroupField';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import CommitmentPicker from 'components/CommitmentPicker/CommitmentPicker';
// utils
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// requests
import { getAdminPigGroupCommitments, updateAdminPigGroupCommitmentsAssignment } from 'endpoints/admin/pigGroups';
// styles
import styles from '../Section.module.scss';

const cn = classnames.bind(styles);

class CommitmentsAssigningSection extends Component {

  state = {
    isLoading: false,
    commitments: [],
    search: ''
  };

  componentDidMount() {
    const { groupId } = this.props;
    if (groupId) {
      this.fetchGroupCommitments();
    }
  }

  componentDidUpdate(prevProps) {
    const { groupId } = this.props;
    if (prevProps.groupId !== groupId) {
      this.fetchGroupCommitments();
    }
  }

  setCommitmentsCallback = (responseCommitments) => {
    this.setState((prevState) => ({
      ...prevState,
      initialCommitments: responseCommitments,
      commitments: responseCommitments,
      isLoading: false,
    }));
  }

  fetchGroupCommitments = () => {
    const { groupId } = this.props;
    getAdminPigGroupCommitments(groupId).then(this.setCommitmentsCallback);
  };

  updateAssignment = () => {
    const { groupId, onClose } = this.props;
    const { commitments } = this.state;
    if (!commitments.length) return onClose();

    this.setState((prevState) => ({ ...prevState, isLoading: true }));
    const commitmentsToSend = commitments.map((commitment) => ({
      id: commitment.id,
      _destroy: !commitment.assigned,
    }));
    return updateAdminPigGroupCommitmentsAssignment(groupId, commitmentsToSend)
      .then((commitments) => {
        this.setCommitmentsCallback(commitments);
        onClose();
      }).catch((errors) => {
        toastResponseErrors(errors);
        this.setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  handleChange = (commitments) => {
    this.setState((prevState) => ({
      ...prevState,
      commitments,
    }));
  };

  onCancel = () => {
    const { onClose } = this.props;
    const { initialCommitments } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      commitments: initialCommitments,
    }));
    onClose();
  }

  render() {
    const { className, isDisabled, isOpened, onOpen, data } = this.props;
    const { isLoading, commitments } = this.state;
    const assignedCommitmentsCount = commitments.filter(({ assigned }) => assigned).length;
    const loadLabel = assignedCommitmentsCount ? (
      <FormattedMessage
        id="component.groupCreate.loadLabel"
        values={{ value: <strong>{assignedCommitmentsCount}</strong> }}
      />
    ) : <FormattedMessage id="component.groupCreate.loadLabelEmpty" />;

    return (
      <GroupField
        isRequired
        className={className}
        label={loadLabel}
        title={<FormattedMessage id="general.incomingLoads" />}
        btnLabel={<FormattedMessage id="component.groupCreate.setLoads" />}
        hint={<FormattedMessage id="component.groupCreate.loadHint" />}
        isSaved={!isEmpty(data)}
        onOpen={onOpen}
        isOpened={isOpened}
        isDisabled={isDisabled}
      >
        <Preloader isActive={isLoading} />
        <div className={cn('loads-body')}>
          <div className={cn('field-body-title')}>
            <FormattedMessage id="component.groupCreate.linkIncomingLoads" />
          </div>
          <div className="pt-10">
            <CommitmentPicker
              isLoading={isLoading}
              commitments={commitments}
              handleChange={this.handleChange}
            />
          </div>
          {!isLoading && (
            <div className="pv-10">
              <Button
                primary
                onClick={this.updateAssignment}
              >
                <FormattedMessage id="general.button.save" />
              </Button>
              <Button className="btn-link ml-5" onClick={this.onCancel}>
                <FormattedMessage id="general.button.cancel" />
              </Button>
            </div>
          )}
        </div>
      </GroupField>
    );
  }
}

CommitmentsAssigningSection.propTypes = {
  className: T.string,
  groupId: T.string,
  onClose: T.func.isRequired,
  data: T.object.isRequired,
  onOpen: T.func.isRequired,
  isOpened: T.bool,
  isDisabled: T.bool,
};

export default CommitmentsAssigningSection;
