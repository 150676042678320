import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
// utils
import renderIf from 'utils/renderIf';
// styles
import './ProgressBox.scss';

class ProgressBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      display: props.steps.find(({ done }) => !done)
    };
  }

  closeProgressBox = () => {
    this.setState({ display: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { steps } = this.props;
    if (nextProps.steps !== steps) {
      const isHaveUndoneSteps = nextProps.steps.find(({ done }) => !done);
      this.setState({ display: isHaveUndoneSteps });
    }
  }

  renderIncompleteStep = ({ text, url }) => (
    <div className="progress-row-incomplete">
      <span>{text}</span>
      <Link to={url} className="button primary">
        <FormattedMessage id="general.button.assign" />
      </Link>
    </div>
  );

  renderCompleteStep = ({ text }) => (
    <div className="progress-row-complete">
      <span><i className="fa fa-check mr-10" />{text}</span>
      <i className="color-dark-gray">
        <FormattedMessage id="general.button.done" />
      </i>
    </div>
  );

  render() {
    const { className = '', closeable, steps, headerText } = this.props;
    const { display } = this.state;

    return renderIf(display)(
      <div className={`ProgressBox panel ${className}`}>
        <div className="panel-heading">
          <span>{headerText}</span>
          {closeable && (
            <button aria-label="Close progress box" className="fa fa-times" onClick={this.closeProgressBox} />
          )}
        </div>
        <div className="panel-body">
          {steps.map((step, index) => (
            <div key={index}>
              {step.done
                ? this.renderCompleteStep(step)
                : this.renderIncompleteStep(step)}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ProgressBox.propTypes = {
  className: T.string,
  closeable: T.bool,
  headerText: T.oneOfType([T.string, T.node]).isRequired,
  steps: T.arrayOf(T.shape({
    text: T.oneOfType([T.string, T.node]).isRequired,
    done: T.bool,
    url: T.string,
  })).isRequired,
};

export default ProgressBox;
