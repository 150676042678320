import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
import { setPageOptions } from 'reducers/layout';
// recompose
import { compose } from 'recompose';
// hoc
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import withDropdownActions from 'hoc/withDropdownActions';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import SearchBox from 'components/SearchBox';
import VerticalAligner from 'components/VerticalAligner';
import DropdownButton from 'components/DropdownButton';
import MedicationFeedbackHeader from './components/MedicationFeedbackHeader';
import Button from 'components/Button';
import NothingBox from 'components/NothingBox';
import Avatar from 'components/Avatar/Avatar';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// api
import { downloadTreatmentFeedbackCSV, getTreatmentFeedback } from 'endpoints/treatmentFeedbacks';
import { downloadTreatmentEfficacyCSV } from 'endpoints/treatmentEfficacy';
// utils
import cn from 'classnames';
import isNull from 'lodash.isnull';
import moment from 'moment';
import download from 'downloadjs';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isTablet } from 'react-device-detect';
// styles
import './TreatmentFeedbackMedication.scss';

class TreatmentFeedbackMedication extends Component {

  state = {
    feedback: null,
    isLoadingFeedback: false,
  };

  componentDidMount = () => {
    const { fetchData, setPageOptions, reqParams } = this.props;
    this.fetchFeedback();
    fetchData(reqParams);
    setPageOptions({
      mobileTitle: 'treatmentFeedback',
      backLink: '/treatments/feedback',
    });
  };

  componentWillUnmount = () => {
    this.props.setPageOptions({ mobileTitle: '', backLink: '' });
  };

  fetchFeedback = () => {
    const { params: { id } } = this.props;
    this.setState({ isLoadingFeedback: true });
    getTreatmentFeedback(id)
      .then((feedback) => {
        this.setState({ feedback, isLoadingFeedback: false });
      })
      .catch((response) => {
        this.setState({ isLoadingFeedback: false });
        return toastResponseErrors(response);
      });
  };

  openDropdown = (e, id, groupId) => {
    const { openDropdown } = this.props;
    const options = [
      { label: <FormattedMessage id="component.treatmentFeedbackHome.downloadAll" />,
        onClick: () => this.downloadEfficacyCSV(id) },
      { label: <FormattedMessage id="general.button.showGroupHistory" />,
        url: `/treatments/feedback/groups/${groupId}` },
      { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${groupId}` },
    ];
    openDropdown(e, options);
  };

  renderGroupNameColumn = ({ pig_group = {}, id }, { rowIndex }) => (
    <CustomColumn>
      <div className="group-name-column">
        <VerticalAligner>
          <Avatar type="pig_group" avatarSize="35" iconSize="20" />
          <div className="group-name">{pig_group?.name}</div>
        </VerticalAligner>
        <i
          data-name={`${id}-${rowIndex}`}
          className="fa fa-dots-three-horizontal hide-for-large"
          onClick={(e) => this.openDropdown(e, id, pig_group.id)}
        />
      </div>
    </CustomColumn>
  );

  renderTreatmentDateColumn = ({ created_at }) => (
    <CustomColumn label={<FormattedMessage id="general.treatmentDate" />}>
      <span className="semibold">{moment(created_at).format('MM/DD/YYYY')}</span>
    </CustomColumn>
  );

  renderFeedbackColumn = ({ effective }) => {
    const result = (
      <FormattedMessage id={`general.feedback.${effective ? 'effective' : 'ineffective'}`}>
        {(text) => <span className={cn('feedback-label', { effective, 'ineffective': !effective })}>{text}</span>}
      </FormattedMessage>
    );
    return (
      <CustomColumn label="Feedback" noBottomBorder>
        {isNull(effective)
          ? <span className="color-dark-gray"><FormattedMessage id="general.feedback.resultsPending" /></span>
          : result}
      </CustomColumn>
    );
  };

  renderActionsColumn = ({ id, pig_group = {} }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        label={<FormattedMessage id="general.button.view" />}
        buttonType="light-gray compact"
        url={`/treatments/feedback/efficacy/${id}`}
        dropDownData={[
          { label: <FormattedMessage id="general.button.downloadDataCSV" />,
            onClick: () => this.downloadEfficacyCSV(id) },
          { label: <FormattedMessage id="general.button.showGroupHistory" />,
            url: `/treatments/feedback/groups/${pig_group.id}` },
          { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${pig_group.id}` },
        ]}
        customClass="show-for-large"
        wide
      />
      <Link to={`/treatments/feedback/efficacy/${id}`} className="button light hide-for-large">
        <FormattedMessage id="general.button.view" />
      </Link>
    </div>
  );

  renderExpandable = ({ pig_group = {}, id }) => (
    <div>
      <Link className="button light small" to={`/treatments/feedback/efficacy/${id}`}>
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.button.showDetails" />
      </Link>
      <Link className="button light small" to={`/treatments/feedback/groups/${pig_group.id}`}>
        <i className="fa fa-ep-farm mr-5" />
        <FormattedMessage id="general.button.groupHistory" />
      </Link>
      <Link className="button light small" to={`/groups/${pig_group.id}`}>
        <i className="fa fa-gear mr-5" />
        <FormattedMessage id="general.manageGroup" />
      </Link>
      <Button light small onClick={() => this.downloadEfficacyCSV(id)}>
        <i className="fa fa-download mr-5" />
        <FormattedMessage id="general.button.downloadData" />
      </Button>
    </div>
  );

  downloadEfficacyCSV = (id) => {
    const filename = `treatment-efficacy-${id}.csv`;
    return downloadTreatmentEfficacyCSV(id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  downloadFeedbackDataCSV = () => {
    const { params: { id } } = this.props;
    const filename = `treatment-feedback-${id}.csv`;
    return downloadTreatmentFeedbackCSV(id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  getRowClass = ({ effective }) => (effective === false ? 'ineffective' : '');

  renderTitle = () => (
    <h2 className="lighter show-for-large"><FormattedMessage id="general.efficacy" /></h2>
  );

  render() {
    const { resources, isLoading, meta: { total }, reqParams: { page, per_page, search, sort }, onSearchChange,
      onSortChange, onPageChange, onPerPageChange } = this.props;
    const { feedback, isLoadingFeedback } = this.state;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.groupName" />, flex: '1 1 160px',
        renderer: this.renderGroupNameColumn, sortKey: 'pig_group_name' },
      { label: <FormattedMessage id="general.treatmentDate" />, flex: '1 1 100px',
        renderer: this.renderTreatmentDateColumn, sortKey: 'from_date' },
      { label: <FormattedMessage id="general.feedback" />, flex: '1 1 120px', renderer: this.renderFeedbackColumn,
        sortKey: 'effective', description: <FormattedMessage id="component.treatmentFeedbackHome.feedDesc" /> },
      { label: '', flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true,
        className: isTablet ? 'hide-for-large' : '' },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };
    const dropdownOptions = [
      { label: <FormattedMessage id="general.button.downloadDataCSV" />, onClick: this.downloadFeedbackDataCSV },
      { label: <FormattedMessage id="general.manageMedication" />,
        url: `/admin/health-variables/medications/${feedback?.treatment_product?.id}` },
    ];

    return (
      <div className="TreatmentFeedbackMedication">
        <MedicationFeedbackHeader
          feedback={feedback}
          isLoading={isLoadingFeedback}
          dropdownOptions={dropdownOptions}
        />

        <section className="small-12 column treatments-feedback-section">
          <Panel>
            <Panel.Heading className="treatment-feedback-panel" renderTitle={this.renderTitle}>
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>

            <Panel.Body noPadding className="treatment-feedback-body">
              <Preloader isActive={isLoading} />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderExpandable}
                getRowClass={this.getRowClass}
                tableKey="treatmentEfficacy"
              />

              <NothingBox
                itemsName="products"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <h2 className="lighter"><FormattedMessage id="component.nothingBox.noData" /></h2>
              </NothingBox>
            </Panel.Body>

          </Panel>
        </section>
      </div>
    );
  }
}

TreatmentFeedbackMedication.propTypes = {
  resources: T.array,
  isLoading: T.bool,
  params: T.object,
  meta: T.object,
  reqParams: T.object,
  fetchData: T.func.isRequired,
  openDropdown: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.treatmentEfficacy.resources,
      reqParams: state.dataTable.treatmentEfficacy.params,
      meta: state.dataTable.treatmentEfficacy.meta,
      isLoading: state.dataTable.treatmentEfficacy.isLoading,
    }),
    (dispatch, { params: { id } }) => ({
      fetchData: (queryParams) => dispatch(fetchData(`/treatment_feedbacks/${id}/treatment_efficacies`,
        'treatmentEfficacy', queryParams)),
      setPageOptions: (options) => dispatch(setPageOptions(options)),
    })
  ),
  withDropdownActions,
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(TreatmentFeedbackMedication);
