import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchPigsUnderCareData, clearPigsUnderCareData } from 'reducers/pigsUnderCare';
// components
import AnalyticsPanel from 'components/Analytics/AnalyticsPanel';
import AnalyticsPanelStats from 'components/Analytics/AnalyticsPanelStats';
// utils
import { formatNumber } from 'utils';
// constants
import { farmTypes } from 'constants.js';

class PigsUnderCarePanel extends Component {

  componentDidMount() {
    const { fetchPigsUnderCareData } = this.props;
    fetchPigsUnderCareData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { companyId, fetchPigsUnderCareData } = this.props;
    const { companyId: nextPropsCompanyId } = nextProps;
    if (nextPropsCompanyId !== companyId) {
      fetchPigsUnderCareData(nextPropsCompanyId);
    }
  }

  componentWillUnmount() {
    const { clearPigsUnderCareData } = this.props;
    clearPigsUnderCareData();
  }

  shouldComponentUpdate(nextProps) {
    return !!nextProps.data;
  }

  render() {
    const { data, isLoading } = this.props;
    const stats = farmTypes.map(({ label, value }) => ({ label, value: formatNumber(data[value]) }));
    return (
      <AnalyticsPanel titleKey="puc" date={data.updated_at} panelData={{ isLoading }}>
        <div className="total-block">
          <div className="total-number-block">
            <div className="total-number">{formatNumber(data.total)}</div>
            <div className="percent" />
          </div>
        </div>
        <div className="stats-block">
          <AnalyticsPanelStats stats={stats} />
        </div>
      </AnalyticsPanel>
    );
  }
}

PigsUnderCarePanel.propTypes = {
  companyId: T.number,
  clearPigsUnderCareData: T.func.isRequired,
  fetchPigsUnderCareData: T.func.isRequired,
  data: T.object.isRequired,
  isLoading: T.bool,
};

export default connect(
  (state, props) => ({
    isLoading: state.pigsUnderCare.isLoading,
    data: state.pigsUnderCare[props.companyId || 'all'],
  }), { fetchPigsUnderCareData, clearPigsUnderCareData }
)(PigsUnderCarePanel);
