import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// helpers
import { groupCardHTML } from '../htmlHelper';
import { cardWidth, cardHeightByType, defaultCardHeight } from '../helpers/groupFlowChartHelper';

am4core.useTheme(am4themes_animated);

export default function createGroupFlowChart(chartId, data, router) {
  const chart = am4core.create(chartId, am4charts.SankeyDiagram);
  chart.hiddenState.properties.opacity = 0;
  chart.data = data;
  chart.paddingRight = 30;
  chart.minNodeSize = 0.5;
  chart.logo.disabled = true;

  chart.dataFields.fromName = 'from';
  chart.dataFields.toName = 'to';
  chart.dataFields.value = 'value';
  chart.dataFields.color = 'color';

  const linkTemplate = chart.links.template;

  linkTemplate.colorMode = 'gradient';
  linkTemplate.tooltipText = '';

  const hoverState = linkTemplate.states.create('hover');
  hoverState.properties.fillOpacity = 0.6;

  const roundedRectangle = new am4core.RoundedRectangle();
  roundedRectangle.cornerRadiusBottomLeft = 5;
  roundedRectangle.cornerRadiusBottomRight = 5;
  roundedRectangle.cornerRadiusTopLeft = 5;
  roundedRectangle.cornerRadiusTopRight = 5;

  const nodeTemplate = chart.nodes.template;
  nodeTemplate.nameLabel.locationX = 0;
  nodeTemplate.nameLabel.label.html = groupCardHTML;
  nodeTemplate.inert = true;
  nodeTemplate.draggable = false;
  nodeTemplate.clickable = false;
  nodeTemplate.userClassName = 'node-card';
  nodeTemplate.width = cardWidth;
  nodeTemplate.background = roundedRectangle;

  nodeTemplate.events.off('hit');

  nodeTemplate.adapter.add('minHeight', (dx, target) => {
    if (!target.dataItem) return dx;
    if (target.dataItem.dataContext.type === 'su') return cardHeightByType.external_source;
    if (target.dataItem.dataContext.type === 'plant') return cardHeightByType.packing_plant;
    return defaultCardHeight;
  });

  nodeTemplate.events.on('ready', (event) => {
    if (event.target.dataItem && event.target.dataItem.dataContext.url) {
      // eslint-disable-next-line no-param-reassign
      event.target.userClassName = 'hovered';
    }
  });

  nodeTemplate.events.on('hit', (event) => {
    if (!event.target.dataItem) return;
    const { url } = event.target.dataItem.dataContext;
    if (url) router.push(url);
  });

  return chart;
}
