import { createAction, handleActions } from 'redux-actions';

// for update comments feed of activity in mediaViewer sidebar
import { POST_COMMENT } from './farmfeed';
// for fetching user for header 'on-fly'
import { fetchFromAPI } from 'utils/api';
// utils
import isEmpty from 'lodash.isempty';
import update from 'react-addons-update';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  assets: [],
  currentIndex: 0,
  isOpen: false,
  data: {},
  mediaUsers: {},
};

const OPEN_VIEWER = 'mediaViewer/OPEN_VIEWER';
const CLOSE_VIEWER = 'mediaViewer/CLOSE_VIEWER';
const SHOW_NEXT = 'mediaViewer/SHOW_NEXT';
const SHOW_PREV = 'mediaViewer/SHOW_PREV';
const SHOW_INDEX = 'mediaViewer/SHOW_INDEX';
const FETCH_USER = 'mediaViewer/FETCH_USER';
const UPDATE_CURRENT_ASSET = 'mediaViewer/UPDATE_CURRENT_ASSET';

export const openViewer = createAction(OPEN_VIEWER, (assets, currentIndex = 0, data = {}, type) => {
  return {
    type,
    assets,
    currentIndex,
    data,
  };
});

export const updateCurrentAsset = (asset) => createAction(UPDATE_CURRENT_ASSET)(asset);

export const fetchUserById = (userId) => (dispatch) => {
  return fetchFromAPI(`/users/${userId}`)
    .then((response) => dispatch(createAction(FETCH_USER, () => response.resource)()));
};

export const closeViewer = createAction(CLOSE_VIEWER);

export const showNext = () => createAction(SHOW_NEXT)();

export const showPrev = () => createAction(SHOW_PREV)();

export const showByIndex = createAction(SHOW_INDEX);

export const actions = {
  openViewer,
  closeViewer,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [OPEN_VIEWER]: (state, action) => ({
    ...state,
    ...action.payload,
    isOpen: true,
  }),
  [UPDATE_CURRENT_ASSET]: (state, { payload }) => ({
    ...state,
    assets: update(state.assets, {
      [state.currentIndex]: { $merge: { ...payload } },
    }),
  }),
  [CLOSE_VIEWER]: () => ({
    ...initialState,
  }),
  [SHOW_NEXT]: (state) => ({
    ...state,
    currentIndex: state.currentIndex !== state.assets.length - 1
      ? state.currentIndex + 1
      : 0,
  }),
  [SHOW_PREV]: (state) => ({
    ...state,
    currentIndex: state.currentIndex !== 0
      ? state.currentIndex - 1
      : state.assets.length - 1,
  }),
  [SHOW_INDEX]: (state, action) => ({
    ...state,
    currentIndex: action.payload,
  }),
  [POST_COMMENT]: (state, action) => {
    const newComment = action.payload.resource;
    if (isEmpty(state.data)) {
      return state;
    }
    return {
      ...state,
      data: {
        ...state.data,
        comments: [
          newComment,
          ...state.data.comments,
        ],
      },
    };
  },
  [FETCH_USER]: (state, action) => {
    const user = action.payload;
    return {
      ...state,
      mediaUsers: {
        ...state.mediaUsers,
        [user.id]: action.payload,
      },
    };
  },
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
