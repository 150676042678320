import React, { Component } from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
// styles
import './HintTooltip.scss';


class HintTooltip extends Component {

  constructor() {
    super();
    this.state = {
      visible: false,
      width: 0,
    };

    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.attachToBody) {
      this.parentNode = this.ref.current.parentNode;
      document.getElementById('root').appendChild(this.ref.current);

      if (isMobile) {
        this.parentNode.addEventListener('click', this.onMouseEnter);
      }

      if (!isMobile) {
        this.parentNode.addEventListener('mouseenter', this.onMouseEnter);
      }

      this.parentNode.addEventListener('mouseleave', this.onMouseLeave);
    }
    const { width } = this.ref.current.getBoundingClientRect();
    this.setState({ width });
  }

  componentWillUnmount() {
    if (this.props.attachToBody) {
      document.getElementById('root').removeChild(this.ref.current);

      if (isMobile) {
        this.parentNode.removeEventListener('click', this.onMouseEnter);
      }

      if (!isMobile) {
        this.parentNode.removeEventListener('mouseenter', this.onMouseEnter);
      }

      this.parentNode.removeEventListener('mouseleave', this.onMouseLeave);
    }
  }

  setTooltipPosition = () => {
    const { showBelow } = this.props;
    const { top, left, width } = this.parentNode.getBoundingClientRect();
    const { height } = this.ref.current.getBoundingClientRect();
    this.coords = {
      left: Math.floor(left + width / 2),
      top: showBelow ? top + height + 20 : top,
    };
  };

  onMouseEnter = () => {
    this.setTooltipPosition();
    this.setState({ visible: true });
    window.addEventListener('scroll', this.onMouseLeave, true);
  };

  onMouseLeave = () => {
    this.setState({ visible: false });
    window.removeEventListener('scroll', this.onMouseLeave, true);
  }

  getTooltipStyles = (width) => ({
    marginLeft: Math.floor(-width / 2) + 'px',
    ...(this.coords || {}),
  });

  render() {
    const { className = '', arrowed, attachToBody, children, showBelow } = this.props;
    const { visible, width } = this.state;
    const style = this.getTooltipStyles(width);
    return (
      <div
        className={cn('HintTooltip', className, { arrowed, attachToBody, visible, showBelow })}
        style={style}
        ref={this.ref}
      >
        { children }
      </div>
    );
  }
}


HintTooltip.propTypes = {
  children: T.node,
  className: T.string,
  arrowed: T.bool,
  attachToBody: T.bool,
  showBelow: T.bool,
};

export default HintTooltip;
