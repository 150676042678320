import { fetchFromAPI } from 'utils/api';
import {
  addDailyCheckupOffline,
  addRequestToOfflineDB,
  farmWhenOneCheckupStarted,
  getFarmOffline,
  getPigGroupOffline,
  mergePigGroupsOffline,
  updateFarmOffline,
  updatePigGroupOffline,
} from 'utils/offlineHelper';
import { generateAllGoodCheckup, makeCheckupTemplate } from 'utils/checkupTemplate';
import { currentUtcOffsetTime } from 'utils/timeHelper';

export const getFarmPigGroups = (pigGroupId, params) => {
  return fetchFromAPI(`/daily_checkups/farms/${pigGroupId}/pig_groups`, { params })
    .then((response) => {
      mergePigGroupsOffline(response.resources);
      return response.resources;
    })
    .catch((response) => {
      throw response;
    });
};

export const getCheckupFarm = (farmId) => {
  return fetchFromAPI(`/daily_checkups/farms/${farmId}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const setPigGroupAllGood = (pigGroupId) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/all_good`, { method: 'post' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const setPigGroupAllGoodOffline = (groupId) => {
  return getPigGroupOffline(groupId)
    .then((group) => {
      const farmId = group.farm_id;
      return getFarmOffline(farmId).then((farm) => {
        const checkup = generateAllGoodCheckup(group, farm);
        addDailyCheckupOffline(checkup);
        addRequestToOfflineDB({
          pig_group_id: groupId,
          farm_id: farm.id,
          utc_offset: farm.utc_offset,
          current_checkup_id: checkup.id,
          created_on: checkup.created_on,
          resource: { all_good: true },
        });
        const newGroup = {
          id: groupId,
          current_checkup_id: checkup.id,
          current_checkup_created_on: checkup.created_on,
          current_checkup_submitted: true,
        };
        updatePigGroupOffline(newGroup);
        return newGroup;
      });
    })
    .catch((response) => {
      throw response;
    });
};

export const startPigGroup = (pigGroupId, resource) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/open_group`, { method: 'post', body: JSON.stringify({ resource }) })
    .catch((response) => {
      throw response;
    });
};

export const createPigGroupCheckup = (pigGroupId) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/daily_checkups`, { method: 'post' })
    .then((response) => {
      const checkup = response.resource;
      addDailyCheckupOffline(checkup);
      updatePigGroupOffline({
        id: pigGroupId,
        current_checkup_id: checkup.id,
        current_checkup_created_on: checkup.created_on,
      });
      getFarmOffline(checkup.pig_group.farm_id || checkup.pig_group.farm.id)
        .then((farm) => {
          if (!farm) return;
          const newFarm = farmWhenOneCheckupStarted(farm);
          updateFarmOffline(newFarm);
        });
      return checkup;
    })
    .catch((response) => {
      throw response;
    });
};

export const createPigGroupCheckupOffline = (groupId) => (
  new Promise((resolve, reject) => {
    getPigGroupOffline(groupId).then((group) => {
      return getFarmOffline(group.farm_id).then((farm) => {
        const checkup = makeCheckupTemplate({
          pig_group_id: groupId,
          pigs: group.pigs || 0,
          initial_pigs: group.pigs || 0,
          farm_id: farm.id,
          created_on: currentUtcOffsetTime(farm.utc_offset).format('YYYY-MM-DD'),
          pig_group: { ...group, farm },
        });
        addDailyCheckupOffline(checkup);
        updatePigGroupOffline({
          ...group,
          current_checkup_id: checkup.id,
          current_checkup_created_on: checkup.created_on,
        });
        const newFarm = farmWhenOneCheckupStarted(farm);
        updateFarmOffline(newFarm);
        resolve(checkup);
      }).catch((error) => {
        reject(error);
      });
    });
  })
);
