import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Waypoint.module.scss';

const cn = classnames.bind(styles);

const renderIcon = (type) => (type === 'source' ?
  <div className={cn('source', 'marker')} /> :
  <i className={cn('fa fa-map-marker-bts', 'marker', 'destination')} />);

const Waypoint = ({ children, type, icon, isEnd: end }) => (
  <div className={cn('wrapper')}>
    <div className={cn('marker-wrapper', { end })}>
      {icon ? <div className={cn('marker')}>{icon}</div> : renderIcon(type)}
    </div>
    <div className={cn('content')}>{children}</div>
  </div>
);

Waypoint.propTypes = {
  icon: T.node,
  children: T.node.isRequired,
  type: T.string,
  isEnd: T.bool,
};

export default Waypoint;
