import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import MobileHeader from 'components/MobileHeader/MobileHeader';
import SearchBox from 'components/SearchBox';
import TenantAvatar from 'components/TenantAvatar/TenantAvatar';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './TenantSwitcher.module.scss';

const cn = classnames.bind(styles);

const TenantSwitcher = ({ goBack, companies, currentTenantId }, { router }) => {
  const [search, setSearch] = useState('');
  const filteredCompanies = companies
    .filter((company) => company.name.toLowerCase().includes(search.toLowerCase()));

  const handleSelect = (id) => {
    if (id !== currentTenantId) router.push(`/switch-company/${id}`);
  };

  return (
    <div className={cn('switch-accounts')}>
      <MobileHeader
        title={<FormattedMessage id="component.mobileMenu.switchAccount" />}
        backLink={goBack}
        backIcon="fa fa-angle-left-btb"
      />

      <div className={cn('tenants-list-box')}>
        <div className={cn('tenant-search')}>
          <SearchBox
            onChange={setSearch}
            className={cn('tenant-search-box')}
          />
        </div>
        <div className={cn('tenants-list')}>
          {filteredCompanies.map(({ name, id }) => (
            <div key={id} className={cn('tenant-row')} onClick={() => handleSelect(id)}>
              <TenantAvatar name={name} highlighted={id === currentTenantId} />
              <div className={cn('tenant-name')}>{name}</div>
              {id === currentTenantId &&
                <i className={cn('fa fa-check', 'check-icon')} />
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

TenantSwitcher.contextTypes = {
  router: T.object.isRequired,
};

TenantSwitcher.propTypes = {
  companies: T.array.isRequired,
  currentTenantId: T.number.isRequired,
  goBack: T.func.isRequired,
};

export default TenantSwitcher;
