import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './DestinationField.module.scss';

const cn = classnames.bind(styles);

const options = [
  { value: 'external', label: <FormattedMessage id="general.externalDestination" /> },
  { value: 'internal', label: <FormattedMessage id="general.internalDestination" /> },
];

const requestParams = { page: 1, per_page: 25 };

const DestinationField = ({ destination, className }) => {
  const destTypeInput = destination.type.input;
  const destDataInput = destination.data.input;
  const onTypeChange = (value) => {
    destTypeInput.onChange(value);
    destDataInput.onChange('');
  };
  return (
    <div className={cn('row', className)}>
      <div className="small-12 medium-3 column label-wrapper">
        <FormattedMessage id="general.destinationType" />
      </div>
      <div className={cn('small-12 medium-9 column', 'field-select-row')}>
        <ButtonRadioGroup
          options={options}
          value={destTypeInput.value}
          onChange={onTypeChange}
          className={cn('field-select')}
        />
      </div>
      <div className="small-12 medium-3 column label-wrapper">
        <FormattedMessage id="general.destination" />
      </div>
      <div className={cn('small-12 medium-9 column', 'field-select-row')}>
        <AsyncSelect
          selected={destDataInput.value || {}}
          onBlur={() => destDataInput.onBlur()}
          onChange={destDataInput.onChange}
          optionsPath={destTypeInput.value === 'external' ? '/admin/external_destinations?active=true' : '/admin/farms'}
          requestParams={requestParams}
          optionsKeys={{ value: 'id', label: 'name' }}
          placeholder={<FormattedMessage id="general.addDestination" />}
          disabled={!destTypeInput.value}
          className={cn('field-select')}
        />
      </div>
    </div>
  );
};

DestinationField.propTypes = {
  destination: T.object.isRequired,
  className: T.string,
};

export default DestinationField;
