import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose, withProps } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
// utils
import isEmpty from 'lodash.isempty';
import sum from 'lodash.sum';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

class CompanyManagedFarms extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    let activeValue = '';
    if (filter) activeValue = filter === 'active';
    fetchData({ ...reqParams, page: 1, active: activeValue, filter });
  };

  revokeRole = (farmId) => () => {
    const { fetchData, reqParams, id } = this.props;
    fetchFromAPI(`/admin/companies/${id}/farm_roles/${farmId}`, { method: 'delete' })
      .then(() => {
        fetchData(reqParams);
        showToastrMessage('component.toastr.role.revoked');
      })
      .catch(toastResponseErrors);
  };

  renderFarmNameColumn = ({ name, farm_type }) => (
    <CustomColumn>
      <FarmTitleBox name={name} type={farm_type} />
    </CustomColumn>
  );

  renderLocationColumn = ({ city, state }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.location" />}>
      {`${city}, ${state}`}
    </CustomColumn>
  );

  renderFarmIdColumn = ({ external_link_id }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmId" />}>
      {external_link_id || 'N/A'}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = (farm, { rowIndex }) => {
    const { route: { type } } = this.props;
    if (type === 'myCompanyFarms') return (
      <div className="collapsible-value button-column">
        <Link to={`/admin/farms/${farm.id}/edit`} className="button small wide">
          <FormattedMessage id="general.button.edit" />
        </Link>
      </div>
    );
    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${farm.id}-${rowIndex}`}
          wide
          buttonType="small light-gray"
          label={<FormattedMessage id="general.button.revokeAccess" />}
          onClick={this.revokeRole(farm.id)}
          dropDownData={[
            { label: <FormattedMessage id="general.editFarm" />, url: `/admin/farms/${farm.id}/edit` },
          ]}
          customClass="show-for-large"
        />
        <Link to={`/admin/farms/${farm.id}/edit`} className="button wide hide-for-large">
          <FormattedMessage id="general.editFarm" />
        </Link>
        <Button darkGrey onClick={this.revokeRole(farm.id)} className="hide-for-large">
          <FormattedMessage id="general.button.revokeAccess" />
        </Button>
      </div>
    );
  };

  renderExpandable = ({ id }) => (
    <div>
      <Link to={`/admin/farms/${id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.editFarm" />
      </Link>

      {(this.props.type === 'companyFarms') && (
        <Button small light onClick={this.revokeRole(id)}>
          <i className="fa fa-times-circle mr-5" />
          <FormattedMessage id="general.button.revokeAccess" />
        </Button>
      )}
    </div>
  );

  render() {
    const { resources, meta: { stats = {}, total }, isLoading, reqParams: { page, per_page, filter, search, sort },
      onSearchChange, onPageChange, onPerPageChange, onSortChange, id, route: { type } } = this.props;
    const statsSum = sum(Object.values(stats));
    const { isTablet } = this.context;
    const filtersData = [
      { label: <FormattedMessage id="general.allFarms" />, value: '', count: statsSum },
      { label: <FormattedMessage id="general.activeFarms" />, value: 'active' },
      { label: <FormattedMessage id="general.disabledFarms" />, value: 'disabled' },
    ];
    const isEmptyTable = !resources.length && !filter && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '2 1 150px', sortKey: 'name',
        renderer: this.renderFarmNameColumn },
      { label: <FormattedMessage id="component.dataTable.headers.location" />, flex: '1 1 100px', sortKey: 'city',
        renderer: this.renderLocationColumn },
      { label: <FormattedMessage id="component.dataTable.headers.farmId" />, flex: '1 1 100px',
        sortKey: 'external_link_id', renderer: this.renderFarmIdColumn },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 100px', sortKey: 'active',
        renderer: this.renderStatusColumn },
      { label: '', flex: `0 0 ${type === 'myCompanyFarms' ? '120px' : '180px'}`,
        renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    // check, if current page is MyCompanyManagedFarms then link to My Company , else - Other Companies
    const assignFarmsLink = type === 'myCompanyFarms' ?
      '/admin/my-company/assign-farms' :
      `/admin/companies/${id}/assign-farms`;

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.managedFarms" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={filtersData}
              onFilterChange={this.onFilterChange}
              stats={stats}
              activeFilter={filter}
              className="ph-10"
            >
              {!isEmpty(resources) && (
                <Link to={assignFarmsLink} className="button small primary wider">
                  <FormattedMessage id="general.manageAssignments" />
                </Link>
              )}
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey={type}
          />

          <NothingBox
            itemsName="farm_assignments"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={filter}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.companyHasntFarms" />
            <Link to={assignFarmsLink} className="button primary mt-10">
              <FormattedMessage id="general.assignFarms" />
            </Link>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

CompanyManagedFarms.propTypes = {
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  route: T.object.isRequired,
  id: T.number.isRequired,
  type: T.string
};

CompanyManagedFarms.contextTypes = {
  isTablet: T.bool,
};

const enhance = compose(
  connect((state, { route: { type }, params: { id } }) => ({
    resources: state.dataTable[type].resources,
    reqParams: state.dataTable[type].params,
    meta: state.dataTable[type].meta,
    isLoading: state.dataTable[type].isLoading,
    authUser: state.auth.user,
    id: +(id || state.auth.user.current_company.id)
  }), {
    fetchData
  }), withProps(({ fetchData, id, route: { type } }) => ({
    fetchData: (query) => fetchData(`/admin/companies/${id}/farm_roles`, type, query),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(CompanyManagedFarms);
