import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MobileMenuSection.module.scss';

const cn = classnames.bind(styles);

const MobileMenuSection = ({ className, data }) => (
  <div className={cn('mobile-menu-section', { [className]: !!className })}>
    {data.map(({ title, subtitle, count, icon, onClick, hide }, index) => !hide && (
      <div key={index} className={cn('section-row')} onClick={onClick}>
        <div className={cn('icon-column')}>{icon}</div>
        <div className={cn('title-column')}>
          <div className={cn('title-box')}>
            <span className={cn('title')}>
              {title}
              {!!count &&
                <span className={cn('count-badge')}>{count}</span>
              }
            </span>
            {subtitle &&
              <div className={cn('subtitle')}>{subtitle}</div>
            }
          </div>
          {typeof onClick === 'function' &&
            <i className={cn('fa fa-angle-right', 'action-icon')} />}
        </div>
      </div>
    ))}
  </div>
);

MobileMenuSection.propTypes = {
  className: T.string,
  data: T.array.isRequired,
};

export default MobileMenuSection;
