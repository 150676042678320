import { useState, useEffect } from 'react';
// api
import { getCommitment } from 'endpoints/commitments';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const initialState = { isLoading: false, commitment: null };

export default function useCommitment(commitmentId) {
  const [data, setData] = useState(initialState);

  useEffect(() => {
    if (commitmentId) {
      setData((prevState) => ({ ...prevState, isLoading: true }));
      getCommitment(commitmentId)
        .then((commitment) => {
          setData({ commitment, isLoading: false });
        })
        .catch((errors) => {
          setData((prevState) => ({ ...prevState, isLoading: false }));
          toastResponseErrors(errors);
          throw errors;
        });
    }
    if (!commitmentId && !!data.commitment) {
      setData(initialState);
    }
  }, [commitmentId, setData]);

  return data;
}
