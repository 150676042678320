import React from 'react';
import T from 'prop-types';
// helpers
import { markMainCountries } from 'helpers';
// components
import { FormattedMessage } from 'react-intl';
import VirtualizedSelect from 'components/Select/VirtualizedSelect';
import FieldWrapper from '../FieldWrapper';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './CountrySelectField.module.scss';

const cn = classnames.bind(styles);

function optionRenderer(dataItem) {
  const { option, style, selectValue, key, valueArray } = dataItem;
  return (
    <div
      key={key}
      style={style}
      className={cn('Select-option', { 'is-selected': valueArray.indexOf(option) >= 0 })}
      onClick={() => selectValue(option)}
    >
      <div>
        <i className={`flag-icon flag-icon-${option.iso.toLowerCase()}`} />
        <span className="ml-15">{option.label}</span>
      </div>
      {option.divider && <div className={styles.divider} />}
    </div>
  );
}

const CountrySelectField = ({ input, label, meta, required, horizontal, countriesData, isLoading }) => (
  <FieldWrapper
    meta={meta}
    label={label}
    required={!input.value && required}
    horizontal={horizontal}
  >
    <VirtualizedSelect
      valueKey="iso"
      labelKey="label"
      options={markMainCountries(countriesData)}
      optionRenderer={optionRenderer}
      optionHeight={({ option }) => (option.divider ? 50 : 40)}
      arrowRenderer={() => <i className="fa fa-angle-down" />}
      value={input.value || ''}
      onBlur={() => input.onBlur(input?.iso)}
      onChange={(option) => (input.onChange(option?.iso))}
      placeholder={<FormattedMessage id="general.search.country" />}
      isLoading={isLoading}
      clearable={false}
    />
  </FieldWrapper>
);

CountrySelectField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.node, T.string]),
  required: T.bool,
  horizontal: T.bool,
  countriesData: T.array.isRequired,
  isLoading: T.bool.isRequired,
};

export default CountrySelectField;
