import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { compose } from 'recompose';
import { fetchData } from 'reducers/dataTable';
import { connect } from 'react-redux';
// utils
import moment from 'moment/moment';
import cn from 'classnames';
// components
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn } from 'components/DataTable/Columns/index';
import NothingBox from 'components/NothingBox';
import Subnavigation from 'components/Subnavigation/Subnavigation';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import './ImportResult.scss';

class ImportResult extends Component {

  state = {
    status: 'create',
    isLoading: false,
  };

  componentDidMount() {
    const { fetchData, groupParams } = this.props;
    fetchData({ ...groupParams });
  }

  renderGroupIDColumn = ({ name, error }) => {
    return (
      <CustomColumn
        label={<FormattedMessage id="component.dataTable.headers.groupId" />}
        className={cn({ 'highlighted': error === 'name' })}
      >
        {name || '-'}
      </CustomColumn>
    );
  };

  renderFarmColumn = ({ farm_name, error }) => (
    <CustomColumn
      label={<FormattedMessage id="component.dataTable.headers.farmName" />}
      className={cn({ 'highlighted': error === 'farm' })}
    >
      {farm_name || '-'}
    </CustomColumn>
  );

  renderStartDateColumn = ({ start_date, created_on, error }) => {
    const { status } = this.state;

    const dateValue = status === 'create' ? created_on : start_date;

    return (
      <CustomColumn
        label={<FormattedMessage id="general.creationDate" />}
        className={cn({ 'highlighted': error === 'start_date' })}
      >
        {dateValue ? moment(dateValue).format('MM/DD/YYYY') : '-'}
      </CustomColumn>
    );
  };

  onGroupStatusChange = (item = {}) => {
    this.setState({ status: item.value });
  };

  render() {
    const { resources, onSortChange, onPageChange, onPerPageChange, meta: { total, import_invalid_rows },
      groupParams: { page, per_page, search, sort } } = this.props;
    const { status, isLoading } = this.state;

    const data = status === 'create' ? resources : import_invalid_rows;
    const rowKey = status === 'create' ? 'formatted_row.row_index' : 'row_index';

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 140px',
        renderer: this.renderGroupIDColumn },
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
        renderer: this.renderFarmColumn },
      { label: <FormattedMessage id="general.creationDate" />, flex: '1 1 90px',
        renderer: this.renderStartDateColumn },

    ];

    const items = [
      { value: 'create', label: <FormattedMessage id="general.created" />, stats: total || 0 },
      { value: 'errors', label: <FormattedMessage id="general.errors" />,
        stats: (import_invalid_rows && import_invalid_rows.length) || 0 },
    ];

    const paginationProps = status === 'create' ? {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    } : {};

    return (
      <div className="ImportResult">
        <Subnavigation
          items={items}
          idKey="value"
          labelKey="label"
          currentId={status}
          onSelect={this.onGroupStatusChange}
          className="small-12 column"
          isSticky
        />

        <DataTable
          data={data}
          columns={columns}
          sort={sort}
          onSortChange={onSortChange}
          paginationProps={paginationProps}
          scrollable
          tableKey="importGroups"
          rowKey={rowKey}
        />

        <NothingBox
          itemsName="groups"
          display={!data.length}
          isLoading={isLoading}
          search={search}
        >
          <FormattedMessage tagName="h2" id="component.nothingBox.noData" />
        </NothingBox>
      </div>
    );
  }
}

ImportResult.propTypes = {
  fetchData: T.func,
  groupParams: T.object,
  resources: T.array,
  meta: T.object,
  onSortChange: T.func,
  onPageChange: T.func,
  onPerPageChange: T.func,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.importGroups.resources,
      groupParams: state.dataTable.importGroups.params,
      meta: state.dataTable.importGroups.meta,
      isLoading: state.dataTable.importGroups.isLoading,
    }), (dispatch, { groupImportId }) => ({
      fetchData: (query) => dispatch(
        fetchData(
          `/admin/pig_groups_imports/${groupImportId}/pig_groups_import_details`,
          'importGroups',
          query
        )
      ),
    }),
  ),
  withDataTableController('fetchData', 'groupParams'),
);

export default enhance(ImportResult);
