import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { updateCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import PackingCompanyForm from '../components/PackingCompanyForm';
import Panel from 'components/Panel';
// constants
import { addressFields } from 'constants.js';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import pick from 'lodash.pick';

class PackingCompanyEdit extends Component {

  updateCompanyName = (data) => {
    const resource = {
      name: data.name,
      ...data.addressObj,
    };
    const { updateCompanyProfile, params: { id } } = this.props;
    return updateCompanyProfile(`/admin/packing_companies/${id}`, {
      method: 'put',
      body: JSON.stringify({ resource })
    })
      .then(() => {
        showToastrMessage('component.toastr.packingCompany.updated');
      })
      .catch(toastResponseErrors);
  };

  getInitialValues = () => {
    const { params, company } = this.props;

    if (company.id === Number(params.id)) {
      return {
        name: company.name,
        addressObj: pick(company, addressFields),
      };
    }
    return null;
  };

  render() {
    const initialValues = this.getInitialValues();
    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.packingCompanyInfo" />} />
        <Panel.Body noPadding>
          <PackingCompanyForm onSubmit={this.updateCompanyName} initialValues={initialValues} isEditForm />
        </Panel.Body>
      </Panel>
    );
  }
}

PackingCompanyEdit.propTypes = {
  updateCompanyProfile: T.func.isRequired,
  params: T.object,
  company: T.object,
};

export default connect((state) => ({
  company: state.profiles.company.data,
}), { updateCompanyProfile })(PackingCompanyEdit);
