import React from 'react';
import T from 'prop-types';
// components
import Input from 'components/Input/Input';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
// utils
import { connect } from 'react-redux';
import { capitalize, formatDosageType } from 'utils';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import './MedicationInfo.scss';

const MedicationInfo = ({
  medication: {
    name, ingredient, route, unit_size, unit_type, dosage_type, dosage_measure,
    vaccine, antibiotic,
  },
  measurement_system,
  isLoading,
}, { formatMessage }) => {

  const dosageType = formatDosageType(dosage_type, measurement_system);
  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.medicationInformation" />} />
        <Panel.Body>
          <div className="row">
            <div className="small-12 medium-10 large-8 column">
              <Preloader isActive={isLoading} />
              <div className="MedicationInfo">
                <div className="row">
                  <div className="column">
                    <label>
                      <FormattedMessage id="general.proprietaryName">
                        {(proprietaryName) => (
                          <>
                            <span className="label-tag">{proprietaryName}</span>
                            <Input
                              value={name || ''}
                              name="name"
                              type="text"
                              placeholder={proprietaryName}
                              readOnly
                            />
                          </>
                        )}
                      </FormattedMessage>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <FormattedMessage id="general.ingredients">
                        {(text) => (
                          <>
                            <span className="label-tag">{text}</span>
                            <Input
                              value={ingredient || ''}
                              name="ingredients"
                              type="text"
                              placeholder={text}
                              readOnly
                            />
                          </>
                        )}
                      </FormattedMessage>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <label>
                      <FormattedMessage id="general.routeAdministration">
                        {(text) => (
                          <>
                            <span className="label-tag">{text}</span>
                            <Input
                              value={route ? route.map((item) => capitalize(item)).join(', ') : ''}
                              name="route"
                              type="text"
                              placeholder={text}
                              readOnly
                            />
                          </>
                        )}
                      </FormattedMessage>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="small-12 medium-4 large-4 column">
                    <label>
                      <FormattedMessage id="general.unitOfMeasure">
                        {(text) => (
                          <>
                            <span className="label-tag">{text}</span>
                            <Input
                              value={unit_type ? formatMessage({ id: `general.${unit_type}` }) : ''}
                              name="unit_type"
                              type="text"
                              placeholder={text}
                              readOnly
                            />
                          </>
                        )}
                      </FormattedMessage>
                    </label>
                  </div>
                  <div
                    className={`small-12 ${dosage_type === 'doses' ? 'medium-4 large-4' : 'medium-3 large-3'} column`}
                  >
                    <label>
                      <FormattedMessage id="general.unitSize">
                        {(text) => (
                          <>
                            <span className="label-tag">{text}</span>
                            <Input
                              value={unit_size || ''}
                              name="unit_size"
                              type="text"
                              placeholder={text}
                              readOnly
                            />
                          </>
                        )}
                      </FormattedMessage>
                    </label>
                  </div>
                  {dosage_type === 'doses'
                    ? (
                      <div className="small-12 medium-4 large-4 column">
                        <label>
                          <FormattedMessage id="general.dosage">
                            {(text) => (
                              <>
                                <span className="label-tag">{text}</span>
                                <Input
                                  value={dosage_type ? formatMessage({ id: `general.${dosage_type}` }) : ''}
                                  name="dosage"
                                  type="text"
                                  readOnly
                                />
                              </>
                            )}
                          </FormattedMessage>
                        </label>
                      </div>
                    )
                    : (
                      <div className="small-12 medium-5 large-5 column">
                        <div className="row">
                          <div className="small-5 medium-5 large-5 column">
                            <label>
                              <FormattedMessage id="general.dosage">
                                {(text) => (
                                  <>
                                    <span className="label-tag">{text}</span>
                                    <Input
                                      value={dosage_type ? formatMessage({ id: `general.${dosageType}` }) : ''}
                                      name="dosage"
                                      type="text"
                                      readOnly
                                    />
                                  </>
                                )}
                              </FormattedMessage>
                            </label>
                          </div>
                          <div className="mt-30 small-2 medium-2 large-2 column">
                            <div className="divider">/</div>
                          </div>
                          <div className="mt-20 small-5 medium-5 large-5 column">
                            <label>
                              <span className="label-tag" />
                              <Input
                                value={dosage_measure ? formatMessage({ id: `general.${dosage_measure}` }) : ''}
                                name="dosage_measure"
                                type="text"
                                readOnly
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className="row">
                  <div className="small-12 medium-3 large-3 column">
                    <label>
                      <FormattedMessage id="general.vaccine">
                        {(text) => (
                          <>
                            <span className="label-tag">{text}</span>
                            <Input
                              value={formatMessage({ id: `general.button.${vaccine ? 'yes' : 'no'}` })}
                              name="vaccine"
                              type="text"
                              placeholder={text}
                              readOnly
                            />
                          </>
                        )}
                      </FormattedMessage>
                    </label>
                  </div>
                  <div className="small-12 medium-3 large-3 column end">
                    <label>
                      <FormattedMessage id="general.antibiotic">
                        {(text) => (
                          <>
                            <span className="label-tag">{text}</span>
                            <Input
                              value={formatMessage({ id: `general.button.${antibiotic ? 'yes' : 'no'}` })}
                              name="antibiotic"
                              type="text"
                              placeholder={text}
                              readOnly
                            />
                          </>
                        )}
                      </FormattedMessage>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </section>
  );
};

MedicationInfo.propTypes = {
  medication: T.object,
  isLoading: T.bool,
  measurement_system: T.string,
};

MedicationInfo.contextTypes = {
  formatMessage: T.func,
};

export default connect((state) => ({
  measurement_system: state.auth.user.current_company.measurement_system,
}))(MedicationInfo);
