import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';

const GroupsMenuContainer = ({
  children,
  setBreadcrumbs,
  setPageOptions,
}) => {
  useEffect(() => {
    setBreadcrumbs();
    setPageOptions({
      links: [
        { link: '/groups', label: <FormattedMessage id="general.pageTitle.groups" /> },
        { link: '/sources', label: <FormattedMessage id="general.sources" /> },
        { link: '/destinations', label: <FormattedMessage id="general.destinations" /> },
      ],
    });
    return () => {
      setPageOptions({ links: null });
    };
  }, []);
  return children;
};

GroupsMenuContainer.propTypes = {
  children: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(null, { setPageOptions, setBreadcrumbs })(GroupsMenuContainer);
