import { sendNotify } from 'utils/airbrakeHelper';

const dbName = 'everypig-db';
const dbVersion = 4;

let db = null;

export function openDB() {
  const openRequest = indexedDB.open(dbName, dbVersion);

  openRequest.onupgradeneeded = (event) => {
    db = openRequest.result;

    if (event.oldVersion < 2) {
      // for db version 2 or create new db
      const farms = db.createObjectStore('farms', { keyPath: 'id', autoIncrement: true });
      const groups = db.createObjectStore('pig_groups', { keyPath: 'id', autoIncrement: true });
      const checkups = db.createObjectStore('daily_checkups', { keyPath: 'id', autoIncrement: true });
      const lastPreloadData = db.createObjectStore('last_preload_data', {
        keyPath: 'current_company_id', autoIncrement: true,
      });
      const requests = db.createObjectStore('requests', { keyPath: 'id', autoIncrement: true });
      const mediaRequests = db.createObjectStore('media_requests', { keyPath: 'id', autoIncrement: true });
      const svrFarms = db.createObjectStore('svr_farms', { keyPath: 'id', autoIncrement: true });
      const svrQuestions = db.createObjectStore('svr_questions', { keyPath: 'id', autoIncrement: true });
      const siteVisitReports = db.createObjectStore('site_visit_reports', { keyPath: 'id', autoIncrement: true });
      const svrDetailedFarms = db.createObjectStore('svr_detailed_farms', { key: 'id', autoIncrement: true });
      db.createObjectStore('treatment_products', { keyPath: 'id', autoIncrement: true });
      db.createObjectStore('symptoms', { keyPath: 'id', autoIncrement: true });
      db.createObjectStore('mortality_reasons', { keyPath: 'id', autoIncrement: true });
      db.createObjectStore('temperature_points', { keyPath: 'id', autoIncrement: true });

      farms.createIndex('id', 'id', { unique: true });
      groups.createIndex('current_checkup_id', 'current_checkup_id', { unique: true });
      groups.createIndex('farm_id', 'farm_id', { unique: false });
      groups.createIndex('id', 'id', { unique: true });
      checkups.createIndex('id', 'id', { unique: true });
      lastPreloadData.createIndex('current_company_id', 'current_company_id', { unique: true });
      requests.createIndex('pig_group_id', 'pig_group_id', { unique: true });
      mediaRequests.createIndex('checkup_id', 'checkup_id', { unique: false });
      svrFarms.createIndex('id', 'id', { unique: true });
      svrQuestions.createIndex('id', 'id', { unique: true });
      siteVisitReports.createIndex('id', 'id', { unique: true });
      svrDetailedFarms.createIndex('id', 'id', { unique: true });
    }

    if (event.oldVersion < 3) {
      db.deleteObjectStore('svr_detailed_farms');
      db.deleteObjectStore('svr_questions');
      const svrDetailedFarms = db.createObjectStore('svr_detailed_farms', { keyPath: 'id', autoIncrement: true });
      const svrCategories = db.createObjectStore('svr_categories', { keyPath: 'id', autoIncrement: true });
      svrDetailedFarms.createIndex('id', 'id', { unique: true });
      svrCategories.createIndex('id', 'id', { unique: true });
    }

    if (event.oldVersion < 4) {
      const svrCustomQuestions = db.createObjectStore('svr_custom_questions', { keyPath: 'id', autoIncrement: true });
      svrCustomQuestions.createIndex('id', 'id', { unique: true });
    }
  };

  openRequest.onerror = () => {
    sendNotify(openRequest.error, {});
  };

  openRequest.onsuccess = () => {
    db = openRequest.result;
    db.onclose = () => {
      sendNotify({ error: `The database ${db.name} has unexpectedly closed.` });
      openDB();
    };
  };
}

function withPromise(request, errorDetails = {}) {
  return new Promise((resolve, reject) => {
    if (!request) {
      const error = new Error('The indexedDB object is empty or null');
      reject(error);
      return;
    }
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = () => {
      sendNotify(request.error, errorDetails);
      reject(request.error);
    };
  });
}

function getTableObjectStore(tableName) {
  return db?.transaction(tableName, 'readwrite').objectStore(tableName);
}

export function tableObjectStoreGetAll(tableName) {
  return withPromise(
    db?.transaction(tableName).objectStore(tableName).getAll(),
    { tableName, indexedDBMethod: tableObjectStoreGetAll.name }
  );
}

export function tableObjectStoreClear(tableName) {
  return withPromise(
    getTableObjectStore(tableName).clear(),
    { tableName, indexedDBMethod: tableObjectStoreClear.name }
  );
}

export function tableObjectStoreAdd(tableName, item) {
  return withPromise(
    getTableObjectStore(tableName).add(item),
    { tableName, indexedDBMethod: tableObjectStoreAdd.name },
  );
}

export function tableObjectStoreDelete(tableName, id) {
  return withPromise(
    getTableObjectStore(tableName).delete(id),
    { tableName, indexedDBMethod: tableObjectStoreDelete.name },
  );
}

export function tableObjectStoreGet(tableName, indexName, indexValue) {
  return withPromise(
    db?.transaction(tableName).objectStore(tableName).index(indexName).get(indexValue),
    { tableName, indexedDBMethod: tableObjectStoreGet.name },
  );
}

export async function tableObjectStoreMerge(tableName, item, itemKey = 'id') {
  const tableObjectStore = getTableObjectStore(tableName);
  const existed = await withPromise(
    tableObjectStore?.index(itemKey)?.get(item[itemKey]),
    { tableName, indexItemKey: itemKey }
  );
  return withPromise(
    existed ? tableObjectStore.put({ ...existed, ...item }) : tableObjectStore.add(item),
    { tableName, indexedDBMethod: tableObjectStoreMerge.name }
  );
}

export async function clearOfflineDB() {
  const objectStoreNames = db?.objectStoreNames || [];
  for (const storeName of objectStoreNames) {
    await getTableObjectStore(storeName).clear();
  }
}

export async function setTableList(tableName, resources) {
  const tableObjectStore = getTableObjectStore(tableName);
  await withPromise(
    tableObjectStore.clear(),
    { tableName, indexedDBMethod: setTableList.name },
  );
  resources.forEach((item) => {
    tableObjectStore.add(item);
  });
}

export async function mergeTableList(tableName, resources) {
  for (const resource of resources) {
    await tableObjectStoreMerge(tableName, resource);
  }
}

export async function getTableListByIndex(tableName, indexName, searchValue) {
  const resources = await tableObjectStoreGetAll(tableName);
  return resources.filter((item) => item[indexName] === searchValue);
}
