import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './PreferenceItem.module.scss';

const cn = classnames.bind(styles);

const PreferenceItem = ({ title, className, children }) => (
  <div className={cn('preference-item', { [className]: !!className })}>
    {title && (
      <>
        <div className={cn('title')}>
          {title}
        </div>
        <div className={cn('divider')} />
      </>
    )}
    {children}
  </div>
);

PreferenceItem.propTypes = {
  title: T.oneOfType([T.object, T.string]),
  className: T.string,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default PreferenceItem;
