import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserFilterSectionDropdown from '../UserFilterSectionDropdown';
import EntityTypeMenu from '../Menu/EntityTypeMenu';
// utils
import isEqual from 'lodash.isequal';
// helper
import { formatOptionsValues, getConditionTranslationsBySection } from 'utils/userFiltersHelper';
// filter constants
import { userFilterSectionTypes, listSectionsOptions } from 'utils/constants/userFilters';

class EntityTypeSection extends Component {

  constructor(props) {
    super(props);
    const { nameKey } = props;
    this.state = {
      [nameKey]: '',
      value: props.condition,
      ...formatOptionsValues(props.options)
    };
  }

  handleConditionChange = ({ target: { name } }) => {
    const { nameKey } = this.props;
    this.setState({ value: name, [nameKey]: '' });
  };

  handleTypeSelect = ({ value }) => {
    const { nameKey } = this.props;
    this.setState({ [nameKey]: value });
  };

  handleApply = () => {
    const { onOptionSelect, nameKey } = this.props;
    const { value } = this.state;
    if (['eq', 'not_eq'].includes(value)) {
      onOptionSelect(value, [{ key: nameKey, value: this.state[nameKey] }]);
      return;
    }
    onOptionSelect(value, []);
  };

  resetData = () => {
    const { condition, options, nameKey } = this.props;
    const newState = { [nameKey]: '', value: condition, ...formatOptionsValues(options) };
    const isEqualState = isEqual(newState, this.state);
    if (!isEqualState) this.setState(newState);
  };

  renderDropdownMenu = (isOpened) => {
    const { listOptions, nameKey, type } = this.props;
    const { value } = this.state;
    const typeValue = this.state[nameKey];
    const selectOptions = listSectionsOptions[type];

    return (
      <EntityTypeMenu
        isOpened={isOpened}
        currentValue={value}
        selectOptions={selectOptions}
        options={listOptions}
        onConditionChange={this.handleConditionChange}
        typeValue={typeValue}
        onInputChange={this.handleInputChange}
        onTypeSelect={this.handleTypeSelect}
        onApply={this.handleApply}
      />
    );
  };

  getLabelValue = () => {
    const upperCasedTypes = [userFilterSectionTypes.farmType];
    const { type, options, nameKey } = this.props;
    const value = formatOptionsValues(options)[nameKey];

    if (upperCasedTypes.includes(type)) {
      return value?.toUpperCase() || null;
    }

    const labelValue = listSectionsOptions[type].find((option) => option.value === value)?.label || '';

    return labelValue || null;
  }

  getLabel = () => {
    const { condition, placeholder, type, isInvalid } = this.props;
    if (isInvalid || !condition) return placeholder;
    const messageKey = getConditionTranslationsBySection(type)[condition];
    return <FormattedMessage id={messageKey} values={{ text: this.getLabelValue() }} />;
  };

  render() {
    const { condition, icon, onSectionRemove, isInitialOpened, isRemovable, isDisabled, isInvalid } = this.props;
    return (
      <UserFilterSectionDropdown
        isInitialOpened={isInitialOpened}
        isDisabled={isDisabled}
        currentValue={condition}
        triggerLabel={this.getLabel()}
        menuRenderer={this.renderDropdownMenu}
        isRemovable={isRemovable}
        onRemove={onSectionRemove}
        triggerIcon={icon}
        onClose={this.resetData}
        isInvalid={isInvalid}
      />
    );
  }
}

EntityTypeSection.propTypes = {
  condition: T.string.isRequired,
  icon: T.string.isRequired,
  listOptions: T.array.isRequired,
  isInvalid: T.bool,
  options: T.array,
  onOptionSelect: T.func,
  onSectionRemove: T.func,
  isRemovable: T.bool,
  isDisabled: T.bool,
  isInitialOpened: T.bool,
  placeholder: T.oneOfType([T.node, T.string]),
  nameKey: T.oneOf(['farm_type', 'load_type', 'status']).isRequired,
  type: T.oneOf([
    userFilterSectionTypes.farmType,
    userFilterSectionTypes.loadType,
    userFilterSectionTypes.commitmentStatus,
    userFilterSectionTypes.loadingStatus,
  ]).isRequired,
};

EntityTypeSection.defaultProps = {
  options: [],
};

export default EntityTypeSection;
