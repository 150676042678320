import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import Button from 'components/Button';
// utils
import cn from 'classnames';
// styles
import styles from './ResourcesSubNav.module.scss';

const ResourcesSubNav = ({ search, viewType, onSearchChange, onAddClick, hasActionBtn, isEmpty }, { router }) => (
  <div className={styles['resources-sub-nav']}>
    <SearchBox className={styles['files-search']} onChange={onSearchChange} initialValue={search} disabled={isEmpty} />
    <div className={styles['actions-section']}>
      {!isEmpty && (
        <div className={styles['view-type-switcher']}>
          <i
            className={cn('fa fa-grid-two-up', styles['view-type-icon'], { [styles.active]: viewType === 'block' })}
            onClick={() => router.push('/tenant-assets?view_type=block')}
          />
          <i
            className={cn('fa fa-ulist', styles['view-type-icon'], { [styles.active]: viewType !== 'block' })}
            onClick={() => router.push('/tenant-assets')}
          />
        </div>
      )}
      {hasActionBtn && (
        <Button className={styles['add-btn']} primary small onClick={onAddClick}>
          <i className={cn('fa fa-plus', styles['plus-icon'])} />
          <FormattedMessage id="general.button.add">
            {(text) => <span className={styles['add-btn-label']}>{text}</span>}
          </FormattedMessage>
        </Button>
      )}
    </div>
  </div>
);

ResourcesSubNav.contextTypes = {
  router: T.object.isRequired,
};

ResourcesSubNav.propTypes = {
  search: T.string.isRequired,
  viewType: T.string,
  onSearchChange: T.func.isRequired,
  onAddClick: T.func.isRequired,
  hasActionBtn: T.bool,
  isEmpty: T.bool,
};

export default ResourcesSubNav;
