import React from 'react';
import { connect } from 'react-redux';
// components
import { formValueSelector, change, Field, reduxForm } from 'redux-form';
import FormField from 'components/ReduxFormFields/FormField/FormField';
import PhoneInputField from 'components/ReduxFormFields/PhoneInputField/PhoneInputField';
import UserProfilePQADate from 'components/UserProfile/UserProfilePQADate';
import Scroll from 'react-scroll';
import UserFieldsMethods from 'components/UserFieldsMethods/UserFieldsMethods';
import Button from 'components/Button';
import BirthdateField from 'components/ReduxFormFields/BirhdateField/BirhdateField';
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import isEmpty from 'lodash.isempty';
// styles
import './UserProfileEditForm.scss';

const scroll = Scroll.animateScroll;

class UserProfileEditForm extends UserFieldsMethods {

  state = {
    disabled: false,
  };

  componentDidMount() {
    if (!isEmpty(this.props.hash)) {
      scroll.scrollToBottom({
        containerId: 'bodybag',
      });
    }
  }

  validateBirthdate = (birthDate) => {
    if (!birthDate) {
      this.setState({ disabled: false });
      return undefined;
    }
    const reg = /^((0|1)\d{1})\/((0|1|2)\d{1})\/((19|20)\d{2})/;
    const momentBirthdate = moment(birthDate, 'MM/DD/YYYY');
    const disabled = !(reg.test(birthDate) && momentBirthdate.isBefore() &&
      momentBirthdate.format('MM/DD/YYYY') === birthDate);
    this.setState({ disabled });

    return disabled ? 'Invalid birth date' : undefined;
  };

  isReadOnlyField = (fieldName) => {
    const { readOnly, alwaysEditableFields = [] } = this.props;
    return readOnly && !alwaysEditableFields.includes(fieldName);
  };

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    const { disabled } = this.state;
    return (
      <form className="clearfix clear UserProfileEditForm" onSubmit={handleSubmit}>
        <div className="row">
          <div className="small-12 column">
            <label>
              <FormattedMessage id="general.typeEmail">
                {(placeholder) => (
                  <Field
                    name="email"
                    component={FormField}
                    type="email"
                    placeholder={placeholder}
                    label={<FormattedMessage id="general.emailAddress" />}
                    readOnly={this.isReadOnlyField('email')}
                  />
                )}
              </FormattedMessage>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-6 column">
            <label>
              <FormattedMessage id="component.userProfile.firstName">
                {(label) => (
                  <Field
                    name="first_name"
                    component={FormField}
                    onChange={this.handleNamesChange}
                    type="text"
                    readOnly={this.isReadOnlyField('first_name')}
                    placeholder={label}
                    label={label}
                  />
                )}
              </FormattedMessage>
            </label>
          </div>
          <div className="small-12 medium-6 column">
            <label>
              <FormattedMessage id="component.userProfile.lastName">
                {(label) => (
                  <Field
                    name="last_name"
                    component={FormField}
                    onChange={this.handleNamesChange}
                    type="text"
                    readOnly={this.isReadOnlyField('last_name')}
                    placeholder={label}
                    label={label}
                  />
                )}
              </FormattedMessage>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 column">
            <label>
              <FormattedMessage id="component.userProfile.userName">
                {(label) => (
                  <Field
                    label={label}
                    name="nickname"
                    component={FormField}
                    onChange={this.handleNicknameChange}
                    icon={<i className="nickname-icon fa fa-at" />}
                    iconLeft
                    type="text"
                    placeholder={label}
                    readOnly={this.isReadOnlyField('nickname')}
                  />
                )}
              </FormattedMessage>
              <FormattedMessage id="component.userProfile.userName.description">
                {(desc) => <i className="fs-12 field-description">{desc}</i>}
              </FormattedMessage>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-6 column">
            <Field
              label={<FormattedMessage id="component.userProfile.phone" />}
              name="telephone"
              component={PhoneInputField}
              readOnly={this.isReadOnlyField('telephone')}
            />
          </div>
          <div className="small-12 column">
            <FormattedMessage id="component.userProfile.phoneDescription">
              {(desc) => <i className="fs-12 field-description">{desc}</i>}
            </FormattedMessage>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="small-12 medium-6 column">
            {this.isReadOnlyField('birthday_date')
              ? (
                <Field
                  name="birthday_date"
                  component={FormField}
                  type="text"
                  placeholder="MM/DD/YYYY"
                  label={<FormattedMessage id="component.userProfile.birthdate" />}
                  readOnly
                />
              )
              : (
                <Field
                  label={<FormattedMessage id="component.userProfile.birthdate" />}
                  name="birthday_date"
                  component={BirthdateField}
                  validate={this.validateBirthdate}
                />
              )}
          </div>
          <div className="small-12 column">
            <i className="fs-12 field-description manage-link">
              <FormattedMessage
                id="component.userProfile.birthdateDescription"
                values={{ settingLink: null }}
              />
            </i>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="small-12 medium-6 column">
            <label>
              <FormattedMessage id="component.userProfile.pqaNumber.placeholder">
                {(placeholder) => (
                  <Field
                    pattern="[0-9]*"
                    name="pqa_number"
                    component={FormField}
                    type="text"
                    label={<FormattedMessage id="component.userProfile.pqaNumber" />}
                    readOnly={this.isReadOnlyField('pqa_number')}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </label>
          </div>
          <div className="small-12 column">
            <UserProfilePQADate fieldProps={{ readOnly: this.isReadOnlyField('pqa_date') }} />
          </div>
        </div>
        <div className="margin-top-2">
          <Button primary disabled={pristine || submitting || disabled} className="mobile-full-width">
            <FormattedMessage id="general.button.saveChanges" />
          </Button>
        </div>
      </form>
    );
  }
}
const selector = formValueSelector('user-profile-edit-form');
const formComponent = reduxForm({
  form: 'user-profile-edit-form',
})(UserProfileEditForm);

export default connect((state) => ({
  first_name: selector(state, 'first_name'),
  last_name: selector(state, 'last_name'),
  nickname: selector(state, 'nickname'),
}), { change })(formComponent);
