import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import CheckboxMenu from './CheckboxMenu';
import Input from 'components/Input';
// utils
import classnames from 'classnames/bind';
import pick from 'lodash.pick';
// filter constants
import { checkboxProps, sectionInputPlaceholderIds, userFilterSectionTypes } from 'utils/constants/userFilters';
// styles
import styles from './Menu.module.scss';

const cn = classnames.bind(styles);

const CommonMenu = ({ currentValue, onInputChange, inputValue, type, ...others }) => (
  <CheckboxMenu {...pick(others, checkboxProps)} currentValue={currentValue}>
    {(value) => (
      (value === currentValue) && (value !== 'any') && (
        <div className={cn('value-input-line')}>
          <FormattedMessage id={sectionInputPlaceholderIds[type]}>
            {(placeholder) => (
              <Input
                placeholder={placeholder}
                className={cn('value-input', 'longer')}
                value={inputValue}
                onChange={onInputChange}
              />
            )}
          </FormattedMessage>
        </div>
      )
    )}
  </CheckboxMenu>
);

CommonMenu.propTypes = {
  type: T.oneOf([
    userFilterSectionTypes.farmExternalLinkId,
    userFilterSectionTypes.farmFederalPremiseId,
    userFilterSectionTypes.farmLocation
  ]).isRequired,
  isOpened: T.bool.isRequired,
  currentValue: T.string,
  options: T.array.isRequired,
  onConditionChange: T.func.isRequired,
  onApply: T.func.isRequired,
  onInputChange: T.func.isRequired,
  inputValue: T.oneOfType([T.number, T.string]),
};

export default CommonMenu;
