import React from 'react';
import T from 'prop-types';
// utils
import renderIf from 'utils/renderIf';
import { capitalize } from 'utils';
import classnames from 'classnames/bind';
// translations
import { FormattedMessage } from 'react-intl';
// assets
import styles from './GroupSourceTypeLabel.module.scss';

const cn = classnames.bind(styles);

const GroupSourceTypeLabel = ({
  sourceType,
  className,
  ...rest
}) => (
  renderIf(sourceType)(
    <FormattedMessage id={`general.sourceTypes.${sourceType}`}>
      {(text) => (<span className={cn('source-type', className)} {...rest}>{capitalize(text)}</span>)}
    </FormattedMessage>
  )
);

GroupSourceTypeLabel.defaultProps = {
  className: '',
};

GroupSourceTypeLabel.propTypes = {
  sourceType: T.string,
  className: T.string,
};

export default GroupSourceTypeLabel;
