import { handleActions, createAction } from 'redux-actions';
import {
  getDestination,
  updateDestination as updateExternalDestination
} from 'endpoints/destinations';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: {},
  isLoading: false,
};

// ------------------------------------
// Action Types
// ------------------------------------

const FETCH_DESTINATION = 'destinations/FETCH_DESTINATION';
const [FETCH_DESTINATION_PENDING, FETCH_DESTINATION_FULFILLED, FETCH_DESTINATION_REJECTED] = [
  `${FETCH_DESTINATION}_PENDING`,
  `${FETCH_DESTINATION}_FULFILLED`,
  `${FETCH_DESTINATION}_REJECTED`,
];

const UPDATE_DESTINATION = 'destinations/UPDATE_DESTINATION';
const [UPDATE_DESTINATION_PENDING, UPDATE_DESTINATION_FULFILLED, UPDATE_DESTINATION_REJECTED] = [
  `${UPDATE_DESTINATION}_PENDING`,
  `${UPDATE_DESTINATION}_FULFILLED`,
  `${UPDATE_DESTINATION}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------

export const fetchDestination = (id) => createAction(FETCH_DESTINATION)(getDestination(id));

export const updateDestination = (id, data) => createAction(UPDATE_DESTINATION)(updateExternalDestination(id, data));

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_DESTINATION
  [FETCH_DESTINATION_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_DESTINATION_FULFILLED]: (state, action) => ({
    resource: action.payload,
    isLoading: false,
  }),
  [FETCH_DESTINATION_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  // UPDATE_DESTINATION
  [UPDATE_DESTINATION_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [UPDATE_DESTINATION_FULFILLED]: (state, action) => ({
    resource: action.payload,
    isLoading: false,
  }),
  [UPDATE_DESTINATION_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
