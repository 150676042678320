import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { fetchUserProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import Button from 'components/Button';
import Panel from 'components/Panel';
import EditAdminForm from './components/EditAdminForm';
import UserProfileHeader from 'components/ProfileHeader/UserProfileHeader/UserProfileHeader';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

class MyCompanyAdminEdit extends Component {

  componentDidMount() {
    const { fetchUserProfile, params: { id } } = this.props;
    fetchUserProfile(`/admin/users/${id}/admin_roles`).catch(toastResponseErrors);
  }

  getInitialValues = () => {
    const { params, adminUser } = this.props;

    if (adminUser.id === Number(params.id)) {
      let user_role;
      if (adminUser.is_tenant_owner) user_role = 'tenant_owner';
      if (adminUser.is_tenant_admin) user_role = 'tenant_admin';
      return { user_role };
    }
    return undefined;
  };

  handleFormSubmit = (data) => {
    const { openModal, adminUser, authUser: { current_company } } = this.props;
    if (data.user_role !== 'tenant_owner') return this.changeRole(data);
    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.transferOwner.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.transferOwner.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.transferOwner.warning" />}
        handleConfirm={() => this.changeRole(data)}
      >
        <FormattedMessage
          id="component.modal.transferOwner.text"
          tagName="p"
          values={{ companyName: <b>{current_company.name}</b>, userName: <b>{adminUser.full_name}</b> }}
        />
      </ConfirmationModal>
    );
  };

  changeRole = (data) => {
    const { router } = this.context;
    const { adminUser, authUser: { current_company } } = this.props;
    const reqParams = { method: 'POST', body: JSON.stringify({ email: adminUser.email, name: data.user_role }) };
    return fetchFromAPI(`/admin/companies/${current_company.id}/admin_roles`, reqParams)
      .then(() => {
        showToastrMessage('component.toastr.role.changed');
        router.push('/admin/my-company/admins');
      })
      .catch(toastResponseErrors);
  };

  revokeRole = () => {
    const { router } = this.context;
    const { adminUser: { id, is_tenant_owner, is_tenant_admin }, authUser: { current_company } } = this.props;
    let name;
    if (is_tenant_owner) name = 'tenant_owner';
    if (is_tenant_admin) name = 'tenant_admin';

    return fetchFromAPI(`/admin/companies/${current_company.id}/admin_roles`, {
      method: 'delete', body: JSON.stringify({ id, name }),
    })
      .then(() => {
        showToastrMessage('component.toastr.role.revoked');
        router.push('/admin/my-company/admins');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { adminUser, isLoading, params } = this.props;
    const initialValues = this.getInitialValues();

    return (
      <div className="MyCompanyAdminEdit">
        <UserProfileHeader actualUserId={Number(params.id)} user={adminUser} isLoading={isLoading}>
          <Button light wide onClick={this.revokeRole}>
            <i className="fa fa-times-circle mr-5" />
            <FormattedMessage id="general.button.revokeAdminRole" />
          </Button>
        </UserProfileHeader>

        <div className="small-12 column">
          <Link to="/admin/my-company/admins" className="back-to color-primary">
            <i className="fa fa-angle-left-btb" />
            <FormattedMessage id="general.backToMyCompany" />
          </Link>
        </div>

        <section className="small-12 column mt-15">
          <Panel className="panel">
            <Panel.Heading title={<FormattedMessage id="general.editAdmin" />} />
            <Panel.Body>
              <div className="row">
                <div className="small-12 medium-10 large-8 column">
                  <EditAdminForm onSubmit={this.handleFormSubmit} initialValues={initialValues} />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

MyCompanyAdminEdit.propTypes = {
  openModal: T.func.isRequired,
  fetchUserProfile: T.func.isRequired,
  adminUser: T.object,
  authUser: T.object,
  params: T.object,
  isLoading: T.bool,
};

MyCompanyAdminEdit.contextTypes = {
  router: T.object,
};

export default connect(
  (state) => ({
    authUser: state.auth.user,
    adminUser: state.profiles.user.data,
    isLoading: state.profiles.user.isLoading,
  }), {
    openModal,
    fetchUserProfile,
  }
)(MyCompanyAdminEdit);
