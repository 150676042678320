import React from 'react';
import T from 'prop-types';
// components
import MaskedInput from 'react-maskedinput';
// utils
import classnames from 'classnames/bind';
import moment from 'moment/moment';
// styles
import styles from './DateInput.module.scss';

const cn = classnames.bind(styles);

const DateInput = (
  { onChange, value, className, onClear, onMobileChange, max, mask, placeholder, icon, clearable }, { isMobile }
) => {
  const handleChange = (e) => {
    const date = e.target.value ? moment(e.target.value).format('MM/DD/YYYY') : '';
    onMobileChange(date);
  };

  return (
    <div className={cn('DateInput', className)}>
      {isMobile ? (
        <div className={cn('birthdate-field', { selected: value })}>
          <input
            className={cn('mobile-input')}
            placeholder={placeholder}
            value={value}
            max={max}
            readOnly
            type="text"
          />
          {value && clearable && (
            <span onTouchEnd={(e) => { e.preventDefault(); onClear(); }} className={cn('clear-date')}>
              <i className="fa fa-times" />
            </span>
          )}
          <input
            className={cn('mobileDateInput')}
            max={moment().format('YYYY-MM-DD')}
            onChange={handleChange}
            type="date"
          />
        </div>
      ) : (
        <div className={cn('birthdate-field')}>
          <div className={cn('masked-input', { 'labeled': icon })}>
            <MaskedInput
              mask={mask}
              name="birthdate"
              placeholder={placeholder}
              onChange={onChange}
              value={value}
            />
            {value && clearable && (
              <span onClick={onClear} className={cn('clear-date')}>
                <i className="fa fa-times" />
              </span>
            )}
          </div>

          {icon && (<div className={cn('icon-label')}><i className="fa fa-calendar" /></div>)}
        </div>
      )}
    </div>
  );
};

DateInput.contextTypes = {
  isMobile: T.bool,
};

DateInput.propTypes = {
  value: T.string,
  max: T.string,
  onChange: T.func,
  onClear: T.func,
  onMobileChange: T.func,
  mask: T.string,
  placeholder: T.string,
  icon: T.bool,
  clearable: T.bool,
  className: T.string,
};

DateInput.defaultProps = {
  placeholder: 'MM/DD/YYYY',
  mask: '11/11/1111',
};

export default DateInput;
