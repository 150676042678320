import React, { Component } from 'react';
import T from 'prop-types';
// components
import withOutsideClick from 'react-onclickoutside';
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import Input from 'components/Input';
import CountryPickerMobile from '../CountryPickerMobile/CountryPickerMobile';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { openPortal } from 'reducers/portal';
import { fetchCountryPhonesData } from 'reducers/staticData';
// utils
import cn from 'classnames';
import pullAll from 'lodash.pullall';
import compact from 'lodash.compact';
// styles
import './PhoneInput.scss';
import 'flag-icon-css/css/flag-icon.css';

class PhoneInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: 'us',
      selectedDialCode: '+1',
      value: '',
      preffered: props.prefferedCountries || ['us', 'es', 'pt'],
      countryPhonesData: [],
      isListOpen: false,
      search: '',
      isDropdownAbove: false,
      limit: props.limit,
    };
  }

  componentDidMount() {
    const { isLoaded, fetchCountryPhonesData, countryPhonesData, value } = this.props;
    if (!isLoaded) {
      fetchCountryPhonesData().then(() => {
        if (value) this.processInitialValueData(value);
        this.processCountryData(this.props.countryPhonesData);
      });
    } else {
      this.processCountryData(countryPhonesData);
      if (value) this.processInitialValueData(value);
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (!prevProps.value && (prevProps.value !== value)) {
      this.processInitialValueData(value);
    }
  }

  processCountryData = (data, search) => {
    const { preffered } = this.state;
    const prefferedList = compact(preffered.map((countryCode) => data.find(
      (country) => country.country_code.toLowerCase() === countryCode
    )));
    const otherList = pullAll([...data], prefferedList);
    if (prefferedList.length && otherList.length) {
      otherList[0].divider = true;
    }
    if (!prefferedList.length && otherList.length) {
      otherList[0].divider = false;
    }
    this.setState({
      countryPhonesData: [
        ...prefferedList,
        ...otherList,
      ],
      search,
    });
  };

  handleScroll = ({ target }) => {
    const { scrollHeight, scrollTop, clientHeight } = target;
    if (scrollHeight - scrollTop === clientHeight) {
      this.setState(({ limit }) => ({
        limit: limit + this.props.limit,
      }));
    }
  };

  processInitialValueData = (value) => {
    const { countryPhonesData } = this.props;
    const countryCode = value.countryCode.toUpperCase();
    const selectedCountry = countryPhonesData.find(({ country_code }) => country_code === countryCode);
    if (!selectedCountry) return;
    this.setState({
      value: value.phone || '',
      selectedCountry: value.countryCode,
      selectedDialCode: selectedCountry.dialling_code,
    });
  };

  toggleList = () => {
    const { isMobile, isTablet } = this.context;
    const { openPortal } = this.props;
    const { countryPhonesData, selectedCountry } = this.state;
    if (isMobile && !isTablet) {
      openPortal(
        <CountryPickerMobile
          title={<FormattedMessage id="general.pageTitle.selectCountry" />}
          countries={countryPhonesData}
          limit={15}
          selectedCountry={selectedCountry}
          onSelect={this.handleSelect}
        />
      );
    }
    this.calcDropdownPosition();
    this.setState((prevState) => ({ isListOpen: !prevState.isListOpen }));
  };

  calcDropdownPosition = () => {
    const { isListOpen, isDropdownAbove } = this.state;
    if (!isListOpen && !isDropdownAbove) {
      const dropdownBoxBottom = this.dropdownBox.getBoundingClientRect().bottom;
      const windowHeight = window.screen.height;
      this.setState({
        isDropdownAbove: dropdownBoxBottom > windowHeight,
      });
    }
  };

  handleClickOutside = () => {
    if (this.state.isListOpen) {
      this.setState({
        isListOpen: false,
      });
    }
  };

  handleSelect = (data) => () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange({ phone: this.state.value, countryCode: data.country_code });
    }
    this.setState({
      selectedCountry: data.country_code,
      selectedDialCode: data.dialling_code,
      isListOpen: false,
    });
  };

  handleSearch = (value) => {
    const { countryPhonesData } = this.props;
    const formattedValue = value.toLowerCase();
    const searchedData = countryPhonesData.filter(
      (data) => data.country_name.toLowerCase().includes(formattedValue)
    );
    this.processCountryData(searchedData, formattedValue);
  };

  handlePhoneChange = (e) => {
    if (!e.target.validity.valid) return;
    const { onChange } = this.props;
    const { selectedCountry } = this.state;
    if (onChange) {
      onChange({ phone: e.target.value, countryCode: selectedCountry });
    }
    this.setState({
      value: e.target.value,
    });
  };

  isSelected = ({ country_code }) => (country_code === this.state.selectedCountry);

  render() {
    const {
      selectedCountry, isListOpen, countryPhonesData, selectedDialCode, search, isDropdownAbove, limit
    } = this.state;
    const { className, readOnly, autoFocus } = this.props;
    const { isMobile, isTablet, isIOS } = this.context;
    const selected = selectedCountry.toLowerCase();
    const arrowClass = isMobile && !isTablet ? 'fa fa-angle-right-btb orange' : 'fa fa-angle-down-btb';

    return (
      <div className={cn('CountryPhoneInput', className, { 'disabled': readOnly })}>
        <div className="country-select">
          <div onClick={this.toggleList} className="selected-country">
            <div className={`flag-icon flag-icon-${selected}`} />
            <i className={cn('country-arrow', arrowClass)} />
          </div>
          <div className={cn('dialling-code', { isIOS })}>{selectedDialCode}</div>
          <Input
            autoFocus={autoFocus}
            value={this.state.value}
            onChange={this.handlePhoneChange}
            className="phone-input"
            type="text"
            name="phone"
            pattern="[0-9]*"
            maxLength={11}
            readOnly={readOnly}
          />
        </div>
        <div
          ref={(ref) => { this.dropdownBox = ref; }}
          onScroll={this.handleScroll}
          className={cn('countries-data country-list', { 'open': isListOpen }, { 'above': isDropdownAbove })}
        >
          <div className="country-search">
            <SearchBox initialValue={search} className="search-input" onChange={this.handleSearch} />
          </div>
          {countryPhonesData
            .slice(0, limit)
            .map((country, index) => (
              <div
                key={index}
                className={cn('country', { 'selected': this.isSelected(country) })}
                onClick={this.handleSelect(country)}
              >
                <div className={`flag-icon flag-icon-${country.country_code.toLowerCase()}`} />
                <div className="country-name">{country.country_name}</div>
                <div className="country-code">{country.dialling_code}</div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

PhoneInput.propTypes = {
  prefferedCountries: T.array,
  onChange: T.func,
  openPortal: T.func,
  value: T.oneOfType([T.string, T.object]),
  className: T.string,
  readOnly: T.bool,
  autoFocus: T.bool,
  countryPhonesData: T.array.isRequired,
  isLoaded: T.bool.isRequired,
  fetchCountryPhonesData: T.func.isRequired,
  limit: T.number,
};

PhoneInput.defaultProps = {
  limit: 20,
};

PhoneInput.contextTypes = {
  isMobile: T.bool,
  isTablet: T.bool,
  isIOS: T.bool,
};

const enhance = compose(
  connect(
    (state) => ({
      countryPhonesData: state.staticData.countryPhonesData.resources,
      isLoaded: state.staticData.countryPhonesData.isLoaded,
    }), {
      openPortal,
      fetchCountryPhonesData,
    }
  ),
  withOutsideClick,
);

export default enhance(PhoneInput);
