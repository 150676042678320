import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Dropdown from 'components/Dropdown';
// utils
import cn from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
// styles
import './AnalyticsDropdown.scss';
// constants
import { rangeOptions } from 'constants.js';

class AnalyticsDropdown extends Component {

  state = {
    showAllOptions: false,
  };

  showAllOptions = (e) => {
    e.stopPropagation();
    this.setState({ showAllOptions: true });
  };

  render() {
    const { closeOnClick, getRangeData, rangeType, options, symptom_id } = this.props;
    const { showAllOptions } = this.state;
    const onlyMobile = isMobile && !isTablet;
    const defaultOptions = showAllOptions ? options : options.slice(0, 4);
    const trigger = (
      <div className="dropdown-trigger">
        <FormattedMessage id={`component.analyticsPanel.dropDownRangeLabel.${rangeType}`} />
        <i className="fa fa-angle-down" />
      </div>
    );

    return (
      <div className="AnalyticsDropdown">
        <Dropdown
          closeOnClick={closeOnClick}
          withBackdrop={onlyMobile}
          trigger={trigger}
          className={cn({ 'responsive-dropdown-container': false })}
          dropdownClassName={cn({ 'responsive-dropdown': onlyMobile })}
        >
          <ul className="dropdown-menu">
            {defaultOptions.map((option, index) => (
              <li
                key={index}
                className={cn({ 'active-option': rangeType === option })}
                onClick={() => getRangeData(option, symptom_id)}
              >
                <span className="option-label">
                  <FormattedMessage id={`component.analyticsPanel.dropDownRangeLabel.${option}`} />
                </span>
                {(rangeType === option) && (
                  <i className="fa fa-check semibold" />
                )}
              </li>
            ))}
            {(defaultOptions.length !== options.length) && (
              <li onClick={this.showAllOptions}>
                <span className="view-more-options">
                  <FormattedMessage id="component.analyticsPanel.dropdown.viewMore" />
                </span>
                <i className="fa fa-angle-down" />
              </li>
            )}
          </ul>
        </Dropdown>
      </div>
    );
  }
}

AnalyticsDropdown.propTypes = {
  closeOnClick: T.bool,
  symptom_id: T.number,
  rangeType: T.string.isRequired,
  getRangeData: T.func,
  options: T.array,
};

AnalyticsDropdown.defaultProps = {
  closeOnClick: true,
  options: rangeOptions,
};

export default AnalyticsDropdown;
