import { fetchFromAPI } from 'utils/api';

export function fetchBarnSheetsFarms(params) {
  return fetchFromAPI('/barnsheets/farms', { params }).catch((errors) => {
    throw errors;
  });
}

export function applyBarnSheetsFarmsFilter(params) {
  return fetchFromAPI('/barnsheets/farms/filter', { method: 'post', body: JSON.stringify(params) })
    .catch((errors) => {
      throw errors;
    });
}

export function fetchBarnSheetsFarmDiagnoses(farmId, params) {
  return fetchFromAPI(`/barnsheets/farms/${farmId}/diagnoses`, { params }).catch((errors) => {
    throw errors;
  });
}
