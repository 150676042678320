import React from 'react';
import T from 'prop-types';
// utils
import renderIf from 'utils/renderIf';
import cn from 'classnames';
// assets
import './FarmTypeLabel.scss';

const FarmTypeLabel = ({
  farmType,
  small,
  light,
  className,
  ...rest
}) => (
  renderIf(farmType)(
    <span className={cn('FarmTypeLabel', className, { small, light })} {...rest}>
      {farmType}
    </span>
  )
);

FarmTypeLabel.defaultProps = {
  className: '',
};

FarmTypeLabel.propTypes = {
  farmType: T.string,
  small: T.bool,
  light: T.bool,
  className: T.string,
};

export default FarmTypeLabel;
