import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import DateRangePicker from 'components/DatePicker/DateRangePicker';
import StatisticsDropdown from './StatisticsDropdown';
// utils
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames/bind';
// styles
import styles from './StatisticsPanel.module.scss';

const cn = classnames.bind(styles);

const customRangeOptionValue = 'custom_period';

const StatisticsFilterLine = ({ currentOption, onOptionChange, options  }) => {
  const [range, setRange] = useState({
    startDate: null,
    endDate: null,
  });

  const handleRangeChange = ({ startDate, endDate }) => {
    setRange({ startDate, endDate });

    if (startDate && endDate) {
      onOptionChange(currentOption, {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      });
    }
  };

  useEffect(() => {
    // if we've chosen not 'custom_period' option but there is date range - we are nulling it
    if (currentOption !== 'custom_period' && (range.startDate || range.endDate)) {
      setRange({ startDate: null, endDate: null });
    }
  }, [currentOption]);

  const trigger = (
    <div className={cn('trigger')}>
      {options.find((option) => (option.value === currentOption)).mobileLabel}
      <div className={cn('dots')}>
        {options.map((option, index) => (
          <div key={index} className={cn('dot', { 'active': option.value === currentOption })} />
        ))}
      </div>
    </div>
  );

  const dropdownProps = { currentOption, onOptionChange, options };

  return (
    <div className={cn('statistics-filter-line', { mobile: isMobile })}>
      <div className={cn({ 'mobile-dropdown': isMobile })}>
        <StatisticsDropdown {...dropdownProps} trigger={isMobile ? trigger : null} />
      </div>
      {currentOption === customRangeOptionValue && (
        <div className={cn('date-range')}>
          <DateRangePicker
            isOutsideRange={(day) => (day.isAfter(moment(), 'day'))}
            startDate={range.startDate}
            endDate={range.endDate}
            onDatesChange={handleRangeChange}
          />
        </div>
      )}
    </div>
  );
};

StatisticsFilterLine.propTypes = {
  currentOption: T.string,
  onOptionChange: T.func,
  options: T.array.isRequired,
};

export default StatisticsFilterLine;
