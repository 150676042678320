import React from 'react';
import T from 'prop-types';
import cn from 'classnames';
import './MobileRow.scss';

const MobileMovementRow = ({ label, description, onClick, icon, isSelected, isHighlighted }) => (
  <div
    className={cn('MobileRow', { 'highlighted': isHighlighted })}
    onClick={onClick}
  >
    <div className="movement-type-icon">
      {icon && (
        <i className={`fa fa-${icon}`} />
      )}
    </div>
    <div className="movement-info">
      <span>{label}</span>
      {description && (
        <span className="movement-description">
          ({description})
        </span>
      )}
    </div>

    <div className={cn('icon', { 'selected': isSelected })}>
      <i className="checked fa fa-check-circle-bts" />
      <i className="unchecked fa fa-radio-unchecked" />
    </div>

  </div>
);

MobileMovementRow.propTypes = {
  label: T.oneOfType([T.string, T.node]).isRequired,
  description: T.oneOfType([T.string, T.node]),
  icon: T.string.isRequired,
  onClick: T.func.isRequired,
  isSelected: T.bool.isRequired,
  isHighlighted: T.bool.isRequired,
};

export default MobileMovementRow;
