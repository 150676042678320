import React from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
// components
import { FormattedMessage } from 'react-intl';
import { reduxForm, formValueSelector } from 'redux-form';
import CompositeAddressField from 'components/ReduxFormFields/CompositeAddressField/CompositeAddressField';
import Button from 'components/Button';
import Field from 'components/Field';
// styles
import './EditCompanyForm.scss';

const EditCompanyForm = ({ handleSubmit, pristine, submitting, countryCode }) => (
  <form onSubmit={handleSubmit} className="clearfix clear EditCompanyForm">
    <div className="row">
      <div className="small-12 medium-10 large-8 column">
        <div className="row">
          <div className="small-12 column">
            <label>
              <Field
                placeholderKey="general.typeAName"
                label={<FormattedMessage id="general.companyName" />}
                name="name"
              />
            </label>
          </div>
        </div>

        {countryCode &&
          <CompositeAddressField countryCode={countryCode} />}

        {/* Button */}
        <div className="row mt-30">
          <div className="small-12 column">
            <Button primary className="mobile-full-width" disabled={pristine || submitting}>
              <FormattedMessage id="general.button.saveChanges" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  </form>
);

EditCompanyForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  countryCode: T.string,
};

const selector = formValueSelector('create-company-by-admin');

const enhance = compose(
  reduxForm({
    form: 'create-company-by-admin',
  }),
  connect((state) => ({
    countryCode: selector(state, 'country_code'),
  })),
);

export default enhance(EditCompanyForm);
