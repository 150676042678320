import React, { Component } from 'react';
import T from 'prop-types';
// components
import { DayPickerSingleDateController as DayPicker } from 'react-dates';
import { VERTICAL_ORIENTATION } from 'react-dates/constants';
import DatePickerMobileBox from 'components/DatePicker/DatePickerMobileBox';
// utils
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
// styles
import './SingleDatePickerMobile.scss';
import '../styles/DatePickerMobile.scss';

const dateLabel = <FormattedMessage id="component.dateRangePicker.pickDate" />;

class SingleDatePickerMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: props.initialDate,
    };
  }

  onDateChange = (date) => {
    this.setState({ date });
  };

  clearDate = () => {
    this.setState({ date: null });
  };

  submitDate = () => {
    const { onChange, closePortal } = this.props;
    const { date } = this.state;
    onChange(date);
    closePortal();
  };

  renderHeaderDates = () => {
    const { date } = this.state;
    return (
      <div className="header-dates">
        <div className="selected-date">
          <div>{date ? date.format('dddd') : ''}</div>
          <div>{date ? date.format('MMMM D, YYYY') : dateLabel}</div>
        </div>
      </div>
    );
  };

  render() {
    const { className, isOutsideRange, closePortal } = this.props;
    const { date } = this.state;

    return (
      <DatePickerMobileBox
        className={cn('SingleDatePickerMobile DatePickerMobile', { [className]: !!className })}
        onClose={closePortal}
        onClearDates={this.clearDate}
        onSubmit={this.submitDate}
        isSubmitBtnDisabled={() => (!date)}
        headerDatesRenderer={this.renderHeaderDates}
      >
        <DayPicker
          date={date}
          onDateChange={this.onDateChange}
          orientation={VERTICAL_ORIENTATION}
          isOutsideRange={isOutsideRange}
          numberOfMonths={2}
          daySize={42}
        />
      </DatePickerMobileBox>
    );
  }
}

SingleDatePickerMobile.propTypes = {
  className: T.string,
  initialDate: T.object,
  onChange: T.func,
  closePortal: T.func,
  isOutsideRange: T.func,
};

export default SingleDatePickerMobile;
