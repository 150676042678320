/* eslint-disable react/prop-types */
import React from 'react';
//  components
import { FormattedMessage } from 'react-intl';
import TimeAgo from '../TimeAgo/TimeAgo';
//  utils
import styles from 'components/EntityFarmfeedTooltip/FarmfeedTooltip.module.scss';

// data for tooltips; tooltip types: farm, company, user, treatment

export const userData = {
  headerData: [
    { key: 'nickname', label: (data) => <div className={styles.nickname}>{`@${data}`}</div> },
    { key: '', // if key is empty then data equal to all user data
      label: ({ farm_role_names = [], company_role_names = [], is_admin }) => {

        if (is_admin) {
          return (
            <span>
              <FormattedMessage id="general.role" /> &bull; <FormattedMessage id="general.role.admin" />
            </span>
          );
        }

        const transformedRoles = [...new Set([
          ...(farm_role_names || []).map((role) => (role === 'owner' ? 'farmOwner' : role)),
          ...(company_role_names || []).map((role) => (role === 'owner' ? 'primary' : role))
        ])];

        if (!transformedRoles.length) {
          return null;
        }

        const rolesEntity = transformedRoles.map((role, idx) => (idx === transformedRoles.length - 1 ?
          <FormattedMessage key={role} id={`general.role.${role}`} /> :
          <span key={role}><FormattedMessage id={`general.role.${role}`} />,&nbsp;</span>));

        return <span><FormattedMessage id="general.role" />&nbsp;&bull;&nbsp;{rolesEntity}</span>;

      }
    },
  ],
  dataLines: [
    { key: 'sign_in_count', label: <FormattedMessage id="general.logins" /> },
  ]
};

export const treatmentData = {
  headerData: [
    { key: 'route',
      label: (data) => (
        <FormattedMessage
          id="general.productTypes"
          values={{ typesList: <span className={styles.types}>{(data || []).join(', ') }</span> }}
        />
      )
    }
  ],
  dataLines: [
    { key: 'withdrawal_value_us',
      label: <FormattedMessage id="general.wdPeriod_us" />,
      getValueUnit: (value) => <FormattedMessage id="general.daysCount" values={{ count: value }} />
    },
    { key: 'withdrawal_value_export',
      label: <FormattedMessage id="general.wdPeriod_export" />,
      getValueUnit: (value) => <FormattedMessage id="general.daysCount" values={{ count: value }} />
    }
  ]
};

export const farmData = {
  headerData: [
    { key: 'federal_premise_id',
      label: (data) => <span><FormattedMessage id="general.id" /> &bull; {data || 'N/A'}</span> },
    { key: 'last_checked_at',
      label: (data) => (
        <span>
          <FormattedMessage id="general.time.lastActivity" />&nbsp;&bull;&nbsp;<TimeAgo date={data} />
        </span>
      )
    }
  ],
  dataLines: [
    { key: 'progress',
      label: <FormattedMessage id="component.tooltip.farmCompliance" />,
      getValueUnit: (value) => `${value}%`,
    },
    { key: 'check_ins_count', label: <FormattedMessage id="component.tooltip.checkins" /> },
  ],
};

export const companyData = {
  headerData: [],
  dataLines: [
    { key: 'farm_roles_count', label: <FormattedMessage id="general.farmRoles" /> },
    { key: 'user_roles_count', label: <FormattedMessage id="general.userRoles" /> }
  ],
};
