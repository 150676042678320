import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import SVRComment from 'components/SVRComment';
// store
import { deleteComment, updateComment } from 'reducers/siteVisitReport';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './QuestionComments.module.scss';

const cn = classnames.bind(styles);

const QuestionComments = ({ question, deleteComment, updateComment }) => {
  const onCommentEdit = (commentId) => async (updatedComment) => {
    try {
      await updateComment({ ...updatedComment, id: commentId });
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const onCommentDelete = (commentId) => async () => {
    try {
      await deleteComment(commentId);
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  return (
    <div>
      {question?.answer?.comments?.map((comment, idx) => (
        <SVRComment
          handleEdit={onCommentEdit(comment.id)}
          onDelete={onCommentDelete(comment.id)}
          className={cn('comment', { 'pt-20': idx === 0 })}
          key={idx}
          comment={comment}
        />
      ))}
    </div>
  );
};

QuestionComments.propTypes = {
  question: T.object.isRequired,
  deleteComment: T.func.isRequired,
  updateComment: T.func.isRequired,
};

export default connect(null,
  (dispatch, { categoryIndex, questionIndex }) => ({
    deleteComment: (commentId) => dispatch(deleteComment(categoryIndex, questionIndex, commentId)),
    updateComment: (commentData) => dispatch(updateComment(categoryIndex, questionIndex, commentData)),
  }))(QuestionComments);
