import React, { Component } from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import ScrollableWrap from 'components/ScrollableWrap';
// utils
import cn from 'classnames';
import has from 'lodash.has';
import { isMobile } from 'react-device-detect';
// styles
import './Subnavigation.scss';

import { MAIN_HEADER_HEIGHT } from 'constants.js';

class SubNavigation extends Component {

  state = {
    isStickyNow: false,
  };

  componentDidMount() {
    if (this.props.stickyContent) {
      this.bodybag = document.getElementById('bodybag');
      this.bodybag.addEventListener('scroll', this.handleScrollEvent);
      if (this.wrapper) {
        const _rect = this.wrapper.getBoundingClientRect();
        this.stickyTrigger = _rect.y - MAIN_HEADER_HEIGHT;
      }
    }
  }

  componentWillUnmount() {
    if (this.props.stickyContent) {
      this.bodybag.removeEventListener('scroll', this.handleScrollEvent);
    }
  }

  handleScrollEvent = () => {
    const isStickyNow = this.bodybag.scrollTop > this.stickyTrigger;
    if (isStickyNow !== this.isStickyNow) this.setState({ isStickyNow });
    this.isStickyNow = isStickyNow;
  };

  renderLinks = () => {
    const { links, pathname } = this.props;
    return links.map((item, index) => !item.isHidden && (
      <Link
        key={index}
        to={item.link}
        activeClassName="active"
        className={cn('item', { 'active': (item.activePaths || []).some((p) => pathname.includes(p)) })}
        onlyActiveOnIndex={item.onlyActiveOnIndex}
      >
        <span>
          {item.label}
          {!!item.counter && (
            <span className="badge-counter">{item.counter}</span>
          )}
          {item.badge && <span className="badge">{item.badge}</span>}
        </span>
      </Link>
    ));
  };

  renderItems = () => {
    const { items, idKey, labelKey, defaultItem, onSelect, isActive, currentId } = this.props;
    const isItemActive = isActive || ((item) => currentId === item[idKey]);
    const isDefaultItemActive = !items.some((item) => isItemActive(item));

    return (
      <div className="flex">
        {defaultItem && (
          <div className={cn('item', { 'active': isDefaultItemActive })} onClick={() => onSelect()}>
            {defaultItem}
          </div>
        )}

        {items.map((item, index) => !item.isHidden && (
          <div
            key={index}
            className={cn('item', { 'active': isItemActive(item) })}
            onClick={() => onSelect(item)}
          >
            <span>
              {has(item, 'icon') && <span>{item.icon}&nbsp;</span>}
              {item[labelKey]}
              {has(item, 'stats') && <span>&nbsp;({item.stats || 0})</span>}
              {has(item, 'counter') && <span className="badge-counter">{item.counter || 0}</span>}
            </span>
          </div>
        ))}
      </div>
    );
  };

  bindRef = (ref) => {
    this.wrapper = ref;
  };

  render() {
    const { className, inner, isSticky, stickyContent, children } = this.props;
    const { isStickyNow } = this.state;

    return (
      <div className={cn('Subnavigation-wrapper clearfix', { 'is-sticky': isSticky })} ref={this.bindRef}>
        <div className={cn('Subnavigation', className, { 'is-mobile': isMobile, inner })}>
          {stickyContent && isStickyNow && (
            <div className="subnav-left-content">{stickyContent}</div>
          )}
          <ScrollableWrap>
            {this.renderItems()}
            {this.renderLinks()}
          </ScrollableWrap>
          {children && (
            <div className="subnav-children">{children}</div>
          )}
        </div>
      </div>
    );
  }
}

SubNavigation.defaultProps = {
  className: '',
  idKey: 'id',
  labelKey: 'name',
  items: [],
  links: [],
};

SubNavigation.propTypes = {
  items: T.oneOfType([T.array, T.object]),
  links: T.array,
  className: T.string,
  pathname: T.string,
  onSelect: T.func,
  defaultItem: T.oneOfType([T.string, T.object]),
  idKey: T.string,
  labelKey: T.string,
  currentId: T.oneOfType([T.string, T.number]),
  inner: T.bool,
  isSticky: T.bool,
  isActive: T.func,
  stickyContent: T.node,
  children: T.node,
};

export default SubNavigation;
