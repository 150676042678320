import React from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import AIPercent from './AIPercent';
import { FormattedMessage } from 'react-intl';
// assets
import magicLoopSvg from '../../../../public/images/magic.svg';
// styles
import styles from './PredictedTagDialog.module.scss';

const PredictedTagDialog = ({
  diagnosesCount,
  label,
  percent,
  buttonsData,
  isSubLabelHidden,
  isButtonsHidden,
}, { isMobile }) => (
  <>
    <div className={styles['predicted-tag-dialog']}>
      <img src={magicLoopSvg} alt="" className={styles['magic-icon']} />
      <span className={styles['prediction-label']}>
        {label}
        {!isSubLabelHidden && (
          <span className={styles['prediction-sub-label']}>
            {diagnosesCount > 1 && (
              <FormattedMessage id="component.imageTag.possibleDiagnoses" values={{ count: diagnosesCount }} />
            )}
          </span>
        )}
      </span>
      <AIPercent percent={percent} />
    </div>
    {!isMobile && !!buttonsData.length && !isButtonsHidden && (
      <div className={styles['buttons-row']}>
        {buttonsData.map(({ label, ...others }, index) => (
          <Button key={index} light className={styles['action-btn']} {...others}>
            {label}
          </Button>
        ))}
      </div>
    )}
  </>
);

PredictedTagDialog.contextTypes = {
  isMobile: T.bool.isRequired,
};

PredictedTagDialog.propTypes = {
  diagnosesCount: T.number,
  label: T.string.isRequired,
  percent: T.number.isRequired,
  buttonsData: T.array,
  isSubLabelHidden: T.bool,
  isButtonsHidden: T.bool,
};

PredictedTagDialog.defaultProps = {
  buttonsData: [],
  isSubLabelHidden: false,
};

export default PredictedTagDialog;
