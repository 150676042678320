import React from 'react';
import T from 'prop-types';
// components
import { formValueSelector } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import Button from 'components/Button';
import NameWithAddrressForm from './NameWithAddrressForm';
// redux
import { connect } from 'react-redux';

const PackingPlantForm = ({ onSubmit, name, addressObj, isEditForm,
  hasLocationDefining, initialValues, companyId }) => {
  const allowSubmit = !!name && !!addressObj && !!addressObj.city;

  return (
    <NameWithAddrressForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      hasLocationDefining={hasLocationDefining}
      nameLabelKey="general.packingPlantName"
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="button-submit">
          <Button
            onClick={handleSubmit}
            primary
            disabled={isSubmitting || !allowSubmit}
          >
            {isEditForm
              ? <FormattedMessage id="general.button.saveChanges" />
              : <FormattedMessage id="general.button.createPackingPlant" />
            }
          </Button>
          {!isEditForm && (
            <Link to={`/admin/packing-companies/${companyId}`} className="button btn-link margin-left-1">
              <FormattedMessage id="general.button.cancel" />
            </Link>
          )}
        </div>
      )}
    </NameWithAddrressForm>
  );
};

PackingPlantForm.propTypes = {
  onSubmit: T.func.isRequired,
  initialValues: T.object,
  isEditForm: T.bool,
  name: T.string,
  hasLocationDefining: T.bool,
  addressObj: T.object,
  companyId: T.string.isRequired,
};

const selector = formValueSelector('address-form');
export default connect(
  (state) => ({
    name: selector(state, 'name'),
    addressObj: selector(state, 'addressObj'),
  })
)(PackingPlantForm);
