import React from 'react';
import T from 'prop-types';
// redux-form
import { reduxForm } from 'redux-form';
// components
import { Link } from 'react-router';
import Button from 'components/Button';
import Field from 'components/Field';
import { FormattedMessage } from 'react-intl';

const ShareholderGroupForm = ({ handleSubmit, pristine, submitting, isEditMode }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="small-12 column">
        <label>
          <Field
            label={<FormattedMessage id="general.groupName" />}
            placeholderKey="general.groupName"
            name="name"
          />
        </label>
      </div>
    </div>

    {/* Button */}
    <div className="row mt-30">
      <div className="small-12 column">
        <Button
          primary
          type="submit"
          className="button mb-0"
          disabled={pristine || submitting}
        >
          <FormattedMessage id={`general.button.${isEditMode ? 'saveChanges' : 'createGroup'}`} />
        </Button>
        {!isEditMode && (
          <Link to="/admin/shareholder-groups" className="button btn-link margin-left-1">
            <FormattedMessage id="general.button.cancel" />
          </Link>
        )}
      </div>
    </div>
  </form>
);

ShareholderGroupForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  isEditMode: T.bool,
};

export default reduxForm({
  form: 'shareholder-group-form',
})(ShareholderGroupForm);
