import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchEfficacy } from 'reducers/treatmentFeedback/efficacy';
import { setPageOptions } from 'reducers/layout';
// components
import { Link } from 'react-router';
import { FormattedMessage }  from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import UserPanel from 'components/UserPanel/UserPanel';
import ResponsiveDropdown from 'components/ResponsiveDropdown';
import Preloader from 'components/Preloader';
// endpoints
import { downloadTreatmentEfficacyCSV } from 'endpoints/treatmentEfficacy';
// utils
import cn from 'classnames';
import { formatMortality, getMedType } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import download from 'downloadjs';
// styles
import './EfficacyFeed.scss';

class EfficacyFeed extends Component {

  componentDidMount() {
    const { fetchEfficacy, setPageOptions, params: { id } } = this.props;
    fetchEfficacy(id);
    setPageOptions({
      mobileTitle: 'treatmentFeedback',
      backLink: '',
    });
  }

  componentWillUnmount() {
    const { setPageOptions } = this.props;
    setPageOptions({ mobileTitle: '', backLink: '' });
  }

  getResponseIcon = (variant) => {
    if (variant === 'better') return <i className="fa fa-smile blue" />;
    if (variant === 'no_changes') return <i className="fa fa-meh orange" />;
    if (variant === 'worse') return <i className="fa fa-frown red" />;
    return undefined;
  };

  getResponseResultKey = (variant) => {
    if (variant === 'better') return 'gettingBetter';
    if (variant === 'no_changes') return 'notImproving';
    if (variant === 'worse') return 'gettingWorse';
    return undefined;
  };

  getResponseClass = (variant) => {
    if (variant === 'better') return 'better';
    if (variant === 'no_changes') return 'same';
    if (variant === 'worse') return 'worse';
    return undefined;
  };

  downloadEfficacyCSV = () => {
    const { params: { id } } = this.props;
    const filename = `treatment-efficacy-${id}.csv`;
    return downloadTreatmentEfficacyCSV(id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  render() {
    const { resource, isLoading } = this.props;
    const { daily_checkup_id, pig_group: pigGroup, effective, user, response, treatment_product } = resource || {};

    const groupStats = [
      { label: <FormattedMessage id="general.puc" />, value: pigGroup?.pigs || 0 },
      { label: <FormattedMessage id="general.estAvgWeight" />,
        value: <FormattedWeight value={pigGroup?.estimated_weight} unitFontSize="13px" /> },
      { label: <FormattedMessage id="general.mortalityRate" />, value: formatMortality(pigGroup?.mortality_rate) },
    ];

    const dropdownOptions = [
      { label: <FormattedMessage id="general.button.downloadEfficacyCSV" />, onClick: this.downloadEfficacyCSV },
      { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${pigGroup?.id}` },
    ];

    return (
      <div className="EfficacyFeed">
        <GroupProfileHeader
          group={pigGroup}
          dropdownOptions={dropdownOptions}
        >
          <ProfileHeaderStats stats={groupStats} />
        </GroupProfileHeader>

        <Preloader isActive={isLoading} />
        <div className="feed-container">
          <div className={cn('panel efficacy-result', effective ? 'effective' : 'ineffective')}>
            <i className={cn('efficacy-icon', { 'fa fa-smile': effective, 'fa fa-frown': !effective })} />
            <div className="description">
              <FormattedMessage
                id={`component.efficacyFeed.meds.${effective ? 'effective' : 'ineffective'}`}
                values={{ medicationName: <b>{treatment_product?.name}</b> }}
              />
            </div>
          </div>

          {response && (
            <UserPanel
              user={response.responder}
              label={<FormattedMessage id="component.efficacyFeed.reportedDrugEfficacy" />}
              timestamp={response.answered_at}
            >
              <div className="panel inner-panel">
                <div className={`efficacy-response ${this.getResponseClass(response.result)}`}>
                  {this.getResponseIcon(response.result)}
                  <div className="description">
                    <FormattedMessage
                      id={`container.farmfeed.row.efficacy.pigs.${this.getResponseResultKey(response.result)}`}
                      values={{ treatment: <b>{treatment_product?.name}</b> }}
                    />
                  </div>
                </div>
                <Link
                  className="button light view-checkup-btn"
                  to={`/barnsheets/daily-checkup/${response.daily_checkup_id}`}
                >
                  <i className="fa fa-eye mr-10" />
                  <FormattedMessage id="general.viewCheckup" />
                </Link>
              </div>
            </UserPanel>
          )}

          <UserPanel user={user} label={<FormattedMessage id="component.efficacyFeed.reportedTreatment" />}>
            <div className="panel inner-panel">
              <div className="treatment-bar">
                <i className="fa fa-water-soluble treatment-icon" />
                <div className="treatment-info">
                  <div>{treatment_product?.name}</div>
                  <div>
                    <FormattedMessage id="general.unitType" /> • <FormattedMessage
                      id={`general.${treatment_product?.unit_type}`}
                    />
                    {' | '}
                    <FormattedMessage id="general.type" /> • <FormattedMessage
                      id={`container.checkupEdit.reportMedications.${getMedType(treatment_product)}`}
                    />
                    {' | '}
                    <FormattedMessage id="general.common" /> • <FormattedMessage
                      id={`general.button.${treatment_product?.common ? 'yes' : 'no'}`}
                    />
                  </div>
                </div>
                <ResponsiveDropdown
                  className="dropdown-actions"
                  trigger={<i className="fa fa-angle-down trigger-icon" />}
                  options={[
                    { label: <FormattedMessage id="general.manageMedication" />,
                      url: `/admin/health-variables/medications/${treatment_product?.id}` },
                  ]}
                />
              </div>
              <Link className="button light view-checkup-btn" to={`/barnsheets/daily-checkup/${daily_checkup_id}`}>
                <i className="fa fa-eye mr-10" />
                <FormattedMessage id="general.viewCheckup" />
              </Link>
            </div>
          </UserPanel>

        </div>
      </div>
    );
  }
}

EfficacyFeed.propTypes = {
  resource: T.object,
  isLoading: T.bool,
  fetchEfficacy: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  params: T.object,
};

export default connect(
  (state) => ({
    resource: state.efficacy.resource,
    isLoading: state.efficacy.isLoading,
  }), { fetchEfficacy, setPageOptions }
)(EfficacyFeed);
