import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { reportPigs } from 'reducers/dailyCheckup/checkupEdit';
// components
import { FormattedMessage } from 'react-intl';
import CheckupSegment from './CheckupSegment/CheckupSegment';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import SegmentComment from './CheckupSegmentComment/SegmentComment';
import MovementInfo from 'components/MovementInfo/MovementInfo';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isSomeSectionReporting } from 'utils/checkupHelper';
import { getCommentByRole } from 'helpers.js';
// styles
import styles from './ReportMovementsSegment.module.scss';
// constants
import { comment_roles } from 'constants.js';

const { PIG_MIGRATIONS_ROLE } = comment_roles;
const cn = classnames.bind(styles);

const ReportMovementsSegment = ({
  checkup,
  openModal,
  paramsCheckupId,
  isOnline,
  isSubmitting,
  isDisabled,
  reportPigs,
  validateCheckup,
}) => {
  const [isAllGoodReported, setIsAllGoodReported] = useState(false);
  const isReported = checkup.pig_migration_count !== null;
  const pigMigrations = checkup.pig_migrations || [];
  const loadsCount = checkup.up_to_date_commitments_count || 0;
  const isRelatedLoadsExisted = !!loadsCount && isOnline;
  const reportPageUrl = isRelatedLoadsExisted && !isReported
    ? `/daily-checkup/${paramsCheckupId}/schedule`
    : `/daily-checkup/${paramsCheckupId}/pig-movements`;

  const handleReportNoPigs = () => {
    if (!validateCheckup(checkup)) return;
    setIsAllGoodReported(true);
    reportPigs(paramsCheckupId, { all_good: true })
      .catch((response) => toastResponseErrors(response, {
        'amount': <FormattedMessage id="component.toastr.wrongPigsAmount" />,
      }));
  };

  const handleOpenConfirmationModal = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.pigMovements" />}
        actionBtnLabel={<FormattedMessage id="component.modal.confirmReportMigrations.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={handleReportNoPigs}
      >
        <FormattedMessage id="component.modal.confirmReportMigrations.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  return (
    <CheckupSegment
      isSegmentConditionChanged={isAllGoodReported}
      isReported={isReported}
      isSubmitting={isSubmitting}
      isDisabled={isDisabled}
      onEmptyDataSubmit={isRelatedLoadsExisted ? handleOpenConfirmationModal : handleReportNoPigs}
      itemsCount={pigMigrations.length}
      titleKey="component.checkupSegment.doYouHaveMovements"
      titleKeyWhenReported="general.pigMovements"
      reportPageUrl={reportPageUrl}
      noData={isReported && !pigMigrations.length}
      warningText={isRelatedLoadsExisted
        ? (
          <FormattedMessage
            id="component.checkupSegment.pendingLoadsCount"
            values={{ count: loadsCount, a: (msg) => msg }}
          />
        )
        : null
      }
    >
      <div className={cn('reported-migrations')}>
        <MovementInfo pigMigrations={pigMigrations} />
        <SegmentComment comment={getCommentByRole(checkup.comments, PIG_MIGRATIONS_ROLE)} />
      </div>
    </CheckupSegment>
  );
};

ReportMovementsSegment.propTypes = {
  checkup: T.object.isRequired,
  openModal: T.func.isRequired,
  paramsCheckupId: T.oneOfType([T.string, T.number]).isRequired,
  reportPigs: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  isDisabled: T.bool.isRequired,
  isSubmitting: T.bool.isRequired,
  isOnline: T.bool.isRequired,
};

export default connect(
  ({ dailyCheckup }) => ({
    isSubmitting: dailyCheckup.checkupEdit.isReportingMovements,
    isDisabled: isSomeSectionReporting(dailyCheckup.checkupEdit) && !dailyCheckup.checkupEdit.isReportingMovements,
  }), {
    openModal,
    reportPigs,
  }
)(ReportMovementsSegment);
