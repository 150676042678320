import React from 'react';
import T from 'prop-types';
// components
import ReactSelect from 'components/Select/ReactSelect';
import FieldWrapper from '../FieldWrapper';
// utils
import getValue from 'lodash.get';

const SelectField = ({ input, label, meta, required, horizontal, valueKey, labelKey, ...others }) => (
  <FieldWrapper
    meta={meta}
    label={label}
    required={!input.value && required}
    horizontal={horizontal}
  >
    <ReactSelect
      valueKey={valueKey}
      labelKey={labelKey}
      arrowRenderer={() => <i className="fa fa-angle-down" />}
      value={input.value || ''}
      onBlur={() => input.onBlur(getValue(input, valueKey))}
      onChange={(option) => (input.onChange(getValue(option, valueKey)))}
      clearable={false}
      {...others}
    />
  </FieldWrapper>
);

SelectField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]),
  required: T.bool,
  horizontal: T.bool,
  valueKey: T.string.isRequired,
  labelKey: T.string.isRequired,
};

export default SelectField;
