import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import renderIf from 'utils/renderIf';
// assets
import styles from './SymptomLevelBadge.module.scss';

const cn = classnames.bind(styles);

const levelDefinitions = {
  'gteq75': 'very-low',
  'from50to74': 'low',
  'from25to49': 'medium',
  'from11to24': 'high',
  'lteq10': 'very-high',
};

const SymptomLevelBadge = ({ level, className }) => (
  renderIf(level)(
    <span className={cn('symptom-level-badge', className, levelDefinitions[level])}>
      <FormattedMessage id={`component.symptomLevelBadge.${levelDefinitions[level]}`} />
    </span>
  )
);

SymptomLevelBadge.defaultProps = {
  className: '',
};

SymptomLevelBadge.propTypes = {
  level: T.string,
  className: T.string,
};

export default SymptomLevelBadge;
