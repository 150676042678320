import React from 'react';
import T from 'prop-types';
// components
import AsyncSelectLoadMore from 'components/AsyncSelect/AsyncSelectLoadMore';
import FieldWrapper from '../FieldWrapper';

const AsyncSelectField = ({ input, label, meta, required, horizontal, ...others }) => (
  <FieldWrapper
    meta={meta}
    label={label}
    required={!input.value && required}
    horizontal={horizontal}
  >
    <AsyncSelectLoadMore
      selected={input.value || null}
      onBlur={() => input.onBlur(input?.value)}
      onChange={input.onChange}
      isClearable
      {...others}
    />
  </FieldWrapper>
);

AsyncSelectField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]),
  required: T.bool,
  horizontal: T.bool,
};

export default AsyncSelectField;
