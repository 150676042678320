import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCompanyProfile, setCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import Subnavigation from 'components/Subnavigation';
import CompanyMiniProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyMiniProfileHeader';
import Panel from 'components/Panel';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
import { showToastrMessage } from 'utils';
// endpoints
import { updateTruckingCompany } from 'endpoints/admin/truckingCompanies';
// styles
import styles from 'components/Admin/CompanyProfileBox.module.scss';

const cn = classnames.bind(styles);

const labels = {
  companyInfo: <FormattedMessage id="general.companyInformation" />,
  userRoles: <FormattedMessage id="general.userRoles" />,
};

class TruckingCompanyProfile extends Component {

  constructor(props) {
    super(props);
    const companyId = props.params.id;
    this.navLinks = [
      { link: `/admin/trucking-companies/${companyId}/user-roles`, label: labels.userRoles },
      { link: `/admin/trucking-companies/${companyId}/edit`, label: labels.companyInfo },
    ];
  }

  componentDidMount() {
    const { fetchCompanyProfile, params: { id } } = this.props;
    fetchCompanyProfile(`/admin/trucking_companies/${id}`).catch(toastResponseErrors);
  }

  updateCompany = (id, resource) => {
    const { setCompanyProfile } = this.props;
    updateTruckingCompany(id, resource)
      .then((company) => {
        setCompanyProfile(company);
        showToastrMessage(`component.toastr.trucking.${company.active ? 'enabled' : 'disabled'}`);
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { children, company, params: { id }, isLoading } = this.props;
    const options = [
      {
        label: <FormattedMessage id={`general.button.${company.active ? 'disable' : 'enable'}TruckingCompany`} />,
        onClick: () => this.updateCompany(id, { active: !company.active }),
      },
    ];

    return (
      <div className={cn('company-profile', 'clearfix')}>
        <CompanyProfileHeader
          avatarType="trucking-company"
          actualCompanyId={Number(id)}
          className="show-for-large"
          company={company}
          hasLocation
          isLoading={isLoading}
          dropdownOptions={options}
        />

        <Subnavigation
          className="small-12 column show-for-large"
          links={this.navLinks}
          isSticky
          stickyContent={
            <CompanyMiniProfileHeader avatarType="trucking-company" company={company} />
          }
        />

        <section className="small-12 column">
          <Panel>
            <Panel.Heading title={company.name} className="hide-for-large">
              <StatusBadge status={company.status} />
            </Panel.Heading>

            <Panel.Body noPadding className={cn('mobile-body')}>
              <Subnavigation links={this.navLinks} className="hide-for-large" inner />
              <div className={cn('mobile-body-box')}>
                {children}
              </div>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

TruckingCompanyProfile.contextTypes = {
  router: T.object,
};

TruckingCompanyProfile.propTypes = {
  fetchCompanyProfile: T.func.isRequired,
  setCompanyProfile: T.func.isRequired,
  params: T.object.isRequired,
  company: T.object.isRequired,
  isLoading: T.bool.isRequired,
  children: T.object.isRequired,
};

export default connect(
  (state) => ({
    company: state.profiles.company.data,
    isLoading: state.profiles.company.isLoading,
  }), {
    fetchCompanyProfile,
    setCompanyProfile,
  }
)(TruckingCompanyProfile);
