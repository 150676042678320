import React from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import Dropdown from '../../../components/Dropdown';
import UserAvatar from '../../../components/UserAvatar';
// translate
import { FormattedMessage } from 'react-intl';
// styles
import './UserDropdown.scss';

const UserDropdown = ({ user }) => (
  <Dropdown
    className="UserDropdown right-actions-item"
    closeOnClick
    trigger={(
      <div className="dropdown-header">
        <UserAvatar user={user} size={30} title={user.full_name || user.email} />
      </div>
    )}
  >
    <ul className="dropdown-menu">
      <li><Link to="/profile"><FormattedMessage id="general.pageTitle.myProfile" /></Link></li>
      <li>
        <Link to="https://www.everypig.co/privacy-policy" target="_blank">
          <FormattedMessage id="general.privacyPolicy" />
        </Link>
      </li>
      <li><Link to="/logout"><FormattedMessage id="general.signOut" /></Link></li>
    </ul>
  </Dropdown>
);

UserDropdown.propTypes = {
  user: T.object,
};

export default UserDropdown;
