import {
  getTableListByIndex,
  mergeTableList,
  tableObjectStoreAdd,
  tableObjectStoreClear,
  tableObjectStoreDelete,
  tableObjectStoreGet,
  tableObjectStoreGetAll,
  tableObjectStoreMerge,
  setTableList
} from 'db/offline-db';
import moment from 'moment';
import uuid from 'uuid';
import { useOfflinePaginationAndSearch } from './offlineHelper';

export const parseReportId = (id) => {
  const parsedId = parseInt(id, 10);
  return Number.isNaN(parsedId) ? id : parsedId;
};

const isSVRFakeID = (id) => {
  return typeof id === 'string' ? id.includes('fake') : false;
};

// SVR Farms
export const getIDBSVRFarms = (params) => {
  return tableObjectStoreGetAll('svr_farms')
    .then((resources) => {
      return useOfflinePaginationAndSearch(params, resources);
    });
};
export const getIDBSVRFarm = (farmId) => tableObjectStoreGet('svr_farms', 'id', farmId);
export const getIDBDetailedSVRFarm = (farmId) => tableObjectStoreGet('svr_detailed_farms', 'id', farmId);
export const mergeSVRFarmsToIDB = (farms) => mergeTableList('svr_farms', farms);
export const mergeSVRDetailedFarmsToIDB = (farm) => mergeTableList('svr_detailed_farms', [farm]);

// SVR Questions (for new SVR's)
export const updateIDBSVRCategories = (categories) => setTableList('svr_categories', categories);
export const clearIDBSVRCategories = () => tableObjectStoreClear('svr_categories');
export const getIDBSVRCategories = () => tableObjectStoreGetAll('svr_categories');
export const getIDBSVRCategory = (categoryId) => tableObjectStoreGet('svr_categories', 'id', categoryId);
export const setIDBSVRCategory = (updatedCategory) => tableObjectStoreMerge('svr_categories', updatedCategory, 'id');

// SVR Custom questions
export const updateIDBSVRCustomQuestions = (customQuestions) => setTableList('svr_custom_questions', customQuestions);
export const addIDBSVRCustomQuestion = (newCustomQuestion) => tableObjectStoreAdd(
  'svr_custom_questions', newCustomQuestion
);

export const updateIDBSVRCustomQuestion = (newCustomQuestion) => tableObjectStoreMerge(
  'svr_custom_questions', newCustomQuestion, 'id'
);

export const deleteIDBSVRCustomQuestion = (deletedCustomQuestionId) => tableObjectStoreDelete(
  'svr_custom_questions', deletedCustomQuestionId
);

export const getIDBSVRCustomQuestions = () => tableObjectStoreGetAll('svr_custom_questions');

// SVR Reports
export const mergeSVRsToIDB = (siteVisitReports) => mergeTableList('site_visit_reports', siteVisitReports);
export const getIDBAllSVRs = () => tableObjectStoreGetAll('site_visit_reports');
export const getIDBFarmSVRs = (farmId, params) => getTableListByIndex('site_visit_reports', 'farm_id', farmId)
  .then((resources) => {
    const filteredResources = resources.filter(({ action, is_draft }) => (params.draft ? is_draft : !is_draft)
      && action !== 'delete');
    return useOfflinePaginationAndSearch(params, filteredResources);
  });

// SVR Report
export const deleteIDBSVR = (svrId) => tableObjectStoreDelete('site_visit_reports', svrId);
export const addIDBSVR = (data) => tableObjectStoreAdd('site_visit_reports', data);
export const mergeIDBSVR = (data) => tableObjectStoreMerge('site_visit_reports', data);
export const getIDBSVR = (svrId) => tableObjectStoreGet('site_visit_reports', 'id', parseReportId(svrId));

export const deleteIDBExistedSVR = (svrId) => {
  return isSVRFakeID(svrId)
    ? deleteIDBSVR(svrId)
    : tableObjectStoreMerge('site_visit_reports', { id: svrId, action: 'delete' }, 'id');
};

export const updateSVROffline = (data) => {
  const preparedData = {
    ...data,
    updated_at: moment().toISOString(),
    action: isSVRFakeID(data.id) ? 'create' : 'update',
    svr_categories: data.svr_categories.map((category) => ({
      ...category,
      svr_questions: category.svr_questions.map((question) => ({
        ...question,
        answer: {
          ...(question.answer || {}),
          value: question.answer?.value || (question.question_type === 'media' ? [] : null),
          updated_at: moment().toISOString(),
        },
      })),
    })),
    svr_custom_questions: data.svr_custom_categories.map((category) => category.svr_questions
      .map((question, position) => ({
        ...question,
        answer: {
          ...(question.answer || {}),
          position,
          value: question.answer?.value || null,
          comments: question.answer?.comments || [],
          created_at: moment().toISOString(),
          active: true,
        },
      }))).flat(),
  };

  return tableObjectStoreMerge('site_visit_reports', preparedData, 'id');
};

const svrQuestionFormatter = (svrQuestion, position) => ({
  ...svrQuestion,
  answer: {
    ...(svrQuestion.answer || {}),
    position,
    value: svrQuestion.answer?.value || (svrQuestion.question_type === 'media' ? [] : null),
    comments: svrQuestion.answer?.comments || [],
    created_at: moment().toISOString(),
    active: true,
  },
});

export const createDraftSVROffline = async (user, farm_id) => {
  // TODO: make a research about to store only enabled questions in IDB
  const customQuestions = (await getIDBSVRCustomQuestions()).filter(({ is_enabled }) => is_enabled);
  const svrCategories = (await getIDBSVRCategories())
    .map((category) => ({
      ...category,
      svr_questions: category.svr_questions.filter((question) => question.is_enabled)
    }))
    .filter((category) => category.svr_questions.length);

  const preparedData = {
    id: `fake-${uuid()}`,
    is_draft: true,
    created_at: moment().toISOString(),
    user,
    farm_id,
    svr_categories: svrCategories
      .map((category) => ({
        ...category,
        svr_questions: category.svr_questions.map(svrQuestionFormatter)
      })),
    svr_custom_questions: customQuestions.map(svrQuestionFormatter),
  };

  return addIDBSVR(preparedData);
};
