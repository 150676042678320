import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setGroupAllGood, startDailyCheckup, startGroup } from 'reducers/dailyCheckup/checkupFarmGroups';
import { openModal, closeModal } from 'reducers/modals';
import { openPortal } from 'reducers/portal';
import { enableEditMode } from 'reducers/barnsheets/barnsheetsGroupCheckups';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import AllGoodConfirmationModal from
  'components/DailyCheckups/modals/AllGoodConfirmationModal/AllGoodConfirmationModal';
import CreateShipmentMobileWizard from './CreateShipmentMobileWizard';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Preloader from 'components/Preloader';
import PigGroupMarketingBadge from 'components/PigGroupMarketingBadge';
import PlusMoreHint from 'components/PlusMoreHint';
// utils
import cn from 'classnames';
import isNull from 'lodash.isnull';
import isEmpty from 'lodash.isempty';
import { isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatWeightToBackEndFormat } from 'utils/weightHelper';
import { showToastrMessage, formatGroupDeliveryDate } from 'utils';
// helpers
import { getGroupStatus, getStatusIcon } from './helper';
// styles
import './DailyCheckupsGroupRow.scss';

class DailyCheckupsGroupRow extends Component {

  state = {
    isLoading: false,
  };

  setLoading = (loading) => {
    this.setState({ isLoading: loading });
  };

  allGoodClick = () => {
    const { openModal, group } = this.props;
    openModal(
      <AllGoodConfirmationModal
        type="group"
        name={group.name}
        onConfirm={this.confirmAllGood}
      />
    );
  };

  confirmAllGood = () => {
    const { group, setGroupAllGood, closeModal } = this.props;
    this.setLoading(true);
    setGroupAllGood(group.id)
      .then(() => {
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
    closeModal();
  };

  goToCheckupEdit = () => {
    const { group: { current_checkup_id: checkupId } } = this.props;
    const { router } = this.context;
    router.push(`/daily-checkup/${checkupId}`);
  };

  startCheckup = () => {
    const { group, startDailyCheckup } = this.props;
    const { router } = this.context;
    this.setLoading(true);

    startDailyCheckup(group.id)
      .then((resource) => {
        this.setLoading(false);
        router.push(`/daily-checkup/${resource.id}`);
      })
      .catch((response) => {
        this.setLoading(false);
        toastResponseErrors(response);
      });
  };

  createPigsShipment = () => {
    const { openPortal, group, isLogisticsEnabled } = this.props;
    const { router } = this.context;
    if (isMobile && !isLogisticsEnabled) {
      openPortal(
        <CreateShipmentMobileWizard
          group={group}
          onSubmit={this.startGroup}
        />
      );
    } else {
      const query = isLogisticsEnabled ? { mode: 'select-load' } : undefined;
      router.push({ pathname: `/daily-checkup/groups/${group.id}/create-shipment`, query });
    }
  };

  startGroup = (resource) => {
    const { group, startGroup, measurement_system } = this.props;
    const { router } = this.context;

    const enhancedResource = {
      ...resource,
      estimated_weight: formatWeightToBackEndFormat(resource.estimated_weight, measurement_system),
    };

    return startGroup(group.id, enhancedResource)
      .then((response) => {
        const dcId = response?.action?.payload?.resource?.current_checkup_id || null;
        if (dcId) {
          showToastrMessage('component.toastr.shipment.successCreated');
          router.push(`/daily-checkup/${dcId}`);
        }
      })
      .catch(toastResponseErrors);
  };

  goToReconcile = () => {
    const { group, enableEditMode } = this.props;
    const { router } = this.context;
    enableEditMode(group.id, { type: 'reconcile', inventory_id: group.inventory_id })
      .then(() => router.push({ pathname: `/barnsheets/groups/${group.id}`, query: { editMode: true } }));
  };

  showManagerButton = () => {
    const { group: { current_checkup_id, current_checkup_submitted } } = this.props;
    const { isLoading } = this.state;
    if (!current_checkup_id) {
      return (
        <Button disabled={isLoading} primary onClick={this.startCheckup}>
          <FormattedMessage id="container.checkupIndex.row.start" />
        </Button>
      );
    }
    if (!current_checkup_submitted) {
      return (
        <Button secondary onClick={this.goToCheckupEdit}>
          <FormattedMessage id="container.checkupIndex.row.finish" />
        </Button>
      );
    }
    return (
      <Button default onClick={this.goToCheckupEdit}>
        <FormattedMessage id="container.checkupIndex.row.update" />
      </Button>
    );
  };

  isManagerBtnEnabled = () => {
    const { farm, currentUser: { roles_map: { admin, manager } } } = this.props;
    if (isEmpty(farm)) return false;
    return admin || (manager.farm_ids || []).includes(farm.id) || (manager.company_ids.includes(farm.manager_id));
  };

  render() {
    const { group, className, isOnline, showAllGoodBtnInTenant } = this.props;
    const { name, barns, progress, pigs, delivery_start, delivery_end } = group;
    const { isLoading } = this.state;
    const { status, label } = getGroupStatus(group);
    const statusIcon = getStatusIcon(status);
    const isActionRequired = ['empty', 'reconcile', 'pending_close'].includes(status);
    const isManagerBtnEnabled = this.isManagerBtnEnabled();

    return (
      <div
        className={cn('DailyCheckupsGroupRow', className, {
          'is-new': status === 'new',
          'is-action-required': isActionRequired,
          'request-close-today': status === 'request_close',
        })}
      >
        {(isActionRequired || status === 'request_close') && (
          <div className="action-required">
            <span className="action-required-icon fa fa-exclamation-triangle-bts" />
            {status === 'reconcile' &&
              <FormattedMessage tagName="em" id="container.checkupIndex.row.disabledForReconcile" />}
            {status === 'pending_close' &&
              <FormattedMessage tagName="em" id="container.checkupIndex.row.pendingClosure" />}
            {status === 'request_close' &&
              <FormattedMessage tagName="em" id="container.checkupIndex.row.pendingClosureToday" />}
            {status === 'empty' && (
              <span>
                {(!delivery_start || !delivery_end)
                  ? <FormattedMessage tagName="em" id="container.checkupIndex.row.groupPending" />
                  : (
                    <FormattedMessage
                      tagName="em"
                      id="container.checkupIndex.row.scheduledForDelivery"
                      values={{ date: formatGroupDeliveryDate(group.delivery_start, group.delivery_end) }}
                    />
                  )}
              </span>
            )}
          </div>
        )}

        <div className="row-content">
          <Preloader isActive={isLoading} />
          <div className="icon-section">{statusIcon}</div>

          {/* GROUP INFO */}
          <div className="row-section group-info">
            <div className="hide-for-large mr-10">{statusIcon}</div>
            <div>
              <div className="group-name">
                <FormattedMessage id="general.titleWithLabel.group" values={{ name }} />
                <PigGroupMarketingBadge className="ml-5" pigGroup={group} size="small" isHintAttachedToBody />
              </div>
              {status !== 'empty' && !!barns.length && (
                <div className="group-barns">
                  <PlusMoreHint items={barns}>
                    <FormattedMessage id="general.barns" />
                    {' • '}
                  </PlusMoreHint>
                </div>
              )}
            </div>
          </div>

          {/* PIGS COUNT  */}
          <div className="section-divider" />
          <div className="row-section pigs-count">
            <strong>{pigs || 0} </strong>
            <FormattedMessage id="general.pigs" />
          </div>

          {/* GROUP PROGRESS  */}
          {!isNull(progress) &&
            <div className="section-divider" />}
          {!isNull(progress) && (
            <div className="row-section group-progress">
              <ProgressBar
                progress={progress}
                inline={false}
                className="farm-progress-bar"
                description={<FormattedMessage id="general.progress.onTime" />}
              />
            </div>
          )}

          {/* GROUP STATUS */}
          <div className="section-divider" />
          <div className="row-section group-status">
            {!isNull(progress) ? label : <FormattedMessage id="container.checkupIndex.row.pendingFirstPlacement" />}
          </div>

          {/* GROUP ACTIONS */}
          <div className={cn('row-section', 'buttons-section', { 'without-buttons': status === 'pending_close' })}>
            {status === 'new' && showAllGoodBtnInTenant && (
              <Button light disabled={isLoading} onClick={this.allGoodClick}>
                <i className="fa fa-thumbs-up mr-5" />
                <FormattedMessage id="container.checkupIndex.row.allGood" />
              </Button>
            )}
            {status === 'new' && (
              <Button disabled={isLoading} primary onClick={this.startCheckup}>
                <FormattedMessage id="container.checkupIndex.row.start" />
              </Button>
            )}
            {status === 'incomplete' && (
              <Button secondary onClick={this.goToCheckupEdit}>
                <FormattedMessage id="container.checkupIndex.row.finish" />
              </Button>
            )}
            {((status === 'complete') || (status === 'request_close')) && (
              <Button default onClick={this.goToCheckupEdit}>
                <FormattedMessage id="container.checkupIndex.row.update" />
              </Button>
            )}
            {status === 'empty' && (
              <Button secondary disabled={!isOnline} onClick={this.createPigsShipment}>
                <FormattedMessage id="container.checkupIndex.row.startGroup" />
              </Button>
            )}
            {status === 'reconcile' && (
              <Button className="reconcile-btn" disabled={!isOnline} onClick={this.goToReconcile}>
                <FormattedMessage id="container.checkupIndex.row.reconcile" />
              </Button>
            )}
            {status === 'reconcile' && isManagerBtnEnabled && this.showManagerButton()}
          </div>
        </div>
      </div>
    );
  }
}

DailyCheckupsGroupRow.contextTypes = {
  router: T.object,
};

DailyCheckupsGroupRow.propTypes = {
  group: T.object.isRequired,
  farm: T.object.isRequired,
  currentUser: T.object.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  openPortal: T.func.isRequired,
  setGroupAllGood: T.func.isRequired,
  startDailyCheckup: T.func.isRequired,
  startGroup: T.func.isRequired,
  enableEditMode: T.func.isRequired,
  isOnline: T.bool.isRequired,
  isLogisticsEnabled: T.bool.isRequired,
  className: T.string,
  showAllGoodBtnInTenant: T.bool.isRequired,
  measurement_system: T.string.isRequired,
};

export default connect(
  ({ network, auth: { user } }) => ({
    isOnline: network.isOnline,
    isLogisticsEnabled: user.current_company.logistics,
    showAllGoodBtnInTenant: !user.current_company.track_temperature && !user.current_company.track_water_usage,
    measurement_system: user.current_company.measurement_system,
  }),
  {
    startDailyCheckup,
    setGroupAllGood,
    startGroup,
    openModal,
    closeModal,
    openPortal,
    enableEditMode,
  }
)(DailyCheckupsGroupRow);
