import React, { useState } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Modal from 'components/Modal/Modal';
import DrBotAvatar from 'components/DrBotAvatar/DrBotAvatar';
// utils
import { FormattedMessage } from 'react-intl';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
// endpoints
import { requestToCloseGroup } from 'endpoints/pigGroups';
// style
import './CloseGroupModal.scss';

const CloseGroupModal = ({ closeModal, pigGroup }, { router }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onCancel = () => closeModal();

  const closeGroup = () => {
    router.push({ pathname: router.location.pathname, query: { force: true } });
    setIsLoading(true);
    requestToCloseGroup(pigGroup.id)
      .then(() => {
        closeModal();
        showToastrMessage('component.toastr.closeGroupRequest.sent');
        router.push(`/daily-checkup/farms/${pigGroup.farm_id}`);
      })
      .catch(toastResponseErrors)
      .finally(() => {
        setIsLoading(false);
      });
  };
  const values = { pgName: <b>{pigGroup.name}</b> };

  return (
    <Modal onClose={closeModal} className="CloseGroupModal">
      <section className="modal-body">
        <Preloader isActive={isLoading} />
        <div className="avatar-wrapper"><DrBotAvatar /></div>
        <div className="content-wrapper">
          <FormattedMessage id="container.dailyCheckup.reportMovements.noPigs" values={values} />
        </div>
      </section>
      <section className="modal-footer">
        <Button className="button light" onClick={onCancel}>
          <FormattedMessage id="container.dailyCheckup.reportMovements.keepOpen" />
        </Button>
        <Button className="button primary" onClick={closeGroup}>
          <FormattedMessage id="container.dailyCheckup.reportMovements.closeGroup" />
        </Button>
      </section>
    </Modal>
  );
};

CloseGroupModal.contextTypes = {
  router: T.object.isRequired,
};

CloseGroupModal.propTypes = {
  closeModal: T.func.isRequired,
  pigGroup: T.object.isRequired,
};

export default CloseGroupModal;
