import React from 'react';
import T from 'prop-types';
import Input from 'components/Input';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './FormField.module.scss';

const cn = classnames.bind(styles);

const FormField = ({
  input,
  label,
  type,
  meta: { touched, error },
  onChange,
  icon,
  iconLeft,
  className,
  ...others
}) => {
  const inputOnChange = (e) => {
    const { validity } = e.target;
    if (!validity.valid && type !== 'email') return;
    input.onChange(e);
    if (typeof onChange === 'function') onChange(e);
  };

  return (
    <div className={cn('FormField', className, { 'has-error': touched && error })}>
      {label &&
        <span className={cn('input-label')}>{label}</span>}
      <div className={cn('input-wrapper', { iconLeft })}>
        <Input
          {...input}
          onChange={inputOnChange}
          type={type}
          {...others}
        />
        {!!icon && <div className={cn('input-wrapper-icon')}>{icon}</div>}
      </div>
    </div>
  );
};

FormField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.object, T.string]),
  type: T.string.isRequired,
  onChange: T.func,
  icon: T.node,
  iconLeft: T.bool,
  className: T.string,
};

export default FormField;
