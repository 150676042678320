import React from 'react';
import T from 'prop-types';
// components
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import ActivityMentions from 'components/Activities/ActivityMentions';
// translations
import { FormattedMessage } from 'react-intl';
// utils
import { getUserNickname } from 'utils';
// styles
import './FarmFeedUserSelect.scss';

const ValueComponent = (props) => {
  const { value, onRemove, children } = props;

  return (
    <div className="Select-value" title={value.title}>
      <span className="Select-value-label">
        {children}&nbsp;
        <span className="remove-icon" onClick={() => onRemove(value)}>
          <i className="fa fa-times" />
        </span>
      </span>
    </div>
  );
};

const FarmFeedUserSelect = ({ onChange, selectedUsers }) => {
  let userInput = null;
  const optionRenderer = (option) => (
    <div className="user-suggestion-item">
      <UserAvatar user={option.item} className="user-avatar" size="40" />
      <div>
        <div className="semibold">{option.item.full_name || option.item.email}</div>
        <div className="muted">@{getUserNickname(option.item)}</div>
      </div>
    </div>
  );

  return (
    <div className="FarmFeedUserSelect">
      <div className="users-line">
        <ActivityMentions users={selectedUsers.map(({ item }) => item)} />
      </div>
      <div className="users-search">
        <div onClick={() => userInput.focus()} className="select-badge">
          <FormattedMessage id="general.users" />
        </div>
        <AsyncSelect
          arrowRenderer={null}
          optionRenderer={optionRenderer}
          multi
          selected={selectedUsers}
          placeholder={<FormattedMessage id="general.placeholder.searchByUser" />}
          noInputMargin
          className="user-select"
          optionsPath="/users/search"
          optionsKeys={{ value: 'id', label: 'full_name' }}
          onChange={onChange}
          valueComponent={ValueComponent}
          getRef={(input) => { userInput = input; }}
          openOnFocus
        />
      </div>
    </div>
  );
};

ValueComponent.propTypes = {
  value: T.object,
  onRemove: T.func,
  children: T.any,
};

FarmFeedUserSelect.propTypes = {
  onChange: T.func,
  selectedUsers: T.array,
};

export default FarmFeedUserSelect;
