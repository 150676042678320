import React from 'react';
import T from 'prop-types';
// components
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Avatar from 'components/Avatar/Avatar';
import VerticalAligner from 'components/VerticalAligner';
// styles
import './ShareholderGroupProfileHeader.scss';

const ShareholderGroupMiniProfileHeader = ({ group, className }) => (
  <VerticalAligner className={`ShareholderGroupMiniProfileHeader ${className}`}>
    <Avatar type="shareholder_group" avatarSize={35} iconSize={16} />
    <h3 className="inline-block group-name">{group.name}</h3>
    <StatusBadge status={group.status} small />
    <div className="vertical-divider" />
  </VerticalAligner>
);

ShareholderGroupMiniProfileHeader.propTypes = {
  group: T.object,
  className: T.string,
};

ShareholderGroupMiniProfileHeader.defaultProps = {
  className: '',
};

export default ShareholderGroupMiniProfileHeader;
