import React from 'react';
import T from 'prop-types';
// components
import Header from './Header';
import Notes from './Notes';
import ExtendedHeader from 'components/DailyCheckups/ExtendedHeader/ExtendedHeader';
// utils
import isEmpty from 'lodash.isempty';
// assets
import './Section.scss';

const Section = ({ className, children, notes, withoutNotes, headerLabel, headerValue, withoutIcon, actionButton,
  extendedHeaderData, highlightEmpty }) => (
    <div className={`Section panel ${className}`}>
      {!isEmpty(extendedHeaderData) && <ExtendedHeader headerData={extendedHeaderData} />}
      <Header
        highlightEmpty={highlightEmpty}
        label={headerLabel}
        value={headerValue}
        withoutIcon={withoutIcon}
        actionButton={actionButton}
      />
      {children}
      {!withoutNotes && !isEmpty(notes) && <Notes value={notes} />}
    </div>
);

Section.propTypes = {
  headerLabel: T.oneOfType([T.string, T.node]),
  headerValue: T.oneOfType([T.number, T.string, T.node]),
  extendedHeaderData: T.shape({
    isError: T.bool,
    text: T.oneOfType([T.string, T.node]),
  }),
  notes: T.object,
  children: T.node,
  withoutNotes: T.bool,
  withoutIcon: T.bool,
  actionButton: T.node,
  className: T.string,
  highlightEmpty: T.bool,
};

Section.defaultProps = {
  withoutNotes: false,
  withoutIcon: false,
  className: '',
};

export default Section;
