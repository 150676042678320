import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// utils
import cn from 'classnames';
// styles
import './StickyHeader.scss';

const StickyHeader = ({ children, className, isMainHeaderVisible, isOnline }) => (
  <div className={cn('StickyHeader', className, { 'header-hidden': !isMainHeaderVisible, 'offline': !isOnline })}>
    {children}
  </div>
);

StickyHeader.defaultProps = {
  className: '',
};

StickyHeader.propTypes = {
  children: T.any,
  className: T.string,
  isMainHeaderVisible: T.bool,
  isOnline: T.bool,
};

export default connect(
  (state) => ({
    isMainHeaderVisible: state.layout.showHeader || state.layout.pinHeader,
    isOnline: state.network.isOnline,
  })
)(StickyHeader);
