import React from 'react';
import T from 'prop-types';
// redux, redux-form
import { connect } from 'react-redux';
import { formValueSelector, change, reduxForm } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import AddressSelect from '../../Farms/components/AddressSelect';
import GoogleMapSelect from 'components/GoogleMap/GoogleMapSelect';
import Field from 'components/Field';
// utils, hooks
import useCountries from 'utils/hooks/useCountries';
import { getFullAddress } from 'utils';
import isEmpty from 'lodash.isempty';
// styles
import './NameWithAddressForm.scss';

const Marker = () => <div className="fa fa-map-marker-bts company-marker" />;

const NameWithAddressForm = ({
  addressObj,
  change,
  children,
  handleSubmit,
  hasLocationDefining,
  nameLabelKey,
  pristine,
  submitting,
}) => {
  const { countries } = useCountries();
  const selected = !isEmpty(addressObj) ? getFullAddress(addressObj) : '';
  return (
    <div className="NameWithAddressForm">
      <div className="content-block">
        <form className="form-content-block">
          {/* COMPANY NAME */}
          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  name="name"
                  label={<FormattedMessage id={nameLabelKey} />}
                  placeholderKey={nameLabelKey}
                />
              </label>
            </div>
          </div>

          {/* COMPANY ADDRESS */}
          <div className="row">
            <div className="small-12 column">
              <label>
                <AddressSelect
                  label={<FormattedMessage id="general.address" />}
                  hasPoweredByLabel
                  selected={selected}
                  countriesData={countries}
                  changeValue={change}
                  placeholder={<FormattedMessage id="general.typeAddress" />}
                />
              </label>
            </div>
          </div>
          {children({ handleSubmit, isSubmitting: pristine || submitting })}
        </form>
        <div className="map-block">
          <GoogleMapSelect
            onChangeAdress={(data) => change('addressObj', data)}
            hasLocationDefining={hasLocationDefining}
            addressData={addressObj}
          >
            {(position) => <Marker {...position} />}
          </GoogleMapSelect>
        </div>
      </div>
    </div>
  );
};

NameWithAddressForm.propTypes = {
  change: T.func.isRequired,
  children: T.func.isRequired,
  hasLocationDefining: T.bool,
  pristine: T.bool,
  nameLabelKey: T.string.isRequired,
  submitting: T.bool,
  handleSubmit: T.func.isRequired,
  addressObj: T.object,
};

const selector = formValueSelector('address-form');
const formComponent = reduxForm({
  form: 'address-form',
})(NameWithAddressForm);

export default connect(
  (state) => ({
    addressObj: selector(state, 'addressObj'),
  }), {
    change,
  }
)(formComponent);
