import React, { Component } from 'react';
import T from 'prop-types';
// components
import Collapse from 'react-collapse';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './CollapsibleFilter.module.scss';

const cn = classnames.bind(styles);

class CollapsibleFilter extends Component {

  state = {
    isOpened: this.props.isOpened,
  };

  toggleCollapse = () => {
    this.setState((prevState) => ({ isOpened: !prevState.isOpened }));
  };

  render() {
    const { title, className, children } = this.props;
    const { isOpened } = this.state;

    return (
      <div className={cn('collapsible-filter', { 'opened': isOpened }, className)}>
        <div className={cn('title-box')} onClick={this.toggleCollapse}>
          <div className={cn('title')}>{title}</div>
          <i className={cn('arrow', 'fa fa-angle-right')} />
        </div>
        <Collapse isOpened={isOpened}>
          <div className={cn('body-box')}>
            {children}
          </div>
        </Collapse>
      </div>
    );
  }
}

CollapsibleFilter.defaultProps = {
  className: '',
  isOpened: false,
};

CollapsibleFilter.propTypes = {
  title: T.oneOfType([T.string, T.node]).isRequired,
  className: T.string,
  isOpened: T.bool,
  children: T.any,
};

export default CollapsibleFilter;
