import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Input from 'components/Input';
import TimeInputButtons from './TimeInputButtons';
// utils
import cn from 'classnames';
import { inRange } from 'utils';
// styles
import styles from './TimeRange.module.scss';

const minHourValue = 1;
const maxHourValue = 12;
const minMinuteValue = 0;
const maxMinuteValue = 59;
const step = 1;

const defaultInputProps = {
  placeholder: '00',
  pattern: '[0-9]*',
  type: 'number',
  size: 2,
  maxLength: 2,
  step,
};

function formatValue(value) {
  const stringValue = value.toString();
  if (stringValue === '') return stringValue;
  return stringValue.padStart(2, '0');
}

function formatHour(hour) {
  if (hour) {
    if (+hour === 0) return maxHourValue.toString();
    return formatValue(+hour > maxHourValue ? +hour - maxHourValue : hour);
  }
  return '';
}

function formatHourOnInputChange(value, isPM) { // value = 1...12
  if (isPM) {
    return +value === maxHourValue ? maxHourValue.toString() : formatValue(+value + maxHourValue);
  }
  return +value === maxHourValue ? '00' : formatValue(+value);
}

const TimeRange = ({
  className,
  hourValue = '', // 24h format
  isDisabled = false,
  minuteValue = '',
  onChange,
}) => {
  const isPM = (+hourValue || 0) > 11;
  const formattedHour = formatHour(hourValue);
  const formattedMinute = formatValue(minuteValue);

  const handleChangeHourInput = (e) => {
    const { value, validity: { valid } } = e.target;
    if (!valid) return;
    onChange({ hourValue: value !== '' ? formatHourOnInputChange(value, isPM) : '', minuteValue });
  };

  const handleChangeMinuteInput = (e) => {
    const { value, validity: { valid }, } = e.target;
    if (!valid) return;
    onChange({ hourValue, minuteValue: value !== '' ? formatValue(+value) : '' });
  };

  const handleChangeHour = (delta) => {
    if (isDisabled) return;
    const value = +formattedHour + delta;
    if (inRange(value, minHourValue, maxHourValue)) {
      onChange({ hourValue: formatHourOnInputChange(value, isPM), minuteValue });
    }
    if (value === (maxHourValue + step)) {
      onChange({ hourValue: isPM ? (maxHourValue + step).toString() : formatValue(minHourValue), minuteValue });
    }
    if (value === (minHourValue - step)) {
      const valueToSave = isPM ? maxHourValue.toString() : '00';
      onChange({ hourValue: valueToSave, minuteValue });
    }
  };

  const handleChangeMinute = (delta) => {
    if (isDisabled) return;
    const value = +minuteValue + delta;
    if (inRange(value, minMinuteValue, maxMinuteValue)) {
      onChange({ hourValue, minuteValue: formatValue(value) });
    }
    if (value === (maxMinuteValue + step)) {
      onChange({ hourValue, minuteValue: '00' });
    }
    if (value === (minMinuteValue - step)) {
      onChange({ hourValue, minuteValue: maxMinuteValue.toString() });
    }
  };

  const handleClickAM = () => {
    if (!isPM || isDisabled) return;
    onChange({ hourValue: formatValue(+hourValue - maxHourValue), minuteValue });
  };

  const handleClickPM = () => {
    if (isPM || isDisabled) return;
    onChange({ hourValue: formatValue(+hourValue + maxHourValue), minuteValue });
  };

  return (
    <div className={cn(styles['time-range'], { [className]: !!className })}>
      <label className={styles.label}>
        <Input
          {...defaultInputProps}
          disabled={isDisabled}
          min={minHourValue}
          max={maxHourValue}
          value={formattedHour}
          className={styles.input}
          onChange={handleChangeHourInput}
        />
        <TimeInputButtons
          className={styles.buttons}
          onUpClick={() => handleChangeHour(step)}
          onDownClick={() => handleChangeHour(-step)}
        />
      </label>
      <span className="mh-10">:</span>
      <label className={styles.label}>
        <Input
          {...defaultInputProps}
          disabled={isDisabled}
          min={minMinuteValue}
          max={maxMinuteValue}
          value={formattedMinute}
          className={styles.input}
          onChange={handleChangeMinuteInput}
        />
        <TimeInputButtons
          className={styles.buttons}
          onUpClick={() => handleChangeMinute(step)}
          onDownClick={() => handleChangeMinute(-step)}
        />
      </label>
      <div
        className={cn(styles['time-format'], 'ml-20 mr-5', {
          [styles.active]: !isPM,
          [styles.disabled]: isDisabled,
        })}
        onClick={handleClickAM}
      >
        <FormattedMessage id="general.timeFormat.am" />
      </div>
      <div
        className={cn(styles['time-format'], 'mr-20 ml-5', {
          [styles.active]: isPM,
          [styles.disabled]: isDisabled,
        })}
        onClick={handleClickPM}
      >
        <FormattedMessage id="general.timeFormat.pm" />
      </div>
    </div>
  );
};

TimeRange.propTypes = {
  className: T.string,
  isDisabled: T.bool,
  onChange: T.func.isRequired,
  hourValue: T.string,
  minuteValue: T.string,
};

export default TimeRange;
