import React from 'react';
import T from 'prop-types';
// components
import UserAvatar from '../UserAvatar';
import DiagnoseButton from 'components/DiagnoseForm/DiagnoseButton';
import MediaQuery from 'react-responsive';
// utils
import moment from 'moment';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
// styles
import './MediaViewerHeader.scss';

const MediaViewerHeader = ({
  currentAsset,
  dailyCheckup,
  creator = {},
  farm = {},
  closeViewer,
  openDiagnosePanel,
  isUserCanDiagnose,
  isCollapsed,
  isFullScreenMode,
}) => {
  const createdAt = currentAsset.created_at || dailyCheckup?.updated_at;

  return (
    <div className={cn('MediaViewerHeader', {
      'is-mobile': isMobile,
      'is-collapsed': isCollapsed,
      'is-fullscreen': isFullScreenMode,
    })}
    >
      <div className="header-data">
        <div className="header-data__inner">
          <UserAvatar user={creator} size={35} className="mh-10" />
          <div className="header-data__info">
            {farm.name && <div className="header-data__info-title">{farm.name}</div>}
            <div className="header-data__info-date">
              {`${creator.full_name} • ${moment(createdAt).format('MMM DD, YYYY, hh:mm a')}`}
            </div>
          </div>
        </div>
      </div>
      <div className="header-actions">
        <MediaQuery minDeviceWidth={813}>
          {isUserCanDiagnose && (
            <div className="diagnose-btn-block">
              <DiagnoseButton primary onClick={openDiagnosePanel} />
            </div>
          )}
        </MediaQuery>
        <div className="header-btn__close fa fa-times" onClick={closeViewer} />
      </div>
    </div>
  );
};

MediaViewerHeader.propTypes = {
  currentAsset: T.object.isRequired,
  dailyCheckup: T.object,
  creator: T.object,
  farm: T.object,
  openDiagnosePanel: T.func.isRequired,
  closeViewer: T.func.isRequired,
  isUserCanDiagnose: T.bool,
  isCollapsed: T.bool.isRequired,
  isFullScreenMode: T.bool.isRequired,
};

export default MediaViewerHeader;
