import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { createInvite } from '../../../reducers/admin/users';
// components
import SuperAdminForm from 'components/SuperAdmin/SuperAdmin/SuperAdminForm';
import FormErrorsBox from 'components/FormErrorsBox';
// utils
import { toastr } from 'react-redux-toastr';
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import pick from 'lodash.pick';

class SuperAdminCreate extends Component {

  handleFormSubmit = (data) => {
    const { createInvite } = this.props;
    if (data.user_status === 'found') {
      const reqParams = { method: 'POST', body: JSON.stringify({ email: data.email }) };
      return fetchFromAPI('/admin/admin_users', reqParams)
        .then(() => {
          this.context.router.push('/super-admin/admin-users');
          toastr.success('', 'Super Admin was successfully created', { icon: <i className="fa fa-thumbs-up" /> });
        })
        .catch(toastResponseErrors);
    }
    const entity = pick(data, ['email', 'first_name', 'last_name']);
    return createInvite({ ...entity, role: 'admin' })
      .then(() => {
        toastr.success('', 'Super Admin was successfully invited', { icon: <i className="fa fa-thumbs-up" /> });
        this.context.router.push('/super-admin/admin-users');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const customErrors = [
      { key: 'email', error: 'This user already Super Admin. Please choose a different email.' }
    ];
    return (
      <div className="small-12 column users-page">
        <section>
          <div className="panel overflow-visible">
            <div className="panel-heading column-on-mobile">
              <h1 className="lighter">Create Super Admin</h1>
            </div>
            <div className="panel-body">
              <section>
                <div className="row">
                  <div className="small-12 medium-10 large-8 column">
                    <FormErrorsBox formName="create-super-admin" customErrors={customErrors} />
                    <SuperAdminForm onSubmit={this.handleFormSubmit} />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

SuperAdminCreate.propTypes = {
  createInvite: T.func
};

SuperAdminCreate.contextTypes = {
  router: T.object
};

export default connect(
  null,
  { createInvite }
)(SuperAdminCreate);
