import { fetchFromAPI } from 'utils/api';

export const createAdminFarm = (resource) => {
  return fetchFromAPI('/admin/farms', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource);
};

export const getAdminFarm = (farmId) => {
  return fetchFromAPI(`/admin/farms/${farmId}`)
    .then((response) => response.resource);
};

export const updateAdminFarm = (farmId, resource) => {
  return fetchFromAPI(`/admin/farms/${farmId}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource);
};

export const getFarmBarn = (farmId, barnId) => {
  return fetchFromAPI(`/admin/farms/${farmId}/barns/${barnId}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const createFarmBarn = (farmId, resource) => {
  return fetchFromAPI(`/admin/farms/${farmId}/barns`, { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateFarmBarn = (farmId, barnId, resource) => {
  return fetchFromAPI(`/admin/farms/${farmId}/barns/${barnId}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateFarmBarnsSort = (farmId, sort_ids) => {
  return fetchFromAPI(`/admin/farms/${farmId}/barns/update_sort`, {
    method: 'put',
    body: JSON.stringify({ resource: { sort_ids } }),
  })
    .catch((response) => {
      throw response;
    });
};

export const getFarmDiagnoses = (farmId) => {
  return fetchFromAPI(`/admin/farms/${farmId}/farm_diagnoses`)
    .then((response) => response.resources)
    .catch((response) => {
      throw response;
    });
};

export const setDiagnosisData = (farmId, id, resource) => {
  return fetchFromAPI(`/admin/farms/${farmId}/farm_diagnoses/${id}`,
    { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getFarmVaccines = (farmId) => {
  return fetchFromAPI(`/admin/farms/${farmId}/vaccinations`)
    .then((response) => response.resources)
    .catch((response) => {
      throw response;
    });
};

export const addFarmVaccine = (farmId, resource) => {
  return fetchFromAPI(`/admin/farms/${farmId}/vaccinations`, { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateFarmVaccine = (farmId, vaccineId, resource) => {
  return fetchFromAPI(`/admin/farms/${farmId}/vaccinations/${vaccineId}`,
    { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteFarmVaccine = (farmId, vaccineId) => {
  return fetchFromAPI(`/admin/farms/${farmId}/vaccinations/${vaccineId}`, { method: 'delete' })
    .catch((response) => {
      throw response;
    });
};

export const getArrivalProtocols = (farmId) => {
  return fetchFromAPI(`/admin/farms/${farmId}/arrival_protocols`)
    .then((response) => response.resources)
    .catch((response) => {
      throw response;
    });
};

export const deleteArrivalProtocol = (farmId, assetId) => {
  return fetchFromAPI(`/admin/farms/${farmId}/arrival_protocols/${assetId}`, { method: 'delete' })
    .catch((response) => {
      throw response;
    });
};

export const updateArrivalProtocol = (farmId, assetId, resource) => {
  return fetchFromAPI(`/admin/farms/${farmId}/arrival_protocols/${assetId}`,
    { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getFarmTerms = (farmId, { perPage, page }) => {
  return fetchFromAPI(`/admin/farms/${farmId}/trucking_terms?page=${page}&per_page=${perPage}`);
};

export const updateFarmTerm = (farmId, termId, resource) => {
  return fetchFromAPI(`/admin/farms/${farmId}/trucking_terms/${termId}`,
    { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const searchAdminFarms = (params) => {
  return fetchFromAPI('/admin/farms/search', { params })
    .catch((response) => {
      throw response;
    });
};
