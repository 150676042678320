import React, { Fragment } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import PigGroupMovementsContainer from 'components/Groups/PigGroupMovementsContainer';
import PigGroupMigrationPanel from 'components/Groups/PigGroupMigrationPanel';
// endpoints
import { getFarmPigMigrations } from 'endpoints/farms';

const BarnSheetsFarmPigMovements = ({ currentUser, params: { farmId } }) => (
  <PigGroupMovementsContainer
    getPigGroupMovements={(reqParams) => getFarmPigMigrations(farmId, reqParams)}
    currentUser={currentUser}
  >
    {({ resources }, migrationClassName) => (
      <Fragment>
        {resources.map((migration) => (
          <PigGroupMigrationPanel
            key={migration.id}
            className={migrationClassName}
            currentUser={currentUser}
            migration={migration}
            redirectUrl={`/barnsheets/farms/${farmId}/pig-movements`}
          />
        ))}
      </Fragment>
    )}
  </PigGroupMovementsContainer>
);

BarnSheetsFarmPigMovements.propTypes = {
  params: T.object.isRequired,
  currentUser: T.object.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  })
)(BarnSheetsFarmPigMovements);
