import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
// styles, assets
import checkImage from '../../../../public/images/checkCard.svg';
import styles from './ImportTypeCard.module.scss';

const ImportTypeCard = ({
  title,
  type = 'medium',
  infoText,
  handleClick,
  isDisabled = false,
  isSelected,
  isSuggested = false,
  icon,
}) => {
  return (
    <div
      className={cn(styles.wrapper, {
        [styles['is-selected']]: isSelected,
        [styles['is-disabled']]: isDisabled,
        [styles['is-suggested']]: isSuggested,
        [styles['is-large']]: type === 'large',
        [styles['is-medium']]: type === 'medium',
      })}
      onClick={!isDisabled ? handleClick : null}
    >
      <div className={styles['check-icon']}>
        {!isSelected ? (
          <div className={styles.icon} />
        ) : (
          <img src={checkImage} alt="check" />
        )}
      </div>
      <div className={styles.image}>
        <img src={icon} alt="import type" />
      </div>
      <div className={styles.title}>
        <FormattedMessage id={title} tagName="h3" />
        <FormattedMessage id={infoText} />
      </div>
    </div>
  );
};

ImportTypeCard.defaultProps = {
  isDisabled: false,
  isSuggested: false,
  isSelected: false,
};

ImportTypeCard.propTypes = {
  title: T.string.isRequired,
  infoText: T.string.isRequired,
  handleClick: T.func.isRequired,
  type: T.oneOf(['medium', 'large']),
  isDisabled: T.bool,
  isSelected: T.bool,
  isSuggested: T.bool,
  icon: T.string,
};

export default ImportTypeCard;
