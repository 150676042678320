import { createAction, handleActions } from 'redux-actions';
// utils
import update from 'react-addons-update';
import findIndex from 'lodash.findindex';
import {
  farmWhenOneCheckupAllGood,
  getFarmPigGroupsOffline,
  getFarmOffline,
  updateFarmOffline,
} from 'utils/offlineHelper';
import { getFarmPigGroups, getCheckupFarm, setPigGroupAllGood, setPigGroupAllGoodOffline, startPigGroup,
  createPigGroupCheckup, createPigGroupCheckupOffline } from 'endpoints/checkup/farmGroups';
import { SET_CHECKUP } from './checkupEdit';
// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  farm: {},
  isFarmLoading: false,
  resources: [],
  isLoading: false,
};

const FETCH_FARM = 'checkupFarms/FETCH_FARM';
const [FETCH_FARM_PENDING, FETCH_FARM_FULFILLED, FETCH_FARM_REJECTED] = [
  `${FETCH_FARM}_PENDING`,
  `${FETCH_FARM}_FULFILLED`,
  `${FETCH_FARM}_REJECTED`,
];

const FETCH_FARM_GROUPS = 'checkupFarms/FETCH_FARM_GROUPS';
const [FETCH_FARM_GROUPS_PENDING, FETCH_FARM_GROUPS_FULFILLED, FETCH_FARM_GROUPS_REJECTED] = [
  `${FETCH_FARM_GROUPS}_PENDING`,
  `${FETCH_FARM_GROUPS}_FULFILLED`,
  `${FETCH_FARM_GROUPS}_REJECTED`,
];

const UPDATE_GROUP = 'checkupFarms/UPDATE_GROUP';
const [UPDATE_GROUP_PENDING, UPDATE_GROUP_FULFILLED, UPDATE_GROUP_REJECTED] = [
  `${UPDATE_GROUP}_PENDING`,
  `${UPDATE_GROUP}_FULFILLED`,
  `${UPDATE_GROUP}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFarmGroups = (id, params = {}) => (dispatch, getState) => {
  dispatch(createAction(FETCH_FARM_GROUPS_PENDING)());
  const { isOnline } = getState().network;
  return (isOnline ? getFarmPigGroups(id, params) : getFarmPigGroupsOffline(parseInt(id, 10)))
    .then((resources) => {
      dispatch(createAction(FETCH_FARM_GROUPS_FULFILLED)({ resources, params }));
      return resources;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_FARM_GROUPS_REJECTED)(response));
      throw response;
    });
};

export const fetchFarm = (id) => (dispatch, getState) => {
  dispatch(createAction(FETCH_FARM_PENDING)());
  const { isOnline } = getState().network;
  return (isOnline ? getCheckupFarm : getFarmOffline)(parseInt(id, 10))
    .then((resource) => {
      dispatch(createAction(FETCH_FARM_FULFILLED)({ resource }));
      return resource;
    })
    .catch((err) => {
      dispatch(createAction(FETCH_FARM_REJECTED)(err));
      throw err;
    });
};

export const setGroupAllGood = (id) => (dispatch, getState) => {
  dispatch(createAction(UPDATE_GROUP_PENDING));
  const {
    network: { isOnline },
    dailyCheckup: { farmGroups: { farm } }
  } = getState();
  return (isOnline ? setPigGroupAllGood : setPigGroupAllGoodOffline)(parseInt(id, 10))
    .then((resource) => {
      const newFarm = farmWhenOneCheckupAllGood(farm);
      updateFarmOffline(newFarm);
      dispatch(createAction(FETCH_FARM_FULFILLED)({ resource: newFarm }));
      dispatch(createAction(UPDATE_GROUP_FULFILLED)({ resource }));
    })
    .catch((err) => {
      dispatch(createAction(UPDATE_GROUP_REJECTED)(err));
      throw err;
    });
};

export const updateFarmGroup = (resource) => (dispatch) => {
  dispatch(createAction(UPDATE_GROUP_FULFILLED)({ resource }));
};

export const startGroup = (id, resource) => createAction(UPDATE_GROUP)(startPigGroup(id, resource));

export const startDailyCheckup = (id) => (dispatch, getState) => {
  const { network: { isOnline } } = getState();
  return (isOnline ? createPigGroupCheckup : createPigGroupCheckupOffline)(parseInt(id, 10))
    .then((checkup) => {
      dispatch(createAction(SET_CHECKUP)(checkup));
      return checkup;
    })
    .catch((err) => {
      throw err;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_FARM_GROUPS
  [FETCH_FARM_GROUPS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_FARM_GROUPS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    resources: action.payload.resources || [],
    isLoading: false,
  }),
  [FETCH_FARM_GROUPS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  // FETCH_FARM
  [FETCH_FARM_PENDING]: (state) => ({
    ...state,
    isFarmLoading: true,
  }),
  [FETCH_FARM_FULFILLED]: (state, action) => ({
    ...state,
    farm: action.payload.resource,
    isFarmLoading: false,
  }),
  [FETCH_FARM_REJECTED]: (state, action) => ({
    ...state,
    farm: action.payload,
    isFarmLoading: false,
  }),

  // UPDATE_GROUP
  [UPDATE_GROUP_FULFILLED]: (state, action) => {
    const { resource } = action.payload;
    const groupIndex = findIndex(state.resources, { id: resource.id });
    return {
      ...state,
      resources: (groupIndex !== -1)
        ? update(state.resources, { [groupIndex]: { $merge: resource } })
        : state.resources,
    };
  },
}, initialState);
