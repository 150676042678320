import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// components
import Modal from 'components/Modal/index';
import Button from 'components/Button';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// styles
import styles from './LoadModal.module.scss';
// translation
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(styles);

const AcceptCommitmentModal = ({ loadName: name, closeModal }) => (
  <Modal>
    <div className={cn('final-step')}>
      <div className={cn('body-wrap')}>
        <div className={cn('icon-wrap')}>
          <i className={cn('truck', 'fa fa-ep-truck')} />
          <i className={cn('overflow-icon', 'fa fa-check')} />
        </div>
        <p className={cn('description')}>
          <FormattedMessage id="component.declineLoadModal.loadAccepted" values={{ name }} />
        </p>
      </div>
      <Button wide light className={cn('ok-btn')} onClick={closeModal}>
        <FormattedMessage id="general.button.ok" />
      </Button>
    </div>
  </Modal>
);

AcceptCommitmentModal.propTypes = {
  loadName: T.string.isRequired,
  closeModal: T.func.isRequired,
};

export default connect(null, { closeModal })(AcceptCommitmentModal);
