import React, { Component, createRef } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import clamp from 'lodash.clamp';
import isFunction from 'lodash.isfunction';
import { getTagStyles, getTooltipSize, getTooltipPosition } from 'utils/imageTagHelper';
import { isEqualKeys } from 'utils';
// styles
import styles from './ImageTag.module.scss';

const cn = classnames.bind(styles);

class ImageTag extends Component {

  state = {
    isMouseOver: false,
    tooltipOffset: null,
    triangleOffset: null,
    position: 'top',
  };

  imageTag = createRef();

  componentDidMount() {
    const { tag, isEditable, hasHover } = this.props;
    this.setStyles(tag);
    this._mounted = true;
    if (isEditable && this.imageTag.current) {
      this.imageTag.current.addEventListener('mousedown', this.onMouseDown);
      document.addEventListener('mouseup', this.onDocMouseTouchEnd);
      this.imageTag.current.addEventListener('dragstart', () => { return false; });
    }
    if (hasHover && this.imageTag.current) {
      this.imageTag.current.addEventListener('mouseover', this.onMouseOver);
      this.imageTag.current.addEventListener('mouseleave', this.onMouseLeave);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isEditable, tag, hasCoordsUpdate } = this.props;
    if (hasCoordsUpdate && !isEqualKeys(['left', 'top', 'width', 'height'], tag, nextProps.tag)) {
      this.setStyles(nextProps.tag);
    }
    if (nextProps.isEditable && (nextProps.isEditable !== isEditable)) {
      this.imageTag.current.addEventListener('mousedown', this.onMouseDown);
      document.addEventListener('mouseup', this.onDocMouseTouchEnd);
      this.imageTag.current.addEventListener('dragstart', () => { return false; });
    }
  }

  componentWillUnmount() {
    const { isEditable, hasHover } = this.props;
    this._mounted = false;
    if (isEditable && this.imageTag.current) {
      this.imageTag.current.removeEventListener('mousedown', this.onMouseDown);
      document.removeEventListener('mouseup', this.onDocMouseTouchEnd);
      this.imageTag.current.removeEventListener('dragstart', () => { return false; });
    }
    if (hasHover && this.imageTag.current) {
      this.imageTag.current.removeEventListener('mouseover', this.onMouseOver);
      this.imageTag.current.removeEventListener('mouseleave', this.onMouseLeave);
    }
  }

  setStyles = (tag) => {
    this.imageTag.current.style.left = tag.left + '%';
    this.imageTag.current.style.top = tag.top + '%';
    this.imageTag.current.style.width = tag.width + '%';
    this.imageTag.current.style.height = tag.height + '%';
  };

  // coords for tooltip position
  getTooltipData = () => {
    const { isMobile, isTablet } = this.context;

    const currentTagNode = this.imageTag.current;
    const { parentElement: imageZone, offsetTop: tagOffsetTop, offsetLeft: tagOffsetLeft,
      offsetHeight: tagHeight, offsetWidth: tagWidth } = currentTagNode;
    const viewBoxNode = imageZone.parentElement;
    const imageOffsetTop = imageZone.offsetTop;
    const imageOffsetLeft = imageZone.offsetLeft;

    const availTopArea = imageOffsetTop + tagOffsetTop;
    const availLeftArea = imageOffsetLeft + tagOffsetLeft;
    const availBottomArea = viewBoxNode.offsetHeight - availTopArea - tagHeight;
    const availRightArea = viewBoxNode.offsetWidth - availLeftArea - tagWidth;

    const childNodes = [...currentTagNode.childNodes];
    const tooltipNode = childNodes.find((node) => node.dataset.name === 'tag-tooltip');

    if (!tooltipNode) {
      // eslint-disable-next-line no-console
      console.warn('No tooltip found!');
      return { tooltipOffset: null, triangleOffset: null, position: null };
    }

    const availAreas = { availTopArea, availLeftArea, availBottomArea, availRightArea };
    const tagSize = { tagWidth, tagHeight };
    const tooltipSize = getTooltipSize(tooltipNode);
    const { position, tooltipOffset, triangleOffset } = getTooltipPosition(tooltipSize, tagSize, availAreas);

    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    const isLandscape = screenWidth > screenHeight;

    if (isMobile && !isTablet && !isLandscape) {
      const tooltipMargin = (screenWidth - tooltipSize.tooltipWidth) / 2;
      const tooltipOffset = -availLeftArea + tooltipMargin;

      // triangle must be in tooltip area, 15px - offsets from left/right
      const triangleOffset = clamp(-tooltipOffset + (tagWidth / 2), 15, tooltipSize.tooltipWidth - 15);

      return {
        position: availTopArea > tooltipSize.tooltipHeight ? 'mobile-top' : 'mobile-bottom',
        tooltipOffset,
        triangleOffset, // triangle must be in tooltip area
      };
    }

    return { tooltipOffset: tooltipOffset || null, triangleOffset: triangleOffset || null, position };
  };

  onMouseOver = () => {
    this.over = true;
    this.mouseOverTimer = setTimeout(() => {
      if (!this._mounted || !this.over) {
        clearTimeout(this.mouseOverTimer);
        return;
      }
      this.setState(({ isMouseOver }) => {
        if (isMouseOver) {
          clearTimeout(this.mouseOverTimer);
          return null;
        }
        const tooltipData = this.getTooltipData();
        if (!tooltipData.position) return null;
        return { isMouseOver: true, ...tooltipData };
      }, () => clearTimeout(this.mouseOverTimer));
    }, 100);
  };

  onMouseLeave = () => {
    this.over = false;
    this.mouseLeaveTimer = setTimeout(() => {
      if (!this._mounted || this.over) {
        clearTimeout(this.mouseLeaveTimer);
        return;
      }
      this.setState(({ isMouseOver }) => {
        if (!isMouseOver) {
          clearTimeout(this.mouseLeaveTimer);
          return null;
        }
        return { isMouseOver: false };
      }, () => clearTimeout(this.mouseLeaveTimer));
    }, 100);
  };

  onMouseDown = (e) => {
    this.startDragEndResize = true;
    const startedPageX = e.pageX;
    const startedPageY = e.pageY;
    const { ord } = e.target.dataset;
    const currentTagNode = this.imageTag.current;
    const imageZone = currentTagNode.parentElement;

    const startedWidth = parseFloat(currentTagNode.style.width);
    const startedLeft = parseFloat(currentTagNode.style.left);
    const startedTop = parseFloat(currentTagNode.style.top);
    const startedHeight = parseFloat(currentTagNode.style.height);

    imageZone.onmousemove = (event) => {
      event.preventDefault();
      const currentPageX = event.pageX;
      const currentPageY = event.pageY;
      const { width, height, top, left } = this.imageTag.current.style;
      const currentStyles = {
        left: parseFloat(left),
        top: parseFloat(top),
        height: parseFloat(height),
        width: parseFloat(width),
      };

      if (ord) {
        const startedStyles = { startedWidth, startedLeft, startedTop, startedHeight };
        const startedPagePos = { startedPageX, startedPageY };
        const currentPagePos = { currentPageX, currentPageY };

        const styles = getTagStyles(ord, startedStyles, currentStyles, startedPagePos, currentPagePos, imageZone);
        this.setStyles(styles);
      } else {

        const coordsDiffX = startedPageX - currentPageX;
        const coordsDiffY = startedPageY - currentPageY;
        const leftDiff = (coordsDiffX / imageZone.offsetWidth) * 100;
        const topDiff = (coordsDiffY / imageZone.offsetHeight) * 100;

        const newLeft = clamp(startedLeft - leftDiff, 0, 100 - currentStyles.width);
        const newTop = clamp(startedTop - topDiff, 0, 100 - currentStyles.height);

        this.imageTag.current.style.left = newLeft + '%';
        this.imageTag.current.style.top = newTop + '%';
      }
    };
  };

  onDocMouseTouchEnd = () => {
    if (!this.startDragEndResize) return;
    const { onUpdatePosition } = this.props;
    const currentTagNode = this.imageTag.current;
    const imageZone = currentTagNode.parentElement;
    const { left, top, height, width } = currentTagNode.style;
    onUpdatePosition({
      left: parseFloat(left),
      top: parseFloat(top),
      height: parseFloat(height),
      width: parseFloat(width),
    });
    imageZone.onmousemove = null;
    this.startDragEndResize = false;
  };

  onTagClick = (e) => {
    const { onClick } = this.props;
    if ((e.target !== this.imageTag.current) || !isFunction(onClick)) return;
    onClick(e);
  };

  render() {
    const { isEditable, onClick, children } = this.props;
    const { isMouseOver, tooltipOffset, triangleOffset, position } = this.state;

    return (
      <div
        className={cn('image-tag', { 'draggable': isEditable, 'clickable': isFunction(onClick) && !isEditable })}
        onClick={this.onTagClick}
        ref={this.imageTag}
      >
        {children({ isMouseOver, tooltipOffset, triangleOffset, position })}
        {isEditable && (
          <>
            <div className={cn('drag-handle', 'ord-nw')} data-ord="nw" />
            <div className={cn('drag-handle', 'ord-n')} data-ord="n" />
            <div className={cn('drag-handle', 'ord-ne')} data-ord="ne" />
            <div className={cn('drag-handle', 'ord-e')} data-ord="e" />
            <div className={cn('drag-handle', 'ord-se')} data-ord="se" />
            <div className={cn('drag-handle', 'ord-s')} data-ord="s" />
            <div className={cn('drag-handle', 'ord-sw')} data-ord="sw" />
            <div className={cn('drag-handle', 'ord-w')} data-ord="w" />
          </>
        )}
      </div>
    );
  }
}

ImageTag.contextTypes = {
  isMobile: T.bool.isRequired,
  isTablet: T.bool.isRequired,
};

ImageTag.propTypes = {
  tag: T.object,
  onClick: T.func,
  onUpdatePosition: T.func,
  isEditable: T.bool,
  hasHover: T.bool,
  hasCoordsUpdate: T.bool,
  children: T.func.isRequired,
};

export default ImageTag;
