import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: {},
  isLoading: false,
  logs: [],
};

const FETCH_CURRENT_CHECKUP = 'barnsheets/FETCH_CURRENT_CHECKUP';
const [FETCH_CURRENT_CHECKUP_PENDING, FETCH_CURRENT_CHECKUP_FULFILLED, FETCH_CURRENT_CHECKUP_REJECTED] = [
  `${FETCH_CURRENT_CHECKUP}_PENDING`,
  `${FETCH_CURRENT_CHECKUP}_FULFILLED`,
  `${FETCH_CURRENT_CHECKUP}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCurrentCheckup = (id, isEditMode) => createAction(FETCH_CURRENT_CHECKUP)(
  fetchFromAPI(`/barnsheets/daily_checkups/${id}${isEditMode ? '/edit_mode_show' : ''}`)
);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_CURRENT_CHECKUP
  [FETCH_CURRENT_CHECKUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_CURRENT_CHECKUP_FULFILLED]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      logs: action.payload.resource.logs,
      isLoading: false,
    };
  },
  [FETCH_CURRENT_CHECKUP_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
