import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
import PigGroupCommitmentsPanel from 'components/PigGroupCommitmentsPanel';
// utils
import { isMobile } from 'react-device-detect';
import { tableNames } from 'utils/constants';

const DailyCheckupPigGroupCommitments = ({
  getCommitmentsCount,
  openMobileWizard,
  pigGroupId,
  router,
  location: { pathname },
}) => {
  const optionsGetter = (commitment) => [
    {
      onClick: () => router.push({ pathname: `/my-schedule/${commitment.id}`, query: { from: 'start-group-page' } }),
      light: true,
      label: <FormattedMessage id="general.button.reviewLoad" />,
      showOnMobile: true
    },
    {
      label: <FormattedMessage id="general.button.receiveLoad" />,
      primary: true,
      onClick: () => {
        if (isMobile) {
          openMobileWizard(commitment);
          return;
        }
        router.push({ pathname, query: { commitmentId: commitment.id } });
      },
      showOnMobile: true,
    }
  ];

  const linkProps = isMobile
    ? { onClick: () => openMobileWizard(), to: null }
    : { to: { pathname, query: { from: 'select-load' } } };

  const panelLink = (
    <Link className="pr-10 color-primary" isUnderlined {...linkProps}>
      <FormattedMessage id="general.continueWithoutLoad" />
    </Link>
  );

  return (
    <PigGroupCommitmentsPanel
      getCount={getCommitmentsCount}
      panelLink={panelLink}
      tableName={tableNames.startPigGroupCommitments}
      title={<FormattedMessage id="general.selectLoad" />}
      pigGroupId={pigGroupId}
      optionsGetter={optionsGetter}
    />
  );
};

DailyCheckupPigGroupCommitments.propTypes = {
  getCommitmentsCount: T.func.isRequired,
  pigGroupId: T.string.isRequired,
  router: T.object.isRequired,
  location: T.object.isRequired,
  openMobileWizard: T.func.isRequired,
};

export default DailyCheckupPigGroupCommitments;
