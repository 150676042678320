import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import ConditionCheckBox from './ConditionCheckBox';
import MobileInputLikeRow from 'components/MobileInputLikeRow';
// utils
import cn from 'classnames';
// styles
import styles from '../ConditionSelector.module.scss';

const NameConditionCheckBox = ({
  conditionValue,
  nameValue = '',
  namePlaceholder,
  onNameClick,
  onConditionValueChange,
  option,
}) => {
  const isOptionChecked = option.value === conditionValue;
  return (
    <Fragment>
      <ConditionCheckBox
        option={option}
        conditionValue={conditionValue}
        onConditionValueChange={onConditionValueChange}
      />
      {isOptionChecked && (
        <MobileInputLikeRow
          className={cn(styles['name-row'], { [styles['is-placeholder']]: !nameValue })}
          value={nameValue || namePlaceholder}
          onClick={onNameClick}
        />
      )}
    </Fragment>
  );
};

NameConditionCheckBox.propTypes = {
  conditionValue: T.string,
  nameValue: T.oneOfType([T.string, T.node]),
  namePlaceholder: T.oneOfType([T.string, T.node]).isRequired,
  option: T.object.isRequired,
  onConditionValueChange: T.func.isRequired,
  onNameClick: T.func.isRequired,
};

export default NameConditionCheckBox;
