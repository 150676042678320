import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/pt';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ko';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/vi';
import '@formatjs/intl-relativetimeformat/dist/locale-data/zh';
// intl plural rules polyfill (e.g IE11 & Safari 12-)
import 'intl-pluralrules';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/fr';
import 'moment/locale/ko';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
import 'moment/locale/th';

export const langOptions = [
  { label: 'English (US)', value: 'en' },
  { label: 'Español', value: 'es-CO' },
  { label: 'Português', value: 'pt-BR' },
  { label: 'Français', value: 'fr' },
  { label: '한국어', value: 'ko' },
  { label: 'Tiếng Việt', value: 'vi' },
  { label: '简体中文', value: 'zh-CN' },
  { label: 'ไทย ', value: 'th' },
];

export const momentLocales = { 'pt-BR': 'pt-br', 'es-CO': 'es', 'zh-CN': 'zh-cn' };
