import React from 'react';
import T from 'prop-types';
// components
import PhoneInput from 'components/PhoneInput/PhoneInput';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './PhoneInputField.module.scss';

const cn = classnames.bind(styles);

const PhoneInputField = ({ input, label, meta: { touched, error }, className, ...others }) => (
  <div className={cn('phone-input-field', className, { 'has-error': touched && error })}>
    {label &&
      <span className={cn('phone-input-label')}>{label}</span>}
    <PhoneInput {...input} {...others} />
  </div>
);

PhoneInputField.propTypes = {
  input: T.object.isRequired,
  label: T.oneOfType([T.string, T.object]),
  meta: T.object.isRequired,
  className: T.string,
};

export default PhoneInputField;
