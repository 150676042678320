import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';
import { FormattedMessage } from 'react-intl';
import SurveyPreviewModal from './SurveyPreviewModal';
import ReactSelect from 'components/Select/ReactSelect';
import LabeledInput from 'components/LabeledInput/LabeledInput';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import isEqual from 'lodash.isequal';
import classnames from 'classnames/bind';
import moment from 'moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// endpoints
import { updateCustomSurvey, addCustomSurvey, removeCustomSurvey } from 'endpoints/tenantSettings';
// styles
import styles from './SurveyBox.module.scss';

const periodTypes = [
  { value: 'day', label: <FormattedMessage id="general.timeOptions.daily" /> },
  { value: 'week', label: <FormattedMessage id="general.timeOptions.weekly" /> },
  { value: 'month', label: <FormattedMessage id="general.timeOptions.monthly" /> },
  { value: 'year', label: <FormattedMessage id="general.timeOptions.yearly" /> },
];

const cn = classnames.bind(styles);

class SurveyBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: props.surveys.length ? props.surveys : [{}],
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.surveys, this.props.surveys)) {
      this.setState(({ surveys }) => ({ surveys: this.props.surveys.length || surveys.length ?
        [...this.props.surveys, ...surveys.filter((survey) => !survey.id)] :
        [{}] }));
    }
  }

  addSurvey = () => { this.setState(({ surveys }) => ({ surveys: [...surveys, {}] })); };

  removeSurvey = (i) => { this.setState(({ surveys }) => ({ surveys: surveys.filter((_, idx) => i !== idx) })); };

  cancelEdit = (i) => {
    this.setState(({ surveys }) => ({
      surveys: surveys.map((survey, idx) => (idx === i ? this.props.surveys[i] : survey))
    }));
  };

  onTitleChange = (i) => ({ target: { value } }) => {
    if (value.length <= 50) {
      this.setState(({ surveys }) => ({
        surveys: surveys.map((survey, idx) => (idx === i ? { ...survey, title: value } : survey))
      }));
    }
  };

  onDateChange = (i) => (value) => {
    this.setState(({ surveys }) => ({
      surveys: surveys.map((survey, idx) => (idx === i ?
        { ...survey, start_date: value.format('MM/DD/YYYY') } :
        survey))
    }));
  };

  onFrequencyChange = (i) => ({ value }) => {
    this.setState(({ surveys }) => ({
      surveys: surveys.map((survey, idx) => (idx === i ? {
        ...survey,
        frequency: value,
        start_date: moment().format('MM/DD/YYYY')
      } :
        survey))
    }));
  };

  openPreview = (i) => {
    const { openModal } = this.props;
    const { surveys } = this.state;
    openModal(<SurveyPreviewModal survey={surveys[i].title} />);
  };

  onSave = (i) => {
    const { surveys } = this.state;
    const data = {
      ...surveys[i],
      start_date: moment(surveys[i].start_date, 'MM/DD/YYYY').format('LLLL'),
      active: true,
    };

    addCustomSurvey(data).then((resource) => {
      const { setSettings } = this.props;
      this.setState(({ surveys }) => ({
        surveys: surveys.filter((_, idx) => idx !== i)
      }));
      const updatedSurvey = { ...resource, start_date: moment(resource.start_date).format('MM/DD/YYYY') };
      setSettings({ surveys: [...this.props.surveys, updatedSurvey] });
    });
  };

  updateSurvey = (i) => {
    const { surveys } = this.state;
    const { setSettings } = this.props;
    const data = { ...surveys[i], start_date: moment(surveys[i].start_date, 'MM/DD/YYYY').format('LLLL') };
    updateCustomSurvey(surveys[i].id, data).then((resource) => {
      const updatedSurvey = { ...resource, start_date: moment(resource.start_date).format('MM/DD/YYYY') };
      setSettings({ surveys: this.props.surveys.map((survey, idx) => (idx === i ? updatedSurvey : survey)) });
    });
  };

  onRemove = (i) => {
    const { surveys, setSettings } = this.props;
    removeCustomSurvey(surveys[i].id).then(() => {
      this.setState(({ surveys }) => ({ surveys: surveys.filter((_, idx) => idx !== i) }));
      setSettings({ surveys: surveys.filter((_, idx) => idx !== i) });
    }).catch(toastResponseErrors);
  };

  showDeleteConfirmation = (i) => {
    const { openModal } = this.props;
    openModal(<ConfirmationModal
      noBorder
      warningMessage={(
        <FormattedMessage
          id="component.settings.customSurvey.confirmModal"
          values={{ red: (value) => <span style={{ color: 'red' }}>{value}</span> }}
        />
      )}
      handleConfirm={() => this.onRemove(i)}
      actionBtnLabel={<FormattedMessage id="component.settings.customSurvey.deleteSurvey" />}
      actionBtnProps={{ red: true }}
      title={<FormattedMessage id="component.settings.customSurvey.deleteSurvey" />}
    />);
  };

  hasSurveyChanged = (i) => {
    const { surveys } = this.props;
    return !isEqual(surveys[i], this.state.surveys[i]);
  };

  render() {
    const { surveys } = this.state;
    return (
      <div className={cn('survey-box')}>
        {surveys.map(({ frequency, title, start_date, id }, i) => (
          <div className={cn('survey-item')} key={i}>
            <div key={i} className={cn('survey-body', 'mb-15')}>
              <div className={cn('survey-item-box')}>
                <div className={cn('survey-item-label')}>
                  <FormattedMessage
                    id="component.settings.customSurvey.questionLength"
                    values={{
                      max: 50,
                      current: title ? title.length : 0,
                    }}
                  />
                </div>
                <FormattedMessage id="general.placeholder.typeQuestion">
                  {(placeholder) => (
                    <LabeledInput
                      label={<FormattedMessage id="general.yesOrNo" />}
                      className={cn({ 'saved': !this.hasSurveyChanged(i) && id })}
                      alignRight={false}
                      onChange={this.onTitleChange(i)}
                      value={title || ''}
                      placeholder={placeholder}
                      isBolded={false}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className={cn('survey-item-box', 'frequency')}>
                <div className={cn('survey-item-label')}>
                  <FormattedMessage id="general.frequency" />
                </div>
                <ReactSelect
                  noInputMargin
                  options={periodTypes}
                  placeholder={<FormattedMessage id="general.choose" />}
                  value={frequency}
                  onChange={this.onFrequencyChange(i)}
                  labelKey="label"
                  matchProp="value"
                  clearable={false}
                  searchable={false}
                />
              </div>
              <div className={cn('survey-item-box', 'start-date', { 'hidden': !frequency })}>
                <div className={cn('survey-item-label')}>
                  <FormattedMessage id="general.startDate" />
                </div>
                <SingleDatePicker
                  className={cn('survey-date')}
                  date={start_date ? moment(start_date, 'MM/DD/YYYY') : null}
                  onDateChange={this.onDateChange(i)}
                  isOutsideRange={(day) => !(day.isSameOrAfter(moment(), 'day'))}
                  displayFormat="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                />
              </div>
              {(!this.hasSurveyChanged(i) && id) && (
                <div className={cn('survey-item-box', 'remove-btn')}>
                  <i className="fa fa-trash-o" onClick={() => this.showDeleteConfirmation(i)} />
                </div>
              )}
            </div>
            <div className={cn('btn-group')}>
              <Button
                className={cn('light', 'preview-btn')}
                onClick={() => this.openPreview(i)}
                disabled={!title}
              >
                <i className="fa fa-remove-red-eye mr-5" />
                <FormattedMessage id="general.preview" />
              </Button>
              {(this.hasSurveyChanged(i) || !id) && (
                <>
                  <Button
                    className={cn('primary')}
                    disabled={!(frequency && title) || (!start_date || start_date.includes('_'))}
                    onClick={() => (id ? this.updateSurvey(i) : this.onSave(i))}
                  >
                    <FormattedMessage id="general.button.save" />
                  </Button>
                  <Button
                    disabled={id ? !this.hasSurveyChanged(i) : i === 0 && this.hasSurveyChanged(i)}
                    onClick={() => (id ? this.cancelEdit(i) : this.removeSurvey(i))}
                  >
                    <FormattedMessage id="general.button.cancel" />
                  </Button>
                </>
              )}
            </div>
          </div>
        ))}
        <Button light className={cn('add-survey')} onClick={this.addSurvey}>
          <FormattedMessage id="component.settings.customSurvey.addSurvey" />
        </Button>
      </div>
    );
  }
}

SurveyBox.propTypes = ({
  openModal: T.func.isRequired,
  surveys: T.array.isRequired,
  setSettings: T.func.isRequired,
});

export default connect(null, { openModal })(SurveyBox);
