import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose, withProps } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// redux
import { connect } from 'react-redux';
import { fetchData, resetResources, setDataItem } from 'reducers/dataTable';
import { setCompanyProfile, fetchCompanyProfile } from 'reducers/profiles';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import CompanyUserRolesManageTable from 'components/Admin/Companies/CompanyUserRolesManage/CompanyUserRolesManageTable';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import RolesManageFixedHeader from 'components/Admin/RolesManageFixedHeader/RolesManageFixedHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import TableFilter from 'components/TableFilter';
import PaginationWrapper from 'components/PaginationWrapper';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';
// styles
import styles from '../RolesManage.module.scss';

const cn = classnames.bind(styles);

const headerColumns = [
  { flex: '1 1 120px', name: <FormattedMessage id="component.dataTable.headers.userName" />, sortField: 'first_name' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.primary" />, sortField: 'is_owner' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.manager" />, sortField: 'is_manager' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.caregiver" />, sortField: 'is_caretaker' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.veterinarian" />, sortField: 'is_vet' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.common" />, sortField: 'is_common' },
];

const tableFilters = [
  { label: <FormattedMessage id="general.allUsers" />, value: '' },
  { label: <FormattedMessage id="general.role.primary" />, value: 'owner' },
  { label: <FormattedMessage id="general.role.farmCaregiver" />, value: 'caretaker' },
  { label: <FormattedMessage id="general.role.farmManager" />, value: 'manager' },
  { label: <FormattedMessage id="general.role.veterinarian" />, value: 'vet' },
  { label: <FormattedMessage id="general.role.common" />, value: 'common_user' },
];

class CompanyUsersRolesManage extends Component {

  componentDidMount() {
    const { fetchData, reqParams, fetchCompanyProfile, id } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
    fetchCompanyProfile(`/admin/companies/${id}`).catch(toastResponseErrors);
  }

  componentWillUnmount() {
    this.props.resetResources();
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  handleRadioChanged = (user, name, checked) => {
    const { id: companyId, profileCompany } = this.props;
    const paramsData = { name, id: user.id, company_id: companyId };
    const alreadyChecked = profileCompany.primary_contact_id === user.id;

    if (checked && alreadyChecked) {
      return this.removeCompanyUserRole(paramsData);
    }

    if (checked && !alreadyChecked) {
      return this.createCompanyUserRole(paramsData, user);
    }

    return this.removeCompanyUserRole(paramsData);
  };

  handleCheckBoxChanged = (user, name, checked) => {
    const { id: companyId } = this.props;
    const paramsData = { name, id: user.id, company_id: companyId };
    if (checked) return this.createCompanyUserRole(paramsData, user);
    return this.removeCompanyUserRole(paramsData);
  };

  revokeAllAccess = () => {
    const { fetchData, reqParams, id } = this.props;
    return fetchFromAPI(`/admin/companies/${id}/user_roles/destroy_all`, { method: 'delete' })
      .then(() => {
        showToastrMessage('component.toastr.roles.revoked');
        fetchData(reqParams);
      });
  };

  createCompanyUserRole(paramsData, { full_name, registration_state }) {
    const { id, setDataItem } = this.props;
    return fetchFromAPI(`/admin/companies/${id}/user_roles`, { method: 'post', body: JSON.stringify(paramsData) })
      .then(({ resource }) => {
        setDataItem(resource);
        this.removePreviousOwnerFlag(paramsData, full_name);
        if (registration_state === 'invited') {
          showToastrMessage('component.toastr.userInvite.successCreated');
        }
      })
      .catch(toastResponseErrors);
  }

  removePreviousOwnerFlag = (paramsData, userName) => {
    const { profileCompany, setCompanyProfile, setDataItem, resources } = this.props;
    if (paramsData.name === 'owner') {
      setCompanyProfile({
        ...profileCompany,
        primary_contact_id: paramsData.id,
        primary_contact_name: userName,
        status: profileCompany.is_farm_assigned ? 'active' : 'incomplete',
      });
      const userData = resources.find(({ id }) => id === profileCompany.primary_contact_id);
      if (userData) setDataItem({ ...userData, is_owner: false });
    }
  };

  removeCompanyUserRole(paramsData) {
    const { id, profileCompany, setCompanyProfile, setDataItem } = this.props;
    return fetchFromAPI(`/admin/companies/${id}/user_roles`, { method: 'delete', body: JSON.stringify(paramsData) })
      .then(({ resource }) => {
        setDataItem(resource);
        if (paramsData.name === 'owner') {
          setCompanyProfile({
            ...profileCompany,
            primary_contact_id: null,
            primary_contact_name: null,
            status: 'incomplete',
          });
        }
      })
      .catch(toastResponseErrors);
  }

  render() {
    const { resources, profileCompany, isLoading, meta, user, reqParams: { page, per_page, role, search, sort },
      id, onSearchChange, onSortChange, onPageChange, onPerPageChange, isCompanyLoading, route: { type } } = this.props;
    const { roles_map: { admin }, id: user_id } = user;
    const isCompanyOwner = user_id === profileCompany.primary_contact_id;

    const activeFilter = role && tableFilters.find(({ value }) => value === role).label;

    const userRolesLink = type === 'myCompanyUserRolesManage' ?
      '/admin/my-company/user-roles' :
      `/admin/companies/${id}/user-roles`;

    const rolesColumns = [
      {
        disabled: !admin,
        name: 'owner',
        labelKey: 'primary',
        fieldPath: 'is_owner',
        type: 'radio',
      },
      {
        disabled: !(admin || isCompanyOwner),
        name: 'manager',
        labelKey: 'manager',
        fieldPath: 'is_manager'
      },
      {
        disabled: !admin,
        name: 'caretaker',
        labelKey: 'caregiver',
        fieldPath: 'is_caretaker'
      },
      {
        disabled: !admin,
        name: 'vet',
        labelKey: 'veterinarian',
        fieldPath: 'is_vet'
      },
      {
        disabled: user.current_company.id === id,
        name: 'common_user',
        labelKey: 'common',
        fieldPath: 'is_common'
      },
    ];

    return (
      <div className={cn('roles-manage')}>
        <CompanyProfileHeader
          company={profileCompany}
          isLoading={isCompanyLoading}
          actualCompanyId={Number(id)}
          isSticky
        >
          <Button light className="mr-10 show-for-large" onClick={this.revokeAllAccess}>
            <i className="fa fa-times-circle mr-5" />
            <FormattedMessage id="general.button.revokeAllRoles" />
          </Button>
          <Link to={userRolesLink} className="button primary show-for-large wider">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </CompanyProfileHeader>

        <RolesManageFixedHeader
          className="show-for-large"
          panelTitle={<FormattedMessage id="general.userRoles" />}
          isEmptyTable={!resources.length}
          search={search}
          onSearchChange={onSearchChange}
          sort={sort}
          onSortChange={onSortChange}
          headerColumns={headerColumns}
        />

        <section className={cn('roles-manage-section')}>
          <div className="small-12 column">
            <Panel>
              <Panel.Heading title={<FormattedMessage id="general.userRoles" />} className="hide-for-large">
                <SearchBox initialValue={search} onChange={onSearchChange} />
              </Panel.Heading>

              <Panel.Body className={cn('roles-manage-body')}>
                <TableFilter
                  filters={tableFilters}
                  onFilterChange={this.onFilterChange}
                  activeFilter={role}
                  className="hide-for-large"
                >
                  <Button className="no-margin" darkGrey onClick={this.revokeAllAccess}>
                    <FormattedMessage id="general.button.revokeAllRoles" />
                  </Button>
                </TableFilter>

                <Preloader isActive={isLoading} />

                <PaginationWrapper
                  onlyBottom
                  totalItems={meta.total}
                  currentPage={page}
                  onPageChange={onPageChange}
                  perPage={per_page}
                  onPerPageChange={onPerPageChange}
                >
                  <CompanyUserRolesManageTable
                    data={resources}
                    company={profileCompany}
                    handleCheckBoxChanged={this.handleCheckBoxChanged}
                    handleRadioChanged={this.handleRadioChanged}
                    search={search}
                    filter={activeFilter}
                    isLoading={isLoading}
                    user={user}
                    rolesColumns={rolesColumns}
                  />
                </PaginationWrapper>
              </Panel.Body>
            </Panel>
          </div>
        </section>

        <StickyFooter className="hide-for-large">
          <Link to={userRolesLink} className="button primary big-button">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </StickyFooter>
      </div>
    );
  }
}

CompanyUsersRolesManage.propTypes = {
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  resetResources: T.func.isRequired,
  setCompanyProfile: T.func.isRequired,
  fetchCompanyProfile: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  profileCompany: T.object.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  user: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isCompanyLoading: T.bool.isRequired,
  id: T.number.isRequired,
  route: T.object.isRequired
};

const enhance = compose(
  connect(
    (state, { params: { id }, route: { type } }) => ({
      profileCompany: state.profiles.company.data,
      isCompanyLoading: state.profiles.company.isLoading,
      user: state.auth.user,
      resources: state.dataTable[type].resources,
      reqParams: state.dataTable[type].params,
      meta: state.dataTable[type].meta,
      isLoading: state.dataTable[type].isLoading,
      id: +(id || state.auth.user.current_company.id)
    }), {
      fetchData,
      setDataItem,
      resetResources,
      setCompanyProfile,
      fetchCompanyProfile
    }
  ), withProps(({ id, fetchData, setDataItem, resetResources,
    setCompanyProfile, fetchCompanyProfile, route: { type } }) => ({
    fetchData: (query) => fetchData(`/admin/companies/${id}/user_roles`, type, query),
    setDataItem: (data) => setDataItem(data, type),
    resetResources: () => resetResources(type),
    setCompanyProfile: (data) => setCompanyProfile(data),
    fetchCompanyProfile: (path) => fetchCompanyProfile(path),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(CompanyUsersRolesManage);
