import { fetchFromAPI } from 'utils/api';

export const getTreatments = () => {
  return fetchFromAPI('/treatment_products').then((response) => response.resources);
};

export const getTreatment = (id) => {
  return fetchFromAPI(`/treatment_products/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
