import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import ActivityBox from './ActivityBox';
import CongratsBox from './CongratsBox';
import EncouragementCountBox from './EncouragementCountBox/EncouragementCountBox';
// utils
import { formatMortality } from 'utils';
import classnames from 'classnames/bind';
import escape from 'lodash.escape';
// hoc
import withActivityController from './hoc/withActivityController';
// styles
import styles from './LowMortalityActivity.module.scss';
// icons
import trophyIcon from '../../../public/images/trophy-icon.png';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed'];

const LowMortalityActivity = ({ activity, currentUser, markAsSeen }) => {
  const { first_name, current_company } = currentUser;
  const {
    flagged, is_viewed,
    pig_group: { name: group_name, id, mortality_rate },
    farm: { name, farm_type },
  } = activity;

  return (
    <ActivityBox isFlagged={flagged} isUnseen={!is_viewed} markAsSeen={markAsSeen}>
      <CongratsBox>
        <div className={cn('congrats-icon-box')}>
          <img src={trophyIcon} className="encourage-icon" loading="lazy" alt="trophy-icon" />
        </div>
        <div className={cn('greeting-block')}>
          <span className={cn('header-text')}>
            <FormattedMessage id="general.congratulations" values={{ first_name }} />
          </span>
          <span className={cn('text')}>
            <FormattedMessage
              id="component.notifications.mortality_encouragement"
              values={{
                b: (msg) => (<b>{msg}</b>),
                group_name,
                farm_name: escape(name),
                farm_type,
                tenant_name: current_company.name,
              }}
            />
          </span>
        </div>
        <Link to={`/barnsheets/groups/${id}`} className="button blue mv-30">
          <FormattedMessage id="general.button.viewReport" />
        </Link>
        <div className={cn('count-boxes-list')}>
          <EncouragementCountBox
            message="general.closeoutMortality"
            count={formatMortality(mortality_rate)}
          />
        </div>
      </CongratsBox>
    </ActivityBox>
  );
};

LowMortalityActivity.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func.isRequired,
};

export default withActivityController(LowMortalityActivity, { affectRerenderKeys });
