import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import Input from 'components/Input';
import CheckboxMenu from './CheckboxMenu';
// utils
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
// filter constants
import {
  checkboxProps,
  entityNameSections,
  sectionInputPlaceholderIds,
  userFilterSectionTypes,
} from 'utils/constants/userFilters';
// styles
import styles from './Menu.module.scss';

const cn = classnames.bind(styles);

const EntityNameMenu = ({
  type,
  currentValue,
  onInputChange,
  inputValue,
  onSelect,
  selectedEntity,
  ...others
}) => (
  <CheckboxMenu {...pick(others, checkboxProps)} currentValue={currentValue}>
    {(value) => {
      const isValueSelected = value === currentValue;
      const showAsyncSelect = isValueSelected && ['only', 'except'].includes(value);
      return (
        <>
          {showAsyncSelect && (type === userFilterSectionTypes.farm) && (
            <AsyncSelect
              className={cn('value-async-select')}
              selected={!isEmpty(selectedEntity) ? { value: selectedEntity.id, label: selectedEntity.name } : null}
              onChange={onSelect}
              optionsPath="/farms/search"
              optionsKeys={{ value: 'id', label: 'name' }}
              placeholder={<FormattedMessage id="general.farmName" />}
            />
          )}
          {showAsyncSelect &&
            [userFilterSectionTypes.company, userFilterSectionTypes.managementCompany].includes(type) && (
              <AsyncSelect
                className={cn('value-async-select')}
                selected={!isEmpty(selectedEntity) ? { value: selectedEntity.id, label: selectedEntity.name } : null}
                onChange={onSelect}
                optionsPath="/companies/search"
                optionsKeys={{ value: 'id', label: 'name' }}
                placeholder={<FormattedMessage id="general.companyName" />}
              />
          )}
          {showAsyncSelect &&
            ([userFilterSectionTypes.user, userFilterSectionTypes.latestSvrCreatedBy].includes(type)) && (
              <AsyncSelect
                className={cn('value-async-select')}
                selected={!isEmpty(selectedEntity) ?
                  { value: selectedEntity.id, label: selectedEntity.full_name } :
                  null
                }
                requestParams={{ with_current_user: true }}
                optionsPath="/users/search"
                optionsKeys={{ value: 'id', label: 'full_name' }}
                onChange={onSelect}
                placeholder={<FormattedMessage id="general.userName" />}
              />
          )}
          {showAsyncSelect && (type === userFilterSectionTypes.pigGroup) && (
            <AsyncSelect
              className={cn('value-async-select')}
              selected={!isEmpty(selectedEntity) ?
                { value: selectedEntity.id, label: selectedEntity.name }
                : null}
              optionsPath="/pig_groups/search"
              optionsKeys={{ value: 'id', label: 'name' }}
              onChange={onSelect}
              placeholder={<FormattedMessage id="general.groupId" />}
            />
          )}
          {isValueSelected && !['only', 'except'].includes(value) && (
            <div className={cn('value-input-line')}>
              <FormattedMessage id={sectionInputPlaceholderIds[type]}>
                {(placeholder) => (
                  <Input
                    placeholder={placeholder}
                    className={cn('value-input', 'longer')}
                    value={inputValue}
                    onChange={onInputChange}
                  />
                )}
              </FormattedMessage>
            </div>
          )}
        </>
      );
    }}
  </CheckboxMenu>
);

EntityNameMenu.propTypes = {
  type: T.oneOf(entityNameSections).isRequired,
  isOpened: T.bool.isRequired,
  currentValue: T.string,
  options: T.array.isRequired,
  onConditionChange: T.func.isRequired,
  onInputChange: T.func.isRequired,
  inputValue: T.oneOfType([T.number, T.string]),
  selectedEntity: T.object,
  onSelect: T.func.isRequired,
  onApply: T.func.isRequired,
};

export default EntityNameMenu;
