import React, { Component } from 'react';
import T from 'prop-types';
// components
import GroupField from './GroupField';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DateRangePicker from 'components/DatePicker/DateRangePicker';
import AttentionBox from 'components/AttentionBox/AttentionBox';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// styles
import styles from './Section.module.scss';

const cn = classnames.bind(styles);

class DeliveryDateRangeSection extends Component {

  state = {
    currentStartDate: null,
    currentEndDate: null,
  };

  onChange = ({ startDate, endDate }) => this.setState({ currentStartDate: startDate, currentEndDate: endDate });

  onSaveDates = () => {
    const { currentStartDate, currentEndDate } = this.state;
    const data = { delivery_start: currentStartDate, delivery_end: currentEndDate };
    this.props.onSave(data);
  };

  renderFieldLabel = () => {
    const { startDate, endDate } = this.props;
    const formattedStartDate = <strong>{moment(startDate).format('MM/DD/YYYY')}</strong>;
    const formattedEndDate = <strong>{moment(endDate).format('MM/DD/YYYY')}</strong>;
    return (
      <FormattedMessage
        id="component.groupCreate.deliveryDatesLabel"
        values={{ startDate: formattedStartDate, endDate: formattedEndDate }}
      />
    );
  };

  render() {
    const { className, startDate, endDate, onClose, isDisabled, isOpened, onOpen } = this.props;
    const { currentEndDate, currentStartDate } = this.state;
    const isEmptyDates = Boolean(!currentStartDate || !currentEndDate);
    const isSameDates = moment(currentStartDate).isSame(startDate, 'day')
      && moment(currentEndDate).isSame(endDate, 'day');

    return (
      <GroupField
        className={className}
        label={this.renderFieldLabel()}
        title={<FormattedMessage id="component.groupCreate.estimatedDateRange" />}
        btnLabel={<FormattedMessage id="component.groupCreate.setDates" />}
        hint={<FormattedMessage id="component.groupCreate.estimatedDateRangeHint" />}
        isSaved={Boolean(startDate && endDate)}
        onOpen={onOpen}
        isOpened={isOpened}
        isDisabled={isDisabled}
      >
        <div className={cn('field-body')}>
          <div className={cn('field-body-title')}>
            <FormattedMessage id="component.groupCreate.dateRange" />
          </div>
          <div className="pv-10">
            <DateRangePicker
              startDate={currentStartDate || startDate}
              endDate={currentEndDate || endDate}
              isOutsideRange={(day) => (day.isBefore(moment(), 'day'))}
              onDatesChange={this.onChange}
            />
          </div>
          {!isEmptyDates && (
            <AttentionBox className={cn('lock-group-info')}>
              <FormattedMessage
                id="component.groupCreate.dateRangeInfo"
                values={{ date: moment(currentStartDate).format('MM/DD/YYYY') }}
              />
            </AttentionBox>
          )}
          <div className="pv-10">
            <Button
              primary
              disabled={isEmptyDates || isSameDates}
              onClick={this.onSaveDates}
            >
              <FormattedMessage id="general.button.save" />
            </Button>
            <Button className="btn-link ml-5" onClick={onClose}>
              <FormattedMessage id="general.button.cancel" />
            </Button>
          </div>
        </div>
      </GroupField>
    );
  }
}

DeliveryDateRangeSection.propTypes = {
  className: T.string,
  startDate: T.object,
  endDate: T.object,
  onSave: T.func.isRequired,
  onClose: T.func.isRequired,
  onOpen: T.func.isRequired,
  isOpened: T.bool,
  isDisabled: T.bool,
};

export default DeliveryDateRangeSection;
