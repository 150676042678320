import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal, closeModal } from 'reducers/modals';
import { saveEfficacyResponse } from 'reducers/dailyCheckup/checkupEdit';
// components
import { FormattedMessage } from 'react-intl';
import CheckupSegment from './CheckupSegment/CheckupSegment';
import TreatmentsEfficacyModal from './modals/TreatmentsEfficacyModal/TreatmentsEfficacyModal';
import Button from 'components/Button';
import DrBotAvatar from 'components/DrBotAvatar/DrBotAvatar';
// utils
import isEmpty from 'lodash.isempty';
import classnames from 'classnames/bind';
import { toastr } from 'react-redux-toastr';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// assets
import wellEfficacyImg from './images/well.svg';
import noChangeEfficacyImg from './images/nochange.svg';
import poorlyEfficacyImg from './images/poorly.svg';
import styles from './ReportTreatmentsEfficacySegment.module.scss';

const cn = classnames.bind(styles);

const efficacyVariants = {
  better: {
    img: wellEfficacyImg,
    label: 'gettingBetter',
    className: '',
  },
  no_changes: {
    img: noChangeEfficacyImg,
    label: 'notImproving',
    className: 'efficient-no-change',
  },
  worse: {
    img: poorlyEfficacyImg,
    label: 'gettingWorse',
    className: 'efficient-poorly',
  },
};

const canSeeModalRoles = ['caretaker', 'owner'];

const ReportTreatmentsEfficacySegment = ({
  checkup,
  closeModal,
  currentUser,
  openModal,
  saveEfficacyResponse,
}) => {
  const efficacyWithResponses = checkup.treatment_efficacies?.filter((te) => te.response);
  const hideSection = isEmpty(efficacyWithResponses);

  const handleSubmitEfficacy = (data = []) => {
    const promises = data.map(
      ({ efficacy_id, response }) => saveEfficacyResponse(checkup.id, efficacy_id, response)
    );
    const messageValues = {
      company_name: <b>{currentUser.current_company?.name}</b>,
    };

    Promise.all(promises)
      .then(() => {
        closeModal();
        toastr.light('', '', {
          icon: <DrBotAvatar />,
          position: 'bottom-center',
          showCloseButton: true,
          className: 'dr-bot',
          width: 400,
          timeOut: 6000,
          component: <FormattedMessage id="container.farmfeed.row.efficacy.successToastr" values={messageValues} />,
        });
      })
      .catch(toastResponseErrors);
  };

  useEffect(() => {
    const efficacyWithoutResponse = checkup.treatment_efficacies?.filter((te) => !te.response);
    if (!isEmpty(efficacyWithoutResponse) && checkup.role_names?.some((role) => canSeeModalRoles.includes(role))) {
      openModal(
        <TreatmentsEfficacyModal
          onSubmit={handleSubmitEfficacy}
          initialEfficacyRecords={efficacyWithoutResponse}
        />
      );
    }
  }, []);

  const handleEditTreatmentResponse = (efficacy) => {
    openModal(
      <TreatmentsEfficacyModal
        onSubmit={handleSubmitEfficacy}
        initialEfficacyRecords={[efficacy]}
      />
    );
  };

  return (
    !hideSection && (
      <CheckupSegment
        isReported
        itemsCount={efficacyWithResponses.length}
        isItemsCountHidden
        isButtonsHidden
        titleKey="general.drugEfficacy"
        noData={!efficacyWithResponses.length}
      >
        <div className={cn('reported-treatment-efficacies')}>
          {efficacyWithResponses.map((efficacy, index) => (
            <div
              className={cn('treatment-efficacy', efficacyVariants[efficacy.response.result].className)}
              key={`treatment-${index}`}
            >
              <div className={cn('efficient-img-wrapper')}>
                <img src={efficacyVariants[efficacy.response.result].img} alt={efficacy.response.result} />
              </div>
              <div className={cn('efficient-description')}>
                <FormattedMessage
                  id={`container.farmfeed.row.efficacy.pigs.${efficacyVariants[efficacy.response.result].label}`}
                  values={{
                    treatment: <b>{efficacy.dc_treatment_product?.treatment_product?.name}</b>,
                  }}
                />
              </div>
              <div className={cn('buttons-segment')}>
                <Button light className={cn('edit-button')} onClick={() => handleEditTreatmentResponse(efficacy)}>
                  <FormattedMessage id="general.button.edit" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </CheckupSegment>
    )
  );
};

ReportTreatmentsEfficacySegment.propTypes = {
  checkup: T.object.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  saveEfficacyResponse: T.func.isRequired,
  currentUser: T.object.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  }), {
    openModal,
    closeModal,
    saveEfficacyResponse,
  }
)(ReportTreatmentsEfficacySegment);
