import React, { PureComponent } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// recompose
import { compose } from 'recompose';
// hoc
import withDropdownActions from 'hoc/withDropdownActions';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import Subnavigation from 'components/Subnavigation/Subnavigation';
import TextLabel from 'components/TextLabel/TextLabel';
import GradientProgressBar from 'components/GradientProgressBar/GradientProgressBar';
import OfflineScreen from 'components/OfflineScreen';
import TruncateHint from 'components/TruncateHint';
// utils
import omit from 'lodash.omit';
import isNull from 'lodash.isnull';
import pickBy from 'lodash.pickby';
import isNil from 'lodash.isnil';
import cn from 'classnames';
import { formatMortality } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isTablet } from 'react-device-detect';
// sources api
import { enableSource, disableSource } from 'endpoints/sources';
// styles
import './Sources.scss';
// constants
import { sourceTypes } from 'constants.js';

const labels = {
  dataTable: {
    sourceName: <FormattedMessage id="general.sourceName" />,
    type: <FormattedMessage id="general.type" />,
    openGroups: <FormattedMessage id="general.openGroups" />,
    pigsPlaced: <FormattedMessage id="general.pigsPlaced" />,
    avgCloseoutMr: <FormattedMessage id="general.avgCloseoutMortality" />,
    avgArrivalCond: <FormattedMessage id="general.avgArrivalCond" />,
    status: <FormattedMessage id="component.dataTable.headers.status" />,
  },
  buttons: {
    view: <FormattedMessage id="general.button.view" />,
    viewSource: <FormattedMessage id="general.viewSource" />,
    viewSourcedGroups: <FormattedMessage id="general.viewSourcedGroups" />,
    viewTreatmentFeedback: <FormattedMessage id="general.viewTreatmentFeedback" />,
    manageSource: <FormattedMessage id="general.manageSource" />,
    activate: <FormattedMessage id="general.activate" />,
    activateSource: <FormattedMessage id="general.activateSource" />,
    deactivate: <FormattedMessage id="general.deactivate" />,
    deactivateSource: <FormattedMessage id="general.deactivateSource" />,
  },
  allSources: <FormattedMessage id="general.allSources" />,
  sources: <FormattedMessage id="general.sources" />,
  activeStatus: <FormattedMessage id="general.status.active" />,
  disabledStatus: <FormattedMessage id="general.status.disabled" />,
};

class Sources extends PureComponent {

  componentDidMount = () => {
    const { fetchData, setPageOptions, setBreadcrumbs, isOnline, params } = this.props;
    if (isOnline) fetchData(params);
    setPageOptions({ mobileTitle: 'externalSources' });
    setBreadcrumbs();
  };

  componentDidUpdate(prevProps) {
    const { isOnline, fetchData } = this.props;
    if (isOnline && isOnline !== prevProps.isOnline) {
      fetchData();
    }
  }

  componentWillUnmount = () => {
    const { setPageOptions } = this.props;
    setPageOptions({ mobileTitle: '' });
  };

  onSubnavFilterChange = ({ value } = {}) => {
    const { fetchData, params, isOnline } = this.props;
    if (isOnline) fetchData({ ...omit(params, 'active'), page: 1, type: value, status: '' });
  };

  onFilterChange = (status) => {
    const { fetchData, params } = this.props;
    fetchData({ ...params, page: 1, status, active: status && (status === 'active') });
  };

  enableExternalSource = ({ id }) => () => {
    const { setDataItem } = this.props;
    enableSource(id)
      .then(setDataItem)
      .catch(toastResponseErrors);
  };

  disableExternalSource = ({ id }) => () => {
    const { setDataItem } = this.props;
    disableSource(id)
      .then(setDataItem)
      .catch(toastResponseErrors);
  };

  renderNameColumn = (source) => (
    <CustomColumn label={labels.dataTable.sourceName}>
      <Link to={`/sources/${source.id}/overview`} className="color-primary">
        <TruncateHint name={source.name} />
      </Link>
    </CustomColumn>
  );

  renderTypeColumn = ({ source_type }) => (
    <CustomColumn label={labels.dataTable.type}>
      <TextLabel className="fs-11">{source_type}</TextLabel>
    </CustomColumn>
  );

  renderOpenGroupsColumn = ({ opened_pig_groups }) => (
    <CustomColumn textRight label={labels.dataTable.openGroups}>
      {opened_pig_groups}
    </CustomColumn>
  );

  getCondition = (value) => {
    if (isNull(value)) return 'null-state';
    if (value >= 75) return 'good';
    if (value >= 25) return 'average';
    return 'poor';
  };

  renderArrivalCondition = ({ avg_arrival_condition }) => {
    const condition = this.getCondition(avg_arrival_condition);
    return (
      <CustomColumn label={labels.dataTable.avgArrivalCond} className="average-condition-column">
        <span className="arrival-condition-content">
          <GradientProgressBar progress={avg_arrival_condition} width="80" />
          <span className={cn('condition-label', condition)}>
            {!isNull(avg_arrival_condition) ? condition : 'N/A'}
          </span>
        </span>
      </CustomColumn>
    );
  };

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.status}>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderPigsPlacedColumn = ({ pigs_placed }) => (
    <CustomColumn textRight noBottomBorder label={labels.dataTable.pigsPlaced}>
      <span>{pigs_placed}</span>
    </CustomColumn>
  );

  renderAvgCloseoutMR = ({ avg_closeout_mortality }) => (
    <CustomColumn textRight noBottomBorder label={labels.dataTable.avgCloseoutMr}>
      <span>{formatMortality(avg_closeout_mortality)}</span>
    </CustomColumn>
  );

  openDropdown = (e, source) => {
    const { openDropdown } = this.props;
    const options = [
      { label: labels.buttons.viewSource, url: `/sources/${source.id}` },
      { label: labels.buttons.viewSourcedGroups, url: `/sources/${source.id}/groups` },
      { label: labels.buttons.viewTreatmentFeedback, url: `/sources/${source.id}/treatment-feedback` },
      { label: labels.buttons.manageSource, url: `/sources/${source.id}/edit` },
      { label: labels.buttons.activateSource, onClick: this.enableExternalSource(source), hide: source.active },
      { label: labels.buttons.deactivateSource, onClick: this.disableExternalSource(source), hide: !source.active },
    ];
    openDropdown(e, options);
  };

  renderActionsColumn = (source, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <i
        data-name={`${source.id}-${rowIndex}`}
        className="fa fa-dots-three-horizontal dropdown-icon show-for-large"
        onClick={(e) => this.openDropdown(e, source)}
      />
      <Link to={`/sources/${source.id}`} className="button light hide-for-large">
        {labels.buttons.view}
      </Link>
      {!!source.active && (
        <Button default onClick={this.disableExternalSource(source)} className="hide-for-large">
          {labels.buttons.deactivate}
        </Button>
      )}
      {!source.active && (
        <Button default onClick={this.enableExternalSource(source)} className="hide-for-large">
          {labels.buttons.activate}
        </Button>
      )}
    </div>
  );

  renderExpandable = (source) => (
    <div>
      <Link className="button light small" to={`/sources/${source.id}`}>
        <i className="fa fa-edit mr-5" />
        {labels.buttons.view}
      </Link>
      <Button
        small
        light
        onClick={source.active ? this.disableExternalSource(source) : this.enableExternalSource(source)}
      >
        <i className={`fa fa-${source.active ? 'times' : 'check'}-circle mr-5`} />
        {labels.buttons[`${source.active ? 'deactivate' : 'activate'}`]}
      </Button>
    </div>
  );

  render() {
    const { resources, isLoading, meta: { total, status_stats = {}, type_stats = {} },
      params: { page, per_page, search, sort, status, type },
      onSearchChange, onSortChange, onPageChange, onPerPageChange, isOnline,
    } = this.props;

    const columns = [
      { label: labels.dataTable.sourceName, flex: '1 1 180px', renderer: this.renderNameColumn, sortKey: 'name' },
      { label: labels.dataTable.type, flex: '0 1 80px', renderer: this.renderTypeColumn, sortKey: 'source_type' },
      { label: labels.dataTable.openGroups, flex: '0 1 110px', renderer: this.renderOpenGroupsColumn, textRight: true },
      { label: labels.dataTable.pigsPlaced, flex: '1 1 120px', renderer: this.renderPigsPlacedColumn, textRight: true },
      { label: labels.dataTable.avgCloseoutMr, flex: '1 1 130px', renderer: this.renderAvgCloseoutMR, textRight: true },
      { label: labels.dataTable.avgArrivalCond, flex: '1 1 160px', renderer: this.renderArrivalCondition },
      { label: labels.dataTable.status, flex: '1 1 80px', renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 45px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };

    const subnavFilters = sourceTypes
      .map(({ label, value }) => ({ label, value, stats: type_stats[value] }))
      .filter(({ stats }) => Boolean(stats));

    const tableFilters = [
      { label: labels.allSources, value: '' },
      { label: labels.activeStatus, value: 'active', stats: status_stats.active },
      { label: labels.disabledStatus, value: 'disabled', stats: status_stats.disabled },
    ];

    return (
      <div className="Sources clearfix">
        <Subnavigation
          items={subnavFilters}
          onSelect={this.onSubnavFilterChange}
          defaultItem={labels.allSources}
          currentId={type}
          idKey="value"
          labelKey="label"
          className="small-12 column"
          isSticky
        >
          {isOnline && (
            <Link to="/sources/create" className="button small primary wider">
              <FormattedMessage id="general.createSource" />
            </Link>
          )}
        </Subnavigation>
        {isOnline
          ? (
            <section className="small-12 column">
              <Panel>
                <Panel.Heading title={labels.sources}>
                  <SearchBox initialValue={search} onChange={onSearchChange} />
                </Panel.Heading>

                <Panel.Body noPadding>
                  <Preloader isActive={isLoading} />

                  <TableFilter
                    filters={tableFilters}
                    activeFilter={status}
                    onFilterChange={this.onFilterChange}
                    stats={status_stats}
                    className="ph-10"
                  />

                  <DataTable
                    data={resources}
                    columns={columns}
                    sort={sort}
                    onSortChange={onSortChange}
                    paginationProps={paginationProps}
                    isExpandable={isTablet}
                    renderExpandable={this.renderExpandable}
                    scrollable
                    tableKey="sources"
                  />

                  <NothingBox
                    itemsName="external_sources"
                    display={!resources.length}
                    isLoading={isLoading}
                    search={search}
                  >
                    <FormattedMessage tagName="h1" id="component.nothingBox.noSources" />
                    <FormattedMessage tagName="p" id="component.sources.addSources" />
                  </NothingBox>
                </Panel.Body>

              </Panel>
            </section>
          )
          : <OfflineScreen />}
      </div>

    );
  }
}

Sources.propTypes = {
  resources: T.array,
  isLoading: T.bool,
  params: T.object,
  meta: T.object,
  fetchData: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  setDataItem: T.func.isRequired,
  isOnline: T.bool.isRequired,
  openDropdown: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.sources.resources,
      isLoading: state.dataTable.sources.isLoading,
      params: state.dataTable.sources.params,
      meta: state.dataTable.sources.meta,
      isOnline: state.network.isOnline,
    }), {
      fetchData: (data) => fetchData('/admin/external_sources', 'sources', pickBy(data, (value) => !isNil(value))),
      setDataItem: (data) => setDataItem(data, 'sources'),
      setPageOptions,
      setBreadcrumbs,
    }
  ),
  withDropdownActions,
  withDataTableController(),
);

export default enhance(Sources);
