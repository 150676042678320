import React from 'react';
import T from 'prop-types';
// components
import { Field as ReduxFormField } from 'redux-form';
import FormField from '../ReduxFormFields/FormField/FormField';

const Field = ({ placeholderKey, placeholder, ...props }, { formatMessage }) => {
  return (
    <ReduxFormField
      component={FormField}
      type="text"
      {...props}
      placeholder={placeholderKey ? formatMessage({ id: placeholderKey }) : placeholder}
    />
  );
};

Field.contextTypes = {
  formatMessage: T.func.isRequired,
};

Field.propTypes = {
  placeholderKey: T.string,
  placeholder: T.string,
};

export default Field;
