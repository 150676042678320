import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { openModal } from 'reducers/modals';
import { setTableParams } from 'reducers/tableParams';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import { useDropdownActions } from 'hooks/useDropdownContext';
// requests
import { fetchVirtualVisits, deleteVirtualVisit } from 'endpoints/virtualVisits';
// utils
import cn from 'classnames';
import moment from 'moment';
import { isTablet } from 'react-device-detect';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { tableNames } from 'utils/constants';

const VirtualVisits = ({
  currentUser,
  openModal,
  setBreadcrumbs,
  setTableParams,
  tableParams,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
  } = useDataTable(fetchVirtualVisits, {
    setTableParams: (params) => setTableParams(tableNames.virtualVisits, params),
    tableParams,
  });
  const { openDropdown } = useDropdownActions();
  const { page, per_page, sort } = tableParams;
  const { total } = meta;

  useEffect(() => {
    fetchData(tableParams).catch(toastResponseErrors);
    setBreadcrumbs([
      { label: <FormattedMessage id="general.virtualVisits" />, useLabelAsMobileTitle: true }
    ]);
  }, []);

  const handleOpenConfirmationModal = (virtualVisitId) => {
    const handleConfirm = () => {
      setIsDeleting(true);
      return deleteVirtualVisit(virtualVisitId)
        .then(() => {
          setIsDeleting(false);
          showToastrMessage('component.toastr.virtualVisit.hasBeenDeleted');
          const newPage = page > 1 && resources.length === 1
            ? page - 1
            : page;
          fetchData({ ...tableParams, page: newPage });
        })
        .catch((errors) => {
          setIsDeleting(false);
          toastResponseErrors(errors);
        });
    };
    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteVirtualVisit" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteVirtualVisit.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={handleConfirm}
      >
        <FormattedMessage id="component.modal.deleteVirtualVisit.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  const renderFarmNameColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.farmName" />}>
      <Link className="color-primary" to={`/virtual-visits/${rowData.id}`}>
        {rowData.farm.name}
      </Link>
    </CustomColumn>
  );

  const renderInviteeColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.invitee" />}>
      {rowData.invitee?.full_name}
    </CustomColumn>
  );

  const renderCreatorColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.creator" />}>
      {rowData.creator?.full_name}
    </CustomColumn>
  );

  const renderScheduleAtColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.scheduleAt" />}>
      {moment(rowData.schedule_at).format('MM/DD/YYYY, hh:mm A')}
    </CustomColumn>
  );

  const renderLinkColumn = (rowData) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="general.link" />}>
      <Link
        target="_blank"
        className="color-primary"
        to={`https://vv.everypig.com:4000/room/${rowData.token}`}
      >
        <FormattedMessage id="general.conference" />
      </Link>
    </CustomColumn>
  );

  const renderButtonsColumn = (rowData, { rowIndex }) => {
    const { id, schedule_at } = rowData;
    const isPastDate = moment(schedule_at).isBefore(moment());
    const isVisitEditable = rowData.creator.id === currentUser.id && !isPastDate;

    const options = [
      { label: <FormattedMessage id="general.button.view" />, url: `/virtual-visits/${id}` },
      { label: <FormattedMessage id="general.button.edit" />, url: `/virtual-visits/${id}/edit`,
        hide: !isVisitEditable },
      { label: <FormattedMessage id="general.button.delete" />, onClick: () => handleOpenConfirmationModal(id),
        hide: !isVisitEditable },
    ];
    return (
      <div className="collapsible-value button-column">
        <i
          data-name={`${id}-${rowIndex}`}
          className="fa fa-dots-three-horizontal show-for-large"
          onClick={(e) => openDropdown(e, options)}
        />
        <Link to={`/virtual-visits/${id}`} className="button light hide-for-large">
          <FormattedMessage id="general.button.view" />
        </Link>
        {isVisitEditable && (
          <Link to={`/virtual-visits/${id}/edit`} className="button light hide-for-large">
            <FormattedMessage id="general.button.edit" />
          </Link>
        )}
        {isVisitEditable && (
          <Button darkGrey className="hide-for-large" onClick={() => handleOpenConfirmationModal(id)}>
            <FormattedMessage id="general.button.delete" />
          </Button>
        )}
      </div>
    );
  };

  const renderExpandable = (rowData) => {
    const { id, schedule_at } = rowData;
    const isPastDate = moment(schedule_at).isBefore(moment());
    const isVisitEditable = rowData.creator.id === currentUser.id && !isPastDate;
    return (
      <div>
        {isVisitEditable && (
          <Button small light onClick={() => handleOpenConfirmationModal(id)}>
            <i className="fa fa-times-circle mr-5" />
            <FormattedMessage id="general.button.delete" />
          </Button>
        )}
        {isVisitEditable && (
          <Link to={`/virtual-visits/${id}/edit`} className="button small light">
            <i className="fa fa-pencil mr-5" />
            <FormattedMessage id="general.button.edit" />
          </Link>
        )}
        <Link to={`/virtual-visits/${id}`} className="button small light">
          <i className="fa fa-eye mr-5" />
          <FormattedMessage id="general.button.view" />
        </Link>
      </div>
    );
  };

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  const columns = [
    { label: <FormattedMessage id="general.farmName" />, flex: '1 1 200px', renderer: renderFarmNameColumn },
    { label: <FormattedMessage id="general.invitee" />, flex: '1 1 200px', renderer: renderInviteeColumn },
    { label: <FormattedMessage id="general.creator" />, flex: '1 1 200px', renderer: renderCreatorColumn },
    { label: <FormattedMessage id="general.scheduleAt" />, flex: '1 1 200px', renderer: renderScheduleAtColumn },
    { label: <FormattedMessage id="general.link" />, flex: '1 0 150px', renderer: renderLinkColumn },
    { label: '', flex: '0 0 45px', renderer: renderButtonsColumn, fixed: true,
      className: cn({ 'hide-for-large': isTablet }) },
    { label: '', flex: '0 0 45px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet, hasPinnedIcon: true },
  ];

  return (
    <Panel className="row centered pv-20 ph-15">
      <Panel.Heading title={<FormattedMessage id="general.pageTitle.virtualVisits" />}>
        {!!resources.length && (
          <Link to="/virtual-visits/create" className="button small primary wider">
            <FormattedMessage id="general.button.createVisit" />
          </Link>
        )}
      </Panel.Heading>
      <Panel.Body noPadding>
        <Preloader isActive={isLoading || isDeleting} />

        <DataTable
          data={resources}
          columns={columns}
          sort={sort}
          onSortChange={onSortChange}
          paginationProps={paginationProps}
          renderExpandable={renderExpandable}
          isExpandable={isTablet}
          isLoading={isLoading}
          scrollable
        />

        <NothingBox
          itemsName="virtualVisits"
          display={!resources.length}
          isLoading={isLoading}
        >
          <FormattedMessage id="component.nothingBox.anyVisitYet" tagName="h1" />
          <FormattedMessage id="component.nothingBox.createVirtualVisit" tagName="p" />
          <Link to="/virtual-visits/create" className="button primary mt-10">
            <FormattedMessage id="general.button.createVisit" />
          </Link>
        </NothingBox>
      </Panel.Body>
    </Panel>
  );
};

VirtualVisits.propTypes = {
  tableParams: T.object,
  currentUser: T.object.isRequired,
  openModal: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    tableParams: tableParamsSelector(state, tableNames.virtualVisits),
  }), {
    openModal,
    setBreadcrumbs,
    setTableParams,
  }
)(VirtualVisits);
