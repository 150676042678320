import { getAuthData } from './authData';
import { getQueryString } from './index';
// constants
import { FRONTEND_V2_URL } from 'constants.js';

export const buildFrontendV2Url = (url, redirectTarget = '') => {
  const headers = getAuthData();
  const authUrl = url + '?' + getQueryString(headers);
  const redirect = `&redirectTarget=${redirectTarget}`;
  return FRONTEND_V2_URL + authUrl + redirect;
};

export const authFrontendV2Url = (redirectTarget = '') => {
  return buildFrontendV2Url('/set-user', redirectTarget);
};
