import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import BarnForm from './components/BarnForm';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import isEmpty from 'lodash.isempty';
// barns API
import { getFarmBarn, updateFarmBarn } from 'endpoints/admin/farms';

class FarmBarnEdit extends Component {

  state = {
    barn: {},
    isLoading: false,
  };

  componentDidMount() {
    const { params: { id: farmId, barnId } } = this.props;
    this.fetchBarn(farmId, barnId);
  }

  fetchBarn = (farmId, barnId) => {
    this.setState({ isLoading: true });
    getFarmBarn(farmId, barnId)
      .then((barn) => {
        this.setState({ barn, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        return toastResponseErrors(error);
      });
  };

  updateBarn = (data) => {
    const { router } = this.context;
    const { params: { id: farmId, barnId } } = this.props;

    return updateFarmBarn(farmId, barnId, data)
      .then(() => {
        router.push(`/admin/farms/${farmId}/barns`);
        showToastrMessage('component.toastr.barn.updated');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { params } = this.props;
    const { barn, isLoading } = this.state;
    const initialValues = !isEmpty(barn)
      ? { name: barn.name, capacity: (barn.capacity || '').toString() }
      : null;

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.manageBarn" />} />
        <Panel.Body noPadding>
          <div className="row">
            <section className="small-12 medium-10 large-8 column">
              <Preloader isActive={isLoading} />
              <BarnForm
                isEditMode
                onSubmit={this.updateBarn}
                params={params}
                initialValues={initialValues}
              />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

FarmBarnEdit.propTypes = {
  params: T.object.isRequired,
};

FarmBarnEdit.contextTypes = {
  router: T.object.isRequired,
};

export default FarmBarnEdit;
