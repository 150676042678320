import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { formValueSelector, FieldArray, reduxForm } from 'redux-form';
import Field from 'components/Field';
import Button from 'components/Button';
import Input from 'components/Input';
import TrashButton from 'components/TrashButton';
import ReactSelect from 'components/Select/ReactSelect';
// utils
import cn from 'classnames';
import { langOptions } from 'utils/localizationHelper';
// assets
import './MortalityReasonForm.scss';

class MortalityReasonForm extends Component {

  addTranslation = () => {
    this.props.array.push('name', { lang: '', value: '' });
  };

  reasonNameField = ({ input, type, placeholder, readOnly, meta: { touched, error } }) => (
    <div className={(touched && error ? 'has-error' : '')}>
      <Input
        {...input}
        className="mr-input"
        placeholder={placeholder}
        type={type}
        readOnly={readOnly}
      />
    </div>
  );

  reasonFields = ({ fields }) => {
    const { names = [] } = this.props;
    const existedLangs = names.map((item) => item.lang);
    const options = langOptions.map((option) => ({ ...option, disabled: existedLangs.includes(option.value) }));
    return (
      <div className="row">
        {fields.map((translation, index) => (
          <div key={index} className="small-12 column">
            <div className="mr-name-row-title">
              <div className="mr-name-label">
                {!index
                  ? <FormattedMessage id="general.pageTitle.mortalityReason" />
                  : <FormattedMessage id="general.pageTitle.translatedReason" />}
              </div>
              {(fields.length > 1) &&
                <div className="mr-lang"><FormattedMessage id="general.pageTitle.language" /></div>}
              <div className="mr-lang-remove" />
            </div>
            <div className="mr-name-row">
              <div className="mr-name">
                <Field
                  placeholderKey={!index ? 'general.typeAName' : 'general.typeTranslation'}
                  name={`${translation}.value`}
                  component={this.reasonNameField}
                />
              </div>
              {(fields.length > 1) && (
                <div className="mr-lang">
                  <Field
                    name={`${translation}.lang`}
                    component={({ input }) => (
                      <ReactSelect
                        noInputMargin
                        value={input.value}
                        options={options}
                        labelKey="label"
                        onChange={(option) => (input.onChange(option.value))}
                        matchProp="value"
                        placeholder={<FormattedMessage id="general.selectLanguage" />}
                        clearable={false}
                        searchable={false}
                        disabled={!index}
                      />
                    )}
                  />
                </div>
              )}
              {(fields.length > 1) && (
                <div className="mr-lang-remove">
                  {!!index && <TrashButton onClick={() => fields.remove(index)} />}
                </div>
              )}
            </div>
          </div>
        ))}
        {(options.length > fields.length) && (
          <div className="small-12 column">
            <span onClick={this.addTranslation} className={cn('add-translation', { 'indent': fields.length > 1 })}>
              <FormattedMessage id="container.admin.healthVariables.reasons.addTranslation" />
            </span>
          </div>
        )}
      </div>
    );
  };

  isEmptySomeTranslation = (names = []) => {
    return names.some((item) => (!(item.value || '').trim() || !item.lang));
  };

  render() {
    const { names, handleSubmit, edit, pristine, submitting } = this.props;
    const isEmptyTranslation = this.isEmptySomeTranslation(names);

    return (
      <form onSubmit={handleSubmit} className="MortalityReasonForm">
        {/* Name */}
        <FieldArray name="name" component={this.reasonFields} rerenderOnEveryChange />

        <div className="row">
          <div className="column small-12 medium-4 large-2">
            <Field
              label={<FormattedMessage id="container.admin.healthVariables.reasons.code" />}
              placeholderKey="container.admin.healthVariables.reasons.code"
              name="code"
              type="number"
            />
          </div>
        </div>

        {/* Button */}
        <div className="row mt-20 mb-20">
          <div className="small-12 column">
            <Button
              primary
              type="submit"
              disabled={pristine || submitting || !!isEmptyTranslation}
              className="no-margin"
            >
              {edit
                ? <FormattedMessage id="general.button.saveChanges" />
                : <FormattedMessage id="container.admin.healthVariables.reasons.add" />}
            </Button>
            <Link to="/admin/health-variables/mortality-reasons" className="button btn-link margin-left-1">
              <FormattedMessage id="general.button.cancel" />
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

MortalityReasonForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  edit: T.bool,
  array: T.object,
  names: T.array,
};

const selector = formValueSelector('create-mortality-reason');

const enhance = compose(
  connect(
    (state) => ({
      names: selector(state, 'name'),
    })
  ),
);

export default reduxForm({
  form: 'create-mortality-reason',
  enableReinitialize: true,
})(enhance(MortalityReasonForm));
