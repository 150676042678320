import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// styles
import styles from './CongratsBox.module.scss';

const CongratsBox = ({ children }) => (
  <div className={styles['congrats-box']}>
    <div className={styles['label-block']}>
      <FormattedMessage id="general.onlyVisibleForYou">
        {(text) => <span className={styles.label}>{text}</span>}
      </FormattedMessage>
    </div>
    {children}
  </div>
);

CongratsBox.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default CongratsBox;
