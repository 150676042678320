import { fetchFromAPI } from 'utils/api';
import pickBy from 'lodash.pickby';

export const fetchAutoReportsPigGroups = (params) => {
  return fetchFromAPI('/auto_reports/pig_groups', { params: pickBy(params) })
    .catch((response) => {
      throw response;
    });
};

export const fetchAutoReportsStats = (params) => {
  return fetchFromAPI('/auto_reports/stats', { params: pickBy(params) })
    .catch((response) => {
      throw response;
    });
};

export const fetchAutoReportsPigGroupsCSV = (params) => {
  return fetchFromAPI('/auto_reports/pig_groups.csv', { blob: true, params: pickBy(params) }).catch((response) => {
    throw response;
  });
};
