import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { fetchData, setDataItem } from 'reducers/dataTable';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Button from 'components/Button';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import Avatar from 'components/Avatar/Avatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// endpoints
import { updatePackingCompany } from 'endpoints/admin/companies';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import { showToastrMessage, formatLocation } from 'utils';
import moment from 'moment';

const tableFilters = [
  { label: <FormattedMessage id="general.allCompanies" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
];

class PackingCompanies extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  updateCompany = (id, resource) => {
    const { setDataItem } = this.props;
    updatePackingCompany(id, resource)
      .then((company) => {
        setDataItem(company);
        showToastrMessage(`component.toastr.packing.${company.active ? 'enabled' : 'disabled'}`);
      })
      .catch(toastResponseErrors);
  };

  renderNameColumn = ({ name }) => (
    <CustomColumn flexRow>
      <Avatar type="packing-company" avatarSize={35} iconSize={16} className="mr-10" />
      <span className="semibold">{name}</span>
    </CustomColumn>
  );

  renderCreatedInfoColumn = ({ created_at }) => (
    <CustomColumn label={<FormattedMessage id="general.created" />}>
      {created_at ? moment(created_at).fromNow() : 'N/A'}
    </CustomColumn>
  );

  renderLocationColumn = ({ city, state }) => (
    <CustomColumn label={<FormattedMessage id="general.location" />}>
      {formatLocation(city, state)}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id, active }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.edit" />}
        buttonType="small light-gray"
        url={`/admin/packing-companies/${id}`}
        dropDownData={[
          {
            label: <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}PackingCompany`} />,
            onClick: () => this.updateCompany(id, { active: !active }),
          },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/packing-companies/${id}`} className="button light hide-for-large">
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button lightGray onClick={() => this.updateCompany(id, { active: !active })} className="hide-for-large">
        <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}PackingCompany`} />,
      </Button>
    </div>
  );

  renderExpandable = ({ id, active }) => (
    <div>
      <Link to={`/admin/packing-companies/${id}`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.button.edit" />
      </Link>
      {!active && (
        <Button small light onClick={() => this.updateCompany(id, { active: true })}>
          <i className="fa fa-check-circle mr-5" />
          <FormattedMessage id="general.button.enablePackingCompany" />
        </Button>
      )}
      {active && (
        <Button small light onClick={() => this.updateCompany(id, { active: false })}>
          <i className="fa fa-times-circle mr-5" />
          <FormattedMessage id="general.button.disablePackingCompany" />
        </Button>
      )}
    </div>
  );

  render() {
    const { resources, isLoading, meta: { stats, total }, reqParams: { page, per_page, status, search, sort },
      onPageChange, onPerPageChange, onStatusChange, onSortChange, onSearchChange } = this.props;
    const { isTablet } = this.context;

    const isEmptyTable = !resources.length && !status && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '2 1 130px',
        renderer: this.renderNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.location" />, flex: '1 1 100px', renderer: this.renderLocationColumn },
      { label: <FormattedMessage id="general.created" />, flex: '1 1 100px', renderer: this.renderCreatedInfoColumn },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 100px',
        renderer: this.renderStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 135px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.packingCompanies" />}>
            {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />
            }
          </Panel.Heading>
          <Panel.Body noPadding>
            {!isEmptyTable && (
              <TableFilter
                filters={tableFilters}
                activeFilter={status}
                onFilterChange={onStatusChange}
                stats={stats}
                className="ph-10"
              >
                <Link to="/admin/packing-companies/create" className="button small primary wider">
                  <FormattedMessage id="general.button.createPackingCompany" />
                </Link>
              </TableFilter>
            )}

            <Preloader isActive={isLoading} />

            <DataTable
              data={resources}
              columns={columns}
              sort={sort}
              onSortChange={onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
              tableKey="packingCompanies"
            />

            <NothingBox
              itemsName="packingCompanies"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
              filter={status}
            >
              <FormattedMessage id="component.nothingBox.anyPackingCompaniesYet" tagName="h1" />
              <FormattedMessage id="component.nothingBox.createPackingCompany" tagName="p" />
              <Link to="/admin/packing-companies/create" className="button primary mt-10">
                <FormattedMessage id="general.button.createPackingCompany" />
              </Link>
            </NothingBox>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

PackingCompanies.propTypes = {
  resources: T.array,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object,
};

PackingCompanies.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.packingCompanies.resources,
      reqParams: state.dataTable.packingCompanies.params,
      meta: state.dataTable.packingCompanies.meta,
      isLoading: state.dataTable.packingCompanies.isLoading,
    }), {
      fetchData: (query) => fetchData('/admin/packing_companies', 'packingCompanies', query),
      setDataItem: (data) => setDataItem(data, 'packingCompanies'),
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(PackingCompanies);
