import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatGrams } from 'utils';
import { formatDateRange } from 'utils/formatDateHelper';
import moment from 'moment';

export const getStatsCaption = {
  yesterday: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.yesterday" />;
    return moment().subtract(i, 'days').format('dddd, MM/DD');
  },
  week_to_date: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.weekToDate" />;
    return (
      <FormattedMessage
        id="component.statsOptions.week"
        values={{ value: moment().subtract(i - 1, 'week').format('W') }}
      />
    );
  },
  last_week: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.lastWeek" />;
    return (
      <FormattedMessage
        id="component.statsOptions.week"
        values={{ value: moment().subtract(i, 'week').format('W') }}
      />
    );
  },
  month_to_date: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.monthToDate" />;
    return moment().subtract(i - 1, 'month').format('MMM, YYYY');
  },
  last_month: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.lastMonth" />;
    return moment().subtract(i, 'month').format('MMM, YYYY');
  },
  last_30_days: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.last_days" values={{ value: 30 }} />;
    const endDate = moment().subtract((i - 1) * 30, 'day');
    const startDate = moment().subtract(((i * 30) - 1), 'day');
    return formatDateRange(startDate, endDate);
  },
  last_year: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.lastYear" />;
    return moment().subtract(i, 'year').format('YYYY');
  },
  year_to_date: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.yearToDate" />;
    return moment().subtract(i - 1, 'year').format('YYYY');
  },
  last_quarter: (i) => {
    if (i === 1) return <FormattedMessage id="component.statsOptions.lastQuarter" />;
    return 'Q' + moment().subtract(i, 'quarter').format('Q, Y');
  },
  quarter_to_date: (i) => {
    if (i === 1) return 'Q' + moment().format('Q, Y');
    return 'Q' + moment().subtract(i - 1, 'quarter').format('Q, Y');
  },
  custom: (i, { date_start, date_end }) => {
    if (!date_start || !date_end) return 'N/A - N/A';
    const daysCount = (date_end.diff(date_start, 'days') + 1);
    const endDate = moment(date_end).subtract((i - 1) * daysCount, 'day');
    const startDate = moment(date_start).subtract((i - 1) * daysCount, 'day');
    return formatDateRange(startDate, endDate);
  },
};

export const getStatsCaptionBalloon = {
  yesterday: (i) => {
    return moment().subtract(i, 'days').format('dddd, MM/DD');
  },
  week_to_date: (i) => {
    return moment().subtract(i - 1, 'week').format('W');
  },
  last_week: (i) => {
    return moment().subtract(i - 1, 'week').format('W');
  },
  month_to_date: (i) => {
    return moment().subtract(i - 1, 'month').format('MMM, YYYY');
  },
  last_month: (i) => {
    return moment().subtract(i, 'month').format('MMM, YYYY');
  },
  last_30_days: (i) => {
    const endDate = moment().subtract((i - 1) * 30, 'day');
    const startDate = moment().subtract(((i * 30) - 1), 'day');
    return formatDateRange(startDate, endDate);
  },
  last_year: (i) => {
    return moment().subtract(i, 'year').format('YYYY');
  },
  year_to_date: (i) => {
    return moment().subtract(i - 1, 'year').format('YYYY');
  },
  last_quarter: (i) => {
    return 'Q' + moment().subtract(i, 'quarter').format('Q, Y');
  },
  quarter_to_date: (i) => {
    if (i === 1) return 'Q' + moment().format('Q, Y');
    return 'Q' + moment().subtract(i - 1, 'quarter').format('Q, Y');
  },
  custom: (i, { date_start, date_end }) => {
    if (!date_start || !date_end) return 'N/A - N/A';
    const daysCount = (date_end.diff(date_start, 'days') + 1);
    const endDate = moment(date_end).subtract((i - 1) * daysCount, 'day');
    const startDate = moment(date_start).subtract((i - 1) * daysCount, 'day');
    return formatDateRange(startDate, endDate);
  },
};

export const getDatesFromRange = (range) => {
  switch (range) {
    case 'yesterday': return ({
      startDate: moment().subtract(1, 'day'),
      endDate: moment().subtract(1, 'day'),
    });
    case 'week_to_date': return ({
      startDate: moment().subtract(7, 'day'),
      endDate: moment(),
    });
    case 'last_week': return ({
      startDate: moment().subtract(1, 'weeks').startOf('isoWeek'),
      endDate: moment().subtract(1, 'weeks').endOf('isoWeek'),
    });
    case 'month_to_date': return ({
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
    });
    case 'last_month': return ({
      startDate: moment().subtract(1, 'month').startOf('month'),
      endDate: moment().subtract(1, 'month').endOf('month'),
    });
    case 'last_30_days': return ({
      startDate: moment().subtract(30, 'day'),
      endDate: moment(),
    });
    case 'year_to_date': return ({
      startDate: moment().subtract(1, 'year'),
      endDate: moment(),
    });
    case 'last_year': return ({
      startDate: moment().subtract(1, 'year').startOf('year'),
      endDate: moment().subtract(1, 'year').endOf('year'),
    });
    case 'last_quarter': return ({
      startDate: moment().subtract(1, 'quarter').startOf('quarter'),
      endDate: moment().subtract(1, 'quarter').endOf('quarter'),
    });
    case 'quarter_to_date': return ({
      startDate: moment().subtract(1, 'quarter').startOf('quarter'),
      endDate: moment(),
    });
    default: return ({
      startDate: null,
      endDate: null,
    });
  }
};

export const formatStatValue = (value) => (value ? value.toFixed(2) : null);

export const getGrowthRate = (current, prev) => {
  if (!prev) return '';
  return (((Number(current || 0) - Number(prev || 0)) / Number(prev)) * 100).toFixed(2);
};

export const groupAntibioticChartColors = {
  tenant_type_avg_g: '#FFB200',
  farm_avg_g: '#FF5E31',
  pig_group_avg_g: '#2170D1',
};

export const farmAntibioticChartColors = {
  tenant_type_avg_g: '#FF5E31',
  farm_avg_g: '#2170D1',
};

export const systemAntibioticChartColors = {
  sum_g1: '#00aeff',
  sum_g2: '#FFB200',
  sum_g3: '#FF5E31',
  sum_g4: '#2170D1',
};

const lineProps = {
  bullet: 'none',
  lineThickness: 2,
  lineColor: '#2170D1',
  type: 'smoothedLine',
};

const getColorTheme = (type) => {
  switch (type) {
    case 'pig_group':
      return groupAntibioticChartColors;
    case 'farm':
      return farmAntibioticChartColors;
    case 'system':
      return systemAntibioticChartColors;
    default:
      return {};
  }
};

const renderTooltipItems = (data, period_name, date_start, date_end, stats, formatMessage) => {
  let result = '';
  const grLabel = formatMessage({ id: 'general.short.grams' });
  stats.forEach((statsItem) => {
    const measureValue = data[`sum_g${statsItem}`];
    if (measureValue) {
      result += `
          <div class="antibiotic-legend-wrapper">\
            <div
             class="antibiotic-legend"
              style="background-color: ${systemAntibioticChartColors[`sum_g${statsItem}`]}">\
            </div>\
            <div class="antibiotic-legend-text">\
              ${getStatsCaptionBalloon[period_name](5 - statsItem, { date_start, date_end })} • \
              ${formatGrams(measureValue)} ${grLabel}\
            </div>\
          </div>`;
    }
  });
  return result;
};

export const antibioticBalloonRender = ({ dataContext }, usageByWeeks, period_name, date_start, date_end,
  stats, formatMessage) => {
  return `
  <div class="graph-tooltip antibiotic">\
    <div class="antibiotic-title">\
      ${formatMessage({ id: `general.${usageByWeeks ? 'week' : 'day'}` })} ${dataContext.position}
    </div>\
    ${renderTooltipItems(dataContext, period_name, date_start, date_end, stats, formatMessage)}\
  </div>`;
};

const renderFarmTooltipItems = (data, period_name, formatMessage, stats) => {
  let result = '';
  const grLabel = formatMessage({ id: 'general.short.grams' });
  stats.forEach((statItem) => {
    const grValue = data[statItem];
    if (grValue) {
      const textKey = statItem === 'tenant_type_avg_g'
        ? 'general.systemWideAverage.all'
        : 'general.farmAverage';
      result += `
        <div class="antibiotic-legend-wrapper">\
          <div class="antibiotic-legend" style="background-color: ${farmAntibioticChartColors[statItem]}"></div>\
          <div class="antibiotic-legend-text">\
            ${formatMessage({ id: textKey })} • ${formatGrams(grValue)} ${grLabel}\
          </div>\
        </div>`;
    }
  });
  return result;
};

export const antibioticFarmBalloonRender = ({ dataContext }, usageByWeeks, period_name, stats, formatMessage) => {
  return `
  <div class="graph-tooltip antibiotic">\
    <div class="antibiotic-title">${formatMessage({ id: `general.${usageByWeeks ? 'week' : 'day'}` })} \
    ${dataContext.position}</div>\
    ${renderFarmTooltipItems(dataContext, period_name, formatMessage, stats)}\
  </div>`;
};

const renderGroupTooltipItems = (data, formatMessage, stats) => {
  let result = '';
  const grLabel = formatMessage({ id: 'general.short.grams' });
  stats.forEach((statItem) => {
    const grValue = data[statItem];
    if (grValue) {
      const textKey = `general.${statItem === 'tenant_type_avg_g'
        ? 'systemWideAverage.all'
        : `antibiotic.${statItem}`}`;
      result += `\
        <div class="antibiotic-legend-wrapper">\
          <div class="antibiotic-legend" style="background-color: ${groupAntibioticChartColors[statItem]}"></div>\
          <div class="antibiotic-legend-text">\
            ${formatMessage({ id: textKey })} • ${formatGrams(grValue)} ${grLabel}\
          </div>\
        </div>`;
    }
  });
  return result;
};

export const antibioticGroupBalloonRender = ({ dataContext }, stats, formatMessage) => {
  return `
  <div class="graph-tooltip antibiotic">\
    <div class="antibiotic-title">${formatMessage({ id: 'general.week' })} ${dataContext.weeks_on_feed}</div>\
    ${renderGroupTooltipItems(dataContext, formatMessage, stats)}\
  </div>`;
};

function getDashLength(type, item) {
  switch (type) {
    case 'pig_group':
      return item !== 'pig_group_avg_g' ? 2 : null; // group line must be solid
    case 'farm':
      return item !== 'farm_avg_g' ? 2 : null; // farm line must be solid
    case 'system':
      return item !== 'sum_g4' ? 2 : null; // last period line must be solid
    default:
      return null;
  }
}

export const getGraphItemData = (item, type = 'pig_group', itemLimit) =>  {
  const color = getColorTheme(type)[item];
  const bullet = itemLimit === 1 ? 'round' : lineProps.bullet;
  const dashLength = getDashLength(type, item);
  return { ...lineProps, valueField: item, id: item, lineColor: color, dashLength, bullet };
};

const daysInQuarter = (i = 0) => {
  const startDate = moment().subtract(i, 'quarter').startOf('quarter');
  const endDate = moment().subtract(i, 'quarter').endOf('quarter');
  return endDate.diff(startDate, 'days') + 1;
};

const currentQuarterDay = () => {
  const startDate = moment().startOf('quarter');
  return moment().diff(startDate, 'days');
};

const currentMonthDay = () => {
  const startDate = moment().startOf('month');
  return moment().diff(startDate, 'days');
};

const getDaysCount = (dateRange = {}) => {
  const { date_start, date_end } = dateRange;
  if (!date_start || !date_end) return 0;
  return (date_end.diff(date_start, 'days') + 1);
};

export const getPeriodDuration = (period, dateRange) => {
  switch (period) {
    case 'yesterday':
      return { maxPeriodDuration: 1, periodLimits: { sum_g4: 1 } };
    case 'week_to_date':
      return { maxPeriodDuration: 7, periodLimits: { sum_g4: moment().weekday() - 1 } };
    case 'last_week':
      return { maxPeriodDuration: 7, periodLimits: { sum_g4: 7 } };
    case 'month_to_date':
      return {
        maxPeriodDuration: 31, periodLimits: {
          sum_g1: moment().subtract(3, 'month').daysInMonth(),
          sum_g2: moment().subtract(2, 'month').daysInMonth(),
          sum_g3: moment().subtract(1, 'month').daysInMonth(),
          sum_g4: currentMonthDay(),
        }
      };
    case 'last_month':
      return {
        maxPeriodDuration: 31, periodLimits: {
          sum_g1: moment().subtract(4, 'month').daysInMonth(),
          sum_g2: moment().subtract(3, 'month').daysInMonth(),
          sum_g3: moment().subtract(2, 'month').daysInMonth(),
          sum_g4: moment().subtract(1, 'month').daysInMonth(),
        }
      };
    case 'last_30_days':
      return { maxPeriodDuration: 30, periodLimits: {} };
    case 'last_year':
      return { maxPeriodDuration: 53, periodLimits: {} };
    case 'year_to_date':
      return { maxPeriodDuration: 53, periodLimits: { sum_g4: moment().week() } };
    case 'last_quarter':
      return {
        maxPeriodDuration: 92, periodLimits: {
          sum_g1: daysInQuarter(4),
          sum_g2: daysInQuarter(3),
          sum_g3: daysInQuarter(2),
          sum_g4: daysInQuarter(1),
        }
      };
    case 'quarter_to_date':
      return {
        maxPeriodDuration: 92, periodLimits: {
          sum_g1: daysInQuarter(3),
          sum_g2: daysInQuarter(2),
          sum_g3: daysInQuarter(1),
          sum_g4: currentQuarterDay(),
        }
      };
    case 'custom':
      return { maxPeriodDuration: getDaysCount(dateRange), periodLimits: {} };
    default:
      return { maxPeriodDuration: 0, periodLimits: {} };
  }
};
