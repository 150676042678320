import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { fetchAllFarms } from 'reducers/checkins/allFarms';
import { openPortal } from 'reducers/portal';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import CheckinsMapFilter from '../CheckinsMapFilter';
import ClusterMarker from 'components/GoogleMap/Markers/ClusterMarker/ClusterMarker';
import FarmMarker from 'components/GoogleMap/Markers/FarmMarker/FarmMarker';
import UserMarker from 'components/GoogleMap/Markers/UserMarker/UserMarker';
import FarmMarkerTooltip from 'components/GoogleMap/Markers/FarmMarker/FarmMarkerTooltip';
import UserMarkerTooltip from 'components/GoogleMap/Markers/UserMarker/UserMarkerTooltip';
import Preloader from 'components/Preloader';
// hocs
import Clusterify from 'components/GoogleMap/hocs/Clusterify';
import MapParamsController from 'components/GoogleMap/hocs/MapParamsController';
import MouseEnterListener from 'hoc/MouseEnterListener';
// utils
import cn from 'classnames';
import toProp from 'utils/toProp';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './CheckinsMap.scss';

const distanceCalculate = ({ x, y }, { x: mouseX, y: mouseY }, { shiftCoef = 0, shift = 25 }) => {
  const rangeX = x + shiftCoef * shift - mouseX;
  const rangeY = y - mouseY;
  return Math.sqrt(rangeX * rangeX + rangeY * rangeY);
};

export class CheckinsMap extends Component {

  componentDidMount() {
    const { fetchAllFarms, farms } = this.props;
    if (!farms) fetchAllFarms().catch(toastResponseErrors);
  }

  UNSAFE_componentWillReceiveProps({ center, setMapParams, mapParams }) {
    if (center && !mapParams.center) setMapParams({ center });
  }

  openMobileFilter = () => {
    const { openPortal, params, onFormChange } = this.props;
    openPortal(
      <CheckinsMapFilter
        params={params}
        isMobile
        fetchCheckins={onFormChange}
      />
    );
  };

  zoomIn = ({ lat, lng }) => {
    const { setMapParams } = this.props;
    setMapParams({
      zoom: 13,
      center: { lat, lng },
    });
  };

  onClusterClick = ({ lat, lng }) => {
    const { setMapParams, mapParams: { zoom } } = this.props;
    setMapParams({
      zoom: zoom + 3,
      center: { lat, lng },
    });
  }

  clearActiveEntity = (event) => {
    const { onEntityMouseLeave, onEntityTooltipMouseLeave } = this.props;
    onEntityMouseLeave();
    onEntityTooltipMouseLeave();
    event.stopPropagation();
  };

  onEntityMouseEnter = (key, elemProps) => {
    const { onEntityMouseEnter } = this.props;
    const type = key.includes('farm') ? 'user' : 'farm';
    if (!elemProps[type]) return;
    const entityId = parseInt(elemProps[type].id, 10);
    onEntityMouseEnter({
      id: entityId,
      parentId: type === 'user' && parseInt(elemProps.farm.id, 10),
      type,
    });
  }

  onMapChange = ({ zoom, center, bounds }) => {
    const { setMapParams } = this.props;
    setMapParams({ zoom, center, bounds });
  };

  renderFarmMarker = (farm) => {
    const {
      mapParams,
      activeEntity,
      activeEntityTooltip,
      onEntityMouseEnter,
      onEntityTooltipMouseEnter,
      onEntityTooltipMouseLeave,
      usersGroupedByFarm,
    } = this.props;
    const { isMobile } = this.context;
    const active = activeEntityTooltip || activeEntity;
    const isFarmActive = active && active.type === 'farm' && active.id  === farm.id;
    const farmUsers = usersGroupedByFarm[farm.id];
    const isUsersShown = farmUsers && mapParams.zoom > 9;

    return [
      <FarmMarker
        key={`farm-marker-${farm.id}`}
        className={cn('map-marker', { 'visible': farmUsers })}
        lat={farm.lat}
        lng={farm.lng}
        farm={farm}
        active={isFarmActive}
        onClick={toProp(isMobile && onEntityMouseEnter)}
        currentZoom={mapParams.zoom}
      >
        <FarmMarkerTooltip
          farm={farm}
          visible={isFarmActive}
          onTooltipClose={this.clearActiveEntity}
          zoomIn={this.zoomIn}
          onMouseEnter={toProp(!isMobile ? onEntityTooltipMouseEnter : null)}
          onMouseLeave={toProp(!isMobile ? onEntityTooltipMouseLeave : null)}
        />
      </FarmMarker>,
      isUsersShown && usersGroupedByFarm[farm.id].map((user, index) => (
        <UserMarker
          user={user}
          farm={farm}
          lat={farm.lat}
          lng={farm.lng}
          shiftCoef={index + 1}
          key={`user-marker-${farm.id}-${user.id}`}
          active={active && active.type === 'user' && active.id === user.id && active.parentId === farm.id}
          onClick={toProp(isMobile && onEntityMouseEnter)}
        >
          <UserMarkerTooltip
            user={user}
            farm={farm}
            visible={active && active.type === 'user' && active.id === user.id && active.parentId === farm.id}
            onTooltipClose={this.clearActiveEntity}
            zoomIn={this.zoomIn}
            onMouseEnter={toProp(!isMobile ? onEntityTooltipMouseEnter : null)}
            onMouseLeave={toProp(!isMobile ? onEntityTooltipMouseLeave : null)}
          />
        </UserMarker>
      ))
    ];
  };

  renderCluster = (cluster) => (
    <ClusterMarker
      key={`cluster-${cluster.id}`}
      lat={cluster.lat}
      lng={cluster.lng}
      cluster={cluster}
      onClick={this.onClusterClick}
    />
  );

  render() {
    const { clusters, mapParams, onEntityMouseLeave, onFormChange, isLoaded, isLoadingFarms, params } = this.props;
    const { isMobile } = this.context;

    return (
      <div className="CheckinsMap">
        <div className="content-wrapper">
          <div className="show-for-medium form-wrapper">
            <CheckinsMapFilter params={params} fetchCheckins={onFormChange} />
          </div>
          <div className="map-wrapper">
            <Preloader isActive={!isLoaded || isLoadingFarms} />

            {isLoaded && !isLoadingFarms && (
              <GoogleMap
                distanceToMouse={distanceCalculate}
                onChange={this.onMapChange}
                onChildMouseEnter={toProp(!isMobile && this.onEntityMouseEnter)}
                onChildMouseLeave={toProp(!isMobile && onEntityMouseLeave)}
                style={{ position: 'initial' }}
                {...mapParams}
              >
                {clusters.map((cluster) => (cluster.numPoints === 1
                  ? this.renderFarmMarker(cluster.points[0])
                  : this.renderCluster(cluster)
                ))}
              </GoogleMap>
            )}
          </div>
        </div>
        <div className="hide-for-medium filter-button">
          <Button primary className="big-button" onClick={this.openMobileFilter}>
            <i className="fa fa-filter mr-10" />
            <FormattedMessage id="component.assetMap.filter" />
          </Button>
        </div>
      </div>
    );
  }
}

CheckinsMap.propTypes = {
  activeEntityTooltip: T.object,
  activeEntity: T.object,
  isLoaded: T.bool.isRequired,
  isLoadingFarms: T.bool.isRequired,
  search: T.string,
  clusters: T.array.isRequired,
  usersGroupedByFarm: T.object.isRequired,
  onFormChange: T.func.isRequired,
  mapParams: T.object.isRequired,
  onEntityMouseLeave: T.func.isRequired,
  onEntityMouseEnter: T.func.isRequired,
  onEntityTooltipMouseLeave: T.func.isRequired,
  onEntityTooltipMouseEnter: T.func.isRequired,
  setMapParams: T.func.isRequired,
  openPortal: T.func.isRequired,
  fetchAllFarms: T.func.isRequired,
  farms: T.array,
  center: T.shape({
    lat: T.number,
    lng: T.number,
  }),
  params: T.object.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      farms: state.checkins.allFarms.data,
      isLoadingFarms: state.checkins.allFarms.isLoading,
    }), {
      fetchAllFarms,
      openPortal,
    },
  ),
  withPropsOnChange(
    ['farms', 'search'],
    ({ farms = [], search, usersGroupedByFarm }) => {
      if (!search) return {};
      return {
        farms: farms.filter(({ id, name }) => usersGroupedByFarm[id] || name.includes(search)),
      };
    }
  ),
  MouseEnterListener(),
  MapParamsController({ zoom: 10 }),
  Clusterify('farms'),
);

export default enhance(CheckinsMap);
