import React from 'react';
import T from 'prop-types';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import './ChartPlaceholder.scss';

const ChartPlaceholder = ({ chartStyle = {}, placeholder = <FormattedMessage id="general.noDataForPeriod" /> }) => (
  <div style={chartStyle} className="ChartPlaceholder">
    <div className="container">
      <div><i className="fa fa-search" /></div>
      <div className="text-placeholder">
        {placeholder}
      </div>
    </div>
  </div>
);

ChartPlaceholder.propTypes = {
  chartStyle: T.object,
  placeholder: T.object,
};

export default ChartPlaceholder;
