import last from 'lodash.last';
import Geocode from 'utils/geocodeHelper';

const createAddressInfo = async (countriesData, method, geodata, callback) => {
  const {
    error,
    latlng,
    countryKey,
    zipcode,
    city,
    strNumber,
    route,
    region,
  } = await Geocode[method]([...geodata]);

  if (error) return;

  const countryObj = countriesData.find(({ iso }) => (iso === countryKey));
  const countryData = last(countryObj?.fields || [])?.locality.reduce(((prev, next) => Object.assign(prev, next)), {});
  const stateOptions = countryData?.administrativearea?.options || [];
  let state = '';

  if (stateOptions.length) {
    stateOptions.forEach((opt) => {
      const fullName = Object.values(opt)[0];
      if (fullName === region) {
        const [firstStateName] = Object.keys(opt);
        state = firstStateName;
      }
    });
  }

  callback({
    country_code: countryObj ? countryObj.iso : countryKey,
    address: [strNumber, route].filter((c) => c).join(' ').trim(),
    city,
    state: state || region,
    zipcode,
    lat: latlng.lat,
    lng: latlng.lng,
  });
};

export default createAddressInfo;
