import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { createCompany } from 'reducers/admin/companies';
// components
import { FormattedMessage } from 'react-intl';
import CreateCompanyForm from './components/CreateCompanyForm';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isEmpty from 'lodash.isempty';
import { showToastrMessage } from 'utils';

const customErrors = [
  { key: 'name', error: <FormattedMessage id="general.customErrors.company" /> },
];

class CreateCompany extends Component {

  createNewCompany = (data) => {
    const { router } = this.context;
    const { createCompany } = this.props;
    const companyData = { ...data };
    if (companyData.user && !isEmpty(companyData.telephone)) {
      companyData.user.phone = companyData.telephone.phone;
      companyData.user.country_code = companyData.telephone.countryCode;
      delete companyData.telephone;
    }

    return createCompany(data)
      .then((company) => {
        showToastrMessage('component.toastr.company.created');
        router.push(`/admin/companies/${company.id}/user-roles`);
      })
      .catch(toastResponseErrors);
  };

  render() {
    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.createCompany" />} />
          <Panel.Body>
            <div className="row">
              <div className="small-12 medium-10 large-8 column">
                <FormErrorsBox
                  excludedFields={['user']}
                  formName="create-company-by-admin"
                  customErrors={customErrors}
                />
                <CreateCompanyForm onSubmit={this.createNewCompany} />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

CreateCompany.contextTypes = {
  router: T.object,
};

CreateCompany.propTypes = {
  createCompany: T.func,
};

export default connect(null, { createCompany })(CreateCompany);
