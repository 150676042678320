import React, { Component } from 'react';
import T from 'prop-types';
// redux-form
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
// components
import Button from 'components/Button';
import RolesFields from 'components/Admin/RolesFields';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
// constants
import { companyTenantRoles } from 'constants.js';

class EditAdminForm extends Component {

  disableAccountOwnerRole = (role) => {
    const { authUser } = this.props;
    return role === 'tenant_owner' && !authUser.roles_map.tenant_owner;
  };

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    const disabledBtn = pristine || submitting;

    return (
      <form onSubmit={handleSubmit}>

        <RolesFields
          roles={companyTenantRoles}
          title={<FormattedMessage id="component.userFieldsMethods.userType" />}
          checkSelection={this.disableAccountOwnerRole}
        />

        <div className="mt-20">
          <Button primary className="wider" type="submit" disabled={disabledBtn}>
            <FormattedMessage id="general.button.save" />
          </Button>
          <Link to="/admin/my-company/admins" className="button btn-link ml-10">
            <FormattedMessage id="general.button.cancel" />
          </Link>
        </div>

      </form>
    );
  }
}

const formComponent = reduxForm({
  form: 'edit-admin',
  enableReinitialize: true,
})(EditAdminForm);

EditAdminForm.propTypes = {
  handleSubmit: T.func.isRequired,
  authUser: T.object.isRequired,
  pristine: T.bool,
  submitting: T.bool,
};

export default connect((state) => ({
  authUser: state.auth.user,
}))(formComponent);
