import React, { Component } from 'react';
import T from 'prop-types';
// utils
import withOutsideClick from 'react-onclickoutside';
import classnames from 'classnames/bind';
import isFunction from 'lodash.isfunction';
// styles
import styles from './UserFilterSectionDropdown.module.scss';

const cn = classnames.bind(styles);

class UserFilterSectionDropdown extends Component {

  state = {
    isOpened: this.props.isInitialOpened,
  };

  handleClick = () => this.setState(({ isOpened }) => ({ isOpened: !isOpened }));

  handleClickOutside = () => {
    if (this.state.isOpened) {
      this.setState({ isOpened: false });
      const { onClose } = this.props;
      if (isFunction(onClose)) onClose();
    }
  };

  renderTrigger = (isOpened) => {
    const { currentValue, isDisabled, triggerLabel, isRemovable, onRemove, triggerIcon } = this.props;
    return (
      <>
        <i className={cn('filter-icon', triggerIcon)} />
        <div className={cn('search-label')}>{triggerLabel}</div>
        {isRemovable && !isDisabled && (
          <i
            className={cn('fa fa-times', 'remove-icon', { 'visible': isOpened || !currentValue })}
            onClick={onRemove}
          />
        )}
      </>
    );
  };

  render() {
    const { className, isDisabled, menuRenderer, triggerRenderer, isInvalid } = this.props;
    const { isOpened } = this.state;
    const hasDefaultTrigger = !triggerRenderer;
    return (
      <div
        onClick={!isDisabled ? this.handleClick : null}
        className={cn('filter-section-dropdown', className, {
          'has-default-trigger': hasDefaultTrigger,
          'disabled': isDisabled,
          'opened': isOpened && hasDefaultTrigger,
          'empty-value': !isOpened && isInvalid && hasDefaultTrigger,
        })}
      >
        {(triggerRenderer || this.renderTrigger)(isOpened)}
        {menuRenderer(isOpened)}
      </div>
    );
  }
}

UserFilterSectionDropdown.propTypes = {
  isInitialOpened: T.bool,
  isDisabled: T.bool,
  className: T.string,
  isRemovable: T.bool,
  currentValue: T.string,
  onClose: T.func,
  onRemove: T.func,
  menuRenderer: T.func.isRequired,
  triggerLabel: T.oneOfType([T.node, T.string]),
  triggerRenderer: T.func,
  triggerIcon: T.string,
  isInvalid: T.bool,
};

UserFilterSectionDropdown.defaultProps = {
  isInitialOpened: false,
  isDisabled: false,
  isRemovable: true,
  isInvalid: false,
  currentValue: '',
};

export default withOutsideClick(UserFilterSectionDropdown);
