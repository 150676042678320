import React, { useState, Fragment } from 'react';
import T from 'prop-types';
// components
import DotSeparator from 'components/DotSeparator/DotSeparator';
import ReactCollapse from 'react-collapse';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// constants
import { generalInfo, groupInfo } from './constants';
// styles
import styles from './FarmInformationBar.module.scss';

const cn = classnames.bind(styles);

const GENERAL_COLLAPSE = 'general';
const DETAILS_COLLAPSE = 'details';
const GROUP_COLLAPSE = 'group';

const FarmInformationBar = ({ farm, currentCompany }) => {
  const [collapses, toggleCollapse] = useState([]);

  const onToggle = (key) => (collapses.includes(key) ?
    toggleCollapse((prevCollapses) => prevCollapses.filter((itemKey) => itemKey !== key)) :
    toggleCollapse((prevCollapses) => [...prevCollapses, key]));

  const isGroupsExist = !!farm.groups?.length;

  return (
    <Fragment>
      {/* GENERAL INFO */}
      <div className={cn('collapse')}>
        <div className={cn('collapse-header')} onClick={() => onToggle(GENERAL_COLLAPSE)}>
          <FormattedMessage id="general.generalInfo" />
          <i
            className={cn('fa fa-angle-right-btb', 'collapse-icon', { opened: collapses.includes(GENERAL_COLLAPSE) })}
          />
        </div>
        <ReactCollapse isOpened={collapses.includes(GENERAL_COLLAPSE)}>
          <div className={cn('collapse-content')}>
            {generalInfo.map(({ label, renderer }, idx) => (
              <div key={idx} className={cn('collapse-content-row')}>
                {label}<DotSeparator />{renderer(farm, currentCompany.name)}
              </div>
            ))}
          </div>
        </ReactCollapse>
      </div>

      {/* GROUPS INFO */}
      {isGroupsExist && (
        <div className={cn('collapse')}>
          <div className={cn('collapse-header')} onClick={() => onToggle(DETAILS_COLLAPSE)}>
            <FormattedMessage id="general.groupDetails" />
            <i
              className={cn('fa fa-angle-right-btb', 'collapse-icon', { opened: collapses.includes(DETAILS_COLLAPSE) })}
            />
          </div>
          <ReactCollapse hasNestedCollapse isOpened={collapses.includes(DETAILS_COLLAPSE)}>
            {farm.groups.map((group, idx) => (
              <div key={idx} className="pb-15">
                <div className={cn('collapse', 'group')}>
                  <div className={cn('collapse-header')} onClick={() => onToggle(`${GROUP_COLLAPSE}-${idx}`)}>
                    {group.name}
                    <i
                      className={cn(
                        'fa fa-angle-right-btb',
                        'collapse-icon',
                        { opened: collapses.includes(`${GROUP_COLLAPSE}-${idx}`) }
                      )}
                    />
                  </div>
                  <ReactCollapse isOpened={collapses.includes(`${GROUP_COLLAPSE}-${idx}`)}>
                    <div className={cn('collapse-content')}>
                      {groupInfo.map(({ label, renderer }, idx) => (
                        <div key={idx} className={cn('collapse-content-row')}>
                          {label}<DotSeparator />{renderer(group)}
                        </div>
                      ))}
                    </div>
                  </ReactCollapse>
                </div>
              </div>
            ))}
          </ReactCollapse>
        </div>
      )}
    </Fragment>
  );
};

FarmInformationBar.propTypes = {
  currentCompany: T.object.isRequired,
  farm: T.object.isRequired,
};

export default FarmInformationBar;
