import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchFarms } from 'reducers/assetMap';
// components
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import FilterPanel from 'components/FilterPanel/FilterPanel';
import CircleCheckbox from 'components/CircleCheckbox';
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import CollapsibleFilter from 'components/CollapsibleFilter/CollapsibleFilter';
// utils
import classnames from 'classnames/bind';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';
import getValue from 'lodash.get';
// styles
import styles from './AssetMapFilter.module.scss';
// const
import { timeOptions } from '../utils';
import { farmTypes } from 'constants.js';

const cn = classnames.bind(styles);

const initialState = {
  type: [],
  checkins: {},
  symptoms: {},
  deaths: {},
  name: '',
};

class AssetMapFilter extends Component {

  state = {
    ...pick(this.props.params, ['type', 'checkins', 'symptoms', 'deaths', 'name']),
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state !== nextState) || (this.props.symptomsList !== nextProps.symptomsList);
  }

  onApplyFilters = () => {
    this.props.fetchFarms({ ...this.state });
    this.props.closePortal();
  };

  fetchData = () => {
    const { fetchFarms, isMobile } = this.props;
    if (!isMobile) fetchFarms({ ...this.state });
  };

  onSearchChange = (search) => {
    this.setState({ name: search }, this.fetchData);
  };

  onTypeChange = (key) => (e) => {
    const { checked } = e.target;
    this.setState((prevState) => {
      const type = checked ? [...prevState.type, key] : prevState.type.filter((item) => item !== key);
      return { type };
    }, this.fetchData);
  };

  onSymptomsReportedChange = (key) => (value) => {
    this.setState((prevState) => ({ symptoms: { ...prevState.symptoms, [key]: value } }), this.fetchData);
  };

  onMortalitiesDateChange = (date) => {
    this.setState({ deaths: { created_on: date } }, this.fetchData);
  };

  onCheckinDateChange = (date) => {
    this.setState((prevState) => ({ checkins: { ...prevState.checkins, created_at: date } }), this.fetchData);
  };

  onCheckinUserChange = (data = {}) => {
    const userData = {
      user_id: getValue(data, 'value', ''),
      user_name: getValue(data, 'label', ''),
    };
    this.setState((prevState) => ({ checkins: { ...prevState.checkins, ...userData } }), this.fetchData);
  };

  onFilterReset = () => {
    if (!isEqual(this.state, initialState)) {
      this.setState(initialState, this.fetchData);
      this.searchbox.resetSearch();
    }
  };

  render() {
    const { isMobile, symptomsList, closePortal } = this.props;
    const { type, checkins, symptoms, deaths, name } = this.state;
    const selectedUser = {
      value: checkins.user_id || '',
      label: checkins.user_name || <FormattedMessage id="general.allUsers" />,
    };

    return (
      <FilterPanel
        isMobile={isMobile}
        onClose={closePortal}
        onReset={this.onFilterReset}
        onApply={this.onApplyFilters}
      >
        <div className={cn('search-box')}>
          <SearchBox
            ref={(ref) => { this.searchbox = ref; }}
            initialValue={name}
            onChange={this.onSearchChange}
            placeholderKey="general.placeholder.searchFarmsOrUsers"
          />
        </div>

        <CollapsibleFilter title={<FormattedMessage id="component.assetMapFilter.CollapsibleFilter.farmType" />}>
          {farmTypes.map(({ label, value }, index) => (
            <div key={index} className="pb-10">
              <CircleCheckbox
                type="checkbox"
                className={cn('farm-type-checkbox')}
                checked={Boolean(type.includes(value))}
                label={<span className={cn('farm-type-label')}>{label} ({value.toUpperCase()})</span>}
                onChange={this.onTypeChange(value)}
              />
              <div className={cn('mark', `${value}-mark`)} />
            </div>
          ))}
        </CollapsibleFilter>

        <CollapsibleFilter title={<FormattedMessage id="component.assetMapFilter.CollapsibleFilter.symptoms" />}>
          <ResponsiveSelect
            grey
            placeholder={<FormattedMessage id="general.placeholder.chooseTimePeriod" />}
            className="pt-5 pb-5"
            noInputMargin
            options={timeOptions}
            onChange={this.onSymptomsReportedChange('created_on')}
            value={symptoms.created_on}
          />
          <ResponsiveSelect
            grey
            className="pt-5 pb-5"
            noInputMargin
            valueKey="id"
            labelKey="name"
            placeholder={<FormattedMessage id="general.placeholder.chooseSymptom" />}
            options={symptomsList}
            onChange={this.onSymptomsReportedChange('symptom_id')}
            value={symptoms.symptom_id || ''}
          />
        </CollapsibleFilter>

        <CollapsibleFilter title={<FormattedMessage id="component.assetMapFilter.CollapsibleFilter.mortality" />}>
          <ResponsiveSelect
            grey
            placeholder={<FormattedMessage id="general.placeholder.chooseTimePeriod" />}
            className="pt-5 pb-5"
            noInputMargin
            options={timeOptions}
            onChange={this.onMortalitiesDateChange}
            value={deaths.created_on}
          />
        </CollapsibleFilter>

        <CollapsibleFilter title={<FormattedMessage id="component.assetMapFilter.CollapsibleFilter.checkin" />}>
          <AsyncSelect
            selected={selectedUser}
            placeholder={<FormattedMessage id="general.placeholder.searchByUser" />}
            noInputMargin
            className="pt-5"
            optionsPath="/admin/users"
            optionsKeys={{ value: 'id', label: 'full_name' }}
            onChange={this.onCheckinUserChange}
          />
          <ResponsiveSelect
            grey
            placeholder={<FormattedMessage id="general.placeholder.chooseTimePeriod" />}
            className="pt-10 pb-5"
            noInputMargin
            options={timeOptions}
            onChange={this.onCheckinDateChange}
            value={checkins.created_at}
          />
        </CollapsibleFilter>
      </FilterPanel>
    );
  }
}

AssetMapFilter.propTypes = {
  fetchFarms: T.func.isRequired,
  closePortal: T.func,
  params: T.object.isRequired,
  symptomsList: T.array.isRequired,
  isMobile: T.bool,
};

export default connect(
  (state) => ({
    params: state.assetMap.params,
    symptomsList: state.staticData.symptomsList.resources,
  }), { fetchFarms }
)(AssetMapFilter);
