import React from 'react';
import T from 'prop-types';
// components
import WeightLabeledInput from 'components/Input/WeightLabeledInput';
// utils
import cn from 'classnames';

const WeightField = ({ input, label, meta: { touched, error }, className, disabled }) => {
  return (
    <div className={cn(className, { 'has-error': touched && error })}>
      <div className="row">
        <div className="small-12 medium-3 column label-wrapper">{label}</div>
        <div className="small-12 medium-3 column">
          <WeightLabeledInput {...input} isDisabled={disabled} className="field-input" />
        </div>
        <div className="small-12 medium-3 column" />
      </div>
    </div>
  );
};

WeightField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]).isRequired,
  className: T.string,
  disabled: T.bool,
};

export default WeightField;
