import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import MessageBox from 'components/MessageBox';
import PhoneInputField from 'components/ReduxFormFields/PhoneInputField/PhoneInputField';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
import UserFieldsMethods from 'components/UserFieldsMethods/UserFieldsMethods';
import FormErrorsBox from 'components/FormErrorsBox';
import RolesFields from 'components/Admin/RolesFields';
import EmailCheckIcon from 'components/Admin/EmailCheckIcon';
import Field from 'components/Field';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { langOptions } from 'utils/localizationHelper';
import cn from 'classnames';
import debounce from 'lodash.debounce';
// styles
import './UserFormFields.scss';

class UserFormFields extends UserFieldsMethods {

  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: false,
      status: null,
      isLoading: false,
    };
    this.checkEmailDebounced = debounce(this.checkEmail, 300);
  }

  validateEmail = (value) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(value);
  };

  checkEmail = (value) => {
    if (this.validateEmail(value)) {
      this.setState({ isLoading: true });
      fetchFromAPI('/invites/users', { params: { email: value } })
        .then((response) => {
          const { status } = response.meta;
          this.setUserStatus(status);
          setTimeout(() => this.setState({ isLoading: false }), 500);
        })
        .catch((response) => {
          toastResponseErrors(response);
          this.setState({ isLoading: false });
        });
    } else {
      this.setUserStatus(null);
    }
  };

  setUserStatus = (status) => {
    const { setFormValidity, change } = this.props;
    this.setState({ status });
    change('user_status', status);
    if (setFormValidity) {
      const isValid = (status === 'not_found') || (status === 'found');
      setFormValidity(isValid);
    }
  };

  emailOnChange = (event, value) => {
    const userEmail = value.toLowerCase();
    this.setState({ isValidEmail: this.validateEmail(userEmail) });
    this.checkEmailDebounced(userEmail);
  };

  getName = (name) => {
    const { wrap } = this.props;
    return wrap ? `${wrap}[${name}]` : name;
  };

  render() {
    const { email, children, customErrors, headerTitle, roles, form, rolesTitle } = this.props;
    const { isValidEmail, status, isLoading } = this.state;

    const showForm = isValidEmail && (status !== 'already_present');
    const showOnlyRoles = isValidEmail && (status === 'found');

    const hasHeader = typeof headerTitle === 'string';

    const userForm = (
      <div className="CreateUserFormFields">
        <Field name="user_status" component="input" type="hidden" />
        {customErrors && customErrors.length && (
          <div className="row">
            <div className="small-12 column">
              <FormErrorsBox
                includedFields={['user']}
                formName={form}
                customErrors={customErrors}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="small-12 column">
            <label>
              <Field
                name={this.getName('email')}
                label={<FormattedMessage id="general.emailAddress" />}
                onChange={this.emailOnChange}
                placeholderKey="general.typeEmail"
                type="email"
                icon={(
                  <EmailCheckIcon
                    wasChecked={(status === 'not_found') || (status === 'found')}
                    isLoading={isLoading}
                    hasWarning={!!email && !isValidEmail}
                  />
                )}
              />
            </label>
            {email && !isValidEmail && (
              <MessageBox type="info">
                <FormattedMessage
                  id="component.userFormFields.invalidEmail"
                  values={{
                    title: <FormattedMessage id="component.userFormFields.invalidEmail.title" tagName="b" />,
                  }}
                />
              </MessageBox>
            )}
            {isValidEmail && status === 'already_present' && (
              <MessageBox type="alert">
                <FormattedMessage id="general.customErrors.email" />
              </MessageBox>
            )}
            {isValidEmail && status === 'found' && (
              <MessageBox type="success">
                <FormattedMessage
                  id="component.userFormFields.goodNews"
                  values={{
                    title: <FormattedMessage id="component.userFormFields.goodNews.title" tagName="b" />,
                  }}
                />
              </MessageBox>
            )}
          </div>
        </div>
        <div className={cn('animated fadeIn', { 'hide': !showForm })}>
          <div className={cn('animated fadeIn row', { 'hide': showOnlyRoles })}>
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  label={<FormattedMessage id="component.userProfile.firstName" />}
                  placeholderKey="component.userProfile.firstName"
                  name={this.getName('first_name')}
                  onChange={this.handleNamesChange}
                />
              </label>
            </div>
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  label={<FormattedMessage id="component.userProfile.lastName" />}
                  placeholderKey="component.userProfile.lastName"
                  name={this.getName('last_name')}
                  onChange={this.handleNamesChange}
                />
              </label>
            </div>
          </div>

          <div className={cn('animated fadeIn row', { 'hide': showOnlyRoles })}>
            <div className="small-12 column">
              <label>
                <Field
                  label={<FormattedMessage id="component.userProfile.userName" />}
                  placeholderKey="component.userProfile.userName"
                  name={this.getName('nickname')}
                  onChange={this.handleNicknameChange}
                  icon={<i className="nickname-icon fa fa-at" />}
                  iconLeft
                />
                <FormattedMessage id="component.userProfile.userName.description">
                  {(desc) => <i className="fs-12 field-description">{desc}</i>}
                </FormattedMessage>
              </label>
            </div>
          </div>

          <div className={cn('animated fadeIn row position-relative z-index-2', { 'hide': showOnlyRoles })}>
            <div className="small-12 medium-6 column">
              <Field
                name={this.getName('language')}
                label={<FormattedMessage id="general.pageTitle.language" />}
                component={SelectField}
                options={langOptions}
                valueKey="value"
                labelKey="label"
              />
            </div>
          </div>

          <div className={cn('animated fadeIn row phone-row', { 'hide': showOnlyRoles })}>
            <div className="small-12 medium-6 column">
              <Field
                label={<FormattedMessage id="component.userProfile.phone" />}
                name="telephone"
                component={PhoneInputField}
              />
              <FormattedMessage id="component.userProfile.phoneDescription">
                {(desc) => <i className="fs-12 field-description">{desc}</i>}
              </FormattedMessage>
            </div>
          </div>

          {!!roles.length && (
            <RolesFields roles={roles} title={rolesTitle} />
          )}

          {children}
        </div>
      </div>
    );

    return hasHeader
      ? (
        <div className="admin-create-farm">
          <div className="row margin-bottom-1">
            <div className="small-12 column">
              <div className="form-user-header">{headerTitle}</div>
            </div>
          </div>
          {userForm}
        </div>
      )
      : userForm;
  }
}

UserFormFields.defaultProps = {
  roles: [],
};

UserFormFields.propTypes = {
  change: T.func,
  children: T.any,
  customErrors: T.array,
  email: T.string,
  form: T.string,
  headerTitle: T.string,
  roles: T.array,
  rolesTitle: T.node,
  setFormValidity: T.func,
  wrap: T.string,
};

export default UserFormFields;
