import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './PasswordStrength.module.scss';

const PasswordStrength = ({ value, description }) => {
  const classes = value.split(' ').map((item) => styles[item]);
  return (
    <div className={cn(styles.strength, ...classes)}>
      <div className={styles.scale}>
        <span className={styles.value} />
      </div>
      <span className={styles.description}>{description}</span>
    </div>
  );
};

PasswordStrength.propTypes = {
  value: T.oneOf(['', 'weak', 'medium', 'strong', 'excellent']),
  description: T.oneOfType([T.node, T.string]).isRequired,
};

export default PasswordStrength;
