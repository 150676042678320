import { fetchFromAPI } from 'utils/api';

export const getCheckupDeadPigs = (checkupId) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/dead_pigs`)
    .then((response) => response.resource)
    .catch((errors) => {
      throw errors;
    });
};

export const getCheckupTreatments = (checkupId) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/treatments`)
    .then((response) => response.resources)
    .catch((errors) => {
      throw errors;
    });
};

export const getCheckupMigrations = (checkupId) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/pig_migrations`)
    .then((response) => response.resources)
    .catch((errors) => {
      throw errors;
    });
};

export const getCheckupSymptoms = (checkupId) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/symptoms`)
    .then((response) => response.resources)
    .catch((errors) => {
      throw errors;
    });
};

export const getCheckupDiagnoses = (checkupId) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/diagnoses`)
    .catch((errors) => {
      throw errors;
    });
};

export const updateCheckupDiagnoses = (checkupId, resource) => {
  return fetchFromAPI(`/daily_checkups/${checkupId}/diagnoses`, {
    method: 'put',
    body: JSON.stringify({ resource }),
  })
    .catch((errors) => {
      throw errors;
    });
};


export const downloadDailyCheckupCSV = (checkupId) => {
  return fetchFromAPI(`/csv/daily_checkups/${checkupId}.csv`, { blob: true })
    .catch((errors) => {
      throw errors;
    });
};
