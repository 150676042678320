import React from 'react';
import T from 'prop-types';
// components
import Input from 'components/Input';
// utils
import cn from 'classnames';

const NumberInputField = ({ input, label, className, meta: { touched, error } }) => (
  <div className={cn(className, { 'has-error': touched && error })}>
    <div className="row">
      <div className="small-12 medium-3 column label-wrapper">{label}</div>
      <div className="small-12 medium-3 column">
        <Input
          {...input}
          type="number"
          min="0"
          max="99999"
          step="1"
          pattern="[0-9]*"
          onFocus={(e) => e.target.select()}
          value={input.value || 0}
          className="field-input"
        />
      </div>
      <div className="column" />
    </div>
  </div>
);

NumberInputField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]).isRequired,
  className: T.string,
};

export default NumberInputField;
