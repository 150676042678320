import { compose, withStateHandlers } from 'recompose';

const MapParamsController = (initialState = {}) => compose(
  withStateHandlers(
    ({ center }) => ({ mapParams: { ...initialState, center } }),
    {
      setMapParams: ({ mapParams }) => (newMapParams) => ({
        mapParams: {
          ...mapParams,
          ...newMapParams,
        },
      })
    },
  ),
);

export default MapParamsController;
