import React, { Component } from 'react';
import T from 'prop-types';
// components
import FormField from 'components/ReduxFormFields/FormField/FormField';
import { Field } from 'redux-form';
// assets
import './UserProfilePQADate.scss';
// translate
import { FormattedMessage } from 'react-intl';

class UserProfilePQADate extends Component {

  handleDateChange = ({ target: { name, value } }) => {
    const valuesValidations = {
      month: () => {
        if (value.length >= 2) {
          this.pqaDay.focus();
        }
      },
      day: () => {
        if (value.length >= 2) {
          this.pqaYear.focus();
        }
      },
    };

    const logicName = name.split('.')[1];
    valuesValidations[logicName]();
  };

  render() {
    const { fieldProps } = this.props;
    return (
      <label className="UserProfilePQADate">
        <span className="input-label"><FormattedMessage id="component.userProfile.pqaDate" /></span>
        <div className="pqa flex align-center justify-start">
          <Field
            name="pqa_date.month"
            maxLength={2}
            component={FormField}
            onChange={this.handleDateChange}
            type="text"
            pattern="[0-9]*"
            className="mr-5 mb-0"
            placeholder="MM"
            {...fieldProps}
          />
          <span>/</span>
          <Field
            name="pqa_date.day"
            getReference={(field) => { this.pqaDay = field; }}
            maxLength={2}
            component={FormField}
            onChange={this.handleDateChange}
            type="text"
            pattern="[0-9]*"
            className="mr-5 ml-5 mb-0"
            placeholder="DD"
            {...fieldProps}
          />
          <span>/</span>
          <Field
            name="pqa_date.year"
            getReference={(field) => { this.pqaYear = field; }}
            maxLength={4}
            component={FormField}
            type="text"
            pattern="[0-9]*"
            className="ml-5 mb-0"
            placeholder="YYYY"
            {...fieldProps}
          />
        </div>
      </label>
    );
  }
}

UserProfilePQADate.propTypes = {
  fieldProps: T.object,
};

export default UserProfilePQADate;
