import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import { calculateCommitmentsTravelDistance } from 'utils/commitmentsHelper';
// components
import TruckingInfo from './TruckingInfo';
import { FormattedMessage } from 'react-intl';
import InfoRow from 'components/InfoRow/InfoRow';
// styles
import styles from './CommitmentCard.module.scss';

const cn = classnames.bind(styles);

const CommitmentInfo = ({ load, commitment }) => (
  <div className={cn('load-info')}>
    {/* TRUCKING INFO */}
    <TruckingInfo commitment={commitment} load={load} />
    {/*  GENERAL INFO */}
    <div className={cn('general-info', 'hide-for-small-only')}>
      <div className={cn('subtitle')}>
        <InfoRow label={<FormattedMessage id="general.company" />} value={load?.trucking_company?.name} />
        <InfoRow
          label={<FormattedMessage id="general.millage" />}
          value={calculateCommitmentsTravelDistance(load.commitments)}
        />
        <InfoRow label={<FormattedMessage id="general.truckDriver" />} value={load.driver?.full_name} />
      </div>
    </div>
  </div>
);

CommitmentInfo.propTypes = {
  load: T.object.isRequired,
  commitment: T.object.isRequired,
};

export default CommitmentInfo;
