import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// hoc
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import withDropdownActions from 'hoc/withDropdownActions';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import Button from 'components/Button';
import UserAvatar from 'components/UserAvatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// api
import { reinviteUser } from 'endpoints/admin/users';
// utils
import isEmpty from 'lodash.isempty';
import sum from 'lodash.sum';
import cn from 'classnames';
import { capitalize, toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import { isTablet } from 'react-device-detect';

const labels = {
  editUser: <FormattedMessage id="general.button.editUser" />,
  resendInvite: <FormattedMessage id="general.button.resendInvite" />,
  dataTable: {
    status: <FormattedMessage id="component.dataTable.headers.status" />,
    name: <FormattedMessage id="component.dataTable.headers.name" />,
    roles: <FormattedMessage id="component.dataTable.headers.roles" />,
    lastSeen: <FormattedMessage id="general.time.lastSeen" />,
  },
  roles: {
    allRoles: <FormattedMessage id="general.allRoles" />,
    trucking_admin: <FormattedMessage id="general.role.truckingAdmin" />,
    driver: <FormattedMessage id="general.role.driver" />,
  },
};

class TruckingCompanyUserRoles extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  reInviteUser = (id) => {
    return reinviteUser(id)
      .then(() => {
        showToastrMessage('component.toastr.userInvite.resent');
      })
      .catch(toastResponseErrors);
  };

  renderNameColumn = (user) => (
    <CustomColumn flexRow>
      <UserAvatar user={user} size="35" className="mr-10" />
      <div className="semibold">{user.full_name}</div>
    </CustomColumn>
  );

  renderEmailColumn = ({ email }) => (
    <CustomColumn className="show-for-large" label={<FormattedMessage id="general.email" />}>
      {email}
    </CustomColumn>
  );

  renderRoleColumn = ({ trucking_roles }) => (
    <CustomColumn flexRow>
      <FormattedMessage id="component.dataTable.headers.roles">
        {(text) => (<span className="hide-for-large semibold">{text}&nbsp;•&nbsp;</span>)}
      </FormattedMessage>
      {trucking_roles.map((role, index) => (
        <span>
          {labels.roles[role.name]}
          {trucking_roles.length !== index + 1 && <span>,&nbsp;</span>}
        </span>
      ))}
    </CustomColumn>
  );

  formatter = (value, unit, suffix) => (
    unit === 'second'
      ? 'Just Now'
      : `${value}${unit[0]} ${capitalize(suffix)}`
  );

  openDropdown = (e, id, registration_state) => {
    const { openDropdown } = this.props;
    const options = [
      { label: labels.editUser, url: `/admin/users/${id}/edit` },
      { label: labels.resendInvite, onClick: () => this.reInviteUser(id),
        hide: !['invited', 'bound'].includes(registration_state) },
    ];
    openDropdown(e, options);
  };

  renderLastSeenColumn = ({ last_seen_at }) => (
    <CustomColumn label={labels.dataTable.lastSeen}>
      {last_seen_at
        ? <TimeAgo date={last_seen_at} formatter={this.formatter} />
        : 'N/A'
      }
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.status}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = ({ full_name, id, registration_state }) => (
    <div className="collapsible-value button-column">
      <i
        data-name={`${full_name}-${id}`}
        className="fa fa-dots-three-horizontal show-for-large"
        onClick={(e) => this.openDropdown(e, id, registration_state)}
      />
      <Link to={`/admin/users/${id}/edit`} className="button light hide-for-large">
        {labels.editUser}
      </Link>
      {['invited', 'bound'].includes(registration_state) && (
        <Button darkGrey onClick={() => this.reInviteUser(id)} className="hide-for-large">
          {labels.resendInvite}
        </Button>
      )}
    </div>
  );

  renderExpandable = ({ id, registration_state }) => (
    <div>
      <Link to={`/admin/users/${id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        {labels.editUser}
      </Link>
      {['invited', 'bound'].includes(registration_state) && (
        <Button small light onClick={() => this.reInviteUser(id)}>
          <i className="fa fa-envelope mr-5" />
          {labels.resendInvite}
        </Button>
      )}
    </div>
  );

  render() {
    const {
      resources, meta: { stats = {}, total }, isLoading, reqParams: { page, per_page, role, search, sort },
      onSearchChange, onPageChange, onPerPageChange, onSortChange, params: { id },
    } = this.props;

    const assignUserRolesLink = `/admin/trucking-companies/${id}/assign-user-roles`;

    const statsSum = sum(Object.values(stats));
    const tableFilters = [
      { label: labels.roles.allRoles, value: '', count: statsSum },
      { label: labels.roles.trucking_admin, value: 'trucking_admin' },
      { label: labels.roles.driver, value: 'driver' },
    ];

    const isEmptyTable = !resources.length && !role && !search;

    const columns = [
      { label: labels.dataTable.name, flex: '2 1 200px', sortKey: 'first_name',
        renderer: this.renderNameColumn },
      { label: <FormattedMessage id="general.email" />, flex: '2 1 200px', renderer: this.renderEmailColumn,
        sortKey: 'email' },
      { label: labels.dataTable.roles, flex: '1 1 150px', renderer: this.renderRoleColumn },
      { label: labels.dataTable.lastSeen, flex: '1 1 120px', renderer: this.renderLastSeenColumn },
      { label: labels.dataTable.status, flex: '1 1 120px',
        renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 45px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.userRoles" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />
          }
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              onFilterChange={this.onFilterChange}
              stats={stats}
              activeFilter={role}
              className="ph-10"
            >
              {!isEmpty(resources) && (
                <Link to={assignUserRolesLink} className="button small primary wider">
                  <FormattedMessage id="general.manageUsers" />
                </Link>
              )}
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="truckingRoles"
          />
          <NothingBox
            itemsName="users_roles"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={role}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noUserRoles" />
            <Link to={assignUserRolesLink} className="button primary mt-10">
              <FormattedMessage id="general.assignUserRoles" />
            </Link>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

TruckingCompanyUserRoles.propTypes = {
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  openDropdown: T.func.isRequired,
  params: T.object.isRequired,
};

const enhance = compose(
  connect((state) => ({
    resources: state.dataTable.truckingRoles.resources,
    reqParams: state.dataTable.truckingRoles.params,
    meta: state.dataTable.truckingRoles.meta,
    isLoading: state.dataTable.truckingRoles.isLoading,
  }), (dispatch, { params: { id } }) => ({
    fetchData: (query) => {
      return dispatch(fetchData(`/admin/trucking_companies/${id}/users/profile_index`, 'truckingRoles', query));
    },
  })),
  withDropdownActions,
  withDataTableController('fetchData', 'reqParams'),
);


export default enhance(TruckingCompanyUserRoles);
