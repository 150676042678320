import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const getDaysAgoDate = (daysAgo) => moment().subtract(daysAgo, 'days').startOf('day');

const dateFormat = 'YYYY-MM-DD';

export const timeOptions = [
  { value: moment(0).format(dateFormat), label: <FormattedMessage id="general.timeOptions.anytime" /> },
  { value: getDaysAgoDate(1).format(dateFormat), label: <FormattedMessage id="general.timeOptions.24h" /> },
  { value: getDaysAgoDate(2).format(dateFormat), label: <FormattedMessage id="general.timeOptions.48h" /> },
  { value: getDaysAgoDate(7).format(dateFormat), label: <FormattedMessage id="general.timeOptions.7days" /> },
  { value: getDaysAgoDate(14).format(dateFormat), label: <FormattedMessage id="general.timeOptions.14days" /> },
  { value: getDaysAgoDate(28).format(dateFormat), label: <FormattedMessage id="general.timeOptions.4weeks" /> },
];
