import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import TruckingCompanyForm from '../components/TruckingCompanyForm';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
// api
import { createTruckingCompany } from 'endpoints/admin/truckingCompanies';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isEmpty from 'lodash.isempty';
import getValue from 'lodash.get';
import pick from 'lodash.pick';
import { showToastrMessage } from 'utils';

const customErrors = [
  { key: 'name', error: <FormattedMessage id="general.customErrors.company" /> },
];

const requiredFields = ['name', 'goose_trailer_count', 'semi_trailer_count', 'user', 'country_code'];

class TruckingCompanyCreate extends Component {

  createNewCompany = (data) => {
    const { router } = this.context;
    const { phone, countryCode: phone_country_code } = getValue(data, 'phone', {});
    const companyData = {
      ...pick(data, requiredFields),
      ...data.addressObj,
      phone,
      phone_country_code
    };
    if (data.has_user) {
      companyData.user_role = 'trucking_admin';
      if (!isEmpty(companyData.telephone)) {
        companyData.user.phone = companyData.telephone.phone;
        companyData.user.country_code = companyData.telephone.countryCode;
        delete companyData.telephone;
      }
    } else {
      delete companyData.user;
    }

    return createTruckingCompany(companyData)
      .then((company) => {
        showToastrMessage('component.toastr.truckingCompany.created');
        router.push(`/admin/trucking-companies/${company.id}/user-roles`);
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { currentCompany } = this.props;
    const initialValues = {
      user: {
        language: currentCompany.language || 'en',
      },
      has_user: false,
    };
    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.button.createTruckingCompany" />} />
          <Panel.Body noPadding>
            <FormErrorsBox
              excludedFields={['user']}
              formName="address-form"
              customErrors={customErrors}
            />
            <TruckingCompanyForm
              onSubmit={this.createNewCompany}
              initialValues={initialValues}
              hasLocationDefining
              canCreateUser
            />
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

TruckingCompanyCreate.contextTypes = {
  router: T.object.isRequired,
};

TruckingCompanyCreate.propTypes = {
  currentCompany: T.object.isRequired,
};

export default connect((state) => ({
  currentCompany: state.auth.user.current_company,
}))(TruckingCompanyCreate);
