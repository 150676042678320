import React from 'react';
import T from 'prop-types';
// recompose
import { branch, renderNothing } from 'recompose';
// components
import Dropdown from 'components/Dropdown/Dropdown2';
import Popover from 'components/Popover';
import Preloader from 'components/Preloader';
// utils
import get from 'lodash.get';
import cn from 'classnames';
import { getMedType } from 'utils';
import { FormattedMessage } from 'react-intl';
// styles
import './MedicationFeedbackHeader.scss';

const MedicationFeedbackHeader = ({
  feedback,
  isLoading,
  dropdownOptions,
  className,
  isSticky,
}) => {
  const name = get(feedback, 'treatment_product.name');
  const unitType = (
    <FormattedMessage id={`general.${get(feedback, 'treatment_product.unit_type')}`} />
  );
  const type = (
    <FormattedMessage
      id={`container.checkupEdit.reportMedications.${getMedType(get(feedback, 'treatment_product'))}`}
    />
  );
  const commonKey = get(feedback, 'treatment_product.common') ? 'yes' : 'no';
  const treatments = get(feedback, 'treatments_count', 0);
  const positive = get(feedback, 'positive_feedback', 0);
  const frequency = get(feedback, 'use_frequency', 0);

  return (
    <div className={cn('MedicationFeedbackHeader', className, { isSticky })}>
      <Preloader isActive={isLoading} />

      <div className="left-side">
        <div className="medication-icon">
          <i className={`fa fa-${getMedType(get(feedback, 'treatment_product'))}`} />
        </div>
        <div className="medication-title">
          <div className="name">{name}</div>
          <div className="description">
            <FormattedMessage id="general.unitType" /> • {unitType}
            {' | '}
            <FormattedMessage id="general.type" /> • {type}
            {' | '}
            <FormattedMessage id="general.common" /> • <FormattedMessage id={`general.button.${commonKey}`} />
          </div>
        </div>
      </div>

      <div className="right-side">

        <FormattedMessage id="component.medicationFeedbackHeader.useFrequencyDesc">
          {(content) => (
            <Popover
              content={content}
              placement="bottomRight"
            >
              <div className="stat-box">
                <FormattedMessage id="component.medicationFeedbackHeader.useFrequency">
                  {(text) => <span className="stat-label">{text}</span>}
                </FormattedMessage>
                <div className="value">{frequency}%</div>
              </div>
            </Popover>
          )}
        </FormattedMessage>

        <FormattedMessage id="component.medicationFeedbackHeader.posFeedbackDesc">
          {(content) => (
            <Popover
              content={content}
              placement="bottomRight"
            >
              <div className="stat-box">
                <FormattedMessage id="component.medicationFeedbackHeader.posFeedback">
                  {(text) => <span className="stat-label">{text}</span>}
                </FormattedMessage>
                <div className="value">{positive}%</div>
              </div>
            </Popover>
          )}
        </FormattedMessage>

        <div className="stat-box">
          <FormattedMessage id="general.treatments">
            {(text) => <span className="stat-label">{text}</span>}
          </FormattedMessage>
          <div className="value">{treatments}</div>
        </div>

        {dropdownOptions && (
          <Dropdown
            isButton
            options={dropdownOptions}
            className="medication-feedback-dropdown"
          />
        )}
      </div>
    </div>
  );
};

MedicationFeedbackHeader.defaultProps = {
  className: '',
};

MedicationFeedbackHeader.propTypes = {
  feedback: T.object,
  isLoading: T.bool,
  isSticky: T.bool,
  dropdownOptions: T.array,
  className: T.string,
};

export default branch(
  ({ feedback }) => !feedback,
  renderNothing,
)(MedicationFeedbackHeader);
