import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import pick from 'lodash.pick';
// styles
import styles from './Line.module.scss';

const cn = classnames.bind(styles);

const possibleKeys = ['almostWhite', 'alignLeft', 'bolded', 'hasTopBorder', 'lightYellow', 'lightBlue', 'lightOrange',
  'isHeaderLine', 'isEmptyLine'];

const Line = ({ boldedValue, boldedLabel, children, label, value, ...others }) => {
  const lineClasses = pick(others, possibleKeys);
  return (
    <div className={cn('line', lineClasses)}>
      {children}
      {!children && <span className={cn({ 'semibold': boldedLabel })}>{label}</span>}
      {!children && <span className={cn({ 'semibold': boldedValue })}>{value}</span>}
    </div>
  );
};

Line.propTypes = {
  boldedValue: T.bool,
  boldedLabel: T.bool,
  label: T.oneOfType([T.string, T.object, T.node]),
  value: T.oneOfType([T.string, T.number, T.node]),
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

export default Line;
