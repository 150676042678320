import React, { Component } from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { isEqualKeys } from 'utils';
import { isActivityMinimized, saveActivityToStorage } from 'utils/activityHelper';
import isEqual from 'lodash.isequal';

export default function withActivityController(WrappedComponent, options = {}) {
  const { affectRerenderKeys = [] } = options;
  return class extends Component {

    constructor(props) {
      super(props);
      this.state = {
        minimized: isActivityMinimized(props.activity),
        truncated: props.isChildActivity,
      };
    }

    shouldComponentUpdate(nextProps, nextState) {
      // check state
      if (!isEqual(nextState, this.state)) return true;
      // check props
      if (!isEqualKeys(affectRerenderKeys, nextProps.activity, this.props.activity)) {
        return true;
      }
      // skip re-render
      return false;
    }

    getDropdownOptions = () => {
      const { activity, currentUser: { roles_map: { admin } }, setActivityFlag } = this.props;
      const { id, pig_group, farm, user, flagged } = activity;
      const { minimized } = this.state;
      const pigGroupId = pig_group?.id;
      const sourceId = pig_group?.external_source_id;
      const farmId = farm?.id;
      const userId = user?.id;

      return [
        { label: <FormattedMessage id={`container.farmfeed.row.${flagged ? 'unflagEvent' : 'flagEvent'}`} />,
          onClick: () => setActivityFlag(!flagged, id), icon: 'bookmark', hide: !minimized,
        },
        { label: <FormattedMessage id={`container.farmfeed.row.${minimized ? 'maximize' : 'minimize'}Event`} />,
          onClick: this.toggleMinimized, icon: `${minimized ? 'plus' : 'minus'}-circle`,
        },
        { label: <FormattedMessage id="general.viewBarnSheet" />, url: `/barnsheets/groups/${pigGroupId}`,
          icon: 'ulist', hide: !pigGroupId },
        { label: <FormattedMessage id="general.viewSourceProfile" />, url: `/sources/${sourceId}`, icon: 'merge',
          hide: !admin || !sourceId },
        { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${pigGroupId}`, icon: 'gear',
          hide: !admin || !pigGroupId },
        { label: <FormattedMessage id="general.manageFarm" />, url: `/admin/farms/${farmId}`, icon: 'gear',
          hide: !admin || !farmId },
        { label: <FormattedMessage id="general.manageUser" />, url: `/admin/users/${userId}`, icon: 'gear',
          hide: !admin || !userId },
      ];
    };

    onViewFullActivity = () => {
      this.setState({ truncated: false });
    };

    toggleMinimized = (e) => {
      e.preventDefault();
      const { activity } = this.props;
      const { minimized } = this.state;
      saveActivityToStorage(activity, minimized);
      this.setState((prevState) => ({ minimized: !prevState.minimized }));
    };

    render() {
      const { minimized, truncated } = this.state;
      const defaultDropdownOptions = this.getDropdownOptions();
      return (
        <WrappedComponent
          {...this.props}
          isMinimized={minimized}
          isTruncated={truncated}
          toggleMinimized={this.toggleMinimized}
          onViewFullActivity={this.onViewFullActivity}
          defaultDropdownOptions={defaultDropdownOptions}
        />
      );
    }
  };
}
