import React from 'react';
import T from 'prop-types';
import ReactPlayer from 'react-player';
import cn from 'classnames';
import './ResponsivePlayer.scss';

const ResponsivePlayer = ({
  url,
  className = '',
  playing = false,
  config = { youtube: { playerVars: { showinfo: 1 } } },
  controls = true,
}) => {
  return (
    <div className={cn('ResponsivePlayer player-wrapper', { [className]: Boolean(className) })}>
      <ReactPlayer
        playing={playing}
        controls={controls}
        config={config}
        className="react-player"
        url={url}
        width="100%"
        height="100%"
      />
    </div>
  );
};

ResponsivePlayer.propTypes = {
  url: T.string.isRequired,
  className: T.string,
  playing: T.bool,
  config: T.object,
  controls: T.bool,
};

export default ResponsivePlayer;
