import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesLabel from 'components/RolesLabel/RolesLabel';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// utils
import sum from 'lodash.sum';
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';
import Avatar from '../../../components/Avatar/Avatar';

class UserTruckingRoles extends Component {

  state = {
    isRoleRemoving: false,
  };

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  revokeUserTruckingRole = ({ id: trucking_company_id, is_driver, is_trucking_admin }) => () => {
    const { params: { id: user_id } } = this.props;
    let name;
    if (is_driver) name = 'driver';
    if (is_trucking_admin) name = 'trucking_admin';
    const paramsData = { trucking_company_id, name, user_id };

    this.removeRole(paramsData);
  };

  removeRole = (data) => {
    const { fetchData, reqParams, params: { id } } = this.props;
    this.setState({ isRoleRemoving: true });
    return fetchFromAPI(`/admin/users/${id}/trucking_roles`, { method: 'delete', body: JSON.stringify(data) })
      .then(() => {
        this.setState({ isRoleRemoving: false });
        fetchData(reqParams);
        showToastrMessage('component.toastr.role.revoked');
      })
      .catch((response) => {
        this.setState({ isRoleRemoving: false });
        toastResponseErrors(response);
      });
  };

  renderTruckingCompanyNameColumn = ({ name }) => (
    <CustomColumn flexRow>
      <Avatar type="trucking-company" avatarSize={35} iconSize={16} className="mr-10" />
      <span className="semibold">{name}</span>
    </CustomColumn>
  );

  renderLocationColumn = ({ city, state }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.location" />}>
      {`${city}, ${state}`}
    </CustomColumn>
  );

  renderUserRoleColumn = ({ is_driver, is_trucking_admin }) => (
    <CustomColumn flexRow>
      <FormattedMessage id="general.userRole">
        {(text) => (
          <span className="hide-for-large semibold">{text}&nbsp;•&nbsp;</span>
        )}
      </FormattedMessage>
      <RolesLabel
        roles={[
          { name: <FormattedMessage id="general.role.driver" />, value: is_driver, shortcut: 'o' },
          { name: <FormattedMessage id="general.role.truckingAdmin" />, value: is_trucking_admin, shortcut: 'm' },
        ]}
      />
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.farmStatus" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = (truckingCompany, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${truckingCompany.id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.revokeAccess" />}
        buttonType="small light-gray"
        onClick={this.revokeUserTruckingRole(truckingCompany)}
        dropDownData={[
          { label: <FormattedMessage id="general.editTruckingCompany" />,
            url: `/admin/trucking-companies/${truckingCompany.id}/edit` },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/trucking-companies/${truckingCompany.id}/edit`} className="button light hide-for-large">
        <FormattedMessage id="general.editTruckingCompany" />
      </Link>
      <Button darkGrey onClick={this.revokeUserTruckingRole(truckingCompany)} className="hide-for-large">
        <FormattedMessage id="general.button.revokeAccess" />
      </Button>
    </div>
  );

  renderExpandable = (truckingCompany) => (
    <div>
      <Link to={`/admin/trucking-companies/${truckingCompany.id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.editTruckingCompany" />
      </Link>
      <Button small light onClick={this.revokeUserTruckingRole(truckingCompany)}>
        <i className="fa fa-times-circle mr-5" />
        <FormattedMessage id="general.button.revokeAccess" />
      </Button>
    </div>
  );

  render() {
    const { resources, meta: { total, stats = {} }, isLoading, reqParams: { page, per_page, role, search, sort },
      params: { id }, onSearchChange, onPageChange, onPerPageChange, onSortChange } = this.props;
    const { isRoleRemoving } = this.state;
    const { isTablet } = this.context;
    const statsSum = sum(Object.values(stats));
    const tableFilters = [
      { label: <FormattedMessage id="general.allRoles" />, value: '', count: statsSum },
      { label: <FormattedMessage id="general.role.truckingAdmin" />, value: 'trucking_admin' },
      { label: <FormattedMessage id="general.role.driver" />, value: 'driver' },
    ];
    const isEmptyTable = !resources.length && !role && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.truckingCompanyName" />, flex: '2 1 160px',
        sortKey: 'name', renderer: this.renderTruckingCompanyNameColumn },
      { label: <FormattedMessage id="component.dataTable.headers.location" />, flex: '1 1 100px',
        sortKey: 'city', renderer: this.renderLocationColumn },
      { label: <FormattedMessage id="general.userRole" />, flex: '1 1 140px',
        sortKey: 'role', renderer: this.renderUserRoleColumn },
      { label: <FormattedMessage id="component.dataTable.headers.truckingCompanyStatus" />, flex: '1 1 120px',
        sortKey: 'active', renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 180px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.truckingRoles" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />
          }
        </Panel.Heading>
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={role}
              onFilterChange={this.onFilterChange}
              stats={stats}
              className="ph-10"
            >
              {!isEmpty(resources) && (
                <Link to={`/admin/users/${id}/assign-trucking-roles`} className="button small primary wider">
                  <FormattedMessage id="general.manageRoles" />
                </Link>
              )}
            </TableFilter>
          )}

          <Preloader isActive={isLoading || isRoleRemoving} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="userTruckingRoles"
            rowKey="trucking_role_id" // todo: id is not unique for roles table now
          />

          <NothingBox
            itemsName="roles"
            display={!resources.length}
            isLoading={isLoading || isRoleRemoving}
            search={search}
            filter={role}
          >
            <FormattedMessage id="component.nothingBox.noTruckingRoles" tagName="h1" />
            <Link to={`/admin/users/${id}/assign-trucking-roles`} className="button primary mt-10">
              <FormattedMessage id="general.assignTruckingRoles" />
            </Link>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

UserTruckingRoles.propTypes = {
  fetchData: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  params: T.object,
};

UserTruckingRoles.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect((state) => ({
    user: state.profiles.user.data,
    resources: state.dataTable.userTruckingRoles.resources,
    reqParams: state.dataTable.userTruckingRoles.params,
    meta: state.dataTable.userTruckingRoles.meta,
    isLoading: state.dataTable.userTruckingRoles.isLoading,
  }), (dispatch, { params: { id } }) => ({
    fetchData: (query) => (
      dispatch(fetchData(`/admin/users/${id}/trucking_roles/profile_index`, 'userTruckingRoles', query))
    )
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(UserTruckingRoles);
