import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import Field from 'components/Field';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
// api
import { getCompanies } from 'endpoints/admin/companies';
// utils
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const FarmManagerField = ({ name, className, currentCompany }) => {
  const [{ companies, isLoaded }, setState] = useState({ companies: [], isLoaded: false });
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!isLoaded) {
      getCompanies()
        .then((resources) => {
          const rootCompany = {
            ...currentCompany,
            name: formatMessage({ id: 'general.defaultCompany' }, { companyName: currentCompany.name }),
          };
          setState({ companies: [rootCompany, ...resources], isLoaded: true });
        })
        .catch((response) => {
          setState((prevState) => ({ ...prevState, isLoaded: true }));
          toastResponseErrors(response);
        });
    }
  }, [isLoaded, setState, formatMessage, currentCompany]);

  return (
    <div className={cn('row', { [className]: !!className })}>
      <div className="small-12 column">
        <label>
          <Field
            label={<FormattedMessage id="general.farmManagementProvider" />}
            placeholderKey="general.farmManagementProvider"
            name={name}
            component={SelectField}
            options={companies}
            isLoading={!isLoaded}
            valueKey="id"
            labelKey="name"
          />
        </label>
      </div>
    </div>
  );
};

FarmManagerField.propTypes = {
  name: T.string.isRequired,
  currentCompany: T.object.isRequired,
  className: T.string,
};

export default FarmManagerField;
