import React from 'react';
import T from 'prop-types';
// components
import PlaceholderShape from 'components/PlaceholderShape/PlaceholderShape';
import TenantAssetIcon from './TenantAssetIcon';
import Waypoint from 'react-waypoint';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './TenantAssetBox.module.scss';

const cn = classnames.bind(styles);

const TenantAssetBox = ({
  dataName,
  assetId,
  contentType,
  progressStyle,
  backgroundImage,
  name,
  openDropdown,
  markAsSeen,
  isFocused,
  isViewed,
  isUploaded,
}, { isMobile }) => (
  <div
    data-name={dataName}
    data-asset-id={assetId}
    className={cn('asset-item-box', { isFocused, 'not-viewed': !isViewed || !assetId })}
  >
    <div className={cn('asset-thumbnail', { 'empty': !contentType, 'uploading': !isUploaded })}>
      {contentType === 'CompanyImage' && backgroundImage && (
        <div className={cn('img-thumb', 'animated fadeIn')} style={{ backgroundImage }} />
      )}
      {(contentType === 'CompanyImage') && !backgroundImage && (
        <TenantAssetIcon contentType={contentType} isBigger />
      )}
      {contentType && (contentType !== 'CompanyImage') && (
        <TenantAssetIcon contentType={contentType} isBigger />
      )}
    </div>
    {!assetId && (
      <div className={cn('upload-progress')}>
        <div className={cn('progress-value')} style={progressStyle} />
      </div>
    )}
    <div className={cn('asset-info-block')}>
      {contentType
        ? <TenantAssetIcon contentType={contentType} />
        : <PlaceholderShape width={25} height={25} borderRadius={2} />}
      {name
        ? <div className={cn('name')}>{name}</div>
        : <PlaceholderShape className="ml-10" height={15} borderRadius={2} />}
      {name && isMobile && assetId && (
        <i
          data-name={`${dataName}-dots`}
          className={cn('fa fa-dots-three-vertical', 'dots-icon')}
          onClick={openDropdown}
        />
      )}
    </div>
    {!isViewed && assetId && <Waypoint onEnter={markAsSeen} />}
  </div>
);

TenantAssetBox.contextTypes = {
  isMobile: T.bool.isRequired,
};

TenantAssetBox.propTypes = {
  dataName: T.oneOfType([T.string, T.number]),
  assetId: T.number,
  name: T.string,
  contentType: T.string,
  progressStyle: T.object,
  isFocused: T.bool,
  isViewed: T.bool,
  isUploaded: T.bool,
  backgroundImage: T.string,
  openDropdown: T.func,
  markAsSeen: T.func,
};

TenantAssetBox.defaultProps = {
  isFocused: false,
};

export default TenantAssetBox;
