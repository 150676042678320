import React from 'react';
import T from 'prop-types';
// components
import GoogleMapReact from 'google-map-react';

const createMapOptions = (maps) => ({
  minZoomOverride: true,
  minZoom: 4,
  disableDoubleClickZoom: true,
  zoomControlOptions: {
    position: maps.ControlPosition.TOP_RIGHT,
    style: maps.ZoomControlStyle.SMALL,
  },
  mapTypeControlOptions: {
    position: maps.ControlPosition.TOP_LEFT,
  },
  mapTypeControl: true,
});

const NEW_YORK_LAT_LNG = { lat: 40.719103, lng: -73.968222 };

const GoogleMap = ({ children, mapRef, ...props }) => (
  <GoogleMapReact
    center={NEW_YORK_LAT_LNG}
    zoom={11}
    options={createMapOptions}
    ref={mapRef}
    {...props}
  >
    {children}
  </GoogleMapReact>
);

GoogleMap.propTypes = {
  children: T.node,
};

export default GoogleMap;
