import React from 'react';
import T from 'prop-types';
// components
import { Field } from 'redux-form';
import ReactSelect from 'components/Select/ReactSelect';
// utils
import classnames from 'classnames/bind';
import getValue from 'lodash.get';
// styles
import styles from './MultiLineSelect.module.scss';

const cn = classnames.bind(styles);

const MultiLineSelect = ({ fields, label, addRowLabel, ...others }) => (
  <div>
    {fields.map((field, index) => (
      <div key={index} className="row">
        <div className={cn('small-12 medium-3 column', 'field-label')}>
          {!index && label}
        </div>
        <div className={cn('small-12 medium-9 column', 'field-select-row')}>
          <Field
            name={`${field}.id`}
            component={({ input }) => (
              <ReactSelect
                className={cn('field-select')}
                valueKey="id"
                labelKey="name"
                arrowRenderer={() => <i className="fa fa-angle-down" />}
                value={input.value || ''}
                onBlur={() => input.onBlur(getValue(input, 'id'))}
                onChange={(option) => (input.onChange(getValue(option, 'id', {})))}
                clearable={false}
                {...others}
              />
            )}
          />
          {(fields.length > 1) &&
            <i onClick={() => fields.remove(index)} className={cn('fa fa-trash-o', 'field-remove-btn')} />}
        </div>
      </div>
    ))}
    {(others.options.length > fields.length) && (
      <div className="row">
        <div className="small-12 medium-3 column" />
        <div className={cn('small-12 medium-9 column', 'add-field-row')}>
          <span onClick={() => fields.push({})} className={cn('add-field')}>{addRowLabel}</span>
        </div>
      </div>
    )}
  </div>
);

MultiLineSelect.propTypes = {
  fields: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]).isRequired,
  addRowLabel: T.oneOfType([T.string, T.node]).isRequired,
};

export default MultiLineSelect;
