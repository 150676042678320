import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
import { setPageOptions } from 'reducers/layout';
// recompose
import { compose } from 'recompose';
// hoc
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import withDropdownActions from 'hoc/withDropdownActions';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import FormattedWeight from 'components/FormattedWeight';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import SearchBox from 'components/SearchBox';
import DropdownButton from 'components/DropdownButton';
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import Button from 'components/Button';
import NothingBox from 'components/NothingBox';
import VerticalAligner from 'components/VerticalAligner';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// endpoints
import { downloadTreatmentEfficacyCSV } from 'endpoints/treatmentEfficacy';
import { getPigGroup, downloadGroupAllTreatmentEfficacyCSV } from 'endpoints/pigGroups';
// utils
import cn from 'classnames';
import isNull from 'lodash.isnull';
import moment from 'moment';
import download from 'downloadjs';
import { formatMortality } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isTablet } from 'react-device-detect';
// styles
import './TreatmentFeedbackGroup.scss';

class TreatmentFeedbackGroup extends Component {
  state = {
    pigGroup: {},
    isPigGroupLoading: false,
  }

  componentDidMount = () => {
    const { fetchData, setPageOptions } = this.props;
    this.fetchGroup();
    fetchData();
    setPageOptions({
      mobileTitle: 'treatmentFeedback',
      backLink: '',
    });
  };

  componentWillUnmount = () => {
    const { setPageOptions } = this.props;
    setPageOptions({
      mobileTitle: '',
      backLink: '',
    });
  };

  fetchGroup = () => {
    const { params } = this.props;
    this.setState((prevState) => ({ ...prevState, isPigGroupLoading: true }));
    getPigGroup(params.id)
      .then((pigGroup) => {
        this.setState({ pigGroup, isPigGroupLoading: false });
      })
      .catch((errors) => {
        toastResponseErrors(errors);
        this.setState((prevState) => ({ ...prevState, isPigGroupLoading: false }));
      });
  }

  openDropdown = (e, id) => {
    const { openDropdown } = this.props;
    const options = [
      { label: <FormattedMessage id="general.button.showDetails" />, url: `/treatments/feedback/efficacy/${id}` },
      { label: <FormattedMessage id="general.button.downloadDataCSV" />, onClick: () => this.downloadEfficacyCSV(id) },
      { label: <FormattedMessage id="general.manageMedication" />, url: `/admin/health-variables/medications/${id}` },
    ];
    openDropdown(e, options);
  };

  renderNameColumn = (rowData, { rowIndex }) => {
    const treatmentName = rowData?.treatment_product?.name;
    const id = rowData?.treatment_product?.id;
    return (
      <CustomColumn>
        <div className="product-name-column">
          <VerticalAligner>
            <i className="fa fa-water-soluble icon" />
            <span className="semibold">{treatmentName}</span>
          </VerticalAligner>
          <i
            data-name={`${treatmentName}-${id}-${rowIndex}`}
            className="fa fa-dots-three-horizontal hide-for-large"
            onClick={(e) => this.openDropdown(e, id)}
          />
        </div>
      </CustomColumn>
    );
  };

  renderTreatmentDateColumn = ({ created_at }) => (
    <CustomColumn label={<FormattedMessage id="general.treatmentDate" />}>
      <span className="semibold">{moment(created_at).format('YYYY-MM-DD')}</span>
    </CustomColumn>
  );

  renderFeedbackColumn = ({ effective }) => {
    const result = (
      <FormattedMessage id={`general.feedback.${effective ? 'effective' : 'ineffective'}`}>
        {(text) => <span className={cn('feedback-label', { effective, 'ineffective': !effective })}>{text}</span>}
      </FormattedMessage>
    );
    return (
      <CustomColumn label={<FormattedMessage id="general.feedback" />} noBottomBorder>
        {isNull(effective)
          ? <span className="color-dark-gray"><FormattedMessage id="general.feedback.resultsPending" /></span>
          : result}
      </CustomColumn>
    );
  };

  renderActionsColumn = ({ id, treatment_product = {} }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        label={<FormattedMessage id="general.button.view" />}
        buttonType="light-gray compact"
        url={`/treatments/feedback/efficacy/${id}`}
        dropDownData={[
          { label: <FormattedMessage id="general.button.downloadDataCSV" />,
            onClick: () => this.downloadEfficacyCSV(id) },
          { label: <FormattedMessage id="general.manageMedication" />,
            url: `/admin/health-variables/medications/${treatment_product.id}` },
        ]}
        customClass="show-for-large"
        wide
      />
      <Link to={`/treatments/feedback/efficacy/${id}`} className="button light hide-for-large">
        <FormattedMessage id="general.button.view" />
      </Link>
    </div>
  );

  renderExpandable = ({ id, treatment_product = {} }) => (
    <div>
      <Link className="button light small" to={`/treatments/feedback/efficacy/${id}`}>
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.button.showDetails" />
      </Link>
      <Button light small onClick={() => this.downloadEfficacyCSV(id)}>
        <i className="fa fa-download mr-5" />
        <FormattedMessage id="general.button.downloadData" />
      </Button>
      <Link className="button light small" to={`/admin/health-variables/medications/${treatment_product.id}`}>
        <i className="fa fa-gear mr-5" />
        <FormattedMessage id="general.manageMedication" />
      </Link>
    </div>
  );

  downloadEfficacyCSV = (id) => {
    const filename = `treatment-efficacy-${id}.csv`;
    return downloadTreatmentEfficacyCSV(id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  downloadGroupDataCSV = () => {
    const { params } = this.props;
    const filename = `farm-efficacy-${params.id}.csv`;
    return downloadGroupAllTreatmentEfficacyCSV(params.id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  getRowClass = ({ effective }) => (effective === false ? 'ineffective' : '');

  renderTitle = () => (
    <h2 className="lighter show-for-large"><FormattedMessage id="general.products" /></h2>
  );

  render() {
    const { resources, isLoading, meta: { total }, reqParams: { page, per_page, search, sort }, onSearchChange,
      onSortChange, onPageChange, onPerPageChange, user, params } = this.props;
    const { pigGroup, isPigGroupLoading } = this.state;
    const isAdmin = user.roles_map.admin;

    const groupStats = [
      { label: <FormattedMessage id="general.puc" />, value: pigGroup.pigs || 0 },
      { label: <FormattedMessage id="general.estAvgWeight" />,
        value: <FormattedWeight value={pigGroup.estimated_weight} unitFontSize="13px" /> },
      { label: <FormattedMessage id="general.mortalityRate" />, value: formatMortality(pigGroup.mortality_rate) },
    ];

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.productName" />, flex: '1 1 160px',
        renderer: this.renderNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.treatmentDate" />, flex: '1 1 100px',
        renderer: this.renderTreatmentDateColumn, sortKey: 'from_date' },
      { label: <FormattedMessage id="general.feedback" />, flex: '1 1 120px', renderer: this.renderFeedbackColumn,
        sortKey: 'effective', description: <FormattedMessage id="component.treatmentFeedbackHome.feedDesc" /> },
      { label: '', flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true,
        className: isTablet ? 'hide-for-large' : '' },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };
    const dropdownOptions = [
      { label: <FormattedMessage id="general.button.downloadCSV" />, onClick: this.downloadGroupDataCSV },
      { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${params.id}`, hide: !isAdmin },
    ];

    return (
      <div className="TreatmentFeedbackGroup">
        <GroupProfileHeader
          group={pigGroup}
          isLoading={isPigGroupLoading}
          dropdownOptions={dropdownOptions}
        >
          <ProfileHeaderStats stats={groupStats} />
        </GroupProfileHeader>

        <section className="small-12 column treatments-table-section">
          <Panel>
            <Panel.Heading className="treatments-table-header" renderTitle={this.renderTitle}>
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>

            <Panel.Body noPadding className="treatments-table-body">
              <Preloader isActive={isLoading} />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderExpandable}
                getRowClass={this.getRowClass}
                tableKey="groupTreatmentFeedback"
              />

              <NothingBox
                itemsName="products"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <h2 className="lighter">
                  <FormattedMessage id="component.nothingBox.noMedications" />
                </h2>
              </NothingBox>
            </Panel.Body>

          </Panel>
        </section>
      </div>
    );
  }
}

TreatmentFeedbackGroup.propTypes = {
  resources: T.array,
  isLoading: T.bool,
  params: T.object,
  meta: T.object,
  fetchData: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  openDropdown: T.func.isRequired,
  reqParams: T.object.isRequired,
  user: T.object.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.groupTreatmentFeedback.resources,
      isLoading: state.dataTable.groupTreatmentFeedback.isLoading,
      reqParams: state.dataTable.groupTreatmentFeedback.params,
      meta: state.dataTable.groupTreatmentFeedback.meta,
      user: state.auth.user,
    }),
    (dispatch, { params: { id } }) => ({
      fetchData: (queryParams) => dispatch(fetchData(`/pig_groups/${id}/treatment_efficacies`,
        'groupTreatmentFeedback', queryParams)),
      setPageOptions: (options) => dispatch(setPageOptions(options)),
    })
  ),
  withDropdownActions,
  withDataTableController(),
);

export default enhance(TreatmentFeedbackGroup);
