import { handleActions, createAction } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  filters: {},
  search: '',
  date_start: null,
  date_end: null,
};

const initialState = {
  show: false,
  params: defaultParams,
  searchText: '',
};

const HIDE_SEARCH = 'farmFeedSearch/HIDE_SEARCH';
const SHOW_SEARCH = 'farmFeedSearch/SHOW_SEARCH';
const SET_SEARCH = 'farmFeedSearch/SET_SEARCH';
const SET_SEARCH_TEXT = 'farmFeedSearch/SET_SEARCH_TEXT';

// ------------------------------------
// Actions
// ------------------------------------
export const hideSearch = createAction(HIDE_SEARCH);
export const showSearch = createAction(SHOW_SEARCH);
export const setSearch = createAction(SET_SEARCH);
export const setSearchText = createAction(SET_SEARCH_TEXT);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [HIDE_SEARCH]: (state) => ({
    ...initialState,
    searchText: state.searchText,
  }),
  [SHOW_SEARCH]: (state) => ({
    ...state,
    show: true,
  }),
  [SET_SEARCH]: (state, { payload }) => ({
    ...state,
    params: { ...defaultParams, ...payload },
  }),
  [SET_SEARCH_TEXT]: (state, { payload }) => ({
    ...state,
    searchText: payload,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
