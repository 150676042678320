import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import OralTreatmentUnit from './OralTreatmentUnit';

const OralTreatmentLabel = ({ quantity, gallonsValue, product }) => (
  <span>
    <span>{product.name} • </span>
    {product.dosage_measure !== 'head'
      ? <span>{quantity} {quantity < 1 ? 'of' : 'x'} <OralTreatmentUnit product={product} /></span>
      : <span>{quantity} <FormattedMessage id="container.checkupEdit.reportMedications.oralLabel" /></span>}
    {gallonsValue && ` / ${parseInt(gallonsValue, 10)} `}
    {gallonsValue && <FormattedMessage id="general.short.gal" />}
  </span>
);

OralTreatmentLabel.propTypes = {
  product: T.object.isRequired,
  quantity: T.oneOfType([T.string, T.number]),
  gallonsValue: T.oneOfType([T.string, T.number]),
};

export default OralTreatmentLabel;
