import React from 'react';
// styles
import './ReachEndPlaceholder.scss';
// translation
import { FormattedMessage } from 'react-intl';

const ReachEndPlaceholder = () => (
  <div className="ReachEndPlaceholder">
    <div className="pattern" />
    <div className="body-content">
      <i className="fa fa-check" />
      <FormattedMessage id="general.reachTheEnd" />
    </div>
  </div>
);

export default ReachEndPlaceholder;
