import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import FilterPanel from 'components/FilterPanel/FilterPanel';
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect';
import CollapsibleFilter from 'components/CollapsibleFilter/CollapsibleFilter';
// utils
import isEqual from 'lodash.isequal';
import classnames from 'classnames/bind';
import pick from 'lodash.pick';
// styles
import styles from './CheckinsMapFilter.module.scss';
// const
import { timeOptions } from './CheckinsMap/utils';

const cn = classnames.bind(styles);

const initialState =  {
  created_at: '',
  search_farm_or_user: '',
};

class CheckinsMapFilter extends Component {

  state = {
    ...pick(this.props.params, ['created_at', 'search_farm_or_user']),
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state !== nextState);
  }

  onApplyFilters = () => {
    this.props.fetchCheckins({ ...this.state });
    this.props.closePortal();
  };

  fetchData = () => {
    const { fetchCheckins, isMobile } = this.props;
    if (!isMobile) fetchCheckins({ ...this.state });
  };

  onSearchChange = (search) => {
    this.setState({ search_farm_or_user: search }, this.fetchData);
  };

  onTimePeriodChange = (date) => {
    this.setState({ created_at: date }, this.fetchData);
  };

  onFilterReset = () => {
    if (!isEqual(this.state, initialState)) {
      this.setState(initialState, this.fetchData);
      this.searchbox.resetSearch();
    }
  };

  render() {
    const { isMobile, closePortal } = this.props;
    const { search_farm_or_user, created_at } = this.state;

    return (
      <FilterPanel
        isMobile={isMobile}
        onClose={closePortal}
        onReset={this.onFilterReset}
        onApply={this.onApplyFilters}
      >
        <div className={cn('search-box')}>
          <SearchBox
            ref={(ref) => { this.searchbox = ref; }}
            initialValue={search_farm_or_user}
            onChange={this.onSearchChange}
            placeholderKey="general.placeholder.searchFarmsOrUsers"
          />
        </div>

        <CollapsibleFilter title={<FormattedMessage id="general.timePeriod" />}>
          <ResponsiveSelect
            grey
            className="pt-5 pb-5"
            noInputMargin
            options={timeOptions}
            onChange={this.onTimePeriodChange}
            value={created_at}
          />
        </CollapsibleFilter>
      </FilterPanel>
    );
  }
}

CheckinsMapFilter.propTypes = {
  fetchCheckins: T.func.isRequired,
  params: T.object.isRequired,
  closePortal: T.func,
  isMobile: T.bool,
};

export default CheckinsMapFilter;
