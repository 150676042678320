import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
// components
import OfflineScreen from 'components/OfflineScreen';
// utils
import { isMobile } from 'react-device-detect';
// assets
import './Checkins.scss';

const Checkins = ({ children, isOnline, setPageOptions }) => {
  useEffect(() => {
    if (isMobile) setPageOptions({ mobileTitle: 'checkinsHistory' });
    return () => {
      setPageOptions({ mobileTitle: '' });
    };
  }, []);

  return (
    <div className="Checkins">
      {isOnline ? children : <OfflineScreen />}
    </div>
  );
};

Checkins.propTypes = {
  children: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  isOnline: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  }),
  { setPageOptions }
)(Checkins);
