import React, { useEffect, Fragment, useState } from 'react';
import T from 'prop-types';
// components
import Panel from 'components/Panel';
import { FormattedMessage } from 'react-intl';
import Waypoint from 'react-waypoint';
import Preloader from 'components/Preloader';
import CurrencyInput from './CurrencyInput';
import SyncNote from 'components/SyncNote/SyncNote';
import ReactSelect from 'components/Select/ReactSelect';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './TruckingTermsPanel.module.scss';

const cn = classnames.bind(styles);

const defaultParams = {
  page: 1,
  perPage: 10
};

const noteTextareaProps = { rows: 4 };

const rateTypeOptions = [
  { value: 'cwt', label: <FormattedMessage id="general.rateType.cwt" /> },
  { value: 'hourly', label: <FormattedMessage id="general.rateType.hourly" /> },
  { value: 'flat', label: <FormattedMessage id="general.rateType.flat" /> },
];

const TruckingTermsPanel = ({ getFarmTerms, updateFarm, updateFarmTerm, truckingComment }) => {
  const [{
    terms,
    total,
    currentPage,
    isLoading,
    updatingTermId,
  }, setState] = useState({ terms: [], total: 0, currentPage: 1, isLoading: false, updatingTermId: undefined });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getFarmTerms(defaultParams)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          terms: response.resources,
          total: response.meta.total,
          isLoading: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, []);

  const fetchMore = () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getFarmTerms({ ...defaultParams, page: currentPage + 1 })
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          currentPage: currentPage + 1,
          terms: [...prevState.terms, ...response.resources],
          isLoading: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const setRateData = (termId, resource) => {
    setState((prevState) => ({ ...prevState, updatingTermId: termId }));
    updateFarmTerm(termId, resource)
      .then((term) => {
        setState((prevState) => {
          const index = prevState.terms.findIndex((item) => term.id === item.id);
          return {
            ...prevState,
            updatingTermId: undefined,
            terms: [
              ...prevState.terms.slice(0, index),
              term,
              ...prevState.terms.slice(index + 1),
            ],
          };
        });
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, updatingTermId: undefined }));
      });
  };

  return (
    <Fragment>
      <Panel className="medium-12 xlarge-8 mb-15">
        <Panel.Heading
          title={<FormattedMessage id="general.truckingTerms" />}
        />
        <Panel.Body className="ph-20">
          <div className={cn('plant-header')}>
            <div className={cn('plant-column')}>
              <FormattedMessage id="general.destinationTypes.packingPlant" />
            </div>
            <div className={cn('rate-data-column', 'mr-10', 'ellipsis')}>
              <FormattedMessage id="general.saleTruckingRate" />
            </div>
            <div className={cn('rate-data-column')}>
              <FormattedMessage id="general.rateType" />
            </div>
          </div>

          {terms.map((item) => (
            <div key={item.id} className={cn('plant-line', { 'is-loading': updatingTermId === item.id })}>
              {updatingTermId === item.id && (
                <Preloader className={cn('plant-line-preloader')} isActive />
              )}
              <div className={cn('plant-column')}>{item.name}</div>
              <CurrencyInput
                className={cn('rate-data-column', 'mr-10')}
                initialValue={item.rate}
                onSave={(rate) => setRateData(item.id, { rate_type: item.rate_type || 'cwt', rate })}
              />
              <div className={cn('rate-data-column')}>
                <ReactSelect
                  noInputMargin
                  options={rateTypeOptions}
                  placeholder={<FormattedMessage id="general.chooseRateType" />}
                  value={item.rate_type}
                  onChange={(option) => (option
                    ? setRateData(item.id, { rate: item.rate, rate_type: option.value })
                    : null
                  )}
                  clearable={false}
                  searchable={false}
                />
              </div>
            </div>
          ))}

          {isLoading && (
            <div className={cn('preloader')}>
              <Preloader isActive />
            </div>
          )}

          {!isLoading && terms.length !== total && (
            <Waypoint onEnter={fetchMore} />
          )}
        </Panel.Body>
      </Panel>

      <Panel className="medium-12 xlarge-8 mb-15">
        <Panel.Heading title={<FormattedMessage id="general.notes" />} />
        <Panel.Body className="ph-20">
          <SyncNote
            componentProps={noteTextareaProps}
            comment={truckingComment}
            onSave={(comment) => updateFarm({ logistics_trucking_comment: comment })}
          />
        </Panel.Body>
      </Panel>
    </Fragment>
  );
};

TruckingTermsPanel.propTypes = {
  getFarmTerms: T.func.isRequired,
  updateFarm: T.func.isRequired,
  updateFarmTerm: T.func.isRequired,
  truckingComment: T.string.isRequired,
};

export default TruckingTermsPanel;
