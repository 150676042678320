import React from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import ResponsiveDropdown from 'components/ResponsiveDropdown';
import Preloader from 'components/Preloader';
import { ThreeDotsBtn } from 'components/Button/Button';
// styles
import cn from 'classnames';
import './ShareholderGroupProfileHeader.scss';

const ShareholderGroupProfileHeader = ({ className, isSticky, children, dropdownOptions, isLoading, group }) => (
  <div className={cn('ShareholderGroupProfileHeader', className, { 'isSticky': isSticky })}>
    <Preloader isActive={isLoading} />
    <Avatar type="shareholder_group" avatarSize={50} iconSize={24} />
    <div className="group-information">
      <div className="group-info-wrapper">
        <span className="group-name">{group.name}</span>
        <StatusBadge status={group.status} />
      </div>
    </div>

    <div className="group-actions">
      {children}
      {!!dropdownOptions.length && (
        <ResponsiveDropdown
          className="group-profile-dropdown ml-10"
          options={dropdownOptions}
          trigger={<ThreeDotsBtn />}
        />
      )}
    </div>
  </div>
);

ShareholderGroupProfileHeader.propTypes = {
  group: T.object,
  className: T.string,
  isSticky: T.bool,
  children: T.any,
  isLoading: T.bool,
  dropdownOptions: T.array,
};

ShareholderGroupProfileHeader.defaultProps = {
  className: '',
  children: null,
  dropdownOptions: [],
  group: {},
};

export default ShareholderGroupProfileHeader;
