import React, { Component, createRef } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
import { updateCurrentUser } from 'reducers/auth';
// components
import Modal from 'components/Modal';
import ImageCropperMobile from './ImageCropperMobile';
import InputRange from 'components/InputRange/InputRange';
import Cropper from 'react-cropper';
import Preloader from 'components/Preloader';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// style
import styles from './ImageCropperModal.module.scss';
import 'cropperjs/dist/cropper.css';
import './cropper.scss';

const cn = classnames.bind(styles);

class ImageCropperModal extends Component {

  constructor(props) {
    super(props);
    this.cropperRef = createRef();
    this.state = {
      image: props.avatar,
      isLoading: false,
    };
  }

  uploadImage = ({ target: { files } }) => {
    if (!files[0]) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    this.setState({ isLoading: true });
    reader.onload = ({ target: { result } }) => {
      this.setState({
        isLoading: false,
        image: result,
      });
    };
  };

  onReady = () => {
    const { cropper } = this.cropperRef.current;
    if (cropper) {
      cropper.setDragMode('move');
      const cropDiameter = this.getCropperDiameter(cropper);
      const { width, height } = cropper.getContainerData();
      cropper.setCropBoxData({
        height: cropDiameter,
        left: (width - cropDiameter) / 2,
        top: (height - cropDiameter) / 2,
      });
    }
  };

  onSave = () => {
    const croppedImage = this.cropperRef.current.getCroppedCanvas().toDataURL();
    this.updateImage(croppedImage);
  };

  getCropperDiameter = (cropper) => {
    const imageCoords = cropper.getImageData();
    return Math.min(imageCoords.height, imageCoords.width);
  };

  updateDiameterIfChanged = (cropper) => {
    const cropBoxData = cropper.getCropBoxData();
    const diameterAfterRotate = this.getCropperDiameter(cropper);
    if (cropBoxData.height !== diameterAfterRotate) {
      cropper.setCropBoxData({ height: diameterAfterRotate });
    }
  };

  removeImage = () => {
    const { id, updateCurrentUser, closeModal } = this.props;
    updateCurrentUser({ remove_profile_photo: true }, id)
      .then(() => {
        closeModal();
        showToastrMessage('component.toastr.myProfileEdit.success.avatar');
      }).catch(toastResponseErrors);
  };

  onRotate = () => {
    this.cropperRef.current.rotate(-90);
    this.updateDiameterIfChanged(this.cropperRef.current.cropper);
  };

  zoomTo = () => {
    let prevZoom = 0;
    return ({ target: { value } }) => {
      const ratio = +value - prevZoom;
      const { cropper } = this.cropperRef.current;
      const image = cropper.getImageData();
      image.zoomLevel = (image.width / image.naturalWidth) + ratio;
      cropper.zoomTo(image.zoomLevel);
      prevZoom = +value;
    };
  };

  rotateTo = ({ target: { value } }) => {
    this.cropperRef.current.rotateTo(value);
    this.updateDiameterIfChanged(this.cropperRef.current.cropper);
  };

  updateImage = (image = null) => {
    const { id, updateCurrentUser, closeModal } = this.props;
    this.setState({ isLoading: true });
    updateCurrentUser({ profile_photo_data_uri: image }, id)
      .then(() => {
        closeModal();
        showToastrMessage('component.toastr.myProfileEdit.success.avatar');
      }).catch(toastResponseErrors);
  };

  render() {
    const { image, isLoading } = this.state;
    const { isMobile } = this.context;
    const { closeModal, isEditMode } = this.props;
    const zoom = this.zoomTo();

    const renderCropper = (isViewMode = false) => (
      <Cropper
        className={`${cn('image-cropper')} ${isViewMode && 'guides-off'}`}
        src={image}
        cropBoxResizable={false}
        toggleDragModeOnDblclick={false}
        aspectRatio={3 / 3}
        viewMode={2}
        ref={this.cropperRef}
        background={false}
        zoomOnWheel={false}
        ready={this.onReady}
      />
    );

    if (isMobile) return (
      <Modal
        className={cn('ImageCropperModal', 'mobile')}
        title={null}
        noCloseButton
      >
        <ImageCropperMobile
          rotateTo={this.rotateTo}
          isLoading={isLoading}
          onSave={this.onSave}
          renderCropper={renderCropper}
          closeModal={closeModal}
          onRotate={this.onRotate}
          uploadImage={this.uploadImage}
          removeImage={this.removeImage}
          isEditMode={isEditMode}
        />
      </Modal>
    );

    return (
      <Modal
        className={cn('ImageCropperModal')}
        title={<FormattedMessage id="component.imageCropperModal.editPhoto" />}
      >
        <Preloader isActive={isLoading} />
        <>
          <i className={cn('rotate-btn', 'fa fa-undo')} onClick={this.onRotate} />
          {renderCropper()}
          <div className={cn('edit-panel')}>
            <InputRange
              className="pr-25"
              defaultValue={0}
              from={0}
              to={4}
              step={0.01}
              label={<FormattedMessage id="component.imageCropperModal.zoom" />}
              onChange={zoom}
            />
            <InputRange
              from={-180}
              to={180}
              step={10}
              label={<FormattedMessage id="component.imageCropperModal.straighten" />}
              onChange={this.rotateTo}
            />
          </div>
          <div className={cn('actions-panel')}>
            {/* remove button */}
            <Button className={cn('remove-btn')} transparent onClick={this.removeImage}>
              <FormattedMessage id="component.imageCropperModal.deletePhoto" />
            </Button>
            {/* upload button */}
            <label htmlFor="image-upload" className={cn('image-label')}>
              <div className="button hollow mr-10">
                <FormattedMessage id="component.imageCropperModal.replacePhoto" />
              </div>
              <input onChange={this.uploadImage} type="file" id="image-upload" accept="image/png, image/jpeg" />
            </label>
            {/* save button */}
            <Button onClick={this.onSave} primary>
              <FormattedMessage id="component.imageCropperModal.savePhoto" />
            </Button>
          </div>
        </>
      </Modal>
    );
  }
}

ImageCropperModal.contextTypes = {
  isMobile: T.bool,
};

ImageCropperModal.propTypes = {
  avatar: T.string,
  id: T.number.isRequired,
  updateCurrentUser: T.func.isRequired,
  closeModal: T.func.isRequired,
  isEditMode: T.bool,
};

export default connect(null, { closeModal, updateCurrentUser })(ImageCropperModal);
