import { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { getInvitedUserByToken, getInvitedUserById } from 'reducers/invitations';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const InvitationsContainer = ({
  children,
  getInvitedUserByToken,
  getInvitedUserById,
  params: { token, id },
  router,
}) => {
  useEffect(() => {
    const errorsCallback = (errors) => {
      router.push('/login');
      toastResponseErrors(errors);
    };
    if (token) getInvitedUserByToken(token).catch(errorsCallback);
    if (id) getInvitedUserById(id).catch(errorsCallback);
  }, []);

  return children;
};

InvitationsContainer.propTypes = {
  getInvitedUserByToken: T.func.isRequired,
  getInvitedUserById: T.func.isRequired,
  params: T.object.isRequired,
  router: T.object.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default connect(null, { getInvitedUserByToken, getInvitedUserById })(InvitationsContainer);
