import React, { Component } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
// redux
import { updateShareholderGroup } from 'reducers/admin/shareholderGroups/groupShow';
// components
import { FormattedMessage } from 'react-intl';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
import ShareholderGroupForm from 'containers/Admin/ShareholderGroups/components/ShareholderGroupForm';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const customErrors = [
  { key: 'name', error: <FormattedMessage id="general.customErrors.shGroup" /> },
];

class ShareholderGroupEdit extends Component {

  getInitialValues = () => {
    const { params: { id }, group } = this.props;
    if (group.id === Number(id)) {
      return { name: group.name };
    }
    return null;
  };

  updateGroup = (data) => {
    const { updateShareholderGroup, params: { id } } = this.props;

    return updateShareholderGroup(id, data)
      .then(() => {
        showToastrMessage('component.toastr.shGroup.updated');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const initialValues = this.getInitialValues();
    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.shareholderGroupInfo" />} />
        <Panel.Body>
          <div className="row">
            <div className="small-12 medium-10 large-8 column">
              <FormErrorsBox
                excludedFields={['user']}
                formName="shareholder-group-form"
                customErrors={customErrors}
              />
              <ShareholderGroupForm isEditMode onSubmit={this.updateGroup} initialValues={initialValues} />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

ShareholderGroupEdit.propTypes = {
  updateShareholderGroup: T.func,
  group: T.object,
  params: T.object,
};

export default connect(
  (state) => ({
    group: state.shareholderGroups.groupShow.data,
  }),
  { updateShareholderGroup }
)(ShareholderGroupEdit);
