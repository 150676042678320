import React, { Component } from 'react';
import T from 'prop-types';
import { API_URL } from '../../constants';
// redux
import { connect } from 'react-redux';
import { uploadAssetComplete, updateAsset, updateAlert } from 'reducers/dailyCheckup/checkupEdit';
// components
import Button from 'components/Button';
import Input from 'components/Input';
import { Link } from 'react-router';
import CenterBox from 'components/CenterBox';
import StickyFooter from 'components/StickyFooter';
import CommentBox from 'components/CommentBox';
import AudioPreview from 'components/AudioPreview';
import MessageBox from 'components/MessageBox';
import { FormattedMessage } from 'react-intl';
import { ReactMic } from 'react-mic';
// utils
import Resumable from 'resumablejs';
import { isIE } from 'react-device-detect';
import { checkMicrophoneCaptured } from 'utils/micHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getAuthData } from 'utils/authData';
import { toastr } from 'react-redux-toastr';
import padStart from 'lodash.padstart';
import cn from 'classnames';
import { sendNotify } from 'utils/airbrakeHelper';
// endpoints
import { getCheckupChunkAsset } from 'endpoints/checkup/checkupEdit';
// indexedDB
import { addMediaRequest, generateAssetObject } from 'utils/offlineHelper';
// styles
import './AudioRecorder.scss';

class AudioRecorder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      recording: false,
      time: 0,
      saveScreen: false,
      title: '',
      notes: '',
      isSavingFile: false,
    };
    this.isSupported = navigator.mediaDevices;
  }

  componentDidMount() {
    this.initializeFileUploader();
  }

  initializeFileUploader = () => {
    const { params } = this.props;
    const { location } = this.context.router;
    const uploadPath = location.query.alert_id
      ? `${API_URL}/daily_checkups/${params.id}/chunk?category=symptoms`
      : `${API_URL}/daily_checkups/${params.id}/chunk`;

    const R = new Resumable({
      target: uploadPath,
      testTarget: uploadPath,
      headers: { 'Accept': '*/*', ...getAuthData() },
      simultaneousUploads: 1,
      testChunks: true,
      chunkRetryInterval: 500,
      maxFileSize: 314572800,
      maxFileSizeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className="fa fa-ban" />,
          component: (
            <FormattedMessage id="general.fileSizeExceeded" values={{ size: '300mb', filename: file.name }}>
              {(text) => (<div className="rrt-text">{text}</div>)}
            </FormattedMessage>
          ),
        });
      },
    });

    if (R.support) {
      this.Resumable = R;
      R.on('fileAdded', this.onFileAdded);
      R.on('fileSuccess', this.onFileSuccess);
      R.on('fileError', this.onFileError);
    } else {
      // eslint-disable-next-line no-console
      console.warn('ResumableJS not supported!');
    }
  };

  onFileAdded = (file) => {
    const { router } = this.context;
    const { checkup, isOnline, uploadAssetComplete, params } = this.props;
    const { notes } = this.state;
    const R = file.resumableObj;

    if (isOnline) {
      R.upload();
      return;
    }

    addMediaRequest(checkup, {
      isAudio: true,
      uniqueIdentifier: file.uniqueIdentifier,
      file: file.file,
      description: notes,
    });
    const newAsset = generateAssetObject(file, {
      dc_id: checkup.id,
      description: notes,
      media_type: file.file.type || 'audio/webm',
      url: { original: this.state.blobObject.blobURL },
    });
    uploadAssetComplete(newAsset);
    router.push(`/daily-checkup/${params.id}`);
  };

  onFileSuccess = (file) => {
    const { router } = this.context;
    const { uploadAssetComplete, updateAsset, updateAlert, params, checkup, currentUser } = this.props;
    const { notes } = this.state;
    const alertId = router.location.query.alert_id;

    getCheckupChunkAsset(params.id, { chunk_identifier: file.uniqueIdentifier })
      .then((resource) => {
        const promises = [];

        if (alertId) {
          promises.push(updateAlert(params.id, alertId, { status: 'uploaded' }));
        } else {
          promises.push(uploadAssetComplete({
            ...resource,
            dc_id: checkup.id,
          }));
        }
        // update asset description after it's creation
        if (notes) {
          promises.push(updateAsset(params.id, resource.id, { description: notes }));
        }

        Promise.all(promises)
          .then(() => router.push(`/daily-checkup/${params.id}`));
      })
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  onFileError = (file, message) => {
    const { currentUser } = this.props;
    // eslint-disable-next-line no-console
    console.log('ResumableJS: onFileError', file.fileName);
    sendNotify(message, currentUser);
    toastResponseErrors(message);
  };

  startRecording = () => {
    if (!this.isSupported) return;

    checkMicrophoneCaptured()
      .then(() => {
        this.setState({
          recording: true,
          blobURL: null,
          blobObject: null,
          time: 0,
        });

        this.timer = setInterval(() => this.setState(({ time = 0 }) => ({ time: time + 1 })), 1000);
      });
  };

  stopRecording = () => {
    this.setState({ recording: false });
    clearInterval(this.timer);
  };

  onStopRecording = (blobObject) => {
    this.setState({
      blobObject,
      blobURL: blobObject.blobURL,
      title: `recording-${Date.now()}`,
    });
  };

  playAudio = () => {
    const { blobURL } = this.state;
    this.audio = new Audio(blobURL);
    this.audio.addEventListener('ended', this.stopAudio);
    this.audio.play();
    this.setState({ isPlaying: true });
  };

  stopAudio = () => {
    this.audio.removeEventListener('ended', this.stopAudio);
    this.audio.pause();
    this.setState({ isPlaying: false });
  };

  removeBlobObject = () => {
    clearInterval(this.timer);
    this.setState({
      blobURL: null,
      blobObject: null,
      recording: false,
      time: 0,
    });
  };

  titleOnChange = (e) => {
    this.setState({ title: e.target.value });
  };

  notesOnChange = (notes) => {
    this.setState({ notes });
  };

  goToSaveAudioScreen = () => {
    this.setState({ saveScreen: true });
  };

  returnToRecordingScreen = () => {
    this.setState({ saveScreen: false });
    this.removeBlobObject();
  };

  renderRecordingTime = (seconds = 0) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${padStart(min, 2, '0')}:${padStart(sec, 2, '0')}`;
  };

  saveAudioFile = () => {
    const { blobObject, title } = this.state;
    const { Resumable } = this;
    const extension = 'webm';
    const audioFile = new File([blobObject.blob], `${title}.${extension}`);
    this.setState({ isSavingFile: true });
    Resumable.addFile(audioFile);
  };

  renderButton = (className = '') => {
    const { saveScreen, isSavingFile, title, blobURL } = this.state;
    return (
      saveScreen
        ? (
          <Button primary className={`${className}`} disabled={!title || isSavingFile} onClick={this.saveAudioFile}>
            <FormattedMessage id={`general.button.${isSavingFile ? 'saving' : 'save'}`} />
          </Button>
        )
        : (
          <Button primary className={`${className}`} disabled={!blobURL} onClick={this.goToSaveAudioScreen}>
            <FormattedMessage id="general.button.continue" />
          </Button>
        )
    );
  };

  render() {
    const { checkup, isLoading, params } = this.props;
    const { recording, time, blobURL, blobObject, saveScreen, title, notes, isSavingFile, isPlaying } = this.state;
    const backLink = `/daily-checkup/${params.id}`;

    return (
      <CenterBox className="AudioRecorder" isLoading={isLoading || isSavingFile} renderIf={Boolean(checkup)}>

        <section className="center-box-header show-for-large">
          <h1 className="title">
            <FormattedMessage id="container.checkupEdit.recordAudio" />
          </h1>
          <Link to={backLink}><i className="fa fa-times close-center-box" /></Link>
        </section>

        {/* screen 1: record audio */}
        {!saveScreen && (
          <section className="center-box-body audio-recorder animated fadeIn">
            {!isIE && (
              <ReactMic
                className={cn('audio-visualizer', { 'recording': recording })}
                record={recording}
                onStop={this.onStopRecording}
                visualSetting="frequencyBars"
                strokeColor="#ff5e31"
                backgroundColor="#fafafa"
              />
            )}
            {!this.isSupported && (
              <section>
                <MessageBox type="alert">
                  <FormattedMessage id="component.alertBox.audioWarning" />
                </MessageBox>
              </section>
            )}
            <div className="audio-controls">
              <div className="audio-buttons">
                <Button className="control-button cancel-button" disabled={!blobURL} onClick={this.removeBlobObject}>
                  <i className="fa fa-times" style={{ fontSize: '20px' }} />
                </Button>
                {recording
                  ? (
                    <Button className="control-button main-control secondary" onClick={this.stopRecording}>
                      <i className="fa fa-pause" />
                    </Button>
                  )
                  : (
                    <Button className="control-button main-control primary" onClick={this.startRecording}>
                      <i className="fa fa-mic" />
                    </Button>
                  )}
                <Button
                  className="control-button"
                  disabled={!blobURL}
                  onClick={isPlaying ? this.stopAudio : this.playAudio}
                >
                  <i className={`fa fa-${isPlaying ? 'stop' : 'play'}`} style={{ fontSize: '32px' }} />
                </Button>
              </div>
              <div className="recording-time">
                <span>{this.renderRecordingTime(time)}</span>
              </div>
            </div>
          </section>
        )}

        {/* screen 2: save audio */}
        {saveScreen && (
          <section className="center-box-body audio-recorder animated fadeIn">
            <AudioPreview className="record-preview" onDelete={this.returnToRecordingScreen} blob={blobObject} />
            <div className="record-info">
              <label className="filename-input">
                <div className="tag">
                  <FormattedMessage id="general.title" />
                </div>
                <FormattedMessage id="container.checkupEdit.enterTitle">
                  {(placeholder) => (
                    <Input
                      type="text"
                      value={title}
                      placeholder={placeholder}
                      onChange={this.titleOnChange}
                      name="filename"
                      autoFocus
                    />
                  )}
                </FormattedMessage>
              </label>
              <CommentBox
                className="comment-box"
                value={notes}
                onValueChange={this.notesOnChange}
                isOpen
              />
            </div>
          </section>
        )}

        <section className="center-box-footer text-right show-for-large">
          {this.renderButton('wider')}
        </section>

        <StickyFooter className="hide-for-large">
          {this.renderButton()}
        </StickyFooter>
      </CenterBox>
    );
  }
}

AudioRecorder.contextTypes = {
  router: T.object.isRequired,
};

AudioRecorder.propTypes = {
  checkup: T.object,
  isLoading: T.bool,
  isOnline: T.bool,
  params: T.object,
  uploadAssetComplete: T.func.isRequired,
  updateAsset: T.func.isRequired,
  updateAlert: T.func.isRequired,
  currentUser: T.object.isRequired,
};

const mapStateToProps = (state) => ({
  checkup: state.dailyCheckup.checkupEdit.resource,
  isLoading: state.dailyCheckup.checkupEdit.isLoading,
  isOnline: state.network.isOnline,
  currentUser: state.auth.user,
});

const mapDispatchToProps = {
  uploadAssetComplete,
  updateAsset,
  updateAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioRecorder);
