import React from 'react';
import T from 'prop-types';
import { compose, withHandlers } from 'recompose';
// utils
import cn from 'classnames';
// assets
import './ClusterMarker.scss';

const MARKER_SIZE = 35;

const ClusterMarker = ({ cluster, onClick, className }) => (
  <div
    className={cn('ClusterMarker', `cluster-type-${cluster.type}`, className)}
    style={{ width: MARKER_SIZE, height: MARKER_SIZE, left: -MARKER_SIZE / 2, top: -MARKER_SIZE / 2 }}
    onClick={onClick}
  >
    <span className="cluster-size">{cluster.numPoints}</span>
    {!!cluster.checkinsCount && <div className="cluster-badge">{cluster.checkinsCount}</div>}
  </div>
);

ClusterMarker.propTypes = {
  cluster: T.object.isRequired,
  onClick: T.func.isRequired,
  className: T.string,
};

const enhance = compose(
  withHandlers({
    onClick: ({ onClick, lat, lng }) => () => {
      return onClick ? onClick({ lat, lng }) : null;
    },
  })
);

export default enhance(ClusterMarker);
