import React, { Component } from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
import throttle from 'lodash.throttle';
// styles
import './FlexTable.scss';

class FlexTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      leftOverflow: false,
      rightOverflow: false,
    };
    this.onTableScrollThrottled = throttle(this.onTableScroll, 200);
  }

  componentDidMount() {
    // set initial overflow state
    this.setInitialOverflowState();
  }

  setInitialOverflowState = () => {
    const isRightOverflow = this.table.scrollWidth > this.wrapper.scrollWidth;
    if (isRightOverflow !== this.state.rightOverflow) {
      this.setState({ rightOverflow: true });
    }
  };

  onTableScroll = ({ target }) => {
    const { scrollLeft, scrollWidth, clientWidth } = target;
    this.setState({
      leftOverflow: scrollLeft > 0,
      rightOverflow: scrollLeft !== (scrollWidth - clientWidth),
    });
  };

  handleTableScroll = (e) => {
    e.persist();
    this.onTableScrollThrottled(e);
  };

  getReference = (ref) => {
    const { getRef } = this.props;
    this.wrapper = ref;
    if (getRef) getRef(ref);
  };

  render() {
    const { children, className, wrapperClassName, scrollable, style } = this.props;
    const { leftOverflow, rightOverflow } = this.state;

    return (
      <div
        ref={this.getReference}
        className={cn('FlexTableWrapper', wrapperClassName, { scrollable })}
        onScroll={this.handleTableScroll}
        style={style}
      >
        <div
          className={cn('FlexTable', className, { scrolledLeft: leftOverflow, scrolledRight: rightOverflow })}
          ref={(ref) => { this.table = ref; }}
        >
          {children}
        </div>
      </div>
    );
  }
}

FlexTable.defaultProps = {
  wrapperClassName: '',
};

FlexTable.propTypes = {
  children: T.any,
  className: T.oneOfType([T.string, T.object]),
  wrapperClassName: T.string,
  getRef: T.func,
  scrollable: T.bool,
  style: T.object,
};

export default FlexTable;
