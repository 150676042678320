import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import UserAvatar from 'components/UserAvatar';
// utils
import { renderMentionsText, formatCheckupLogDate, formatDateAt } from 'utils';
import cn from 'classnames';
// styles
import './CheckupLogsList.scss';

const CheckupLogsList = ({
  logs,
  className = '',
  currentUser,
  checkup,
}) => {
  const comments = checkup.comments?.reduce(
    (accumulator, { role, comment, ...rest }) => ({
      ...accumulator,
      [`${role}_commented`]: { comment, ...rest }
    }), {}
  );

  const addCommentText = (variant) => {
    const { comment } = comments?.[variant] || {};
    return comment
      ? <span className="italic"> {renderMentionsText(comment)}</span>
      : '';
  };

  return (
    <div className={cn('CheckupLogsList', { [className]: !!className })}>
      {logs.map(({ id, updated_at, user, variety }) => {
        const isAuthor = currentUser.id === user.id;
        return (
          <div className="activity-row" key={id}>
            <UserAvatar size="40" user={user} className="user-avatar" />
            <div className="log">
              <div className="description">
                <strong>{isAuthor ? <FormattedMessage id="general.you" /> : user.full_name} </strong>
                {variety.map((variant) => (
                  <span key={variant} className="variety">
                    <FormattedMessage id={`component.checkupActivity.${variant}`} />
                    {addCommentText(variant)}
                    <span className="comma">, </span>
                  </span>
                ))}
              </div>
              <div className="time">
                {checkup.pig_group?.farm?.utc_offset
                  ? formatCheckupLogDate(updated_at, checkup.pig_group.farm.utc_offset)
                  : formatDateAt(updated_at)
                }
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CheckupLogsList.propTypes = {
  logs: T.array,
  className: T.string,
  currentUser: T.object.isRequired,
  checkup: T.object.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  })
)(CheckupLogsList);
