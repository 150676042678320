import React from 'react';
import T from 'prop-types';
// hoc
import withSoundOnMount from 'hoc/withSoundOnMount';
// components
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Button';
// style
import drBotImage from '../../images/dr-bot.svg';
import './AllGoodConfirmationModal.scss';

const AllGoodConfirmationModal = ({ name, onConfirm, type }) => (
  <Modal drBot className="AllGoodConfirmationModal" title="Dr.Bot">
    <section className="modal-body">
      <div className="vet-bot-wrapper">
        <img src={drBotImage} alt="Dr.Bot" />
        <div className="text">
          <FormattedMessage id={`component.modal.allGood.text.${type}`} values={{ name: <b>{name}</b> }} />
        </div>
      </div>
    </section>
    <section className="modal-footer flex sticky-on-mobile">
      <Button light onClick={onConfirm}>
        <FormattedMessage id="component.modal.allGood.confirm" />
      </Button>
    </section>
  </Modal>
);

AllGoodConfirmationModal.propTypes = {
  type: T.oneOf(['farm', 'group']),
  name: T.string.isRequired,
  onConfirm: T.func.isRequired,
};

export default withSoundOnMount('/static/sounds/dr_bot_sound.wav')(AllGoodConfirmationModal);
