import React from 'react';
import T from 'prop-types';
// styles
import classnames from 'classnames';
import './PigsUnderCareLine.scss';
// translate
import { FormattedMessage } from 'react-intl';

const PigsUnderCareLine = ({ pigsCount, className, mobile }) => (
  <div className={classnames(`PigsUnderCareLine ${className}`, { 'mobile': mobile })}>
    <span className="left-value">
      <span className="icon fa fa-ep-piggy" />
      <FormattedMessage id="general.puc" />
    </span>
    <span><strong>{pigsCount || 0}</strong></span>
  </div>
);

PigsUnderCareLine.propTypes = {
  pigsCount: T.number,
  className: T.string,
  mobile: T.bool,
};

PigsUnderCareLine.defaultProps = {
  className: '',
};

export default PigsUnderCareLine;
