import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchFarmGroups, fetchFarm } from 'reducers/dailyCheckup/checkupFarmGroups';
import { checkinUser } from 'reducers/auth';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import Collapse from 'react-collapse';
import Button from 'components/Button';
import NothingBox from 'components/NothingBox';
import DailyCheckupsGroupRow from './components/DailyCheckupsGroupRow';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Spacer from 'components/Spacer';
import StickyHeader from 'components/StickyHeader';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import Preloader from 'components/Preloader';
import DrBotAvatar from 'components/DrBotAvatar/DrBotAvatar';
// utils
import once from 'lodash.once';
import isNull from 'lodash.isnull';
import cn from 'classnames';
import { toastr } from 'react-redux-toastr';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { arrangeGroupsByStatus } from 'utils/pigGroupsHelper';
// styles
import './DailyCheckupFarmGroups.scss';

class DailyCheckupFarmGroups extends Component {

  state = {
    showHidden: false,
    isPendingCheckin: false,
  };

  componentDidMount() {
    const { setPageOptions, fetchFarmGroups, fetchFarm, params: { id } } = this.props;
    fetchFarmGroups(id);
    fetchFarm(id);
    setPageOptions({ backLink: '/daily-checkup' });
  }

  componentDidUpdate(prevProps) {
    const { fetchFarmGroups, fetchFarm, params: { id }, farm } = this.props;
    const { params: { id: prevId } } = prevProps;
    if (prevId !== id) {
      fetchFarmGroups(id);
      fetchFarm(id);
    }
    if (farm?.id === Number(id)) {
      this.setBreadcrumbs(farm);
    }
  }

  componentWillUnmount() {
    this.props.setBreadcrumbs();
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      showHidden: !prevState.showHidden,
    }));
  };

  checkinUser = () => {
    const { checkinUser, farm, currentUser } = this.props;
    this.setState({ isPendingCheckin: true });
    checkinUser(farm.id)
      .then(() => {
        this.setState({ isPendingCheckin: false });
        toastr.light('', '', {
          icon: <DrBotAvatar />,
          position: 'bottom-center',
          showCloseButton: true,
          className: 'dr-bot',
          width: 400,
          timeOut: 6000,
          component: (
            <FormattedMessage
              id="container.checkupEdit.checkInSuccess"
              values={{
                farm_name: <b>{farm.name}</b>,
                company_name: <b>{currentUser.current_company.name}</b>,
              }}
            />
          ),
        });
      })
      .catch((response) => {
        this.setState({ isPendingCheckin: false });
        toastResponseErrors(response);
      });
  };

  renderOnTime = () => {
    const { farm: { progress } } = this.props;
    if (isNull(progress)) return null;
    return (
      <ProgressBar
        progress={progress}
        inline={false}
        className="on-time-progress"
        description={<FormattedMessage id="general.progress.onTime" />}
      />
    );
  };

  setBreadcrumbs = once((farm) => (
    this.props.setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.checkups" />, path: '/daily-checkup' },
      { label: farm.name, path: `/daily-checkup/farms/${farm.id}`, useLabelAsMobileTitle: true },
    ])
  ));

  renderCheckinButton = (isCheckedIn) => {
    const { isPendingCheckin } = this.state;
    return (
      <Button
        light
        onClick={this.checkinUser}
        className="checkin-btn"
        disabled={isPendingCheckin || isCheckedIn}
      >
        <i className={`fa fa-${isCheckedIn ? 'check' : 'map-marker'} mr-5`} />
        <FormattedMessage id={`container.checkupEdit.${isCheckedIn ? 'checkedIn' : 'checkIn'}`}>
          {(text) => <span className="show-for-large">{text}</span>}
        </FormattedMessage>
        <FormattedMessage id={`container.checkupEdit.${isCheckedIn ? 'checkedIn' : 'checkIn'}ToFarm`}>
          {(text) => <span className="hide-for-large">{text}</span>}
        </FormattedMessage>
      </Button>
    );
  };

  render() {
    const { groups, isLoading, farm, isFarmLoading, currentUser, params: { id } } = this.props;
    const { showHidden } = this.state;
    const { pigs_count: farm_pigs_count, last_checked_at: farm_last_checked_at,
      dc_progress_related_groups_count, federal_premise_id } = farm;
    const isCheckedIn = currentUser?.check_in?.farm_id === Number(id);
    const { scheduledGroups, openedGroups, pendingGroups } = arrangeGroupsByStatus(groups);
    return (
      <div className="DailyCheckupFarmGroups">
        <FarmProfileHeader
          farm={farm}
          isLoading={isFarmLoading}
          actualFarmId={Number(id)}
          className="show-for-large"
          stats={[
            { renderer: this.renderOnTime },
            { label: <FormattedMessage id="general.openGroups" />,
              value: `${dc_progress_related_groups_count || 0}/${groups.length}`,
            },
            { label: <FormattedMessage id="general.puc" />,
              value: farm_pigs_count || 0,
            },
          ]}
          dropdownOptions={[
            { label: <FormattedMessage id="general.button.viewBarnsheets" />,
              url: `/barnsheets/farms/${id}`,
            },
            { label: <FormattedMessage id="general.manageFarm" />,
              url: `/admin/farms/${id}`, hide: !currentUser.roles_map.admin,
            },
          ]}
          farmInfo={(
            <div className="farm-details">
              <span>ID&nbsp;&bull;&nbsp;{federal_premise_id || 'N/A'}</span>
              &nbsp;
              &bull;
              &nbsp;
              <FormattedMessage id="general.time.lastActivity" />
              {farm_last_checked_at && <TimeAgo className="ml-5" date={farm_last_checked_at} />}
            </div>
          )}
          checkinBtn={this.renderCheckinButton(isCheckedIn)}
        />

        <StickyHeader className="hide-for-large">
          <PigsUnderCareLine mobile pigsCount={farm_pigs_count} />
        </StickyHeader>

        {isCheckedIn && (
          <div className="checked-in-state animated fadeInDown">
            <i className="fa fa-map-marker mr-10" />
            <FormattedMessage id="container.checkupEdit.checkedInState" />
          </div>
        )}

        <div className="row centered">
          <div className="small-12 column">
            <section className="panel-wrapper">
              <div className="panel">
                <div className="panel-heading">
                  <h1 className="lighter"><FormattedMessage id="general.pageTitle.groups" /></h1>
                </div>

                <div className="panel-body">
                  <Preloader isActive={isLoading} />

                  <div className="hide-for-large">
                    {this.renderCheckinButton(isCheckedIn)}
                  </div>

                  {openedGroups.map((group) => (
                    <DailyCheckupsGroupRow
                      key={group.id}
                      group={group}
                      farm={farm}
                      currentUser={currentUser}
                    />
                  ))}

                  {pendingGroups.map((group) => (
                    <DailyCheckupsGroupRow
                      key={group.id}
                      group={group}
                      farm={farm}
                      currentUser={currentUser}
                    />
                  ))}

                  {!!scheduledGroups.length && (
                    <div
                      className={cn('view-scheduled-groups', { 'is-open': showHidden })}
                      onClick={this.toggleCollapse}
                    >
                      <FormattedMessage id={`container.checkupEdit.${showHidden ? 'close' : 'view'}ScheduledGroups`} />
                      <i className="fa fa-angle-down ml-5" />
                    </div>
                  )}

                  {scheduledGroups.length
                    ? <div className="divider" />
                    : <Spacer />}

                  {!!scheduledGroups.length && (
                    <Collapse isOpened={showHidden}>
                      <Spacer />
                      {scheduledGroups.map((group) => (
                        <div key={group.id} className="pb-20">
                          <DailyCheckupsGroupRow
                            group={group}
                            farm={farm}
                            currentUser={currentUser}
                            className="no-margin"
                          />
                        </div>
                      ))}
                    </Collapse>
                  )}

                  <NothingBox
                    itemsName="groups"
                    display={!groups.length}
                    isLoading={isLoading}
                  >
                    <FormattedMessage tagName="h1" id="component.nothingBox.noGroupsToDisplay" />
                  </NothingBox>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    );
  }
}

DailyCheckupFarmGroups.propTypes = {
  groups: T.array.isRequired,
  isLoading: T.bool,
  farm: T.object,
  params: T.object,
  isFarmLoading: T.bool,
  fetchFarmGroups: T.func.isRequired,
  fetchFarm: T.func.isRequired,
  currentUser: T.object.isRequired,
  checkinUser: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
};

export default connect(
  (state) => ({
    farm: state.dailyCheckup.farmGroups.farm,
    isFarmLoading: state.dailyCheckup.farmGroups.isFarmLoading,
    groups: state.dailyCheckup.farmGroups.resources,
    isLoading: state.dailyCheckup.farmGroups.isLoading,
    currentUser: state.auth.user,
  }), {
    fetchFarmGroups,
    fetchFarm,
    checkinUser,
    setBreadcrumbs,
    setPageOptions,
  }
)(DailyCheckupFarmGroups);
