import { fetchFromAPI } from 'utils/api';

export const getInventoriesStats = (params, isAdmin) => {
  return fetchFromAPI((isAdmin ? '/admin' : '') + '/csv/inventories/stats', { params })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getInventoriesCSV = (params, isAdmin) => {
  return fetchFromAPI((isAdmin ? '/admin' : '') + '/csv/inventories', { params, blob: true })
    .catch((response) => {
      throw response;
    });
};
