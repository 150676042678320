import React from 'react';
import T from 'prop-types';
// components
import ReactTooltip from 'rc-tooltip';
// assets
import './Popover.scss';
import 'rc-tooltip/assets/bootstrap.css';

const Popover = ({ children, content, ...props }) => {
  return (
    <ReactTooltip
      mouseEnterDelay={0.3}
      mouseLeaveDelay={0.3}
      overlay={content}
      overlayClassName="Popover"
      {...props}
      getTooltipContainer={() => document.getElementById('root')}
    >
      {children}
    </ReactTooltip>
  );
};

Popover.propTypes = {
  children: T.any,
  content: T.any,
};

export default Popover;
