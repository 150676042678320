import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchActivities } from 'reducers/farmfeed';
import { setSearch, setSearchText } from 'reducers/farmFeedSearch';
import { openPortal } from 'reducers/portal';
// components
import FarmFeedAutoSuggestMobile from './FarmFeedAutoSuggestMobile/FarmFeedAutoSuggestMobile';
// api
import { searchActivities } from 'endpoints/farmfeed';
// utils
import cn from 'classnames';
// assets
import './FarmFeedSearchMobile.scss';

const FarmFeedSearchMobile = ({
  farmFeedParams,
  fetchActivities,
  openPortal,
  setSearch,
  setSearchText,
}) => {
  const getSearchEntity = (value) => {
    const params = value.type === 'search'
      ? { search: value.text, filters: {} }
      : { filters: { [`${value.type}_id`]: value.id }, search: '' };
    fetchActivities({ ...farmFeedParams, ...params });
    setSearch(params);
    setSearchText(value.type === 'search' ? value.text : value.name);
  };

  const openPortalSearch = () => {
    openPortal(
      <FarmFeedAutoSuggestMobile
        getSuggestions={(search) => searchActivities({ search })}
        onSelect={getSearchEntity}
      />
    );
  };

  return (
    <i className={cn('fa fa-search ', 'init-search')} onClick={openPortalSearch} />
  );
};

FarmFeedSearchMobile.propTypes = {
  openPortal: T.func.isRequired,
  fetchActivities: T.func.isRequired,
  setSearch: T.func.isRequired,
  setSearchText: T.func.isRequired,
  farmFeedParams: T.object.isRequired,
};

export default connect(
  (state) => ({
    farmFeedParams: state.farmfeed.params,
  }), {
    fetchActivities,
    setSearch,
    setSearchText,
    openPortal,
  }
)(FarmFeedSearchMobile);
