import React from 'react';
import T from 'prop-types';
// components
import Input from 'components/Input';
// utils
import classnames from 'classnames/bind';
// assets
import styles from './LabeledInput.module.scss';

const cn = classnames.bind(styles);

const LabeledInput = ({
  label,
  className = '',
  input = {},
  alignRight,
  isBolded,
  isLabelBoxTransparent,
  ...others
}) => (
  <div className={cn('labeled-input', className, { 'bolded': isBolded })}>
    <Input {...input} {...others} alignRight={alignRight} />
    <div className={cn('label-box', { 'is-transparent': isLabelBoxTransparent })}>{label}</div>
  </div>
);

LabeledInput.defaultProps = {
  alignRight: true,
  isBolded: true,
  isLabelBoxTransparent: false,
};

LabeledInput.propTypes = {
  label: T.oneOfType([T.string, T.node]),
  alignRight: T.bool,
  isBolded: T.bool,
  isLabelBoxTransparent: T.bool,
  className: T.string,
  input: T.object,
};

export default LabeledInput;
