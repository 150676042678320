import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import NothingBox from 'components/NothingBox';
import CircleCheckbox from 'components/CircleCheckbox';
import UserAvatar from 'components/UserAvatar';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import cn from 'classnames';
import get from 'lodash.get';
// styles
import './CompanyUserRolesManageTable.scss';

class CompanyUserRolesManageTable extends Component {

  handleRadioChanged = (user) => (e) => {
    const { handleRadioChanged, openModal, company } = this.props;
    const { name, checked } = e.target;
    if (user.is_owner || !company.primary_contact_id) return handleRadioChanged(user, name, checked);
    const primaryContactName = company.primary_contact_name || get(company, 'primary_contact.name', '');
    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.changePrimary.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.changePrimary.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.changePrimary.warning" />}
        handleConfirm={() => handleRadioChanged(user, name, checked)}
      >
        <FormattedMessage
          id="component.modal.changePrimary.text"
          values={{
            primaryContact: <strong>{primaryContactName}</strong>,
            companyName: <strong>{company.name}</strong>,
            newPrimaryContact: <strong>{user.full_name}</strong>,
          }}
          tagName="p"
        />
      </ConfirmationModal>
    );
  };

  handleCheckBoxChanged = (e, user) => {
    const { handleCheckBoxChanged } = this.props;
    const { name, checked } = e.target;
    handleCheckBoxChanged(user, name, checked);
  };

  render() {
    const { data, search, filter, isLoading, rolesColumns } = this.props;

    return (
      <div className="CompanyUserRolesManageTable">
        <div className="flex-data-table">

          {
            data.map((user, idx) => {
              const { full_name } = user;

              return (
                <div className="table-item" key={idx}>
                  {/* Full Name */}
                  <div className="table-item-block column-username">
                    <div className="collapsible-value">
                      <div className="value-amount title">
                        <div className="user-info">
                          <div className="avatar">
                            <UserAvatar user={user} size="35" />
                          </div>
                          <div className="info truncate">
                            <div className="name truncate black">{full_name}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {rolesColumns.map(({
                    disabled,
                    name,
                    labelKey,
                    fieldPath,
                    type,
                    onChange = (user) => (e) => this.handleCheckBoxChanged(e, user),
                    onRadioChange = (user) => this.handleRadioChanged(user)
                  }, idx) => (
                    <div
                      key={`role-checkbox-${idx}`}
                      className={cn('table-item-block column-roles', {
                        disabled,
                        checked: user[fieldPath],
                      })}
                    >
                      <div className="collapsible-value with-checkbox">
                        <div className="value-amount flex align-center">
                          <CircleCheckbox
                            className="roles-manage"
                            type={type}
                            disabled={disabled}
                            label={(
                              <span className="semibold hide-for-large">
                                <FormattedMessage id={`general.role.${labelKey}`} />
                              </span>
                            )}
                            name={name}
                            onChange={type === 'radio' ? onRadioChange(user) : onChange(user)}
                            checked={user[fieldPath]}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              );
            })
          }
        </div>

        <NothingBox
          itemsName="roles"
          display={!data.length}
          isLoading={isLoading}
          search={search}
          filter={filter}
        >
          <FormattedMessage tagName="h1" id="general.nothingBox.noRoles" />
        </NothingBox>
      </div>
    );
  }
}

CompanyUserRolesManageTable.propTypes = {
  data: T.array.isRequired,
  company: T.object.isRequired,
  search: T.string,
  filter: T.string,
  handleCheckBoxChanged: T.func.isRequired,
  handleRadioChanged: T.func,
  openModal: T.func.isRequired,
  isLoading: T.bool,
  rolesColumns: T.array.isRequired,
};

export default connect(null, { openModal })(CompanyUserRolesManageTable);
