import React from 'react';
import T from 'prop-types';
// utils
import { isIOS } from 'react-device-detect';
import cn from 'classnames';
// styles
import './Select.scss';

const Select = ({ className, children, ...rest }) => {
  return (
    <select className={cn('CustomSelect', className, { isIOS })} {...rest}>
      {children}
    </select>
  );
};

Select.propTypes = {
  children: T.node,
  className: T.string,
};

export default Select;
