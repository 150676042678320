import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import { isIOS } from 'react-device-detect';
// styles
import './FarmFeedNewActivitiesBar.scss';

const FarmFeedNewActivitiesBar = ({
  newActivities = [],
  onClick,
  isHeaderHidden,
  isSidebarCollapsed,
  isMediaViewerOpen,
}) => (
  <div
    className={cn('FarmFeedNewLabel', {
      'active': Boolean(newActivities.length) && !isMediaViewerOpen,
      'header-hidden': isHeaderHidden,
      'sidebar-collapsed': isSidebarCollapsed,
      isIOS,
    })}
  >
    <div className="message" onClick={onClick}>
      <i className="fa fa-arrow-up-btm" />
      <FormattedMessage id="container.farmfeed.newEvents" values={{ count: newActivities.length }} />
    </div>
  </div>
);

FarmFeedNewActivitiesBar.propTypes = {
  newActivities: T.array,
  onClick: T.func.isRequired,
  isHeaderHidden: T.bool.isRequired,
  isSidebarCollapsed: T.bool.isRequired,
  isMediaViewerOpen: T.bool.isRequired,
};

export default connect(
  (state, props) => ({
    isHeaderHidden: Boolean(props.newActivities.length) && !state.layout.showHeader,
    isSidebarCollapsed: state.sidebar.isSidebarCollapsed,
    isMediaViewerOpen: state.mediaViewer.isOpen,
  })
)(FarmFeedNewActivitiesBar);
