import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// assets
import './Section.scss';

const Row = ({ leftValue, rightValue, onlyLeft, isHeader, blue, leftSemibold, rightSemibold, gray, semibold, red,
  className }) => {
  const wrapperClassName = cn('', {
    'panel-heading': isHeader,
    'panel-body content-row': !isHeader,
    'blue': blue,
    'gray': gray,
    'red': red,
  }, className);
  const leftClassName = cn('', { 'semibold': leftSemibold || semibold });
  const rightClassName = cn('', { 'semibold': rightSemibold || semibold });

  return (
    <div className={wrapperClassName}>
      <span className={leftClassName}>
        {leftValue}
      </span>
      {!onlyLeft && (
        <span className={rightClassName}>
          {rightValue}
        </span>
      )}
    </div>
  );
};

Row.propTypes = {
  leftValue: T.node,
  rightValue: T.node,
  onlyLeft: T.bool,
  isHeader: T.bool,
  blue: T.bool,
  red: T.bool,
  gray: T.bool,
  leftSemibold: T.bool,
  rightSemibold: T.bool,
  semibold: T.bool,
  className: T.string,
};

Row.defaultProps = {
  onlyLeft: false,
  isHeader: false,
  blue: false,
  gray: false,
  leftSemibold: false,
  rightSemibold: false,
  semibold: false,
  red: false,
};

export default Row;
