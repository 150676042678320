import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  resources: [],
  isLoading: false,
  params: {
    page: 1,
    per_page: 25,
    search: '',
    sort: '',
    status: '',
  },
  meta: {
    total: 0,
    stats: {},
  },
};

// ------------------------------------
// Action Types
// ------------------------------------

const FETCH_USERS_CHECKINS = 'checkins/users/FETCH_USERS_CHECKINS';
const [FETCH_USERS_CHECKINS_PENDING, FETCH_USERS_CHECKINS_FULFILLED, FETCH_USERS_CHECKINS_REJECTED] = [
  `${FETCH_USERS_CHECKINS}_PENDING`,
  `${FETCH_USERS_CHECKINS}_FULFILLED`,
  `${FETCH_USERS_CHECKINS}_REJECTED`
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchUsersCheckins = (params) => createAction(FETCH_USERS_CHECKINS)(
  fetchFromAPI('/check_ins/users', { params })
    .then((response) => {
      return { ...response, params };
    })
    .catch(toastResponseErrors)
);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_USERS_CHECKINS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_USERS_CHECKINS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [FETCH_USERS_CHECKINS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
