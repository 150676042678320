import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import Button, { TransparentBtn } from 'components/Button';
import Panel from 'components/Panel';
import FormattedWeight from 'components/FormattedWeight';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import { CustomColumn } from 'components/DataTable/Columns';
// api
import { getTemperaturePoints } from 'endpoints/common';
import { updateAllTemperaturePoints } from 'endpoints/admin/temperaturePoints';
// utils
import { isMobile } from 'react-device-detect';
import { formatBackEndWeightToSystem } from 'utils/weightHelper';
import { calcSetPointValue, formatSetpointValue, showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './SetPoints.scss';

const tableFarmTypes = ['nursery', 'wf', 'finisher'];

const tableFarmTypesLabels = {
  nursery: 'n_value',
  wf: 'wf_value',
  finisher: 'f_value',
};


class SetPoints extends Component {

  state = {
    isEditMode: false,
    data: [],
    dataToSave: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchPoints();
  }

  fetchPoints = () => {
    this.setState({ isLoading: true });
    getTemperaturePoints().then((resources) => {
      this.setState({ isLoading: false, data: resources, dataToSave: resources });
    }).catch((errors) => {
      this.setState({ isLoading: false });
      return toastResponseErrors(errors);
    });
  };

  updatePoints = () => {
    const { dataToSave } = this.state;
    this.setState({ isLoading: true });
    updateAllTemperaturePoints({ temperature_points: dataToSave })
      .then((resources) => {
        this.setState({ isLoading: false, data: resources, dataToSave: resources, isEditMode: false });
        showToastrMessage('component.toastr.setPoints.successUpdated');
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  renderAvgWeightColumn = ({ estimated_weight, rule_type }) => {
    const { measurementSystem } = this.props;
    const value = formatBackEndWeightToSystem(estimated_weight, measurementSystem);

    return (
      <CustomColumn textCenter className="weight-column">
        <FormattedWeight className="semibold" value={value} afterValue={rule_type === 'gteq' && '+'} />
      </CustomColumn>
    );
  };

  renderFarmTypeColumn = (valueKey) => (column) => {
    const { temperatureSystem } = this.props;
    const { isEditMode, dataToSave } = this.state;
    const actualValue = isEditMode ? dataToSave.find(({ id }) => id === column.id)[valueKey] : column[valueKey];
    const value = calcSetPointValue(actualValue, temperatureSystem);

    return (
      <CustomColumn textCenter>
        {isEditMode
          ? (
            <input
              type="number"
              min="0"
              max="99999"
              step="0.1"
              pattern="[0-9]+([\,|\.][0-9]+)?"
              className="farmtype-temp-input"
              value={value || ''}
              placeholder="--"
              onChange={this.onTempSetPointChange(column.id, valueKey)}
            />
          )
          : formatSetpointValue(value)}
      </CustomColumn>
    );
  };

  onTempSetPointChange = (id, valueKey) => ({ target: { value, validity } }) => {
    if (!validity.valid) return;

    const { temperatureSystem } = this.props;
    const { dataToSave } = this.state;
    const newValue = calcSetPointValue(Number(value), 'fahrenheit', temperatureSystem);
    const column = dataToSave.find((col) => col.id === id);
    const colIndex = dataToSave.indexOf(column);

    this.setState({ dataToSave: [
      ...dataToSave.slice(0, colIndex),
      { ...column, [valueKey]: newValue },
      ...dataToSave.slice(colIndex + 1),
    ],
    });
  };

  getFarmTypesColumns = () => {
    const { temperatureSystem } = this.props;
    const value = <FormattedMessage id={`general.${temperatureSystem}`} />;
    return (
      tableFarmTypes.map((type) => (
        {
          label: <FormattedMessage id={`container.setPoints.tableHeaders.${type}`} values={{ value }} />,
          flex: '1 0 150px', renderer: this.renderFarmTypeColumn(tableFarmTypesLabels[type]),
          className: 'setpoint-table-cell' }
      ))
    );
  };

  switchEditMode = () => (this.setState({ isEditMode: true }));

  resetToDefault = () => {
    this.setState((prevState) => ({ dataToSave: [...prevState.data], isEditMode: false }));
    showToastrMessage('component.toastr.setPoints.successReseted');
  };

  render() {
    const { isEditMode, data, isLoading } = this.state;

    const columns = [
      { label: <FormattedMessage id="general.averageWeight" />, renderer: this.renderAvgWeightColumn,
        flex: isMobile ? '0 0 140px' : '1 0 150px', fixed: true, className: 'setpoint-table-cell' },
      ...this.getFarmTypesColumns(),
    ];
    const transparentBtnStyle = { fontWeight: 400 };

    return (
      <div className="SetPoints">
        <section className="temperature-table">
          <Panel>
            <Panel.Heading title={<FormattedMessage id="general.tempSetpoints" />}>
              {!isEditMode && !!data.length && (
                <TransparentBtn style={transparentBtnStyle} onClick={this.switchEditMode}>
                  <FormattedMessage id="general.editTable" />
                </TransparentBtn>
              )}
              {isEditMode && (
                <div className="edit-buttons">
                  <Button default small onClick={this.resetToDefault} className="reset-btn">
                    <FormattedMessage id="general.button.resetToDefault" />
                  </Button>
                  <Button primary small onClick={this.updatePoints} className="save-btn">
                    <FormattedMessage id="general.button.saveChanges" />
                  </Button>
                </div>
              )}
            </Panel.Heading>
            <Panel.Body noPadding>
              <Preloader isActive={isLoading} />

              <DataTable
                data={data}
                columns={columns}
                scrollable
                hasDesktopViewOnly
                hasScrollHint={isMobile}
              />

              <NothingBox display={!data.length}>
                <FormattedMessage tagName="h1" id="component.nothingBox.noSetPoints" />
              </NothingBox>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

SetPoints.propTypes = {
  temperatureSystem: T.string.isRequired,
  measurementSystem: T.string.isRequired,
};

export default connect(
  (state) => ({
    temperatureSystem: state.auth.user.current_company.temperature_system,
    measurementSystem: state.auth.user.current_company.measurement_system,
  }), null
)(SetPoints);
