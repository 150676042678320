import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserFilterSectionDropdown from '../UserFilterSectionDropdown';
import EntityValueMenu from '../Menu/EntityValueMenu';
// hooks
import useNumericFilterSectionState from 'hooks/userFilters/useNumericFilterSectionState';
// helper
import { getConditionTranslationsBySection, formatOptionsValues } from 'utils/userFiltersHelper';
// filters constants
import { numberConditions, sectionIcons } from 'utils/constants/userFilters';

const keyOptions = { valueKey: 'count', valueEndKey: 'count_to', valueStartKey: 'count_from' };

const InventoryUserFilterSection = (props) => {
  const { resetData, ...otherSectionState } = useNumericFilterSectionState(props, keyOptions);
  const { condition, options, onSectionRemove, isInitialOpened, isRemovable, isDisabled, isInvalid } = props;
  const triggerLabel = (isInvalid || !condition)
    ? <FormattedMessage id="component.advancedSearchItem.emptyGroupInventory" />
    : (
      <FormattedMessage
        id={getConditionTranslationsBySection(props.type)[condition]}
        values={formatOptionsValues(options)}
      />
    );

  return (
    <UserFilterSectionDropdown
      isInitialOpened={isInitialOpened}
      isDisabled={isDisabled}
      currentValue={condition}
      triggerLabel={triggerLabel}
      menuRenderer={(isOpened) => (
        <EntityValueMenu
          isOpened={isOpened}
          options={numberConditions}
          inputLabel={<FormattedMessage id="general.pigs" />}
          maxInputValue={999999}
          inputStep={1}
          {...otherSectionState}
        />
      )}
      isRemovable={isRemovable}
      onRemove={onSectionRemove}
      triggerIcon={sectionIcons[props.type]}
      isInvalid={isInvalid}
      onClose={resetData}
    />
  );
};

InventoryUserFilterSection.propTypes = {
  type: T.string.isRequired,
  condition: T.string.isRequired,
  options: T.array,
  onOptionSelect: T.func,
  onSectionRemove: T.func,
  isRemovable: T.bool,
  isDisabled: T.bool,
  isInitialOpened: T.bool,
  isInvalid: T.bool,
};

InventoryUserFilterSection.defaultProps = {
  options: [],
};

export default InventoryUserFilterSection;
