import { handleActions, createAction } from 'redux-actions';

const initialState = {};
const SET_TABLE_PARAMS = 'tableParams/SET_TABLE_PARAMS';

// ------------------------------------
// Actions
// ------------------------------------

export const setTableParams = (tableName, params) => createAction(SET_TABLE_PARAMS)({ tableName, params });

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({
  [SET_TABLE_PARAMS]: (state, { payload: { tableName, params } }) => ({
    ...state,
    [tableName]: params,
  }),
  'global/RESET_STORE': () => initialState,
}, initialState);
