import React, { useState } from 'react';
import T from 'prop-types';
// components
import QuestionRow from '../QuestionRow';
import { UnmountClosed } from 'react-collapse';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './CategoryRow.module.scss';

const cn = classnames.bind(styles);

const CategoryRow = ({ category, categoryIndex }) => {
  const [isOpened, setIsOpened] = useState(false);
  const allQuestionsCount = category.svr_questions.length;
  const answeredQuestionsCount = category.svr_questions.filter(({ answer }) => answer?.value).length;

  const questionRenderer = (question, index) => (
    <QuestionRow categoryIndex={categoryIndex} questionIndex={index} question={question} key={index} />
  );

  return (
    <div className={cn('category-row')}>
      {/* header */}
      <div className={cn('category-header')} onClick={() => setIsOpened(!isOpened)}>
        <div>
          <span className="semibold">{category.name}</span>
          &nbsp;
          <span>{answeredQuestionsCount}/{allQuestionsCount}</span>
        </div>
        <i className={cn('fa fa-angle-right-btb', 'collapse-icon', { opened: isOpened })} />
      </div>
      {/* content */}
      <UnmountClosed hasNestedCollapse isOpened={isOpened}>
        {category.svr_questions.map(questionRenderer)}
      </UnmountClosed>
    </div>
  );
};

CategoryRow.propTypes = {
  category: T.object.isRequired,
  categoryIndex: T.number.isRequired,
};

export default CategoryRow;
