import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  resource: {},
  resources: [],
  isFarmLoading: false,
  isLoading: false,
  params: {
    page: 1,
    per_page: 25,
    search: '',
    sort: ''
  },
  meta: {
    total: 0
  }
};

// ------------------------------------
// Action Types
// ------------------------------------

const FETCH_FARM = 'common/farm/FETCH_FARM';
const [FETCH_FARM_PENDING, FETCH_FARM_FULFILLED, FETCH_FARM_REJECTED] = [
  `${FETCH_FARM}_PENDING`,
  `${FETCH_FARM}_FULFILLED`,
  `${FETCH_FARM}_REJECTED`
];

const FETCH_FARM_CHECKINS = `${FETCH_FARM}_CHECKINS`;
const [FETCH_FARM_CHECKINS_PENDING, FETCH_FARM_CHECKINS_FULFILLED, FETCH_FARM_CHECKINS_REJECTED] = [
  `${FETCH_FARM_CHECKINS}_PENDING`,
  `${FETCH_FARM_CHECKINS}_FULFILLED`,
  `${FETCH_FARM_CHECKINS}_REJECTED`
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFarmCheckins = (farmId, params) => createAction(FETCH_FARM_CHECKINS)(
  fetchFromAPI(`/farms/${farmId}/check_ins`, { params })
    .then((response) => ({ ...response, params }))
);


export const fetchFarm = (id) => createAction(FETCH_FARM)(
  fetchFromAPI(`/admin/farms/${id}`)
);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_FARM_PENDING]: (state) => ({
    ...state,
    isFarmLoading: true,
  }),
  [FETCH_FARM_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isFarmLoading: false,
  }),
  [FETCH_FARM_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isFarmLoading: false,
  }),

  [FETCH_FARM_CHECKINS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_FARM_CHECKINS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [FETCH_FARM_CHECKINS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
