import React from 'react';
import T from 'prop-types';
// components
import CSVImportFooter from 'components/CSVImport/CSVImportFooter';
import CSVImportHeader from 'components/CSVImport/CSVImportHeader';
// styles
import './CSVImportPage.scss';

const CSVImportPage = ({
  activeStep,
  children,
  footerContent,
  isBackDisabled = false,
  isNextDisabled = false,
  closePathName,
  onBackBtnClick,
  onNextBtnClick,
}) => (
  <div className="CSVImportPage">
    <CSVImportHeader activeStep={activeStep} closePathName={closePathName} />
    {children}
    <CSVImportFooter
      activeStep={activeStep}
      onBackBtnClick={onBackBtnClick}
      onNextBtnClick={onNextBtnClick}
      closePathName={closePathName}
      isNextDisabled={isNextDisabled}
      isBackDisabled={isBackDisabled}
    >
      {footerContent}
    </CSVImportFooter>
  </div>
);

CSVImportPage.propTypes = {
  activeStep: T.number.isRequired,
  onNextBtnClick: T.func.isRequired,
  closePathName: T.string.isRequired,
  isNextDisabled: T.bool,
  isBackDisabled: T.bool,
  onBackBtnClick: T.func,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  footerContent: T.oneOfType([T.arrayOf(T.node), T.node]),
};

export default CSVImportPage;
