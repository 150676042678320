import React from 'react';
import T from 'prop-types';
// redux-form
import { connect } from 'react-redux';
import { formValueSelector, change, reduxForm } from 'redux-form';
// components
import UserFormFields from 'components/ReduxFormFields/UserFormFields/UserFormFields';
import Button from 'components/Button';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

const CreateUserForm = ({ handleSubmit, pristine, submitting, status, ...props }) => {
  const disabledBtn = pristine || submitting || status === 'already_present' || !props.email;
  return (
    <form onSubmit={handleSubmit} className="UserProfileEditForm CreateUserForm">

      <UserFormFields {...props} />

      <div className="row margin-top-2">
        <div className="small-12 column">
          <Button primary type="submit" disabled={disabledBtn}>
            <FormattedMessage id={`general.button.${status === 'found' ? 'inviteUser' : 'createUser'}`} />
          </Button>
          <Link to="/admin/users" className="button btn-link margin-left-1">
            <FormattedMessage id="general.button.cancel" />
          </Link>
        </div>
      </div>
    </form>
  );
};

const selector = formValueSelector('create-user-by-admin');

const formComponent = reduxForm({
  form: 'create-user-by-admin',
})(CreateUserForm);

CreateUserForm.propTypes = {
  handleSubmit: T.func.isRequired,
  change: T.func.isRequired,
  telephone: T.object,
  email: T.string,
  status: T.string,
  first_name: T.string,
  last_name: T.string,
  nickname: T.string,
  pristine: T.bool,
  submitting: T.bool,
};

export default connect((state) => ({
  email: selector(state, 'email'),
  status: selector(state, 'user_status'),
  first_name: selector(state, 'first_name'),
  last_name: selector(state, 'last_name'),
  nickname: selector(state, 'nickname'),
  telephone: selector(state, 'telephone'),
}), { change })(formComponent);
