import React, { useCallback, useState } from 'react';
import T from 'prop-types';
// components
import AutoSuggest from 'react-autosuggest';
import SuggestionItem from 'components/FarmFeedSearch/SuggestionItem/SuggestionItem';
// utils
import debounce from 'lodash.debounce';

const FarmFeedAutoSuggest = ({
  getInputReference,
  getSuggestions,
  handleSelectedValue,
  onKeyPress,
  onChange,
  onFocus,
  placeholderText,
  value,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const handleFetchSuggestions = useCallback(debounce(({ value }) => {
    getSuggestions(encodeURIComponent(value))
      .then((response) => {
        const suggestions = [{ title: 'search', search: [{ text: value, type: 'search' }] }];
        Object.entries(response).forEach(([key, valueArr]) => {
          if (valueArr.length) {
            suggestions.unshift({
              title: key,
              [key]: valueArr,
            });
          }
        });
        setSuggestions(suggestions);
      });
  }, 300), []);

  const handleGetSuggestionValue = (suggestion) => {
    return suggestion.type === 'search'
      ? suggestion.text
      : suggestion.name;
  };

  const inputProps = {
    placeholder: placeholderText,
    value,
    onChange,
    onFocus,
    onKeyPress,
  };

  return (
    <AutoSuggest
      multiSection
      getSectionSuggestions={(section) => section[section.title]}
      renderSectionTitle={() => <div />}
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleFetchSuggestions}
      onSuggestionsClearRequested={() => setSuggestions([])}
      onSuggestionSelected={handleSelectedValue}
      getSuggestionValue={handleGetSuggestionValue}
      renderSuggestion={(suggestion) => <SuggestionItem suggestion={suggestion} />}
      inputProps={inputProps}
      ref={getInputReference}
    />
  );
};

FarmFeedAutoSuggest.propTypes = {
  placeholderText: T.string.isRequired,
  value: T.string.isRequired,
  getSuggestions: T.func.isRequired,
  onChange: T.func.isRequired,
  handleSelectedValue: T.func,
  getInputReference: T.func,
  onFocus: T.func,
  onKeyPress: T.func,
};

export default FarmFeedAutoSuggest;
