import { fetchFromAPI } from 'utils/api';

export const fetchUserByToken = (token) => {
  return fetchFromAPI(`/invites/${token}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateUserByToken = (data, token) => {
  return fetchFromAPI(`/invites/${token}`, {
    method: 'put',
    body: JSON.stringify(data),
    authRequest: true,
    noAuthHeaders: true,
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
