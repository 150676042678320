import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import FiltersSearchInput from 'components/FiltersSearchInput';
// styles
import styles from './UserFilterMobileHeader.module.scss';

const UserFilterMobileHeader = ({ onClick, onClose, segmentName, sectionsCount }) => (
  <div className={styles['mobile-user-filter']}>
    <FiltersSearchInput
      onClick={onClick}
      onClose={(e) => {
        e.stopPropagation();
        onClose();
      }}
      inputIcon="fa fa-filter"
      value={segmentName || <FormattedMessage id="general.unsavedSegment" />}
    />
    <FormattedMessage id="general.appliedFiltersCount" values={{ count: sectionsCount }}>
      {(text) => <div className={styles['filters-count-block']} onClick={onClick}>{text}</div>}
    </FormattedMessage>
  </div>
);

UserFilterMobileHeader.propTypes = {
  onClick: T.func.isRequired,
  onClose: T.func.isRequired,
  segmentName: T.string,
  sectionsCount: T.number.isRequired,
};

export default UserFilterMobileHeader;
