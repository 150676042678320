import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import './DiagnosisBadge.scss';

const DiagnosisBadge = ({ diagnosed, className = '', dismissed }) => {
  const getText = () => {
    if (dismissed) return 'dismissed';
    return diagnosed ? 'diagnosed' : 'undiagnosed';
  };

  return (
    <div className={cn('DiagnosisBadge', className, { 'diagnosed': diagnosed, 'dismissed': dismissed })}>
      {!dismissed && <i className={`fa fa-${diagnosed ? 'label' : 'warning-outline'}`} />}
      <FormattedMessage id={`general.${getText()}`} />
    </div>
  );
};

DiagnosisBadge.propTypes = {
  className: T.string,
  diagnosed: T.bool,
  dismissed: T.bool,
};

export default DiagnosisBadge;
