import { combineReducers } from 'redux';
import farms from './checkupFarms';
import farmGroups from './checkupFarmGroups';
import checkupEdit from './checkupEdit';
import checkupDiagnoses from './checkupDiagnoses';

const checkupsReducer = combineReducers({
  farms,
  farmGroups,
  checkupEdit,
  checkupDiagnoses,
});

export default checkupsReducer;
