import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import StatisticSection from 'components/StatisticSection/StatisticSection';
import AntibioticsTable from 'components/AntibioticsTable/AntibioticsTable';
import AntibioticChart from 'components/AntibioticChart/AntibioticChart';
// endpoints
import { getFarmAntibioticStatistics } from 'endpoints/antibioticTracking';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatParams, prepareFarmAntibioticCumulativeUsage } from 'utils/treatmentAntibioticHelper';
import {
  antibioticFarmBalloonRender,
  formatStatValue,
  farmAntibioticChartColors,
  getGraphItemData,
  getPeriodDuration,
} from 'utils/treatmentChartsHelper';
import { formatGrams } from 'utils';
import { formatDateRange } from 'utils/formatDateHelper';

const statsKeys = ['sum_g_1', 'sum_g_2', 'sum_g_3', 'sum_g_4'];

const BarnSheetsTreatmentsAntibiotic = ({
  farm,
  queryParams,
  paramsFarmId,
}) => {
  const [{ stats, isLoading, usage }, setState] = useState({ stats: {}, isLoading: false, usage: {} });
  const { formatMessage } = useIntl();

  const prepareUsageData = (data, usageByWeeks, periodName, dateRange) => {
    const result = [];
    if (!data.length) {
      return { data: result, usageByWeeks, cumulativeData: [] };
    }
    const { maxPeriodDuration, periodLimits } = getPeriodDuration(periodName, dateRange);

    for (let i = 0; i < data.length; i += 1) {
      const { position } = data[i];
      const farm_avg_g = data[i].farm_avg_g || 0;
      const tenant_type_avg_g = data[i].tenant_type_avg_g || 0;
      result[position] = { farm_avg_g, tenant_type_avg_g, position: position + 1 };
    }

    for (let i = 1; i <= maxPeriodDuration; i += 1) {
      if (!result[i - 1]) {
        const farm_avg_g = (i > periodLimits.sum_g4) ? null : 0;
        const tenant_type_avg_g = (i > periodLimits.sum_g4) ? null : 0;
        result[i - 1] = { farm_avg_g, tenant_type_avg_g, position: i };
      }
    }

    const cumulativeData = prepareFarmAntibioticCumulativeUsage(result);
    return { data: result, usageByWeeks, cumulativeData };
  };

  const fetchStats = ({ date_start, date_end, period_name }) => {
    const dates = ((period_name === 'custom') && date_start && date_end)
      ? { date_start: date_start.format('YYYY-MM-DD'), date_end: date_end.format('YYYY-MM-DD') }
      : {};
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getFarmAntibioticStatistics(paramsFarmId, { ...dates, period_name })
      .then(({ meta: { stats, usage_by_weeks }, resources }) => {
        const dateRange = { date_start, date_end };
        const usage = prepareUsageData(resources, usage_by_weeks, period_name, dateRange);
        setState((prevState) => ({ ...prevState, isLoading: false, stats, usage }));
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        return toastResponseErrors(errors);
      });
  };

  const getGraphData = () => {
    const { usageByWeeks } = usage;
    const { period_name } = queryParams;
    const stats = ['farm_avg_g', 'tenant_type_avg_g'];
    const graphData = [];
    const labels = [];

    stats.forEach((statItem) => {
      const itemGraphData = getGraphItemData(statItem, 'farm');
      const textKey = statItem === 'tenant_type_avg_g'
        ? 'general.systemWideAverage.all'
        : 'general.farmAverage';
      const label = { text: <FormattedMessage id={textKey} />, color: farmAntibioticChartColors[statItem] };
      itemGraphData.balloonFunction = (item) => (
        antibioticFarmBalloonRender(item, usageByWeeks, period_name, stats, formatMessage)
      );
      graphData.push(itemGraphData);
      labels.push(label);
    });

    return { graphData, labels };
  };

  const getGraphGrowsData = () => {
    const { date_start, date_end, period_name } = queryParams;
    const systemAvg = formatStatValue(stats.tenant_type_avg_g);
    const farmAvg = formatStatValue(stats.farm_avg_g);
    const grows = (systemAvg && farmAvg)
      ? (((Number(farmAvg) - Number(systemAvg)) / Number(farmAvg)) * 100).toFixed(2)
      : null;
    return {
      value: formatGrams(stats.farm_avg_g),
      grows,
      growsLabel: (
        <FormattedMessage
          id={`general.antibiotic.growsLabelUsage.${period_name}`}
          values={{ range: formatDateRange(date_start, date_end) }}
        />
      ),
    };
  };

  useEffect(() => {
    const { period_name, date_start, date_end } = queryParams;
    if ((period_name === 'custom') && (!date_start || !date_end)) return;
    fetchStats({ ...queryParams, date_start, date_end });
  }, [queryParams.period_name, queryParams.date_start, queryParams.date_end]);

  const { graphData, labels } = getGraphData();
  const growsGraphData = getGraphGrowsData();
  const categoryTitle = formatMessage({
    id: usage.usageByWeeks ? 'general.week' : 'general.day',
  }).toUpperCase();

  const statsData = [
    { caption: farm.name, value: formatGrams(stats.current_avg_g) },
    { caption: <FormattedMessage id="general.farmAverage" />, value: formatGrams(stats.farm_avg_g) },
    { caption: <FormattedMessage id={`general.systemWideAverage.${farm.farm_type || 'all'}`} />,
      value: formatGrams(stats.tenant_type_avg_g) },
  ];

  return (
    <div className="BarnsheetsFarmTreatmentsAntibiotic">
      <StatisticSection data={statsData} isLoading={isLoading} className="mt-20" />

      <AntibioticChart
        isLoading={isLoading}
        data={usage.data}
        graphData={graphData}
        labels={labels}
        categoryField="position"
        categoryTitle={categoryTitle}
        growsData={growsGraphData}
        title={(
          <FormattedMessage
            id="general.antibioticUsage"
            values={{ period: usage.usageByWeeks ? 'week' : 'day' }}
          />
        )}
      />
      <AntibioticChart
        isLoading={isLoading}
        data={usage.cumulativeData}
        graphData={graphData}
        labels={labels}
        categoryField="position"
        categoryTitle={categoryTitle}
        growsData={growsGraphData}
        title={<FormattedMessage id="general.cumulativeAntibioticUsage" />}
      />

      <AntibioticsTable
        statsKeys={statsKeys}
        params={formatParams(queryParams)}
        endpoint={`/antibiotic_tracking/farms/${paramsFarmId}/treatments`}
        tableKey="farmAntibiotics"
      />
    </div>
  );
};

BarnSheetsTreatmentsAntibiotic.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsTreatmentsAntibiotic.propTypes = {
  paramsFarmId: T.string.isRequired,
  farm: T.object.isRequired,
  queryParams: T.object.isRequired,
};

export default BarnSheetsTreatmentsAntibiotic;
