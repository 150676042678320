import React from 'react';
import T from 'prop-types';
// components
import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
// styles
import classnames from 'classnames/bind';
import styles from './NotificationInstructionModal.module.scss';

const cn = classnames.bind(styles);

const steps = [
  { step: 1, description: <FormattedMessage
    id="component.modal.NotificationInstruction.lockIconDesc"
    values={{
      icon: <span className={cn('icon-wrapper')}><i className={cn('fa fa-lock-locked', 'lock-icon')} /></span>,
    }}
  /> },
  { step: 2, description: <FormattedMessage
    id="component.modal.NotificationInstruction.gearIconDesc"
    values={{
      b: (msg) => <b>{msg}</b>,
      icon: <span className={cn('icon-wrapper')}><i className="fa fa-gear-bts" /></span>,
    }}
  /> },
  { step: 3, description: <FormattedMessage
    id="component.modal.NotificationInstruction.bellIconDesc"
    values={{
      b: (msg) => <b>{msg}</b>,
      em: (msg) => <em>{msg}</em>,
      icon: <span className={cn('icon-wrapper')}><i className="fa fa-bell-fas" /></span>,
    }}
  /> },
];

const NotificationInstructionModal = ({ browserName }) => {
  const translationPath = 'component.modal.NotificationInstruction';
  const modalTitle = (
    <FormattedMessage id={`${translationPath}.title`} values={{ browserName: <strong>{browserName}</strong> }} />
  );

  return (
    <Modal title={modalTitle} className={cn('browser-instruction-modal')}>
      <section className="modal-body">
        <div className={cn('content')}>

          <span className={cn('content-title')}>
            <FormattedMessage id={`${translationPath}.toEnable`} values={{ browserName }} />
          </span>

          {steps.map(({ step, description }) => (
            <div key={step} className={cn('instructions-line')}>
              <div className={cn('number')}>{step}</div>
              {description}
            </div>
          ))}

        </div>
      </section>
    </Modal>
  );
};

NotificationInstructionModal.propTypes = {
  browserName: T.string,
};

export default NotificationInstructionModal;
