import React from 'react';
import T from 'prop-types';
// components
import Preloader from 'components/Preloader';
import StatisticsFilterLine from './StatisticsFilterLine';
// utils
import classnames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
// assets
import styles from './StatisticsPanel.module.scss';
// constants
const cn = classnames.bind(styles);

const StatisticsPanel = ({
  title,
  renderLegend,
  renderTitleActions,
  className,
  currentOption,
  rangeOptions,
  onOptionChange,
  isLoading,
  children,
}) => (
  <div className={cn('statistics-panel', className)}>
    <Preloader isActive={isLoading} />

    <div className={cn('statistics-panel-title')}>
      <div className={cn('title-box')}>
        <div className={cn('title')}>
          <span>
            {title} {rangeOptions.find((option) => (option.value === currentOption)).label}
            {typeof renderLegend === 'function' && renderLegend()}
          </span>
        </div>
      </div>
      {isMobile && (
        <StatisticsFilterLine
          currentOption={currentOption}
          onOptionChange={onOptionChange}
          options={rangeOptions}
        />
      )}
      <div className={cn('current-data-block')}>
        {typeof renderTitleActions === 'function' && renderTitleActions()}
      </div>
    </div>
    <div className={cn('statistics-panel-body')}>
      {children}
    </div>
    {!isMobile && (
      <StatisticsFilterLine
        currentOption={currentOption}
        onOptionChange={onOptionChange}
        options={rangeOptions}
      />
    )}
  </div>
);

StatisticsPanel.propTypes = {
  title: T.oneOfType([T.string, T.node]).isRequired,
  className: T.string,
  currentOption: T.string.isRequired,
  rangeOptions: T.array.isRequired,
  isLoading: T.bool.isRequired,
  children: T.any,
  onOptionChange: T.func.isRequired,
  renderLegend: T.func,
  renderTitleActions: T.func,
};

export default StatisticsPanel;
