import { handleActions, createAction } from 'redux-actions';
import { getCommitment, getCommitmentHealthInfo } from 'endpoints/commitments';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  healthInfo: [],
  data: {},
  isLoading: false,
};

// ------------------------------------
// Action Types
// ------------------------------------
const FETCH_COMMITMENT = 'commitments/FETCH_COMMITMENT';
const FETCH_HEALTH_INFO = 'commitments/FETCH_HEALTH_INFO';
const [FETCH_COMMITMENT_PENDING, FETCH_COMMITMENT_FULFILLED, FETCH_COMMITMENT_REJECTED] = [
  `${FETCH_COMMITMENT}_PENDING`,
  `${FETCH_COMMITMENT}_FULFILLED`,
  `${FETCH_COMMITMENT}_REJECTED`
];

const SET_COMMITMENT = 'commitments/SET_COMMITMENT';

const [FETCH_HEALTH_INFO_PENDING, FETCH_HEALTH_INFO_FULFILLED, FETCH_HEALTH_INFO_REJECTED] = [
  `${FETCH_HEALTH_INFO}_PENDING`,
  `${FETCH_HEALTH_INFO}_FULFILLED`,
  `${FETCH_HEALTH_INFO}_REJECTED`
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchCommitment = (id) => createAction(FETCH_COMMITMENT)(getCommitment(id));
export const fetchCommitmentHealthInfo = (id) => createAction(FETCH_HEALTH_INFO)(getCommitmentHealthInfo(id));
export const setCommitment = createAction(SET_COMMITMENT);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_COMMITMENT_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_COMMITMENT_FULFILLED]: (state, action) => ({
    ...state,
    data: {
      ...action.payload
    },
    isLoading: false,
  }),
  [FETCH_COMMITMENT_REJECTED]: (state, action) => ({
    ...action.payload,
    isLoading: false,
  }),
  [FETCH_HEALTH_INFO_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_HEALTH_INFO_FULFILLED]: (state, action) => ({
    ...state,
    healthInfo: action.payload.resources,
    isLoading: false,
  }),
  [FETCH_HEALTH_INFO_REJECTED]: (state, action) => ({
    ...action.payload,
    isLoading: false,
  }),
  [SET_COMMITMENT]: (state, action) => ({
    ...state,
    data: {
      ...action.payload
    }
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
