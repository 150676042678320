import React from 'react';
import T from 'prop-types';
// components
import MobileHeader from 'components/MobileHeader/MobileHeader';
import MobileMenuSection from './MobileMenuSection';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './InsightsMenu.module.scss';

const cn = classnames.bind(styles);

const InsightsMenu = ({ goBack, sections }) => (
  <div className={cn('insights-menu')}>
    <MobileHeader
      title={<FormattedMessage id="general.pageTitle.insights" />}
      backLink={goBack}
      backIcon="fa fa-angle-left-btb"
    />
    <div className={cn('insights-menu-sections')}>
      <MobileMenuSection data={sections} />
    </div>
  </div>
);

InsightsMenu.propTypes = {
  sections: T.array.isRequired,
  goBack: T.func.isRequired,
};

export default InsightsMenu;
