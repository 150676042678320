import React, { Component } from 'react';
import T from 'prop-types';
// components
import SearchBox from 'components/SearchBox';
import { CompanyLine, FarmLine, PigGroupLine } from 'components/SearchList/Lines/Lines';
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
// utils
import { fetchFromAPI } from 'utils/api';
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './FilterItemListMobile.module.scss';

const cn = classnames.bind(styles);

const endpoints = {
  company: '/companies/search',
  farm: '/farms/search',
  group: '/pig_groups/search',
};

class FilterItemListMobile extends Component {

  state = {
    resources: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchList();
  }

  fetchList = (params = {}) => {
    const { filterType, reqParams } = this.props;
    this.setState({ isLoading: true });
    fetchFromAPI(endpoints[filterType], { params: { ...reqParams, ...params } }).then(({ resources }) => {
      this.setState({ isLoading: false, resources });
    }).catch((error) => {
      this.setState({ isLoading: false });
      return toastResponseErrors(error);
    });
  };

  onSelect = (e, item) => {
    const { filterType, onSelect } = this.props;
    e.stopPropagation();
    onSelect(filterType, item);
  };

  renderItemLine = (lineData) => {
    const { filterType, value } = this.props;
    const defaultProps = {
      key: lineData.id,
      name: lineData.name,
      onClick: (e) => this.onSelect(e, lineData),
      isDisabled: lineData.id === value.id,
    };
    switch (filterType) {
      case 'company': {
        return <CompanyLine {...defaultProps} />;
      }
      case 'farm': {
        return <FarmLine {...defaultProps} />;
      }
      case 'group': {
        return <PigGroupLine {...defaultProps} pigGroup={lineData} />;
      }
      default:
        return null;
    }
  };

  render() {
    const { resources, isLoading } = this.state;
    const { filterType, onClose } = this.props;
    return (
      <div className={cn('filter-item-list')}>
        <div className={cn('filter-item-header')}>
          <i className={cn('fa fa-times', 'close-icon')} onClick={onClose} />
          <FormattedMessage id={`general.filter_${filterType}`}>
            {(title) => (<span className={cn('title')}>{title}</span>)}
          </FormattedMessage>
        </div>
        <div className={cn('search-box-wrapper')}>
          <SearchBox className={cn('search-box')} onChange={(search) => this.fetchList({ search })} />
        </div>
        <div className={cn('resources-list')}>
          <Preloader isActive={isLoading} />
          {resources.map(this.renderItemLine)}
        </div>
      </div>
    );
  }

}

FilterItemListMobile.propTypes = {
  value: T.oneOfType([T.string, T.object]),
  onSelect: T.func.isRequired,
  onClose: T.func.isRequired,
  filterType: T.string.isRequired,
  reqParams: T.object.isRequired,
};

export default FilterItemListMobile;
