import React from 'react';
import InfoRow from 'components/InfoRow/InfoRow';
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import get from 'lodash.get';

export const renderCommitmentDetails = (direction, commitment) => {
  // INCOMING
  if (direction === 'in') {
    if (commitment.commitment_type === 'source') return null;
    return <InfoRow value={get(commitment, 'pig_group.name')} label={<FormattedMessage id="general.group" />} />;
  }
  // OUTGOING
  if (commitment.commitment_type === 'source') {
    return <InfoRow value={get(commitment, 'pig_group.name')} label={<FormattedMessage id="general.group" />} />;
  }
  return (
    <InfoRow
      label={<FormattedMessage id="general.dockTime" />}
      value={commitment.arrive_at ? moment(commitment.arrive_at).format('hh:mm A') : ''}
    />
  );
};
