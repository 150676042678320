export const answerValues = {
  fail: 'fail',
  pass: 'pass',
  not_available: 'not_available'
};

export const answerButtons = [
  { value: answerValues.pass, labelKey: 'general.pass' },
  { value: answerValues.fail, labelKey: 'general.fail' },
  { value: answerValues.not_available, labelKey: 'general.notAvail' },
];
