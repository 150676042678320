import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// components
import Toggle from 'components/Toggle';
// styles
import styles from './ToggleSwitcher.module.scss';

const cn = classnames.bind(styles);

const ToggleSwitcher = ({ input, name, checked, onChange, isDisabled, text, description, className, children }) => {
  const handleChange = input
    ? (e) => input.onChange(e.target.checked)
    : onChange;
  const isChecked = input ? Boolean(input.checked) : checked;

  return (
    <div className={cn('toggle-switcher', { isDisabled, [className]: !!className })}>
      <Toggle
        name={name}
        isDisabled={isDisabled}
        handleChange={handleChange}
        isChecked={isChecked}
      />
      <div>
        <span className={cn('text')}>
          {text && <span>{text}: </span>}
          <span className={cn({ 'checked': isChecked, 'unchecked': !isChecked })}>{isChecked ? 'ON' : 'OFF'}</span>
        </span>
        {description && <div className={cn('description')}>{description}</div>}
        {children && <div className={cn('children')}>{children}</div>}
      </div>
    </div>
  );
};

ToggleSwitcher.propTypes = {
  checked: T.bool,
  className: T.string,
  isDisabled: T.bool,
  onChange: T.func,
  input: T.object,
  name: T.string,
  text: T.oneOfType([T.string, T.object]),
  description: T.oneOfType([T.string, T.object]),
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

export default ToggleSwitcher;
