import React from 'react';
import T from 'prop-types';
// components
import FormattedWeight from 'components/FormattedWeight';
import LabeledInput from 'components/LabeledInput/LabeledInput';

const availSymbols = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];

const WeightLabeledInput = ({
  className = '',
  placeholder = '0.00',
  value,
  onChange,
  onFocus,
  hasAutoFocus = false,
  isDisabled = false,
}) => {
  const formattedValue = (value || '').toString();

  const handleChange = (e) => {
    const { value } = e.target;
    if (!value) {
      onChange('');
      return;
    }
    const strArr = value.split('');
    const isCorrectStr = strArr.every((n) => availSymbols.includes(n));
    if (!isCorrectStr || strArr[0] === '.' || strArr.filter((s) => s === '.').length > 1) return;

    const [beforeDotStr = '', afterDotStr = ''] = value.split('.');
    if (beforeDotStr.length <= 3 && afterDotStr.length <= 2) {
      onChange(value);
    }
  };

  const handleBlur = () => {
    if (formattedValue.slice(-1) === '.') {
      onChange(formattedValue.slice(0, -1));
    }
  };

  return (
    <LabeledInput
      autoFocus={hasAutoFocus}
      label={<FormattedWeight hasOnlyUnit />}
      placeholder={placeholder}
      inputMode="numeric"
      value={formattedValue}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      className={className}
      disabled={isDisabled}
    />
  );
};

WeightLabeledInput.propTypes = {
  className: T.string,
  placeholder: T.string,
  hasAutoFocus: T.bool,
  isDisabled: T.bool,
  value: T.oneOfType([T.string, T.number]),
  onChange: T.func.isRequired,
  onFocus: T.func.isRequired,
};

export default WeightLabeledInput;
