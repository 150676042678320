import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { updateCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import TruckingCompanyForm from '../components/TruckingCompanyForm';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import pick from 'lodash.pick';
import getValue from 'lodash.get';
// constants
import { addressFields } from 'constants.js';

const requiredFields = ['name', 'goose_trailer_count', 'semi_trailer_count', 'country_code'];

class TruckingCompanyEdit extends Component {

  updateCompanyName = (data) => {
    const { phone, countryCode: phone_country_code } = getValue(data, 'phone', {});
    const resource = {
      ...pick(data, requiredFields),
      ...data.addressObj,
      phone,
      phone_country_code,
    };
    const { updateCompanyProfile, params: { id } } = this.props;
    return updateCompanyProfile(`/admin/trucking_companies/${id}`, {
      method: 'put',
      body: JSON.stringify({ resource })
    })
      .then(() => {
        showToastrMessage('component.toastr.truckingCompany.updated');
      })
      .catch(toastResponseErrors);
  };

  getInitialValues = () => {
    const { params, company } = this.props;

    if (company.id === Number(params.id)) {
      return {
        name: company.name,
        goose_trailer_count: (company.goose_trailer_count || '').toString(),
        semi_trailer_count: (company.semi_trailer_count || '').toString(),
        has_user: false,
        addressObj: pick(company, addressFields),
        phone: {
          phone: company.phone,
          countryCode: company.phone_country_code || '',
        },
      };
    }
    return null;
  };

  render() {
    const initialValues = this.getInitialValues();
    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.truckingCompanyInfo" />} />
        <Panel.Body noPadding>
          <TruckingCompanyForm onSubmit={this.updateCompanyName} initialValues={initialValues} isEditForm />
        </Panel.Body>
      </Panel>
    );
  }
}

TruckingCompanyEdit.propTypes = {
  updateCompanyProfile: T.func.isRequired,
  params: T.object,
  company: T.object,
};

export default connect((state) => ({
  company: state.profiles.company.data,
}), { updateCompanyProfile })(TruckingCompanyEdit);
