import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import VirtualVisitForm from './components/VirtualVisitForm';
// api
import { fetchVirtualVisit, deleteVirtualVisit, updateVirtualVisit } from 'endpoints/virtualVisits';
// utils
import moment from 'moment';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const VirtualVisitUpdate = ({
  currentUser,
  openModal,
  params: { id },
  router,
  setBreadcrumbs,
}) => {
  const [{ isLoading, virtualVisit }, setState] = useState({ isLoading: false, virtualVisit: null });
  const { formatMessage } = useIntl();

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    fetchVirtualVisit(id)
      .then((resource) => {
        const isPastDate = moment(resource.schedule_at).isBefore(moment());
        if (resource?.creator?.id !== currentUser.id || isPastDate) {
          router.push(`/virtual-visits/${id}`);
          return;
        }
        setState({ virtualVisit: resource, isLoading: false });
        setBreadcrumbs([
          { label: formatMessage({ id: 'general.virtualVisits' }), path: '/virtual-visits' },
          { label: `#${resource.token}`, mobileTitle: formatMessage({ id: 'general.virtualVisits' }) },
        ]);
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        toastResponseErrors(errors);
      });
  }, [id]);

  const handleDeleteVirtualVisit = () => {
    const handleConfirm = () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      return deleteVirtualVisit(id)
        .then(() => {
          showToastrMessage('component.toastr.virtualVisit.hasBeenDeleted');
          router.push('/virtual-visits');
        })
        .catch((errors) => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
          toastResponseErrors(errors);
        });
    };

    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteVirtualVisit" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteVirtualVisit.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={handleConfirm}
      >
        <FormattedMessage id="component.modal.deleteVirtualVisit.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  const handleSubmit = (data) => {
    const date = data.schedule_date.format('YYYY-MM-DD');
    const hour = data.schedule_time.hourValue;
    const minute = data.schedule_time.minuteValue;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    return updateVirtualVisit(id, {
      farm_id: data.farm.value,
      invitee_id: data.user.value,
      schedule_at: moment(date + ' ' + hour + ':' + minute, 'YYYY-MM-DD hh:mm').format(),
    })
      .then(() => {
        showToastrMessage('component.toastr.virtualVisit.successUpdated');
        router.push('/virtual-visits');
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        toastResponseErrors(errors);
      });
  };

  const initialValues = +id === virtualVisit?.id
    ? {
      farm: {
        value: virtualVisit.farm.id,
        label: virtualVisit.farm.name,
      },
      user: {
        value: virtualVisit.invitee.id,
        label: virtualVisit.invitee.full_name,
      },
      schedule_date: moment(virtualVisit.schedule_at),
      schedule_time: {
        hourValue: moment(virtualVisit.schedule_at).format('HH'),
        minuteValue: moment(virtualVisit.schedule_at).format('mm'),
      },
    }
    : null;

  return (
    <Panel className="pv-20 ph-15">
      <Panel.Heading
        title={<FormattedMessage id="general.virtualVisitName" values={{ name: virtualVisit?.token || null }} />}
      >
        {!!virtualVisit && (
          <Button small primary onClick={handleDeleteVirtualVisit}>
            <FormattedMessage id="general.button.delete" />
          </Button>
        )}
      </Panel.Heading>
      <Panel.Body>
        <Preloader isActive={isLoading} />
        <VirtualVisitForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isDisabled={!virtualVisit}
          submitButtonLabel={<FormattedMessage id="general.button.save" />}
        />
      </Panel.Body>
    </Panel>
  );
};

VirtualVisitUpdate.propTypes = {
  router: T.object.isRequired,
  params: T.object.isRequired,
  currentUser: T.object.isRequired,
  openModal: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  }), { openModal, setBreadcrumbs }
)(VirtualVisitUpdate);
