import React from 'react';
import T from 'prop-types';
// components
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Avatar from 'components/Avatar/Avatar';
import VerticalAligner from 'components/VerticalAligner';
import FarmTypeLabel from 'components/FarmTypeLabel';
// styles
import './FarmProfileHeader.scss';

const FarmMiniProfileHeader = ({ farm, className }) => (
  <VerticalAligner className={`FarmMiniProfileHeader ${className}`}>
    <Avatar type="farm" avatarSize={35} iconSize={16} />
    <h3 className="inline-block farm-name">{farm.name}</h3>
    <FarmTypeLabel farmType={farm.farm_type} small className="mr-5" />
    <StatusBadge status={farm.active ? 'active' : 'disabled'} small />
    <div className="vertical-divider" />
  </VerticalAligner>
);

FarmMiniProfileHeader.propTypes = {
  farm: T.object,
  className: T.string,
};

FarmMiniProfileHeader.defaultProps = {
  className: '',
  farm: {},
};

export default FarmMiniProfileHeader;
