import React from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import NotificationInstructionModal from 'components/NotificationInstructionModal/NotificationInstructionModal';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// utils
import { getBrowserName } from 'utils';
import classNames from 'classnames/bind';
// images
import chromeImage from '../../../../public/images/chrome.svg';
import firefoxImage from '../../../../public/images/firefox.png';
import safariImage from '../../../../public/images/safari.svg';
import edgeImage from '../../../../public/images/edge_icon.png';
// styles
import styles from './BrowserSection.module.scss';

const cn = classNames.bind(styles);

const supportedBrowsers = ['Chrome', 'Firefox', 'Edge', 'Safari'];
const detectedBrowser = getBrowserName();
const browser = supportedBrowsers.includes(detectedBrowser) ? detectedBrowser : undefined;
const browserImage = { chrome: chromeImage, firefox: firefoxImage, edge: edgeImage, safari: safariImage };

const BrowserSection = ({ openModal }) => (
  <div className={cn('browser-block', 'mt-10')}>
    {browser
      ? <img className={cn('browser-img')} src={browserImage[browser.toLowerCase()]} alt={browser} />
      : <i className={cn('fa fa-ban', 'not-support')} />}
    <div className={cn('browser-desc')}>
      {browser
        ? <b>{browser}</b>
        : (
          <span className={cn('not-support')}>
            <FormattedMessage id="container.myProfile.settings.desktop.notSupportBrowser" />
          </span>
        )}
      <FormattedMessage id={`container.myProfile.settings.desktop.${browser ? 'browser' : 'tryNewBrowser'}`}>
        {(text) => (<span className="desc">{text}</span>)}
      </FormattedMessage>
    </div>
    <Button
      onClick={() => openModal(<NotificationInstructionModal browserName={browser} />)}
      disabled={!browser}
      blue
      small
    >
      <FormattedMessage id="general.button.turnOn" />
    </Button>
  </div>
);

BrowserSection.propTypes = {
  openModal: T.func.isRequired,
};

export default connect(null, { openModal })(BrowserSection);
