import { withProps } from 'recompose';

export function extendParamsOnChange(name, params, changedParam) {
  switch (name) {
    case 'search': return { ...params, page: 1, search: changedParam };
    case 'sort': return { ...params, page: 1, sort: changedParam };
    case 'page': return { ...params, page: changedParam };
    case 'perPage': return { ...params, page: 1, per_page: changedParam };
    case 'status': return { ...params, page: 1, status: changedParam };
    default: return params;
  }
}

const withDataTableController = (
  fetchDataKey = 'fetchData',
  paramsKey = 'params'
) => withProps((props) => {

  const fetchData = props[fetchDataKey];
  const params = props[paramsKey];

  return {
    onSearchChange: (search) => {
      const newParams = extendParamsOnChange('search', params, search);
      fetchData(newParams);
    },
    onSortChange: (sort) => {
      const newParams = extendParamsOnChange('sort', params, sort);
      fetchData(newParams);
    },
    onPageChange: (page) => {
      const newParams = extendParamsOnChange('page', params, page);
      fetchData(newParams);
    },
    onPerPageChange: (perPage) => {
      const newParams = extendParamsOnChange('perPage', params, perPage);
      fetchData(newParams);
    },
    onStatusChange: (status) => {
      const newParams = extendParamsOnChange('status', params, status);
      fetchData(newParams);
    },
  };
});

export default withDataTableController;
