import React, { useEffect } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import { CustomColumn, ArrowColumn } from 'components/DataTable/Columns';
import Dropdown from 'components/Dropdown/Dropdown2';
// hooks
import useDataTableController from 'hooks/useDataTableController';
// utils
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getMedType, formatGrams } from 'utils';
import { getStatsCaption } from 'utils/treatmentChartsHelper';
import { formatDateToMomentObj } from 'utils/formatDateHelper';
// styles
import styles from './AntibioticsTable.module.scss';

const AntibioticsTable = ({
  averageStatKey,
  farmParams,
  fetchData,
  isCurrentUserAdmin,
  isLoading,
  meta,
  params = {},
  resources,
  statsKeys = [],
  tableKey,
}) => {
  const { onPageChange, onPerPageChange, onSearchChange, onSortChange } = useDataTableController(fetchData, farmParams);
  const { search, sort, page, per_page } = farmParams;

  useEffect(() => {
    const { period_name, date_start, date_end } = params;
    if ((period_name === 'custom') && (!date_start || !date_end)) return;
    fetchData({ ...farmParams, ...params, page: 1 }).catch(toastResponseErrors);
  }, [params.farm_type, params.period_name, params.date_start, params.date_end]);

  const renderMedicationColumn = (rowData) => {
    const { id, name, route } = rowData;
    return (
      <CustomColumn label={<FormattedMessage id="general.medication" />}>
        <div className={styles['product-name-column']}>
          <i className={`fa fa-${getMedType({ route })}`} />
          {isCurrentUserAdmin &&
            <Link to={`/admin/health-variables/medications/${id}`} className="color-primary">{name}</Link>
          }
          {!isCurrentUserAdmin &&
          <span className="color-primary">{name}</span>}
        </div>
      </CustomColumn>
    );
  };

  const renderStatItemColumn = (key, label, noBottomBorder) => (data) => {
    const measureValue = data[key];
    return (
      <CustomColumn label={label} noBottomBorder={noBottomBorder}>
        {measureValue ? (<span>{formatGrams(measureValue)} <FormattedMessage id="general.short.grams" /></span>) : '-'}
      </CustomColumn>
    );
  };

  const renderButtonsColumn = (rowData, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <Dropdown
        idKey={`antibiotic-${rowData.id}-${rowIndex}`}
        options={[
          { label: <FormattedMessage id="general.manageMedication" />,
            url: `/admin/health-variables/medications/${rowData.id}` }
        ]}
        className="show-for-large"
      />
      <Link to={`/admin/health-variables/medications/${rowData.id}`} className="button light hide-for-large">
        <FormattedMessage id="general.manageMedication" />
      </Link>
    </div>
  );

  const renderExpandable = (rowData) => (
    <div>
      <Link to={`/admin/health-variables/medications/${rowData.id}`} className="button small light">
        <i className="fa fa-gear mr-5" />
        <FormattedMessage id="general.manageMedication" />
      </Link>
    </div>
  );

  const getStatsColumns = () => {
    const { period_name, date_start, date_end } = params;
    const dateRange = { date_start: formatDateToMomentObj(date_start), date_end: formatDateToMomentObj(date_end) };
    const columns = statsKeys.map((key, index) => {
      const label = getStatsCaption[period_name](statsKeys.length - index, dateRange);
      const noBottomBorder = averageStatKey ? statsKeys.length === index + 1 : false;
      return { label, flex: '1 1 100px', renderer: renderStatItemColumn(key, label, noBottomBorder), sortKey: key };
    });
    return averageStatKey
      ? columns.concat({
        label: <FormattedMessage id="general.average" />,
        flex: '1 1 100px',
        renderer: renderStatItemColumn(averageStatKey, <FormattedMessage id="general.average" />, true),
        sortKey: averageStatKey,
      })
      : columns;
  };

  const columns = [
    { label: <FormattedMessage id="general.medication" />, flex: '1 1 140px',
      renderer: renderMedicationColumn, sortKey: 'name' },
    ...getStatsColumns(),
    { label: '', flex: '0 0 45px', renderer: renderButtonsColumn, fixed: true,
      className: isTablet ? 'hide-for-large' : '', hide: !isCurrentUserAdmin },
    { label: '', flex: '0 0 45px', renderer: () => <ArrowColumn />, fixed: true,
      hide: !isTablet || !isCurrentUserAdmin, hasPinnedIcon: true },
  ];

  const paginationProps = {
    totalItems: meta.total,
    currentPage: page,
    perPage: per_page,
    onPageChange,
    onPerPageChange,
  };

  return (
    <section>
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.antibiotics" />}>
          <SearchBox initialValue={search} onChange={onSearchChange} />
        </Panel.Heading>
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet && isCurrentUserAdmin}
            renderExpandable={renderExpandable}
            scrollable
            tableKey={tableKey}
          />

          <NothingBox
            itemsName="farms"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noAntibioticsToDisplay" />
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

AntibioticsTable.propTypes = {
  averageStatKey: T.string,
  isCurrentUserAdmin: T.bool.isRequired,
  params: T.object,
  statsKeys: T.array,
  isLoading: T.bool.isRequired,
  resources: T.array.isRequired,
  farmParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  tableKey: T.string.isRequired,
};

export default connect(
  (state, { tableKey }) => ({
    isCurrentUserAdmin: state.auth.user.roles_map.admin,
    resources: state.dataTable[tableKey].resources,
    farmParams: state.dataTable[tableKey].params,
    meta: state.dataTable[tableKey].meta,
    isLoading: state.dataTable[tableKey].isLoading,
  }), (dispatch, { endpoint, tableKey }) => ({
    fetchData: (query) => dispatch(fetchData(endpoint, tableKey, query)),
  }),
)(AntibioticsTable);
