import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import ConditionCheckBox from './ConditionItems/ConditionCheckBox';
import InputConditionCheckBox from './ConditionItems/InputConditionCheckBox';
import InputRangeConditionCheckBox from './ConditionItems/InputRangeConditionCheckBox';
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
import { FormattedMessage } from 'react-intl';
// utils
import pickBy from 'lodash.pickby';
import { formatOptionsValues } from 'utils/userFiltersHelper';
// styles
import styles from './ConditionSelector.module.scss';

const NumberConditionSelector = ({
  conditions,
  onBack,
  onSave,
  selectedSection,
  title,
  inputLabel,
  inputStep,
  max,
  min,
  valueKey = 'value',
  valueEndKey = 'value_end',
  valueStartKey = 'value_start',
}) => {
  const optionsValues = formatOptionsValues(selectedSection.options);
  const [{ condition, value, valueStart, valueEnd }, setState] = useState({
    condition: selectedSection.condition,
    value: optionsValues[valueKey],
    valueStart: optionsValues[valueStartKey],
    valueEnd: optionsValues[valueEndKey],
  });

  const isDoneDisabled = () => {
    if (condition === 'any') return condition === selectedSection.condition;
    if (condition === 'range') {
      const isSameValues = condition === selectedSection.condition && valueStart === optionsValues[valueStartKey]
        && valueEnd === optionsValues[valueEndKey];
      return !valueStart || !valueEnd || isSameValues || +valueStart >= +valueEnd;
    }
    if (condition && condition !== 'range') {
      const isSameValues = condition === selectedSection.condition && value === optionsValues[valueKey];
      return !value || isSameValues;
    }
    return true;
  };

  const handleSave = () => {
    const getOptions = () => {
      if (condition === 'range') {
        return [
          { key: valueStartKey, value: valueStart },
          { key: valueEndKey, value: valueEnd },
        ];
      }
      if (condition && condition !== 'range') {
        return [{ key: valueKey, value }];
      }
      return undefined;
    };
    onSave(pickBy({
      condition,
      options: getOptions(),
      id: selectedSection.id,
      sectionIndex: selectedSection.sectionIndex,
      type: selectedSection.type,
    }));
  };

  const handleChangeCondition = ({ target: { name } }) => {
    setState({ condition: name, value: '', valueStart: '', valueEnd: '' });
  };

  const handleChangeInputValue = ({ target: { value, validity } }) => {
    if (!validity.valid) return;
    setState((prevState) => ({ ...prevState, value }));
  };

  const handleChangeRangeInputValue = ({ target: { value, name, validity } }) => {
    if (!validity.valid) return;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <MobileListPickerComponent
      title={title}
      onBack={onBack}
      headerRightContent={(
        <Button disabled={isDoneDisabled()} primary small onClick={handleSave}>
          <FormattedMessage id="general.button.done" />
        </Button>
      )}
    >
      <div className={styles.checkboxes}>
        {conditions.map((option) => {
          const isSingleInput = !['range', 'any'].includes(option.value);
          return (
            <Fragment key={option.value}>
              {isSingleInput && (
                <InputConditionCheckBox
                  conditionValue={condition}
                  conditionInputValue={value}
                  option={option}
                  onConditionValueChange={handleChangeCondition}
                  onConditionInputValueChange={handleChangeInputValue}
                  inputPlaceholder="0"
                  inputLabel={inputLabel}
                  maxInputValue={max}
                  minInputValue={min}
                  inputStep={inputStep}
                />
              )}
              {option.value === 'range' && (
                <InputRangeConditionCheckBox
                  option={option}
                  conditionValue={condition}
                  onConditionValueChange={handleChangeCondition}
                  inputStep={inputStep}
                  inputLabel={inputLabel}
                  inputEndValue={valueEnd}
                  inputStartValue={valueStart}
                  onInputValueChange={handleChangeRangeInputValue}
                  maxInputValue={max}
                />
              )}
              {option.value === 'any' && (
                <ConditionCheckBox
                  option={option}
                  conditionValue={condition}
                  onConditionValueChange={handleChangeCondition}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </MobileListPickerComponent>
  );
};

NumberConditionSelector.propTypes = {
  conditions: T.array.isRequired,
  selectedSection: T.object.isRequired,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
  title: T.string.isRequired,
  valueKey: T.oneOf(['value', 'count']),
  valueEndKey: T.oneOf(['value_end', 'count_to']),
  valueStartKey: T.oneOf(['value_start', 'count_from']),
  inputLabel: T.oneOfType([T.node, T.string]),
  inputStep: T.number,
  min: T.number,
  max: T.number,
};

export default NumberConditionSelector;
