import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Collapse from 'components/Collapse/Collapse';
import DeadPigsBlock from 'components/DailyCheckups/DeadPigsBlock';
import CollapseTitle from './CollapseTitle';
// requests
import { getCheckupDeadPigs } from 'endpoints/dailyCheckups';
// utils
import classnames from 'classnames/bind';
import { getDeathsCount } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './SectionCollapse.module.scss';

const cn = classnames.bind(styles);

const DeathsCollapse = ({ comment, className, dailyCheckup, isEdited, showCheckup, titleClassName }) => {
  const [{ isLoading, isCollapseOpened, isLoaded, data, hasDeathReasons }, setState] = useState({
    isLoaded: false,
    isLoading: false,
    isCollapseOpened: false,
    data: [],
    hasDeathReasons: false,
  });
  const deathsCount = getDeathsCount(dailyCheckup);
  const hasComment = Boolean(comment);

  if (!deathsCount && !hasComment) return null;

  const triggered = Object.values(dailyCheckup.death_alerts).includes(true) || hasComment;

  const toggleCollapse = (isCollapseOpened) => {
    const { id } = dailyCheckup;

    if (isLoaded) {
      setState((prevState) => ({ ...prevState, isCollapseOpened }));
      return;
    }

    setState((prevState) => ({ ...prevState, isCollapseOpened, isLoading: true }));
    getCheckupDeadPigs(id)
      .then((resource) => {
        const data = resource.mortality_reasons || [
          { type: 'chronic', value: resource.chronic_death_count },
          { type: 'acute', value: resource.acute_death_count },
          { type: 'euthanasia', value: resource.euthanasia_death_count },
        ];
        setState((prevState) => ({
          ...prevState,
          data,
          hasDeathReasons: Boolean(resource.mortality_reasons),
          isLoaded: true,
          isLoading: false,
        }));
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        return toastResponseErrors(errors);
      });
  };

  return ((triggered || showCheckup) && (
    <Collapse
      className={cn(className, 'section-collapse', { 'highlighted': triggered && !isCollapseOpened })}
      titleClassName={titleClassName}
      title={(
        <CollapseTitle hasEditedBadge={isEdited} hasNotesBadge={hasComment} hasTriggerIcon={triggered}>
          <FormattedMessage tagName="b" id="general.deathsCount" values={{ count: deathsCount }} />
        </CollapseTitle>
      )}
      onToggle={toggleCollapse}
      isLoading={isLoading}
    >
      <DeadPigsBlock
        className={cn('no-bottom-border')}
        data={data}
        dailyCheckup={dailyCheckup}
        hasDeathReasons={hasDeathReasons}
      />
    </Collapse>
  ));
};

DeathsCollapse.propTypes = {
  className: T.string,
  titleClassName: T.string,
  dailyCheckup: T.object,
  showCheckup: T.bool,
  isEdited: T.bool,
  comment: T.string,
};

export default DeathsCollapse;
