import React, { useCallback, useState, useEffect, useRef } from 'react';
import T from 'prop-types';
// components
import ReactSelect from 'components/Select/ReactSelect';
import AsyncSelectOption from './AsyncSelectOption';
// utils
import debounce from 'lodash.debounce';

const AsyncSelectLoadMore = ({
  autoLoad,
  className,
  isClearable = false,
  isDisabled = false,
  labelRenderer,
  multi = false,
  onChange,
  onFocus,
  optionsKeys = { value: 'id', label: 'name' },
  optionsGetter,
  placeholder,
  perPage = 25,
  selected,
}) => {
  const [{ isLoading, options, cachedOptions, total, page, searchText }, setState] = useState({
    isLoading: false,
    options: [],
    total: 0,
    cachedTotal: 0,
    cachedOptions: [],
    page: 1,
    searchText: '',
  });
  const ref = useRef({ isMounted: false });

  const formatLabel = (item) => {
    if (labelRenderer) return labelRenderer(item);
    return item[optionsKeys.label];
  };

  const getOptionValue = (item) => item[optionsKeys.value];

  const loadFromServer = useCallback(
    debounce((search, page = 1) => {
      return optionsGetter({
        page,
        per_page: perPage,
        search: encodeURIComponent(search),
      })
        .then((response) => {
          if (!ref.current.isMounted || !response?.resources) return;

          const { resources, meta } = response;

          const options = resources.map((item) => ({
            value: getOptionValue(item),
            label: formatLabel(item),
            item,
          }));

          setState((prevState) => ({
            ...prevState,
            options: page === 1 ? options : prevState.options.concat(options),
            page,
            searchText: search,
            total: meta?.total || options.length,
            cachedTotal: prevState.cachedTotal || meta?.total || options.length,
            cachedOptions: prevState.cachedOptions.length ? prevState.cachedOptions : options,
            isLoading: false,
          }));
        })
        .catch(() => {
          if (ref.current.isMounted) {
            setState((prevState) => ({
              ...prevState,
              searchText: '',
              options: prevState.cachedOptions,
              total: prevState.cachedTotal,
              isLoading: false,
            }));
          }
        });
    }, 300),
    [page, optionsGetter]
  );

  const loadOptions = (search) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    if (search === '' && cachedOptions.length) {
      return setState((prevState) => ({
        ...prevState,
        isLoading: false,
        options: cachedOptions,
        searchText: search,
        page: 1,
        total: prevState.cachedTotal,
      }));
    }
    return loadFromServer(search);
  };

  useEffect(() => {
    if (ref.current.isMounted && !!optionsGetter && !isDisabled) {
      setState((prevState) => ({ ...prevState, searchText: '', cachedOptions: [], cachedTotal: 0, page: 1 }));
      loadFromServer('');
    }
  }, [optionsGetter]);

  useEffect(() => {
    if (autoLoad && !isDisabled) loadOptions('');
    ref.current.isMounted = true;
    return () => {
      ref.current.isMounted = false;
    };
  }, []);

  const handleChange = (option) => {
    const isEmptyOption = option === null;
    if (!isEmptyOption) {
      onChange?.(option);
      return;
    }
    if (isClearable) {
      setState((prevState) => ({
        ...prevState,
        options: prevState.cachedOptions,
        total: prevState.cachedTotal,
        searchText: '',
        page: 1,
      }));
      onChange?.(option);
    }
  };

  const handleFocus = (value) => {
    if (onFocus) onFocus(value);
    if (!cachedOptions.length) loadOptions('');
  };

  const hasValue = Boolean(multi ? selected.length : selected?.value);
  const formattedOptions = total > options.length
    ? options.concat({ isLoadMoreOption: true, onLoadMore: () => loadFromServer(searchText, page + 1) })
    : options;

  return (
    <ReactSelect
      className={className}
      clearable={isClearable}
      disabled={isDisabled}
      placeholder={placeholder}
      multi={multi}
      options={formattedOptions}
      optionComponent={AsyncSelectOption}
      value={hasValue ? selected : null}
      onChange={handleChange}
      onInputChange={(input) => {
        if (input !== searchText) loadOptions(input);
      }}
      onFocus={handleFocus}
      isLoading={isLoading}
      filterOptions={(options) => options} // Do no filtering, just return all options
    />
  );
};

AsyncSelectLoadMore.propTypes = {
  className: T.string,
  optionsGetter: T.func.isRequired,
  optionsKeys: T.object,
  labelRenderer: T.func,
  autoLoad: T.bool,
  selected: T.oneOfType([T.object, T.array]),
  onFocus: T.func,
  onChange: T.func,
  multi: T.bool,
  isClearable: T.bool,
  isDisabled: T.bool,
  placeholder: T.oneOfType([T.string, T.object]),
  perPage: T.number,
};

export default AsyncSelectLoadMore;
