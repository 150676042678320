import { fetchFromAPI } from 'utils/api';

export const createDestination = (resource) => {
  return fetchFromAPI('/admin/external_destinations', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getDestination = (id) => {
  return fetchFromAPI(`/admin/external_destinations/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateDestination = (id, resource) => {
  return fetchFromAPI(`/admin/external_destinations/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const enableDestination = (id) => {
  return fetchFromAPI(`/admin/external_destinations/${id}/enable`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const disableDestination = (id) => {
  return fetchFromAPI(`/admin/external_destinations/${id}/disable`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
