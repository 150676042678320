import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './CommitmentStatusHeader.module.scss';

const cn = classnames.bind(styles);

const themes = {
  sale: 'primary',
  arrival: 'brand-blue',
  in_transfer: 'brand-orange',
  out_transfer: 'gray',
};

const CommitmentStatusHeader = ({ loadType, titleKey }) => (
  <div className={cn('status-header', themes[loadType])}>
    <i className={cn('fa fa-ep-truck', 'icon', { 'rotated': ['arrival', 'in_transfer'].includes(loadType) })} />
    <FormattedMessage id={titleKey} />
  </div>
);

CommitmentStatusHeader.propTypes = {
  loadType: T.string.isRequired,
  titleKey: T.string.isRequired,
};

export default CommitmentStatusHeader;
