import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { fetchData, resetResources, setDataItem } from 'reducers/dataTable';
import { fetchUserProfile, updateUserProfile } from 'reducers/profiles';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Panel from 'components/Panel';
import UsersTruckingRolesManageTable
  from 'components/Admin/Users/UsersTruckingRolesManage/UsersTruckingRolesManageTable';
import UserProfileHeader from 'components/ProfileHeader/UserProfileHeader/UserProfileHeader';
import RolesManageFixedHeader from 'components/Admin/RolesManageFixedHeader/RolesManageFixedHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import TableFilter from 'components/TableFilter';
import PaginationWrapper from 'components/PaginationWrapper';
import MessageBox from 'components/MessageBox';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
// utils
import isEmpty from 'lodash.isempty';
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';
// styles
import styles from '../RolesManage.module.scss';

const cn = classnames.bind(styles);

const headerColumns = [
  { flex: '2 0 120px', name: <FormattedMessage id="component.dataTable.headers.farmName" />, sortField: 'name' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.truckingAdmin" />, sortField: 'is_trucking_admin' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.driver" />, sortField: 'is_driver' },
];

const tableFilters = [
  { label: <FormattedMessage id="general.allFarms" />, value: '' },
  { label: <FormattedMessage id="general.role.truckingAdmin" />, value: 'trucking_admin' },
  { label: <FormattedMessage id="general.role.driver" />, value: 'driver' },
];

class UserTruckingRolesManage extends Component {

  state = {
    isUpdatingData: false,
  };

  componentDidMount() {
    const { fetchData, reqParams, fetchUserProfile } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
    fetchUserProfile().catch(toastResponseErrors);
  }

  componentWillUnmount() {
    this.props.resetResources();
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  handleRoleChanged = (truckingCompanyId, roleName, checked) => {
    const { params: { id } } = this.props;
    const paramsData = { name: roleName, trucking_company_id: truckingCompanyId, user_id: id };
    return checked
      ? this.createUserTruckingRole(paramsData)
      : this.removeUserTruckingRole(paramsData);
  };

  checkUserUpdate = (sendData, user, action) => {
    const rolesCount = user.trucking_roles_count || 0;

    return action === 'create' ? (rolesCount === 0) : true;
  };

  createUserTruckingRole = (data) => {
    const { params: { id }, setDataItem, profileUser, fetchUserProfile } = this.props;
    const reqParams = { method: 'post', body: JSON.stringify(data) };
    this.setState({ isUpdatingData: true });
    return fetchFromAPI(`/admin/users/${id}/trucking_roles`, reqParams)
      .then(({ resource }) => {
        setDataItem(resource);
        this.setState({ isUpdatingData: false });
        const needUserUpdate = this.checkUserUpdate(data, profileUser, 'create');
        if (needUserUpdate) {
          fetchUserProfile();
        }
      })
      .catch((response) => {
        this.setState({ isUpdatingData: false });
        return toastResponseErrors(response);
      });
  };

  removeUserTruckingRole = (data) => {
    const { params: { id }, setDataItem, profileUser, fetchUserProfile } = this.props;
    const reqParams = { method: 'delete', body: JSON.stringify(data) };
    this.setState({ isUpdatingData: true });
    return fetchFromAPI(`/admin/users/${id}/trucking_roles`, reqParams)
      .then(({ resource }) => {
        setDataItem(resource);
        this.setState({ isUpdatingData: false });
        const needUserUpdate = this.checkUserUpdate(data, profileUser, 'delete');
        if (needUserUpdate) {
          fetchUserProfile();
        }
      })
      .catch((response) => {
        this.setState({ isUpdatingData: false });
        return toastResponseErrors(response);
      });
  };

  revokeAllAccess = () => {
    const { params: { id }, fetchData, reqParams } = this.props;
    this.setState({ isUpdatingData: true });
    return fetchFromAPI(`/admin/users/${id}/trucking_roles/destroy_all`, { method: 'delete' })
      .then(() => {
        this.setState({ isUpdatingData: false });
        showToastrMessage('component.toastr.roles.revoked');
        fetchData(reqParams);
      })
      .catch((response) => {
        this.setState({ isUpdatingData: false });
        return toastResponseErrors(response);
      });
  };

  enableUser = () => {
    const { params: { id }, updateUserProfile, profileUser } = this.props;
    const isDisabled = !isEmpty(profileUser) && profileUser.disabled;
    const endpoint = `/admin/users/${id}/${isDisabled ? 'enable' : 'activations'}`;
    const method = isDisabled ? 'put' : 'post';

    updateUserProfile(endpoint, { method })
      .then(() => {
        showToastrMessage('component.toastr.user.activated');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { resources, isLoading, meta: { total }, reqParams: { page, per_page, role, search, sort }, params: { id },
      onSearchChange, onPageChange, onPerPageChange, onSortChange, profileUser, isUserLoading } = this.props;
    const { isUpdatingData } = this.state;
    const isUserDisabled = profileUser.status === 'disabled';
    const activeFilter = role && tableFilters.find(({ value }) => value === role).label;

    return (
      <div className={cn('roles-manage')}>
        <UserProfileHeader actualUserId={Number(id)} user={profileUser} isLoading={isUserLoading} isSticky>
          <Button light className="show-for-large mr-10" onClick={this.revokeAllAccess}>
            <i className="fa fa-times-circle mr-5" />
            <FormattedMessage id="general.button.revokeAllRoles" />
          </Button>
          <Link to={`/admin/users/${id}/trucking-roles`} className="button primary wider show-for-large">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </UserProfileHeader>

        {isUserDisabled && (
          <div className="small-12 column">
            <MessageBox type="warning" className="mt-20 mb-0" icon={<i className="fa fa-info-circle" />}>
              <span>
                <FormattedMessage id="component.alertBox.userDisabled" tagName="strong" />
                <FormattedMessage id="component.alertBox.userDisabled.text" />
              </span>
              <button className={cn('message-box-btn')} onClick={this.enableUser}>
                <FormattedMessage id="general.button.enableUser" />
              </button>
            </MessageBox>
          </div>
        )}

        <RolesManageFixedHeader
          className="show-for-large"
          panelTitle={<FormattedMessage id="general.truckingRoles" />}
          isEmptyTable={!resources.length}
          search={search}
          onSearchChange={onSearchChange}
          sort={sort}
          onSortChange={onSortChange}
          headerColumns={headerColumns}
        />

        <section className={cn('roles-manage-section')}>
          <div className="small-12 column">
            <Panel>
              <Panel.Heading title={<FormattedMessage id="general.truckingRoles" />} className="hide-for-large">
                <SearchBox initialValue={search} onChange={onSearchChange} />
              </Panel.Heading>

              <Panel.Body className={cn('roles-manage-body')}>
                <TableFilter
                  filters={tableFilters}
                  activeFilter={role}
                  onFilterChange={this.onFilterChange}
                  className="hide-for-large"
                >
                  <Button darkGrey onClick={this.revokeAllAccess}>
                    <FormattedMessage id="general.button.revokeAllRoles" />
                  </Button>
                </TableFilter>

                <Preloader isActive={isLoading || isUpdatingData} />

                <PaginationWrapper
                  onlyBottom
                  totalItems={total}
                  currentPage={page}
                  onPageChange={onPageChange}
                  perPage={per_page}
                  onPerPageChange={onPerPageChange}
                >
                  <UsersTruckingRolesManageTable
                    data={resources}
                    handleRoleChanged={this.handleRoleChanged}
                    search={search}
                    filter={activeFilter}
                    isLoading={isLoading || isUpdatingData}
                  />
                </PaginationWrapper>
              </Panel.Body>
            </Panel>
          </div>
        </section>

        <StickyFooter className="hide-for-large">
          <Link to={`/admin/users/${id}/trucking-roles`} className="button primary big-button">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </StickyFooter>
      </div>
    );
  }
}

UserTruckingRolesManage.propTypes = {
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  resetResources: T.func.isRequired,
  fetchUserProfile: T.func.isRequired,
  updateUserProfile: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  params: T.object,
  profileUser: T.object.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isUserLoading: T.bool.isRequired,
};

const enhance = compose(
  connect((state) => ({
    profileUser: state.profiles.user.data,
    isUserLoading: state.profiles.user.isLoading,
    resources: state.dataTable.userTruckingRolesManage.resources,
    reqParams: state.dataTable.userTruckingRolesManage.params,
    meta: state.dataTable.userTruckingRolesManage.meta,
    isLoading: state.dataTable.userTruckingRolesManage.isLoading,
  }), (dispatch, { params: { id } }) => ({
    fetchData: (query) => (
      dispatch(fetchData(`/admin/users/${id}/trucking_roles`, 'userTruckingRolesManage', query))
    ),
    setDataItem: (data) => dispatch(setDataItem(data, 'userTruckingRolesManage')),
    resetResources: () => dispatch(resetResources('userTruckingRolesManage')),
    updateUserProfile: (path, query) => dispatch(updateUserProfile(path, query)),
    fetchUserProfile: () => dispatch(fetchUserProfile(`/admin/users/${id}`)),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(UserTruckingRolesManage);
