import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// styles
import './ModalSidebar.scss';

const ModalSidebar = ({ children, isOpen }) => (
  <div className="ModalSidebar">
    {isOpen && children}
  </div>
);

ModalSidebar.propTypes = {
  children: T.any.isRequired,
  isOpen: T.bool.isRequired,
};

export default connect(
  (state) => ({ isOpen: state.modals.isOpen })
)(ModalSidebar);
