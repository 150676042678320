import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './FieldWrapper.module.scss';

const cn = classnames.bind(styles);

const FieldWrapper = ({ horizontal, label, required, children, className, meta: { touched, error } }) => (
  <div className={cn({ 'has-error': touched && error }, 'field-wrapper', className)}>
    <div className="row">
      <div className={cn('small-12 column', 'label-box', { 'medium-3': horizontal, 'horizontal': horizontal })}>
        {required &&
          <i className={cn('fa fa-exclamation-triangle-bts', 'required-field-icon')} />}
        {label}
      </div>
      <div className={cn('small-12 column', { 'medium-9': horizontal })}>
        {children}
      </div>
      <div className="column" />
    </div>
  </div>
);

FieldWrapper.propTypes = {
  meta: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]),
  required: T.bool,
  horizontal: T.bool,
  className: T.string,
  children: T.any,
};

export default FieldWrapper;
