import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './InputRange.module.scss';

const cn = classnames.bind(styles);

const InputRange = ({
  label,
  onChange,
  from,
  to,
  step,
  defaultValue,
  className,
}) => (
  <div className={cn('range', { [className]: className })}>
    {label && <label>{label}</label>}
    <input
      defaultValue={defaultValue}
      className={cn('input-range')}
      step={step}
      onChange={onChange}
      type="range"
      min={from}
      max={to}
    />
  </div>
);

InputRange.propTypes = {
  label: T.object,
  onChange: T.func.isRequired,
  from: T.number,
  to: T.number,
  step: T.number,
  defaultValue: T.number,
  className: T.string,
};

export default InputRange;
