import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCompaniesList } from 'reducers/staticData';
// components
import GroupField from './GroupField';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import ReactSelect from 'components/Select/ReactSelect';
// utils
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
// styles
import styles from './Section.module.scss';

const cn = classnames.bind(styles);

class OwnershipSection extends Component {

  state = {
    currentData: {},
  };

  componentDidMount() {
    const { fetchCompaniesList, companies } = this.props;
    if (!companies.length) fetchCompaniesList();
  }

  onChange = (currentData) => this.setState({ currentData });

  renderFieldLabel = () => {
    const { data } = this.props;
    return (
      <FormattedMessage
        id="component.groupCreate.ownershipLabel"
        values={{ name: <strong>{data.name}</strong> }}
      />
    );
  };

  render() {
    const { className, data, onSave, onClose, companies, isCompaniesLoading, isDisabled, isOpened,
      onOpen } = this.props;
    const { currentData } = this.state;

    return (
      <GroupField
        isRequired
        className={className}
        label={this.renderFieldLabel()}
        title={<FormattedMessage id="component.groupCreate.ownership" />}
        btnLabel={<FormattedMessage id="component.groupCreate.setOwnership" />}
        hint={<FormattedMessage id="component.groupCreate.ownershipHint" />}
        isSaved={!isEmpty(data)}
        onOpen={onOpen}
        isOpened={isOpened}
        isDisabled={isDisabled}
      >
        <div className={cn('field-body')}>
          <div className={cn('field-body-title')}>
            <FormattedMessage id="component.groupCreate.groupOwner" />
          </div>
          <div className="pt-10">
            <ReactSelect
              valueKey="id"
              labelKey="name"
              options={companies}
              value={currentData.id || data.id}
              type="text"
              placeholder={<FormattedMessage id="general.searchBy.companies" />}
              onChange={this.onChange}
              isLoading={isCompaniesLoading}
            />
          </div>
          <div className="pv-10">
            <Button
              primary
              disabled={isEmpty(currentData) || (currentData.id === data.id)}
              onClick={() => onSave({ company_id: currentData.id })}
            >
              <FormattedMessage id="general.button.save" />
            </Button>
            <Button className="btn-link ml-5" onClick={onClose}>
              <FormattedMessage id="general.button.cancel" />
            </Button>
          </div>
        </div>
      </GroupField>
    );
  }
}

OwnershipSection.propTypes = {
  className: T.string,
  data: T.object,
  onSave: T.func.isRequired,
  onClose: T.func.isRequired,
  onOpen: T.func.isRequired,
  isOpened: T.bool,
  isDisabled: T.bool,
  isCompaniesLoading: T.bool,
  companies: T.array.isRequired,
  fetchCompaniesList: T.func.isRequired,
};

export default connect(
  (state) => ({
    companies: state.staticData.companiesList.resources,
    isCompaniesLoading: state.staticData.companiesList.isLoading,
  }), { fetchCompaniesList }
)(OwnershipSection);
