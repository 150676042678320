import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { reduxForm, getFormValues } from 'redux-form';
import Field from 'components/Field';
import Button from 'components/Button';
import Input from 'components/Input';
import FormErrorsBox from 'components/FormErrorsBox';
import MessageBox from 'components/MessageBox';
// styles
import './SourceCommingledEditForm.scss';

const customErrors = [{
  key: 'name',
  error: <FormattedMessage id="component.sourceForm.errors.commingledName" />,
}];

const SourceCommingledEditForm = ({ handleSubmit, pristine, submitting, formValues }) => (
  <div className="SourceCommingledEditForm small-12 medium-8 column">
    <MessageBox type="warning">
      <strong><FormattedMessage id="container.sources.commingled_form.pro_tip" />&nbsp;</strong>
      <FormattedMessage id="container.sources.commingled_form" />
    </MessageBox>

    <FormErrorsBox formName="source-commingled-edit-form" customErrors={customErrors} />

    <form onSubmit={handleSubmit}>
      {/* NAME */}
      <div className="row">
        <div className="small-12 column">
          <label>
            <Field
              label={<FormattedMessage id="general.sourceName" />}
              name="name"
              placeholderKey="general.typeAName"
            />
          </label>
        </div>
      </div>

      <div className="sources-list">
        <FormattedMessage id="general.selectedSources">
          {(text) => <div className="select-commingle-label">{text}</div>}
        </FormattedMessage>
        {formValues && formValues.sources.map((source) => (
          <Input key={source.value} value={source.label} disabled />
        ))}
      </div>

      {/* BUTTON */}
      <div className="row mt-20 mb-20">
        <div className="small-12 column">
          <Button primary type="submit" disabled={pristine || submitting || !formValues.name}>
            <FormattedMessage id="general.button.saveChanges" />
          </Button>
          <Link to="/sources" className="button btn-link margin-left-1">
            <FormattedMessage id="general.button.cancel" />
          </Link>
        </div>
      </div>
    </form>
  </div>
);

SourceCommingledEditForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  formValues: T.object,
};

const formComponent = reduxForm({
  form: 'source-commingled-edit-form',
  enableReinitialize: true,
})(SourceCommingledEditForm);

export default connect(
  (state) => ({
    formValues: getFormValues('source-commingled-edit-form')(state),
  }),
)(formComponent);
