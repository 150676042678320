import React from 'react';
import T from 'prop-types';
// components
import Field from 'components/Field';
import FarmFormTooltip from 'containers/Admin/Farms/components/FarmFormTooltip';
// utils
import cn from 'classnames';
// styles
import './FederalPremiseIDField.scss';

const FederalPremiseIDField = ({ className, name }, { formatMessage }) => (
  <div className={cn('FederalPremiseIDField', className)}>
    <div className="small-11 medium-11 column">
      <label>
        <Field
          name={name}
          label={formatMessage({ id: 'component.createFarmForm.federalPremiseID' })}
          placeholder={formatMessage({ id: 'component.createFarmForm.federalPremiseID' })}
        />
      </label>
      <a
        className="premise-link"
        rel="noopener noreferrer"
        target="_blank"
        href="https://lms.pork.org/Premises"
      >
        {formatMessage({ id: 'component.createFarmForm.premiseVerification' })}
      </a>
    </div>
    <FarmFormTooltip
      className="mt-35 pl-0 small-1 medium-1 column"
      overlay={formatMessage({ id: 'container.admin.farm.premiseId.hint' })}
    />
  </div>
);

FederalPremiseIDField.contextTypes = {
  formatMessage: T.func.isRequired,
};

FederalPremiseIDField.propTypes = {
  name: T.string.isRequired,
  className: T.string,
};

export default FederalPremiseIDField;
