import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchUsersCheckins } from 'reducers/checkins/usersCheckins';
import { compose } from 'recompose';
// hoc
import withDropdownActions from 'hoc/withDropdownActions';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import NothingBox from 'components/NothingBox';
import UserAvatar from 'components/UserAvatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import TableFilter from 'components/TableFilter';
import PaginationWrapper from 'components/PaginationWrapper';
import SearchBox from 'components/SearchBox';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// utils
import { isTablet } from 'react-device-detect';
import { timeFormatter } from 'utils/checkinsHelper';

const tableFilters = [
  { label: <FormattedMessage id="general.allUsers" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.pending" />, value: 'pending' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
  { label: <FormattedMessage id="general.status.incomplete" />, value: 'incomplete' },
];

class UsersCheckinsTable extends Component {

  componentDidMount() {
    const { reqParams, fetchUsersCheckins } = this.props;
    fetchUsersCheckins({ ...reqParams, sort: 'last_checked_in desc' });
  }

  onFilterChange = (status) => {
    const { reqParams, fetchUsersCheckins } = this.props;
    const active = status === 'active' ? 'true' : 'false';
    fetchUsersCheckins({ ...reqParams, page: 1, status, active: status ? active : undefined });
  };

  renderUserNameColumn = (user) => (
    <CustomColumn>
      <div className="user-info">
        <div className="avatar">
          <UserAvatar user={user} size={35} />
        </div>
        <div className="info truncate">
          <div className="name truncate black">
            <div className="farm-name">{user.full_name}</div>
          </div>
        </div>
      </div>
    </CustomColumn>
  );

  renderTotalCheckinsColumn = ({ check_ins_count }) => (
    <CustomColumn>
      {check_ins_count}
    </CustomColumn>
  );

  renderLastCheckinColumn = ({ last_checked_in }) => (
    <CustomColumn>
      {last_checked_in
        ? <TimeAgo date={last_checked_in} formatter={timeFormatter} />
        : 'N/A'}
    </CustomColumn>
  );

  renderUserStatusColumn = ({ status }) => (
    <CustomColumn>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderExpandable = (user) => (
    <div>
      {this.props.currentUser.roles_map.admin && (
        <Link to={`/admin/users/${user.id}`} className="button small light">
          <i className="fa fa-gear mr-5" />
          <FormattedMessage id="general.manageUser" />
        </Link>
      )}
      <Link to={`/checkins/users/${user.id}`} className="button small light">
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.button.view" />
      </Link>
    </div>
  );

  renderButtons = (user, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${user.id}-${rowIndex}`}
        wide
        buttonType="small light-gray"
        label={<FormattedMessage id="general.button.view" />}
        url={`/checkins/users/${user.id}`}
        dropDownData={[{
          label: <FormattedMessage id="general.manageUser" />,
          url: `/admin/users/${user.id}`,
          hide: !this.props.currentUser.roles_map.admin,
        }]}
      />
    </div>
  );

  renderMobileTable() {
    const { resources, isLoading, reqParams: { search } } = this.props;

    return (
      <div className="CheckinsTable">
        <div className="mobile-table">
          {resources.map((user, index) => (
            <div className="mobile-row" key={index}>
              <div className="avatar">
                <UserAvatar user={user} size={30} />
              </div>
              <div className="info">
                <div className="name">{user.full_name}</div>
                <div className="description">
                  {user.last_check_in
                    ? (
                      <span>
                        {user.last_check_in.farm.name} •&nbsp;
                        <TimeAgo date={user.last_check_in.created_at} formatter={timeFormatter} />
                      </span>
                    )
                    : 'N/A'}
                </div>
              </div>
              <i
                data-name={`${user.id}-${user.full_name}`}
                className="fa fa-dots-three-horizontal dropdown-icon"
                onClick={(e) => this.openDropdown(e, user.id)}
              />
            </div>
          ))}
        </div>

        <NothingBox
          itemsName="users_roles"
          display={!resources.length}
          isLoading={isLoading}
          search={search}
        >
          <FormattedMessage id="component.nothingBox.noUsersCheckins" tagName="h1" />
        </NothingBox>
      </div>
    );
  }

  openDropdown = (e, id) => {
    const { openDropdown, currentUser: { roles_map: { admin } } } = this.props;
    const options = [
      { label: <FormattedMessage id="general.button.viewUserCheckInHistory" />,
        url: `/checkins/users/${id}` },
      { label: <FormattedMessage id="general.manageUser" />, url: `/admin/users/${id}`, hide: !admin },
    ];
    openDropdown(e, options);
  };

  renderTitle = () => (
    <h2 className="lighter show-for-large"><FormattedMessage id="general.users" /></h2>
  );

  render() {
    const { isLoading, resources, meta: { total, stats }, reqParams: { page, per_page, search, status, sort },
      onSortChange, onPageChange, onPerPageChange, onSearchChange } = this.props;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.userName" />, flex: '2 1 220px',
        renderer: this.renderUserNameColumn, sortKey: 'first_name' },
      { label: <FormattedMessage id="component.dataTable.headers.checkInsTotal" />, flex: '1 1 150px',
        renderer: this.renderTotalCheckinsColumn, sortKey: 'check_ins_count' },
      { label: <FormattedMessage id="component.dataTable.headers.checkInsLast" />, flex: '1 1 180px',
        renderer: this.renderLastCheckinColumn, sortKey: 'last_checked_in' },
      { label: <FormattedMessage id="component.dataTable.headers.userStatus" />, flex: '1 1 150px',
        renderer: this.renderUserStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 145px', renderer: this.renderButtons,
        className: isTablet ? 'hide-for-large' : '', fixed: true },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <section className="CheckinsPanelSection">
        <Panel className="checkins-panel">
          <Panel.Heading
            className="checkins-panel-header"
            renderTitle={this.renderTitle}
          >
            <SearchBox initialValue={search} onChange={onSearchChange} />
          </Panel.Heading>

          <Panel.Body noPadding className="show-for-large checkins-panel-body">
            <Preloader isActive={isLoading} />

            <TableFilter
              filters={tableFilters}
              onFilterChange={this.onFilterChange}
              activeFilter={status}
              stats={stats}
              className="mh-10"
            />

            <DataTable
              data={resources}
              columns={columns}
              sort={sort}
              onSortChange={onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
            />

            <NothingBox
              itemsName="users_roles"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
            >
              <FormattedMessage id="component.nothingBox.noUsersCheckins">
                {(text) => <h2 className="lighter">{text}</h2>}
              </FormattedMessage>
            </NothingBox>
          </Panel.Body>

          <Panel.Body noPadding className="hide-for-large checkins-panel-body">
            <Preloader isActive={isLoading} />

            <PaginationWrapper
              onlyBottom
              totalItems={total}
              currentPage={page}
              onPageChange={onPageChange}
              perPage={per_page}
              onPerPageChange={onPerPageChange}
            >
              {this.renderMobileTable()}
            </PaginationWrapper>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

UsersCheckinsTable.propTypes = {
  meta: T.object.isRequired,
  resources: T.array.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  fetchUsersCheckins: T.func.isRequired,
  openDropdown: T.func.isRequired,
  currentUser: T.object.isRequired,
};

const enhance = compose(
  connect((state) => ({
    currentUser: state.auth.user,
    meta: state.checkins.usersCheckins.meta,
    resources: state.checkins.usersCheckins.resources,
    reqParams: state.checkins.usersCheckins.params,
    isLoading: state.checkins.usersCheckins.isLoading,
  }), {
    fetchUsersCheckins,
  }),
  withDropdownActions,
  withDataTableController('fetchUsersCheckins', 'reqParams')
);

export default enhance(UsersCheckinsTable);
