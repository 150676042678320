import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import ConditionCheckBox from './ConditionItems/ConditionCheckBox';
import NameConditionCheckBox from './ConditionItems/NameConditionCheckBox';
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
import MobilePortalOverlay from 'components/MobilePortalOverlay';
import MultiItemsSelector from './SectionTargetSelector/MultiItemsSelector';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as RemoveIcon } from 'assets/svg/times.svg';
// api
import { getDiagnoses } from 'endpoints/diagnoses';
// utils
import pickBy from 'lodash.pickby';
import { formatOptionsValues, getSectionOptionValueKey, isMultiItemsCondition } from 'utils/userFiltersHelper';
import { sectionEntityKeys, userFilterSectionTypes } from 'utils/constants/userFilters';
// styles
import styles from './ConditionSelector.module.scss';

const getMultiItemsSelectorProps = (sectionType) => {
  switch (sectionType) {
    case userFilterSectionTypes.dcDiagnoses:
      return {
        searchEntities: getDiagnoses,
      };
    default:
      return {};
  }
};

const MultiItemsConditionSelector = ({ conditions, inputPlaceholderId, onBack, onSave, selectedSection, title }) => {
  const optionsValues = formatOptionsValues(selectedSection.options);
  const optionsKey = getSectionOptionValueKey(selectedSection.type);
  const targetKey = sectionEntityKeys[selectedSection.type];
  const [isSelectorOpened, setIsSelectorOpened] = useState(false);
  const [{ condition, targetList, targetIds }, setState] = useState({
    condition: selectedSection.condition,
    targetList: selectedSection[targetKey] || [],
    targetIds: optionsValues[optionsKey] || [],
  });
  const isMultiCondition = isMultiItemsCondition(condition);
  const { searchEntities, nameKey = 'name' } = getMultiItemsSelectorProps(selectedSection.type);

  const isDoneDisabled = () => {
    if (['any', 'none'].includes(condition)) return condition === selectedSection.condition;
    if (isMultiCondition) {
      const isSameIds = JSON.stringify(targetIds.sort()) === JSON.stringify((optionsValues[optionsKey] || []).sort());
      const isSameValues = condition === selectedSection.condition && isSameIds;
      return !targetIds.length || isSameValues;
    }
    return true;
  };

  const handleSave = () => {
    const options = isMultiCondition ? [{ key: optionsKey, value: targetIds }] : undefined;
    onSave(pickBy({
      condition,
      options,
      id: selectedSection.id,
      sectionIndex: selectedSection.sectionIndex,
      type: selectedSection.type,
      [targetKey]: targetList,
    }));
  };

  const handleChangeCondition = ({ target: { name } }) => {
    setState({ condition: name, value: '', targetIds: [], targetList: [] });
  };

  const handleRemoveItem = (itemId) => {
    setState((prevState) => ({
      ...prevState,
      targetList: prevState.targetList.filter(({ id }) => id !== itemId),
      targetIds: prevState.targetIds.filter((id) => id !== itemId),
    }));
  };

  const handleSaveItems = (selectedItems) => {
    setState((prevState) => ({
      ...prevState,
      targetList: selectedItems,
      targetIds: selectedItems.map(({ id }) => id),
    }));
    setIsSelectorOpened(false);
  };

  return (
    <Fragment>
      <MobileListPickerComponent
        title={title}
        onBack={onBack}
        headerRightContent={(
          <Button disabled={isDoneDisabled()} primary small onClick={handleSave}>
            <FormattedMessage id="general.button.done" />
          </Button>
        )}
      >
        <div className={styles.checkboxes}>
          {conditions.map((option) => {
            const isMulti = isMultiItemsCondition(option.value);
            return (
              <Fragment key={option.value}>
                {isMulti && (
                  <Fragment>
                    <NameConditionCheckBox
                      conditionValue={condition}
                      namePlaceholder={<FormattedMessage id={inputPlaceholderId} />}
                      onConditionValueChange={handleChangeCondition}
                      onNameClick={() => setIsSelectorOpened(true)}
                      option={option}
                    />
                    {condition === option.value && targetList.map((item) => (
                      <div key={item.id} className={styles['selected-item']}>
                        <div className={styles.name}>{item[nameKey]}</div>
                        <RemoveIcon height={25} className={styles.icon} onClick={() => handleRemoveItem(item.id)} />
                      </div>
                    ))}
                  </Fragment>
                )}
                {!isMulti && (
                  <ConditionCheckBox
                    conditionValue={condition}
                    onConditionValueChange={handleChangeCondition}
                    option={option}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </MobileListPickerComponent>
      <MobilePortalOverlay isHidden={!isSelectorOpened}>
        {isSelectorOpened && (
          <MultiItemsSelector
            searchEntities={searchEntities}
            nameKey={nameKey}
            title={<FormattedMessage id={inputPlaceholderId} />}
            onBack={() => setIsSelectorOpened(false)}
            onSave={handleSaveItems}
            initialSelectedItems={targetList}
          />
        )}
      </MobilePortalOverlay>
    </Fragment>
  );
};

MultiItemsConditionSelector.propTypes = {
  conditions: T.array.isRequired,
  selectedSection: T.object.isRequired,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
  inputPlaceholderId: T.string.isRequired,
  title: T.string.isRequired,
};

export default MultiItemsConditionSelector;
