import React from 'react';
import T from 'prop-types';
// components
import Modal from 'components/Modal';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SurveyPreviewModal.module.scss';

const cn = classnames.bind(styles);

const SurveyPreviewModal = ({ survey }) => (
  <Modal title={<FormattedMessage id="component.surveyPreviewModal.title" />} className={cn('survey-preview')}>
    <div className={cn('modal-content')}>
      <div className={cn('question-box')}>
        <span className={cn('preview-layout')} />
        <span className={cn('survey-question')}>{survey}</span>
        <div className={cn('btn-group')}>
          <Button light className={cn('answer-btn')}><FormattedMessage id="general.button.yes" /></Button>
          <Button light className={cn('answer-btn')}><FormattedMessage id="general.button.no" /></Button>
        </div>
      </div>
    </div>
  </Modal>
);

SurveyPreviewModal.propTypes = ({
  survey: T.string,
});

export default SurveyPreviewModal;
