import React, { Component } from 'react';
import T from 'prop-types';
// components
import Draggable from 'react-draggable';
// utils
import getValue from 'lodash.get';
// styles
import './PercentInput.scss';

class PercentInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.rememberWidth();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    this.rememberWidth();
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    this.rememberWidth();
  }

  onResize = () => {
    this.forceUpdate();
  };

  onClick = (event) => {
    const { onChange } = this.props;
    const width = this.bar.offsetWidth;
    const x = event.clientX - this.bar.getBoundingClientRect().left;
    let newValue = Math.ceil((x / width) * 100);
    if (newValue < 0) newValue = 0;
    if (newValue > 100) newValue = 100;
    onChange(newValue);
  };

  onChange = (event, { x }) => {
    const { onChange } = this.props;
    const width = this.bar.offsetWidth;
    const newValue = Math.ceil((x / width) * 100);
    onChange(newValue);
  };

  rememberWidth = () => {
    const { progressWidth } = this.state;
    const currentWidth = getValue(this.bar, 'offsetWidth', 0);
    if (progressWidth !== currentWidth) setTimeout(() => this.setState({ progressWidth: currentWidth }));
  };

  render() {
    const { value = 0 } = this.props;
    const { progressWidth } = this.state;
    const position = (progressWidth * value) / 100;

    return (
      <div className="PercentInput">
        <div className="progress-bar" ref={(bar) => { this.bar = bar; }} onClick={this.onClick}>
          <div className="progress-bar-meter" style={{ width: value + '%' }} />
          <div className="toggler-wrapper">
            <Draggable
              axis="x"
              onDrag={this.onChange}
              bounds="parent"
              position={{ x: position, y: 0 }}
            >
              <div className="toggler">
                <div className="toggler-btn">
                  <div className="toggler-content" />
                  <div className="toggler-content" />
                  <div className="toggler-content" />
                  <div className="toggler-tooltip">{value}%</div>
                </div>
              </div>
            </Draggable>
          </div>
        </div>
      </div>
    );
  }
}

PercentInput.propTypes = {
  value: T.number,
  onChange: T.func.isRequired,
};

export default PercentInput;
