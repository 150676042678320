import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import { Link } from 'react-router';
import SearchBox from 'components/SearchBox';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import TruncateHint from 'components/TruncateHint';
import NothingBox from 'components/NothingBox';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { FormattedMessage } from 'react-intl';
import { isTablet } from 'react-device-detect';
import moment from 'moment';
// styles
import './ColumnsTable.scss';

class ColumnsTable extends Component {
  state = {
    isLoading: false
  };

  componentDidMount() {
    this.renderRerunArray();
  }

  renderNameColumn = (data) => {
    const { setHeaderList } = this.props;
    return (
      <CustomColumn
        label={<FormattedMessage id="component.dataTable.headers.name" />}
      >
        <Link to={`/imports/${data.id}/${data.type[0]}`} onClick={() => setHeaderList(data)}>
          <TruncateHint name={data.name || 'N/A'} className="groupIdName" />
        </Link>
      </CustomColumn>
    );
  };

  renderNewRecordsColumn = (data) => {
    return (
      <CustomColumn
        label={<FormattedMessage id="general.imports.table.newRecords" />}
      >
        {data.new_records || 0}
      </CustomColumn>
    );
  };


  renderUpdatedRecordsColumn = (data) => (
    <CustomColumn
      label={<FormattedMessage id="general.imports.table.updatedRecords"  />}
    >
      {data.updated_records || 0 }
    </CustomColumn>
  );

  renderErrorCountColumn = (data) => (
    <CustomColumn
      label={<FormattedMessage id="general.imports.table.errorCount" />}
    >
      {data.errors_count || 0}
    </CustomColumn>
  );

  renderUserColumn = (data) => (
    <CustomColumn
      label={<FormattedMessage id="general.user" />}
    >
      {data.user || 'N/A'}
    </CustomColumn>
  );

  renderCreatedColumn = (data) => (
    <CustomColumn
      label={<FormattedMessage id="general.created" />}
    >
      { moment(data.created_at).format('MM/DD/YY') || 0}
    </CustomColumn>
  );

  renderRerunArray = () => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onFilterChange = (filter) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, status: filter,  sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => { this.setState({ isLoading: false }); });
  };

  onSearchChangeArray = (searchName) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, search: searchName,  sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => { this.setState({ isLoading: false }); });
  };

  render() {
    const {
      reqParams: { page, per_page, search, sort },
      meta: { total },
      importsData,
      onSortChange,
      onPageChange,
      onPerPageChange
    } = this.props;
    const { isLoading } = this.state;
    const columns = [
      {
        label: <FormattedMessage id="component.dataTable.headers.name" />,
        flex: '1 1 130px',
        renderer: this.renderNameColumn,
        sortKey: 'name'
      },
      {
        label: <FormattedMessage id="general.imports.table.newRecords" />,
        flex: '1 1 130px',
        renderer: this.renderNewRecordsColumn,
        sortKey: 'new_records'
      },
      {
        label: <FormattedMessage id="general.imports.table.updatedRecords" />,
        flex: '1 1 90px',
        renderer: this.renderUpdatedRecordsColumn,
        sortKey: 'updated_records',
      },
      {
        label: <FormattedMessage id="general.imports.table.errorCount" />,
        flex: '1 1 100px',
        renderer: this.renderErrorCountColumn,
        sortKey: 'error_count',
      },
      {
        label: <FormattedMessage id="general.user" />,
        flex: '1 1 110px',
        renderer: this.renderUserColumn,
        sortKey: 'user'
      },
      {
        label: <FormattedMessage id="general.created" />,
        flex: '1 1 110px',
        renderer: this.renderCreatedColumn,
        sortKey: 'created'
      },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page
    };

    if (isLoading) {
      return <Preloader isActive={isLoading} />;
    }

    return (
      <div className="small-12 column">
        <div className="BarnsheetsGroups">
          <Panel className="mv-20">
            <Panel.Heading
              renderTitle={() => (
                <h2 className="lighter show-for-large">
                  <FormattedMessage id="general.imports.table" />
                </h2>
              )}
            >
              <SearchBox initialValue={search} onChange={this.onSearchChangeArray} />
            </Panel.Heading>
            <Panel.Body noPadding>
              <Preloader isActive={isLoading} />
              {(!importsData.length) ? (
                <NothingBox
                  display={!importsData.length}
                  itemsName="imports"
                  isLoading={isLoading}
                  filter="title"
                />
              ) : (
                <DataTable
                  data={importsData}
                  columns={columns}
                  sort={sort}
                  onSortChange={onSortChange}
                  paginationProps={paginationProps}
                  isExpandable={isTablet}
                  scrollable
                  tableKey="importsData"
                />
              )}
            </Panel.Body>
          </Panel>
        </div>
      </div>
    );
  }
}

ColumnsTable.contextTypes = {
  router: T.object.isRequired,
};

ColumnsTable.propTypes = {
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  importsData: T.array,
  setHeaderList: T.func,
};

const enhance = compose(
  connect(
    (state) => ({
      meta: state.dataTable.importsData.meta,
      reqParams: state.dataTable.importsData.params,
      isAdmin: state.auth.user.roles_map.admin,
      importsData: state.dataTable.importsData.resources,
    }),
    (dispatch) => ({
      fetchData: (query) => dispatch(fetchData('/admin/csv_imports', 'importsData', query)),
    })
  ),
  withDataTableController('fetchData', 'reqParams')
);

export default enhance(ColumnsTable);
