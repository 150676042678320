import React from 'react';
import T from 'prop-types';

const RolesLabel = ({ roles, className }) => (
  <span className={className}>
    {roles.map((status, idx) => (
      <div key={idx}>
        {status.value && (
          <span>
            <span className="status-badge mr-5">{status.shortcut}</span>
            {status.name}
          </span>
        )}
      </div>
    ))}
  </span>
);

RolesLabel.propTypes = {
  roles: T.array.isRequired,
  className: T.string,
};

export default RolesLabel;
