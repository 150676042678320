import React, { useState } from 'react';
import T from 'prop-types';
// components
import { ReactComponent as PencilIcon } from 'assets/svg/pencil-mdc.svg';
import { ReactComponent as RemoveIcon } from 'assets/svg/times.svg';
// hooks
import useOnclickOutside from 'react-cool-onclickoutside';
// utils, constants
import cn from 'classnames';
import { sectionIcons } from 'utils/constants/userFilters';
// styles
import styles from './UserFilterSections.module.scss';

const SelectedFilterSectionRow = ({ conditionText, onEdit, onRemove, sectionType }) => {
  const [isClicked, setIsClicked] = useState(false);
  const iconClassName = sectionIcons[sectionType];

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove();
  };

  const sectionRowRef = useOnclickOutside(() => {
    if (isClicked) setIsClicked(false);
  });

  return (
    <div
      className={styles['selected-section-row']}
      onClick={() => setIsClicked((prevIsClicked) => !prevIsClicked)}
      ref={sectionRowRef}
    >
      {iconClassName && <i className={cn(iconClassName, styles['section-icon'])} />}
      <span className={styles['condition-text']}>{conditionText}</span>
      <div className={cn(styles.backdrop, { [styles['is-active']]: isClicked })}>
        <div className={styles['actions-block']}>
          <PencilIcon className={styles['edit-icon']} onClick={onEdit} />
          <RemoveIcon className={styles['remove-icon']} onClick={handleRemove} />
        </div>
      </div>
    </div>
  );
};

SelectedFilterSectionRow.propTypes = {
  conditionText: T.oneOfType([T.node, T.string]).isRequired,
  onEdit: T.func.isRequired,
  onRemove: T.func.isRequired,
  sectionType: T.string.isRequired,
};

export default SelectedFilterSectionRow;
