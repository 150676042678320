import React, { Component } from 'react';
import T from 'prop-types';
// components
import MessageBox from './MessageBox';
import { connect } from 'react-redux';
// utils
import getValue from 'lodash.get';
import isEmpty from 'lodash.isempty';
import forEach from 'lodash.foreach';
import isString from 'lodash.isstring';
import pick from 'lodash.pick';
import { capitalize } from '../utils/responseErrorsHelper';

class FormErrorsBox extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formErrors: [],
    };
  }

  // syncErrors needs for reduxForm field-validation
  UNSAFE_componentWillReceiveProps({ form, formName }) { // eslint-disable-line consistent-return
    const currentForm = form[formName];
    if (!currentForm) return this.setState({ formErrors: [] });
    const errors = {
      ...getValue(currentForm, 'syncErrors', {}),
      ...getValue(currentForm, 'submitErrors', {}),
    };
    const isFailed = currentForm.submitFailed || !isEmpty(currentForm.syncErrors);
    if (!isEmpty(errors) && isFailed) {
      this.resolveErrors(errors);
    } else {
      this.setState({ formErrors: [] });
    }
  }

  resolveErrors = (errors) => {
    const { includedFields } = this.props;
    let includedErrors;
    if (includedFields) {
      includedErrors = pick(errors, includedFields);
    }
    const formErrors = this.parseErrors(includedErrors || errors);

    this.setState({ formErrors });
  };

  parseErrors = (unparsedErrors) => {
    const { excludedFields, customErrors } = this.props;
    const formErrors = [];

    const deepErrorsParse = (errors) => forEach(errors, (error, key) => {
      if (excludedFields) {
        const excludedFieldsIndex = excludedFields.findIndex((e) => e === key);
        if (excludedFieldsIndex !== -1) return;
      }

      if (customErrors) {
        const customErrorsIndex = customErrors.findIndex((e) => e.key === key);
        if (customErrorsIndex !== -1) {
          customErrors[customErrorsIndex].custom = true;
          formErrors.push(customErrors[customErrorsIndex]);
          return;
        }
      }

      const errorKey = capitalize(key.replace('_', ' '));

      if (isString(error)) {
        formErrors.push({ key: errorKey, error });
        return;
      }

      if (Array.isArray(error)) {
        formErrors.push({ key: errorKey, error: error.join(', ') });
      } else {
        deepErrorsParse(error); // is object
      }
    });

    deepErrorsParse(unparsedErrors);

    return formErrors;
  };

  render() {
    const { formErrors } = this.state;
    const style = { fontWeight: 400 };

    return (
      <div>
        {formErrors.map((e, idx) => (
          <MessageBox type="alert" key={idx}>
            <span className="error-message" style={style}>
              {!e.custom && `${e.key}: `}{e.error}
            </span>
          </MessageBox>
        ))}
      </div>
    );
  }
}

FormErrorsBox.propTypes = {
  form: T.object, // eslint-disable-line react/no-unused-prop-types
  formName: T.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  includedFields: T.array,
  excludedFields: T.array,
  customErrors: T.array,
};

export default connect((state) => ({ form: state.form }))(FormErrorsBox);
