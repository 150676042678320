import React from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import Dropdown from '../Dropdown';
// styles
import cn from 'classnames';

const SidebarDropdownItem = ({ className, label, items, isCollapsedSidebar, isOpenAbove }, { isTablet }) => (
  <li className={cn({ [className]: !!className })}>
    <div className="more">
      <span className="item-icon fa fa-bars" />
      <span className="item-name">
        {label}
      </span>
      <span className={cn('sidebar-tooltip', { isTablet })}>
        {label}
      </span>

      <Dropdown
        trigger={<div className="trigger-overlay" />}
        className={cn('more-dropdown', { isCollapsed: isCollapsedSidebar, isOpenAbove })}
        closeOnClick
      >
        <div className="more-menu">
          {items.map((item, index) => (
            <Link
              activeClassName="active"
              to={item.link}
              onClick={item.onClick}
              key={index}
              className={cn('more-item', { hide: item.hide, active: item.isActive })}
            >
              <div><i className={item.icon} /><span>{item.name}</span></div>
              <div />
            </Link>
          ))}
        </div>
      </Dropdown>

    </div>
  </li>
);

SidebarDropdownItem.propTypes = {
  className: T.string,
  label: T.node.isRequired,
  items: T.array.isRequired,
  isCollapsedSidebar: T.bool.isRequired,
  isOpenAbove: T.bool,
};

SidebarDropdownItem.contextTypes = {
  isTablet: T.bool.isRequired,
};

export default SidebarDropdownItem;
