import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal';
// utils
import cn from 'classnames/bind';
import {
  buildGoogleEventLink,
  buildOutlookComEventLink,
  buildYahooEventLink,
  openEventFile,
} from 'utils/addToCalendarHelper';
import { isIPad13 } from 'react-device-detect';

const AddEventToCalendarModal = ({ calendarData, closeModal }, { isMobile, isIOS }) => {
  const onBlobDownload = () => {
    openEventFile(calendarData, (isMobile && isIOS) || isIPad13);
  };
  const className = 'mv-10 semibold color-primary block text-center';
  return (
    <Modal className={cn('save-filters-modal')} title={<FormattedMessage id="general.addToCalendar" />}>
      <section className="modal-body">
        <a className={className} href={buildGoogleEventLink(calendarData)} target="_blank" rel="noopener noreferrer">
          Google
        </a>
        <a className={className} onClick={onBlobDownload} rel="noopener noreferrer">
          Outlook
        </a>
        <a
          className={className}
          href={buildOutlookComEventLink(calendarData)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Outlook.com
        </a>
        <a className={className} onClick={onBlobDownload} rel="noopener noreferrer">
          iCal
        </a>
        <a className={className} href={buildYahooEventLink(calendarData)} target="_blank" rel="noopener noreferrer">
          Yahoo
        </a>
      </section>
      <section className="modal-footer text-right">
        <Button className="margin-right-1" onClick={closeModal}>
          <FormattedMessage id="general.button.cancel" />
        </Button>
      </section>
    </Modal>
  );
};

AddEventToCalendarModal.contextTypes = {
  isMobile: T.bool.isRequired,
  isTablet: T.bool.isRequired,
  isIOS: T.bool.isRequired,
};

AddEventToCalendarModal.propTypes = {
  calendarData: T.shape({
    title: T.string.isRequired,
    description: T.string.isRequired,
    location: T.string.isRequired,
    time: T.string.isRequired,
  }),
  closeModal: T.func.isRequired,
};

export default connect(null, { closeModal })(AddEventToCalendarModal);
