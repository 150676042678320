import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// assets
import loaderSvg from '../../../images/loader.svg';
// styles
import styles from './EmailCheckIcon.module.scss';

const cn = classnames.bind(styles);

const loaderStyle = { backgroundImage: 'url(' + loaderSvg + ')' };

const EmailCheckIcon = ({ isLoading, hasWarning, wasChecked }) => {
  if (isLoading) {
    return <div className={cn('email-check-icon', 'loader-svg')} style={loaderStyle} />;
  }
  if (hasWarning) {
    return <i className={cn('email-check-icon', 'fa fa-exclamation-triangle-bts', 'input-icon', 'color-warning')} />;
  }
  if (wasChecked) {
    return <i className={cn('email-check-icon', 'fa fa-check', 'input-icon', 'color-secondary')} />;
  }
  return null;
};

EmailCheckIcon.propTypes = {
  isLoading: T.bool.isRequired,
  hasWarning: T.bool.isRequired,
  wasChecked: T.bool.isRequired,
};

export default EmailCheckIcon;
