import React from 'react';
import T from 'prop-types';
// components
import CheckboxMenu from './CheckboxMenu';
import ReactSelect from 'components/Select/ReactSelect';
// utils
import classnames from 'classnames/bind';
import pick from 'lodash.pick';
// styles
import styles from './Menu.module.scss';
// constants
import { checkboxProps } from 'utils/constants/userFilters';

const cn = classnames.bind(styles);

const EntityTypeMenu = ({
  currentValue,
  onInputChange,
  onTypeSelect,
  typeValue,
  selectOptions,
  ...others
}) =>  (
  <CheckboxMenu {...pick(others, checkboxProps)} currentValue={currentValue}>
    {(value) => (value === currentValue) && ['eq', 'not_eq'].includes(value) && (
      <ReactSelect
        value={typeValue || ''}
        className={cn('value-async-select')}
        options={selectOptions}
        onChange={onTypeSelect}
        onInputChange={onInputChange}
      />
    )}
  </CheckboxMenu>
);

EntityTypeMenu.propTypes = {
  isOpened: T.bool.isRequired,
  currentValue: T.string,
  options: T.array.isRequired,
  onConditionChange: T.func,
  onInputChange: T.func,
  typeValue: T.string,
  onTypeSelect: T.func,
  onApply: T.func.isRequired,
  selectOptions: T.array.isRequired,
};

export default EntityTypeMenu;
