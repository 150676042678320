import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import NotificationItemBody from 'containers/Notifications/components/NotificationItemBody';
import Waypoint from 'react-waypoint';
// utils
import classnames from 'classnames/bind';
import pick from 'lodash.pick';
// styles
import styles from './NotificationItem.module.scss';

const cn = classnames.bind(styles);

class NotificationItem extends Component {

  shouldComponentUpdate(nextProps) {
    const { hasTodayDivider, hasEarlierDivider, isRead } = this.props;
    return (nextProps.hasTodayDivider !== hasTodayDivider) || (nextProps.hasEarlierDivider !== hasEarlierDivider)
      || (nextProps.isRead !== isRead);
  }

  getOptions = () => {
    const { onDismiss, item, isRead, isDropdownView } = this.props;
    const options = pick(item, ['id', 'target', 'created_at', 'initiator', 'options']);
    return {
      ...options,
      type: item.notification_type,
      read: isRead,
      avatarSize: isDropdownView ? 30 : 50,
      handleDismissAction: onDismiss,
    };
  };

  render() {
    const { hasTodayDivider, hasEarlierDivider, onMarkAsRead, isRead, currentUser, isDropdownView } = this.props;
    const hasTopDivider = (hasTodayDivider || hasEarlierDivider) && isDropdownView;
    const options = this.getOptions();
    return (
      <>
        {hasTodayDivider && (
          <div className={cn('divider', { 'has-padding': isDropdownView })}>
            <FormattedMessage id="general.time.today" />
            {!isDropdownView && <hr className={cn('horizontal-line')} />}
          </div>
        )}
        {hasEarlierDivider && (
          <div className={cn('divider', { 'has-padding': isDropdownView })}>
            <FormattedMessage id="component.notifications.earlier" />
            {!isDropdownView && <hr className={cn('horizontal-line')} />}
          </div>
        )}
        {!isDropdownView && (
          <div className={cn('notification-item')}>
            <div className={cn('triangle-mark', { 'read': isRead })} />
            <NotificationItemBody hasTopDivider={hasTopDivider} options={options} user={currentUser} />
            {!isRead && <Waypoint onEnter={() => onMarkAsRead(options.id)} />}
          </div>
        )}
        {isDropdownView && (
          <>
            <NotificationItemBody isDropdownView hasTopDivider={hasTopDivider} options={options} user={currentUser} />
            {!isRead && <Waypoint onEnter={() => onMarkAsRead(options.id)} />}
          </>
        )}
      </>
    );
  }
}

NotificationItem.propTypes = {
  hasTodayDivider: T.bool.isRequired,
  hasEarlierDivider: T.bool.isRequired,
  isDropdownView: T.bool,
  isRead: T.bool.isRequired,
  onMarkAsRead: T.func,
  onDismiss: T.func,
  currentUser: T.object.isRequired,
  item: T.object.isRequired,
};

export default NotificationItem;
