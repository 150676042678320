import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import SearchBox from 'components/SearchBox';
import TableFilter from 'components/TableFilter';
import DataTable from 'components/DataTable/DataTable';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import NothingBox from 'components/NothingBox';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import download from 'downloadjs';
import getTableColumns from '../Results/columns/getTableColumns';
// styles
import './CSVImportDataTable.scss';

const tableFilters = [
  { label: <FormattedMessage id="general.created" />, value: 'created' },
  { label: <FormattedMessage id="general.csvImport.table.updated" />, value: 'updated' },
  { label: <FormattedMessage id="general.errors" />, value: 'errors' }
];

class CSVImportDataTable extends Component {

  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.fetchResults();
  }

  downloadCSV = (path, fileName) => (e) => {
    e.stopPropagation();
    return fetchFromAPI(path, { blob: true })
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };

  fetchResults = () => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData, importType } = this.props;
    fetchData({ ...reqParams, status: 'created', type: importType })
      .catch(toastResponseErrors)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onFilterChange = (filter) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, status: filter })
      .catch(toastResponseErrors)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onSearchChange = (searchName) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, search: searchName })
      .catch(toastResponseErrors)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  downloadAll = () => {
    fetchFromAPI(this.getPatchDownload(), { blob: true })
      .then((blob) => download(blob, 'Download', 'text/csv'))
      .catch(toastResponseErrors);
  };

  getPatchDownload =() => {
    const { importId, importType } = this.props;
    return `/admin/csv_imports/download?id=${importId}&type=${importType}`;
  };

  render() {
    const { isTablet } = this.context;
    const {
      reqParams: { page, per_page, search, sort, status },
      meta: { total = 0, created = 0, updated = 0, errors = 0, duplicates = 0 },
      onSortChange,
      onPageChange,
      onPerPageChange,
      tableName,
      resources,
      importType,
    } = this.props;
    const stats = { created, updated, errors };
    const { isLoading } = this.state;
    const columns = getTableColumns(importType);

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page
    };

    return (
      <div className="wrapperTableImport">

        <div className="import-result-summary">
          <i className="check-icon fa fa-check-circle" />
          <FormattedMessage
            tagName="div"
            id="component.csv.complete"
            values={{
              count: created,
              requestType: created > 1 ? importType : importType.replace(/(s)$/gi, ''),
              b: (msg) => <b>{msg}</b> }}
          />

          {errors > 0 && !duplicates && (
            <FormattedMessage
              tagName="div"
              id="component.csv.errors"
              values={{
                count: errors,
                requestType: errors > 1 ? importType : importType.replace(/(s)$/gi, ''),
                b: (msg) => <b>{msg}</b>
              }}
            />
          )}
          {duplicates > 0 && !errors && (
            <FormattedMessage
              tagName="div"
              id="component.csv.duplicates"
              values={{
                count: duplicates,
                requestType: duplicates > 1 ? importType : importType.replace(/(s)$/gi, ''),
                b: (msg) => <b>{msg}</b>,
              }}
            />
          )}
          {errors > 0 && duplicates > 0 && (
            <FormattedMessage
              tagName="div"
              id="component.csv.errorsAndDuplicates"
              values={{
                errorsCount: errors,
                duplicatesCount: duplicates,
                requestTypeErrors: errors > 1 ? importType : importType.replace(/(s)$/gi, ''),
                b: (msg) => <b>{msg}</b>
              }}
            />
          )}
        </div>

        <div className="small-12 column">
          <div>
            <Panel className="mv-20">
              <Panel.Heading
                renderTitle={() => (
                  <h2 className="lighter show-for-large">
                    <FormattedMessage id={`general.csvImport.title.${importType}`} />
                  </h2>
                )}
              >
                <SearchBox initialValue={search} onChange={this.onSearchChange} />
              </Panel.Heading>
              <Panel.Body noPadding>
                <Preloader isActive={isLoading} />
                <TableFilter
                  filters={tableFilters}
                  onFilterChange={this.onFilterChange}
                  activeFilter={status}
                  stats={stats}
                  className="mh-10"
                >
                  <div className="containerButtons">
                    <Button primary onClick={this.downloadAll} disabled={isLoading}>
                      <FormattedMessage id="general.csvImport.results.export" />
                    </Button>
                  </div>
                </TableFilter>

                <DataTable
                  data={resources}
                  columns={columns}
                  sort={sort}
                  onSortChange={onSortChange}
                  paginationProps={paginationProps}
                  isExpandable={isTablet}
                  scrollable
                  tableKey={tableName}
                />

                <NothingBox
                  display={!resources.length}
                  itemsName={importType}
                  isLoading={isLoading}
                  filter={tableName}
                />
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

CSVImportDataTable.contextTypes = {
  router: T.object.isRequired,
  isTablet: T.bool
};

CSVImportDataTable.propTypes = {
  importType: T.string.isRequired,
  tableName: T.string.isRequired,
  reqParams: T.object,
  meta: T.object,
  fetchData: T.func,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  resources: T.array,
  // eslint-disable-next-line react/no-unused-prop-types
  importId: T.number,
};

const enhance = compose(
  connect(
    (state, { tableName }) => {
      return ({
        meta: state.dataTable[tableName].meta,
        reqParams: state.dataTable[tableName].params,
        resources: state.dataTable[tableName].resources,
      });
    },
    (dispatch, { requestUrl, tableName }) => {
      return ({
        fetchData: (query) => dispatch(fetchData(requestUrl, tableName, query)),
      });
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(CSVImportDataTable);
