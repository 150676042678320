import { fetchFromAPI } from 'utils/api';

export const updateAllTemperaturePoints = (resource) => {
  return fetchFromAPI('/admin/temperature_points/update_all', {
    method: 'put',
    body: JSON.stringify({ resource }),
  })
    .then(({ resources }) => resources)
    .catch((response) => {
      throw response;
    });
};
