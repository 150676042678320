import React from 'react';
import T from 'prop-types';
// components
import Link from 'react-router/lib/Link';
import Button from 'components/Button/index';
// utils
import { getLetterInitials } from 'utils/index';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import './ImportLayout.scss';

const ImportLayout = ({
  title,
  children,
  user,
  closeButtonHeader,
  handleNextButton,
  handleBackButton,
  disabledNextButton,
  step,
}) => (
  <div className="ImportLayout">
    <div>
      <div className="importGroup-header">
        <div>
          <div className="company-avatar" title={user.current_company.name}>
            {getLetterInitials(user.current_company.name)}
          </div>
          {title}
        </div>
        <div className="close" onClick={closeButtonHeader}>
          <i className="fa fa-times" />
        </div>
      </div>
    </div>
    <div className="bodyContent">
      {children}
    </div>
    <div className="importGroup-footer">
      <div>
        {step !== 3 && (
          <Link onClick={handleBackButton}>
            <i className="fa fa-angle-left back-icon" />
            <FormattedMessage tagName="b" id={step === 1 ? 'general.returnToGroups' : 'general.button.back'} />
          </Link>
        )}
      </div>
      <Button
        onClick={handleNextButton}
        className="button primary next-button"
        disabled={disabledNextButton}
      >
        <FormattedMessage id={`general.button.${step === 3 ? 'finish' : 'next'}`} />
        <i className="fa fa-angle-right ml-5" />
      </Button>
    </div>
  </div>
);

ImportLayout.propTypes = {
  title: T.node.isRequired,
  children: T.node,
  user: T.object,
  closeButtonHeader: T.func,
  handleNextButton: T.func,
  handleBackButton: T.func,
  disabledNextButton: T.bool,
  step: T.number,
};

export default ImportLayout;
