import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import {
  buildGoogleEventLink,
  buildOutlookComEventLink,
  buildYahooEventLink,
  openEventFile,
} from 'utils/addToCalendarHelper';
import { isIPad13 } from 'react-device-detect';

const AddEventToCalendar = ({ calendarData }, { isMobile, isIOS }) => {
  const renderLink = (link, label) => {
    return (
      <a className="semibold color-primary" href={link} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    );
  };

  const onBlobDownload = () => {
    openEventFile(calendarData, (isMobile && isIOS) || isIPad13);
  };

  const renderBlobLink = (label) => (
    <a className="semibold color-primary" onClick={onBlobDownload} rel="noopener noreferrer">
      {label}
    </a>
  );

  return (
    <FormattedMessage
      id="general.addToCalendarLinks"
      values={{
        googleLink: renderLink(buildGoogleEventLink(calendarData), 'Google'),
        outlookLink: renderBlobLink('Outlook'),
        outlookComLink: renderLink(buildOutlookComEventLink(calendarData), 'Outlook.com'),
        iCalLink: renderBlobLink('iCal'),
        yahooLink: renderLink(buildYahooEventLink(calendarData), 'Yahoo'),
      }}
    />
  );
};

AddEventToCalendar.contextTypes = {
  isMobile: T.bool.isRequired,
  isIOS: T.bool.isRequired,
};

AddEventToCalendar.propTypes = {
  calendarData: T.shape({
    title: T.string.isRequired,
    description: T.string.isRequired,
    location: T.string.isRequired,
    time: T.string.isRequired,
  }),
};

export default AddEventToCalendar;
