import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import { getTooltipStyles } from 'utils/imageTagHelper';
// styles
import styles from './ImageTagTooltip.module.scss';

const cn = classnames.bind(styles);

const ImageTagTooltip = ({
  className,
  tooltipOffset,
  triangleOffset,
  position,
  isVisible,
  isActive,
  children,
}) => (
  <div
    data-name="tag-tooltip" // for ImageTag component
    style={getTooltipStyles(tooltipOffset, triangleOffset)}
    className={cn('tag-tooltip', className, {
      [position]: !!position,
      'visible': isVisible,
      'active': isActive,
    })}
  >
    {children}
  </div>
);

ImageTagTooltip.defaultProps = {
  position: 'top',
};

ImageTagTooltip.propTypes = {
  className: T.string,
  tooltipOffset: T.number,
  triangleOffset: T.number,
  isVisible: T.bool.isRequired,
  isActive: T.bool,
  position: T.oneOf(['top', 'bottom', 'left', 'right', 'mobile-top', 'mobile-bottom', 'default']),
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default ImageTagTooltip;
