import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const backendDateFormat = 'YYYY-MM-DD';
const currentWeek = moment().week();
const currentMonth = moment().month();

const buildWeekRange = (period) => ({
  date_start: moment().week(currentWeek).subtract(period - 1, 'week').startOf('week')
    .format(backendDateFormat),
  date_end: moment().week(currentWeek).endOf('week').format(backendDateFormat),
});

const buildDayRange = (period) => ({
  date_start: moment().subtract(period - 1, 'day')
    .format(backendDateFormat),
  date_end: moment().format(backendDateFormat),
});

const buildMonthRange = (period) => ({
  date_start: moment().month(currentMonth).subtract(period - 1, 'month').startOf('month')
    .format(backendDateFormat),
  date_end: moment().month(currentMonth).endOf('month').format(backendDateFormat),
});

const weekOptions = [4, 8, 16, 26, 52].map((count) => ({
  value: `previous_${count}_weeks`,
  label: <FormattedMessage id="general.ranges.previousWeeks" values={{ count }} />,
  mobileLabel: <FormattedMessage id="general.ranges.weeks" values={{ count }} />,
  ...buildWeekRange(count),
}));

const dayOptions = [7, 14, 28, 90, 180].map((count) => ({
  value: `previous_${count}_days`,
  label: <FormattedMessage id="general.ranges.previousDays" values={{ count }} />,
  mobileLabel: <FormattedMessage id="general.ranges.days" values={{ count }} />,
  ...buildDayRange(count),
}));

const monthOptions = [3, 6, 12, 24].map((count) => ({
  value: `previous_${count}_month`,
  label: <FormattedMessage id="general.ranges.previousMonths" values={{ count }} />,
  mobileLabel: <FormattedMessage id="general.ranges.months" values={{ count }} />,
  ...buildMonthRange(count),
}));

export const rangeOptions = { weekly: weekOptions, daily: dayOptions, monthly: monthOptions };
