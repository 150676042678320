import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
import { fetchData, setDataItem, updateMeta } from 'reducers/dataTable';
import { openModal } from 'reducers/modals';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import SymptomLevelBadge from 'components/SymptomLevelBadge/SymptomLevelBadge';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import sum from 'lodash.sum';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

class Symptoms extends Component {

  componentDidMount() {
    const { reqParams, fetchData } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  changeSymptomStatus = (id, active) => {
    const { setDataItem, meta: { stats }, updateMeta } = this.props;
    fetchFromAPI(`/admin/symptoms/${id}`, {
      method: 'PUT',
      body: JSON.stringify({ resource: { active: !active } }),
    })
      .then(({ resource }) => {
        setDataItem(resource);
        updateMeta({ stats: {
          ...stats,
          active: active ? stats.active - 1 : stats.active + 1,
          disabled: active ? stats.disabled + 1 : stats.disabled - 1,
        },
        });
        showToastrMessage(`component.toastr.symptom.${active ? 'deactivated' : 'activated'}`);
      })
      .catch(toastResponseErrors);
  };

  openConfirmModal = ({ id, active }) => {
    if (!active) {
      this.changeSymptomStatus(id, active);
      return;
    }

    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.disableSymptom.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.disableSymptom.confirm" />}
        actionBtnProps={{ red: true }}
        warningMessage={<FormattedMessage id="component.modal.disableSymptom.warning" />}
        handleConfirm={() => this.changeSymptomStatus(id, active)}
      >
        <FormattedMessage id="component.modal.disableSymptom.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  renderNameColumn = ({ id, name }) => (
    <CustomColumn>
      <Link to={`/admin/health-variables/symptoms/${id}/edit`} className="color-primary">
        {name}
      </Link>
    </CustomColumn>
  );

  renderConcernLevelsColumn = ({ alert_frequency }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.concernLevel" />}>
      <SymptomLevelBadge level={alert_frequency} />
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = (symptom, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${symptom.id}-${rowIndex}`}
        customClass="show-for-large"
        wide
        buttonType="small light-gray"
        label={<FormattedMessage id={`general.button.${symptom.active ? 'disable' : 'enable'}`} />}
        onClick={() => this.openConfirmModal(symptom)}
        dropDownData={[
          { label: <FormattedMessage id="general.manageSymptom" />,
            url: `/admin/health-variables/symptoms/${symptom.id}/edit` },
        ]}
      />
      <Link to={`/admin/health-variables/symptoms/${symptom.id}/edit`} className="button light hide-for-large">
        <FormattedMessage id="general.manageSymptom" />
      </Link>
      <Button default onClick={() => this.openConfirmModal(symptom)} className="hide-for-large">
        <FormattedMessage id={`general.button.${symptom.active ? 'disable' : 'enable'}`} />
      </Button>
    </div>
  );

  renderExpandable = (symptom) => (
    <div>
      <Link to={`/admin/health-variables/symptoms/${symptom.id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.manageSymptom" />
      </Link>
      <Button default onClick={() => this.openConfirmModal(symptom)} className="hide-for-large">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id={`general.button.${symptom.active ? 'disable' : 'enable'}`} />
      </Button>
    </div>
  );

  render() {
    const { isLoading, meta: { stats = {}, total }, data, reqParams: { page, per_page, status, search, sort },
      onSearchChange, onPageChange, onPerPageChange, onStatusChange, onSortChange } = this.props;
    const { isTablet } = this.context;
    const statsSum = sum(Object.values(stats));
    const tableFilters = [
      { label: <FormattedMessage id="general.allSymptoms" />, value: '', count: statsSum },
      { label: <FormattedMessage id="general.status.active" />, value: 'active',
        count: stats.active },
      { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled',
        count: stats.disabled },
    ];
    const isEmptyTable = !data.length && !status && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '2 0 150px',
        renderer: this.renderNameColumn },
      { label: <FormattedMessage id="component.dataTable.headers.concernLevel" />, flex: '1 0 100px',
        renderer: this.renderConcernLevelsColumn },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 0 100px',
        renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 160px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.symptoms" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={status}
              onFilterChange={onStatusChange}
              stats={stats}
              className="ph-10"
            />
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={data}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="adminSymptoms"
          />

          <NothingBox
            itemsName="symptoms"
            display={!data.length}
            isLoading={isLoading}
            search={search}
            filter={status}
          >
            <FormattedMessage tagName="h1" id="container.symptoms.noSymptoms" />
            <FormattedMessage tagName="p" id="container.symptoms.addFirstSymptom" />
            <Link to="/admin/health-variables/symptoms/create" className="button primary mt-10">
              <FormattedMessage id="container.symptoms.addSymptom" />
            </Link>
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

Symptoms.contextTypes = {
  isTablet: T.bool.isRequired,
};

Symptoms.propTypes = {
  data: T.array.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  updateMeta: T.func.isRequired,
  openModal: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      data: state.dataTable.adminSymptoms.resources,
      isLoading: state.dataTable.adminSymptoms.isLoading,
      reqParams: state.dataTable.adminSymptoms.params,
      meta: state.dataTable.adminSymptoms.meta,
    }), {
      fetchData: (query) => fetchData('/admin/symptoms', 'adminSymptoms', query),
      updateMeta: (data) => updateMeta(data, 'adminSymptoms'),
      setDataItem: (data) => setDataItem(data, 'adminSymptoms'),
      openModal,
    },
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(Symptoms);
