import React, { Component } from 'react';
import T from 'prop-types';
// components
import UserFilterSectionDropdown from '../UserFilterSectionDropdown';
import FilterDropdownMenu from '../FilterDropdownMenu';

class SelectionSection extends Component {

  renderDropdownMenu = (isOpened) => {
    const { condition, listOptions, onOptionSelect } = this.props;
    return (
      <FilterDropdownMenu
        isOpened={isOpened}
        currentValue={condition}
        options={listOptions}
        onOptionClick={onOptionSelect}
      />
    );
  };

  render() {
    const { condition, listOptions, icon, onSectionRemove, isInitialOpened, isRemovable, isDisabled,
      placeholder, isInvalid } = this.props;
    const { label } = (listOptions.find(({ value }) => value === condition) || {});
    return (
      <UserFilterSectionDropdown
        isInitialOpened={isInitialOpened}
        isDisabled={isDisabled}
        currentValue={condition}
        triggerLabel={label || placeholder || listOptions[0].label}
        menuRenderer={this.renderDropdownMenu}
        isRemovable={isRemovable}
        isInvalid={isInvalid}
        onRemove={onSectionRemove}
        triggerIcon={icon}
      />
    );
  }
}

SelectionSection.propTypes = {
  condition: T.string.isRequired,
  icon: T.string.isRequired,
  listOptions: T.array.isRequired,
  onOptionSelect: T.func,
  onSectionRemove: T.func,
  isRemovable: T.bool,
  isDisabled: T.bool,
  isInitialOpened: T.bool,
  isInvalid: T.bool,
  placeholder: T.oneOfType([T.node, T.string]),
};

export default SelectionSection;
