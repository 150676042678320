import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import PackingPlantForm from '../../components/PackingPlantForm';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
// api
import { createPackingPlant } from 'endpoints/admin/packingPlants';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const customErrors = [
  { key: 'name', error: <FormattedMessage id="general.customErrors.company" /> },
];

class PackingPlantCreate extends Component {

  createNewPackingPlant = (data) => {
    const { router, params: { id } } = this.props;
    const companyData = {
      name: data.name,
      ...data.addressObj,
    };

    return createPackingPlant(id, companyData)
      .then(() => {
        showToastrMessage('component.toastr.packingPlant.created');
        router.push(`/admin/packing-companies/${id}`);
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { params: { id } } = this.props;
    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.button.createPackingPlant" />} />
          <Panel.Body noPadding>
            <FormErrorsBox
              formName="address-form"
              customErrors={customErrors}
            />
            <PackingPlantForm companyId={id} hasLocationDefining onSubmit={this.createNewPackingPlant} />
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

PackingPlantCreate.propTypes = {
  router: T.object.isRequired,
  params: T.object.isRequired,
};

export default PackingPlantCreate;
