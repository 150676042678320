import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router';
import IntlProvider from 'components/IntlProvider/IntlProvider';
import FarmTypeLabel from '../../../FarmTypeLabel';
// compose
import { compose, withHandlers } from 'recompose';
// utils
import cn from 'classnames';
// assets
import './FarmMarkerTooltip.scss';

const FarmMarkerTooltip = ({
  farm,
  visible,
  onTooltipClose,
  zoomIn,
  onMouseEnter,
  onMouseLeave
}) => (
  <IntlProvider>
    <div
      className={cn('FarmMarkerTooltip', { visible })}
      onMouseEnter={visible ? onMouseEnter : null}
      onMouseLeave={visible ? onMouseLeave : null}
    >
      <div className="farm-main-info">
        <div>
          <Link to={`/barnsheets/farms/${farm.id}`} className="farm-name">{farm.name}</Link>
          <FarmTypeLabel farmType={farm.farm_type} className="farm-type" />
          {farm.active && (
            <FormattedMessage id="general.status.active">
              {(status) => <span className="farm-active">{status}</span>}
            </FormattedMessage>
          )}
          <div className="farm-address">
            <FormattedMessage
              id="general.farmFullAddress"
              values={{ ...farm, external_link_id: farm.external_link_id || null }}
            />
          </div>
        </div>
        <i className="fa fa-times" onClick={onTooltipClose} />
      </div>
      <div className="farm-additional-info">
        {!!farm.manager && (
          <section>
            <span className="name"><FormattedMessage id="general.management" /> • </span>
            <span className="value">{farm.manager.name}</span>
          </section>
        )}
        <section>
          <span className="name"><FormattedMessage id="general.pucLetters" /> • </span>
          <span className="value">{farm.pigs_count}</span>
        </section>
        <section>
          <span className="name"><FormattedMessage id="general.lastCheckup" /> • </span>
          <span className="value">
            {farm.last_checked_at
              ? <TimeAgo date={farm.last_checked_at} />
              : <FormattedMessage id="general.pendingCheckup" />}
          </span>
        </section>
        <div className="farm-info-bottom-container">
          <div>
            <span className="name"><FormattedMessage id="general.lastCheckIn" /> • </span>
            <span className="value">
              {farm.last_checked_in
                ? <TimeAgo date={farm.last_checked_in} />
                : <FormattedMessage id="component.nothingBox.noCheckinsYet" />}
            </span>
          </div>
          <div className="farm-focus" onClick={zoomIn}>
            <FormattedMessage id="general.zoomMap" />
          </div>
        </div>
      </div>
    </div>
  </IntlProvider>
);

FarmMarkerTooltip.propTypes = {
  farm: T.object.isRequired,
  visible: T.bool,
  onTooltipClose: T.func.isRequired,
  zoomIn: T.func.isRequired,
  onMouseEnter: T.func,
  onMouseLeave: T.func,
};

const enhance = compose(
  withHandlers({
    zoomIn: ({ zoomIn, farm }) => () => {
      zoomIn(farm);
    },
    onMouseEnter: ({ farm, onMouseEnter }) => (event) => {
      if (onMouseEnter) onMouseEnter({ id: farm.id, type: 'farm' }, event);
    },
    onTooltipClose: ({ onTooltipClose }) => (event) => {
      if (onTooltipClose) onTooltipClose(event);
      event.stopPropagation();
    },
  })
);

export default enhance(FarmMarkerTooltip);
