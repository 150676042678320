import React, { useState } from 'react';
import T from 'prop-types';
// components
import Collapse from 'react-collapse';
import AddEventToCalendar from 'components/AddEventToCalendar';
import AddEventToCalendarModal from 'components/AddEventToCalendarModal';
import { ThreeDotsBtn } from 'components/Button';
import { LoadingStatusBadge } from 'components/StatusBadge/StatusBadge';
import TruckingStatusLine from 'components/TruckingStatusLine/TruckingStatusLine';
import CommitmentInfo from './CommitmentInfo';
import TruckingInfo from './TruckingInfo';
import Button from 'components/Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoRow from '../InfoRow/InfoRow';
// utils
import { getCommitmentDirection } from 'utils/commitmentsHelper';
import classnames from 'classnames/bind';
import moment from 'moment';
import { prepareCalendarData } from 'utils/addToCalendarHelper';
// styles
import styles from './CommitmentCard.module.scss';

const cn = classnames.bind(styles);

const CommitmentCard = ({
  commitment,
  className,
  onToggle,
  onDropdownOpen,
  options = [],
  mobileOptions = [],
  openModal,
}) => {
  const [isOpened, setIsOpened] = useState(commitment.status === 'pending');
  const { formatMessage } = useIntl();

  const toggleCollapse = () => {
    setIsOpened((prevIsOpened) => {
      if (onToggle) onToggle(!prevIsOpened);
      return !prevIsOpened;
    });
  };

  const { loading, arrive_at } = commitment;
  const { commitments } = loading;
  const deliveryDate = commitments[commitments.length - 1]?.arrive_at || '';
  const  { load_type } = loading;
  const calendarData = prepareCalendarData(commitment, formatMessage);

  return (
    <div className={className}>
      <TruckingStatusLine
        date={arrive_at ? moment(arrive_at).format('MM/DD/YYYY') : 'N/A'}
        direction={getCommitmentDirection(commitment)}
        type={load_type}
      />
      <div className={cn('content')}>
        <div className={cn('card-info')}>
          {/* HEADER */}
          <div className={cn('header-content')}>
            <div className={cn('name-section')}>
              <div className="semibold">{loading.name}</div>
              <LoadingStatusBadge className="mh-10" status={loading.status} />
            </div>
            <div className={cn('delivery-date', 'hide-for-mobile')}>
              <InfoRow
                label={<FormattedMessage id="general.deliveryDate" />}
                value={deliveryDate ? moment(deliveryDate).format('MM/DD/YYYY') : ''}
              />

            </div>
            <div className={cn('subtitle', 'show-for-mobile-only')}>
              {arrive_at ? moment(arrive_at).format('MM/DD/YYYY') : 'N/A'}
            </div>
            <div className={cn('btn-group', 'hide-for-mobile')}>
              <div className={cn('show-for-medium', 'ml-5', 'header-btn-group')}>
                {options.filter(({ hide }) => !hide).length < 4 ?
                  (options.map(({ label, onClick = () => undefined, ...other }, idx) => (
                    <Button
                      className={cn('btn', 'mr-10')}
                      key={idx}
                      {...other}
                      onClick={onClick}
                    >
                      {label}
                    </Button>
                  ))) :
                  <ThreeDotsBtn dataName="commitment" onClick={onDropdownOpen} className={cn('dots-btn')} />
                }
              </div>
              <div className="hide-for-medium">
                <ThreeDotsBtn dataName="commitment" onClick={onDropdownOpen} className={cn('dots-btn')} />
              </div>
              <i onClick={toggleCollapse} className={cn('fa fa-angle-right', 'icon', { isOpened })} />
            </div>
          </div>
          {/* COLLAPSE CONTENT */}
          <Collapse isOpened={isOpened}>
            <div className={cn('hide-for-mobile')}>
              <CommitmentInfo commitment={commitment} load={loading} />
              {calendarData.isFutureEvent && (
                <div className={cn('add-to-calendar')}>
                  <AddEventToCalendar calendarData={calendarData} />
                </div>
              )}
            </div>
          </Collapse>
        </div>
        <div className={cn('trucking-details', 'show-for-mobile-only')}>
          <TruckingInfo commitment={commitment} load={loading} />
          {calendarData.isFutureEvent && (
            <FormattedMessage id="general.addToCalendar">
              {(text) => (
                <div
                  className={cn('add-to-calendar-btn')}
                  onClick={() => openModal(<AddEventToCalendarModal calendarData={calendarData} />)}
                >
                  {text}
                </div>
              )}
            </FormattedMessage>
          )}
        </div>
        <div className={cn('action-btn-group', 'show-for-mobile-only')}>
          {mobileOptions.map(({ label, onClick = () => undefined, hide, ...other }, idx) => (
            !hide ? (
              <Button className={cn('btn')} key={idx} {...other} onClick={onClick}>
                {label}
              </Button>
            ) : null))}
        </div>
      </div>
    </div>
  );
};

CommitmentCard.propTypes = {
  onDropdownOpen: T.func.isRequired,
  openModal: T.func.isRequired,
  onToggle: T.func,
  className: T.string,
  commitment: T.object.isRequired,
  options: T.arrayOf(T.object),
  mobileOptions: T.arrayOf(T.object),
};

export default CommitmentCard;
