import React from 'react';
import T from 'prop-types';
// components
import ImportTypeCard from '../ImportTypeCard';
// constants
import { csvImportModes, importObjectsTypes, oneObjectMode, multipleObjectsMode } from 'constants/csvImport';
// utils
import { getCardProps } from 'utils/csvImportHelper';
// styles, assets
import styles from './SelectTypeForImport.module.scss';

const ImportTypeSelector = ({ savedMode, setCards, selectedCards, selectedMode, setMode }) => {
  const handleMultipleObjectsClick = (card) => {
    const isAlreadySelected = selectedCards.find(({ type }) => type === card.type);
    return isAlreadySelected
      ? setCards(selectedCards.filter(({ type }) => type !== card.type))
      : setCards([...selectedCards, card]);
  };

  return (
    <div className={styles.cardCSV}>
      {!savedMode && csvImportModes.map(({ type, title, infoText, svgIcon }) => (
        <ImportTypeCard
          key={type}
          title={title}
          type="large"
          infoText={infoText}
          isSelected={type === selectedMode}
          handleClick={() => setMode(type)}
          icon={svgIcon}
        />
      ))}
      {savedMode && savedMode === oneObjectMode && (
        importObjectsTypes.map((card) => (
          <ImportTypeCard
            handleClick={() => setCards([card])}
            isSelected={selectedCards[0]?.type === card.type}
            key={card.type}
            title={card.title}
            infoText={card.infoText}
            icon={card.svgIcon}
          />
        ))
      )}
      {savedMode && savedMode === multipleObjectsMode && (
        importObjectsTypes.map((card) => (
          <ImportTypeCard
            handleClick={() => handleMultipleObjectsClick(card)}
            key={card.type}
            title={card.title}
            infoText={card.infoText}
            icon={card.svgIcon}
            {...getCardProps(card, selectedCards)}
          />
        ))
      )}
    </div>
  );
};

ImportTypeSelector.propTypes = {
  savedMode: T.string,
  setCards: T.func,
  setMode: T.func.isRequired,
  selectedMode: T.string.isRequired,
  selectedCards: T.array,
};

export default ImportTypeSelector;
