import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { createUserFilterAction, deleteUserFilterAction, updateUserFilterAction } from 'reducers/userFilters';
import { closePortalRight } from 'reducers/rightToLeftPortal';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Toggle from 'components/Toggle';
import MobileHeader from 'components/MobileHeader/MobileHeader';
import MobilePortalOverlay from 'components/MobilePortalOverlay';
import SwitchUserFilterSection from './UserFilterSections/SwitchUserFilterSection';
import UserFilterMobileModal from './UserFilterMobileModal/UserFilterMobileModal';
import SaveSegmentConfirmationModal from './UserFilterMobileModal/SaveSegmentConfirmationModal';
import UserFilterSectionPicker from './UserFilterSectionPicker';
import UserFilterSegmentSelector from './UserFilterSegmentSelector';
import { ReactComponent as DotsIcon } from 'assets/svg/three-dots-vertical.svg';
import { ReactComponent as FilterIcon } from 'assets/svg/filter-ep.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
// utils, constants
import cn from 'classnames';
import intersection from 'lodash.intersection';
import { formatSectionOptionsForView, formatSectionOptionsForSave, isEqualFilters } from 'utils/userFiltersHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { canAddedOnceSections, sectionEntityKeys } from 'utils/constants/userFilters';
// styles
import styles from './UserFilterMobile.module.scss';

const modalTypes = {
  saveModal: 'save-modal',
  deleteModal: 'delete-modal',
};

function prepareFilterToState(userFilter, measurementSystem) {
  return {
    id: userFilter?.id,
    name: userFilter?.name,
    any_condition: userFilter?.any_condition || false,
    sections: userFilter?.sections?.map((section, index) => ({
      ...section,
      target_id: section.target_id || section[sectionEntityKeys[section.type]]?.id,
      options: formatSectionOptionsForView(section, measurementSystem),
      sectionIndex: index + 1,
    })) || [],
  };
}

const UserFilterMobile = ({
  allOrAnyConditionLabel,
  appliedFilter,
  createUserFilterAction,
  closePortalRight,
  deleteUserFilterAction,
  filterGroupName,
  filterSections,
  isUserFiltersLoading,
  measurementSystem,
  savedSegment,
  setFilter,
  updateUserFilterAction,
}) => {
  const [currentFilter, setCurrentFilter] = useState(prepareFilterToState(appliedFilter, measurementSystem));
  const [savedStateSegment, setSavedSegment] = useState(prepareFilterToState(savedSegment, measurementSystem));
  const [deletedSections, setDeletedSections] = useState([]);
  const [isSectionPickerOpened, setIsSectionPickerOpened] = useState(false);
  const [isSegmentPickerOpened, setIsSegmentPickerOpened] = useState(false);
  const [clickedSection, setClickedSection] = useState();
  const [openedModalType, setOpenedModalType] = useState('');
  const sectionsCount = currentFilter.sections.length;
  const isSectionsNotEmpty = sectionsCount > 0;
  const canBeApplied = isSectionsNotEmpty || (sectionsCount === 0 && (appliedFilter?.sections?.length || 0) > 0);

  const setSegmentToState = (segment) => {
    const formattedFilter = prepareFilterToState(segment, measurementSystem);
    setCurrentFilter(formattedFilter);
    setSavedSegment(formattedFilter);
  };

  const saveSegmentCallback = (segment) => {
    setOpenedModalType('');
    setSegmentToState(segment);
    if (deletedSections.length) setDeletedSections([]);
  };

  const handleAddSection = (section) => {
    if (clickedSection) setClickedSection(undefined);
    if (section.sectionIndex) {
      setCurrentFilter((prevState) => ({
        ...prevState,
        sections: prevState.sections.map(
          (prevSection) => (section.sectionIndex === prevSection.sectionIndex ? section : prevSection)
        ),
      }));
      setIsSectionPickerOpened(false);
      return;
    }
    setCurrentFilter((prevState) => ({
      ...prevState,
      sections: prevState.sections.concat({
        ...section,
        sectionIndex: (prevState.sections[prevState.sections.length - 1]?.sectionIndex || 0) + 1,
      })
    }));
    setIsSectionPickerOpened(false);
  };

  const handleApplyAll = () => {
    closePortalRight();
    setFilter(isSectionsNotEmpty ? currentFilter : undefined);
  };

  const handleChooseSegment = (segment) => {
    setIsSegmentPickerOpened(false);
    setSegmentToState(segment);
  };

  const handleCloseSectionPicker = () => {
    if (clickedSection) setClickedSection(undefined);
    setIsSectionPickerOpened(false);
  };

  const handleDeleteSegment = () => {
    return deleteUserFilterAction(currentFilter.id, filterGroupName)
      .then(saveSegmentCallback)
      .catch(toastResponseErrors);
  };

  const handleTapSection = (section) => {
    setClickedSection(section);
    setIsSectionPickerOpened(true);
  };

  const handleRemoveSection = (section) => {
    setCurrentFilter((prevState) => ({
      ...prevState,
      sections: prevState.sections.filter((prevSection) => (section.sectionIndex !== prevSection.sectionIndex)),
    }));
    if (section.id) {
      setDeletedSections((prevState) => prevState.concat({ id: section.id, _destroy: true }));
    }
  };

  const handleSaveSegment = (name, isUpdating) => {
    if (isUpdating) {
      const resourceToUpdate = {
        name,
        any_condition: currentFilter.any_condition,
        sections: currentFilter.sections.map((section) => {
          const { id, target_id, type, condition } = section;
          return { id, target_id, type, condition, options: formatSectionOptionsForSave(section, measurementSystem) };
        }).concat(deletedSections),
      };
      return updateUserFilterAction(currentFilter.id, filterGroupName, resourceToUpdate)
        .then(saveSegmentCallback)
        .catch(toastResponseErrors);
    }
    const resourceToCreate = {
      name,
      any_condition: currentFilter.any_condition,
      sections: currentFilter.sections.map((section) => {
        const { target_id, type, condition } = section;
        return { target_id, type, condition, options: formatSectionOptionsForSave(section, measurementSystem) };
      }),
    };
    return createUserFilterAction({ ...resourceToCreate, group_name: filterGroupName })
      .then(saveSegmentCallback)
      .catch(toastResponseErrors);
  };

  return (
    <Fragment>
      <MobileHeader
        title={<FormattedMessage id="general.filters" />}
        backLink={() => closePortalRight()}
        backIcon="fa fa-times"
      >
        <Dropdown
          className={styles['header-dropdown']}
          closeOnClick
          onlyDesktop
          trigger={<DotsIcon height={28} className={styles['dots-icon']} />}
        >
          <ul className={styles.menu}>
            {isSectionsNotEmpty && (
              <li
                className={cn(styles['menu-option'], styles['border-bottom'], 'ph-10')}
                onClick={() => setOpenedModalType(modalTypes.saveModal)}
              >
                <FormattedMessage id="general.button.saveSegment" />
              </li>
            )}
            {currentFilter.id && (
              <li
                className={cn(styles['menu-option'], styles['border-bottom'], 'ph-10')}
                onClick={() => setOpenedModalType(modalTypes.deleteModal)}
              >
                <FormattedMessage id="general.deleteSegment" />
              </li>
            )}
            <li className={cn(styles['menu-option'], 'ph-10')} onClick={() => setIsSegmentPickerOpened(true)}>
              <FormattedMessage id="general.chooseSegment" />
            </li>
          </ul>
        </Dropdown>
      </MobileHeader>

      {currentFilter.id && (
        <div className={styles['segment-header']}>
          <FilterIcon height={18} className={styles['filter-icon']} />
          <div className="nowrap-ellipsis">{currentFilter.name}</div>
          {!isEqualFilters(currentFilter, savedStateSegment) && (
            <FormattedMessage id="general.unsaved">
              {(text) => <div className={styles['editing-badge']}>{text}</div>}
            </FormattedMessage>
          )}
        </div>
      )}

      <div className={cn(styles['filters-list'], { [styles['is-existed-segment']]: !!currentFilter.id })}>
        <label className={styles['all-or-any-toggle']}>
          <Toggle
            isChecked={!currentFilter.any_condition}
            handleChange={() => {
              setCurrentFilter((prevState) => ({ ...prevState, any_condition: !currentFilter.any_condition }));
            }}
          />
          {allOrAnyConditionLabel}
        </label>

        {currentFilter.sections.map((section) => (
          <SwitchUserFilterSection
            key={section.sectionIndex}
            section={section}
            onEdit={handleTapSection}
            onRemove={handleRemoveSection}
          />
        ))}

        <div className={styles['add-filter-section']} onClick={() => setIsSectionPickerOpened(true)}>
          <PlusIcon height={10} className="mr-5" />
          {isSectionsNotEmpty
            ? <FormattedMessage id="general.addAnotherFilter" />
            : <FormattedMessage id="general.addFilter" />
          }
        </div>

        {isSectionsNotEmpty && (
          <div className={styles['clear-all']} onClick={() => setSegmentToState()}>
            <FormattedMessage id="general.button.clearAll" />
          </div>
        )}

        {canBeApplied && (
          <div className={styles['apply-btn-section']}>
            <Button primary wide onClick={handleApplyAll}>
              <FormattedMessage id="general.button.apply" />
            </Button>
          </div>
        )}
      </div>

      <div className={cn(styles['save-segment-overlay'], { [styles['is-active']]: !!openedModalType })}>
        {openedModalType === modalTypes.saveModal && (
          <SaveSegmentConfirmationModal
            initialName={currentFilter.name}
            isSaving={isUserFiltersLoading}
            onCancel={() => setOpenedModalType('')}
            onSave={handleSaveSegment}
          />
        )}
        {openedModalType === modalTypes.deleteModal && (
          <UserFilterMobileModal
            onLeftBtnClick={() => setOpenedModalType('')}
            onRightBtnClick={handleDeleteSegment}
            isLoading={isUserFiltersLoading}
            rightBtnLabel={<FormattedMessage id="component.modal.deleteSegment.confirm" />}
          >
            <FormattedMessage
              id="component.modal.deleteSegment.text"
              values={{ name: currentFilter.name, b: (text) => <b>{text}</b> }}
            >
              {(text) => <div className="text-center">{text}</div>}
            </FormattedMessage>
          </UserFilterMobileModal>
        )}
      </div>

      <MobilePortalOverlay isHidden={!isSectionPickerOpened && !isSegmentPickerOpened}>
        {isSectionPickerOpened && (
          <UserFilterSectionPicker
            initialSelectedSection={clickedSection}
            disabledSectionTypes={intersection(canAddedOnceSections, currentFilter.sections.map(({ type }) => type))}
            filterSections={filterSections}
            onClose={handleCloseSectionPicker}
            onSave={handleAddSection}
          />
        )}
        {isSegmentPickerOpened && (
          <UserFilterSegmentSelector
            currentSegment={currentFilter}
            filterGroupName={filterGroupName}
            onClose={() => setIsSegmentPickerOpened(false)}
            onSegmentChoose={handleChooseSegment}
          />
        )}
      </MobilePortalOverlay>
    </Fragment>
  );
};

UserFilterMobile.propTypes = {
  allOrAnyConditionLabel: T.oneOfType([T.string, T.node]).isRequired,
  appliedFilter: T.object.isRequired,
  closePortalRight: T.func.isRequired,
  createUserFilterAction: T.func.isRequired,
  deleteUserFilterAction: T.func.isRequired,
  updateUserFilterAction: T.func.isRequired,
  setFilter: T.func.isRequired,
  savedSegment: T.object,
  filterSections: T.array.isRequired,
  filterGroupName: T.string.isRequired,
  isUserFiltersLoading: T.bool.isRequired,
  measurementSystem: T.string.isRequired,
};

export default connect(
  (state, { filterGroupName, appliedFilter }) => ({
    savedSegment: appliedFilter?.id
      ? state.userFilters[filterGroupName]?.filters?.find(({ id }) => id === appliedFilter.id)
      : undefined,
    measurementSystem: state.auth.user.current_company.measurement_system,
    isUserFiltersLoading: state.userFilters[filterGroupName]?.isLoading || false,
  }),
  {
    createUserFilterAction,
    closePortalRight,
    deleteUserFilterAction,
    updateUserFilterAction,
  }
)(UserFilterMobile);
