import { fetchFromAPI } from 'utils/api';

export const getCompanies = () => {
  return fetchFromAPI('/admin/companies')
    .then((response) => response.resources)
    .catch((response) => {
      throw response;
    });
};

export const deleteCompany = (id) => {
  return fetchFromAPI(`/admin/companies/${id}`, { method: 'delete' });
};

export const createPackingCompany = (resource) => {
  return fetchFromAPI('/admin/packing_companies', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updatePackingCompany = (id, resource) => {
  return fetchFromAPI(`/admin/packing_companies/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
