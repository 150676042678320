import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Button from 'components/Button';
import Link from 'react-router/lib/Link';
import { openModal } from 'reducers/modals';
import TableFilter from 'components/TableFilter';
// utils
import cn from 'classnames';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// api
import { updateGenetic } from 'endpoints/admin/genetics';
// styles
import './Genetics.scss';

const tableFilters = [
  { label: <FormattedMessage id="general.allGenetics" />, value: '' },
  { label: <FormattedMessage id="general.male" />, value: 'male' },
  { label: <FormattedMessage id="general.female" />, value: 'female' },
];

class Genetics extends Component {

  componentDidMount() {
    const { reqParams, fetchData } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, type: filter });
  };

  renderGeneticNameColumn = ({ name, id }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.geneticName" />}>
      <div className="genetic-name-column">
        <i className="fa fa-ep-dna icon show-for-large" />
        <span className="color-primary"><Link to={`/admin/health-variables/genetics/${id}`}>{name}</Link></span>
      </div>
    </CustomColumn>
  );

  renderProviderColumn = ({ provider }) => (
    <CustomColumn label={<FormattedMessage id="general.provider" />}>
      <span className="route-column">
        {provider || 'N/A'}
      </span>
    </CustomColumn>
  );

  renderTypeColumn = ({ genetic_type }) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      {genetic_type ? <FormattedMessage id={`general.${genetic_type}`} /> : ''}
    </CustomColumn>
  );

  renderUsagesColumn = ({ usage_count }) => (
    <CustomColumn label={<FormattedMessage id="general.usages" />}>
      {usage_count || '-'}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  changeGeneticStatus = (id, active) => {
    const { setDataItem } = this.props;
    updateGenetic(id, { active: !active })
      .then(({ resource }) => {
        setDataItem(resource);
        showToastrMessage(`component.toastr.genetic.${active ? 'deactivated' : 'activated'}`);
      })
      .catch(toastResponseErrors);
  };

  renderActionsColumn = (item) => (
    <div className="collapsible-value button-column">
      <Button
        default
        onClick={() => this.changeGeneticStatus(item.id, item.active)}
      >
        <FormattedMessage id={`general.button.${item.active ? 'disable' : 'enable'}`} />
      </Button>
    </div>
  );

  renderExpandable = (item) => (
    <div>
      <Button small light onClick={() => this.changeGeneticStatus(item.id, item.active)}>
        <i className={`fa fa-${item.active ? 'times' : 'check'}-circle mr-5`} />
        <FormattedMessage id={`general.button.${item.active ? 'disable' : 'enable'}`} />
      </Button>
    </div>
  );

  render() {
    const { isLoading, meta: { total, stats = {} }, data, reqParams: { page, per_page, type = '', search, sort },
      onSearchChange, onPageChange, onPerPageChange, onSortChange } = this.props;
    const { isTablet } = this.context;
    const isEmptyTable = !data.length && !type && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.geneticName" />, flex: '2 0 170px',
        renderer: this.renderGeneticNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.provider" />, flex: '2 0 130px', renderer: this.renderProviderColumn,
        sortKey: 'provider' },
      { label: <FormattedMessage id="general.type" />, flex: '1 0 70px', renderer: this.renderTypeColumn,
        sortKey: 'genetic_type' },
      { label: <FormattedMessage id="general.usages" />, flex: '1 0 70px', renderer: this.renderUsagesColumn,
        sortKey: 'usage_count' },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 120px',
        renderer: this.renderStatusColumn, sortKey: 'status' },
      { label: '', flex: '0 0 110px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <div className="Genetics">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.genetics" />}>
            {!isEmptyTable && <SearchBox initialValue={search} onChange={onSearchChange} />}
          </Panel.Heading>
          <Panel.Body noPadding>
            {!isEmptyTable && (
              <TableFilter
                filters={tableFilters}
                activeFilter={type}
                onFilterChange={this.onFilterChange}
                stats={stats}
                className="ph-10"
              />
            )}
            <Preloader isActive={isLoading} />
            <DataTable
              data={data}
              columns={columns}
              sort={sort}
              onSortChange={onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
              tableKey="adminGenetics"
            />
            <NothingBox
              itemsName="genetics"
              display={!data.length}
              isLoading={isLoading}
              search={search}
              filter={type}
            >
              <h2 className="lighter"><FormattedMessage id="component.nothingBox.noData" /></h2>
            </NothingBox>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

Genetics.contextTypes = {
  isTablet: T.bool.isRequired,
};

Genetics.propTypes = {
  data: T.array.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      data: state.dataTable.adminGenetics.resources,
      isLoading: state.dataTable.adminGenetics.isLoading,
      reqParams: state.dataTable.adminGenetics.params,
      meta: state.dataTable.adminGenetics.meta,
      isAdmin: state.auth.user.roles_map.admin,
    }), {
      fetchData: (query) => fetchData('/admin/genetics', 'adminGenetics', query),
      setDataItem: (data) => setDataItem(data, 'adminGenetics'),
      openModal,
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(Genetics);
