import { handleActions, createAction, combineActions } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  page: 1,
  per_page: 25,
  padding: 0,
};


const initialState = {
  resources: [],
  isLoading: false,
  isLoaded: false,
  params: {
    ...defaultParams,
  },
  meta: {
    total: 0,
  },
};

const RESET_RESOURCES = 'notifications/RESET_RESOURCES';
const FETCH_DATA_WITH_MERGE = 'notifications/FETCH_DATA_WITH_MERGE';
const DISMISS_ACTION = 'notifications/DISMISS_ACTION';
const DISMISS_ALL_ACTION = 'notifications/DISMISS_ALL_ACTION';
const SET_READ_ACTION = 'notifications/SET_READ_ACTION';
const MERGE_NEW_NOTIFICATION = 'notifications/MERGE_NEW_NOTIFICATION';
const FETCH_ITEM_AFTER_DISMISS = 'notifications/FETCH_ITEM_AFTER_DISMISS';

const [FETCH_DATA_WITH_MERGE_PENDING, FETCH_DATA_WITH_MERGE_FULFILLED, FETCH_DATA_WITH_MERGE_REJECTED] = [
  `${FETCH_DATA_WITH_MERGE}_PENDING`,
  `${FETCH_DATA_WITH_MERGE}_FULFILLED`,
  `${FETCH_DATA_WITH_MERGE}_REJECTED`,
];

const [FETCH_ITEM_AFTER_DISMISS_PENDING, FETCH_ITEM_AFTER_DISMISS_FULFILLED, FETCH_ITEM_AFTER_DISMISS_REJECTED] = [
  `${FETCH_ITEM_AFTER_DISMISS}_PENDING`,
  `${FETCH_ITEM_AFTER_DISMISS}_FULFILLED`,
  `${FETCH_ITEM_AFTER_DISMISS}_REJECTED`,
];

const [DISMISS_ACTION_PENDING, DISMISS_ACTION_FULFILLED, DISMISS_ACTION_REJECTED] = [
  `${DISMISS_ACTION}_PENDING`,
  `${DISMISS_ACTION}_FULFILLED`,
  `${DISMISS_ACTION}_REJECTED`,
];

const [SET_READ_ACTION_PENDING, SET_READ_ACTION_FULFILLED, SET_READ_ACTION_REJECTED] = [
  `${SET_READ_ACTION}_PENDING`,
  `${SET_READ_ACTION}_FULFILLED`,
  `${SET_READ_ACTION}_REJECTED`,
];

const [DISMISS_ALL_ACTION_PENDING, DISMISS_ALL_ACTION_FULFILLED, DISMISS_ALL_ACTION_REJECTED] = [
  `${DISMISS_ALL_ACTION}_PENDING`,
  `${DISMISS_ALL_ACTION}_FULFILLED`,
  `${DISMISS_ALL_ACTION}_REJECTED`,
];

const filterParams = { group: 'production' };

// ------------------------------------
// Actions
// ------------------------------------

export const fetchDataWithMerge = (userId, params = defaultParams) => createAction(FETCH_DATA_WITH_MERGE)(
  fetchFromAPI(`/users/${userId}/notifications`, { params: { ...params, ...filterParams } })
    .then((response) => ({ ...response, params }))
);

export const dismissNotification = (userId, notificationId) => createAction(DISMISS_ACTION)(
  fetchFromAPI(
    `/users/${userId}/notifications/${notificationId}/dismiss`,
    { method: 'PUT', params: filterParams }
  ).then(() => notificationId)
);

export const fetchItemAfterDismiss = (userId, padding) => createAction(FETCH_ITEM_AFTER_DISMISS)(
  fetchFromAPI(
    `/users/${userId}/notifications`,
    { params: { page: 1, per_page: 1, padding, ...filterParams } }
  )
);

export const dismissAllNotification = (userId, last_notification_id) => createAction(DISMISS_ALL_ACTION)(
  fetchFromAPI(
    `/users/${userId}/notifications/dismiss_all`,
    { method: 'PUT', params: { last_notification_id, ...filterParams } }
  ).then(() => last_notification_id)
);

export const setReadAction = (userId, viewedIds) => createAction(SET_READ_ACTION)(
  fetchFromAPI(`/users/${userId}/notifications/read`, {
    method: 'PUT',
    params: filterParams,
    body: JSON.stringify({ resource: { read_notifications_ids: viewedIds } }),
  })
    .then(() => viewedIds)
);

export const mergeNewNotification = (object) => createAction(MERGE_NEW_NOTIFICATION)(object);

export const resetResources = createAction(RESET_RESOURCES);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [combineActions(FETCH_ITEM_AFTER_DISMISS_PENDING, SET_READ_ACTION_PENDING, DISMISS_ALL_ACTION_PENDING,
    DISMISS_ACTION_PENDING, FETCH_DATA_WITH_MERGE_PENDING)]: (state) => ({
    ...state,
    isLoading: true,
  }),

  [combineActions(FETCH_ITEM_AFTER_DISMISS_REJECTED, SET_READ_ACTION_REJECTED, DISMISS_ALL_ACTION_REJECTED,
    DISMISS_ACTION_REJECTED, FETCH_DATA_WITH_MERGE_REJECTED)]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [FETCH_DATA_WITH_MERGE_FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      resources: state.resources.concat(payload?.resources || []),
      meta: payload?.meta || state.meta || {},
      params: { ...state.params, ...payload?.params },
      isLoaded: true,
      isLoading: false,
    };
  },
  [FETCH_ITEM_AFTER_DISMISS_FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      resources: state.resources.concat(payload.resources),
      isLoading: false,
    };
  },
  [DISMISS_ACTION_FULFILLED]: (state, { payload }) => {
    return {
      ...state,
      resources: state.resources.filter((item) => item.id !== payload),
      meta: { ...state.meta, total: state.meta.total - 1 },
    };
  },
  [DISMISS_ALL_ACTION_FULFILLED]: (state) => {
    return {
      ...state,
      resources: [],
      meta: { ...state.meta, total: 0 },
      params: { ...defaultParams },
      isLoading: false,
    };
  },
  [SET_READ_ACTION_FULFILLED]: (state, { payload }) => {
    const newData = state.resources.map((item) => {
      return payload.has(item.id) ? { ...item, read: true } : item;
    });
    return {
      ...state,
      resources: newData,
      isLoading: false,
    };
  },
  [MERGE_NEW_NOTIFICATION]: (state, { payload }) => {
    return {
      ...state,
      resources: [payload, ...state.resources],
      params: { ...state.params, padding: state.params.padding + 1 },
    };
  },
  [RESET_RESOURCES]: (state) => ({
    ...state,
    resources: [],
    meta: {
      total: 0,
      stats: {},
    },
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
