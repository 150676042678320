import React from 'react';
import T from 'prop-types';
// utils
import renderIf from 'utils/renderIf';
import cn from 'classnames';
// styles
import './TextLabel.scss';

const TextLabel = ({
  className,
  children,
  ...restProps
}) => (
  renderIf(children)(
    <span className={cn('TextLabel', className)} {...restProps}>
      {children}
    </span>
  )
);


TextLabel.defaultProps = {
  className: '',
};

TextLabel.propTypes = {
  className: T.string,
  children: T.node,
};

export default TextLabel;
