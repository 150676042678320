import { useState } from 'react';

export default function (fetchResources, defaultParams = { page: 1, per_page: 10 }) {
  const [state, setState] = useState({
    resources: [],
    isLoading: false,
    isFirstFetchFinished: false,
    params: defaultParams,
    meta: {
      total: 0,
    },
  });

  const fetchData = (reqParams) => {
    const params = { ...state.params, ...reqParams };
    setState((prevState) => ({ ...prevState, isLoading: true }));
    return fetchResources({ ...state.params, ...reqParams })
      .then((response) => {
        if (params.page > 1) {
          setState((prevState) => ({
            ...prevState,
            params,
            meta: response.meta,
            isLoading: false,
            resources: [...state.resources, ...response.resources],
          }));
          return response;
        }
        setState((prevState) => ({ ...prevState, ...response, params, isLoading: false, isFirstFetchFinished: true }));
        return response;
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isLoading: false, isFirstFetchFinished: true }));
        throw errors;
      });
  };

  const fetchNextPage = () => {
    return fetchData({ page: state.params.page + 1 });
  };

  return [state, fetchData, fetchNextPage];
}
