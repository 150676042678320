import React, { Fragment } from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
import { formatLocation } from 'utils';
import { getCommitmentAddressEntity } from 'utils/commitmentsHelper';
import { renderCommitmentDetails } from './utils';
// styles
import styles from './TruckingRouteCard.module.scss';

const TruckingRouteCard = ({ commitments, loadType, direction, className }) => (
  <div className={cn(styles['trucking-route-wrapper'], className)}>
    <div className={cn(styles['trucking-route'])}>
      {commitments.map((commitment, idx) => {
        const addressEntity = getCommitmentAddressEntity(commitment);
        return (
          <Fragment key={idx}>
            <div className={styles.route}>
              <div>
                <div className="semibold">{addressEntity?.name || 'N/A'}</div>
                {formatLocation(addressEntity.city, addressEntity.state)}
                {renderCommitmentDetails(direction, commitment)}
              </div>
              <span className={cn(styles.arrow, styles[loadType])}>
                <i className={cn('fa fa-arrow-right')} />
              </span>
            </div>
          </Fragment>
        );
      })}
    </div>
    {commitments.length > 2 && <div className={cn(styles.divider)} />}
  </div>
);

TruckingRouteCard.propTypes = {
  commitments: T.array.isRequired,
  className: T.string,
  direction: T.string,
  loadType: T.string.isRequired,
};

export default TruckingRouteCard;
