import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './Divider.module.scss';

const Divider = ({ className }) => <div className={cn(styles.divider, className)} />;

Divider.propTypes = {
  className: T.string,
};

export default Divider;
