import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import InfoRow from 'components/InfoRow/InfoRow';
import { FormattedMessage } from 'react-intl';
import TruncatedComment from 'components/TruncatedComment';
import PhoneNumberLink from 'components/PhoneNumberLink';
// utils
import moment from 'moment';
import { getFullAddress } from 'utils';
import { getCommitmentAddressEntity } from 'utils/commitmentsHelper';
import get from 'lodash.get';

function getDateLabels(type) {
  switch (type) {
    case 'sourceFarm':
      return { dateLabel: 'general.loadDate', timeLabel: 'general.loadTime' };
    case 'destinationFarm':
      return { dateLabel: 'general.deliveryDate', timeLabel: 'general.deliveryTime' };
    case 'sowUnit':
      return { dateLabel: 'general.loadDate', timeLabel: 'general.loadTime' };
    case 'packingPlant':
      return { dateLabel: 'general.date', timeLabel: 'general.dockTime' };
    default:
      return { dateLabel: '', timeLabel: '' };
  }
}

const CommitmentDetails = ({ commitment, type }) => {
  const addressEntity = getCommitmentAddressEntity(commitment);
  const { arrive_at } = commitment;
  const formatArriveAt = (format) => (arrive_at ? moment(arrive_at).format(format) : 'N/A');
  const location = getFullAddress({ ...addressEntity, countryCode: addressEntity.country_code }) || 'N/A';
  const showGroupAndBarn = type === 'sourceFarm' || type === 'destinationFarm';
  const { dateLabel, timeLabel } = getDateLabels(type);
  const schedulingContact = addressEntity?.logistics_contact;
  const schedulingContactPhone = get(schedulingContact, 'contact_phone');

  return (
    <Fragment>
      <div>{location}</div>
      {schedulingContact && (
        <Fragment>
          <span>{get(schedulingContact, 'full_name')}</span>,&nbsp;
          {schedulingContactPhone ? <PhoneNumberLink mobileOnly phone={schedulingContactPhone} /> : 'N/A'}
        </Fragment>
      )}
      {showGroupAndBarn && (
        <Fragment>
          <InfoRow label={<FormattedMessage id="general.group" />} value={commitment.pig_group?.name} />
          <InfoRow label={<FormattedMessage id="general.barn" />} value={commitment.barn?.name} />
        </Fragment>
      )}
      <InfoRow label={<FormattedMessage id={dateLabel} />} value={formatArriveAt('MM/DD/YYYY')} />
      <InfoRow label={<FormattedMessage id={timeLabel} />} value={formatArriveAt('hh:mm A')} />
      {commitment.comment && (
        <div className="mt-10">
          <div className="semibold"><FormattedMessage id="general.loadNotes" /></div>
          <TruncatedComment comment={commitment.comment} />
        </div>
      )}
    </Fragment>
  );
};

CommitmentDetails.propTypes = {
  commitment: T.object.isRequired,
  type: T.oneOf(['sourceFarm', 'destinationFarm', 'sowUnit', 'packingPlant']).isRequired,
};

export default CommitmentDetails;
