import React from 'react';
import T from 'prop-types';
// utils
import inRange from 'lodash.inrange';
import cn from 'classnames';
// styles
import './ProgressBar.scss';

const ProgressBar = ({
  progress,
  className,
  inline,
  description,
  grayScale,
  withoutBar,
}) => {
  const progressNum = Number(progress);
  const style = { width: `${progressNum}%` };

  return (
    <div
      className={cn(
        className,
        'ProgressBar',
        {
          'danger': inRange(progressNum, 0, 19.99),
          'alert': inRange(progressNum, 20, 69.99),
          'warning': inRange(progressNum, 70, 89.99),
          'success': inRange(progressNum, 90, 200),
          'inline': inline,
          'grayscale': grayScale,
          'inline-flex': withoutBar,
        }
      )}
    >
      <div className="pb-text">
        {Math.round(progressNum)}%
        {description && (
          <span className="pb-description">
            {description}
          </span>
        )}
      </div>
      {!withoutBar && (
        <div className="pb-scale">
          <div className="pb-meter" style={style} />
        </div>
      )}
    </div>
  );
};

ProgressBar.defaultProps = {
  progress: 0,
  className: '',
  inline: true,
  withoutBar: false,
};

ProgressBar.propTypes = {
  className: T.string,
  description: T.oneOfType([T.string, T.object]),
  inline: T.bool,
  grayScale: T.bool,
  withoutBar: T.bool,
  progress: T.oneOfType([T.string, T.number]),
};

export default ProgressBar;
