import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
import './CustomColumn.scss';

const CustomColumn = ({
  textCenter,
  textRight,
  noBottomBorder,
  label,
  children,
  flexRow,
  onClick,
  className,
}) => (
  <div
    className={cn('CustomColumn collapsible-value', className, {
      'no-bottom-border': noBottomBorder,
    })}
  >
    <div className={cn('value-amount', { 'center-text': textCenter, 'right-text': textRight })}>
      {label && (
        <span className="hide-for-large semibold">{label}&nbsp;•&nbsp;</span>
      )}
      <span className={cn({ 'flex-row': flexRow })} onClick={onClick}>
        {children}
      </span>
    </div>
  </div>
);

CustomColumn.propTypes = {
  textCenter: T.bool,
  textRight: T.bool,
  noBottomBorder: T.bool,
  label: T.oneOfType([T.string, T.object]),
  children: T.any,
  flexRow: T.bool,
  onClick: T.func,
  className: T.string,
};

export default CustomColumn;
