import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import MentionsInput from 'components/MentionsInput';
// hooks
import useDidMountEffect from 'hooks/useDidMountEffect';
// utils
import cn from 'classnames';
// styles
import './CommentBox.scss';

const CommentBox = ({
  className = '',
  disabledIds,
  entityId,
  entityType,
  hasMentions,
  isOpen,
  label = <FormattedMessage id="general.notes.label" />,
  onOpen,
  onClose,
  onValueChange,
  onMentionsChange,
  placeholder,
  value,
}) => {
  const [isOpened, setIsOpened] = useState(isOpen);

  useDidMountEffect(() => {
    if (isOpened !== isOpen) setIsOpened(isOpen);
  }, [isOpen]);

  const setIsOpenedCallback = (nextIsOpened) => {
    if (nextIsOpened) onOpen?.();
    else onClose?.();
    return nextIsOpened;
  };

  const handleToggleCommentBox = () => {
    setIsOpened((prevIsOpened) => setIsOpenedCallback(!prevIsOpened));
  };

  const handleClearComment = () => {
    setIsOpened(setIsOpenedCallback(false));
    onValueChange('');
    onMentionsChange?.([]);
  };

  return (
    <div className={cn('CommentBoxComponent', { [className]: !!className })}>
      <div className="comment-box-header">
        <a onClick={handleToggleCommentBox} className="link">{label}</a>
        <a onClick={handleClearComment} className={cn('remove-comment', { 'visible': isOpened })}>
          <FormattedMessage id="general.button.remove" />
        </a>
      </div>
      <div className="CommentBoxComponent__wrapper">
        <div className={cn('comment', { 'opened': isOpened, 'closed': !isOpened })}>
          {hasMentions && (
            <MentionsInput
              inputType="square"
              comment={value || ''}
              disabledIds={disabledIds}
              entityId={entityId}
              entityType={entityType}
              onCommentChange={onValueChange}
              onMentionsChange={onMentionsChange}
              placeholder={placeholder}
            />
          )}
          {!hasMentions && (
            <textarea
              className="CommentBox__textArea"
              placeholder={placeholder}
              onChange={(e) => onValueChange(e.target.value)}
              value={value || ''}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CommentBox.propTypes = {
  isOpen: T.bool,
  hasMentions: T.bool,
  onValueChange: T.func.isRequired,
  onMentionsChange: T.func,
  onOpen: T.func,
  onClose: T.func,
  value: T.string,
  label: T.oneOfType([T.string, T.node]),
  className: T.string,
  placeholder: T.string,
  disabledIds: T.array,
  entityId: T.oneOfType([T.number, T.string]),
  entityType: T.string,
};

export default CommentBox;
