import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import RolesLabel from 'components/RolesLabel/RolesLabel';
import UserAvatar from 'components/UserAvatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// utils
import sum from 'lodash.sum';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

const labels = {
  admins: <FormattedMessage id="general.admins" />,
  allRoles: <FormattedMessage id="general.allRoles" />,
  tenantOwner: <FormattedMessage id="general.role.tenantOwner" />,
  tenantAdmin: <FormattedMessage id="general.role.admin" />,
  buttons: {
    revokeAccess: <FormattedMessage id="general.button.revokeAccess" />,
    resendInvite: <FormattedMessage id="general.button.resendInvite" />,
    inviteAdmin: <FormattedMessage id="general.button.inviteAdmin" />,
    editAdmin: <FormattedMessage id="general.editAdmin" />,
  },
  dataTable: {
    roles: <FormattedMessage id="component.dataTable.headers.roles" />,
    userStatus: <FormattedMessage id="component.dataTable.headers.userStatus" />,
    status: <FormattedMessage id="component.dataTable.headers.status" />,
    name: <FormattedMessage id="component.dataTable.headers.name" />,
  },
};

class MyCompanyAdminRoles extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  reInviteUser = (id) => {
    fetchFromAPI(`/users/${id}/reinvite`, { method: 'PUT' })
      .then(() => {
        showToastrMessage('component.toastr.userInvite.resent');
      })
      .catch(toastResponseErrors);
  };

  revokeRole = ({ id, is_tenant_owner, is_tenant_admin }) => {
    const { currentCompanyId, fetchData, reqParams } = this.props;
    let name;
    if (is_tenant_owner) name = 'tenant_owner';
    if (is_tenant_admin) name = 'tenant_admin';

    return fetchFromAPI(`/admin/companies/${currentCompanyId}/admin_roles`, {
      method: 'delete', body: JSON.stringify({ id, name }),
    })
      .then(() => {
        fetchData(reqParams);
        showToastrMessage('component.toastr.role.revoked');
      })
      .catch(toastResponseErrors);
  };

  renderNameColumn = (user) => (
    <CustomColumn flexRow>
      <UserAvatar user={user} size="35" className="mr-10" />
      <div className="semibold">{user.full_name}</div>
    </CustomColumn>
  );

  renderRoleColumn = ({ is_tenant_owner, is_tenant_admin }) => (
    <CustomColumn flexRow>
      <FormattedMessage id="component.dataTable.headers.roles">
        {(text) => <span className="hide-for-large semibold">{text}&nbsp;•&nbsp;</span>}
      </FormattedMessage>
      <RolesLabel
        roles={[
          { name: labels.tenantOwner, value: is_tenant_owner, shortcut: 'O' },
          { name: labels.tenantAdmin, value: is_tenant_admin, shortcut: 'A' },
        ]}
      />
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.userStatus}>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderActionsColumn = (user, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${user.id}-${rowIndex}`}
        wide
        label={labels.buttons.revokeAccess}
        buttonType="small light-gray"
        onClick={() => this.revokeRole(user)}
        dropDownData={[
          { label: labels.buttons.editAdmin, url: `/admin/my-company/admins/${user.id}/edit` },
          { label: labels.buttons.resendInvite, onClick: () => this.reInviteUser(user.id),
            hide: user.status !== 'pending' },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/my-company/admins/${user.id}/edit`} className="button light hide-for-large">
        {labels.buttons.editAdmin}
      </Link>
      {user.status === 'pending' && (
        <Button default onClick={() => this.reInviteUser(user.id)} className="hide-for-large">
          {labels.buttons.resendInvite}
        </Button>
      )}
      <Button darkGrey onClick={() => this.revokeRole(user)} className="hide-for-large">
        {labels.buttons.revokeAccess}
      </Button>
    </div>
  );

  renderExpandable = (user) => (
    <div>
      <Link to={`/admin/my-company/admins/${user.id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        {labels.buttons.editAdmin}
      </Link>
      {user.status === 'pending' && (
        <Button small light onClick={() => this.reInviteUser(user.id)}>
          <i className="fa fa-envelope mr-5" />
          {labels.buttons.resendInvite}
        </Button>
      )}
      <Button small light onClick={() => this.revokeRole(user)}>
        <i className="fa fa-times-circle mr-5" />
        {labels.buttons.revokeAccess}
      </Button>
    </div>
  );

  render() {
    const { resources, meta: { stats = {}, total }, isLoading, reqParams: { page, per_page, search, sort, role },
      onSearchChange, onPageChange, onPerPageChange, onSortChange } = this.props;
    const { isTablet } = this.context;
    const statsSum = sum(Object.values(stats));
    const filtersData = [
      { label: labels.allRoles, value: '', count: statsSum },
      { label: labels.tenantOwner, value: 'tenant_owner' },
      { label: labels.tenantAdmin, value: 'tenant_admin' },
    ];
    const isEmptyTable = !resources.length && !role && !search;

    const columns = [
      { label: labels.dataTable.name, flex: '2 1 200px', renderer: this.renderNameColumn, sortKey: 'first_name' },
      { label: labels.dataTable.roles, flex: '1 1 120px', renderer: this.renderRoleColumn, sortKey: 'role' },
      { label: labels.dataTable.status, flex: '1 1 120px', renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 190px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={labels.admins}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={filtersData}
              onFilterChange={this.onFilterChange}
              stats={stats}
              activeFilter={role}
              className="ph-10"
            >
              <Link to="/admin/my-company/admins/create" className="button small primary wider">
                {labels.buttons.inviteAdmin}
              </Link>
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="myCompanyAdmins"
          />

          <NothingBox
            itemsName="admin_roles"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={role}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noAdminRoles" />
            <Link to="/admin/my-company/admins/create" className="button primary mt-10">
              {labels.buttons.inviteAdmin}
            </Link>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

MyCompanyAdminRoles.contextTypes = {
  isTablet: T.bool,
};

MyCompanyAdminRoles.propTypes = {
  fetchData: T.func.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  currentCompanyId: T.number.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
};

const enhance = compose(
  connect((state) => ({
    resources: state.dataTable.myCompanyAdmins.resources,
    reqParams: state.dataTable.myCompanyAdmins.params,
    meta: state.dataTable.myCompanyAdmins.meta,
    isLoading: state.dataTable.myCompanyAdmins.isLoading,
    currentCompanyId: state.auth.user.current_company.id,
  }), { fetchData }),
  withProps(({ currentCompanyId: id, fetchData }) => ({
    fetchData: (query) => fetchData(`/admin/companies/${id}/admin_roles`, 'myCompanyAdmins', query),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(MyCompanyAdminRoles);
