import { onSWUpdate } from '../serviceWorker';
import { fetchBuildData } from 'endpoints/static';
import {
  getLSBuildDate,
  getLSFetchBuildDate,
  isNewContentAvailable,
  setLSBuildDate,
  setLSFetchBuildDate,
} from 'utils/localStorageHelper';

export default function checkNewFrontendFiles() {
  const currentBuildDate = getLSBuildDate();
  const fetchBuildDate = getLSFetchBuildDate();
  const newFetchBuildData = new Date();
  const diffInMs = newFetchBuildData - new Date(fetchBuildDate);
  const diffInMinutes = diffInMs / 1000 / 60;

  if (!currentBuildDate || (diffInMinutes > 5)) {
    fetchBuildData()
      .then(({ build_date }) => {
        setLSBuildDate(build_date);
        setLSFetchBuildDate(newFetchBuildData.toJSON());

        if (currentBuildDate !== build_date && !isNewContentAvailable()) {
          if ('serviceWorker' in navigator) {
            const swUrl = `${process.env.PUBLIC_URL}/workboxSW.js`;
            navigator.serviceWorker.getRegistration(swUrl).then(onSWUpdate);
          }
        }
      });
  }
}
