import React from 'react';
import T from 'prop-types';
// recompose
import { branch, renderNothing } from 'recompose';
// components
import UserAvatar from 'components/UserAvatar';
import Dropdown from 'components/Dropdown/Dropdown2';
// utils
import get from 'lodash.get';
import cn from 'classnames';
import moment from 'moment';
// styles
import './UserPanel.scss';

const UserPanel = ({
  user,
  customUserName,
  userImageSize,
  dropdownOptions,
  timestamp,
  customTimestamp,
  label,
  className,
  children,
  headerLine,
}) => {
  const username = customUserName || get(user, 'full_name') || get(user, 'name');
  const timestampNode = <div className="timestamp">{moment(timestamp).format('MMM D [at] hh:mm a')}</div>;
  const idKey = moment(timestamp) + new Date().getUTCMilliseconds();

  return (
    <div className={cn('UserPanel panel overflow-visible', className)}>
      {headerLine}
      <div className="user-bar">
        <UserAvatar user={user} size={userImageSize} className="user-avatar" />
        <div className="user-description">
          <div><strong>{username}</strong> {label}</div>
          {customTimestamp || timestampNode}
        </div>
        <div className="user-actions">
          <Dropdown idKey={idKey} options={dropdownOptions} />
        </div>
      </div>
      {children}
    </div>
  );
};

UserPanel.defaultProps = {
  className: '',
  userImageSize: 40,
  dropdownOptions: [],
};

UserPanel.propTypes = {
  user: T.object,
  dropdownOptions: T.array,
  timestamp: T.string,
  label: T.oneOfType([T.string, T.node]),
  className: T.string,
  customUserName: T.oneOfType([T.string, T.node]),
  customTimestamp: T.node,
  userImageSize: T.number,
  children: T.any,
  headerLine: T.node,
};

export default branch(
  ({ user }) => !user,
  renderNothing,
)(UserPanel);
