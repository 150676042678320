import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './ResourcesDropzone.module.scss';

const cn = classnames.bind(styles);

let dragCounter = 0;

class ResourcesDropzone extends Component {

  state = {
    isDragOver: false,
  };

  componentDidMount() {
    this.rootElem = document.getElementById('root');
    if (this.rootElem) {
      this.rootElem.addEventListener('dragenter', this.onDragEnter);
      this.rootElem.addEventListener('dragleave', this.onDragLeave);
      this.rootElem.addEventListener('drop', this.onDropEvent);
    }
  }

  componentWillUnmount() {
    if (this.rootElem) {
      this.rootElem.removeEventListener('dragenter', this.onDragEnter);
      this.rootElem.removeEventListener('dragleave', this.onDragLeave);
      this.rootElem.removeEventListener('drop', this.onDropEvent);
    }
  }

  // Drag & Drop

  onDragEnter = () => {
    dragCounter++;
    if (dragCounter === 1) {
      this.setState({ isDragOver: true });
    }
  };

  onDragLeave = () => {
    dragCounter--;
    if (dragCounter === 0) {
      this.setState({ isDragOver: false });
    }
  };

  onDropEvent = () => {
    dragCounter = 0;
    this.setState({ isDragOver: false });
  };

  resetDragCounter = () => {
    this.onDropEvent();
  };

  render() {
    const { className, isEmpty } = this.props;
    const { isDragOver } = this.state;
    return (
      <div id="resource-dropzone" className={cn('resources-dropzone', className, { isDragOver })}>
        {isEmpty && (
          <div className={cn('empty-upload-hint')}>
            <div className={cn('upload-circle')}>
              <i className={cn('fa fa-cloud-upload-ios', 'upload-icon')} />
            </div>
            <div className={cn('drop-files-text')}>
              <FormattedMessage id={`component.resourcesUploader.${isDragOver ? 'dropFilesHere' : 'shareFilesHint'}`} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ResourcesDropzone.propTypes = {
  className: T.string,
  isEmpty: T.bool.isRequired,
};

export default ResourcesDropzone;
