import React, { Component } from 'react';
import T from 'prop-types';
//  components
import PreferenceCollapse from 'containers/MyProfile/components/PreferenceCollapse';
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import PreferenceItem from 'containers/MyProfile/components/PreferenceItem';
import Button from 'components/Button';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import Input from 'components/Input';
import InputLabel from 'components/Input/InputLabel';
//  utils
import moment from 'moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import classnames from 'classnames/bind';
//  styles
import styles from './PasswordCollapseSection.module.scss';

const cn = classnames.bind(styles);

const passwordFields = [
  { name: 'current_password', label: <FormattedMessage id="general.currentPassword" /> },
  { name: 'password', label: <FormattedMessage id="general.newPassword" /> },
  { name: 'password_confirmation', label: <FormattedMessage id="general.retype" /> },
];

const initialState = {
  isOpened: false,
  isLoading: false,
  current_password: '',
  password: '',
  password_confirmation: '',
};

class PasswordCollapseSection extends Component {

    state = initialState;

    onCollapseToggle = () => (
      this.setState((prevState) => ({ isOpened: !prevState.isOpened }))
    );

    onSubmitData = () => {
      const { user: { id }, updateUser } = this.props;
      const { password, password_confirmation, current_password } = this.state;
      this.setState({ isLoading: true });
      updateUser({ password, password_confirmation, current_password }, id)
        .then(() => {
          showToastrMessage('component.toastr.myProfileEdit.success.profile');
          this.setState(initialState);
        })
        .catch((errors) => {
          toastResponseErrors(errors);
          this.setState({ isLoading: false });
        });
    };

    handleChange = (type) => (e) => {
      this.setState({ [type]: e.target.value });
    };


    render() {
      const { isOpened, isLoading, password, password_confirmation, current_password } = this.state;
      const { user: { password_changed_at } } = this.props;
      const isValid = password && password_confirmation && current_password;
      const changedAt = password_changed_at
        ? moment(password_changed_at).format('MM/DD/YYYY')
        : <FormattedMessage id="general.notAvail" />;
      return (
        <CollapsesBox className="mv-20">
          <PreferenceCollapse
            className={cn('profile-collapse-section')}
            isOpened={isOpened}
            onToggle={this.onCollapseToggle}
            icon={<i className={cn('orange-icon', 'fa fa-key')} />}
            title={<FormattedMessage id="container.login.changePassword" />}
            subtitle={<FormattedMessage id="general.time.lastChange" values={{ changedAt }} />}
          >
            <Preloader isActive={isOpened && isLoading} />
            <PreferenceItem className={cn('section-data')}>
              {passwordFields.map(({ name, label }) => (
                <div key={name} className="small-12 medium-5">
                  <InputLabel>{label}</InputLabel>
                  <Input type="password" onChange={this.handleChange(name)} value={this.state[name]} />
                </div>
              ))}
              <div className={cn('forgot-pass')}>
                <Link to="/reset-password" tabIndex="0" className="color-primary">
                  <FormattedMessage id="container.login.isForgot" />
                </Link>
              </div>
              <Button
                primary
                className="mobile-full-width mt-20"
                disabled={!isValid || isLoading}
                onClick={this.onSubmitData}
              >
                <FormattedMessage id="general.button.saveChanges" />
              </Button>
            </PreferenceItem>
          </PreferenceCollapse>
        </CollapsesBox>
      );
    }
}

PasswordCollapseSection.propTypes = {
  user: T.object.isRequired,
  updateUser: T.func.isRequired,
};

export default PasswordCollapseSection;
