import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import FarmFeed from './FarmFeed';
import FarmFeedExpiredMessage from './FarmFeedExpiredMessage';
import FarmFeedActivityPlaceholder from 'components/FarmFeed/FarmFeedActivityPlaceholder';
import OfflineContainer from 'components/OfflineScreen/OfflineContainer';
// utils
import times from 'lodash.times';
// styles
import './FarmFeed.scss';

const FarmFeedContainer = ({ setBreadcrumbs, currentUser }) => {
  const { current_company, roles_map } = currentUser;
  const isFeedNotAvailable = current_company.is_expired && !roles_map.super_admin;

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.pageTitle.farmfeed" />,
        path: '/farmfeed',
        useLabelAsMobileTitle: true,
      },
    ]);
  }, []);

  return (
    <OfflineContainer
      customContent={times(3, (i) => <FarmFeedActivityPlaceholder key={i} />)}
    >
      {isFeedNotAvailable && <FarmFeedExpiredMessage />}
      {!isFeedNotAvailable && <FarmFeed currentUser={currentUser} />}
    </OfflineContainer>
  );
};

FarmFeedContainer.propTypes = {
  currentUser: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  }), {
    setBreadcrumbs,
  }
)(FarmFeedContainer);
