import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Avatar from '../Avatar/Avatar';
import FarmfeedTooltip from './FarmfeedTooltip';
// endpoints
import { fetchActivityFarm } from 'endpoints/farmfeed';
//  constants
import { farmData } from './constants';

const FarmTooltip = ({ activityId, farmId, isAdmin }, { router }) => {
  const [activityFarm, setActivityFarm] = useState({});

  useEffect(() => {
    fetchActivityFarm(activityId, farmId).then(setActivityFarm);
  }, []);

  return (
    <FarmfeedTooltip
      type="farm"
      data={activityFarm}
      tooltipData={farmData}
      getAvatar={() => <Avatar type="farm" avatarSize={55} iconSize={25} />}
      isAdmin={isAdmin}
      onManageBtnClick={() => router.push(`/admin/farms/${farmId}`)}
      manageBtnLabel={<FormattedMessage id="general.manageFarm" />}
    />
  );
};

FarmTooltip.contextTypes = {
  router: T.object.isRequired,
};

FarmTooltip.propTypes = {
  activityId: T.number.isRequired,
  farmId: T.number.isRequired,
  isAdmin: T.bool.isRequired,
};

export default FarmTooltip;
