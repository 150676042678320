import { handleActions, createAction } from 'redux-actions';
import { prepareTreatmentsList } from 'utils';
import update from 'react-addons-update';
import pull from 'lodash.pull';
import { getSymptoms, getSymptomsOffline, getTreatments, getTreatmentsOffline, getMortalityReasons,
  getMortalityReasonsOffline } from 'endpoints/common';
import { fetchFromAPI } from 'utils/api';
// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  dataList: {
    resources: [],
    isLoading: false,
    params: {
      search: '',
    },
  },
  selected: [],
  destroyed: [],
};

// ------------------------------------
// Action Types
// ------------------------------------
const FETCH_LIST_PENDING = 'mobileListPicker/FETCH_LIST_PENDING';
const FETCH_LIST_FULFILLED = 'mobileListPicker/FETCH_LIST_FULFILLED';
const FETCH_LIST_REJECTED = 'mobileListPicker/FETCH_LIST_REJECTED';
const UPDATE_SELECTED_ITEM = 'mobileListPicker/UPDATE_SELECTED_ITEM';
const SELECT_ITEM = 'mobileListPicker/SELECT_ITEM';
const SET_SEARCH = 'mobileListPicker/SET_SEARCH';
const UNSELECT_ITEM = 'mobileListPicker/UNSELECT_ITEM';
const SET_DESTROYED_DATA = 'mobileListPicker/SET_DESTROYED_DATA';
const SET_SELECTED_DATA = 'mobileListPicker/SET_SELECTED_DATA';
const RESET_RESOURCES = 'mobileListPicker/RESET_RESOURCES';
const RESET_DATA = 'mobileListPicker/RESET_DATA';
const SET_DATA = 'mobileListPicker/SET_DATA';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchList = (path, params = {}) => (dispatch, getState) => {
  dispatch(createAction(FETCH_LIST_PENDING)());
  const { isOnline } = getState().network;
  const requestsData = {
    '/treatment_products': isOnline ? getTreatments : getTreatmentsOffline,
    '/symptoms': isOnline ? getSymptoms : getSymptomsOffline,
    '/mortality_reasons': isOnline ? getMortalityReasons : getMortalityReasonsOffline,
  };

  const request = () => {
    if (requestsData[path]) return requestsData[path]();
    return fetchFromAPI(path, { params });
  };

  return request()
    .then((response) => {
      const newResponse = response.resources ? response : { resources: response };
      const resources = path === '/treatment_products'
        ? prepareTreatmentsList(newResponse.resources)
        : newResponse.resources;
      return dispatch(createAction(FETCH_LIST_FULFILLED)({ resources, params }));
    })
    .catch((response) => dispatch(createAction(FETCH_LIST_REJECTED)(response)));
};

export const selectItem = (item) => createAction(SELECT_ITEM)(item);
export const setSearch = (search) => createAction(SET_SEARCH)(search);
export const updateSelectedItem = (index, item) => createAction(UPDATE_SELECTED_ITEM)({ index, item });
export const unselectItem = (item) => createAction(UNSELECT_ITEM)(item);
export const setDestroyedData = (data) => createAction(SET_DESTROYED_DATA)(data);
export const setSelectedData = (data) => createAction(SET_SELECTED_DATA)(data);
export const resetResources = createAction(RESET_RESOURCES);
export const resetData = createAction(RESET_DATA);
export const setData = (data) => createAction(SET_DATA)(data);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [SET_DATA]: (state, { payload }) => ({
    ...state,
    dataList: {
      ...state.dataList,
      resources: payload,
    },
  }),
  [FETCH_LIST_PENDING]: (state) => ({
    ...state,
    dataList: {
      ...state.dataList,
      isLoading: true,
    },
  }),
  [FETCH_LIST_FULFILLED]: (state, { payload: { resources, params } }) => ({
    ...state,
    dataList: {
      resources,
      params: {
        ...state.dataList.params,
        ...params,
      },
      isLoading: false,
    },
  }),
  [FETCH_LIST_REJECTED]: (state, action) => ({
    ...state,
    dataList: {
      ...state.dataList,
      ...action.payload,
      isLoading: false,
    },
  }),
  [SELECT_ITEM]: (state, action) => ({
    ...state,
    selected: [
      ...state.selected,
      action.payload,
    ],
  }),
  [SET_SEARCH]: (state, action) => ({
    ...state,
    dataList: {
      ...state.dataList,
      params: { search: action.payload },
    },
  }),
  [UNSELECT_ITEM]: (state, action) => {
    const selected = pull([...state.selected], action.payload);
    return {
      ...state,
      selected,
    };
  },
  [UPDATE_SELECTED_ITEM]: (state, { payload: { index, item } }) => ({
    ...state,
    selected: update(state.selected, {
      [index]: { $merge: item },
    }),
  }),
  [SET_SELECTED_DATA]: (state, action) => ({
    ...state,
    selected: action.payload,
  }),
  [SET_DESTROYED_DATA]: (state, action) => ({
    ...state,
    destroyed: action.payload,
  }),
  [RESET_RESOURCES]: (state) => ({
    ...state,
    dataList: {
      ...state.dataList,
      resources: [],
    },
  }),
  [RESET_DATA]: () => ({
    ...initialState,
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
