import React from 'react';
import { FormattedMessage } from 'react-intl';

export const getDirection = (loading, pathname) => {
  if (loading.is_incoming && pathname.includes('incoming')) return 'in';
  if (loading.is_outgoing && pathname.includes('outgoing')) return 'out';
  return '';
};

export const getNavLinks = (commitment, isHealthInfoExists) => {
  const { sales_results } = commitment;
  return [
    { label: <FormattedMessage id="general.loadInfo" />, link: `/my-schedule/${commitment.id}/load-info` },
    { label: <FormattedMessage id="general.health" />,
      link: `/my-schedule/${commitment.id}/health`,
      isHidden: !isHealthInfoExists },
    { label: <FormattedMessage id="general.salesResults" />,
      link: `/my-schedule/${commitment.id}/sales-results`,
      isHidden: !sales_results.length,
    },
  ];
};
