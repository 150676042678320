import React from 'react';
import T from 'prop-types';
import { Link } from 'react-router';
// static
import everyPigLogo from 'assets/svg/ep-logo-black.svg';
import everyPigLogoWhite from 'assets/svg/ep-logo-white.svg';
// translate
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
// styles
import './LayoutMinimal.scss';

const values = { year: new Date().getFullYear() };

const LayoutMinimal = ({ containerClass, children }) => (
  <div className="layout-minimal">
    <Link to="/" className="layout-minimal-logo">
      <img src={everyPigLogoWhite} alt="everypig-trademarked-logo" />
      <img src={everyPigLogo} alt="everypig-trademarked-logo" className="logo-mobile" />
    </Link>
    <div className="wrapper">
      <div className={cn('container', containerClass)}>
        {children}
      </div>
    </div>
    <div className="minimal-footer">
      <Link
        to="https://www.everypig.co/privacy-policy"
        className="privacy-policy"
        target="_blank"
      >
        <FormattedMessage id="general.privacyPolicy" />
      </Link>
      <FormattedMessage id="container.login.rights" values={values} />
    </div>
  </div>
);

LayoutMinimal.propTypes = {
  children: T.oneOfType([T.string, T.node]),
  containerClass: T.string,
};

export default LayoutMinimal;
