import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import PlacesAutocomplete from 'react-places-autocomplete';
import Input from 'components/Input';
// utils
import createAddressInfo from 'utils/createAddressInfo';
import classnames from 'classnames/bind';
// styles
import styles from './AddressSelect.module.scss';
import 'components/Select/ReactSelect.scss';

const cn = classnames.bind(styles);

const AddressSelect = ({ changeValue, countriesData, hasPoweredByLabel, label, placeholder, selected }) => {
  const [focused, setIsFocused] = useState(false);
  const [value, setValue] = useState(selected || '');

  useEffect(() => {
    if (!focused && selected) setValue(selected);
  }, [focused, selected]);

  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  const handleSelect = async (addressValues) => {

    if (!addressValues) {
      return;
    }

    await createAddressInfo(
      countriesData,
      'fromAddress',
      [addressValues],
      (addressInfo) => changeValue('addressObj', addressInfo)
    );
    setValue(addressValues);
  };

  return (
    <div>
      {label && <span className={styles['input-label']}>{label}</span>}
      {hasPoweredByLabel && (
        <FormattedMessage id="general.poweredByGoogle">
          {(text) => <span className={styles['powered-by']}>{text}</span>}
        </FormattedMessage>
      )}
      <PlacesAutocomplete
        highlightFirstSuggestion
        value={value}
        onChange={setValue}
        onSelect={handleSelect}
        debounce={300}
        shouldFetchSuggestions={(value || '').length >= 2}
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          const inputProps = getInputProps({ className: cn('select')  });
          return (
            <div className={cn('select-container')}>
              <div className={cn('input-wrap', { 'is-focused': focused })}>
                {!value && placeholder && <span className={cn('placeholder')}>{placeholder}</span>}
                <Input
                  {...inputProps}
                  onFocus={() => {
                    setIsFocused(true);
                  }}
                  onBlur={() => {
                    inputProps.onBlur();
                    setIsFocused(false);
                  }}
                />
                <span className={cn('arrow-zone')}>
                  <i className="fa fa-angle-down" />
                </span>
              </div>
              {suggestions[0] && (
                <div className={cn('autocomplete-dropdown-container')}>
                  {suggestions.map((suggestion) => {
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: cn('suggestion-item', {
                            'suggestion-item--active': suggestion.active,
                            'current-item': suggestion.description === value,
                          }),
                        })}
                        key={suggestion.index}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>

    </div>
  );
};

AddressSelect.propTypes = {
  selected: T.string,
  hasPoweredByLabel: T.bool,
  label: T.oneOfType([T.string, T.node]),
  changeValue: T.func,
  countriesData: T.array,
  placeholder: T.oneOfType([T.string, T.object]),
};

export default AddressSelect;
