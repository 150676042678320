import React, { Component } from 'react';
import T from 'prop-types';
// components
import MonthlyDatePickerController from './MonthlyDatePickerController';
import DatePickerMobileBox from 'components/DatePicker/DatePickerMobileBox';
// utils
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
// styles
import './MonthlyDatePickerMobile.scss';

class MonthlyDatePickerMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      date: props.initialDate,
    };
  }

  onDatesChange = ({ date }) => {
    this.setState({ date });
  };

  clearDates = () => this.setState({
    date: null,
  });

  submitDates = () => {
    const { onChange, closePortal } = this.props;
    const { date } = this.state;
    onChange({ date });
    closePortal();
  };

  renderHeaderDates = () => {
    const { date } = this.state;
    return (
      <div className="header-dates">
        <div className="selected-date">
          <div>
            {date ? date.format('MMMM YYYY') : <FormattedMessage id="component.dateRangePicker.pickDate" />}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { className, closePortal } = this.props;
    const { date } = this.state;

    return (
      <DatePickerMobileBox
        className={cn('MonthlyDatePickerMobile', { [className]: !!className })}
        onClose={closePortal}
        onClearDates={this.clearDates}
        onSubmit={this.submitDates}
        headerDatesRenderer={this.renderHeaderDates}
        isSubmitBtnDisabled={() => (!date)}
      >
        <MonthlyDatePickerController
          mobileMode
          orientation="vertical"
          date={date}
          onChange={this.onDatesChange}
        />
      </DatePickerMobileBox>
    );
  }
}

MonthlyDatePickerMobile.contextTypes = {
  isMobile: T.bool,
};

MonthlyDatePickerMobile.propTypes = {
  className: T.string,
  initialDate: T.object,
  closePortal: T.func,
  onChange: T.func,
};

export default MonthlyDatePickerMobile;
