import { handleActions, createAction } from 'redux-actions';
import { getAdminFarm, updateAdminFarm } from 'endpoints/admin/farms';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: {
    data: {},
    loading: false,
  }
};

const SET_FARM_DATA = 'farms/SET_FARM_DATA';
const SET_RESOURCE_LOADING = 'farms/SET_RESOURCE_LOADING';
const UPDATE_FARM_DATA = 'farms/UPDATE_FARM_DATA';

// ------------------------------------
// Actions
// ------------------------------------

export const setFarmData = createAction(SET_FARM_DATA);
export const setResourceLoading = createAction(SET_RESOURCE_LOADING);
export const updateFarmData = createAction(UPDATE_FARM_DATA);

// ------------------------------------
// Action creators
// ------------------------------------

// admin action creators

// many aside usages
export const getFarm = (farmId) => (dispatch) => {
  dispatch(setResourceLoading(true));
  return getAdminFarm(farmId)
    .then((resource) => {
      dispatch(setFarmData(resource));
      dispatch(setResourceLoading(false));
      return resource;
    })
    .catch((error) => {
      dispatch(setResourceLoading(false));
      throw error;
    });
};

export const updateFarm = (data, farmId) => (dispatch) => {
  dispatch(setResourceLoading(true));
  return updateAdminFarm(farmId, data)
    .then((resource) => {
      dispatch(updateFarmData(resource));
      dispatch(setResourceLoading(false));
      return resource;
    })
    .catch((error) => {
      dispatch(setResourceLoading(false));
      throw error;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [SET_FARM_DATA]: (state, action) => ({
    resource: {
      ...state.resource,
      data: action.payload,
    }
  }),
  [SET_RESOURCE_LOADING]: (state, action) => ({
    resource: {
      ...state.resource,
      loading: action.payload
    }
  }),
  [UPDATE_FARM_DATA]: (state, action) => ({
    resource: {
      ...state.resource,
      data: { ...state.resource.data, ...action.payload }
    }
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
