import moment from 'moment';
import uuid from 'uuid';
import { getCommitmentAddressEntity, getCommitmentDirection } from 'utils/commitmentsHelper';
import { formatLocation } from 'utils';
import download from 'downloadjs';

function formatTime(date) {
  return moment.utc(date).format('YYYYMMDDTHHmmssZ').replace('+00:00', 'Z');
}

function formatTimeForOutlook(date) {
  return moment(date).format('YYYY-MM-DDTHH:mm:SSZ');
}

function getCommitmentsDesc(commitment, formatMessage, indent = '\n') {
  const commitmentsDesc = [];
  commitment.loading.commitments.forEach((commitment) => {
    const source = getCommitmentAddressEntity(commitment);
    const sourceName = source.name || 'N/A';
    const sourceAddress = formatLocation(source.city, source.state);
    const sourceData = [sourceName, sourceAddress];
    if (commitment.pig_group) {
      sourceData.push(`${formatMessage({ id: 'general.group' })} • ${commitment.pig_group.name}`);
    }
    if (commitment.barn) {
      sourceData.push(`${formatMessage({ id: 'general.barn' })} • ${commitment.barn.name}`);
    }
    const loadAt = commitment.arrive_at ? moment(commitment.arrive_at).format('MM/DD/YY hh:mm A') : 'N/A';
    sourceData.push(`${formatMessage({ id: 'general.loadAt' })} • ${loadAt}`);
    commitmentsDesc.push(sourceData.join(indent));
  });

  return commitmentsDesc.join(indent + indent + '==================>>>' + indent + indent);
}

export function prepareCalendarData(commitment, formatMessage) {
  const time = commitment.arrive_at;
  const commitmentData = commitment.loading.commitments.find(({ id }) => commitment.id === id);
  const caregiverLocationObj = getCommitmentAddressEntity(commitmentData);
  const direction = getCommitmentDirection(commitment);
  const translateKey = direction === 'in' ? 'general.incomingLoadName' : 'general.outgoingLoadName';
  return {
    title: formatMessage({ id: translateKey }, { name: commitment.loading.name }),
    description: getCommitmentsDesc(commitment, formatMessage),
    descriptionOutlook: getCommitmentsDesc(commitment, formatMessage, '<br/>'),
    descriptionIcs: getCommitmentsDesc(commitment, formatMessage, '\\n'),
    location: `${caregiverLocationObj.city}, ${caregiverLocationObj.state}`,
    time: formatTime(time),
    outlookTime: formatTimeForOutlook(time),
    isFutureEvent: moment(time).isAfter(),
  };
}

export function buildGoogleEventLink({ title, description, location, time }) {
  let calendarUrl = 'https://calendar.google.com/calendar/render';
  calendarUrl += '?action=TEMPLATE';
  calendarUrl += '&dates=' + time;
  calendarUrl += '/' + time;
  calendarUrl += '&location=' + encodeURIComponent(location);
  calendarUrl += '&text=' + encodeURIComponent(title);
  calendarUrl += '&details=' + encodeURIComponent(description);
  return calendarUrl;
}

export function buildOutlookComEventLink({ title, descriptionOutlook, location, outlookTime }) {
  let calendarUrl = 'https://outlook.live.com/owa/?rru=addevent';
  calendarUrl += '&startdt=' + outlookTime;
  calendarUrl += '&enddt=' + outlookTime;
  calendarUrl += '&subject=' + encodeURIComponent(title);
  calendarUrl += '&location=' + encodeURIComponent(location);
  calendarUrl += '&body=' + encodeURIComponent(descriptionOutlook);
  calendarUrl += '&allday=false';
  calendarUrl += '&uid=' + uuid();
  calendarUrl += '&path=/calendar/view/Month';
  return calendarUrl;
}

export function buildBlobUrl({ title, descriptionIcs, location, time }) {
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    'URL:' + document.URL,
    'DTSTART:' + time,
    'DTEND:' + time,
    'SUMMARY:' + title,
    'DESCRIPTION:' + descriptionIcs,
    'LOCATION:' + location,
    'END:VEVENT',
    'END:VCALENDAR'
  ].join('\n');
}

export function buildYahooEventLink({ title, description, location, time }) {
  let calendarUrl = 'https://calendar.yahoo.com/?v=60&view=d&type=20';
  calendarUrl += '&title=' + encodeURIComponent(title);
  calendarUrl += '&st=' + time;
  calendarUrl += '&desc=' + encodeURIComponent(description);
  calendarUrl += '&in_loc=' + encodeURIComponent(location);
  return calendarUrl;
}

export function openEventFile(calendarData, isMobileIOS) {
  const url = buildBlobUrl(calendarData);
  if (!isMobileIOS) {
    const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
    download(blob, 'calendar-event.ics', 'text/calendar');
    return;
  }
  const encodedUrl = encodeURI('data:text/calendar;charset=utf8,' + url);
  window.open(encodedUrl, '_blank');
}
