const getDevices = () => navigator.mediaDevices.enumerateDevices();

const findCapturedMicrophones = (devices) => (
  devices.filter((device) => device.kind === 'audioinput' && device.label).length
);

const initializeMicrophone = () => navigator.mediaDevices.getUserMedia({ audio: true, video: false });

export const checkMicrophoneCaptured = () => {
  return new Promise((resolve, reject) => {
    return getDevices()
      .then((devices) => {
        return findCapturedMicrophones(devices)
          ? resolve()
          : initializeMicrophone().then(resolve);
      })
      .catch(reject);
  });
};
