import React from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
// styles
import classnames from 'classnames/bind';
import styles from './NavButton.module.scss';

const cx = classnames.bind(styles);

const NavButton = ({ left = false, right = false, scrollTop = 0, ...rest }) => {
  const classes = cx('nav-button', { left, right });
  return (
    <Button light className={classes} {...rest} style={{ marginTop: scrollTop + 'px' }}>
      {left
        ? <i className="fa fa-arrow-left" />
        : <i className="fa fa-arrow-right" />
      }
    </Button>
  );
};

NavButton.propTypes = {
  left: T.bool,
  right: T.bool,
  scrollTop: T.number,
};

export default NavButton;
