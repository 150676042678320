import React from 'react';
import T from 'prop-types';
// components
import DotSeparator from 'components/DotSeparator/DotSeparator';

const InfoRow = ({ label, value, separator: Separator = DotSeparator, emptyPlaceholder = 'N/A', className }) => (
  <div className={className}>
    {label}
    {<Separator />}
    {value === 0 ? value : value || emptyPlaceholder}
  </div>
);

InfoRow.propTypes = {
  label: T.oneOfType([T.node, T.string]),
  value: T.oneOfType([T.node, T.string]),
  separator: T.oneOfType([T.func, T.string]),
  emptyPlaceholder: T.oneOfType([T.node, T.string]),
  className: T.string,
};

export default InfoRow;
