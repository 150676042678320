import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
const OPEN_PORTAL_RIGHT = 'rightToLeftPortal/OPEN_PORTAL_RIGHT';
const CLOSE_PORTAL_RIGHT = 'rightToLeftPortal/CLOSE_PORTAL_RIGHT';

const initialState = {
  component: null,
  options: {},
};

// ------------------------------------
// Actions
// ------------------------------------
export const openPortalRight = createAction(OPEN_PORTAL_RIGHT, (component, options = {}) => ({ component, options }));

export const closePortalRight = createAction(CLOSE_PORTAL_RIGHT);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [OPEN_PORTAL_RIGHT]: (state, { payload }) => payload,
  [CLOSE_PORTAL_RIGHT]: (state) => ({
    ...initialState,
    options: state.options,
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
