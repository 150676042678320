import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './RadioSelectButton.module.scss';

const cn = classnames.bind(styles);

const RadioSelectButton = ({ isActive, onClick, value, label }) => (
  <div className={cn('RadioSelectButton', { isActive })} onClick={() => onClick(value)}>
    {isActive && <i className={cn('icon', 'fa fa-check')} />}
    <span className={cn('label')}>
      {label}
    </span>
  </div>
);

RadioSelectButton.propTypes = {
  isActive: T.bool,
  onClick: T.func.isRequired,
  value: T.string.isRequired,
  label: T.node.isRequired,
};

export default RadioSelectButton;
