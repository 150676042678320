import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ResponsiveDropdown from '../../ResponsiveDropdown';
import AnalyticsPanel from '../AnalyticsPanel';
import AnalyticsPanelStats from '../AnalyticsPanelStats';
import Amchart from '../../Amchart';
import GraphLegend from '../../GraphLegend/GraphLegend';
// api
import { getAnalyticsFarmCompliance } from 'endpoints/admin/analytics';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isNull from 'lodash.isnull';
import cn from 'classnames';
// constants
import { defaultPieChartConfig } from 'components/Amchart/chartConfig';

class CompliancePanel extends Component {

  state = {
    data: {
      isLoading: false,
      rangeType: '7_days',
      farm_compliance: 0,
      farm_compliance_change: 0,
    },
  };

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data: { rangeType } } = this.state;
    if (nextProps.companyId !== this.props.companyId) this.getData(rangeType, nextProps.companyId);
  }

  getData = (type = '7_days', companyId) => {
    const company_id = (isNull(companyId) || companyId) ? companyId : this.props.companyId;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        isLoading: true,
      },
    }));
    getAnalyticsFarmCompliance({ type, company_id })
      .then((response) => {
        this.setState({
          data: {
            ...response.resource,
            isLoading: false,
            rangeType: type,
          },
        });
      })
      .catch((response) => {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            isLoading: false,
          },
        }));
        toastResponseErrors(response);
      });
  };

  renderDropdownButton = () => {
    const { isMobile } = this.context;
    return (
      <ResponsiveDropdown
        trigger={<i className="fa fa-exclamation-circle" />}
        options={[
          {
            label: <FormattedMessage id="component.analyticsPanel.complianceInfo" />,
            className: cn('dropdown-info-item', { 'dekstop': !isMobile }),
          },
        ]}
      />
    );
  };

  render() {
    const { data, data: { rangeType, farm_compliance, farm_compliance_change } } = this.state;
    const { formatMessage } = this.context;
    const showGrowthRate = rangeType !== 'today';
    const compliantText = formatMessage({ id: 'component.compliancePanel.compliant' });
    const nonCompliantText = formatMessage({ id: 'component.compliancePanel.nonCompliant' });
    const pieChartData = [
      { title: compliantText, value: farm_compliance, className: 'compliant' },
      { title: nonCompliantText, value: 100 - farm_compliance, className: 'non-compliant' },
    ];

    const pieChartConfig = {
      ...defaultPieChartConfig,
      dataProvider: pieChartData,
      defs: {
        linearGradient: [{
          id: 'non-compliant',
          gradientUnits: 'userSpaceOnUse',
          x1: '34.1793',
          x2: '107.1459',
          y1: '31.4433',
          y2: '157.8253',
          stop: [{
            offset: 0.24,
            'stop-color': '#FF5E3A',
          }, {
            offset: 0.76,
            'stop-color': '#FF8A3B',
          }, {
            offset: 1,
            'stop-color': '#FF8A3B',
          }],
        }, {
          id: 'compliant',
          gradientUnits: 'userSpaceOnUse',
          x1: '134.2251',
          x2: '134.2251',
          y1: '233.2633',
          y2: '1.4366',
          stop: [{
            offset: 0,
            'stop-color': '#2170D1',
          }, {
            offset: 1,
            'stop-color': '#32C8DF',
          }],
        }],
      },
    };
    const stats = [
      {
        label: compliantText,
        value: farm_compliance + '%',
        growthRate: showGrowthRate && {
          value: farm_compliance_change,
        },
      },
    ];
    const amChartStyle = { height: '175px' };

    return (
      <AnalyticsPanel
        titleKey="farmCompliance"
        panelData={data}
        getRangeData={this.getData}
        renderDropdownButton={this.renderDropdownButton}
      >
        <div className="chart-block">
          <Amchart style={amChartStyle} config={pieChartConfig} />
        </div>
        <div className="stats-block">
          <AnalyticsPanelStats stats={stats}>
            <div className="legend-block">
              <GraphLegend backgroundColor="#FF5E30" text={nonCompliantText} />
              <GraphLegend backgroundColor="#2170D1" text={compliantText} />
            </div>
          </AnalyticsPanelStats>
        </div>
      </AnalyticsPanel>
    );
  }
}

CompliancePanel.propTypes = {
  companyId: T.number,
};

CompliancePanel.contextTypes = {
  formatMessage: T.func.isRequired,
  isMobile: T.bool.isRequired,
};

export default CompliancePanel;
