import { fetchFromAPI } from 'utils/api';

export function getTruckingCompanies(params) {
  return fetchFromAPI('/admin/trucking_companies', { params })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      throw errors;
    });
}

export const createTruckingCompany = (resource) => {
  return fetchFromAPI('/admin/trucking_companies', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateTruckingCompany = (id, resource) => {
  return fetchFromAPI(`/admin/trucking_companies/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteTruckingRole = (company_id, user_id, role_id) => {
  return fetchFromAPI(`/admin/trucking_companies/${company_id}/users/${user_id}/roles/${role_id}`, { method: 'delete' })
    .then(({ resource }) => resource)
    .catch((response) => {
      throw response;
    });
};

export const setTruckingRole = (company_id, user_id, paramsData) => {
  return fetchFromAPI(`/admin/trucking_companies/${company_id}/users/${user_id}/roles`,
    { method: 'post', body: JSON.stringify({ resource: paramsData }) })
    .then(({ resource }) => resource)
    .catch((response) => {
      throw response;
    });
};
