import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { markAsSeen } from 'reducers/farmfeed';
import { fetchFlaggedActivities, clearFlaggedFarmFeed } from 'reducers/flaggedFarmFeed';
import { setAdvancedSearchVisibility } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import FarmFeedActivities from 'components/FarmFeed/FarmFeedActivities';
import FarmFeedNullState from 'components/FarmFeed/FarmFeedNullState';
import FarmFeedActivityPlaceholder from 'components/FarmFeed/FarmFeedActivityPlaceholder';
import Preloader from 'components/Preloader';
import ReachEndPlaceholder from 'components/ReachEndPlaceholder/ReachEndPlaceholder';
import FarmFeedExpiredMessage from './FarmFeedExpiredMessage';
import OfflineContainer from 'components/OfflineScreen/OfflineContainer';
// utils
import times from 'lodash.times';

const FlaggedFarmFeed = ({
  activities,
  fetchFlaggedActivities,
  isLoaded,
  isLoading,
  markAsSeen,
  meta,
  reqParams,
  setAdvancedSearchVisibility,
  clearFlaggedFarmFeed,
  setBreadcrumbs,
  currentUser,
  location: { pathname },
}) => {
  const isNullState = !isLoading && !activities.length;
  const hasMoreItems = activities.length < meta.total;
  const { current_company, roles_map } = currentUser;
  const isFeedNotAvailable = current_company.is_expired && !roles_map.super_admin;

  useEffect(() => {
    fetchFlaggedActivities();
    setBreadcrumbs([
      {
        label: <FormattedMessage id="component.sidebar.flaggedActivities" />,
        path: pathname,
        useLabelAsMobileTitle: true,
      },
    ]);

    return () => {
      clearFlaggedFarmFeed();
      setAdvancedSearchVisibility(false);
    };
  }, []);

  const handleLoadMoreActivities = () => {
    fetchFlaggedActivities({ ...reqParams, page: reqParams.page + 1 }, true);
  };

  return (
    <OfflineContainer>
      {isFeedNotAvailable && <FarmFeedExpiredMessage />}

      <div className="farmfeed-page">
        <div className="feed-container">
          <Preloader isActive={isLoading} />
          {isLoading && !isLoaded && (
            <div className="animated fadeIn">
              {times(3, (i) => <FarmFeedActivityPlaceholder key={i} />)}
            </div>
          )}

          {isNullState
            ? <FarmFeedNullState />
            : (
              <FarmFeedActivities
                activities={activities}
                loadMoreActivities={handleLoadMoreActivities}
                markAsSeen={markAsSeen}
                isLoading={isLoading}
                meta={meta}
                currentUser={currentUser}
              />
            )}
        </div>

        {!hasMoreItems && <ReachEndPlaceholder />}
      </div>
    </OfflineContainer>
  );
};

FlaggedFarmFeed.propTypes = {
  activities: T.array.isRequired,
  isLoading: T.bool.isRequired,
  isLoaded: T.bool.isRequired,
  fetchFlaggedActivities: T.func.isRequired,
  meta: T.object,
  reqParams: T.shape({ page: T.number }),
  markAsSeen: T.func.isRequired,
  location: T.object.isRequired,
  setAdvancedSearchVisibility: T.func.isRequired,
  clearFlaggedFarmFeed: T.func.isRequired,
  currentUser: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    activities: state.flaggedFarmFeed.resources,
    isLoading: state.flaggedFarmFeed.isLoading,
    isLoaded: state.flaggedFarmFeed.isLoaded,
    meta: state.flaggedFarmFeed.meta,
    reqParams: state.flaggedFarmFeed.params,
  }), {
    setBreadcrumbs,
    markAsSeen,
    clearFlaggedFarmFeed,
    fetchFlaggedActivities,
    setAdvancedSearchVisibility,
  }
)(FlaggedFarmFeed);
