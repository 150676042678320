import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Translation from 'components/Translation';
import Collapse from 'components/Collapse/Collapse';
import CollapseTitle from './CollapseTitle';
import Line from 'components/Line';
// requests
import { getCheckupMigrations } from 'endpoints/dailyCheckups';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { renderMentionsText } from 'utils';
// styles
import styles from './SectionCollapse.module.scss';

const cn = classnames.bind(styles);

const PigMigrationsCollapse = ({
  checkupId,
  className,
  comment,
  isEdited,
  itemsCount,
  showCheckup,
  titleClassName,
}) => {
  const [{ isLoading, isCollapseOpened, isLoaded, migrations }, setState] = useState({
    isLoaded: false,
    isLoading: false,
    isCollapseOpened: false,
    migrations: [],
  });

  if (!comment && !itemsCount) return null;

  const toggleCollapse = (isCollapseOpened) => {
    if (isLoaded) {
      setState((prevState) => ({ ...prevState, isCollapseOpened }));
      return;
    }

    setState((prevState) => ({ ...prevState, isCollapseOpened, isLoading: true }));
    getCheckupMigrations(checkupId)
      .then((migrations) => {
        setState((prevState) => ({ ...prevState, isLoading: false, isLoaded: true, migrations }));
      })
      .catch((response) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        toastResponseErrors(response);
      });
  };

  return ((comment || showCheckup) && (
    <Collapse
      className={cn(className, 'section-collapse', { 'highlighted': comment && !isCollapseOpened })}
      titleClassName={titleClassName}
      title={(
        <CollapseTitle hasEditedBadge={isEdited} hasNotesBadge={Boolean(comment)} hasTriggerIcon={Boolean(comment)}>
          <FormattedMessage tagName="b" id="general.pigMovementsCount" values={{ count: itemsCount }} />
        </CollapseTitle>
      )}
      onToggle={toggleCollapse}
      isLoading={isLoading}
    >
      {migrations.map(({ id, amount }, index) => (
        <Line
          key={id}
          boldedLabel
          alignLeft
          hasTopBorder={!index}
          lightBlue={amount > 0}
          lightOrange={amount <= 0}
          label={Math.abs(amount) + ' '}
          value={<FormattedMessage id={`general.${amount > 0 ? 'pigsAdded' : 'pigsRemoved'}`} />}
        />
      ))}
      {comment && (
        <>
          <Line almostWhite bolded hasTopBorder={!migrations.length}>
            <FormattedMessage id="general.notes" />
          </Line>
          <Line lightYellow>
            <Translation text={comment}>{renderMentionsText(comment)}</Translation>
          </Line>
        </>
      )}
    </Collapse>
  ));
};

PigMigrationsCollapse.propTypes = {
  className: T.string,
  titleClassName: T.string,
  checkupId: T.oneOfType([T.number, T.string]).isRequired,
  itemsCount: T.number,
  comment: T.string,
  isEdited: T.bool,
  showCheckup: T.bool,
};

export default PigMigrationsCollapse;
