import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// styles
import styles from './LastCommentInfo.module.scss';

const cn = classnames.bind(styles);

const editReasonsKeys = ['symptoms_updated', 'deaths_updated', 'treatments_updated', 'pig_migrations_updated',
  'diagnoses_reported', 'diagnoses_updated', 'assets_removed', 'checkup_comment_removed',
  'treatments_efficacies_updated', 'deaths_comment_removed', 'symptoms_comment_removed', 'treatments_comment_removed',
  'pig_migrations_comment_removed'];

function getEditReasons(activity) {
  const { daily_checkup, comments, user } = activity;
  const activityCreator = user || {};
  if (activity.variety.includes('no_checkup')) return [];
  const newestComment = (comments || []).slice(0, 1);
  if (!daily_checkup) return newestComment;

  const editReasons = daily_checkup.dc_logs.concat(newestComment);
  const filteredReasons = editReasons.filter(({ variety, user }) => {
    if (!user || (activityCreator.id === user.id)) return false;
    if (variety) {
      return variety.map((vr) => editReasonsKeys.includes(vr)).some((item) => Boolean(item));
    }
    return true;
  });
  return filteredReasons.sort((curr, next) => (
    Number(moment(curr.created_at) < moment(next.created_at))
  ));
}

function getValues(lastComment, activity, currentUser) {
  const { pig_group, farm, company } = activity;
  const { user, created_at, variety } = lastComment;
  if (!variety) {
    const _company = company || currentUser.current_company;
    const commentedOnFarm = farm
      ? <Link to={`/barnsheets/farms/${farm.id}`}>{farm.name}</Link>
      : <Link to={`/barnsheets/companies/${_company.id}`}>{_company.name}</Link>;

    return {
      user: currentUser.roles_map.admin
        ? <Link to={`/admin/users/${user.id}`}>{user.full_name}</Link>
        : <strong>{user.full_name}</strong>,
      entity: commentedOnFarm,
      time: moment(created_at).format('MMM D'),
    };
  }

  const commentOnPigGroup = currentUser.roles_map.admin
    ? <Link to={`/groups/${pig_group.id}`}>{pig_group.name}</Link>
    : <strong>{pig_group.name}</strong>;

  return {
    user: currentUser.roles_map.admin
      ? <Link to={`/admin/users/${user.id}`}>{user.full_name}</Link>
      : <strong>{user.full_name}</strong>,
    action: <FormattedMessage id={`component.checkupActivity.${variety[variety.length - 1]}`} />,
    entity: commentOnPigGroup,
  };
}

const LastCommentInfo = ({ activity, currentUser }) => {

  const reasons = getEditReasons(activity);

  if (!reasons.length) return null;

  const lastComment = reasons[0];
  const idKey = !lastComment.variety ? 'userCommented' : 'userUpdated';
  const values = getValues(lastComment, activity, currentUser);

  return (
    <div className={cn('last-comment-info')}>
      <FormattedMessage
        id={`container.farmfeed.row.${idKey}`}
        values={values}
      />
    </div>
  );
};

LastCommentInfo.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object,
};

export default LastCommentInfo;
