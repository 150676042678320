import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
import { compose, withProps } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import Link from 'react-router/lib/Link';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import RolesLabel from 'components/RolesLabel/RolesLabel';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Avatar from 'components/Avatar/Avatar';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import sum from 'lodash.sum';
// translate
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

class MyProfileCompanyRoles extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  renderCompanyNameColumn = ({ name }) => (
    <CustomColumn flexRow>
      <Avatar className="mr-10" type="company" avatarSize={35} iconSize={16} />
      <span className="semibold">{name}</span>
    </CustomColumn>
  );

  renderUserRoleColumn = ({ is_owner, is_manager, is_caretaker, is_common, is_vet }) => (
    <CustomColumn flexRow>
      <span className="hide-for-large semibold">
        <FormattedMessage id="general.companyRoles" />&nbsp;•&nbsp;
      </span>
      <RolesLabel
        roles={[
          { name: <FormattedMessage id="general.role.primary" />, value: is_owner, shortcut: 'p' },
          { name: <FormattedMessage id="general.role.farmManager" />, value: is_manager, shortcut: 'm' },
          { name: <FormattedMessage id="general.role.farmCaregiver" />, value: is_caretaker, shortcut: 'c' },
          { name: <FormattedMessage id="general.role.common" />, value: is_common, shortcut: 'u' },
          { name: <FormattedMessage id="general.role.veterinarian" />, value: is_vet, shortcut: 'v' },
        ]}
      />
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id }) => (
    <div className="collapsible-value button-column">
      <Link to={`/admin/companies/${id}/user-roles`} className="button small light-gray wide">
        <FormattedMessage id="general.button.edit" />
      </Link>
    </div>
  );

  renderExpandable = ({ id }) => (
    <div>
      <Link to={`/admin/companies/${id}/user-roles`} className="button small light">
        <i className="fa fa-pencil mr-5" /><FormattedMessage id="general.button.edit" />
      </Link>
    </div>
  );

  render() {
    const { resources, meta: { total, stats = {} }, isLoading, reqParams: { page, per_page, role, search, sort },
      isAuthUserAdmin, onSearchChange, onPageChange, onPerPageChange, onSortChange } = this.props;
    const { isTablet } = this.context;
    const statsSum = sum(Object.values(stats));
    const tableFilters = [
      { label: <FormattedMessage id="general.allRoles" />, value: '', count: statsSum },
      { label: <FormattedMessage id="general.role.common" />, value: 'common_user' },
      { label: <FormattedMessage id="general.role.primary" />, value: 'owner' },
      { label: <FormattedMessage id="general.role.caregiver" />, value: 'caretaker' },
      { label: <FormattedMessage id="general.role.farmManager" />, value: 'manager' },
      { label: <FormattedMessage id="general.role.veterinarian" />, value: 'vet' },
    ];

    const isEmptyTable = !resources.length && !role && !search;

    const columns = [
      { label: <FormattedMessage id="general.companyName" />, flex: '1 1 220px',
        renderer: this.renderCompanyNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.companyRoles" />, flex: '1 1 220px',
        renderer: this.renderUserRoleColumn, sortKey: 'role' },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 150px',
        renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true, hide: !isAuthUserAdmin,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true,
        hide: !isTablet || !isAuthUserAdmin, hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.companyRoles" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              onFilterChange={this.onFilterChange}
              stats={stats}
              activeFilter={role}
              className="ph-10"
            />
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet && isAuthUserAdmin}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="myCompanyRoles"
            rowKey="role_id" // todo: id is not unique for roles table now
          />

          <NothingBox
            itemsName="roles"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={role}
          >
            <FormattedMessage tagName="h1" id="container.myProfile.companyRoles.empty" />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

MyProfileCompanyRoles.propTypes = {
  fetchData: T.func.isRequired,
  isAuthUserAdmin: T.bool.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
};

MyProfileCompanyRoles.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.myCompanyRoles.resources,
      reqParams: state.dataTable.myCompanyRoles.params,
      meta: state.dataTable.myCompanyRoles.meta,
      isLoading: state.dataTable.myCompanyRoles.isLoading,
      isAuthUserAdmin: state.auth.user.roles_map.admin,
      currentUserId: state.auth.user.id,
    }), { fetchData },
  ),
  withProps(({ currentUserId: id, fetchData }) => ({
    fetchData: (query) => fetchData(`/admin/users/${id}/company_roles/profile_index`, 'myCompanyRoles', query),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(MyProfileCompanyRoles);
