import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { updateActivity } from 'reducers/farmfeed';
// components
import { FormattedMessage } from 'react-intl';
import CircularProgressbar from 'react-circular-progressbar';
// utils
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
// styles
import './VideoPreviewOverlay.scss';
import './CircularProgressbar.scss';

const getWrapperClass = (size) => {
  if (size < 30) return 'small';
  if (size < 40) return 'medium';
  return 'default';
};

class VideoPreviewOverlay extends Component {

  state = {
    showProgress: false,
    fileUploaded: false,
    percent: 0,
  };

  timerImitateUpload = null;

  timerCheckAssetStatus = null;

  imitateLoader = () => {
    let i = 1;
    const go = () => {
      if (this._isMounted) {
        this.setState((prevState) => ({ percent: prevState.percent + 7 }));
        if (i < 14) setTimeout(go, 800);
        i += 1;
      }
    };

    this.timerImitateUpload = setTimeout(go, 800);
  };

  getActivity = () => {
    const { activity: { id }, asset, updateActivity } = this.props;
    const statusesForUploadedAsset = ['complete', 'error', 'canceled'];

    fetchFromAPI(`/activities/${id}`)
      .then(({ resource }) => {
        const item = resource.assets.find(({ id }) => asset.id === id);
        if (statusesForUploadedAsset.includes(item.progress)) {
          updateActivity(resource);
          this.setState(() => ({ showProgress: false, fileUploaded: true }));
        }
      })
      .catch((response) => {
        toastResponseErrors(response);
        this.setState({ showProgress: false });
      });
  };

  checkAssetStatus = (queryCount) => {
    const { fileUploaded } = this.state;
    const interval = queryCount < 3 ? 10000 : 20000;

    this.getActivity();
    if (queryCount === 5 || fileUploaded) {
      this.setState({ showProgress: false });
      clearTimeout(this.timerCheckAssetStatus);
    } else {
      this.timerCheckAssetStatus = setTimeout(() => this.checkAssetStatus(queryCount + 1), interval);
    }
  };

  startShowLoading = () => {
    this.setState({ showProgress: true });
    this.imitateLoader();
    this.checkAssetStatus(0);
  };

  componentDidMount() {
    const { asset } = this.props;
    const statusesForImitate = ['processing', 'submitted', 'created'];
    this._isMounted = true;

    if (!isEmpty(asset)) {
      if (statusesForImitate.includes(asset.progress)) {
        this.startShowLoading();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timerImitateUpload);
    clearTimeout(this.timerCheckAssetStatus);
  }

  render() {
    const { size = 40, simple, className = '', noBackdrop, showText } = this.props;
    const { showProgress, percent } = this.state;
    const iconStyle = { fontSize: size + 'px' };

    return (
      <div className={cn('VideoPreviewOverlay', className, { 'no-backdrop': noBackdrop })}>
        {showProgress && (
          <div className="process-loader">
            <CircularProgressbar className="video-progress" strokeWidth={5} percentage={percent} />
              {showText && (
                <div className="loader-caption">
                  <div className="process-header"><FormattedMessage id="container.farmfeed.videoConverting" /></div>
                  <div className="process-tip"><FormattedMessage id="container.farmfeed.videoAvailable" /></div>
                </div>
              )}
          </div>
        )}
        {!showProgress && (
          <div className={cn(`video-icon ${getWrapperClass(Number(size))}`, { simple })}>
            <i className="fa fa-triangle-right" style={iconStyle} />
          </div>
        )}
      </div>
    );
  }
}

VideoPreviewOverlay.defaultProps = {
  asset: {},
};

VideoPreviewOverlay.propTypes = {
  asset: T.object,
  activity: T.object,
  size: T.oneOfType([T.number, T.string]),
  simple: T.bool,
  className: T.string,
  noBackdrop: T.bool,
  showText: T.bool,
  updateActivity: T.func,
};

export default connect(null, { updateActivity })(VideoPreviewOverlay);
