import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { fetchShareholderGroup, setSharedholderGroup } from 'reducers/admin/shareholderGroups/groupShow';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import GroupProfileHeader from 'components/ProfileHeader/ShareholderGroupProfileHeader/ShareholderGroupProfileHeader';
import RolesManageFixedHeader from 'components/Admin/RolesManageFixedHeader/RolesManageFixedHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import TableFilter from 'components/TableFilter';
import PaginationWrapper from 'components/PaginationWrapper';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import NothingBox from 'components/NothingBox';
import CircleCheckbox from 'components/CircleCheckbox';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
import { showToastrMessage } from 'utils';
// shareholder groups API
import { createFarmRole, revokeFarmRole, revokeAllFarmRoles } from 'endpoints/admin/shareholderGroups';
// styles
import styles from '../RolesManage.module.scss';

const cn = classnames.bind(styles);

const tableFilters = [
  { label: <FormattedMessage id="general.allFarms" />, value: '' },
  { label: <FormattedMessage id="general.activeFarms" />, value: 'active' },
  { label: <FormattedMessage id="general.disabledFarms" />, value: 'disabled' },
];

const headerColumns = [
  { flex: '1 1 120px', name: <FormattedMessage id="component.dataTable.headers.farmName" />, sortField: 'name' },
  { flex: '1 1 120px', name: <FormattedMessage id="general.assignFarm" />, sortField: 'assignment' },
];

const labels = {
  buttons: {
    revokeAllRoles: <FormattedMessage id="general.button.revokeAllRoles" />,
    done: <FormattedMessage id="general.button.IamDone" />,
  },
  roles: {
    managementCo: <FormattedMessage id="general.role.managementCo" />,
  },
};

class ShareholderGroupsFarmsManage extends Component {

  componentDidMount() {
    const { fetchData, fetchShareholderGroup, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
    fetchShareholderGroup();
  }

  handleRoleChanged = (farm) => (e) => {
    if (e.target.checked) return this.createFarmRole(farm);
    return this.removeUserFarmRole(farm);
  };

  createFarmRole = (farm) => {
    const { setSharedholderGroup, params: { id }, setDataItem } = this.props;
    createFarmRole(id, farm.id)
      .then((resource) => {
        setDataItem({ ...farm, assignment: true });
        setSharedholderGroup(resource);
      })
      .catch(toastResponseErrors);
  };

  removeUserFarmRole = (farm) => {
    const { setSharedholderGroup, params: { id }, setDataItem } = this.props;
    revokeFarmRole(id, farm.id)
      .then((resource) => {
        setDataItem({ ...farm, assignment: false });
        setSharedholderGroup(resource);
      })
      .catch(toastResponseErrors);
  };

  revokeAllAccess = () => {
    const { setSharedholderGroup, params: { id }, fetchData } = this.props;
    revokeAllFarmRoles(id)
      .then((resource) => {
        showToastrMessage('component.toastr.roles.revoked');
        setSharedholderGroup(resource);
        fetchData();
      });
  };

  render() {
    const { groupFarms, isLoading, meta: { total }, reqParams: { page, per_page, status, search, sort }, isGroupLoading,
      group, params: { id }, onSearchChange, onSortChange, onPageChange, onPerPageChange, onStatusChange } = this.props;

    return (
      <div className={cn('roles-manage')}>
        <GroupProfileHeader isSticky group={group} isLoading={isGroupLoading}>
          {group.farms_present && (
            <Button light className="mr-10 show-for-large" onClick={this.revokeAllAccess}>
              <i className="fa fa-times-circle mr-5" />
              {labels.buttons.revokeAllRoles}
            </Button>
          )}
          <Link to={`/admin/shareholder-groups/${id}/farm-roles`} className="button primary wider show-for-large">
            {labels.buttons.done}
          </Link>
        </GroupProfileHeader>

        <RolesManageFixedHeader
          className="show-for-large"
          panelTitle="Farm Assignments"
          isEmptyTable={!groupFarms.length}
          search={search}
          onSearchChange={onSearchChange}
          sort={sort}
          onSortChange={onSortChange}
          headerColumns={headerColumns}
        />

        <section className={cn('roles-manage-section')}>
          <div className="small-12 column">
            <Panel>
              <Panel.Heading title={<FormattedMessage id="general.farmAssignments" />} className="hide-for-large">
                <SearchBox initialValue={search} onChange={onSearchChange} />
              </Panel.Heading>

              <Panel.Body className={cn('roles-manage-body')}>
                <TableFilter
                  filters={tableFilters}
                  onFilterChange={onStatusChange}
                  activeFilter={status}
                  className="hide-for-large"
                >
                  <Button className="no-margin" darkGrey onClick={this.revokeAllAccess}>
                    {labels.buttons.revokeAllRoles}
                  </Button>
                </TableFilter>

                <Preloader isActive={isLoading} />

                <PaginationWrapper
                  onlyBottom
                  totalItems={total}
                  currentPage={page}
                  onPageChange={onPageChange}
                  perPage={per_page}
                  onPerPageChange={onPerPageChange}
                >
                  <div className="CompanyFarmsRolesManageTable">
                    <div className="flex-data-table">

                      {groupFarms.map((farm, idx) => (
                        <div className="table-item" key={idx}>
                          {/* Farm Name */}
                          <div className="table-item-block column-farm-name">
                            <div className="collapsible-value">
                              <div className="value-amount title">
                                <FarmTitleBox name={farm.name} type={farm.farm_type} />
                              </div>
                            </div>
                          </div>
                          {/* Assign Farm */}
                          <div
                            className={cn('table-item-block column-roles', { 'checked': farm.assignment })}
                          >
                            <div className="collapsible-value with-checkbox">
                              <div className="value-amount flex align-center">
                                <CircleCheckbox
                                  className="roles-manage"
                                  label={<span className="semibold hide-for-large">{labels.roles.managementCo}</span>}
                                  name="manager"
                                  onChange={this.handleRoleChanged(farm)}
                                  checked={farm.assignment}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <NothingBox
                      itemsName="roles"
                      display={!groupFarms.length}
                      isLoading={isLoading}
                      search={search}
                      filter={status}
                    >
                      <FormattedMessage tagName="h1" id="general.nothingBox.noRoles" />
                    </NothingBox>
                  </div>
                </PaginationWrapper>
              </Panel.Body>
            </Panel>
          </div>
        </section>

        <StickyFooter className="hide-for-large">
          <Link to={`/admin/shareholder-groups/${id}/farm-roles`} className="button primary big-button">
            {labels.buttons.done}
          </Link>
        </StickyFooter>
      </div>
    );
  }
}

ShareholderGroupsFarmsManage.propTypes = {
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  fetchShareholderGroup: T.func.isRequired,
  setSharedholderGroup: T.func.isRequired,
  params: T.object.isRequired,
  group: T.object.isRequired,
  groupFarms: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isGroupLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
};

const enhance = compose(
  connect((state) => ({
    groupFarms: state.dataTable.adminShareholderGroupFarmsManage.resources,
    isLoading: state.dataTable.adminShareholderGroupFarmsManage.isLoading,
    reqParams: state.dataTable.adminShareholderGroupFarmsManage.params,
    meta: state.dataTable.adminShareholderGroupFarmsManage.meta,
    group: state.shareholderGroups.groupShow.data,
    isGroupLoading: state.shareholderGroups.groupShow.isLoading,
  }), (dispatch, { params: { id } }) => ({
    fetchData: (query) => dispatch(fetchData(`/admin/shareholder_groups/${id}/farms`,
      'adminShareholderGroupFarmsManage', query)),
    setDataItem: (data) => dispatch(setDataItem(data, 'adminShareholderGroupFarmsManage')),
    fetchShareholderGroup: () => dispatch(fetchShareholderGroup(id)),
    setSharedholderGroup: (data) => dispatch(setSharedholderGroup(data)),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(ShareholderGroupsFarmsManage);
