import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './GroupActivityBox.module.scss';

const cn = classnames.bind(styles);

const GroupActivityBox = ({ resources, children, className }) => (
  <div className={cn('group-activity-box', className)}>
    {resources.map((resource, index) => (!resource.hide && (
      <div key={index} className={cn('group-activity-line')}>
        <div>{resource.label}</div>
        <div className="semibold">{resource.value}</div>
      </div>
    )
    ))}
    {children}
  </div>
);

GroupActivityBox.defaultProps = {
  resources: [],
  className: '',
};

GroupActivityBox.propTypes = {
  resources: T.array,
  className: T.string,
  children: T.any,
};

export default GroupActivityBox;
