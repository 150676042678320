import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import VerticalAligner from 'components/VerticalAligner';
import { ThreeDotsBtn } from 'components/Button';
import Avatar from 'components/Avatar/Avatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Preloader from 'components/Preloader';
import ProfilePlaceholder from '../ProfilePlaceholder';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// utils
import isNumber from 'lodash.isnumber';
import cn from 'classnames';
import { formatLocation } from 'utils';
// styles
import './CompanyProfileHeader.scss';

const CompanyProfileHeader = ({
  avatarType = 'company',
  actualCompanyId,
  company,
  className,
  children,
  currentUser,
  dropdownOptions,
  hasLocation,
  isLoading,
  isSticky,
  statusBadge,
}) => {
  const { openDropdown } = useDropdownActions();

  return (
    <div className={cn('CompanyProfileHeader', { isSticky, [className]: !!className })}>
      <Preloader isActive={isLoading} />

      <ProfilePlaceholder isActive={isLoading && company.id !== actualCompanyId}>
        <div className="company-info-block">
          <Avatar type={avatarType} avatarSize={50} iconSize={24} />
          <div className="company-information">
            <VerticalAligner className="vertical-aligner">
              <h1 className="lighter inline-block company-name">{company.name}</h1>
              {statusBadge || <StatusBadge status={company.status} />}
            </VerticalAligner>
            {isNumber(company.primary_contact_id) && (
              <FormattedMessage
                tagName="div"
                id="general.primaryContactWithName"
                values={{
                  name: currentUser?.roles_map.admin
                    ? (
                      <Link to={`/admin/users/${company.primary_contact_id}`}>
                        {company.primary_contact_name}
                      </Link>
                    )
                    : <span>{company.primary_contact_name}</span>
                }}
              />
            )}
            {hasLocation && formatLocation(company.city, company.state)}
          </div>
        </div>
      </ProfilePlaceholder>

      <div className="company-actions">
        {children}
        {!!dropdownOptions?.filter(({ hide }) => !hide).length &&
          <ThreeDotsBtn dataName="icon-btn" onClick={(e) => openDropdown(e, dropdownOptions)} className="ml-10" />}
      </div>
    </div>
  );
};

CompanyProfileHeader.propTypes = {
  company: T.object.isRequired,
  isLoading: T.bool,
  actualCompanyId: T.number,
  currentUser: T.object,
  className: T.string,
  isSticky: T.bool,
  children: T.any,
  dropdownOptions: T.array,
  hasLocation: T.bool,
  statusBadge: T.node,
  avatarType: T.oneOf(['company', 'trucking-company', 'packing-company', 'packing-plant']),
};

export default connect((state) => ({
  currentUser: state.auth.user,
}))(CompanyProfileHeader);
