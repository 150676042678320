import React from 'react';
import T from 'prop-types';
// styles
import cn from 'classnames';
import './Preloader.scss';

const Preloader = ({ isActive = false, style, className = '', isCentered = false, isSmall = false }) => (
  <div
    className={cn('preloader', className, {
      'is-active': isActive,
      'is-centered': isCentered,
      'is-small': isSmall,
    })}
    style={style}
  />
);

Preloader.propTypes = {
  isActive: T.bool,
  isCentered: T.bool,
  isSmall: T.bool,
  className: T.string,
  style: T.object,
};

export default Preloader;
