import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import FarmFeedRowComment from './FarmFeedRowComment';
// styles
import './FarmFeedRowComments.scss';

const FarmFeedRowComments = ({ activity }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { comments, company } = activity;
  const showCollapser = comments.length > 3;
  const commentsList = isCollapsed ? comments.slice(0, 3) : comments;

  const toggleComment = (e) => {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  return !!comments.length && (
    <div className="FarmFeedRowComments">
      {commentsList.map((comment) => (
        <FarmFeedRowComment
          key={comment.id}
          comment={comment}
          activity={activity}
          company={company}
        />
      ))}
      {showCollapser && (
        <div className="show-more-comments">
          <span className="comments-collapser" onClick={toggleComment}>
            <FormattedMessage id={`component.comments.${isCollapsed ? 'show' : 'hide'}Comments`} />
          </span>
          <FormattedMessage
            id="component.pagination.pageOfTotal"
            values={{ page: commentsList.length, total: comments.length }}
          />
        </div>
      )}
    </div>
  );
};

FarmFeedRowComments.propTypes = {
  activity: T.object.isRequired,
};

export default FarmFeedRowComments;
