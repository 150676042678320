import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import Subnavigation from 'components/Subnavigation';
// utils
import { FormattedMessage } from 'react-intl';
// styles
import './MetaHome.scss';
import OfflineScreen from '../../OfflineScreen';

// TODO: remove comments when API will be ready

const subNavLinks = [
  {
    label: <FormattedMessage id="general.apiMonitoring.pushResults" />,
    link: '/monitoring/metafarms/pushresults',
  },
  // {
  //   label: <FormattedMessage id="general.apiMonitoring.pullResults" />,
  //   link: '/monitoring/metafarms/pullresults',
  // },
];

const MetaHome = ({ isOnline, children }) => (
  <div className="BarnsheetsHome clearfix">
    <Subnavigation isSticky links={subNavLinks} className="small-12 column" />
    {isOnline ? (
      <div className="small-12 column">{children}</div>
    ) : (
      <OfflineScreen />
    )}
  </div>
);

MetaHome.propTypes = {
  children: T.object.isRequired,
  isOnline: T.bool,
};

export default connect((state) => ({
  isOnline: state.network.isOnline,
  active: state.dataTable.metaFarms.meta.active,
}))(MetaHome);
