import React from 'react';
import T from 'prop-types';
// styles
import classnames from 'classnames/bind';
import styles from './ProfileHeaderStats.module.scss';

const cn = classnames.bind(styles);

const ProfileHeaderStats = ({ stats, className }) => (
  <div className={cn('header-stats', className)}>
    {stats.map(({ label, value, renderer, unit }, index) => (
      <div key={index} className={cn('stat-box')}>
        <div className={cn('stat-label')}>{label}</div>
        <div className={cn('stat-value')}>
          {value}
          {unit && (
            <small>{unit}</small>
          )}
        </div>
        {renderer && renderer()}
      </div>
    ))}
  </div>
);

ProfileHeaderStats.propTypes = {
  stats: T.array,
  className: T.string,
};

ProfileHeaderStats.defaultProps = {
  stats: [],
  className: '',
};

export default ProfileHeaderStats;
