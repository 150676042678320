import React from 'react';
import { FormattedMessage } from 'react-intl';

export const getGroupStatus = ({
  status,
  disabled_due_inventory,
  current_checkup_id,
  current_checkup_submitted,
}) => {
  if (disabled_due_inventory) return {
    status: 'reconcile',
    label: <FormattedMessage id="container.checkupIndex.row.disabledForReconcile" />,
  };
  if (['scheduled_delivery', 'awaiting_delivery'].includes(status)) return {
    status: 'empty',
  };
  if (['request_close', 'pending_close'].includes(status)) return {
    status,
    label: status === 'request_close' ? <FormattedMessage id="general.status.upToDate" /> : '',
  };
  if (!current_checkup_id) return {
    status: 'new',
    label: <FormattedMessage id="general.status.pending" />,
  };
  if (current_checkup_submitted) return {
    status: 'complete',
    label: <FormattedMessage id="general.status.upToDate" />,
  };
  return {
    status: 'incomplete',
    label: <FormattedMessage id="general.status.inProgress" />,
  };
};

export const getStatusIcon = (status) => {
  switch (status) {
    case 'new':
      return <div className="red-marker" />;
    case 'incomplete':
      return <div className="fa fa-check-circle status-icon" />;
    case 'request_close':
    case 'complete':
      return <div className="fa fa-checkbox-marked-circle status-icon blue" />;
    case 'reconcile':
    case 'pending_close':
    case 'disabled':
      return <div className="fa fa-lock status-icon" />;
    case 'empty':
      return <div className="fa fa-no-pigs status-icon" />;
    default:
      return null;
  }
};
