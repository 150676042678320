import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ReactSelect from 'components/Select/ReactSelect';
import CommentBox from 'components/CommentBox';
import CircleCheckbox from 'components/CircleCheckbox';
// utils
import classnames from 'classnames/bind';
import { diagnosesTypeOptions } from 'utils/diagnosesHelper';
// styles
import styles from './DiseaseField.module.scss';

const cn = classnames.bind(styles);

const DiseaseField = ({ disease, onChange, onUpdate, onRemove, isRemovable }) => (
  <div className={cn('disease-container')}>
    <div className={cn('choose-disease-field')}>
      <div className={cn('disease-name')} onClick={onChange}>
        {disease.name}
        <span className={cn('disease-icon-box')}>
          <i className={cn('fa fa-angle-right-btb', 'icon')} />
        </span>
      </div>
      {isRemovable && (
        <div className={cn('remove-icon-block')}>
          <i onClick={onRemove} className={cn('fa fa-trash-o', 'remove-icon')} />
        </div>
      )}
    </div>
    <div className={cn('disease-type-field')}>
      <div className={cn('disease-type-label')}>
        <FormattedMessage id="component.mobileDiseasesSelector.diseaseType" />
      </div>
      <ReactSelect
        onChange={({ value }) => onUpdate({ diagnosis_type: value })}
        noInputMargin
        value={disease.diagnosis_type}
        options={diagnosesTypeOptions}
        labelKey="label"
        valueKey="value"
        placeholder={<FormattedMessage id="component.diagnoseForm.chooseDiagnosisType" />}
      />
    </div>
    <div className={cn('notify-users')}>
      <CircleCheckbox
        label={<FormattedMessage id="component.diagnoseForm.sendAlert" />}
        checked={disease.notify_all}
        onChange={() => onUpdate({ notify_all: !disease.notify_all })}
      />
    </div>
    <div className={cn('note-field')}>
      <CommentBox
        className={cn('disease-note')}
        onValueChange={(note) => onUpdate({ note })}
        value={disease.note || ''}
        isOpen={Boolean(disease.note)}
      />
    </div>
    <div className="divider">
      <hr />
    </div>
  </div>
);

DiseaseField.propTypes = {
  disease: T.object.isRequired,
  onChange: T.func.isRequired,
  onUpdate: T.func.isRequired,
  onRemove: T.func.isRequired,
  isRemovable: T.bool,
};

export default DiseaseField;
