import React from 'react';
import T from 'prop-types';
import './FarmFeedRowSegment.scss';

const FarmFeedRowSegment = ({ children }) => (
  <div className="FarmFeedRowSegment">
    {children}
  </div>
);

FarmFeedRowSegment.propTypes = {
  children: T.any,
};

export default FarmFeedRowSegment;
