import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { fetchData } from 'reducers/dataTable';
// component
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import Button from 'components/Button';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import UserAvatar from 'components/UserAvatar';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import RolesLabel from 'components/RolesLabel/RolesLabel';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import isEmpty from 'lodash.isempty';
import sum from 'lodash.sum';
import classnames from 'classnames';
import { showToastrMessage } from 'utils';

const labels = {
  allAssignments: <FormattedMessage id="general.allAssignments" />,
  farmOwner: <FormattedMessage id="general.role.farmOwner" />,
  farmManager: <FormattedMessage id="general.role.farmManager" />,
  farmCaregiver: <FormattedMessage id="general.role.farmCaregiver" />,
  veterinarian: <FormattedMessage id="general.role.veterinarian" />,
  buttons: {
    revokeAccess: <FormattedMessage id="general.button.revokeAccess" />,
    editUser: <FormattedMessage id="general.button.editUser" />,
  },
  dataTable: {
    userName: <FormattedMessage id="component.dataTable.headers.userName" />,
    roles: <FormattedMessage id="component.dataTable.headers.roles" />,
    status: <FormattedMessage id="component.dataTable.headers.status" />,
  },
  userRoles: <FormattedMessage id="general.userRoles" />,
};

class FarmUserRoles extends Component {

  state = {
    isRoleRemoving: false,
  };

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  onRevokeRole = ({ is_owner, is_manager, is_caretaker, is_vet, id: user_id }) => {
    const { fetchData, reqParams, params: { id } } = this.props;
    let role;
    if (is_owner) role = 'owner';
    if (is_manager) role = 'manager';
    if (is_caretaker) role = 'caretaker';
    if (is_vet) role = 'vet';

    this.setState({ isRoleRemoving: true });
    fetchFromAPI(`/admin/farms/${id}/user_roles`, { method: 'delete', params: { user_id, name: role } })
      .then(() => {
        showToastrMessage('component.toastr.access.revoked');
        fetchData({ ...reqParams });
        this.setState({ isRoleRemoving: false });
      })
      .catch((response) => {
        this.setState({ isRoleRemoving: false });
        return toastResponseErrors(response);
      });
  };

  renderUserColumn = (user) => (
    <CustomColumn flexRow>
      <UserAvatar className="mr-10" user={user} size={35} />
      <span className="semibold">{user.full_name}</span>
    </CustomColumn>
  );

  renderUserRoleColumn = ({ is_owner, is_manager, is_caretaker, is_vet }) => (
    <CustomColumn flexRow>
      <span className="hide-for-large semibold">
        <FormattedMessage id="general.userRole" />&nbsp;•&nbsp;
      </span>
      <RolesLabel
        roles={[
          { name: labels.farmOwner, value: is_owner, shortcut: 'o' },
          { name: labels.farmManager, value: is_manager, shortcut: 'm' },
          { name: labels.farmCaregiver, value: is_caretaker, shortcut: 'c' },
          { name: labels.veterinarian, value: is_vet, shortcut: 'v' },
        ]}
      />
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.status}>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderActionsColumn = (user, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${user.id}-${rowIndex}`}
        wide
        buttonType="small light-gray"
        label={labels.buttons.revokeAccess}
        onClick={() => this.onRevokeRole(user)}
        dropDownData={[
          { label: labels.buttons.editUser, url: `/admin/users/${user.id}/edit` },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/users/${user.id}/edit`} className="button light hide-for-large">
        {labels.buttons.editUser}
      </Link>
      <Button lightGray onClick={() => this.onRevokeRole(user)} className="hide-for-large">
        {labels.buttons.revokeAccess}
      </Button>
    </div>
  );

  renderExpandable = (user) => (
    <div>
      <Link to={`/admin/users/${user.id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        {labels.buttons.editUser}
      </Link>
      <Button small light onClick={() => this.onRevokeRole(user)}>
        <i className="fa fa-times-circle mr-5" />
        {labels.buttons.revokeAccess}
      </Button>
    </div>
  );

  render() {
    const { isLoading, resources, meta: { stats = {}, total }, reqParams: { page, per_page, role, search, sort },
      onPageChange, onPerPageChange, onSortChange, onSearchChange, params: { id } } = this.props;
    const { isTablet } = this.context;
    const { isRoleRemoving } = this.state;
    const statsSum = sum(Object.values(stats));
    const tableFilters = [
      { label: labels.allAssignments, value: '', count: statsSum },
      { label: labels.farmOwner, value: 'owner' },
      { label: labels.farmCaregiver, value: 'caretaker' },
      { label: labels.farmManager, value: 'manager' },
      { label: labels.veterinarian, value: 'vet' },
    ];
    const isEmptyTable = !resources.length && !search && !role;

    const columns = [
      { label: labels.dataTable.userName, flex: '1 0 250px', renderer: this.renderUserColumn, sortKey: 'first_name' },
      { label: labels.dataTable.roles, flex: '1 0 100px', renderer: this.renderUserRoleColumn, sortKey: 'role' },
      { label: labels.dataTable.status, flex: '1 0 100px', renderer: this.renderStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 180px', renderer: this.renderActionsColumn, fixed: true,
        className: classnames({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={labels.userRoles}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={role}
              onFilterChange={this.onFilterChange}
              stats={stats}
              className="ph-10"
            >
              {!isEmpty(resources) && (
                <Link to={`/admin/farms/${id}/assign-user-roles`} className="button small primary wider">
                  <FormattedMessage id="general.manage" />
                </Link>
              )}
            </TableFilter>
          )}

          <Preloader isActive={isLoading || isRoleRemoving} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="farmUserRoles"
            rowKey="role_id" // todo: id is not unique for roles table now
          />

          <NothingBox
            itemsName="users_roles"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={role}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.farmNoUserRoles" />
            <Link to={`/admin/farms/${id}/assign-user-roles`} className="button primary mt-10">
              <FormattedMessage id="general.assignUserRoles" />
            </Link>
            <RolesPlaceholder />
          </NothingBox>

        </Panel.Body>
      </Panel>

    );
  }
}

FarmUserRoles.contextTypes = {
  isTablet: T.bool,
};

FarmUserRoles.propTypes = {
  resources: T.array,
  fetchData: T.func.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  params: T.object,
};


const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.farmUserRoles.resources,
      reqParams: state.dataTable.farmUserRoles.params,
      meta: state.dataTable.farmUserRoles.meta,
      isLoading: state.dataTable.farmUserRoles.isLoading,
    }), (dispatch, { params: { id } }) => ({
      fetchData: (query) => dispatch(fetchData(`/admin/farms/${id}/user_roles/profile_index`, 'farmUserRoles', query)),
    })
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(FarmUserRoles);
