import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import BarnForm from './components/BarnForm';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
// barns API
import { createFarmBarn } from 'endpoints/admin/farms';

class FarmBarnCreate extends Component {

  createNewFarm = (data) => {
    const { router } = this.context;
    const { params: { id } } = this.props;

    return createFarmBarn(id, data)
      .then(() => {
        router.push(`/admin/farms/${id}/barns`);
        showToastrMessage('component.toastr.barn.created');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { params } = this.props;

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.createBarn" />} />
        <Panel.Body noPadding>
          <div className="row">
            <section className="small-12 medium-10 large-8 column">
              <BarnForm onSubmit={this.createNewFarm} params={params} />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

FarmBarnCreate.propTypes = {
  params: T.object.isRequired,
};

FarmBarnCreate.contextTypes = {
  router: T.object.isRequired,
};

export default FarmBarnCreate;
