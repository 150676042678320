import React from 'react';
import T from 'prop-types';
// utils
import inRange from 'lodash.inrange';
import cn from 'classnames';
// styles
import './ProgressPercent.scss';

const ProgressPercent = ({
  progress,
  className,
}) => {
  const progressNum = Number(progress);

  return (
    <span
      className={cn(
        className,
        'ProgressPercent',
        {
          'alert': inRange(progressNum, 0, 69.99),
          'warning': inRange(progressNum, 70, 89.99),
          'success': inRange(progressNum, 90, 200),
        }
      )}
    >
      {Math.round(progressNum)}%
    </span>
  );
};

ProgressPercent.defaultProps = {
  progress: 0,
  className: '',
};

ProgressPercent.propTypes = {
  className: T.string,
  progress: T.oneOfType([T.string, T.number]),
};

export default ProgressPercent;
