import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { fetchAssets, resetData } from 'reducers/mediaGallery';
import { openViewer } from 'reducers/mediaViewer';
// components
import { FormattedMessage } from 'react-intl';
import Waypoint from 'react-waypoint';
import AssetBox from 'components/AssetBox/AssetBox';
import GalleryFilters from './components/GalleryFilters';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import OfflineScreen from 'components/OfflineScreen';
// utils
import cn from 'classnames';
// styles
import './MediaGallery.scss';

class MediaGallery extends Component {

  componentDidMount() {
    const { fetchAssets, setBreadcrumbs, isOnline } = this.props;
    setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.mediaGallery" />, useLabelAsMobileTitle: true }
    ]);
    if (isOnline) fetchAssets();
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  loadMoreAssets = () => {
    const { fetchAssets, reqParams } = this.props;
    fetchAssets({ ...reqParams, page: reqParams.page + 1 });
  };

  onSearchChange = (search) => {
    const { fetchAssets, reqParams, isOnline } = this.props;
    if (isOnline) fetchAssets({ ...reqParams, page: 1, search });
  };

  getPlaceHolderMessage = (reqParams) => {
    if (reqParams.search) return 'noFilesMatchSearch';
    if (reqParams.symptom_ids || reqParams.deaths || reqParams.external_source_name) return 'noFilesMatchFilters';
    return 'noFilesToDisplay';
  };

  handleFetchAssets = (params) => {
    const { fetchAssets, isOnline } = this.props;
    const search = encodeURIComponent(params?.search || '');
    if (isOnline) fetchAssets({ ...params, search });
  };

  render() {
    const { resources, isLoading, reqParams, meta: { total }, openViewer, isOnline } = this.props;
    const hasMoreItems = resources.length < total;
    const isLoadingMore = !!resources.length && isLoading;

    return (
      <div className="MediaGallery">
        <Preloader isActive={isLoading} />

        <div className="mobile-search hide-for-large">
          <SearchBox
            onChange={this.onSearchChange}
            placeholderKey="general.placeholder.searchFarmsOrUsers"
          />
        </div>

        <div className="gallery-layout">
          <div className="filters-panel show-for-large">
            <GalleryFilters
              className="gallery-filters"
              fetchAssets={this.handleFetchAssets}
              reqParams={reqParams}
            />
          </div>

          {isOnline
            ? (
              <div className="gallery-images">
                {resources.map(({ id: assets, pig_group, ...other }, index) => (
                  <AssetBox
                    assets={assets}
                    farm={pig_group.farm}
                    pigGroup={pig_group}
                    key={index}
                    openViewer={openViewer}
                    {...other}
                  />
                ))}
                {!resources.length && !isLoading && (
                  <div className="gallery-placeholder">
                    <i className="fa fa-photo" />
                    <FormattedMessage
                      tagName="p"
                      id={`component.mediaGallery.${this.getPlaceHolderMessage(reqParams)}`}
                    />
                  </div>
                )}

                <div className={cn('waypoint', { hidden: !hasMoreItems })}>
                  <Preloader isActive={isLoadingMore} />
                  {hasMoreItems &&
                    <Waypoint onEnter={this.loadMoreAssets} />}
                </div>
              </div>
            )
            : <OfflineScreen />}
        </div>
      </div>
    );
  }
}

MediaGallery.propTypes = {
  resources: T.array.isRequired,
  isLoading: T.bool,
  reqParams: T.object,
  meta: T.object,
  fetchAssets: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  openViewer: T.func.isRequired,
  resetData: T.func.isRequired,
  isOnline: T.bool.isRequired,
};

export default connect(
  (state) => ({
    resources: state.mediaGallery.resources,
    isLoading: state.mediaGallery.isLoading,
    reqParams: state.mediaGallery.params,
    meta: state.mediaGallery.meta,
    isOnline: state.network.isOnline,
  }), {
    fetchAssets,
    setBreadcrumbs,
    openViewer,
    resetData,
  }
)(MediaGallery);
