import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// assets
import { ReactComponent as AngleUpIcon } from 'assets/svg/angle-up.svg';
import { ReactComponent as AngleDownIcon } from 'assets/svg/angle-down.svg';
// styles
import styles from '../TimeRange.module.scss';

const TimeInputButtons = ({ className, onUpClick, onDownClick }) => (
  <span className={cn(styles['arrows-buttons'], { [className]: !!className })}>
    <AngleUpIcon className={styles.icon} onClick={onUpClick} />
    <AngleDownIcon className={styles.icon} onClick={onDownClick} />
  </span>
);

TimeInputButtons.propTypes = {
  className: T.string,
  onUpClick: T.func.isRequired,
  onDownClick: T.func.isRequired,
};

export default TimeInputButtons;
