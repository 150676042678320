import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { fetchData, setDataItem, updateMeta } from 'reducers/dataTable';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import Button from 'components/Button';
import { GroupStatusBadge } from 'components/StatusBadge/StatusBadge';
import Link from 'react-router/lib/Link';
import NothingBox from 'components/NothingBox';
import SearchBox from 'components/SearchBox';
import DownloadLink from 'components/DownloadLink';
import TableFilter from 'components/TableFilter';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import VerticalAligner from 'components/VerticalAligner';
import Avatar from 'components/Avatar/Avatar';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// utils
import moment from 'moment';
import cn from 'classnames';
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
// groups API
import { closeAdminPigGroup, deleteAdminPigGroup, reopenAdminPigGroup } from 'endpoints/admin/pigGroups';
// styles
import './CompanyGroups.scss';
// constants
import { companyGroupsTabFilters } from 'constants.js';

const labels = {
  dataTable: {
    groupId: <FormattedMessage id="component.dataTable.headers.groupId" />,
    farmName: <FormattedMessage id="component.dataTable.headers.farmName" />,
    startDate: <FormattedMessage id="component.dataTable.headers.startDate" />,
    closeDate: <FormattedMessage id="component.dataTable.headers.closeDate" />,
    status: <FormattedMessage id="component.dataTable.headers.status" />,
  },
  buttons: {
    deleteGroup: <FormattedMessage id="general.deleteGroup" />,
    manage: <FormattedMessage id="general.manage" />,
    closeGroup: <FormattedMessage id="general.button.closeGroup" />,
    openGroup: <FormattedMessage id="general.button.openGroup" />,
  },
};

class CompanyGroups extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  openPigGroup = (id) => () => {
    const { setDataItem, updateMeta, meta } = this.props;
    reopenAdminPigGroup(id)
      .then((resource) => {
        setDataItem(resource);
        updateMeta({
          ...meta,
          stats: {
            ...meta.stats,
            opened: meta.stats.opened + 1,
            closed: meta.stats.closed - 1,
          },
        });
        showToastrMessage('component.toastr.group.wasReopened');
      })
      .catch(toastResponseErrors);
  };

  closePigGroup = (id) => () => {
    const { setDataItem, updateMeta, meta } = this.props;
    closeAdminPigGroup(id)
      .then((resource) => {
        setDataItem(resource);
        updateMeta({
          ...meta,
          stats: {
            ...meta.stats,
            opened: meta.stats.opened - 1,
            closed: meta.stats.closed + 1,
          },
        });
        showToastrMessage('component.toastr.group.wasClosed');
      })
      .catch(toastResponseErrors);
  };

  deletePigGroup = (id) => () => {
    const { fetchData, reqParams } = this.props;
    deleteAdminPigGroup(id)
      .then(() => {
        showToastrMessage('component.toastr.group.wasDeleted');
        fetchData(reqParams);
      })
      .catch(toastResponseErrors);
  };

  renderGroupIDColumn = ({ id, name }) => (
    <CustomColumn>
      <div className="group-id-column">
        <VerticalAligner>
          <Avatar type="pig_group" avatarSize={35} iconSize={16} />
          <span className="semibold ml-5">{name}</span>
        </VerticalAligner>
        <DownloadLink
          endpoint={`/csv/pig_groups/${id}`}
          className="button download-btn hide-for-large"
        >
          <i className="fa fa-download-btn" />
        </DownloadLink>
      </div>
    </CustomColumn>
  );

  renderFarmColumn = ({ farm_name }) => (
    <CustomColumn label={labels.dataTable.farmName}>
      {farm_name}
    </CustomColumn>
  );

  renderStartDateColumn = ({ started_on }) => (
    <CustomColumn label={labels.dataTable.startDate}>
      {started_on ? moment(started_on).format('MM/DD/YYYY') : '-'}
    </CustomColumn>
  );

  renderCloseDateColumn = ({ closed_on }) => (
    <CustomColumn label={labels.dataTable.closeDate}>
      {closed_on ? moment(closed_on).format('MM/DD/YYYY') : '-'}
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.status}>
      <GroupStatusBadge status={status} textValues={{ date: null }} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id, status, pigs }, { rowIndex }) => {
    const btnClasses = cn('compact', status === 'pending_close' ? 'primary' : 'light-gray');
    const managePath = status === 'draft' ? `/groups/create?draftId=${id}` : `/groups/${id}`;
    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${id}-${rowIndex}`}
          label={labels.buttons.manage}
          buttonType={btnClasses}
          url={managePath}
          dropDownData={[
            { label: labels.buttons.closeGroup, onClick: this.closePigGroup(id),
              hide: !!pigs || !['opened', 'pending_close'].includes(status) },
            { label: labels.buttons.openGroup, onClick: this.openPigGroup(id), hide: status !== 'closed' },
            { label: labels.buttons.deleteGroup, onClick: this.deletePigGroup(id),
              hide: !!pigs || (status === 'request_close') },
          ]}
          customClass="show-for-large"
          wide
        />
        <Link to={managePath} className="button light hide-for-large">
          {labels.buttons.manage}
        </Link>
      </div>
    );
  };

  renderExpandable = ({ id, pigs, status }) => (
    <div>
      <Link className="button light small" to={status === 'draft' ? `/groups/create?draftId=${id}` : `/groups/${id}`}>
        <i className="fa fa-gear mr-5" />
        {labels.buttons.manage}
      </Link>
      {['opened', 'pending_close'].includes(status) && !pigs && (
        <Button small light onClick={this.closePigGroup(id)}>
          {labels.buttons.closeGroup}
        </Button>
      )}
      {status === 'closed' && (
        <Button small light onClick={this.openPigGroup(id)}>
          {labels.buttons.openGroup}
        </Button>
      )}
      {!pigs && (status !== 'request_close') && (
        <Button small light onClick={this.deletePigGroup(id)}>
          <i className="fa fa-times-circle mr-5" />
          {labels.buttons.deleteGroup}
        </Button>
      )}
    </div>
  );

  getFilter = (status) => {
    if (!status) return '';
    if (Array.isArray(status)) {
      return status.includes('opened') ? 'opened' : 'pending';
    }
    return status;
  };

  render() {
    const { pigGroups, isLoading, meta: { total, stats }, reqParams: { page, per_page, status, search, sort },
      onStatusChange, onSearchChange, onPageChange, onPerPageChange, onSortChange, route: { type } } = this.props;
    const isEmptyTable = !pigGroups.length && !status && !search;

    const columns = [
      { label: labels.dataTable.groupId, flex: '1 1 140px', renderer: this.renderGroupIDColumn, sortKey: 'name' },
      { label: labels.dataTable.farmName, flex: '1 1 160px', renderer: this.renderFarmColumn, sortKey: 'farm_name' },
      { label: labels.dataTable.startDate, flex: '1 1 135px', renderer: this.renderStartDateColumn,
        sortKey: 'started_on' },
      { label: labels.dataTable.closeDate, flex: '1 1 135px', renderer: this.renderCloseDateColumn,
        sortKey: 'closed_on' },
      { label: labels.dataTable.status, flex: '1 1 125px', renderer: this.renderStatusColumn, sortKey: 'status' },
      { label: '', flex: '0 0 135px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.ownedGroups" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={companyGroupsTabFilters}
              onFilterChange={onStatusChange}
              stats={stats}
              activeFilter={status}
              className="ph-10"
            >
              <Link to="/groups/create" className="button small primary wider hide-for-large">
                <FormattedMessage id="general.button.createGroup" />
              </Link>
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={pigGroups}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey={type}
          />

          <NothingBox
            itemsName="groups"
            display={!pigGroups.length}
            isLoading={isLoading}
            search={search}
            filter={this.getFilter(status)}
          >
            <h2 className="lighter"><FormattedMessage id="component.nothingBox.noData" /></h2>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

CompanyGroups.propTypes = {
  isLoading: T.bool,
  pigGroups: T.array,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  onStatusChange: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  updateMeta: T.func.isRequired,
  route: T.object.isRequired
};

const enhance = compose(
  connect(
    (state, { route: { type }, params: { id } }) => ({
      isLoading: state.dataTable[type].isLoading,
      pigGroups: state.dataTable[type].resources,
      reqParams: state.dataTable[type].params,
      meta: state.dataTable[type].meta,
      authUser: state.auth.user,
      id: +(id || state.auth.user.current_company.id)
    }), {
      fetchData,
      setDataItem,
      updateMeta
    },
  ),
  withProps(({ id, fetchData, route: { type } }) => ({
    fetchData: (query) => fetchData(`/admin/companies/${id}/pig_groups`, type, query),
    updateMeta: (meta) => updateMeta(meta, type),
    setDataItem: (data) => setDataItem(data, type),

  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(CompanyGroups);
