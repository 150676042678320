import React from 'react';
import T from 'prop-types';
// components
import AddEventToCalendar from 'components/AddEventToCalendar';
import AddEventToCalendarModal from 'components/AddEventToCalendarModal';
import { FormattedMessage } from 'react-intl';
import PhoneNumberLink from 'components/PhoneNumberLink';
// utils
import classnames from 'classnames/bind';
import { formatLocation } from 'utils';
// styles
import styles from '../CommitmentProfile.module.scss';

const cn = classnames.bind(styles);

const TruckingCompanySection = ({ openModal, loading, calendarData, isMobile }) => (
  <div>
    {/* TUCKING COMPANY */}
    <div className="semibold"><FormattedMessage id="general.truckingCompany" /></div>
    <div>{loading.trucking_company?.name || 'N/A'}</div>
    <div>
      {formatLocation(loading.trucking_company?.city, loading.trucking_company?.state)}
    </div>
    <div>
      {loading.trucking_company?.contact_phone ?
        <PhoneNumberLink phone={loading.trucking_company?.contact_phone} mobileOnly /> :
        'N/A'
      }
    </div>
    {/* TRUCK DRIVER */}
    <div className="pt-20">
      <div className="semibold"><FormattedMessage id="general.truckDriver" /></div>
      <div>{loading.driver?.full_name || 'N/A'}</div>
      <div>
        {loading.driver?.contact_phone ?
          <PhoneNumberLink phone={loading.driver?.contact_phone} mobileOnly /> :
          'N/A'
        }
      </div>
    </div>
    {calendarData.isFutureEvent && (
      <div className={cn('add-to-calendar', { 'is-mobile': isMobile })}>
        <div className={cn('divider', 'mt-20', 'mb-20')} />
        {!isMobile && <AddEventToCalendar calendarData={calendarData} />}
        {isMobile && (
          <FormattedMessage id="general.addToCalendar">
            {(text) => (
              <div onClick={() => openModal(<AddEventToCalendarModal calendarData={calendarData} />)}>
                {text}
              </div>
            )}
          </FormattedMessage>
        )}
      </div>
    )}
  </div>
);

TruckingCompanySection.propTypes = {
  isMobile: T.bool.isRequired,
  loading: T.object.isRequired,
  openModal: T.func.isRequired,
  calendarData: T.object.isRequired,
};

export default TruckingCompanySection;
