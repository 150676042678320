import React from 'react';
import T from 'prop-types';
// components
import QuestionRow from '../QuestionRow';

const MobileCategoryQuestions = ({ currentCategory, categoryIndex }) => (
  <div>
    {currentCategory.svr_questions.map((question, questionIndex) => (
      <QuestionRow
        categoryIndex={categoryIndex}
        key={question.id}
        question={question}
        questionIndex={questionIndex}
      />
    ))}
  </div>
);

MobileCategoryQuestions.propTypes = {
  currentCategory: T.object.isRequired,
  categoryIndex: T.number.isRequired,
};

export default MobileCategoryQuestions;
