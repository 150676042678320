/* eslint-disable consistent-return */
import { handleActions, createAction } from 'redux-actions';
// utils
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import map from 'lodash.map';
import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';
import uniqueId from 'lodash.uniqueid';
import unionBy from 'lodash.unionby';
import { fetchFromAPI } from 'utils/api';
// ------------------------------------
// Constants
// ------------------------------------

const defaultParams = {
  page: 1,
  per_page: 50,
  type: [],
  checkins: {},
  symptoms: {},
  deaths: {},
  name: '',
};

const initialState = {
  isLoading: false,
  isFarmsLoaded: false,
  farms: [],
  params: { ...defaultParams },
  isCoordsObtained: false,
  loadQueID: null,
  coords: null,
};

const FETCH_FARMS = 'assetMap/FETCH_FARMS';
const [FETCH_FARMS_PENDING, FETCH_FARMS_FULFILLED, FETCH_FARMS_REJECTED] = [
  `${FETCH_FARMS}_PENDING`,
  `${FETCH_FARMS}_FULFILLED`,
  `${FETCH_FARMS}_REJECTED`
];

const FETCH_FARMS_PAGE = 'assetMap/FETCH_FARMS_PAGE';

const FETCH_COORDS = 'assetMap/FETCH_COORDS';
const [FETCH_COORDS_PENDING, FETCH_COORDS_FULFILLED, FETCH_COORDS_REJECTED] = [
  `${FETCH_COORDS}_PENDING`,
  `${FETCH_COORDS}_FULFILLED`,
  `${FETCH_COORDS}_REJECTED`
];

const RESET_REDUCER = 'assetMap/RESET_STORE';

// ------------------------------------
// Action creators
// ------------------------------------

export const resetReducer = createAction(RESET_REDUCER);

const fetchFarmsPage = (params = defaultParams, currentLoadQueID) => (dispatch, getState) => {
  return fetchFromAPI('/asset_map', { params: pickBy(params) })
    .then((response) => {
      const { loadQueID } = getState().assetMap;
      const isCanceled = currentLoadQueID !== loadQueID;
      const isAllLoaded = response.meta.total <= response.resources.length + (params.page - 1) * params.per_page;
      if (isCanceled) return;
      dispatch(createAction(FETCH_FARMS_PAGE)({ farms: response.resources, params }));
      if (isAllLoaded) return dispatch(createAction(FETCH_FARMS_FULFILLED)());
      dispatch(fetchFarmsPage({ ...params, page: params.page + 1 }, currentLoadQueID));
    })
    .catch((response) => dispatch(createAction(FETCH_FARMS_REJECTED)(response)));
};

const debouncedFetchFarms = debounce((dispatch, params, loadQueID, clearFarms) => {
  dispatch(createAction(FETCH_FARMS_PENDING)({ loadQueID, clearFarms }));
  dispatch(fetchFarmsPage(params, loadQueID));
}, 200);

export const fetchFarms = (newParams = {}, clearFarms = true) => (dispatch, getState) => {
  const { params, farms } = getState().assetMap;
  const currentLoadQueID = uniqueId();
  const reqParams = {
    ...params,
    ...newParams,
    page: 1,
    exclude_ids: clearFarms ? null : map(farms, 'id').join(','),
  };
  debouncedFetchFarms(dispatch, reqParams, currentLoadQueID, clearFarms);
};

/**
   * Get coords by one farm
   * to get coords based on several farms look at:
   * https://github.com/istarkov/google-map-react/issues/329
   * https://github.com/istarkov/google-map-react/issues/3
   */
export const fetchCompanyCoords = (companyId) => (dispatch, getState) => {
  dispatch(createAction(FETCH_COORDS_PENDING)(companyId));
  const state = getState().assetMap;
  return fetchFromAPI('/asset_map',
    {
      params: pickBy({ ...state.params, manager_id: companyId, page: 1, per_page: 1, bounding_box: undefined })
    })
    .then(({ resources }) => {
      const center = pick(resources[0], ['lat', 'lng']);
      if (isEmpty(center)) return Promise.reject();
      dispatch(createAction(FETCH_COORDS_FULFILLED)(center));
    })
    .catch((response) => {
      dispatch(createAction(FETCH_COORDS_REJECTED)(response));
      return Promise.reject();
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_FARMS
  [FETCH_FARMS_PENDING]: (state, action) => {
    const { loadQueID, clearFarms } = action.payload;
    return {
      ...state,
      isLoading: true,
      isFarmsLoaded: false,
      loadQueID,
      farms: clearFarms ? [] : state.farms,
      error: null,
    };
  },
  [FETCH_FARMS_FULFILLED]: (state) => ({
    ...state,
    loadQueID: null,
    isLoading: false,
    isFarmsLoaded: true,
    error: null,
  }),
  [FETCH_FARMS_REJECTED]: (state, action) => ({
    ...state,
    isLoading: false,
    isFarmsLoaded: true,
    error: action.payload.errors,
  }),

  // FETCH_FARMS_PAGE
  [FETCH_FARMS_PAGE]: (state, { payload }) => ({
    ...state,
    params: payload.params,
    farms: unionBy(state.farms, payload.farms, 'id'),
  }),

  // FETCH_COORDS
  [FETCH_COORDS_PENDING]: (state, action) => ({
    ...state,
    isLoading: true,
    loadQueID: null,
    companyCoords: null,
    farms: [],
    params: {
      ...state.params,
      manager_id: action.payload,
    },
  }),
  [FETCH_COORDS_FULFILLED]: (state, action) => ({
    ...state,
    isLoading: false,
    companyCoords: action.payload,
    isCoordsObtained: true,
  }),
  [FETCH_COORDS_REJECTED]: (state, action) => ({
    ...state,
    isLoading: false,
    isCoordsObtained: true,
    error: action.payload.errors,
  }),

  // RESET_REDUCER
  [RESET_REDUCER]: () => ({
    ...initialState,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
