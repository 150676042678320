import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';
// utils
import classnames from 'classnames/bind';
import { formatAssetLink, capitalize } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import get from 'lodash.get';
// api
import { createLinkAsset, updateLinkAsset, updateAssetName } from 'endpoints/tenantResources';
// assets
import styles from './AddTenantAssetModal.module.scss';

const cn = classnames.bind(styles);
const modes = ['initial', 'create_link', 'edit_link', 'rename'];

class AddTenantAssetModal extends Component {

  state = {
    mode: this.props.initialMode,
    link: this.props.initialLink || '',
    name: this.props.initialName || '',
    linkErrorText: '',
    isLoading: false,
  };

  componentDidMount() {
    const { onBrowseLinkAssign } = this.props;
    if (document && onBrowseLinkAssign) {
      const browseLink = document.getElementById('add-file-modal');
      onBrowseLinkAssign(browseLink);
    }
  }

  getModalTitle = (mode) => {
    switch (mode) {
      case 'initial':
        return <FormattedMessage id="component.addResourceModal.new" />;
      case 'create_link':
        return <FormattedMessage id="component.addResourceModal.createLink" />;
      case 'edit_link':
        return <FormattedMessage id="component.addResourceModal.editLink" />;
      case 'rename':
        return <FormattedMessage id="general.editName" />;
      default:
        return null;
    }
  };

  onLinkChange = ({ target: { value } }) => {
    this.setState({ link: value });
  };

  onNameChange = ({ target: { value } }) => {
    this.setState({ name: value });
  };

  errorCallback = (response) => {
    const linkErrorText = get(response, 'errors.link[0]');
    if (linkErrorText) {
      this.setState({ linkErrorText, isLoading: false });
      return;
    }
    this.setState({ isLoading: false });
    toastResponseErrors(response);
  };

  onLinkAssetCreate = () => {
    const { link, name } = this.state;
    const { onLinkCreate } = this.props;
    const formattedLink = formatAssetLink(link);
    this.setState({ isLoading: true });
    createLinkAsset(formattedLink, name)
      .then((resource) => {
        onLinkCreate(resource);
        this.closeModal();
      })
      .catch(this.errorCallback);
  };

  onLinkAssetUpdate = () => {
    const { onLinkUpdate, assetId } = this.props;
    const { link, name } = this.state;
    const formattedLink = formatAssetLink(link);
    this.setState({ isLoading: true });
    updateLinkAsset(assetId, formattedLink, name)
      .then((resource) => {
        onLinkUpdate(resource);
        this.closeModal();
      })
      .catch(this.errorCallback);
  };

  onNameAssetUpdate = () => {
    const { onFileNameUpdate, assetId } = this.props;
    const { name } = this.state;
    this.setState({ isLoading: true });
    updateAssetName(assetId, name)
      .then((resource) => {
        onFileNameUpdate(resource);
        this.closeModal();
      }).catch(this.errorCallback);
  };

  // () => onClose() instead of onClose because of event goes to redux action payload
  closeModal = () => this.props.onClose();

  isSaveDisabled = () => {
    const { mode, link, name, isLoading } = this.state;
    const { initialLink, initialName } = this.props;

    if (mode === 'create_link') return !link || !name || isLoading;

    const isSameName = (initialName === name);
    const isSameLink = (initialLink === link);

    if (mode === 'rename') return isLoading || !name || isSameName;
    return isLoading || !name || !link || (isSameName && isSameLink);
  };

  render() {
    const { mode, link, linkErrorText, name } = this.state;
    const title = this.getModalTitle(mode);
    const isSaveDisabled = this.isSaveDisabled();
    return (
      <Modal title={title} onClose={this.closeModal}>
        <section className="modal-body">
          {(mode === 'initial') && (
            <div className={cn('add-resource-body')}>
              <div id="add-file-modal" className={cn('add-resource-square')}>
                <i className={cn('fa fa-ep-doc', 'icon')} />
                <FormattedMessage id="general.file" />
              </div>
              <div className={cn('add-resource-square')} onClick={() => this.setState({ mode: 'create_link' })}>
                <i className={cn('fa fa-link', 'icon')} />
                <FormattedMessage id="general.link" />
              </div>
            </div>
          )}
          {(mode !== 'initial') && (
            <div className={cn('url-input-body')}>
              <div className={cn('url-label')}>Name</div>
              <Input
                value={name}
                onChange={this.onNameChange}
                placeholder="Name"
                hasNoMargin
              />
              {['create_link', 'edit_link'].includes(mode) && (
                <>
                  <div className={cn('url-label')}>URL</div>
                  <Input
                    value={link}
                    onChange={this.onLinkChange}
                    placeholder="www.example.com"
                    hasNoMargin
                  />
                  {linkErrorText && link && (
                    <div className={cn('error-text')}>{capitalize(linkErrorText)}</div>
                  )}
                </>
              )}
            </div>
          )}
        </section>
        {(mode !== 'initial') && (
          <section className="modal-footer text-right">
            <Button className="margin-right-1" onClick={this.closeModal}>
              <FormattedMessage id="general.button.cancel" />
            </Button>
            {(mode === 'create_link') && (
              <Button primary disabled={isSaveDisabled} onClick={this.onLinkAssetCreate}>
                <FormattedMessage id="general.button.create" />
              </Button>
            )}
            {(mode !== 'create_link') && (
              <Button
                primary
                disabled={isSaveDisabled}
                onClick={mode === 'edit_link' ? this.onLinkAssetUpdate : this.onNameAssetUpdate}
              >
                <FormattedMessage id="general.button.save" />
              </Button>
            )}
          </section>
        )}
      </Modal>
    );
  }
}

AddTenantAssetModal.propTypes = {
  initialLink: T.string,
  initialName: T.string,
  assetId: T.number,
  onClose: T.func.isRequired,
  onLinkCreate: T.func,
  onLinkUpdate: T.func,
  onFileNameUpdate: T.func,
  onBrowseLinkAssign: T.func,
  initialMode: T.oneOf(modes),
};

AddTenantAssetModal.defaultProps = {
  initialMode: 'initial',
};

export default AddTenantAssetModal;
