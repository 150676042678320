import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { fetchUserCompanies } from 'reducers/auth';
import { fetchSymptomsList } from 'reducers/staticData';
import { fetchFarms, fetchCompanyCoords, resetReducer } from 'reducers/assetMap';
import { openPortal } from 'reducers/portal';
import { setPageOptions } from 'reducers/layout';
// components
import Subnavigation from 'components/Subnavigation';
import Button from 'components/Button';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import ClusterMarker from 'components/GoogleMap/Markers/ClusterMarker/ClusterMarker';
import FarmMarker from 'components/GoogleMap/Markers/FarmMarker/FarmMarker';
import UserMarker from 'components/GoogleMap/Markers/UserMarker/UserMarker';
import FarmMarkerTooltip from 'components/GoogleMap/Markers/FarmMarker/FarmMarkerTooltip';
import AssetMapFilter from './components/AssetMapFilter';
import Preloader from 'components/Preloader';
import OfflineScreen from 'components/OfflineScreen';
// hocs
import Clusterify from 'components/GoogleMap/hocs/Clusterify';
import MapParamsController from 'components/GoogleMap/hocs/MapParamsController';
import MouseEnterListener from 'hoc/MouseEnterListener';
// utils
import getValue from 'lodash.get';
import pick from 'lodash.pick';
import pickBy from 'lodash.pickby';
import countBy from 'lodash.countby';
import isEmpty from 'lodash.isempty';
import toProp from 'utils/toProp';
import cn from 'classnames';
import { isEqual } from 'lodash';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import './AssetMap.scss';

const formatClusterData = (farms) => {
  const clusterFarmTypes = farms.map(({ farm_type }) => farm_type);
  const counts = countBy(clusterFarmTypes);
  const clusterType = Object.keys(counts).reduce((a, b) => { return counts[a] > counts[b] ? a : b; });
  return { type: clusterType };
};

class AssetMap extends Component {

  componentDidMount() {
    const { companies, fetchUserCompanies, fetchCompanyCoords, fetchFarms, fetchSymptomsList,
      setPageOptions, isOnline } = this.props;

    if (!companies.length) {
      fetchUserCompanies();
    }

    setPageOptions({ mobileTitle: 'assetMap' });

    if (isOnline) {
      fetchSymptomsList();
      fetchCompanyCoords()
        .then(() => {
          this.setInitialMapCenter();
          fetchFarms();
        })
        .catch(() => fetchFarms());
    }
  }

  setInitialMapCenter = () => {
    const {
      isCompanyDefaultCenteringEnabled,
      companyDefaultMapCenterCoords,
      setMapParams,
      companyDefaultMapZoomLevel
    } = this.props;

    if (isCompanyDefaultCenteringEnabled && companyDefaultMapCenterCoords.lat && companyDefaultMapCenterCoords.lng) {
      setMapParams({ center: companyDefaultMapCenterCoords, zoom: companyDefaultMapZoomLevel });
    }
  }

  componentDidUpdate({ isOnline, center }) {
    const { setMapParams } = this.props;

    if (!isEqual(this.props.center, center)) {
      setMapParams({ center: this.props.center });
    }

    if (isOnline && isOnline !== this.props.isOnline) {
      this.props.fetchSymptomsList();
      this.props.fetchCompanyCoords()
        .then(() => fetchFarms())
        .catch(() => fetchFarms());
    }
  }

  componentWillUnmount() {
    this.props.resetReducer();
    this.props.setPageOptions({ mobileTitle: '' });
  }

  onCompanyChange = (company) => {
    const { params, fetchCompanyCoords, isOnline } = this.props;
    const companyId = getValue(company, 'id');
    if (companyId !== params.manager_id && isOnline) {
      fetchCompanyCoords(companyId)
        .then(() => fetchFarms())
        .catch(() => fetchFarms());
    }
  };

  onMapChange = ({ bounds, zoom, center }) => {
    const { fetchFarms, setMapParams } = this.props;
    const newCoords = !isEmpty(pickBy(bounds.ne))
      ? { n_e: bounds.ne, s_w: bounds.sw }
      : undefined;
    setMapParams({ center, zoom, bounds });
    fetchFarms({ bounding_box: newCoords }, false);
  };

  onClusterClick = ({ lat, lng }) => {
    const { setMapParams, mapParams: { zoom } } = this.props;
    setMapParams({
      zoom: zoom + 3,
      center: {
        lat,
        lng,
      },
    });
  };

  clearActiveFarm = (event) => {
    const { onFarmMouseLeave, onFarmTooltipMouseLeave } = this.props;
    onFarmMouseLeave();
    onFarmTooltipMouseLeave();
    if (event) {
      event.stopPropagation();
    }
  };

  onFarmMouseEnter = (key, { farm }) => {
    const { onFarmMouseEnter } = this.props;
    if (farm) onFarmMouseEnter({ id: farm.id, type: 'farm' });
  };

  openMobileFilter = () => {
    this.props.openPortal(<AssetMapFilter isMobile />);
  };

  renderFarmMarker = (farm) => {
    const {
      mapParams,
      activeFarm,
      activeFarmTooltip,
      onFarmMouseEnter,
      onFarmTooltipMouseEnter,
      onFarmTooltipMouseLeave,
      params: { checkins },
    } = this.props;
    const { isMobile } = this.context;
    const active = activeFarmTooltip || activeFarm;
    const isActive = getValue(active, 'id') === farm.id;
    const isUserShown = !isEmpty(checkins) && mapParams.zoom > 10 && getValue(farm, 'last_check_in.user');

    const zoomIn = ({ lat, lng }) => {
      const { setMapParams } = this.props;
      setMapParams({
        zoom: 13,
        center: {
          lat,
          lng,
        },
      });
    };

    return [
      <FarmMarker
        key={`farm-marker-${farm.id}`}
        lat={farm.lat}
        lng={farm.lng}
        farm={farm}
        active={isActive}
        onClick={toProp(isMobile && onFarmMouseEnter)}
        currentZoom={mapParams.zoom}
      >
        <FarmMarkerTooltip
          farm={farm}
          visible={isActive}
          onTooltipClose={this.clearActiveFarm}
          zoomIn={zoomIn}
          onMouseEnter={toProp(!isMobile ? onFarmTooltipMouseEnter : null)}
          onMouseLeave={toProp(!isMobile ? onFarmTooltipMouseLeave : null)}
        />
      </FarmMarker>,
      isUserShown && (
        <UserMarker
          user={getValue(farm, 'last_check_in.user')}
          {...pick(farm, ['lat', 'lng'])}
          key={`user-marker-${farm.id}`}
        />
      )
    ];
  };

  renderCluster = (cluster) => (
    <ClusterMarker
      key={`cluster-${cluster.id}`}
      lat={cluster.lat}
      lng={cluster.lng}
      cluster={cluster}
      onClick={this.onClusterClick}
    />
  );

  render() {
    const { companies, onFarmMouseLeave, mapParams, isLoaded, clusters,
      params: { manager_id }, isCoordsObtained, isOnline } = this.props;
    const { isMobile } = this.context;
    const showEmptyMsg = isLoaded && isCoordsObtained && !clusters.length;

    return (
      <div className="AssetMap">

        <Subnavigation
          items={companies}
          currentId={manager_id}
          onSelect={this.onCompanyChange}
          defaultItem={<FormattedMessage id="general.allCompanies" />}
          className="small-12 columns"
        />

        {isOnline
          ? (
            <div className="asset-map-content">
              <div className="show-for-medium form-wrapper">
                <AssetMapFilter />
              </div>
              <div className={cn('map-wrapper', { showEmptyMsg })}>
                <Preloader isActive={!isLoaded} />

                <div className={cn('empty-message animated fadeIn', { 'fade-out': !showEmptyMsg })}>
                  <i className="fa fa-info-circle" />
                  <FormattedMessage id="component.assetMap.noFarms" />
                </div>

                {isCoordsObtained && (
                  <GoogleMap
                    onChange={this.onMapChange}
                    {...mapParams}
                    onChildMouseEnter={toProp(!isMobile && this.onFarmMouseEnter)}
                    onChildMouseLeave={toProp(!isMobile && onFarmMouseLeave)}
                    style={{ position: 'initial' }}
                  >
                    {clusters.map((cluster) => {
                      return cluster.numPoints === 1
                        ? this.renderFarmMarker(cluster.points[0])
                        : this.renderCluster(cluster);
                    })}
                  </GoogleMap>
                )}

                <div className="hide-for-medium filter-button">
                  <Button primary className="big-button" onClick={this.openMobileFilter}>
                    <i className="fa fa-filter mr-10" />
                    <FormattedMessage id="component.assetMap.filter" />
                  </Button>
                </div>
              </div>
            </div>
          )
          : <OfflineScreen />}
      </div>
    );
  }
}

AssetMap.propTypes = {
  activeFarmTooltip: T.object,
  activeFarm: T.object,
  onFarmTooltipMouseEnter: T.func.isRequired,
  onFarmTooltipMouseLeave: T.func.isRequired,
  onFarmMouseEnter: T.func.isRequired,
  onFarmMouseLeave: T.func.isRequired,
  mapParams: T.object.isRequired,
  setMapParams: T.func.isRequired,
  isLoaded: T.bool.isRequired,
  clusters: T.array.isRequired,
  params: T.object.isRequired,
  isCoordsObtained: T.bool.isRequired,
  center: T.object,
  resetReducer: T.func.isRequired,
  fetchFarms: T.func.isRequired,
  fetchCompanyCoords: T.func.isRequired,
  fetchSymptomsList: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  openPortal: T.func.isRequired,
  isOnline: T.bool.isRequired,
  companies: T.any,
  fetchUserCompanies: T.any,
  isCompanyDefaultCenteringEnabled: T.bool.isRequired,
  companyDefaultMapCenterCoords: T.object.isRequired,
  companyDefaultMapZoomLevel: T.number
};

AssetMap.contextTypes = {
  isMobile: T.bool,
};

const enhance = compose(
  connect(
    (state) => ({
      companies: state.auth.user_companies,
      isLoaded: state.assetMap.isFarmsLoaded,
      farms: state.assetMap.farms,
      params: state.assetMap.params,
      isCoordsObtained: state.assetMap.isCoordsObtained,
      companyDefaultMapZoomLevel: state.auth.user.current_company.map_centralize ?
        state.auth.user.current_company.map_zoom :
        10,
      isCompanyDefaultCenteringEnabled: state.auth.user.current_company.map_centralize,
      companyDefaultMapCenterCoords: {
        lat: state.auth.user.current_company.map_lat,
        lng: state.auth.user.current_company.map_lng
      },
      center: state.assetMap.companyCoords,
      isOnline: state.network.isOnline,
    }), {
      fetchUserCompanies,
      fetchFarms,
      fetchCompanyCoords,
      fetchSymptomsList,
      resetReducer,
      setPageOptions,
      openPortal,
    }
  ),
  withProps({
    formatClusterData,
    mouseListeners: ['Farm', 'FarmTooltip'],
  }),
  MouseEnterListener(),
  MapParamsController(),
  Clusterify('farms'),
);

export default enhance(AssetMap);
