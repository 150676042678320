import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import ActivityAuthorLine from './ActivityAuthorLine/ActivityAuthorLine';
import ActivityCheckupBox from './ActivityCheckupBox/ActivityCheckupBox';
import ActivityBox from './ActivityBox';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityHeader from './ActivityHeader';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
// hoc
import withActivityController from './hoc/withActivityController';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './CheckinActivity.module.scss';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments'];

const CheckinActivity = ({
  className,
  activity,
  currentUser,
  isChildActivity,
  markAsSeen,
  openDropdown,
  setActivityFlag,
  isMinimized,
  toggleMinimized,
  defaultDropdownOptions,
}) => {
  const { id, flagged, is_viewed, user, farm, check_in_id } = activity;
  const isAdmin = currentUser.roles_map.admin;

  const handleOpenDropdown = (e) => {
    const farmId = farm?.id;
    const userId = user?.id;
    const checkinOptions = [
      { label: <FormattedMessage id="general.button.viewUserCheckInHistory" />, url: `/checkins/users/${userId}`,
        icon: 'map-marker', hide: !userId },
      { label: <FormattedMessage id="general.button.viewFarmCheckInHistory" />, url: `/checkins/farms/${farmId}`,
        icon: 'map-marker', hide: !farm },
    ];
    const options = checkinOptions.concat(defaultDropdownOptions);
    openDropdown(e, options);
  };

  return (
    <ActivityBox
      className={className}
      isFlagged={flagged}
      isUnseen={!is_viewed}
      hasMarker={!isChildActivity}
      markAsSeen={markAsSeen}
    >
      {!isMinimized && <LastCommentInfo activity={activity} currentUser={currentUser} />}
      <ActivityHeader activityId={id} onOpenDropdown={handleOpenDropdown} onDoubleClick={toggleMinimized}>
        <ActivityAuthorLine
          activity={activity}
          currentUser={currentUser}
          minimized={isMinimized}
        >
          <FormattedMessage
            tagName="div"
            id="container.farmfeed.row.reason.checkedIn"
            values={{
              ownCheckin: currentUser.id === user.id ? 'yes' : 'no',
              name: user.full_name,
              b: (msg) => <b>{msg}</b>,
            }}
          />
        </ActivityAuthorLine>
      </ActivityHeader>

      <ActivityBodyCollapse isClosed={isMinimized}>
        <ActivityCheckupBox
          className={cn({ 'mb-10': isChildActivity })}
          isAdmin={isAdmin}
          activity={activity}
          actionButtons={(
            <Link to={`/checkins/${check_in_id}`} className={cn('button light full-width', 'view-btn')}>
              <span>
                <i className="fa fa-map mr-5" />
                <FormattedMessage id="general.button.viewOnMap" />
              </span>
            </Link>
          )}
        />

        {!isChildActivity && (
          <>
            <ActivityActionsBar
              activity={activity}
              currentUser={currentUser}
              openDropdown={openDropdown}
              setActivityFlag={setActivityFlag}
            />
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

CheckinActivity.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
  isChildActivity: T.bool.isRequired,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
  // from hoc
  isMinimized: T.bool.isRequired,
  toggleMinimized: T.func.isRequired,
  defaultDropdownOptions: T.array.isRequired,
};

export default withActivityController(CheckinActivity, { affectRerenderKeys });
