import React from 'react';
import T from 'prop-types';
// components
import ReactTimeAgo from 'react-timeago';
// translate
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';

const TimeAgo = ({
  date,
  formatter,
  className,
}) => {
  return (
    <ReactTimeAgo
      date={date}
      title={moment(date).format('MM/DD/YYYY HH:MM')}
      formatter={formatter}
      className={className}
    />
  );
};

TimeAgo.defaultProps = {
  formatter: (value, unit) => (
    <FormattedMessage
      id={`component.timeAgo.${value > 1 ? unit + 's' : unit}`}
      values={{ value }}
    />
  ),
};

TimeAgo.propTypes = {
  date: T.any,
  className: T.string,
  formatter: T.func,
};

export default TimeAgo;
