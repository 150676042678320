import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ActivityBox from './ActivityBox';
import FarmFeedRowComments from '../FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from '../FarmFeed/FarmFeedRowAddComment';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
import UserAvatar from '../UserAvatar/UserAvatar';
// utils
import classnames from 'classnames/bind';
// hoc
import withActivityController from './hoc/withActivityController';
// styles
import styles from './BirthdayCongratsActivity.module.scss';
// assets
import congratIcon from '../../../public/images/congrat-icon.png';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'comments'];

const BirthdayCongratsActivity = ({ activity, currentUser, markAsSeen }) => {
  const { flagged, is_viewed, user } = activity;
  const selfBirthday = currentUser.id === user.id;

  return (
    <ActivityBox isFlagged={flagged} isUnseen={!is_viewed} markAsSeen={markAsSeen}>
      <LastCommentInfo activity={activity} currentUser={currentUser} />
      <div className={cn('birthday-congrats-box')}>
        {selfBirthday
          ? <img src={congratIcon} className={cn('congrats-icon')} alt="birthday-icon" loading="lazy" />
          : (
            <>
              <UserAvatar size="80" user={user} />
              <span className={cn('birthday-boy-full-name')}>{user.full_name}</span>
            </>
          )}
        <span className={cn('greeting-message')}>
          {selfBirthday
            ? (
              <FormattedMessage
                id="container.farmfeed.birthdateCongrat"
                values={{ name: currentUser.first_name }}
              />
            )
            : (
              <span>
                <img src={congratIcon} className={cn('congrats-icon-message')} alt="birthday-icon" loading="lazy" />
                <FormattedMessage
                  id="container.farmfeed.birthdateCongratUser"
                  values={{ name: user.first_name }}
                />
              </span>
            )}
        </span>
        <span className={cn('greeting-text')}>
          <FormattedMessage id={`container.farmfeed.birthdateCongratMessage${selfBirthday ? '' : 'User'}`} />
        </span>
      </div>
      <FarmFeedRowComments activity={activity} />
      <FarmFeedRowAddComment activity={activity} />
    </ActivityBox>
  );
};

BirthdayCongratsActivity.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
};

export default withActivityController(BirthdayCongratsActivity, { affectRerenderKeys });
