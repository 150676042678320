import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { getUserFiltersAction } from 'reducers/userFilters';
// components
import { FormattedMessage } from 'react-intl';
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
import PickerRow from '../PickerRow';
import Preloader from 'components/Preloader';
import { ReactComponent as FilterIcon } from 'assets/svg/filter-ep.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/info-circle.svg';
// styles
import styles from './UserFilterSegmentSelector.module.scss';

const UserFilterSegmentSelector = ({
  currentSegment,
  filterGroupName,
  getUserFiltersAction,
  isUserFiltersLoaded,
  isUserFiltersLoading,
  onClose,
  onSegmentChoose,
  userFilterSegments,
}) => {
  const [search, setSearch] = useState('');
  const userFilterSegmentsCount = userFilterSegments.length;

  useEffect(() => {
    if (!isUserFiltersLoaded) {
      getUserFiltersAction(filterGroupName);
    }
  }, []);

  const searchedSegments = search
    ? userFilterSegments.filter((segment) => segment.name.toLowerCase().includes(search.toLowerCase()))
    : userFilterSegments;

  const placeholder = isUserFiltersLoaded && !searchedSegments.length && (
    <div className={styles['no-segments-box']}>
      <Preloader isCentered isActive={isUserFiltersLoading} />
      <InfoIcon height={60} className={styles['info-icon']} />
      {search
        ? <FormattedMessage id="component.userFilters.noSearchedSegments" />
        : <FormattedMessage id="component.userFilters.noSegments" />
      }
    </div>
  );

  return (
    <MobileListPickerComponent
      isLoading={isUserFiltersLoading}
      title={<FormattedMessage id="general.chooseSegment" />}
      isSearchHidden={isUserFiltersLoaded && userFilterSegmentsCount === 0}
      onBack={onClose}
      search={search}
      onSearchChange={setSearch}
      placeholder={placeholder}
    >
      {searchedSegments.map((segment) => {
        const isSelected = segment.id === currentSegment?.id;
        return (
          <PickerRow
            key={segment.id}
            isSelected={isSelected}
            onClick={isSelected ? null : () => onSegmentChoose(segment)}
          >
            <FilterIcon height={20} className={styles['segment-icon']} />
            <span className="mh-5 nowrap-ellipsis">{segment.name}</span>
          </PickerRow>
        );
      })}
    </MobileListPickerComponent>
  );
};

UserFilterSegmentSelector.propTypes = {
  onClose: T.func.isRequired,
  getUserFiltersAction: T.func.isRequired,
  filterGroupName: T.string.isRequired,
  isUserFiltersLoading: T.bool.isRequired,
  isUserFiltersLoaded: T.bool.isRequired,
  onSegmentChoose: T.func.isRequired,
  currentSegment: T.object,
  userFilterSegments: T.array.isRequired,
};

export default connect(
  (state, props) => ({
    isUserFiltersLoading: state.userFilters[props.filterGroupName]?.isLoading || false,
    isUserFiltersLoaded: state.userFilters[props.filterGroupName]?.isLoaded || false,
    userFilterSegments: state.userFilters[props.filterGroupName]?.filters || [],
  }), {
    getUserFiltersAction,
  }
)(UserFilterSegmentSelector);
