import React from 'react';
import T from 'prop-types';
// utils
import { FormattedMessage } from 'react-intl';
import { renderMentionsText } from 'utils';
// import renderIf from 'utils/renderIf';
import classnames from 'classnames/bind';
// components
import Translation from 'components/Translation';
// styles
import styles from './SegmentComment.module.scss';

const cn = classnames.bind(styles);

const ReportSegmentComment = (props) => {
  const { comment: { comment: prop_comment, text } } = props;

  const comment = text || prop_comment;

  return comment ? (
    <div>
      <div className={cn('comment-head')}>
        <FormattedMessage id="general.notes" />
      </div>
      <div className={cn('comment-body')}>
        <Translation text={comment}>
          {renderMentionsText(comment)}
        </Translation>
      </div>
    </div>
  ) : null;
};

ReportSegmentComment.propTypes = {
  comment: T.shape({
    comment: T.string,
    text: T.string,
  }),
};

ReportSegmentComment.defaultProps = {
  comment: {
    comment: '',
  },
};

export default ReportSegmentComment;
