import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Hint from 'components/HintTooltip';
// styles
import classnames from 'classnames/bind';
import styles from './PlusMoreHint.module.scss';

const cn = classnames.bind(styles);

const PlusMoreHint = ({ items, children, itemsToShow, separator, label, className, nameKey }) => {
  if (items.length === 0) return null;

  const itemsNames = items.map((item) => item[nameKey]);
  const shownItems = itemsNames.slice(0, itemsToShow);
  const otherItems = itemsNames.slice(itemsToShow);

  return (
    <div className={cn('items-line', className)}>
      {children}
      {shownItems.join(separator)}&nbsp;
      {itemsNames.length > itemsToShow && (
        <div className={cn('hint')}>
          {label || <FormattedMessage id="component.plusmore.more" values={{ amount: otherItems.length }} />}
          <Hint className={cn('hint-body')} attachToBody showBelow arrowed>
            {otherItems.map((item, idx) => (
              <span key={`item-tooltip-${item}-${idx}`}>
                {item}
                {idx !== (otherItems.length - 1) && separator}
              </span>
            ))}
          </Hint>
        </div>
      )}
    </div>
  );
};

PlusMoreHint.propTypes = {
  items: T.array.isRequired,
  children: T.node,
  itemsToShow: T.number,
  separator: T.string,
  label: T.node,
  className: T.string,
  nameKey: T.string,
};

PlusMoreHint.defaultProps = {
  itemsToShow: 1,
  separator: ' • ',
  nameKey: 'name',
};

export default PlusMoreHint;
