import React, { Component } from 'react';
import T from 'prop-types';
// redux, reconnect
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import NothingBox from 'components/NothingBox';
import CircleCheckbox from 'components/CircleCheckbox';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import cn from 'classnames';
// styles
import './UsersFarmsRolesManageTable.scss';

class UsersFarmsRolesManageTable extends Component {

  state = {
    markers: {},
  };

  setMarkerValue = (key, value) => {
    this.setState((prevState) => {
      return { markers: { ...prevState.markers, [key]: value } };
    });
  };

  getMarkerValue = (key) => {
    return this.state.markers[key] || false;
  };

  getMarkerKey = (farm, name) => {
    return `${name}_${farm.id}`;
  };

  onChangeFarmOwner = (farm) => (e) => {
    const { openModal, handleRoleChanged, user: { full_name } } = this.props;
    const { name: target_name, checked } = e.target;
    const { id, name: farm_name, is_owner, owner } = farm;

    if (is_owner || !owner) {
      return handleRoleChanged(id, target_name, checked);
    }

    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.changeFarmOwner.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.changeFarmOwner.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.changeFarmOwner.warning" />}
        handleConfirm={() => handleRoleChanged(id, target_name, checked)}
      >
        <FormattedMessage
          id="component.modal.changeFarmOwner.text"
          values={{
            farmName: <strong>{farm_name}</strong>,
            ownerName: <strong>{owner.full_name}</strong>,
            newOwnerName: <strong>{full_name}</strong>,
          }}
          tagName="p"
        />
      </ConfirmationModal>
    );
  };

  handleRoleChange = (farm) => (e) => {
    const { name, checked } = e.target;
    const markerKey = this.getMarkerKey(farm, name);
    this.setMarkerValue(markerKey, true);
    this.props.handleRoleChanged(farm.id, name, checked)
      .then(() => { this.setMarkerValue(markerKey, false); })
      .catch(() => { this.setMarkerValue(markerKey, false); });
  };

  isDisabledFarmRoleCheckbox = (farm, roleName) => {
    const markerKey = this.getMarkerKey(farm, roleName);
    return this.getMarkerValue(markerKey);
  };

  render() {
    const { data, search, filter, isLoading, user: { disabled } } = this.props;

    return (
      <div className="UsersFarmsRolesManageTable">
        <div className="flex-data-table">

          {data.map((farm, idx) => {
            const { name, farm_type, is_owner, is_manager, is_caretaker, is_vet } = farm;
            return (
              <div className="table-item" key={idx}>
                {/* Farm Name */}
                <div className="table-item-block column-farm-name">
                  <div className="collapsible-value">
                    <div className="value-amount title">
                      <div className="user-info">
                        <FarmTitleBox name={name} type={farm_type} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Owner Role */}
                <div className={cn('table-item-block column-roles', { 'checked': is_owner, disabled })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.farmOwner" />
                          </span>
                        )}
                        name="owner"
                        onChange={this.onChangeFarmOwner(farm)}
                        checked={is_owner}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                {/* Manager Role */}
                <div className={cn('table-item-block column-roles', { 'checked': is_manager, disabled })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.manager" />
                          </span>
                        )}
                        name="manager"
                        onChange={this.handleRoleChange(farm)}
                        checked={is_manager}
                        disabled={disabled || this.isDisabledFarmRoleCheckbox(farm, 'manager')}
                      />
                    </div>
                  </div>
                </div>
                {/* Caregiver Role */}
                <div className={cn('table-item-block column-roles', { 'checked': is_caretaker, disabled })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.caregiver" />
                          </span>
                        )}
                        name="caretaker"
                        onChange={this.handleRoleChange(farm)}
                        checked={is_caretaker}
                        disabled={disabled || this.isDisabledFarmRoleCheckbox(farm, 'caretaker')}
                      />
                    </div>
                  </div>
                </div>
                {/* Veterinarian Role */}
                <div className={cn('table-item-block column-roles', { 'checked': is_vet, disabled })}>
                  <div className="collapsible-value no-border with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.veterinarian" />
                          </span>
                        )}
                        name="vet"
                        onChange={this.handleRoleChange(farm)}
                        checked={is_vet}
                        disabled={disabled || this.isDisabledFarmRoleCheckbox(farm, 'vet')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <NothingBox
          itemsName="roles"
          display={!data.length}
          isLoading={isLoading}
          search={search}
          filter={filter}
        >
          <h1><FormattedMessage id="general.nothingBox.noRoles" /></h1>
        </NothingBox>
      </div>
    );
  }
}

UsersFarmsRolesManageTable.propTypes = {
  data: T.array,
  handleRoleChanged: T.func,
  user: T.object,
  openModal: T.func,
  search: T.string,
  filter: T.oneOfType([T.string, T.object]),
  isLoading: T.bool,
};

export default connect(
  null,
  { openModal }
)(UsersFarmsRolesManageTable);
