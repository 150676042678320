import { handleActions, createAction } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  mobileTitle: '',
  renderMobileTitle: null,
  backLink: '',
  rightButton: '',
  pinHeader: false,
  showHeader: true,
  showHeaderNotification: false,
  showAdvancedSearch: false,
  links: null,
  isHeaderHidden: false,
  inEditMode: false,
  inSuperAdminMode: false,
  isSchedulePageOutdated: false,
  isFullScreenMode: false,
};

const SET_PAGE_OPTIONS = 'layout/SET_PAGE_OPTIONS';
const SET_HEADER_VISIBILITY = 'layout/SET_HEADER_VISIBILITY';
const SET_NOTIFICATION_VISIBILITY = 'layout/SET_NOTIFICATION_VISIBILITY';
const SET_SEARCH_VISIBILITY = 'layout/SET_SEARCH_VISIBILITY';
const SET_IS_SCHEDULE_PAGE_OUTDATED = 'layout/SET_IS_SCHEDULE_PAGE_OUTDATED';

// ------------------------------------
// Actions
// ------------------------------------
export const setPageOptions = createAction(SET_PAGE_OPTIONS);
export const setHeaderVisibility = createAction(SET_HEADER_VISIBILITY);
export const showTenantNotification = createAction(SET_NOTIFICATION_VISIBILITY);
export const setAdvancedSearchVisibility = createAction(SET_SEARCH_VISIBILITY);
export const setIsSchedulePageOutdated = createAction(SET_IS_SCHEDULE_PAGE_OUTDATED);

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({
  [SET_PAGE_OPTIONS]: (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  },
  [SET_HEADER_VISIBILITY]: (state, action) => ({
    ...state,
    showHeader: action.payload,
  }),
  [SET_SEARCH_VISIBILITY]: (state, action) => ({
    ...state,
    showAdvancedSearch: action.payload,
  }),
  [SET_NOTIFICATION_VISIBILITY]: (state, action) => ({
    ...state,
    showHeaderNotification: action.payload,
  }),
  [SET_IS_SCHEDULE_PAGE_OUTDATED]: (state, action) => ({
    ...state,
    isSchedulePageOutdated: action.payload,
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
