import React, { useEffect } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import { CustomColumn, ArrowColumn } from 'components/DataTable/Columns';
import FarmTypeLabel from 'components/FarmTypeLabel/FarmTypeLabel';
import Dropdown from 'components/Dropdown/Dropdown2';
// hooks
import useDataTableController from 'hooks/useDataTableController';
// utils
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatGrams } from 'utils';
import { getStatsCaption } from 'utils/treatmentChartsHelper';
import { formatDateToMomentObj } from 'utils/formatDateHelper';

const AntibioticFarmsTable = ({
  farmParams,
  fetchData,
  isLoading,
  meta,
  params,
  resources,
  statsKeys,
}) => {
  const { onPageChange, onPerPageChange, onSearchChange, onSortChange } = useDataTableController(fetchData, farmParams);
  const { search, sort, page, per_page } = farmParams;

  useEffect(() => {
    const { period_name, date_start, date_end } = params;
    if ((period_name === 'custom') && (!date_start || !date_end)) return;
    fetchData({ ...farmParams, ...params, page: 1 }).catch(toastResponseErrors);
  }, [params.farm_type, params.period_name, params.date_start, params.date_end]);

  const renderFarmColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
      <Link to={`/barnsheets/farms/${rowData.id}/treatments/antibiotic`} className="color-primary">
        {rowData.name}
      </Link>
    </CustomColumn>
  );

  const renderFarmTypeColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      <FarmTypeLabel farmType={rowData.farm_type} />
    </CustomColumn>
  );

  const renderStatItemColumn = (key, label) => (data) => {
    const mgValue = data[key];
    return (
      <CustomColumn label={label}>
        {mgValue ? (<span>{formatGrams(mgValue)} <FormattedMessage id="general.short.grams" /></span>) : '-'}
      </CustomColumn>
    );
  };

  const renderButtonsColumn = (rowData, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <Dropdown
        idKey={`antibiotic-farm-${rowData.id}-${rowIndex}`}
        options={[
          { label: <FormattedMessage id="general.viewFarmAntibioticUsage" />,
            url: `/barnsheets/farms/${rowData.id}/treatments/antibiotic` }
        ]}
        className="show-for-large"
      />
      <Link to={`/barnsheets/farms/${rowData.id}/treatments/antibiotic`} className="button light hide-for-large">
        <FormattedMessage id="general.viewFarmAntibioticUsage" />
      </Link>
    </div>
  );

  const renderExpandable = (rowData) => (
    <div>
      <Link to={`/barnsheets/farms/${rowData.id}/treatments/antibiotic`} className="button small light">
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.viewFarmAntibioticUsage" />
      </Link>
    </div>
  );

  const getStatsColumns = () => {
    const { period_name, date_start, date_end } = params;
    const dateRange = { date_start: formatDateToMomentObj(date_start), date_end: formatDateToMomentObj(date_end) };
    return statsKeys.map((key, index) => {
      const label = getStatsCaption[period_name](statsKeys.length - index, dateRange);
      return { label, flex: '1 1 100px', renderer: renderStatItemColumn(key, label), sortKey: key };
    });
  };

  const columns = [
    { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
      renderer: renderFarmColumn, sortKey: 'name' },
    { label: <FormattedMessage id="general.type" />, flex: '1 1 60px', renderer: renderFarmTypeColumn,
      sortKey: 'farm_type' },
    ...getStatsColumns(),
    { label: '', flex: '0 0 45px', renderer: renderButtonsColumn, className: isTablet ? 'hide-for-large' : '',
      fixed: true },
    { label: '', flex: '0 0 45px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  const paginationProps = {
    totalItems: meta.total,
    currentPage: page,
    perPage: per_page,
    onPageChange,
    onPerPageChange,
  };

  return (
    <section>
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.farms" />}>
          <SearchBox initialValue={search} onChange={onSearchChange} />
        </Panel.Heading>
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={renderExpandable}
            scrollable
            tableKey="farmsAntibiotics"
          />

          <NothingBox
            itemsName="farms"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noFarmsToDisplay" />
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

AntibioticFarmsTable.propTypes = {
  params: T.object.isRequired,
  statsKeys: T.array.isRequired,
  isLoading: T.bool.isRequired,
  resources: T.array.isRequired,
  farmParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
};

export default connect(
  (state) => ({
    resources: state.dataTable.farmsAntibiotics.resources,
    farmParams: state.dataTable.farmsAntibiotics.params,
    meta: state.dataTable.farmsAntibiotics.meta,
    isLoading: state.dataTable.farmsAntibiotics.isLoading,
  }), (dispatch) => ({
    fetchData: (query) => dispatch(fetchData('/antibiotic_tracking/tenants/farms', 'farmsAntibiotics', query)),
  }),
)(AntibioticFarmsTable);
