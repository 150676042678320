import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ReactSelect from 'components/Select/ReactSelect';
// api
import { getSymptoms } from 'endpoints/common';
// assets
import './SymptomDropdownButton.scss';

const activeItem = { id: 0, name: <FormattedMessage id="general.allSymptomaticFarms" /> };

class SymptomDropdownButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resources: [activeItem],
      activeItem,
    };
  }

  componentDidMount() {
    this.fetchSymptoms();
  }

  fetchSymptoms = () => {
    getSymptoms()
      .then((resources) => {
        this.setState((prevState) => ({
          resources: [...prevState.resources, ...resources],
        }));
      });
  };

  selectSymptom = (item) => {
    if (!item) return;
    const { getRangeData, rangeType } = this.props;
    this.setState({ activeItem: item });
    getRangeData(rangeType, item.id);
  };

  render() {
    const { resources, activeItem } = this.state;
    return (
      <div className="SymptomDropdownButton">
        <ReactSelect
          placeholder={<FormattedMessage id="general.selectSymptom" />}
          options={resources}
          valueKey="id"
          labelKey="name"
          arrowRenderer={() => <i className="fa fa-angle-down" />}
          value={activeItem.id}
          onChange={this.selectSymptom}
          resetValue={{}}
          clearable={false}
          noInputMargin
        />
      </div>
    );
  }
}

SymptomDropdownButton.propTypes = {
  getRangeData: T.func.isRequired,
  rangeType: T.string,
};

export default SymptomDropdownButton;
