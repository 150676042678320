import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import Modal from 'components/Modal';
import Button from 'components/Button';
import CircleCheckbox from 'components/CircleCheckbox';
import DateRangePicker from 'components/DatePicker/DateRangePicker';
// requests
import { getInventoriesCSV, getInventoriesStats } from 'endpoints/inventories';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import download from 'downloadjs';
import pickBy from 'lodash.pickby';
import moment from 'moment';
// styles
import './BatchDownloadModal.scss';

const fileTypeOptions = [
  { value: 'multiple_files', label: <FormattedMessage id="component.modal.batchDownload.type.multipleFiles" /> },
  { value: 'one_file', label: <FormattedMessage id="component.modal.batchDownload.type.oneFile" /> },
];

const getStatusOptions = (stats) => [
  {
    value: 'all_reports',
    label: <FormattedMessage id="component.modal.batchDownload.status.all" />,
    count: (stats.pending || 0) + (stats.accepted || 0) + (stats.submitted || 0),
  },
  {
    value: 'pending',
    label: <FormattedMessage id="component.modal.batchDownload.status.pending" />,
    count: stats.pending,
  },
  {
    value: 'accepted',
    label: <FormattedMessage id="component.modal.batchDownload.status.accepted" />,
    count: stats.accepted,
  },
  {
    value: 'submitted',
    label: <FormattedMessage id="component.modal.batchDownload.status.submitted" />,
    count: stats.submitted,
  },
];

function formatDatesParams(startDate, endDate) {
  const started_on = moment(startDate).startOf('day').format('YYYY-MM-DD');
  const ended_on = moment(endDate).endOf('day').format('YYYY-MM-DD');
  return { started_on, ended_on };
}

const BatchDownloadModal = ({ closeModal, isAdmin, refreshInventories }) => {
  const [{ isAutoAccept, isSubmitting, endDate, startDate, stats, status, type }, setState] = useState({
    isAutoAccept: true,
    isSubmitting: false,
    endDate: moment(new Date()),
    startDate: moment(new Date()).add(-14, 'days'),
    stats: {},
    status: '',
    type: '',
  });
  const showAcceptAll = isAdmin && !!status && (status !== 'accepted');
  const statusOptions = getStatusOptions(stats);

  const onDatesChange = ({ startDate, endDate }) => {
    setState((prevState) => ({ ...prevState, startDate, endDate }));
  };

  const getStats = () => {
    const params = formatDatesParams(startDate, endDate);
    return getInventoriesStats(params, isAdmin)
      .then((stats) => setState((prevState) => ({ ...prevState, stats })))
      .catch(toastResponseErrors);
  };

  const onSubmit = () => {
    const isAcceptAll = isAdmin && isAutoAccept && !!status && (status !== 'accepted');
    const dates = formatDatesParams(startDate, endDate);
    const params = pickBy({ type, ...dates, status: status || undefined, accept_all: isAcceptAll });
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    return getInventoriesCSV(params, isAdmin)
      .then((blob) => {
        if (type === 'multiple_files') {
          download(blob, `inventories-${dates.started_on}-${dates.ended_on}.zip`, 'application/zip');
        } else {
          download(blob, `inventories-${dates.started_on}-${dates.ended_on}.csv`, 'text/csv');
        }
        closeModal();
        if (isAcceptAll) {
          refreshInventories();
          showToastrMessage('component.toastr.inventories.reportsAccepted');
        }
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isSubmitting: false }));
        return toastResponseErrors(errors);
      });
  };

  return (
    <Modal title={<FormattedMessage id="component.modal.batchDownload.title" />} className="BatchDownloadModal">
      <section className="modal-body">

        <div className="row mb-10">
          <FormattedMessage tagName="label" id="component.modal.batchDownload.selectTimePeriod" />
          <DateRangePicker
            isOutsideRange={(day) => day.isSameOrAfter(moment(new Date()))}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onDatesChange}
          />
        </div>

        {/* type */}
        <div className="row mb-10">
          <FormattedMessage tagName="label" id="component.modal.batchDownload.fileType" />
          <DropdownSelect
            value={type}
            onChange={(type) => setState((prevState) => ({ ...prevState, type }))}
            placeholder={<FormattedMessage id="component.modal.batchDownload.selectType" />}
            options={fileTypeOptions}
          />
        </div>

        {/* status */}
        <div className="row mb-10">
          <FormattedMessage tagName="label" id="component.modal.batchDownload.reportStatus" />
          <DropdownSelect
            value={status}
            onChange={(status) => setState((prevState) => ({ ...prevState, status }))}
            onFocus={getStats}
            placeholder={<FormattedMessage id="component.modal.batchDownload.selectStatus" />}
            options={statusOptions}
          />
        </div>

        {showAcceptAll && (
          <div className="row animated fadeIn">
            <CircleCheckbox
              className="accept-all-checkbox"
              name="accept_all"
              checked={isAutoAccept}
              onChange={() => setState((prevState) => ({ ...prevState, isAutoAccept: !prevState.isAutoAccept }))}
              label={<FormattedMessage id="component.modal.batchDownload.acceptAll" />}
            />
          </div>
        )}
      </section>
      <section className="modal-footer text-right sticky-on-mobile">
        <Button className="mr-10" onClick={closeModal}>
          <FormattedMessage id="general.button.cancel" />
        </Button>
        <Button primary onClick={onSubmit} disabled={!(startDate && endDate && type && status) || isSubmitting}>
          <FormattedMessage id={`general.button.${isSubmitting ? 'submitting' : 'download'}`} />
        </Button>
      </section>
    </Modal>
  );
};

BatchDownloadModal.propTypes = {
  closeModal: T.func.isRequired,
  refreshInventories: T.func.isRequired,
  isAdmin: T.bool,
};

export default connect(null, { closeModal })(BatchDownloadModal);
