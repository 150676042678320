import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchSingleCheckin } from 'reducers/checkins/singleCheckin';
import { fetchAllFarms } from 'reducers/checkins/allFarms';
import { setPageOptions } from 'reducers/layout';
// component
import { FormattedMessage } from 'react-intl';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import FarmMarker from 'components/GoogleMap/Markers/FarmMarker/FarmMarker';
import FarmMarkerTooltip from 'components/GoogleMap/Markers/FarmMarker/FarmMarkerTooltip';
import UserMarker from 'components/GoogleMap/Markers/UserMarker/UserMarker';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import Preloader from 'components/Preloader';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import getValue from 'lodash.get';
// assets
import './CheckInMap.scss';

const PREFIX = 'container.checkinMap';

class CheckInMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedFarm: {},
      mapZoom: 10,
      mapCenter: null,
    };
  }

  componentDidMount() {
    const { fetchSingleCheckin, setPageOptions, fetchAllFarms, params: { id }, farms } = this.props;
    fetchSingleCheckin(id)
      .catch(toastResponseErrors);
    if (!farms) fetchAllFarms().catch(toastResponseErrors);
    setPageOptions({ title: 'checkins', backLink: '' });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.checkin) return;
    if (nextProps.checkin.id === Number(this.props.params.id)) {
      const { farm } = nextProps.checkin;
      this.setState({
        mapCenter: { lat: farm.lat, lng: farm.lng },
        selectedFarm: farm,
      });
    }
  }

  onMapChange = ({ center, zoom }) => {
    const { checkin, params } = this.props;
    if (checkin.id !== Number(params.id)) return; // prevent map change if current checkin is not loaded
    this.setState({
      mapCenter: center,
      mapZoom: zoom,
    });
  };

  zoomIn = () => {
    const { selectedFarm } = this.state;
    this.setState({
      mapZoom: 13,
      mapCenter: { lat: selectedFarm.lat, lng: selectedFarm.lng },
    });
  };

  selectFarm = (farm) => (entity, e) => {
    this.setState({ selectedFarm: farm });
    if (e) e.stopPropagation();
  };

  render() {
    const { checkin, farms, isLoadingCheckin, isLoadingFarms, currentUser, params } = this.props;
    const { selectedFarm, mapCenter, mapZoom } = this.state;
    const user = getValue(checkin, 'user', {});
    const farm = getValue(checkin, 'farm', {});
    const farmCenter = { lat: farm.lat, lng: farm.lng };
    const isActive = selectedFarm.id === farm.id;
    const isCheckinLoaded = checkin && checkin.id === Number(params.id);

    return (
      <div className="CheckInMap">
        <Preloader isActive={isLoadingCheckin || isLoadingFarms} />

        {isCheckinLoaded && (
          <div className="checkin-wrapper">
            <FarmProfileHeader
              farm={farm}
              isLoading={isLoadingCheckin}
              className="show-for-large"
              dropdownOptions={[
                { label: <FormattedMessage id={`${PREFIX}.dropdown.checkinHistory`} />,
                  url: `/checkins/farms/${farm.id}` },
                { label: <FormattedMessage id={`${PREFIX}.dropdown.barnsheetsHistory`} />,
                  url: `/barnsheets/farms/${farm.id}` },
                { label: <FormattedMessage id="general.manageFarm" />,
                  url: `/admin/farms/${farm.id}`, hide: !currentUser.roles_map.admin },
              ]}
            >
              <div className="right-bar">
                <div className="checkins-count">
                  <div className="checkins-count-label"><FormattedMessage id={`${PREFIX}.checkins`} /></div>
                  <div className="checkins-count-value">{farm.check_ins_count || 'N/A'}</div>
                </div>
              </div>
            </FarmProfileHeader>

            <div className="checkin-map-wrapper">
              <GoogleMap
                center={mapCenter || farmCenter}
                zoom={mapZoom}
                onChange={this.onMapChange}
                style={{ position: 'initial' }}
              >
                <FarmMarker
                  className="map-marker"
                  {...farmCenter}
                  farm={farm}
                  onClick={this.selectFarm(farm)}
                  active={isActive}
                >
                  <FarmMarkerTooltip
                    farm={farm}
                    visible={isActive}
                    onTooltipClose={this.selectFarm({})}
                    zoomIn={this.zoomIn}
                  />
                </FarmMarker>
                <UserMarker user={user} {...farmCenter} />

                {farms && farms.filter(({ id }) => id !== farm.id).map((item, index) => {
                  const isActive = item.id === selectedFarm.id;
                  const isValid = item.lat && item.lng;
                  const coords = { lat: item.lat, lng: item.lng };

                  return (isValid && (
                    <FarmMarker
                      className="map-marker"
                      {...coords}
                      key={index}
                      farm={item}
                      onClick={this.selectFarm(item)}
                      active={isActive}
                    >
                      <FarmMarkerTooltip
                        farm={item}
                        visible={isActive}
                        onTooltipClose={this.selectFarm({})}
                        zoomIn={this.zoomIn}
                      />
                    </FarmMarker>
                  ));
                })}
              </GoogleMap>
            </div>

          </div>
        )}
      </div>
    );
  }
}

CheckInMap.propTypes = {
  checkin: T.object,
  isLoadingCheckin: T.bool,
  farms: T.array,
  isLoadingFarms: T.bool,
  params: T.object.isRequired,
  currentUser: T.object,
  fetchSingleCheckin: T.func.isRequired,
  fetchAllFarms: T.func.isRequired,
  setPageOptions: T.func.isRequired,
};

export default connect(
  (state) => ({
    checkin: state.checkins.singleCheckin.data,
    isLoadingCheckin: state.checkins.singleCheckin.isLoading,
    farms: state.checkins.allFarms.data,
    isLoadingFarms: state.checkins.allFarms.isLoading,
    currentUser: state.auth.user,
  }), {
    fetchSingleCheckin,
    fetchAllFarms,
    setPageOptions,
  }
)(CheckInMap);
