import React from 'react';

import styles from './CardTemplates.module.scss';

import T from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router';

const CardTemplates = ({ title, onClickCompany, imgCard, className, type, choseImport, link, active  }) => {

  if (link && active) return (
    <Link
      className={cn(styles.wrapperCard, className)}
      to={link}
      onClick={() => onClickCompany(type, active)}
    >
      { choseImport === true ? <div className={styles.wrapperImg}> <img src={imgCard} alt="fileImg" /> </div> :
      <div className={styles.uploadFile} /> }
      {title}
    </Link>
  );
  return (
    <div
      className={cn(styles.wrapperCard, className)}
      onClick={() => onClickCompany(type, active)}
    >
      { choseImport === true ? <div className={styles.wrapperImg}> <img src={imgCard} alt="fileImg" /> </div> :
      <div className={styles.uploadFile} /> }
      {title}
    </div>
  );
};

CardTemplates.propTypes = {
  choseImport: T.bool,
  title: T.object,
  onClickCompany: T.func,
  imgCard: T.string,
  className: T.string,
  type: T.string,
  link: T.string,
  active: T.bool,
};

export default CardTemplates;
