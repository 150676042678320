import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import './Button.scss';

export const TransparentBtn = ({ children, className, ...props }) => (
  <Button {...props} className={cn('transparent-btn', className)} transparent>
    {children}
  </Button>
);

TransparentBtn.propTypes = {
  className: T.string,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export const ThreeDotsBtn = ({ className, onClick, dataName = '' }) => (
  <Button onClick={onClick} light className={cn('three-dots-btn', className)}>
    <i data-name={dataName} className="fa fa-dots-three-horizontal" />
  </Button>
);

ThreeDotsBtn.propTypes = {
  className: T.string,
  dataName: T.string,
  onClick: T.func,
};

const Button = (props) => {
  const classes = {
    'primary': props.primary,
    'secondary': props.secondary,
    'transparent': props.transparent,
    'light': props.light,
    'light-gray': props.default,
    'gray': props.gray,
    'dark-grey': props.darkGrey,
    'red': props.red,
    'blue': props.blue,
    'hollow': props.hollow,
    'warning': props.warning,
    'disabled': props.disabled,
    'small': props.small,
    'full-width': props.wide,
    'hide': props.hide,
    'sticky-footer': props.stickyFooter,
  };

  const style = {
    fontWeight: 600,
    ...props.style,
  };

  return (
    <button
      id={props.id}
      tabIndex={props.tabIndex}
      className={cn('button', props.className, classes)}
      disabled={props.disabled}
      onClick={!props.disabled ? props.onClick : null}
      style={style}
      type={props.type}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  primary: T.bool,
  secondary: T.bool,
  transparent: T.bool,
  light: T.bool,
  default: T.bool,
  gray: T.bool,
  darkGrey: T.bool,
  red: T.bool,
  blue: T.bool,
  hollow: T.bool,
  warning: T.bool,
  disabled: T.bool,
  small: T.bool,
  wide: T.bool,
  hide: T.bool,
  stickyFooter: T.bool,
  style: T.object,
  id: T.oneOfType([T.string, T.number]),
  tabIndex: T.oneOfType([T.string, T.number]),
  onClick: T.func,
  type: T.string,
  className: T.string,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default Button;
