import uuid from 'uuid';
import get from 'lodash.get';
import { currentUtcOffsetTime } from './timeHelper';

export const makeCheckupTemplate = (fields) => ({
  id: `fake-${uuid()}`,
  acute_death_count: null,
  alerts: [],
  all_good: false,
  chronic_death_count: null,
  comments: [],
  dc_assets: [],
  dc_symptoms: [],
  dc_treatments: [],
  death_alerts: {
    acute: false,
    chronic: false,
    euthanasia: false,
  },
  deaths_sum: 0,
  estimated_weight: 0,
  euthanasia_death_count: null,
  initial_pigs: null,
  next_checkup_id: null,
  notes: null,
  pig_group: {},
  pig_migrations: [],
  pig_migration_count: null,
  pigs: 0,
  role_names: [],
  submitted: false,
  symptom_count: null,
  svrs: [],
  treatment_count: null,
  treatment_efficacies: [],
  low_temperature: null,
  high_temperature: null,
  temperature_reported: false,
  water_usage_reported: false,
  water_usage_value: null,
  ...fields,
});

export const generateAllGoodCheckup = (group, farm, additionalData = {}) => makeCheckupTemplate({
  pig_group_id: group.id,
  pigs: get(group, 'pigs', 0),
  initial_pigs: get(group, 'pigs', 0),
  farm_id: farm.id,
  pig_group: {
    ...group,
    farm,
  },
  temperature_reported: true,
  all_good: true,
  acute_death_count: 0,
  chronic_death_count: 0,
  euthanasia_death_count: 0,
  deaths_sum: 0,
  treatment_count: 0,
  symptom_count: 0,
  diagnosis_count: 0,
  updated_at: Date.now(),
  created_on: currentUtcOffsetTime(farm.utc_offset).format('YYYY-MM-DD'),
  variety: 'default',
  submitted: true,
  total_pigs_treated: 0,
  pig_migration_count: 0,
  pig_migrations: [],
  ...additionalData,
});
