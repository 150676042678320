import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import BarnSheetsContainer from '../BarnSheetsContainer';
import Link from 'react-router/lib/Link';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import TableFilter from 'components/TableFilter';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ProgressPercent from 'components/ProgressBar/ProgressPercent';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// api
import { getBarnSheetsCompanies } from 'endpoints/barnsheets/companies';
// utils, hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import { useDropdownActions } from 'hooks/useDropdownContext';
import { formatMortality, formatTableValue } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isTablet } from 'react-device-detect';
import { tableNames } from 'utils/constants';

const tableFilters = [
  { label: <FormattedMessage id="general.allCompanies" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.incomplete" />, value: 'incomplete' },
];

const BarnSheetsCompanies = ({
  isAdmin,
  setBreadcrumbs,
  setTableParams,
  tableParams,
}) => {
  const { openDropdown } = useDropdownActions();
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onStatusChange,
    onSearchChange,
  } = useDataTable(getBarnSheetsCompanies, {
    setTableParams: (params) => setTableParams(tableNames.barnSheetsCompanies, params),
    tableParams,
  });
  const { page, per_page, search, sort, status } = tableParams;
  const { total, stats } = meta;

  useEffect(() => {
    fetchData({ ...tableParams, sort: tableParams.sort || 'status_active desc' }).catch(toastResponseErrors);
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.pageTitle.barnsheets" />,
        path: '/barnsheets/companies',
        useLabelAsMobileTitle: true,
      },
    ]);
  }, []);

  const handleOpenDropdown = (e, id) => {
    const options = [
      { label: <FormattedMessage id="general.viewBarnSheet" />, url: `/barnsheets/companies/${id}/farms` },
      { label: <FormattedMessage id="general.manageCompany" />, url: `/admin/companies/${id}`, hide: !isAdmin },
    ];
    openDropdown(e, options);
  };

  const renderNameColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.companyName" />}>
      <Link to={`/barnsheets/companies/${rowData.id}/farms`} className="color-primary">
        {rowData.name}
      </Link>
    </CustomColumn>
  );

  const renderFarmsColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="general.farms" />}>
      {formatTableValue(rowData.farms_count, Number(rowData.farms_count))}
    </CustomColumn>
  );

  const renderGroupsColumn = (rowData) => {
    const { open_pig_groups_count } = rowData;
    return (
      <CustomColumn textRight label={<FormattedMessage id="general.openGroups" />}>
        {formatTableValue(open_pig_groups_count, Number(open_pig_groups_count || 0))}
      </CustomColumn>
    );
  };

  const renderTreatedColumn = (rowData) => {
    const { avg_treated_24h } = rowData;
    return (
      <CustomColumn textRight label={<FormattedMessage id="general.treatedPeriod" values={{ period: '24HR' }} />}>
        {formatTableValue(avg_treated_24h, Number(avg_treated_24h).toFixed(2) + '%', '-')}
      </CustomColumn>
    );
  };

  const renderInventoryColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.inventory" />}>
      {formatTableValue(rowData.pigs_count)}
    </CustomColumn>
  );

  const renderMortalityColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.mortRate" />}>
      {formatMortality(rowData.avg_mortality_rate)}
    </CustomColumn>
  );

  const renderProgressColumn = (rowData) => (
    <CustomColumn
      textRight
      noBottomBorder
      label={<FormattedMessage id="component.dataTable.headers.compliance" />}
    >
      {formatTableValue(rowData.avg_progress, <ProgressPercent progress={rowData.avg_progress} />)}
    </CustomColumn>
  );

  const renderButtonsColumn = (rowData, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <i
        data-name={`${rowData.id}-${rowIndex}`}
        className="fa fa-dots-three-horizontal show-for-large"
        onClick={(e) => handleOpenDropdown(e, rowData.id)}
      />
      <Link to={`/barnsheets/companies/${rowData.id}/farms`} className="button light hide-for-large">
        <FormattedMessage id="general.viewBarnSheet" />
      </Link>
      {isAdmin && (
        <Link to={`/admin/companies/${rowData.id}`} className="button dark-grey hide-for-large">
          <FormattedMessage id="general.manageCompany" />
        </Link>
      )}
    </div>
  );

  const renderExpandable = (rowData) => (
    <div>
      {isAdmin && (
        <Link to={`/admin/companies/${rowData.id}`} className="button small light">
          <i className="fa fa-gear mr-5" />
          <FormattedMessage id="general.manage" />
        </Link>
      )}
      <Link to={`/barnsheets/companies/${rowData.id}/farms`} className="button small light">
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.viewBarnSheet" />
      </Link>
    </div>
  );

  const renderTitle = () => (
    <h2 className="lighter show-for-large"><FormattedMessage id="general.companies" /></h2>
  );

  const columns = [
    { label: <FormattedMessage id="general.companyName" />, flex: '1 1 140px', renderer: renderNameColumn,
      sortKey: 'name' },
    { label: <FormattedMessage id="general.farms" />, flex: '1 1 50px', renderer: renderFarmsColumn,
      sortKey: 'farms_count', textRight: true },
    { label: <FormattedMessage id="general.openGroups" />, flex: '1 1 95px', renderer: renderGroupsColumn,
      sortKey: 'open_pig_groups_count', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.inventory" />, flex: '1 1 70px',
      renderer: renderInventoryColumn, sortKey: 'pigs_count', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.mortRate" />, flex: '1 1 100px',
      renderer: renderMortalityColumn, sortKey: 'avg_mortality_rate', textRight: true },
    { label: <FormattedMessage id="general.treatedPeriod" values={{ period: '24HR' }} />, flex: '1 1 140px',
      renderer: renderTreatedColumn, textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.compliance" />, flex: '1 1 90px',
      renderer: renderProgressColumn, sortKey: 'avg_progress', textRight: true },
    { label: '', flex: '0 0 45px', renderer: renderButtonsColumn, fixed: true,
      className: isTablet ? 'hide-for-large' : '' },
    { label: '', flex: '0 0 45px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  return (
    <BarnSheetsContainer>
      <Panel className="companies-panel panel-padding">
        <Panel.Heading renderTitle={renderTitle}>
          <SearchBox initialValue={search} onChange={onSearchChange} />
        </Panel.Heading>
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />

          <TableFilter
            filters={tableFilters}
            onFilterChange={onStatusChange}
            activeFilter={status}
            stats={stats}
            className="mh-10"
          />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            isLoading={isLoading}
            renderExpandable={renderExpandable}
            scrollable
          />

          <NothingBox
            itemsName="barnsheets"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
          >
            <h2 className="lighter"><FormattedMessage id="component.nothingBox.noBarnSheets" /></h2>
          </NothingBox>
        </Panel.Body>
      </Panel>
    </BarnSheetsContainer>
  );
};

BarnSheetsCompanies.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsCompanies.propTypes = {
  isAdmin: T.bool.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.barnSheetsCompanies),
    isAdmin: state.auth.user.roles_map.admin,
  }), {
    setBreadcrumbs,
    setTableParams,
  }
)(BarnSheetsCompanies);
