import React, { Component } from 'react';
import T from 'prop-types';
// components
import GoogleMap from 'components/GoogleMap/GoogleMap';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// utils
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import { getFullAddress } from 'utils';
import Geocode from 'utils/geocodeHelper';
// styles
import './GoogleMapSelect.scss';

const initPosition = { lat: 41.79562614187575, lng: -74.74447008123319 };

class GoogleMapSelect extends Component {

  componentDidMount() {
    if (this.props.hasLocationDefining) this.setUserCurrentLocation();
  }

  setUserCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { lat, lng } = this.props.addressData || {};
        if (lat && lng) return;

        const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.handleClick(latlng);

      }, (err) => toastResponseErrors({ error: err.message }));
    }
  };

  handleClick = async (latlng) => {
    const { onChangeAdress } = this.props;
    const {
      countryKey,
      city,
      zipcode,
      strNumber,
      route,
      region,
    } = await Geocode.fromLatLng([latlng.lat, latlng.lng]);

    const sendData = {
      country_code: countryKey || '',
      address: [strNumber, route].filter(Boolean).join(' ').trim() || '',
      city,
      state: region,
      zipcode,
      lat: latlng.lat,
      lng: latlng.lng,
    };

    onChangeAdress(sendData);
  };

  getCoords = () => {
    const { addressData } = this.props;
    return (isEmpty(addressData) || (!addressData.lat && !addressData.lng))
      ? initPosition
      : pick(addressData, ['lat', 'lng']);
  }

  render() {
    const { zoom, addressData, children, mapRef, onZoomAnimationEnd } = this.props;
    const showMarker = !isEmpty(addressData) && !!getFullAddress(addressData);
    const coords = this.getCoords();
    return (
      <div className="GoogleMapSelect">
        <GoogleMap
          onClick={this.handleClick}
          center={coords}
          zoom={zoom || 14}
          mapRef={mapRef}
          onZoomAnimationEnd={onZoomAnimationEnd}
        >
          {!isEmpty(coords) && showMarker && children(coords)}
        </GoogleMap>
      </div>);
  }
}

GoogleMapSelect.propTypes = {
  onChangeAdress: T.func.isRequired,
  addressData: T.object,
  zoom: T.number,
  hasLocationDefining: T.bool,
  children: T.func.isRequired,
  mapRef: T.object,
  onZoomAnimationEnd: T.func,

};


export default GoogleMapSelect;
