import { fetchFromAPI } from 'utils/api';

export const removeTenantAsset = (id) => {
  return fetchFromAPI(`/company_assets/${id}`, { method: 'delete' })
    .catch((response) => {
      throw response;
    });
};

export const getUnseenTenantAssetsCount = () => {
  return fetchFromAPI('/company_assets/unviewed_company_assets_count');
};

export const markViewedAssets = (viewedIds) => {
  return fetchFromAPI('/company_assets/mark_viewed_assets', {
    method: 'post',
    body: JSON.stringify({ resource: { company_assets_ids: viewedIds } }),
  })
    .catch((response) => {
      throw response;
    });
};

export const createLinkAsset = (link, name) => {
  return fetchFromAPI('/company_link_assets', {
    method: 'post',
    body: JSON.stringify({ resource: { link, name } }),
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateLinkAsset = (id, link, name) => {
  return fetchFromAPI(`/company_link_assets/${id}`, {
    method: 'put',
    body: JSON.stringify({ resource: { link, name } }),
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateAssetName = (id, name) => {
  return fetchFromAPI(`/company_assets/${id}`, {
    method: 'put',
    body: JSON.stringify({ resource: { name } }),
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
