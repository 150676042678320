import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

const Pagination = ({ count, current, onPrev, onNext, totalItems, perPage }) => {
  const start = (current - 1) * perPage + 1;
  const end = (start + perPage) > totalItems ? totalItems : start + perPage - 1;

  return (
    <div className="dataTables_paginate paging_simple_numbers">
      {(count > 1) && (
        <button
          className={cn('paginate_button previous', { 'disabled': current === 1 })}
          disabled={current <= 1}
          onClick={onPrev}
        >
          <div className="button small">
            <i className="fa fa-angle-left-btb" />
          </div>
        </button>
      )}

      <span className="pagination-stats">
        <FormattedMessage
          id="component.pagination.pageOfTotal"
          values={{
            page: <b>{start} - {end}</b>,
            total: <b>{totalItems}</b>,
          }}
        />
      </span>

      {(count > 1) && (
        <button
          className={cn('paginate_button next', { 'disabled': current >= count })}
          disabled={current >= count}
          onClick={onNext}
        >
          <div className="button small">
            <i className="fa fa-angle-right-btb" />
          </div>
        </button>
      )}
    </div>
  );
};

Pagination.propTypes = {
  count: T.number,
  current: T.number,
  perPage: T.number,
  onPrev: T.func,
  onNext: T.func,
  totalItems: T.number,
};

export default Pagination;
