import React from 'react';
import { useDropdownActions } from 'hooks/useDropdownContext';

const withDropdownActions = (Component) => {
  return (props) => {
    const actions = useDropdownActions();
    return <Component {...props} {...actions} />;
  };
};

export default withDropdownActions;
