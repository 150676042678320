import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { compose } from 'recompose';
import { fetchData } from 'reducers/dataTable';
import { connect } from 'react-redux';
// utils
import cn from 'classnames';
import getValue from 'lodash.get';
// components
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn } from 'components/DataTable/Columns/index';
import NothingBox from 'components/NothingBox';
import Subnavigation from 'components/Subnavigation/Subnavigation';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import './ImportResult.scss';

class ImportRenameResult extends Component {

  state = {
    status: 'create',
    isLoading: false,
  };

  componentDidMount() {
    const { fetchData, groupParams } = this.props;
    fetchData({ ...groupParams });
  }

  renderCurrentIDColumn = ({ current_group_name, error }) => {
    return (
      <CustomColumn
        label={<FormattedMessage id="component.dataTable.headers.current_group_name" />}
        className={cn({ 'highlighted': error === 'current_group_name' })}
      >
        {current_group_name || '-'}
      </CustomColumn>
    );
  };

  renderNewIDColumn = ({ new_group_name, error }) => (
    <CustomColumn
      label={<FormattedMessage id="component.dataTable.headers.new_group_name" />}
      className={cn({ 'highlighted': error === 'new_group_name' })}
    >
      {new_group_name || '-'}
    </CustomColumn>
  );

  onGroupStatusChange = (item = {}) => {
    this.setState({ status: item.value });
  };

  render() {
    const { resources, onSortChange, onPageChange, onPerPageChange, meta: { total, import_invalid_rows },
      groupParams: { page, per_page, search, sort } } = this.props;
    const { status, isLoading } = this.state;

    const prepareData = (resources) => {
      return resources.map((item) => ({
        current_group_name: getValue(item, 'formatted_row.current_group_name', ''),
        new_group_name: getValue(item, 'formatted_row.new_group_name', '') || item.name,
      }));
    };

    const data = status === 'create' ? prepareData(resources) : import_invalid_rows;
    const rowKey = status === 'create' ? 'formatted_row.row_index' : 'row_index';

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.current_group_name" />, flex: '1 1 140px',
        renderer: this.renderCurrentIDColumn },
      { label: <FormattedMessage id="component.dataTable.headers.new_group_name" />, flex: '1 1 140px',
        renderer: this.renderNewIDColumn },
    ];

    const items = [
      { value: 'create', label: <FormattedMessage id="general.created" />, stats: total || 0 },
      { value: 'errors', label: <FormattedMessage id="general.errors" />,
        stats: (import_invalid_rows && import_invalid_rows.length) || 0 },
    ];

    const paginationProps = status === 'create' ? {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    } : {};


    return (
      <div className="ImportResult">
        <Subnavigation
          items={items}
          idKey="value"
          labelKey="label"
          currentId={status}
          onSelect={this.onGroupStatusChange}
          className="small-12 column"
          isSticky
        />


        <DataTable
          data={data}
          columns={columns}
          sort={sort}
          onSortChange={onSortChange}
          paginationProps={paginationProps}
          scrollable
          tableKey="renameGroups"
          rowKey={rowKey}
        />

        <NothingBox
          itemsName="groups"
          display={!data.length}
          isLoading={isLoading}
          search={search}
        >
          <FormattedMessage tagName="h2" id="component.nothingBox.noData" />
        </NothingBox>
      </div>
    );
  }
}

ImportRenameResult.propTypes = {
  fetchData: T.func,
  groupParams: T.object,
  resources: T.array,
  meta: T.object,
  onSortChange: T.func,
  onPageChange: T.func,
  onPerPageChange: T.func,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.renameGroups.resources,
      groupParams: state.dataTable.renameGroups.params,
      meta: state.dataTable.renameGroups.meta,
      isLoading: state.dataTable.renameGroups.isLoading,
    }), (dispatch, { groupImportId }) => ({
      fetchData: (query) => dispatch(
        fetchData(
          `/admin/pig_groups_imports/${groupImportId}/pig_groups_import_details`,
          'renameGroups',
          query
        )
      ),
    }),
  ),
  withDataTableController('fetchData', 'groupParams'),
);

export default enhance(ImportRenameResult);
