import React from 'react';
import classnames from 'classnames/bind';
import T from 'prop-types';
// components
import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import ExpandSection from './ExpandSection';
// utils
import moment from 'moment';
// styles
import styles from './SynchroniseModal.module.scss';

const cn = classnames.bind(styles);

const SynchroniseModal = ({ groups }) => {
  return (
    <Modal
      title={(
        <FormattedMessage
          id="component.groups.synchronizationTitle"
          values={{
            date: moment().format('MM/DD/YYYY'),
          }}
        />
      )}
    >
      <section className="modal-body">
        {!!groups.created.length && (
          <ExpandSection
            groups={groups.created}
            success
            icon={<i className={cn('fa fa-2x fa-check green')} />}
          />
        )}
        {!!groups.not_created.length && (
          <>
            <hr />
            <ExpandSection
              groups={groups.not_created}
              icon={<i className={cn('fa fa-2x fa-ban red')} />}
            />
          </>
        )}
      </section>
    </Modal>
  );
};


SynchroniseModal.propTypes = {
  groups: T.object.isRequired,
};

export default SynchroniseModal;
