import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import AnalyticsPanel from 'components/Analytics/AnalyticsPanel';
import Amchart from 'components/Amchart';
import GraphLegend from '../../GraphLegend/GraphLegend';
import SymptomDropdownButton from '../SymptomDropdownButton';
// api
import { getAnalyticsSymptoms } from 'endpoints/admin/analytics';
// utils
import { getArrowClass, getRateClass } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import values from 'lodash.values';
import isNull from 'lodash.isnull';
import moment from 'moment';
// constants
import { defaultSerialChartConfig } from 'components/Amchart/chartConfig';
import { symptomsBalloonHTML } from 'components/Amchart/GraphItemBalloon/GraphItemBalloon';
import { rangeOptions } from 'constants.js';

class SymptomsPanel extends Component {

  state = {
    data: {
      graphDates: [],
      rangeType: '7_days',
      isLoading: false,
    },
  };

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data: { rangeType, symptom_id } } = this.state;
    if (nextProps.companyId !== this.props.companyId) this.getData(rangeType, symptom_id, nextProps.companyId);
  }

  getData = (type = '7_days', symptom_id, companyId) => {
    const company_id = (isNull(companyId) || companyId) ? companyId : this.props.companyId;
    const params = symptom_id ? { type, company_id, symptom_id } : { type, company_id };
    this.setState(({ data }) => ({
      data: {
        ...data,
        isLoading: true,
      },
    }));

    getAnalyticsSymptoms(params)
      .then((response) => {
        const graphDates = values(response.resource).map((value) => {
          const { symptomatic, symptomatic_was, symptomatic_change, date, date_was } = value;
          return {
            symptomatic,
            symptomatic_was,
            symptomatic_change: Math.abs(symptomatic_change).toFixed(3) + '%',
            blockClass: getRateClass(-symptomatic_change),
            iconClass: getArrowClass(symptomatic_change),
            date: moment(date).format('DD MMM'),
            tooltip_date: moment(date).format('ddd DD MMM'),
            tooltip_date_was: moment(date_was).format('ddd DD MMM'),
          };
        });
        this.setState({
          data: {
            graphDates,
            symptom_id,
            rangeType: type,
            isLoading: false,
          },
        });
      })
      .catch((response) => {
        this.setState(({ data }) => ({
          data: {
            ...data,
            isLoading: false,
          },
        }));
        toastResponseErrors(response);
      });
  };

  renderDropdownButton = () => {
    const { data: { rangeType } } = this.state;
    return <SymptomDropdownButton rangeType={rangeType} getRangeData={this.getData} />;
  };

  render() {
    const { data, data: { rangeType } } = this.state;
    const { formatMessage } = this.context;
    const amChartStyle = { height: '330px' };

    const serialChartConfig = {
      ...defaultSerialChartConfig,
      dataProvider: data.graphDates,
      chartCursor: {
        ...defaultSerialChartConfig.chartCursor,
        cursorAlpha: 0,
      },
      classNameField: 'className',
      columnSpacing: 0,
      addClassNames: true,
      defs: {
        linearGradient: [{
          id: 'brand-blue',
          x1: '0%',
          x2: '0%',
          y1: '100%',
          y2: '0%',
          stop: [{
            offset: '3%',
            'stop-color': '#1567b3',
          }, {
            offset: '103%',
            'stop-color': '#27c0e3',
          }],
        }, {
          id: 'brand-orange',
          x1: '0%',
          x2: '0%',
          y1: '100%',
          y2: '0%',
          stop: [{
            offset: '3%',
            'stop-color': '#f74205',
          }, {
            offset: '103%',
            'stop-color': '#f78445',
          }],
        }],
      },
      graphs: [
        {
          balloonText: symptomsBalloonHTML,
          fillAlphas: 1,
          id: 'AmGraph-1',
          title: formatMessage({ id: 'component.symptomPanel.symptomaticFarms' }),
          type: 'column',
          valueField: 'symptomatic_was',
        },
        {
          balloonText: symptomsBalloonHTML,
          fillAlphas: 1,
          id: 'AmGraph-2',
          title: formatMessage({ id: 'component.symptomPanel.symptomaticFarms' }),
          type: 'column',
          valueField: 'symptomatic',
        },
      ],
    };

    return (
      <AnalyticsPanel
        titleKey="symptoms"
        panelData={data}
        getRangeData={this.getData}
        rangeOptions={rangeOptions.filter((option) => !['today', 'yesterday'].includes(option))}
        fullRow
        renderDropdownButton={this.renderDropdownButton}
      >
        <div className="legend-block in-row">
          <GraphLegend
            backgroundColor="#FF5E30"
            text={<FormattedMessage id={`component.analyticsPanel.rangeLabel.current.${rangeType}`} />}
          />
          <GraphLegend
            backgroundColor="#2170D1"
            text={<FormattedMessage id={`component.analyticsPanel.rangeLabel.${rangeType}`} />}
          />
        </div>
        <div className="chart-block">
          {!!data.graphDates.length &&
            <Amchart style={amChartStyle} config={serialChartConfig} />
          }
        </div>
      </AnalyticsPanel>
    );
  }
}

SymptomsPanel.propTypes = {
  companyId: T.number,
};

SymptomsPanel.contextTypes = {
  formatMessage: T.func,
};

export default SymptomsPanel;
