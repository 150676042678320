import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { deleteCompany } from 'reducers/admin/companies';
import { openModal } from 'reducers/modals';
import { fetchCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import CompanyProfileBox from 'components/Admin/CompanyProfileBox';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const labels = {
  setup: <FormattedMessage id="container.companyProfile.setup" />,
  setupProgress: <FormattedMessage id="container.companyProfile.setupProgress" />,
  setupComplete: <FormattedMessage id="general.setupComplete" />,
  basicInfoSaved: <FormattedMessage id="container.companyProfile.basicInfoSaved" />,
  assignPrimary: <FormattedMessage id="container.companyProfile.assignPrimary" />,
  assignFarms: <FormattedMessage id="container.companyProfile.assignFarms" />,
  companyInfo: <FormattedMessage id="general.companyInformation" />,
  userRoles: <FormattedMessage id="general.userRoles" />,
  managedFarms: <FormattedMessage id="general.managedFarms" />,
  ownedGroups: <FormattedMessage id="general.ownedGroups" />,
  farmAssignments: <FormattedMessage id="general.farmAssignments" />,
  primaryContact: <FormattedMessage id="general.role.primary" />,
};

class CompanyProfile extends Component {

  constructor(props) {
    super(props);
    const companyId = props.params.id;
    this.navLinks = [
      { link: `/admin/companies/${companyId}/user-roles`, label: labels.userRoles },
      { link: `/admin/companies/${companyId}/managed-farms`, label: labels.managedFarms },
      { link: `/admin/companies/${companyId}/owned-groups`, label: labels.ownedGroups },
      { link: `/admin/companies/${companyId}/edit`, label: labels.companyInfo },
    ];
  }

  componentDidMount() {
    const { fetchCompanyProfile, params: { id } } = this.props;
    fetchCompanyProfile(`/admin/companies/${id}`).catch(toastResponseErrors);
  }

  progressData = () => {
    const { params: { id }, company: { is_farm_assigned, primary_contact_id } } = this.props;
    return {
      closeable: !!primary_contact_id && is_farm_assigned,
      headerText: labels.setupProgress,
      steps: [
        { text: labels.basicInfoSaved, done: true },
        { text: labels.assignPrimary, url: `/admin/companies/${id}/assign-user-roles`, done: !!primary_contact_id },
        { text: labels.assignFarms, url: `/admin/companies/${id}/assign-farms`, done: is_farm_assigned },
      ],
    };
  };

  deleteCompany = () => {
    const { router } = this.context;
    const { deleteCompany, params: { id } } = this.props;
    return deleteCompany(id)
      .then(() => {
        showToastrMessage('component.toastr.company.deleted');
        router.push('/admin/companies');
      })
      .catch(toastResponseErrors);
  };

  deleteCompanyConfirm = () => {
    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteCompany" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteCompany.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.deleteCompany.warning" />}
        handleConfirm={this.deleteCompany}
      >
        <FormattedMessage id="component.modal.deleteCompany.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  render() {
    const { children, company, params, isLoading } = this.props;

    const steps = [
      { label: labels.setup, icon: 'fa fa-check', completed: true },
      { label: labels.primaryContact, icon: 'fa fa-user-male', completed: !!company.primary_contact_id },
      { label: labels.farmAssignments, icon: 'fa fa-ep-farm', completed: company.is_farm_assigned },
      { label: labels.setupComplete, icon: 'fa fa-trophy',
        completed: !!company.primary_contact_id && company.is_farm_assigned,
      },
    ];

    return (
      <CompanyProfileBox
        companyId={params.id}
        company={company}
        isLoading={isLoading}
        steps={steps}
        navLinks={this.navLinks}
        onCompanyDelete={this.deleteCompanyConfirm}
        progressData={this.progressData()}
      >
        {children}
      </CompanyProfileBox>
    );
  }
}

CompanyProfile.contextTypes = {
  router: T.object,
};

CompanyProfile.propTypes = {
  deleteCompany: T.func.isRequired,
  fetchCompanyProfile: T.func.isRequired,
  openModal: T.func.isRequired,
  params: T.object.isRequired,
  company: T.object.isRequired,
  isLoading: T.bool.isRequired,
  children: T.object.isRequired,
};

export default connect(
  (state) => ({
    company: state.profiles.company.data,
    isLoading: state.profiles.company.isLoading,
  }), {
    deleteCompany,
    openModal,
    fetchCompanyProfile,
  }
)(CompanyProfile);
