import { fetchFromAPI } from 'utils/api';

export function getPigGroupAntibioticStatistics(pigGroupId) {
  return fetchFromAPI(`/antibiotic_tracking/pig_groups/${pigGroupId}/statistics`).catch((errors) => {
    throw errors;
  });
}

export function getFarmAntibioticStatistics(farmId, params) {
  return fetchFromAPI(`/antibiotic_tracking/farms/${farmId}/statistics`, { params }).catch((errors) => {
    throw errors;
  });
}

export function getTenantAntibioticStatistics(params) {
  return fetchFromAPI('/antibiotic_tracking/tenants/statistics', { params }).catch((errors) => {
    throw errors;
  });
}

export function getFarmTreatmentsCSV(farmId, params) {
  return fetchFromAPI(`/antibiotic_tracking/farms/${farmId}/treatments/csv`, { blob: true, params }).catch((errors) => {
    throw errors;
  });
}

export function getPigGroupTreatmentsCSV(pigGroupId) {
  return fetchFromAPI(`/antibiotic_tracking/pig_groups/${pigGroupId}/treatments/csv`, { blob: true })
    .catch((errors) => {
      throw errors;
    });
}

export function getTenantFarmsAntibioticCSV(params) {
  return fetchFromAPI('/antibiotic_tracking/tenants/farms/csv', { blob: true, params }).catch((errors) => {
    throw errors;
  });
}

export function getTenantTreatmentsAntibioticCSV(params) {
  return fetchFromAPI('/antibiotic_tracking/tenants/treatments/csv', { blob: true, params }).catch((errors) => {
    throw errors;
  });
}
