import { handleActions, createAction } from 'redux-actions';
import pickBy from 'lodash.pickby';
import moment from 'moment';
// endpoints
import { fetchFilteredActivities as getFilteredActivities } from 'endpoints/farmfeed';
import { POST_COMMENT, UPDATE_COMMENT, DELETE_COMMENT, SET_FLAGGED, UPDATE_ACTIVITY } from './farmfeed';
// ------------------------------------
// Constants
// ------------------------------------
const flaggedFilter = {
  any_condition: false,
  sections: [{
    type: 'Flagged',
    condition: 'yes',
    options: [],
  }],
};

const defaultParams = {
  page: 1,
  per_page: 12,
};

const initialState = {
  resources: [],
  meta: {},
  isLoading: false,
  isLoaded: false,
  params: defaultParams,
};

const FETCH_FLAGGED_ACTIVITIES = 'flaggedFarmFeed/FETCH_FLAGGED_ACTIVITIES';
const [FETCH_FLAGGED_ACTIVITIES_PENDING, FETCH_FLAGGED_ACTIVITIES_FULFILLED, FETCH_FLAGGED_ACTIVITIES_REJECTED] = [
  `${FETCH_FLAGGED_ACTIVITIES}_PENDING`,
  `${FETCH_FLAGGED_ACTIVITIES}_FULFILLED`,
  `${FETCH_FLAGGED_ACTIVITIES}_REJECTED`,
];

const LOAD_MORE_ACTIVITIES = 'flaggedFarmFeed/LOAD_MORE_ACTIVITIES';
const CLEAR_FLAGGED_FARM_FEED = 'flaggedFarmFeed/CLEAR_FLAGGED_FARM_FEED';
// ------------------------------------
// Actions
// ------------------------------------

export const fetchFlaggedActivities = (params = defaultParams, loadMore) => (dispatch) => {
  dispatch(createAction(FETCH_FLAGGED_ACTIVITIES_PENDING)());

  return getFilteredActivities(pickBy(params), flaggedFilter)
    .then((response) => {
      const action = loadMore
        ? LOAD_MORE_ACTIVITIES
        : FETCH_FLAGGED_ACTIVITIES_FULFILLED;
      dispatch(createAction(action)({ ...response, params }));
      return response;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_FLAGGED_ACTIVITIES_REJECTED)(response));
      throw response;
    });
};

export const clearFlaggedFarmFeed = createAction(CLEAR_FLAGGED_FARM_FEED);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_FLAGGED_ACTIVITIES_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_FLAGGED_ACTIVITIES_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    params: {
      ...defaultParams,
      ...action.payload.params,
    },
    isLoading: false,
    isLoaded: true,
  }),
  [FETCH_FLAGGED_ACTIVITIES_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [LOAD_MORE_ACTIVITIES]: (state, action) => ({
    ...state,
    ...action.payload,
    resources: [
      ...state.resources,
      ...action.payload.resources,
    ],
    isLoading: false,
  }),

  [POST_COMMENT]: (state, action) => {
    const newComment = action.payload.resource;
    const activity = state.resources.find(({ id }) => id === newComment.commentable_id);
    if (!activity) return state;

    const activityIndex = state.resources.indexOf(activity);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        {
          ...activity,
          updated_at: newComment.updated_at,
          comments: [
            newComment,
            ...activity.comments,
          ],
        },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },
  [DELETE_COMMENT]: (state, action) => {
    const { activityId, commentId } = action.payload;
    const activity = state.resources.find(({ id }) => id === activityId);
    if (!activity) return state;

    const activityIndex = state.resources.indexOf(activity);
    const comment = activity.comments.find(({ id }) => id === commentId);
    const commentIndex = activity.comments.indexOf(comment);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        {
          ...activity,
          updated_at: moment().toISOString(),
          comments: [
            ...activity.comments.slice(0, commentIndex),
            ...activity.comments.slice(commentIndex + 1),
          ],
        },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },
  [UPDATE_COMMENT]: (state, action) => {
    const { id: commentId, updated_at } = action.payload.resource;
    const { activityId } = action.payload;
    const activity = state.resources.find(({ id }) => id === activityId);
    if (!activity) return state;

    const activityIndex = state.resources.indexOf(activity);
    const comment = activity.comments.find(({ id }) => id === commentId);
    const commentIndex = activity.comments.indexOf(comment);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        {
          ...activity,
          updated_at,
          comments: [
            ...activity.comments.slice(0, commentIndex),
            action.payload.resource,
            ...activity.comments.slice(commentIndex + 1),
          ],
        },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },
  [SET_FLAGGED]: (state, action) => {
    const { value, activityId } = action.payload;
    const activity = state.resources.find(({ id }) => id === activityId);
    const activityIndex = state.resources.indexOf(activity);
    const updatedActivity = {
      ...activity,
      flagged: value,
    };

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        updatedActivity,
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },
  [UPDATE_ACTIVITY]: (state, { payload }) => {
    const activity = state.resources.find(({ id }) => id === payload.id);
    if (!activity) return state;
    const activityIndex = state.resources.indexOf(activity);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        { ...payload },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },

  [CLEAR_FLAGGED_FARM_FEED]: () => ({
    ...initialState,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
