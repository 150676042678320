import React from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import StatusBadge from '../../StatusBadge/StatusBadge';
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
// utils
import isEmpty from 'lodash.isempty';
import { capitalize, getMedType } from 'utils';
// styles
import cn from 'classnames';
import './MedicationHeader.scss';

const MedicationHeader = ({
  medication,
  isLoading,
  handleClick,
  isSticky,
  className,
}) => {
  return (
    <div className={cn('MedicationHeader', className, { 'isSticky': isSticky })}>
      <Preloader isActive={isLoading} />
      <Avatar
        type={getMedType(medication)}
        avatarSize={50}
        iconSize={40}
      />

      {!isEmpty(medication) && (
        <div className="medication-information">
          <div className="medication-info-wrapper">
            <span className="medication-name">{medication.name}</span>
            <StatusBadge status={medication.active ? 'active' : 'disabled'} />
          </div>
          <div className="medication-info">
            <FormattedMessage id="general.type" />&nbsp;&bull;&nbsp;
            <span className="type-string">{medication.route.map((item) => capitalize(item)).join(', ')}</span>
          </div>
        </div>
      )}

      <div className="header-actions">
        <Button onClick={handleClick} dark-grey>
          <FormattedMessage id={`general.button.${medication.active ? 'disable' : 'enable'}`} />
        </Button>
      </div>
    </div>
  );
};

MedicationHeader.defaultProps = {
  className: '',
};

MedicationHeader.propTypes = {
  medication: T.object,
  className: T.string,
  isSticky: T.bool,
  isLoading: T.bool,
  handleClick: T.func,
};

export default MedicationHeader;
