import React from 'react';
import T from 'prop-types';
// utils
import inRange from 'lodash.inrange';
import isNull from 'lodash.isnull';
import cn from 'classnames';
// styles
import './GradientProgressBar.scss';

const GradientProgressBar = ({
  progress,
  className,
  width,
}) => {
  const isValueNull = isNull(progress);
  const value = Number(progress);
  return (
    <div
      className={cn('GradientProgressBar', className)}
      style={{ width: width ? `${width}px` : 'auto' }}
    >
      <div
        className={cn('gpb-scale', {
          'gray': isValueNull,
          'low': inRange(value, 0, 25) && !isValueNull,
          'average-low': inRange(value, 25, 40),
          'average': inRange(value, 40, 60),
          'average-high': inRange(value, 60, 75),
          'high': inRange(value, 75, 200),
        })}
      >
        {!isValueNull &&
          <div className="gpb-meter" style={{ width: `${Number(value)}%` }} />}
      </div>
    </div>
  );
};

GradientProgressBar.defaultProps = {
  progress: 0,
  className: '',
};

GradientProgressBar.propTypes = {
  className: T.string,
  progress: T.oneOfType([T.string, T.number]),
  width: T.oneOfType([T.string, T.number]),
};

export default GradientProgressBar;
