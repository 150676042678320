import React from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
// assets
import nullStateImg from '../../../public/images/ff-home-null.svg';
import './FarmFeedNullState.scss';
// translate
import { FormattedMessage } from 'react-intl';

const FarmFeedNullState = ({ takeATour }) => (
  <div className="FarmFeedNullState">
    <div className="null-state-box">
      <div className="null-state-placeholder">
        <div className="label-text"><FormattedMessage id="container.farmfeed.welcome" /></div>
        <img className="null-state-img" src={nullStateImg} role="presentation" alt="Welcome to Farmfeed" />
        <div className="sub-label-text"><FormattedMessage id="container.farmfeed.welcomeText" /></div>
      </div>
      {takeATour && (
        <Button primary onClick={takeATour}>
          <FormattedMessage id="general.button.takeTour" />
        </Button>
      )}
    </div>
  </div>
);

FarmFeedNullState.propTypes = {
  takeATour: T.func,
};

export default FarmFeedNullState;
