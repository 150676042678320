import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// utils
import classnames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import { getDeathsCount } from 'utils';
// styles
import styles from './FarmFeedEmptySections.module.scss';

const cx = classnames.bind(styles);

const FarmFeedEmptySections = ({
  dailyCheckup: {
    treatment_count,
    symptom_count,
    pig_migration_count,
    chronic_death_count,
    acute_death_count,
    euthanasia_death_count,
    high_temperature,
    low_temperature,
    water_usage_value,
  },
  showCheckup,
  trackTemperature,
  trackWaterUsage,
}) => {
  const symptoms = Boolean(symptom_count);
  const deaths = Boolean(getDeathsCount({ chronic_death_count, acute_death_count, euthanasia_death_count }));
  const medications = Boolean(treatment_count);
  const movements = Boolean(pig_migration_count);
  const emptyTemps = Boolean(trackTemperature && (!high_temperature && !low_temperature));
  const emptyWaterUsage = Boolean(trackWaterUsage && !water_usage_value);

  const renderSegment =
    showCheckup && (!medications || !deaths || !symptoms || !movements);

  return (
    renderSegment && (
      <div className={cx('empty-sections-block')}>
        {!movements && (
          <div className={cx('empty-sections-line')}>
            <span>
              <FormattedMessage id="general.pigMovements" /> • 0
            </span>
          </div>
        )}
        {!deaths && (
          <div className={cx('empty-sections-line')}>
            <span>
              <FormattedMessage id="general.deaths" /> • 0
            </span>
          </div>
        )}
        {!symptoms && (
          <div className={cx('empty-sections-line')}>
            <span>
              <FormattedMessage id="general.symptoms" /> • 0
            </span>
          </div>
        )}
        {!medications && (
          <div className={cx('empty-sections-line')}>
            <span>
              <FormattedMessage id="general.medications" /> • 0
            </span>
          </div>
        )}
        {emptyTemps && (
          <div className={cx('empty-sections-line')}>
            <span>
              <FormattedMessage id="general.highLowTemps" /> • N/A
            </span>
          </div>
        )}
        {emptyWaterUsage && (
          <div className={cx('empty-sections-line')}>
            <span>
              <FormattedMessage id="general.waterUsage" /> • N/A
            </span>
          </div>
        )}
      </div>
    )
  );
};

FarmFeedEmptySections.propTypes = {
  dailyCheckup: T.object.isRequired,
  showCheckup: T.bool.isRequired,
  trackWaterUsage: T.bool.isRequired,
  trackTemperature: T.bool.isRequired,
};

export default connect(
  (state) => ({
    trackWaterUsage: state.auth.user.current_company.track_water_usage,
    trackTemperature: state.auth.user.current_company.track_temperature,
  })
)(FarmFeedEmptySections);
