import React, { Fragment } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import PigGroupMovementsContainer from 'components/Groups/PigGroupMovementsContainer';
import PigGroupMigrationPanel from 'components/Groups/PigGroupMigrationPanel';
// endpoints
import { getPigGroupMigrations } from 'endpoints/pigGroups';

const BarnSheetsGroupPigMovements = ({ currentUser, params: { id: groupId }, pigGroup }) => (
  <PigGroupMovementsContainer
    getPigGroupMovements={(reqParams) => getPigGroupMigrations(groupId, reqParams)}
    currentUser={currentUser}
    pigGroup={pigGroup}
  >
    {({ resources }, migrationClassName) => (
      <Fragment>
        {resources.map((migration) => (
          <PigGroupMigrationPanel
            key={migration.id}
            className={migrationClassName}
            currentUser={currentUser}
            migration={migration}
            pigGroupId={groupId}
          />
        ))}
      </Fragment>
    )}
  </PigGroupMovementsContainer>
);

BarnSheetsGroupPigMovements.propTypes = {
  params: T.object.isRequired,
  pigGroup: T.object,
  currentUser: T.object.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.barnsheets.groupCheckups.pigGroup,
    currentUser: state.auth.user,
  })
)(BarnSheetsGroupPigMovements);
