import { getCommentByRole } from 'helpers';
import isEmpty from 'lodash.isempty';
import {
  tableObjectStoreAdd,
  tableObjectStoreClear,
  tableObjectStoreDelete,
  tableObjectStoreGet,
  tableObjectStoreGetAll,
  tableObjectStoreMerge,
  getTableListByIndex,
  mergeTableList,
  setTableList,
} from 'db/offline-db';
import { currentUtcOffsetTime } from './timeHelper';
import moment from 'moment';
import uuid from 'uuid';
import { generateAllGoodCheckup } from './checkupTemplate';

export const isFakeId = (id) => {
  return id.toString().includes('fake');
};

export const isAssetNotUploadedToServer = (assetId) => {
  return Number.isNaN(Number(assetId)); // when uniqueIdentifier is id
};

export const parseCheckupId = (id) => {
  const parsedId = parseInt(id, 10);
  return Number.isNaN(parsedId) ? id : parsedId;
};

export const transformComment = (commentsArray = [], resource = {}, role) => {
  if (isEmpty(resource) || !resource[`comments_${role}`]) return commentsArray;
  const newComment = {
    role,
    comment: resource[`comments_${role}`].text
  };
  const isExist = getCommentByRole(commentsArray, role);
  if (!isEmpty(isExist)) {
    return commentsArray.map((comment) => (comment.role === role ? newComment : comment));
  }
  return [...commentsArray, newComment];
};

export const countDeathSumOffline = (resource) => {
  if (!resource) return 0;
  const { mortality_reasons, acute_death_count, chronic_death_count, euthanasia_death_count } = resource;
  if (mortality_reasons) {
    return mortality_reasons.reduce(
      (sum, _) => sum + (_.acute_death_count || 0) + (_.chronic_death_count || 0) + (_.euthanasia_death_count || 0), 0
    );
  }
  return acute_death_count + chronic_death_count + euthanasia_death_count;
};

export const countFromMortalityReasons = (resource) => {
  const { mortality_reasons } = resource;
  let { acute_death_count, chronic_death_count, euthanasia_death_count } = resource;
  if (mortality_reasons) {
    mortality_reasons.forEach((i) => {
      if (i.acute_death_count || i.acute_death_count === 0) acute_death_count += i.acute_death_count;
      if (i.chronic_death_count || i.chronic_death_count === 0) chronic_death_count += i.chronic_death_count;
      if (i.euthanasia_death_count || i.euthanasia_death_count === 0) {
        euthanasia_death_count += i.euthanasia_death_count;
      }

    });
  }
  return { acute_death_count, chronic_death_count, euthanasia_death_count };
};

export const useOfflinePaginationAndSearch = (params, resources) => {
  const { page, per_page, search = '' } = params;
  let searchedResources = [];

  if (!search) {
    searchedResources = resources;
  } else {
    searchedResources = resources.filter((data) => (
      data.name.toLowerCase().includes(decodeURIComponent(search.toLowerCase()))
    ));
  }

  const paginateResources = searchedResources.slice(((page - 1) * per_page), (page * per_page));
  return { resources: paginateResources, meta: { total: searchedResources.length } };
};

export const generateAssetObject = (file, data = {}) => ({
  uniqueIdentifier: file.uniqueIdentifier,
  id: file.uniqueIdentifier,
  media_type: file.file.type,
  filename: file.fileName,
  media_size: file.size,
  ...data,
});

const defaultFarmChanges = (status) => ({
  last_checked_at: moment().format(),
  dc_progress_status: status,
});

export const farmWhenOneCheckupStarted = (farm) => ({
  ...farm,
  ...defaultFarmChanges('incomplete'),
  pending_checkup_count: farm.pending_checkup_count - 1,
});

export const farmWhenAllCheckupsSubmitted = (farm) => ({
  ...farm,
  ...defaultFarmChanges('complete'),
  pending_checkup_count: 0,
});

export const farmWhenOneCheckupSubmitted = (farm) => ({
  ...farm,
  ...defaultFarmChanges('complete'),
});

export const farmWhenOneCheckupAllGood = (farm) => ({
  ...farm,
  ...defaultFarmChanges('complete'),
  pending_checkup_count: farm.pending_checkup_count - 1,
});

// daily checkups
export const addDailyCheckupOffline = (data) => tableObjectStoreAdd('daily_checkups', data);
export const clearOfflineCheckups = () => tableObjectStoreClear('daily_checkups');
export const deleteCheckupOffline = (checkupId) => tableObjectStoreDelete('daily_checkups', parseCheckupId(checkupId));
export const getCheckupOffline = (checkupId) => {
  return tableObjectStoreGet('daily_checkups', 'id', parseCheckupId(checkupId));
};
export const updateCheckupOffline = (checkup) => tableObjectStoreMerge('daily_checkups', checkup, 'id');

// farms
export const getFarmOffline = (farmId) => tableObjectStoreGet('farms', 'id', farmId);
export const mergeFarmsOffline = (farms) => mergeTableList('farms', farms);
export const setFarmsListOffline = (resources) => setTableList('farms', resources);
export const updateFarmOffline = (farm) => tableObjectStoreMerge('farms', farm, 'id');

// media requests
export const addMediaRequestOffline = (data) => tableObjectStoreAdd('media_requests', data);
export const deleteMediaRequest = (requestId) => tableObjectStoreDelete('media_requests', requestId);
export const clearOfflineMediaRequests = () => tableObjectStoreClear('media_requests');
export const getAllMediaRequests = () => tableObjectStoreGetAll('media_requests');
export const getMediaRequestsByCheckup = (checkupId) => getTableListByIndex('media_requests', 'checkup_id', checkupId);

// requests
export const addRequestToOfflineDB = (data) => tableObjectStoreMerge('requests', data, 'pig_group_id');
export const clearOfflineRequests = () => tableObjectStoreClear('requests');
export const deleteRequest = (requestId) => tableObjectStoreDelete('requests', requestId);
export const getAllRequests = () => tableObjectStoreGetAll('requests');
export const getRequest = (groupId) => tableObjectStoreGet('requests', 'pig_group_id', groupId);

// pig groups
export const getFarmPigGroupsOffline = (farmId) => getTableListByIndex('pig_groups', 'farm_id', farmId);
export const getPigGroupByCheckup = (checkupId) => (
  getTableListByIndex('pig_groups', 'current_checkup_id', parseCheckupId(checkupId)));
export const getPigGroupOffline = (groupId) => tableObjectStoreGet('pig_groups', 'id', groupId);
export const mergePigGroupsOffline = (groups) => mergeTableList('pig_groups', groups);
export const setGroupsListOffline = (resources) => setTableList('pig_groups', resources);
export const updatePigGroupOffline = (group) => tableObjectStoreMerge('pig_groups', group, 'id');

// last preload
export const getLastPreloadData = (companyId) => {
  return tableObjectStoreGet('last_preload_data', 'current_company_id', companyId);
};

export const updateLastPreloadData = (companyId) => (
  tableObjectStoreMerge('last_preload_data', {
    current_company_id: companyId,
    loaded_at: moment().format(),
  }, 'current_company_id')
);

// other
export const setProductsListOffline = (resources) => setTableList('treatment_products', resources);
export const setSymptomsListOffline = (resources) => setTableList('symptoms', resources);
export const setMRListOffline = (resources) => setTableList('mortality_reasons', resources);
export const setTempPointsListOffline = (resources) => setTableList('temperature_points', resources);

export const getOpenedFarmGroupsOffline = (farmId) => (
  getFarmPigGroupsOffline(farmId)
    .then((farmPigGroups) => {
      return farmPigGroups.filter(({ status }) => status === 'opened');
    })
);

export const updateGroupAndSaveRequest = (group, farm, isOnline) => {
  const checkupId = `fake-${uuid()}`;
  const creatonOn = currentUtcOffsetTime(farm.utc_offset).format('YYYY-MM-DD');
  updatePigGroupOffline({
    id: group.id,
    current_checkup_id: checkupId,
    current_checkup_created_on: creatonOn,
    current_checkup_submitted: true,
  });
  if (!isOnline) {
    const checkup = generateAllGoodCheckup(group, farm, { id: checkupId, created_on: creatonOn });
    addDailyCheckupOffline(checkup);
    addRequestToOfflineDB({
      pig_group_id: group.id,
      farm_id: farm.id,
      utc_offset: farm.utc_offset,
      current_checkup_id: checkupId,
      created_on: creatonOn,
      resource: { all_good: true },
    });
  }
};

export const updateRequestWithData = (checkup, updatedData) => {
  getRequest(checkup.pig_group_id)
    .then((response) => {
      const request = response || {
        pig_group_id: checkup.pig_group_id,
        farm_id: checkup.pig_group.farm.id,
        utc_offset: checkup.pig_group.farm.utc_offset,
        current_checkup_id: checkup.id,
        created_on: checkup.created_on,
        resource: { data: {} },
      };
      addRequestToOfflineDB({
        ...request,
        resource: {
          data: {
            ...request.resource.data,
            ...updatedData,
          },
        },
      });
    });
};

export const addMediaRequest = (checkup, asset) => {
  addMediaRequestOffline({
    checkup_id: checkup.id,
    created_on: checkup.created_on,
    utc_offset: checkup.pig_group.farm.utc_offset,
    asset,
  })
    .then(() => {
      if (isFakeId(checkup.id)) {
        getRequest(checkup.pig_group.id)
          .then((request) => {
            if (!request) {
              addRequestToOfflineDB({
                pig_group_id: checkup.pig_group.id,
                farm_id: checkup.pig_group.farm.id,
                utc_offset: checkup.pig_group.farm.utc_offset,
                current_checkup_id: checkup.id,
                created_on: checkup.created_on,
                resource: { all_good: true },
              });
            }
          });
      }
    });
};

export const deleteAssetFromMediaRequest = (checkupId, uniqueId) => {
  getMediaRequestsByCheckup(checkupId)
    .then((resources = []) => {
      resources.forEach((mediaRequest) => {
        if (mediaRequest.asset.uniqueIdentifier === uniqueId) {
          deleteMediaRequest(mediaRequest.id);
        }
      });
    });
};

export const deleteSavedAssetFromMediaRequest = (checkup, assetId) => {
  addMediaRequestOffline({
    checkup_id: checkup.id,
    created_on: checkup.created_on,
    utc_offset: checkup.pig_group.farm.utc_offset,
    asset: checkup.dc_assets.find(({ id }) => id === assetId),
    destroy: true,
  });
};

export const updateCheckupWithMigrations = (checkup, data) => {
  if (data.all_good) {
    return { ...checkup, pig_migrations: [], pig_migration_count: 0 };
  }
  const comments = transformComment(checkup.comments, data.resource, 'pig_migrations');
  const { pig_migrations } = data.resource;
  return {
    ...checkup,
    pig_migrations,
    pig_migration_count: pig_migrations.length,
    comments,
  };
};

export const updateCheckupWithDeaths = (checkup, data) => {
  if (data.all_good) {
    return {
      ...checkup,
      chronic_death_count: 0,
      euthanasia_death_count: 0,
      acute_death_count: 0,
      deaths_sum: 0,
    };
  }
  const { resource } = data;
  const comments = transformComment(checkup.comments, resource, 'deaths');
  const deaths_sum = countDeathSumOffline(resource);
  const deathsData = countFromMortalityReasons(resource);
  const reasonsData = resource.mortality_reasons ? { mortality_reasons: resource.mortality_reasons } : {};
  return {
    ...checkup,
    ...deathsData,
    ...reasonsData,
    deaths_sum,
    comments,
  };
};

export const updateCheckupWithTreatments = (checkup, data) => {
  if (data.all_good) {
    return { ...checkup, dc_treatments: [], treatment_count: 0 };
  }
  const { resource } = data;
  const comments = transformComment(checkup.comments, resource, 'treatments');
  const { dc_treatments } = data.resource;
  return {
    ...checkup,
    dc_treatments,
    treatment_count: dc_treatments.length,
    comments,
  };
};

export const updateCheckupWithSymptoms = (checkup, data) => {
  if (data.all_good) {
    return { ...checkup, dc_symptoms: [], symptom_count: 0 };
  }
  const { resource } = data;
  const comments = transformComment(checkup.comments, resource, 'symptoms');
  const { dc_symptoms } = data.resource;
  return {
    ...checkup,
    dc_symptoms,
    symptom_count: dc_symptoms.length,
    comments,
  };
};

export const updateCheckupWithTemps = (checkup, data) => {
  if (data.all_good) {
    return { ...checkup, temperature_reported: true };
  }
  const { resource } = data;
  const comments = transformComment(checkup.comments, resource, 'temperature');
  const { low_temperature, high_temperature } = data.resource;
  return {
    ...checkup,
    low_temperature,
    high_temperature,
    comments,
    temperature_reported: true,
  };
};

export const updateCheckupWithWaterUsage = (checkup, data) => {
  if (data.all_good) {
    return { ...checkup, water_usage_reported: true };
  }
  const { resource } = data;
  const comments = transformComment(checkup.comments, resource, 'water_usage');
  const { water_usage_value } = data.resource;
  return {
    ...checkup,
    water_usage_value,
    comments,
    water_usage_reported: true,
  };
};
