import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { closeModal } from 'reducers/modals';
import { submitMyInventory } from 'reducers/inventoriesList';
// hoc
import withSoundOnMount from 'hoc/withSoundOnMount';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import Modal from 'components/Modal/Modal';
// style
import reconcileImage from './time-to-reconcile.svg';
import './TimeToReconcileModal.scss';

const TimeToReconcileModal = ({ closeModal, submitMyInventory }) => {
  const onSubmit = () => {
    submitMyInventory();
    closeModal();
  };

  return (
    <Modal className="TimeToReconcileModal" noCloseButton>
      <section className="modal-body">
        <FormattedMessage tagName="h1" id="component.modal.reconcile.title" />
        <img src={reconcileImage} alt="" />
        <FormattedMessage tagName="p" id="component.modal.reconcile.text" />
      </section>
      <section className="modal-footer flex sticky-on-mobile">
        <Link className="button light" to="/daily-checkup" onClick={onSubmit}>
          <FormattedMessage id="general.button.ok" />
        </Link>
      </section>
    </Modal>
  );
};

TimeToReconcileModal.propTypes = {
  closeModal: T.func.isRequired,
  submitMyInventory: T.func.isRequired,
};

const enhance = compose(
  connect(null, { closeModal, submitMyInventory }),
  withSoundOnMount('/static/sounds/attention_needed_sound.wav'),
);

export default enhance(TimeToReconcileModal);
