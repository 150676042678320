import React from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import Field from 'components/Field';
import { reduxForm } from 'redux-form';
import FormErrorsBox from 'components/FormErrorsBox';
import { FormattedMessage } from 'react-intl';

const CompanyEditForm = ({ handleSubmit, pristine, submitting }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="small-12 medium-10 large-8 column">
        <div className="row">
          <div className="small-12 column">
            <FormErrorsBox
              formName="user-company-edit-form"
            />
          </div>
        </div>
        <div className="row">
          <div className="small-12 column">
            <label>
              <Field
                placeholderKey="general.typeAName"
                label={<FormattedMessage id="general.companyName" />}
                name="name"
              />
            </label>
          </div>
        </div>
        <div className="margin-top-1">
          <Button primary className="mobile-full-width mb-0" disabled={pristine || submitting}>
            <FormattedMessage id="general.button.saveChanges" />
          </Button>
        </div>
      </div>
    </div>
  </form>
);

CompanyEditForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
};

export default reduxForm({
  form: 'user-company-edit-form',
})(CompanyEditForm);
