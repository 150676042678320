/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomColumn } from 'components/DataTable/Columns/index';

import cn from 'classnames';

const renderNameBarnsColumn = ({ name, error }) => {
  return (
    <CustomColumn
      label={<FormattedMessage id="component.dataTable.headers.name" />}
      className={cn({ 'highlighted': error === 'name' })}
    >
      {name || '-'}
    </CustomColumn>
  );
};

const renderCapacityColumn = ({ capacity, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.dataTable.headers.capacity" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {capacity || '-'}
  </CustomColumn>
);

const renderFarmColumn = ({ farm_name, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.dataTable.headers.farmName" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {farm_name || '-'}
  </CustomColumn>
);


const barnColumns = () => ([
  { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '1 1 140px',
    renderer: renderNameBarnsColumn, sortKey: 'name' },
  { label: <FormattedMessage id="component.dataTable.headers.capacity" />, flex: '1 1 140px',
    renderer: renderCapacityColumn, sortKey: 'capacity' },
  { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
    renderer: renderFarmColumn, sortKey: 'farm_name' },

]);

export default barnColumns;
