import React, { Component } from 'react';
import T from 'prop-types';
// hoc, recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// redux
import { connect } from 'react-redux';
import { fetchFarms, setFarmAllGood } from 'reducers/dailyCheckup/checkupFarms';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setPageOptions } from 'reducers/layout';
import { openModal, closeModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import DailyCheckupsFarmRow from './components/DailyCheckupsFarmRow';
import PaginationWrapper from 'components/PaginationWrapper';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './DailyCheckupHome.scss';

class DailyCheckupHome extends Component {

  componentDidMount() {
    const { fetchFarms, setBreadcrumbs, setPageOptions, farmParams } = this.props;
    fetchFarms(farmParams).catch(toastResponseErrors);
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.pageTitle.checkups" />,
        path: '/daily-checkup',
        useLabelAsMobileTitle: true,
      },
    ]);
    setPageOptions({ backLink: '' });
  }

  componentDidUpdate(prevProps) {
    const { fetchFarms, farmParams, isOnline } = this.props;
    if (prevProps.isOnline !== isOnline) {
      fetchFarms(farmParams).catch(toastResponseErrors);
    }
  }

  componentWillUnmount() {
    this.props.setBreadcrumbs();
  }

  getFarmsByRoles = (farms) => ([
    {
      key: 'caregiver',
      label: <FormattedMessage id="general.role.caregiver" />,
      farms: farms.filter(({ is_caretaker }) => is_caretaker),
    },
    {
      key: 'not-caregiver',
      label: <FormattedMessage id="container.checkupIndex.otherRoles" />,
      farms: farms.filter(({ is_caretaker }) => !is_caretaker),
    },
  ].filter(({ farms }) => farms.length));

  renderTitle = () => (
    <h1 className="lighter">
      <FormattedMessage id="container.checkupIndex.title" />
    </h1>
  );

  render() {
    const { isLoading, meta, farmParams: { page, per_page, search }, resources, onPageChange, onPerPageChange,
      onSearchChange, currentCompanyName, canSetAllGood, setFarmAllGood, closeModal, openModal } = this.props;
    const totalItems = meta?.total || 0;
    const farmsByRoles = this.getFarmsByRoles(resources);
    return (
      <div className="row centered DailyCheckupHome">
        <div className="small-12 column">

          <section>
            <Panel>
              <Panel.Heading className="farms-panel-title" renderTitle={this.renderTitle}>
                <SearchBox initialValue={search} onChange={onSearchChange} disableFocus />
              </Panel.Heading>
              <Panel.Body className="farms-panel-body">
                <Preloader isActive={isLoading} />

                {farmsByRoles.map(({ key, label, farms }) => (
                  <div key={key}>
                    <h4 className="text-capitalize role-group-header">{label}</h4>
                    {farms.map((farm) => (
                      <DailyCheckupsFarmRow
                        key={farm.id}
                        farm={farm}
                        currentCompanyName={currentCompanyName}
                        canSetAllGood={canSetAllGood}
                        onSetAllGood={setFarmAllGood}
                        closeModal={closeModal}
                        openModal={openModal}
                      />
                    ))}
                  </div>
                ))}

                <NothingBox
                  itemsName="farms"
                  display={!resources.length}
                  isLoading={isLoading}
                  search={search}
                >
                  <FormattedMessage tagName="h1" id="component.nothingBox.noFarmsToDisplay" />
                </NothingBox>

                <PaginationWrapper
                  currentPage={page}
                  perPage={per_page}
                  onPageChange={onPageChange}
                  onPerPageChange={onPerPageChange}
                  totalItems={totalItems}
                  onlyBottom
                />
              </Panel.Body>
            </Panel>
          </section>
        </div>
      </div>
    );
  }
}

DailyCheckupHome.propTypes = {
  resources: T.array.isRequired,
  isLoading: T.bool,
  farmParams: T.shape({
    page: T.number,
    per_page: T.number,
    search: T.string,
  }),
  meta: T.object,
  fetchFarms: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  isOnline: T.bool.isRequired,
  currentCompanyName: T.string.isRequired,
  canSetAllGood: T.bool.isRequired,
  setFarmAllGood: T.func.isRequired,
  closeModal: T.func.isRequired,
  openModal: T.func.isRequired,
};

export default compose(
  connect(
    (state) => ({
      resources: state.dailyCheckup.farms.resources,
      isLoading: state.dailyCheckup.farms.isLoading,
      farmParams: state.dailyCheckup.farms.params,
      meta: state.dailyCheckup.farms.meta,
      isOnline: state.network.isOnline,
      currentCompanyName: state.auth.user.current_company.name,
      canSetAllGood: !state.auth.user.current_company.track_temperature &&
        !state.auth.user.current_company.track_water_usage,
    }), {
      closeModal,
      fetchFarms,
      openModal,
      setFarmAllGood,
      setBreadcrumbs,
      setPageOptions,
    }
  ),
  withDataTableController('fetchFarms', 'farmParams'),
)(DailyCheckupHome);
