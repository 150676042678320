import T from 'prop-types';
import { connect } from 'react-redux';
import { sendNotify } from 'utils/airbrakeHelper';

const NotFoundRedirect = ({ router, location, currentUser }) => {
  sendNotify({
    error: new Error('Unknown route'),
    route: location.pathname + location.search,
  }, currentUser);
  router.push('/');
  return null;
};

NotFoundRedirect.propTypes = {
  router: T.object.isRequired,
  location: T.object.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  })
)(NotFoundRedirect);
