import React from 'react';
import T from 'prop-types';
// utils
import { getLetterInitials } from 'utils';
import classnames from 'classnames/bind';
// styles
import styles from './TenantAvatar.module.scss';

const cn = classnames.bind(styles);

const TenantAvatar = ({ name, highlighted, className }) => (
  <div className={cn('tenant-avatar', { highlighted, [className]: !!className })}>
    {getLetterInitials(name)}
  </div>
);

TenantAvatar.propTypes = {
  name: T.string.isRequired,
  className: T.string,
  highlighted: T.bool,
};

export default TenantAvatar;
