import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { Link } from 'react-router';
import { FieldArray, Field, reduxForm, getFormValues } from 'redux-form';
import Button from 'components/Button';
import FormField from 'components/ReduxFormFields/FormField/FormField';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import FormErrorsBox from 'components/FormErrorsBox';
import MessageBox from 'components/MessageBox';
// utils
import compact from 'lodash.compact';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { checkDisabledFields } from 'utils';
// styles
import './SourceCommingledForm.scss';
// constants
import { sourceTypes } from 'constants.js';

const customErrors = [{
  key: 'name',
  error: <FormattedMessage id="component.sourceForm.errors.commingledName" />,
}];

const requiredFields = ['name'];

const checkRequiredFieldsFilled = (values = {}) => (
  requiredFields.every(((field) => !!values[field]))
);

const requestParams = { page: 1, per_page: 25 };

class SourceCommingledForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCustomName: this.isCustomName(props),
    };
  }

  isCustomName = ({ initialValues, isEditMode }) => {
    if (!isEditMode) return false;
    const initialName = initialValues.name;
    const generatedName = this.getNameField(initialValues.sources);
    return initialName !== generatedName;
  };

  getSourceOptionsUrl = () => {
    const types = compact(sourceTypes.map(({ value }) => ((value !== 'co') ? value : '')));
    return `/admin/external_sources?type[]=${types.join('&type[]=')}`;
  };

  getNameField = (sources) => {
    const name = sources.map((item) => ((item && item.label) ? item.label : ''));
    return compact(name).join(' - ').trim();
  };

  updateNameField = () => {
    const { formValues: { sources } } = this.props;
    const name = this.getNameField(sources);
    if (this.state.isCustomName) return;
    this.props.change('name', name);
  };

  onSourceNameChange = (e) => {
    const { value } = e.target;
    const { formValues: { sources } } = this.props;
    if (!value) return this.setState({ isCustomName: false });
    const name = this.getNameField(sources);
    if (name === value.trim()) return this.setState({ isCustomName: false });
    return this.setState({ isCustomName: true });
  };

  removeSource = (fields, index) => {
    fields.remove(index);
    setTimeout(() => this.updateNameField(), 0);
  };

  submitForm = (data) => {
    const { onSubmit } = this.props;

    return onSubmit({
      name: data.name,
      sources_ids: data.sources.map(({ value }) => value),
    });
  };

  renderSources = ({ fields, maxFieldsCount }) => (
    <div className="SourceCommingledForm">
      <FormattedMessage id="component.sourceForm.selectToCommingle">
        {(text) => <div className="select-commingle-label">{text}</div>}
      </FormattedMessage>
      {fields.map((field, index) => {
        return (
          <div key={index}>
            <div className="source-container">
              <div className="search-select">
                <Field
                  name={`${field}`}
                  onChange={() => setTimeout(() => this.updateNameField(), 0)}
                  onBlur={() => setTimeout(() => this.updateNameField(), 0)}
                  component={({ input }) => (
                    <AsyncSelect
                      selected={fields.get(index) || {}}
                      onChange={input.onChange}
                      optionsPath={this.getSourceOptionsUrl()}
                      requestParams={requestParams}
                      optionsKeys={{ value: 'id', label: 'name' }}
                      placeholder={<FormattedMessage id="general.addASource" />}
                      optionsMapper={checkDisabledFields(fields.getAll(), 'value')}
                    />
                  )}
                />
              </div>
              {(fields.length > 1) && (
                <div className="remove-icon">
                  <i onClick={() => this.removeSource(fields, index)} className={cn('fa fa-trash-o')} />
                </div>
              )}
            </div>
          </div>
        );
      })}
      {(fields.length < maxFieldsCount) && (
        <div className="add-source">
          <FormattedMessage id="general.addSource">
            {(text) => <span onClick={() => fields.push({})}>{text}</span>}
          </FormattedMessage>
        </div>
      )}
    </div>
  );

  render() {
    const { handleSubmit, pristine, submitting, isEditMode, formValues } = this.props;

    return (
      <div className="small-12 medium-8 column">
        <MessageBox type="warning">
          <strong><FormattedMessage id="container.sources.commingled_form.pro_tip" />&nbsp;</strong>
          <FormattedMessage id="container.sources.commingled_form" />
        </MessageBox>

        <FormErrorsBox formName="source-commingled-form" customErrors={customErrors} />

        <form onSubmit={handleSubmit(this.submitForm)}>
          {/* NAME */}
          <div className="row">
            <div className="small-12 column">
              <label>
                <FormattedMessage id="general.typeAName">
                  {(placeholder) => (
                    <Field
                      label={<FormattedMessage id="general.sourceName" />}
                      name="name"
                      component={FormField}
                      onChange={this.onSourceNameChange}
                      type="text"
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
              </label>
            </div>
          </div>

          {/* SOURCE */}
          <FieldArray
            name="sources"
            component={this.renderSources}
            maxFieldsCount={5}
          />

          {/* BUTTON */}
          <div className="row mt-20 mb-20">
            <div className="small-12 column">
              <Button primary type="submit" disabled={pristine || submitting || !checkRequiredFieldsFilled(formValues)}>
                {isEditMode
                  ? <FormattedMessage id="general.button.saveChanges" />
                  : <FormattedMessage id="general.createSource" />}
              </Button>
              <Link to="/sources" className="button btn-link margin-left-1">
                <FormattedMessage id="general.button.cancel" />
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SourceCommingledForm.propTypes = {
  handleSubmit: T.func.isRequired,
  onSubmit: T.func.isRequired,
  change: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
  isEditMode: T.bool,
  formValues: T.object,
};

const formComponent = reduxForm({
  form: 'source-commingled-form',
  enableReinitialize: true,
})(SourceCommingledForm);

export default connect(
  (state) => ({
    formValues: getFormValues('source-commingled-form')(state),
  }),
)(formComponent);
