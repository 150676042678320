import { handleActions, createAction } from 'redux-actions';
// utils
import { fetchFromAPI } from 'utils/api';
// helpers
import { getFarmTypesValues } from 'helpers';
// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  isLoading: false,
  data: null,
};


const FETCH_ALL_FARMS = 'checkins/FETCH_ALL_FARMS';
const [FETCH_ALL_FARMS_PENDING, FETCH_ALL_FARMS_FULFILLED, FETCH_ALL_FARMS_REJECTED] = [
  `${FETCH_ALL_FARMS}_PENDING`,
  `${FETCH_ALL_FARMS}_FULFILLED`,
  `${FETCH_ALL_FARMS}_REJECTED`
];

// ------------------------------------
// Action creators
// ------------------------------------

export const fetchAllFarms = () => createAction(FETCH_ALL_FARMS)(
  fetchFromAPI('/asset_map', { params: { page: 1, per_page: 1000, type: getFarmTypesValues() } })
);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({

  // FETCH_ALL_FARMS
  [FETCH_ALL_FARMS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_ALL_FARMS_FULFILLED]: (state, action) => ({
    ...state,
    data: action.payload.resources,
    isLoading: false,
  }),
  [FETCH_ALL_FARMS_REJECTED]: (state, action) => ({
    ...state,
    error: action.payload.errors,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
