import React from 'react';
import T from 'prop-types';
import cn from 'classnames';
import './StickyFooter.scss';

const StickyFooter = ({ children, className = '', hidden }) => {
  return (
    <div className={cn('StickyFooter', { [className]: !!className, '_hidden': hidden })}>
      {children}
    </div>
  );
};

StickyFooter.propTypes = {
  children: T.any,
  className: T.string,
  hidden: T.bool,
};

export default StickyFooter;
