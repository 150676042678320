import React, {Fragment, useEffect, useState} from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Collapse from 'components/Collapse/Collapse';
import Line from 'components/Line';
import Translation from 'components/Translation';
import AudioPreview from 'components/AudioPreview';
import CollapseTitle from './CollapseTitle';
// requests
import { getCheckupSymptoms } from 'endpoints/dailyCheckups';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isAudioAsset, renderMentionsText } from 'utils';
// styles
import styles from './SectionCollapse.module.scss';

const cn = classnames.bind(styles);

const SymptomsCollapse = ({
  checkupId,
  className,
  comment,
  dailyCheckup,
  data = [],
  isEdited,
  itemsCount,
  showCheckup,
  titleClassName,
}) => {
  const [{ isLoaded, isCollapseOpened, isLoading, symptoms }, setState] = useState({
    isLoaded: false,
    isLoading: false,
    isCollapseOpened: false,
    symptoms: [],
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoaded: Boolean(data.length), symptoms: data }));
  }, [data, setState]);

  if (!comment && !dailyCheckup.symptom_count) return null;

  const toggleCollapse = (isCollapseOpened) => {
    if (isLoaded) {
      setState((prevState) => ({ ...prevState, isCollapseOpened }));
      return;
    }

    setState((prevState) => ({ ...prevState, isCollapseOpened, isLoading: true }));
    getCheckupSymptoms(checkupId)
      .then((symptoms) => {
        setState((prevState) => ({ ...prevState, symptoms, isLoaded: true, isLoading: false }));
      })
      .catch((response) => {
        setState((prevState) => ({ ...prevState, isCollapseOpened, isLoading: false }));
        toastResponseErrors(response);
      });
  };

  const triggered = Boolean(comment) || symptoms.some((symptom) => symptom.critical);
  const symptomAudios = dailyCheckup.dc_assets
    .filter((asset) => (asset.category === 'symptoms') && isAudioAsset(asset));

  return ((triggered || showCheckup) && (
    <Collapse
      className={cn(className, 'section-collapse', { 'highlighted': triggered && !isCollapseOpened })}
      titleClassName={titleClassName}
      title={(
        <CollapseTitle hasEditedBadge={isEdited} hasNotesBadge={Boolean(comment)} hasTriggerIcon={triggered}>
          <FormattedMessage tagName="b" id="general.symptomsCount" values={{ count: itemsCount }} />
        </CollapseTitle>
      )}
      onToggle={toggleCollapse}
      isLoading={isLoading}
    >
      {symptoms.map(({ id, name, frequency, critical }, index) => (
        <Line
          key={id}
          boldedValue
          lightYellow={critical}
          hasTopBorder={!index}
          label={name}
          value={frequency + '%'}
        />
      ))}
      {!!comment && (
        <>
          <Line almostWhite bolded hasTopBorder={!symptoms.length}>
            <FormattedMessage id="general.notes" />
          </Line>
          <Line lightYellow>
            <Translation text={comment}>{renderMentionsText(comment)}</Translation>
          </Line>
        </>
      )}
      {!!symptomAudios.length && symptomAudios.map((asset) => (
        <Fragment key={asset.id}>
          <Line lightYellow>
            <AudioPreview
              asset={asset}
              controlSize="40"
              hideAction
            />
          </Line>
          {asset.description && (
            <>
              <Line almostWhite bolded>
                <FormattedMessage id="general.notes" />
              </Line>
              <Line lightYellow>
                <Translation text={asset.description}>{asset.description}</Translation>
              </Line>
            </>
          )}
        </Fragment>
      ))}
    </Collapse>
  ));
};

SymptomsCollapse.propTypes = {
  checkupId: T.number.isRequired,
  className: T.string,
  titleClassName: T.string,
  dailyCheckup: T.object,
  data: T.array,
  showCheckup: T.bool,
  isEdited: T.bool,
  comment: T.string,
  itemsCount: T.number,
};

export default SymptomsCollapse;
