/* eslint-disable */
import React, { Component } from 'react';
import T from 'prop-types';
// components
import Dropdown from '../Dropdown';
// utils
import cn from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
// assets
import './DropdownSelect.scss';

class DropdownSelect extends Component {

  state = {
    isOpen: false,
  };

  setIsOpen = (isOpen) => {
    this.setState({ isOpen });
    const { onFocus, value } = this.props;
    onFocus && onFocus(value);
  };

  selectOption = ({ value }) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const { value, options, placeholder } = this.props;
    const { isOpen } = this.state;

    const selectedOption = options.find((option) => option.value === value);
    const withCount = selectedOption && selectedOption.hasOwnProperty('count');
    const isPhone = isMobile && !isTablet;
    const pseudoSelect = (
      <div className="pseudo-select">
        <span>
          {selectedOption ? selectedOption.label : placeholder}
          {withCount && <span className="count">({selectedOption.count || '0'})</span>}
        </span>
      </div>
    );

    return (
      <div className={cn('DropdownSelect', { 'is-mobile': isPhone, 'is-open': isOpen })}>
        <Dropdown
          trigger={pseudoSelect}
          withBackdrop={isPhone}
          className="dropdown-container"
          dropdownClassName="responsive-dropdown"
          closeOnClick
          onToggle={this.setIsOpen}
        >
          <ul className="dropdown-menu">
            {options.map((option, index) => {
              const isSelected = option.value === value;
              const withCount = option.hasOwnProperty('count');
              return (
                <li
                  key={index}
                  className={cn('dropdown-option', { 'active-option': isSelected })}
                  onClick={() => this.selectOption(option)}
                >
                  <span className="option-label">
                    {option.label}
                    {withCount && <span className="count">({option.count || '0'})</span>}
                  </span>
                  {isSelected && <i className="fa fa-check semibold" />}
                </li>
              );
            })}
          </ul>
        </Dropdown>
      </div>
    );
  }
}

DropdownSelect.propTypes = {
  value: T.any.isRequired,
  onChange: T.func.isRequired,
  onFocus: T.func,
  options: T.array.isRequired,
  placeholder: T.oneOfType([T.string, T.node]),
};

DropdownSelect.defaultProps = {
  placeholder: 'Select an option...',
};

export default DropdownSelect;
