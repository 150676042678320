import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setUnreadNotificationsCount, increaseUnreadNotificationsCount } from 'reducers/sidebar';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { fetchDataWithMerge, dismissNotification, setReadAction, dismissAllNotification,
  fetchItemAfterDismiss } from 'reducers/notifications';
// components
import { FormattedMessage } from 'react-intl';
import Waypoint from 'react-waypoint';
import Preloader from 'components/Preloader';
import Link from 'react-router/lib/Link';
import AllCaughtUp from '../Farmfeed/components/AllCaughtUp';
import NotificationItem from 'components/Notifications/NotificationItem';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import moment from 'moment';
import throttle from 'lodash.throttle';
// styles
import './Notifications.scss';

class Notifications extends Component {

  constructor(props) {
    super(props);
    this.throttleCheck = throttle(this.sendReadIds, 2000, { leading: false });
    this.readIds = new Set();
    this.state = { isSettingRead: false };
  }

  componentDidMount() {
    const { setBreadcrumbs } = this.props;
    setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.notifications" />, useLabelAsMobileTitle: true }
    ]);
  }

  sendReadIds = () => {
    const { currentUser: { id }, increaseUnreadNotificationsCount, setReadAction } = this.props;
    this.setState({ isSettingRead: true });
    setReadAction(id, this.readIds)
      .then(() => {
        increaseUnreadNotificationsCount(-this.readIds.size);
        this.readIds.clear();
        this.setState({ isSettingRead: false });
      })
      .catch((errors) => {
        this.setState({ isSettingRead: false });
        toastResponseErrors(errors);
      });
  };

  dismissAll = () => {
    const { currentUser: { id }, setUnreadNotificationsCount, dismissAllNotification } = this.props;
    const { resources } = this.props;
    const last_notification_id = resources[0]?.id || null;

    dismissAllNotification(id, last_notification_id)
      .then(() => setUnreadNotificationsCount(0))
      .catch(toastResponseErrors);
  };

  handleDismissAction = (notificationId) => {
    const { dismissNotification, currentUser, isLoading, fetchItemAfterDismiss, resources } = this.props;
    if (isLoading) return false;
    return dismissNotification(currentUser.id, notificationId)
      .then(() => fetchItemAfterDismiss(currentUser.id, resources.length - 1))
      .catch(toastResponseErrors);
  };

  markAsRead = (id) => {
    this.readIds.add(id);
    this.throttleCheck();
  };

  render() {
    const { fetchData, reqParams, isLoaded, currentUser, isLoading, resources,
      meta: { total } } = this.props;
    const { isSettingRead } = this.state;
    const hasMoreItems = total > resources.length;

    return (
      <div className="Notifications">
        <div className="action-block">
          {resources.length > 0 && (
            <span>
              <span onClick={this.dismissAll}>
                <FormattedMessage id="general.dismissAll" />
              </span>
              &nbsp;|&nbsp;
            </span>
          )}
          <Link to="/profile/messaging"><FormattedMessage id="general.settings" /></Link>
        </div>
        <div className="block-content">
          {resources.map((item, index) => {
            const isCurrentItemToday = moment(item.created_at).isSame(new Date(), 'day');
            const isFirstItem = index === 0;
            const hasTodayDivider = isFirstItem && isCurrentItemToday;
            const hasEarlierDivider = isFirstItem
              ? !isCurrentItemToday
              : !isCurrentItemToday && moment(resources[index - 1].created_at).isSame(new Date(), 'day');
            return (
              <NotificationItem
                key={item.id}
                hasEarlierDivider={hasEarlierDivider}
                hasTodayDivider={hasTodayDivider}
                currentUser={currentUser}
                onMarkAsRead={this.markAsRead}
                onDismiss={this.handleDismissAction}
                isRead={item.read}
                item={item}
              />
            );
          })}
          {isLoading &&
            <Preloader isActive={isLoading} />}
          {!isLoading && hasMoreItems && isLoaded && !isSettingRead &&
            <Waypoint onEnter={() => fetchData(currentUser.id, { ...reqParams, page: reqParams.page + 1 })} />}
          {!isLoading && !resources.length && (
            <AllCaughtUp>
              <FormattedMessage id="general.haveSeanAll" />
            </AllCaughtUp>
          )}
        </div>
      </div>
    );
  }
}

Notifications.propTypes = {
  resources: T.array.isRequired,
  isLoading: T.bool.isRequired,
  isLoaded: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  currentUser: T.object.isRequired,
  fetchData: T.func.isRequired,
  setUnreadNotificationsCount: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  dismissNotification: T.func.isRequired,
  dismissAllNotification: T.func.isRequired,
  setReadAction: T.func.isRequired,
  fetchItemAfterDismiss: T.func.isRequired,
  increaseUnreadNotificationsCount: T.func.isRequired,
};

export default connect(
  (state) => ({
    resources: state.notifications.resources,
    isLoading: state.notifications.isLoading,
    isLoaded: state.notifications.isLoaded,
    reqParams: state.notifications.params,
    meta: state.notifications.meta,
    currentUser: state.auth.user,
  }), {
    fetchData: fetchDataWithMerge,
    setUnreadNotificationsCount,
    setBreadcrumbs,
    dismissNotification,
    dismissAllNotification,
    setReadAction,
    fetchItemAfterDismiss,
    increaseUnreadNotificationsCount,
  },
)(Notifications);
