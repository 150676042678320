import React from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import MainFooterPlusButton from './MainFooterPlusButton/MainFooterPlusButton';
// utils
import { formatSignInCount } from 'utils';
import cn from 'classnames';
// styles
import './MainFooter.scss';

const MainFooter = ({ isMobileMenuOpen, items, onItemClick, isEditMode }) => {
  const renderItem = (item) => (
    <Link
      key={item.id}
      to={item.link}
      className={cn('badge-icon', { 'active': item.isMore && isMobileMenuOpen })}
      activeClassName={isMobileMenuOpen ? '' : 'active'}
      onClick={() => onItemClick(item)}
    >
      <span className={item.icon} />
      {!!item.count &&
      <span className="count-value">{formatSignInCount(item.count)}</span>}
      <div className="title">{item.name}</div>
    </Link>
  );

  return (
    <nav className={cn('main-footer hide-for-large', { 'is-hidden': isEditMode })}>
      {items.slice(0, 2).map(renderItem)}
      <MainFooterPlusButton items={items.slice(2, items.length - 2)} />
      {items.slice(items.length - 2).map(renderItem)}
    </nav>
  );
};

MainFooter.propTypes = {
  isMobileMenuOpen: T.bool.isRequired,
  onItemClick: T.func.isRequired,
  isEditMode: T.bool.isRequired,
  items: T.array.isRequired,
};

export default MainFooter;
