import { fetchFromAPI } from 'utils/api';

export const createShareholderGroup = (resource) => {
  return fetchFromAPI('/admin/shareholder_groups', { method: 'POST', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getShareholderGroup = (id) => {
  return fetchFromAPI(`/admin/shareholder_groups/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateShareholderGroup = (id, resource) => {
  return fetchFromAPI(`/admin/shareholder_groups/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteShareholderGroup = (id) => {
  return fetchFromAPI(`/admin/shareholder_groups/${id}`, { method: 'delete' })
    .catch((response) => {
      throw response;
    });
};

export const createFarmRole = (id, farmId) => {
  return fetchFromAPI(`/admin/shareholder_groups/${id}/farms`, { method: 'post', body: JSON.stringify({ id: farmId }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const revokeFarmRole = (id, farmId) => {
  return fetchFromAPI(`/admin/shareholder_groups/${id}/farms/${farmId}`, { method: 'delete' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const revokeAllFarmRoles = (id) => {
  return fetchFromAPI(`/admin/shareholder_groups/${id}/farms/revoke_all`, { method: 'delete' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
