import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { openPortal } from 'reducers/portal';
// components
import { DateRangePicker as ReactDatesPicker } from 'react-dates';
import DateRangePickerMobile from './DateRangePickerMobile';
// utils
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import withOutsideClick from 'react-onclickoutside';
// constants
import { START_DATE, END_DATE } from 'react-dates/constants';
// styles
import 'react-dates/lib/css/_datepicker.css';
import './DateRangePicker.scss';

class DateRangePicker extends Component {

  state = {
    focused: false,
    focusedInput: null,
  };

  setFocus = (e) => {
    if (!this.state.focused) {
      const focusedInput = (e.target.name === 'endDate') ? 'endDate' : 'startDate';
      this.setState({ focused: true, focusedInput });
    }
  };

  onFocusChange = (focusedInput) => this.setState({ focusedInput });

  openMobilePicker = (e) => {
    e.stopPropagation();
    const { startDate, endDate, onDatesChange, openPortal, isOutsideRange } = this.props;
    openPortal(
      <DateRangePickerMobile
        initialStartDate={startDate}
        initialEndDate={endDate}
        onChange={onDatesChange}
        isOutsideRange={isOutsideRange}
        minimumNights={1}
      />
    );
  };

  handleClickOutside = () => this.setState({ focused: false });

  render() {
    const { className, startDate, endDate, onDatesChange, isOutsideRange } = this.props;
    const { focused, focusedInput } = this.state;
    const { formatMessage } = this.context;

    return (
      <div
        onClick={this.setFocus}
        className={cn('DateRangePickerWrapper', {
          [className]: !!className,
          'focused': focused,
          isMobile,
        })}
      >
        <ReactDatesPicker
          startDatePlaceholderText={formatMessage({ id: 'general.startDate' })}
          endDatePlaceholderText={formatMessage({ id: 'general.endDate' })}
          readOnly
          startDate={startDate}               // momentPropTypes.momentObj or null,
          endDate={endDate}                   // momentPropTypes.momentObj or null,
          startDateId={START_DATE}
          endDateId={END_DATE}
          onDatesChange={onDatesChange}       // PropTypes.func.isRequired,
          focusedInput={focusedInput}         // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={this.onFocusChange}
          isOutsideRange={isOutsideRange}
          hideKeyboardShortcutsPanel
          customArrowIcon={<i className="fa fa-arrow-right input-arrow" />}
        />
        <div className={cn('mobile-overlay', { 'active': isMobile })} onClick={this.openMobilePicker} />
        <div className="calendar-icon-wrapper">
          <i className="fa fa-calendar" />
        </div>
      </div>
    );
  }
}

DateRangePicker.defaultProps = {
  className: '',
};

DateRangePicker.contextTypes = {
  formatMessage: T.func.isRequired,
};

DateRangePicker.propTypes = {
  className: T.string,
  startDate: T.oneOfType([T.object, T.string]),
  endDate: T.oneOfType([T.object, T.string]),
  onDatesChange: T.func.isRequired,
  isOutsideRange: T.func,
  openPortal: T.func,
};

export default compose(
  connect(null, { openPortal }),
  withOutsideClick,
)(DateRangePicker);
