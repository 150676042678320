import React from 'react';
import drBotImage from '../../../public/images/dr-bot.svg';
import './DrBotAvatar.scss';

const DrBotAvatar = () => (
  <div className="DrBotAvatar">
    <img src={drBotImage} alt="Dr. Bot" />
    <span>Dr. Bot</span>
  </div>
);

export default DrBotAvatar;
