import React from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
import styles from './ButtonRadioGroup.module.scss';

const cn = classnames.bind(styles);

const ButtonRadioGroup = ({ options, onChange, value, className, name, disabled }) => (
  <div className={cn('radio-group', className, { disabled })}>
    {options.map((type, radioIndex) => (
      <label key={radioIndex}>
        <input
          name={name}
          disabled={disabled}
          required="required"
          type="radio"
          value={String(type.value)}
          onChange={onChange}
          checked={value === type.value}
        />
        <span>{type.label}</span>
      </label>
    ))}
  </div>
);

ButtonRadioGroup.defaultProps = {
  options: [],
  className: '',
  name: '',
};

ButtonRadioGroup.propTypes = {
  onChange: T.func.isRequired,
  options: T.array,
  className: T.string,
  name: T.string,
  disabled: T.bool,
  value: T.oneOfType([T.string, T.bool]),
};

export default ButtonRadioGroup;
