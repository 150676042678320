import React, { useState } from 'react';
import T from 'prop-types';
// components
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CopyIcon } from '../../../public/images/copy-icon.svg';
// utils
import cn from 'classnames';
// constants
import { APP_VERSION } from '../../constants';
// styles, images
import styles from './AppVersionLine.module.scss';

const AppVersionLine = ({ className, isSidebarView = false }) => {
  const [isShowSnackbar, setIsShowSnackbar] = useState(false);

  const showSnackbar = () => {
    setIsShowSnackbar(true);
    const timer = setTimeout(() => {
      setIsShowSnackbar(false);
      clearTimeout(timer);
    }, 1000);
  };

  return (
    <FormattedMessage id="general.versionNumber" values={{ value: APP_VERSION }}>
      {(version) => (
        <CopyToClipboard text={version} onCopy={showSnackbar}>
          <div
            className={cn(styles['app-version-container'], {
              [className]: !!className,
              [styles['sidebar-view']]: isSidebarView,
            })}
          >
            {isShowSnackbar && <span className={styles.snackbar}><FormattedMessage id="general.copied" /></span>}
            <div className={cn(styles['app-version'], { [styles['is-active']]: isShowSnackbar })}>
              <span className={styles.version}>{version}</span>
              <CopyIcon className={styles['copy-icon']} />
            </div>
          </div>
        </CopyToClipboard>
      )}
    </FormattedMessage>
  );
};

AppVersionLine.propTypes = {
  className: T.string,
  isSidebarView: T.bool,
};

export default AppVersionLine;
