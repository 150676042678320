import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './ActivityHeader.module.scss';

const ActivityHeader = ({
  className,
  activityId,
  onDoubleClick,
  onOpenDropdown,
  hasDropdown,
  isColumnDirection,
  children,
}) => (
  <div
    className={cn(styles['activity-header'], className, { [styles.column]: isColumnDirection })}
    onDoubleClick={onDoubleClick}
  >
    {children}
    {hasDropdown && (
      <div className={styles['action-column']}>
        <i
          data-name={activityId}
          className={cn('fa fa-dots-three-horizontal', styles.dropdown)}
          onClick={onOpenDropdown}
        />
      </div>
    )}
  </div>
);

ActivityHeader.propTypes = {
  className: T.string,
  activityId: T.number.isRequired,
  onOpenDropdown: T.func,
  onDoubleClick: T.func,
  hasDropdown: T.bool,
  isColumnDirection: T.bool,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

ActivityHeader.defaultProps = {
  hasDropdown: true,
};

export default ActivityHeader;
