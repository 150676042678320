import { fetchFromAPI } from 'utils/api';

export const createAdminPigGroup = (resource) => {
  return fetchFromAPI('/admin/pig_groups', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateAdminPigGroup = (id, resource) => {
  return fetchFromAPI(`/admin/pig_groups/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const publishAdminPigGroup = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}/publish`, { method: 'put' })
    .catch((response) => {
      throw response;
    });
};

export const getAdminPigGroup = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteAdminPigGroup = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}`, { method: 'delete' })
    .catch((response) => {
      throw response;
    });
};

export const closeAdminPigGroup = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}/close_group`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const reopenAdminPigGroup = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}/reopen_group`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const makeAdminPigGroupMarketing = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}/marketing`, { method: 'post' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const makeAdminPigGroupNotMarketing = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}/marketing`, { method: 'delete' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getAdminPigGroupSource = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}/external_source`)
    .catch((response) => {
      throw response;
    });
};

export const getAdminPigGroupCommitments = (id) => {
  return fetchFromAPI(`/admin/pig_groups/${id}/commitments`)
    .then((response) => response.resources)
    .catch((response) => {
      throw response;
    });
};

export const updateAdminPigGroupCommitmentsAssignment = (id, commitments) => {
  return fetchFromAPI(`/admin/pig_groups/${id}/commitments/assignment_update`, {
    method: 'put',
    body: JSON.stringify({ resource: { commitments } }),
  })
    .then(({ resources }) => resources)
    .catch((response) => {
      throw response;
    });
};

export const getPendingCloseAdminGroupsExists = () => (
  fetchFromAPI('/admin/pig_groups/pending_close_exists')
);

export const importAdminPigGroups = (formData) => (
  fetchFromAPI('/admin/pig_groups_imports', { method: 'post', body: formData })
    .then((response) => response.resource)
);

export const makeFasLinkAdminGroupsImport = () => {
  return fetchFromAPI('/admin/pig_groups_fas_link/import', { method: 'post' })
    .then((response) => response.resources)
    .catch((response) => {
      throw response;
    });
};

export const checkFasLinkAdminGroupsOption = () => {
  return fetchFromAPI('/admin/pig_groups_fas_link/check_fas_link_option')
    .then((response) => response.import_pig_groups)
    .catch((response) => {
      throw response;
    });
};
