// react
import React, { Component } from 'react';
import T from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import SearchBox from 'components/SearchBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import Button from 'components/Button';
// utils
import { showToastrMessage } from 'utils';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// health variables api
import { updateDisease } from 'endpoints/admin/healthVariables';

const labels = {
  diseases: <FormattedMessage id="general.diseases" />,
  dataTable: {
    name: <FormattedMessage id="component.dataTable.headers.name" />,
    languagesSupported: <FormattedMessage id="component.dataTable.headers.languagesSupported" />,
  },
  buttons: {
    edit: <FormattedMessage id="general.button.edit" />,
    view: <FormattedMessage id="general.button.view" />,
  },
  id: <FormattedMessage id="general.id" />,
  commonName: <FormattedMessage id="general.commonName" />,
  status: <FormattedMessage id="component.dataTable.headers.status" />,
  getActive: (active) => <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}`} />,
};

class Diseases extends Component {

  componentDidMount() {
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, per_page: 10 }).catch(toastResponseErrors);
  }

  toggleItem = (id, status) => () => {
    const { setDataItem } = this.props;

    return updateDisease(id, { active: status })
      .then((resource) => {
        setDataItem(resource);
        showToastrMessage('component.toastr.status.changed');
      })
      .catch(toastResponseErrors);
  };

  onFilterChange = (filter) => {
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, type: filter, active: filter ? (filter === 'active') : undefined });
  };

  renderNameColumn = ({ name }) => (
    <CustomColumn label={labels.dataTable.name}>
      <span className="semibold">{name}</span>
    </CustomColumn>
  );

  renderExpandable = ({ id }) => (
    <div>
      <Link to={`/admin/health-variables/diseases/${id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        {labels.buttons.edit}
      </Link>
    </div>
  );

  renderActionsColumn = ({ id, active }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        wide
        label={labels.buttons.edit}
        buttonType="small light-gray"
        url={`/admin/health-variables/diseases/${id}/edit`}
        dropDownData={[
          { label: labels.buttons.edit, url: `/admin/health-variables/diseases/${id}/edit` },
          {
            label: labels.getActive(active),
            onClick: this.toggleItem(id, !active),
          }
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/health-variables/diseases/${id}/edit`} className="button light hide-for-large">
        {labels.edit}
      </Link>
      <Button default onClick={this.toggleItem(id, !active)} className="hide-for-large">
        {labels.getActive(active)}
      </Button>
    </div>
  );

  renderIdColumn = ({ code }) => (
    <CustomColumn label={labels.id}>
      {`${('0'.repeat(3 - String(code).length))}${code}`}
    </CustomColumn>
  );

  renderCommonName = ({ common_name }) => (
    <CustomColumn label={labels.commonName}>
      {common_name || '--'}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={labels.status}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  render() {
    const { data, isLoading, meta: { total }, reqParams: { page, per_page, type, search, sort },
      onSearchChange, onPageChange, onPerPageChange, onSortChange } = this.props;
    const { isTablet } = this.context;
    const columns = [
      {
        label: labels.dataTable.name,
        flex: '1 0 150px',
        renderer: this.renderNameColumn,
        sortKey: 'name',
      },
      { label: labels.commonName, flex: '1 0 120px', renderer: this.renderCommonName, sortKey: 'common_name' },
      { label: labels.id, flex: '1 0 120px', renderer: this.renderIdColumn, sortKey: 'id' },
      {
        label: labels.status,
        flex: '1 0 120px',
        renderer: this.renderStatusColumn,
        sortKey: 'active',
      },
      { label: '', flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true },
      { label: '',
        flex: '0 0 40px',
        renderer: () => <ArrowColumn />,
        fixed: true,
        hide: !isTablet, hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    const isEmptyTable = !data.length && !type && !search;

    return (
      <Panel>
        <Panel.Heading title={labels.diseases}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />

          <DataTable
            data={data}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="adminDiseases"
          />

          <NothingBox
            itemsName="diseases"
            display={!data.length}
            isLoading={isLoading}
            search={search}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noDiagnoses" />
            <FormattedMessage tagName="p" id="component.nothingBox.addDiagnosis" />

          </NothingBox>

        </Panel.Body>
      </Panel>
    );
  }
}

Diseases.contextTypes = {
  isTablet: T.bool.isRequired,
};

Diseases.propTypes = {
  fetchData: T.func.isRequired,
  reqParams: T.object.isRequired,
  data: T.array.isRequired,
  meta: T.object.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  setDataItem: T.func.isRequired,
  isLoading: T.bool.isRequired,
};

export default compose(
  connect(
    (state) => ({
      data: state.dataTable.adminDiseases.resources,
      isLoading: state.dataTable.adminDiseases.isLoading,
      reqParams: state.dataTable.adminDiseases.params,
      meta: state.dataTable.adminDiseases.meta,
    }), {
      fetchData: (query) => fetchData('/admin/diagnoses', 'adminDiseases', query),
      setDataItem: (data) => setDataItem(data, 'adminDiseases'),
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
)(Diseases);
