import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import PigGroupCommitmentsPanel from 'components/PigGroupCommitmentsPanel';
// utils
import { getCommitmentDirection } from 'utils/commitmentsHelper';
import { tableNames } from 'utils/constants';

const GroupScheduledLoads = ({
  params: { id: groupId },
  router,
}) => {
  const getOptions = (commitment) => {
    const { id, loading } = commitment;
    const { ready_for_migration } = loading;
    const direction = getCommitmentDirection(commitment);

    const handleClickReceiveLoad = () => {
      router.push({ pathname: `/groups/${groupId}/shipments/create`, query: { commitmentId: id } });
    };

    const handleClickShipLoad = () => {
      router.push({ pathname: `/groups/${groupId}/transfers/create`, query: { commitmentId: id } });
    };

    return [
      {
        label: <FormattedMessage id="general.button.receiveLoad" />, primary: true,
        onClick: handleClickReceiveLoad, hide: !ready_for_migration || direction === 'out', showOnMobile: true },
      {
        label: <FormattedMessage id="general.button.shipLoad" />, primary: true, showOnMobile: true,
        onClick: handleClickShipLoad, hide: !ready_for_migration || direction === 'in' }
    ];
  };

  return (
    <PigGroupCommitmentsPanel
      optionsGetter={getOptions}
      pigGroupId={groupId}
      tableName={tableNames.adminGroupScheduledLoads}
      title={<FormattedMessage id="general.scheduledLoads" />}
    />
  );
};

GroupScheduledLoads.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
};

export default GroupScheduledLoads;
