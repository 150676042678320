import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import StatisticSection from 'components/StatisticSection/StatisticSection';
import DateRangePicker from 'components/DatePicker/DateRangePicker';
import TableFilter from 'components/TableFilter';
import Button from 'components/Button';
import ReactSelect from 'components/Select/ReactSelect';
import AntibioticsTable from 'components/AntibioticsTable/AntibioticsTable';
import AntibioticFarmsTable from './components/AntibioticFarmsTable';
import AntibioticChart from 'components/AntibioticChart/AntibioticChart';
import { animateScroll } from 'react-scroll';
// hooks
import useDidMountEffect from 'hooks/useDidMountEffect';
// endpoints
import {
  getTenantAntibioticStatistics,
  getTenantFarmsAntibioticCSV,
  getTenantTreatmentsAntibioticCSV,
} from 'endpoints/antibioticTracking';
// utils
import {
  getStatsCaption,
  getPeriodDuration,
  getGraphItemData,
  systemAntibioticChartColors,
  antibioticBalloonRender,
} from 'utils/treatmentChartsHelper';
import {
  getGraphGrowsData,
  prepareAntibioticUsageData,
  prepareAntibioticCumulativeUsage,
  prepareStatsData,
} from 'utils/treatmentAntibioticHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatDateToMomentObj } from 'utils/formatDateHelper';
import moment from 'moment';
import download from 'downloadjs';
// styles
import './TreatmentAntibiotic.scss';
//  constants
import { antibioticStatsOptions, antibioticFarmTypesFilters } from 'constants.js';

const statsKeys = ['sum_g_1', 'sum_g_2', 'sum_g_3', 'sum_g_4'];

const tableFilters = [
  { value: '', label: <FormattedMessage id="general.farms" /> },
  { value: 'drugs', label: <FormattedMessage id="general.drugs" /> },
];

const TreatmentAntibiotic = ({
  currentCompany,
  location,
  setPageOptions,
}, {
  router,
}) => {
  const [reqParams, setReqParams] = useState({
    farm_type: '',
    period_name: 'year_to_date',
    date_start: null,
    date_end: null,
  });
  const [{
    cumulativeData,
    farm_types_counts,
    graphData,
    growsGraphData,
    isLoading,
    labels,
    stats,
    usageData,
    usage_by_weeks
  }, setState] = useState({
    cumulativeData: [],
    farm_types_counts: {},
    graphData: [],
    growsGraphData: {},
    isLoading: false,
    labels: [],
    stats: [],
    usage_by_weeks: false,
    usageData: [],
  });
  const { formatMessage } = useIntl();

  const getGraphData = (uniquePeriods, periodLimits, usage_by_weeks) => {
    const { period_name, date_start, date_end } = reqParams;
    const dateStart = formatDateToMomentObj(date_start);
    const dateEnd = formatDateToMomentObj(date_end);
    const graphData = [];
    const labels = [];

    uniquePeriods.forEach((period) => {
      const itemGraphData = getGraphItemData(`sum_g${period}`, 'system', periodLimits[`sum_g${period}`]);
      const label = {
        text: getStatsCaption[period_name](5 - period, { date_start: dateStart, date_end: dateEnd }),
        color: systemAntibioticChartColors[`sum_g${period}`],
      };
      itemGraphData.balloonFunction = (item) => (
        antibioticBalloonRender(
          item,
          usage_by_weeks,
          period_name,
          dateStart,
          dateEnd,
          [4, 3, 2, 1],
          formatMessage
        )
      );
      graphData.push(itemGraphData);
      labels.push(label);
    });

    return { graphData, labels };
  };

  const fetchAntibioticStats = ({ date_start, date_end, period_name, farm_type }) => {
    const dates = ((period_name === 'custom') && date_start && date_end)
      ? { date_start, date_end }
      : {};
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getTenantAntibioticStatistics({ ...dates, period_name, farm_type })
      .then(({ meta: { stats, usage_by_weeks, farm_types_counts }, resources }) => {
        const dateRange = {
          date_start: formatDateToMomentObj(date_start),
          date_end: formatDateToMomentObj(date_end),
        };
        const statsData = prepareStatsData(stats, period_name, dateRange);
        const { maxPeriodDuration, periodLimits } = getPeriodDuration(period_name, dateRange);
        const { uniquePeriods, usageData } = prepareAntibioticUsageData(resources, maxPeriodDuration, periodLimits);
        const growsGraphData = getGraphGrowsData(statsData, period_name, dateRange);
        const { graphData, labels } = getGraphData(uniquePeriods, periodLimits, usage_by_weeks);
        setState({
          isLoading: false,
          stats: statsData,
          farm_types_counts,
          usage_by_weeks,
          usageData,
          labels,
          graphData,
          cumulativeData: prepareAntibioticCumulativeUsage(usageData),
          growsGraphData,
        });
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        return toastResponseErrors(errors);
      });
  };

  useEffect(() => {
    if ((reqParams.period_name === 'custom') && (!reqParams.date_start || !reqParams.date_end)) return;
    fetchAntibioticStats(reqParams);
  }, [reqParams]);

  useEffect(() => {
    setPageOptions({ mobileTitle: 'antibioticTracking' });
    return () => {
      setPageOptions({ mobileTitle: '' });
    };
  }, []);

  useDidMountEffect(() => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  }, [location.query]);

  const handleChangeFarmType = (value) => {
    if (value !== reqParams.farm_type) {
      setReqParams((prevParams) => ({ ...prevParams, farm_type: value }));
    }
  };

  const handleChangeTableType = (value) => {
    router.push({ pathname: location.pathname, query: value === 'drugs' ? { tab: 'drugs' } : {} });
  };

  const handleSelectPeriod = (option) => {
    if (!option) return;
    const { value } = option;
    if (value !== reqParams.period_name) {
      const dates = value !== 'custom' ? { date_start: null, date_end: null } : {};
      setReqParams((prevParams) => ({ ...prevParams, period_name: value, ...dates }));
    }
  };

  const handleChangeDates = ({ startDate, endDate }) => {
    setReqParams((prevParams) => ({
      ...prevParams,
      date_start: startDate ? startDate.format('YYYY-MM-DD') : null,
      date_end: endDate ? endDate.format('YYYY-MM-DD') : null,
    }));
  };

  const handleDownloadCSV = (e) => {
    e.stopPropagation();
    const { farm_type, period_name, date_start, date_end } = reqParams;
    const typeTitle = farm_type ? `-farm-type-${farm_type}` : '';
    const periodTitle = (period_name && (period_name !== 'custom')) ? `-period-${period_name}` : '';
    const datesTitle = date_start && date_end
      ? `-date-start-${date_start}-date-end-${date_end}` : '';
    const scope = location.query.tab === 'drugs' ? 'treatments' : 'farms';
    const fileName = `${currentCompany.name}-antibiotic-usage-by-${scope}${typeTitle + periodTitle + datesTitle}`;
    (scope === 'farms' ? getTenantFarmsAntibioticCSV : getTenantTreatmentsAntibioticCSV)(reqParams)
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };

  const isCustomPeriod = reqParams.period_name === 'custom';
  const categoryTitle = formatMessage({
    id: usage_by_weeks ? 'general.week' : 'general.day',
  }).toUpperCase();
  const farmFilters = antibioticFarmTypesFilters.filter(({ value }) => (!value || Boolean(farm_types_counts[value])));
  const chartTitleValues = { period: usage_by_weeks ? 'week' : 'day' };

  return (
    <div className="TreatmentAntibiotic small-12 column">
      <TableFilter
        filters={farmFilters}
        onFilterChange={handleChangeFarmType}
        activeFilter={reqParams.farm_type}
        className="mt-10"
      >
        <div className="subnav-content">
          {isCustomPeriod && (
            <DateRangePicker
              startDate={formatDateToMomentObj(reqParams.date_start)}
              endDate={formatDateToMomentObj(reqParams.date_end)}
              isOutsideRange={(day) => (day.isAfter(moment(), 'day'))}
              onDatesChange={handleChangeDates}
            />
          )}
          <ReactSelect
            className="select-period"
            placeholder={<FormattedMessage id="general.selectPeriod" />}
            options={antibioticStatsOptions}
            valueKey="value"
            labelKey="name"
            value={reqParams.period_name}
            onChange={handleSelectPeriod}
            noInputMargin
            searchable={false}
          />
          <Button
            primary
            onClick={handleDownloadCSV}
            disabled={isCustomPeriod && (!reqParams.date_start || !reqParams.date_end)}
          >
            <FormattedMessage id="general.button.download" />
          </Button>
        </div>
      </TableFilter>

      <StatisticSection isLoading={isLoading} className="mt-20" data={stats} />

      <AntibioticChart
        isLoading={isLoading}
        data={usageData}
        graphData={graphData}
        labels={labels}
        categoryField="position"
        categoryTitle={categoryTitle}
        growsData={growsGraphData}
        title={<FormattedMessage id="general.antibioticUsage" values={chartTitleValues} />}
      />
      <AntibioticChart
        isLoading={isLoading}
        data={cumulativeData}
        graphData={graphData}
        labels={labels}
        categoryField="position"
        categoryTitle={categoryTitle}
        growsData={growsGraphData}
        title={<FormattedMessage id="general.cumulativeAntibioticUsage" />}
      />

      <TableFilter
        className="mv-15"
        filters={tableFilters}
        onFilterChange={handleChangeTableType}
        activeFilter={location.query.tab || ''}
      />
      {location.query.tab !== 'drugs' && (
        <AntibioticFarmsTable params={reqParams} statsKeys={statsKeys} />
      )}
      {location.query.tab === 'drugs' && (
        <AntibioticsTable
          params={reqParams}
          statsKeys={statsKeys}
          endpoint="/antibiotic_tracking/tenants/treatments"
          tableKey="antibiotics"
        />
      )}
    </div>
  );
};

TreatmentAntibiotic.contextTypes = {
  router: T.object.isRequired,
};

TreatmentAntibiotic.propTypes = {
  setPageOptions: T.func.isRequired,
  location: T.object.isRequired,
  currentCompany: T.object.isRequired,
};

export default connect(
  ((state) => ({
    currentCompany: state.auth.user.current_company,
  })),
  { setPageOptions }
)(TreatmentAntibiotic);
