import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// translation
import { FormattedMessage } from 'react-intl';
// styles
import styles from './AllCaughtUp.module.scss';

const cn = classnames.bind(styles);

const AllCaughtUp = ({ className, children }) => (
  <div className={cn('AllCaughtUp', { [className]: !!className })}>
    <i className={cn('check-icon', 'fa', 'fa-check-circle')} />
    <span className={cn('caption')}><FormattedMessage id="container.farmfeed.caughtUp" /></span>
    {children}
  </div>
);

AllCaughtUp.propTypes = {
  className: T.string,
  children: T.any,
};

AllCaughtUp.defaultProps = {
  className: '',
};

export default AllCaughtUp;
