import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { createInvite, inviteToTenant } from 'reducers/admin/users';
// components
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel';
import CreateUserForm from './components/CreateUserForm';
import FormErrorsBox from 'components/FormErrorsBox';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import pick from 'lodash.pick';
import get from 'lodash.get';
import { showToastrMessage } from 'utils';

const customErrors = [
  { key: 'email', error: <FormattedMessage id="general.customErrors.email" /> },
];

class CreateUser extends Component {

  handleFormSubmit = (data) => {
    const { router } = this.context;
    const { createInvite, inviteToTenant } = this.props;
    // if user exists we use another action to invite him to tenant
    if (data.user_status === 'found') {
      const inviteEmail = get(data, 'email', '').toLowerCase();
      return inviteToTenant(inviteEmail)
        .then(({ id }) => {
          showToastrMessage('component.toastr.userInvite.sent');
          router.push(`/admin/users/${id}/farm-roles`);
        })
        .catch(toastResponseErrors);
    }

    const entity = pick(data, ['email', 'first_name', 'last_name', 'nickname', 'telephone', 'language']);
    if (entity.telephone) {
      entity.phone = entity.telephone.phone;
      entity.country_code = entity.telephone.countryCode;
      delete entity.telephone;
    }
    const email = get(entity, 'email', '').toLowerCase();
    return createInvite({ ...entity, email })
      .then(({ id }) => {
        showToastrMessage('component.toastr.user.created');
        router.push(`/admin/users/${id}/farm-roles`);
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { tenantLanguage } = this.props;
    const initialValues = { language: tenantLanguage || 'en' };
    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.button.createUser" />} />
          <Panel.Body noPadding>
            <div className="row">
              <section className="small-12 medium-10 large-8 column">
                <FormErrorsBox formName="create-user-by-admin" customErrors={customErrors} />
                <CreateUserForm onSubmit={this.handleFormSubmit} initialValues={initialValues} />
              </section>
            </div>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

CreateUser.propTypes = {
  createInvite: T.func,
  inviteToTenant: T.func,
  tenantLanguage: T.string.isRequired,
};

CreateUser.contextTypes = {
  router: T.object,
};

export default connect(
  (state) => ({
    tenantLanguage: state.auth.user.current_company.language,
  }),
  { createInvite, inviteToTenant }
)(CreateUser);
