import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { fetchData } from 'reducers/dataTable';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn } from 'components/DataTable/Columns';
import FormattedWeight from 'components/FormattedWeight';
import { GroupStatusBadge } from 'components/StatusBadge/StatusBadge';
import Link from 'components/Link/Link';
import NothingBox from 'components/NothingBox';
import SearchBox from 'components/SearchBox';
import TableFilter from 'components/TableFilter';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import FarmTypeLabel from 'components/FarmTypeLabel/FarmTypeLabel';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// utils
import moment from 'moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatMortality } from 'utils';
// constants
import { companyGroupsTabFilters } from 'constants.js';

const labels = {
  groupId: <FormattedMessage id="component.dataTable.headers.groupId" />,
  farmName: <FormattedMessage id="component.dataTable.headers.farmName" />,
  startDate: <FormattedMessage id="component.dataTable.headers.startDate" />,
  closeDate: <FormattedMessage id="component.dataTable.headers.closeDate" />,
  status: <FormattedMessage id="component.dataTable.headers.status" />,
  farmType: <FormattedMessage id="general.type" />,
  inventory: <FormattedMessage id="component.dataTable.headers.inventory" />,
  averageWeight: <FormattedMessage id="component.dataTable.headers.avgWeight" />,
  mortality: <FormattedMessage id="component.dataTable.headers.mortRate" />,
};

class GeneticGroups extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  renderGroupIDColumn = ({ id, name }) => (
    <CustomColumn>
      <div className="group-id-column">
        <Link className="primary" to={`/groups/${id}`}>
          {name}
        </Link>
      </div>
    </CustomColumn>
  );

  renderFarmColumn = ({ farm_name, farm_id }) => (
    <CustomColumn label={labels.farmName}>
      <Link className="primary" to={`/admin/farms/${farm_id}`}>
        {farm_name}
      </Link>
    </CustomColumn>
  );

  renderStartDateColumn = ({ started_on }) => (
    <CustomColumn label={labels.startDate}>
      {started_on ? moment(started_on).format('MM/DD/YYYY') : '-'}
    </CustomColumn>
  );

  renderFarmTypeColumn = ({ farm_type }) => (
    <CustomColumn label={labels.farmType}>
      <FarmTypeLabel farmType={farm_type} />
    </CustomColumn>
  );

  renderInventoryColumn = ({ pigs }) => (
    <CustomColumn label={labels.inventory}>
      {pigs || 'N/A'}
    </CustomColumn>
  );

  renderCloseDateColumn = ({ closed_on }) => (
    <CustomColumn label={labels.closeDate}>
      {closed_on ? moment(closed_on).format('MM/DD/YYYY') : '-'}
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn label={labels.status}>
      <GroupStatusBadge status={status} textValues={{ date: null }} />
    </CustomColumn>
  );

  renderWeightColumn = ({ estimated_weight }) => (
    <CustomColumn label={labels.averageWeight}>
      <FormattedWeight value={estimated_weight} />
    </CustomColumn>
  );

  renderMortalityColumn = ({ mortality_rate }) => (
    <CustomColumn label={labels.mortality}>
      {formatMortality(mortality_rate)}
    </CustomColumn>
  );

  getFilter = (status) => {
    if (!status) return '';
    if (Array.isArray(status)) {
      return status.includes('opened') ? 'opened' : 'pending';
    }
    return status;
  };

  render() {
    const { pigGroups, isLoading, meta: { total, stats }, reqParams: { page, per_page, status, search, sort },
      onStatusChange, onSearchChange, onPageChange, onPerPageChange, onSortChange, route: { type } } = this.props;
    const isEmptyTable = !pigGroups.length && !status && !search;

    const columns = [
      { label: labels.groupId, flex: '1 140px', renderer: this.renderGroupIDColumn, sortKey: 'name' },
      { label: labels.farmName, flex: '1 140px', renderer: this.renderFarmColumn, sortKey: 'farm_name' },
      { label: labels.farmType, flex: '1 50px', renderer: this.renderFarmTypeColumn, sortKey: 'farm_type' },
      {
        label: labels.startDate, flex: '1 100px', renderer: this.renderStartDateColumn, sortKey:
          'started_on'
      },
      {
        label: labels.closeDate, flex: '1 100px', renderer: this.renderCloseDateColumn,
        sortKey: 'closed_on'
      },
      { label: labels.inventory, flex: '1 100px', renderer: this.renderInventoryColumn },
      { label: labels.status, flex: '1 1 120px', renderer: this.renderStatusColumn, sortKey: 'status' },
      { label: labels.mortality, flex: '1 100px', renderer: this.renderMortalityColumn, sortKey: 'mortality_rate' },
      { label: labels.averageWeight, flex: '1 105px', renderer: this.renderWeightColumn, },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.sourcedGroups" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />
          }
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={companyGroupsTabFilters}
              onFilterChange={onStatusChange}
              stats={stats}
              activeFilter={status}
              className="ph-10"
            />
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={pigGroups}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            scrollable
            tableKey={type}
          />

          <NothingBox
            itemsName="groups"
            display={!pigGroups.length}
            isLoading={isLoading}
            search={search}
            filter={this.getFilter(status)}
          >
            <h2 className="lighter"><FormattedMessage id="component.nothingBox.noData" /></h2>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

GeneticGroups.propTypes = {
  isLoading: T.bool,
  pigGroups: T.array,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  onStatusChange: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  route: T.object.isRequired
};

const enhance = compose(
  connect(
    (state, { params: { id } }) => ({
      isLoading: state.dataTable.geneticGroups.isLoading,
      pigGroups: state.dataTable.geneticGroups.resources,
      reqParams: state.dataTable.geneticGroups.params,
      meta: state.dataTable.geneticGroups.meta,
      authUser: state.auth.user,
      id: +(id || state.auth.user.current_company.id)
    }), { fetchData }
  ),
  withProps(({ id, fetchData }) => ({
    fetchData: (query) => fetchData(`/admin/genetics/${id}/pig_groups`, 'geneticGroups', query),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(GeneticGroups);
