import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './PlusButton.module.scss';

const PlusButton = ({ onClick, className }) => (
  <div onClick={onClick} className={cn(styles['plus-button'], className)}>
    <div className={styles.icon}>+</div>
  </div>
);

PlusButton.propTypes = {
  onClick: T.func.isRequired,
  className: T.string,
};

export default PlusButton;
