import React from 'react';
import T from 'prop-types';
// components
import { Link as ReactRouterLink } from 'react-router';
// utils
import cn from 'classnames';
// styles
import styles from './Link.module.scss';

const isExternalUrl = (url) => {
  if (typeof url === 'object' || typeof url === 'function') return false;
  return ['http://', 'https://', 'www.'].some((item) => url.includes(item));
};

const Link = ({ children, to, activeClassName, isUnderlined, className, ...others }) => {
  const isExternal = isExternalUrl(to);

  if (isExternal) {
    return (
      <a href={to} {...others}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink
      to={to}
      activeClassName={activeClassName}
      {...others}
      className={cn(styles.link, { [className]: !!className, [styles['is-underlined']]: isUnderlined })}
    >
      {children}
    </ReactRouterLink>
  );
};

Link.propTypes = {
  activeClassName: T.string,
  className: T.string,
  isUnderlined: T.bool,
  to: T.oneOfType([T.object, T.string, T.func]),
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default Link;
