import { fetchFromAPI } from './api';
import { toastResponseErrors } from './responseErrorsHelper';
import { languageMapping } from 'constants.js';

export const fetchTranslate = (language, text, comment_id) => {
  const params = { text, language: languageMapping[language] || language };
  if (comment_id) params.comment_id = comment_id;
  return fetchFromAPI('/translation/translate_text', {
    method: 'POST',
    body: JSON.stringify({ resource: params }),
  })
    .then((resp) => (resp))
    .catch(toastResponseErrors);
};
