import React, { Fragment } from 'react';
// components
import InfoRow from 'components/InfoRow/InfoRow';
import { FormattedMessage } from 'react-intl';
// utils
import get from 'lodash.get';
import moment from 'moment';

export const departureDetails = [
  { label: <FormattedMessage id="general.barn" />, key: 'pig_group.name' },
  { label: <FormattedMessage id="general.group" />, key: 'barn.name' },
];

export const renderCommitmentDetails = (commitment, loadType) => {
  if (loadType === 'arrival' && commitment.commitment_type === 'source') {
    return null;
  }
  if (loadType === 'sale' && commitment.commitment_type === 'destination') {
    return (
      <InfoRow
        label={<FormattedMessage id="general.dockTime" />}
        value={commitment.arrive_at ? moment(commitment.arrive_at).format('hh:mm A') : ''}
      />
    );
  }
  if (loadType === 'sale' && commitment.commitment_type === 'source') {
    return (
      <InfoRow label={<FormattedMessage id="general.group" />} value={get(commitment, 'pig_group.name', 'N/A')} />
    );
  }

  return (
    <Fragment>
      <InfoRow label={<FormattedMessage id="general.group" />} value={get(commitment, 'pig_group.name', 'N/A')} />
      <InfoRow label={<FormattedMessage id="general.barn" />} value={get(commitment, 'barn.name', 'N/A')} />
    </Fragment>
  );

};
