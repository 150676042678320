import React from 'react';
import T from 'prop-types';
// components
import Select from 'react-select';
// utils
import cn from 'classnames';
import { isIOS } from 'react-device-detect';
// styles
import 'react-select/dist/react-select.css';
import './ReactSelect.scss';

const ReactSelect = ({ getRef, className, noInputMargin, ...others }) => {
  const props = {
    ...others,
    className: cn('ReactSelect', className, { isIOS, noInputMargin }),
  };
  return (
    <Select {...props} ref={getRef} />
  );
};

ReactSelect.defaultProps = {
  clearable: false,
  resetValue: {},
  arrowRenderer: () => <i className="fa fa-angle-down" />,
};

ReactSelect.propTypes = {
  className: T.string,
  noInputMargin: T.bool,
  getRef: T.func,
  clearable: T.bool,
  resetValue: T.object,
  arrowRenderer: T.func,
};

export default ReactSelect;
