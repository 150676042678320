import React from 'react';
// utils
import classnames from 'classnames/bind';
// assets
import noRolesPlaceholder from '../../../public/images/roles-placeholder.svg';
import styles from './RolesPlaceholder.module.scss';

const cn = classnames.bind(styles);

const RolesPlaceholder = () => (
  <img className={cn('roles-placeholder')} src={noRolesPlaceholder} role="presentation" alt="Assign Role" />
);

export default RolesPlaceholder;
