import React from 'react';
import './FarmFeedActivityPlaceholder.scss';

const FarmFeedActivityPlaceholder = () => (
  <div className="FarmFeedActivityPlaceholder">
    <div className="null-state-row">
      <div className="square" />
      <div className="lines-block direction-column">
        <div className="long line" />
        <div className="normal line" />
      </div>
    </div>
    <div className="null-state-row border direction-column">
      <div className="row-item">
        <div className="item-block">
          <div className="square" />
          <div className="lines-block direction-column">
            <div className="small line" />
            <div className="long line" />
          </div>
        </div>
      </div>
      <div className="row-divider" />
      <div className="row-item">
        <div className="lines-block big">
          <div className="small line" />
          <div className="extra-small line" />
        </div>
      </div>
      <div className="row-divider" />
      <div className="row-item">
        <div className="item-block direction-column border">
          <div className="single-line-block">
            <div className="small line" />
          </div>
          <div className="row-divider" />
          <div className="lines-block big">
            <div className="small line" />
            <div className="extra-small line" />
          </div>
        </div>
      </div>
      <div className="row-divider" />
      <div className="row-item">
        <div className="single-line-block center">
          <div className="small line" />
        </div>
      </div>
    </div>
    <div className="row-divider" />
    <div className="null-state-row">
      <div className="square little" />
      <div className="single-line-column">
        <div className="small line" />
      </div>
    </div>
  </div>
);

export default FarmFeedActivityPlaceholder;
