import React, { Component } from 'react';
import T from 'prop-types';
// redux, reconnect
// components
import { FormattedMessage } from 'react-intl';
import NothingBox from 'components/NothingBox';
import CircleCheckbox from 'components/CircleCheckbox';
import Avatar from 'components/Avatar/Avatar';
import { CustomColumn } from 'components/DataTable/Columns';
// utils
import cn from 'classnames';
// styles
import './UsersTruckingRolesManageTable.scss';

class UsersTruckingRolesManageTable extends Component {

  state = {
    markers: {},
  };

  setMarkerValue = (key, value) => {
    this.setState((prevState) => {
      return { markers: { ...prevState.markers, [key]: value } };
    });
  };

  getMarkerValue = (key) => {
    return this.state.markers[key] || false;
  };

  getMarkerKey = (truckingCompany, name) => {
    return `${name}_${truckingCompany.id}`;
  };

  handleRoleChange = (truckingCompany) => (e) => {
    const { name, checked } = e.target;
    const markerKey = this.getMarkerKey(truckingCompany, name);
    this.setMarkerValue(markerKey, true);
    this.props.handleRoleChanged(truckingCompany.id, name, checked)
      .then(() => { this.setMarkerValue(markerKey, false); })
      .catch(() => { this.setMarkerValue(markerKey, false); });
  };

  isDisabledRoleCheckbox = (truckingCompany, roleName) => {
    const markerKey = this.getMarkerKey(truckingCompany, roleName);
    return this.getMarkerValue(markerKey);
  };

  render() {
    const { data, search, filter, isLoading } = this.props;

    return (
      <div className="UsersTruckingRolesManageTable">
        <div className="flex-data-table">

          {data.map((truckingCompany) => {
            const { name, is_trucking_admin, is_driver } = truckingCompany;
            return (
              <div className="table-item" key={truckingCompany.id}>
                {/* Farm Name */}
                <div className="table-item-block column-farm-name">
                  <div className="collapsible-value">
                    <div className="value-amount title">
                      <div className="user-info">
                        <CustomColumn flexRow>
                          <Avatar type="trucking-company" avatarSize={35} iconSize={16} className="mr-10" />
                          <span className="semibold">{name}</span>
                        </CustomColumn>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Trucking Admin Role */}
                <div className={cn('table-item-block column-roles', { 'checked': is_trucking_admin })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={
                          (
                            <span className="semibold hide-for-large">
                              <FormattedMessage id="general.role.truckingAdmin" />
                            </span>
                          )
                        }
                        name="trucking_admin"
                        onChange={this.handleRoleChange(truckingCompany)}
                        checked={is_trucking_admin}
                        disabled={this.isDisabledRoleCheckbox(truckingCompany, 'trucking_admin')}
                      />
                    </div>
                  </div>
                </div>
                {/* Driver Role */}
                <div className={cn('table-item-block column-roles', { 'checked': is_driver })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={
                          (
                            <span className="semibold hide-for-large">
                              <FormattedMessage id="general.role.driver" />
                            </span>
                          )
                        }
                        name="driver"
                        onChange={this.handleRoleChange(truckingCompany)}
                        checked={is_driver}
                        disabled={this.isDisabledRoleCheckbox(truckingCompany, 'driver')}
                      />
                    </div>
                  </div>
                </div>
              </div>);
          })}
        </div>

        <NothingBox
          itemsName="roles"
          display={!data.length}
          isLoading={isLoading}
          search={search}
          filter={filter}
        >
          <h1><FormattedMessage id="general.nothingBox.noRoles" /></h1>
        </NothingBox>
      </div>
    );
  }
}

UsersTruckingRolesManageTable.propTypes = {
  data: T.array,
  handleRoleChanged: T.func,
  search: T.string,
  filter: T.oneOfType([T.string, T.object]),
  isLoading: T.bool,
};

export default UsersTruckingRolesManageTable;
