import React from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import Modal from 'components/Modal/Modal';
import DrBotAvatar from 'components/DrBotAvatar/DrBotAvatar';
// utils
import { FormattedMessage } from 'react-intl';
import getValue from 'lodash.get';
// style
import './CheckinSuccessModal.scss';

const CheckinSuccessModal = ({ checkin, closeModal }, { router }) => {

  const onCancel = () => closeModal();
  const goToFarm = () => {
    router.push(`/daily-checkup/farms/${checkin.farm_id}`);
    closeModal();
  };

  const messageValues = { farm_name: <b>{getValue(checkin, 'farm.name')}</b> };

  return (
    <Modal className="CheckinSuccessModal">
      <section className="modal-body">
        <div className="avatar-wrapper"><DrBotAvatar /></div>
        <div className="content-wrapper">
          <FormattedMessage id="container.checkinMobile.successMessage" values={messageValues} />
        </div>
      </section>
      <section className="modal-footer">
        <Button className="button light" onClick={onCancel}>
          <FormattedMessage id="general.button.noThanks" />
        </Button>
        <Button className="button primary" onClick={goToFarm}>
          <FormattedMessage id="general.button.goToFarm" />
        </Button>
      </section>
    </Modal>
  );
};

CheckinSuccessModal.contextTypes = {
  router: T.object.isRequired,
};

CheckinSuccessModal.propTypes = {
  checkin: T.object.isRequired,
  closeModal: T.func.isRequired,
};

export default CheckinSuccessModal;
