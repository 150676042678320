import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { formatGrams } from './index';
import { formatDateRange } from './formatDateHelper';
import { getGrowthRate, getStatsCaption } from './treatmentChartsHelper';
import isNull from 'lodash.isnull';

export function prepareStatsData(stats, period_name, dateRange) {
  return [
    {
      caption: getStatsCaption[period_name](4, dateRange), value: formatGrams(stats.sum_g_1),
      grows: getGrowthRate(stats.sum_g_1, stats.sum_g_0),
    },
    {
      caption: getStatsCaption[period_name](3, dateRange), value: formatGrams(stats.sum_g_2),
      grows: getGrowthRate(stats.sum_g_2, stats.sum_g_1),
    },
    {
      caption: getStatsCaption[period_name](2, dateRange), value: formatGrams(stats.sum_g_3),
      grows: getGrowthRate(stats.sum_g_3, stats.sum_g_2),
    },
    {
      caption: getStatsCaption[period_name](1, dateRange), value: formatGrams(stats.sum_g_4),
      grows: getGrowthRate(stats.sum_g_4, stats.sum_g_3),
    },
  ];
}

export function prepareAntibioticUsageData(data, maxPeriodDuration, periodLimits) {
  const usageData = [];
  const periods = new Set();
  const itemData = {};

  if (!data.length) {
    return { usageData: [], uniquePeriods: [] };
  }

  for (let i = 0; i < data.length; i += 1) {
    const { position, period } = data[i];
    const sumGr = data[i].sum_g || 0;
    const existedData = usageData[position] || {};
    usageData[position] = { ...existedData, period, [`sum_g${period}`]: sumGr, position: position + 1 };
    periods.add(period);
  }

  periods.forEach((i) => { itemData[`sum_g${i}`] = 0; });

  for (let i = 1; i <= maxPeriodDuration; i += 1) {
    const dataForAvg4 = (i > periodLimits.sum_g4) ? { sum_g4: null } : {};
    const dataForAvg3 = (i > periodLimits.sum_g3) ? { sum_g3: null } : {};
    const dataForAvg2 = (i > periodLimits.sum_g2) ? { sum_g2: null } : {};
    const dataForAvg1 = (i > periodLimits.sum_g1) ? { sum_g1: null } : {};
    const periodsData = { ...dataForAvg4, ...dataForAvg3, ...dataForAvg2, ...dataForAvg1 };
    const existedData = usageData[i - 1] || {};
    usageData[i - 1] = { ...itemData, ...existedData, ...periodsData, position: i };
  }
  return { usageData, uniquePeriods: [...periods].sort((a, b) => (a - b)) };
}

export function prepareGroupAntibioticUsageData(data, statKeys) {
  const result = [];
  const periodsLastIndex = {};

  for (let i = 0; i < data.length; i += 1) {
    const el = data[i];
    for (let j = 0; j < statKeys.length; j += 1) {
      if (!isNull(el[statKeys[j]]) &&
        (!(statKeys[j] in periodsLastIndex) || periodsLastIndex[statKeys[j]] < el.weeks_on_feed)) {
        periodsLastIndex[statKeys[j]] = el.weeks_on_feed;
      }
    }
    result[el.weeks_on_feed] = el;
  }
  const maxPosition = Math.max(...Object.values(periodsLastIndex));
  for (let i = 0; i <= maxPosition; i += 1) {
    let el = result[i];
    if (!el) {
      el = { weeks_on_feed: i };
      result[i] = el;
    }
    for (const key in periodsLastIndex) {
      if (el.weeks_on_feed < periodsLastIndex[key] && !el[key]) {
        el[key] = 0;
      } else if (!el[key]) {
        el[key] = null;
      }
    }
  }

  return result;
}

export function prepareGroupAntibioticCumulativeUsage(data, uniquePeriods) {
  const cumulativeData = [];
  const periodValues = {};
  uniquePeriods.forEach((period) => {
    periodValues[period] = 0;
  });

  for (let i = 0; i < data.length; i += 1) {
    const resource = { ...data[i] };
    uniquePeriods.forEach((period) => {
      periodValues[period] = resource[period] === null ? periodValues[period] : resource[period] + periodValues[period];
    });
    cumulativeData[i] = { ...resource, ...periodValues };
  }

  return cumulativeData;
}

export function prepareAntibioticCumulativeUsage(data) {
  const cumulativeData = [];
  let sum_g1 = 0;
  let sum_g2 = 0;
  let sum_g3 = 0;
  let sum_g4 = 0;
  for (let i = 0; i < data.length; i += 1) {
    sum_g4 = data[i].sum_g4 === null ? null : data[i].sum_g4 + sum_g4;
    sum_g3 = data[i].sum_g3 === null ? null : data[i].sum_g3 + sum_g3;
    sum_g2 = data[i].sum_g2 === null ? null : data[i].sum_g2 + sum_g2;
    sum_g1 = data[i].sum_g1 === null ? null : data[i].sum_g1 + sum_g1;
    cumulativeData.push({ ...data[i], sum_g1, sum_g2, sum_g3, sum_g4 });
  }

  return cumulativeData;
}

export function prepareFarmAntibioticCumulativeUsage(data) {
  const cumulativeData = [];
  let farm_avg_g = 0;
  let tenant_type_avg_g = 0;
  for (let i = 0; i < data.length; i += 1) {
    farm_avg_g = data[i].farm_avg_g === null ? null : data[i].farm_avg_g + farm_avg_g;
    tenant_type_avg_g = data[i].tenant_type_avg_g === null ? null : data[i].tenant_type_avg_g + tenant_type_avg_g;
    cumulativeData.push({ farm_avg_g, tenant_type_avg_g, position: data[i].position });
  }

  return cumulativeData;
}

export function formatParams(params) {
  return {
    ...params,
    date_start: params.date_start?.format?.('YYYY-MM-DD') || null,
    date_end: params.date_end?.format?.('YYYY-MM-DD') || null,
  };
}

export function getGraphGrowsData(data, period, { date_start, date_end }) {
  const growsLabel = (
    <FormattedMessage
      id={`general.antibiotic.growsLabelUsage.${period}`}
      values={{ range: formatDateRange(date_start, date_end) }}
    />
  );
  const emptyData = { value: null, grows: null, growsLabel };
  if (!data.length) return emptyData;

  for (let i = 3; i >= 0; i -= 1) {
    const { value, grows } = data[i];
    if (value) return { value, grows, growsLabel };
  }

  return emptyData;
}
