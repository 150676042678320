import moment from 'moment';

export function formatDateToMomentObj(date, format = 'YYYY-MM-DD') {
  return date ? moment(date, format) : null;
}

export function formatDateRange(startDate, endDate) {
  if (!startDate || !endDate) return 'N/A - N/A';
  const isSameMonth = moment(startDate).isSame(endDate, 'month');
  const isSameYear = moment(startDate).isSame(endDate, 'year');
  const formatStartDate = isSameYear ? 'MMM DD' : 'MMM DD, YYYY';
  const formatEndDate = isSameYear && isSameMonth ? 'DD, YYYY' : 'MMM DD, YYYY';
  return moment(startDate).format(formatStartDate) + ' - ' + moment(endDate).format(formatEndDate);
}
