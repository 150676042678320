import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { updatePlantProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import PackingPlantForm from '../../components/PackingPlantForm';
import Panel from 'components/Panel';
// constants
import { addressFields } from 'constants.js';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import pick from 'lodash.pick';

class PackingPlantEdit extends Component {

  updatePackingPlant = (data) => {
    const resource = {
      name: data.name,
      ...data.addressObj,
    };
    const { updatePlantProfile, params: { id, plantId } } = this.props;
    return updatePlantProfile(`/admin/packing_companies/${id}/packing_plants/${plantId}`, {
      method: 'put',
      body: JSON.stringify({ resource })
    })
      .then(() => {
        showToastrMessage('component.toastr.packingPlant.updated');
      })
      .catch(toastResponseErrors);
  };

  getInitialValues = () => {
    const { params: { plantId }, plant } = this.props;

    if (plant.id === Number(plantId)) {
      return {
        name: plant.name,
        addressObj: pick(plant, addressFields),
      };
    }
    return null;
  };

  render() {
    const initialValues = this.getInitialValues();
    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.plantInfo" />} />
        <Panel.Body noPadding>
          <PackingPlantForm onSubmit={this.updatePackingPlant} initialValues={initialValues} isEditForm />
        </Panel.Body>
      </Panel>
    );
  }
}

PackingPlantEdit.propTypes = {
  updatePlantProfile: T.func.isRequired,
  params: T.object,
  plant: T.object,
};

export default connect((state) => ({
  plant: state.profiles.plant.data,
}), { updatePlantProfile })(PackingPlantEdit);
