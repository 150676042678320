import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { getUserFiltersAction, deleteUserFilterAction } from 'reducers/userFilters';
// components
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Preloader from 'components/Preloader';
// hooks
import useOnclickOutside from 'react-cool-onclickoutside';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './ChooseSegmentDropdown.module.scss';

const cn = classnames.bind(styles);

const ChooseSegmentDropdown = ({
  deleteUserFilterAction,
  getUserFiltersAction,
  filterGroupName,
  isUserFiltersLoading,
  isUserFiltersLoaded,
  currentFilterId,
  onFilterChoose,
  openModal,
  userFilters,
}) => {
  const [isOpened, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpened && !isUserFiltersLoaded) {
      getUserFiltersAction(filterGroupName);
    }
  }, [isOpened]);

  const handleClick = () => setIsOpen(!isOpened);

  const handleDeleteSegment = (e, filterId, filterName) => {
    e.stopPropagation();
    const handleSubmit = () => {
      return deleteUserFilterAction(filterId, filterGroupName)
        .then(() => {
          if (filterId === currentFilterId) onFilterChoose();
        })
        .catch(toastResponseErrors);
    };
    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteSegment" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteSegment.confirm" />}
        actionBtnProps={{ primary: true }}
        handleConfirm={handleSubmit}
      >
        <FormattedMessage
          id="component.modal.deleteSegment.text"
          values={{
            name: <strong>{filterName}</strong>,
            b: (msg) => <b>{msg}</b>,
          }}
        />
      </ConfirmationModal>
    );
  };

  const wrapperRef = useOnclickOutside(() => {
    if (isOpened) setIsOpen(false);
  });

  return (
    <div ref={wrapperRef} onClick={handleClick} className={cn('choose-filter-wrapper')}>
      <FormattedMessage id="general.chooseSegment" />
      <i className={cn('fa fa-angle-down-btb', 'angle-icon')} />
      {isOpened && (
        <div className={cn('filter-dropdown-menu')}>
          {userFilters.map((filter) => (
            <li
              key={filter.id}
              onClick={() => onFilterChoose(filter)}
              className={cn('list-item', { active: currentFilterId === filter.id })}
            >
              <div className={cn('option-label')}>{filter.name}</div>
              {currentFilterId === filter.id && <i className={cn('fa fa-check', 'selected-icon')} />}
              <i
                className={cn('fa fa-times', 'remove-icon')}
                onClick={(e) => handleDeleteSegment(e, filter.id, filter.name)}
              />
            </li>
          ))}
          {!userFilters.length && (
            <div className={cn('no-filters-box')} onClick={(e) => e.stopPropagation()}>
              <Preloader isCentered isActive={isUserFiltersLoading && !isUserFiltersLoaded} isSmall />
              <i className={cn('fa fa-info-circle', 'info-icon')} />
              <FormattedMessage id="component.userFilters.noSegments" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ChooseSegmentDropdown.propTypes = {
  deleteUserFilterAction: T.func.isRequired,
  getUserFiltersAction: T.func.isRequired,
  filterGroupName: T.string.isRequired,
  isUserFiltersLoading: T.bool.isRequired,
  isUserFiltersLoaded: T.bool.isRequired,
  onFilterChoose: T.func.isRequired,
  openModal: T.func.isRequired,
  currentFilterId: T.number,
  userFilters: T.array.isRequired,
};

export default connect(
  (state, props) => ({
    isUserFiltersLoading: state.userFilters[props.filterGroupName]?.isLoading || false,
    isUserFiltersLoaded: state.userFilters[props.filterGroupName]?.isLoaded || false,
    userFilters: state.userFilters[props.filterGroupName]?.filters || [],
  }), {
    deleteUserFilterAction,
    getUserFiltersAction,
  }
)(ChooseSegmentDropdown);
