import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import { getCommitmentDirection } from 'utils/commitmentsHelper';
// icons
import { ReactComponent as TruckTransferIcon } from './ep-truck-transfer.svg';
import { ReactComponent as SalesResultIcon } from './ep-sales-results.svg';
// styles
import styles from './LogisticsNotificationIcon.module.scss';

const cn = classnames.bind(styles);

const renderLogisticsBody = (type) => {
  switch (type) {
    case 'sales_result_created_production':
      return <SalesResultIcon className={cn('icon', 'sales-results-icon')} />;
    case 'loading_created_production':
    case 'loading_edited_production':
    case 'loading_edited_confirmation_production':
      return <TruckTransferIcon className={cn('icon', 'truck-icon')} />;
    case 'loading_canceled_production':
      return (
        <>
          <i className={cn('fa fa-ep-truck')} />
          <i className={cn('fa fa-times', 'times-icon')} />
        </>
      );

    default: return null;
  }
};

const LogisticsNotificationIcon = ({ type, target, isDropdownView }) => {
  const load_type = target?.loading?.load_type;
  const direction = getCommitmentDirection(target);
  return (
    <div
      className={cn(
        'logistics-icon-wrapper',
        load_type,
        type,
        { isDropdownView, [direction]: load_type === 'transfer' }
      )}
    >
      {renderLogisticsBody(type)}
    </div>
  );
};

LogisticsNotificationIcon.propTypes = {
  type: T.string.isRequired,
  target: T.object.isRequired,
  isDropdownView: T.bool,
};

export default LogisticsNotificationIcon;
