import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { postComment } from 'reducers/farmfeed';
// components
import MentionsInput from 'components/MentionsInput';
import FarmFeedAddComment from './FarmFeedAddComment';
// hooks
import useLoading from 'hooks/useLoading';
import useCommentWithMentionsState from 'hooks/useCommentWithMentionsState';
// utils
import { isMobile } from 'react-device-detect';
import { getActivityMentionsInputData } from 'utils/activityHelper';

export const FarmFeedRowAddComment = ({
  currentUser,
  activity,
  postComment,
}) => {
  const { comment, mentions, setComment, setMentions } = useCommentWithMentionsState();

  const [handlePostComment, isSubmitting] = useLoading(() => {
    if (!comment) return null;
    const comment_mentions_attributes = mentions.map((mention) => ({ user_id: parseInt(mention.id, 10) }));
    return postComment(activity.id, { comment, comment_mentions_attributes }).then(() => {
      setComment('');
    });
  });

  const handlePressEnterKey = (e) => {
    if (e.key === 'Enter' && !isMobile) {
      e.preventDefault();
      handlePostComment();
    }
  };

  return (
    <FarmFeedAddComment
      activityComment
      activity={activity}
      commentOnPost={handlePostComment}
      commentOnEnter={handlePressEnterKey}
      currentUser={currentUser}
      isPostDisabled={isSubmitting || !comment}
    >
      <MentionsInput
        comment={comment}
        disabledIds={mentions.map((mention) => mention.id)}
        hasMentionBtn
        onCommentChange={setComment}
        onMentionsChange={setMentions}
        {...getActivityMentionsInputData(activity, currentUser)}
      />
    </FarmFeedAddComment>
  );
};

FarmFeedRowAddComment.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  postComment: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  }),
  { postComment }
)(FarmFeedRowAddComment);
