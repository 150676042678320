import React, { Component } from 'react';
import T from 'prop-types';
// recompose, redux
import { connect } from 'react-redux';
// components
import Button from 'components/Button';
import FilterItemListMobile from './FilterItemListMobile';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import { isEqualKeys } from 'utils';
// styles
import styles from './FiltersListMobile.module.scss';

const cn = classnames.bind(styles);

class FiltersListMobile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filterType: '',
      ...props.initialData,
    };
  }

  openSelector = (type) => {
    if (!this.isFilterDisabled(type)) this.setState({ filterType: type });
  };

  setValue = (type, value) => {
    const { onChange } = this.props;
    let newState = { [type]: value };
    if (isEmpty(value) && type === 'company') newState = { company: {}, farm: {}, group: {} };
    if (isEmpty(value) && type === 'farm') newState = { farm: {}, group: {} };
    if (isEmpty(value) && type === 'group') newState = { group: {} };
    this.setState({ ...newState, filterType: '' });
    onChange(value, type);
  };

  clearData = () => {
    const { onReset } = this.props;
    onReset();
    this.setState({ farm: {}, group: {}, company: {} });
  };

  closeFilters = () => {
    const { fetchData, farmfeedParams, initialData: { farm, group, company }, closePortal, syncState,
      initialData, initialParams } = this.props;
    if (isEqualKeys(['company', 'farm', 'group'], initialData, this.state)) {
      closePortal();
      return;
    }
    fetchData({
      ...farmfeedParams,
      filters: {
        ...farmfeedParams.filters,
        farm_id: farm.id || null,
        company_id: company.id || null,
        pig_group_id: group.id || null,
        ...initialParams.filters,
      },
    });
    this.setState({ farm, group, company });
    syncState(initialData);
    closePortal();
  };

  isFilterDisabled = (type) => {
    const { farm, group } = this.state;
    const { initialParams: { filters: { company_id, farm_id, pig_group_id } } } = this.props;
    switch (type) {
      case 'company':
        return Boolean(farm_id || pig_group_id || company_id) || !isEmpty(farm) || !isEmpty(group);
      case 'farm':
        return Boolean(farm_id || pig_group_id) || !isEmpty(group);
      default:
        return false;
    }
  };

  getReqParams = (type) => {
    const { initialParams: { filters: { company_id, farm_id } } } = this.props;
    const { company, farm } = this.state;
    switch (type) {
      case 'farm':
        return { manager_id: company_id || company.id || null };
      case 'group':
        return { farm_id: farm_id || farm.id || null };
      default:
        return {};
    }
  };

  render() {
    const { closePortal, activitiesCount } = this.props;
    const { filterType } = this.state;
    return (
      <div className={cn('filters-list-mobile')}>
        <div className={cn('entity-selector', { 'visible': filterType })}>
          {filterType && (
            <FilterItemListMobile
              filterType={filterType}
              onClose={() => this.setState({ filterType: '' })}
              value={this.state[filterType]}
              onSelect={this.setValue}
              reqParams={this.getReqParams(filterType)}
            />
          )}
        </div>
        <div className={cn('filters-header')}>
          <i className="fa fa-times ph-10 pv-10" onClick={this.closeFilters} />
          <FormattedMessage id="general.filters">
            {(txt) => (<span className={cn('title')}>{txt}</span>)}
          </FormattedMessage>
          <Button default className={cn('clear-filters-btn')} onClick={this.clearData}>
            <FormattedMessage id="general.button.clear" />
          </Button>
        </div>
        <div className={cn('filters-body')}>
          {['company', 'farm', 'group'].map((item, index, data) => (
            <div key={index}>
              <FormattedMessage id={`general.filter_${item}`}>
                {(txt) => (<span className="semibold">{txt}</span>)}
              </FormattedMessage>
              <div className={cn('filter-input-box')}>
                <div
                  className={cn('filter-input', { 'isDisabled': this.isFilterDisabled(item) })}
                  onClick={() => this.openSelector(item)}
                >
                  {!isEmpty(this.state[item])
                    ? this.state[item].name
                    : <FormattedMessage id={`general.select_${item}`} />}
                  <i className={cn('fa fa-angle-right', 'select-icon')} />
                </div>
                {!isEmpty(this.state[item]) &&
                  <i className={cn('fa fa-trash-o', 'remove-icon')} onClick={() => this.setValue(item, {})} />}
              </div>
              {data.length !== (index + 1) &&
                <div className={cn('divider')} />}
            </div>
          ))}
        </div>
        <div className={cn('save-filters')}>
          <Button wide primary onClick={closePortal}>
            <FormattedMessage id="general.button.showEventsCount" values={{ count: activitiesCount }} />
          </Button>
        </div>
      </div>
    );
  }

}

FiltersListMobile.propTypes = {
  closePortal: T.func,
  activitiesCount: T.number,
  onChange: T.func.isRequired,
  onReset: T.func.isRequired,
  fetchData: T.func.isRequired,
  syncState: T.func.isRequired,
  initialData: T.object.isRequired,
  initialParams: T.object.isRequired,
  farmfeedParams: T.object.isRequired,
};

export default connect(
  (state) => ({
    activitiesCount: state.farmfeed.meta.total,
    farmfeedParams: state.farmfeed.params,
  })
)(FiltersListMobile);
