import { getLSItem, removeLSItem, setLSItem } from './localStorageHelper';

const AUTH_HEADERS = [
  'uid',
  'access-token',
  'client',
  'expiry',
];

export function setAuthData(data) {
  if (data) {
    AUTH_HEADERS.forEach((key) => {
      setLSItem(key, data[key]);
    });
  }
}

export function getAuthData(noAuthHeaders) {
  const data = {};
  if (noAuthHeaders) return {};
  AUTH_HEADERS.forEach((key) => {
    data[key] = getLSItem(key);
  });
  return data;
}

export function clearAuthData() {
  console.warn('%c clearAuthData()', 'color: blue'); // eslint-disable-line no-console

  AUTH_HEADERS.forEach((key) => removeLSItem(key));
}
