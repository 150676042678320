import React from 'react';
import T from 'prop-types';
// components
import RadioSelectButton from './RadioSelectButton';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './RadioSelectGroup.module.scss';

const cn = classnames.bind(styles);

const RadioSelectGroup = ({ options, onChange, className, selected }) => (
  <div className={cn('RadioSelectGroup', className)}>
    {options.map((options) => (
      <RadioSelectButton
        key={`radio-${options.value}`}
        isActive={options.value === selected}
        onClick={onChange}
        {...options}
      />
    ))}
  </div>
);

RadioSelectGroup.propTypes = {
  options: T.arrayOf(T.object).isRequired,
  onChange: T.func.isRequired,
  className: T.string.isRequired,
  selected: T.string.isRequired,
};

export default RadioSelectGroup;
