import { combineReducers } from 'redux';
import groupCheckups from './barnsheetsGroupCheckups';
import checkup from './barnsheetsCheckup';

const barnsheetsReducer = combineReducers({
  groupCheckups,
  checkup,
});

export default barnsheetsReducer;
