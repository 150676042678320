import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { clearIdImportsCSV } from 'reducers/importCSV';
// components
import { FormattedMessage } from 'react-intl';
import OfflineScreen from 'components/OfflineScreen';
import ImportsTable from './ImportsTable/ImportsTable';
import Preloader from 'components/Preloader';
// styles
import './ResultsAllImports..scss';

const columns = {
  groups: 'general.groups',
  farms: 'general.farms',
  companies: 'general.companies',
};

const links = [
  { link: '/imports', label: <FormattedMessage id="general.imports.table" /> },
];

class ResultsAllImports extends Component {

  state = {
    subNavLinks: [],
  };

  componentDidMount() {
    const { setPageOptions, setBreadcrumbs, selectedNameAllImports } = this.props;
    const nameImports = selectedNameAllImports.length === 2 ? 'meta-farms' : 'fas';
    setPageOptions({ links });
    setBreadcrumbs();

    const subNavLinks = selectedNameAllImports.map((item) => {
      return {
        label: <FormattedMessage id={columns[item]} />,
        link: `/imports/choose/${nameImports}/${item}`,
      };
    });
    this.setState({ subNavLinks });
  }

  componentWillUnmount() {
    this.props.setPageOptions({ links: null });
    this.props.clearIdImportsCSV();
  }

  render() {
    const { isOnline, isLoading } = this.props;
    const { subNavLinks } = this.state;
    if (isLoading) return <Preloader isActive={isLoading} />;
    return (
      <div className="wrapperResultsAllImports">
        <div className="BarnsheetsHome clearfix">
          {isOnline
            ? (
              <ImportsTable
                subNavLinks={subNavLinks}
                isLoading={isLoading}
              />
            )
            : <OfflineScreen />}
        </div>
      </div>
    );
  }
}

ResultsAllImports.propTypes = {
  isOnline: T.bool,
  setPageOptions: T.func,
  setBreadcrumbs: T.func,
  selectedNameAllImports: T.array,
  isLoading: T.bool,
  clearIdImportsCSV: T.func,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
    selectedNameAllImports: state.importCSV.selectedNameAllImports,
    isLoading: state.importCSV.isLoading,
  }), { setPageOptions, setBreadcrumbs, clearIdImportsCSV }
)(ResultsAllImports);
