import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Amchart from 'components/Amchart/Amchart';
import Preloader from 'components/Preloader';
import ChartPlaceholder from 'components/Amchart/ChartPlaceholder';
// utils
import get from 'lodash.get';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import groupBy from 'lodash.groupby';
import sumBy from 'lodash.sumby';
// sources api
import { getSourceSymptomTrendsData } from 'endpoints/sources';
// styles
import './LifecycleSymptomTrendChart.scss';

// todo: add more different colors
const colors = ['#FC5E3B', '#FDB12B', '#1EB0FC', '#0E6A9F', '#1F273D',
  '#FC5E3B', '#1EB0FC', '#FDB12B', '#0E6A9F', '#1F273D'];

const symptomsAmountToShow = 5;

class LifecycleSymptomTrendChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      period: 'daily',
      isLoading: false,
      meta: {},
    };
    const { formatMessage } = props;
    this.titles = {
      daily: formatMessage({ id: 'general.day' }),
      weekly: formatMessage({ id: 'general.week' }),
      monthly: formatMessage({ id: 'general.month' }),
    };
    this.pluralTitles = {
      daily: formatMessage({ id: 'general.days' }),
      weekly: formatMessage({ id: 'general.weeks' }),
      monthly: formatMessage({ id: 'general.months' }),
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.farmType !== nextProps.farmType) {
      this.getChartData(nextProps.farmType);
    }
  }

  getChartData = (farmType) => {
    const { sourceId } = this.props;
    const reqParams = { farm_type: farmType !== undefined ? farmType : this.props.farmType };
    this.setState({ isLoading: true });

    getSourceSymptomTrendsData(sourceId, reqParams)
      .then(({ meta = {}, resources }) => {
        const initialData = this.prepareGraphData(resources, meta);
        const symptoms = this.getTopSymptoms(meta.symptoms);
        this.setState({
          meta: {
            ...meta,
            symptoms,
          },
          initialData,
          data: initialData,
          isLoading: false,
        });
      })
      .catch(toastResponseErrors);
  };

  prepareGraphData = (data, { symptoms = [] }) => {
    const { formatMessage } = this.props;
    const symptomsObj = symptoms.reduce((acc, { id }) => Object.assign(acc, { [`symptom_${id}`]: 0 }), {});
    const label = formatMessage({ id: 'general.day' });

    return data.map(({ day, stats }) => {
      const statsObj = stats.reduce((acc, { id, value }) => Object.assign(acc, { [`symptom_${id}`]: value }), {});
      return {
        day,
        label,
        ...symptomsObj,
        ...statsObj,
      };
    });
  };

  getTopSymptoms = (symptoms = []) => {
    if (symptoms.length <= symptomsAmountToShow) return symptoms;
    const filteredSymptoms = symptoms.map((item) => ({
      ...item,
      count: symptoms.filter((s) => (s[`symptom_${item.id}`])).length,
    }));

    return filteredSymptoms.sort((curr, next) => (next.count - curr.count)).slice(0, symptomsAmountToShow);
  };

  prepareDataByPeriod = (data, period) => {
    if (period === 'daily') return data;
    const { meta: { symptoms } } = this.state;
    const number = { weekly: 7, monthly: 30 }[period];
    const groupedData = groupBy(data, ({ day }) => (Math.ceil(day / number)));
    const keys = Object.keys(groupedData);
    return keys.map((key) => {
      const symptomsObj = symptoms.reduce((acc, { id }) => Object.assign(acc, {
        [`symptom_${id}`]: (sumBy(groupedData[key], (item) => (item[`symptom_${id}`])) / groupedData[key].length)
          .toFixed(2),
      }), {});
      return {
        day: key,
        label: this.titles[period],
        ...symptomsObj,
      };
    });
  };

  handleGraphMode = (period) => () => {
    const { initialData } = this.state;
    this.setState({
      period,
      data: this.prepareDataByPeriod(initialData, period),
    });
  };

  getChartGraphs = () => {
    const { meta: { symptoms = [] } } = this.state;
    const { formatMessage } = this.props;

    const symptomNames = symptoms.reduce((acc, { id, name }) => Object.assign(acc, { [`symptom_${id}`]: name }), {});
    const topSymptoms = formatMessage({ id: 'general.topSymptomsWithCount' }, { count: symptomsAmountToShow });

    const symptomNamesHTML = Object
      .keys(symptomNames)
      .reduce((acc, key, index) => acc.concat(
        `<div class="tooltip-symptom-line">\
          <div class="color-box" style="background-color: ${colors[index]}"></div>\
          ${symptomNames[key]} • [[${key}]]%\
        </div>`
      ), '');

    const baloonHTML = `
      <div class='graph-tooltip'>\
        <div class='day'>[[label]] [[day]]</div>\
        <div class='semibold mb-3 mt-3'>${topSymptoms}</div>\
         ${symptomNamesHTML}\
      </div>\
    `;

    return symptoms.map(({ id, name }, index) => {
      return {
        type: 'smoothedLine',
        fillAlphas: 0,
        lineThickness: 2,
        id: `symptom_${id}`,
        title: name,
        valueField: `symptom_${id}`,
        lineColor: colors[index],
        balloonText: baloonHTML,
      };
    });
  };

  getChartConfig = () => {
    const { data, period } = this.state;
    const { formatMessage } = this.props;

    return {
      type: 'serial',
      categoryField: 'day',
      fontFamily: ['Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
      categoryAxis: {
        title: this.pluralTitles[period].toUpperCase(),
        gridPosition: 'start',
        color: '#8c8c8c',
        gridThickness: 1,
        gridAlpha: 0,
        tickLength: 8,
        labelFunction: (valueText) => `${this.titles[period].toUpperCase()} ${valueText}`,
      },
      valueAxes: [{
        id: 'average_death',
        title: formatMessage({ id: 'general.averageSymptomatic' }),
        color: '#8c8c8c',
        axisThickness: 0,
        tickLength: 0,
      }],
      chartCursor: {
        enabled: true,
        cursorColor: '#a3a3a3',
        oneBalloonOnly: true,
        categoryBalloonEnabled: false,
        onlyOneBalloon: true,
      },
      zoomOutText: '',
      trendLines: [],
      graphs: this.getChartGraphs(),
      allLabels: [],
      balloon: {
        borderThickness: 0,
        horizontalPadding: 0,
        verticalPadding: 0,
        pointerWidth: 0,
        shadowAlpha: 0,
        fillAlpha: 0,
      },
      dataProvider: data,
    };
  };

  render() {
    const { data, period, isLoading, meta } = this.state;
    const chartConfig = this.getChartConfig();
    const chartStyle = { height: '330px' };
    const averageDaysOnFeed = get(meta, 'avg_days', 0);
    const symptoms = get(meta, 'symptoms', []);

    return (
      <div className="LifecycleSymptomTrendChart">
        <div className="chart-box">

          <div className="chart-box-header">
            <div className="header-left">
              <div className="header-title">
                <FormattedMessage id="general.lifecycleSymptomTrends">
                  {(text) => <h3 className="lighter">{text}</h3>}
                </FormattedMessage>
                <div className="period-buttons">
                  {['daily', 'weekly', 'monthly'].map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      className={cn('period-button', { 'active': period === item })}
                      onClick={this.handleGraphMode(item)}
                    >
                      <FormattedMessage id={`general.timeOptions.${item}`} />
                    </button>
                  ))}
                </div>
              </div>
              <div className="chart-legend mt-10">
                {symptoms.map(({ name, id }, index) => (
                  <div className="chart-legend-item" key={id}>
                    <div className="color-box" style={{ backgroundColor: colors[index] }} />
                    <div className="symptom-name">{name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="header-right">
              <div className="summary-data">
                <div className="data-block">
                  <FormattedMessage id="general.daysCountBig" values={{ count: averageDaysOnFeed }} />
                </div>
                <div className="current-range">
                  <FormattedMessage id="general.averageDaysOnFeed" />
                </div>
              </div>
            </div>
          </div>

          <div className="chart-box-body">
            <Preloader isActive={isLoading} />
            {(!data.length || !symptoms.length)
              ? <ChartPlaceholder chartStyle={chartStyle} />
              : <Amchart config={chartConfig} style={chartStyle} />}
          </div>
        </div>
      </div>
    );
  }
}

LifecycleSymptomTrendChart.propTypes = {
  sourceId: T.oneOfType([T.string, T.number]),
  farmType: T.string,
  formatMessage: T.func.isRequired,
};

export default LifecycleSymptomTrendChart;
