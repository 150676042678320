import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import DestinationForm from './components/DestinationForm';
import Panel from 'components/Panel';
// utils
import handleFormErrors from 'utils/handleFormErrors';
import { showToastrMessage } from 'utils';
// destinations API
import { createDestination } from 'endpoints/destinations';

const initialValues = { country_code: 'US' };

const DestinationCreate = (props, { router }) => {
  const onSubmit = (data) => {
    return createDestination(data)
      .then(() => {
        showToastrMessage('component.toastr.destination.created');
        router.push('/destinations');
      })
      .catch(handleFormErrors);
  };
  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="component.destinationCreate.createDestination" />} />
        <Panel.Body noPadding>
          <div className="row">
            <section className="small-12 medium-10 large-8 column">
              <DestinationForm onSubmit={onSubmit} initialValues={initialValues} />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    </section>
  );
};

DestinationCreate.contextTypes = {
  router: T.object.isRequired,
};

export default DestinationCreate;
