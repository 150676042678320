import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { updateAlert } from 'reducers/dailyCheckup/checkupEdit';
import { closeModal } from 'reducers/modals';
import { compose } from 'recompose';
// components
import Modal from 'components/Modal';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// hoc
import withSoundOnMount from 'hoc/withSoundOnMount';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// style
import drBotImage from './dr-bot.svg';
import './VetBotAlertModal.scss';

class VetBotAlertModal extends Component {

  remindMeLater = () => {
    const { updateAlert, checkup, alert, closeModal } = this.props;
    updateAlert(checkup.id, alert.id, { status: 'remind_later' })
      .then(() => closeModal())
      .catch(toastResponseErrors);
  };

  onRecordAudioNow = () => {
    const { router } = this.context;
    const { checkup, closeModal, alert } = this.props;
    router.push(`/daily-checkup/${checkup.id}/record-audio?alert_id=${alert.id}`);
    closeModal();
  };

  render() {
    return (
      <Modal drBot className="VetBotAlertModal" title="Vet Bot">
        <section className="modal-body">
          <div className="vet-bot-wrapper">
            <div className="vet-bot-image">
              <img src={drBotImage} alt="Vet Bot" />
              <FormattedMessage id="general.drBot">
                {(text) => <span className="vet-bot-label">{text}</span>}
              </FormattedMessage>
            </div>
            <div className="text">
              <FormattedMessage id="component.modal.vetBot.coughAlert.text" />
            </div>
          </div>
          <Button transparent onClick={this.remindMeLater}>
            <FormattedMessage id="component.modal.vetBot.notNow" />
          </Button>
        </section>
        <section className="modal-footer flex sticky-on-mobile">
          <Button secondary onClick={this.onRecordAudioNow}>
            <i className="fa fa-mic mr-10" />
            <FormattedMessage id="component.modal.vetBot.recordAudio" />
          </Button>
        </section>
      </Modal>
    );
  }
}

VetBotAlertModal.contextTypes = {
  router: T.object.isRequired,
};

VetBotAlertModal.propTypes = {
  checkup: T.object.isRequired,
  alert: T.object.isRequired,
  updateAlert: T.func.isRequired,
  closeModal: T.func.isRequired,
};

const enhance = compose(
  connect(null, { updateAlert, closeModal }),
  withSoundOnMount('/static/sounds/dr_bot_sound.wav'),
);

export default enhance(VetBotAlertModal);
