import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import Button from 'components/Button';
import InputConditionCheckBox from './ConditionItems/InputConditionCheckBox';
import NameConditionCheckBox from './ConditionItems/NameConditionCheckBox';
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
import MobilePortalOverlay from 'components/MobilePortalOverlay';
import PickerRow from '../PickerRow';
import SectionTargetSelector from './SectionTargetSelector';
import UserAvatar from 'components/UserAvatar';
import { FormattedMessage, useIntl } from 'react-intl';
// api
import { searchCompanies } from 'endpoints/companies';
import { searchFarms } from 'endpoints/farms';
import { searchPigGroups } from 'endpoints/pigGroups';
import { searchUsers } from 'endpoints/users';
// utils
import pickBy from 'lodash.pickby';
import { sectionEntityKeys, userFilterSectionTypes } from 'utils/constants/userFilters';
// styles
import styles from './ConditionSelector.module.scss';

function isNameCondition(conditionValue) {
  return ['only', 'except'].includes(conditionValue);
}

const getTargetSelectorProps = (sectionType) => {
  switch (sectionType) {
    case userFilterSectionTypes.farm:
      return {
        searchEntities: searchFarms,
        title: <FormattedMessage id="component.groupCreate.selectFarm" />,
        getIcon: () => <Avatar className="mr-10" avatarSize={35} iconSize={18} type="farm" />,
      };
    case userFilterSectionTypes.pigGroup:
      return {
        searchEntities: searchPigGroups,
        title: <FormattedMessage id="general.selectPigGroup" />,
        getIcon: () => <Avatar className="mr-10" avatarSize={35} iconSize={18} type="pig_group" />,
      };
    case userFilterSectionTypes.company:
    case userFilterSectionTypes.managementCompany:
      return {
        searchEntities: searchCompanies,
        title: <FormattedMessage id="general.selectCompany" />,
        getIcon: () => <Avatar className="mr-10" avatarSize={35} iconSize={18} type="company" />,
      };
    case userFilterSectionTypes.user:
    case userFilterSectionTypes.latestSvrCreatedBy:
      return {
        searchEntities: searchUsers,
        title: <FormattedMessage id="general.selectUser" />,
        getIcon: (user) => <UserAvatar size={35} className="mr-10" user={user} />,
        nameKey: 'full_name',
        searchParams: { with_current_user: true },
      };
    default:
      return {
        title: <FormattedMessage id="general.button.select" />,
      };
  }
};

const NameAndTextConditionSelector = ({ conditions, inputPlaceholderId, onBack, onSave, selectedSection, title }) => {
  const selectedValue = selectedSection.options?.[0]?.value || '';
  const targetKey = sectionEntityKeys[selectedSection.type];
  const { formatMessage } = useIntl();
  const [isTargetSelectorOpened, setIsTargetSelectorOpened] = useState(false);
  const [{ condition, targetId, target, value }, setState] = useState({
    condition: selectedSection.condition,
    targetId: selectedSection.target_id,
    target: selectedSection[targetKey],
    value: selectedValue,
  });
  const isNameCheckBox = isNameCondition(condition);
  const isWrongText = isNameCheckBox ? !targetId : !value;
  const isSameValues = isNameCheckBox ? selectedSection.target_id === targetId : selectedValue === value;
  const isAlreadySelected = condition === selectedSection.condition && isSameValues;
  const isDoneDisabled = !condition || isWrongText || isAlreadySelected;
  const placeholder = formatMessage({ id: inputPlaceholderId });
  const {
    searchEntities,
    searchParams,
    title: overlayPickerTitle,
    getIcon,
    nameKey = 'name',
  } = getTargetSelectorProps(selectedSection.type);

  const handleSave = () => {
    const options = !isNameCheckBox ? [{ key: 'text', value }] : undefined;
    onSave(pickBy({
      condition,
      options,
      id: selectedSection.id,
      target_id: targetId,
      [targetKey]: target,
      sectionIndex: selectedSection.sectionIndex,
      type: selectedSection.type,
    }));
  };

  const handleChangeCondition = ({ target: { name } }) => {
    setState({ condition: name, value: '', targetId: null, target: undefined });
  };

  const handleChangeValue = ({ target: { value } }) => {
    setState((prevState) => ({ ...prevState, value }));
  };

  const handleSelectTarget = (item) => {
    setState((prevState) => ({ ...prevState, target: item, targetId: item.id }));
    setIsTargetSelectorOpened(false);
  };

  return (
    <Fragment>
      <MobileListPickerComponent
        title={title}
        onBack={onBack}
        headerRightContent={(
          <Button disabled={isDoneDisabled} primary small onClick={handleSave}>
            <FormattedMessage id="general.button.done" />
          </Button>
        )}
      >
        <div className={styles.checkboxes}>
          {conditions.map((option) => {
            const isNameConditionCheckBox = isNameCondition(option.value);
            return (
              <Fragment key={option.value}>
                {isNameConditionCheckBox && (
                  <NameConditionCheckBox
                    conditionValue={condition}
                    nameValue={condition === option.value ? target?.name || target?.full_name : undefined}
                    namePlaceholder={placeholder}
                    onConditionValueChange={handleChangeCondition}
                    onNameClick={() => setIsTargetSelectorOpened(true)}
                    option={option}
                  />
                )}
                {!isNameConditionCheckBox && (
                  <InputConditionCheckBox
                    conditionValue={condition}
                    conditionInputValue={value}
                    onConditionValueChange={handleChangeCondition}
                    onConditionInputValueChange={handleChangeValue}
                    option={option}
                    inputPlaceholder={placeholder}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </MobileListPickerComponent>
      <MobilePortalOverlay isHidden={!isTargetSelectorOpened}>
        {isTargetSelectorOpened && (
          <SectionTargetSelector
            title={overlayPickerTitle}
            searchEntities={searchEntities}
            searchParams={searchParams}
            currentTargetId={targetId}
            onBack={() => setIsTargetSelectorOpened(false)}
          >
            {(resources) => resources.map((item) => (
              <PickerRow
                key={item.id}
                isSelected={item.id === targetId}
                onClick={item.id === targetId ? null : () => handleSelectTarget(item)}
              >
                {getIcon(item)}
                {item[nameKey]}
              </PickerRow>
            ))}
          </SectionTargetSelector>
        )}
      </MobilePortalOverlay>
    </Fragment>
  );
};

NameAndTextConditionSelector.propTypes = {
  conditions: T.array.isRequired,
  selectedSection: T.object.isRequired,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
  inputPlaceholderId: T.string.isRequired,
  title: T.string.isRequired,
};

export default NameAndTextConditionSelector;
