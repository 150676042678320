import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Hint from 'components/HintTooltip';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// styles
import styles from './PigGroupMarketingBadge.module.scss';

const cn = classnames.bind(styles);

const PigGroupMarketingBadge = ({
  isMinimized = false,
  className = '',
  hintClassName = '',
  isHintAttachedToBody = false,
  size,
  pigGroup,
}) => {
  const date = pigGroup?.marketing_reported_at;
  if (!date || pigGroup?.status === 'closed') {
    return null;
  }

  const formattedDate = moment(date).format('MM/DD/YYYY');

  return (
    <div className={cn('badge', { [className]: !!className, 'small': size === 'small' })}>
      <Hint attachToBody={isHintAttachedToBody} arrowed className={hintClassName}>
        <FormattedMessage id="component.pigGroupMarketingBadge.hint" values={{ date: formattedDate }} />
      </Hint>
      <div className={cn('badge-text')}>
        {isMinimized
          ? <FormattedMessage id="component.pigGroupMarketingBadge.short" />
          : <FormattedMessage id="component.pigGroupMarketingBadge.full" />}
      </div>
    </div>
  );
};

PigGroupMarketingBadge.propTypes = {
  size: T.oneOf(['small']),
  isMinimized: T.bool,
  className: T.string,
  hintClassName: T.string,
  isHintAttachedToBody: T.bool,
  pigGroup: T.object,
};

export default PigGroupMarketingBadge;
