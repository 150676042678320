import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fetchData } from 'reducers/dataTable';
// hoc
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import withDropdownActions from 'hoc/withDropdownActions';
// components
import TableFilter from 'components/TableFilter';
import TextLabel from 'components/TextLabel/TextLabel';
import { FormattedMessage } from 'react-intl';
import { CustomColumn } from 'components/DataTable/Columns';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import download from 'downloadjs';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Link from 'react-router/lib/Link';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import Button from 'components/Button';
import Panel from 'components/Panel/Panel';
import PigGroupMarketingBadge from 'components/PigGroupMarketingBadge/PigGroupMarketingBadge';
import DownloadLink from 'components/DownloadLink';
import FarmTypeLabel from 'components/FarmTypeLabel/FarmTypeLabel';
import FormattedWeight from 'components/FormattedWeight';
import TruncateHint from 'components/TruncateHint';
// utils
import { formatGroupStatus, formatMortality } from 'utils';
import cn from 'classnames';
import kebabCase from 'lodash.kebabcase';
import moment from 'moment/moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isTablet } from 'react-device-detect';
// groups api
import { downloadPigGroupCSV } from 'endpoints/pigGroups';
// styles
import './SourceGroups.scss';

class SourceGroups extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  renderGroupIDColumn = (pigGroup) => {
    const { id, name, status } = pigGroup;
    const url = status === 'draft' ? `/groups/create?draftId=${id}` : `/groups/${id}`;
    return (
      <CustomColumn>
        <div className="group-id-column">
          <div className="flex align-center">
            <Link to={url} className="color-primary">{name}</Link>
            <PigGroupMarketingBadge className="ml-5" pigGroup={pigGroup} size="small" isMinimized />
          </div>
          <DownloadLink
            endpoint={`/csv/pig_groups/${id}`}
            title={<FormattedMessage id="general.button.downloadData" />}
            className="button download-btn hide-for-large"
          >
            <i className="fa fa-download-btn" />
          </DownloadLink>
        </div>
      </CustomColumn>
    );
  };

  renderFarmColumn = ({ farm_name, farm_id }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
      <Link className="primary-link" to={`/barnsheets/farms/${farm_id}/groups`}><TruncateHint name={farm_name} /></Link>
    </CustomColumn>
  );

  renderFarmTypeColumn = ({ farm_type }) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      <FarmTypeLabel farmType={farm_type} />
    </CustomColumn>
  );

  renderStartDateColumn = ({ started_on }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.startDate" />}>
      {started_on ? moment(started_on).format('MM/DD/YYYY') : '-'}
    </CustomColumn>
  );

  renderInventoryColumn = ({ pigs }) => (
    <CustomColumn label={<FormattedMessage id="component.sidebar.inventory" />}>
      {pigs}
    </CustomColumn>
  );

  renderWeeksOnFeedColumn = ({ weeks_on_feed }) => (
    <CustomColumn label={<FormattedMessage id="general.weeksOnFeed" />}>
      {weeks_on_feed || '-'}
    </CustomColumn>
  );

  renderEstWeightColumn = ({ estimated_weight }) => (
    <CustomColumn label={<FormattedMessage id="general.estAvgWeight" />}>
      <FormattedWeight value={estimated_weight} />
    </CustomColumn>
  );

  downloadCSV = (id, name) => (e) => {
    e.stopPropagation();
    const filename = `pig-group-${kebabCase(name)}-${moment().format('MM-DD-YYYY')}.csv`;
    return downloadPigGroupCSV(id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  renderExpandable = ({ id }) => (
    <div>
      <Link className="button light small" to={`/groups/${id}`}>
        <i className="fa fa-gear mr-5" /><FormattedMessage id="general.manage" />
      </Link>
      <Button small light onClick={this.downloadCSV(id)}>
        <i className="fa fa-download mr-5" /><FormattedMessage id="general.button.downloadData" />
      </Button>
      <Link className="button light small" to={`/barnsheets/groups/${id}`}>
        <i className="fa fa-eye mr-5" /><FormattedMessage id="general.viewBarnSheet" />
      </Link>
    </div>
  );

  renderMortalityColumn = ({ mortality_rate, status, closed_on }) => (
    <CustomColumn className="mortality-to-date-column" label={<FormattedMessage id="general.mortalityToDate" />}>
      <span className="mortality-column-content">
        <span className="mr-5">{formatMortality(mortality_rate)}</span>
      </span>
      {closed_on && (
        <TextLabel className={cn(`fs-11 ${status}`)}>
          {formatGroupStatus(status)}
          {' '}
          {moment(closed_on).format('MM/DD/YY')}
        </TextLabel>
      )}
    </CustomColumn>
  );

  renderActionsColumn = ({ id, status, name }, { rowIndex }) => {
    const url = status === 'draft' ? `/groups/create?draftId=${id}` : `/groups/${id}`;
    return (
      <div className="collapsible-value button-column">
        <i
          data-name={`${id}-${rowIndex}`}
          className="fa fa-dots-three-horizontal show-for-large"
          onClick={(e) => this.openDropdown(e, id, name, url)}
        />
        <Link
          to={url}
          className="button light hide-for-large"
        >
          <FormattedMessage id="general.manage" />
        </Link>
        <Link to={`/barnsheets/groups/${id}`} className="button light-gray hide-for-large">
          <FormattedMessage id="general.viewBarnSheet" />
        </Link>
      </div>
    );
  };

  openDropdown = (e, id, name, url) => {
    const { openDropdown } = this.props;
    const options = [
      { label: <FormattedMessage id="general.manageGroup" />, url },
      { label: <FormattedMessage id="general.viewBarnSheet" />, url: `/barnsheets/groups/${id}` },
      { label: <FormattedMessage id="general.downloadGroupData" />, onClick: this.downloadCSV(id, name) },
    ];
    openDropdown(e, options);
  };

  onGroupStatusChange = (status) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, status, page: 1 });
  };

  render() {
    const { resources, isLoading, meta: { total, stats }, reqParams: { page, per_page, search, sort, status },
      onSearchChange, onSortChange, onPageChange, onPerPageChange } = this.props;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 130px',
        renderer: this.renderGroupIDColumn, sortKey: 'name' },
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 130px',
        renderer: this.renderFarmColumn, sortKey: 'farm_name' },
      { label: <FormattedMessage id="general.type" />, flex: '1 1 40px', renderer: this.renderFarmTypeColumn,
        sortKey: 'farm_type' },
      { label: <FormattedMessage id="component.dataTable.headers.startDate" />, flex: '1 1 100px',
        renderer: this.renderStartDateColumn, sortKey: 'started_on' },
      { label: <FormattedMessage id="component.sidebar.inventory" />, flex: '1 1 90px',
        renderer: this.renderInventoryColumn },
      { label: <FormattedMessage id="general.weeksOnFeed" />, flex: '1 1 100px',
        renderer: this.renderWeeksOnFeedColumn },
      { label: <FormattedMessage id="general.estAvgWeight" />, flex: '1 1 90px', renderer: this.renderEstWeightColumn },
      { label: <FormattedMessage id="general.mortalityToDate" />, flex: '1 1 180px',
        renderer: this.renderMortalityColumn, sortKey: 'mortality_rate' },
      { label: '', flex: '0 0 45px', renderer: this.renderActionsColumn, fixed: true,
        className: isTablet ? 'hide-for-large' : '' },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };

    const tableFilters = [
      { label: <FormattedMessage id="general.allGroups" />, value: '' },
      { label: <FormattedMessage id="general.status.opened" />, value: 'opened', stats: stats.opened || 0 },
      { label: <FormattedMessage id="general.status.closed" values={{ date: null }} />, value: 'closed',
        stats: stats.closed || 0 },
    ];

    return (
      <div className="SourceGroups">
        <section className="small-12 column treatments-table-section">
          <Panel>
            <Panel.Heading className="source-treatment-header">
              <h1 className="show-for-large">
                <FormattedMessage id="general.pageTitle.sourceGroups" />
              </h1>
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>
            <Panel.Body noPadding>
              <Preloader isActive={isLoading} />

              <TableFilter
                filters={tableFilters}
                activeFilter={status}
                onFilterChange={this.onGroupStatusChange}
                stats={stats}
                className="ph-10"
              />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderExpandable}
                tableKey="sourceGroups"
              />

              <NothingBox
                itemsName="groups"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <h2 className="lighter">
                  <FormattedMessage id="component.nothingBox.noData" />
                </h2>
              </NothingBox>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

SourceGroups.propTypes = {
  resources: T.array,
  isLoading: T.bool,
  reqParams: T.object,
  meta: T.object,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  openDropdown: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.sourceGroups.resources,
      isLoading: state.dataTable.sourceGroups.isLoading,
      meta: state.dataTable.sourceGroups.meta,
      reqParams: state.dataTable.sourceGroups.params,
    }), (dispatch, { params: { id } }) => ({
      fetchData: (query) => dispatch(fetchData(`/admin/external_sources/${id}/pig_groups`, 'sourceGroups', query)),
    }),
  ),
  withDropdownActions,
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(SourceGroups);
