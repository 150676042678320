import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import GrowthRate from '../GrowthRate/GrowthRate';
import Preloader from '../Preloader';
// utils
import isNull from 'lodash.isnull';
import classnames from 'classnames/bind';
import renderIf from 'utils/renderIf';
// styles
import styles from './StatisticSection.module.scss';

const cn = classnames.bind(styles);

const StatisticSection = ({
  data,
  isLoading,
  className = '',
  defaultMeasure = <FormattedMessage id="general.grams" />,
}) => (
  renderIf(data?.length)(
    <div className={cn('StatisticSection', className)}>
      <Preloader isActive={isLoading} />
      {data.map((item, index) => (
        <div key={index} className={cn('section-item', { 'not-available': isNull(item.value) })}>
          <div className={cn('caption-text')}>{item.caption}</div>
          <div className={cn('average-amount')}>
            <span className={cn('item-value')}> {isNull(item.value) ? 'N/A' : item.value}</span>
            {('grows' in item && !isNull(item.value)) && (
              <span className={cn('grow-span')}>
                <GrowthRate className="ml-5" small value={Number(item.grows)} />
              </span>
            )}
          </div>
          <div className={cn('average-measure')}>{item.measure || defaultMeasure}</div>
        </div>
      ))}
    </div>
  )
);

StatisticSection.propTypes = {
  data: T.array,
  className: T.string,
  isLoading: T.bool,
};

export default StatisticSection;
