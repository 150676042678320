import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './ProgressBar.module.scss';
import round from 'lodash.round';
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(styles);

const calculateAnsweredPercent = (questionsData) => {
  const totalQuestionsLength = questionsData
    .map(({ svr_questions }) => svr_questions.length)
    .reduce((cur, next) => cur + next, 0);
  const answeredQuestionsLength = questionsData
    .map(({ svr_questions }) => svr_questions.filter(({ answer }) => answer?.value).length)
    .reduce((cur, next) => cur + next, 0);

  return round(answeredQuestionsLength * 100 / totalQuestionsLength);
};

const ProgressBar = ({ questionsData, className }) => {
  const progress = calculateAnsweredPercent(questionsData);
  return (
    <div className={cn('progress-container', className)}>
      <div className={cn('label')}><FormattedMessage id="general.percentCompleted" values={{ value: progress }} /></div>
      <div className={cn('progress-bar')}>
        <div style={{ width: `${progress}%` }} className={cn('progress')} />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  questionsData: T.array.isRequired,
  className: T.string,
};

export default ProgressBar;
