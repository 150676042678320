/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import LabelledInput from 'components/LabelledInput';
import MessageBox from 'components/MessageBox';
import Preloader from 'components/Preloader';
// utils
import { isChrome, isSafari } from 'react-device-detect';

const LoginForm = ({
  inputs,
  validInputs,
  onSubmit,
  errorMessage,
  onInputsChange,
  isLoading,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(validInputs);
  };
  const isDisabled = Object.keys(validInputs).filter((key) => !validInputs[key]).length !== 0 || isLoading;
  return (
    <form noValidate onSubmit={handleSubmit}>
      {errorMessage !== null && (
        <MessageBox type="alert">
          {errorMessage}
          {errorMessage && (
            <Link className="underline" to="/reset-password">
              <FormattedMessage id="container.login.recoverPassword" />
            </Link>
          )}
        </MessageBox>
      )}

      <LabelledInput
        tabIndex="1"
        model={inputs.email}
        onChange={onInputsChange}
        autoComplete="off"
      >
        <FormattedMessage id="general.email" />
      </LabelledInput>

      <div className="forgot-pass">
        <Link to="/reset-password" tabIndex="2" className="color-primary">
          <FormattedMessage id="container.login.forgot" />
        </Link>
      </div>

      <LabelledInput
        tabIndex="3"
        model={inputs.password}
        password
        onChange={onInputsChange}
      >
        <FormattedMessage id="container.login.password" />
      </LabelledInput>

      <Button id="login-submit" disabled={isDisabled} primary tabIndex="4" className="lm-button mb-30">
        {isLoading ? (
          <Preloader isActive className="button-loader" />
        ) : (
          <FormattedMessage id="container.login.button.signIn" />
        )}
      </Button>

      <Link to="/reset-password" className="color-primary align-center block">
        <FormattedMessage id="container.login.troubleLogIn" />
      </Link>

      {!isSafari && !isChrome && (
        <div className="recommended-browsers">
          <FormattedMessage id="container.login.recommendBrowsers" tagName="i" />
        </div>
      )}
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: T.func,
  onInputsChange: T.func,
  errorMessage: T.object,
  inputs: T.object.isRequired,
  validInputs: T.object.isRequired,
  isLoading: T.bool,
};

export default LoginForm;
