import React from 'react';
import T from 'prop-types';
// recompose
import { withState, compose } from 'recompose';
// components
import { FormattedMessage } from 'react-intl';
import Dropdown from '../Dropdown';
import SearchBox from 'components/SearchBox';
import TenantAvatar from 'components/TenantAvatar/TenantAvatar';
// utils
import cn from 'classnames';
// styles
import './CompanySwitcher.scss';

const CompanySwitcher = ({
  user,
  companies,
  compact,
  isDropdownOpen,
  onDropdownToggle,
  search,
  onSearchChange,
}, {
  router,
}) => {
  const switchCompany = (id) => {
    router.push(`/switch-company/${id}`);
  };

  const ignoreClosing = (e) => e.stopPropagation();

  const filteredCompanies = search
    ? companies.filter((company) => company.name.toLowerCase().includes(search.toLowerCase()))
    : companies;

  return (
    <div className={cn('CompanySwitcher', { compact, isDropdownOpen })}>
      <TenantAvatar className="company-avatar" name={user.current_company.name} highlighted />
      <div className="company-name" title={user.current_company.name}>
        {user.current_company.name}
      </div>
      <i className="fa fa-angle-down dropdown-trigger" />
      {companies && (
        <Dropdown
          onToggle={onDropdownToggle}
          trigger={<div className="trigger-overlay" />}
          className="companies-dropdown"
          closeOnClick
          onlyDesktop
        >
          <div className="dropdown-header">
            <FormattedMessage id="component.mobileMenu.switchAccount" />
          </div>

          <div className="dropdown-search" onClick={ignoreClosing}>
            <SearchBox
              initialValue={search}
              onChange={onSearchChange}
              placeholderKey="general.search.accounts"
              className="search-box"
              disableFocus
            />
          </div>

          <ul className="dropdown-menu">
            {filteredCompanies.map((company, index) => (
              <li
                key={index}
                title={company.name}
                onClick={() => switchCompany(company.id)}
                className={cn('company-item', { 'current': company.id === user.current_company.id })}
              >
                <TenantAvatar
                  className="mr-10"
                  name={company.name}
                  highlighted={company.id === user.current_company.id}
                />
                <a className="item-name">{company.name}</a>
                <i className="fa fa-check" />
              </li>
            ))}
          </ul>
        </Dropdown>
      )}
    </div>
  );
};

CompanySwitcher.contextTypes = {
  router: T.object,
};

CompanySwitcher.propTypes = {
  user: T.object,
  companies: T.array,
  compact: T.bool,
  isDropdownOpen: T.bool,
  search: T.string.isRequired,
  onSearchChange: T.func.isRequired,
  onDropdownToggle: T.func.isRequired,
};

const enhance = compose(
  withState('search', 'onSearchChange', ''),
  withState('isDropdownOpen', 'onDropdownToggle', false),
);

export default enhance(CompanySwitcher);
