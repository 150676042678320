import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MediaViewerNavButtons.module.scss';

const cn = classnames.bind(styles);

const MediaViewerNavButtons = ({ currentIndex, lastIndex, handleShowPrev, handleShowNext }) => (
  <>
    {currentIndex !== 0 && (
      <div className={cn('nav-button', 'nav-prev')} onClick={handleShowPrev}>
        <div className={cn('btn-circle')}>
          <i className="fa fa-arrow-left" />
        </div>
      </div>
    )}
    {currentIndex !== lastIndex && (
      <div className={cn('nav-button', 'nav-next')} onClick={handleShowNext}>
        <div className={cn('btn-circle')}>
          <i className="fa fa-arrow-right" />
        </div>
      </div>
    )}
  </>
);

MediaViewerNavButtons.propTypes = {
  currentIndex: T.number.isRequired,
  lastIndex: T.number.isRequired,
  handleShowPrev: T.func.isRequired,
  handleShowNext: T.func.isRequired,
};

export default MediaViewerNavButtons;
