import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateActivity } from 'reducers/farmfeed';
import { updateCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
import { openModal, TYPE_PANEL } from 'reducers/modals';
import { openPortal } from 'reducers/portal';
import { openViewer } from 'reducers/mediaViewer';
// components
import { FormattedMessage } from 'react-intl';
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import ActivityAuthorLine from './ActivityAuthorLine/ActivityAuthorLine';
import ActivityCheckupBox from './ActivityCheckupBox/ActivityCheckupBox';
import ActivityBox from './ActivityBox';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityHeader from './ActivityHeader';
import ActivityHeaderBadge from './ActivityHeader/ActivityHeaderBadge';
import AssetsSection from './CheckupSections/AssetsSection';
import Button from 'components/Button';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import DeathsCollapse from './CheckupSections/DeathsCollapse';
import DiagnosesCollapse from 'components/FarmFeed/GroupAnalyticsBlock/DiagnosesCollapse';
import DiagnosisSidebar from 'components/DiagnosisSidebar/DiagnosisSidebar';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import FarmFeedRowAudioAssets from 'components/FarmFeed/FarmFeedRowAudioAssets';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedEmptySections from 'components/FarmFeed/FarmFeedEmptySections';
import GroupInfoCollapse from 'components/Groups/GroupInfoCollapse';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
import Line from 'components/Line';
import MedicationsCollapse from './CheckupSections/MedicationsCollapse';
import MobileDiseasesSelector from 'components/MobileDiseasesSelector/MobileDiseasesSelector';
import ModalSidebar from 'components/Modal/ModalSidebar';
import PigMigrationsCollapse from './CheckupSections/PigMigrationsCollapse';
import SymptomsCollapse from './CheckupSections/SymptomsCollapse';
import TemperaturesCollapse from './CheckupSections/TemperaturesCollapse';
import Translation from 'components/Translation';
import WaterUsageCollapse from './CheckupSections/WaterUsageCollapse';
import Hint from 'components/HintTooltip';
// hoc
import withActivityController from './hoc/withActivityController';
// utils
import { checkVariety, isUserCanDiagnose, renderMentionsText, showToastrMessage } from 'utils';
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import uniqBy from 'lodash.uniqby';
import { isMobile } from 'react-device-detect';
// styles
import styles from './ExtendedCheckupActivity.module.scss';
// constants
import { comment_roles } from 'constants.js';
// assets
import magicLoopSvg from '../../../public/images/magic_2_looped.svg';

const {
  CHECKUP_ROLE,
  PIG_MIGRATIONS_ROLE,
  TREATMENTS_ROLE,
  DEATHS_ROLE,
  SYMPTOMS_ROLE,
  TEMPERATURE_ROLE,
  WATER_USAGE_ROLE,
} = comment_roles;

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments', 'daily_checkup'];

const ExtendedCheckupActivity = ({
  activity,
  className,
  currentUser,
  defaultDropdownOptions,
  isChildActivity,
  isMinimized,
  isTruncated,
  markAsSeen,
  measurement_system,
  onViewFullActivity,
  openDropdown,
  openModal,
  openPortal,
  openViewer,
  setActivityFlag,
  toggleMinimized,
  updateActivity,
  updateCheckupDiagnoses,
}) => {
  const [checkupExpanded, setCheckupExpanded] = useState(false);
  const { id, flagged, is_viewed, variety, pig_group, daily_checkup, user, farm } = activity;
  const { id: userId, full_name = 'unknown' } = user || {};
  const isUserVet = isUserCanDiagnose(currentUser, farm?.id, farm?.manager?.id);

  const onActivityDiagnosesUpdate = (diagnosis_count) => {
    updateActivity({
      ...activity,
      daily_checkup: {
        ...activity.daily_checkup,
        diagnosis_count,
      },
    });
  };

  const removeDiagnosis = (id) => {
    updateCheckupDiagnoses(daily_checkup.id, [{ id, _destroy: true }])
      .then(({ value: { meta } }) => {
        showToastrMessage('component.toastr.diagnoses.successUpdated');
        onActivityDiagnosesUpdate(meta.total);
      })
      .catch(toastResponseErrors);
  };

  const onSidebarDataSubmit = (data) => {
    return updateCheckupDiagnoses(daily_checkup.id, data)
      .then(({ value: { meta } }) => {
        showToastrMessage('component.toastr.diagnoses.successUpdated');
        onActivityDiagnosesUpdate(meta.total);
      })
      .catch(toastResponseErrors);
  };

  const openSidebar = (id) => {
    const diagnosisId = typeof id === 'number' ? id : null;

    if (isMobile) {
      openPortal(
        <MobileDiseasesSelector
          checkupId={daily_checkup.id}
          pigGroup={pig_group}
          onSubmitData={onSidebarDataSubmit}
          diagnosisId={diagnosisId}
        />
      );
    } else {
      openModal(
        <ModalSidebar>
          <DiagnosisSidebar
            checkupId={daily_checkup.id}
            pigGroup={pig_group}
            onSubmitData={onSidebarDataSubmit}
            diagnosisId={diagnosisId}
          />
        </ModalSidebar>,
        {},
        TYPE_PANEL
      );
    }
  };

  const renderDiagnosisOptions = (id) => {
    if (!isUserVet) return [];
    return [
      { label: <FormattedMessage id="general.editDiagnosis" />, onClick: () => openSidebar(id) },
      { label: <FormattedMessage id="general.deleteDiagnosis" />, onClick: () => removeDiagnosis(id) },
    ];
  };

  const renderViewCheckupButton = () => {
    const iconClassName = checkupExpanded ? 'fa fa-times' : 'fa fa-eye';
    const messageId = checkupExpanded ? 'container.farmfeed.row.closeCheckup' : 'general.viewCheckup';
    return (
      <Button
        onClick={() => setCheckupExpanded(!checkupExpanded)}
        className={cn(checkupExpanded ? 'dark-grey' : 'light', 'dc-expanded-button')}
      >
        <i className={cn(iconClassName, 'mr-5')} />
        <FormattedMessage id={messageId} />
      </Button>
    );
  };

  const formatComments = (comments = []) => {
    return comments.reduce((result, { role, comment }) => ({ ...result, [role]: comment }), {});
  };

  const renderIcon = () => <img className={styles['magic-loop-img']} src={magicLoopSvg} loading="lazy" alt="" />;

  const showSuggestionBadge = isUserVet && variety.includes('media') && variety.includes('ai_suggestion')
    && !!daily_checkup.dc_assets.length;
  const suggestionsCount = showSuggestionBadge
    ? daily_checkup.dc_assets.reduce((prev, next) => prev + next.prediction_image_tags_count, 0)
    : 0;
  const formattedComments = formatComments(daily_checkup.comments);
  const contributors = daily_checkup
    ? uniqBy(daily_checkup.dc_logs, 'user.id').filter((item) => (item.user.id !== userId))
    : [];

  return (
    <ActivityBox
      className={className}
      isFlagged={flagged}
      isUnseen={!is_viewed}
      isTruncated={isTruncated}
      hasMarker={!isChildActivity}
      markAsSeen={markAsSeen}
      onViewFullActivity={onViewFullActivity}
    >
      {!isMinimized &&
        <LastCommentInfo activity={activity} currentUser={currentUser} />
      }
      <ActivityHeader
        isColumnDirection
        activityId={id}
        onOpenDropdown={(e) => openDropdown(e, defaultDropdownOptions)}
        onDoubleClick={toggleMinimized}
      >
        {!!suggestionsCount && (
          <ActivityHeaderBadge className="mb-10" renderIcon={renderIcon} isCentered>
            <FormattedMessage id="general.imageDiagnosisSuggestion" />
          </ActivityHeaderBadge>
        )}
        <ActivityAuthorLine
          activity={activity}
          currentUser={currentUser}
          minimized={isMinimized}
        >
          <div>
            <FormattedMessage
              id="container.farmfeed.row.reason.submittedCheckup"
              values={{
                ownPost: currentUser.id === userId ? 'yes' : 'no',
                name: full_name,
                b: (msg) => <b>{msg}</b>,
              }}
            />
            <span className={cn('reason-for-alert')}>
              {!!contributors.length && (
                <>
                  <span>&nbsp;<FormattedMessage id="general.and" />&nbsp;</span>
                  <span className={cn('contributors-block')}>
                    <Hint className="fs-12" attachToBody arrowed>
                      {contributors.map(({ user }, index) => (
                        user.full_name + `${index + 1 === contributors.length ? '' : ', '}`
                      ))}
                    </Hint>
                    <span className={cn('contributors')}>
                      <FormattedMessage
                        id="container.farmfeed.row.contributors"
                        values={{
                          count: contributors.length,
                        }}
                      />
                    </span>
                  </span>
                </>
              )}
            </span>
          </div>
        </ActivityAuthorLine>
      </ActivityHeader>
      <ActivityBodyCollapse isClosed={isMinimized}>
        <ActivityCheckupBox
          isAdmin={currentUser.roles_map.admin}
          className={cn('extended-checkup-box', { 'mb-10': isChildActivity })}
          activity={activity}
          actionButtons={renderViewCheckupButton()}
        >
          {pig_group && (
            <CollapsesBox className="mv-15">
              <GroupInfoCollapse
                isAdmin={currentUser.roles_map.admin}
                titleClassName={cn('checkup-collapse')}
                group={pig_group}
                checkupId={daily_checkup.id}
              />
              <DiagnosesCollapse
                currentUser={currentUser}
                titleClassName={cn('checkup-collapse')}
                checkupId={daily_checkup.id}
                diagnosisCount={daily_checkup.diagnosis_count}
                renderDiagnosisOptions={renderDiagnosisOptions}
              />
            </CollapsesBox>
          )}

          <PigMigrationsCollapse
            titleClassName={cn('checkup-collapse')}
            checkupId={daily_checkup.id}
            itemsCount={daily_checkup.pig_migration_count}
            comment={formattedComments[PIG_MIGRATIONS_ROLE] || ''}
            showCheckup={checkupExpanded}
            isEdited={checkVariety(daily_checkup.dc_logs, 'pig_migrations_updated')}
          />

          <SymptomsCollapse
            titleClassName={cn('checkup-collapse')}
            dailyCheckup={daily_checkup}
            itemsCount={daily_checkup.symptom_count}
            comment={formattedComments[SYMPTOMS_ROLE] || ''}
            data={daily_checkup.dc_symptoms}
            checkupId={daily_checkup.id}
            showCheckup={checkupExpanded}
            isEdited={checkVariety(daily_checkup.dc_logs, 'symptoms_updated')}
          />

          <DeathsCollapse
            titleClassName={cn('checkup-collapse')}
            dailyCheckup={daily_checkup}
            comment={formattedComments[DEATHS_ROLE] || ''}
            showCheckup={checkupExpanded}
            isEdited={checkVariety(daily_checkup.dc_logs, 'deaths_updated')}
          />

          <MedicationsCollapse
            titleClassName={cn('checkup-collapse')}
            checkupId={daily_checkup.id}
            showCheckup={checkupExpanded}
            comment={formattedComments[TREATMENTS_ROLE] || ''}
            itemsCount={daily_checkup.treatment_count}
            isEdited={checkVariety(daily_checkup.dc_logs, 'treatments_updated')}
          />

          <TemperaturesCollapse
            titleClassName={cn('checkup-collapse')}
            showCheckup={checkupExpanded}
            comment={formattedComments[TEMPERATURE_ROLE] || ''}
            dailyCheckup={daily_checkup}
            isEdited={checkVariety(daily_checkup.dc_logs, 'temperature_updated')}
          />

          <WaterUsageCollapse
            titleClassName={cn('checkup-collapse')}
            showCheckup={checkupExpanded}
            comment={formattedComments[WATER_USAGE_ROLE] || ''}
            dailyCheckup={daily_checkup}
            isEdited={checkVariety(daily_checkup.dc_logs, 'water_usage_updated')}
            measurement_system={measurement_system}
          />

          {/* <FarmFeedRowTreatmentsEfficacy reasons={reasons} /> */}
          <FarmFeedRowAudioAssets dailyCheckup={daily_checkup} showCheckup={checkupExpanded} user={user || {}} />

          {formattedComments[CHECKUP_ROLE] && (
            <div className={styles['general-note']}>
              <Line isHeaderLine bolded>
                <FormattedMessage id="general.notes" />
              </Line>
              <Line lightYellow>
                <Translation text={formattedComments[CHECKUP_ROLE]}>
                  {renderMentionsText(formattedComments[CHECKUP_ROLE])}
                </Translation>
              </Line>
            </div>
          )}

          <AssetsSection activity={activity} onAssetPreview={openViewer} suggestionsCount={suggestionsCount} />
          <FarmFeedEmptySections dailyCheckup={daily_checkup} showCheckup={checkupExpanded} />
        </ActivityCheckupBox>

        {!isChildActivity && (
          <>
            <ActivityActionsBar
              activity={activity}
              currentUser={currentUser}
              openDropdown={openDropdown}
              setActivityFlag={setActivityFlag}
              onDiagnoseClick={openSidebar}
            />
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

ExtendedCheckupActivity.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  isChildActivity: T.bool.isRequired,
  markAsSeen: T.func,
  className: T.string,
  openDropdown: T.func.isRequired,
  openModal: T.func.isRequired,
  openPortal: T.func.isRequired,
  openViewer: T.func.isRequired,
  updateActivity: T.func.isRequired,
  updateCheckupDiagnoses: T.func.isRequired,
  measurement_system: T.string.isRequired,
  setActivityFlag: T.func.isRequired,
  // from hoc
  isTruncated: T.bool.isRequired,
  isMinimized: T.bool.isRequired,
  toggleMinimized: T.func.isRequired,
  onViewFullActivity: T.func.isRequired,
  defaultDropdownOptions: T.array.isRequired,
};

export default connect(
  (state) => ({ measurement_system: state.auth.user.current_company.measurement_system }),
  { openModal, openPortal, openViewer, updateActivity, updateCheckupDiagnoses }
)(withActivityController(ExtendedCheckupActivity, { affectRerenderKeys }));
