import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserFilterSectionDropdown from '../UserFilterSectionDropdown';
import DatesMenu from '../Menu/DatesMenu';
// utils
import isEqual from 'lodash.isequal';
import moment from 'moment';
import {
  formatOptionsValues,
  getConditionTranslationsBySection,
  isRelativeDateCondition
} from 'utils/userFiltersHelper';
import { dateSections, sectionOptionsDateFormat } from 'utils/constants/userFilters';

const initialDate = moment().format(sectionOptionsDateFormat);

const initialData = {
  days: '',
  date: initialDate,
  date_start: null,
  date_end: null,
  isRangeError: false,
};

class DatesSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...initialData,
      value: props.condition,
      ...formatOptionsValues(props.options)
    };
  }

  handleConditionChange = ({ target: { name } }) => {
    this.setState({ value: name, ...initialData });
  };

  handleInputChange = ({ target: { value } }) => {
    this.setState({ days: value, date: initialDate });
  };

  handleDateChange = (date) => {
    this.setState({ date: date.format(sectionOptionsDateFormat) });
  };

  handleDatesRangeChange = ({ startDate, endDate }) => {
    this.setState({
      date_start: startDate ? startDate.format(sectionOptionsDateFormat) : null,
      date_end: endDate ? endDate.format(sectionOptionsDateFormat) : null,
    });
  };

  handleApply = (e) => {
    const { onOptionSelect } = this.props;
    const { value, days, date, date_start, date_end } = this.state;
    if (isRelativeDateCondition(value)) {
      onOptionSelect(value, [{ key: 'days', value: days }]);
      return;
    }
    if (value === 'range') {
      if (!date_start || !date_end) {
        e.stopPropagation();
        this.setState({ isRangeError: true });
        return;
      }
      onOptionSelect(value, [
        { key: 'date_start', value: date_start },
        { key: 'date_end', value: date_end },
      ]);
      return;
    }
    onOptionSelect(value, [{ key: 'date', value: date }]);
  };

  resetData = () => {
    const { condition, options } = this.props;
    const newState = {
      ...initialData,
      value: condition,
      ...formatOptionsValues(options),
    };
    const isEqualState = isEqual(newState, this.state);
    if (!isEqualState) this.setState(newState);
  };

  renderDropdownMenu = (isOpened) => {
    const { listOptions, inputLabel, type } = this.props;
    const { value, days, date, date_start, date_end } = this.state;

    return (
      <DatesMenu
        type={type}
        isOpened={isOpened}
        currentValue={value}
        options={listOptions}
        onConditionChange={this.handleConditionChange}
        inputValue={days}
        dateValue={moment(date, sectionOptionsDateFormat)}
        onInputChange={this.handleInputChange}
        onDateChange={this.handleDateChange}
        onDatesRangeChange={this.handleDatesRangeChange}
        dateStartValue={date_start ? moment(date_start, sectionOptionsDateFormat) : null}
        dateEndValue={date_end ? moment(date_end, sectionOptionsDateFormat) : null}
        onApply={this.handleApply}
        inputLabel={inputLabel}
      />
    );
  };

  getLabel = () => {
    const { placeholder, options, type, condition, isInvalid } = this.props;
    if (isInvalid || !condition) return placeholder;
    const optionsValues = formatOptionsValues(options);
    const messageKey = getConditionTranslationsBySection(type)[condition];
    return <FormattedMessage id={messageKey} values={optionsValues} />;
  };

  render() {
    const { condition, icon, onSectionRemove, isInitialOpened, isRemovable, isDisabled, isInvalid } = this.props;
    return (
      <UserFilterSectionDropdown
        isInitialOpened={isInitialOpened}
        isDisabled={isDisabled}
        currentValue={condition}
        triggerLabel={this.getLabel()}
        menuRenderer={this.renderDropdownMenu}
        isRemovable={isRemovable}
        onRemove={onSectionRemove}
        triggerIcon={icon}
        onClose={this.resetData}
        isInvalid={isInvalid}
      />
    );
  }
}

DatesSection.propTypes = {
  type: T.oneOf(dateSections).isRequired,
  condition: T.string.isRequired,
  icon: T.string.isRequired,
  listOptions: T.array.isRequired, // available options
  options: T.array, // saved options
  onOptionSelect: T.func,
  onSectionRemove: T.func,
  isRemovable: T.bool,
  isDisabled: T.bool,
  isInitialOpened: T.bool,
  isInvalid: T.bool,
  placeholder: T.oneOfType([T.node, T.string]).isRequired,
  inputLabel: T.oneOfType([T.node, T.string]).isRequired,
};

DatesSection.defaultProps = {
  options: [],
};

export default DatesSection;
