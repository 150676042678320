import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// hoc
import withDropdownActions from 'hoc/withDropdownActions';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import NothingBox from 'components/NothingBox';
import Avatar from 'components/Avatar/Avatar';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import PaginationWrapper from 'components/PaginationWrapper';
import SearchBox from 'components/SearchBox';
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn, ArrowColumn } from 'components/DataTable/Columns';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// utils
import { fetchFromAPI } from 'utils/api';
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { timeFormatter } from 'utils/checkinsHelper';
import { isTablet } from 'react-device-detect';
// styles
import styles from './UserCheckinsTable.module.scss';

// const
const defaultParams = {
  page: 1,
  per_page: 25,
};
const cn = classnames.bind(styles);

class UserCheckinsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      isLoading: false,
      params: {
        ...defaultParams,
        search: '',
        sort: '',
      },
      meta: {
        total: 0,
        stats: {},
      },
    };
  }

  componentDidMount() {
    const { params } = this.state;
    this.fetchCheckins({ ...params, sort: 'created_at desc' });
  }

  onPageChange = (page) => {
    const { params } = this.state;
    this.fetchCheckins({ ...params, page });
  };

  onPerPageChange = (perPage) => {
    const { params } = this.state;
    this.fetchCheckins({ ...params, per_page: perPage, page: 1 });
  };

  onSearchChange = (search) => {
    const { params } = this.state;
    this.fetchCheckins({ ...params, page: 1, search });
  };

  onSortChange = (sort) => {
    const { params } = this.state;
    this.fetchCheckins({ ...params, page: 1, sort });
  };

  fetchCheckins = (params) => {
    const { userId } = this.props;
    this.setState({ isLoading: true });
    fetchFromAPI(`/users/${userId}/check_ins`, { params })
      .then((response) => {
        this.setState({
          ...response,
          params,
          isLoading: false,
        });
      })
      .catch((response) => {
        toastResponseErrors(response);
        this.setState({ isLoading: false });
      });
  };

  renderFarmNameColumn = ({ farm }) => (
    <CustomColumn>
      <FarmTitleBox name={farm.name} type={farm.farm_type} />
    </CustomColumn>
  );

  renderLocationColumn = ({ farm }) => (
    <CustomColumn>
      {`${farm.address ? farm.address + ',' : ''} ${farm.city} ${farm.state}`}
    </CustomColumn>
  );

  renderDateColumn = (checkin) => (
    <CustomColumn>
      <TimeAgo date={checkin.created_at} formatter={timeFormatter} />
    </CustomColumn>
  );

  renderExpandable = ({ id, farm }) => (
    <div className="collapsable-buttons-row show-for-large">
      <Link to={`/checkins/${id}`} className="button small light">
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.button.viewOnMap" />
      </Link>
      <Link to={`/checkins/farms/${farm.id}`} className="button small light">
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.button.viewFarmCheckInHistory" />
      </Link>
      <Link to={`/barnsheets/farms/${farm.id}`} className="button small light">
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.button.viewFarmBarnsheetsHistory" />
      </Link>
      {this.props.currentUser.roles_map.admin && (
        <Link to={`/admin/farms/${farm.id}`} className="button small light">
          <i className="fa fa-gear mr-5" />
          <FormattedMessage id="general.manageFarm" />
        </Link>
      )}
    </div>
  );

  renderButtons = ({ id, farm }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        wide
        buttonType="small light-gray"
        label={<FormattedMessage id="general.button.viewOnMap" />}
        url={`/checkins/${id}`}
        dropDownData={[
          {
            label: <FormattedMessage id="general.button.viewFarmCheckInHistory" />,
            url: `/checkins/farms/${farm.id}`,
          }, {
            label: <FormattedMessage id="general.button.viewFarmBarnsheetsHistory" />,
            url: `/barnsheets/farms/${farm.id}`,
          }, {
            label: <FormattedMessage id="general.manageFarm" />,
            url: `/admin/farms/${farm.id}`,
            hide: !this.props.currentUser.roles_map.admin,
          },
        ]}
      />
    </div>
  );

  openDropdown = (e, checkin) => {
    const { openDropdown, currentUser } = this.props;
    const options = [
      { label: <FormattedMessage id="general.button.viewFarmOnMap" />, url: `/checkins/${checkin.id}` },
      { label: <FormattedMessage id="general.button.viewFarmBarnsheetsHistory" />,
        url: `/barnsheets/farms/${checkin.farm.id}` },
      { label: <FormattedMessage id="general.manageFarm" />, url: `/admin/farms/${checkin.farm.id}`,
        hide: !currentUser.roles_map.admin },
    ];
    openDropdown(e, options);
  };

  render() {
    const { isLoading, resources, meta: { total }, params: { page, per_page, search, sort } } = this.state;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '2 1 220px', sortKey: 'farm_name',
        renderer: this.renderFarmNameColumn },
      { label: <FormattedMessage id="component.dataTable.headers.location" />, flex: '2 1 220px',
        renderer: this.renderLocationColumn },
      { label: <FormattedMessage id="component.dataTable.headers.checkIn" />, flex: '1 1 180px', sortKey: 'created_at',
        renderer: this.renderDateColumn },
      { flex: '0 0 175px', renderer: this.renderButtons, hide: isTablet, fixed: true },
      { flex: '0 0 40px', className: 'pin-column', renderer: () => <ArrowColumn />, hide: !isTablet, fixed: true,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange: this.onPageChange,
      onPerPageChange: this.onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <section className={cn('user-checkins-table', 'small-12 column')}>
        <Panel className="show-for-large">
          <Panel.Heading title={<FormattedMessage id="general.checkins" />}>
            <SearchBox initialValue={search} onChange={this.onSearchChange} />
          </Panel.Heading>

          <Panel.Body noPadding>
            <Preloader isActive={isLoading} />

            <DataTable
              data={resources}
              columns={columns}
              sort={sort}
              onSortChange={this.onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
            />

            <NothingBox
              itemsName="checkins"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
            >
              <FormattedMessage id="component.nothingBox.noUserCheckins" tagName="h1" />
            </NothingBox>
          </Panel.Body>
        </Panel>

        <div className={cn('mobile-table-wrapper', 'hide-for-large')}>
          <Preloader isActive={isLoading} />

          <div className={cn('search')}>
            <SearchBox initialValue={search} onChange={this.onSearchChange} />
          </div>

          <PaginationWrapper
            onlyBottom
            totalItems={total}
            currentPage={page}
            onPageChange={this.onPageChange}
            perPage={per_page}
            onPerPageChange={this.onPerPageChange}
          >
            <div className={cn('mobile-table')}>
              {resources.map((checkin) => (
                <div className={cn('mobile-row')} key={checkin.id}>
                  <Avatar className={cn('user-avatar')} type="farm" iconSize="16" avatarSize="35" />
                  <div className={cn('info')}>
                    <div className={cn('name')}>{checkin.farm.name}</div>
                    <div className={cn('description')}>
                      <span><TimeAgo date={checkin.created_at} formatter={timeFormatter} /></span>
                    </div>
                  </div>
                  <i
                    data-name={`${checkin.id}`}
                    className={cn('fa fa-dots-three-horizontal', 'dropdown-icon')}
                    onClick={(e) => this.openDropdown(e, checkin)}
                  />
                </div>
              ))}
            </div>
            <NothingBox
              itemsName="checkins"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
            >
              <FormattedMessage id="component.nothingBox.noUserCheckins" tagName="h1" />
            </NothingBox>
          </PaginationWrapper>
        </div>
      </section>
    );
  }
}

UserCheckinsTable.propTypes = {
  userId: T.string,
  openDropdown: T.func.isRequired,
  currentUser: T.object.isRequired,
};

export default connect((state) => ({ currentUser: state.auth.user }))(withDropdownActions(UserCheckinsTable));
