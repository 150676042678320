import React, { Component } from 'react';
import T from 'prop-types';
// react-router
import { Link } from 'react-router';
//  components
import PreferenceCollapse from 'containers/MyProfile/components/PreferenceCollapse';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import PreferenceItem from 'containers/MyProfile/components/PreferenceItem';
import Button from 'components/Button';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import Input from 'components/Input';
import IconInput from 'components/IconInput/IconInput';
import InputLabel from 'components/Input/InputLabel';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import DateInput from 'components/DateInput/DateInput';
//  utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import classnames from 'classnames/bind';
import moment from 'moment';
import pick from 'lodash.pick';
//  styles
import styles from './EditUserCollapse.module.scss';

const cn = classnames.bind(styles);

class EditUserCollapse extends Component {

  constructor(props) {
    super(props);

    const userFields = pick(props.user, [
      'first_name',
      'pqa_expire',
      'last_name',
      'nickname',
      'email',
      'country_code',
      'phone',
      'birthday_date',
      'pqa_number'
    ]);
    const { hash } = props;

    this.state = {
      ...userFields,
      pqa_number: userFields.pqa_number || userFields.pqa_number === 0 ? userFields.pqa_number : '',
      focusedOn: hash || null,
      activeTab: hash === '#phone' ? 'phone' : null,
      isLoading: false,
      birthday_date: userFields.birthday_date ? moment(userFields.birthday_date).format('MM/DD/YYYY') : '',
      pqa_expire: userFields.pqa_expire ? moment(userFields.pqa_expire).format('MM/DD/YYYY') : ''
    };
  }

  onCollapseToggle = (tabName) => (
    this.setState((prevState) => ({ activeTab: prevState.activeTab === tabName ? null : tabName, focusedOn: null }))
  );

  onSubmitData = (types) => {
    const { user: { id }, updateUser } = this.props;
    this.setState({ isLoading: true });
    const entity = {
      ...pick(this.state, types),
      ...(types.includes('birthday_date') ?
        { birthday_date: this.state.birthday_date.includes('_') ?
          '_' : moment(this.state.birthday_date, 'MM/DD/YYYY').format('YYYY-MM-DD') } : {}
      ),
      ...(types.includes('pqa_expire') ?
        { pqa_expire: this.state.pqa_expire.includes('_') ?
          '_' : moment(this.state.pqa_expire, 'MM/DD/YYYY').format('YYYY-MM-DD') } : {}
      ),
    };
    updateUser({ ...entity, ...entity.email && ({ email: entity.email.toLowerCase() }) }, id)
      .then(() => {
        showToastrMessage('component.toastr.myProfileEdit.success.profile');
        this.setState({
          isLoading: false,
          activeTab: null,
        });
      })
      .catch((errors) => {
        toastResponseErrors(errors);
        this.setState({ isLoading: false });
      });
  };

  handleChange = (type) => (e) => this.setState({
    [type]: type === 'email' ? e.target.value.toLowerCase() : e.target.value
  });

  onMobileChange = (type) => (value) => this.setState({ [type]: value });

  handlePhoneChange = ({ phone, countryCode }) => this.setState({ phone, country_code: countryCode });

  onPqaNumberChange = ({ target: { value } }) => this.setState({ pqa_number: value ? +value : '' });

  hasChanged = (types = []) => {
    const { user } = this.props;
    return types.some((type) => {
      if (['birthday_date', 'pqa_expire'].includes(type)) {
        return user[type] ?
          moment(user[type]).format('MM/DD/YYYY') !== this.state[type] :
          this.state[type] !== '';
      }
      return user[type] === null ? this.state[type] !== '' : user[type] !== this.state[type];
    });
  };

  render() {
    const {
      isLoading, first_name, email, nickname, phone, last_name, birthday_date, country_code, pqa_expire, pqa_number,
      focusedOn
    } = this.state;
    const { user: { full_name } } = this.props;
    const isActiveTab = (tabName) => this.state.activeTab === tabName;
    const renderSubmitBtn = (types) => (
      <Button
        primary
        className="mt-20"
        onClick={() => this.onSubmitData(types)}
        disabled={!this.hasChanged(types)}
      >
        <FormattedMessage id="general.button.saveChanges" />
      </Button>
    );

    return (
      <CollapsesBox className="mv-20">
        {/* NAME */}
        <PreferenceCollapse
          className={cn('profile-collapse-section')}
          isOpened={isActiveTab('name')}
          onToggle={() => this.onCollapseToggle('name')}
          title={<FormattedMessage id="general.name" />}
          subtitle={full_name}
        >
          <Preloader isActive={isActiveTab('name') && isLoading} />
          <PreferenceItem className={cn('section-data')}>
            <div className="row">
              <div className="small-12 medium-3 mr-20 float-left">
                <FormattedMessage id="component.userProfile.firstName">
                  {(label) => (
                    <>
                      <InputLabel>{label}</InputLabel>
                      <Input
                        onChange={this.handleChange('first_name')}
                        type="text"
                        placeholder={label}
                        value={first_name}
                      />
                    </>
                  )}
                </FormattedMessage>
              </div>
              <div className="small-12 medium-6 float-left">
                <FormattedMessage id="component.userProfile.lastName">
                  {(label) => (
                    <>
                      <InputLabel>{label}</InputLabel>
                      <Input
                        onChange={this.handleChange('last_name')}
                        type="text"
                        placeholder={label}
                        value={last_name}
                      />
                    </>
                  )}
                </FormattedMessage>
              </div>
            </div>
            {renderSubmitBtn(['last_name', 'first_name'])}
          </PreferenceItem>
        </PreferenceCollapse>
        {/* EMAIL */}
        <PreferenceCollapse
          className={cn('profile-collapse-section')}
          isOpened={isActiveTab('email')}
          onToggle={() => this.onCollapseToggle('email')}
          title={<FormattedMessage id="component.userProfile.email" />}
          subtitle={email}
        >
          <Preloader isActive={isActiveTab('email') && isLoading} />
          <PreferenceItem className={cn('section-data')}>
            <div className="row">
              <div className="small-12 medium-10">
                <FormattedMessage id="general.typeEmail">
                  {(placeholder) => (
                    <>
                      <InputLabel><FormattedMessage id="general.emailAddress" /></InputLabel>
                      <Input
                        type="email"
                        placeholder={placeholder}
                        onChange={this.handleChange('email')}
                        value={email}
                      />
                    </>
                  )}
                </FormattedMessage>
              </div>
            </div>
            {renderSubmitBtn(['email'])}
          </PreferenceItem>
        </PreferenceCollapse>
        {/* NICKNAME */}
        <PreferenceCollapse
          className={cn('profile-collapse-section')}
          isOpened={isActiveTab('nickname')}
          onToggle={() => this.onCollapseToggle('nickname')}
          title={<FormattedMessage id="component.userProfile.userName" />}
          subtitle={nickname}
        >
          <Preloader isActive={isActiveTab('nickname') && isLoading} />
          <PreferenceItem className={cn('section-data')}>
            <div className="row">
              <div className="small-12 medium-10">
                <FormattedMessage id="component.userProfile.userName">
                  {(label) => (
                    <>
                      <InputLabel>{label}</InputLabel>
                      <IconInput
                        label={label}
                        onChange={this.handleChange('nickname')}
                        icon={<i className={cn('nickname-icon', 'fa fa-at')} />}
                        iconLeft
                        type="text"
                        placeholder={label}
                        value={nickname}
                      />
                    </>
                  )}
                </FormattedMessage>
                <FormattedMessage id="component.userProfile.userName.description">
                  {(desc) => <i className="fs-12 field-description">{desc}</i>}
                </FormattedMessage>
              </div>
            </div>
            {renderSubmitBtn(['nickname'])}
          </PreferenceItem>
        </PreferenceCollapse>
        {/* PHONE */}
        <PreferenceCollapse
          className={cn('profile-collapse-section')}
          isOpened={isActiveTab('phone')}
          onToggle={() => this.onCollapseToggle('phone')}
          title={<FormattedMessage id="component.userProfile.phone" />}
          subtitle={phone}
        >
          <Preloader isActive={isActiveTab('phone') && isLoading} />
          <PreferenceItem className={cn('section-data')}>
            <div className="row">
              <div className="small-12 medium-4">
                <InputLabel><FormattedMessage id="component.userProfile.phone" /></InputLabel>
                <PhoneInput
                  limit={15}
                  autoFocus={focusedOn === '#phone'}
                  value={{ phone, countryCode: country_code }}
                  onChange={this.handlePhoneChange}
                />
              </div>
            </div>
            <div className="small-12 column">
              <FormattedMessage id="component.userProfile.phoneDescription">
                {(desc) => <i className="fs-12 field-description">{desc}</i>}
              </FormattedMessage>
            </div>
            {renderSubmitBtn(['phone', 'country_code'])}
          </PreferenceItem>
        </PreferenceCollapse>
        {/* BIRTHDAY */}
        <PreferenceCollapse
          className={cn('profile-collapse-section')}
          isOpened={isActiveTab('birthday_date')}
          onToggle={() => this.onCollapseToggle('birthday_date')}
          title={<FormattedMessage id="container.myProfile.myBirthday" />}
          subtitle={birthday_date}
        >
          <Preloader isActive={isActiveTab('birthday_date') && isLoading} />
          <PreferenceItem className={cn('section-data')}>
            <div className="row">
              <div className="small-12 medium-2">
                <InputLabel><FormattedMessage id="container.myProfile.myBirthday" /></InputLabel>
                <DateInput
                  clearable
                  onClear={() => this.setState({ birthday_date: '' })}
                  value={birthday_date}
                  onChange={this.handleChange('birthday_date')}
                  onMobileChange={this.onMobileChange('birthday_date')}
                />
              </div>
              <div className="small-12">
                <i className="fs-12 field-description manage-link">
                  <FormattedMessage
                    id="component.userProfile.birthdateDescription"
                    values={{
                      settingLink: (
                        <Link onlyActiveOnIndex to="/profile/preferences" className="primary">
                          <FormattedMessage id="component.userProfile.manageBirthdateReminder" />
                        </Link>
                      )
                    }}
                  />
                </i>
              </div>
            </div>
            {renderSubmitBtn(['birthday_date'])}
          </PreferenceItem>
        </PreferenceCollapse>
        {/* PQA NUMBER */}
        <PreferenceCollapse
          className={cn('profile-collapse-section')}
          isOpened={isActiveTab('pqa_data')}
          onToggle={() => this.onCollapseToggle('pqa_data')}
          title={<FormattedMessage id="component.userProfile.pqaNumber" />}
          subtitle={pqa_number}
        >
          <Preloader isActive={isActiveTab('pqa_data') && isLoading} />
          <PreferenceItem className={cn('section-data')}>
            <div className="row">
              <div className="small-12 medium-3">
                <FormattedMessage id="component.userProfile.pqaNumber.placeholder">
                  {(placeholder) => (
                    <>
                      <InputLabel><FormattedMessage id="component.userProfile.pqaNumber" /></InputLabel>
                      <Input
                        type="number"
                        value={pqa_number === null ? '' : pqa_number}
                        placeholder={placeholder}
                        onChange={this.onPqaNumberChange}
                      />
                    </>
                  )}
                </FormattedMessage>
              </div>
              <div className="small-12 medium-3">
                <InputLabel><FormattedMessage id="component.userProfile.pqaDate" /></InputLabel>
                <DateInput
                  clearable
                  value={pqa_expire}
                  onClear={() => this.setState({ pqa_expire: '' })}
                  onChange={this.handleChange('pqa_expire')}
                  onMobileChange={this.onMobileChange('pqa_expire')}
                />
              </div>
            </div>
            {renderSubmitBtn(['pqa_expire', 'pqa_number'])}
          </PreferenceItem>
        </PreferenceCollapse>
      </CollapsesBox>
    );
  }
}

EditUserCollapse.propTypes = {
  user: T.object.isRequired,
  updateUser: T.func.isRequired,
  hash: T.string,
};

export default EditUserCollapse;
