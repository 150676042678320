import { handleActions, createAction } from 'redux-actions';
// api
import { getUserFilters, createUserFilter, deleteUserFilter, updateUserFilter } from 'endpoints/userFilters';

// should be like { Activities: { isLoading: false, isLoaded: true, filters: [] } }
const initialState = {};

const CREATE_USER_FILTERS_FULFILLED = 'userFilters/CREATE_USER_FILTERS_FULFILLED';
const DELETE_USER_FILTERS_FULFILLED = 'userFilters/DELETE_USER_FILTERS_FULFILLED';
const FETCH_USER_FILTERS_FULFILLED = 'userFilters/FETCH_USER_FILTERS_FULFILLED';
const FETCH_USER_FILTERS_REJECTED = 'userFilters/FETCH_USER_FILTERS_REJECTED';
const SET_IS_LOADING = 'userFilters/SET_IS_LOADING';
const UPDATE_USER_FILTERS_FULFILLED = 'userFilters/UPDATE_USER_FILTERS_FULFILLED';

// ------------------------------------
// Actions
// ------------------------------------

export const getUserFiltersAction = (groupName) => (dispatch) => {
  dispatch(createAction(SET_IS_LOADING)({ groupName, isLoading: true }));
  return getUserFilters(groupName)
    .then((filters) => {
      dispatch(createAction(FETCH_USER_FILTERS_FULFILLED)({ filters, groupName }));
      return filters;
    })
    .catch(() => {
      dispatch(createAction(FETCH_USER_FILTERS_REJECTED)({ groupName }));
    });
};

export const createUserFilterAction = (resource) => (dispatch) => {
  dispatch(createAction(SET_IS_LOADING)({ groupName: resource.group_name, isLoading: true }));
  return createUserFilter(resource)
    .then((filter) => {
      dispatch(createAction(CREATE_USER_FILTERS_FULFILLED)(filter));
      return filter;
    })
    .catch((errors) => {
      dispatch(createAction(SET_IS_LOADING)({ groupName: resource.group_name, isLoading: false }));
      throw errors;
    });
};

export const deleteUserFilterAction = (filterId, groupName) => (dispatch) => {
  dispatch(createAction(SET_IS_LOADING)({ groupName, isLoading: true }));
  return deleteUserFilter(filterId)
    .then(() => {
      dispatch(createAction(DELETE_USER_FILTERS_FULFILLED)({ groupName, filterId }));
    })
    .catch((errors) => {
      dispatch(createAction(SET_IS_LOADING)({ groupName, isLoading: false }));
      throw errors;
    });
};

export const updateUserFilterAction = (filterId, groupName, resource) => (dispatch) => {
  dispatch(createAction(SET_IS_LOADING)({ groupName, isLoading: true }));
  return updateUserFilter(filterId, resource)
    .then((filter) => {
      dispatch(createAction(UPDATE_USER_FILTERS_FULFILLED)(filter));
      return filter;
    })
    .catch((errors) => {
      dispatch(createAction(SET_IS_LOADING)({ groupName, isLoading: false }));
      throw errors;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({
  [SET_IS_LOADING]: (state, { payload }) => ({
    ...state,
    [payload.groupName]: {
      ...state[payload.groupName],
      isLoading: payload.isLoading,
    },
  }),
  [FETCH_USER_FILTERS_FULFILLED]: (state, { payload }) => ({
    ...state,
    [payload.groupName]: {
      filters: payload.filters,
      isLoading: false,
      isLoaded: true,
    },
  }),
  [FETCH_USER_FILTERS_REJECTED]: (state, { payload }) => ({
    ...state,
    [payload.groupName]: {
      ...state[payload.groupName],
      isLoading: false,
      isLoaded: true,
    },
  }),
  [CREATE_USER_FILTERS_FULFILLED]: (state, { payload: createdFilter }) => ({
    ...state,
    [createdFilter.group_name]: {
      ...state[createdFilter.group_name],
      filters: [
        ...(state[createdFilter.group_name]?.filters || []),
        createdFilter,
      ],
      isLoading: false,
    },
  }),
  [DELETE_USER_FILTERS_FULFILLED]: (state, { payload }) => ({
    ...state,
    [payload.groupName]: {
      ...state[payload.groupName],
      filters: state[payload.groupName]?.filters.filter(({ id }) => id !== payload.filterId) || [],
      isLoading: false,
    },
  }),
  [UPDATE_USER_FILTERS_FULFILLED]: (state, { payload: updatedFilter }) => ({
    ...state,
    [updatedFilter.group_name]: {
      ...state[updatedFilter.group_name],
      filters: state[updatedFilter.group_name]?.filters.map(
        (filter) => (filter.id === updatedFilter.id ? updatedFilter : filter)
      ),
      isLoading: false,
    },
  }),
  'global/RESET_STORE': () => initialState,
}, initialState);
