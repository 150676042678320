import { SubmissionError } from 'redux-form';
import each from 'lodash.foreach';
import isString from 'lodash.isstring';
import isObject from 'lodash.isobject';

export default function handleFormErrors(response, extendKeys = {}) {
  // isString
  if (isString(response.error)) {
    throw new SubmissionError({ error: response.error });
  }

  // isArray
  if (Array.isArray(response.errors)) {
    throw new SubmissionError(response.errors);
  }

  // isObject
  if (isObject(response.errors)) {
    each(extendKeys, (formKey, responseKey) => {
      if (response.errors[responseKey] && response.errors[responseKey].length) {
        response.errors[formKey] = response.errors[formKey] || response.errors[responseKey]; // eslint-disable-line no-param-reassign
      }
    });
    const deepObjectParser = (errors) => each(errors, (error, key) => {
      if (Array.isArray(errors[key])) {
        errors[key] = error.join(','); // eslint-disable-line no-param-reassign
        return;
      }

      if (isObject(error)) deepObjectParser(error);
    });

    deepObjectParser(response.errors);

    throw new SubmissionError(response.errors);
  }
}
