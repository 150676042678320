import React, { useEffect } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { openModal } from 'reducers/modals';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Button from 'components/Button';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Avatar from 'components/Avatar/Avatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// hooks
import useDataTableController from 'hooks/useDataTableController';
// shareholder groups API
import { deleteShareholderGroup, updateShareholderGroup } from 'endpoints/admin/shareholderGroups';
// utils
import cn from 'classnames';
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const tableFilters = [
  { label: <FormattedMessage id="general.allGroups" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
  { label: <FormattedMessage id="general.status.incomplete" />, value: 'incomplete' },
];

const ShareholderGroups = ({
  fetchData,
  isLoading,
  meta,
  openModal,
  reqParams,
  setDataItem,
  shareholderGroups,
}) => {
  const {
    onPageChange,
    onPerPageChange,
    onSearchChange,
    onStatusChange,
    onSortChange,
  } = useDataTableController(fetchData, reqParams);
  const { status, search, sort, page, per_page } = reqParams;
  const { total, stats } = meta;

  useEffect(() => {
    fetchData(reqParams).catch(toastResponseErrors);
  }, []);

  const handleDeleteShareholderGroup = (id) => {
    deleteShareholderGroup(id)
      .then(() => {
        showToastrMessage('component.toastr.shGroup.deleted');
        fetchData();
      })
      .catch(toastResponseErrors);
  };

  const handleUpdateShareholderGroup = (id, resource) => {
    updateShareholderGroup(id, resource)
      .then(setDataItem)
      .catch(toastResponseErrors);
  };

  const handleConfirmDelete = (id) => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteGroup" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteSHGroup.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.deleteSHGroup.warning" />}
        handleConfirm={() => handleDeleteShareholderGroup(id)}
      >
        <FormattedMessage tagName="p" id="component.modal.deleteSHGroup.text" />
      </ConfirmationModal>
    );
  };

  const renderNameColumn = (rowData) => (
    <CustomColumn flexRow>
      <Avatar type="shareholder_group" avatarSize={35} iconSize={16} className="mr-10" />
      <span className="semibold">{rowData.name}</span>
    </CustomColumn>
  );

  const renderFarmsInGroup = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.farms" />}>
      {rowData.farms_count}
    </CustomColumn>
  );

  const renderUsersWithAccess = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.users" />}>
      {rowData.users_count}
    </CustomColumn>
  );

  const renderStatusColumn = (rowData) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={rowData.status} />
    </CustomColumn>
  );

  const renderActionsColumn = (rowData, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${rowData.id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.view" />}
        buttonType="small light-gray"
        url={`/admin/shareholder-groups/${rowData.id}/farm-roles`}
        dropDownData={[
          {
            label: <FormattedMessage id="general.editGroup" />,
            url: `/admin/shareholder-groups/${rowData.id}`,
          },
          {
            label: rowData.status === 'disabled'
              ? <FormattedMessage id="general.activateGroup" />
              : <FormattedMessage id="general.deactivateGroup" />,
            onClick: () => handleUpdateShareholderGroup(rowData.id, { active: rowData.status === 'disabled' }) },
          {
            label: <FormattedMessage id="general.deleteGroup" />,
            onClick: () => handleConfirmDelete(rowData.id)
          },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/shareholder-groups/${rowData.id}`} className="button light hide-for-large">
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button darkGrey onClick={() => handleConfirmDelete(rowData.id)} className="hide-for-large">
        <FormattedMessage id="general.button.delete" />
      </Button>
    </div>
  );

  const renderExpandable = (rowData) => (
    <div>
      <Link to={`/admin/shareholder-groups/${rowData.id}/farm-roles`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button small light onClick={() => handleConfirmDelete(rowData.id)}>
        <i className="fa fa-trash-o mr-5" />
        <FormattedMessage id="general.button.delete" />
      </Button>
    </div>
  );

  const isEmptyTable = !shareholderGroups.length && !status && !search;

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };
  const columns = [
    { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '2 1 150px',
      renderer: renderNameColumn, sortKey: 'name' },
    { label: <FormattedMessage id="component.dataTable.headers.farmsInGroup" />, flex: '1 1 100px',
      renderer: renderFarmsInGroup, sortKey: 'farms_count' },
    { label: <FormattedMessage id="component.dataTable.headers.userWithAccess" />, flex: '1 1 100px',
      renderer: renderUsersWithAccess, sortKey: 'users_count' },
    { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 100px',
      renderer: renderStatusColumn },
    { label: '', flex: '0 0 170px', renderer: renderActionsColumn, fixed: true,
      className: cn({ 'hide-for-large': isTablet }) },
    { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  return (
    <section className="ShareholderGroups small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.shareholderGroups" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />
          }
        </Panel.Heading>
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={status}
              onFilterChange={onStatusChange}
              stats={stats}
              className="ph-10"
            >
              <Link to="/admin/shareholder-groups/create" className="button small primary wider">
                <FormattedMessage id="general.button.createGroup" />
              </Link>
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={shareholderGroups}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={renderExpandable}
            scrollable
            tableKey="adminShareholderGroups"
          />

          <NothingBox
            itemsName="shareholderGroups"
            display={!shareholderGroups.length}
            isLoading={isLoading}
            search={search}
            filter={status}
          >
            <FormattedMessage id="component.nothingBox.anySHGroupsYet" tagName="h1" />
            <FormattedMessage id="component.nothingBox.createSHGroup" tagName="p" />
            <Link to="/admin/shareholder-groups/create" className="button primary mt-10">
              <FormattedMessage id="general.button.createGroup" />
            </Link>
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

ShareholderGroups.propTypes = {
  shareholderGroups: T.array,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  openModal: T.func.isRequired,
};

export default connect(
  (state) => ({
    shareholderGroups: state.dataTable.adminShareholderGroups.resources,
    isLoading: state.dataTable.adminShareholderGroups.isLoading,
    reqParams: state.dataTable.adminShareholderGroups.params,
    meta: state.dataTable.adminShareholderGroups.meta,
  }), {
    fetchData: (query) => fetchData('/admin/shareholder_groups', 'adminShareholderGroups', query),
    setDataItem: (data) => setDataItem(data, 'adminShareholderGroups'),
    openModal,
  }
)(ShareholderGroups);
