import React from 'react';

import styles from './RedirectBoard.module.scss';

import T from 'prop-types';
// import cn from 'classnames';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

const RedirectBoard = ({ type, title, onClick  }) => {
  return (
    <div className={styles.bodyRedirect}>
      <div className={styles.wrapperInfoText}>
        <FormattedMessage
          id="general.imports.templates.redirect.infoText"
          values={{ name: <FormattedMessage id={title} /> }}
        />
      </div>
      <div className={styles.wrapperButtons}>
        <button onClick={onClick}>
          <FormattedMessage id="general.button.cancel" />
        </button>
        <Link to={type === 'FAS' ? '/admin/preferences/integrations/prairie-systems'
          : '/admin/preferences/integrations/meta-farms'}
        >
          <FormattedMessage id="general.button.connect" />
        </Link>
      </div>
    </div>

  );
};

RedirectBoard.propTypes = {
  type: T.string,
  title: T.string,
  onClick: T.func,
};

export default RedirectBoard;
