import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';
// api
import { getSymptoms } from 'endpoints/common';
import { fetchCompanies } from 'endpoints/companies';
import { fetchCountryPhones } from 'endpoints/static';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  countriesData: {
    resources: [],
    isLoading: false,
    isLoaded: false,
  },
  countryPhonesData: {
    resources: [],
    isLoading: false,
    isLoaded: false,
  },
  symptomsList: {
    resources: [],
    isLoading: false,
  },
  companiesList: {
    resources: [],
    isLoading: false,
  },
  shareholderGroupsList: {
    resources: [],
    isLoading: false,
  },
  diagnosesList: {
    resources: [],
    isLoading: false,
  },
  isLoading: false,
};

const FETCH_SYMPTOMS_LIST = 'static/FETCH_SYMPTOMS_LIST';
const FETCH_COMPANIES_LIST = 'static/FETCH_COMPANIES_LIST';
const FETCH_SH_GROUPS_LIST = 'static/FETCH_SH_GROUPS_LIST';
const FETCH_DIAGNOSES_LIST = 'static/FETCH_DIAGNOSES_LIST';
const FETCH_COUNTRY_PHONES_DATA = 'static/FETCH_COUNTRY_PHONES_DATA';

const FETCH_COUNTRY_PHONES_DATA_PENDING = 'static/FETCH_COUNTRY_PHONES_DATA_PENDING';
const FETCH_COUNTRY_PHONES_DATA_FULFILLED = 'static/FETCH_COUNTRY_PHONES_DATA_FULFILLED';
const FETCH_COUNTRY_PHONES_DATA_REJECTED = 'static/FETCH_COUNTRY_PHONES_DATA_REJECTED';

const FETCH_COMPANIES_LIST_PENDING = 'static/FETCH_COMPANIES_LIST_PENDING';
const FETCH_COMPANIES_LIST_FULFILLED = 'static/FETCH_COMPANIES_LIST_FULFILLED';
const FETCH_COMPANIES_LIST_REJECTED = 'static/FETCH_COMPANIES_LIST_REJECTED';

const FETCH_SYMPTOMS_LIST_PENDING = 'static/FETCH_SYMPTOMS_LIST_PENDING';
const FETCH_SYMPTOMS_LIST_FULFILLED = 'static/FETCH_SYMPTOMS_LIST_FULFILLED';
const FETCH_SYMPTOMS_LIST_REJECTED = 'static/FETCH_SYMPTOMS_LIST_REJECTED';

const FETCH_SH_GROUPS_LIST_PENDING = 'static/FETCH_SH_GROUPS_LIST_PENDING';
const FETCH_SH_GROUPS_LIST_FULFILLED = 'static/FETCH_SH_GROUPS_LIST_FULFILLED';
const FETCH_SH_GROUPS_LIST_REJECTED = 'static/FETCH_SH_GROUPS_LIST_REJECTED';

const FETCH_DIAGNOSES_LIST_PENDING = 'static/FETCH_DIAGNOSES_LIST_PENDING';
const FETCH_DIAGNOSES_LIST_FULFILLED = 'static/FETCH_DIAGNOSES_LIST_FULFILLED';
const FETCH_DIAGNOSES_LIST_REJECTED = 'static/FETCH_DIAGNOSES_LIST_REJECTED';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchSymptomsList = () => createAction(FETCH_SYMPTOMS_LIST)(getSymptoms());

export const fetchCompaniesList = () => createAction(FETCH_COMPANIES_LIST)(fetchCompanies());

export const fetchShareholderGroupsList = () => createAction(FETCH_SH_GROUPS_LIST)(
  fetchFromAPI('/shareholder_groups')
);

export const fetchDiagnosesList = () => createAction(FETCH_DIAGNOSES_LIST)(
  fetchFromAPI('/diagnoses')
);

export const fetchCountryPhonesData = () => createAction(FETCH_COUNTRY_PHONES_DATA)(fetchCountryPhones());

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({
  [FETCH_COUNTRY_PHONES_DATA_PENDING]: (state) => ({
    ...state,
    countryPhonesData: {
      ...state.countryPhonesData,
      isLoading: true,
    },
  }),
  [FETCH_COUNTRY_PHONES_DATA_FULFILLED]: (state, action) => ({
    ...state,
    countryPhonesData: {
      resources: action.payload,
      isLoading: false,
      isLoaded: true,
    },
  }),
  [FETCH_COUNTRY_PHONES_DATA_REJECTED]: (state) => ({
    ...state,
    countryPhonesData: {
      ...state.countryPhonesData,
      isLoading: false,
      isLoaded: false,
    },
  }),
  [FETCH_SYMPTOMS_LIST_PENDING]: (state) => ({
    ...state,
    symptomsList: {
      ...state.symptomsList,
      isLoading: true,
    },
  }),
  [FETCH_SYMPTOMS_LIST_FULFILLED]: (state, action) => ({
    ...state,
    symptomsList: {
      resources: action.payload,
      isLoading: false,
    },
  }),
  [FETCH_SYMPTOMS_LIST_REJECTED]: (state) => ({
    ...state,
    symptomsList: {
      ...state.symptomsList,
      isLoading: false,
    },
  }),
  [FETCH_COMPANIES_LIST_PENDING]: (state) => ({
    ...state,
    companiesList: {
      ...state.companiesList,
      isLoading: true,
    },
  }),
  [FETCH_COMPANIES_LIST_FULFILLED]: (state, action) => ({
    ...state,
    companiesList: {
      resources: action.payload,
      isLoading: false,
    },
  }),
  [FETCH_COMPANIES_LIST_REJECTED]: (state) => ({
    ...state,
    companiesList: {
      ...state.companiesList,
      isLoading: false,
    },
  }),
  [FETCH_SH_GROUPS_LIST_PENDING]: (state) => ({
    ...state,
    shareholderGroupsList: {
      ...state.shareholderGroupsList,
      isLoading: true,
    },
  }),
  [FETCH_SH_GROUPS_LIST_FULFILLED]: (state, action) => ({
    ...state,
    shareholderGroupsList: {
      resources: action.payload.resources,
      isLoading: false,
    },
  }),
  [FETCH_SH_GROUPS_LIST_REJECTED]: (state) => ({
    ...state,
    shareholderGroupsList: {
      ...state.shareholderGroupsList,
      isLoading: false,
    },
  }),
  [FETCH_DIAGNOSES_LIST_PENDING]: (state) => ({
    ...state,
    diagnosesList: {
      ...state.diagnosesList,
      isLoading: true,
    },
  }),
  [FETCH_DIAGNOSES_LIST_FULFILLED]: (state, action) => ({
    ...state,
    diagnosesList: {
      resources: action.payload.resources,
      isLoading: false,
    },
  }),
  [FETCH_DIAGNOSES_LIST_REJECTED]: (state) => ({
    ...state,
    diagnosesList: {
      ...state.diagnosesList,
      isLoading: false,
    },
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
