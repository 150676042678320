import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setProgressSync } from 'reducers/network';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classNames from 'classnames/bind';
import debounce from 'lodash.debounce';
import random from 'lodash.random';
// styles
import styles from './NetworkConnectNotification.scss';

const cx = classNames.bind(styles);

class NetworkConnectNotification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVisible: !props.isOnline,
      isShowSync: false,
    };
    this.interval = 0;
  }

  UNSAFE_componentWillReceiveProps({ isOnline, progress }) {
    if (!progress && this.props.isOnline !== isOnline) {
      this.setState({ isVisible: true }, this.hideNotification);
    }
    if (this.props.progress !== progress) {
      if (progress && progress !== 100 && !this.state.isShowSync) {
        this.setState({ isShowSync: true, isVisible: true }, this.animate);
      }
      if (progress === 100) {
        clearInterval(this.interval);
        this.hideSyncNotification();
      }
    }
  }

  animate = () => {
    const { setProgressSync } = this.props;
    let { progress } = this.props;
    this.interval = setInterval(() => {
      progress += random(10, 20);
      if (progress <= 97) setProgressSync({ progress });
      else {
        setProgressSync({ progress: 97 });
        clearInterval(this.interval);
      }
    }, 2000);
  };

  hideNotification = debounce(() => {
    if (this.props.isOnline && !this.props.progress) this.setState({ isVisible: false });
  }, 3000);

  handleReloadConnect = () => {
    if (!this.props.isOnline) {
      this.setState({ isVisible: false }, debounce(() => this.setState({ isVisible: true }), 1500));
    }
  };

  hideSyncNotification = debounce(() => {
    this.setState({ isShowSync: false, isVisible: false }, () => {
      this.props.setProgressSync({ progress: 0, syncingData: '' });
    });
  }, 1500);

  render() {
    const { isOnline, isStickyHidden, progress, syncingData } = this.props;
    const { isVisible, isShowSync } = this.state;

    return (
      <div
        className={cx('NetworkNotificationBox', {
          visible: isVisible,
          offline: !isOnline,
          stickyMobileView: isStickyHidden,
        })}
      >
        {(isOnline && !isShowSync && !progress) &&
          <FormattedMessage id="component.networkConnectNotification.backOnline" />}
        {!isOnline && <FormattedMessage id="component.networkConnectNotification.noConnect" />}
        {isShowSync && progress === 100 && (
          <span>
            <i className="fa fa-check" />&nbsp;
            {syncingData === 'preloadData' && (
              <FormattedMessage id="component.networkConnectNotification.readyForOffline" />
            )}
            {syncingData === 'syncingDC' && (
              <FormattedMessage id="component.networkConnectNotification.dcSynced" />
            )}
            {syncingData === 'syncingSVR' && (
              <FormattedMessage id="component.networkConnectNotification.svrSynced" />
            )}
            {syncingData === 'syncingSVRAndDC' && (
              <FormattedMessage id="component.networkConnectNotification.svrAndDcSynced" />
            )}
          </span>
        )}

        {isShowSync && progress !== 100 && (
          <div className={cx('progress-bar')}>
            <FormattedMessage id="component.networkConnectNotification.syncData" values={{ progress }}>
              {(text) => <span className="z-index-1">{text}</span>}
            </FormattedMessage>
            <div className={cx('progress')} style={{ width: `${progress}%` }} />
          </div>
        )}

        {!isOnline && <i onClick={this.handleReloadConnect} className={cx('redo-icon', 'fa fa-repeat')} />}
      </div>
    );
  }
}

NetworkConnectNotification.propTypes = {
  isOnline: T.bool.isRequired,
  isStickyHidden: T.bool.isRequired,
  setProgressSync: T.func.isRequired,
  progress: T.number,
  syncingData: T.string,
};

export default connect(
  (state) => ({
    syncingData: state.network.syncingData,
    progress: state.network.progress,
    isOnline: state.network.isOnline,
    isStickyHidden: state.layout.isHeaderHidden || !(state.layout.showHeader || state.layout.pinHeader),
  }), { setProgressSync }
)(NetworkConnectNotification);
