import { handleActions, createAction } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = [];

const SET_BREADCRUMBS = 'breadcrumbs/SET_BREADCRUMBS';

export const setBreadcrumbs = createAction(SET_BREADCRUMBS);

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({
  [SET_BREADCRUMBS]: (state, { payload = [] }) => payload,
  'global/RESET_STORE': () => initialState,
}, initialState);
