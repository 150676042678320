import React from 'react';
import T from 'prop-types';
import { getLetterInitials } from 'utils';
import { FormattedMessage } from 'react-intl';
import './CompanySelector.scss';

const CompanySelector = ({ companies, onSelect, goBack }) => (
  <div className="CompanySelector">
    {companies.map((company, index) => (
      <div
        key={index}
        className="company-item"
        onClick={() => onSelect(company.id)}
        title={company.name}
      >
        <div className="company-avatar"><span>{getLetterInitials(company.name)}</span></div>
        <div className="company-name">{company.name}</div>
        <i className="fa fa-angle-right" />
      </div>
    ))}

    <a className="back-link" onClick={goBack}>
      <FormattedMessage id="container.login.returnToLogin" />
    </a>
  </div>
);

CompanySelector.propTypes = {
  companies: T.array.isRequired,
  onSelect: T.func.isRequired,
  goBack: T.func.isRequired,
};

export default CompanySelector;
