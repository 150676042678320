import { fetchFromAPI } from 'utils/api';

export function getTreatmentFeedback(id) {
  return fetchFromAPI(`/treatment_feedbacks/${id}`)
    .then((response) => response.resource);
}

export function downloadTreatmentFeedbackCSV(id) {
  return fetchFromAPI(`/treatment_feedbacks/${id}/csv`, { blob: true });
}

export function downloadAllTreatmentFeedbacksCSV() {
  return fetchFromAPI('/treatment_feedbacks/index_csv', { blob: true });
}
