import { Notifier } from '@airbrake/browser';

const isAirbrakeEnabled = Boolean(
  process.env.REACT_APP_AIR_BRAKE_PROJECT_ID && process.env.REACT_APP_AIR_BRAKE_API_KEY
);

export function createAirbrakeClient() {
  return isAirbrakeEnabled ? new Notifier({
    projectId: process.env.REACT_APP_AIR_BRAKE_PROJECT_ID,
    projectKey: process.env.REACT_APP_AIR_BRAKE_API_KEY,
  }) : undefined;
}

export function sendNotify(err, currentUser, severity = 'error') {
  if (!isAirbrakeEnabled) return undefined;
  const errorObject = {
    error: err?.exception || err?.error || 'Unknown error',
    context: {
      response: err,
      user: {
        id: currentUser?.id,
        email: currentUser?.email,
      },
      companyId: currentUser?.current_company?.id,
      severity,
    },
  };

  return window.airbrake?.notify(errorObject);
}
