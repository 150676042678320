import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateCurrentUser } from 'reducers/auth';
// components
import { FormattedMessage } from 'react-intl';
import EditUserCollapse from '../components/EditUserCollapse/EditUserCollapse';
import PasswordCollapseSection from '../components/PasswordCollapseSection/PasswordCollapseSection';
// utils
import isEmpty from 'lodash.isempty';
import moment from 'moment';
// assets
import './MyProfileEdit.scss';
// constants

class UserProfileEdit extends Component {

  renderTitle = () => (
    <h2 className="lighter"><FormattedMessage id="container.myProfile.myInfo.title" /></h2>
  );

  render() {
    const { user, updateCurrentUser, router: { location: { hash } } } = this.props;
    let initialValues;
    if (!isEmpty(user)) {
      initialValues = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        nickname: user.nickname,
        birthday_date: user.birthday_date ? moment(user.birthday_date).format('MM/DD/YYYY') : '',
        pqa_number: user.pqa_number,
        telephone: { phone: user.phone, countryCode: user.country_code },
      };
      if (user.pqa_expire) {
        initialValues.pqa_date = {
          year: moment(user.pqa_expire).format('YYYY'),
          month: moment(user.pqa_expire).format('MM'),
          day: moment(user.pqa_expire).format('DD'),
        };
      }
    }

    return (
      <>
        <EditUserCollapse user={user} updateUser={updateCurrentUser} hash={hash} />
        <PasswordCollapseSection updateUser={updateCurrentUser} user={user} />
      </>
    );
  }
}

UserProfileEdit.propTypes = {
  updateCurrentUser: T.func,
  user: T.object,
  router: T.object,
};

UserProfileEdit.contextTypes = {
  router: T.object,
};

export default connect((state) => ({ user: state.auth.user }), { updateCurrentUser })(UserProfileEdit);
