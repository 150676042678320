import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
// hoc
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import withDropdownActions from 'hoc/withDropdownActions';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { fetchCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import TableFilter from 'components/TableFilter';
import FarmTypeLabel from 'components/FarmTypeLabel/FarmTypeLabel';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ProgressPercent from 'components/ProgressBar/ProgressPercent';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Preloader from 'components/Preloader';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Panel from 'components/Panel';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatMortality, formatTableValue } from 'utils';
import { isTablet } from 'react-device-detect';
// css
import './BarnsheetsCompanyFarms.scss';

const tableFilters = [
  { label: <FormattedMessage id="general.allFarms" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
];

class BarnSheetsCompanyFarms extends Component {

  componentDidMount() {
    const { fetchData, reqParams, params: { companyId }, fetchCompanyProfile } = this.props;
    fetchData({ ...reqParams, company_id: companyId, sort: 'active desc' }).catch(toastResponseErrors);
    fetchCompanyProfile(`/barnsheets/companies/${companyId}`)
      .then((response) => this.onSuccessLoad(response.value.resource))
      .catch((response) => {
        this.onErrorLoad();
        return toastResponseErrors(response);
      });
  }

  onSuccessLoad = (data) => {
    const { params: { companyId }, setBreadcrumbs } = this.props;
    const label = <FormattedMessage id="general.pageTitle.barnsheets" />;
    setBreadcrumbs([
      { label, path: '/barnsheets/companies' },
      { label: data.name, path: `/barnsheets/companies/${companyId}/farms`, mobileTitle: label },
    ]);
  };

  onErrorLoad = () => {
    const { setBreadcrumbs } = this.props;
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.pageTitle.barnsheets" />,
        path: '/barnsheets/companies',
        useLabelAsMobileTitle: true,
      },
    ]);
  };

  openDropdown = (e, id) => {
    const { openDropdown, isAdmin, params: { companyId } } = this.props;
    const bsPath = `/barnsheets/companies/${companyId}/farms/${id}/groups`;
    const options = [
      { label: <FormattedMessage id="general.viewBarnSheet" />, url: bsPath },
      { label: <FormattedMessage id="general.manageFarm" />, url: `/admin/farms/${id}`, hide: !isAdmin },
    ];
    openDropdown(e, options);
  };

  renderFarmNameColumn = ({ id, name }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
      <Link className="primary-link" to={`/barnsheets/companies/${this.props.params.companyId}/farms/${id}/groups`}>
        {name}
      </Link>
    </CustomColumn>
  );

  renderFarmTypeColumn = ({ farm_type }) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      <FarmTypeLabel farmType={farm_type} />
    </CustomColumn>
  );

  renderGroupsCountColumn = ({ open_pig_groups_count }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.openGroups" />}>
      {formatTableValue(open_pig_groups_count)}
    </CustomColumn>
  );

  renderInventoryColumn = ({ pigs_count }) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.inventory" />}>
      {formatTableValue(pigs_count)}
    </CustomColumn>
  );

  renderMortalityColumn = ({ avg_mortality_rate }) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.mortRate" />}>
      {formatMortality(avg_mortality_rate)}
    </CustomColumn>
  );

  renderProgressColumn = ({ progress }) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.compliance" />}>
      {formatTableValue(progress, <ProgressPercent progress={progress} />)}
    </CustomColumn>
  );

  renderTreated24HColumn = ({ avg_treated_24h }) => (
    <CustomColumn textRight label={<FormattedMessage id="general.treatedPeriod" values={{ period: '24h' }} />}>
      {formatTableValue(avg_treated_24h, Number(avg_treated_24h || 0).toFixed(2) + '%')}
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.status" />} noBottomBorder>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderButtonsColumn = ({ id }, { rowIndex }) => {
    const { isAdmin, params: { companyId } } = this.props;
    const bsPath = `/barnsheets/companies/${companyId}/farms/${id}/groups`;
    return (
      <div className="collapsible-value button-column">
        <i
          data-name={`${id}-${rowIndex}`}
          className="fa fa-dots-three-horizontal show-for-large"
          onClick={(e) => this.openDropdown(e, id)}
        />
        <Link to={bsPath} className="button light hide-for-large">
          <FormattedMessage id="general.viewBarnSheet" />
        </Link>
        {isAdmin && (
          <Link to={`/admin/farms/${id}/edit`} className="button dark-grey hide-for-large">
            <FormattedMessage id="general.manageFarm" />
          </Link>
        )}
      </div>
    );
  };

  renderExpandable = ({ id }) => {
    const { isAdmin, params: { companyId } } = this.props;
    const bsPath = `/barnsheets/companies/${companyId}/farms/${id}/groups`;
    return (
      <div>
        {isAdmin && (
          <Link to={`/admin/farms/${id}`} className="button small light">
            <i className="fa fa-gear mr-5" />
            <FormattedMessage id="general.manage" />
          </Link>
        )}
        <Link to={bsPath} className="button small light">
          <i className="fa fa-eye mr-5" />
          <FormattedMessage id="general.viewBarnSheet" />
        </Link>
      </div>
    );
  };

  getCompanyStats = ({ pigs_count, avg_treated_24h, avg_progress }) => {
    return [
      { label: <FormattedMessage id="general.puc" />, value: formatTableValue(pigs_count) },
      { label: <FormattedMessage id="general.treatedPeriod" values={{ period: '24H' }} />,
        value: formatTableValue(avg_treated_24h, Number(avg_treated_24h || 0).toFixed(2) + '%') },
      { label: <FormattedMessage id="component.analyticsPanel.title.farmCompliance" />,
        value: formatTableValue(avg_progress, <ProgressPercent progress={avg_progress} />) },
    ];
  };

  render() {
    const { isAdmin, resources, isLoading, reqParams: { page, per_page, search, sort, status }, meta: { stats, total },
      onPageChange, onPerPageChange, onSearchChange, onStatusChange, onSortChange, company,
      isCompanyLoading } = this.props;
    const { companyId } = this.props.params;

    const title = <FormattedMessage id="general.farms" />;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
        renderer: this.renderFarmNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.type" />, flex: '1 1 50px', renderer: this.renderFarmTypeColumn,
        sortKey: 'farm_type' },
      { label: <FormattedMessage id="general.openGroups" />, flex: '1 1 95px', renderer: this.renderGroupsCountColumn,
        sortKey: 'open_pig_groups_count', textRight: true },
      { label: <FormattedMessage id="component.dataTable.headers.inventory" />, flex: '1 1 70px',
        renderer: this.renderInventoryColumn, sortKey: 'pigs_count', textRight: true },
      { label: <FormattedMessage id="component.dataTable.headers.mortRate" />, flex: '1 1 100px',
        renderer: this.renderMortalityColumn, sortKey: 'avg_mortality_rate', textRight: true },
      { label: <FormattedMessage id="general.treatedPeriod" values={{ period: '24h' }} />, flex: '1 1 100px',
        renderer: this.renderTreated24HColumn, sortKey: 'avg_treated_24h', textRight: true },
      { label: <FormattedMessage id="component.dataTable.headers.compliance" />, flex: '1 1 90px',
        renderer: this.renderProgressColumn, sortKey: 'progress', textRight: true },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 80px',
        renderer: this.renderStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 45px', renderer: this.renderButtonsColumn, fixed: true,
        className: isTablet ? 'hide-for-large' : '' },
      { label: '', flex: '0 0 45px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    const companyStats = this.getCompanyStats(company);

    return (
      <div className="BarnsheetsCompanyFarms">
        <CompanyProfileHeader
          company={company}
          isLoading={isCompanyLoading}
          actualCompanyId={Number(companyId)}
          dropdownOptions={[
            { label: <FormattedMessage id="general.manageCompany" />, url: `/admin/companies/${companyId}/edit`,
              hide: !isAdmin },
          ]}
        >
          <ProfileHeaderStats stats={companyStats} className="company-stats" />
        </CompanyProfileHeader>
        <div className="small-12 column">
          <Panel className="mv-20">
            <Panel.Heading renderTitle={() => <h2 className="lighter show-for-large">{title}</h2>}>
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>
            <Panel.Body noPadding>
              <Preloader isActive={isLoading} />

              <TableFilter
                filters={tableFilters}
                onFilterChange={onStatusChange}
                activeFilter={status}
                stats={stats}
                className="mh-10"
              />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderExpandable}
                scrollable
                tableKey="barnsheetsCompanyFarms"
              />

              <NothingBox
                itemsName="barnsheets"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <h2 className="lighter"><FormattedMessage id="component.nothingBox.noBarnSheets" /></h2>
              </NothingBox>
            </Panel.Body>
          </Panel>
        </div>
      </div>
    );
  }
}

BarnSheetsCompanyFarms.propTypes = {
  company: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isCompanyLoading: T.bool.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  openDropdown: T.func.isRequired,
  isAdmin: T.bool.isRequired,
  params: T.object.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  fetchCompanyProfile: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.barnsheetsCompanyFarms.resources,
      isLoading: state.dataTable.barnsheetsCompanyFarms.isLoading,
      meta: state.dataTable.barnsheetsCompanyFarms.meta,
      reqParams: state.dataTable.barnsheetsCompanyFarms.params,
      isAdmin: state.auth.user.roles_map.admin,
      company: state.profiles.company.data,
      isCompanyLoading: state.profiles.company.isLoading,
    }), (dispatch) => ({
      setBreadcrumbs: (levels) => dispatch(setBreadcrumbs(levels)),
      fetchData: (query) => dispatch(fetchData('/barnsheets/farms', 'barnsheetsCompanyFarms', query)),
      fetchCompanyProfile: (query) => dispatch(fetchCompanyProfile(query)),
    })
  ),
  withDropdownActions,
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(BarnSheetsCompanyFarms);
