import React, { Component } from 'react';
import T from 'prop-types';
// components
import ReactSelect from 'components/Select/ReactSelect';
// utils
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import isNull from 'lodash.isnull';
import identity from 'lodash.identity';
import omit from 'lodash.omit';
import map from 'lodash.map';
import getValue from 'lodash.get';
import { fetchFromAPI } from 'utils/api';

class AsyncSelect extends Component {

  state = {
    isLoading: false,
    options: [],
  };

  componentDidMount() {
    const { autoLoad } = this.props;
    this.mounted = true;
    if (autoLoad) this.loadOptions('');
  }

  componentDidUpdate(prevProps) {
    const { optionsPath } = this.props;
    if (prevProps.optionsPath && !isEqual(prevProps.optionsPath, optionsPath)) {
      this.setState({ cache: '' }, () => this.loadFromServer(''));
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onChange = (option) => {
    const { onChange } = this.props;
    if (onChange) onChange(option);
    if (isNull(option)) this.setState((prevState) => ({ options: prevState.cache }));
  };

  onFocus = (value) => {
    const { onFocus } = this.props;
    if (onFocus) onFocus(value);
    if (!this.state.cache) this.loadOptions('');
  };

  getOptions = (options) => {
    const { selected, multi } = this.props;
    if (isEmpty(selected)) return options;
    let currentOptions = [];
    if (multi) {
      const notInOptions = selected.filter((selected) => !map(options, 'value').includes(selected.value));
      currentOptions = [...options, ...notInOptions];
    } else {
      const selectedInOptions = options.find((option) => (option.value === selected.value));
      currentOptions = selectedInOptions ? options : [...options, selected];
    }
    return currentOptions;
  };

  loadOptions = (input) => {
    const { optionsMapper } = this.props;
    const { cache } = this.state;
    this.setState({ isLoading: true });
    if (input.length < 2 && cache) {
      return this.setState({ isLoading: false, options: optionsMapper(cache) });
    }
    return this.loadFromServer(input);
  };

  handleOptions = (defaultOptions, targetArray) => {
    const options = targetArray.length
      ? defaultOptions.filter((option) => !targetArray.includes(option.item.id))
      : defaultOptions;
    return options;
  }

  loadFromServer = debounce((search) => {
    const {
      requestParams,
      optionsKeys: {
        value
      },
      optionsPath,
      optionsMapper,
      isGroupSelector,
      isExternalFarmsSelector,
      selectedGroups,
      selectedFarms,
    } = this.props;
    const { cache } = this.state;
    fetchFromAPI(optionsPath, { params: { ...requestParams, search } })
      .then(({ resources }) => {
        if (!this.mounted) return;
        const defaultOptions = resources.map((item) => ({
          value: item[value],
          label: this.renderLabel(item),
          item,
        }));

        let options = defaultOptions;
        if (isGroupSelector) {
          options = this.handleOptions(defaultOptions, selectedGroups);
        }
        if (isExternalFarmsSelector) {
          options = this.handleOptions(defaultOptions, selectedFarms);
        }

        if (!cache) this.setState({ cache: options });
        this.setState({
          options: optionsMapper(options),
          isLoading: false,
        });
      })
      .catch(() => {
        if (this.mounted) {
          this.setState((prevState) => ({
            isLoading: false,
            options: prevState.cache,
          }));
        }
      });
  }, 300);

  renderLabel = (item) => {
    const { optionsKeys, labelRenderer } = this.props;
    if (labelRenderer) return labelRenderer(item);
    return item[optionsKeys.label];
  };

  render() {
    const { multi, selected } = this.props;
    const props = omit(this.props, ['optionsPath', 'optionsKeys', 'requestParams', 'labelRenderer', 'selected']);
    const options = this.getOptions(this.state.options);
    const value = multi ? map(selected, 'value') : getValue(selected, 'value', null);
    return (
      <ReactSelect
        {...props}
        options={options}
        value={value}
        onChange={this.onChange}
        onInputChange={this.loadOptions}
        onFocus={this.onFocus}
        isLoading={this.state.isLoading}
      />
    );
  }
}

AsyncSelect.defaultProps = {
  requestParams: {},
  selected: {},
  optionsMapper: identity,
};

AsyncSelect.propTypes = {
  optionsPath: T.string.isRequired,
  optionsKeys: T.object.isRequired,
  labelRenderer: T.func,
  requestParams: T.object,
  optionsMapper: T.func,
  autoLoad: T.bool,
  selected: T.oneOfType([T.object, T.array]),
  onFocus: T.func,
  onChange: T.func,
  multi: T.bool,
  selectedGroups: T.array,
  selectedFarms: T.array,
  isGroupSelector: T.bool,
  isExternalFarmsSelector: T.bool,
};

export default AsyncSelect;
