import React, { Component } from 'react';
import T from 'prop-types';
// components
import ScrollableWrap from 'components/ScrollableWrap';
// utils
import classnames from 'classnames/bind';
import isNumber from 'lodash.isnumber';
// styles
import styles from './TableFilter.module.scss';

const cn = classnames.bind(styles);

class TableFilter extends Component {

  getFilterCounter = (count, value) => {
    const { stats } = this.props;
    if (isNumber(count)) return `(${count})`;
    if (!value) return stats.all ? `(${stats.all})` : '';
    return Array.isArray(value)
      ? `(${value.reduce((sum = 0, val) => sum + (stats[val] || 0), 0)})`
      : `(${stats[value] || 0})`;
  };

  isActive = (value, activeFilter) => {
    if (!value) return !activeFilter;
    return String(value) === String(activeFilter);
  };

  render() {
    const { filters, activeFilter, children, onFilterChange, className, stats } = this.props;
    return (
      <div className={cn('table-filter', className)}>
        <div className={cn('filters-list')}>
          <ScrollableWrap>
            {filters.map(({ value, label, count, hide }, index) => !hide && (
              <div
                key={index}
                className={cn('filter', { 'active': this.isActive(value, activeFilter) })}
                onClick={() => onFilterChange(value)}
              >
                <span>
                  {label}&nbsp;
                  {stats && this.getFilterCounter(count, value)}
                </span>
              </div>
            ))}
          </ScrollableWrap>
        </div>

        <div className={cn('filter-buttons')}>
          {children}
        </div>
      </div>
    );
  }
}

TableFilter.propTypes = {
  filters: T.array.isRequired,
  onFilterChange: T.func.isRequired,
  activeFilter: T.oneOfType([T.string, T.array]),
  stats: T.object,
  children: T.any,
  className: T.string,
};

export default TableFilter;
