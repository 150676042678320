import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './MobileInputLikeRow.module.scss';

const MobileInputLikeRow = ({ className = '', value, onClick }) => (
  <div className={cn(styles['mobile-input-like-row'], { [className]: !!className })} onClick={onClick}>
    <div className={styles.value}>{value}</div>
    <i className={cn(styles.icon, 'fa fa-angle-right-btb')} />
  </div>
);

MobileInputLikeRow.propTypes = {
  className: T.string,
  onClick: T.func.isRequired,
  value: T.oneOfType([T.string, T.node]).isRequired,
};

export default MobileInputLikeRow;
