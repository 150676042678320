import React from 'react';
import T from 'prop-types';
// components
import TimeAgo from 'react-timeago';
import Waypoint from 'react-waypoint';
import { FormattedMessage } from 'react-intl';
import NothingBox from 'components/NothingBox';
import TenantAssetIcon from './TenantAssetIcon';
import ContentPlaceholder from 'components/ContentPlaceholder/ContentPlaceholder';
import Preloader from 'components/Preloader';
// utils
import { getFileUploadState, dateModifiedFormatter, getResourceName } from 'utils/tenantAssetsHelper';
import classnames from 'classnames/bind';
import get from 'lodash.get';
// styles
import styles from './TenantAssetsList.module.scss';

const cn = classnames.bind(styles);

const TenantAssetsList = ({
  search,
  resources,
  isLoading,
  hasMoreResources,
  onLoadMore,
  onOpenDropdown,
  onMarkItemAsSeen,
  onCancelItemUpload,
  onPreviewAsset,
}) => (
  <div className={cn('tenant-assets-list', { 'all-assets-loaded': !hasMoreResources })}>
    {resources.map((asset) => {
      const { uploaded, style } = getFileUploadState(asset);
      return (
        <div
          key={asset.id || asset.uniqueIdentifier}
          className={cn('asset-item-row', { 'not-viewed': !asset.is_viewed || !asset.id })}
          onClick={() => onPreviewAsset(asset)}
        >
          <div className={cn('icon-column')}>
            <TenantAssetIcon
              contentType={asset.content_type}
              hasImageThumb={Boolean(asset.url)}
              imageUrl={get(asset, 'url.small')}
            />
          </div>
          <div className={cn('asset-name-column')}>
            <div className={cn('name')}>{getResourceName(asset)}</div>
            {uploaded && (
              <TimeAgo date={asset.updated_at} formatter={dateModifiedFormatter(asset.updated_at)} />
            )}
            {!uploaded && asset.uniqueIdentifier && (
              <div className={cn('upload-progress')}>
                <div className={cn('progress-value')} style={style} />
              </div>
            )}
          </div>
          <div className={cn('action-column')}>
            {uploaded && (
              <i
                data-name={`${asset.filename || asset.link}-${asset.id}`}
                className={cn('fa fa-dots-three-horizontal', 'action-icon')}
                onClick={onOpenDropdown(asset)}
              />
            )}
            {!uploaded && (
              <i className={cn('fa fa-times', 'action-icon')} onClick={() => onCancelItemUpload(asset)} />
            )}
          </div>
          {!asset.is_viewed && asset.id && <Waypoint onEnter={onMarkItemAsSeen(asset)} />}
        </div>
      );
    })}
    {(isLoading && !resources.length)
      ? <ContentPlaceholder width={300} rowsCount={5} columnsCount={1} />
      : (
        <NothingBox
          itemsName="tenant_resources"
          display={!resources.length}
          isLoading={isLoading}
          search={search}
        >
          <h2 className="lighter semibold">
            <FormattedMessage id="component.nothingBox.noResources" />
          </h2>
        </NothingBox>
      )}
    {hasMoreResources && (
      <>
        {!isLoading && <Waypoint onEnter={onLoadMore} />}
        <Preloader isActive={isLoading} />
      </>
    )}
  </div>
);

TenantAssetsList.propTypes = {
  search: T.string,
  resources: T.array.isRequired,
  isLoading: T.bool.isRequired,
  hasMoreResources: T.bool.isRequired,
  onOpenDropdown: T.func.isRequired,
  onMarkItemAsSeen: T.func.isRequired,
  onCancelItemUpload: T.func,
  onLoadMore: T.func.isRequired,
  onPreviewAsset: T.func.isRequired,
};

export default TenantAssetsList;
