import React, { Component } from 'react';
import T from 'prop-types';
// components
import FilterItem from 'components/FarmFeed/SearchFilters/FilterItem';
import SearchList from 'components/SearchList/SearchList';
import { CompanyLine, FarmLine, PigGroupLine } from 'components/SearchList/Lines/Lines';
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
// utils
import withOutsideClick from 'react-onclickoutside';
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const endpoints = {
  company: '/companies/search',
  farm: '/farms/search',
  group: '/pig_groups/search',
};

class FilterItemContainer extends Component {

  state = {
    resources: [],
    isLoading: false,
  };

  fetchList = (params = {}) => {
    const { filterType, reqParams } = this.props;
    this.setState({ isLoading: true });
    fetchFromAPI(endpoints[filterType], { params: { ...reqParams, ...params } }).then(({ resources }) => {
      this.setState({ isLoading: false, resources });
    }).catch((error) => {
      this.setState({ isLoading: false });
      return toastResponseErrors(error);
    });
  };

  onOpen = () => {
    const { resources } = this.state;
    const { onOpen, isOpened, filterType } = this.props;
    if (isOpened) return;
    onOpen(filterType);
    if (!resources.length) this.fetchList();
  };

  handleClickOutside = () => {
    const { onOpen, isOpened } = this.props;
    if (isOpened) {
      onOpen('');
      this.setState({ resources: [] });
    }
  };

  onSelect = (e, item) => {
    const { onChange, onOpen } = this.props;
    e.stopPropagation();
    onChange(item);
    onOpen('');
  };

  onClear = (e) => {
    const { onChange, isOpened, onOpen } = this.props;
    e.stopPropagation();
    onChange({});
    if (isOpened) onOpen('');
  };

  renderItemLine = (lineData) => {
    const { filterType, value } = this.props;
    const defaultProps = {
      key: lineData.id,
      name: lineData.name,
      onClick: (e) => this.onSelect(e, lineData),
      isDisabled: lineData.id === value.id,
    };
    switch (filterType) {
      case 'company': {
        return <CompanyLine {...defaultProps} />;
      }
      case 'farm': {
        return <FarmLine {...defaultProps} />;
      }
      case 'group': {
        return <PigGroupLine {...defaultProps} pigGroup={lineData} />;
      }
      default:
        return null;
    }
  };

  render() {
    const { resources, isLoading } = this.state;
    const { value, filterType, isOpened, isDisabled } = this.props;
    return (
      <FilterItem
        label={<FormattedMessage id={`general.${filterType}`} />}
        placeholder={<FormattedMessage id={`general.select_${filterType}`} />}
        isOpened={isOpened}
        isDisabled={isDisabled}
        value={value.name}
        onOpen={this.onOpen}
        onClear={this.onClear}
      >
        <SearchList onSearchChange={(search) => this.fetchList({ search })}>
          <Preloader isActive={isLoading} />
          {resources.map(this.renderItemLine)}
        </SearchList>
      </FilterItem>
    );
  }

}

FilterItemContainer.propTypes = {
  value: T.oneOfType([T.string, T.object]),
  onOpen: T.func.isRequired,
  onChange: T.func.isRequired,
  isOpened: T.bool.isRequired,
  isDisabled: T.bool.isRequired,
  filterType: T.string.isRequired,
  reqParams: T.object.isRequired,
};

export default withOutsideClick(FilterItemContainer);
