import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  data: {},
  isLoading: false,
};

// ------------------------------------
// Action Types
// ------------------------------------
const CLEAR_PIG_GROUP_MIGRATION = 'pigGroupMigration/CLEAR_PIG_GROUP_MIGRATION';
const FETCH_PIG_GROUP_MIGRATION = 'pigGroupMigration/FETCH_PIG_GROUP_MIGRATION';
const [FETCH_PIG_GROUP_MIGRATION_PENDING, FETCH_PIG_GROUP_MIGRATION_FULFILLED, FETCH_PIG_GROUP_MIGRATION_REJECTED] = [
  `${FETCH_PIG_GROUP_MIGRATION}_PENDING`,
  `${FETCH_PIG_GROUP_MIGRATION}_FULFILLED`,
  `${FETCH_PIG_GROUP_MIGRATION}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------

export const fetchPigGroupMigration = (id) => createAction(FETCH_PIG_GROUP_MIGRATION)(
  fetchFromAPI(`/pig_migrations/${id}`)
);

export const clearPigGroupMigration = createAction(CLEAR_PIG_GROUP_MIGRATION);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_PIG_GROUP_MIGRATION_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_PIG_GROUP_MIGRATION_FULFILLED]: (state, action) => ({
    data: action.payload.resource,
    isLoading: false,
  }),
  [FETCH_PIG_GROUP_MIGRATION_REJECTED]: (state, action) => ({
    data: action.payload.resource,
    isLoading: false,
  }),
  [CLEAR_PIG_GROUP_MIGRATION]: () => ({
    ...initialState,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
