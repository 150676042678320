import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  page: 1,
  per_page: 25,
  search: '',
};

const initialState = {
  resource: {
    meta: {
      total: 0,
      errors: 0,
    },
    resources: {
      data: {
        report_data: [
          {
            count: 0,
            type: 'death',
            code: 0,
          },
          {
            count: 0,
            type: 'euthanize',
            code: 0
          }
        ],
      }
    },
    params: defaultParams,
  },
  isLoading: false,
  logs: [],
};

const FETCH_CURRENT_CHECKUP = 'mate_farms/FETCH_CURRENT_CHECKUP';
const [FETCH_CURRENT_CHECKUP_PENDING, FETCH_CURRENT_CHECKUP_FULFILLED, FETCH_CURRENT_CHECKUP_REJECTED] = [
  `${FETCH_CURRENT_CHECKUP}_PENDING`,
  `${FETCH_CURRENT_CHECKUP}_FULFILLED`,
  `${FETCH_CURRENT_CHECKUP}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------

export const setDataMeta = createAction(FETCH_CURRENT_CHECKUP_FULFILLED);

export const fetchCurrentMetaFarms = (numberPage, stepPage) => (dispatch) => {

  return  (
    fetchFromAPI(
      `/push_results?page=${!numberPage ? 1 : numberPage}&per_page=${!stepPage ? 25 : stepPage}&api=metafarms`,
      {
        method: 'GET'
      }
    )
      .then((response) => {

        return  dispatch(setDataMeta(response));
      })
      .catch((error) => {
        throw error;
      }));
};


export const fetchCurrentMetaFarmsID = (id) => createAction(FETCH_CURRENT_CHECKUP)(
  fetchFromAPI(`/push_result/:${id}`, { method: 'GET' })
);
export const fetchCurrentMetaFarmsRepeat = (id) => createAction(FETCH_CURRENT_CHECKUP)(
  fetchFromAPI(`/push_result/:${id}/rerun`, { method: 'POST' })
);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_CURRENT_CHECKUP
  [FETCH_CURRENT_CHECKUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_CURRENT_CHECKUP_FULFILLED]: (state, action) => {
    return {
      ...state,
      resource: action.payload,
      isLoading: false,
    };
  },
  [FETCH_CURRENT_CHECKUP_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
