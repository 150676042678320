import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
// styles
import './ProgressPercent.scss';

const WaterUsageProgress = ({ progress, className = '' }) => {
  if (progress === null || progress === undefined) {
    return <FormattedMessage id="general.notAvail" />;
  }

  return (
    <span className={cn(className, 'ProgressPercent', { 'alert': progress < 0, 'success': progress > 0 })}>
      {`${progress > 0 ? '+' : ''}${progress}%`}
    </span>
  );
};

WaterUsageProgress.propTypes = {
  className: T.string,
  progress: T.oneOfType([T.string, T.number]),
};

export default WaterUsageProgress;
