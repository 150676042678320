import React from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MobileHeader.module.scss';

const cx = classnames.bind(styles);

const MobileHeader = ({
  title,
  backLink,
  backIcon = 'fa fa-angle-left',
  children,
}) => (
  <div className={cx('mobile-header')}>
    <Link
      to={typeof backLink === 'string' ? backLink : undefined}
      onClick={typeof backLink === 'function' ? backLink : undefined}
      className={cx('back-link')}
    >
      <i className={backIcon} />
    </Link>
    <div className={cx('title')}>
      {typeof title === 'function'
        ? title()
        : title}
    </div>
    <div className={cx('right-action')}>
      {children}
    </div>
  </div>
);

MobileHeader.propTypes = {
  title: T.oneOfType([T.string, T.node, T.func]),
  backLink: T.oneOfType([T.string, T.func]),
  backIcon: T.string,
  children: T.any,
};

export default MobileHeader;
