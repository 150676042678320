import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import MedicationHeader from 'components/ProfileHeader/MedicationHeader/MedicationHeader';
import Subnavigation from 'components/Subnavigation';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// endpoints
import { getAdminTreatmentProduct, updateAdminTreatmentProduct } from 'endpoints/admin/treatmentProducts';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import { showToastrMessage } from 'utils';

class MedicationProfile extends Component {

  state = {
    medication: {},
    isLoading: false,
  };

  componentDidMount() {
    const { params: { id } } = this.props;
    this.getMedication(id);
  }

  getMedication = (id) => {
    this.setState({ isLoading: true });
    getAdminTreatmentProduct(id)
      .then((medication) => {
        this.setState({ isLoading: false, medication });
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        this.context.router.push('/farmfeed');
        return toastResponseErrors(errors);
      });
  };

  getInitialValues = () => {
    const { params: { id } } = this.props;
    const { medication } = this.state;
    if (!isEmpty(medication) && parseInt(id, 10) === medication.id) {
      return pick(medication, ['withdrawal_period_export', 'withdrawal_value_export', 'withdrawal_value_us', 'notes',
        'withdrawal_period_us', 'common', 'efficacy_survey']);
    }
    return null;
  };

  updateSingleMedication = (data) => {
    const { router } = this.context;
    const { params: { id } } = this.props;
    const resource = {
      ...data,
      withdrawal_value_export: data.withdrawal_value_export || 0,
      withdrawal_value_us: data.withdrawal_value_us || 0,
    };

    this.setState({ isLoading: true });
    return updateAdminTreatmentProduct(id, resource)
      .then(() => {
        this.setState({ isLoading: false });
        showToastrMessage('component.toastr.medication.updated');
        router.push('/admin/health-variables/medications');
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  openConfirmModal({ id, active }) {
    if (!active) {
      this.changeStatus(id, active);
      return;
    }

    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.disableMedication.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.disableMedication.confirm" />}
        actionBtnProps={{ red: true }}
        warningMessage={<FormattedMessage id="component.modal.disableMedication.warning" />}
        handleConfirm={() => this.changeStatus(id, active)}
      >
        <FormattedMessage id="component.modal.disableMedication.text" tagName="p" />
      </ConfirmationModal>
    );
  }

  changeStatus = (id, active) => {
    this.setState({ isLoading: true });
    return updateAdminTreatmentProduct(id, { active: !active })
      .then((medication) => {
        this.setState({ isLoading: false, medication });
        showToastrMessage(`component.toastr.medication.${active ? 'deactivated' : 'activated'}`);
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  render() {
    const { medication, isLoading } = this.state;
    const { children, params: { id } } = this.props;

    const initialValues = this.getInitialValues();
    const childrenWithProps = React.Children.map(
      children,
      (child) => React.cloneElement(child, {
        medication,
        isLoading,
        onSubmit: this.updateSingleMedication,
        initialValues,
      })
    );
    const links = [
      { label: <FormattedMessage id="general.medicationInformation" />,
        link: `/admin/health-variables/medications/${id}/information` },
      { label: <FormattedMessage id="general.settings" />,
        link: `/admin/health-variables/medications/${id}/settings` },
    ];

    return (
      <div>
        <MedicationHeader
          handleClick={() => this.openConfirmModal({ id, active: medication.active })}
          medication={medication}
        />
        <Subnavigation isSticky links={links} className="small-12 column" />
        {childrenWithProps}
      </div>
    );
  }
}

MedicationProfile.propTypes = {
  params: T.object,
  children: T.node,
  openModal: T.func,
};

MedicationProfile.contextTypes = {
  router: T.object,
};

export default connect(null, { openModal })(MedicationProfile);
