import React, { Component } from 'react';
import T from 'prop-types';
// components
import { defaultPieChartConfig } from 'components/Amchart/chartConfig';
import { FormattedMessage } from 'react-intl';
import Amchart from 'components/Amchart/Amchart';
import ChartPlaceholder from 'components/Amchart/ChartPlaceholder';
import StatisticsDropdown from 'components/StatisticsPanel/StatisticsDropdown';
import Preloader from 'components/Preloader';
// utils
import sumBy from 'lodash.sumby';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { conditionBalloonHTML } from 'components/Amchart/GraphItemBalloon/GraphItemBalloon';
// sources api
import { getSourceArrivalConditionData } from 'endpoints/sources';
// constants
import { sourceRangeOptions } from 'constants.js';
// styles
import './ArrivalConditionChart.scss';

class ArrivalConditionChart extends Component {

  state = {
    isLoading: false,
    rangeType: '12_months',
    data: [],
  };

  componentDidMount() {
    const { rangeType } = this.state;
    this.getChartData(rangeType);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { rangeType } = this.state;
    if (this.props.farmType !== nextProps.farmType) {
      this.getChartData(rangeType, nextProps.farmType);
    }
  }

  getChartData = (rangeType, farmType) => {
    const { sourceId, formatMessage } = this.props;
    this.setState({ isLoading: true });
    const getLargest = (data) => data.sort((a, b) => b.value - a.value)[0];
    const reqParams = { period: rangeType, farm_type: farmType !== undefined ? farmType : this.props.farmType };

    getSourceArrivalConditionData(sourceId, reqParams)
      .then((response) => {
        const data = [];
        Object.keys(response).map((label) => data.push({
          label,
          value: response[label],
          title: formatMessage({ id: `general.status.${label}` })
        }));
        this.setState({
          isLoading: false,
          rangeType,
          data: this.prepareChartData(data),
          largest: getLargest(data),
        });
      })
      .catch((response) => {
        this.setState({
          isLoading: false,
          rangeType,
        });
        toastResponseErrors(response);
      });
  };

  onRangeTypeChange = ({ value }) => {
    this.getChartData(value);
  };

  prepareChartData = (data) => {
    const sum = sumBy(data, 'value');
    return data.map((entry) => ({
      ...entry,
      percent: sum === 0 ? 0 : Math.round((entry.value * 100) / sum),
    }));
  };

  getChartConfig = () => {
    const { data } = this.state;

    return {
      ...defaultPieChartConfig,
      classNameField: 'label',
      gradientType: 'linear',
      hoverAlpha: 0.8,
      pullOutRadius: 20,
      pullOutOnlyOne: true,
      balloonText: conditionBalloonHTML,
      defs: {
        linearGradient: [{
          id: 'poor',
          stop: [
            { offset: 0, 'stop-color': '#FF5E3A' },
            { offset: 1, 'stop-color': '#FF8A3B' },
          ],
        }, {
          id: 'good',
          stop: [
            { offset: 0, 'stop-color': '#6DBBDF' },
            { offset: 1, 'stop-color': '#3B68B1' },
          ],
        }, {
          id: 'avg',
          stop: [
            { offset: 0, 'stop-color': '#FFA824' },
            { offset: 1, 'stop-color': '#FFE04B' },
          ],
        }],
      },
      dataProvider: data,
    };
  };

  render() {
    const { rangeType, isLoading, data, largest = {} } = this.state;
    const chartConfig = this.getChartConfig();
    const chartStyle = { height: '250px', minWidth: '330px' };
    const rangeLabel = (sourceRangeOptions.find(({ value }) => value === rangeType) || sourceRangeOptions[0]).label;
    const emptyData = sumBy(data, 'value') === 0;

    return (
      <div className="ArrivalConditionChart">
        <div className="chart-box">
          <div className="chart-box-header">
            <div className="header-left">
              <FormattedMessage id="general.averageAC">
                {(text) => <h3 className="lighter">{text}</h3>}
              </FormattedMessage>
            </div>
            <div className="header-right">
              <div className="summary-data">
                <div className="data-block">
                  <span className={`color-${largest.label}`}>{largest.title}</span>
                </div>
                <div className="range-type">
                  {rangeLabel}
                </div>
              </div>
            </div>
          </div>

          <div className="chart-box-body">
            <Preloader isActive={isLoading} />
            {emptyData
              ? <ChartPlaceholder chartStyle={chartStyle} />
              : <Amchart style={chartStyle} config={chartConfig} />}
            <div className="chart-legend">
              {data.map(({ label, title, percent }, index) => (
                <div className="legend-item" key={index}>
                  <div className={`graph-color ${label}`} />
                  <span className="mr-5">{title}</span>
                  <span>{percent}%</span>
                </div>
              ))}
            </div>
          </div>

          <div className="chart-box-footer">
            <StatisticsDropdown
              options={sourceRangeOptions}
              onOptionChange={this.onRangeTypeChange}
              currentOption={rangeType}
            />
          </div>
        </div>
      </div>
    );
  }
}

ArrivalConditionChart.propTypes = {
  sourceId: T.oneOfType([T.string, T.number]),
  farmType: T.string,
  formatMessage: T.func.isRequired,
};

export default ArrivalConditionChart;
