import React, { Component } from 'react';
import T from 'prop-types';
// components
import TableFilter from 'components/TableFilter/TableFilter';
import CloseoutMortalityChart from './CloseoutMortalityChart';
import ArrivalConditionChart from './ArrivalConditionChart';
import LifecycleDeathTrendChart from './LifecycleDeathTrendChart';
import LifecycleSymptomTrendChart from './LifecycleSymptomTrendChart';
// constants
import { farmTypes } from 'constants.js';
// styles
import './SourceOverview.scss';

class SourceOverview extends Component {

  state = {
    farmType: '',
  };

  onFilterChange = (farmType) => {
    this.setState({ farmType });
  };

  render() {
    const { params } = this.props;
    const { farmType } = this.state;
    const { formatMessage } = this.context;

    const tableFilters = [
      { label: formatMessage({ id: 'general.allFarmTypes' }), value: '' },
      ...farmTypes,
    ];
    const chartProps = {
      sourceId: params.id,
      farmType,
      formatMessage,
    };

    return (
      <div className="SourceOverview">
        <div className="mt-10">
          <div className="mb-20">
            <TableFilter
              filters={tableFilters}
              onFilterChange={this.onFilterChange}
              activeFilter={farmType}
            />
          </div>
          <div className="xlarge-8 column mb-20">
            <CloseoutMortalityChart {...chartProps} />
          </div>
          <div className="xlarge-4 column mb-20">
            <ArrivalConditionChart {...chartProps} />
          </div>
          <div className="column small-12 mb-20">
            <LifecycleDeathTrendChart {...chartProps} />
          </div>
          <div className="column small-12 mb-20">
            <LifecycleSymptomTrendChart {...chartProps} />
          </div>
        </div>
      </div>
    );
  }
}

SourceOverview.contextTypes = {
  formatMessage: T.func.isRequired,
};

SourceOverview.propTypes = {
  params: T.object.isRequired,
};

export default SourceOverview;
