import { compose, withStateHandlers, withHandlers } from 'recompose';

const MouseEnterListener = () => compose(
  withStateHandlers(
    ({
      mouseListeners = []
    }) => mouseListeners.reduce((state, listener) => ({ ...state, [`active${listener}`]: null }), {}),
    {
      mouseEnterListenerStateUpdate: () => (listener, value) => ({
        [`active${listener}`]: value,
      }),
    },
  ),
  withHandlers(
    ({ mouseListeners = [] }) => mouseListeners.reduce((handlers, listener) => ({
      ...handlers,
      [`on${listener}MouseEnter`]: (
        {
          mouseEnterListenerStateUpdate
        }
      ) => (newValue) => mouseEnterListenerStateUpdate(listener, newValue),
      [`on${listener}MouseLeave`]: (
        {
          mouseEnterListenerStateUpdate
        }
      ) => () => mouseEnterListenerStateUpdate(listener, null)
      ,
    }), {})
  )
);

export default MouseEnterListener;
