import { fetchFromAPI } from 'utils/api';

export const createTenant = (resource) => {
  return fetchFromAPI('/admin/tenants', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getTenant = (id) => {
  return fetchFromAPI(`/admin/tenants/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deactivateTenant = (id) => {
  return fetchFromAPI(`/admin/tenants/${id}/disable`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const activateTenant = (id) => {
  return fetchFromAPI(`/admin/tenants/${id}/enable`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateTenant = (id, resource) => {
  return fetchFromAPI(`/admin/tenants/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateSuperAdmin = (id, resource) => {
  return fetchFromAPI(`/admin/admin_users/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
