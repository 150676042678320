import React from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import StepperCSV from '../StepperCSV/StepperCSV';
// utils
import { IntercomAPI } from 'react-intercom';
import classnames from 'classnames/bind';
// assets
import askImg from '../../../../public/images/ask.svg';
import closeImg from '../../../../public/images/close.svg';
// constants
import { INTERCOM_APP_ID } from 'utils/constants';
// styles
import styles from './CSVImportHeader.module.scss';

const cn = classnames.bind(styles);

const CSVImportHeader = ({ activeStep, closePathName }) => (
  <div className={cn('csv-import-header')}>
    <FormattedMessage id="general.csvImport" />
    <div className={cn('steps-wrapper')}>
      <StepperCSV activeStep={activeStep} />
    </div>
    <div className={cn('actions')}>
      <img src={askImg} alt="askImg" onClick={() => (INTERCOM_APP_ID && IntercomAPI('show'))} />
      <div className={cn('vertical-line')} />
      <Link to={closePathName}>
        <img src={closeImg} alt="closeImg" />
      </Link>
    </div>
  </div>
);

CSVImportHeader.propTypes = {
  activeStep: T.number.isRequired,
  closePathName: T.string.isRequired,
};

export default CSVImportHeader;
