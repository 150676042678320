import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
// components
import Field from 'components/Field';
import Button from 'components/Button';
import MessageBox from 'components/MessageBox';
import { Link } from 'react-router';
// translate
import { FormattedMessage } from 'react-intl';

const MetafarmForm = (props) => {
  const {
    change,
    passwordHint,
    handleForm,
    resetForm,
    handleSubmit,
    pristine,
    submitting,
    formValues,
  } = props;
  const isSubmitDisabled = pristine || submitting || !formValues.username.trim() || !formValues.reason_code.trim();
  const onSubmit = (data) => handleForm(data, change);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb-20">
      <Field
        placeholderKey="general.typeUsername"
        label={<FormattedMessage id="general.username" />}
        name="username"
      />

      <Field
        placeholderKey={!passwordHint ? 'general.typePassword' : ''}
        placeholder={passwordHint}
        label={<FormattedMessage id="general.password" />}
        name="password"
      />

      <div className="row">
        <div className="column small-6">
          <Field
            placeholderKey="general.placeholder.reasonCode"
            label={<FormattedMessage id="general.mortalityReasonCode" />}
            name="reason_code"
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <MessageBox type="warning" className="message-box">
            <div className="pl-20">
              <FormattedMessage
                id="component.settings.warningTracking"
                values={{
                  settings_url: (
                    <Link className="color-black underline" to="/admin/preferences/settings">
                      <FormattedMessage id="general.mortalityReasons" />
                    </Link>
                  )
                }}
              />
              <br /><br />
              <FormattedMessage
                id="component.settings.warningNotTracking"
                values={{
                  settings_url: (
                    <Link className="color-black underline" to="/admin/preferences/settings">
                      <FormattedMessage id="general.mortalityReasons" />
                    </Link>
                  )
                }}
              />
              <br /><br />
              <FormattedMessage
                id="component.settings.warningNote"
                values={{
                  titleText: <FormattedMessage tagName="b" id="general.note" />,
                }}
              />
            </div>
          </MessageBox>
        </div>
      </div>

      <div className="mt-10 text-right">
        <Button type="reset" light className="mr-10 wider" onClick={resetForm}>
          <FormattedMessage id="general.button.reset" />
        </Button>
        <Button type="submit" primary className="wider" disabled={isSubmitDisabled}>
          <FormattedMessage id="general.button.submit" />
        </Button>
      </div>
    </form>
  );
};

MetafarmForm.propTypes = {
  change: T.func.isRequired,
  handleForm: T.func.isRequired,
  resetForm: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  passwordHint: T.string,
  formValues: T.object,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
};

const formComponent = reduxForm({
  form: 'metafarms-form',
  enableReinitialize: true,
})(MetafarmForm);

export default connect(
  (state) => ({
    formValues: getFormValues('metafarms-form')(state),
  }), {}
)(formComponent);
