import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import renderIf from 'utils/renderIf';
// assets
import styles from './StatusBadge.module.scss';
// translate
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(styles);

const defaultTheme = { active: 'brand-blue', disabled: 'brand-gray', incomplete: 'brand-red', pending: 'brand-orange' };
const movementTheme = { poor: 'brand-red', good: 'brand-blue', avg: 'brand-orange' };
const shipmentTheme = { received: 'brand-blue' };
const metaFarmsTheme = { success: 'brand-blue', error: 'brand-red' };
const inventoryTheme = { pending: 'brand-red', accepted: 'brand-blue', submitted: 'brand-orange' };
const tenantTheme = { active: 'aquamarine', trial: 'brand-blue', trial_expired_in_days: 'brand-blue',
  trial_expired_today: 'brand-blue', trial_expired: 'brand-orange', deactivated: 'brand-red' };
const groupTheme = { request_close: 'brand-blue', opened: 'brand-blue', action_required: 'brand-red',
  pending_close: 'brand-red', awaiting_delivery: 'brand-orange', scheduled_delivery: 'brand-orange',
  closed: 'brand-gray', draft: 'white-smoke', incomplete: 'brand-orange', };

const commitmentTheme = { accepted: 'brand-blue', pending: 'brand-orange', declined: 'brand-red' };

const loadingTheme = { in_progress: 'aquamarine', processing: 'dark-gray', delivered: 'brand-blue',
  draft: 'white-smoke', declined: 'brand-red', conflict: 'brand-red', incomplete: 'brand-orange',
  confirmed: 'brand-blue', pending: 'brand-orange' };

const StatusBadge = ({ status, theme, className, small, textValues, children }) => (
  renderIf(status)(
    <span className={cn('status-badge', { [className]: !!className, [theme[status]]: !!theme[status], small })}>
      {status && <FormattedMessage id={`general.status.${status}`} values={textValues} />}
      {children}
    </span>
  )
);

StatusBadge.defaultProps = {
  theme: defaultTheme,
  textValues: {},
};

StatusBadge.propTypes = {
  theme: T.object.isRequired,
  className: T.string,
  status: T.string,
  small: T.bool,
  textValues: T.object,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

const PigMovementConditionBadge = (props) => (<StatusBadge theme={movementTheme} {...props} />);
const ShipmentBadge = (props) => (<StatusBadge theme={shipmentTheme} {...props} />);
const InventoryBadge = (props) => (<StatusBadge theme={inventoryTheme} {...props} />);
const TenantStatusBadge = (props) => (<StatusBadge theme={tenantTheme} {...props} />);
const GroupStatusBadge = (props) => (<StatusBadge theme={groupTheme} {...props} />);
const CommitmentStatusBadge = (props) => (<StatusBadge theme={commitmentTheme} {...props} />);
const LoadingStatusBadge = (props) => (<StatusBadge theme={loadingTheme} {...props} />);
const GroupStatusMetaFarms = (props) => (<StatusBadge theme={metaFarmsTheme} {...props} />);

export {
  GroupStatusBadge,
  PigMovementConditionBadge,
  ShipmentBadge,
  InventoryBadge,
  TenantStatusBadge,
  GroupStatusMetaFarms,
  CommitmentStatusBadge,
  LoadingStatusBadge,
};

export default StatusBadge;
