import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import PredictedTagDialog from './PredictedTagDialog';
import ImageTagTooltip from './ImageTagTooltip';
import ImageTag from './ImageTag';

const PredictedImageTagView = ({ tag, onRejectTag, onAcceptTag }) => (
  <ImageTag tag={tag} hasHover onClick={onAcceptTag}>
    {({ isMouseOver, tooltipOffset, triangleOffset, position }) => (
      <ImageTagTooltip
        className="pv-5 ph-10"
        tooltipOffset={tooltipOffset}
        triangleOffset={triangleOffset}
        isVisible={isMouseOver}
        position={position}
      >
        <PredictedTagDialog
          diagnosesCount={tag.diagnosis_ids.length}
          label={tag.label}
          percent={tag.ai_suggestion_percent}
          buttonsData={[
            { onClick: onAcceptTag, label: tag.diagnosis_ids.length === 1
              ? <FormattedMessage id="general.button.accept" />
              : <FormattedMessage id="component.imageTag.viewSuggestions" />,
            },
            { onClick: () => onRejectTag(tag), label: <FormattedMessage id="general.button.reject" /> },
          ]}
        />
      </ImageTagTooltip>
    )}
  </ImageTag>
);

PredictedImageTagView.propTypes = {
  tag: T.object,
  onAcceptTag: T.func.isRequired,
  onRejectTag: T.func.isRequired,
};

export default PredictedImageTagView;
