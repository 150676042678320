import React from 'react';
import T from 'prop-types';
// components
import { Field } from 'redux-form';
import CircleCheckbox from 'components/CircleCheckbox';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './RolesFields.module.scss';

const cn = classnames.bind(styles);

const RolesFields = ({ className, title, roles, checkSelection }) => (
  <div className={cn('row', 'roles-fields', { [className]: !!className })}>
    <div className="small-12 column">
      <label className={cn('label')}>{title}</label>
      <ul>
        {roles.map(({ label, value, description }, idx) => {
          const disabled = typeof checkSelection === 'function' ? checkSelection(value) : false;
          return (
            <li key={idx} className="mt-15">
              <Field
                name="user_role"
                type="radio"
                value={value}
                component={({ input }) => (
                  <CircleCheckbox
                    disabled={disabled}
                    type="radio"
                    label={<span className="semibold">{label}</span>}
                    {...input}
                    className={cn({ disabled })}
                  />
                )}
              />
              <div className={cn('description', { disabled })}>{description}</div>
            </li>
          );
        })}
      </ul>
    </div>
  </div>
);

RolesFields.propTypes = {
  className: T.string,
  title: T.oneOfType([T.string, T.node]).isRequired,
  roles: T.array.isRequired,
  checkSelection: T.func,
};

export default RolesFields;
