import { fetchFromAPI } from 'utils/api';

export const updatePackingPlant = (id, companyId, resource) => {
  return fetchFromAPI(`/admin/packing_companies/${companyId}/packing_plants/${id}`,
    { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const createPackingPlant = (companyId, resource) => {
  return fetchFromAPI(`/admin/packing_companies/${companyId}/packing_plants`,
    { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
