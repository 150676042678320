import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';

const OralTreatmentUnit = ({ product }) => (
  <span>
    {product.unit_size}&nbsp;
    <FormattedMessage id={`general.${product.unit_type}`} />&nbsp;
    {product.unit_measure_type && <FormattedMessage id={`general.${product.unit_measure_type}`} />}
    {product.unit_measure_type && ' '}
    {product.unit_description && `(${product.unit_description})`}
  </span>
);

OralTreatmentUnit.propTypes = {
  product: T.object.isRequired,
};

export default OralTreatmentUnit;
