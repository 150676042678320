import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { openModal } from 'reducers/modals';
// utils
import getValue from 'lodash.get';
// components
import ImageCropperModal from 'components/ImageCropperModal/ImageCropperModal';
import MessageBox from 'components/MessageBox';
import Subnavigation from 'components/Subnavigation';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import UserProfileHeader from 'components/ProfileHeader/UserProfileHeader/UserProfileHeader';
// translate
import { FormattedMessage } from 'react-intl';
// styles
import './MyProfile.scss';

const navLinks = [
  { link: '/profile/edit', label: <FormattedMessage id="container.myProfile.myInfo.title" /> },
  { link: '/profile/farm-roles', label: <FormattedMessage id="general.farmRoles" />, isAdmin: true },
  { link: '/profile/company-roles', label: <FormattedMessage id="general.companyRoles" />, isAdmin: true },
  { link: '/profile/admin-roles', label: <FormattedMessage id="general.adminRoles" />, isAdmin: true },
  { link: '/profile/messaging', label: <FormattedMessage id="container.myProfile.messaging" /> },
  { link: '/profile/preferences', label: <FormattedMessage id="general.preferences" /> },
];

class MyProfile extends Component {

  constructor(props) {
    super(props);
    const isRoleTabsShown = Boolean(!props.user.current_company.is_expired || props.user.roles_map.super_admin);

    this.links = isRoleTabsShown
      ? navLinks
      : navLinks.filter(({ isAdmin }) => !isAdmin);
  }

  componentDidMount() {
    const { setBreadcrumbs } = this.props;
    setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.myProfile" />, useLabelAsMobileTitle: true }
    ]);
  }

  uploadImage = ({ target: { files } }) => {
    if (!files[0]) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = ({ target: { result } }) => {
      this.openCropper(result, true); // upload of avatar trigger edit mode
    };
  };

  openCropper = (avatar = null, isEditMode = false) => {
    const { user, openModal } = this.props;
    openModal(
      <ImageCropperModal
        isEditMode={isEditMode}
        id={user.id}
        avatar={avatar || getValue(user, 'profile_photo.large')}
      />
    );
  };

  render() {
    const { user, children } = this.props;
    const isUserDisabled = user.status === 'disabled';
    const userStats = [
      { label: <FormattedMessage id="general.farmRoles" />, value: user.farm_roles_count },
      { label: <FormattedMessage id="general.companyRoles" />, value: user.company_roles_count },
      { label: <FormattedMessage id="general.logins" />, value: user.sign_in_count },
    ];

    return (
      <div className="MyProfile">
        <UserProfileHeader
          isEditable
          onEditClick={this.openCropper}
          uploadImage={this.uploadImage}
          user={user}
          showStatus
        >
          <ProfileHeaderStats stats={userStats} />
        </UserProfileHeader>

        <Subnavigation links={this.links} className="small-12 column" />

        <div className="small-12 column">
          {isUserDisabled && (
            <div className="small-12 column">
              <MessageBox type="warning" className="mt-20" icon={<i className="fa fa-info-circle" />}>
                <span>
                  <FormattedMessage id="component.alertBox.accountDisabled" tagName="strong" />
                  <FormattedMessage id="component.alertBox.accountDisabled.text" />
                </span>
              </MessageBox>
            </div>
          )}

          <section>
            {children}
          </section>
        </div>
      </div>
    );
  }
}

MyProfile.propTypes = {
  user: T.object,
  children: T.object,
  setBreadcrumbs: T.func.isRequired,
  openModal: T.func.isRequired,
};

MyProfile.contextTypes = {
  router: T.object,
};

export default connect(
  (state) => ({
    user: state.auth.user
  }), {
    setBreadcrumbs,
    openModal,
  }
)(MyProfile);
