import React from 'react';
import T from 'prop-types';
import { API_URL } from 'constants.js';
import { getQueryString } from 'utils';
import { getAuthData } from 'utils/authData';

function getDownloadLink(endpoint) {
  const query = '?' + getQueryString(getAuthData());
  return API_URL + endpoint + query;
}

const DownloadLink = ({
  children,
  endpoint,
  ...props
}) => {
  const target = getDownloadLink(endpoint);

  return (
    <a href={target} {...props}>
      {children}
    </a>
  );
};

DownloadLink.propTypes = {
  endpoint: T.string.isRequired,
  children: T.any,
};

export default DownloadLink;
