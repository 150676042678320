import React from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
import styles from './AttentionBox.module.scss';

const cn = classnames.bind(styles);

const AttentionBox = ({ children, className, showIcon = true }) => (
  <div className={cn('attention-box', className)}>
    {showIcon && <i className="fa fa-info-circle mr-10" />}
    {children}
  </div>
);

AttentionBox.propTypes = {
  children: T.node,
  className: T.string,
  showIcon: T.bool,
};

export default AttentionBox;
