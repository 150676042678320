import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './FilterItem.module.scss';

const cn = classnames.bind(styles);

const FilterItem = ({ className, value, label, placeholder, isOpened, isDisabled, onOpen, onClear, children }) => (
  <div
    onClick={!isDisabled ? onOpen : null}
    className={cn('filter-item', className, { isOpened, isDisabled, 'has-value': !!value })}
  >
    {value || (isOpened ? placeholder : label)}
    {value
      ? <i className={cn('fa fa-times', 'clear-icon')} onClick={onClear} />
      : <i className="fa fa-angle-down ml-5" />}
    {isOpened && (
      <div className={cn('filter-dropdown')}>
        {children}
      </div>
    )}
  </div>
);

FilterItem.propTypes = {
  onOpen: T.func.isRequired,
  onClear: T.func.isRequired,
  className: T.string,
  isOpened: T.bool,
  isDisabled: T.bool,
  value: T.oneOfType([T.string, T.object]),
  label: T.oneOfType([T.string, T.object]),
  placeholder: T.oneOfType([T.string, T.object]),
  children: T.node,
};

export default FilterItem;
