// TODO: fix back-end (bug - push new activity twice) and after remove lastActivityId

import { createAction, handleActions, combineActions } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';
import { isTablet } from 'react-device-detect';
// user filters api
import { getPendingCloseAdminGroupsExists } from 'endpoints/admin/pigGroups';
import { getUnseenActivitiesStats, getFlaggedActivitiesCount } from 'endpoints/farmfeed';
import { getUnseenTenantAssetsCount } from 'endpoints/tenantResources';
import { getUnreadUserNotificationsCount } from 'endpoints/users/notifications';
// ff actions
import { MARK_AS_SEEN, MERGE_NEW_ACTIVITIES, ADD_NEW_POSTED_ACTIVITY, SET_FLAGGED } from 'reducers/farmfeed';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  needAttention: {
    groups: false,
    inventories: false,
  },
  counters: {
    unreadNotifications: 0,
    unviewedActivities: 0,
    unviewedAssets: 0,
    flaggedActivities: 0,
  },
  lastActivityId: null,
  isSidebarCollapsed: isTablet,
  isLoading: false,
};

const FETCH_GROUPS_INFO = 'sidebar/FETCH_GROUPS_INFO';
const FETCH_INVENTORIES_INFO = 'sidebar/FETCH_INVENTORIES_INFO';
const SET_SIDEBAR_COLLAPSED = 'sidebar/SET_SIDEBAR_COLLAPSED';

const FETCH_UNREAD_NOTIFICATIONS_COUNT = 'sidebar/FETCH_UNREAD_NOTIFICATIONS_COUNT';
const SET_UNREAD_NOTIFICATIONS_COUNT = 'sidebar/SET_UNREAD_NOTIFICATIONS_COUNT';
const FETCH_UNVIEWED_COUNT = 'sidebar/FETCH_UNVIEWED_COUNT';
const FETCH_FLAGGED_COUNT = 'sidebar/FETCH_FLAGGED_COUNT';

const INCREASE_UNVIEWED_ASSETS_COUNT = 'sidebar/INCREASE_UNVIEWED_ASSETS_COUNT';
const INCREASE_UNREAD_NOTIFICATIONS_COUNT = 'sidebar/INCREASE_UNREAD_NOTIFICATIONS_COUNT';
const FETCH_UNVIEWED_ASSETS_COUNT = 'sidebar/FETCH_UNVIEWED_ASSETS_COUNT';
const FETCH_UNVIEWED_ASSETS_COUNT_PENDING = 'sidebar/FETCH_UNVIEWED_ASSETS_COUNT_PENDING';
const FETCH_UNVIEWED_ASSETS_COUNT_FULFILLED = 'sidebar/FETCH_UNVIEWED_ASSETS_COUNT_FULFILLED';
const FETCH_UNVIEWED_ASSETS_COUNT_REJECTED = 'sidebar/FETCH_UNVIEWED_ASSETS_COUNT_REJECTED';

const REMOVE_ATTENTION_DOT = 'sidebar/REMOVE_ATTENTION_DOT';

const [FETCH_GROUPS_INFO_PENDING, FETCH_GROUPS_INFO_FULFILLED, FETCH_GROUPS_INFO_REJECTED] = [
  `${FETCH_GROUPS_INFO}_PENDING`,
  `${FETCH_GROUPS_INFO}_FULFILLED`,
  `${FETCH_GROUPS_INFO}_REJECTED`,
];

const [FETCH_INVENTORIES_INFO_PENDING, FETCH_INVENTORIES_INFO_FULFILLED, FETCH_INVENTORIES_INFO_REJECTED] = [
  `${FETCH_INVENTORIES_INFO}_PENDING`,
  `${FETCH_INVENTORIES_INFO}_FULFILLED`,
  `${FETCH_INVENTORIES_INFO}_REJECTED`,
];

const [FETCH_UNVIEWED_COUNT_PENDING, FETCH_UNVIEWED_COUNT_FULFILLED, FETCH_UNVIEWED_COUNT_REJECTED] = [
  `${FETCH_UNVIEWED_COUNT}_PENDING`,
  `${FETCH_UNVIEWED_COUNT}_FULFILLED`,
  `${FETCH_UNVIEWED_COUNT}_REJECTED`,
];

const [FETCH_UNREAD_NOTIFICATIONS_COUNT_PENDING, FETCH_UNREAD_NOTIFICATIONS_COUNT_FULFILLED,
  FETCH_UNREAD_NOTIFICATIONS_COUNT_REJECTED] = [
  `${FETCH_UNREAD_NOTIFICATIONS_COUNT}_PENDING`,
  `${FETCH_UNREAD_NOTIFICATIONS_COUNT}_FULFILLED`,
  `${FETCH_UNREAD_NOTIFICATIONS_COUNT}_REJECTED`,
];

const [FETCH_FLAGGED_COUNT_PENDING, FETCH_FLAGGED_COUNT_FULFILLED, FETCH_FLAGGED_COUNT_REJECTED] = [
  `${FETCH_FLAGGED_COUNT}_PENDING`,
  `${FETCH_FLAGGED_COUNT}_FULFILLED`,
  `${FETCH_FLAGGED_COUNT}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------

export const fetchInventoriesInfo = () => createAction(FETCH_INVENTORIES_INFO)(
  fetchFromAPI('/action_required/inventories')
);

export const fetchGroupsInfo = () => createAction(FETCH_GROUPS_INFO)(getPendingCloseAdminGroupsExists());

export const fetchUnreadNotificationsCount = (id) => createAction(FETCH_UNREAD_NOTIFICATIONS_COUNT)(
  getUnreadUserNotificationsCount(id, 'production')
);

export const setUnreadNotificationsCount = (count) => createAction(SET_UNREAD_NOTIFICATIONS_COUNT)(count);
export const increaseUnreadNotificationsCount = (count) => createAction(INCREASE_UNREAD_NOTIFICATIONS_COUNT)(count);

export const fetchUnviewedActivitiesCount = () => createAction(FETCH_UNVIEWED_COUNT)(getUnseenActivitiesStats());

export const fetchUnviewedAssetsCount = () => createAction(FETCH_UNVIEWED_ASSETS_COUNT)(getUnseenTenantAssetsCount());

export const fetchFlaggedActivitiesCount = () => createAction(FETCH_FLAGGED_COUNT)(getFlaggedActivitiesCount());

export const increaseUnviewedAssetsCount = createAction(INCREASE_UNVIEWED_ASSETS_COUNT);

export const removeAttentionDot = createAction(REMOVE_ATTENTION_DOT);
export const collapseSidebar = createAction(SET_SIDEBAR_COLLAPSED);

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({
  // GROUPS
  [FETCH_GROUPS_INFO_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_GROUPS_INFO_FULFILLED]: (state, action) => ({
    ...state,
    needAttention: {
      ...state.needAttention,
      groups: action?.payload?.pending_close_exists || false,
    },
    isLoading: false,
  }),
  [FETCH_GROUPS_INFO_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  // INVENTORIES
  [FETCH_INVENTORIES_INFO_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_INVENTORIES_INFO_FULFILLED]: (state, action) => ({
    ...state,
    needAttention: {
      ...state.needAttention,
      inventories: action?.payload?.action_required || false,
    },
    isLoading: false,
  }),
  [FETCH_INVENTORIES_INFO_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [SET_SIDEBAR_COLLAPSED]: (state, action) => ({
    ...state,
    isSidebarCollapsed: action.payload,
  }),
  [FETCH_UNREAD_NOTIFICATIONS_COUNT_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_UNREAD_NOTIFICATIONS_COUNT_FULFILLED]: (state, action) => ({
    ...state,
    counters: {
      ...state.counters,
      unreadNotifications: action?.payload?.unread_notifications_count || 0,
    },
    isLoading: false,
  }),
  [FETCH_UNREAD_NOTIFICATIONS_COUNT_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [SET_UNREAD_NOTIFICATIONS_COUNT]: (state, action) => ({
    ...state,
    counters: {
      ...state.counters,
      unreadNotifications: action.payload,
    },
    isLoading: false,
  }),
  [INCREASE_UNREAD_NOTIFICATIONS_COUNT]: (state, action) => {
    const unreadNotifications = state.counters.unreadNotifications + action.payload;
    return {
      ...state,
      counters: {
        ...state.counters,
        unreadNotifications,
      },
      isLoading: false,
    };
  },
  [FETCH_UNVIEWED_COUNT_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_UNVIEWED_COUNT_FULFILLED]: (state, action) => ({
    ...state,
    counters: {
      ...state.counters,
      unviewedActivities: action?.payload?.unviewed_activities_count || 0,
      predictionActivities: action?.payload?.prediction_activities_count || 0,
    },
    lastActivityId: null,
    isLoading: false,
  }),
  [FETCH_UNVIEWED_COUNT_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [FETCH_UNVIEWED_ASSETS_COUNT_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_UNVIEWED_ASSETS_COUNT_FULFILLED]: (state, action) => ({
    ...state,
    counters: {
      ...state.counters,
      unviewedAssets: action?.payload?.unviewed_company_assets_count || 0,
    },
    isLoading: false,
  }),
  [FETCH_UNVIEWED_ASSETS_COUNT_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [INCREASE_UNVIEWED_ASSETS_COUNT]: (state, action) => {
    const unviewedAssets = state.counters.unviewedAssets + action.payload;
    return {
      ...state,
      counters: {
        ...state.counters,
        unviewedAssets,
      },
      isLoading: false,
    };
  },
  [FETCH_FLAGGED_COUNT_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_FLAGGED_COUNT_FULFILLED]: (state, action) => ({
    ...state,
    counters: {
      ...state.counters,
      flaggedActivities: action?.payload?.flagged_activities_count || 0,
    },
    isLoading: false,
  }),
  [FETCH_FLAGGED_COUNT_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [SET_FLAGGED]: (state, action) => {
    const { value } = action.payload;
    const { flaggedActivities } = state.counters;

    return ({
      ...state,
      counters: {
        ...state.counters,
        flaggedActivities: value ? flaggedActivities + 1 : flaggedActivities - 1
      },
    });
  },
  [MARK_AS_SEEN]: (state) => {
    const { unviewedActivities } = state.counters;

    return ({
      ...state,
      counters: {
        ...state.counters,
        unviewedActivities: unviewedActivities === 0 ? unviewedActivities : unviewedActivities - 1,
      }
    });
  },
  [combineActions(MERGE_NEW_ACTIVITIES, ADD_NEW_POSTED_ACTIVITY)]: (state, { payload: { unViewedDiff } }) => ({
    ...state,
    counters: {
      ...state.counters,
      unviewedActivities: state.counters.unviewedActivities + unViewedDiff,
    },
  }),
  [REMOVE_ATTENTION_DOT]: (state, action) => ({
    ...state,
    needAttention: {
      ...state.needAttention,
      [action.payload]: false,
    },
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
