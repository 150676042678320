import React from 'react';
import T from 'prop-types';
// components
import NothingBox from 'components/NothingBox';
import CircleCheckbox from 'components/CircleCheckbox';
import UserAvatar from 'components/UserAvatar';
// utils
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
// styles
import './FarmUserRolesManageTable.scss';

const FarmUserRolesManageTable = ({ data, handleCheckBoxChanged, handleRadioChanged, search, filter, isLoading }) => (
  <div className="FarmUserRolesManageTable">
    <div className="flex-data-table">

      {data.map((user, idx) => {
        const { full_name, is_owner, is_manager, is_caretaker, is_vet } = user;
        return (
          <div className="table-item" key={idx}>
            {/* User Name */}
            <div className="table-item-block column-username">
              <div className="collapsible-value">
                <div className="value-amount title">
                  <div className="user-info">
                    <UserAvatar user={user} size={35} className="mr-10" />
                    <div className="info truncate">
                      <div className="name truncate black">{full_name || '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Primary Role */}
            <div className={cn('table-item-block column-user-role', { 'checked': is_owner })}>
              <div className="collapsible-value with-checkbox">
                <div className="value-amount flex align-center">
                  <CircleCheckbox
                    className="roles-manage"
                    type="radio"
                    label={(
                      <FormattedMessage id="general.role.farmOwner">
                        {(role) => <span className="semibold hide-for-large">{role}</span>}
                      </FormattedMessage>
                    )}
                    name="owner"
                    onChange={(e) => handleRadioChanged(e, user)}
                    checked={is_owner}
                  />
                </div>
              </div>
            </div>
            {/* Manager Role */}
            <div className={cn('table-item-block column-user-role', { 'checked': is_manager })}>
              <div className="collapsible-value with-checkbox">
                <div className="value-amount flex align-center">
                  <CircleCheckbox
                    className="roles-manage"
                    label={(
                      <FormattedMessage id="general.role.manager">
                        {(role) => <span className="semibold hide-for-large">{role}</span>}
                      </FormattedMessage>
                    )}
                    name="manager"
                    onChange={(e) => handleCheckBoxChanged(e, user)}
                    checked={is_manager}
                  />
                </div>
              </div>
            </div>
            {/* Caregiver Role */}
            <div className={cn('table-item-block column-user-role', { 'checked': is_caretaker })}>
              <div className="collapsible-value with-checkbox">
                <div className="value-amount flex align-center">
                  <CircleCheckbox
                    className="roles-manage"
                    label={(
                      <FormattedMessage id="general.role.caregiver">
                        {(role) => <span className="semibold hide-for-large">{role}</span>}
                      </FormattedMessage>
                    )}
                    name="caretaker"
                    onChange={(e) => handleCheckBoxChanged(e, user)}
                    checked={is_caretaker}
                  />
                </div>
              </div>
            </div>
            {/* Veterinarian Role */}
            <div className={cn('table-item-block column-user-role', { 'checked': is_vet })}>
              <div className="collapsible-value with-checkbox no-border">
                <div className="value-amount flex align-center">
                  <CircleCheckbox
                    className="roles-manage"
                    label={(
                      <FormattedMessage id="general.role.veterinarian">
                        {(role) => <span className="semibold hide-for-large">{role}</span>}
                      </FormattedMessage>
                    )}
                    name="vet"
                    onChange={(e) => handleCheckBoxChanged(e, user)}
                    checked={is_vet}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>

    <NothingBox
      itemsName="roles"
      display={!data.length}
      isLoading={isLoading}
      search={search}
      filter={filter}
    >
      <FormattedMessage tagName="h1" id="general.nothingBox.noRoles" />
    </NothingBox>
  </div>
);

FarmUserRolesManageTable.propTypes = {
  data: T.array,
  handleRadioChanged: T.func,
  handleCheckBoxChanged: T.func,
  search: T.string,
  filter: T.string,
  isLoading: T.bool,
};

export default FarmUserRolesManageTable;
