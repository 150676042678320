import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import UserAvatar from 'components/UserAvatar';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './DraftReportCard.module.scss';

const cn = classnames.bind(styles);

const DraftReportCard = ({ isFlagged, onContinue, onDelete, reportUser }) => (
  <div className={cn('draft-report-card', { 'is-flagged': isFlagged })}>
    <div className={cn('bg')} />
    <div className={cn('top-content')}>
      <UserAvatar user={reportUser} size={25} />
      <div className={cn('status-badge')}>
        <FormattedMessage id="general.status.draft" />
      </div>
      <i onClick={onDelete} className={cn('fa fa-times', 'delete-btn')} />
    </div>
    <Button onClick={onContinue} primary className={cn('continue-button')}>
      <FormattedMessage id="general.button.continue" />
    </Button>
  </div>
);

DraftReportCard.propTypes = {
  reportUser: T.object.isRequired,
  isFlagged: T.bool.isRequired,
  onContinue: T.func.isRequired,
  onDelete: T.func.isRequired,
};

export default DraftReportCard;
