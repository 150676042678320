/* eslint-disable no-console */
import { getBrowserName } from './index';
import { getAuthData } from './authData';
import { getLSDeviceToken, setLSDeviceToken } from './localStorageHelper';
import { saveUserDevice } from 'endpoints/userDevices';
import { API_URL } from 'constants.js';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const webPushId = process.env.REACT_APP_WEBPUSH_ID;
const availBrowsersList = ['chrome', 'safari', 'firefox', 'edge'];

function sendTokenToServer(newToken, expiredToken) {
  const agent = getBrowserName().toLowerCase();
  const tokens = [
    { iid_token: newToken, agent: availBrowsersList.indexOf(agent) !== -1 ? agent : undefined, action: 'create' },
  ];
  if (expiredToken) {
    tokens.push({ iid_token: expiredToken, action: 'destroy' });
  }
  saveUserDevice({ tokens })
    .then(() => {
      setLSDeviceToken(newToken);
    });
}

function saveFcmToken(token) {
  const currentToken = getLSDeviceToken();

  if (currentToken === token) return;

  sendTokenToServer(token, currentToken);
}

function requestSafariPermission(permissionData) {
  if (permissionData.permission === 'default') {
    const authData = getAuthData();
    const endpoint = API_URL + '/safari';
    window.safari.pushNotification.requestPermission(
      endpoint,
      webPushId,
      { 'uid': authData.uid, 'token': authData['access-token'], 'client_id': authData.client },
      requestSafariPermission
    );
    return;
  }
  if (permissionData.permission === 'granted') {
    // The web service URL is a valid push provider, and the user said yes.
    // permissionData.deviceToken is now available to use.
    sendTokenToServer(permissionData.deviceToken);
    return;
  }
  console.warn('User not allowed notifications');
}

export function checkSafariPermission() {
  if ('safari' in window && 'pushNotification' in window.safari) {
    const permissionData = window.safari.pushNotification.permission(webPushId);
    const currentToken = getLSDeviceToken();
    if ((permissionData.deviceToken !== currentToken) || !currentToken || !permissionData.deviceToken) {
      requestSafariPermission(permissionData);
    }
  }
}

export function initFirebaseMessaging() {
  initializeApp({
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID,
  });

  if (('Notification' in window) && !('safari' in window)) {
    const messaging = getMessaging();

    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        getToken(messaging)
          .then(saveFcmToken)
          .catch((error) => {
            console.warn('Unable to get permission to notify.', error); // eslint-disable-line
          });
      }
    });
  }
}
