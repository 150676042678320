import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import {
  fetchList,
  setSelectedData,
  selectItem,
  unselectItem,
  resetData,
  resetResources,
} from 'reducers/mobileListPicker';
// components
import Button from 'components/Button';
import MobileHeader from 'components/MobileHeader/MobileHeader';
import VerticalAligner from 'components/VerticalAligner';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import ActivitySwitcher from 'components/Activities/ActivitySwitcher';
import ActivityMentions from 'components/Activities/ActivityMentions';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileFarmRow from 'components/MobileListPicker/Rows/MobileFarmRow';
import MobileUserRow from 'components/MobileListPicker/Rows/MobileUserRow';
import MobilePortalOverlay from 'components/MobilePortalOverlay';
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
// assets
import './ShareActivityPortal.scss';
// utils
import { fetchFromAPI } from 'utils/api';
import autosize from 'autosize';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const nameKeys = { user: 'full_name', farm: 'name' };

class ShareActivityPortal extends Component {

  state = {
    message: '',
    truncated: true,
    isSubmitting: false,
    isSelectMode: ['user', 'farm'].includes(this.props.type),
  };

  componentDidMount() {
    const { type, fetchList } = this.props;
    if (this.textarea) autosize(this.textarea);
    if (['user', 'farm'].includes(type)) fetchList(this.getEnpoint());
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  getEnpoint = () => {
    const { type } = this.props;
    if (type === 'user') {
      return '/users/search';
    }
    return '/farms/search';
  };

  onSubmit = () => {
    const { closePortal, activityToShare, type, currentUser: { current_company }, selectedData } = this.props;
    const { message } = this.state;
    const resource = {
      message,
      farm_id: type === 'farm' ? selectedData[0].id : null,
      company_id: type === 'company' ? current_company.id : null,
      mentions: type === 'user' ? selectedData.map((user) => ({ user_id: user.id })) : null,
    };
    this.setState({ isSubmitting: true });
    fetchFromAPI(`/activities/${activityToShare.id}/shared_activities`, {
      method: 'post', body: JSON.stringify({ resource }),
    })
      .then(() => {
        this.setState({ isSubmitting: true });
        closePortal();
      })
      .catch((errors) => {
        this.setState({ isSubmitting: true });
        return toastResponseErrors(errors);
      });
  };

  toggleView = (e) => {
    e.stopPropagation();
    this.setState(({ truncated }) => ({ truncated: !truncated }));
  };

  renderMobileListRow = (item, mode, { isSelected }) => {
    const { type } = this.props;
    const selectedRow = isSelected(item);

    if (type === 'farm') {
      return (
        <MobileFarmRow
          key={item.id}
          actionBtnType="button"
          name={item.name}
          onRowClick={() => this.onFarmSelect(item)}
          selectedRow={selectedRow}
        />
      );
    }

    return (
      <MobileUserRow
        key={item.id}
        user={item}
        actionBtnType="checkbox"
        name={item.full_name}
        onRowClick={() => this.onUserAdd(item)}
        selectedRow={selectedRow}
      />
    );
  };

  onUserAdd = (user) => {
    const { selectedData, selectItem, unselectItem } = this.props;
    const selectedUser = selectedData.find((item) => item.id === user.id);
    return selectedUser ? unselectItem(selectedUser) : selectItem(user);
  };

  onFarmSelect = (farm) => {
    const { setSelectedData, resetResources } = this.props;
    setSelectedData([farm]);
    this.setState({ isSelectMode: false });
    resetResources();
  };

  onSaveSelectedData = () => {
    const { resetResources } = this.props;
    this.setState({ isSelectMode: false });
    resetResources();
  };

  backToFarmfeed = () => {
    const { closePortal, resetResources } = this.props;
    resetResources();
    closePortal();
  };

  renderRightButtonOptions = () => {
    const { type } = this.props;
    if (type === 'user') {
      return { label: 'next', onClick: this.onSaveSelectedData, disableOnEmptyList: true };
    }
    return null;
  };

  render() {
    const { type, currentUser, activityToShare, selectedData, closePortal } = this.props;
    const { message, truncated, isSubmitting, isSelectMode } = this.state;
    const { formatMessage } = this.context;

    return (
      <div className="ShareActivityPortal">
        <MobilePortalOverlay isHidden={!isSelectMode}>
          {isSelectMode && (
            <MobileListPicker
              title={<FormattedMessage id="general.pageTitle.selectSomeone" />}
              endpoint={this.getEnpoint()}
              nameKey={nameKeys[type]}
              rightButtonOptions={this.renderRightButtonOptions()}
              backLinkOptions={{ onClick: this.backToFarmfeed }}
              rowRenderer={this.renderMobileListRow}
            />
          )}
        </MobilePortalOverlay>

        <MobileHeader
          title={<FormattedMessage id="general.pageTitle.postToFarmfeed" />}
          backLink={closePortal}
          backIcon="fa fa-times"
        >
          <Button primary small onClick={this.onSubmit}>
            <FormattedMessage id="general.button.post" />
          </Button>
        </MobileHeader>

        <div className="post-content">
          <Preloader isActive={isSubmitting} />

          <VerticalAligner>
            <UserAvatar user={currentUser} size={40} className="mr-10" />
            <div className={cn('user-name')}>{currentUser.full_name}</div>
          </VerticalAligner>

          <div className={cn('mention-line')}>
            <ActivityMentions
              company={(type === 'company') ? currentUser.current_company : {}}
              farm={(type === 'farm') ? selectedData[0] : {}}
              users={(type === 'user') ? selectedData : []}
              isAdmin={currentUser.roles_map.admin}
            />
          </div>

          <textarea
            className={cn('text-block')}
            ref={(ref) => { this.textarea = ref; }}
            rows={1}
            value={message}
            onChange={(e) => this.setState({ message: e.target.value })}
            placeholder={formatMessage({ id: 'container.farmfeed.writeSmth' })}
          />

          <div className={cn('activity-preview', { truncated })} onClick={this.toggleView}>
            <ActivitySwitcher currentUser={currentUser} activity={activityToShare} className="no-pointer-events" />
            {truncated && <div className="backdrop-gradient" />}
          </div>
        </div>
      </div>
    );
  }
}

ShareActivityPortal.contextTypes = {
  formatMessage: T.func,
};

ShareActivityPortal.propTypes = {
  type: T.string.isRequired,
  activityToShare: T.object.isRequired,
  closePortal: T.func.isRequired,
  fetchList: T.func.isRequired,
  resetResources: T.func.isRequired,
  selectItem: T.func.isRequired,
  unselectItem: T.func.isRequired,
  resetData: T.func.isRequired,
  setSelectedData: T.func.isRequired,
  selectedData: T.array.isRequired,
  currentUser: T.object.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    selectedData: state.mobileListPicker.selected,
  }), {
    fetchList,
    resetResources,
    setSelectedData,
    selectItem,
    unselectItem,
    resetData,
  }
)(ShareActivityPortal);
