import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// utils
import cn from 'classnames';
import { calcSetPointValue } from 'utils';

const FormattedTemperature = ({ className, temperatureSystem, isUnitHidden, value }) => (
  <span className={cn({ [className]: !!className })}>
    {calcSetPointValue(value, temperatureSystem)}
    {!isUnitHidden && temperatureSystem === 'fahrenheit' && <>&#8457;</>}
    {!isUnitHidden && temperatureSystem === 'celsius' && <>&#8451;</>}
  </span>
);

FormattedTemperature.propTypes = {
  temperatureSystem: T.string.isRequired,
  value: T.oneOfType([T.string, T.number]),
  className: T.string,
  isUnitHidden: T.bool,
};

export default connect(
  (state) => ({
    temperatureSystem: state.auth.user.current_company.temperature_system,
  })
)(FormattedTemperature);
