import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import IntlProvider from './components/IntlProvider/IntlProvider';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { applyRouterMiddleware, browserHistory, Router } from 'react-router';
import useScroll from 'react-router-scroll/lib/useScroll';
import { getRoutes } from './routes';
import { isProduction, disableDoubleTapZoom } from './utils';
import { createAirbrakeClient } from './utils/airbrakeHelper';
import checkNewFrontendFiles from './utils/checkNewFrontendFiles';
import { API_URL, GA_CODE, GTM_ID } from './constants.js';
import ActionCable from 'actioncable';
import * as serviceWorker from './serviceWorker';
import { openDB } from './db/offline-db';
import './index.css';

disableDoubleTapZoom();

const store = configureStore();

if (GA_CODE) ReactGA.initialize(GA_CODE);
if (GTM_ID) TagManager.initialize({ gtmId: GTM_ID });

function onUpdateRoute() {
  if (GA_CODE) {
    const { pathname, search } = window.location;
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname + search);
  }
  if (isProduction && navigator.onLine) {
    checkNewFrontendFiles();
  }
}

global.actionCable = ActionCable.createConsumer(`${API_URL}/cable`);
global.airbrake = createAirbrakeClient();

serviceWorker.register();

openDB();

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider>
      <Router
        onUpdate={onUpdateRoute}
        history={browserHistory}
        routes={getRoutes(store)}
        render={applyRouterMiddleware(useScroll())}
      />
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);
