import React from 'react';
import T from 'prop-types';
// components
import Amchart from 'components/Amchart';
import ChartPlaceholder from 'components/Amchart/ChartPlaceholder';
import Preloader from 'components/Preloader';
// constants
import { defaultSerialChartConfig } from 'components/Amchart/chartConfig';
import { waterUsageBallonHtml }
  from 'components/Amchart/GraphItemBalloon/GraphItemBalloon';
import GraphLegend from 'components/GraphLegend/GraphLegend';
import classnames from 'classnames/bind';
import GrowthRate from 'components/GrowthRate/GrowthRate';
// translations
import { FormattedMessage, useIntl } from 'react-intl';
// styles
import styles from './WaterUsagePanel.module.scss';

const cn = classnames.bind(styles);

const amChartStyle = { height: '290px' };

const WaterUsagePanel = ({ isLoading, waterUsageData, diff, growth }) => {
  const { formatMessage } = useIntl();
  const serialChartConfig = {
    ...defaultSerialChartConfig,
    dataProvider: waterUsageData,
    categoryAxis: {
      ...defaultSerialChartConfig.categoryAxis,
      title: `${formatMessage({ id: 'general.day' }).toUpperCase()}`,
    },
    valueAxes: [{
      axisAlpha: 0,
      unit: '',
      gridColor: '#A3A3A3',
      gridAlpha: 0.3,
      color: '#A3A3A3',
      title: `${formatMessage({ id: 'component.dataTable.headers.totalGallonsPerHead' }).toUpperCase()}`,
    }],
    graphs: [
      {
        showBalloon: false,
        lineColor: '#ff5e31',
        lineThickness: 2,
        id: 'AmGraph-2',
        type: 'smoothedLine',
        valueField: 'average',
        bulletSize: 1,
        dashLength: 4,
      },
      {
        balloonText: waterUsageBallonHtml(formatMessage),
        balloonColor: '#A3A3A3',
        bullet: 'round',
        lineColor: '#0069a1',
        lineThickness: 3,
        id: 'AmGraph-1',
        title: formatMessage({ id: 'general.waterUsage' }),
        type: 'smoothedLine',
        valueField: 'gal_per_head',
        connect: false,
        bulletSize: 2,
        minBulletSize: 0,
      },
    ],
    listeners: [],
  };

  return (
    <div className={cn('WaterUsagePanel')}>
      <Preloader isActive={isLoading} />
      <div className={cn('header')}>
        <FormattedMessage id="general.waterUsage" />
        <div>
          <div className={cn('average-amount')}>
            <span className={cn('item-value')}> {diff || 'N/A'}</span>
            <span className={cn('grow-span')}>
              <GrowthRate className="ml-5" small value={Number(growth)} />
            </span>
          </div>
          <div className={cn('grows-label')}>
            <FormattedMessage id="general.antibiotic.growsLabelUsage.turn_to_date" />
          </div>
        </div>
      </div>
      <div className={cn('legend-block')}>
        <GraphLegend
          backgroundColor="#0069a1"
          text={<FormattedMessage id="general.antibiotic.pig_group_avg_g" />}
        />
        <GraphLegend
          backgroundColor="#ff5e31"
          text={<FormattedMessage id="general.averageGallonsPerHead" />}
        />
      </div>
      <div className={cn('chart-block')}>
        {!waterUsageData.length ?
          <ChartPlaceholder chartStyle={amChartStyle} />
          :
          <Amchart style={amChartStyle} config={serialChartConfig} />}
      </div>
    </div>
  );
};

WaterUsagePanel.propTypes = {
  diff: T.number,
  growth: T.number,
  isLoading: T.bool.isRequired,
  waterUsageData: T.array.isRequired,
};

export default WaterUsagePanel;
