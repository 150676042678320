import { useEffect, useState } from 'react';

export default function useCountries() {
  const [data, setData] = useState({ countries: [], isImported: false });

  useEffect(() => {
    import('static/countries_data.json')
      .then((data) => {
        setData({ isImported: true, countries: data.default });
      })
      .catch(() => {
        setData((prevState) => ({ ...prevState, isImported: true }));
      });
  }, []);

  return data;
}
