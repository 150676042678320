import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import Link from 'react-router/lib/Link';
import Button from 'components/Button';
import Avatar from 'components/Avatar/Avatar';
import TableFilter from 'components/TableFilter';
import { TenantStatusBadge } from 'components/StatusBadge/StatusBadge';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import EveryPigHeader from 'components/SuperAdmin/EveryPigHeader';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors, capitalize } from 'utils/responseErrorsHelper';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import sum from 'lodash.sum';
import cn from 'classnames';

class Tenants extends Component {

  componentDidMount() {
    const { fetchData, params } = this.props;
    fetchData(params).catch(toastResponseErrors);
  }

  visitTenant = (id) => () => {
    this.context.router.push(`/switch-company/${id}`);
  };

  deactivateTenant = (id) => () => {
    const { setDataItem } = this.props;
    fetchFromAPI(`/admin/tenants/${id}/disable`, { method: 'PUT' })
      .then(({ resource }) => {
        toastr.success('', 'Tenant deactivated!', { icon: <i className="fa fa-thumbs-up" /> });
        setDataItem(resource);
      })
      .catch(toastResponseErrors);
  };

  activateTenant = (id) => () => {
    const { setDataItem } = this.props;
    fetchFromAPI(`/admin/tenants/${id}/enable`, { method: 'PUT' })
      .then(({ resource }) => {
        toastr.success('', 'Tenant activated!', { icon: <i className="fa fa-thumbs-up" /> });
        setDataItem(resource);
      })
      .catch(toastResponseErrors);
  };

  formatter = (value, unit, suffix) => (
    unit === 'second'
      ? 'Just Now'
      : `${value}${unit[0]} ${capitalize(suffix)}`
  );

  renderCompanyNameColumn = ({ name }) => (
    <CustomColumn flexRow>
      <Avatar className="mr-10" type="company" avatarSize="35" iconSize="16" />
      <div className="semibold">{name}</div>
    </CustomColumn>
  );

  renderActiveUsersColumn = ({ active_users_count }) => (
    <CustomColumn label="Active Users">
      {active_users_count}
    </CustomColumn>
  );

  renderLastActivityColumn = ({ last_activity_at }) => (
    <CustomColumn textLeft label="Last Activity">
      {last_activity_at
        ? <TimeAgo date={last_activity_at} formatter={this.formatter} />
        : 'N/A'}
    </CustomColumn>
  );

  renderCustomerSinceColumn = ({ created_at }) => (
    <CustomColumn label="Customer Since">
      {moment(created_at).format('MMMM, YYYY')}
    </CustomColumn>
  );

  renderStatusColumn = ({ tenant_status }) => (
    <CustomColumn noBottomBorder label="Status">
      <TenantStatusBadge status={tenant_status} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id, tenant_status }, { rowIndex }) => {
    const dropdownData = [
      { label: 'Visit Tenant', onClick: this.visitTenant(id) },
      { label: 'Deactivate Tenant', onClick: this.deactivateTenant(id), hide: tenant_status === 'deactivated' },
      { label: 'Activate Tenant', onClick: this.activateTenant(id), hide: tenant_status !== 'deactivated' },
    ];
    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${id}-${rowIndex}`}
          wide
          buttonType="small light-gray"
          label="Edit"
          url={`/super-admin/tenants/${id}/edit`}
          customClass="show-for-large"
          dropDownData={dropdownData}
        />

        <Link to={`/super-admin/tenants/${id}/edit`} className="button light hide-for-large">Edit</Link>
        <Button lightGrey onClick={this.visitTenant(id)} className="hide-for-large">
          Visit
        </Button>
        {tenant_status !== 'deactivated' && (
          <Button darkGrey onClick={this.deactivateTenant(id)} className="hide-for-large">
            Deactivate
          </Button>
        )}
        {tenant_status === 'deactivated' && (
          <Button darkGrey onClick={this.activateTenant(id)} className="hide-for-large">
            Activate
          </Button>
        )}
      </div>
    );
  };

  renderExpandable = ({ id, tenant_status }) => (
    <div className="collapsable-buttons-row show-for-large">
      <Button small light onClick={this.visitTenant(id)}>
        Visit Tenant
      </Button>
      {tenant_status !== 'deactivated' && (
        <Button small light onClick={this.deactivateTenant(id)}>
          <i className="fa fa-times-circle mr-5" />Deactivate Tenant
        </Button>
      )}
      {tenant_status === 'deactivated' && (
        <Button small light onClick={this.activateTenant(id)}>
          <i className="fa fa-check-circle mr-5" />Activate Tenant
        </Button>
      )}
      <Link to={`/super-admin/tenants/${id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />Edit
      </Link>
    </div>
  );

  render() {
    const { resources, meta: { total, stats = {} }, params: { page, per_page, sort, search, status }, isLoading,
      onSortChange, onSearchChange, onPageChange, onPerPageChange, onStatusChange } = this.props;
    const { isTablet } = this.context;
    const statsSum = sum(Object.values(stats));

    const tableFilters = [
      { label: 'All Tenants', value: '', count: statsSum },
      { label: 'Active', value: 'active' },
      { label: 'Trial', value: 'trial' },
      { label: 'Trial Expired', value: 'trial_expired' },
      { label: 'Deactivated', value: 'deactivated' },
    ];

    const columns = [
      { label: 'Company', flex: '2 1 180px', sortKey: 'name', renderer: this.renderCompanyNameColumn },
      { label: 'Active Users', flex: '1 1 120px', sortKey: 'active_users_count',
        renderer: this.renderActiveUsersColumn },
      { label: 'Last Activity', flex: '1 1 120px', sortKey: 'last_activity_at',
        renderer: this.renderLastActivityColumn },
      { label: 'Customer Since', flex: '1 1 150px', sortKey: 'created_at', renderer: this.renderCustomerSinceColumn },
      { label: 'Status', flex: '1 1 130px', sortKey: 'tenant_status', renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };

    return (
      <div className="Tenants">
        <EveryPigHeader>
          <Link to="/super-admin/tenants/create" className="button primary wider show-for-large">
            Create Tenant
          </Link>
        </EveryPigHeader>

        <section className="small-12 column">
          <Panel>
            <Panel.Heading title="Tenants">
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>

            <Panel.Body noPadding>
              <TableFilter
                filters={tableFilters}
                activeFilter={status}
                onFilterChange={onStatusChange}
                stats={stats}
                className="ph-10"
              >
                <Link to="/super-admin/tenants/create" className="button small primary wider hide-for-large">
                  Create Tenant
                </Link>
              </TableFilter>

              <Preloader isActive={isLoading} />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderExpandable}
                tableKey="tenants"
              />

              <NothingBox
                itemsName="tenants"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
                filter={status}
              >
                <h1>You don&#39;t have any tenants yet</h1>
              </NothingBox>
            </Panel.Body>

          </Panel>
        </section>
      </div>
    );
  }
}

Tenants.contextTypes = {
  isTablet: T.bool.isRequired,
  router: T.object.isRequired,
};

Tenants.propTypes = {
  resources: T.array,
  params: T.object,
  meta: T.object,
  isLoading: T.bool,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.tenants.resources,
      params: state.dataTable.tenants.params,
      meta: state.dataTable.tenants.meta,
      isLoading: state.dataTable.tenants.isLoading,
    }), {
      fetchData: (data) => fetchData('/admin/tenants', 'tenants', data),
      setDataItem: (data) => setDataItem(data, 'tenants'),
    },
  ),
  withDataTableController(),
);

export default enhance(Tenants);
