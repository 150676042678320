import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Waypoint from 'react-waypoint';
import TimeAgo from 'react-timeago';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import Preloader from 'components/Preloader';
import TenantAssetIcon from './TenantAssetIcon';
// utils
import classnames from 'classnames/bind';
import { dateModifiedFormatter, getAssetShortType, getResourceName } from 'utils/tenantAssetsHelper';
// styles
import styles from './TenantAssetsTable.module.scss';

const cn = classnames.bind(styles);

class TenantAssetsTable extends Component {

  renderNameColumn = (asset) => {
    const { content_type, link, url = {}, is_viewed } = asset;
    const { onMarkItemAsSeen, onPreviewAsset } = this.props;
    const name = getResourceName(asset);
    return (
      <CustomColumn className={cn({ 'not-viewed': !is_viewed })} flexRow>
        <div className={cn('icon-col')}>
          <TenantAssetIcon contentType={content_type} hasImageThumb imageUrl={url.small} />
        </div>
        {link
          ? <a className={cn('asset-name')} href={link} rel="noopener noreferrer" target="_blank">{name}</a>
          : <div className={cn('asset-name')} onClick={() => onPreviewAsset(asset)}>{name}</div>}
        {!is_viewed && <Waypoint onEnter={onMarkItemAsSeen(asset)} />}
      </CustomColumn>
    );
  };

  renderFileTypeColumn = ({ content_type }) => (
    <CustomColumn>
      {getAssetShortType(content_type)}
    </CustomColumn>
  );

  renderDateModifiedColumn = ({ updated_at }) => (
    <CustomColumn>
      <TimeAgo date={updated_at} formatter={dateModifiedFormatter(updated_at)} />
    </CustomColumn>
  );

  renderActionsColumn = (asset) => {
    const { onOpenDropdown } = this.props;
    const { filename, link, id } = asset;
    return (
      <div className="collapsible-value button-column">
        <i
          data-name={`${filename || link}-${id}`}
          className={cn('fa fa-dots-three-horizontal', 'dropdown-icon')}
          onClick={onOpenDropdown(asset)}
        />
      </div>
    );
  };

  columns = [
    { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '1 1 160px',
      renderer: this.renderNameColumn, sortKey: 'asset_name' },
    { label: <FormattedMessage id="component.dataTable.headers.fileType" />, flex: '1 1 100px',
      renderer: this.renderFileTypeColumn, sortKey: 'content_type' },
    { label: <FormattedMessage id="component.dataTable.headers.dateModified" />, flex: '1 1 100px',
      renderer: this.renderDateModifiedColumn, sortKey: 'updated_at' },
    { label: '', flex: '0 0 45px', renderer: this.renderActionsColumn, fixed: true },
  ];

  render() {
    const { resources, isLoading, reqParams: { search, sort }, onSortChange, onLoadMore,
      hasMoreResources } = this.props;
    return (
      <div className={cn('tenant-assets-table', { 'all-assets-loaded': !hasMoreResources })}>
        <Panel className={cn('assets-table-panel', 'show-for-large')}>
          <Panel.Body noPadding className={cn('assets-table-body', { 'no-bottom-border': !!resources.length })}>
            <DataTable
              data={resources}
              columns={this.columns}
              sort={sort}
              onSortChange={onSortChange}
            />

            <NothingBox
              itemsName="tenant_resources"
              display={!resources.length && !isLoading}
              isLoading={isLoading}
              search={search}
            >
              <h2 className="lighter semibold">
                <FormattedMessage id="component.nothingBox.noResources" />
              </h2>
            </NothingBox>
          </Panel.Body>
        </Panel>
        {hasMoreResources && (
          <>
            {!isLoading && <Waypoint onEnter={onLoadMore} />}
            <Preloader isActive={isLoading} />
          </>
        )}
      </div>
    );
  }
}

TenantAssetsTable.propTypes = {
  resources: T.array.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  hasMoreResources: T.bool.isRequired,
  onSortChange: T.func.isRequired,
  onOpenDropdown: T.func.isRequired,
  onMarkItemAsSeen: T.func.isRequired,
  onLoadMore: T.func.isRequired,
  onPreviewAsset: T.func.isRequired,
};

export default TenantAssetsTable;
