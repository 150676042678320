import React from 'react';
import T from 'prop-types';
// components
import DateInput from 'components/DateInput/DateInput';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './BirhdateField.scss';
import moment from 'moment/moment';

const cn = classnames.bind(styles);

const BirhdateField = ({ input, label, meta: { touched, error }, className }) => {
  const validDate = (current) => {
    return current.isBefore(moment());
  };

  return (
    <div className={cn('BirhdateField', className, { 'has-error': touched && error })}>
      {label &&
        <span className={cn('bithday-input-label')}>{label}</span>}
      <DateInput
        clearable
        onValidDate={validDate}
        value={input.value}
        onChange={(date) => input.onChange(date)}
        onMobileChange={(value) => input.onChange(value)}
        onClear={() => { input.onChange(''); }}
        max={moment().format('YYYY-MM-DD')}
      />
    </div>
  );
};

BirhdateField.propTypes = {
  input: T.object.isRequired,
  label: T.oneOfType([T.string, T.object]),
  meta: T.object.isRequired,
  className: T.string,
};

export default BirhdateField;
