import React from 'react';
import T from 'prop-types';
// components
import Field from 'components/Field';
import { FormattedMessage } from 'react-intl';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
// utils
import cn from 'classnames';
import { farmTypes } from 'constants.js';

const FarmTypeField = ({ className, name }) => (
  <div className={cn('row', { [className]: !!className })}>
    <div className="small-12 column">
      <label>
        <Field
          label={<FormattedMessage id="general.farmTypeOptional" />}
          placeholderKey="general.chooseFarmType"
          name={name}
          component={SelectField}
          options={farmTypes}
          valueKey="value"
          labelKey="label"
        />
      </label>
    </div>
  </div>
);

FarmTypeField.propTypes = {
  name: T.string.isRequired,
  className: T.string,
};

export default FarmTypeField;
