import React, { useState } from 'react';
import T from 'prop-types';
// components
import SearchBox from 'components/SearchBox';
// utils
import classnames from 'classnames/bind';
import isFunction from 'lodash.isfunction';
import { sectionIcons } from 'utils/constants/userFilters';
// hocs
import withMenuPositionCheck from 'hoc/withMenuPositionCheck';
// styles
import styles from './FilterDropdownMenu.module.scss';

const cn = classnames.bind(styles);

function handleOptionClick(value, isDisabledOption, onClick) {
  return (e) => {
    if (isDisabledOption) {
      e.stopPropagation();
      return;
    }
    if (isFunction(onClick)) onClick(value);
  };
}

const FilterDropdownMenu = ({
  isOpened,
  hasSearch,
  currentValue,
  options,
  onOptionClick,
  disabledValues,
  menuNode,
}) => {
  const [search, onSearchChange] = useState('');
  const availableOptions = search
    ? options.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase()))
    : options;
  return (
    <ul ref={menuNode} className={cn('dropdown-list', { 'opened': isOpened })}>
      {hasSearch && (
        <div className={cn('item-search-box')} onClick={(e) => e.stopPropagation()}>
          <SearchBox
            className={cn('item-search')}
            placeholderKey="general.placeholder.searchFilters"
            search={search}
            onChange={onSearchChange}
          />
        </div>
      )}
      {availableOptions.map(({ value, label }, index) => {
        const isDisabledOption = disabledValues.includes(value);
        const icon = sectionIcons[value];
        return (
          <li
            key={index}
            onClick={handleOptionClick(value, isDisabledOption, onOptionClick)}
            className={cn('list-item', { 'active': currentValue === value, 'not-available': isDisabledOption })}
          >
            {icon && <i className={cn(icon, 'option-icon')} />}
            <div className={cn('option-label')}>{label}</div>
            {currentValue === value && <i className={cn('fa fa-check', 'selected-icon')} />}
          </li>
        );
      })}
    </ul>
  );
};

FilterDropdownMenu.propTypes = {
  isOpened: T.bool.isRequired,
  hasSearch: T.bool,
  currentValue: T.string,
  disabledValues: T.array,
  options: T.array.isRequired,
  onOptionClick: T.func,
  // hoc
  menuNode: T.object.isRequired,
};

FilterDropdownMenu.defaultProps = {
  disabledValues: [],
  hasSearch: false,
};

export default withMenuPositionCheck(FilterDropdownMenu);
