import React from 'react';
import T from 'prop-types';
// components
import CheckboxMenu from './CheckboxMenu';
import AsyncMultiSelect from 'components/AsyncMultiSelect';
// utils
import classnames from 'classnames/bind';
import pick from 'lodash.pick';
// styles
import styles from './Menu.module.scss';
// filter constants
import { isMultiItemsCondition } from 'utils/userFiltersHelper';
import { checkboxProps, multiEntityNameSections, userFilterSectionTypes } from 'utils/constants/userFilters';

const cn = classnames.bind(styles);

const filterOption = (selectedEntities) => () => (option) => {
  const isAlreadySelected = !!selectedEntities.find((entity) => entity.id === option.value);
  if (isAlreadySelected) return null;
  return option;
};

const MultiSelectMenu = ({
  type,
  currentValue,
  inputValue,
  onSelect,
  selectedEntity,
  entities,
  onRemove,
  ...others
}) =>  (
  <CheckboxMenu {...pick(others, checkboxProps)} currentValue={currentValue}>
    {(value) => (value === currentValue) && type === userFilterSectionTypes.dcDiagnoses
      && isMultiItemsCondition(value) && (
        <AsyncMultiSelect
          scrollable
          labelClassName={cn('multi-label')}
          filterOption={filterOption(entities)}
          className={cn('value-async-select', 'multi')}
          values={entities}
          onSelect={onSelect}
          optionsPath="/diagnoses"
          onRemove={onRemove}
          optionKeys={{ value: 'id', label: 'name' }}
          placeholderKey="general.searchDiagnosis"
          extractLabel={({ name }) => name}
        />
    )}
  </CheckboxMenu>
);

MultiSelectMenu.propTypes = {
  type: T.oneOf(multiEntityNameSections).isRequired,
  isOpened: T.bool.isRequired,
  currentValue: T.string,
  options: T.array.isRequired,
  onConditionChange: T.func.isRequired,
  inputValue: T.oneOfType([T.number, T.string]),
  selectedEntity: T.object,
  onSelect: T.func.isRequired,
  onApply: T.func.isRequired,
};

export default MultiSelectMenu;
