import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Amchart from 'components/Amchart/Amchart';
import StatisticsDropdown from 'components/StatisticsPanel/StatisticsDropdown';
import GrowthRate from 'components/GrowthRate';
import Preloader from 'components/Preloader';
import ChartPlaceholder from 'components/Amchart/ChartPlaceholder';
// helpers, constants
import { getPeriodAverage } from './chartHelpers';
import { sourceRangeOptions } from 'constants.js';
// utils
import moment from 'moment';
import sortBy from 'lodash.sortby';
import sumBy from 'lodash.sumby';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { avgMortalityRateBalloonHTML, closedGroupBalloon } from 'components/Amchart/GraphItemBalloon/GraphItemBalloon';
// sources api
import { getSourceCloseoutMortalityData } from 'endpoints/sources';
// styles
import './CloseoutMortalityChart.scss';

class CloseoutMortalityChart extends Component {

  state = {
    data: [],
    isLoading: false,
    rangeType: '12_months',
    prev_closeout_mortality: 0,
    closeout_mortality: 0,
  };

  componentDidMount() {
    const { rangeType } = this.state;
    this.getChartData(rangeType);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { rangeType } = this.state;
    if (this.props.farmType !== nextProps.farmType) {
      this.getChartData(rangeType, nextProps.farmType);
    }
  }

  calcCurrentMortality = (data) => {
    if (!data.length) return 0;
    return (sumBy(data, 'mortality_rate') / data.length).toFixed(2);
  };

  getChartData = (rangeType, farmType) => {
    const { sourceId } = this.props;
    const reqParams = { period: rangeType, farm_type: farmType !== undefined ? farmType : this.props.farmType };
    this.setState({ isLoading: true });

    getSourceCloseoutMortalityData(sourceId, reqParams)
      .then(({ resources, meta }) => {
        this.setState({
          rangeType,
          isLoading: false,
          data: this.prepareGraphData(resources, rangeType),
          closeout_mortality: this.calcCurrentMortality(resources),
          prev_closeout_mortality: meta.prev_avg_closeout_mortality || 0,
        });
      })
      .catch((response) => {
        this.setState({
          rangeType,
          isLoading: false,
          data: [],
        });
        toastResponseErrors(response);
      });
  };

  onRangeTypeChange = ({ value }) => {
    this.getChartData(value);
  };

  getAveragePeriod = (data) => {
    const durationMs = moment(data[data.length - 1].closed_on) - moment(data[0].closed_on);
    const durationDays = Math.floor(durationMs / (3600 * 24 * 1000));
    return durationDays > 120 ? 'month' : 'week';
  };

  prepareGraphData = (data) => {
    if (!data.length) return [];
    const period = this.getAveragePeriod(data);
    const periodsData = getPeriodAverage(data, period);
    const periodsDataPopulated = periodsData.map(({ avgValue, closed_on, ...other }, index, data) => {
      const delta = index ? (avgValue - data[index - 1].avgValue) : 0;
      return {
        closed_on,
        avgValue,
        delta: Math.abs(delta).toFixed(2) + '%',
        rateClass: ['positive', 'neutral', 'negative'][Math.sign(delta) + 1],
        iconClass: 'fa fa-' + ['arrow-down', 'arrow-no-change', 'arrow-up-btm'][Math.sign(delta) + 1],
        ...other,
      };
    });

    const sortedData = sortBy(data.concat(periodsDataPopulated), 'closed_on');

    return sortedData.map((item) => ({
      ...item,
      closed_on: moment(item.closed_on).format('MM/DD/YYYY'),
    }));
  };

  getChartConfig = () => {
    const { data } = this.state;
    const { formatMessage } = this.props;
    const mortalityRate = formatMessage({ id: 'general.mortalityRate' });

    return {
      type: 'serial',
      categoryField: 'closed_on',
      fontFamily: ['Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
      dataDateFormat: 'MM/DD/YYYY',
      cornerRadiusTop: '10%',
      categoryAxis: {
        title: formatMessage({ id: 'general.months' }).toUpperCase(),
        gridPosition: 'start',
        minPeriod: 'DD',
        parseDates: true,
        boldPeriodBeginning: false,
        color: '#8c8c8c',
        gridThickness: 0,
      },
      valueAxes: [{
        title: mortalityRate.toUpperCase(),
        position: 'left',
        unit: '%',
        color: '#8c8c8c',
        axisThickness: 0,
        tickLength: 0,
      }],
      chartCursor: {
        enabled: true,
        cursorColor: '#A3A3A3',
        categoryBalloonEnabled: false,
      },
      zoomOutText: '',
      trendLines: [],
      graphs: [{
        id: 'AmGraph-1',
        title: formatMessage({ id: 'general.groupCloseoutMR' }),
        balloonText: closedGroupBalloon,
        valueField: 'mortality_rate',
        type: 'line',
        lineThickness: 0,
        bullet: 'round',
        bulletAlpha: 0.5,
        bulletSize: 10,
      }, {
        id: 'AmGraph-2',
        title: formatMessage({ id: 'general.avgMR' }),
        balloonText: avgMortalityRateBalloonHTML(mortalityRate),
        valueField: 'avgValue',
        type: 'smoothedLine',
        lineThickness: 3,
        bullet: 'none',
        bulletSize: 8,
        bulletAlpha: 0.5,
        lineColor: '#DC3522',
      }],
      allLabels: [],
      balloon: {
        borderThickness: 0,
        horizontalPadding: 0,
        verticalPadding: 0,
        pointerWidth: 0,
        shadowAlpha: 0,
        fillAlpha: 0,
      },
      dataProvider: data,
    };
  };

  render() {
    const { rangeType, isLoading, data, prev_closeout_mortality, closeout_mortality } = this.state;
    const rangeLabel = (sourceRangeOptions.find(({ value }) => value === rangeType) || sourceRangeOptions[0]).label;
    const chartConfig = this.getChartConfig();
    const chartStyle = { height: '330px', minWidth: '330px' };
    const growthRate = Number((closeout_mortality - prev_closeout_mortality).toFixed(2));
    const emptyData = data.length === 0;

    return (
      <div className="CloseoutMortalityChart">
        <div className="chart-box">

          <div className="chart-box-header">
            <div className="header-left">
              <FormattedMessage id="general.closeoutMortality">
                {(text) => <h3 className="lighter">{text}</h3>}
              </FormattedMessage>
            </div>
            <div className="header-right">
              <div className="summary-data">
                <div className="data-block">
                  <span className="percent-text">{ closeout_mortality }%&nbsp;</span>
                  <GrowthRate value={growthRate} small />
                </div>
                <div className="current-range">
                  <FormattedMessage id="general.average" /> {rangeLabel}
                </div>
              </div>
            </div>
          </div>

          <div className="chart-box-body">
            <Preloader isActive={isLoading} />
            {emptyData
              ? <ChartPlaceholder chartStyle={chartStyle} />
              : <Amchart config={chartConfig} style={chartStyle} bindRef={this.bindRef} />}
          </div>

          <div className="chart-box-footer">
            <StatisticsDropdown
              options={sourceRangeOptions}
              onOptionChange={this.onRangeTypeChange}
              currentOption={rangeType}
            />
          </div>
        </div>
      </div>
    );
  }
}

CloseoutMortalityChart.propTypes = {
  sourceId: T.oneOfType([T.string, T.number]),
  farmType: T.string,
  formatMessage: T.func.isRequired,
};

export default CloseoutMortalityChart;
