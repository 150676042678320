import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Toggle.module.scss';

const cn = classnames.bind(styles);

const Toggle = ({
  name,
  isDisabled,
  handleChange,
  isChecked,
  className,
}) => {
  return (
    <label className={cn('switch', className, { isDisabled })}>
      <input
        name={name}
        className={cn('checkbox-input')}
        type="checkbox"
        onChange={!isDisabled ? handleChange : null}
        checked={isChecked}
        disabled={isDisabled}
      />
      <span className={cn('round-slider')} />
    </label>
  );
};

Toggle.propTypes = {
  name: T.string,
  isDisabled: T.bool,
  handleChange: T.func,
  isChecked: T.bool,
  className: T.string,
};

export default Toggle;
