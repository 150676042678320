import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ImportTypeSelector from 'components/CSVImport/ImportTypeSelector';
import CSVImportPage from './CSVImportPage';
// utils
import { getBackPathname } from 'utils/csvImportHelper';
// constants
import { oneObjectMode, multipleObjectsMode } from 'constants/csvImport';

const CSVImportSelectMode = ({ location }, { router }) => {
  const [{ selectedMode, selectedCards }, setState] = useState({
    selectedMode: oneObjectMode,
    selectedCards: [],
  });
  const { query } = location;
  const savedMode = [oneObjectMode, multipleObjectsMode].includes(query?.mode) ? query?.mode : '';
  const backPathName = getBackPathname(query?.from, query?.entityId);

  const getTitleImport = () => {
    if (!savedMode) return 'general.csvImport.title';
    if (selectedMode === multipleObjectsMode) {
      return 'general.csvImport.titleNextType.multi';
    }
    return 'general.csvImport.titleNextType.oneObject';
  };

  const onBackBtnClick = () => {
    if (!savedMode) {
      router.push(backPathName);
      return;
    }
    router.push({ ...location, query: { ...(query || {}), mode: '' } });
    setState((prevState) => ({
      ...prevState,
      selectedCards: [],
    }));
  };

  const onNextBtnClick = () => {
    if (!savedMode) {
      router.push({ ...location, query: { ...(query || {}), mode: selectedMode } });
      return;
    }
    router.push({
      pathname: '/csv-import/upload',
      query: { from: query.from, entityId: query?.entityId, cards: selectedCards.map(({ type }) => type) },
    });
  };

  const isNextDisabled = savedMode
    ? (savedMode === multipleObjectsMode && selectedCards.length <= 1)
    || (savedMode === oneObjectMode && selectedCards.length === 0)
    : false;

  return (
    <CSVImportPage
      activeStep={0}
      onBackBtnClick={onBackBtnClick}
      onNextBtnClick={onNextBtnClick}
      isNextDisabled={isNextDisabled}
      closePathName={backPathName}
    >
      <div className="bodyCSV">
        <div className="wrapperInfoBodyCSV">
          <FormattedMessage id={getTitleImport()}>
            {(title) => <div className="step-title">{title}</div>}
          </FormattedMessage>
          {!savedMode && (
            <>
              <FormattedMessage id="general.csvImport.infoText">
                {(desc) => <div className="step-description">{desc}</div>}
              </FormattedMessage>
              <FormattedMessage id="general.csvImport.optionsText" tagName="b" />
            </>
          )}
        </div>
        <div className="wrapperCardsCSV">
          <ImportTypeSelector
            savedMode={savedMode}
            selectedMode={selectedMode}
            selectedCards={selectedCards}
            setCards={(selectedCards) => setState((prevState) => ({ ...prevState, selectedCards }))}
            setMode={(selectedMode) => setState((prevState) => ({ ...prevState, selectedMode }))}
          />
        </div>
      </div>
    </CSVImportPage>
  );
};

CSVImportSelectMode.contextTypes = {
  router: T.object.isRequired,
};

CSVImportSelectMode.propTypes = {
  location: T.object.isRequired,
};

export default CSVImportSelectMode;
