// utils, constants
import round from 'lodash.round';
import { METERS_IN_MILE } from '../constants';

export const getCommitmentAddressEntity = (commitment) => {
  return commitment.farm || commitment.sow_unit || commitment.packing_plant;
};

export const getCommitmentDirection = (commitment) => ({
  source: 'out',
  destination: 'in',
})[commitment.commitment_type];

export function getAvgWeightForTransfer(commitment) {
  return commitment.loading.commitments.find(({ id }) => id === commitment.id)?.pig_group?.estimated_weight;
}

export function getAvgWeightForShipment(commitments) {
  // if transfer - we always have pig_group in source, if arrival - wa always haven't
  const sourcedCommitmentsWithGroup = commitments
    .filter(({ commitment_type, pig_group }) => commitment_type === 'source' && !!pig_group);
  const sourcedCommitmentsCount = sourcedCommitmentsWithGroup.length;
  if (sourcedCommitmentsCount === 0) return undefined;
  if (sourcedCommitmentsCount === 1) {
    return sourcedCommitmentsWithGroup[0].pig_group.estimated_weight;
  }
  const { totalPigs, totalWeight } = sourcedCommitmentsWithGroup.reduce((result, commitment) => ({
    totalPigs: result.totalPigs + commitment.head_count,
    totalWeight: result.totalWeight + (commitment.head_count * (commitment.pig_group.estimated_weight || 0)),
  }), { totalPigs: 0, totalWeight: 0 });
  return round(totalWeight / totalPigs, 2);
}

export function getAvgWeightBasedOnCommitment(commitment) {
  return commitment.loading.load_type === 'sale'
    ? getAvgWeightForTransfer(commitment)
    : getAvgWeightForShipment(commitment.loading.commitments);
}

export function getMigrationTypeBasedOnCommitment(commitment) {
  if (commitment.loading.load_type === 'sale') {
    return 'sale';
  }
  return commitment.commitment_type === 'source' ? 'transfer' : 'placement';
}

export function calculateCommitmentsTravelDistance(commitments = []) {
  if (!commitments.length) return 'N/A';
  const travelDistances = [];
  commitments.forEach(({ travel_distance }, index) => {
    if (index < commitments.length - 1) {
      travelDistances.push(travel_distance);
    }
  });
  if (travelDistances.some((value) => !value)) return 'N/A';
  const calculatedDistance = travelDistances
    .reduce((distance, nextDistance) => distance + nextDistance, 0) / METERS_IN_MILE;
  return `${round(calculatedDistance, 2)} miles`;
}

export function getCommitmentAddressType(commitment, loadType) {
  if (loadType === 'sale') {
    return commitment.commitment_type === 'source' ? 'sourceFarm' : 'packingPlant';
  }
  if (loadType === 'arrival') {
    return commitment.commitment_type === 'source' ? 'sowUnit' : 'destinationFarm';
  }
  // transfer
  return commitment.commitment_type === 'source' ? 'sourceFarm' : 'destinationFarm';
}
