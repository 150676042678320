import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
// utils
import cn from 'classnames';
// styles
import styles from './AsyncSelectOption.module.scss';

class AsyncSelectOption extends Component {
  state = {
    isLoading: false,
  }

  handleMouseDown = (event) => {
    const { onSelect, option } = this.props;
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  }

  handleMouseEnter = (event) => {
    const { onFocus, option } = this.props;
    onFocus(option, event);
  }

  handleMouseMove = (event) => {
    const { onFocus, option, isFocused } = this.props;
    if (isFocused) return;
    onFocus(option, event);
  };

  handleLoadMore = () => {
    const { option } = this.props;
    this.setState({ isLoading: true });
    option.onLoadMore();
  }

  render() {
    const { className, children, option } = this.props;
    const { isLoading } = this.state;

    if (option?.isLoadMoreOption) {
      return (
        <div className={cn(styles['load-more-option'], { [styles['is-loading']]: isLoading })}>
          {isLoading
            ? <Preloader className={styles.preloader} isActive />
            : (
              <span className={styles['load-more-label']} onClick={this.handleLoadMore}>
                <FormattedMessage id="general.loadMore" />
              </span>
            )
          }
        </div>
      );
    }
    return (
      <div
        className={className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
      >
        {children}
      </div>
    );
  }
}

AsyncSelectOption.propTypes = {
  children: T.node,
  className: T.string,
  // isDisabled: T.bool,
  isFocused: T.bool,
  // isSelected: T.bool,
  onFocus: T.func,
  onSelect: T.func,
  option: T.object.isRequired,
};

export default AsyncSelectOption;
