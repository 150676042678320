import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const getHoursAgo = (hoursAgo) => moment().subtract(hoursAgo, 'hours').startOf('hour');

export const timeOptions = [
  { value: '', label: <FormattedMessage id="general.timeOptions.anytime" /> },
  { value: getHoursAgo(1).format(), label: <FormattedMessage id="general.timeOptions.1h" /> },
  { value: getHoursAgo(2).format(), label: <FormattedMessage id="general.timeOptions.2h" /> },
  { value: getHoursAgo(4).format(), label: <FormattedMessage id="general.timeOptions.4h" /> },
  { value: getHoursAgo(8).format(), label: <FormattedMessage id="general.timeOptions.8h" /> },
  { value: getHoursAgo(24).format(), label: <FormattedMessage id="general.timeOptions.24h" /> },
  { value: getHoursAgo(48).format(), label: <FormattedMessage id="general.timeOptions.48h" /> },
];
