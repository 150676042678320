import React from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import FarmTypeLabel from 'components/FarmTypeLabel';
// utils
import classnames from 'classnames/bind';
import styles from './FarmTitleBox.module.scss';

const cn = classnames.bind(styles);

const FarmTitleBox = ({ name, type }) => (
  <div className={cn('farm-title-box')}>
    <Avatar type="farm" avatarSize="35" iconSize="16" />
    <div className="ml-10">
      <div>
        <strong className="mr-10">{name}</strong>
        <FarmTypeLabel farmType={type} />
      </div>
    </div>
  </div>
);

FarmTitleBox.propTypes = {
  name: T.string,
  type: T.string,
};

export default FarmTitleBox;
