import React, { Component } from 'react';
import { compose, withPropsOnChange, defaultProps } from 'recompose';
// components
import CheckinsMap from '../components/CheckinsMap/CheckinsMap';
import { connect } from 'react-redux';
// utils
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import pickBy from 'lodash.pickby';
import debounce from 'lodash.debounce';
import uniqueId from 'lodash.uniqueid';
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// const
import { farmTypes } from 'constants.js';


const formatClusterData = (farms, { usersGroupedByFarm }) => {
  const types = farmTypes.map(({ value }) => value);
  const farmsTypesCounts = types.reduce((res, type) => { res[type] = 0; return res; }, {});
  let checkinsCount = 0;

  farms.forEach(({ farm_type, id }) => {
    if (farm_type) farmsTypesCounts[farm_type]++;
    if (usersGroupedByFarm[id]) checkinsCount += usersGroupedByFarm[id].length;
  });

  const clusterType = types.reduce(
    (maxKey, curKey) => ((farmsTypesCounts[curKey] > farmsTypesCounts[maxKey]) ? curKey : maxKey)
  );

  return {
    type: clusterType,
    checkinsCount,
  };
};

const findMapCenter = ({ checkins, isLoaded, farms }) => {
  if (!isEmpty(checkins)) return { center: pick(checkins[0].farm, ['lat', 'lng']) };
  if (!isLoaded || isEmpty(farms)) return { center: null };
  const anyFarmWithCoords = farms.find(({ lat }) => !!lat);
  return { center: anyFarmWithCoords ? pick(anyFarmWithCoords, ['lat', 'lng']) : null };
};

const DecoratedMap = compose(
  connect(
    (state) => ({ farms: state.checkins.allFarms.data })
  ),
  defaultProps({
    formatClusterData,
    formatClusterDataOnChange: ['usersGroupedByFarm'],
    mouseListeners: ['Entity', 'EntityTooltip'],
  }),
  withPropsOnChange(
    ['checkins'],
    ({ checkins }) => {
      const checkinsWithValidFarm = checkins.filter(({ farm }) => !!farm.lat);
      return {
        checkins: checkinsWithValidFarm,
        usersGroupedByFarm: checkinsWithValidFarm.reduce((result, checkin) => {
          const farmId = checkin.farm.id;
          const newResult = result;
          const newUser = {
            ...checkin.user,
            total_check_ins_at_farm: checkin.total_check_ins_at_farm,
            checked_in: checkin.created_at,
          };
          if (!newResult[farmId]) {
            newResult[farmId] = [newUser];
          } else if (!newResult[farmId].some((user) => user.id === newUser.id)) {
            newResult[farmId] = newResult[farmId].concat(newUser);
          }
          return newResult;
        }, {}),
      };
    },
  ),
  withPropsOnChange(
    ['isLoaded', 'farms'],
    findMapCenter
  )
)(CheckinsMap);


export class UsersCheckinsMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      isSomeDataLoaded: false,
      params: {
        page: 1,
        per_page: 50,
        search_farm_or_user: '',
        sort: '',
        created_at: '',
      },
    };
    this.loadQueId = null;
    this._isMounted = false;
  }

  componentDidMount() {
    const { params } = this.state;
    this.fetchCheckins(params);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchCheckins = (params) => {
    const currentLoadQueID = uniqueId();
    this.loadQueId = currentLoadQueID;
    this.setState({ resources: [] });
    this.fetchCheckinsPage({ ...params, sort: 'created_at desc', page: 1 }, currentLoadQueID);
  };

  fetchCheckinsPage = (params, currentLoadQueID) => {
    return fetchFromAPI('/check_ins/users/check_ins', { params: pickBy(params) })
      .then((response) => {
        if (currentLoadQueID !== this.loadQueId) return;

        const newResources = this.state.resources.concat(response.resources);
        if (this._isMounted) {
          this.setState((prevState) => ({
            ...response,
            resources: prevState.resources.concat(response.resources),
            params,
            isSomeDataLoaded: true,
          }));
        }
        if (response.meta.total <= newResources.length) {
          this.setState({ isSomeDataLoaded: true });
        } else {
          this.fetchCheckinsPage({ ...params, page: params.page + 1 }, currentLoadQueID);
        }
      })
      .catch((response) => {
        toastResponseErrors(response);
        this.setState({ isSomeDataLoaded: true });
      });
  };

  onSubmitMapForm = debounce(({ search_farm_or_user, created_at }) => {
    const { params } = this.state;
    this.fetchCheckins({
      ...params,
      page: 1,
      search_farm_or_user,
      created_at,
    });
  }, 300);

  render() {
    const { resources, isSomeDataLoaded, params, params: { search_farm_or_user } } = this.state;
    return (
      <DecoratedMap
        search={search_farm_or_user}
        params={params}
        onFormChange={this.onSubmitMapForm}
        checkins={resources}
        isLoaded={isSomeDataLoaded}
      />
    );
  }
}

export default UsersCheckinsMap;
