import React from 'react';
import T from 'prop-types';
// components
import UserAvatar from 'components/UserAvatar';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import Popover from 'components/Popover';
import UserFarmfeedTooltip from 'components/EntityFarmfeedTooltip/UserFarmfeedTooltip';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './ActivityAuthorLine.module.scss';

const cn = classnames.bind(styles);

const ActivityAuthorLine = ({ activity, currentUser, minimized, children }) => {
  const { user, created_at, is_viewed, id } = activity;
  const isAdmin = currentUser.roles_map.admin;

  return (
    <div className={cn('activity-author-line', { minimized, isViewed: is_viewed })}>
      {!minimized && user && (
        <Popover
          content={<UserFarmfeedTooltip activityId={id} userId={user.id} isAdmin={isAdmin} />}
          placement="topLeft"
        >
          <UserAvatar user={user} size={40} />
        </Popover>
      )}
      <div className={cn('user-actions-desc')}>
        {children}
        <span className={cn('timeago')}>
          <TimeAgo date={created_at} />
        </span>
      </div>
    </div>
  );
};

ActivityAuthorLine.propTypes = {
  activity: T.object.isRequired,
  minimized: T.bool.isRequired,
  currentUser: T.object.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default ActivityAuthorLine;
