import React from 'react';
import T from 'prop-types';
// components
import Preloader from 'components/Preloader';
// utils
import conditionalRender from 'utils/renderIf';
import cn from 'classnames';
// styles
import './CenterBox.scss';

const CenterBox = ({ isLoading, children, className, renderIf = true }) => (
  <div className={cn('CenterBoxComponent', { [className]: !!className })}>
    <Preloader isActive={isLoading} />
    {conditionalRender(renderIf)(
      children
    )}
  </div>
);

CenterBox.propTypes = {
  children: T.any,
  isLoading: T.bool,
  renderIf: T.bool,
  className: T.string
};

export default CenterBox;
