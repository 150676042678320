import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/Button';
import StatisticSection from 'components/StatisticSection/StatisticSection';
import AntibioticsTable from 'components/AntibioticsTable/AntibioticsTable';
import AntibioticChart from 'components/AntibioticChart/AntibioticChart';
import BarnSheetsTreatmentsProfile from '../components/BarnSheetsTreatmentsProfile';
// endpoints
import { getPigGroupAntibioticStatistics, getPigGroupTreatmentsCSV } from 'endpoints/antibioticTracking';
// utils
import download from 'downloadjs';
import { antibioticGroupBalloonRender, formatStatValue, getGraphItemData,
  groupAntibioticChartColors } from 'utils/treatmentChartsHelper';
import {
  prepareGroupAntibioticCumulativeUsage,
  prepareGroupAntibioticUsageData,
} from 'utils/treatmentAntibioticHelper';
import { getFilterOptions } from '../utils/barnSheetsTreatments';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatGrams } from 'utils';

const statKeys = ['pig_group_avg_g', 'farm_avg_g', 'tenant_type_avg_g'];

const BarnSheetsGroupTreatmentsAntibiotic = ({
  currentUser,
  isGroupLoading,
  location,
  params,
  pigGroup,
}) => {
  const [{ stats, isLoading, usage: { data, cumulativeData } }, setState] = useState({
    stats: {},
    isLoading: false,
    usage: {},
  });
  const { formatMessage } = useIntl();
  const { roles_map: { admin } } = currentUser;
  const { id: groupId } = params;

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getPigGroupAntibioticStatistics(groupId)
      .then(({ meta: { stats }, resources }) => {
        const data = prepareGroupAntibioticUsageData(resources, statKeys);
        const cumulativeData = prepareGroupAntibioticCumulativeUsage(data, statKeys);
        setState({ isLoading: false, stats, usage: { data, cumulativeData } });
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        return toastResponseErrors(errors);
      });
  }, []);

  const downloadCSV = (e) => {
    e.stopPropagation();
    getPigGroupTreatmentsCSV(groupId)
      .then((blob) => download(blob, `group-${pigGroup.name}-antibiotic-usage`, 'text/csv'))
      .catch(toastResponseErrors);
  };

  const getGraphData = () => {
    const graphData = [];
    const labels = [];

    statKeys.forEach((statItem) => {
      const itemGraphData = getGraphItemData(statItem);
      const textKey = `general.${statItem === 'tenant_type_avg_g'
        ? 'systemWideAverage.all'
        : `antibiotic.${statItem}`}`;
      const label = { text: <FormattedMessage id={textKey} />, color: groupAntibioticChartColors[statItem] };
      itemGraphData.balloonFunction = (item) => (
        antibioticGroupBalloonRender(item, statKeys, formatMessage)
      );
      graphData.push(itemGraphData);
      labels.push(label);
    });

    return { graphData, labels };
  };

  const getGraphGrowsData = (stats) => {
    const groupAvg = formatStatValue(stats.pig_group_avg_g);
    const farmAvg = formatStatValue(stats.farm_avg_g);
    const grows = (groupAvg && farmAvg)
      ? (((Number(groupAvg) - Number(farmAvg)) / Number(groupAvg)) * 100).toFixed(2)
      : null;
    return {
      value: formatGrams(stats.pig_group_avg_g),
      grows,
      growsLabel: <FormattedMessage id="general.antibiotic.growsLabelUsage.turn_to_date"  />
    };
  };

  const farmType = pigGroup?.farm?.farm_type || 'all';
  const { graphData, labels } = getGraphData();

  const statsData = [
    { caption: <FormattedMessage id="general.titleWithLabel.averageGroup" values={{ name: pigGroup.name }} />,
      value: formatGrams(stats.pig_group_avg_g) },
    { caption: <FormattedMessage id="general.farmAverage" />, value: formatGrams(stats.farm_avg_g) },
    { caption: <FormattedMessage id={`general.systemWideAverage.${farmType}`} />,
      value: formatGrams(stats.tenant_type_avg_g) },
  ];

  const growsGraphData = getGraphGrowsData(stats);
  const categoryTitle = formatMessage({ id: 'general.weekOnFeed' }).toUpperCase();
  const filterOptions = getFilterOptions(`/barnsheets/groups/${groupId}/treatments`, admin);

  return (
    <BarnSheetsTreatmentsProfile
      pathname={location.pathname}
      filterOptions={filterOptions}
      buttonsContent={(
        <Button primary onClick={downloadCSV} disabled={isLoading || isGroupLoading}>
          <FormattedMessage id="general.button.download" />
        </Button>
      )}
    >
      <div className="BarnsheetsFarmTreatmentsAntibiotic">
        <StatisticSection data={statsData} isLoading={isLoading} className="mt-20" />

        <AntibioticChart
          isLoading={isLoading}
          data={data}
          graphData={graphData}
          labels={labels}
          categoryField="weeks_on_feed"
          categoryTitle={categoryTitle}
          growsData={growsGraphData}
          title={<FormattedMessage id="general.antibioticUsageTitle" />}
        />
        <AntibioticChart
          isLoading={isLoading}
          data={cumulativeData}
          graphData={graphData}
          labels={labels}
          categoryField="weeks_on_feed"
          categoryTitle={categoryTitle}
          growsData={growsGraphData}
          title={<FormattedMessage id="general.cumulativeAntibioticUsage" />}
        />

        <AntibioticsTable
          averageStatKey="total_sum_g"
          endpoint={`/antibiotic_tracking/pig_groups/${groupId}/treatments`}
          tableKey="groupAntibiotics"
        />
      </div>
    </BarnSheetsTreatmentsProfile>
  );
};

BarnSheetsGroupTreatmentsAntibiotic.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsGroupTreatmentsAntibiotic.propTypes = {
  currentUser: T.object.isRequired,
  params: T.object.isRequired,
  location: T.object.isRequired,
  pigGroup: T.object.isRequired,
  isGroupLoading: T.bool.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    pigGroup: state.barnsheets.groupCheckups.pigGroup,
    isGroupLoading: state.barnsheets.groupCheckups.isGroupLoading,
  })
)(BarnSheetsGroupTreatmentsAntibiotic);
