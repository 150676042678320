import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './GroupFeedPanel.module.scss';

const cn = classnames.bind(styles);

const classByType = {
  by_default: 'dark-gray',
  close: 'brand-orange',
  shipment_received: 'brand-blue',
};

const GroupFeedPanel = ({ headlineIcon, headlineTitle, type, className, children }) => (
  <div className={cn('group-feed-panel', className)}>
    {headlineTitle && (
      <div className={cn('headline', classByType[type])}>
        {headlineIcon}{headlineTitle}
      </div>
    )}
    <div className={cn('group-feed-body', { 'no-headline': !headlineTitle })}>
      {children}
    </div>
  </div>
);

GroupFeedPanel.defaultProps = {
  className: '',
  type: 'by_default',
  headlineIcon: <i className="fa fa-exclamation-triangle-bts" />,
};

GroupFeedPanel.propTypes = {
  headlineIcon: T.node,
  headlineTitle: T.node,
  type: T.string,
  className: T.string,
  children: T.any,
};

export default GroupFeedPanel;
