import { combineReducers } from 'redux';

import farmCheckins from './farmCheckins';
import singleCheckin from './singleCheckin';
import allFarms from './allFarms';
import mobileCheckin from './mobileCheckin';
import usersCheckins from './usersCheckins';

export default combineReducers({
  farmCheckins,
  singleCheckin,
  allFarms,
  mobileCheckin,
  usersCheckins,
});
