import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import LeftArrow from 'react-dates/lib/components/LeftArrow';
import RightArrow from 'react-dates/lib/components/RightArrow';
import ChevronDown from 'react-dates/lib/components/ChevronDown';
import ChevronUp from 'react-dates/lib/components/ChevronUp';
// utils
import moment from 'moment';
import cn from 'classnames';
// styles
import './MonthlyDatePickerController.scss';

const monthsKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

class MonthlyDatePickerController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentYear: moment(props.date).year(),
      selectedMonth: props.date ? moment(props.date).month() : null,
      selectedYear: props.date ? moment(props.date).year() : null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.mobileMode && this.props.date !== nextProps.date) {
      this.setState({
        selectedMonth: nextProps.date ? moment(nextProps.date).month() : null,
        selectedYear: nextProps.date ? moment(nextProps.date).year() : null,
      });
    }
  }

  onMonthSelect = (month) => () => {
    const { currentYear } = this.state;
    const { onChange } = this.props;
    this.setState({ selectedMonth: month, selectedYear: currentYear });
    onChange({ date: moment().year(currentYear).month(month) });
  };

  changeYear = (delta) => () => {
    this.setState(({ currentYear }) => ({ currentYear: currentYear + delta }));
  };

  isOutSideRange = (month) => {
    const { currentYear } = this.state;
    return moment().isBefore(moment().year(currentYear).month(month), 'month');
  };

  isSelected = (month) => {
    const { currentYear, selectedMonth, selectedYear } = this.state;
    return (currentYear === selectedYear) && (selectedMonth === month);
  };

  render() {
    const { currentYear } = this.state;
    const { orientation } = this.props;
    const showMobileNavigation = orientation === 'vertical';

    return (
      <div className="MonthlyPicker">
        <div className="nav-container">
          {!showMobileNavigation && (
            <div className="nav-button" onClick={this.changeYear(-1)}>
              <LeftArrow />
            </div>
          )}
          <div className="year">{currentYear}</div>
          {!showMobileNavigation && (
            <div className="nav-button" onClick={this.changeYear(1)}>
              <RightArrow />
            </div>
          )}
        </div>
        <div className="months-container">
          {monthsKeys.map((month, index) => (
            <div
              key={index}
              onClick={this.onMonthSelect(index)}
              className={cn('month', {
                selected: this.isSelected(index),
                blocked: this.isOutSideRange(index),
              })}
            >
              <FormattedMessage id={`general.months.${month}`} />
            </div>
          ))}
        </div>
        {showMobileNavigation && (
          <div className="mobile-nav-container">
            <div className="nav-button" onClick={this.changeYear(-1)}>
              <ChevronUp />
            </div>
            <div className="nav-button" onClick={this.changeYear(1)}>
              <ChevronDown />
            </div>
          </div>
        )}
      </div>
    );
  }
}

MonthlyDatePickerController.defaultProps = {
  orientation: 'horizontal',
};

MonthlyDatePickerController.propTypes = {
  onChange: T.func.isRequired,
  date: T.object,
  mobileMode: T.bool,
  orientation: T.string,
};

export default MonthlyDatePickerController;
