import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Collapse from 'components/Collapse/Collapse';
import Line from 'components/Line';
import Translation from 'components/Translation';
import OralTreatmentLabel from 'components/DailyCheckups/TreatmentProductLabels/OralTreatmentLabel';
import InjectableTreatmentLabel from 'components/DailyCheckups/TreatmentProductLabels/InjectableTreatmentLabel';
import CollapseTitle from './CollapseTitle';
// requests
import { getCheckupTreatments } from 'endpoints/dailyCheckups';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { filterMedsByType, renderMentionsText } from 'utils';
// styles
import styles from './SectionCollapse.module.scss';

const cn = classnames.bind(styles);

const MedicationsCollapse = ({ checkupId, className, titleClassName, showCheckup, comment, isEdited, itemsCount }) => {
  const [{ isLoading, isCollapseOpened, isLoaded, treatments }, setState] = useState({
    isLoaded: false,
    isLoading: false,
    isCollapseOpened: false,
    treatments: [],
  });

  if (!comment && !itemsCount) return null;

  const isTriggered = Boolean(comment);
  const { injectable, oral, topical } = filterMedsByType(treatments);

  const toggleCollapse = (isCollapseOpened) => {
    if (isLoaded) {
      setState((prevState) => ({ ...prevState, isCollapseOpened }));
      return;
    }

    setState((prevState) => ({ ...prevState, isCollapseOpened, isLoading: true }));
    getCheckupTreatments(checkupId)
      .then((treatments) => {
        setState((prevState) => ({ ...prevState, treatments, isLoaded: true, isLoading: false }));
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isCollapseOpened, isLoading: false }));
        toastResponseErrors(errors);
      });
  };

  return ((isTriggered || showCheckup) && (
    <Collapse
      className={cn(className, 'section-collapse', { 'highlighted': isTriggered && !isCollapseOpened })}
      titleClassName={titleClassName}
      title={(
        <CollapseTitle hasEditedBadge={isEdited} hasNotesBadge={isTriggered} hasTriggerIcon={isTriggered}>
          <FormattedMessage tagName="b" id="general.medicationsCount" values={{ count: itemsCount }} />
        </CollapseTitle>
      )}
      onToggle={toggleCollapse}
      isLoading={isLoading}
    >
      <Line
        almostWhite
        hasTopBorder
        bolded
        label={<FormattedMessage id="general.meds.injectable" />}
        value={<FormattedMessage id="general.meds.head" />}
      />
      {injectable.length
        ? injectable.map((item) => (
          <Line
            key={item.id}
            boldedValue
            label={<InjectableTreatmentLabel product={item.treatment_product} quantity={item.quantity} />}
            value={item.head_count}
          />
        ))
        : (
          <Line isEmptyLine>
            <FormattedMessage id="general.none" />
          </Line>
        )}

      <Line
        almostWhite
        bolded
        label={<FormattedMessage id="general.meds.oral" />}
        value={<FormattedMessage id="general.meds.head" />}
      />
      {oral.length
        ? oral.map((item) => (
          <Line
            key={item.id}
            boldedValue
            label={(
              <OralTreatmentLabel
                product={item.treatment_product}
                quantity={item.quantity}
                gallonsValue={item.gallons_value}
              />
            )}
            value={item.head_count}
          />
        ))
        : (
          <Line isEmptyLine>
            <FormattedMessage id="general.none" />
          </Line>
        )}

      <Line
        almostWhite
        bolded
        label={<FormattedMessage id="general.meds.topical" />}
        value={<FormattedMessage id="general.meds.head" />}
      />
      {topical.length
        ? topical.map((item) => (
          <Line
            key={item.id}
            boldedValue
            label={item.treatment_product.name}
            value={item.head_count}
          />
        ))
        : (
          <Line isEmptyLine>
            <FormattedMessage id="general.none" />
          </Line>
        )}

      {!!comment && (
        <>
          <Line almostWhite bolded>
            <FormattedMessage id="general.notes" />
          </Line>
          <Line lightYellow>
            <Translation text={comment}>{renderMentionsText(comment)}</Translation>
          </Line>
        </>
      )}
    </Collapse>
  ));
};

MedicationsCollapse.propTypes = {
  checkupId: T.number.isRequired,
  className: T.string,
  titleClassName: T.string,
  showCheckup: T.bool,
  isEdited: T.bool,
  comment: T.string,
  itemsCount: T.number,
};

export default MedicationsCollapse;
