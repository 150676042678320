import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCompanyProfile } from 'reducers/profiles';
// components
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import Subnavigation from 'components/Subnavigation';
import CompanyMiniProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyMiniProfileHeader';
import Panel from 'components/Panel';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import 'components/Admin/CompanyProfileBox.module.scss';

const labels = {
  companyInfo: <FormattedMessage id="general.companyInformation" />,
  plants: <FormattedMessage id="general.plants" />
};

class PackingCompanyProfile extends Component {

  constructor(props) {
    super(props);
    const companyId = props.params.id;
    this.navLinks = [
      { link: `/admin/packing-companies/${companyId}/packing-plants`, label: labels.plants },
      { link: `/admin/packing-companies/${companyId}/edit`, label: labels.companyInfo },
    ];
  }

  componentDidMount() {
    const { fetchCompanyProfile, params: { id } } = this.props;
    fetchCompanyProfile(`/admin/packing_companies/${id}`).catch(toastResponseErrors);
  }

  render() {
    const { children, company, isLoading } = this.props;

    return (
      <div className={cn('company-profile', 'clearfix')}>
        <CompanyProfileHeader
          avatarType="packing-company"
          hasLocation
          actualCompanyId={company.id}
          company={company}
          isLoading={isLoading}
          className="show-for-large"
        />

        <Subnavigation
          className="small-12 column show-for-large"
          links={this.navLinks}
          isSticky
          stickyContent={
            <CompanyMiniProfileHeader avatarType="packing-company" company={company} />
          }
        />

        <section className="small-12 column">
          <Panel>
            <Panel.Heading title={company.name} className="hide-for-large">
              <StatusBadge status={company.status} />
            </Panel.Heading>

            <Panel.Body noPadding className={cn('mobile-body')}>
              <Subnavigation links={this.navLinks} className="hide-for-large" inner />
              <div className={cn('mobile-body-box')}>
                {children}
              </div>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

PackingCompanyProfile.contextTypes = {
  router: T.object,
};

PackingCompanyProfile.propTypes = {
  fetchCompanyProfile: T.func.isRequired,
  params: T.object.isRequired,
  company: T.object.isRequired,
  isLoading: T.bool.isRequired,
  children: T.object.isRequired,
};

export default connect(
  (state) => ({
    company: state.profiles.company.data,
    isLoading: state.profiles.company.isLoading,
  }), {
    fetchCompanyProfile,
  }
)(PackingCompanyProfile);
