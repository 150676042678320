import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// components
import Button from 'components/Button';
// styles
import styles from './SyncNote.module.scss';

const cn = classnames.bind(styles);

const DefaultNoteComponent = (props) => <textarea {...props} />;

const SyncNote = ({
  className,
  isEdit: IsEditProp = false,
  setIsEdit: setIsEditProp,
  comment: commentProp = '',
  onCancel,
  component: Component = DefaultNoteComponent,
  componentProps = {},
  onSave,
  addLabel = <FormattedMessage id="general.notes.label" />,
  isSaveDisabled,
}) => {
  const [isEdit, setIsEdit] = useState(IsEditProp);
  const [comment, setComment] = useState(commentProp);

  useEffect(() => {
    if (commentProp) setComment(commentProp);
  }, [commentProp]);

  useEffect(() => {
    setIsEdit(IsEditProp);
  }, [IsEditProp]);

  const toggleEdit = (value) => {
    if (setIsEditProp) {
      setIsEditProp(value);
      return;
    }
    setIsEdit(value);
  };

  const onCancelClick = () => {
    if (onCancel) {
      onCancel();
      return;
    }
    setIsEdit(false);
    setComment(commentProp);
  };

  const onCommentChange = ({ target: { value, validity: { valid } } }) => {
    if (valid) setComment(value);
  };

  const onSaveClick = () => {
    setIsEdit(false);
    onSave(comment).catch(toastResponseErrors);
  };

  return (
    <div className={className}>
      <div className={cn('comment-box')}>
        {!!comment.length && !isEdit && (
          <div className={cn('display-text')} onClick={() => toggleEdit(true)}>
            {comment}
          </div>
        )}
        {!isEdit && !comment.length && (
          <div className={cn('add-note-btn')} onClick={() => toggleEdit(true)}>
            {addLabel}
          </div>
        )}
        {isEdit && (
          <Component
            autoFocus={!!comment.length}
            value={comment}
            onChange={onCommentChange}
            {...componentProps}
          />
        )}
      </div>
      {isEdit && (
        <div className={cn('buttons-line')}>
          <Button disabled={isSaveDisabled} primary className="mr-15" onClick={onSaveClick}>
            <FormattedMessage id="general.button.save" />
          </Button>
          <Button transparent onClick={onCancelClick}>
            <FormattedMessage id="general.button.cancel" />
          </Button>
        </div>
      )}
    </div>
  );
};

SyncNote.propTypes = {
  className: T.string,
  addLabel: T.oneOfType([T.string, T.node]),
  isEdit: T.bool,
  setIsEdit: T.func,
  component: T.func,
  comment: T.string,
  onCancel: T.func,
  onSave: T.func.isRequired,
  isSaveDisabled: T.bool,
  componentProps: T.object,
};

export default SyncNote;
