import React, { Component } from 'react';
import T from 'prop-types';
import { compose, withPropsOnChange, defaultProps } from 'recompose';
// components
import CheckinsMap from '../components/CheckinsMap/CheckinsMap';
import { connect } from 'react-redux';
// utils
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import pickBy from 'lodash.pickby';
import debounce from 'lodash.debounce';
import uniqueId from 'lodash.uniqueid';
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// const
import { farmTypes } from 'constants.js';

const defaultParams = {
  page: 1,
  per_page: 50,
};

const formatClusterData = (farms, { usersGroupedByFarm }) => {
  const types = farmTypes.map(({ value }) => value);
  const farmsTypesCounts = types.reduce((res, type) => { res[type] = 0; return res; }, {});
  let checkinsCount = 0;

  farms.forEach(({ farm_type, id }) => {
    if (farm_type) farmsTypesCounts[farm_type]++;
    if (usersGroupedByFarm[id]) checkinsCount += usersGroupedByFarm[id].length;
  });

  const clusterType = types.reduce(
    (maxKey, curKey) => ((farmsTypesCounts[curKey] > farmsTypesCounts[maxKey]) ? curKey : maxKey)
  );

  return {
    type: clusterType,
    checkinsCount,
  };
};

const findMapCenter = ({ checkins, isLoaded, farms }) => {
  if (!isEmpty(checkins)) return { center: pick(checkins[0].farm, ['lat', 'lng']) };
  if (!isLoaded || isEmpty(farms)) return { center: null };
  const anyFarmWithCoords = farms.find(({ lat }) => !!lat);
  return { center: anyFarmWithCoords ? pick(anyFarmWithCoords, ['lat', 'lng']) : null };
};

const DecoratedMap = compose(
  connect(
    (state) => ({ farms: state.checkins.allFarms.data })
  ),
  defaultProps({
    formatClusterData,
    formatClusterDataOnChange: ['usersGroupedByFarm'],
    mouseListeners: ['Entity', 'EntityTooltip'],
  }),
  withPropsOnChange(
    ['checkins', 'user'],
    ({ checkins, user = {} }) => {
      const checkinsWithValidFarm = checkins.filter(({ farm }) => !!farm.lat);
      return {
        checkins: checkinsWithValidFarm,
        usersGroupedByFarm: checkinsWithValidFarm.reduce((result, checkin) => {
          const farmId = checkin.farm.id;
          const newResult = result;
          if (!newResult[farmId]) {
            newResult[farmId] = [{
              ...user,
              total_check_ins_at_farm: checkin.total_check_ins_at_farm,
              checked_in: checkin.created_at,
            }];
          }
          return newResult;
        }, {}),
      };
    }
  ),
  withPropsOnChange(
    ['isLoaded', 'farms'],
    findMapCenter,
  )
)(CheckinsMap);

export class UserCheckinsMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      isSomeDataLoaded: false,
      params: {
        ...defaultParams,
        created_at: '',
        search_farm_or_user: '',
      },
    };
    this.loadQueId = null;
  }

  componentDidMount() {
    const { params }  = this.state;
    this.fetchCheckins(params);
  }

  fetchCheckins = (params) => {
    const currentLoadQueID = uniqueId();
    this.loadQueId = currentLoadQueID;
    this.setState({ resources: [] });
    this.fetchCheckinsPage({ ...params, sort: 'created_at desc', page: 1 }, currentLoadQueID);
  };

  fetchCheckinsPage = (params, currentLoadQueID) => {
    const { userId } = this.props;
    return fetchFromAPI(`/users/${userId}/map/check_ins`, { params: pickBy(params) })
      .then((response) => {
        if (currentLoadQueID !== this.loadQueId) return;
        const newResources = this.state.resources.concat(response.resources);
        this.setState((prevState) => ({
          ...response,
          resources: prevState.resources.concat(response.resources),
          params,
          isSomeDataLoaded: true,
        }));
        if (response.meta.total <= newResources.length) {
          this.setState({ isSomeDataLoaded: true });
        } else {
          this.fetchCheckinsPage({ ...params, page: params.page + 1 }, currentLoadQueID);
        }
      })
      .catch((response) => {
        toastResponseErrors(response);
        this.setState({ isSomeDataLoaded: true });
      });
  };

  onSubmitMapForm = debounce(({ search_farm_or_user, created_at }) => {
    const { params } = this.state;
    this.fetchCheckins({
      ...params,
      page: 1,
      search_farm_or_user,
      created_at,
    });
  }, 300);

  render() {
    const { user } = this.props;
    const { resources, isSomeDataLoaded, params, params: { search_farm_or_user } } = this.state;
    return (
      <DecoratedMap
        search={search_farm_or_user}
        params={params}
        onFormChange={this.onSubmitMapForm}
        checkins={resources}
        isLoaded={isSomeDataLoaded}
        user={user}
      />
    );
  }
}

UserCheckinsMap.propTypes = {
  user: T.object,
  userId: T.oneOfType([T.string, T.number]).isRequired,
};

export default UserCheckinsMap;
