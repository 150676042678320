import React from 'react';
import T from 'prop-types';
// components
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';

const DatePickerField = ({
  input,
  label,
  meta: { touched, error },
  className,
  ...others
}) => (
  <div className={className + (touched && error ? 'has-error' : '')}>
    <div className="row">
      <div className="small-12 medium-3 column label-wrapper">{label}</div>
      <div className="small-12 medium-9 column input-wrapper">
        <SingleDatePicker
          {...input}
          {...others}
          date={input.value || null}
          onDateChange={input.onChange}
          inputLabel="MM/DD/YYYY"
        />
      </div>
    </div>
  </div>
);

DatePickerField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]).isRequired,
  className: T.string,
};

export default DatePickerField;
