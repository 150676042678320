/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import T from 'prop-types';
// components
import MatchColumnItem from './MatchColumnItem.js';
// utils
import compact from 'lodash.compact';
import differenceWith from 'lodash.differencewith';
import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import isEmpty from 'lodash.isempty';
// translations
import { FormattedMessage } from 'react-intl';
// styles
import './MatchGroupColumns.scss';

class MatchGroupColumns extends Component {

  state = {
    parsedColumns: [],
    selectedOptions: [],
    allMatchParsedColumn: [],
    editColumns: [],
  };

  componentDidMount() {
    const { parsedData, mappingColumns, getCountSkipCards } = this.props;
    let parsedColumns = Object.keys(parsedData[0]).map((columnName, index) => ({ columnName, index }));
    const autoMatchParsedColumns = [];
    const allMatchParsedColumn = [];
    const autoMatchColumns = parsedColumns.map(({ columnName, index }) => {
      const compareName = columnName.toLowerCase().trim();
      const mappedValue = mappingColumns[compareName];
      if (mappedValue) {
        autoMatchParsedColumns.push({ columnName, index });
        allMatchParsedColumn.push({ autoMatch: true, label: columnName, value: mappingColumns[compareName], index });
        return { autoMatch: true, label: columnName, value: mappingColumns[compareName], index };
      }
      allMatchParsedColumn.push({ autoMatch: false, label: columnName, value: mappingColumns[compareName], index });
      return false;
    });


    // sort automatch columns to the beginning
    if (autoMatchParsedColumns.length) {
      parsedColumns = autoMatchParsedColumns.concat(differenceWith(parsedColumns, autoMatchParsedColumns, isEqual));
    }
    const selectedOptions = compact(autoMatchColumns).map((item) => {
      const column = parsedColumns.find(({ columnName }) => columnName === item.label);
      return { ...item, orderIndex: parsedColumns.indexOf(column) };
    });

    if (getCountSkipCards) this.getCountNotAutoMatched(false, allMatchParsedColumn);

    return this.setData(parsedColumns, selectedOptions, allMatchParsedColumn);
  }

  componentDidUpdate(prevProps, prevState) {
    const { getCardStatusesStats, saveAllAutoMatched, skipAllUnMatched } = this.props;
    const { selectedOptions, parsedColumns } = this.state;
    if (getCardStatusesStats && this.state !== prevState) {
      getCardStatusesStats({
        unmatchedCount: parsedColumns.length - selectedOptions.length,
        canBeSaved: selectedOptions.filter(({ autoMatch, matched, value }) => autoMatch && !matched && !!value).length,
      });
    }
    if (skipAllUnMatched && selectedOptions.length !== parsedColumns.length) {
      this.setState((prevState) => ({
        ...prevState,
        selectedOptions: parsedColumns.map(({ columnName, index }) => {
          const elem = selectedOptions.find(({ label }) => label === columnName);
          if (elem) return elem;
          return { label: columnName, value: columnName, index, orderIndex: index, skipped: true };
        }),
      }), this.checkDisableButton);
    }
    if (saveAllAutoMatched
      && !!selectedOptions.filter(({ autoMatch, matched, value }) => autoMatch && !matched && !!value).length
    ) {
      this.setState((prevState) => ({
        ...prevState,
        selectedOptions: prevState.selectedOptions.map((item) => (
          item.autoMatch && !item.matched && !!item.value ? { ...item, skipped: false, matched: true } : item
        )),
      }), this.checkDisableButton);
    }
  }

  checkAmountOfUnmatchedColumns = (parsedColumns, selectedOptions) => {
    const matchedColumns = selectedOptions.filter(({ matched }) => matched);
    return parsedColumns.length - matchedColumns.length;
  };

  setData = (parsedColumns, selectedOptions, allMatchParsedColumn) => {
    this.setState({
      parsedColumns,
      selectedOptions,
      allMatchParsedColumn,
    });
  };

  handleChange = (index, columnName, autoMatch) => (option) => {
    const { selectedOptions } = this.state;
    const selected = selectedOptions.find(({ label }) => label === columnName);

    if (selected) {
      const ind = selectedOptions.indexOf(selected);
      const arr = [
        ...selectedOptions.slice(0, ind),
        { ...option, label: columnName, index, autoMatch },
        ...selectedOptions.slice(ind + 1),
      ];
      this.setState({ selectedOptions: arr });
    } else {
      this.setState((prevState) => ({
        selectedOptions: [...prevState.selectedOptions, { ...option, label: columnName, index, autoMatch }],
      }));
    }
  };

  validateColumns = (matchedColumnValues) => {
    const { requiredColumns } = this.props;
    const requiredMatchedColumns = compact(requiredColumns.map((col) => matchedColumnValues.includes(col)));
    return requiredMatchedColumns.length === requiredColumns.length;
  };

  checkDisableButton = () => {
    const { selectedOptions, parsedColumns, editColumns } = this.state;
    const { matched, setMatchData, matchColumns } = this.props;
    const matchedColumns = selectedOptions.filter(({ matched }) => !!matched);
    const applyColumns = selectedOptions.filter(({ matched, skipped }) => (matched || skipped));
    if (applyColumns.length === parsedColumns.length && !editColumns.length) {
      const matchedColumnValues = matchedColumns.map((column) => column.value);
      const isValidToSave = this.validateColumns(matchedColumnValues);
      if (isValidToSave) {
        const data = {};
        selectedOptions.filter(({ value }) => value).map(({ value, index }) => {
          if (matchColumns.includes(value)) data[value] = index;
          return false;
        });
        setMatchData(data);
      } else if (!isEmpty(matched)) {
        setMatchData({});
      }
    } else if (!isEmpty(matched)) {
      setMatchData({});
    }
  };

  matchAction = (columnName, orderIndex, autoMatch) => () => {
    const { selectedOptions } = this.state;
    const selected = selectedOptions.find(({ label }) => label === columnName);
    const index = selectedOptions.indexOf(selected);
    const newOptions = [
      ...selectedOptions.slice(0, index),
      { ...selected, skipped: false, matched: true, orderIndex },
      ...selectedOptions.slice(index + 1),
    ];

    const newEditColumns = this.checkNextEditColumn(columnName, autoMatch);

    this.setState({ selectedOptions: newOptions, editColumns: newEditColumns }, this.checkDisableButton);
  };

  checkNextEditColumn = (columnName, autoMatch) => {
    const { selectedOptions, editColumns, parsedColumns } = this.state;

    const newEditColumns = editColumns.filter((item) => item !== columnName);
    const autoMatchColumns = selectedOptions.filter(({ autoMatch }) => autoMatch === true);
    let autoMatchDataLength = autoMatchColumns.filter(({ matched, skipped }) => (matched || skipped)).length;
    autoMatchDataLength = autoMatch ? autoMatchDataLength + 1 : autoMatchDataLength;
    if (autoMatchDataLength === autoMatchColumns.length) {
      const ind = this.getAllowMatchInd();
      const column = parsedColumns[autoMatch ? ind : ind + 1];
      if (column && column.columnName !== columnName) {
        const columnInOptions = selectedOptions.find(({ label }) => label === column.columnName);
        if (columnInOptions) {
          if (!columnInOptions.skipped && !columnInOptions.matched) newEditColumns.push(column.columnName);
        } else {
          newEditColumns.push(column.columnName);
        }
      }
    }

    return newEditColumns;
  };

   getCountNotAutoMatched = (isAutoMatched, selectedOptions) => {
     const { getCountSkipCards } = this.props;
     if (!getCountSkipCards) return;
     const autoMatchColumns = selectedOptions.filter(
       ({ autoMatch, skipped }) => autoMatch === isAutoMatched && !skipped
     );
     getCountSkipCards(autoMatchColumns);
   };

  skipAction = (columnName, index, autoMatch, orderIndex) => () => {
    const { selectedOptions, allMatchParsedColumn } = this.state;
    const selected = selectedOptions.find(({ label }) => label === columnName);
    const newEditColumns = this.checkNextEditColumn(columnName, autoMatch);

    const allMatchColumn = allMatchParsedColumn.map((item) => {
      if (item.ColumnTable === index) return ({ ...item, skipped: true });
      return  item;

    });

    if (selected) {
      const ind = selectedOptions.indexOf(selected);
      const arr = [
        ...selectedOptions.slice(0, ind),
        { ...omit(selected, ['value']), matched: false, skipped: true, orderIndex },
        ...selectedOptions.slice(ind + 1),
      ];

      this.setState({ selectedOptions: arr, editColumns: newEditColumns, newEditColumns }, this.checkDisableButton);
    } else {
      this.setState((prevState) => ({
        selectedOptions: [...prevState.selectedOptions, { label: columnName, index, autoMatch, matched: false,
          skipped: true, orderIndex }],
        editColumns: newEditColumns,
        allMatchParsedColumn: allMatchColumn,
      }), this.checkDisableButton);
    }
    this.getCountNotAutoMatched(false, allMatchColumn);
  };

  editAction = (columnName) => () => {
    const { allMatchParsedColumn } = this.state;
    const allMatchColumn = allMatchParsedColumn.map((item) => {
      if (item.label === columnName) return { ...item, skipped: false };
      return  item;
    });
    this.setState((prevState) => ({
      editColumns: [...prevState.editColumns, columnName],
      selectedOptions: prevState.selectedOptions.map((item) => ({
        ...item,
        matched: item.label === columnName ? false : item.matched,
        skipped: item.label === columnName ? false : item.skipped,
      })),
      allMatchParsedColumn: allMatchColumn,
    }), this.checkDisableButton);
  };

  getAllowMatchInd = () => {
    const { selectedOptions } = this.state;
    let orderIndexes = selectedOptions.map(({ orderIndex }) => orderIndex);
    orderIndexes = orderIndexes.sort();

    let res = -1;
    const ind = orderIndexes.reduce((prevValue, currentItem) => {
      if (currentItem - prevValue <= 1) {
        return currentItem;
      } if (res === -1) {
        res = prevValue + 1;
      }
      return currentItem;
    }, orderIndexes[0] || -1);

    return res !== -1 ? res : ind + 1;
  };

  render() {
    const { parsedData, options, customTitle } = this.props;
    const { parsedColumns, selectedOptions, editColumns } = this.state;
    const amountOfUnmatchedColumns = this.checkAmountOfUnmatchedColumns(parsedColumns, selectedOptions);

    return (
      <div className="MatchGroupColumns">

        {!customTitle && <div className="text"><FormattedMessage id="general.letsMatch" /></div>}
        <div className="columns-count-status text">
          {customTitle || `${amountOfUnmatchedColumns}${<FormattedMessage id="general.unmatchedColumns" />}`}
        </div>

        <div className="content-block-items">
          {parsedColumns.map(({ columnName, index }, orderIndex) => {
            const foundOption = selectedOptions.find(({ label }) => label === columnName);
            const isAutoMatch = foundOption?.autoMatch || false;
            return (
              <MatchColumnItem
                isEdit={editColumns.includes(columnName)}
                onEditAction={this.editAction(columnName)}
                key={index}
                selectedOptions={selectedOptions}
                orderIndex={orderIndex}
                data={parsedData.map((item) => item[columnName])}
                columnName={columnName}
                isAutoMatch={isAutoMatch}
                onMatchAction={this.matchAction(columnName, orderIndex, isAutoMatch)}
                onSkipAction={this.skipAction(columnName, index, isAutoMatch, orderIndex)}
                onChange={this.handleChange(index, columnName, isAutoMatch)}
                selected={foundOption?.value || ''}
                options={options}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

MatchGroupColumns.propTypes = {
  customTitle: T.oneOfType([T.node, T.string]),
  parsedData: T.array.isRequired,
  matched: T.object,
  setMatchData: T.func,
  requiredColumns: T.array,
  matchColumns: T.array,
  mappingColumns: T.object.isRequired,
  options: T.array,
  getCountSkipCards: T.func,
  getCardStatusesStats: T.func,
  saveAllAutoMatched: T.bool,
  skipAllUnMatched: T.bool,
};

export default MatchGroupColumns;
