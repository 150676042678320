import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
import { fetchCompanyProfile } from 'reducers/profiles';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import RolesLabel from 'components/RolesLabel/RolesLabel';
import UserAvatar from 'components/UserAvatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// utils
import isEmpty from 'lodash.isempty';
import sum from 'lodash.sum';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

const labels = {
  editUser: <FormattedMessage id="general.button.editUser" />,
  revokeAccess: <FormattedMessage id="general.button.revokeAccess" />,
  dataTable: {
    status: <FormattedMessage id="component.dataTable.headers.status" />,
    name: <FormattedMessage id="component.dataTable.headers.name" />,
    roles: <FormattedMessage id="component.dataTable.headers.roles" />,
  },
  roles: {
    allRoles: <FormattedMessage id="general.allRoles" />,
    primary: <FormattedMessage id="general.role.primary" />,
    farmManager: <FormattedMessage id="general.role.farmManager" />,
    manager: <FormattedMessage id="general.role.manager" />,
    caregiver: <FormattedMessage id="general.role.caregiver" />,
    common: <FormattedMessage id="general.role.common" />,
    veterinarian: <FormattedMessage id="general.role.veterinarian" />,
  },
};

class CompanyUsersRoles extends  Component {

  state = {
    isRoleRemoving: false,
  };

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  revokeRole = ({ id, is_owner, is_manager, is_caretaker, is_common, is_vet }) => {
    const { authUser: { id: userId } } = this.props;
    let name;
    if (is_owner) name = 'owner';
    if (is_manager) name = 'manager';
    if (is_caretaker) name = 'caretaker';
    if (is_common) name = 'common_user';
    if (is_vet) name = 'vet';
    const paramsData = { id, name, company_id: this.props.id };

    if (name === 'common_user' && id === userId) {
      showToastrMessage(
        'component.toastr.currentCompany.cantRevokeRole',
        'warning',
        'fa fa-exclamation-triangle-bts'
      );
      return;
    }

    this.removeRoleAction(paramsData);
  };

  removeRoleAction = (data) => {
    const { fetchData, reqParams, id, fetchCompanyProfile } = this.props;
    this.setState({ isRoleRemoving: true });
    fetchFromAPI(`/admin/companies/${id}/user_roles`, { method: 'delete', body: JSON.stringify(data) })
      .then(() => {
        this.setState({ isRoleRemoving: false });
        fetchData(reqParams).then(() => {
          if (data.name === 'owner') fetchCompanyProfile();
        });
        showToastrMessage('component.toastr.role.revoked');
      })
      .catch((response) => {
        this.setState({ isRoleRemoving: false });
        toastResponseErrors(response);
      });
  };

  renderNameColumn = (user) => (
    <CustomColumn flexRow>
      <UserAvatar user={user} size="35" className="mr-10" />
      <div className="semibold">{user.full_name}</div>
    </CustomColumn>
  );

  renderRoleColumn = ({ is_owner, is_manager, is_caretaker, is_common, is_vet }) => (
    <CustomColumn flexRow>
      <FormattedMessage id="component.dataTable.headers.roles">
        {(text) => (<span className="hide-for-large semibold">{text}&nbsp;•&nbsp;</span>)}
      </FormattedMessage>
      <RolesLabel
        roles={[
          { name: labels.roles.primary, value: is_owner, shortcut: 'o' },
          { name: labels.roles.farmManager, value: is_manager, shortcut: 'm' },
          { name: labels.roles.caregiver, value: is_caretaker, shortcut: 'c' },
          { name: labels.roles.common, value: is_common, shortcut: 'u' },
          { name: labels.roles.veterinarian, value: is_vet, shortcut: 'v' },
        ]}
      />
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.status}>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderActionsColumn = (user, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${user.id}-${rowIndex}`}
        wide
        label={labels.revokeAccess}
        buttonType="small light-gray"
        onClick={() => this.revokeRole(user)}
        dropDownData={[
          { label: labels.editUser, url: `/admin/users/${user.id}/edit` },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/users/${user.id}/edit`} className="button light hide-for-large">
        {labels.editUser}
      </Link>
      <Button default onClick={() => this.revokeRole(user)} className="hide-for-large">
        {labels.revokeAccess}
      </Button>
    </div>
  );

  renderExpandable = (user) => (
    <div>
      <Link to={`/admin/users/${user.id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        {labels.editUser}
      </Link>
      <Button small light onClick={() => this.revokeRole(user)}>
        <i className="fa fa-times-circle mr-5" />
        {labels.revokeAccess}
      </Button>
    </div>
  );

  render() {
    const {
      resources, meta: { stats = {}, total }, isLoading, reqParams: { page, per_page, role, search, sort },
      onSearchChange, onPageChange, onPerPageChange, onSortChange, route: { type }, id
    } = this.props;
    const { isTablet } = this.context;

    // check, if current page is CompanyUserRoles then link to My Company , else - Other Companies
    const assignUserRolesLink = type === 'companyUserRoles' ?
      `/admin/companies/${id}/assign-user-roles` :
      '/admin/my-company/assign-user-roles';

    const statsSum = sum(Object.values(stats));
    const tableFilters = [
      { label: labels.roles.allRoles, value: '', count: statsSum },
      { label: labels.roles.primary, value: 'owner' },
      { label: labels.roles.caregiver, value: 'caretaker' },
      { label: labels.roles.manager, value: 'manager' },
      { label: labels.roles.veterinarian, value: 'vet' },
      { label: labels.roles.common, value: 'common_user' },
    ];

    const isEmptyTable = !resources.length && !role && !search;

    const columns = [
      { label: labels.dataTable.name, flex: '2 1 200px', sortKey: 'first_name',
        renderer: this.renderNameColumn },
      { label: labels.dataTable.roles, flex: '1 1 120px', sortKey: 'role',
        renderer: this.renderRoleColumn },
      { label: labels.dataTable.status, flex: '1 1 120px',
        renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 190px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };
    const { isRoleRemoving } = this.state;

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.userRoles" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              onFilterChange={this.onFilterChange}
              stats={stats}
              activeFilter={role}
              className="ph-10"
            >
              {!isEmpty(resources) && (
                <Link to={assignUserRolesLink} className="button small primary wider">
                  <FormattedMessage id="general.manageUsers" />
                </Link>
              )}
            </TableFilter>
          )}

          <Preloader isActive={isLoading || isRoleRemoving} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey={type}
            rowKey="role_id" // todo: id is not unique for roles table now
          />
          <NothingBox
            itemsName="users_roles"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={role === 'owner' ? 'primary_contact' : role}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noUserRoles" />
            <Link to={assignUserRolesLink} className="button primary mt-10">
              <FormattedMessage id="general.assignUserRoles" />
            </Link>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}


CompanyUsersRoles.propTypes = {
  fetchCompanyProfile: T.func.isRequired,
  authUser: T.object.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  id: T.number.isRequired,
  route: T.object.isRequired
};

CompanyUsersRoles.contextTypes = {
  isTablet: T.bool.isRequired,
};


const enhance = compose(
  connect((state, { params: { id }, route: { type } }) => ({
    resources: state.dataTable[type].resources,
    reqParams: state.dataTable[type].params,
    meta: state.dataTable[type].meta,
    isLoading: state.dataTable[type].isLoading,
    authUser: state.auth.user,
    id: id ? +id : state.auth.user.current_company.id,
  }), {
    fetchData,
    fetchCompanyProfile,
  }),
  withProps(({ fetchData, fetchCompanyProfile, id, route: { type } }) => ({
    fetchData: (query) => fetchData(`/admin/companies/${id}/user_roles/profile_index`, type, query),
    fetchCompanyProfile: () => fetchCompanyProfile(`/admin/companies/${id}`),
  })),
  withDataTableController('fetchData', 'reqParams'),
);


export default enhance(CompanyUsersRoles);
