import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// assets
import './MessageBox.scss';

const icons = {
  info: <i className="fa fa-info-circle" />,
  warning: <i className="fa fa-warning" />,
  alert: <i className="fa fa-warning" />,
  success: <i className="fa fa-check" />,
  default: <i className="fa fa-info-circle" />,
};

const MessageBox = ({ children, type, className, icon, isBorderHidden }) => (
  <div className={cn(`MessageBox type-${type} ${className}`, { 'no-border': isBorderHidden })}>
    <div className="MessageBox__icon">
      { icon || icons[type] }
    </div>
    <div className="MessageBox__message">
      { children }
    </div>
  </div>
);

MessageBox.defaultProps = {
  type: 'info',
  className: '',
};

MessageBox.propTypes = {
  type: T.string,
  isBorderHidden: T.bool,
  className: T.string,
  icon: T.node,
  children: T.any,
};

export default MessageBox;
