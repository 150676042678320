import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import Subnavigation from 'components/Subnavigation';

const navLinks = [
  { link: '/admin/companies', label: <FormattedMessage id="general.managementCompanies" /> },
  { link: '/admin/trucking-companies', label: <FormattedMessage id="general.truckingCompanies" /> },
  { link: '/admin/packing-companies', label: <FormattedMessage id="general.packingCompanies" /> },
];

const CompaniesContainer = ({ children, isTenantUseLogistics }) => (
  <div>
    {isTenantUseLogistics && <Subnavigation links={navLinks} className="small-12 column" />}
    <section className="small-12 column mt-0">
      {children}
    </section>
  </div>
);

CompaniesContainer.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isTenantUseLogistics: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isTenantUseLogistics: state.auth.user.current_company.logistics,
  }),
)(CompaniesContainer);
