import { useState, useEffect } from 'react';
// api
import { fetchCompanies } from 'endpoints/companies';

const initialState = { isLoading: false, companies: [] };

export default function useCompanies() {
  const [data, setData] = useState(initialState);

  useEffect(() => {
    setData((prevState) => ({ ...prevState, isLoading: true }));
    fetchCompanies()
      .then((companies) => {
        setData({ companies, isLoading: false });
      })
      .catch(() => {
        setData((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, [setData]);

  return data;
}
