import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: {
    data: {},
    loading: false,
  },
};

const SET_USER_DATA = 'users/SET_USER_DATA';
const SET_RESOURCE_LOADING = 'users/SET_RESOURCE_LOADING';

// ------------------------------------
// Actions
// ------------------------------------
export const setUserData = createAction(SET_USER_DATA);
export const setResourceLoading = createAction(SET_RESOURCE_LOADING);

// ------------------------------------
// Action creators
// ------------------------------------

// default action creators
export const createInvite = (data) => (dispatch) => {
  dispatch(setResourceLoading(true));
  const reqParams = { method: 'POST', body: JSON.stringify({ resource: data }) };
  return fetchFromAPI('/users/new/invite', reqParams)
    .then((response) => {
      dispatch(setUserData(response.resource));
      dispatch(setResourceLoading(false));
      return response.resource;
    })
    .catch((error) => {
      dispatch(setResourceLoading(false));
      throw error;
    });
};

export const inviteToTenant = (email, role) => (dispatch) => {
  dispatch(setResourceLoading(true));
  const reqParams = { method: 'POST', body: JSON.stringify({ email, role }) };
  return fetchFromAPI('/users/invite_to_tenant', reqParams)
    .then((response) => {
      dispatch(setUserData(response.resource));
      dispatch(setResourceLoading(false));
      return response.resource;
    })
    .catch((error) => {
      dispatch(setResourceLoading(false));
      throw error;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [SET_USER_DATA]: (state, action) => ({
    resource: { ...state.resource, data: action.payload },
  }),
  [SET_RESOURCE_LOADING]: (state, action) => ({
    resource: {
      ...state.resource,
      loading: action.payload,
    },
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
