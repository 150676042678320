import React from 'react';
import T from 'prop-types';
// components
import Field from 'components/Field';
import CircleCheckbox from 'components/CircleCheckbox';
// utils
import cn from 'classnames';

const CircleCheckboxField = ({ className, name, label, isDisabled, onChangeCallback }) => (
  <div className={cn('row', { [className]: !!className })}>
    <div className="small-12 column">
      <Field
        name={name}
        parse={Boolean}
        component={({ input }) => (
          <CircleCheckbox
            label={label}
            checked={Boolean(input.value)}
            {...input}
            onChange={(e) => {
              input.onChange(e);
              onChangeCallback?.(e.target.checked);
            }}
            disabled={isDisabled}
          />
        )}
      />
    </div>
  </div>
);

CircleCheckboxField.propTypes = {
  name: T.string.isRequired,
  label: T.oneOfType([T.string, T.object]).isRequired,
  className: T.string,
  isDisabled: T.bool,
  onChangeCallback: T.func,
};

export default CircleCheckboxField;
