import React, { Component } from 'react';
import { connect } from 'react-redux';

import styles from './ChooseImport.module.scss';
import closeImg from '../../../../../public/images/close_template.svg';
import metaFarmsImg from '../../../../../public/images/metaFarms.png';
import FASImg from '../../../../../public/images/FAS.png';
import uploadImg from '../../../../../public/images/upload-file.svg';

import { FormattedMessage } from 'react-intl';
import T from 'prop-types';
import cn from 'classnames';

import CardTemplates from '../Templates/CardTemplates';
import { Link } from 'react-router';
import {
  getActiveCompany,
  getIdImportsCSV,
  setSelectedNameAllImports
} from 'reducers/importCSV';
import RedirectBoard from './RedirectBoard/RedirectBoard';


const typesCompany = {
  metaFarms: ['farms', 'groups'],
  FAS: ['farms', 'groups', 'companies'],
};

const methodStep = [
  {
    title: <FormattedMessage id="general.csvImport" />,
    imageCard: uploadImg,
    type: 'CSV',
    link: '/csv-import/select-mode',
  },
  {
    title: <FormattedMessage id="component.metafarms" />,
    imageCard: metaFarmsImg,
    type: 'metaFarms',
    link: '/imports/choose/meta-farms/farms'
  },
  {
    title: <FormattedMessage id="component.prairieSystems" />,
    imageCard: FASImg,
    type: 'FAS',
    link: '/imports/choose/fas/farms'

  },
];

class ChooseImport extends Component {

  state = {
    isRedirectBoard: false,
    typeRedirect: '',
  };


  componentDidMount() {
    const { getActiveCompany } = this.props;
    getActiveCompany();
  }

  getClassName = (type) => {
    switch (type) {
      case 'CSV':
        return styles.nextStepCsv;
      case 'metaFarms':
        return styles.nextStepMetaFarms;
      case 'FAS':
        return styles.nextStepFAS;
      default:
        return null;
    }
  };

  getActive = (type) => {
    const { activeCompany } = this.props;
    switch (type) {
      case 'metaFarms':
        return activeCompany.meta_farms;
      case 'FAS':
        return activeCompany.fas_link;
      case 'CSV':
        return true;
      default:
        return null;
    }
  };

    downloadFiles = (type, active) => {
      let typesUrl = '';
      const { idCompany, getIdImportsCSV, setSelectedNameAllImports } = this.props;
      const company = {
        metaFarms: 'meta_farms_credential',
        FAS: 'fas_link_credential',
      };
      if (active === false) {
        return this.setState((prevState) => ({
          isRedirectBoard: !prevState.isRedirectBoard,
          typeRedirect: type,
        }));
      }
      setSelectedNameAllImports(typesCompany[type]);
      typesCompany[type].forEach((item, index) => {
        const isEndTypes =  index !== typesCompany[type].length - 1;
        typesUrl += `types[]=${item}${isEndTypes ? '&' : ''}`;
      });
      return   getIdImportsCSV(idCompany, company[type], typesUrl);

    // return fetchFromAPI(`/admin/companies/${idCompany}/${company[type]}/import`, { method: 'GET' })
    //   .catch(toastResponseErrors);
    // return null;
    };

  getTitle = () => {
    const { isRedirectBoard, typeRedirect } = this.state;

    const titleName = typeRedirect === 'FAS' ? 'FAS' : 'MetaFarms';
    if (isRedirectBoard === true) return (
      <FormattedMessage
        id="general.imports.templates.redirect.title"
        values={{ name: titleName }}
      />
    );
    return <FormattedMessage id="general.imports.templates.header.nextStep" />;
  };

  backMethod = () => {
    return this.setState((prevState) => ({
      isRedirectBoard: !prevState.isRedirectBoard,
    }));
  };


  render() {
    const { isRedirectBoard, typeRedirect } = this.state;
    return (
      <div className={styles.Templates}>
        <div className={styles.ModalTemplates} />
        <div className={cn(styles.Board, { [styles.redirectBoard]: isRedirectBoard })}>
          <div className={styles.header}>
            {this.getTitle()}
            <Link to="/imports">
              <img src={closeImg} alt="close" />
            </Link>
          </div>
          <div className={styles.bodyTemplates}>
            {isRedirectBoard === false ? methodStep.map((item, index) => {
              return (
                <CardTemplates
                  key={index}
                  choseImport
                  title={item.title}
                  imgCard={item.imageCard}
                  type={item.type}
                  className={this.getClassName(item.type)}
                  onClickCompany={this.downloadFiles}
                  active={this.getActive(item.type)}
                  link={item.link}
                />
              );
            }) : (
              <RedirectBoard
                title={typeRedirect === 'FAS' ? 'component.prairieSystems' : 'component.metafarms.link'}
                onClick={this.backMethod}
                type={typeRedirect}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ChooseImport.propTypes = {
  idCompany: T.number,
  getActiveCompany: T.func,
  activeCompany: T.object,
  getIdImportsCSV: T.func,
  setSelectedNameAllImports: T.func,
};

const mapStateToProps = (state) => ({
  idCompany: state.auth.user.current_company.id,
  activeCompany: state.importCSV.activeCompany,
});

const mapDispatchToProps = (dispatch) => ({
  getActiveCompany: () => dispatch(getActiveCompany()),
  getIdImportsCSV: (idCompany, nameCompany, types) => dispatch(getIdImportsCSV(idCompany, nameCompany, types)),
  setSelectedNameAllImports: (name) => (dispatch)(setSelectedNameAllImports(name))
});
export default connect(mapStateToProps, mapDispatchToProps)(ChooseImport);
