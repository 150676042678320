import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import moment from 'moment';
import { renderMedicationLine, formatDosageType } from 'utils';

am4core.useTheme(am4themes_animated);

export default function createGanttChart(formatMessage, ranges, measurement_system) {
  const chart = am4core.create('chartDiv', am4charts.XYChart);

  chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
  chart.paddingRight = 30;
  chart.dateFormatter.dateFormat = 'yyyy-MM-dd';
  chart.logo.disabled = true;

  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = 'name';
  categoryAxis.renderer.minGridDistance = 70;
  categoryAxis.renderer.grid.template.strokeOpacity = 0;

  categoryAxis.renderer.line.strokeOpacity = 1;
  categoryAxis.renderer.line.strokeWidth = 1;
  categoryAxis.renderer.line.stroke = am4core.color('#a3a3a3');
  categoryAxis.renderer.inversed = true;
  categoryAxis.title.text = 'TREATMENTS';
  categoryAxis.title.fontSize = 12;
  categoryAxis.title.marginRight = 10;
  categoryAxis.title.fontWeight = 'bold';
  categoryAxis.renderer.disabled = true;

  categoryAxis.renderer.tooltip.fontSize = 10;

  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.minGridDistance = 70;
  dateAxis.title.text = 'PERIOD';
  dateAxis.title.fontSize = 12;
  dateAxis.title.fontWeight = 'bold';
  dateAxis.renderer.line.strokeOpacity = 1;
  dateAxis.renderer.line.strokeWidth = 1;
  dateAxis.renderer.line.stroke = am4core.color('#a3a3a3');

  dateAxis.renderer.fontSize = 12;
  dateAxis.renderer.ticks.template.strokeOpacity = 1;
  dateAxis.renderer.ticks.template.stroke = am4core.color('#a3a3a3');
  dateAxis.renderer.ticks.template.strokeWidth = 1;
  dateAxis.renderer.ticks.template.length = 10;

  dateAxis.renderer.tooltip.fontSize = 10;

  dateAxis.max = moment(ranges.date_end).valueOf();
  dateAxis.min = moment(ranges.date_start).valueOf();

  const series = chart.series.push(new am4charts.ColumnSeries());
  series.columns.template.tooltipText = '{name}: [bold]{start}[/] - [bold]{end}[/]';

  series.dataFields.openDateX = 'start';
  series.dataFields.dateX = 'end';
  series.dataFields.categoryY = 'name';
  series.columns.template.propertyFields.fill = 'color'; // get color from data
  series.columns.template.propertyFields.stroke = 'color';
  series.columns.template.strokeOpacity = 1;
  series.columns.height = 15;
  series.columns.width = 15;
  series.columns.template.height = 12;
  series.columns.template.strokeOpacity = 0;

  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = am4core.color('#1E263E');
  series.tooltip.pointerOrientation = 'vertical';

  series.columns.template.adapter.add('tooltipHTML', (current, target) => {
    const data = target.dataItem.dataContext;
    if (data.bullet) return null;
    const text = `<b>${moment(data.start).format('DD MMM')}</b>`;
    if (data.withdrawal) {
      return `<div class="tooltip-wrapper"><b>${moment(data.end).format('DD MMM')}</b><br />${data.name} • ` +
        `${formatMessage({ id: 'general.withdrawalPeriodDays' }, { days: data.withdrawal })}</div>`;
    }
    const dosage_type = formatDosageType(data.dosage_type, measurement_system);
    const medication = {
      gallons_value: data.gallons_value || '', quantity: data.quantity,
      treatment_product: {
        dosage_type, route: data.route, name: data.name,
        dosage_measure: data.dosage_measure
      }
    };
    return `<div class="tooltip-wrapper">${text}<br />${renderMedicationLine(medication, formatMessage)} • ` +
      `${data.head_count} ${formatMessage({ id: 'general.headTreated' })}</div>`;
  });

  series.columns.template.column.adapter.add('cornerRadiusTopLeft', (current, target) => {
    return target.dataItem.dataContext.roundStart && !target.dataItem.dataContext.bullet ? 10 : 0;
  });
  series.columns.template.column.adapter.add('cornerRadiusBottomLeft', (current, target) => {
    return target.dataItem.dataContext.roundStart && !target.dataItem.dataContext.bullet ? 10 : 0;
  });

  const bullet = series.bullets.push(new am4charts.Bullet());
  bullet.tooltipText = '{name}: [bold]{start}[/] - [bold]{end}[/]';

  const image = bullet.createChild(am4core.Image);
  image.height = 12;
  image.width = 12;
  image.horizontalCenter = 'middle';
  image.verticalCenter = 'middle';
  image.propertyFields.href = 'bullet';
  bullet.adapter.add('tooltipHTML', (current, target) => {
    if (target.dataItem && target.dataItem.dataContext.tooltipType === 'round') {
      const data = target.dataItem.dataContext;
      const text = `<b>${moment(data.start).format('DD MMM')}</b>`;
      const dosage_type = formatDosageType(data.dosage_type, measurement_system);
      const medication = {
        gallons_value: data.gallons_value || '', quantity: data.quantity,
        treatment_product: {
          dosage_type, route: data.route, name: data.name,
          dosage_measure: data.dosage_measure
        }
      };
      return `<div class="tooltip-wrapper">${text}<br />${renderMedicationLine(medication, formatMessage)} • ` +
        `${data.head_count} ${formatMessage({ id: 'general.headTreated' })}</div>`;
    }
    if (target.dataItem && target.dataItem.dataContext.tooltipType === 'triangle') {
      const data = target.dataItem.dataContext;
      let text = `<b>${moment(data.start).format('DD MMM')}</b>`;

      const getDiagnosesTypeLabelKey = (type) => {
        switch (type) {
          case 'clinical': return 'general.diagnosesTypes.clinical';
          case 'lab_confirmed': return 'general.diagnosesTypes.labConfirmed';
          case 'presumed': return 'general.diagnosesTypes.presumed';
          case 'gross_only': return 'general.diagnosesTypes.grossOnly';
          default: return `general.diagnosesTypes.${type}`;
        }
      };

      if (data.diagnosesNames) {
        data.diagnosesNames.forEach(({ name, type, pig_group }) => {
          const pgName = pig_group ? `${pig_group.name} • ` : '';
          text += `<div>${pgName}${name} • ${formatMessage(
            { id: getDiagnosesTypeLabelKey(type) }
          )}</div>`;
        });
        return `<div class="tooltip-wrapper">${text}</div>`;
      }
    }

    return null;
  });

  return chart;
}
