import { createAction, handleActions } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

const SET_PARSED_FILES = 'csv-import/SET_PARSED_FILES';
const SET_FILES_DATA = 'csv-import/SET_FILES_DATA';
const SET_IMPORT_ACTION = 'csv-import/SET_IMPORT_ACTION';
const PENDING = 'PENDING';
const SET_IMPORTS_ARCHIVE  = 'SET_IMPORTS_ARCHIVE';
const GET_ACTIVE_COMPANY =  'GET_ACTIVE_COMPANY';
const GET_ID_IMPORTS_CSV = 'GET_ID_IMPORTS_CSV';
const SET_SELECTED_ALL_IMPORTS  = 'SET_SELECTED_ALL_IMPORTS';
const CLEAR_ID_IMPORTS_CSV = 'CLEAR_ID_IMPORTS_CSV';

const initialState = {
  parsedFiles: [],
  filesByType: {},
  importActionByType: {},
  isLoading: false,
  selectedArchiveImports: {},
  activeCompany: {},
  typeIdImports: [],
  selectedNameAllImports: [],
};

const RESET_STEP = 'importCSV/RESET_STEP';

export const setParsedFiles = (parsedFiles) => (dispatch) => {
  dispatch(createAction(SET_PARSED_FILES)(parsedFiles));
};

export const setFilesData = (parsedFiles, filesByType) => (dispatch) => {
  dispatch(createAction(SET_FILES_DATA)({ parsedFiles, filesByType }));
};

export const setImportAction = (importAction, type) => (dispatch) => {
  dispatch(createAction(SET_IMPORT_ACTION)({ importAction, type }));
};

export const setSelectedArchiveImports = (data) => (dispatch) => {
  createAction(PENDING);
  dispatch(createAction(SET_IMPORTS_ARCHIVE)(data));
};

export const getActiveCompany = () => (dispatch) => {
  createAction(PENDING);
  fetchFromAPI('/admin/csv_imports/available_external_api').then((result) => {
    dispatch(createAction(GET_ACTIVE_COMPANY)(result));
  });

};

export const getIdImportsCSV = (idCompany, nameCompany, types) => (dispatch) => {
  createAction(PENDING);
  fetchFromAPI(`/admin/companies/${idCompany}/${nameCompany}/import?${types}`,
    { method: 'GET' })
    .then((data) => {
      dispatch(createAction(GET_ID_IMPORTS_CSV)(data));
    });
};

export const setSelectedNameAllImports = (name) => (dispatch) => {
  createAction(PENDING);
  dispatch(createAction(SET_SELECTED_ALL_IMPORTS)(name));
};

export const clearIdImportsCSV  = () => (dispatch) => {
  createAction(PENDING);
  dispatch(createAction(CLEAR_ID_IMPORTS_CSV)());
};

export default handleActions({
  [SET_PARSED_FILES]: (state, { payload }) => ({
    ...state,
    parsedFiles: payload,
  }),
  [SET_FILES_DATA]: (state, { payload }) => ({
    ...state,
    parsedFiles: payload.parsedFiles,
    filesByType: payload.filesByType,
  }),
  [SET_IMPORT_ACTION]: (state, { payload }) => ({
    ...state,
    importActionByType: {
      ...state.importActionByType,
      [payload.type]: payload.importAction,
    }
  }),
  [PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [GET_ACTIVE_COMPANY]: (state, { payload }) => {
    return ({
      ...state,
      isLoading: false,
      activeCompany: { ...payload.external_api },
    });
  },
  [SET_SELECTED_ALL_IMPORTS]: (state, { payload }) => {
    return ({
      ...state,
      isLoading: false,
      selectedNameAllImports: payload,
    });
  },
  [SET_IMPORTS_ARCHIVE]: (state, { payload }) => {
    return ({
      ...state,
      isLoading: false,
      selectedArchiveImports: payload,
    });
  },
  [GET_ID_IMPORTS_CSV]: (state, { payload }) => {
    return ({
      ...state,
      isLoading: false,
      typeIdImports: payload,
    });
  },
  [CLEAR_ID_IMPORTS_CSV]: (state) => {
    return ({
      ...state,
      isLoading: false,
      typeIdImports: [],
    });
  },
  [RESET_STEP]: () => {
    return ({
      ...initialState
    });
  },
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
