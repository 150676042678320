import React from 'react';
import { FormattedMessage } from 'react-intl';

export const timeFormatter = (value, unit) => {
  if (unit === 'second') return <FormattedMessage id="general.time.justNow" />;
  return (
    <FormattedMessage
      id={`component.timeAgo.${value > 1 ? unit + 's' : unit}`}
      values={{ value }}
    />
  );
};
