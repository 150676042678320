import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { fetchData } from 'reducers/dataTable';
// recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import Link from 'react-router/lib/Link';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import SearchBox from 'components/SearchBox';
import DropdownButton from 'components/DropdownButton';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import NothingBox from 'components/NothingBox';
import MobileList from 'components/MobileList/MobileList';
import Preloader from 'components/Preloader';
import OfflineScreen from 'components/OfflineScreen';
import Panel from 'components/Panel';
// api
import { downloadTreatmentFeedbackCSV, downloadAllTreatmentFeedbacksCSV } from 'endpoints/treatmentFeedbacks';
// utils
import download from 'downloadjs';
import isNull from 'lodash.isnull';
import { isMobile, isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { FormattedMessage } from 'react-intl';
import { getMedType } from 'utils';
// styles
import './TreatmentFeedbackHome.scss';

const feedDesc = <FormattedMessage id="component.treatmentFeedbackHome.feedDesc" />;

class TreatmentFeedbackHome extends Component {

  componentDidMount() {
    const { fetchData, setPageOptions, params, isOnline } = this.props;
    if (isOnline) fetchData({ ...params, page: 1 });
    setPageOptions({
      mobileTitle: 'treatmentFeedback',
    });
  }

  componentDidUpdate(prevProps) {
    const { isOnline, fetchData } = this.props;
    if (isOnline && isOnline !== prevProps.isOnline) {
      fetchData();
    }
  }

  componentWillUnmount() {
    this.props.setPageOptions({ mobileTitle: '' });
  }

  renderNameColumn = (rowData) => (
    <CustomColumn>
      <div className="product-name-column">
        <i
          className={`fa fa-${getMedType(rowData?.treatment_product)} icon`}
        />
        <span className="semibold">{rowData?.treatment_product?.name}</span>
      </div>
    </CustomColumn>
  );

  renderTreatmentsColumn = ({ treatments_count }) => (
    <CustomColumn>
      <span className="semibold">{treatments_count}</span>
    </CustomColumn>
  );

  renderFeedBackColumn = (key) => (item) => (
    <CustomColumn>
      {isNull(item[key])
        ? <span className="color-dark-gray"><FormattedMessage id="general.feedback.resultsPending" /></span>
        : <ProgressBar progress={item[key]} />}
    </CustomColumn>
  );

  renderActionsColumn = (feedback, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${feedback.id}-${rowIndex}`}
        label={<FormattedMessage id="general.button.view" />}
        buttonType="light-gray compact"
        url={`/treatments/feedback/${feedback.id}`}
        dropDownData={this.getDropdownOptions(feedback)}
        wide
      />
    </div>
  );

  getDropdownOptions = ({ treatment_product, id }) => {
    return [
      { label: <FormattedMessage id="component.treatmentFeedbackHome.showFeedback" />,
        url: `/treatments/feedback/${id}`, hide: !isMobile },
      { label: <FormattedMessage id="general.button.downloadDataCSV" />, onClick: () => this.downloadFeedbackCSV(id) },
      { label: <FormattedMessage id="general.manageMedication" />,
        url: `/admin/health-variables/medications/${treatment_product.id}` },
    ];
  };

  renderExpandable = ({ id, treatment_product }) => (
    <div>
      <Link className="button light small" to={`/treatments/feedback/${id}`}>
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="component.treatmentFeedbackHome.showFeedback" />
      </Link>
      <Button light small onClick={() => this.downloadFeedbackCSV(id)}>
        <i className="fa fa-download mr-5" />
        <FormattedMessage id="general.button.downloadData" />
      </Button>
      <Link className="button light small" to={`/admin/health-variables/medications/${treatment_product.id}`}>
        <i className="fa fa-gear mr-5" />
        <FormattedMessage id="general.manageMedication" />
      </Link>
    </div>
  );

  renderMobileRow = ({ treatment_product }) => (
    <>
      <i className={`fa fa-${getMedType(treatment_product)} medication-icon`} />
      <div className="mobile-treatment-name">
        {treatment_product?.name || ''}
      </div>
    </>
  );

  downloadFeedbackCSV = (id) => {
    const filename = `treatment-feedback-${id}.csv`;
    return downloadTreatmentFeedbackCSV(id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  downloadAllCSV = () => {
    const filename = 'all-treatment-feedback.csv';
    return downloadAllTreatmentFeedbacksCSV()
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  renderTitle = () => (
    <h2 className="lighter show-for-large">
      <FormattedMessage id="general.products" />
    </h2>
  );

  columns = [
    { label: <FormattedMessage id="component.dataTable.headers.productName" />, flex: '1 1 160px',
      renderer: this.renderNameColumn, sortKey: 'name',
    },
    { label: <FormattedMessage id="general.treatments" />, flex: '1 1 100px', renderer: this.renderTreatmentsColumn,
      sortKey: 'treatments_count',
    },
    { label: <FormattedMessage id="component.treatmentFeedbackHome.negativeFeedback" />, flex: '1 1 120px',
      renderer: this.renderFeedBackColumn('negative_feedback'), sortKey: 'negative_feedback', description: feedDesc,
    },
    { label: <FormattedMessage id="component.treatmentFeedbackHome.neutralFeedback" />, flex: '1 1 120px',
      renderer: this.renderFeedBackColumn('neutral_feedback'), sortKey: 'neutral_feedback', description: feedDesc,
    },
    { label: <FormattedMessage id="component.treatmentFeedbackHome.positiveFeedback" />, flex: '1 1 120px',
      renderer: this.renderFeedBackColumn('positive_feedback'), sortKey: 'positive_feedback', description: feedDesc,
    },
    { flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true, hide: isTablet },
    { flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  render() {
    const { resources, isLoading, meta: { total }, params: { page, per_page, search, sort }, user, fetchData, params,
      onSearchChange, onSortChange, onPageChange, onPerPageChange, isOnline } = this.props;

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };

    const dropdownOptions = isOnline ? [
      { label: <FormattedMessage id="component.treatmentFeedbackHome.downloadAll" />, onClick: this.downloadAllCSV },
      { label: <FormattedMessage id="general.manageMedications" />, url: '/admin/health-variables/medications' },
    ] : [];

    return (
      <div className="TreatmentFeedbackHome">
        <CompanyProfileHeader company={user.current_company} dropdownOptions={dropdownOptions} />

        {isOnline
          ? (
            <section className="small-12 column treatments-table-section">
              <Panel>
                <Panel.Heading className="treatments-table-header" renderTitle={this.renderTitle}>
                  <SearchBox initialValue={search} onChange={onSearchChange} />
                </Panel.Heading>

                <Panel.Body noPadding className="treatments-table-body">
                  <Preloader isActive={isLoading} />

                  <DataTable
                    data={resources}
                    columns={this.columns}
                    sort={sort}
                    onSortChange={onSortChange}
                    paginationProps={paginationProps}
                    isExpandable={isTablet}
                    renderExpandable={this.renderExpandable}
                    className="show-for-large"
                    scrollable
                    tableKey="treatmentFeedbacks"
                  />

                  <MobileList
                    className="hide-for-large"
                    data={resources}
                    fetchData={fetchData}
                    params={params}
                    getDropdownOptions={this.getDropdownOptions}
                    renderRow={this.renderMobileRow}
                    total={total}
                    isLoading={isLoading}
                  />

                  <NothingBox
                    itemsName="medications"
                    display={!resources.length}
                    isLoading={isLoading}
                    search={search}
                  >
                    <h2 className="lighter">
                      <FormattedMessage id="component.nothingBox.noMedications" />
                    </h2>
                  </NothingBox>
                </Panel.Body>

              </Panel>
            </section>
          )
          : <OfflineScreen />}
      </div>
    );
  }
}

TreatmentFeedbackHome.propTypes = {
  resources: T.array,
  isLoading: T.bool,
  params: T.object,
  meta: T.object,
  user: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  fetchData: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  isOnline: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.treatmentFeedbacks.resources,
      params: state.dataTable.treatmentFeedbacks.params,
      meta: state.dataTable.treatmentFeedbacks.meta,
      isLoading: state.dataTable.treatmentFeedbacks.isLoading,
      user: state.auth.user,
      isOnline: state.network.isOnline,
    }), (dispatch) => ({
      fetchData: (...data) => dispatch(fetchData('/treatment_feedbacks', 'treatmentFeedbacks', ...data)),
      setPageOptions: (options) => dispatch(setPageOptions(options)),
    }),
  ),
  withDataTableController(),
);

export default enhance(TreatmentFeedbackHome);
