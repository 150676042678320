import React from 'react';
import T from 'prop-types';
// components
import VerticalAligner from 'components/VerticalAligner';
import UserAvatar from 'components/UserAvatar';
import Avatar from 'components/Avatar/Avatar';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SuggestionItem.module.scss';

const cn = classnames.bind(styles);

function renderAvatar(suggestion) {
  const { type, url, name } = suggestion;
  if (!type) return null;
  if (type === 'user') return (
    <UserAvatar url={url} name={name} size="30" className="mr-10" />
  );
  return (
    <Avatar type={type} avatarSize="30" iconSize="13" className="mr-10" />
  );
}

const SuggestionItem = ({ suggestion }) => {
  if (suggestion.type === 'search') {
    return (
      <VerticalAligner>
        <i className={cn('fa fa-search', 'suggestion-icon')} />
        <strong>{suggestion.text}</strong>
      </VerticalAligner>
    );
  }

  return (
    <VerticalAligner>
      {renderAvatar(suggestion)}
      <strong>{suggestion.name}</strong>
    </VerticalAligner>
  );
};

SuggestionItem.propTypes = {
  suggestion: T.object.isRequired,
};

export default SuggestionItem;
