import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import ConditionCheckBox from './ConditionItems/ConditionCheckBox';
import NameConditionCheckBox from './ConditionItems/NameConditionCheckBox';
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
import MobilePortalOverlay from 'components/MobilePortalOverlay';
import PickerRow from '../PickerRow';
import { FormattedMessage } from 'react-intl';
// utils
import pickBy from 'lodash.pickby';
import { formatOptionsValues, getSectionOptionValueKey } from 'utils/userFiltersHelper';
import { userFilterSectionTypes } from 'utils/constants/userFilters';
import { farmTypes, loadTypes, commitmentStatuses, loadingStatuses } from 'constants.js';
// styles
import styles from './ConditionSelector.module.scss';

function isOptionsRequired(conditionValue) {
  return ['eq', 'not_eq'].includes(conditionValue);
}

const getListData = (sectionType) => {
  switch (sectionType) {
    case userFilterSectionTypes.farmType:
      return {
        pickerTitle: <FormattedMessage id="general.selectFarmType" />,
        list: farmTypes,
      };
    case userFilterSectionTypes.loadType:
      return {
        pickerTitle: <FormattedMessage id="general.selectLoadType" />,
        list: loadTypes,
      };
    case userFilterSectionTypes.commitmentStatus:
      return {
        pickerTitle: <FormattedMessage id="general.selectConfirmationStatus" />,
        list: commitmentStatuses,
      };
    case userFilterSectionTypes.loadingStatus:
      return {
        pickerTitle: <FormattedMessage id="general.selectLoadStatus" />,
        list: loadingStatuses,
      };
    default:
      return {
        pickerTitle: <FormattedMessage id="general.button.select" />,
      };
  }
};

const FixedListConditionSelector = ({ conditions, onBack, onSave, selectedSection, title }) => {
  const { pickerTitle, list } = getListData(selectedSection.type);
  const optionKey = getSectionOptionValueKey(selectedSection.type);
  const selectedValue = formatOptionsValues(selectedSection.options)[optionKey] || '';
  const [isPickerOpened, setIsPickerOpened] = useState(false);
  const [{ condition, value }, setState] = useState({ condition: selectedSection.condition, value: selectedValue });

  const isDoneDisabled = () => {
    if (['any', 'none'].includes(condition)) return condition === selectedSection.condition;
    if (isOptionsRequired(condition)) {
      const isSameValues = condition === selectedSection.condition && value === selectedValue;
      return !value || isSameValues;
    }
    return true;
  };

  const handleSave = () => {
    const options = isOptionsRequired(condition) ? [{ key: optionKey, value }] : undefined;
    onSave(pickBy({
      condition,
      options,
      id: selectedSection.id,
      sectionIndex: selectedSection.sectionIndex,
      type: selectedSection.type,
    }));
  };

  const handleChangeCondition = ({ target: { name } }) => {
    setState({ condition: name, value: '' });
  };

  const handleSelectListItem = (item) => {
    setState((prevState) => ({ ...prevState, value: item.value }));
    setIsPickerOpened(false);
  };

  return (
    <Fragment>
      <MobileListPickerComponent
        title={title}
        onBack={onBack}
        headerRightContent={(
          <Button disabled={isDoneDisabled()} primary small onClick={handleSave}>
            <FormattedMessage id="general.button.done" />
          </Button>
        )}
      >
        <div className={styles.checkboxes}>
          {conditions.map((option) => {
            const isListConditionCheckBox = isOptionsRequired(option.value);
            return (
              <Fragment key={option.value}>
                {isListConditionCheckBox && (
                  <NameConditionCheckBox
                    conditionValue={condition}
                    nameValue={list.find((listItem) => listItem.value === value)?.label}
                    namePlaceholder={pickerTitle}
                    onConditionValueChange={handleChangeCondition}
                    onNameClick={() => setIsPickerOpened(true)}
                    option={option}
                  />
                )}
                {!isListConditionCheckBox && (
                  <ConditionCheckBox
                    option={option}
                    conditionValue={condition}
                    onConditionValueChange={handleChangeCondition}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </MobileListPickerComponent>
      <MobilePortalOverlay isHidden={!isPickerOpened}>
        {isPickerOpened && (
          <MobileListPickerComponent title={pickerTitle} onBack={() => setIsPickerOpened(false)}>
            {list?.map((item) => (
              <PickerRow
                key={item.value}
                isSelected={item.value === value}
                onClick={item.value === value ? null : () => handleSelectListItem(item)}
              >
                {item.label}
              </PickerRow>
            ))}
          </MobileListPickerComponent>
        )}
      </MobilePortalOverlay>
    </Fragment>
  );
};

FixedListConditionSelector.propTypes = {
  conditions: T.array.isRequired,
  selectedSection: T.object.isRequired,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
  title: T.string.isRequired,
};

export default FixedListConditionSelector;
