import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Filter from 'components/TableFilter';
import ReactSelect from 'components/Select/ReactSelect';
import DateRangePicker from 'components/DatePicker/DateRangePicker';
// utils
import moment from 'moment';
import cn from 'classnames';
// styles
import styles from './BarnSheetsTreatmentsProfile.module.scss';

const BarnSheetsTreatmentsProfile = ({
  buttonsContent,
  children,
  dateEnd,
  dateStart,
  filterOptions,
  options,
  onChangeDates,
  onSelectPeriod,
  pathname,
  periodName,
}, { router }) => (
  <div className={styles['treatments-profile']}>
    <Filter
      filters={filterOptions}
      onFilterChange={router.push}
      activeFilter={pathname}
      className="mb-15"
    >
      <div className={styles['sub-nav-content']}>
        {periodName === 'custom' && onChangeDates && (
          <DateRangePicker
            className={styles['date-picker']}
            startDate={dateStart}
            endDate={dateEnd}
            isOutsideRange={(day) => (day.isAfter(moment(), 'day'))}
            onDatesChange={onChangeDates}
          />
        )}
        {onSelectPeriod && (
          <ReactSelect
            className={cn(styles['select-period'], { 'mr-15': !!buttonsContent })}
            placeholder={<FormattedMessage id="general.selectPeriod" />}
            options={options}
            valueKey="value"
            labelKey="name"
            value={periodName}
            onChange={onSelectPeriod}
            noInputMargin
            searchable={false}
          />
        )}
        {buttonsContent}
      </div>
    </Filter>
    <div>{children}</div>
  </div>
);

BarnSheetsTreatmentsProfile.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsTreatmentsProfile.propTypes = {
  buttonsContent: T.oneOfType([T.arrayOf(T.node), T.node]),
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  dateEnd: T.object,
  dateStart: T.object,
  options: T.array,
  onChangeDates: T.func,
  onSelectPeriod: T.func,
  filterOptions: T.array.isRequired,
  pathname: T.string.isRequired,
  periodName: T.string,
};

export default BarnSheetsTreatmentsProfile;
