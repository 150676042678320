import { formatLocation } from 'utils';

export const defaultCardHeight = 100;
export const minChartWidth = 1024;
export const cardWidth = 230;
const indentBetweenBlocksH = 150;
const indentBetweenBlocksV = 40;

export const cardHeightByType = {
  external_source: 55,
  packing_plant: 65,
};

const colors = {
  su: '#f74d8e',
  n: '#0069a1',
  f: '#00aeff',
  wf: '#00aeff',
  ff: '#00aeff',
  packing_plant: '#96adbd',
};

function getFarmFields({ farm }) {
  if (!farm) return {};
  return {
    name: farm.name,
    color: farm.farm_type ? colors[farm.farm_type] : '#a3a3a3',
    address: formatLocation(farm.city, farm.state),
    type: farm.farm_type,
  };
}

function getGroupFields(entity, type, formatMessage) {
  if (type !== 'pig_group') return {};
  return {
    group_name: entity.name,
    group_status: entity.status,
    label: formatMessage({ id: `general.status.${entity.status}` }, { date: null }),
  };
}

function getPlantFields(entity, type) {
  if (type !== 'packing_plant') return {};
  return {
    name: entity.name,
    type: 'plant',
    address: formatLocation(entity.city, entity.state),
    color: colors.packing_plant,
  };
}

function getBarnsheetUrl(type, groupId, hasAccess) {
  if (!hasAccess || type !== 'pig_group') return undefined;
  return `/barnsheets/groups/${groupId}`;
}

export function getGroupFlowWidth(flowLength = 1) {
  return cardWidth * flowLength + indentBetweenBlocksH * (flowLength - 1);
}

export function getHeight(blocksCount = 1, blockHeight = defaultCardHeight) {
  return blockHeight * blocksCount + indentBetweenBlocksV * (blocksCount - 1);
}

export function prepareData(records, formatMessage) {
  const data = [];
  let cardIdsByType = {};

  const updateCardIdsData = (key, id) => {
    cardIdsByType = {
      ...cardIdsByType,
      [key]: new Set([...(cardIdsByType[key] || []), id]),
    };
  };

  records.forEach((record) => {
    const {
      destination,
      destination_access,
      destination_id,
      destination_type,
      source,
      source_access,
      source_id,
      source_type,
    } = record;
    const sourceKey = source_type + '-' + source_id;
    const destinationKey = destination_type + '-' + destination_id;

    if (source && source_type) {
      if (!data.find((node) => (node.from === sourceKey) && !node.to)) { // to exclude duplicates
        data.push({
          from: sourceKey,
          url: getBarnsheetUrl(source_type, source_id, source_access),
          ...getFarmFields(source),
          ...getGroupFields(source, source_type, formatMessage),
        });
      }
      const chainKey = source_type === 'pig_group' ? source_type + '_' + source.farm.farm_type : source_type;
      updateCardIdsData(chainKey, source_id);
    }

    if (destination && destination_type) {
      if (!data.find((node) => (node.from === destinationKey) && !node.to)) { // to exclude duplicates
        data.push({
          from: destinationKey,
          url: getBarnsheetUrl(destination_type, destination_id, destination_access),
          ...getFarmFields(destination),
          ...getGroupFields(destination, destination_type, formatMessage),
          ...getPlantFields(destination, destination_type)
        });
      }
      const chainKey = destination_type === 'pig_group'
        ? destination_type + '_' + destination.farm.farm_type
        : destination_type;
      updateCardIdsData(chainKey, destination_id);
    }

    data.push({ from: sourceKey, to: destinationKey, value: 1 });
  });

  return { data, cardIdsByType };
}
