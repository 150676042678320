const headers = {
  'Cache-Control': 'no-cache',
};

export function fetchBuildData() {
  return fetch('/static/json/build.json', { headers }).then((data) => data.json());
}

export function fetchCountryPhones() {
  return fetch('/static/json/country_phones.json', { headers }).then((data) => data.json());
}
