import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SVRStatBlocks.module.scss';
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(styles);

const countAnsweredQuestionsByType = (categories, answerValue) => categories
  .map(({ svr_questions }) => svr_questions.map(({ answer }) => (answer?.value === answerValue ? 1 : 0)))
  .flat(1)
  .reduce((prev, next) => prev + next, 0);

const SVRStatBlocks = ({ categories }) => {
  const failedQuestionsCount = countAnsweredQuestionsByType(categories, 'fail');
  const passedQuestionsCount = countAnsweredQuestionsByType(categories, 'pass');
  const naQuestionsCount = countAnsweredQuestionsByType(categories, 'not_available');

  return (
    <div className={cn('stat-blocks')}>
      <div className={cn('block', 'pass')}>
        <div className={cn('value')}>{passedQuestionsCount}</div>
        <div className={cn('label')}><FormattedMessage id="general.pass" /></div>
      </div>
      <div className={cn('block', 'fail')}>
        <div className={cn('value')}>{failedQuestionsCount}</div>
        <div className={cn('label')}><FormattedMessage id="general.fail" /></div>
      </div>
      <div className={cn('block', 'not_available')}>
        <div className={cn('value')}>{naQuestionsCount}</div>
        <div className={cn('label')}><FormattedMessage id="general.notAvail" /></div>
      </div>
    </div>
  );
};

SVRStatBlocks.propTypes = {
  categories: T.array.isRequired,
};

export default SVRStatBlocks;
