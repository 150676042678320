import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import OfflineScreen from 'components/OfflineScreen';
// styles
import ImportsTable from './ImportsTable/ImportsTable';
import { FormattedMessage } from 'react-intl';


const columns = {
  groups: 'general.groups',
  barns: 'general.barns',
  farms: 'general.farms',
  users: 'general.users',
};
class PushResults extends Component {

  state ={
    subNavLinks: [],
  };

  componentDidMount() {
    const { selectedArchiveImports } = this.props;
    const  subNavLinks = selectedArchiveImports.type.map((type) => {
      return   {
        label: <FormattedMessage id={columns[type]} />,
        link: `/imports/${selectedArchiveImports.id}/${type}`,
      };
    });
    this.setState({
      subNavLinks
    });
  }

  render() {
    const { isOnline } = this.props;
    const { subNavLinks } = this.state;
    return (
      <div className="wrapperApi">
        <div className="BarnsheetsHome clearfix">
          {isOnline
            ? (
              <div className="small-12 column">
                <ImportsTable
                  subNavLinks={subNavLinks}
                />
              </div>
            )
            : <OfflineScreen />}
        </div>
      </div>
    );
  }
}

PushResults.propTypes = {
  isOnline: T.bool,
  selectedArchiveImports: T.object,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
    selectedArchiveImports: state.importCSV.selectedArchiveImports,
  })
)(PushResults);
