import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Amchart from 'components/Amchart/Amchart';
import Preloader from 'components/Preloader';
import ChartPlaceholder from 'components/Amchart/ChartPlaceholder';
// utils
import cn from 'classnames';
import groupBy from 'lodash.groupby';
import sumBy from 'lodash.sumby';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// sources api
import { getSourceDeathTrendsData } from 'endpoints/sources';
// styles
import './LifecycleDeathTrendChart.scss';

class LifecycleDeathTrendChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      meta: {},
      period: 'daily',
      isLoading: false,
    };
    const { formatMessage } = props;
    this.titles = {
      daily: formatMessage({ id: 'general.day' }),
      weekly: formatMessage({ id: 'general.week' }),
      monthly: formatMessage({ id: 'general.month' }),
    };
    this.pluralTitles = {
      daily: formatMessage({ id: 'general.days' }),
      weekly: formatMessage({ id: 'general.weeks' }),
      monthly: formatMessage({ id: 'general.months' }),
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.farmType !== nextProps.farmType) {
      this.getChartData(nextProps.farmType);
    }
  }

  getChartData = (farmType) => {
    const { sourceId, formatMessage } = this.props;
    const reqParams = { farm_type: farmType !== undefined ? farmType : this.props.farmType };
    this.setState({ isLoading: true });
    getSourceDeathTrendsData(sourceId, reqParams)
      .then(({ resources, meta }) => {
        const label = formatMessage({ id: 'general.day' });
        const formatData = resources.map(({ day, stats }) => ({ day, label, ...stats }));
        const data = this.prepareGraphData(formatData);
        this.setState({
          meta,
          period: 'daily',
          initialData: data,
          data,
          isLoading: false,
        });
      })
      .catch((response) => {
        toastResponseErrors(response);
        this.setState({ isLoading: false });
      });
  };

  prepareGraphData = (data, period = 'daily') => {
    if (period === 'daily') return data;
    const number = { weekly: 7, monthly: 30 }[period];
    const groupedData = groupBy(data, ({ day }) => (Math.ceil(day / number)));
    const keys = Object.keys(groupedData);
    return keys.map((key) => ({
      day: key,
      label: this.titles[period],
      acute: sumBy(groupedData[key], ({ acute }) => (acute)),
      chronic: sumBy(groupedData[key], ({ chronic }) => (chronic)),
      euthanasia: sumBy(groupedData[key], ({ euthanasia }) => (euthanasia)),
    }));
  };

  handleGraphMode = (period) => () => {
    const { initialData } = this.state;
    this.setState({
      period,
      data: this.prepareGraphData(initialData, period),
    });
  };

  getChartConfig = () => {
    const { data, period } = this.state;
    const { formatMessage } = this.props;

    const title = formatMessage({ id: 'general.averageDeaths' });
    const acute = formatMessage({ id: 'general.deaths.acute' });
    const chronic = formatMessage({ id: 'general.deaths.chronic' });
    const euthanasia = formatMessage({ id: 'general.deaths.euthanasia' });

    const columnBaloonHTML = `
      <div class='graph-tooltip'>\
        <div class='day'>[[label]] [[day]]</div>\
        <div class='text-gray mb-3 mt-3'>${title}</div>\
         <div class='death-value acute'>${acute} • [[acute]]</div>\
         <div class='death-value chronic'>${chronic} • [[chronic]]</div>\
         <div class='death-value euthanasia'>${euthanasia} • [[euthanasia]]</div>\
      </div>\
    `;

    const graphOptoins = {
      type: 'column',
      fixedColumnWidth: 3,
      fillAlphas: 1,
    };

    return {
      type: 'serial',
      categoryField: 'day',
      fontFamily: ['Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
      categoryAxis: {
        title: this.pluralTitles[period].toUpperCase(),
        gridPosition: 'start',
        color: '#8c8c8c',
        gridThickness: 1,
        gridAlpha: 0,
        tickLength: 8,
        labelFunction: (valueText) => `${this.titles[period].toUpperCase()} ${valueText}`,
      },
      valueAxes: [{
        id: 'average_death',
        title: title.toUpperCase(),
        stackType: 'regular',
        color: '#8c8c8c',
        axisThickness: 0,
        tickLength: 0,
      }],
      chartCursor: {
        enabled: true,
        cursorColor: '#a3a3a3',
        oneBalloonOnly: true,
        categoryBalloonEnabled: false,
        onlyOneBalloon: true,
      },
      zoomOutText: '',
      trendLines: [],
      graphs: [{
        ...graphOptoins,
        balloonText: columnBaloonHTML,
        id: 'acute',
        valueField: 'acute',
        lineColor: '#FC5E3B',
      }, {
        ...graphOptoins,
        balloonText: columnBaloonHTML,
        id: 'chronic',
        valueField: 'chronic',
        lineColor: '#1EB0FC',
      }, {
        ...graphOptoins,
        balloonText: columnBaloonHTML,
        id: 'euthanasia',
        valueField: 'euthanasia',
        lineColor: '#FDB12B',
      }],
      allLabels: [],
      balloon: {
        borderThickness: 0,
        horizontalPadding: 0,
        verticalPadding: 0,
        pointerWidth: 0,
        shadowAlpha: 0,
        fillAlpha: 0,
      },
      dataProvider: data,
    };
  };

  render() {
    const { data, isLoading, meta: { avg_days }, period } = this.state;
    const chartConfig = this.getChartConfig();
    const chartStyle = { height: '330px' };

    return (
      <div className="LifecycleDeathTrendChart">
        <div className="chart-box">

          <div className="chart-box-header">
            <div className="header-left">
              <div className="header-title">
                <FormattedMessage id="general.lifecycleDeathTrends">
                  {(text) => <h3 className="lighter mr-15">{text}</h3>}
                </FormattedMessage>
                <div className="period-buttons">
                  {['daily', 'weekly', 'monthly'].map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      className={cn('period-button', { 'active': period === item })}
                      onClick={this.handleGraphMode(item)}
                    >
                      <FormattedMessage id={`general.timeOptions.${item}`} />
                    </button>
                  ))}
                </div>
              </div>
              <div className="chart-legend mt-10">
                {['acute', 'chronic', 'euthanasia'].map((category, index) => (
                  <div className="chart-legend-item" key={index}>
                    <div className={cn('color-box', category)} />
                    <FormattedMessage id={`general.deaths.${category}`}>
                      {(text) => <div className="category-name">{text}</div>}
                    </FormattedMessage>
                  </div>
                ))}
              </div>
            </div>
            <div className="header-right">
              <div className="summary-data">
                <div className="data-block">
                  <FormattedMessage id="general.daysCountBig" values={{ count: avg_days }} />
                </div>
                <div className="current-range">
                  <FormattedMessage id="general.averageDaysOnFeed" />
                </div>
              </div>
            </div>
          </div>

          <div className="chart-box-body">
            <Preloader isActive={isLoading} />
            {!data.length
              ? <ChartPlaceholder chartStyle={chartStyle} />
              : <Amchart config={chartConfig} style={chartStyle} />}
          </div>
        </div>
      </div>
    );
  }
}

LifecycleDeathTrendChart.propTypes = {
  sourceId: T.oneOfType([T.string, T.number]),
  farmType: T.string,
  formatMessage: T.func.isRequired,
};

export default LifecycleDeathTrendChart;
