import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import VirtualVisitForm from './components/VirtualVisitForm';
// hooks
import { useIntl } from 'react-intl';
// api
import { createVirtualVisit } from 'endpoints/virtualVisits';
// utils
import moment from 'moment';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const VirtualVisitCreate = ({
  router,
  setBreadcrumbs,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setBreadcrumbs([
      { label: formatMessage({ id: 'general.virtualVisits' }), path: '/virtual-visits' },
      {
        label: formatMessage({ id: 'general.createVirtualVisit' }),
        mobileTitle: formatMessage({ id: 'general.virtualVisits' }),
      },
    ]);
  }, []);

  const handleSubmit = (data) => {
    const date = data.schedule_date.format('YYYY-MM-DD');
    const hour = data.schedule_time.hourValue;
    const minute = data.schedule_time.minuteValue;
    setIsLoading(true);
    return createVirtualVisit({
      farm_id: data.farm.value,
      invitee_id: data.user.value,
      schedule_at: moment(date + ' ' + hour + ':' + minute, 'YYYY-MM-DD hh:mm').format(),
    })
      .then(() => {
        showToastrMessage('component.toastr.virtualVisit.successCreated');
        router.push('/virtual-visits');
      })
      .catch((errors) => {
        setIsLoading(false);
        toastResponseErrors(errors);
      });
  };

  return (
    <Panel className="pv-20 ph-15">
      <Panel.Heading title={formatMessage({ id: 'general.createVirtualVisit' })} />
      <Panel.Body>
        <Preloader isActive={isLoading} />
        <VirtualVisitForm onSubmit={handleSubmit} />
      </Panel.Body>
    </Panel>
  );
};

VirtualVisitCreate.propTypes = {
  router: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(null, { setBreadcrumbs })(VirtualVisitCreate);
