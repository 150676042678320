import React from 'react';
import T from 'prop-types';
// redux-form
import { Field, reduxForm } from 'redux-form';
// components
import Button from 'components/Button';
import { Link } from 'react-router';
import FormField from 'components/ReduxFormFields/FormField/FormField';

const EditSuperAdminForm = ({ handleSubmit, pristine, submitting }) => (
  <form className="clearfix clear" onSubmit={handleSubmit}>
    <div className="row">
      <div className="small-12 column">
        <label>
          <Field
            name="email"
            component={FormField}
            type="email"
            placeholder="Type email"
            label="Email"
          />
        </label>
      </div>
    </div>
    <div className="row">
      <div className="small-12 medium-6 column">
        <label>
          <label>
            <Field
              name="first_name"
              component={FormField}
              type="text"
              placeholder="First Name"
              label="First Name"
            />
          </label>
        </label>
      </div>
      <div className="small-12 medium-6 column">
        <label>
          <Field
            name="last_name"
            component={FormField}
            type="text"
            placeholder="Last Name"
            label="Last Name"
          />
        </label>
      </div>
    </div>

    <div className="margin-top-2">
      <Button primary type="submit" disabled={pristine || submitting}>
        Save Changes
      </Button>
      <Link to="/super-admin/admin-users" className="button btn-link margin-left-1">Cancel</Link>
    </div>
  </form>
);

EditSuperAdminForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  submitting: T.bool,
};

export default reduxForm({
  form: 'edit-super-admin',
  enableReinitialize: true,
})(EditSuperAdminForm);
