import React from 'react';
import T from 'prop-types';
// utils
import { getInitials } from 'utils';
import cn from 'classnames';
// styles
import './UserAvatar.scss';

const types = {
  open_group: 'ep-groups',
  request_close_group: 'ep-groups',
  ff_comment: 'comment-btm',
  mention: 'at',
  svr_mention: 'at',
  svr_note_mention: 'at',
  direct_post: 'at',
  diagnosis_alert: 'diagnosis',
  health_alert: 'diagnosis',
  tenant_resource: 'exclamation-triangle',
};

const UserAvatar = ({
  user,
  size = 30,
  url,
  name,
  className = '',
  fluid,
  notificationType,
  isEditable,
  onChange,
  openModal,
  onEditClick,
  uploadImage,
  ...rest
}) => {
  const imageSrc = url || user?.profile_photo?.medium;
  const fullName = user?.first_name && user?.last_name ? `${user.first_name} ${user.last_name}` : '';
  const username = name || fullName || user?.full_name || user?.name;
  const nameLetters = getInitials(username || '');

  const styles = {
    width: fluid ? '100%' : Number(size),
    height: fluid ? '100%' : Number(size),
    lineHeight: Number(size) + 'px',
    fontSize: Math.round(6 + (Number(size) / 5)),
  };
  const notificationTypeSize = { fontSize: Math.round((Number(size) / 2) - 12) };

  return (
    <div className={cn('UserAvatar', { [className]: !!className })} {...rest}>
      {isEditable && (
        <div className={cn('edit-avatar')} onClick={imageSrc ? () => onEditClick() : null}>
          <label htmlFor="avatar-upload">
            <span className={cn('edit-btn')}>
              <i className="fa fa-camera-fa camera-icon" />
            </span>
            {!imageSrc &&
              <input onChange={uploadImage} type="file" id="avatar-upload" accept="image/png, image/jpeg" />
            }
          </label>
        </div>
      )}
      {imageSrc
        ? <img className="avatar-image" src={imageSrc} alt={username} style={styles} loading="lazy" />
        : <div className="avatar-letters" style={styles}>{nameLetters}</div>}
      {notificationType && (
        <div className={cn('notification-badge', notificationType)}>
          <i className={`fa fa-${types[notificationType]}`} style={notificationTypeSize} />
        </div>
      )}
    </div>
  );
};

UserAvatar.propTypes = {
  user: T.shape({
    profile_photo: T.object,
    full_name: T.string,
  }),
  size: T.oneOfType([T.string, T.number]),
  className: T.string,
  notificationType: T.string,
  url: T.string,
  name: T.string,
  fluid: T.bool,
  isEditable: T.bool,
  onChange: T.func,
  openModal: T.any,
  onEditClick: T.func,
  uploadImage: T.func,
};

export default UserAvatar;
