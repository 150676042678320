import { handleActions, createAction } from 'redux-actions';
// shareholder groups API
import {
  getShareholderGroup,
  createShareholderGroup as createSHGroup,
  updateShareholderGroup as updateSHGroup,
} from 'endpoints/admin/shareholderGroups';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  data: {},
  isLoading: false,
};

// ------------------------------------
// Actions
// ------------------------------------

const FETCH_SH_GROUP = 'group_show/FETCH_SH_GROUP';
const FETCH_SH_GROUP_PENDING = 'group_show/FETCH_SH_GROUP_PENDING';
const FETCH_SH_GROUP_FULFILLED = 'group_show/FETCH_SH_GROUP_FULFILLED';
const FETCH_SH_GROUP_REJECTED = 'group_show/FETCH_SH_GROUP_REJECTED';

const CREATE_SH_GROUP = 'group_show/CREATE_SH_GROUP';
const CREATE_SH_GROUP_PENDING = 'group_show/CREATE_SH_GROUP_PENDING';
const CREATE_SH_GROUP_FULFILLED = 'group_show/CREATE_SH_GROUP_FULFILLED';
const CREATE_SH_GROUP_REJECTED = 'group_show/CREATE_SH_GROUP_REJECTED';

const UPDATE_SH_GROUP = 'group_show/UPDATE_SH_GROUP';
const UPDATE_SH_GROUP_PENDING = 'group_show/UPDATE_SH_GROUP_PENDING';
const UPDATE_SH_GROUP_FULFILLED = 'group_show/UPDATE_SH_GROUP_FULFILLED';
const UPDATE_SH_GROUP_REJECTED = 'group_show/UPDATE_SH_GROUP_REJECTED';

const SET_SH_GROUP = 'group_show/SET_SH_GROUP';

// ------------------------------------
// Action creators
// ------------------------------------

export const fetchShareholderGroup = (id) => createAction(FETCH_SH_GROUP)(getShareholderGroup(id));

export const updateShareholderGroup = (id, data) => createAction(UPDATE_SH_GROUP)(updateSHGroup(id, data));

export const createSharedholderGroup = (data) => createAction(CREATE_SH_GROUP)(createSHGroup(data));

export const setSharedholderGroup = createAction(SET_SH_GROUP);

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({
  [FETCH_SH_GROUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_SH_GROUP_FULFILLED]: (state, action) => ({
    data: action.payload,
    isLoading: false,
  }),
  [FETCH_SH_GROUP_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [UPDATE_SH_GROUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [UPDATE_SH_GROUP_FULFILLED]: (state, action) => ({
    data: action.payload,
    isLoading: false,
  }),
  [UPDATE_SH_GROUP_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [CREATE_SH_GROUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [CREATE_SH_GROUP_FULFILLED]: (state, action) => ({
    data: action.payload,
    isLoading: false,
  }),
  [CREATE_SH_GROUP_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [SET_SH_GROUP]: (state, action) => ({
    ...state,
    data: action.payload,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
