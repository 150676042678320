import React, { useState } from 'react';
import T from 'prop-types';
// components
import { DayPickerRangeController } from 'react-dates';
// constants
import { START_DATE, END_DATE } from 'react-dates/constants';

const ConditionDateRangePicker = (props) => {
  const [focusedInput, setIsFocusedInput] = useState(props.startDate ? END_DATE : START_DATE);

  return (
    <DayPickerRangeController
      {...props}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setIsFocusedInput(!focusedInput ? START_DATE : focusedInput)}
    />
  );
};

ConditionDateRangePicker.propTypes = {
  startDate: T.object,
  endDate: T.object,
};

export default ConditionDateRangePicker;
