import React, { useEffect, useState, useRef } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import GraphLegend from 'components/GraphLegend/GraphLegend';
import Preloader from 'components/Preloader';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getDiffDaysWithoutTime } from 'utils';
import { getTreatmentColor, prepareDiagnosesToChart, titlesByPeriod } from '../utils/barnSheetsTreatments';
import moment from 'moment';
import createGanttChart from 'components/Amchart/charts/ganttChart';
// styles
import './TreatmentsPanel.scss';

const chartLabels = [
  { text: <FormattedMessage id="general.meds.injectable" />, backgroundColor: '#ff5e31' },
  { text: <FormattedMessage id="general.meds.waterAdditive" />, backgroundColor: '#0069a1' },
  { text: <FormattedMessage id="general.vaccine" />, backgroundColor: '#00aeff' },
  { text: <FormattedMessage id="general.meds.topical" />, backgroundColor: '#ffb200' },
  { text: <FormattedMessage id="general.withdrawalPeriod" />, backgroundColor: '#e8e8e8' },
  { text: <FormattedMessage id="general.diagnosis" />, type: 'triangle' },
];

const TreatmentsPanel = ({ closeDate, dateEnd, dateStart, path, periodName, measurementSystem }) => {
  const [{ isLoading, graphLines }, setState] = useState({ isLoading: false, graphLines: 0 });
  const chartRef = useRef();
  const { formatMessage } = useIntl();

  const drawGanttChart = (rangeData) => {
    chartRef.current?.dispose();
    chartRef.current = createGanttChart(formatMessage, rangeData, measurementSystem);
  };

  const checkSequence = (items) => {
    for (let i = 0; i < items.length; i += 1) {
      const currentElem = items[i];
      const treatmentColor = getTreatmentColor(currentElem);

      currentElem.start = currentElem.withdrawal ? currentElem.start : moment(currentElem.created_on);
      currentElem.end = currentElem.withdrawal ? currentElem.end : moment(currentElem.created_on).add(1, 'days');
      currentElem.color = treatmentColor;
      currentElem.quantity = Number(currentElem.quantity) ? currentElem.quantity : '0.0';

      const nextElem = items[i + 1];
      const prevElem = items[i - 1];

      if (!currentElem.withdrawal) {
        if ((!nextElem && !prevElem)) currentElem.roundEnd = true;
        if (((prevElem && !nextElem) && getDiffDaysWithoutTime(prevElem.created_on, currentElem.created_on) > 1)) {
          currentElem.roundEnd = true;
        }
        if (
          (nextElem && !prevElem) &&
          getDiffDaysWithoutTime(currentElem.start.format('YYYY-MM-DD'), nextElem.created_on) > 1
        ) {
          currentElem.roundEnd = true;
        }

        currentElem.roundStart = (!prevElem || (getDiffDaysWithoutTime(prevElem.start, currentElem.created_on) > 1));

        if (currentElem.roundStart && currentElem.roundEnd) {
          const frag = document.createDocumentFragment();
          const elem = document.createElement('svg');
          elem.innerHTML = `
            <svg
              version="1.1" id="Layer_1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 12 12"
              style="enable-background:new 0 0 12 12;"
              xml:space="preserve"
            >
              <g><circle cx="6" cy="6" r="6" fill="${treatmentColor}" /></g>
            </svg>`;
          while (elem.childNodes[0]) {
            frag.appendChild(elem.childNodes[0]);
          }
          const svg = new XMLSerializer().serializeToString(frag);
          const base64 = btoa(svg);
          currentElem.bullet = `data:image/svg+xml;charset=utf-8;base64,${base64}`;
          currentElem.end = currentElem.start;
          currentElem.tooltipType = 'round';
        }
      }
      currentElem.start = currentElem.start.format('YYYY-MM-DD');
      currentElem.end = currentElem.end.format('YYYY-MM-DD');
    }

    return items;
  };

  const addWithdrawalObjects = (items) => {
    const data = [];
    const graphDateMax = chartRef.current.xAxes.values[0].max;
    const getEndDate = ({ withdrawal_period_end_date_export }) => {
      if (closeDate && moment(withdrawal_period_end_date_export).isAfter(moment(closeDate))) {
        return moment(closeDate).format('YYYY-MM-DD');
      }

      return moment(withdrawal_period_end_date_export);
    };
    for (let i = 0; i < items.length; i += 1) {
      const currentElem = items[i];
      const nextElem = items[i + 1];
      if (
        currentElem.withdrawal_period_days_export &&
        (!nextElem || getDiffDaysWithoutTime(currentElem.created_on, nextElem.created_on) > 1)
      ) {
        const withdrawalObj = {
          created_on: currentElem.created_on,
          start: moment(currentElem.created_on).add(1, 'days'),
          end: getEndDate(currentElem),
          color: '#E8E8E8',
          withdrawal: currentElem.withdrawal_period_days_export,
          withdrawalEnd: currentElem.withdrawal_period_end_date_export,
          name: currentElem.name,
        };

        if (graphDateMax < withdrawalObj.end.valueOf()) {
          chartRef.current.xAxes.values[0].max = withdrawalObj.end.valueOf();
        }
        data.push(currentElem, withdrawalObj);
      } else {
        data.push(currentElem);
      }
    }
    return data;
  };

  const prepareGanttData = (resources) => {
    let result = [];
    const data = {};
    const diagnoses = prepareDiagnosesToChart(resources);

    for (let i = 0; i < resources.length; i += 1) {
      const treatmentItems = resources[i].daily_checkup_treatment_products;
      for (let j = 0; j < treatmentItems.length; j += 1) {
        const elem = treatmentItems[j];
        const newElem = { ...elem, ...elem.treatment_product };
        if (data[newElem.name]) {
          data[newElem.name].push(newElem);
        } else {
          data[newElem.name] = [newElem];
        }
      }
    }
    Object.keys(data).forEach((key) => {
      let items = data[key];
      items.sort((a, b) => (new Date(a.created_on) - new Date(b.created_on)));
      items = addWithdrawalObjects(items);
      data[key] = checkSequence(items);
    });

    const treatmentsNames = Object.keys(data);
    treatmentsNames.sort((a, b) => (a.localeCompare(b))).forEach((key) => {
      result = result.concat(data[key]);
    });

    const frag = document.createDocumentFragment();
    const elem = document.createElement('svg');
    elem.innerHTML = `
      <svg
        version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 13 13"
        style="enable-background:new 0 0 13 13;"
        xml:space="preserve"
      >
        <g><polygon points="6.5,0 0,13 13,13" fill="#1E263E"/></g>
      </svg>`;
    while (elem.childNodes[0]) {
      frag.appendChild(elem.childNodes[0]);
    }
    const svg = new XMLSerializer().serializeToString(frag);
    const base64 = btoa(svg);
    const bullet = `data:image/svg+xml;charset=utf-8;base64,${base64}`;

    if (diagnoses.length) {
      diagnoses.sort((a, b) => (new Date(a.start) - new Date(b.start)));
      diagnoses.forEach((diagnose) => {
        result.push({ name: 'diagnoses', bullet, tooltipType: 'triangle', color: '#1E263E', ...diagnose });
      });
    }

    setState((prevState) => ({
      ...prevState,
      graphLines: diagnoses.length ? treatmentsNames.length + 1 : treatmentsNames.length
    }));

    return result;
  };

  const getTreatmentData = async (params) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const { resources: treatmentsData } = await fetchFromAPI(path, { params });
      drawGanttChart(params);
      chartRef.current.data = prepareGanttData(treatmentsData);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      toastResponseErrors(error);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dateEnd && dateStart) {
      getTreatmentData({
        date_start: dateStart.format('YYYY-MM-DD'),
        date_end: dateEnd.format('YYYY-MM-DD'),
      });
    }
  }, [dateStart, dateEnd]);

  const computedHeight = (graphLines * 25) + 80;
  const amChartStyle = { height: Math.max(computedHeight, 170) };

  return (
    <div className="TreatmentsPanel">
      <div className="treatments-chart">
        <Preloader isActive={isLoading} />
        <div className="treatments-chart-title">
          <FormattedMessage id="general.treatments">
            {(text) => <span className="mr-5">{text}</span>}
          </FormattedMessage>
          {titlesByPeriod[periodName]}
        </div>
        <div className="treatments-chart-body">
          <div className="legends-line">
            {chartLabels.map((legendData, index) => (
              <GraphLegend key={index} className="mr-5" isReverseText {...legendData} />
            ))}
          </div>
          <div id="chartDiv" style={amChartStyle} />
        </div>
      </div>
    </div>
  );
};

TreatmentsPanel.propTypes = {
  closeDate: T.string,
  dateStart: T.object,
  dateEnd: T.object,
  path: T.string,
  periodName: T.string,
  measurementSystem: T.string,
};

export default TreatmentsPanel;
