import { fetchFromAPI } from 'utils/api';
import { uploadUrls } from 'constants/csvImport';

export const makeCSVImport = async (formData, type) => {
  try {
    const { resource: { result, id } } = await fetchFromAPI(`/admin/${uploadUrls[type]}`,
      { method: 'post', body: formData });
    return { result, id, type };
  } catch (error) {
    throw error;
  }
};
