import { combineReducers }  from 'redux';
import metaFarms from './metaFarms';
import prairieSystems from './prairieSystems';

const apiMonitoringReducer  = combineReducers({
  metaFarms,
  prairieSystems
});

export default apiMonitoringReducer;
