import React from 'react';
import T from 'prop-types';
// components
import VerticalAligner from '../VerticalAligner';
// utils
import { getArrowClass, getRateClass } from 'utils';
import cn from 'classnames';
// assets
import './GrowthRate.scss';

const GrowthRate = ({ value, className = '', transparent = false, small = false, isColorsInverted = false }) => (
  <VerticalAligner
    className={cn('GrowthRateBlock', getRateClass(value), {
      [className]: !!className,
      'is-colors-inverted': isColorsInverted,
      transparent,
      small,
    })}
  >
    <i className={getArrowClass(value)} />
    <span className="rate-value">{Math.abs(value).toFixed(2) + ' %'}</span>
  </VerticalAligner>
);

GrowthRate.propTypes = {
  value: T.number,
  className: T.string,
  transparent: T.bool,
  small: T.bool,
  isColorsInverted: T.bool,
};

export default GrowthRate;
