import React from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import TextLabel from 'components/TextLabel/TextLabel';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import ResponsiveDropdown from 'components/ResponsiveDropdown';
import Preloader from 'components/Preloader';
import { ThreeDotsBtn } from 'components/Button/Button';
// utils
import get from 'lodash.get';
import compact from 'lodash.compact';
// styles
import cn from 'classnames';
import './SourceProfileHeader.scss';

const renderSourceAddress = (source = {}) => {
  const { address, city, state, country_code, zipcode } = source;
  return compact([
    address,
    city,
    state,
    country_code === 'US' ? 'USA' : country_code,
    zipcode,
  ]).join(', ');
};

const SourceProfileHeader = ({
  source,
  isLoading,
  dropdownOptions,
  isSticky,
  className,
  children,
}) => {
  const isCommingled = get(source, 'source_type') === 'co';

  return (
    <div className={cn('SourceProfileHeader', className, { 'isSticky': isSticky })}>
      <Preloader isActive={isLoading} />
      <Avatar type="source" avatarSize={50} iconSize={24} />

      {source && (
        <div className="source-information">
          <div className="source-info-wrapper">
            <span className="source-name">{source.name}</span>
            <TextLabel className="commingled-label">{isCommingled && 'commingled'}</TextLabel>
            <StatusBadge status={source.status} />
          </div>
          <div className="source-info">
            {renderSourceAddress(source)}
          </div>
        </div>
      )}

      <div className="header-actions">
        {children}
        {!!dropdownOptions.length && (
          <ResponsiveDropdown
            className="header-dropdown ml-10"
            options={dropdownOptions}
            trigger={<ThreeDotsBtn />}
          />
        )}
      </div>
    </div>
  );
};

SourceProfileHeader.defaultProps = {
  className: '',
  children: null,
  dropdownOptions: [],
};

SourceProfileHeader.propTypes = {
  source: T.object,
  className: T.string,
  isSticky: T.bool,
  children: T.any,
  isLoading: T.bool,
  dropdownOptions: T.array,
};

export default SourceProfileHeader;
