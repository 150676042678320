import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reportSurvey } from 'reducers/dailyCheckup/checkupEdit';
// components
import { FormattedMessage } from 'react-intl';
import CheckupSegment from './CheckupSegment/CheckupSegment';
// utils
import { isSomeSectionReporting } from 'utils/checkupHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const ReportSurveySegment = ({
  checkup,
  paramsCheckupId,
  isDisabled,
  isSubmitting,
  reportSurvey,
  survey,
  validateCheckup,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSurveyWillHaveValue, setIsSurveyWillHaveValue] = useState(false);
  const { value, id, question } = survey;
  const isCurrentSurveySubmitting = isSubmitting && isLoading;
  const isOtherSurveySubmitting = isSubmitting && !isLoading;
  const formattedValue = (isEdit || isLoading) ? null : value;
  const isValueExists = formattedValue !== null;

  const handleUpdateSurveyValue = (newValue) => {
    if (!validateCheckup(checkup)) return;
    setIsLoading(true);
    setIsSurveyWillHaveValue(true);
    if (isEdit) setIsEdit(false);
    reportSurvey(paramsCheckupId, { dc_surveys: [{ id, value: newValue }] })
      .catch(toastResponseErrors)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEdit = () => {
    setIsEdit(true);
    setIsSurveyWillHaveValue(false);
  };

  return (
    <CheckupSegment
      onEdit={handleEdit}
      isReported={isValueExists}
      isSegmentConditionChanged={isSurveyWillHaveValue}
      isSubmitting={isCurrentSurveySubmitting}
      isDisabled={isDisabled || isOtherSurveySubmitting}
      onDataSubmit={() => handleUpdateSurveyValue(true)}
      title={question}
      noData={isValueExists}
      itemsCountPlaceholder={value ?
        <FormattedMessage id="general.button.yes" /> :
        <FormattedMessage id="general.button.no" />}
      onEmptyDataSubmit={() => handleUpdateSurveyValue(false)}
    />
  );
};

ReportSurveySegment.propTypes = {
  checkup: T.object.isRequired,
  paramsCheckupId: T.oneOfType([T.string, T.number]).isRequired,
  isDisabled: T.bool.isRequired,
  isSubmitting: T.bool.isRequired,
  reportSurvey: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  survey: T.object.isRequired,
};

export default connect(
  ({ dailyCheckup }) => ({
    isSubmitting: dailyCheckup.checkupEdit.isReportingSurvey,
    isDisabled: isSomeSectionReporting(dailyCheckup.checkupEdit) && !dailyCheckup.checkupEdit.isReportingSurvey,
  }), {
    reportSurvey,
  }
)(ReportSurveySegment);
