import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import { parseURL } from './index';

export function getFileTypeData(filename) {
  const name = filename || '';
  const splitNameArr = name.split('.');
  const extension = (splitNameArr[splitNameArr.length - 1] || 'unknown').toLowerCase();
  if (['csv', 'doc', 'docx', 'xls', 'xlsx'].includes(extension)) {
    return { extension, contentType: 'CompanyDoc' };
  }
  if (extension.includes('pdf')) {
    return { extension, contentType: 'CompanyPdf' };
  }
  if (['jpg', 'jpeg', 'gif', 'png'].includes(extension)) {
    return { extension, contentType: 'CompanyImage' };
  }
  return { extension, contentType: '' };
}

export function getFileUploadState(asset) {
  if (!asset.uniqueIdentifier) {
    return { uploaded: true, style: {} };
  }
  const uploaded = asset.uploadProgress === 1;
  const progress = Math.ceil((asset.uploadProgress || 0) * 99);
  return { uploaded, style: { width: `${progress}%` } };
}

export function getAssetShortType(type) {
  switch (type) {
    case 'CompanyDoc':
      return 'DOC';
    case 'CompanyPdf':
      return 'PDF';
    case 'CompanyImage':
      return 'Image';
    case 'CompanyLink':
      return 'Weblink';
    case 'CompanyVideo':
      return 'Video';
    default:
      return '';
  }
}

export function getAssetIcon(type) {
  switch (type) {
    case 'CompanyDoc':
      return 'fa fa-page-doc';
    case 'CompanyPdf':
      return 'fa fa-page-pdf';
    case 'CompanyImage':
      return 'fa fa-photo-bts';
    case 'CompanyLink':
      return 'fa fa-link';
    case 'CompanyVideo':
      return 'fa fa-film';
    default:
      return '';
  }
}

export const dateModifiedFormatter = (date) => (value, unit) => (
  unit === 'second'
    ? <FormattedMessage id="general.time.justNow" />
    : moment(date).format('MM/DD/YY')
);

export const getResourceName = ({ link, name }) => {
  if (link) {
    const linkName = name || '';
    if (linkName) return linkName;
    const { hostname, port, pathname } = parseURL(link);
    return `${hostname}${port}${pathname}`;
  }
  return name;
};
