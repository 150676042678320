import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SVRQuestionStatus.module.scss';

const cn = classnames.bind(styles);

const SVRQuestionStatus = ({ question }) => {
  if (question.answer.value === 'pass') {
    return (
      <div className={cn('question-status', 'pass')}>
        <i className={cn('fa fa-check', 'icon')} />
        <FormattedMessage id="general.pass" />
      </div>
    );
  }

  if (question.answer.value === 'fail') {
    return (
      <div className={cn('question-status', 'fail')}>
        <i className={cn('fa fa-times', 'icon')} />
        <FormattedMessage id="general.fail" />
      </div>
    );
  }

  if (question.answer.value === 'not_available') {
    return (
      <div className={cn('question-status', 'not-available')}>
        <FormattedMessage id="general.notAvail" />
      </div>
    );
  }

  return null;
};

SVRQuestionStatus.propTypes = {
  question: T.object.isRequired,
};

export default SVRQuestionStatus;
