import { useState, useCallback } from 'react';
// utils
import { formatOptionsValues } from 'utils/userFiltersHelper';
import isEqual from 'lodash.isequal';

function getInitialState(additionalOptions) {
  return {
    condition: '',
    [additionalOptions.valueKey]: '',
    [additionalOptions.valueEndKey]: '',
    [additionalOptions.valueStartKey]: '',
    hasRangeError: false,
  };
}

function findObjectToUpdate(name, value, additionalOptions) {
  switch (name) {
    case 'start': return { [additionalOptions.valueStartKey]: value };
    case 'end': return { [additionalOptions.valueEndKey]: value };
    case 'value': return { [additionalOptions.valueKey]: value };
    default: return {};
  }
}

export default function useNumericFilterSectionState(
  props,
  additionalOptions = { valueKey: 'value', valueEndKey: 'value_end', valueStartKey: 'value_start' }
) {
  const [state, setState] = useState({
    ...getInitialState(additionalOptions),
    condition: props.condition,
    ...formatOptionsValues(props.options),
  });
  const { condition, hasRangeError } = state;
  const value = state[additionalOptions.valueKey];
  const valueEnd = state[additionalOptions.valueEndKey];
  const valueStart = state[additionalOptions.valueStartKey];

  const onConditionChange = useCallback(
    ({ target: { name } }) => {
      setState((prevState) => ({ ...prevState, ...getInitialState(additionalOptions), condition: name }));
    },
    []
  );

  const onInputChange = useCallback(
    ({ target: { value, name, validity } }) => {
      if (!validity.valid) return;
      setState((prevState) => ({
        ...prevState,
        ...findObjectToUpdate(name, value, additionalOptions),
        hasRangeError: false,
      }));
    },
    []
  );

  const onApply = (e) => {
    const { onOptionSelect } = props;
    if (condition === 'any') {
      onOptionSelect(condition);
      return;
    }
    if (condition === 'range') {
      if ((+valueStart || 0) >= (+valueEnd || 0)) {
        e.stopPropagation();
        setState((prevState) => ({ ...prevState, hasRangeError: true }));
        return;
      }
      onOptionSelect(condition, [
        { key: additionalOptions.valueEndKey, value: valueEnd || 0 },
        { key: additionalOptions.valueStartKey, value: valueStart || 0 },
      ]);
      return;
    }
    onOptionSelect(condition, [{ key: additionalOptions.valueKey, value }]);
  };

  const resetData = useCallback(
    () => {
      const newState = {
        ...getInitialState(additionalOptions),
        condition: props.condition,
        ...formatOptionsValues(props.options),
      };
      const isEqualState = isEqual(newState, state);
      if (!isEqualState) setState(newState);
    },
    [state, props.condition, props.options]
  );

  return {
    currentValue: condition,
    hasRangeError,
    inputValue: value,
    inputStartValue: valueStart,
    inputEndValue: valueEnd,
    onApply,
    onConditionChange,
    onInputChange,
    resetData,
  };
}
