import { fetchFromAPI } from 'utils/api';

export const createSource = (resource) => {
  return fetchFromAPI('/admin/external_sources', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getSource = (id) => {
  return fetchFromAPI(`/admin/external_sources/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateSource = (id, resource) => {
  return fetchFromAPI(`/admin/external_sources/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const enableSource = (id) => {
  return fetchFromAPI(`/admin/external_sources/${id}/enable`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const disableSource = (id) => {
  return fetchFromAPI(`/admin/external_sources/${id}/disable`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getSourceCloseoutMortalityData = (id, params) => {
  return fetchFromAPI(`/admin/external_sources/${id}/analytics/closeout_mortality`, { params })
    .catch((response) => {
      throw response;
    });
};

export const getSourceArrivalConditionData = (id, params) => {
  return fetchFromAPI(`/admin/external_sources/${id}/analytics/avg_arrival_condition`, { params })
    .catch((response) => {
      throw response;
    });
};

export const getSourceDeathTrendsData = (id, params) => {
  return fetchFromAPI(`/admin/external_sources/${id}/analytics/lifecycle_death_trends`, { params })
    .catch((response) => {
      throw response;
    });
};

export const getSourceSymptomTrendsData = (id, params) => {
  return fetchFromAPI(`/admin/external_sources/${id}/analytics/lifecycle_symptom_trends`, { params })
    .catch((response) => {
      throw response;
    });
};
