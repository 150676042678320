import React from 'react';
import T from 'prop-types';

const VerticalAligner = ({ children, className }) => {
  const style = {
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    'flexWrap': 'wrap',
  };

  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

VerticalAligner.propTypes = {
  children: T.any,
  className: T.string,
};

export default VerticalAligner;
