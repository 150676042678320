import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import SubNavigation from 'components/Subnavigation';
import OfflineScreen from 'components/OfflineScreen';

const navLinks = [
  { link: '/super-admin/tenants', label: <FormattedMessage id="general.tenants" /> },
  { link: '/super-admin/admin-users', label: <FormattedMessage id="general.superAdmins" /> },
];

class SuperAdminContainer extends Component {

  componentDidMount() {
    const { setPageOptions, setBreadcrumbs } = this.props;
    setPageOptions({
      backLink: '',
      pinHeader: false,
      inSuperAdminMode: true,
      links: navLinks,
    });
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.pageTitle.superAdmin" />,
        path: '/super-admin/tenants',
        useLabelAsMobileTitle: true,
      },
    ]);
  }

  componentWillUnmount() {
    this.props.setPageOptions({
      inSuperAdminMode: false,
      links: null,
    });
  }

  render() {
    const { children, isOnline } = this.props;
    return (
      <div className="SuperAdminContainer">
        <SubNavigation links={navLinks} className="small-12 column hide-for-large" />
        {isOnline ? children : <OfflineScreen />}
      </div>
    );
  }
}

SuperAdminContainer.propTypes = {
  children: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  isOnline: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  }),
  { setPageOptions, setBreadcrumbs }
)(SuperAdminContainer);
