import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Preloader from 'components/Preloader';
// utils
import { momentLocales } from 'utils/localizationHelper';
import { getLSLanguage } from 'utils/localStorageHelper';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { sendNotify } from 'utils/airbrakeHelper';

const loaderStyle = { top: '50%' };

const ReactIntlProvider = ({ lang, children }) => {
  const [langData, setLangData] = useState({});
  const language = lang === null ? 'en' : lang;
  moment.locale(momentLocales[language] || language);

  useEffect(() => {
    import(`i18n/${language}.json`)
      .then(setLangData)
      .catch((error) => {
        sendNotify(error, {});
        toastResponseErrors(error);
      });
  }, [language]);

  if (isEmpty(langData)) return <Preloader style={loaderStyle} isActive />;

  return (
    <IntlProvider locale={language} defaultLocale="en" messages={langData} textComponent="span">
      {children}
    </IntlProvider>
  );
};

ReactIntlProvider.propTypes = {
  lang: T.string.isRequired,
  children: T.element,
};

export default connect(
  (state) => ({
    lang: state.auth.user?.language || state.invitations.user?.language || getLSLanguage() || 'en',
  })
)(ReactIntlProvider);
