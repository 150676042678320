import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Collapse from 'react-collapse';
import UserAvatar from 'components/UserAvatar';
import CheckupLogsList from './CheckupLogsList';
// utils
import uniqBy from 'lodash.uniqby';
import cn from 'classnames';
// styles
import './CheckupLogs.scss';

const CheckupLogs = ({ className = '', checkup, logs }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const logsUsers = logs.map((log) => log.user);
  const contributors = uniqBy(logsUsers, 'id');

  const handleToggleIsCollapsed = () => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  };

  return (
    <div className={cn('CheckupLogs', { [className]: !!className })}>
      <div className={cn('contributors', { 'expanded': !isCollapsed })} onClick={handleToggleIsCollapsed}>
        {contributors.map((user, index) => (
          <UserAvatar key={index} size="40" user={user} className="contributor" />
        ))}
        <i className="fa fa-angle-down show-for-large" />
      </div>
      <Collapse isOpened={!isCollapsed}>
        <div className="heading"><FormattedMessage id="component.checkupActivity.checkupActivity" /></div>
        <CheckupLogsList logs={logs} checkup={checkup} />
      </Collapse>
      <Button
        darkGrey={!isCollapsed}
        light={isCollapsed}
        onClick={handleToggleIsCollapsed}
        className="full-width view-btn hide-for-large"
      >
        {isCollapsed
          ? <span className="text-primary"><FormattedMessage id="general.button.viewActivity" /></span>
          : (
            <span>
              <i className="fa fa-times mr-5" />
              <FormattedMessage id="component.checkupActivity.hideActivity" />
            </span>
          )}
      </Button>
    </div>
  );
};

CheckupLogs.propTypes = {
  logs: T.array.isRequired,
  className: T.string,
  checkup: T.object,
};

export default CheckupLogs;
