import React from 'react';
import T from 'prop-types';
// components
import CheckboxMenu from './CheckboxMenu';
import Input from 'components/Input';
import ConditionDateRangePicker from '../ConditionDateRangePicker';
import { DayPickerSingleDateController } from 'react-dates';
// utils
import classnames from 'classnames/bind';
import pick from 'lodash.pick';
import moment from 'moment';
// filter constants
import { isAbsoluteDateCondition, isRelativeDateCondition } from 'utils/userFiltersHelper';
import { checkboxProps, userFilterSectionTypes } from 'utils/constants/userFilters';
// styles
import styles from './Menu.module.scss';

const cn = classnames.bind(styles);

const isOutsideRange = (filterType) => (day) => {
  const unlimitedFilterTypes = [userFilterSectionTypes.arriveAt];

  if (unlimitedFilterTypes.includes(filterType)) {
    return false;
  }

  return day.isAfter(moment(), 'day');
};

const DatesMenu = ({
  currentValue,
  onInputChange,
  onDateChange,
  onDatesRangeChange,
  inputValue,
  dateStartValue,
  dateEndValue,
  dateValue,
  inputLabel,
  type,
  ...others
}) => (
  <CheckboxMenu {...pick(others, checkboxProps)} currentValue={currentValue}>
    {(value, label, index) => (
      <>
        {!value && label && (
          <div className={cn('section-title', { 'pt-5': !!index })}>{label}</div>
        )}
        {(value === currentValue) && isRelativeDateCondition(value) && (
          <div className={cn('value-input-line')}>
            <Input
              name="days"
              type="number"
              min="0"
              max="9999999"
              placeholder={0}
              className={cn('value-input')}
              value={inputValue}
              onChange={onInputChange}
            />
            <div className={cn('value-label')}>{inputLabel}</div>
          </div>
        )}
        {(value === currentValue) && isAbsoluteDateCondition(value) && (
          <div className={cn('day-picker-line')}>
            <DayPickerSingleDateController
              date={dateValue}
              onDateChange={onDateChange}
              numberOfMonths={1}
              daySize={30}
              isOutsideRange={isOutsideRange(type)}
              navPrev={<i className={cn('fa fa-arrow-left', 'nav-icon')} />}
              navNext={<i className={cn('fa fa-arrow-right', 'nav-icon')} />}
            />
          </div>
        )}
        {(value === currentValue) && (value === 'range') && (
          <div className={cn('day-picker-line')}>
            <ConditionDateRangePicker
              startDate={dateStartValue}
              endDate={dateEndValue}
              onDatesChange={onDatesRangeChange}
              numberOfMonths={2}
              isOutsideRange={isOutsideRange(type)}
              daySize={30}
              navPrev={<i className={cn('fa fa-arrow-left', 'nav-icon')} />}
              navNext={<i className={cn('fa fa-arrow-right', 'nav-icon')} />}
            />
          </div>
        )}
      </>
    )}
  </CheckboxMenu>
);

DatesMenu.propTypes = {
  isOpened: T.bool.isRequired,
  currentValue: T.string,
  inputLabel: T.oneOfType([T.string, T.node]).isRequired,
  options: T.array.isRequired,
  onConditionChange: T.func.isRequired,
  onInputChange: T.func.isRequired,
  onDateChange: T.func.isRequired,
  onDatesRangeChange: T.func.isRequired,
  inputValue: T.oneOfType([T.number, T.string]),
  dateValue: T.oneOfType([T.object, T.string]),
  dateStartValue: T.object,
  dateEndValue: T.object,
  onApply: T.func.isRequired,
};

export default DatesMenu;
