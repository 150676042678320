import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './SuggestionBadge.module.scss';
// assets
import magicLoopSvg from '../../../public/images/magic_2_looped.svg';

const SuggestionBadge = ({ children, className }) => (
  <div className={cn(styles['suggestion-badge'], { [className]: !!className })}>
    <img className={styles['magic-loop-img']} src={magicLoopSvg} loading="lazy" alt="" />
    {children}
  </div>
);

SuggestionBadge.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  className: T.string,
};

export default SuggestionBadge;
