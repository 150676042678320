import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
const OPEN_MODAL = 'modals/OPEN_MODAL';
const CLOSE_MODAL = 'modals/CLOSE_MODAL';
const CLOSE_HOME_MODAL = 'modals/CLOSE_HOME_MODAL';

export const TYPE_MODAL = 'modal';
export const TYPE_PANEL = 'panel';

const initialState = {
  isOpen: false,
  isShowedAddToHomeScreenModal: true,
  type: TYPE_MODAL,
  component: null,
  options: {},
};

// ------------------------------------
// Actions
// ------------------------------------
export const openModal = createAction(OPEN_MODAL,
  (component, options = {}, type = TYPE_MODAL) => ({ component, options, type }));

// can't use like closeModal = createAction(CLOSE_MODAL), sometimes event goes there as payload, it takes lots of memory
export const closeModal = () => createAction(CLOSE_MODAL)();

export const closeAddToHomeScreenModal = () => createAction(CLOSE_HOME_MODAL)();

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [OPEN_MODAL]: (state, { payload }) => ({
    ...payload,
    isOpen: true,
  }),
  [CLOSE_MODAL]: (state) => ({
    ...initialState,
    type: state.type,
    component: state.type === TYPE_PANEL ? state.component : null,
  }),
  [CLOSE_HOME_MODAL]: () => ({
    ...initialState,
    isShowedAddToHomeScreenModal: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
