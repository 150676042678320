import React from 'react';
import T from 'prop-types';
// translate
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './EncouragementCountBox.module.scss';

const cn = classnames.bind(styles);

export const EncouragementCountBox = ({ count, message }) => {
  if (!count) return null;

  return (
    <div className={cn('encouragement-count-box')}>
      <span className={cn('count')}>{count}</span>
      <span className={cn('type')}><FormattedMessage id={message} values={{ value: count }} /></span>
    </div>
  );
};

EncouragementCountBox.propTypes = {
  count: T.oneOfType([T.number, T.string]),
  message: T.string,
};

export default EncouragementCountBox;
