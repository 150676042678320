import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// styles
import classnames from 'classnames/bind';
import styles from './MovementInfo.module.scss';

const cx = classnames.bind(styles);

const conditions = {
  good: <FormattedMessage id="general.status.good" />,
  avg: <FormattedMessage id="general.status.avg" />,
  poor: <FormattedMessage id="general.status.poor" />
};

const MovementInfo = ({ pigMigrations, className, isHighlighted }) => (
  <div>
    {pigMigrations.map(({ amount, estimated_weight, condition }, idx) => {
      const migrationType = amount > 0 ? 'positive' : 'negative';
      const migrationMessage = amount > 0 ? 'general.pigsAdded' : 'general.pigsRemoved';

      return (
        <div className={cx(className, { 'highlighted': isHighlighted && isHighlighted(pigMigrations[idx]) })} key={idx}>

          <div className={cx('info-row', migrationType)}>
            <FormattedMessage id={migrationMessage} />
            <strong className="float-right">{Math.abs(amount)}</strong>
          </div>

          {Boolean(estimated_weight) && (
            <div className={cx('info-row')}>
              <FormattedMessage id="general.averageWeight" />
              <strong className="float-right">
                <FormattedWeight value={estimated_weight}  />
              </strong>
            </div>
          )}

          {Boolean(condition) && (
            <div className={cx('info-row', 'condition')}>
              <FormattedMessage id="general.arrivalCondition" />
              <span className={cx('value', 'float-right', condition)}>
                {conditions[condition]}
              </span>
            </div>
          )}

        </div>
      );
    })}
  </div>
);

MovementInfo.propTypes = {
  pigMigrations: T.array.isRequired,
  className: T.string,
  isHighlighted: T.func,
};

export default MovementInfo;
