import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// components
import Button from 'components/Button';
import Modal from 'components/Modal/Modal';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import FarmFeedUserSelect from '../FarmFeedPost/FarmFeedUserSelect';
import FarmFeedFarmSelect from '../FarmFeedPost/FarmFeedFarmSelect';
import ActivitySwitcher from 'components/Activities/ActivitySwitcher';
import ActivityMentions from 'components/Activities/ActivityMentions';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
// assets
import './ShareActivityModal.scss';
// utils
import { fetchFromAPI } from 'utils/api';
import autosize from 'autosize';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

class ShareActivityModal extends Component {

  state = {
    selectedUsers: [],
    selectedFarm: {},
    message: '',
    truncated: true,
    isSubmitting: false,
  };

  componentDidMount() {
    if (this.textarea) autosize(this.textarea);
  }

  onSelectUser = (value) => {
    this.setState({ selectedUsers: isEmpty(value) ? [] : value });
  };

  onSelectFarm = (value) => {
    this.setState({ selectedFarm: isEmpty(value) ? {} : value });
  };

  onSubmit = () => {
    const { closeModal, activityToShare, type, currentUser: { current_company } } = this.props;
    const { selectedFarm, selectedUsers, message } = this.state;
    const resource = {
      message,
      farm_id: type === 'farm' ? selectedFarm.item.id : null,
      company_id: type === 'company' ? current_company.id : null,
      mentions: type === 'user' ? selectedUsers.map((user) => ({ user_id: user.item.id })) : null,
    };
    this.setState({ isSubmitting: true });
    fetchFromAPI(`/activities/${activityToShare.id}/shared_activities`, {
      method: 'post', body: JSON.stringify({ resource }),
    })
      .then(() => {
        this.setState({ isSubmitting: true });
        closeModal();
      })
      .catch((errors) => {
        this.setState({ isSubmitting: true });
        return toastResponseErrors(errors);
      });
  };

  toggleView = (e) => {
    e.stopPropagation();
    this.setState(({ truncated }) => ({ truncated: !truncated }));
  };

  isDisabled = () => {
    const { type } = this.props;
    const { selectedUsers, selectedFarm } = this.state;

    if (type === 'user') return !selectedUsers.length;
    if (type === 'farm') return isEmpty(selectedFarm);
    return false;
  };

  render() {
    const { type, closeModal, currentUser, activityToShare } = this.props;
    const { selectedUsers, selectedFarm, message, truncated, isSubmitting } = this.state;
    const { formatMessage } = this.context;

    const title = (
      <div>
        <i className="fa fa-share share-icon" />
        <FormattedMessage id="general.shareEvent" />
      </div>
    );

    return (
      <Modal className="ShareActivityModal" title={title}>
        <section className="modal-body">
          <div className="type-message-block">
            <UserAvatar user={currentUser} size={40} className="user-photo" />
            <textarea
              className={cn('text-block')}
              ref={(ref) => { this.textarea = ref; }}
              rows={1}
              value={message}
              onChange={(e) => this.setState({ message: e.target.value })}
              placeholder={formatMessage({ id: 'container.farmfeed.writeSmth' })}
            />
          </div>
          <div className="select-data-box">
            <Preloader isActive={isSubmitting} />
            {type === 'user' && (
              <FarmFeedUserSelect
                selectedUsers={selectedUsers}
                onChange={this.onSelectUser}
              />
            )}
            {type === 'farm' && (
              <FarmFeedFarmSelect
                selectedFarm={selectedFarm}
                onChange={this.onSelectFarm}
              />
            )}
            {type === 'company' && (
              <ActivityMentions
                className="ph-15"
                company={currentUser.current_company}
                isAdmin={currentUser.roles_map.admin}
              />
            )}
          </div>
          <div className={cn('activity-preview', { truncated })} onClick={this.toggleView}>
            <ActivitySwitcher currentUser={currentUser} activity={activityToShare} className="no-pointer-events" />
            {truncated && <div className="backdrop-gradient" />}
          </div>
        </section>
        <section className="modal-footer sticky-on-mobile">
          <Button default small onClick={closeModal} className="mr-10">
            <FormattedMessage id="general.button.cancel" />
          </Button>
          <Button primary small onClick={this.onSubmit} disabled={this.isDisabled() || isSubmitting}>
            <FormattedMessage id="general.button.post" />
          </Button>
        </section>
      </Modal>
    );
  }
}

ShareActivityModal.contextTypes = {
  formatMessage: T.func,
};

ShareActivityModal.propTypes = {
  type: T.string.isRequired,
  activityToShare: T.object.isRequired,
  closeModal: T.func.isRequired,
  currentUser: T.object.isRequired,
};

export default connect((state) => ({
  currentUser: state.auth.user,
}), { closeModal })(ShareActivityModal);
