import React, { useState } from 'react';
import T from 'prop-types';
// components
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
import PickerRow from '../PickerRow';
import SwitchConditionSelector from './SwitchConditionSelector';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import { sectionIcons } from 'utils/constants/userFilters';
// styles
import styles from '../UserFilterMobile.module.scss';

const UserFilterSectionPicker = ({ disabledSectionTypes, filterSections, onClose, initialSelectedSection, onSave }) => {
  const [search, setSearch] = useState('');
  const [selectedSection, setSelectedSection] = useState(initialSelectedSection);

  if (selectedSection) {
    const onBack = initialSelectedSection ? onClose : () => setSelectedSection(undefined);
    const title = selectedSection.title || filterSections.find(({ value }) => value === selectedSection.type)?.label;
    return (
      <SwitchConditionSelector title={title} selectedSection={selectedSection} onBack={onBack} onSave={onSave} />
    );
  }

  const searchedFilterSections = search
    ? filterSections.filter((section) => section.label.toLowerCase().includes(search.toLowerCase()))
    : filterSections;

  return (
    <MobileListPickerComponent
      title={<FormattedMessage id="general.selectFilter" />}
      onBack={onClose}
      search={search}
      onSearchChange={setSearch}
    >
      {searchedFilterSections.map((section) => {
        const canAddedOnce = disabledSectionTypes.includes(section.value);
        return (
          <PickerRow
            key={section.value}
            onClick={canAddedOnce
              ? undefined
              : () => setSelectedSection({ type: section.value, condition: '', title: section.label })
            }
            isSelected={canAddedOnce}
          >
            <i className={cn(styles['section-icon'], sectionIcons[section.value])} />
            {section.label}
          </PickerRow>
        );
      })}
    </MobileListPickerComponent>
  );
};

UserFilterSectionPicker.propTypes = {
  filterSections: T.array.isRequired,
  disabledSectionTypes: T.array.isRequired,
  onClose: T.func.isRequired,
  onSave: T.func.isRequired,
  initialSelectedSection: T.object,
};

export default UserFilterSectionPicker;
