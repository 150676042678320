import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { selectCurrentCompany, fetchUserCompanies, resetStore } from 'reducers/auth';
// components
import Preloader from 'components/Preloader';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import omit from 'lodash.omit';

const CompanySwitcher = ({
  fetchUserCompanies,
  location: { query },
  params,
  resetStore,
  router,
  selectCurrentCompany,
}) => {
  useEffect(() => {
    resetStore();
    selectCurrentCompany(params.id)
      .then((user) => {
        if (!user.current_company.is_expired) fetchUserCompanies();
        const pathname = decodeURIComponent(query.redirect_url || '/');
        const newQuery = omit(query, 'redirect_url');
        router.push({ pathname, query: newQuery });
      })
      .catch(toastResponseErrors);
  }, []);

  return <Preloader isActive />;
};

CompanySwitcher.propTypes = {
  params: T.object.isRequired,
  location: T.object.isRequired,
  router: T.object.isRequired,
  selectCurrentCompany: T.func.isRequired,
  fetchUserCompanies: T.func.isRequired,
  resetStore: T.func.isRequired,
};

export default connect(
  null, {
    selectCurrentCompany,
    fetchUserCompanies,
    resetStore,
  }
)(CompanySwitcher);
