import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import CommitmentPicker from 'components/CommitmentPicker/CommitmentPicker';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// requests
import { getAdminPigGroupCommitments, updateAdminPigGroupCommitmentsAssignment } from 'endpoints/admin/pigGroups';
// utils
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const GroupLinkLoads = ({ params: { id: groupId } }) => {
  const [{ commitments, isLoading, initialCommitments }, setState] = useState({
    isLoading: false,
    initialCommitments: [],
    commitments: [],
  });
  const isSameCommitments = initialCommitments.every(({ assigned }, idx) => commitments[idx].assigned === assigned);

  const setCommitments = (responseCommitments) => {
    setState({
      initialCommitments: responseCommitments,
      commitments: responseCommitments,
      isLoading: false,
    });
  };

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getAdminPigGroupCommitments(groupId)
      .then(setCommitments)
      .catch((errors) => {
        toastResponseErrors(errors);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, [groupId]);

  const onSaveChanges = () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const commitmentsToSend = commitments.map((commitment) => ({
      id: commitment.id,
      _destroy: !commitment.assigned,
    }));

    updateAdminPigGroupCommitmentsAssignment(groupId, commitmentsToSend)
      .then(setCommitments)
      .catch(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.linkLoads" />} />
        <Panel.Body className="ph-15">
          <Preloader isActive={isLoading} />
          <CommitmentPicker
            className={cn({ 'small-12 medium-12 large-8': commitments.length })}
            commitments={commitments}
            isLoading={isLoading}
            handleChange={(commitments) => setState((prevState) => ({ ...prevState, commitments }))}
          />
          {!!commitments.length && (
            <Button disabled={isSameCommitments} className="mt-10 mb-20" primary onClick={onSaveChanges}>
              <FormattedMessage id="general.button.save" />
            </Button>
          )}
        </Panel.Body>
      </Panel>
    </section>
  );
};

GroupLinkLoads.propTypes = {
  params: T.object.isRequired,
};

export default GroupLinkLoads;
