import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchSource, enableSource, disableSource } from 'reducers/sources/sourceProfile';
// components
import { FormattedMessage } from 'react-intl';
import SourceProfileHeader from 'components/ProfileHeader/SourceProfileHeader/SourceProfileHeader';
import Subnavigation from 'components/Subnavigation';
// utils
import get from 'lodash.get';
import { showToastrMessage } from 'utils';

const labels = {
  activateSource: <FormattedMessage id="general.activateSource" />,
  deactivateSource: <FormattedMessage id="general.deactivateSource" />,
  sourceOverview: <FormattedMessage id="general.sourceOverview" />,
  sourceInfo: <FormattedMessage id="general.sourceInfo" />,
  treatmentFeedback: <FormattedMessage id="general.pageTitle.treatmentFeedback" />,
  sourceGroups: <FormattedMessage id="general.pageTitle.sourceGroups" />,
};

class SourceProfileContainer extends Component {

  constructor(props) {
    super(props);
    const { params: { id } } = props;
    this.links = [
      { link: `/sources/${id}/overview`, label: labels.sourceOverview },
      { link: `/sources/${id}/groups`, label: labels.sourceGroups },
      { link: `/sources/${id}/treatment-feedback`, label: labels.treatmentFeedback },
      { link: `/sources/${id}/edit`, label: labels.sourceInfo },
    ];
  }

  componentDidMount() {
    const { fetchSource, params: { id } } = this.props;
    fetchSource(id);
  }

  disableSource = () => {
    const { disableSource, params: { id } } = this.props;
    disableSource(id)
      .then(() => {
        showToastrMessage('component.toastr.source.disabled');
      });
  };

  enableSource = () => {
    const { enableSource, params: { id } } = this.props;
    enableSource(id)
      .then(() => {
        showToastrMessage('component.toastr.source.enabled');
      });
  };

  render() {
    const { children, source, isLoading } = this.props;
    const status = get(source, 'status');
    const dropdownOptions = [
      { label: labels.activateSource, onClick: this.enableSource, hide: status === 'active' },
      { label: labels.deactivateSource, onClick: this.disableSource, hide: status !== 'active' },
    ];

    return (
      <div className="SourceProfileContainer clearfix">

        <SourceProfileHeader
          source={source}
          isLoading={isLoading}
          dropdownOptions={dropdownOptions}
        />

        <Subnavigation
          isSticky
          className="small-12 column"
          links={this.links}
        />

        {children}

      </div>
    );
  }
}

SourceProfileContainer.propTypes = {
  source: T.object,
  isLoading: T.bool,
  fetchSource: T.func.isRequired,
  disableSource: T.func.isRequired,
  enableSource: T.func.isRequired,
  params: T.object.isRequired,
  children: T.node,
};

export default connect(
  (state) => ({
    source: state.sources.profile.resource,
    isLoading: state.sources.profile.isLoading,
  }), {
    fetchSource,
    disableSource,
    enableSource,
  }
)(SourceProfileContainer);
