import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { formValueSelector, change, reduxForm } from 'redux-form';
// components
import { Link } from 'react-router';
import Field from 'components/Field';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import LabeledInput from 'components/LabeledInput/LabeledInput';
import InputLabel from 'components/Input/InputLabel';

const LabeledInputField = (data) => (
  <LabeledInput
    input={data.input}
    pattern="[0-9]*"
    placeholder={0}
    alignRight={false}
    isBolded={false}
    label={<FormattedMessage id="general.spaces" />}
  />
);

const BarnForm = ({ handleSubmit, pristine, submitting, params: { id: farmId }, name, isEditMode }) => (
  <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="small-12 column">
        <label>
          <Field
            name="name"
            label={<FormattedMessage id="general.placeholder.barnName" />}
            placeholderKey="general.placeholder.barnName"
          />
        </label>
      </div>
    </div>
    <div className="row">
      <div className="small-12 medium-4 column">
        <label>
          <InputLabel>
            <FormattedMessage id="component.barnForm.capacity" />
          </InputLabel>
          <Field
            name="capacity"
            component={LabeledInputField}
          />
        </label>
      </div>
    </div>

    <div className="row mt-30">
      <div className="small-12 column">
        <Button
          primary
          type="submit"
          className="button mb-0"
          disabled={pristine || submitting || !name}
        >
          <FormattedMessage id={`general.${isEditMode ? 'button.saveChanges' : 'createBarn'}`} />
        </Button>
        <Link to={`/admin/farms/${farmId}/barns`} className="button btn-link margin-left-1">
          <FormattedMessage id="general.button.cancel" />
        </Link>
      </div>
    </div>
  </form>
);

BarnForm.propTypes = {
  handleSubmit: T.func.isRequired,
  params: T.object.isRequired,
  isEditMode: T.bool,
  name: T.string,
  pristine: T.bool,
  submitting: T.bool,
};

const selector = formValueSelector('barn-form');

const formComponent = reduxForm({
  form: 'barn-form',
})(BarnForm);

export default connect((state) => ({
  name: selector(state, 'name'),
}), { change })(formComponent);
