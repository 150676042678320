import React, { Component } from 'react';
import T from 'prop-types';
// react, redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fetchFarm, fetchFarmCheckins } from 'reducers/checkins/farmCheckins';
// hoc
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import withDropdownActions from 'hoc/withDropdownActions';
// components
import Link from 'react-router/lib/Link';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import PaginationWrapper from 'components/PaginationWrapper';
import SearchBox from 'components/SearchBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import UserAvatar from 'components/UserAvatar';
import NothingBox from 'components/NothingBox';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn, ArrowColumn } from 'components/DataTable/Columns';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import VerticalAligner from 'components/VerticalAligner';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
// utils
import { timeFormatter } from 'utils/checkinsHelper';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { isTablet, isMobile } from 'react-device-detect';
// assets
import '../Checkins.scss';

class FarmCheckins extends Component {

  componentDidMount() {
    const { fetchFarm, fetchFarmCheckins, params: { farmId }, params } = this.props;

    fetchFarm(farmId).then(() => {
      fetchFarmCheckins({ ...params, sort: 'created_at desc' });
    });
  }

  renderUserNameColumn = ({ user, user: { full_name } }) => (
    <CustomColumn>
      <VerticalAligner>
        <UserAvatar user={user} size={35} className="mr-10" />
        <div className="semibold">{full_name}</div>
      </VerticalAligner>
    </CustomColumn>
  );

  renderLastCheckinColumn = ({ created_at }) => (
    <CustomColumn>
      <TimeAgo date={created_at} formatter={timeFormatter} />
    </CustomColumn>
  );

  renderStatusColumn = ({ farm: { active } }) => (
    <CustomColumn>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderTabletButtons = (checkin) => {
    const { user } = this.props;
    return (
      <div className="collapsable-buttons-row show-for-large">
        <Link to={`/farmfeed/activities/${checkin?.last_activity_id}`} className="button light">
          <i className="fa fa-eye" />
          <FormattedMessage id="general.button.viewActivity" />
        </Link>
        {user.roles_map.admin && (
          <Link to={`/admin/users/${checkin.user.id}`} className="button light">
            <i className="fa fa-gear" />
            <FormattedMessage id="general.manageUser" />
          </Link>
        )}
      </div>
    );
  };

  renderButtons = (checkin, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${checkin.id}-${rowIndex}`}
        wide
        buttonType="small light-gray"
        label={<FormattedMessage id="general.button.viewActivity" />}
        url={`/farmfeed/activities/${checkin?.last_activity_id}`}
        dropDownData={[{
          label: <FormattedMessage id="general.manageUser" />,
          url: `/admin/users/${checkin.user.id}`,
          hide: !this.props.user.roles_map.admin,
        }]}
      />
    </div>
  );

  renderMobileTable() {
    const { resources, isLoading, reqParams: { search } } = this.props;

    return (
      <div className="CheckinsTable">
        <div className="mobile-table">
          {resources.map((checkin) => (
            <div className="mobile-row" key={checkin.id}>
              <div className="avatar">
                <UserAvatar user={checkin.user} size={35} />
              </div>
              <div className="info">
                <div className="name">{checkin.user.full_name}</div>
                <div className="description">
                  <span><TimeAgo date={checkin.created_at} formatter={timeFormatter} /></span>
                </div>
              </div>
              <i
                data-name={`${checkin.id}`}
                className="fa fa-dots-three-horizontal dropdown-icon"
                onClick={(e) => this.openDropdown(e, checkin)}
              />
            </div>
          ))}
        </div>

        <NothingBox
          itemsName="checkins"
          display={!resources.length}
          isLoading={isLoading}
          search={search}
        >
          <FormattedMessage id="component.nothingBox.noFarmCheckins" tagName="h1" />
        </NothingBox>
      </div>
    );
  }

  openDropdown = (e, checkin) => {
    const { openDropdown, user: { roles_map: { admin } } } = this.props;
    const options = [
      { label: <FormattedMessage id="general.button.viewUserCheckInHistory" />,
        url: `/checkins/users/${checkin.user.id}` },
      { label: <FormattedMessage id="general.manageUser" />, url: `/admin/users/${checkin.user.id}`, hide: !admin },
    ];
    openDropdown(e, options);
  };

  renderTitle = () => (
    <h1 className="lighter show-for-large"><FormattedMessage id="general.checkins" /></h1>
  );

  render() {
    const { farm, isFarmLoading, isLoading, resources, meta: { total }, reqParams: { page, per_page, search, sort },
      onSortChange, onPageChange, onPerPageChange, onSearchChange, params: { farmId }, user } = this.props;

    const columns = [
      {
        label: <FormattedMessage id="component.dataTable.headers.userName" />,
        sortKey: 'user_name',
        flex: '2 1 220px',
        renderer: this.renderUserNameColumn,
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.checkInsLast" />,
        sortKey: 'created_at',
        flex: '1 1 180px',
        renderer: this.renderLastCheckinColumn,
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.userStatus" />,
        sortKey: 'active',
        flex: '1 1 180px',
        renderer: this.renderStatusColumn,
      },
      { flex: '0 0 175px', renderer: this.renderButtons, hide: isTablet, fixed: true },
      { flex: '0 0 40px', renderer: () => <ArrowColumn />, hide: !isTablet, fixed: true,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <div className={cn({ isMobile })}>
        <FarmProfileHeader
          farm={farm}
          isLoading={isFarmLoading}
          actualFarmId={Number(farmId)}
          dropdownOptions={[
            { label: <FormattedMessage id="general.button.viewFarmOnMap" />,
              url: `/checkins/${farm?.last_check_in_id}`, hide: !farm?.last_check_in_id },
            { label: <FormattedMessage id="general.manageFarm" />, url: `/admin/farms/${farm?.id}`,
              hide: !user.roles_map.admin },
          ]}
        >
          <ProfileHeaderStats
            stats={[{ label: <FormattedMessage id="general.checkins" />, value: (total || 'n/a') }]}
            className="show-for-large"
          />
        </FarmProfileHeader>

        <section className="CheckinsPanelSection farm-checkins">
          <Panel className="checkins-panel">
            <Panel.Heading
              className="checkins-panel-header"
              renderTitle={this.renderTitle}
            >
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>

            <Panel.Body className="show-for-large checkins-panel-body" noPadding>
              <Preloader isActive={isLoading} />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderTabletButtons}
                scrollable
              />

              <NothingBox
                itemsName="checkins"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <FormattedMessage tagName="h1" id="component.nothingBox.noFarmCheckins" />
              </NothingBox>

            </Panel.Body>

            <Panel.Body className="hide-for-large checkins-panel-body" noPadding>
              <Preloader isActive={isLoading} />

              <PaginationWrapper
                onlyBottom
                totalItems={total}
                currentPage={page}
                onPageChange={onPageChange}
                perPage={per_page}
                onPerPageChange={onPerPageChange}
              >
                {this.renderMobileTable()}
              </PaginationWrapper>

            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

FarmCheckins.propTypes = {
  resources: T.array,
  isLoading: T.bool.isRequired,
  params: T.object.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  user: T.object.isRequired,
  farm: T.object,
  isFarmLoading: T.bool,
  fetchFarm: T.func.isRequired,
  fetchFarmCheckins: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  openDropdown: T.func.isRequired,
};

FarmCheckins.contextTypes = {
  isMobile: T.bool,
  isTablet: T.bool,
};


const enhance = compose(
  connect(
    (state) => ({
      resources: state.checkins.farmCheckins.resources,
      meta: state.checkins.farmCheckins.meta,
      reqParams: state.checkins.farmCheckins.params,
      farm: state.checkins.farmCheckins.resource || {},
      user: state.auth.user,
      isLoading: state.checkins.farmCheckins.isLoading,
      isFarmLoading: state.checkins.farmCheckins.isFarmLoading,
    }),
    (dispatch, { params }) => ({
      fetchFarmCheckins: (args) => dispatch(fetchFarmCheckins(params.farmId, args)),
      fetchFarm: (id) => dispatch(fetchFarm(id)),
    })
  ),
  withDropdownActions,
  withDataTableController('fetchFarmCheckins', 'reqParams'),
);

export default enhance(FarmCheckins);
