import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { formValueSelector, change, reduxForm } from 'redux-form';
// components
import { Link } from 'react-router';
import Field from 'components/Field';
import Button from 'components/Button';
import CircleCheckbox from 'components/CircleCheckbox';
import UserFormFields from 'components/ReduxFormFields/UserFormFields/UserFormFields';
import { FormattedMessage } from 'react-intl';

const companiesUserRoles = [
  {
    value: 'owner',
    label: <FormattedMessage id="general.role.primary" />,
    description: <FormattedMessage id="component.createCompanyForm.roles.primaryDesc" />,
  }, {
    value: 'caretaker',
    label: <FormattedMessage id="general.role.caregiver" />,
    description: <FormattedMessage id="component.createCompanyForm.roles.caregiverDesc" />,
  }, {
    value: 'manager',
    label: <FormattedMessage id="general.role.farmManager" />,
    description: <FormattedMessage id="component.createCompanyForm.roles.managerDesc" />,
  },
];

const customErrors = [
  { key: 'email', error: <FormattedMessage id="general.customErrors.email" /> },
];

class CreateCompanyForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      wantUser: false,
      isFormValid: false,
    };
  }

  setFormValidity = (isValid) => this.setState({ isFormValid: isValid });

  toggleUserWant = () => this.setState(({ wantUser }) => ({ wantUser: !wantUser }));

  render() {
    const { handleSubmit, pristine, submitting, ...props } = this.props;
    const { wantUser, isFormValid } = this.state;

    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="small-12 column">
            <label>
              <Field
                placeholderKey="general.typeAName"
                label={<FormattedMessage id="general.companyName" />}
                name="name"
              />
            </label>
          </div>
        </div>
        <div className="row margin-bottom-2 margin-top-1">
          <div className="small-12 column">
            <CircleCheckbox
              type="radio"
              value="false"
              onClick={this.toggleUserWant}
              checked={wantUser}
              label={<FormattedMessage id="component.createCompanyForm.newUserAccount.text" />}
            />
          </div>
        </div>

        {/* USER FORM */}
        {wantUser && (
          <UserFormFields
            wrap="user"
            headerTitle={<FormattedMessage id="component.createCompanyForm.newUserAccount" />}
            roles={companiesUserRoles}
            rolesTitle={<FormattedMessage id="component.userFieldsMethods.userRolesAtCompany" />}
            customErrors={customErrors}
            setFormValidity={this.setFormValidity}
            {...props}
          />
        )}

        {/* Button */}
        <div className="row mt-30">
          <div className="small-12 column">
            <Button
              primary
              type="submit"
              className="button mb-0"
              disabled={pristine || submitting || (wantUser && !isFormValid)}
            >
              <FormattedMessage id={`general.button.create${wantUser ? 'CompanyAndUser' : 'Company'}`} />
            </Button>
            <Link to="/admin/companies" className="button btn-link margin-left-1">
              <FormattedMessage id="general.button.cancel" />
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

CreateCompanyForm.propTypes = {
  handleSubmit: T.func.isRequired,
  email: T.string,
  first_name: T.string,
  last_name: T.string,
  nickname: T.string,
  pristine: T.bool,
  submitting: T.bool,
};

const selector = formValueSelector('create-company-by-admin');

const formComponent = reduxForm({
  form: 'create-company-by-admin',
})(CreateCompanyForm);

export default connect((state) => ({
  email: selector(state, 'user[email]'),
  first_name: selector(state, 'user[first_name]'),
  last_name: selector(state, 'user[last_name]'),
  nickname: selector(state, 'user[username]'),
}), { change })(formComponent);
