import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openDropdown } from 'reducers/dropdownLayout';
// components
import { Link } from 'react-router';
// utils
import cn from 'classnames';
// assets
import './DropdownButton.scss';

function onDropdownToggle(e, options, action, ignoreScroll) {
  const dataName = e.target.dataset.name;
  const childrenCollection = e.target.children;
  const coords = childrenCollection.length
    ? childrenCollection[0].getBoundingClientRect()
    : e.target.getBoundingClientRect();
  return action(options, coords, dataName, ignoreScroll);
}

const DropdownButton = ({
  idKey,
  buttonType,
  label,
  onClick,
  url,
  dropDownData,
  customClass,
  wide,
  wideOnTablet,
  openDropdown,
  ignoreScroll,
}) => {
  const visibleOptions = dropDownData.filter(({ hide }) => !hide);
  const leftBtnClass = cn('button', buttonType, { 'left': !!visibleOptions.length });

  return (
    <span className={cn('DropdownButton', customClass, { 'flex': wide, 'flex-tablet': wideOnTablet })}>
      {url &&
        <Link to={url} className={leftBtnClass}>{label}</Link>}
      {!url && (typeof onClick === 'function') &&
        <button onClick={onClick} className={leftBtnClass}>{label}</button>}
      {!!visibleOptions.length && (
        <button
          data-name={idKey}
          onClick={(e) => onDropdownToggle(e, visibleOptions, openDropdown, ignoreScroll)}
          className={cn('button right', buttonType)}
        >
          <i data-name={idKey} className="fa fa-angle-down" />
        </button>
      )}
    </span>
  );
};

DropdownButton.propTypes = {
  buttonType: T.string,
  idKey: T.oneOfType([T.string, T.number]),
  label: T.oneOfType([T.string, T.node]),
  customClass: T.string,
  url: T.string,
  onClick: T.func,
  wide: T.bool,
  ignoreScroll: T.bool,
  wideOnTablet: T.bool,
  openDropdown: T.func.isRequired,
  dropDownData: T.array.isRequired,
};

DropdownButton.defaultProps = {
  buttonType: '',
};

export default connect(null, { openDropdown })(DropdownButton);
