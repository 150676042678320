import React, { useState } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CheckIcon } from 'assets/svg/check.svg';
// utils
import cn from 'classnames';
import pickBy from 'lodash.pickby';
// styles
import styles from './ConditionSelector.module.scss';

const SimpleConditionSelector = ({ conditions, onBack, onSave, selectedSection, title }) => {
  const [condition, setCondition] = useState(selectedSection.condition);
  const handleSave = () => {
    onSave(pickBy({
      id: selectedSection.id,
      sectionIndex: selectedSection.sectionIndex,
      type: selectedSection.type,
      condition,
    }));
  };
  return (
    <MobileListPickerComponent
      title={title}
      onBack={onBack}
      headerRightContent={(
        <Button disabled={!condition || condition === selectedSection.condition} primary small onClick={handleSave}>
          <FormattedMessage id="general.button.done" />
        </Button>
      )}
    >
      {conditions.map((option) => (
        <div
          className={cn(styles['boolean-condition-row'], { [styles['is-selected']]: option.value === condition })}
          key={option.value}
          onClick={() => setCondition(option.value)}
        >
          {option.label}
          {option.value === condition && <CheckIcon className={styles['check-icon']} height={20} />}
        </div>
      ))}
    </MobileListPickerComponent>
  );
};

SimpleConditionSelector.propTypes = {
  conditions: T.array.isRequired,
  selectedSection: T.object.isRequired,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
  title: T.string.isRequired,
};

export default SimpleConditionSelector;
