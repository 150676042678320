import React, { Component } from 'react';
import T from 'prop-types';
// components
import MobileHeader from 'components/MobileHeader/MobileHeader';
import SearchBox from 'components/SearchBox';
import MobileMovementRow from 'components/DailyCheckups/MobileMovementRow';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
// styles
import './MobilePortalSelector.scss';

/*
* multiSelectMode -
* when we fill an empty pig_movements we must be able to select few types of movement at once (1406)
* but when we select already existed movement type it should be considerated like changing type of current movement.
* */

class MobilePortalSelector extends Component {

  state = {
    search: '',
    multiOptions: [],
  };

  onSearchChange = (search) => {
    this.setState({ search });
  };

  selectOption = (option) => () => {
    const { setValue, closePortal, multiSelectMode } = this.props;
    const { multiOptions } = this.state;

    if (multiSelectMode) {
      this.setState({
        multiOptions: multiOptions.find(({ value }) => value === option?.value)
          ? multiOptions.filter(({ value }) => value !== option.value)
          : [...multiOptions, option],
      });
    } else {
      setValue(option);
      closePortal();
    }
  };

  closePortal = () => {
    const { closePortal } = this.props;
    closePortal();
  };


  onDoneButtonClick = () => {
    const { onMultiSelectionClose, closePortal } = this.props;
    const { multiOptions } = this.state;
    onMultiSelectionClose(multiOptions);
    closePortal();
  };

  render() {
    const { title, options, currentValue, multiSelectMode } = this.props;
    const { search, multiOptions } = this.state;
    const filteredOptions = search
      ? options.filter((option) => option.label.toLowerCase().includes(search))
      : options;

    return (
      <div className="MobilePortalSelector">
        <MobileHeader title={title} backLink={this.closePortal}>
          {multiSelectMode && (
            <div className="mobile-btn">
              <Button disabled={!multiOptions.length} primary small onClick={this.onDoneButtonClick}>
                <FormattedMessage id="general.button.next" />
              </Button>
            </div>
          )}
        </MobileHeader>

        <div className="mobile-selector-data">
          <div className="mobile-selector-search">
            <SearchBox initialValue={search} onChange={this.onSearchChange} className="mobile-selector-search-box" />
          </div>

          <div className="options-list-wrapper">
            {filteredOptions.map((option, index) => (
              <MobileMovementRow
                key={index}
                isSelected={multiSelectMode
                  ? !!multiOptions.find(({ value }) => value === option.value)
                  : option.value === currentValue
                }
                isHighlighted={!multiSelectMode && option.value === currentValue}
                onClick={this.selectOption(option)}
                icon={option.icon}
                label={option.label}
                description={option.description}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

MobilePortalSelector.propTypes = {
  title: T.object.isRequired,
  options: T.arrayOf(T.shape({
    value: T.string.isRequired,
    label: T.string.isRequired,
    description: T.oneOfType([T.string, T.node]),
    icon: T.string,
  })).isRequired,
  currentValue: T.oneOfType([T.string, T.number, T.bool]),
  setValue: T.func,
  closePortal: T.func.isRequired,
  multiSelectMode: T.bool,
  onMultiSelectionClose: T.func,
};

export default MobilePortalSelector;
