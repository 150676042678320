import { createAction, handleActions } from 'redux-actions';
import { getSVRsFarms } from 'endpoints/svr';

// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  page: 1,
  per_page: 25,
};

const initialState = {
  resources: [],
  isLoading: false,
  params: {
    ...defaultParams,
    search: '',
  },
};

const FETCH_FARMS = 'siteVisitReports/FETCH_FARMS';
const [FETCH_FARMS_PENDING, FETCH_FARMS_FULFILLED, FETCH_FARMS_REJECTED] = [
  `${FETCH_FARMS}_PENDING`,
  `${FETCH_FARMS}_FULFILLED`,
  `${FETCH_FARMS}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchSVRFarms = (params = defaultParams) => (dispatch) => {
  dispatch(createAction(FETCH_FARMS_PENDING)());
  return getSVRsFarms(params)
    .then((response) => {
      dispatch(createAction(FETCH_FARMS_FULFILLED)({ ...response, params }));
      return response;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_FARMS_REJECTED)(response));
      throw response;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_FARMS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_FARMS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [FETCH_FARMS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
