import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closePortalRight } from 'reducers/rightToLeftPortal';
// hooks
import usePortalState from 'utils/hooks/usePortalState';
// other
import cn from 'classnames';
import './RootPortal.scss';

const RootRightToLeftPortal = ({ component, closePortalRight, options, pathname }) => {
  const [{ isVisible, content }] = usePortalState(component);

  useEffect(() => {
    if (component) closePortalRight();
  }, [pathname]);

  return (
    <div
      className={cn('RootNodePortal right-to-left', { isOpen: isVisible, 'has-footer-area': options.hasFooterArea })}
    >
      {content
        ? React.cloneElement(content, { closePortal: closePortalRight })
        : null}
    </div>
  );
};

RootRightToLeftPortal.propTypes = {
  closePortalRight: T.func.isRequired,
  options: T.object.isRequired,
  component: T.element,
  pathname: T.string,
};

export default connect(
  (state) => ({
    options: state.rightToLeftPortal.options,
    component: state.rightToLeftPortal.component,
  }),
  { closePortalRight }
)(RootRightToLeftPortal);
