import React, { Component } from 'react';
import T from 'prop-types';
// components
import { DayPickerRangeController as DayPicker } from 'react-dates';
import { START_DATE, END_DATE, VERTICAL_ORIENTATION } from 'react-dates/constants';
import DatePickerMobileBox from 'components/DatePicker/DatePickerMobileBox';
// utils
import cn from 'classnames';
import moment from 'moment';
// styles
import '../styles/DatePickerMobile.scss';

class WeeklyDatePickerMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      focusedInput: END_DATE,
    };
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  onFocusChange = (focusedInput) => {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  };

  clearDates = () => this.setState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });

  submitDates = () => {
    const { onChange, closePortal } = this.props;
    const { startDate, endDate } = this.state;
    onChange({ startDate, endDate });
    closePortal();
  };

  render() {
    const { className, closePortal, isOutsideRange, startDateOffset, endDateOffset } = this.props;
    const { startDate, endDate, focusedInput } = this.state;
    const initialVisibleMonth = () => moment().subtract(2, 'weeks');

    return (
      <DatePickerMobileBox
        className={cn('DatePickerMobile', { [className]: !!className })}
        onClose={closePortal}
        onClearDates={this.clearDates}
        onSubmit={this.submitDates}
        {...this.state}
      >
        <DayPicker
          startDate={startDate}
          endDate={endDate}
          focusedInput={focusedInput}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          numberOfMonths={2}
          orientation={VERTICAL_ORIENTATION}
          isOutsideRange={isOutsideRange}
          initialVisibleMonth={initialVisibleMonth}
          daySize={42}
          startDateOffset={startDateOffset}
          endDateOffset={endDateOffset}
        />
      </DatePickerMobileBox>
    );
  }
}

WeeklyDatePickerMobile.contextTypes = {
  isMobile: T.bool,
};

WeeklyDatePickerMobile.propTypes = {
  className: T.string,
  initialStartDate: T.object,
  initialEndDate: T.object,
  onChange: T.func,
  isOutsideRange: T.func,
  startDateOffset: T.func,
  endDateOffset: T.func,
  closePortal: T.func,
};

export default WeeklyDatePickerMobile;
