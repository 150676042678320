import React from 'react';
import T from 'prop-types';
import classnames from 'classnames/bind';
// components
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// styles
import styles from './SynchroniseModal.module.scss';

const cn = classnames.bind(styles);

class ExpandSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  toggleExpand = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  renderTitle = (success, amount) => (
    <FormattedMessage
      values={{
        amount,
        b: (msg) => <b>{msg}</b>
      }}
      id={`component.groups.synchronization.${success ? 'success' : 'failure'}`}
    />
  )

  render() {
    const { expanded } = this.state;
    const { icon, success, groups } = this.props;

    return (
      <div className={cn('expand-container')}>
        <div className={cn('expand-line')}>
          {icon}
          <p>
            {this.renderTitle(success, groups.length)}
          </p>
          <Button
            onClick={this.toggleExpand}
          >
            <i className={cn(`fa fa-angle-${expanded ? 'down' : 'right'}`)} />
          </Button>
        </div>
        <div className={cn('row-expand', { expanded })}>
          <p>
            {groups.map((group) => group.group_name).join(' • ')}
          </p>
        </div>
      </div>
    );
  }
}

ExpandSection.propTypes = {
  icon: T.node.isRequired,
  success: T.bool.isRequired,
  groups: T.array.isRequired,
};

export default ExpandSection;
