import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import SingleDatePickerMobile from 'components/DatePicker/SingleDatePicker/SingleDatePickerMobile';
import DateRangePickerMobile from 'components/DatePicker/DateRangePicker/DateRangePickerMobile';
import InputConditionCheckBox from './ConditionItems/InputConditionCheckBox';
import NameConditionCheckBox from './ConditionItems/NameConditionCheckBox';
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
import MobilePortalOverlay from 'components/MobilePortalOverlay';
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import pickBy from 'lodash.pickby';
import { formatOptionsValues, isAbsoluteDateCondition, isRelativeDateCondition } from 'utils/userFiltersHelper';
import { sectionOptionsDateFormat, userFilterSectionTypes } from 'utils/constants/userFilters';
// styles
import styles from './ConditionSelector.module.scss';

const isOutsideRange = (sectionType) => (day) => {
  return sectionType === userFilterSectionTypes.arriveAt ? false : day.isAfter(moment(), 'day');
};

const DateConditionSelector = ({ conditions, onBack, onSave, selectedSection, title }) => {
  const optionsValues = formatOptionsValues(selectedSection.options);
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);
  const [isDateRangePickerOpened, setIsDateRangePickerOpened] = useState(false);
  const [{ condition, date, dateEnd, dateStart, days }, setState] = useState({
    condition: selectedSection.condition,
    days: optionsValues.days || '',
    date: optionsValues.date || null,
    dateStart: optionsValues.date_start || null,
    dateEnd: optionsValues.date_end || null,
  });

  const getDateRangeValue = () => {
    return dateStart && dateEnd ? dateStart + ' - ' + dateEnd : undefined;
  };

  const isDoneDisabled = () => {
    if (condition === 'range') {
      const isSameValues = condition === selectedSection.condition && dateStart === optionsValues.date_start
        && dateEnd === optionsValues.date_end;
      return !dateStart || !dateEnd || isSameValues;
    }
    if (isAbsoluteDateCondition(condition)) {
      const isSameValues = condition === selectedSection.condition && date === optionsValues.date;
      return !date || isSameValues;
    }
    if (isRelativeDateCondition(condition)) {
      const isSameValues = condition === selectedSection.condition && days === optionsValues.days;
      return !days || isSameValues;
    }
    return true;
  };

  const handleSave = () => {
    const getOptions = () => {
      if (condition === 'range') {
        return [
          { key: 'date_start', value: dateStart },
          { key: 'date_end', value: dateEnd },
        ];
      }
      if (isAbsoluteDateCondition(condition)) {
        return [{ key: 'date', value: date }];
      }
      if (isRelativeDateCondition(condition)) {
        return [{ key: 'days', value: days }];
      }
      return undefined;
    };
    onSave(pickBy({
      condition,
      options: getOptions(),
      id: selectedSection.id,
      sectionIndex: selectedSection.sectionIndex,
      type: selectedSection.type,
    }));
  };

  const handleChangeCondition = ({ target: { name } }) => {
    setState({ condition: name, days: '', date: null, dateStart: null, dateEnd: null });
  };

  const handleChangeInputValue = ({ target: { value, validity } }) => {
    if (!validity.valid) return;
    setState((prevState) => ({ ...prevState, days: value }));
  };

  const handleChangeDate = (date) => {
    setState((prevState) => ({ ...prevState, date: date.format(sectionOptionsDateFormat) }));
  };

  const handleChangeDatesRange = ({ startDate, endDate }) => {
    setState((prevState) => ({
      ...prevState,
      dateStart: startDate ? startDate.format(sectionOptionsDateFormat) : null,
      dateEnd: endDate ? endDate.format(sectionOptionsDateFormat) : null,
    }));
  };

  return (
    <Fragment>
      <MobileListPickerComponent
        title={title}
        onBack={onBack}
        headerRightContent={(
          <Button disabled={isDoneDisabled()} primary small onClick={handleSave}>
            <FormattedMessage id="general.button.done" />
          </Button>
        )}
      >
        <div className={styles.checkboxes}>
          {conditions.map((option, index) => {
            const isRelative = isRelativeDateCondition(option.value);
            const isAbsolute = isAbsoluteDateCondition(option.value);
            return (
              <Fragment key={index}>
                {!option.value && <div className={styles['conditions-sub-title']}>{option.label}</div>}
                {isRelative && (
                  <InputConditionCheckBox
                    conditionValue={condition}
                    conditionInputValue={days}
                    option={option}
                    onConditionValueChange={handleChangeCondition}
                    onConditionInputValueChange={handleChangeInputValue}
                    inputPlaceholder="0"
                    inputLabel={<FormattedMessage id="general.daysAgo" />}
                    minInputValue={0}
                    maxInputValue={999999}
                  />
                )}
                {isAbsolute && (
                  <NameConditionCheckBox
                    conditionValue={condition}
                    nameValue={condition === option.value ? date : undefined}
                    namePlaceholder={<FormattedMessage id="general.selectDate" />}
                    onConditionValueChange={handleChangeCondition}
                    onNameClick={() => setIsDatePickerOpened(true)}
                    option={option}
                  />
                )}
                {option.value === 'range' && (
                  <NameConditionCheckBox
                    conditionValue={condition}
                    nameValue={condition === option.value ? getDateRangeValue() : undefined}
                    namePlaceholder={<FormattedMessage id="general.selectDates" />}
                    onConditionValueChange={handleChangeCondition}
                    onNameClick={() => setIsDateRangePickerOpened(true)}
                    option={option}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </MobileListPickerComponent>
      <MobilePortalOverlay isHidden={!isDatePickerOpened && !isDateRangePickerOpened}>
        {isDatePickerOpened && (
          <SingleDatePickerMobile
            initialDate={date ? moment(date, sectionOptionsDateFormat) : null}
            onChange={handleChangeDate}
            isOutsideRange={isOutsideRange(selectedSection.type)}
            closePortal={() => setIsDatePickerOpened(false)}
          />
        )}
        {isDateRangePickerOpened && (
          <DateRangePickerMobile
            initialStartDate={dateStart ? moment(dateStart, sectionOptionsDateFormat) : null}
            initialEndDate={dateEnd ? moment(dateEnd, sectionOptionsDateFormat) : null}
            onChange={handleChangeDatesRange}
            isOutsideRange={isOutsideRange(selectedSection.type)}
            minimumNights={1}
            closePortal={() => setIsDateRangePickerOpened(false)}
          />
        )}
      </MobilePortalOverlay>
    </Fragment>
  );
};

DateConditionSelector.propTypes = {
  conditions: T.array.isRequired,
  selectedSection: T.object.isRequired,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
  title: T.string.isRequired,
};

export default DateConditionSelector;
