import React, { Component } from 'react';
import T from 'prop-types';
// react, redux
import { connect } from 'react-redux';
import { fetchUserProfile } from 'reducers/profiles';
// components
import UserCheckinsTable from './UserCheckinsTable';
import UserProfileHeader from 'components/ProfileHeader/UserProfileHeader/UserProfileHeader';
import ModeSwitcher from '../components/ModeSwitcher/ModeSwitcher';
import UserCheckinsMap from './UserCheckinsMap';
// utils
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// assets
import '../Checkins.scss';

class UserCheckins extends Component {

  componentDidMount() {
    const { params: { userId }, fetchUserProfile } = this.props;
    fetchUserProfile(`/users/${userId}`).catch(toastResponseErrors);
  }

  render() {
    const { currentUser: { roles_map: { admin } }, user, isLoading, location, params: { userId } } = this.props;
    const { isMobile } = this.context;
    const isMapMode = location.pathname.includes('map');
    return (
      <div className={cn('CheckinsSection', { isMobile })}>
        <UserProfileHeader
          actualUserId={Number(userId)}
          user={user}
          isLoading={isLoading}
          dropdownOptions={[
            { label: <FormattedMessage id="general.manageUser" />, url: `/admin/users/${user.id}`, hide: !admin },
          ]}
        >
          <ModeSwitcher
            tableLink={`/checkins/users/${userId}`}
            mapLink={`/checkins/users/${userId}/map`}
          />
        </UserProfileHeader>

        {isMapMode ? <UserCheckinsMap user={user} userId={userId} /> : <UserCheckinsTable userId={userId} />}
      </div>
    );
  }
}

UserCheckins.propTypes = {
  params: T.object.isRequired,
  user: T.object,
  currentUser: T.object,
  location: T.object.isRequired,
  fetchUserProfile: T.func.isRequired,
  isLoading: T.bool.isRequired,
};

UserCheckins.contextTypes = {
  isMobile: T.bool,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    user: state.profiles.user.data,
    isLoading: state.profiles.user.isLoading,
  }), { fetchUserProfile }
)(UserCheckins);
