import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import AsyncMultiSelect from 'components/AsyncMultiSelect';
import Panel from 'components/Panel';

const splitGenetics = (genetics = []) => {
  const maleGenetics = [];
  const femaleGenetics = [];
  genetics.forEach(({ genetic, ...rest }) => {
    if (genetic.genetic_type === 'male') {
      maleGenetics.push({ genetic, ...rest });
      return;
    }
    femaleGenetics.push({ genetic, ...rest });
  });
  return { maleGenetics, femaleGenetics };
};

const GeneticsPanel = ({
  genetics = [],
  updateFarm,
}) => {
  const addGenetic = (data) => {
    updateFarm({ farm_genetics_attributes: [{ genetic_id: data.value }] });
  };

  const removeGenetic = (data) => {
    updateFarm({ farm_genetics_attributes: [{ id: data.id, _destroy: true }] });
  };

  const commonGeneticsSelectProps = {
    labelRenderer: ({ name, provider }) => `${name} (${provider})`,
    onSelect: addGenetic,
    onRemove: removeGenetic,
    optionKeys: { value: 'id', label: 'name' },
    placeholderKey: 'component.searchbox.placeholder',
    extractLabel: ({ genetic: { name, provider } }) => `${name} (${provider})`,
    selectClassName: 'mt-10',
  };

  const { maleGenetics, femaleGenetics } = splitGenetics(genetics);

  return (
    <Panel className="medium-12 xlarge-8 mb-15">
      <Panel.Heading
        title={<FormattedMessage id="general.genetics" />}
      />
      <Panel.Body className="ph-20">
        <div className="medium-6 xlarge-4">
          <AsyncMultiSelect
            title={<FormattedMessage id="general.femaleGeneticOptional" />}
            values={femaleGenetics}
            optionsPath="/genetics?type=female"
            {...commonGeneticsSelectProps}
          />
          <AsyncMultiSelect
            title={<FormattedMessage id="general.maleGeneticOptional" />}
            values={maleGenetics}
            optionsPath="/genetics?type=male"
            {...commonGeneticsSelectProps}
          />
        </div>
      </Panel.Body>
    </Panel>
  );
};

GeneticsPanel.propTypes = {
  updateFarm: T.func.isRequired,
  genetics: T.array,
};

export default GeneticsPanel;
