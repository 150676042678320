import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Button from 'components/Button';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import Avatar from 'components/Avatar/Avatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// endpoints
import { getTruckingCompanies, updateTruckingCompany } from 'endpoints/admin/truckingCompanies';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import { showToastrMessage, formatLocation } from 'utils';
import { isTablet } from 'react-device-detect';
import { tableNames } from 'utils/constants';

const tableFilters = [
  { label: <FormattedMessage id="general.allCompanies" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
];

const TruckingCompanies = (props) => {
  const { reqParams, setTableParams } = props;

  const {
    data: { resources, isLoading, meta: { stats, total } },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
    onSortChange,
    onStatusChange
  } = useDataTable(getTruckingCompanies, {
    tableParams: reqParams,
    setTableParams: (params) => setTableParams(tableNames.truckingCompanies, params)
  });
  const { page, per_page, status, search, sort } = reqParams;

  useEffect(() => {
    fetchData(reqParams).catch(toastResponseErrors);
  }, []);

  const updateCompany = (id, resource) => {
    updateTruckingCompany(id, resource)
      .then((company) => {
        fetchData(reqParams).catch(toastResponseErrors);
        showToastrMessage(`component.toastr.trucking.${company.active ? 'enabled' : 'disabled'}`);
      })
      .catch(toastResponseErrors);
  };

  const renderNameColumn = (rowData) => (
    <CustomColumn flexRow>
      <Avatar type="trucking-company" avatarSize={35} iconSize={16} className="mr-10" />
      <span className="semibold">{rowData.name}</span>
    </CustomColumn>
  );

  const renderUsersCountColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.users" />}>
      {rowData.roles_count || 0}
    </CustomColumn>
  );

  const renderLocationColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.location" />}>
      {formatLocation(rowData.city, rowData.state)}
    </CustomColumn>
  );

  const renderStatusColumn = (rowData) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={rowData.active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  const renderActionsColumn = (rowData, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${rowData.id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.edit" />}
        buttonType="small light-gray"
        url={`/admin/trucking-companies/${rowData.id}/user-roles`}
        dropDownData={[
          {
            label: <FormattedMessage id={`general.button.${rowData.active ? 'disable' : 'enable'}TruckingCompany`} />,
            onClick: () => updateCompany(rowData.id, { active: !rowData.active }),
          },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/trucking-companies/${rowData.id}/user-roles`} className="button light hide-for-large">
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button
        lightGray
        onClick={() => updateCompany(rowData.id, { active: !rowData.active })}
        className="hide-for-large"
      >
        <FormattedMessage id={`general.button.${rowData.active ? 'disable' : 'enable'}TruckingCompany`} />
      </Button>
    </div>
  );

  const renderExpandable = (rowData) => (
    <div>
      <Link to={`/admin/trucking-companies/${rowData.id}/user-roles`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.button.edit" />
      </Link>
      {!rowData.active && (
        <Button small light onClick={() => updateCompany(rowData.id, { active: true })}>
          <i className="fa fa-check-circle mr-5" />
          <FormattedMessage id="general.button.enableTruckingCompany" />
        </Button>
      )}
      {rowData.active && (
        <Button small light onClick={() => updateCompany(rowData.id, { active: false })}>
          <i className="fa fa-times-circle mr-5" />
          <FormattedMessage id="general.button.disableTruckingCompany" />
        </Button>
      )}
    </div>
  );

  const isEmptyTable = !resources.length && !status && !search;

  const columns = [
    { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '2 1 130px',
      renderer: renderNameColumn, sortKey: 'name' },
    { label: <FormattedMessage id="general.location" />, flex: '1 1 100px', renderer: renderLocationColumn },
    { label: <FormattedMessage id="general.users" />, flex: '1 1 100px', renderer: renderUsersCountColumn },
    { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 100px',
      renderer: renderStatusColumn, sortKey: 'active' },
    { label: '', flex: '0 0 135px', renderer: renderActionsColumn, fixed: true,
      className: cn({ 'hide-for-large': isTablet }) },
    { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.truckingCompanies" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />
          }
        </Panel.Heading>
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={status}
              onFilterChange={onStatusChange}
              stats={stats}
              className="ph-10"
            >
              <Link to="/admin/trucking-companies/create" className="button small primary wider">
                <FormattedMessage id="general.button.createTruckingCompany" />
              </Link>
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={renderExpandable}
            scrollable
            isLoading={isLoading}
          />

          <NothingBox
            itemsName={tableNames.truckingCompanies}
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={status}
          >
            <FormattedMessage id="component.nothingBox.anyTruckingCompaniesYet" tagName="h1" />
            <FormattedMessage id="component.nothingBox.createTruckingCompany" tagName="p" />
            <Link to="/admin/trucking-companies/create" className="button primary mt-10">
              <FormattedMessage id="general.button.createTruckingCompany" />
            </Link>
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );

};

TruckingCompanies.propTypes = {
  reqParams: T.object.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect(
  (state) => ({
    reqParams: tableParamsSelector(state, tableNames.truckingCompanies),
  }), {
    setTableParams
  }
)(TruckingCompanies);
