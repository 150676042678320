import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import activityShow from './activityShow';
import assetMap from './assetMap';
import autoReport from './autoReport';
import auth from './auth';
import apiMonitoring from './apiMonitoring';
import barnsheets from './barnsheets/index';
import checkins from './checkins/index';
import dailyCheckup from './dailyCheckup/index';
import dataTable from './dataTable';
import destinations from './destinations';
import dropdownLayout from './dropdownLayout';
import companies from './admin/companies';
import shareholderGroups from './admin/shareholderGroups/index';
import efficacy from './treatmentFeedback/efficacy';
import farmfeed from './farmfeed';
import farmFeedSearch from './farmFeedSearch';
import flaggedFarmFeed from './flaggedFarmFeed';
import farms from './admin/farms';
import importCSV from './importCSV';
import inventoriesList from './inventoriesList';
import invitations from './invitations';
import layout from './layout';
import breadcrumbs from './breadcrumbs';
import mediaGallery from './mediaGallery';
import mediaViewer from './mediaViewer';
import mobileListPicker from './mobileListPicker';
import modals from './modals';
import pigGroups from './pigGroups/index';
import pigsUnderCare from './pigsUnderCare';
import portal from './portal';
import profiles from './profiles';
import rightToLeftPortal from './rightToLeftPortal';
import sources from './sources';
import staticData from './staticData';
import tableParams from './tableParams';
import users from './admin/users';
import sidebar from './sidebar';
import network from './network';
import notifications from './notifications';
import mentions from './mentions';
import commitment from './commitment';
import userFilters from './userFilters';
// svr
import siteVisitReport from './siteVisitReport';
import svrReducer from './svr';

// keep it in alphabetical order
const rootReducer = combineReducers({
  activityShow,
  assetMap,
  apiMonitoring,
  auth,
  autoReport,
  barnsheets,
  breadcrumbs,
  checkins,
  commitment,
  companies,
  dailyCheckup,
  dataTable,
  destinations,
  dropdownLayout,
  efficacy,
  farmfeed,
  farmFeedSearch,
  flaggedFarmFeed,
  farms,
  form: formReducer,
  importCSV,
  inventoriesList,
  invitations,
  layout,
  mobileListPicker,
  modals,
  mediaGallery,
  mediaViewer,
  mentions,
  network,
  notifications,
  pigGroups,
  pigsUnderCare,
  portal,
  profiles,
  rightToLeftPortal,
  shareholderGroups,
  sidebar,
  staticData,
  sources,
  svr: svrReducer,
  siteVisitReport,
  tableParams,
  toastr: toastrReducer,
  users,
  userFilters,
});

export default rootReducer;
