import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './QuestionAnswerIcon.module.scss';

const cn = classnames.bind(styles);

const QuestionAnswerIcon = ({ questionIndex, answerValue }) => (
  <div className={cn('question-icon', answerValue)}>
    {!answerValue && questionIndex + 1}
    {['not_available', 'pass'].includes(answerValue) && <i className="fa fa-check" />}
    {answerValue === 'fail' && <i className="fa fa-times" />}

  </div>
);

QuestionAnswerIcon.propTypes = {
  answerValue: T.string,
  questionIndex: T.number.isRequired,
};

export default QuestionAnswerIcon;
