import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';

const TruncatedComment = ({ comment, length = 100, placeholder = 'N/A' }) => {
  const [isReadMore, setReadMore] = useState(false);

  if (!comment) return placeholder;

  if (comment.length > length && !isReadMore) return (
    <div>
      {comment.substr(0, length)}...
      <span className="color-primary cursor-pointer" onClick={() => setReadMore(true)}>
        <FormattedMessage id="general.readMore" />
      </span>
    </div>
  );

  return comment;
};

TruncatedComment.propTypes = {
  comment: T.string,
  placeholder: T.string,
  length: T.number,
};

export default TruncatedComment;
