import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import SubNavigation from 'components/Subnavigation';
import OfflineScreen from 'components/OfflineScreen';
// utils
import cn from 'classnames';
// styles
import './BarnSheetsContainer.scss';

const subNavLinks = [
  { label: <FormattedMessage id="general.pageTitle.groups" />, link: '/barnsheets/groups' },
  { label: <FormattedMessage id="general.farms" />, link: '/barnsheets/farms' },
  { label: <FormattedMessage id="general.companies" />, link: '/barnsheets/companies' },
];

const BarnSheetsContainer = ({ children, className = '', headerContent, isOnline }) => (
  <div className={cn('BarnSheetsContainer', { [className]: !!className })}>
    {isOnline && headerContent}
    <SubNavigation isSticky links={subNavLinks} className="bs-sub-nav" />
    {isOnline ? children : <OfflineScreen />}
  </div>
);

BarnSheetsContainer.propTypes = {
  className: T.string,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  headerContent: T.oneOfType([T.arrayOf(T.node), T.node]),
  isOnline: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  })
)(BarnSheetsContainer);
