import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { openPortal } from 'reducers/portal';
// components
import { FormattedMessage } from 'react-intl';
import ShareActivityPortal from 'containers/Farmfeed/components/ShareActivityPortal';
import ShareActivityModal from 'containers/Farmfeed/components/ShareActivityModal';
// utils
import classnames from 'classnames/bind';
import { isMobile, isTablet } from 'react-device-detect';
import { isUserCanDiagnose, isUserCanShare } from 'utils';
// styles
import styles from './ActivityActionsBar.module.scss';

const cn = classnames.bind(styles);

const ActivityActionsBar = ({
  activity,
  currentUser,
  openDropdown,
  onDiagnoseClick,
  openModal,
  openPortal,
  setActivityFlag,
}) => {
  const { id, variety, flagged, farm } = activity;
  const isUserVet = !!onDiagnoseClick && isUserCanDiagnose(currentUser, farm?.id, farm?.manager?.id);
  const canShare = isUserCanShare(currentUser, farm?.id, farm?.manager?.id) && !variety.includes('shared')
    && !variety.includes('direct');

  const openShareDialogue = (type) => {
    if (isMobile && !isTablet) {
      openPortal(
        <ShareActivityPortal type={type} activityToShare={activity} />
      );
      return;
    }
    openModal(
      <ShareActivityModal type={type} activityToShare={activity} />
    );
  };

  const onShareClick = (e) => {
    const target = e.target.dataset.name ? e.target : e.target.parentNode;
    const options = [
      { label: <FormattedMessage id="general.shareWithUser" />, onClick: () => openShareDialogue('user'),
        icon: 'ep-users', desktopIcon: true },
      { label: <FormattedMessage id="general.shareWithFarm" />, onClick: () => openShareDialogue('farm'),
        icon: 'ep-farm', desktopIcon: true },
      { label: <FormattedMessage id="general.shareWithCompany" />, onClick: () => openShareDialogue('company'),
        icon: 'megaphone', desktopIcon: true, hide: !currentUser.roles_map.admin },
    ];
    openDropdown(e, options, { customTarget: target });
  };

  return (
    <div className={cn('activity-actions-bar')}>
      <div className={cn('activity-action')} onClick={() => setActivityFlag(!flagged, id)}>
        <i className={cn('action-icon', `fa fa-${flagged ? 'bookmark-bts' : 'bookmark'}`, { flagged })} />
        <FormattedMessage id={`container.farmfeed.row.${flagged ? 'unflag' : 'flag'}`} />
      </div>
      {isUserVet && (
        <div className={cn('activity-action')} onClick={onDiagnoseClick}>
          <i className={cn('fa fa-diagnosis', 'action-icon')} />
          <FormattedMessage id="general.diagnose" />
        </div>
      )}
      {canShare && (
        <div data-name={`${id}-share`} className={cn('activity-action')} onClick={onShareClick}>
          <i className={cn('fa fa-share', 'action-icon')} />
          <FormattedMessage id="general.share" />
        </div>
      )}
    </div>
  );
};

ActivityActionsBar.propTypes = {
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  onDiagnoseClick: T.func,
  setActivityFlag: T.func.isRequired,
  openModal: T.func.isRequired,
  openPortal: T.func.isRequired,
  openDropdown: T.func.isRequired,
};

export default connect(null, {
  openModal,
  openPortal,
})(ActivityActionsBar);
