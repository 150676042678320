import React, { useState } from 'react';
import T from 'prop-types';
// components
import Input from 'components/Input';
// styles
import './LabelledInput.scss';

const LabelledInput = ({ children, model, tabIndex, onChange, autoComplete, isRequired, password, disabled }) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const passwordType = isPasswordVisible ? 'text' : 'password';

  return (
    <label>
      <span className="tag">{children}</span>
      <div className="labeled-input-wrapper">
        <Input
          className="labelled-input"
          type={password ? passwordType : model.type}
          name={model.name}
          autoComplete={autoComplete}
          aria-required={isRequired}
          tabIndex={tabIndex}
          disabled={disabled}
          onChange={(e) => onChange(model, e.target.value)}
        />
        {password && (
          <span
            className={`fa password-icon ${isPasswordVisible ? 'fa-eye-off' : 'fa-remove-red-eye'}`}
            onClick={() => setPasswordVisibility(!isPasswordVisible)}
          />
        )}
      </div>
    </label>
  );
};

LabelledInput.propTypes = {
  model: T.shape({
    type: T.string,
    name: T.string,
  }),
  tabIndex: T.string,
  children: T.oneOfType([T.string, T.node]),
  autoComplete: T.string,
  password: T.bool,
  isRequired: T.bool,
  onChange: T.func,
  disabled: T.bool,
};

export default LabelledInput;
