import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { deleteCompany } from 'reducers/admin/companies';
import { inviteToTenant, createInvite } from 'reducers/admin/users';
import { openModal } from 'reducers/modals';
import { fetchCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import Panel from 'components/Panel';
import CreateAdminForm from './components/CreateAdminForm';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import ProgressChecklist from 'components/ProgressChecklist/ProgressChecklist';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import pick from 'lodash.pick';
import get from 'lodash.get';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

class MyCompanyAdminCreate extends Component {

  componentDidMount() {
    const { fetchCompanyProfile, currentCompanyId } = this.props;
    fetchCompanyProfile(`/admin/companies/${currentCompanyId}`).catch(toastResponseErrors);
  }

  deleteCompany = () => {
    const { router } = this.context;
    const { deleteCompany, currentCompanyId } = this.props;
    return deleteCompany(currentCompanyId)
      .then(() => {
        showToastrMessage('component.toastr.company.hasBeenDeleted');
        router.push('/admin/companies');
      })
      .catch(toastResponseErrors);
  };

  deleteCompanyConfirm = () => {
    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteCompany" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteCompany.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.deleteCompany.warning" />}
        handleConfirm={this.deleteCompany}
      >
        <FormattedMessage id="component.modal.deleteCompany.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  handleFormSubmit = (data) => {
    const { router } = this.context;
    const { createInvite, inviteToTenant, currentCompanyId } = this.props;
    const email = get(data, 'email', '').toLowerCase();
    // if user exists in tenant
    if (data.user_status === 'member') {
      const reqParams = {
        method: 'POST',
        body: JSON.stringify({ email, name: data.user_role })
      };
      return fetchFromAPI(`/admin/companies/${currentCompanyId}/admin_roles`, reqParams)
        .then(() => {
          showToastrMessage('component.toastr.userInvite.sent');
          router.push('/admin/my-company/admins');
        })
        .catch(toastResponseErrors);
    }

    // if user exists in system, not in this tenant
    if (data.user_status === 'found') {
      return inviteToTenant(email, data.user_role)
        .then(() => {
          showToastrMessage('component.toastr.userInvite.sent');
          router.push('/admin/my-company/admins');
        })
        .catch(toastResponseErrors);
    }

    const entity = pick(data, ['email', 'first_name', 'last_name', 'language']);

    return createInvite({ ...entity, role: data.user_role, email, })
      .then(() => {
        showToastrMessage('component.toastr.user.created');
        router.push('/admin/my-company/admins');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { company, currentCompanyId, isLoading, currentCompanyLanguage } = this.props;
    const steps = [
      { label: <FormattedMessage id="container.companyProfile.setup" />, icon: 'fa fa-check', completed: true },
      { label: <FormattedMessage id="general.role.primary" />, icon: 'fa fa-user-male',
        completed: !!company.primary_contact_id },
      { label: <FormattedMessage id="general.setupComplete" />, icon: 'fa fa-trophy',
        completed: !!company.primary_contact_id },
    ];

    const initialValues = { language: currentCompanyLanguage || 'en' };

    return (
      <div className="MyCompanyAdminCreate">
        <CompanyProfileHeader
          actualCompanyId={currentCompanyId}
          company={company}
          isLoading={isLoading}
          dropdownOptions={[{
            label: <FormattedMessage id="general.deleteCompany" />,
            onClick: this.deleteCompanyConfirm,
          }]}
        >
          <ProgressChecklist steps={steps} className="show-for-large" />
        </CompanyProfileHeader>

        <div className="small-12 column">
          <Link to="/admin/my-company" className="back-to color-primary">
            <i className="fa fa-angle-left-btb" />
            <FormattedMessage id="general.backToMyCompany" />
          </Link>
        </div>

        <section className="small-12 column mt-15">
          <Panel>
            <Panel.Heading title={<FormattedMessage id="general.createAdmin" />} />
            <Panel.Body>
              <div className="row">
                <div className="small-12 medium-10 large-8 column">
                  <CreateAdminForm onSubmit={this.handleFormSubmit} initialValues={initialValues} />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

MyCompanyAdminCreate.propTypes = {
  company: T.object.isRequired,
  isLoading: T.bool.isRequired,
  currentCompanyId: T.number.isRequired,
  currentCompanyLanguage: T.string.isRequired,
  deleteCompany: T.func.isRequired,
  fetchCompanyProfile: T.func.isRequired,
  inviteToTenant: T.func.isRequired,
  createInvite: T.func.isRequired,
  openModal: T.func.isRequired,
};

MyCompanyAdminCreate.contextTypes = {
  router: T.object,
};

export default connect(
  (state) => ({
    company: state.profiles.company.data,
    isLoading: state.profiles.company.isLoading,
    currentCompanyId: state.auth.user.current_company.id,
    currentCompanyLanguage: state.auth.user.current_company.language,
  }), {
    deleteCompany,
    inviteToTenant,
    createInvite,
    openModal,
    fetchCompanyProfile,
  }
)(MyCompanyAdminCreate);
