import React, { Component } from 'react';
import T from 'prop-types';
// components
import { START_DATE, END_DATE } from 'react-dates/constants';
import Button from 'components/Button';
// utils
import classnames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
// styles
import styles from './DatePickerMobileBox.module.scss';

const cn = classnames.bind(styles);

const dateLabel = <FormattedMessage id="component.dateRangePicker.pickDate" />;

class DatePickerMobileBox extends Component {

  renderHeaderDates = () => {
    const { startDate, endDate, focusedInput, headerDatesRenderer } = this.props;
    if (typeof headerDatesRenderer === 'function') return headerDatesRenderer();
    return (
      <div className={cn('header-dates')}>
        <div className={cn({ active: focusedInput === START_DATE })}>
          <div>{startDate ? startDate.format('dddd') : ''}</div>
          <div>{startDate ? startDate.format('MMMM D, YYYY') : dateLabel}</div>
        </div>
        <div className={cn('date-arrow')}>
          <i className="fa fa-arrow-right" />
        </div>
        <div className={cn({ active: focusedInput === END_DATE })}>
          <div>{endDate ? endDate.format('dddd') : ''}</div>
          <div>{endDate ? endDate.format('MMMM D, YYYY') : dateLabel}</div>
        </div>
      </div>
    );
  };

  isSubmitButtonDisabled = () => {
    const { isSubmitBtnDisabled, startDate, endDate } = this.props;
    if (typeof isSubmitBtnDisabled === 'function') return isSubmitBtnDisabled();
    return !startDate || !endDate;
  };

  render() {
    const { children, className, onClearDates, onClose, onSubmit } = this.props;
    return (
      <div className={cn('date-picker-mobile-box', { [className]: !!className })}>
        <div className={cn('picker-header')}>
          <div className={cn('header-actions')}>
            <i className={cn('fa fa-times', 'close')} onClick={onClose} />
            <Button default className={cn('clear-dates')} onClick={onClearDates}>
              <FormattedMessage id="general.button.clear" />
            </Button>
          </div>
          {this.renderHeaderDates()}
        </div>

        <div className={cn('picker-body')}>
          {children}
        </div>

        <div className={cn('picker-footer')}>
          <Button
            primary
            wide
            className={cn('save-button')}
            onClick={onSubmit}
            disabled={this.isSubmitButtonDisabled()}
          >
            <FormattedMessage id="general.button.save" />
          </Button>
        </div>
      </div>
    );
  }
}

DatePickerMobileBox.propTypes = {
  className: T.string,
  onClose: T.func.isRequired,
  onClearDates: T.func.isRequired,
  onSubmit: T.func.isRequired,
  headerDatesRenderer: T.func,
  startDate: T.object,
  endDate: T.object,
  focusedInput: T.string,
  isSubmitBtnDisabled: T.func,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

export default DatePickerMobileBox;
