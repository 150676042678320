import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
import { fetchUserProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesLabel from 'components/RolesLabel/RolesLabel';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// utils
import sum from 'lodash.sum';
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

class UserFarmRoles extends Component {

  state = {
    isRoleRemoving: false,
  };

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  revokeUserFarmRole = ({ id: farm_id, is_owner, is_caretaker, is_manager, is_vet }) => () => {
    const { params: { id: user_id } } = this.props;
    let name;
    if (is_owner) name = 'owner';
    if (is_caretaker) name = 'caretaker';
    if (is_manager) name = 'manager';
    if (is_vet) name = 'vet';
    const paramsData = { farm_id, name, user_id };

    this.removeRole(paramsData);
  };

  removeRole = (data) => {
    const { fetchData, meta: { stats = {} }, reqParams, params: { id }, fetchUserProfile } = this.props;
    this.setState({ isRoleRemoving: true });
    return fetchFromAPI(`/admin/users/${id}/farm_roles`, { method: 'delete', body: JSON.stringify(data) })
      .then(() => {
        this.setState({ isRoleRemoving: false });
        fetchData(reqParams).then(({ resources }) => {
          const isLastVetRoleRemoved = (data.name === 'vet') && (stats.vet === 1);
          if (!resources.length || isLastVetRoleRemoved) fetchUserProfile();
        });
        showToastrMessage('component.toastr.role.revoked');
      })
      .catch((response) => {
        this.setState({ isRoleRemoving: false });
        toastResponseErrors(response);
      });
  };

  renderFarmNameColumn = ({ name, farm_type }) => (
    <CustomColumn>
      <FarmTitleBox name={name} type={farm_type} />
    </CustomColumn>
  );

  renderLocationColumn = ({ city, state }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.location" />}>
      {`${city}, ${state}`}
    </CustomColumn>
  );

  renderFarmIdColumn = ({ external_link_id }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmId" />}>
      {external_link_id || 'N/A'}
    </CustomColumn>
  );

  renderUserRoleColumn = ({ is_owner, is_manager, is_caretaker, is_vet }) => (
    <CustomColumn flexRow>
      <FormattedMessage id="general.userRole">
        {(text) => (
          <span className="hide-for-large semibold">{text}&nbsp;•&nbsp;</span>
        )}
      </FormattedMessage>
      <RolesLabel
        roles={[
          { name: <FormattedMessage id="general.role.farmOwner" />, value: is_owner, shortcut: 'o' },
          { name: <FormattedMessage id="general.role.farmManager" />, value: is_manager, shortcut: 'm' },
          { name: <FormattedMessage id="general.role.farmCaregiver" />, value: is_caretaker, shortcut: 'c' },
          { name: <FormattedMessage id="general.role.veterinarian" />, value: is_vet, shortcut: 'v' },
        ]}
      />
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.farmStatus" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = (farm, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${farm.id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.revokeAccess" />}
        buttonType="small light-gray"
        onClick={this.revokeUserFarmRole(farm)}
        dropDownData={[
          { label: <FormattedMessage id="general.editFarm" />, url: `/admin/farms/${farm.id}/edit` },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/farms/${farm.id}/edit`} className="button light hide-for-large">
        <FormattedMessage id="general.editFarm" />
      </Link>
      <Button darkGrey onClick={this.revokeUserFarmRole(farm)} className="hide-for-large">
        <FormattedMessage id="general.button.revokeAccess" />
      </Button>
    </div>
  );

  renderExpandable = (farm) => (
    <div>
      <Link to={`/admin/farms/${farm.id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.editFarm" />
      </Link>
      <Button small light onClick={this.revokeUserFarmRole(farm)}>
        <i className="fa fa-times-circle mr-5" />
        <FormattedMessage id="general.button.revokeAccess" />
      </Button>
    </div>
  );

  render() {
    const { resources, meta: { total, stats = {} }, isLoading, reqParams: { page, per_page, role, search, sort },
      params: { id }, onSearchChange, onPageChange, onPerPageChange, onSortChange } = this.props;
    const { isRoleRemoving } = this.state;
    const { isTablet } = this.context;
    const statsSum = sum(Object.values(stats));
    const tableFilters = [
      { label: <FormattedMessage id="general.allRoles" />, value: '', count: statsSum },
      { label: <FormattedMessage id="general.role.farmOwner" />, value: 'owner' },
      { label: <FormattedMessage id="general.role.farmCaregiver" />, value: 'caretaker' },
      { label: <FormattedMessage id="general.role.farmManager" />, value: 'manager' },
      { label: <FormattedMessage id="general.role.veterinarian" />, value: 'vet' },
    ];
    const isEmptyTable = !resources.length && !role && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '2 1 160px',
        sortKey: 'name', renderer: this.renderFarmNameColumn },
      { label: <FormattedMessage id="component.dataTable.headers.location" />, flex: '1 1 100px',
        sortKey: 'city', renderer: this.renderLocationColumn },
      { label: <FormattedMessage id="component.dataTable.headers.farmId" />, flex: '1 1 100px',
        sortKey: 'external_link_id', renderer: this.renderFarmIdColumn },
      { label: <FormattedMessage id="general.userRole" />, flex: '1 1 140px',
        sortKey: 'role', renderer: this.renderUserRoleColumn },
      { label: <FormattedMessage id="component.dataTable.headers.farmStatus" />, flex: '1 1 120px',
        sortKey: 'active', renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 180px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.farmRoles" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={role}
              onFilterChange={this.onFilterChange}
              stats={stats}
              className="ph-10"
            >
              {!isEmpty(resources) && (
                <Link to={`/admin/users/${id}/assign-farm-roles`} className="button small primary wider">
                  <FormattedMessage id="general.manageRoles" />
                </Link>
              )}
            </TableFilter>
          )}

          <Preloader isActive={isLoading || isRoleRemoving} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="userFarmRoles"
            rowKey="role_id" // todo: id is not unique for roles table now
          />

          <NothingBox
            itemsName="roles"
            display={!resources.length}
            isLoading={isLoading || isRoleRemoving}
            search={search}
            filter={role}
          >
            <FormattedMessage id="component.nothingBox.noFarmRoles" tagName="h1" />
            <Link to={`/admin/users/${id}/assign-farm-roles`} className="button primary mt-10">
              <FormattedMessage id="general.assignFarmRoles" />
            </Link>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

UserFarmRoles.propTypes = {
  fetchData: T.func.isRequired,
  fetchUserProfile: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  params: T.object,
};

UserFarmRoles.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect((state) => ({
    user: state.profiles.user.data,
    resources: state.dataTable.userFarmRoles.resources,
    reqParams: state.dataTable.userFarmRoles.params,
    meta: state.dataTable.userFarmRoles.meta,
    isLoading: state.dataTable.userFarmRoles.isLoading,
  }), (dispatch, { params: { id } }) => ({
    fetchUserProfile: () => dispatch(fetchUserProfile(`/admin/users/${id}`)),
    fetchData: (query) => dispatch(fetchData(`/admin/users/${id}/farm_roles/profile_index`, 'userFarmRoles', query)),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(UserFarmRoles);
