import React, { useState } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// components
import Input from 'components/Input';
// styles
import styles from './CurrencyInput.module.scss';

const cn = classnames.bind(styles);

const regex = /^\d+(\.\d{0,2})?$/;

const CurrencyInput = ({
  initialValue,
  currency = '$',
  className,
  onSave,
  ...inputProps
}) => {
  const [inputValue, setValue] = useState(`${currency} ${(+initialValue).toFixed(2) || '0.00'}`);


  const onChange = ({ target: { value } }) => {
    if (value === '' || regex.test(value)) setValue(value || '');
  };

  const onFocus = ({ target: { value } }) => {
    setValue(parseFloat(value.replace(currency, '').trim() || '', 10));
  };

  const onBlur = () => {
    if (Number(inputValue) !== Number(initialValue)) {
      const saveValue = inputValue === '' || !regex.test(inputValue) ? '0' : `${+inputValue}`;
      onSave(saveValue);
    }
    setValue(`${currency} ${(+inputValue).toFixed(2)}`);
  };

  return (
    <div className={cn('input-wrap', className)}>
      <i className="fa fa-money" />
      <Input
        className={cn('input')}
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        {...inputProps}
      />
    </div>

  );
};

CurrencyInput.propTypes = {
  initialValue: T.oneOfType([T.string, T.number]),
  currency: T.string,
  onSave: T.func,
  className: T.string,
};

export default CurrencyInput;
