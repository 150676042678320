import React from 'react';
import T from 'prop-types';
// components
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn } from 'components/DataTable/Columns';
import TruncateHint from 'components/TruncateHint';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import { capitalize, getMedType } from 'utils';
// styles
import styles from './TreatmentProtocolProfile.module.scss';

const TreatmentProtocolProductsTable = ({ treatmentProducts }) => {
  const renderTreatmentProductNameColumn = (rowData) => (
    <CustomColumn>
      <div className={styles['product-name-column']}>
        <i className={cn(`fa fa-${getMedType(rowData.treatment_product)}`, styles.icon)} />
        {rowData.treatment_product.name}
      </div>
    </CustomColumn>
  );

  const showRouteForHint = (route) => (
    <TruncateHint
      className="routes-hint"
      name={route.map((item) => capitalize(item)).join(', ')}
      showName={<span>{capitalize(route[0])} <span className="plus-route">{` +${route.length - 1}`}</span></span>}
    />
  );

  const renderRouteColumn = (rowData) => {
    const route = rowData.treatment_product.route || [];
    return (
      <CustomColumn label={<FormattedMessage id="general.route" />}>
        <span className="route-column">
          {route.length > 1 ? showRouteForHint(route) : capitalize(route[0] || '')}
        </span>
      </CustomColumn>
    );
  };

  const renderUnitTypeColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.unit" />}>
      {rowData.treatment_product.unit_type ?
        <FormattedMessage id={`general.${rowData.treatment_product.unit_type}`} /> :
        ''
      }
    </CustomColumn>
  );

  const renderVaccineColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.vaccine" />}>
      <FormattedMessage id={`general.button.${rowData.treatment_product.vaccine ? 'yes' : 'no'}`} />
    </CustomColumn>
  );

  const columns = [
    {
      label: <FormattedMessage id="component.dataTable.headers.productName" />,
      flex: '1 1 160px',
      renderer: renderTreatmentProductNameColumn,
    },
    {
      label: <FormattedMessage id="general.route" />,
      flex: '2 0 130px',
      renderer: renderRouteColumn,
    },
    {
      label: <FormattedMessage id="general.unit" />,
      flex: '1 0 70px',
      renderer: renderUnitTypeColumn,
    },
    {
      label: <FormattedMessage id="general.vaccine" />,
      flex: '1 0 70px',
      renderer: renderVaccineColumn,
    },
  ];

  const renderTitle = () => (
    <div className={styles['treatment-protocol-title']}>
      <FormattedMessage id="general.products" />
    </div>
  );

  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={renderTitle()} />
        <Panel.Body noPadding>
          <DataTable data={treatmentProducts} columns={columns} />
        </Panel.Body>
      </Panel>
    </section>
  );
};


TreatmentProtocolProductsTable.propTypes = {
  treatmentProducts: T.array.isRequired,
};

export default TreatmentProtocolProductsTable;
