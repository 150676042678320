import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import Button from 'components/Button';
import Panel from 'components/Panel';
import Input from 'components/Input';
import Preloader from 'components/Preloader';
import CircleCheckbox from 'components/CircleCheckbox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './UpdateSymptom.scss';

const symptomLevels = [
  { label: <FormattedMessage id="component.updateSymptom.veryLowConcern" />, className: 'very-low', value: 'gteq75',
    description: <FormattedMessage id="component.updateSymptom.concernDesc" values={{ value: 75 }} /> },
  { label: <FormattedMessage id="component.updateSymptom.lowConcern" />, className: 'low', value: 'from50to74',
    description: <FormattedMessage id="component.updateSymptom.concernDesc" values={{ value: 50 }} /> },
  { label: <FormattedMessage id="component.updateSymptom.mediumConcern" />, className: 'medium', value: 'from25to49',
    description: <FormattedMessage id="component.updateSymptom.concernDesc" values={{ value: 25 }} /> },
  { label: <FormattedMessage id="component.updateSymptom.highConcern" />, className: 'high', value: 'from11to24',
    description: <FormattedMessage id="component.updateSymptom.concernDesc" values={{ value: 11 }} /> },
  { label: <FormattedMessage id="component.updateSymptom.veryHighConcern" />, className: 'very-high', value: 'lteq10',
    description: <FormattedMessage id="component.updateSymptom.concernDesc" values={{ value: 0 }} /> },
];

class UpdateSymptom extends Component {

  state = {
    symptom: { name: '' },
    alertFrequencyToSave: '',
    isLoading: false,
  };

  componentDidMount() {
    const { params: { id } } = this.props;
    this.getSymptom(id);
  }

  getSymptom = (id) => {
    this.setState({ isLoading: true });
    fetchFromAPI(`/admin/symptoms/${id}`)
      .then(({ resource }) => {
        this.setState({ isLoading: false, symptom: resource, alertFrequencyToSave: resource.alert_frequency });
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        this.context.router.push('/farmfeed');
        return toastResponseErrors(errors);
      });
  };

  updateConcernLevel = () => {
    const { router } = this.context;
    const { alertFrequencyToSave } = this.state;
    const { params: { id } } = this.props;

    this.setState({ isLoading: true });
    return fetchFromAPI(`/admin/symptoms/${id}`, {
      method: 'put',
      body: JSON.stringify({ resource: { alert_frequency: alertFrequencyToSave } }),
    })
      .then(() => {
        this.setState({ isLoading: false });
        showToastrMessage('component.toastr.symptom.updated');
        router.push('/admin/health-variables/symptoms');
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  onAlertFrequencyChange = ({ target: { name } }) => {
    const { alertFrequencyToSave } = this.state;
    if (alertFrequencyToSave === name) return;
    this.setState({ alertFrequencyToSave: name });
  };

  changeSymptomStatus = (active) => {
    const { params: { id } } = this.props;
    this.setState({ isLoading: true });
    return fetchFromAPI(`/admin/symptoms/${id}`, { method: 'put', body: JSON.stringify({ resource: { active } }) })
      .then(({ resource }) => {
        this.setState({ isLoading: false, symptom: resource });
        showToastrMessage(`component.toastr.symptom.${active ? 'activated' : 'deactivated'}`);
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  openConfirmModal = () => {
    const { symptom: { active } } = this.state;

    if (!active) {
      this.changeSymptomStatus(!active);
      return;
    }

    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.disableSymptom.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.disableSymptom.confirm" />}
        actionBtnProps={{ red: true }}
        warningMessage={<FormattedMessage id="component.modal.disableSymptom.warning" />}
        handleConfirm={() => this.changeSymptomStatus(!active)}
      >
        <FormattedMessage id="component.modal.disableSymptom.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  render() {
    const { isLoading, symptom: { alert_frequency, name, active }, alertFrequencyToSave } = this.state;
    const actualAlertFrequency = alertFrequencyToSave || alert_frequency;
    const isSavedFrequency = (alertFrequencyToSave === alert_frequency);
    return (
      <section className="UpdateSymptom">
        <div className="symptom-header">
          <div className="symptom-header-name">{name}</div>
          <StatusBadge status={active ? 'active' : 'disabled'} className="mh-10" />
          <Button className="symptom-btn" onClick={this.openConfirmModal}>
            <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}`} />
          </Button>
        </div>
        <Panel className="small-12 column mt-20">
          <Panel.Heading title={<FormattedMessage id="general.manageSymptom" />} />
          <Panel.Body noPadding>
            <div className="row">
              <section className="small-12 medium-10 large-8 column">
                <Preloader isActive={isLoading} />
                <div className="manage-symptom-data">
                  <div className="symptom-name-row">
                    <div className="row-title">
                      <FormattedMessage id="component.updateSymptom.symptomName" />
                    </div>
                    <Input value={name} readOnly />
                  </div>
                  <div className="symptom-concern-row">
                    <div className="row-title">
                      <FormattedMessage id="component.updateSymptom.setConcernLevel" />
                    </div>
                    <div className="concern-levels">
                      {symptomLevels.map(({ label, value, description, className }, idx) => (
                        <div key={idx} className="mt-10 mb-20">
                          <CircleCheckbox
                            type="radio"
                            name={value}
                            label={(
                              <span className={`${className} concern`} style={{ marginTop: '-2px' }}>
                                {label}
                              </span>
                            )}
                            checked={actualAlertFrequency === value}
                            onChange={this.onAlertFrequencyChange}
                          />
                          <div className="concern-description">{description}</div>
                        </div>
                      ))}
                    </div>
                    <div className="concern-pro-tip">
                      <FormattedMessage tagName="b" id="general.proTip" />
                      <FormattedMessage tagName="i" id="component.updateSymptom.concernProTip" />
                    </div>
                  </div>
                  <div className="symptom-buttons-row">
                    <Button
                      primary
                      onClick={this.updateConcernLevel}
                      disabled={isSavedFrequency || isLoading}
                      className="no-margin"
                    >
                      <FormattedMessage id="general.button.saveChanges" />
                    </Button>
                    <Link to="/admin/health-variables/symptoms" className="button btn-link ml-10">
                      <FormattedMessage id="general.button.cancel" />
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

UpdateSymptom.contextTypes = {
  router: T.object.isRequired,
};

UpdateSymptom.propTypes = {
  params: T.object.isRequired,
  openModal: T.func.isRequired,
};

export default connect(null, { openModal })(UpdateSymptom);
