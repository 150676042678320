import React, { Component } from 'react';
import T from 'prop-types';
// recompose, redux
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchFarmProfile } from 'reducers/profiles';
import { fetchData, resetResources, setDataItem } from 'reducers/dataTable';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import FarmUserRolesManageTable from 'components/Admin/Farms/FarmUserRolesManage/FarmUserRolesManageTable';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import RolesManageFixedHeader from 'components/Admin/RolesManageFixedHeader/RolesManageFixedHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import TableFilter from 'components/TableFilter';
import PaginationWrapper from 'components/PaginationWrapper';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';
import classnames from 'classnames/bind';
// styles
import styles from '../RolesManage.module.scss';

const cn = classnames.bind(styles);

const tableFilters = [
  { label: <FormattedMessage id="general.allAssignments" />, value: '' },
  { label: <FormattedMessage id="general.role.farmOwner" />, value: 'owner' },
  { label: <FormattedMessage id="general.role.caregiver" />, value: 'caretaker' },
  { label: <FormattedMessage id="general.role.farmManager" />, value: 'manager' },
  { label: <FormattedMessage id="general.role.veterinarian" />, value: 'vet' },
];

const headerColumns = [
  { flex: '1 1 120px', name: <FormattedMessage id="component.dataTable.headers.userName" />, sortField: 'first_name' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.farmOwner" />, sortField: 'is_owner' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.manager" />, sortField: 'is_manager' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.caregiver" />, sortField: 'is_caretaker' },
  { flex: '1 1 80px', name: <FormattedMessage id="general.role.veterinarian" />, sortField: 'is_vet' },
];

class FarmUserRolesManage extends Component {

  componentDidMount() {
    const { fetchData, fetchFarmProfile, reqParams } = this.props;
    fetchFarmProfile().catch(toastResponseErrors);
    fetchData(reqParams).catch(toastResponseErrors);
  }

  componentWillUnmount() {
    this.props.resetResources();
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  revokeAllAccess = () => {
    const { fetchData, reqParams, params: { id } } = this.props;
    fetchFromAPI(`/admin/farms/${id}/user_roles/destroy_all`, { method: 'delete' })
      .then(() => {
        showToastrMessage('component.toastr.roles.revoked');
        fetchData(reqParams);
      });
  };

  handleCheckBoxChanged = (e, user) => {
    const { checked, name } = e.target;
    const paramsData = { name, user_id: user.id };
    if (checked) return this.createFarmUserRole(paramsData, user);
    return this.removeFarmUserRole(paramsData);
  };

  handleRadioChanged = (e, user) => {
    const { checked, name } = e.target;
    const paramsData = { name, user_id: user.id };
    const alreadyChecked = user[`is_${name}`];

    if (checked && alreadyChecked) {
      return this.removeFarmUserRole(paramsData);
    }

    if (checked && !alreadyChecked) {
      return this.createFarmUserRole(paramsData, user);
    }

    return this.removeFarmUserRole(paramsData);
  };

  createFarmUserRole = (paramsData, { id, registration_state }) => {
    const { params, setDataItem } = this.props;
    fetchFromAPI(`/admin/farms/${params.id}/user_roles`, { method: 'post', body: JSON.stringify(paramsData) })
      .then(({ resource }) => {
        setDataItem(resource);
        this.removePreviousOwnerFlag(paramsData, id);
        if (registration_state === 'invited') {
          showToastrMessage('component.toastr.userInvite.successCreated');
        }
      });
  };

  removeFarmUserRole = (paramsData) => {
    const { params: { id }, setDataItem } = this.props;
    return fetchFromAPI(`/admin/farms/${id}/user_roles`, { method: 'delete', body: JSON.stringify(paramsData) })
      .then(({ resource }) => {
        setDataItem(resource);
      })
      .catch(toastResponseErrors);
  };

  removePreviousOwnerFlag = (paramsData, userId) => {
    const { setDataItem, resources } = this.props;
    if (paramsData.name === 'owner') {
      const userData = resources.find(({ id, is_owner }) => (is_owner && id !== userId));
      if (userData) setDataItem({ ...userData, is_owner: false });
    }
  };

  render() {
    const { farm, isFarmLoading, resources, isLoading, reqParams: { page, per_page, role, search, sort },
      meta: { total }, params: { id }, onSortChange, onSearchChange, onPageChange, onPerPageChange } = this.props;

    const activeFilter = role && tableFilters.find(({ value }) => (value === role)).label;

    return (
      <div className={cn('roles-manage')}>
        <FarmProfileHeader
          isSticky
          farm={farm}
          isLoading={isFarmLoading}
          actualFarmId={Number(id)}
        >
          <Button light className="mr-10 show-for-large" onClick={this.revokeAllAccess}>
            <i className="fa fa-times-circle mr-5" />
            <FormattedMessage id="general.button.revokeAllRoles" />
          </Button>
          <Link to={`/admin/farms/${id}/user-roles`} className="button primary wider show-for-large">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </FarmProfileHeader>

        <RolesManageFixedHeader
          className="show-for-large"
          panelTitle={<FormattedMessage id="general.userRoles" />}
          isEmptyTable={!resources.length}
          search={search}
          onSearchChange={onSearchChange}
          sort={sort}
          onSortChange={onSortChange}
          headerColumns={headerColumns}
        />

        <section className={cn('roles-manage-section')}>
          <div className="small-12 column">
            <Panel>
              <Panel.Heading title={<FormattedMessage id="general.userRoles" />} className="hide-for-large">
                <SearchBox initialValue={search} onChange={onSearchChange} />
              </Panel.Heading>
              <Panel.Body className={cn('roles-manage-body')}>
                <TableFilter
                  filters={tableFilters}
                  activeFilter={role}
                  onFilterChange={this.onFilterChange}
                  className="hide-for-large"
                >
                  <Button className="no-margin" darkGrey onClick={this.revokeAllAccess}>
                    <FormattedMessage id="general.button.revokeAllRoles" />
                  </Button>
                </TableFilter>

                <Preloader isActive={isLoading} />

                <PaginationWrapper
                  onlyBottom
                  totalItems={total}
                  currentPage={page}
                  onPageChange={onPageChange}
                  perPage={per_page}
                  onPerPageChange={onPerPageChange}
                >
                  <FarmUserRolesManageTable
                    data={resources}
                    handleCheckBoxChanged={this.handleCheckBoxChanged}
                    handleRadioChanged={this.handleRadioChanged}
                    search={search}
                    filter={activeFilter}
                    isLoading={isLoading}
                  />
                </PaginationWrapper>
              </Panel.Body>
            </Panel>
          </div>
        </section>

        <StickyFooter className="hide-for-large">
          <Link to={`/admin/farms/${id}/user-roles`} className="button primary big-button">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </StickyFooter>
      </div>
    );
  }
}

FarmUserRolesManage.propTypes = {
  fetchData: T.func.isRequired,
  resetResources: T.func.isRequired,
  setDataItem: T.func.isRequired,
  fetchFarmProfile: T.func.isRequired,
  farm: T.object.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  params: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isFarmLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      farm: state.profiles.farm.data,
      isFarmLoading: state.profiles.farm.isLoading,
      resources: state.dataTable.farmUserRolesManage.resources,
      reqParams: state.dataTable.farmUserRolesManage.params,
      meta: state.dataTable.farmUserRolesManage.meta,
      isLoading: state.dataTable.farmUserRolesManage.isLoading,
    }), (dispatch, { params: { id } }) => ({
      fetchFarmProfile: () => dispatch(fetchFarmProfile(`/admin/farms/${id}`)),
      fetchData: (query) => dispatch(fetchData(`/admin/farms/${id}/user_roles`, 'farmUserRolesManage', query)),
      setDataItem: (data) => dispatch(setDataItem(data, 'farmUserRolesManage')),
      resetResources: () => dispatch(resetResources('farmUserRolesManage')),
    })
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(FarmUserRolesManage);
