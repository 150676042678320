import React from 'react';
import T from 'prop-types';
// components
import StatusBadge from 'components/StatusBadge/StatusBadge';
import { FormattedMessage } from 'react-intl';
import DotSeparator from 'components/DotSeparator/DotSeparator';
import Button from 'components/Button';
// utils
import isEmpty from 'lodash.isempty';
// styles
import classnames from 'classnames/bind';
import styles from './GeneticHeader.module.scss';

const cn = classnames.bind(styles);

const GeneticHeader = ({
  handleClick,
  genetic,
  className = '',
}) =>  (
  <div className={cn('GeneticHeader', { [className]: !!className })}>
    <div className={cn('avatar-wrapper')}>
      <i className={cn('fa fa-ep-dna', 'header-avatar')} />
    </div>
    {!isEmpty(genetic) && (
      <div className={cn('genetic-information')}>
        <div className={cn('genetic-info-wrapper')}>
          <span className={cn('genetic-name')}>{genetic.name}</span>
          <StatusBadge status={genetic.active ? 'active' : 'disabled'} />
        </div>
        <div className={cn('genetic-info')}>
          <span>{genetic.name} ({genetic.provider})</span>
          <DotSeparator />
          <FormattedMessage id={`general.${genetic.genetic_type}`} />
        </div>
      </div>
    )}
    <div className={cn('header-actions')}>
      <Button onClick={handleClick} dark-grey>
        <FormattedMessage id={`general.button.${genetic.active ? 'disable' : 'enable'}`} />
      </Button>
    </div>
  </div>
);

GeneticHeader.propTypes = {
  className: T.string,
  genetic: T.object.isRequired,
  handleClick: T.func,
};

export default GeneticHeader;
