import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Collapse from 'react-collapse';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './GroupField.module.scss';

const cn = classnames.bind(styles);

const GroupField = ({
  title,
  label,
  hint,
  btnLabel,
  isRequired,
  isOpened,
  isSaved,
  isDisabled,
  onOpen,
  children,
  className,
}) => (
  <div className={cn('group-create-field', className, { 'blackout': !isRequired && !isSaved && !isOpened })}>
    <div className={cn('disabled-overlay', { 'visible': isDisabled })} />
    <div className={cn('field-row')}>
      <i className={cn(`fa fa-check-circle${isSaved ? '-bts' : ''}`, 'icon', { 'saved': isSaved })} />
      <div className={cn('title-box')}>
        <div className={cn('title')}>{title}</div>
        {isSaved
          ? <span className={cn('label')}>{label}</span>
          : <div className={cn('hint')}>{hint}</div>}
        <Collapse isOpened={isOpened}>
          {children}
        </Collapse>
      </div>
      <div className={cn('buttons')}>
        {!isOpened && !isSaved && (
          <Button disabled={isDisabled} primary={isRequired} light={!isRequired} onClick={onOpen}>
            {btnLabel}
          </Button>
        )}
        {!isOpened && isSaved && (
          <Button disabled={isDisabled} onClick={onOpen}>
            <FormattedMessage id="general.button.edit" />
          </Button>
        )}
      </div>
    </div>
  </div>
);

GroupField.propTypes = {
  title: T.node.isRequired,
  hint: T.node.isRequired,
  btnLabel: T.node.isRequired,
  onOpen: T.func.isRequired,
  children: T.node.isRequired,
  label: T.oneOfType([T.string, T.node]),
  className: T.string,
  isRequired: T.bool,
  isOpened: T.bool,
  isSaved: T.bool,
  isDisabled: T.bool,
};

export default GroupField;
