import React from 'react';
import T from 'prop-types';
// utils
import { isMobile } from 'react-device-detect';

const PhoneNumberLink = ({ phone, mobileOnly }) => {

  if (mobileOnly) {
    return isMobile ? <a href={`tel:${phone}`}>{phone}</a> : <span>{phone}</span>;
  }

  return <a href={`tel:${phone}`}>{phone}</a>;
};

PhoneNumberLink.propTypes = {
  phone: T.string.isRequired,
  mobileOnly: T.bool,
};

export default PhoneNumberLink;
