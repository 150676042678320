import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import FarmFeedRowSegment from './FarmFeedRowSegment';
import AudioPreview from '../AudioPreview';
// utils
import { isAudioAsset } from 'utils';
// styles
import './FarmFeedRowAudioAssets.scss';

const FarmFeedRowAudioAssets = ({ dailyCheckup, showCheckup, user }) => {
  const audioAssets = dailyCheckup.dc_assets.filter((asset) => isAudioAsset(asset) && !asset.category);
  const assets = showCheckup
    ? audioAssets
    : audioAssets.filter((asset) => (asset.creator_id === user.id) || !asset.creator_id);

  return (!!assets.length && (
    <FarmFeedRowSegment>
      <div className="segment animated fadeIn FarmFeedRowAudioAssets">
        <div className="segment-header">
          <span>
            <i className="fa fa-exclamation-triangle-bts is-trigger" />
            <FormattedMessage id="general.audioUploaded" /> • {assets.length}
          </span>
        </div>
        <div className="segment-body">
          {assets.map((asset) => (
            <div className="asset-wrapper" key={asset.id}>
              <AudioPreview
                className="assets-item audio-item"
                asset={asset}
                controlSize="40"
                hideAction
              />
              {asset.description && (
                <div className="comment-box">
                  <div className="comment-head">
                    <FormattedMessage id="general.notes" />
                  </div>
                  <div className="comment-body">{asset.description}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </FarmFeedRowSegment>
  ));
};

FarmFeedRowAudioAssets.propTypes = {
  dailyCheckup: T.object.isRequired,
  user: T.object.isRequired,
  showCheckup: T.bool,
};

export default FarmFeedRowAudioAssets;
