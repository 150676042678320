import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import { CommitmentStatusBadge } from 'components/StatusBadge/StatusBadge';
import Panel from 'components/Panel';
import CommitmentStatusHeader from 'components/CommitmentStatusHeader/CommitmentStatusHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import DeclineCommitmentModal from 'components/CommitmentModal/DeclineCommitmentModal';
import AcceptCommitmentModal from 'components/CommitmentModal/AcceptCommitmentModal';
import TruckingCompanySection from './Sections/TruckingCompanySection';
import GeneralInfoSection from './Sections/GeneralInfoSection';
import TruckingInfoSection from './Sections/TruckingInfoSection';
// redux
import { openModal } from 'reducers/modals';
import { connect } from 'react-redux';
import { setCommitment } from 'reducers/commitment';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// utils
import classnames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { prepareCalendarData } from 'utils/addToCalendarHelper';
import { getCommitmentDirection } from 'utils/commitmentsHelper';
// api
import { acceptCommitment } from 'endpoints/commitments';
// styles
import styles from './CommitmentProfile.module.scss';

const cn = classnames.bind(styles);

const CommitmentProfile = ({ commitment, openModal, setCommitment }) => {
  const { formatMessage } = useIntl();
  const { openDropdown } = useDropdownActions();
  const { acceptable, confirmable, declinable, loading, status } = commitment;
  const isButtonsVisible = acceptable && declinable;
  const calendarData = prepareCalendarData(commitment, formatMessage);
  const isCommitmentAccepted = status === 'accepted';
  const isCommitmentDeclined = status === 'declined';
  const isCommitmentPending = status === 'pending';
  const type = loading.load_type === 'transfer' ?
    `${getCommitmentDirection(commitment)}_transfer` :
    loading.load_type;
  const titleKey = `general.${type}.${status || 'pending'}`;

  const onLoadAccept = () => {
    acceptCommitment(commitment.id)
      .then((commitment) => {
        setCommitment(commitment);
        openModal(<AcceptCommitmentModal loadName={commitment.loading.name} />);
      })
      .catch(toastResponseErrors);
  };

  const onLoadDecline = () => {
    openModal(
      <DeclineCommitmentModal
        onSubmit={setCommitment}
        commitment={commitment}
      />
    );
  };

  const onDropdownOpen = (e) => {
    const options = [
      {
        label: <FormattedMessage id="general.confirm" />,
        onClick: onLoadAccept,
        hide: !acceptable || isCommitmentAccepted
      },
      {
        label: <FormattedMessage id="general.reportConflict" />,
        onClick: onLoadDecline,
        hide: !declinable || isCommitmentDeclined
      },
    ];
    openDropdown(e, options);
  };

  return (
    <Fragment>
      <Panel>
        <Panel.Heading
          title={(
            <div className={cn('header')}>
              <span className="pr-10">{loading.name}</span>
              <CommitmentStatusBadge status={status} />
            </div>
          )}
        >
          {!isCommitmentPending && confirmable && (
            <i className="fa fa-dots-three-horizontal icon-dropdown" onClick={onDropdownOpen} />
          )}
        </Panel.Heading>
        <Panel.Body noPadding>
          <div className="ph-30 pb-20">
            <div className="pt-25">
              <div className="pb-20">
                <CommitmentStatusHeader titleKey={titleKey} loadType={type} />
              </div>
              <GeneralInfoSection commitment={commitment} loadType={loading.load_type} />
            </div>
            <div className={cn('divider', 'mt-20', 'mb-20')} />
            <div className={cn('load-details')}>
              <TruckingInfoSection commitments={loading.commitments} loadType={loading.load_type} />
              <div className={cn('divider', 'mt-20', 'mb-20')} />
              <TruckingCompanySection
                isMobile={isMobile}
                loading={loading}
                calendarData={calendarData}
                openModal={openModal}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      {isButtonsVisible && (
        <div className={cn('btn-group')}>
          {!isCommitmentDeclined && (
            <Button
              red
              className={cn('button', 'decline', { 'mr-10': !isCommitmentAccepted })}
              onClick={onLoadDecline}
            >
              <FormattedMessage id="general.reportConflict" />
            </Button>
          )}
          {!isCommitmentAccepted && (
            <Button disabled={isCommitmentAccepted} onClick={onLoadAccept} blue className={cn('button', 'accept')}>
              <FormattedMessage id="general.confirm" />
            </Button>
          )}
        </div>
      )}
    </Fragment>
  );
};

CommitmentProfile.propTypes = {
  commitment: T.object.isRequired,
  openModal: T.func.isRequired,
  setCommitment: T.func.isRequired,
};

export default connect((state) => ({
  commitment: state.commitment.data,
}), { openModal, setCommitment })(CommitmentProfile);
