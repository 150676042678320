import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
// utils
import cn from 'classnames';
// styles, assets
import angelLeft from '../../../../public/images/angel-left.svg';
import angelRight from '../../../../public/images/angel-right.svg';
import styles from './CSVImportFooter.module.scss';

const CSVImportFooter = ({
  activeStep,
  closePathName,
  onBackBtnClick,
  onNextBtnClick,
  isNextDisabled,
  isBackDisabled,
  children,
}) => (
  <div className={styles.footer}>
    <button
      className={cn(styles.backBtn, { [styles.disableBtn]: isBackDisabled })}
      onClick={onBackBtnClick}
      disabled={isBackDisabled}
    >
      <img className={styles.angelImg} src={angelLeft} alt="angelLeft" />
      <FormattedMessage id="general.button.back" />
    </button>
    {children}
    {activeStep <= 2 ? (
      <button
        className={cn(styles.nextBtn, { [styles.disableBtn]: isNextDisabled })}
        onClick={onNextBtnClick}
        disabled={isNextDisabled}
      >
        <FormattedMessage id="general.button.next" />
        <img className={styles.angelImg} src={angelRight} alt="angelRight" />
      </button>
    ) : (
      <Link
        to={closePathName}
        className={cn(styles.nextBtn, { [styles.disableBtn]: isNextDisabled })}
      >
        <FormattedMessage id="container.checkupIndex.row.finish" />
        <img className={styles.angelImg} src={angelRight} alt="angelRight" />
      </Link>
    )}
  </div>
);

CSVImportFooter.propTypes = {
  activeStep: T.number.isRequired,
  onNextBtnClick: T.func.isRequired,
  closePathName: T.string.isRequired,
  isNextDisabled: T.bool.isRequired,
  isBackDisabled: T.bool.isRequired,
  onBackBtnClick: T.func,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

export default CSVImportFooter;
