import { fetchFromAPI } from 'utils/api';

export const createVirtualVisit = (resource) => {
  return fetchFromAPI('/virtual_visits', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const fetchVirtualVisit = (id) => {
  return fetchFromAPI(`/virtual_visits/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateVirtualVisit = (id, resource) => {
  return fetchFromAPI(`/virtual_visits/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteVirtualVisit = (id) => {
  return fetchFromAPI(`/virtual_visits/${id}`, { method: 'delete' }).catch((response) => {
    throw response;
  });
};

export const fetchVirtualVisits = (params) => {
  return fetchFromAPI('/virtual_visits', { params }).catch((response) => {
    throw response;
  });
};
