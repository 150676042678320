import React, { Component } from 'react';

import styles from './Templates.module.scss';
import closeImg from '../../../../../public/images/close_template.svg';


import { FormattedMessage } from 'react-intl';
// import T from 'prop-types';

import CardTemplates from './CardTemplates';
import { Link } from 'react-router';

import  User_Template from './CSVTemplatFiles/Template_File_User.csv';
import  Barn_Template from './CSVTemplatFiles/Template_File_Barn.csv';
import  Farm_Template from './CSVTemplatFiles/Template_File_Farm.csv';
import  Group_Template from './CSVTemplatFiles/Template_File_Group.csv';
import download from 'downloadjs';


const cards = [
  {
    title: <FormattedMessage id="general.imports.templates.user" />,
    type: 'user',
  },
  {
    title: <FormattedMessage id="general.imports.templates.barn" />,
    type: 'barn',
  },
  {
    title: <FormattedMessage id="general.imports.templates.farm" />,
    type: 'farm',
  },
  {
    title: <FormattedMessage id="general.imports.templates.group" />,
    type: 'group',
  }
];

class Templates extends Component {

     downloadFile =(type) => {
       const templates = {
         farm: Farm_Template,
         barn: Barn_Template,
         user: User_Template,
         group: Group_Template,
       };
       return download(templates[type], `${type}_template.csv`, 'text/csv');
     };


     render() {
       return (
         <div className={styles.Templates}>
           <div className={styles.ModalTemplates} />
           <div className={styles.Board}>
             <div className={styles.header}>
               <FormattedMessage id="general.imports.templates.header" />
               <Link to="/imports">
                 <img src={closeImg} alt="close" />
               </Link>
             </div>
             <div className={styles.bodyTemplates}>
               {cards.map((item) => {
                 return (
                   <CardTemplates
                     title={item.title}
                     onClick={this.downloadFile}
                     type={item.type}
                   />);
               })}
             </div>
           </div>
         </div>
       );
     }
}

Templates.propTypes = {

};


export default  Templates;
