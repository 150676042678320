import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
import times from 'lodash.times';
// styles
import './StepDots.scss';

const StepDots = ({ dotsCount, activeStep }) => (
  <div className="step-dots">
    {times(dotsCount, (i) => (
      <span key={i} className={cn('step-dots-item', { 'active': activeStep === i + 1 })} />
    ))}
  </div>
);

StepDots.propTypes = {
  dotsCount: T.number.isRequired,
  activeStep: T.number.isRequired,
};

export default StepDots;
