import React from 'react';
import T from 'prop-types';
// redux
import { Field } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import CountrySelectField from 'components/ReduxFormFields/SelectField/CountrySelectField';
import StatesSelectField from 'components/ReduxFormFields/SelectField/StatesSelectField';
import FormField from 'components/ReduxFormFields/FormField/FormField';
// utils, hooks
import useCountries from 'utils/hooks/useCountries';
import cn from 'classnames';

const CompositeAddressField = ({ countryCode }) => {
  const { countries, isImported } = useCountries();

  const handleGetSelectedCountryData = () => {
    if (!countryCode || !countries.length) return { stateLabel: '', zipLabel: '', stateOptions: [] };

    const country = countries.find(({ iso }) => (iso === countryCode));
    const { locality } = country.fields.reduce(((prev, next) => ({ ...prev, ...next })), {});
    const { postalcode, administrativearea } = locality.reduce(((prev, next) => ({ ...prev, ...next })), {});
    return {
      stateLabel: (administrativearea?.label || '').toLowerCase(),
      zipLabel: (postalcode?.label || '').toLowerCase().split(' ').join(''),
      stateOptions: administrativearea?.options || [],
    };
  };

  const { stateLabel, zipLabel, stateOptions } = handleGetSelectedCountryData();
  const needFlex = !stateOptions.length || !zipLabel;

  return (
    <div>
      {/* COUNTRY */}
      <div className="row">
        <div className="small-12 medium-6 column">
          <label>
            <Field
              label={<FormattedMessage id="general.country" />}
              name="country_code"
              component={CountrySelectField}
              countriesData={countries}
              isLoading={!isImported}
            />
          </label>
        </div>
      </div>

      {/* ADDRESS */}
      <div className="row">
        <div className="small-12 column">
          <label>
            <FormattedMessage id="general.streetAddress">
              {(placeholder) => (
                <Field
                  label={<FormattedMessage id="general.address" />}
                  name="address"
                  component={FormField}
                  type="text"
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
          </label>
        </div>
      </div>

      {/* CITY */}
      <div className={cn('row', { 'flex': needFlex })}>
        <div className="small-12 medium-4 large-5 column">
          <label>
            <FormattedMessage id="general.city">
              {(text) => (
                <Field
                  label={text}
                  name="city"
                  component={FormField}
                  type="text"
                  placeholder={text}
                />
              )}
            </FormattedMessage>
          </label>
        </div>

        {/* STATES */}
        {Boolean(stateOptions.length) && (
          <div className="small-12 medium-4 large-4 column">
            <label>
              <FormattedMessage id={`general.stateLabel.select.${stateLabel}`}>
                {(placeholder) => (
                  <Field
                    label={<FormattedMessage id={`general.stateLabel.${stateLabel}`} />}
                    name="state"
                    className="mr-5"
                    component={StatesSelectField}
                    states={stateOptions}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
            </label>
          </div>
        )}

        {/* ZIP_CODE */}
        {zipLabel && (
          <div className="small-12 medium-4 large-3 column">
            <label>
              <FormattedMessage id={`general.ziplabel.${zipLabel}`}>
                {(label) => (
                  <Field
                    label={label}
                    maxLength={10}
                    name="zipcode"
                    component={FormField}
                    type="text"
                    placeholder={label}
                  />
                )}
              </FormattedMessage>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

CompositeAddressField.propTypes = {
  countryCode: T.string.isRequired,
};

export default CompositeAddressField;
