import { fetchFromAPI } from 'utils/api';

export const acceptCommitment = (id) => {
  return fetchFromAPI(`/commitments/${id}/accept`, { method: 'PUT' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const declineCommitment = (id, resource) => {
  return fetchFromAPI(`/commitments/${id}/decline`, { method: 'PUT', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getCommitment = (id) => {
  return fetchFromAPI(`/commitments/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getCommitmentHealthInfo = (id) => {
  return fetchFromAPI(`/commitments/${id}/health_info`)
    .catch((response) => {
      throw response;
    });
};

export const fetchScheduledCommitments = (params) => {
  return fetchFromAPI('/commitments/schedule', { params }).catch((errors) => {
    throw errors;
  });
};

export function applyCommitmentsFilter(params) {
  return fetchFromAPI('/commitments/filter', { method: 'post', body: JSON.stringify(params) })
    .catch((errors) => {
      throw errors;
    });
}
