import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import PigsUnderCarePanel from 'components/Analytics/Panels/PigsUnderCarePanel';
import DailyMortalityPanel from 'components/Analytics/Panels/DailyMortalityPanel';
import SymptomsPanel from 'components/Analytics/Panels/SymptomsPanel';
import CompliancePanel from 'components/Analytics/Panels/CompliancePanel';
import TotalDeathsPanel from 'components/Analytics/Panels/TotalDeathsPanel';
import SubNavigation from 'components/Subnavigation';
import OfflineContainer from 'components/OfflineScreen/OfflineContainer';
// utils
import { isTablet } from 'react-device-detect';
import cn from 'classnames';
// styles
import './Analytics.scss';

// todo: companies was hidden by EP-1199, 07/02/2018

const Analytics = ({ setPageOptions }) => {
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    setPageOptions({ mobileTitle: 'analytics' });
    return () => {
      setPageOptions({ mobileTitle: '' });
    };
  }, []);

  const handleChangeCompany = (company) => {
    const nextCompanyId = company?.id || null;
    if (nextCompanyId !== companyId) setCompanyId(nextCompanyId);
  };

  return (
    <div className="AnalyticsPage">
      <SubNavigation
        currentId={companyId}
        onSelect={handleChangeCompany}
        defaultItem={<FormattedMessage id="general.allCompanies" />}
        className="small-12 columns"
      />
      <OfflineContainer>
        <div className="row centered analytics-container">
          <div className="small-12 column">
            <div className={cn('analytics-row', { isTablet })}>
              <PigsUnderCarePanel companyId={companyId} />
              <TotalDeathsPanel companyId={companyId} />
              <CompliancePanel companyId={companyId} />
            </div>
            <div className="analytics-row">
              <DailyMortalityPanel companyId={companyId} />
            </div>
            <div className="analytics-row">
              <SymptomsPanel companyId={companyId} />
            </div>
          </div>
        </div>
      </OfflineContainer>
    </div>
  );
};

Analytics.propTypes = {
  setPageOptions: T.func.isRequired,
};

export default connect(null, { setPageOptions })(Analytics);
