import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import TenantAssetIcon from './TenantAssetIcon';
// utils
import { bytesToSize } from 'utils';
import { getFileTypeData, getFileUploadState } from 'utils/tenantAssetsHelper';
import classnames from 'classnames/bind';
// styles
import styles from './UploadsList.module.scss';

const cn = classnames.bind(styles);

const UploadsList = ({ files, onClose, onFileUploadCancel }) => (
  <div className={cn('uploads-list')}>
    <div className={styles.header}>
      <FormattedMessage id="component.uploadsList.completedCount" values={{ count: files.length }} />
      <i className={cn('fa fa-times', 'close-icon')} onClick={onClose} />
    </div>
    {files.map((resumableFile, index) => {
      const fileName = resumableFile.fileName || resumableFile.file.name;
      const { extension, contentType } = getFileTypeData(fileName);
      const { uploaded, style } = getFileUploadState(resumableFile);

      return (
        <div className={cn('asset-wrapper')} key={`${index}-${resumableFile.uniqueIdentifier}`}>
          {uploaded && (
            <div className={cn('file-icon-col')}>
              <TenantAssetIcon contentType={contentType} />
            </div>
          )}
          <div className={cn('uploading-asset-info')}>
            <div className={cn('asset-name', { 'not-uploaded': !uploaded })}>{fileName}</div>
            {uploaded && (
              <div className={cn('asset-size')}>{extension} | {bytesToSize(resumableFile.size)}</div>
            )}
            {!uploaded && (
              <div className={cn('upload-progress')}>
                <div className={cn('progress-value')} style={style} />
              </div>
            )}
          </div>
          {!uploaded && (
            <i className={cn('fa fa-times', 'cancel-upload-icon')} onClick={() => onFileUploadCancel(resumableFile)} />
          )}
        </div>
      );
    })}
  </div>
);

UploadsList.propTypes = {
  files: T.array.isRequired,
  onClose: T.func.isRequired,
  onFileUploadCancel: T.func.isRequired,
};

export default UploadsList;
