import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './GraphLegend.module.scss';

const cn = classnames.bind(styles);
const legendTypes = ['square', 'triangle'];

const GraphLegend = ({
  className,
  text,
  type = 'square',
  background = '',
  backgroundColor = '',
  isReverseText = false,
}) => {
  if (!legendTypes.includes(type)) return null;
  const style = { background, backgroundColor };

  return (
    <div className={cn('legend-item', { [className]: !!className, 'is-reverse': isReverseText })}>
      <div style={style} className={cn('designation', type, { 'mr-10': !isReverseText })} />
      <div className={cn('legend-label', { 'mr-5': isReverseText })}>{text}</div>
    </div>
  );
};

GraphLegend.propTypes = {
  className: T.string,
  text: T.oneOfType([T.string, T.node]).isRequired,
  type: T.oneOf(legendTypes),
  background: T.string,
  backgroundColor: T.string,
  isReverseText: T.bool,
};

export default GraphLegend;
