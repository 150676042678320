import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

const DefaultLayout = ({ layout: { gray }, children }) => (
  <div className="layout-takeover">
    <div className={cn('bodybag', { gray })} id="bodybag">
      {children}
    </div>
  </div>
);

DefaultLayout.propTypes = {
  children: T.object,
  layout: T.shape({
    gray: T.bool,
  }).isRequired,
};

export default connect((state) => ({ layout: state.layout }))(DefaultLayout);
