import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { formatDosageType } from 'utils';

const InjectableTreatmentLabel = ({ quantity, product, measurement_system }) => {
  const dosage_type = formatDosageType(product.dosage_type, measurement_system);

  return (
    <>
      <span>{product.name} • {quantity} </span>
      <FormattedMessage
        id="container.checkupEdit.reportMedications.injectableLabel"
        values={{
          type: <FormattedMessage id={`general.short.${dosage_type}`} />,
          measure: <FormattedMessage id={`general.${product.dosage_measure}`} />,
        }}
      />
    </>
  );
};

InjectableTreatmentLabel.propTypes = {
  product: T.object.isRequired,
  quantity: T.oneOfType([T.string, T.number]),
  measurement_system: T.string,
};

export default connect((state) => ({
  measurement_system: state.auth.user.current_company.measurement_system,
}))(InjectableTreatmentLabel);
