import React from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
// utils
import cn from 'classnames';
// styles
import './ModeSwitcher.scss';

const ModeSwitcher = ({ mapLink, tableLink, className }) => (
  <div className={cn('ModeSwitcher', className)}>
    <Link to={tableLink} activeClassName="active" onlyActiveOnIndex>
      <div className="fa fa-ulist" />
    </Link>
    <Link to={mapLink} activeClassName="active" onlyActiveOnIndex>
      <div className="fa fa-globe-bts" />
    </Link>
  </div>
);

ModeSwitcher.propTypes = {
  mapLink: T.string.isRequired,
  tableLink: T.string.isRequired,
  className: T.string,
};

export default ModeSwitcher;
