import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserFilterSectionDropdown from '../UserFilterSectionDropdown';
import EntityNameMenu from '../Menu/EntityNameMenu';
// utils
import isEqual from 'lodash.isequal';
// helper
import { getConditionTranslationsBySection, formatOptionsValues } from 'utils/userFiltersHelper';
import { entityNameSections, sectionEntityKeys } from 'utils/constants/userFilters';

class EntityNameSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity || {},
      text: '',
      value: props.condition,
      ...formatOptionsValues(props.options)
    };
  }

  handleConditionChange = ({ target: { name } }) => {
    this.setState({ value: name, entity: {}, text: '' });
  };

  handleEntitySelect = (option) => {
    if (!option) return;
    this.setState({ entity: option.item });
  };

  handleInputChange = ({ target: { value } }) => {
    this.setState({ text: value });
  };

  handleApply = () => {
    const { onOptionSelect, type } = this.props;
    const { value, entity, text } = this.state;
    if (['only', 'except'].includes(value)) {
      onOptionSelect(value, [], { target_id: entity.id, [sectionEntityKeys[type]]: entity });
      return;
    }
    onOptionSelect(value, [{ key: 'text', value: text }]);
  };

  resetData = () => {
    const { condition, entity, options } = this.props;
    const newState = { entity: entity || {}, value: condition, text: '', ...formatOptionsValues(options) };
    const isEqualState = isEqual(newState, this.state);
    if (!isEqualState) this.setState(newState);
  };

  renderDropdownMenu = (isOpened) => {
    const { listOptions, type } = this.props;
    const { value, entity, text } = this.state;
    return (
      <EntityNameMenu
        type={type}
        isOpened={isOpened}
        currentValue={value}
        options={listOptions}
        onConditionChange={this.handleConditionChange}
        inputValue={text}
        onInputChange={this.handleInputChange}
        selectedEntity={entity}
        onSelect={this.handleEntitySelect}
        onApply={this.handleApply}
      />
    );
  };

  getLabel = () => {
    const { placeholder, entity, options, type, condition, isInvalid } = this.props;
    if (isInvalid || !condition) return placeholder;
    const optionsValues = formatOptionsValues(options);
    const values = { name: entity.name || entity.full_name, text: optionsValues.text };
    const messageKey = getConditionTranslationsBySection(type)[condition];
    return <FormattedMessage id={messageKey} values={values} />;
  };

  render() {
    const { condition, icon, onSectionRemove, isInitialOpened, isRemovable, isDisabled, isInvalid } = this.props;
    return (
      <UserFilterSectionDropdown
        isInitialOpened={isInitialOpened}
        isDisabled={isDisabled}
        currentValue={condition}
        triggerLabel={this.getLabel()}
        menuRenderer={this.renderDropdownMenu}
        isRemovable={isRemovable}
        onRemove={onSectionRemove}
        triggerIcon={icon}
        onClose={this.resetData}
        isInvalid={isInvalid}
      />
    );
  }
}

EntityNameSection.propTypes = {
  type: T.oneOf(entityNameSections).isRequired,
  condition: T.string.isRequired,
  entity: T.object,
  icon: T.string.isRequired,
  listOptions: T.array.isRequired,
  options: T.array,
  onOptionSelect: T.func,
  onSectionRemove: T.func,
  isRemovable: T.bool,
  isDisabled: T.bool,
  isInvalid: T.bool,
  isInitialOpened: T.bool,
  placeholder: T.oneOfType([T.node, T.string]),
};

EntityNameSection.defaultProps = {
  options: [],
  entity: {},
};

export default EntityNameSection;
