import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
import UserFilterSectionDropdown from '../UserFilterSectionDropdown';
import EntityValueMenu from '../Menu/EntityValueMenu';
// utils
import isEqual from 'lodash.isequal';
// helper
import { formatOptionsValues, getConditionTranslationsBySection } from 'utils/userFiltersHelper';
import { numberSections } from 'utils/constants/userFilters';

function getInitialValues(props) {
  const { valueKey, valueEndKey, valueStartKey } = props;
  return {
    [valueKey]: '',
    [valueStartKey]: '',
    [valueEndKey]: '',
  };
}

function findObjectToUpdate(name, value, props) {
  const { valueKey, valueEndKey, valueStartKey } = props;
  switch (name) {
    case 'start': return { [valueStartKey]: value };
    case 'end': return { [valueEndKey]: value };
    case 'value': return { [valueKey]: value };
    default: return {};
  }
}

const unit = <FormattedWeight hasOnlyUnit />;

class EntityValueSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...getInitialValues(props),
      isRangeError: false,
      condition: props.condition,
      ...formatOptionsValues(props.options),
    };
  }

  handleConditionChange = ({ target: { name } }) => {
    this.setState({ condition: name, isRangeError: false, ...getInitialValues(this.props) });
  };

  handleInputChange = ({ target: { value, name, validity } }) => {
    if (!validity.valid) return;
    this.setState({ ...findObjectToUpdate(name, value, this.props), isRangeError: false });
  };

  handleApply = (e) => {
    const { onOptionSelect, valueKey, valueEndKey, valueStartKey } = this.props;
    const { condition } = this.state;
    if (condition === 'any') {
      onOptionSelect(condition);
      return;
    }
    if (condition === 'range') {
      if ((+this.state[valueStartKey] || 0) >= (+this.state[valueEndKey] || 0)) {
        e.stopPropagation();
        this.setState({ isRangeError: true });
        return;
      }
      onOptionSelect(condition, [
        { key: valueEndKey, value: this.state[valueEndKey] || 0 },
        { key: valueStartKey, value: this.state[valueStartKey] || 0 },
      ]);
      return;
    }
    onOptionSelect(condition, [{ key: valueKey, value: this.state[valueKey] }]);
  };

  resetData = () => {
    const { condition, options } = this.props;
    const newState = {
      ...getInitialValues(this.props),
      isRangeError: false,
      condition,
      ...formatOptionsValues(options),
    };
    const isEqualState = isEqual(newState, this.state);
    if (!isEqualState) this.setState(newState);
  };

  renderDropdownMenu = (isOpened) => {
    const { listOptions, inputLabel, maxValue, valueKey, valueEndKey, valueStartKey, step } = this.props;
    const { condition, isRangeError } = this.state;
    return (
      <EntityValueMenu
        isOpened={isOpened}
        currentValue={condition}
        options={listOptions}
        onConditionChange={this.handleConditionChange}
        onApply={this.handleApply}
        inputValue={this.state[valueKey]}
        inputStartValue={this.state[valueStartKey]}
        inputEndValue={this.state[valueEndKey]}
        onInputChange={this.handleInputChange}
        inputLabel={inputLabel}
        hasRangeError={isRangeError}
        maxInputValue={maxValue}
        inputStep={step}
      />
    );
  };

  getLabel = () => {
    const { condition, placeholder, options, type, isInvalid } = this.props;
    if (isInvalid || !condition) return placeholder;
    const countValues = formatOptionsValues(options);
    const messageKey = getConditionTranslationsBySection(type)[condition];
    return (
      <FormattedMessage
        id={messageKey}
        values={{
          unit,
          value: countValues.count,
          value_start: countValues.count_from,
          value_end: countValues.count_to,
          ...countValues
        }}
      />
    );
  };

  render() {
    const { condition, icon, onSectionRemove, isInitialOpened, isRemovable, isDisabled, isInvalid } = this.props;
    return (
      <UserFilterSectionDropdown
        isInitialOpened={isInitialOpened}
        isDisabled={isDisabled}
        currentValue={condition}
        triggerLabel={this.getLabel()}
        menuRenderer={this.renderDropdownMenu}
        isRemovable={isRemovable}
        onRemove={onSectionRemove}
        triggerIcon={icon}
        isInvalid={isInvalid}
        onClose={this.resetData}
      />
    );
  }
}

EntityValueSection.propTypes = {
  condition: T.string.isRequired,
  icon: T.string.isRequired,
  listOptions: T.array.isRequired,
  type: T.oneOf(numberSections).isRequired,
  placeholder: T.oneOfType([T.node, T.string]).isRequired,
  inputLabel: T.oneOfType([T.node, T.string]).isRequired,
  options: T.array,
  onOptionSelect: T.func,
  onSectionRemove: T.func,
  isRemovable: T.bool,
  isDisabled: T.bool,
  isInitialOpened: T.bool,
  isInvalid: T.bool,
  valueKey: T.string,
  valueStartKey: T.string,
  valueEndKey: T.string,
  maxValue: T.number,
  step: T.number,
};

EntityValueSection.defaultProps = {
  options: [],
  valueKey: 'value',
  valueStartKey: 'value_start',
  valueEndKey: 'value_end',
  maxValue: 999999,
  step: 1,
};

export default EntityValueSection;
