import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from '../utils/api';
import { updateAuthUserData } from './auth';
import pickBy from 'lodash.pickby';

// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  page: 1,
  per_page: 25,
  search: '',
  filter: '',
  sort: ''
};

const initialState = {
  resources: [],
  isLoading: false,
  params: {
    ...defaultParams
  },
  meta: {
    total: 0,
    stats: {}
  },
};

const FETCH_INVENTORIES = 'inventories/FETCH_INVENTORIES';
const [FETCH_INVENTORIES_PENDING, FETCH_INVENTORIES_FULFILLED, FETCH_INVENTORIES_REJECTED] = [
  `${FETCH_INVENTORIES}_PENDING`,
  `${FETCH_INVENTORIES}_FULFILLED`,
  `${FETCH_INVENTORIES}_REJECTED`
];

const CONFIRM_INVENTORY = 'inventories/CONFIRM_INVENTORY';
const [CONFIRM_INVENTORY_PENDING, CONFIRM_INVENTORY_FULFILLED, CONFIRM_INVENTORY_REJECTED] = [
  `${CONFIRM_INVENTORY}_PENDING`,
  `${CONFIRM_INVENTORY}_FULFILLED`,
  `${CONFIRM_INVENTORY}_REJECTED`
];

const UPDATE_INVENTORY = 'inventories/UPDATE_INVENTORY';
const [UPDATE_INVENTORY_PENDING, UPDATE_INVENTORY_FULFILLED, UPDATE_INVENTORY_REJECTED] = [
  `${UPDATE_INVENTORY}_PENDING`,
  `${UPDATE_INVENTORY}_FULFILLED`,
  `${UPDATE_INVENTORY}_REJECTED`
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchInventories = (params = defaultParams) => (dispatch) => {
  dispatch(createAction(FETCH_INVENTORIES_PENDING)());
  return fetchFromAPI(params.admin ? '/admin/inventories' : '/inventories', { params: pickBy(params) })
    .then((response) => dispatch(createAction(FETCH_INVENTORIES_FULFILLED)({ ...response, params })))
    .catch((response) => {
      dispatch(createAction(FETCH_INVENTORIES_REJECTED)(response));
      throw response;
    });
};

export const submitMyInventory = () => (dispatch) => {
  return fetchFromAPI('/user/submit_inventories', { method: 'POST' })
    .then((response) => dispatch(updateAuthUserData({ ...response.resource, pig_groups_locked: false })));
};

export const updateAdminInventory = (id, data) => createAction(UPDATE_INVENTORY)(
  fetchFromAPI(`/admin/inventories/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ resource: data })
  })
);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_INVENTORIES
  [FETCH_INVENTORIES_PENDING]: (state) => ({
    ...state,
    isLoading: true
  }),
  [FETCH_INVENTORIES_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false
  }),
  [FETCH_INVENTORIES_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false
  }),

  // CONFIRM_INVENTORY
  [CONFIRM_INVENTORY_PENDING]: (state) => ({
    ...state,
    isLoading: true
  }),
  [CONFIRM_INVENTORY_FULFILLED]: (state, action) => {
    const { resource } = action.payload;
    const inventory = state.resources.find(({ id }) => id === resource.id);
    const inventoryIndex = state.resources.indexOf(inventory);
    return {
      ...state,
      resources: [
        ...state.resources.slice(0, inventoryIndex),
        { ...inventory, ...resource },
        ...state.resources.slice(inventoryIndex + 1),
      ],
      isLoading: false
    };
  },
  [CONFIRM_INVENTORY_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false
  }),

  // UPDATE_INVENTORY
  [UPDATE_INVENTORY_PENDING]: (state) => ({
    ...state,
    isLoading: true
  }),
  [UPDATE_INVENTORY_FULFILLED]: (state, action) => {
    const { resource } = action.payload;
    const inventory = state.resources.find(({ id }) => id === resource.id);
    const inventoryIndex = state.resources.indexOf(inventory);
    return {
      ...state,
      resources: [
        ...state.resources.slice(0, inventoryIndex),
        resource,
        ...state.resources.slice(inventoryIndex + 1)
      ],
      isLoading: false
    };
  },
  [UPDATE_INVENTORY_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
