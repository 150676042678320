import React from 'react';
import T from 'prop-types';

const Spacer = ({ size, className }) => {
  const style = { height: size + 'px' };
  return (
    <div className={`Spacer ${className}`} style={style} />
  );
};

Spacer.defaultProps = {
  size: '20',
  className: '',
};

Spacer.propTypes = {
  size: T.string,
  className: T.string,
};

export default Spacer;
