import React from 'react';
import T from 'prop-types';
// styles
import styles from './DateLine.module.scss';

const DateLine = ({ date }) => (
  <div className={styles.container}>
    <span className={styles.date}>{date}</span>
    <span className={styles.line} />
  </div>
);

DateLine.propTypes = {
  date: T.string.isRequired,
};

export default DateLine;
