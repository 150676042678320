import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './TruckingStatusLine.module.scss';

const cn = classnames.bind(styles);

const theme = {
  arrival: 'blue-theme',
  sale: 'orange-theme',
  transfer: 'yellow-theme',
};

const TruckingStatusLine = ({ direction, type, date }) => (
  <div className={cn('trucking-line', { [theme[type]]: true })}>
    <i className={cn('fa fa-arrow-right', 'mh-5', { outgoing: direction === 'out' })} />
    <FormattedMessage id={`general.direction.${direction}`} />&nbsp;
    (<FormattedMessage id={`general.loadType.${type}`} />) &bull; {date}
  </div>
);

TruckingStatusLine.propTypes = {
  type: T.string.isRequired,
  direction: T.string.isRequired,
  date: T.string.isRequired,
};

export default TruckingStatusLine;
