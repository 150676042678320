import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import OfflineScreen from 'components/OfflineScreen';
// utils
import { FormattedMessage } from 'react-intl';
// styles
import './ImportTable.scss';
import { setPageOptions } from '../../../../reducers/layout';
import { setBreadcrumbs } from '../../../../reducers/breadcrumbs';
import ColumnsTable from './ColumnsTable/ColumnTable';
import once from 'lodash.once';
import moment from 'moment';
import { setSelectedArchiveImports } from '../../../../reducers/importCSV';

const links = [
  { backLink: '/imports', label: <FormattedMessage id="general.imports.table" /> },
];

class ImportTable extends Component {

  componentDidMount() {
    const { setPageOptions, setBreadcrumbs } = this.props;
    setPageOptions({ links });
    setBreadcrumbs();
  }

  componentWillUnmount() {
    this.props.setPageOptions({ links: null });
  }

  setHeaderList = once((results) => {
    const { setSelectedArchiveImports, setPageOptions, setBreadcrumbs } = this.props;
    setPageOptions({ links: null });
    setSelectedArchiveImports(results);
    const typeArr = ['groups', 'farms'];
    const columnsType = {
      groups: 'Groups ',
      barns: 'Barns ',
      farms: 'Farms ',
      users: 'Users ',
    };
    const nameLink = `${typeArr.map((type) => columnsType[type])} ${moment(results.created_at).format('MM/DD/YY')}`;
    return (
      setBreadcrumbs([
        { label: <FormattedMessage id="general.pageTitle.imports" />, path: '/imports' },
        { label: nameLink, path: `/imports/${results.id}`, useLabelAsMobileTitle: true },
      ])
    );
  });

  render() {
    const { isOnline } = this.props;
    return (
      <div className="wrapperApi">
        <div className="BarnsheetsHome clearfix">
          {isOnline
            ? (
              <div className="small-12 column">
                <ColumnsTable
                  setHeaderList={this.setHeaderList}
                />
              </div>
            )
            : <OfflineScreen />}
        </div>
      </div>
    );
  }
}

ImportTable.propTypes = {
  isOnline: T.bool,
  setPageOptions: T.func,
  setBreadcrumbs: T.func,
  setSelectedArchiveImports: T.func,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  }),
  { setPageOptions, setBreadcrumbs, setSelectedArchiveImports }
)(ImportTable);
