import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import ReactSelect from 'components/Select/ReactSelect';
import Button from 'components/Button';
// utils
import getValue from 'lodash.get';
import cn from 'classnames';
// styles
import './MatchColumnItem.scss';

const checkDisabledFields = (allFields, key) => (options) => {
  return options.map((option) => ({
    ...option,
    disabled: Boolean(allFields.find((field) => getValue(field, key) === option.value && !field.skipped)),
  }));
};

const MatchColumnItem = ({
  selectedOptions,
  onEditAction,
  isEdit,
  onSkipAction,
  onMatchAction,
  selected,
  onChange,
  data,
  columnName,
  isAutoMatch,
  options,
}) => {
  const { formatMessage } = useIntl();
  const optionList = checkDisabledFields(selectedOptions, 'value')(options);
  const foundColumn = selectedOptions.find(({ label }) => label === columnName);
  const isSkipped = foundColumn?.skipped || false;
  const isMatched = foundColumn?.matched || false;
  return (
    <div
      className={cn('MatchColumnItem',
        {
          'active-state': isAutoMatch || isEdit,
          'skipped': isSkipped && !isEdit,
          'matched': isMatched && !isEdit,
        })}
    >
      <div className="select-block">
        {((!isMatched && !isSkipped && isAutoMatch) || isEdit) && (
          <div className="active-block">
            {isAutoMatch &&
              <div className="automatch-caption"><FormattedMessage id="general.autoMatched" /></div>}
            <ReactSelect
              grey
              className="pt-5 pb-5 mt-10 mb-10"
              noInputMargin
              valueKey="value"
              labelKey="label"
              placeholder={formatMessage({ id: 'general.button.select' })}
              options={optionList}
              onChange={onChange}
              value={selected}
            />
            <div className="mb-10">
              <Button
                small
                primary
                disabled={!((optionList.filter(({ disabled }) => !disabled).length > 0 || selected) && selected)}
                onClick={onMatchAction}
              >
                <FormattedMessage id="general.button.save" /> <i className="fa fa-angle-right ml-5" />
              </Button>
              <span className="skip-button ml-10" onClick={onSkipAction}>
                <FormattedMessage id="general.button.skip" />
              </span>
            </div>
          </div>
        )}
        {(!isMatched && !isSkipped && !isEdit && !isAutoMatch) && (
          <div className="unmatch-block">
            <b>Unmatched Column</b>
            <div>
              <span className="unmatch-btn" onClick={onEditAction}>
                <FormattedMessage id="general.button.edit" />
              </span>&nbsp;&bull;&nbsp;
              <span className="unmatch-btn" onClick={onSkipAction}>
                <FormattedMessage id="general.button.skip" />
              </span>
            </div>
          </div>
        )}
        {isMatched && !isEdit && (
          <div className="matched-block">
            <b>{options.find(({ value }) => value === selected)?.label}</b>
            <div>
              <span className="unmatch-btn" onClick={onEditAction}>
                <FormattedMessage id="general.button.edit" />
              </span>&nbsp;&bull;&nbsp;
              <span className="unmatch-btn" onClick={onSkipAction}>
                <FormattedMessage id="general.button.skip" />
              </span>
            </div>
          </div>
        )}
        {isSkipped && !isEdit && (
          <div className="skipped-block">
            <b className="skipped-caption">WILL NOT BE IMPORTED</b>
            <span className="unmatch-btn" onClick={onEditAction}>
              <FormattedMessage id="general.button.edit" />
            </span>
          </div>
        )}
      </div>
      <div className="data-block">
        <div className="column-item">
          {columnName}
        </div>
        {data.map((value, ind) => (
          <div className="data-item" key={ind}>{value}</div>
        ))}
      </div>
    </div>
  );
};

MatchColumnItem.propTypes = {
  selectedOptions: T.array,
  onEditAction: T.func,
  isEdit: T.bool,
  onSkipAction: T.func,
  selected: T.string,
  onChange: T.func,
  data: T.array,
  columnName: T.string,
  isAutoMatch: T.bool,
  onMatchAction: T.func,
  options: T.array,
};

export default MatchColumnItem;
