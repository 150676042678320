import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchActivities, setDefaultParams } from 'reducers/farmfeed';
import { setSearch, setSearchText } from 'reducers/farmFeedSearch';
import { openPortal } from 'reducers/portal';
// components
import FarmFeedAutoSuggestMobile from './FarmFeedAutoSuggestMobile/FarmFeedAutoSuggestMobile';
import FiltersSearchInput from 'components/FiltersSearchInput';
// api
import { searchActivities } from 'endpoints/farmfeed';

const FarmFeedSearchInput = ({
  farmFeedParams,
  fetchActivities,
  openPortal,
  setDefaultParams,
  setSearch,
  setSearchText,
  searchText,
}) => {
  const getSearchEntity = (value) => {
    const params = value.type === 'search'
      ? { search: value.text, filters: {} }
      : { filters: { [`${value.type}_id`]: value.id }, search: '' };
    fetchActivities({ ...farmFeedParams, ...params });
    setSearch(params);
    setSearchText(value.type === 'search' ? value.text : value.name);
  };

  const handleOpenSearchPortal = () => {
    openPortal(
      <FarmFeedAutoSuggestMobile
        getSuggestions={(search) => searchActivities({ search })}
        onSelect={getSearchEntity}
      />
    );
  };

  const handleCloseSearch = async (e) => {
    e.stopPropagation();
    setDefaultParams();
    setSearch({});
    await fetchActivities();
    setSearchText('');
  };

  return (
    <FiltersSearchInput
      onClick={handleOpenSearchPortal}
      onClose={handleCloseSearch}
      value={searchText}
    />
  );
};

FarmFeedSearchInput.propTypes = {
  openPortal: T.func.isRequired,
  fetchActivities: T.func.isRequired,
  setDefaultParams: T.func.isRequired,
  setSearch: T.func.isRequired,
  setSearchText: T.func.isRequired,
  farmFeedParams: T.object.isRequired,
  searchText: T.string.isRequired,
};

export default connect(
  (state) => ({
    farmFeedParams: state.farmfeed.params,
    searchText: state.farmFeedSearch.searchText,
  }), {
    fetchActivities,
    setDefaultParams,
    setSearch,
    setSearchText,
    openPortal,
  }
)(FarmFeedSearchInput);
