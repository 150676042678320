import { useState } from 'react';

export default function useCommentWithMentionsState(initialComment = '', initialMentions = []) {
  const initialState = { comment: initialComment, mentions: initialMentions };
  const [state, setState] = useState(initialState);
  const setComment = (comment) => setState((prevState) => ({ ...prevState, comment }));
  const setMentions = (mentions) => setState((prevState) => ({ ...prevState, mentions }));

  return {
    comment: state.comment,
    mentions: state.mentions,
    setCommentWithMentions: setState,
    setComment,
    setMentions,
    resetCommentWithMentions: () => setState({ comment: '', mentions: [] }),
    resetToInitial: () => setState(initialState),
  };
}
