import React, { PureComponent } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateDestination, fetchDestination } from 'reducers/destinations';
// components
import { FormattedMessage } from 'react-intl';
import DestinationForm from './components/DestinationForm';
import Panel from 'components/Panel';
// utils
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import handleFormErrors from 'utils/handleFormErrors';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const destinationFields = ['name', 'address', 'city', 'state', 'country_code', 'zipcode', 'destination_type',
  'country_code', 'prem_id'];

class DestinationEdit extends PureComponent {

  componentDidMount() {
    const { fetchDestination, params: { id } } = this.props;
    fetchDestination(id)
      .catch((errors) => {
        toastResponseErrors(errors);
        this.context.router.push('/farmfeed');
      });
  }

  getInitialFormValues = () => {
    const { destination, params: { id } } = this.props;
    if (!isEmpty(destination) && parseInt(id, 10) === destination.id) return pick(destination, destinationFields);
    return null;
  };

  updateDestination = (data) => {
    const { router } = this.context;
    const { updateDestination, params: { id } } = this.props;
    return updateDestination(id, data)
      .then(() => {
        showToastrMessage('component.toastr.destination.updated');
        router.push('/destinations');
      })
      .catch(handleFormErrors);
  };

  render() {
    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="component.destinationEdit.editExternaldDstination" />} />
          <Panel.Body noPadding>
            <div className="row">
              <section className="small-12 medium-10 large-8 column">
                <DestinationForm
                  isEditMode
                  onSubmit={this.updateDestination}
                  initialValues={this.getInitialFormValues()}
                />
              </section>
            </div>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

DestinationEdit.propTypes = {
  updateDestination: T.func.isRequired,
  fetchDestination: T.func.isRequired,
  params: T.object.isRequired,
  destination: T.object,
};

DestinationEdit.contextTypes = {
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    destination: state.destinations.resource,
  }),
  { updateDestination, fetchDestination }
)(DestinationEdit);
