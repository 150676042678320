import React, { Component } from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import NavButton from 'components/NavButton/NavButton';

class CheckupNavigation extends Component {

  state = {
    scrolled: 0,
  };

  componentDidMount() {
    this.bodybag = document.getElementById('bodybag');
    this.bodybag.addEventListener('scroll', this.handleScrollEvent);
  }

  componentWillUnmount() {
    this.bodybag.removeEventListener('scroll', this.handleScrollEvent);
  }

  handleScrollEvent = () => {
    this.setState({
      scrolled: this.bodybag.scrollTop,
    });
  };

  getCheckupUrl = (id) => {
    const { isEditMode } = this.props;
    const query = isEditMode ? { editMode: true } : {};
    return { pathname: `/barnsheets/daily-checkup/${id}`, query };
  };

  render() {
    const { checkup } = this.props;
    const { scrolled } = this.state;

    return (
      <div className="CheckupNavigation">
        {checkup.prev_checkup_id && (
          <Link to={this.getCheckupUrl(checkup.prev_checkup_id)} className="show-for-large">
            <NavButton left scrollTop={scrolled} />
          </Link>
        )}

        {checkup.next_checkup_id && (
          <Link to={this.getCheckupUrl(checkup.next_checkup_id)} className="show-for-large">
            <NavButton right scrollTop={scrolled} />
          </Link>
        )}
      </div>
    );
  }
}

CheckupNavigation.propTypes = {
  checkup: T.object.isRequired,
  isEditMode: T.bool,
};

export default CheckupNavigation;
