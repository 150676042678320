import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
import { updateUserSetting, updateCurrentUser } from 'reducers/auth';
// components
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import DateInput from 'components/DateInput/DateInput';
import Preloader from 'components/Preloader';
// utils
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import moment from 'moment';
// translation
import { FormattedMessage } from 'react-intl';
// styles
import './BirthdayModal.scss';
import drBotLogo from '../../../public/images/dr-bot.svg';

const dateReg = /^((0|1)\d{1})\/((0|1|2)\d{1})\/((19|20)\d{2})/;

class BirthdayModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      birthDate: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    this.updateBirthdayModalStatus();
  }

  updateBirthdayModalStatus = () => {
    const { user, updateCurrentUser } = this.props;

    return updateCurrentUser(
      { static_store: Object.assign(user.static_store, { BIRTHDAY_MODAL_SEEN: true }) },
      user.id
    )
      .catch(toastResponseErrors);
  };

  updateUser = (birthdate) => {
    const { user: { id }, updateUserSetting, updateCurrentUser, closeModal } = this.props;

    this.setState({ isLoading: true });
    return updateCurrentUser({ birthday_date: moment(birthdate, 'MM/DD/YYYY').format('YYYY-MM-DD') }, id)
      .then(() => {
        this.setState({ isLoading: false });
        updateUserSetting({ birthday_congrats: true }, id)
          .then(() => {
            closeModal();
          })
          .catch(() => {
            closeModal();
            return toastResponseErrors;
          });
      })
      .catch(() => {
        closeModal();
        this.setState({ isLoading: false });
        return toastResponseErrors;
      });
  };

  closeModalWindow = () => {
    const { closeModal, updateUserSetting, user: { id } } = this.props;

    return updateUserSetting({ birthday_congrats: false }, id)
      .then(closeModal)
      .catch(() => {
        closeModal();
        return toastResponseErrors;
      });
  };

  onChange = (e) => {
    const birthDate = e.target.value;
    this.setState({ birthDate });
  };

  onMobileChange = (birthDate) => {
    this.setState({ birthDate });
  };

  render() {
    const { birthDate, isLoading } = this.state;
    const { isMobile } = this.context;

    const validDate = (current) => {
      return current.isBefore(moment());
    };

    const date = moment(birthDate, 'MM/DD/YYYY');
    const disabled = !(dateReg.test(birthDate) && date.isBefore() && date.format('MM/DD/YYYY') === birthDate);

    return (
      <Modal
        noCloseButton
        className={cn('BirthdayModal', { 'mobile-view': isMobile })}
      >
        <section className="modal-body">
          <div className="section-block">
            <Preloader isActive={isLoading} />
            <div className="dr-bot-body">
              <img className="dr-bot-doc-image" src={drBotLogo} alt="Dr.Bot" />
              <br />
              <div className="dr-bot-doc-name"><FormattedMessage id="general.drBot" /></div>
              <div className="title-block">
                <FormattedMessage id="general.setBirthdateDescription" />
              </div>
              <div className="content-block">
                <DateInput
                  clearable
                  onValidDate={validDate}
                  value={birthDate}
                  onChange={this.onChange}
                  onMobileChange={this.onMobileChange}
                  onClear={() => this.setState({ birthDate: '' })}
                />
              </div>
              <section className="action-buttons">
                <Button
                  disabled={disabled}
                  primary
                  onClick={() => this.updateUser(birthDate)}
                >
                  <FormattedMessage id="general.button.done" />
                </Button>
                <Button
                  className="mt-20"
                  transparent
                  onClick={this.closeModalWindow}
                >
                  <FormattedMessage id="general.button.noThanks" />
                </Button>
              </section>
            </div>
          </div>
        </section>
      </Modal>
    );
  }
}

BirthdayModal.contextTypes = {
  isMobile: T.bool,
};

BirthdayModal.propTypes = {
  closeModal: T.func.isRequired,
  user: T.object.isRequired,
  updateCurrentUser: T.func,
  updateUserSetting: T.func,
};

export default connect(
  null,
  { closeModal, updateCurrentUser, updateUserSetting }
)(BirthdayModal);
