import React, { Fragment } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closePigGroup, resetPigGroupMarketing } from 'reducers/pigGroups/pigGroupShow';
// components
import PigGroupMovementsContainer from 'components/Groups/PigGroupMovementsContainer';
import PigGroupMigrationPanel from 'components/Groups/PigGroupMigrationPanel';
import UserPanel from 'components/UserPanel/UserPanel';
import Button from 'components/Button';
import GroupFeedPanel from 'components/Groups/GroupFeedPanel/GroupFeedPanel';
import GroupMarketingPanel from './components/GroupMarketingPanel';
import { FormattedMessage } from 'react-intl';
// endpoints
import { getPigGroupMigrations } from 'endpoints/pigGroups';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
// styles
import styles from './GroupFeed.module.scss';

const cn = classnames.bind(styles);

const GroupFeed = ({
  closePigGroup,
  currentUser,
  pigGroup,
  params: { id: groupId },
  resetPigGroupMarketing,
}) => {
  const handleCloseGroup = () => {
    closePigGroup(groupId)
      .then(() => {
        showToastrMessage('component.toastr.group.wasClosed');
      })
      .catch(toastResponseErrors);
  };

  const handleResetMarketingGroup = () => {
    resetPigGroupMarketing(groupId)
      .then(() => {
        showToastrMessage('component.toastr.group.markAsNotMarketing');
      })
      .catch(toastResponseErrors);
  };

  const showCloseGroupSection = ['opened', 'pending_close'].includes(pigGroup.status) && !pigGroup.pigs;
  const marketingDropdownOptions = [
    { label: <FormattedMessage id="component.groupFeed.markGroupNotMarketing" />, onClick: handleResetMarketingGroup },
  ];

  return (
    <PigGroupMovementsContainer
      className={cn('group-feed-container')}
      getPigGroupMovements={(reqParams) => getPigGroupMigrations(groupId, reqParams)}
      currentUser={currentUser}
      pigGroup={pigGroup}
    >
      {({ resources }, migrationPanelClassName) => (
        <Fragment>
          {pigGroup.status === 'closed' && (
            <GroupFeedPanel
              headlineTitle={<FormattedMessage id="general.groupFeed.groupClosed" />}
              className={migrationPanelClassName}
            >
              <UserPanel
                className="no-margin no-border"
                user={pigGroup.closed_by || { full_name: currentUser.current_company.name }}
                timestamp={pigGroup.closed_on}
                label={<FormattedMessage id="component.groupFeed.closedGroup" values={{ name: pigGroup.name }} />}
              />
            </GroupFeedPanel>
          )}

          {showCloseGroupSection && (
            <GroupFeedPanel
              headlineTitle={<FormattedMessage id="general.groupFeed.groupClosed" />}
              type="close"
              className={migrationPanelClassName}
            >
              <div className={cn('panel-text')}>
                <FormattedMessage id="component.groupFeed.closeGroupQuestion" />
                <Button primary onClick={handleCloseGroup} className="wider">
                  <FormattedMessage id="general.button.closeGroup" />
                </Button>
              </div>
            </GroupFeedPanel>
          )}

          {pigGroup.marketing_reported_at && (
            <GroupMarketingPanel
              user={pigGroup.marketing_reporter}
              timestamp={pigGroup.marketing_reported_at}
              dropdownOptions={marketingDropdownOptions}
              className={migrationPanelClassName}
            />
          )}

          {resources.map((migration) => (
            <PigGroupMigrationPanel
              key={migration.id}
              className={migrationPanelClassName}
              currentUser={currentUser}
              migration={migration}
              pigGroupId={pigGroup.id}
            />
          ))}
        </Fragment>
      )}
    </PigGroupMovementsContainer>
  );
};

GroupFeed.propTypes = {
  params: T.object.isRequired,
  pigGroup: T.object,
  closePigGroup: T.func.isRequired,
  resetPigGroupMarketing: T.func.isRequired,
  currentUser: T.object.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.pigGroups.groupShow.data,
    currentUser: state.auth.user,
  }), {
    closePigGroup,
    resetPigGroupMarketing,
  },
)(GroupFeed);
