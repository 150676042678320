// utils
import isNumber from 'lodash.isnumber';
import { getDeathsCount } from 'utils';

/**
 * extends correction with derived/calculated values
 */
export function extendCorrection({ correction }) {
  const corr = { ...correction };

  // calculate death_sum if any death type present in correction
  if (isNumber(corr.acute_death_count) || isNumber(corr.chronic_death_count) || isNumber(corr.euthanasia_death_count)) {
    corr.deaths_sum = getDeathsCount(corr);
  }

  // Water Med Injectable fields
  if (corr.dc_treatments) {
    corr.dc_treatments = corr.dc_treatments
      .filter(({ _destroy }) => !_destroy);
  }

  // calculate symptoms count (reject deleted values)
  if (corr.dc_symptoms) {
    corr.symptom_count = corr.dc_symptoms.filter(({ _destroy }) => !_destroy).length;
  }

  // calculate pig migrations: pigs_added, pigs_removed
  if (corr.pig_migrations) {
    corr.pigs_added = 0;
    corr.pigs_corrected = 0;
    corr.pigs_out = 0;

    corr.pig_migrations.forEach(({ amount, _destroy, migration_type }) => {
      if (_destroy) return;

      // for 'Shipment', 'Transfer', 'Sale', 'Corrections' fields
      if (migration_type) {
        // 'placement' type is the backend equivalent to 'shipment', dc.pigs_added field
        if (migration_type === 'placement') {
          corr.pigs_added = (corr.pigs_added || 0) + Number(Math.abs(amount));
        }
        if (['sale', 'transfer'].includes(migration_type)) {
          corr.pigs_out = (corr.pigs_out || 0) + Number(Math.abs(amount));
        }
        if (['count_add', 'count_remove'].includes(migration_type)) {
          corr.pigs_corrected = (corr.pigs_corrected || 0) + Number(amount);
        }
      }
    });
  }

  return corr;
}

/**
 * calculate back url on out from edit mode
 */
export function editModeBackUrl(pigGroup, user) {
  const isAdmin = user?.roles_map?.admin;
  const editModeType = pigGroup?.edit_mode_type;

  switch (editModeType) {
    case 'inventory':
      return isAdmin ? '/admin/inventory' : '/inventory';
    case 'reconcile':
      return pigGroup.farm_id ? `/daily-checkup/farms/${pigGroup.farm_id}` : '/daily-checkup';
    case 'pig_migration':
      return `/groups/${pigGroup.id}/groupfeed`;
    case 'edit':
      return `/barnsheets/groups/${pigGroup.id}`;
    default:
      return false;
  }
}

/**
 * calculate dc variety by correction
 */
export function dailyCheckupVariety(checkup) {
  if (checkup.variety === 'empty_farm') {
    const pigs = checkup?.correction?.pigs || 0;

    if (pigs !== 0) {
      return 'default';
    }
  }

  return checkup.variety;
}
