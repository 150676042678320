import React from 'react';
import T from 'prop-types';
// components
import Collapse from 'components/Collapse/Collapse';
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
// styles
import styles from './CollapseWithdrawalWarning.module.scss';

const cn = classnames.bind(styles);

const CollapseWithDrawalWarning = ({ data, className, title }) => (
  <Collapse
    className={cn('withdrawal-warning', 'no-padding', { [className]: !!className })}
    titleClassName={cn('collapse-title')}
    title={title}
  >
    <div>
      <div className={cn('options-header')}>
        <FormattedMessage id="general.product" />
        <FormattedMessage id="general.withdrawalDate" />
      </div>
      {data.map((medication, index) => (
        <div key={index} className={cn('medication-line')}>
          <div>
            <span>
              {medication.name} • {medication.duration_value} {medication.duration_period}
            </span>
          </div>
          <div>
            <span>{moment(medication.withdrawn_on).format('MMM DD, YYYY')}</span>
          </div>
        </div>
      ))}
    </div>
  </Collapse>
);

CollapseWithDrawalWarning.propTypes = {
  className: T.string,
  title: T.oneOfType([T.string, T.object]).isRequired,
  data: T.array,
};

export default CollapseWithDrawalWarning;
