import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// utils
import moment from 'moment';

export const getFormattedSalesResults = (commitment) => {
  if (commitment.sales_results) {
    const { loading, sales_results } = commitment;
    return sales_results.map((salesResult) => ({
      ...salesResult,
      source_commitment: loading.commitments.find(
        ({ id }) => +id === salesResult.source_loading_commitment_id
      ),
      destination_commitment: loading.commitments.find(
        ({ id }) => +id === salesResult.destination_loading_commitment_id
      ),
    }));
  }
  return [];
};

export const getSalesResultInfo = (salesResult) => [
  {
    label: <FormattedMessage id="general.dateDelivered" />,
    value: salesResult.delivered_at ? moment(salesResult.delivered_at).format('MM/DD/YYYY') : 'N/A',
    targetValue: salesResult.destination_commitment.arrive_at ?
      moment(salesResult.destination_commitment.arrive_at).format('MM/DD/YYYY') :
      'N/A',
  },
  {
    label: <FormattedMessage id="general.deliveryTime" />,
    value: salesResult.delivered_at ? moment(salesResult.delivered_at).format('hh:mm A') : 'N/A',
    targetValue: salesResult.destination_commitment.arrive_at ?
      moment(salesResult.destination_commitment.arrive_at).format('hh:mm A') :
      'N/A',
  },
  {
    label: <FormattedMessage id="general.headDelivered" />,
    value: salesResult.head_count || 'N/A',
    targetValue: salesResult.destination_commitment.head_count || 'N/A',
  },
  {
    label: <FormattedMessage id="general.prelimAvgWeight" />,
    value: <FormattedWeight value={salesResult.avg_pig_weight} />,
    targetValue: 'N/A',
  },
];
