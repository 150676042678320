import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import ConditionCheckBox from './ConditionCheckBox';
import Input from 'components/Input';
// styles
import styles from '../ConditionSelector.module.scss';

const InputConditionCheckBox = ({
  conditionValue,
  conditionInputValue,
  inputPlaceholder,
  inputStep,
  inputLabel,
  onConditionInputValueChange,
  onConditionValueChange,
  option,
  maxInputValue,
  minInputValue,
}) => {
  const isOptionChecked = option.value === conditionValue;
  const isText = maxInputValue === undefined && minInputValue === undefined;
  return (
    <Fragment>
      <ConditionCheckBox
        option={option}
        conditionValue={conditionValue}
        onConditionValueChange={onConditionValueChange}
      />
      {isOptionChecked && (
        <div className={styles['input-row']}>
          <Input
            autoFocus
            className="mb-5"
            value={conditionInputValue}
            onChange={onConditionInputValueChange}
            placeholder={inputPlaceholder}
            type={isText ? 'text' : 'number'}
            min={minInputValue}
            max={maxInputValue}
            step={inputStep}
          />
          {inputLabel && <div className={styles['input-row-label']}>{inputLabel}</div>}
        </div>
      )}
    </Fragment>
  );
};

InputConditionCheckBox.propTypes = {
  conditionValue: T.string,
  conditionInputValue: T.string,
  option: T.object.isRequired,
  inputPlaceholder: T.string.isRequired,
  onConditionValueChange: T.func.isRequired,
  onConditionInputValueChange: T.func.isRequired,
  inputLabel: T.oneOfType([T.string, T.node]),
  inputStep: T.number,
  minInputValue: T.number,
  maxInputValue: T.number,
};

export default InputConditionCheckBox;
