import { useMemo } from 'react';
import { extendParamsOnChange } from 'components/DataTable/DataTableController/DataTableController';

export default function useDataTableController(fetchData, params) {
  return useMemo(() => ({
    onSearchChange: (search) => fetchData(extendParamsOnChange('search', params, search)),
    onSortChange: (sort) => fetchData(extendParamsOnChange('sort', params, sort)),
    onPageChange: (page) => fetchData(extendParamsOnChange('page', params, page)),
    onPerPageChange: (perPage) => fetchData(extendParamsOnChange('perPage', params, perPage)),
    onStatusChange: (status) => fetchData(extendParamsOnChange('status', params, status)),
  }), [fetchData, params]);
}
