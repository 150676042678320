import { useState, useEffect } from 'react';
// api
import { getPigGroup } from 'endpoints/pigGroups';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

export default function usePigGroup(pigGroupId) {
  const [data, setData] = useState({ isLoading: false, pigGroup: null });

  useEffect(() => {
    if (pigGroupId) {
      setData((prevState) => ({ ...prevState, isLoading: true }));
      getPigGroup(pigGroupId)
        .then((pigGroup) => {
          setData({ pigGroup, isLoading: false });
        })
        .catch((errors) => {
          setData((prevState) => ({ ...prevState, isLoading: false }));
          toastResponseErrors(errors);
          throw errors;
        });
    }
  }, [pigGroupId, setData]);

  return data;
}
