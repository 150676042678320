import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { openPortal } from 'reducers/portal';
// components
import { FormattedMessage } from 'react-intl';
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import DayPickerRangeController from 'react-dates/lib/components/DayPickerRangeController';
import MonthlyDatePickerController from 'components/DatePicker/MonthlyDatePicker/MonthlyDatePickerController';
import WeeklyDatePickerMobile from 'components/DatePicker/WeeklyDatePicker/WeeklyDatePickerMobile';
import SingleDatePickerMobile from 'components/DatePicker/SingleDatePicker/SingleDatePickerMobile';
import MonthlyDatePickerMobile from 'components/DatePicker/MonthlyDatePicker/MonthlyDatePickerMobile';
// utils
import cn from 'classnames';
import moment from 'moment';
import withOutsideClick from 'react-onclickoutside';
// styles
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.scss';

const reportPeriodLabels = { weekly: 'week', daily: 'day', monthly: 'month' };

const periodDates = (start, end) => ({
  weekly: `${moment(start).format('MM/DD/YYYY')} - ${moment(end).format('MM/DD/YYYY')}`,
  daily: moment(start).format('MM/DD/YYYY'),
  monthly: moment(start).format('MMMM YYYY'),
});

class DatePicker extends Component {

  constructor() {
    super();
    this.state = {
      focusedInput: 'startDate',
    };
  }

  onClick = () => {
    const { startDate, endDate, openPortal, selectionType } = this.props;
    const { isMobile } = this.context;
    if (isMobile && selectionType === 'weekly') {
      return openPortal(
        <WeeklyDatePickerMobile
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={this.onWeekChange}
          isOutsideRange={this.isOutsideRange}
          startDateOffset={(day) => day.startOf('week')}
          endDateOffset={(day) => day.endOf('week')}
        />
      );
    }
    if (isMobile && selectionType === 'daily') {
      return openPortal(
        <SingleDatePickerMobile
          initialDate={startDate}
          onChange={this.onDayChange}
          isOutsideRange={this.isOutsideRange}
        />
      );
    }
    if (isMobile && selectionType === 'monthly') {
      return openPortal(
        <MonthlyDatePickerMobile
          initialDate={startDate}
          onChange={this.onMonthChange}
        />
      );
    }
    if (!this.state.opened) {
      this.setState({ opened: true });
    }
    return null;
  };

  onDayChange = (day) => {
    this.props.onDatesChange({ startDate: day, endDate: day });
    this.setState({ opened: false });
  };

  onWeekChange = (dates) => {
    this.props.onDatesChange(dates);
    this.setState({ opened: false });
  };

  onMonthChange = ({ date }) => {
    this.props.onDatesChange({
      startDate: moment(date).startOf('month'),
      endDate: moment(date).endOf('month'),
    });
    this.setState({ opened: false });
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput: !focusedInput ? 'startDate' : focusedInput });
  };

  handleClickOutside = () => {
    this.setState({ opened: false });
  };

  isOutsideRange = (day) => (day.isAfter(moment(), 'day'));

  getPeriodLabel = () => {
    const { startDate, endDate, selectionType } = this.props;
    if (!startDate.isValid()) {
      return <FormattedMessage id="general.selectPeriod" />;
    }
    return periodDates(startDate, endDate)[selectionType];
  };

  render() {
    const { className, startDate, endDate, selectionType } = this.props;
    const { focusedInput, opened } = this.state;

    return (
      <div
        onClick={this.onClick}
        className={cn('DatePickerWrapper', { [className]: !!className, opened })}
      >
        <div className="report-period-box">
          <FormattedMessage id={`container.autoReport.${reportPeriodLabels[selectionType]}DatePicker.title`}>
            {(title) => <span className="report-period-label">{title}</span>}
          </FormattedMessage>
          <span className="report-period-value">
            {this.getPeriodLabel()}
          </span>
        </div>
        <div className="calendar-icon-wrapper">
          <i className="fa fa-calendar" />
        </div>
        {opened && selectionType === 'daily' && (
          <DayPickerSingleDateController
            onDateChange={this.onDayChange}
            onFocusChange={this.onFocusChange}
            date={startDate}
            hideKeyboardShortcutsPanel
            isOutsideRange={this.isOutsideRange}
          />
        )}
        {opened && selectionType === 'weekly' && (
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={this.onWeekChange}
            onFocusChange={this.onFocusChange}
            focusedInput={focusedInput}
            numberOfMonths={2}
            hideKeyboardShortcutsPanel
            isOutsideRange={this.isOutsideRange}
            startDateOffset={(day) => day.startOf('week')}
            endDateOffset={(day) => day.endOf('week')}
          />
        )}
        {opened && selectionType === 'monthly' && (
          <MonthlyDatePickerController
            date={startDate}
            onChange={this.onMonthChange}
          />
        )}
      </div>
    );
  }
}

DatePicker.defaultProps = {
  className: '',
  startDate: moment(),
};

DatePicker.contextTypes = {
  isMobile: T.bool,
};

DatePicker.propTypes = {
  className: T.string,
  selectionType: T.string,
  startDate: T.object,
  endDate: T.object,
  onDatesChange: T.func.isRequired,
  openPortal: T.func.isRequired,
};

export default compose(
  connect(null, { openPortal }),
  withOutsideClick,
)(DatePicker);
