import { fetchFromAPI } from 'utils/api';

export function getAnalyticsDeaths(params) {
  return fetchFromAPI('/admin/analytics/deaths', { params });
}

export function getAnalyticsFarmCompliance(params) {
  return fetchFromAPI('/admin/analytics/farm_compliance', { params });
}

export function getAnalyticsDailyMortality(params) {
  return fetchFromAPI('/admin/analytics/daily_mortality', { params });
}

export function getAnalyticsSymptoms(params) {
  return fetchFromAPI('/admin/analytics/symptoms', { params });
}
