import React from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import { formatBackEndWeightToSystem } from 'utils/weightHelper';

const FormattedWeight = ({
  className,
  hasOnlyValue = false,
  hasOnlyUnit = false,
  value,
  afterValue,
  measurementSystem,
  unitFontSize,
}) => {
  const messageKey = `general.weight.${measurementSystem === 'metric' ? 'kg' : 'lbs'}`;

  if (hasOnlyUnit) {
    return (
      <FormattedMessage id={messageKey}>
        {(text) => <span style={{ fontSize: unitFontSize }}>{text}</span>}
      </FormattedMessage>
    );
  }

  return (
    <span className={cn({ [className]: !!className })}>
      {formatBackEndWeightToSystem(value, measurementSystem, <FormattedMessage id="general.notAvail" />)}
      {afterValue}
      {!hasOnlyValue && !!value && (
        <FormattedMessage id={messageKey}>
          {(text) => <span className="ml-5" style={{ fontSize: unitFontSize }}>{text}</span>}
        </FormattedMessage>
      )}
    </span>
  );
};

FormattedWeight.propTypes = {
  value: T.oneOfType([T.string, T.number]),
  afterValue: T.oneOfType([T.string, T.number]),
  hasOnlyValue: T.bool,
  hasOnlyUnit: T.bool,
  className: T.string,
  measurementSystem: T.string,
  unitFontSize: T.string,
};

export default connect(
  (state) => ({
    measurementSystem: state.auth.user.current_company.measurement_system,
  })
)(FormattedWeight);
