import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { updateCompanyProfile } from 'reducers/profiles';
import { setCurrentCompany } from 'reducers/auth';
// components
import { FormattedMessage } from 'react-intl';
import CompanyEditForm from './components/EditCompanyForm';
import Panel from 'components/Panel';
import { connect } from 'react-redux';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import handleFormErrors from 'utils/handleFormErrors';
import isEmpty from 'lodash.isempty';
import { showToastrMessage } from 'utils';

class MyCompanyEdit extends Component {

  getInitialValues = () => {
    const { company } = this.props;
    if (isEmpty(company)) return null;
    return { ...company };
  };

  updateCurrentCompany = (data) => {
    const { updateCompanyProfile, setCurrentCompany, company } = this.props;
    return updateCompanyProfile(`/admin/companies/${company.id}`, {
      method: 'put', body: JSON.stringify({ resource: data }),
    })
      .then(({ value }) => {
        showToastrMessage('component.toastr.company.updated');
        if (company.name !== value.resource.name) setCurrentCompany({ name: value.resource.name });
      })
      .catch((response) => {
        handleFormErrors(response);
        toastResponseErrors(response);
      });
  };

  render() {
    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.companyInformation" />} />
        <Panel.Body noPadding>
          <div className="row">
            <section className="small-12 column">
              <CompanyEditForm onSubmit={this.updateCurrentCompany} initialValues={this.getInitialValues()} />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

MyCompanyEdit.propTypes = {
  updateCompanyProfile: T.func,
  setCurrentCompany: T.func,
  company: T.object,
};

export default connect((state) => ({
  company: state.profiles.company.data,
}), { updateCompanyProfile, setCurrentCompany })(MyCompanyEdit);
