import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import { getSaleInfo, getArrivalInfo, getTransferInfo } from '../utils';
// styles
import styles from '../CommitmentProfile.module.scss';

const cn = classnames.bind(styles);

const infoRenderers = {
  sale: getSaleInfo,
  arrival: getArrivalInfo,
  transfer: getTransferInfo,
};

const GeneralInfoSection = ({ commitment, loadType }) => (
  <div className={cn('info-block')}>
    {infoRenderers[loadType](commitment).map(({ value, label }, idx) => (
      <div key={idx} className={cn('info-row')}>
        <div className={cn('label')}>{label}</div>
        <div className={cn('value')}>{value}</div>
      </div>
    ))}
  </div>
);

GeneralInfoSection.propTypes = {
  commitment: T.object.isRequired,
  loadType: T.string.isRequired,
};

export default GeneralInfoSection;
