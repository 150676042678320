import React from 'react';
import map from 'lodash.map';
import compact from 'lodash.compact';
import startCase from 'lodash.startcase';
import { toastr } from 'react-redux-toastr';

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const keyToString = (key) => {
  return capitalize(key.replace('_', ' '));
};

const stringify = (error) => {
  if (typeof error === 'string') return error;
  if (Array.isArray(error)) return compact(error.map(stringify)).join(', ');
  if (typeof error === 'object') {
    return compact(map(error, (value, key) => `${keyToString(key)} ${stringify(value)}`)).join(', ');
  }
  return null;
};

export const toastResponseErrors = (response, messages = {}) => { // eslint-disable-line consistent-return
  const { errors, error, exception, isAbortError, isTypeError } = response;

  if (isAbortError || isTypeError) return undefined;

  if (!error && !errors && !exception) {
    return console.warn(response); // eslint-disable-line
  }

  if (typeof exception === 'string') {
    return toastr.error(error, exception, { icon: <i className="fa fa-ban" /> });
  }

  if (typeof error === 'string') {
    return toastr.error('', error, { icon: <i className="fa fa-ban" /> });
  }

  if (typeof errors === 'string') {
    return toastr.error('', errors, { icon: <i className="fa fa-ban" /> });
  }

  if (Array.isArray(errors)) {
    return toastr.error('', errors.join(', '), { icon: <i className="fa fa-ban" /> });
  }

  if (typeof errors === 'object') {
    Object.keys(errors).forEach((key) => {
      const error = errors[key]; // eslint-disable-line no-shadow
      if (typeof error === 'object' && !Array.isArray(error)) {
        // handle nested errors (when response.errors[key] is object)
        Object.keys(error).forEach((key2) => {
          const error2 = error[key2];
          const errorMessage = `${capitalize(key)} ${key2}: ${Array.isArray(error2) ? error2.join(', ') : error2}`;
          toastr.error('', messages[key] || errorMessage, { icon: <i className="fa fa-ban" /> });
        });
      } else {
        const serializedKey = key === 'base' ? '' : startCase(key) + ': ';
        const errorMessage = serializedKey + (Array.isArray(error) ? compact(error.map(stringify)).join(', ') : error);
        toastr.error('', messages[key] || errorMessage, { icon: <i className="fa fa-ban" /> });
      }
    });
  }
};
