import React from 'react';
import T from 'prop-types';
// utils
import { getAssetIcon } from 'utils/tenantAssetsHelper';
import classnames from 'classnames/bind';
// styles
import styles from './TenantAssetIcon.module.scss';

const cn = classnames.bind(styles);

const TenantAssetIcon = ({ contentType, isBigger, hasImageThumb, imageUrl }) => {
  if ((contentType === 'CompanyImage') && hasImageThumb) {
    return <img className={cn('img-thumb')} src={imageUrl} alt="" loading="lazy" />;
  }
  return (
    <i
      className={cn(getAssetIcon(contentType), 'tenant-asset-icon', {
        isBigger,
        'pdf-icon': contentType === 'CompanyPdf',
        'doc-icon': contentType === 'CompanyDoc',
        'img-icon': contentType === 'CompanyImage',
        'link-icon': contentType === 'CompanyLink',
        'video-icon': contentType === 'CompanyVideo',
      })}
    />
  );
};

TenantAssetIcon.propTypes = {
  contentType: T.string.isRequired,
  isBigger: T.bool,
  hasImageThumb: T.bool,
  imageUrl: T.string,
};

export default TenantAssetIcon;
