import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { Link } from 'react-router';
import TenantAvatar from 'components/TenantAvatar/TenantAvatar';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// api
import { updatePackingPlant } from 'endpoints/admin/packingPlants';
// utils
import cn from 'classnames';
import moment from 'moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage, formatLocation } from 'utils';

class PackingPlants extends  Component {

  updatePlant = (plantId, resource) => {
    const { setDataItem } = this.props;
    const { params: { id: companyId } } = this.props;
    updatePackingPlant(plantId, companyId, resource)
      .then((plant) => {
        setDataItem(plant);
        showToastrMessage(`component.toastr.packingPlant.${plant.active ? 'enabled' : 'disabled'}`);
      })
      .catch(toastResponseErrors);
  };

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  renderNameColumn = ({ name }) => (
    <CustomColumn flexRow>
      <TenantAvatar className={cn('mr-10', 'ml-0')} name={name} />
      <span className="semibold">{name}</span>
    </CustomColumn>
  );

  renderAddedInfoColumn = ({ created_at }) => (
    <CustomColumn label={<FormattedMessage id="general.users" />}>
      {created_at ? moment(created_at).fromNow() : 'N/A'}
    </CustomColumn>
  );

  renderLocationColumn = ({ city, state }) => (
    <CustomColumn label={<FormattedMessage id="general.location" />}>
      {formatLocation(city, state)}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id, active }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.edit" />}
        buttonType="small light-gray"
        url={`/admin/packing-companies/${this.props.params.id}/packing-plants/${id}`}
        dropDownData={[
          {
            label: <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}PackingPlant`} />,
            onClick: () => this.updatePlant(id, { active: !active }),
          },
        ]}
        customClass="show-for-large"
      />
      <Link
        to={`/admin/packing-companies/${this.props.params.id}/packing-plants/${id}`}
        className="button light hide-for-large"
      >
        <FormattedMessage id="general.button.edit" />
      </Link>
      <Button lightGray onClick={() => this.updatePlant(id, { active: !active })} className="hide-for-large">
        <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}PackingPlant`} />,
      </Button>
    </div>
  );

  renderExpandable = ({ id, active }) => (
    <div>
      <Link to={`/admin/packing-companies/${this.props.params.id}/packing-plants/${id}`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.button.edit" />
      </Link>
      {!active && (
        <Button small light onClick={() => this.updatePlant(id, { active: true })}>
          <i className="fa fa-check-circle mr-5" />
          <FormattedMessage id="general.button.enablePackingPlant" />
        </Button>
      )}
      {active && (
        <Button small light onClick={() => this.updatePlant(id, { active: false })}>
          <i className="fa fa-times-circle mr-5" />
          <FormattedMessage id="general.button.disablePackingPlant" />
        </Button>
      )}
    </div>
  );

  render() {
    const { resources, isLoading, meta: { stats, total }, reqParams: { page, per_page, status, search, sort },
      onPageChange, onPerPageChange, onStatusChange, onSortChange, params: { id }, onSearchChange } = this.props;
    const { isTablet } = this.context;

    const tableFilters = [
      { label: <FormattedMessage id="general.allPlants" />, value: '' },
      { label: <FormattedMessage id="general.status.active" />, value: 'active' },
      { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
    ];

    const isEmptyTable = !resources.length && !status && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '2 1 130px',
        renderer: this.renderNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="general.location" />, flex: '1 1 100px', renderer: this.renderLocationColumn },
      { label: <FormattedMessage id="general.added" />, flex: '1 1 100px', renderer: this.renderAddedInfoColumn },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 100px',
        renderer: this.renderStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 135px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.plants" />}>
          {!isEmptyTable &&
          <SearchBox initialValue={search} onChange={onSearchChange} />
          }
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={status}
              onFilterChange={onStatusChange}
              stats={stats}
              className="ph-10"
            >
              <Link to={`/admin/packing-companies/${id}/packing-plants/create`} className="button small primary wider">
                <FormattedMessage id="general.button.createPackingPlant" />
              </Link>
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="packingPlants"
          />
          <NothingBox
            itemsName="packingPlants"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={status}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noPlants" />
            <Link to={`/admin/packing-companies/${id}/packing-plants/create`} className="button primary mt-10">
              <FormattedMessage id="general.button.createPackingPlant" />
            </Link>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

PackingPlants.propTypes = {
  setDataItem: T.func.isRequired,
  resources: T.array.isRequired,
  onStatusChange: T.func.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  params: T.object.isRequired,
};

PackingPlants.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect((state) => ({
    resources: state.dataTable.packingPlants.resources,
    reqParams: state.dataTable.packingPlants.params,
    meta: state.dataTable.packingPlants.meta,
    isLoading: state.dataTable.packingPlants.isLoading,
  }), (dispatch, { params: { id } }) => ({
    fetchData: (query) => dispatch(fetchData(`/admin/packing_companies/${id}/packing_plants`, 'packingPlants', query)),
    setDataItem: (data) => dispatch(setDataItem(data, 'packingPlants')),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(PackingPlants);
