import React from 'react';
import T from 'prop-types';
// components
import UserAvatar from 'components/UserAvatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import VerticalAligner from 'components/VerticalAligner';
// styles
import './UserProfileHeader.scss';

const UserMiniProfileHeader = ({ user }) => (
  <VerticalAligner className="UserMiniProfileHeader">
    <UserAvatar user={user} size="35" />
    <div className="user-name lighter inline-block">{user.full_name}</div>
    {user.status &&
      <StatusBadge status={user.status} small />}
    <div className="vertical-divider" />
  </VerticalAligner>
);

UserMiniProfileHeader.propTypes = {
  user: T.object,
};

export default UserMiniProfileHeader;
