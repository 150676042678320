import React from 'react';
import T from 'prop-types';
// components
import GrowthRate from 'components/GrowthRate';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './StatIndicatorBlock.module.scss';
// constants
const cn = classnames.bind(styles);

const StatIndicatorBlock = ({ value, label, className, growthRate }) => (
  <div className={cn('indicator-block', className)}>
    <div className={cn('values')}>
      <div className={cn('value')}>{value}</div>
      <GrowthRate value={growthRate} small />
    </div>
    <div className={cn('label')}>{label}</div>
  </div>
);

StatIndicatorBlock.propTypes = {
  value: T.oneOfType([T.string, T.number]),
  growthRate: T.number,
  label: T.oneOfType([T.string, T.node]),
  className: T.string,
};

export default StatIndicatorBlock;
