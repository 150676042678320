import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal, TYPE_PANEL, TYPE_MODAL } from 'reducers/modals';
// utils
import isFunction from 'lodash.isfunction';
import { ESC_KEY } from 'utils/constants';
// other
import cn from 'classnames';
import './ModalsContainer.scss';

function keyCode(e) {
  return (e.which) ? e.which : e.keyCode;
}

class ModalsContainer extends Component {

  componentDidMount() {
    if (window) {
      window.addEventListener('keyup', this.handleOnKeyUp);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('keyup', this.handleOnKeyUp);
    }
  }

  closeModal = () => {
    const { closeModal, isOpen, options: { closeable = true, onClose } } = this.props;
    if (isOpen && closeable) {
      closeModal();

      if (isFunction(onClose)) {
        onClose();
      }
    }
  };

  handleOnKeyUp = (e) => {
    if (keyCode(e) === ESC_KEY) this.closeModal();
  };

  onBackdropClick = (e) => {
    e.stopPropagation();
    this.closeModal();
  };

  render() {
    const { isOpen, type, component, options: { position, backdropStyles } } = this.props;
    return (
      <div className={cn('ModalsContainer', { 'isOpen': isOpen })}>
        <div className="ModalsContainer__backdrop" style={backdropStyles} onClick={this.onBackdropClick} />
        <div className={cn('modal-wrapper-panel', { 'is-open': isOpen })}>
          {type === TYPE_PANEL ? component : null}
        </div>
        <div className={cn('modal-wrapper', { 'animated fadeInDown': isOpen, [`position-${position}`]: position })}>
          {type === TYPE_MODAL ? component : null}
        </div>
      </div>
    );
  }
}

ModalsContainer.propTypes = {
  isOpen: T.bool,
  component: T.object,
  options: T.object,
  type: T.string,
  closeModal: T.func.isRequired,
};

export default connect(
  (state) => ({
    isOpen: state.modals.isOpen,
    type: state.modals.type,
    component: state.modals.component,
    options: state.modals.options,
  }),
  { closeModal }
)(ModalsContainer);
