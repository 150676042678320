import { createAction, handleActions } from 'redux-actions';
import { getOpenedFarmGroupsOffline, updateGroupAndSaveRequest } from 'utils/offlineHelper';
import {
  getCheckupFarms,
  getCheckupFarmsOffline,
  setFarmCheckupsAllGood,
  setFarmCheckupsAllGoodOffline,
} from 'endpoints/checkup/farms';

// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  page: 1,
  per_page: 25,
};

const initialState = {
  resources: [],
  isLoading: false,
  params: {
    ...defaultParams,
    search: '',
  },
};

const FETCH_FARMS = 'checkupFarms/FETCH_FARMS';
const [FETCH_FARMS_PENDING, FETCH_FARMS_FULFILLED, FETCH_FARMS_REJECTED] = [
  `${FETCH_FARMS}_PENDING`,
  `${FETCH_FARMS}_FULFILLED`,
  `${FETCH_FARMS}_REJECTED`,
];

const SET_FARM_ALL_GOOD = 'dailyCheckups/SET_FARM_ALL_GOOD';
const [SET_FARM_ALL_GOOD_PENDING, SET_FARM_ALL_GOOD_FULFILLED, SET_FARM_ALL_GOOD_REJECTED] = [
  `${SET_FARM_ALL_GOOD}_PENDING`,
  `${SET_FARM_ALL_GOOD}_FULFILLED`,
  `${SET_FARM_ALL_GOOD}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFarms = (params = defaultParams) => (dispatch, getState) => {
  dispatch(createAction(FETCH_FARMS_PENDING)());
  const { isOnline } = getState().network;
  return (isOnline ? getCheckupFarms : getCheckupFarmsOffline)(params)
    .then((response) => {
      dispatch(createAction(FETCH_FARMS_FULFILLED)({ ...response, params }));
      return response;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_FARMS_REJECTED)(response));
      throw response;
    });
};

export const setFarmAllGood = (id) => (dispatch, getState) => {
  dispatch(createAction(SET_FARM_ALL_GOOD_PENDING));
  const { isOnline } = getState().network;
  return (isOnline ? setFarmCheckupsAllGood : setFarmCheckupsAllGoodOffline)(parseInt(id, 10))
    .then((response) => {
      const farm = response.resource;

      getOpenedFarmGroupsOffline(farm.id)
        .then((groups) => {
          groups.forEach((group) => {
            updateGroupAndSaveRequest(group, farm, isOnline);
          });
        });

      dispatch(createAction(SET_FARM_ALL_GOOD_FULFILLED)(response));
      return response;
    })
    .catch((err) => {
      dispatch(createAction(SET_FARM_ALL_GOOD_REJECTED)(err));
      throw err;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_FARMS
  [FETCH_FARMS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_FARMS_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [FETCH_FARMS_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  // SET_FARM_ALL_GOOD
  [SET_FARM_ALL_GOOD_FULFILLED]: (state, action) => {
    const { resource } = action.payload;
    const farm = state.resources.find(({ id }) => id === resource.id);
    const farmIndex = state.resources.indexOf(farm);
    const resources = farmIndex === -1
      ? state.resources
      : [
        ...state.resources.slice(0, farmIndex),
        { ...farm, ...resource },
        ...state.resources.slice(farmIndex + 1),
      ];
    return {
      ...state,
      resources,
    };
  },

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
