import React from 'react';
import T from 'prop-types';
import { compose, withHandlers } from 'recompose';
// component
import UserAvatar from 'components/UserAvatar';
// utils
import cn from 'classnames';
// assets
import './UserMarker.scss';

const MARKER_SIZE = 40;

const markerPlacementStyle = {
  position: 'absolute',
  width: MARKER_SIZE,
  height: MARKER_SIZE,
  left: -MARKER_SIZE / 2,
  top: -MARKER_SIZE / 2,
};

const UserMarker = ({ user, children, onClick, active, shiftCoef, shift }) => {
  return (
    <div
      className={cn('UserMarker', { active })}
      style={{ ...markerPlacementStyle, left: markerPlacementStyle.left + shift * shiftCoef }}
      onClick={onClick}
    >
      <UserAvatar user={user} size="36" />
      {children}
    </div>
  );
};

UserMarker.propTypes = {
  user: T.object.isRequired,
  shiftCoef: T.number,
  shift: T.number,
  onClick: T.func,
  children: T.node,
  active: T.bool,
};

UserMarker.defaultProps = {
  shiftCoef: 1,
  shift: 25,
};

const enhance = compose(
  withHandlers({
    onClick: ({ user, onClick, farm = {} }) => () => {
      return onClick ? onClick({ id: user.id, type: 'user', parentId: farm.id }) : null;
    }
  })
);

export default enhance(UserMarker);
