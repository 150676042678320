import React from 'react';
import T from 'prop-types';
// components
import Panel from 'components/Panel';
import { FormattedMessage } from 'react-intl';
// utils
import get from 'lodash.get';
import { formatLocation } from 'utils';
import cn from 'classnames';
// redux
import { connect } from 'react-redux';
// styles
import styles from './CommitmentHealthInfo.module.scss';

const getDiagnoseResult = (active) => (
  <div className={cn(styles.value, { [styles.active]: active })}>
    <FormattedMessage id={`general.${active ? 'positive' : 'negative'}`} />
  </div>
);

const CommitmentHealthInfo = ({ healthInfo, router: { push } }) => {
  if (!healthInfo.length) {
    push('/my-schedule');
    return null;
  }
  return (
    <Panel>
      <Panel.Heading title="Health" />
      <Panel.Body noPadding>
        <div className="ph-30 pb-20">
          {healthInfo.map((healthItem, idx) => (
            <div key={idx} className={cn({ 'pt-25': idx === 0 })}>
              <div className={styles['source-badge']}>
                <div>
                  <div className="semibold">{get(healthItem, 'source.name', 'N/A')}</div>
                  <div className="fs-13">
                    {formatLocation(get(healthItem, 'source.city'), get(healthItem, 'source.state'))}
                  </div>
                </div>
              </div>
              {/*  HERE WILL BE CAREGIVER */}
              <div className={cn(styles.divider, 'mt-20 mb-20')} />
              <div className={styles['diagnoses-block']}>
                {healthItem.diagnoses.map(({ name, active, comment }, idx) => (
                  <div className={cn(styles.wrapper, 'pb-10 pt-10')} key={idx}>
                    <div className={styles['diagnose-row']}>
                      <div className={styles.label}>{name}</div>
                      {getDiagnoseResult(active)}
                    </div>
                    <div>{comment}</div>
                  </div>
                ))}
              </div>
              <div className={cn(styles.divider, 'mt-20 mb-20')} />
            </div>
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

CommitmentHealthInfo.propTypes = {
  healthInfo: T.array.isRequired,
  router: T.object.isRequired,
};

export default connect((state) => ({
  healthInfo: state.commitment.healthInfo
}))(CommitmentHealthInfo);
