import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchShareholderGroup } from 'reducers/admin/shareholderGroups/groupShow';
// components
import { FormattedMessage } from 'react-intl';
import Subnavigation from 'components/Subnavigation';
import ProgressChecklist from 'components/ProgressChecklist/ProgressChecklist';
import GroupProfileHeader from 'components/ProfileHeader/ShareholderGroupProfileHeader/ShareholderGroupProfileHeader';
import GroupMiniProfileHeader from 'components/ProfileHeader/ShareholderGroupProfileHeader/GroupMiniProfileHeader';
import ProgressBox from 'components/Admin/ProgressBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isEmpty from 'lodash.isempty';
import classnames from 'classnames/bind';
// styles
import styles from './ShareholderGroupProfile.module.scss';

const cn = classnames.bind(styles);

class ShareholderGroupProfile extends Component {

  componentDidMount() {
    const { group, fetchShareholderGroup, params: { id } } = this.props;
    if (group.id !== Number(id)) fetchShareholderGroup(id).catch(toastResponseErrors);
  }

  progressData = () => {
    const { group: { id, farms_present } } = this.props;
    return {
      closeable: farms_present,
      headerText: <FormattedMessage id="component.progressBox.group.setupProgress" />,
      steps: [
        { text: <FormattedMessage id="component.progressBox.group.basicInfo" />, done: true },
        { text: <FormattedMessage id="general.assignFarms" />, done: farms_present,
          url: `/admin/shareholder-groups/${id}/assign-farms` },
      ],
    };
  };

  render() {
    const { group, isLoading, children, params: { id } } = this.props;
    const navLinks = [
      { link: `/admin/shareholder-groups/${id}/farm-roles`, label: <FormattedMessage id="general.farmAssignments" /> },
      { link: `/admin/shareholder-groups/${id}/edit`, label: <FormattedMessage id="general.groupInformation" /> },
    ];
    const steps = [
      { label: <FormattedMessage id="general.farmAssignments" />, icon: 'fa fa-ep-farm',
        completed: group.farms_present },
      { label: <FormattedMessage id="general.setupComplete" />, icon: 'fa fa-trophy', completed: group.farms_present },
    ];

    return (
      <div className={cn('shareholder-group-profile', 'clearfix')}>
        <GroupProfileHeader
          group={group}
          isLoading={isLoading}
          className="show-for-large"
        >
          <ProgressChecklist steps={steps} />
        </GroupProfileHeader>

        <Subnavigation
          className="small-12 column show-for-large"
          links={navLinks}
          isSticky
          stickyContent={<GroupMiniProfileHeader group={group} />}
        />

        <section className="small-12 column">

          <Panel>
            <Panel.Heading title={group.name} className="hide-for-large">
              <StatusBadge status={group.status} />
            </Panel.Heading>
            <Panel.Body noPadding className={cn('mobile-body')}>
              <Subnavigation className="hide-for-large" links={navLinks} inner />

              <div className={cn('mobile-body-box')}>

                {!isEmpty(group) &&
                  <ProgressBox className={cn('hide-for-large', 'progress-box')} {...this.progressData()} />}
                {children}
              </div>
            </Panel.Body>
          </Panel>

        </section>
      </div>
    );
  }
}

ShareholderGroupProfile.propTypes = {
  fetchShareholderGroup: T.func,
  params: T.object,
  group: T.object,
  isLoading: T.bool,
  children: T.object,
};

ShareholderGroupProfile.contextTypes = {
  router: T.object,
};

export default connect(
  (state) => ({
    group: state.shareholderGroups.groupShow.data,
    isLoading: state.shareholderGroups.groupShow.isLoading,
  }), { fetchShareholderGroup }
)(ShareholderGroupProfile);
