import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';
import CircleCheckbox from 'components/CircleCheckbox';
import Preloader from 'components/Preloader';
import InputLabel from 'components/Input/InputLabel';
import FilterSectionSwitcher from 'components/UserFilter/FilterSectionSwitcher';
// utils
import classnames from 'classnames/bind';
// assets
import styles from './SaveFilterModal.module.scss';

const cn = classnames.bind(styles);

class SaveFilterModal extends Component {

  state = {
    name: '',
    isSubmitting: false,
    isUpdating: Boolean(this.props.initialName),
  };

  onNameChange = ({ target: { value } }) => {
    this.setState({ name: value });
  };

  handleCheckBoxChange = ({ target: { name } }) => {
    const isUpdating = (name === 'existing-filter');
    this.setState((prevState) => ({
      isUpdating,
      name: isUpdating ? '' : prevState.name,
    }));
  };

  onSave = () => {
    const { onClose, onSave, initialName } = this.props;
    const { name, isUpdating } = this.state;
    this.setState({ isSubmitting: true });
    onSave(name || initialName, isUpdating)
      .then(onClose)
      .catch(() => {
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { onClose, sections, initialName } = this.props;
    const { name, isSubmitting, isUpdating } = this.state;
    return (
      <Modal
        className={cn('save-filters-modal')}
        title={<FormattedMessage id="general.button.saveSegment" />}
      >
        <section className="modal-body">
          <Preloader isActive={isSubmitting} />
          {!initialName && (
            <FormattedMessage id="general.filterName">
              {(text) => (
                <label>
                  <InputLabel>{text}</InputLabel>
                  <Input className={cn('input')} value={name} onChange={this.onNameChange} placeholder={text} />
                </label>
              )}
            </FormattedMessage>
          )}
          {initialName && (
            <>
              <CircleCheckbox
                className={cn('filter-checkbox', { 'not-checked': !isUpdating })}
                type="radio"
                name="existing-filter"
                checked={isUpdating}
                onChange={this.handleCheckBoxChange}
                label={(
                  <span>
                    <FormattedMessage tagName="b" id="component.saveFilterModal.updateExistingSegment" />
                    {initialName}
                  </span>
                )}
              />
              <CircleCheckbox
                className={cn('filter-checkbox', { 'not-checked': isUpdating })}
                type="radio"
                name="new-filter"
                checked={!isUpdating}
                onChange={this.handleCheckBoxChange}
                label={(
                  <FormattedMessage tagName="b" id="component.saveFilterModal.createNewSegment" />
                )}
              />
              <FormattedMessage id="general.filterName">
                {(text) => (
                  <Input
                    className={cn('input')}
                    value={name}
                    onChange={this.onNameChange}
                    placeholder={text}
                    disabled={isUpdating}
                  />
                )}
              </FormattedMessage>
            </>
          )}
          <div className={cn('selected-sections')}>
            {sections.map((item) => <FilterSectionSwitcher key={item.key} uniqueId={item.key} {...item} isDisabled />)}
          </div>
        </section>
        <section className="modal-footer text-right">
          <Button className="margin-right-1" onClick={onClose}>
            <FormattedMessage id="general.button.cancel" />
          </Button>
          <Button primary disabled={(!isUpdating && !name) || isSubmitting} onClick={this.onSave}>
            <FormattedMessage id="general.button.save" />
          </Button>
        </section>
      </Modal>
    );
  }
}

SaveFilterModal.propTypes = {
  initialName: T.string,
  onClose: T.func.isRequired,
  onSave: T.func.isRequired,
  sections: T.array.isRequired,
};

export default SaveFilterModal;
