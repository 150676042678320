import './GraphItemBalloon.scss';

export const statsGraphBalloon = (title) => `\n
  <div class="graph-tooltip">\
    <div>${title}</div>\
    <div>[[dateLabel]] [[date]]</div>\
    <div class="title-balloon">[[title]]</div>\
    <div class="current-value">[[valueLabel]]</div>\
    <div class='growth-rate-block [[blockClass]]'>\
      <i class='[[iconClass]]'></i>\
      <div>[[change]]</div>\
    </div>\
  </div>\
`;

export const dailyMortalityBalloonHTML = `\n
  <div class="graph-tooltip">\
    <div>[[tooltip_date]] vs</div>\
    <div>[[tooltip_date_was]]</div>\
    <div class="title-balloon">[[title]]</div>\
    <div class="current-value">[[value]]%</div>\
    <div class='growth-rate-block [[blockClass]]'>
      <i class='[[iconClass]]'></i>
      <div>[[mortality_change]]</div>
    </div>\
  </div>\
`;

export const symptomsBalloonHTML = `
  <div class='graph-tooltip'>\
    <div>[[tooltip_date]] vs</div>\
    <div>[[tooltip_date_was]]</div>\
    <div class='title-balloon'>[[title]]</div>\
    <div class='current-value'>[[symptomatic]]%</div>\
    <div class='growth-rate-block [[blockClass]]'>\
      <i class='[[iconClass]]'></i><div>[[symptomatic_change]]</div>\
    </div>\
  </div>`;

export const avgMortalityRateBalloonHTML = (title) => `
    <div class='graph-tooltip'>\
      <div>${title}</div>\
      <div>[[startDate]] - [[endDate]]</div>\
      <div class='current-value'>[[avgValue]]%</div>\
      <div class='growth-rate-block [[rateClass]]'>\
        <i class='[[iconClass]]'></i>\
        <span>[[delta]]</span>\
      </div>\
    </div>\
  `;

export const closedGroupBalloon = `\
    <div class='line-balloon'>\
      [[name]] • [[closed_on]] • [[mortality_rate]]%
    </div>\
  `;

export const conditionBalloonHTML = `\
    <div class="line-balloon">\
      [[title]] • [[percent]]% ([[value]])
    </div>\
  `;

export const waterUsageBallonHtml = (formatMessage) => `\
  <div class="graph-tooltip">\
    <div class="tooltip-date">\
      [[tooltip_date]]\
    </div>\
    <div class="values-wrap">\
      <div class="value">\
      ${formatMessage({ id: 'component.dataTable.headers.totalGallonsConsumed' })} • [[water_usage_value]]\
      </div>\
      <div class="value">\
        ${formatMessage({ id: 'component.dataTable.headers.totalInventory' })} • [[pigs]]\
      </div>\
      <div class="value">\
        ${formatMessage({ id: 'component.dataTable.headers.totalGallonsPerHead' })} • [[formattedValue]]\
      </div>\
    </div>\
    <div class="values-wrap orange">\
      <div class="value">\
      ${formatMessage({ id: 'general.averageGallonsPerHead' })} • [[average]]\
      </div>\
    </div>\
  </div>\
`;
