import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchPlantProfile } from 'reducers/profiles';
// components
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import Subnavigation from 'components/Subnavigation';
import CompanyMiniProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyMiniProfileHeader';
import Panel from 'components/Panel';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import 'components/Admin/CompanyProfileBox.module.scss';

const labels = {
  plantInfo: <FormattedMessage id="general.plantInfo" />,
};

class PackingPlant extends Component {

  constructor(props) {
    super(props);
    const { id: companyId, plantId } = props.params;
    this.navLinks = [
      { link: `/admin/packing-companies/${companyId}/packing-plants/${plantId}/edit`, label: labels.plantInfo },
    ];
  }

  componentDidMount() {
    const { fetchPlantProfile, params: { id, plantId } } = this.props;
    fetchPlantProfile(`/admin/packing_companies/${id}/packing_plants/${plantId}`).catch(toastResponseErrors);
  }

  render() {
    const { children, plant, isLoading } = this.props;

    return (
      <div className={cn('company-profile', 'clearfix')}>
        <CompanyProfileHeader
          avatarType="packing-plant"
          hasLocation
          actualCompanyId={plant.id}
          company={plant}
          isLoading={isLoading}
          className="show-for-large"
        />

        <Subnavigation
          className="small-12 column show-for-large"
          links={this.navLinks}
          isSticky
          stickyContent={
            <CompanyMiniProfileHeader avatarType="packing-plant" company={plant} />
          }
        />

        <section className="small-12 column">
          <Panel>
            <Panel.Heading title={plant.name} className="hide-for-large">
              <StatusBadge status={plant.status} />
            </Panel.Heading>

            <Panel.Body noPadding className={cn('mobile-body')}>
              <Subnavigation links={this.navLinks} className="hide-for-large" inner />
              <div className={cn('mobile-body-box')}>
                {children}
              </div>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

PackingPlant.contextTypes = {
  router: T.object,
};

PackingPlant.propTypes = {
  fetchPlantProfile: T.func.isRequired,
  params: T.object.isRequired,
  plant: T.object.isRequired,
  isLoading: T.bool.isRequired,
  children: T.object.isRequired,
};

export default connect(
  (state) => ({
    plant: state.profiles.plant.data,
    isLoading: state.profiles.plant.isLoading,
  }), {
    fetchPlantProfile,
  }
)(PackingPlant);
