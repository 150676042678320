import React, { useState } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Preloader from 'components/Preloader';
// utils
import cn from 'classnames';
// assets
import wellEfficacyImg from '../../images/well.svg';
import noChangeEfficacyImg from '../../images/nochange.svg';
import poorlyEfficacyImg from '../../images/poorly.svg';
import './TreatmentsEfficacyModal.scss';

const efficacyResponseVariants = [
  { value: 'worse', img: poorlyEfficacyImg },
  { value: 'no_changes', img: noChangeEfficacyImg },
  { value: 'better', img: wellEfficacyImg },
];

function formatEfficacyRecords(efficacyRecords) {
  return efficacyRecords.map((efficacy) => ({
    response: efficacy.response || {},
    efficacy_id: efficacy.id,
    efficacy_name: efficacy.dc_treatment_product.treatment_product.name,
  }));
}

const TreatmentsEfficacyModal = ({
  initialEfficacyRecords,
  isLoading,
  onSubmit,
}) => {
  const [{ efficacyRecords, currentIndex }, setState] = useState({
    efficacyRecords: formatEfficacyRecords(initialEfficacyRecords),
    currentIndex: 0,
  });
  const currentEfficacy = efficacyRecords[currentIndex];
  const isPrevExist = currentIndex > 0;
  const isNextExist = !!efficacyRecords[currentIndex + 1];
  const isDisabled = !currentEfficacy.response.result;

  const handleChangeEfficacyRecord = (value) => {
    setState((prevState) => ({
      ...prevState,
      efficacyRecords: [
        ...prevState.efficacyRecords.slice(0, currentIndex),
        {
          ...currentEfficacy,
          response: {
            ...currentEfficacy.response,
            result: value,
          },
        },
        ...prevState.efficacyRecords.slice(currentIndex + 1),
      ],
    }));
  };

  const handleClickNext = () => {
    setState((prevState) => ({ ...prevState, currentIndex: prevState.currentIndex + 1 }));
  };

  const handleClickPrev = () => {
    setState((prevState) => ({ ...prevState, currentIndex: prevState.currentIndex - 1 }));
  };

  return (
    <Modal
      className="TreatmentsEfficacyModal animated fadeIn"
      noCloseButton
      title={<FormattedMessage id="general.drugEfficacy" />}
    >
      <section className="modal-body">
        <Preloader isActive={isLoading} />
        <h2 className="body-title">
          <FormattedMessage
            id="component.modal.efficacy.text"
            values={{ name: <b>{currentEfficacy.efficacy_name}</b> }}
          />
        </h2>
        <div className="custom-radio">
          {efficacyResponseVariants.map((efficacy, radioIndex) => (
            <label key={radioIndex}>
              <input
                required="required"
                type="radio"
                value={efficacy.value}
                onChange={() => handleChangeEfficacyRecord(efficacy.value)}
                checked={efficacy.value === currentEfficacy.response.result}
              />
              <FormattedMessage id={`component.modal.efficacy.${efficacy.value}`}>
                {(text) => (
                  <span>
                    <img src={efficacy.img} alt={text} />
                    {text}
                  </span>
                )}
              </FormattedMessage>
            </label>
          ))}
        </div>
        {(efficacyRecords.length > 1) && (
          <ol className="dotnav">
            {efficacyRecords.map((tr, index) => <li className={cn({ active: index === currentIndex })} key={index} />)}
          </ol>
        )}
      </section>
      <section className="modal-footer flex sticky-on-mobile">
        {isPrevExist && (
          <Button light onClick={handleClickPrev}>
            <i className="fa fa-arrow-left" />
            <FormattedMessage id="general.button.back" />
          </Button>
        )}
        {isNextExist && (
          <Button primary onClick={handleClickNext} disabled={isDisabled}>
            <FormattedMessage id="general.button.next" />
            <i className="fa fa-arrow-right" />
          </Button>
        )}
        {!isNextExist && (
          <Button primary onClick={() => onSubmit(efficacyRecords)} disabled={isDisabled || isLoading}>
            <FormattedMessage id="general.button.submit" />
          </Button>
        )}
      </section>
    </Modal>
  );
};

TreatmentsEfficacyModal.propTypes = {
  onSubmit: T.func.isRequired,
  initialEfficacyRecords: T.array.isRequired,
  isLoading: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isLoading: state.dailyCheckup.checkupEdit.isReportingEfficacy,
  })
)(TreatmentsEfficacyModal);
