import React, { Component } from 'react';
import T from 'prop-types';
import update from 'react-addons-update';
// components
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import CollapsibleFilter from 'components/CollapsibleFilter/CollapsibleFilter';
import CircleCheckbox from 'components/CircleCheckbox';
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect';
// utils
import { animateScroll } from 'react-scroll';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import get from 'lodash.get';
// api
import { getSymptoms } from 'endpoints/common';
// styles
import './GalleryFilters.scss';
// constants
import { deathTypes } from 'constants.js';

class GalleryFilters extends Component {

  state = {
    symptomsList: [],
  };

  componentDidMount() {
    this.fetchSymptoms();
  }

  fetchSymptoms = () => {
    getSymptoms()
      .then((resources) => this.setState({ symptomsList: resources }))
      .catch(toastResponseErrors);
  };

  scrollToTop = () => {
    animateScroll.scrollToTop({
      containerId: 'bodybag',
      duration: 0,
    });
  };

  resetFilters = () => {
    const { fetchAssets } = this.props;
    fetchAssets();
    this.searchbox.resetSearch();
    if (this.sourceBox) this.sourceBox.resetSearch();
    this.scrollToTop();
  };

  onSearchChange = (search) => {
    const { fetchAssets, reqParams } = this.props;
    fetchAssets({ ...reqParams, page: 1, search });
    this.scrollToTop();
  };

  onSourceChange = (source) => {
    const { fetchAssets, reqParams } = this.props;
    fetchAssets({ ...reqParams, page: 1, external_source_name: source });
    this.scrollToTop();
  };

  onSymptomChange = (value) => {
    const { fetchAssets, reqParams } = this.props;
    fetchAssets({ ...reqParams, page: 1, symptom_ids: [value] });
    this.scrollToTop();
  };

  onDeathTypeChange = (value) => () => {
    const { fetchAssets, reqParams } = this.props;
    const deaths = Array.from(reqParams.deaths || []);
    const index = deaths.indexOf(value);
    this.scrollToTop();
    fetchAssets({ ...reqParams,
      page: 1,
      deaths: ~index
        ? update(deaths, { $splice: [[index, 1]] })
        : update(deaths, { $push: [value] }),
    });
  };

  render() {
    const { className, reqParams } = this.props;
    const { symptomsList } = this.state;

    const symptomFilter = get(reqParams, 'symptom_ids[0]', '');
    const deathTypeFilter = get(reqParams, 'deaths', []);

    return (
      <div className={cn('GalleryFilters', className)}>
        <div className="filters-header">
          <span className="heading">
            <FormattedMessage id="general.filters" />
          </span>
          <span className="reset-filters" onClick={this.resetFilters}>
            <FormattedMessage id="general.button.reset" />
          </span>
        </div>
        <div className="filters-body">
          <div className="gallery-search">
            <SearchBox
              ref={(ref) => { this.searchbox = ref; }}
              placeholderKey="general.placeholder.searchFarmsOrUsers"
              onChange={this.onSearchChange}
            />
          </div>
          <CollapsibleFilter
            title={<FormattedMessage id="component.assetMapFilter.CollapsibleFilter.symptoms" />}
            className="gallery-filter"
          >
            <ResponsiveSelect
              grey
              noInputMargin
              options={symptomsList}
              value={symptomFilter}
              onChange={this.onSymptomChange}
              valueKey="id"
              labelKey="name"
              placeholder={<FormattedMessage id="general.placeholder.chooseSymptom" />}
            />
          </CollapsibleFilter>
          <CollapsibleFilter title={<FormattedMessage id="general.mortalityType" />} className="gallery-filter">
            {deathTypes.map(({ label, key }) => (
              <CircleCheckbox
                className="death-type"
                type="checkbox"
                label={label}
                checked={deathTypeFilter.includes(key)}
                onChange={this.onDeathTypeChange(key)}
                key={key}
              />
            ))}
          </CollapsibleFilter>
          <CollapsibleFilter title={<FormattedMessage id="general.externalSource" />} className="gallery-filter">
            <SearchBox
              ref={(ref) => { this.sourceBox = ref; }}
              placeholderKey="general.searchBy.source"
              onChange={this.onSourceChange}
            />
          </CollapsibleFilter>
        </div>
      </div>
    );
  }
}

GalleryFilters.defaultProps = {
  className: '',
};

GalleryFilters.propTypes = {
  className: T.string,
  fetchAssets: T.func.isRequired,
  reqParams: T.object.isRequired,
};

export default GalleryFilters;
