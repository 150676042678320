import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import './FlexTableItem.scss';

const FlexTableItem = ({ children, className, sorted, flex, fixed }) => (
  <div
    className={cn('FlexTableItem', className, { sorted, 'fixed-column': fixed })}
    style={{ flex }}
  >
    {children}
  </div>
);

FlexTableItem.propTypes = {
  children: T.any,
  className: T.string,
  sorted: T.bool,
  fixed: T.bool,
  flex: T.string,
};

export default FlexTableItem;
