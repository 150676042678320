import React from 'react';
import T from 'prop-types';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import FarmTooltip from '../../EntityFarmfeedTooltip/FarmTooltip';
import CompanyFarmfeedTooltip from '../../EntityFarmfeedTooltip/CompanyFarmfeedTooltip';
import Popover from '../../Popover';
import FarmMarker from 'components/GoogleMap/Markers/FarmMarker/FarmMarker';
import Avatar from 'components/Avatar/Avatar';
import TextLabel from 'components/TextLabel/TextLabel';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './ActivityCheckupBox.module.scss';

const cn = classnames.bind(styles);

const ActivityCheckupBox = ({ activity, className, children, isAdmin, actionButtons }) => {
  const { id, farm, variety, check_in_id } = activity;
  const isCheckIn = variety.includes('checked_in');

  return (
    <div className={cn('checkup-box', className)}>
      <div className={cn('checkup-box-header')}>
        <Popover
          content={<FarmTooltip activityId={id} farmId={farm.id} isAdmin={isAdmin} />}
          placement="bottomLeft"
        >
          <span>
            {isCheckIn
              ? <FarmMarker farm={farm} inline />
              : <Avatar type="farm" avatarSize={40} iconSize={20} />}
          </span>
        </Popover>

        <div className={cn('farm-info')}>
          <div>
            <strong>{farm.name}</strong>
            <TextLabel className={cn('farm-label')}>{farm.farm_type}</TextLabel>
          </div>
          <div className={cn('manager')}>
            {farm.manager
              ? (
                <Popover
                  content={<CompanyFarmfeedTooltip activityId={id} companyId={farm.manager.id} isAdmin={isAdmin} />}
                  placement="topLeft"
                >
                  <div>
                    {farm.manager.name}
                  </div>
                </Popover>
              )
              : <FormattedMessage id="container.farmfeed.row.noManageCompany" />}
          </div>
        </div>

        {isCheckIn && (
          <div className={cn('farm-actions')}>
            <Link to={`/checkins/${check_in_id}`} className="button light show-for-large">
              <FormattedMessage id="general.button.viewOnMap" />
            </Link>
          </div>
        )}
      </div>
      {children && (
        <div className={cn('checkup-box-body')}>
          {children}
        </div>
      )}
      {actionButtons && (
        <div className={cn('checkup-box-footer', { 'hide-for-large': isCheckIn })}>
          {actionButtons}
        </div>
      )}
    </div>
  );
};

ActivityCheckupBox.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  actionButtons: T.oneOfType([T.arrayOf(T.node), T.node]),
  isAdmin: T.bool,
};

ActivityCheckupBox.defaultProps = {
  isAdmin: false,
};

export default ActivityCheckupBox;
