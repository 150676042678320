import React from 'react';
import T from 'prop-types';
// components
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
// utils
import renderIf from 'utils/renderIf';

const defaultColor = '#cacaca';

const getSpotColors = (positiveColor, negativeColor) => ({
  '-1': positiveColor,
  '0': positiveColor,
  '1': negativeColor,
});

const getLineColor = (data, positiveColor, negativeColor) => {
  return data[data.length - 1] - data[data.length - 2] > 0 ? negativeColor : positiveColor;
};

const InlineGraph = ({ data, positiveColor, negativeColor, isGrayscale, ...others }) => {
  const uptrendColor = isGrayscale ? defaultColor : positiveColor;
  const downtrendColor = isGrayscale ? defaultColor : negativeColor;
  return (
    renderIf(data.length > 1)(
      <Sparklines data={data} {...others}>
        <SparklinesLine color={getLineColor(data, uptrendColor, downtrendColor)} />
        <SparklinesSpots spotColors={getSpotColors(uptrendColor, downtrendColor)} />
      </Sparklines>
    )
  );
};

InlineGraph.propTypes = {
  positiveColor: T.string,
  negativeColor: T.string,
  data: T.array,
  isColored: T.bool,
};

InlineGraph.defaultProps = {
  data: [],
  isGrayscale: false,
  positiveColor: '#396a9f',
  negativeColor: '#e05d33',
};

export default InlineGraph;
