import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import CheckboxMenu from './CheckboxMenu';
import Input from 'components/Input';
// utils
import classnames from 'classnames/bind';
import pick from 'lodash.pick';
// filter constants
import { checkboxProps } from 'utils/constants/userFilters';
// styles
import styles from './Menu.module.scss';

const cn = classnames.bind(styles);

const EntityValueMenu = ({
  currentValue,
  onInputChange,
  inputValue,
  inputStartValue,
  inputEndValue,
  maxInputValue,
  inputStep,
  inputLabel,
  onFarmSelect,
  hasRangeError,
  ...others
}) => (
  <CheckboxMenu {...pick(others, checkboxProps)} currentValue={currentValue}>
    {(value) => (
      <>
        {(value === currentValue) && !['range', 'any'].includes(value) && (
          <div className={cn('value-input-line')}>
            <Input
              name="value"
              type="number"
              min="0"
              max={maxInputValue}
              step={inputStep}
              placeholder={0}
              className={cn('value-input')}
              value={inputValue}
              onChange={onInputChange}
            />
            <div className={cn('value-label')}>{inputLabel}</div>
          </div>
        )}
        {(value === currentValue) && (value === 'range') && (
          <>
            <div className={cn('value-input-line')}>
              <FormattedMessage id="general.from">
                {(text) => <span className={cn('from-or-to')}>{text}</span>}
              </FormattedMessage>
              <Input
                name="start"
                type="number"
                min="0"
                max={maxInputValue}
                step={inputStep}
                placeholder={0}
                className={cn('value-input')}
                hasError={hasRangeError}
                value={inputStartValue}
                onChange={onInputChange}
              />
              <div className={cn('value-label')}>{inputLabel}</div>
            </div>
            <div className={cn('value-input-line')}>
              <FormattedMessage id="general.to">
                {(text) => <span className={cn('from-or-to')}>{text}</span>}
              </FormattedMessage>
              <Input
                name="end"
                type="number"
                min="0"
                max={maxInputValue}
                step={inputStep}
                placeholder={0}
                className={cn('value-input')}
                hasError={hasRangeError}
                value={inputEndValue}
                onChange={onInputChange}
              />
              <div className={cn('value-label')}>{inputLabel}</div>
            </div>
          </>
        )}
      </>
    )}
  </CheckboxMenu>
);

EntityValueMenu.propTypes = {
  isOpened: T.bool.isRequired,
  hasRangeError: T.bool,
  currentValue: T.string,
  maxInputValue: T.number,
  inputLabel: T.oneOfType([T.string, T.node]),
  options: T.array.isRequired,
  onConditionChange: T.func.isRequired,
  onInputChange: T.func.isRequired,
  inputValue: T.oneOfType([T.number, T.string]),
  inputStartValue: T.oneOfType([T.number, T.string]),
  inputEndValue: T.oneOfType([T.number, T.string]),
  onApply: T.func.isRequired,
  inputStep: T.number,
  onFarmSelect: T.func,
};

export default EntityValueMenu;
