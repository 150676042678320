import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import TableFilter from 'components/TableFilter';
import DataTable from 'components/DataTable/DataTable';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import download from 'downloadjs';
import { isTablet } from 'react-device-detect';
import getTableColumns from 'components/CSVImport/Results/columns/getTableColumns';
// styles
import './DataTableImport.scss';

const tableFilters = [
  { label: <FormattedMessage id="general.created" />, value: 'created' },
  { label: <FormattedMessage id="general.csvImport.table.updated" />, value: 'updated' },
  { label: <FormattedMessage id="general.errors" />, value: 'errors' }
];

class DataTableImports extends Component {

  state = {
    isLoading: false
  };

  componentDidMount() {
    this.renderRerunArray();
  }

  downloadCSV = (path, fileName) => (e) => {
    e.stopPropagation();
    return fetchFromAPI(path, { blob: true })
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };

  renderRerunArray = () => {
    this.setState({ isLoading: true });
    // eslint-disable-next-line react/prop-types
    const { reqParams, fetchData, params: { nameType } } = this.props;
    fetchData({ ...reqParams, sort: 'status asc', type: nameType })
      .catch(toastResponseErrors)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onFilterChange = (filter) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, status: filter, sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  onSearchChangeArray = (searchName) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, search: searchName, sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };


  downloadAll = () => {
    fetchFromAPI(this.getPatchDownload(), { blob: true })
      .then((blob) => download(blob, 'Download', 'text/csv'))
      .catch(toastResponseErrors);
  };

  getPatchDownload =() => {
    const { params: { nameType, id } } = this.props;
    return `/admin/csv_imports/download?id=${id}&type=${nameType}`;
  };

  getUrlDownload = (success) => {
    return `${this.getPatchDownload()}&success=${success ? 'true' : 'false'}`;
  };

  getNameDownload = (success) => {
    return success ? 'Successes' : 'Errors';
  };

  getTypeName = (type) => {
    const columns = {
      groups: 'general.group',
      barns: 'general.barns',
      farms: 'general.farms',
      users: 'general.user',
    };
    return <FormattedMessage id={columns[type]} />;
  };

  render() {
    const {
      reqParams: { page, per_page, search, sort, status },
      meta: { total, created, updated, errors },
      onSortChange,
      onPageChange,
      onPerPageChange,
      importResults,
      getNameStore,
      params: {
        nameType,
      }
    } = this.props;
    const { isLoading } = this.state;
    const stats = { created, updated, errors };
    const datePush = (
      <FormattedMessage
        id="general.csvImport.search.imports"
        values={{
          nameCard: this.getTypeName(nameType),
        }}
      />
    );
    const columns = getTableColumns(nameType, status);
    const labelButton = <FormattedMessage id="general.csvImport.results.export" />;
    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page
    };
    if (isLoading) {
      return <Preloader isActive={isLoading} />;
    }
    return (
      <div className="wrapperTableImport">
        <div className="small-12 column">
          <div className="BarnsheetsGroups">
            <Panel className="mv-20">
              <Panel.Heading
                renderTitle={() => (
                  <h2 className="lighter show-for-large">
                    {datePush}
                  </h2>
                )}
              >
                <SearchBox initialValue={search} onChange={this.onSearchChangeArray} />
              </Panel.Heading>
              <Panel.Body noPadding>
                <Preloader isActive={isLoading} />
                <TableFilter
                  filters={tableFilters}
                  onFilterChange={this.onFilterChange}
                  activeFilter={status}
                  stats={stats}
                  className="mh-10"
                >
                  <div className="containerButtons">
                    <DropdownButton
                      idKey="groups-dropdown"
                      ignoreScroll
                      label={labelButton}
                      buttonType="compact"
                      onClick={this.downloadAll}
                      dropDownData={[
                        {
                          label: (
                            <FormattedMessage id="general.csvImport.results.exportSuccesses" />
                          ),
                          onClick: this.downloadCSV(
                            this.getUrlDownload(true),
                            this.getNameDownload(true)
                          )
                        },
                        {
                          label: (
                            <FormattedMessage id="general.csvImport.results.exportErrors" />
                          ),
                          onClick: this.downloadCSV(
                            this.getUrlDownload(false),
                            this.getNameDownload(false)
                          )
                        }
                      ]}
                      customClass="show-for-large"
                    />
                  </div>
                </TableFilter>
                {(!importResults.length) ? (
                  <NothingBox
                    display={!importResults.length}
                    itemsName={nameType}
                    isLoading={isLoading}
                    filter={getNameStore}
                  />
                ) : (
                  <DataTable
                    data={importResults}
                    getRowClass={() => ({ 'pink': status === 'errors' })}
                    columns={columns}
                    sort={sort}
                    onSortChange={onSortChange}
                    paginationProps={paginationProps}
                    isExpandable={isTablet}
                    scrollable
                    tableKey={getNameStore}
                  />
                )}
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

DataTableImports.propTypes = {
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  importResults: T.array,
  // eslint-disable-next-line react/no-unused-prop-types
  getDataUrl: T.string,
  getNameStore: T.string,
};

const enhance = compose(
  connect(
    (state, ownProps) => {
      return ({
        meta: state.dataTable[ownProps.getNameStore].meta,
        reqParams: state.dataTable[ownProps.getNameStore].params,
        isAdmin: state.auth.user.roles_map.admin,
        importResults: state.dataTable[ownProps.getNameStore].resources,
      });
    },
    (dispatch, ownProps) => {
      return ({
        fetchData: (query) => dispatch(fetchData(ownProps.getDataUrl, ownProps.getNameStore, query)),
      });
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(DataTableImports);
