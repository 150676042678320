import moment from 'moment';
import sumBy from 'lodash.sumby';

export function getPeriodAverage(data, period = 'week', dateFormat = 'YYYY-MM-DD') {
  const periodsAverage = [];
  const minDate = moment(data[0].closed_on);
  const maxDate = moment(data[data.length - 1].closed_on);

  for (let startDate = minDate.clone(); startDate.isBefore(maxDate); startDate.add(1, `${period}s`)) {
    startDate.startOf(period);
    const endDate = startDate.clone().endOf(period);
    const periodValues = data.filter((item) => moment(item.closed_on).isBetween(startDate, endDate));
    const periodSum = sumBy(periodValues, 'mortality_rate');
    const isLastPeriod = !endDate.isBefore(maxDate);

    periodsAverage.push({
      closed_on: isLastPeriod ? endDate.format(dateFormat) : startDate.format(dateFormat),
      avgValue: periodValues.length > 0 ? Math.round((periodSum * 100) / periodValues.length) / 100 : 0,
      periodValues,
      startDate: startDate.format('D MMM'),
      endDate: endDate.format('D MMM YYYY'),
    });
  }

  return periodsAverage;
}
