import React, { useState, useCallback } from 'react';
import T from 'prop-types';
// components
import Autosuggest from 'react-autosuggest';
import Input from 'components/Input';
import VerticalAligner from 'components/VerticalAligner';
import Avatar from 'components/Avatar/Avatar';
// api
import { searchAdminFarms } from 'endpoints/admin/farms';
// utils
import debounce from 'lodash.debounce';
import cn from 'classnames';
// styles
import './FarmAutosuggest.scss';

const FarmAutosuggest = ({ placeholderText, onChange, onFarmSet, value, isFarmSet }) => {
  const [suggestions, setSuggestions] = useState([]);

  const getFarms = useCallback(debounce((value) => {
    const searchParams = { search: encodeURIComponent(value), with_source_marker: true };
    return searchAdminFarms(searchParams)
      .then((response) => {
        setSuggestions(response.resources);
        const farm = response.resources.find((farm) => farm.name.toLowerCase() === value.toLowerCase());
        if (!farm && !isFarmSet) return;
        onFarmSet(farm);
      });
  }, 300), [setSuggestions, isFarmSet]);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion) => (
    <VerticalAligner className={cn('suggestion-item', { 'with-source': suggestion.external_source_assigned })}>
      <Avatar type="farm" avatarSize="30" iconSize="13" className="mr-10" />
      <b>{suggestion.name}</b>
      {suggestion.external_link_id && <span className="source-assigned-label">({suggestion.external_link_id})</span>}
    </VerticalAligner>
  );

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => getFarms(value)}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={(e, { suggestion }) => onFarmSet(suggestion)}
      getSuggestionValue={(suggestion) => suggestion.name}
      renderSuggestion={renderSuggestion}
      renderInputComponent={(props) => (
        <Input
          {...props}
          getReference={(input) => props.ref(input)}
          ref={null}
        />
      )}
      inputProps={{
        placeholder: placeholderText,
        value,
        onChange,
      }}
    />
  );
};

FarmAutosuggest.propTypes = {
  placeholderText: T.string.isRequired,
  value: T.string.isRequired,
  onChange: T.func.isRequired,
  onFarmSet: T.func.isRequired,
  isFarmSet: T.bool.isRequired,
};

export default FarmAutosuggest;
