import React, { Component } from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
import clamp from 'lodash.clamp';
import debounce from 'lodash.debounce';
// styles
import './ScrollableWrap.scss';

class ScrollableWrap extends Component {

  constructor() {
    super();
    this.debouncedUpdate = debounce(this.updateScrollableWrap, 300);
    this.state = {
      scrollLeft: 0,
      scrollSize: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedUpdate, true);
    this.updateScrollableWrap();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.props.children) {
      this.debouncedUpdate();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedUpdate);
  }

  resetScroll = () => {
    this.setState({ scrollLeft: 0 });
  };

  updateScrollableWrap = () => {
    const { container, contentWrapper } = this;
    if (!contentWrapper) return;
    const scrollSize = contentWrapper.scrollWidth - container.offsetWidth;
    if (scrollSize !== this.state.scrollSize) {
      this.setState({ scrollSize });
    }
  };

  updateScroll = (direction = 1) => () => {
    const { scrollLeft, scrollSize } = this.state;
    const nextScroll = clamp(scrollLeft + (320 * direction), 0, scrollSize);
    this.setState({ scrollLeft: nextScroll });
  };

  bindRef = (refName) => (ref) => {
    this[refName] = ref;
  };

  render() {
    const { className, children } = this.props;
    const { isMobile } = this.context;
    const { scrollLeft, scrollSize } = this.state;
    const contentWrapperStyle = { transform: `translate(-${scrollLeft}px)` };

    return (
      <div
        ref={this.bindRef('container')}
        className={cn(`ScrollableWrap ${className}`, {
          'isMobile': isMobile,
          'scrollable': scrollSize > 0,
          'scrollable-left': scrollLeft > 0,
          'scrollable-right': scrollLeft < scrollSize,
        })}
      >
        <div
          className="scrollable-content"
          style={contentWrapperStyle}
          ref={this.bindRef('contentWrapper')}
        >
          {children}
        </div>

        <div
          className={cn('scroll-button scroll-left', { '_hidden': scrollLeft === 0 })}
          onClick={this.updateScroll(-1)}
        >
          <i className="fa fa-arrow-left mr-3" />
        </div>
        <div
          className={cn('scroll-button scroll-right', { '_hidden': scrollLeft === scrollSize })}
          onClick={this.updateScroll(1)}
        >
          <i className="fa fa-arrow-right ml-3" />
        </div>

        <div className="inset-shadow left" />
        <div className="inset-shadow right" />
      </div>
    );
  }
}

ScrollableWrap.defaultProps = {
  className: '',
  children: [],
};

ScrollableWrap.contextTypes = {
  isMobile: T.bool,
};

ScrollableWrap.propTypes = {
  children: T.array,
  className: T.string,
};

export default ScrollableWrap;
