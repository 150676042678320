
export const groupCardHTML = '<div class="name-label">' +
  '<div class="plant-badge {type}"><i class="fa fa-industry"></i></div>' +
  '<div class="farm-name-address">' +
  '<span>{group_name}</span>' +
  '{name}' +
  '<span class="address">{address}</span>' +
  '<span class="status {group_status} {type}">{label}</span>' +
  '</div>' +
  '<div class="farm-type-badge {type}">{type}</div>' +
  '</div>';
