import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import Popover from 'components/Popover';
import TreatmentFarmfeedTooltip from 'components/EntityFarmfeedTooltip/TreatmentFarmfeedTooltip';

const TreatmentPopoverList = ({ treatments, isCurrentUserAdmin }) => (
  treatments.map(({ id, name }, index) => (
    <Fragment key={id}>
      <Popover
        content={<TreatmentFarmfeedTooltip treatmentId={id} isAdmin={isCurrentUserAdmin} />}
        placement="bottomLeft"
      >
        <span>{name}</span>
      </Popover>
      {!!treatments[index + 1] && ', '}
    </Fragment>
  ))
);

TreatmentPopoverList.propTypes = {
  treatments: T.array.isRequired,
  isCurrentUserAdmin: T.bool.isRequired,
};

export default TreatmentPopoverList;
