import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reset } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel/index';
import ToggleSwitcher from 'components/ToggleSwitcher/index';
import FormErrorsBox from 'components/FormErrorsBox';
import PrairieSystemsForm from './components/PrairieSystemsForm';
// utils
import { fetchFromAPI } from 'utils/api';
import handleFormErrors from 'utils/handleFormErrors';
import { showToastrMessage } from 'utils';

const initialState = {
  formValues: {
    user_name: '',
    password: '',
    application_id: '',
    token_id: '',
    export_placements: false,
    export_transfers: false,
    export_sales: false,
    export_mortalities: false,
    export_awg_weight: false,
    export_death_types: false,
    import_treatments: false,
    import_pig_groups: false,
    import_head_treated: false,
    import_withdrawal: false,
  },
  active: false,
  isLoading: false,
  passwordHint: '',
  connected: false,
};

class PrairieSystemsIntegration extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    const { companyId } = this.props;
    this.setState({ isLoading: true });

    fetchFromAPI(`/admin/companies/${companyId}/fas_link_credential`)
      .then(({ resource }) => {
        const {
          password_hint,
          active,
          user_name,
          application_id,
          token_id,
          export_placements,
          export_transfers,
          export_sales,
          export_sale_results,
          export_mortalities,
          export_awg_weight,
          export_death_types,
          import_treatments,
          import_pig_groups,
          import_head_treated,
          import_withdrawal,
        } = resource;
        this.setState({
          active,
          isLoading: false,
          passwordHint: password_hint || '',
          connected: !!password_hint,
          formValues: {
            export_placements,
            export_transfers,
            export_sales,
            export_sale_results,
            export_mortalities,
            export_awg_weight,
            export_death_types,
            import_treatments,
            import_pig_groups,
            import_head_treated,
            import_withdrawal,
            user_name: user_name || '',
            password: '',
            application_id: application_id || '',
            token_id: token_id || '',
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  handleSubmit = (resource, change) => {
    const { companyId } = this.props;
    const reqParams = { method: 'PUT', body: JSON.stringify({ resource: { ...resource, active: true } }) };
    this.setState({ isLoading: true });

    return fetchFromAPI(`/admin/companies/${companyId}/fas_link_credential`, reqParams)
      .then(({ resource }) => {
        showToastrMessage('component.toastr.credentials.updated');
        change('password', '');
        const {
          password_hint,
          active,
          user_name,
          application_id,
          token_id,
          export_placements,
          export_transfers,
          export_sales,
          export_sale_results,
          export_mortalities,
          export_awg_weight,
          export_death_types,
          import_treatments,
          import_pig_groups,
          import_head_treated,
          import_withdrawal,
        } = resource;
        this.setState({
          active,
          connected: true,
          isLoading: false,
          passwordHint: password_hint,
          formValues: {
            user_name,
            application_id,
            token_id,
            export_placements,
            export_transfers,
            export_sales,
            export_sale_results,
            export_mortalities,
            export_awg_weight,
            export_death_types,
            import_treatments,
            import_pig_groups,
            import_head_treated,
            import_withdrawal,
          },
        });
      },
      (error) => {

        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  toggleActive = (resource) => {
    const { companyId } = this.props;
    const reqParams = { method: 'PUT', body: JSON.stringify({ resource }) };
    this.setState({ isLoading: true });

    fetchFromAPI(`/admin/companies/${companyId}/fas_link_credential`, reqParams)
      .then(({ resource: { active } }) => {
        this.setState({ active, isLoading: false });
      },
      (error) => {
        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  resetForm = () => {
    const { companyId, resetForm } = this.props;
    const resource = { user_name: '', password: '', application_id: '', token_id: '' };
    const reqParams = { method: 'delete', body: JSON.stringify({ resource }) };
    this.setState({ isLoading: true });

    return fetchFromAPI(`/admin/companies/${companyId}/fas_link_credential`, reqParams)
      .then(() => {
        resetForm('prairie-systems-form');
        showToastrMessage('component.toastr.credentials.reset');
        this.setState(initialState);
      },
      (error) => {
        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  };

  switchToggle = (name, value) => {
    const { companyId } = this.props;
    const { formValues } = this.state;
    const resource = { [name]: value };
    const reqParams = { method: 'PUT', body: JSON.stringify({ resource }) };
    this.setState({ isLoading: true });
    return fetchFromAPI(`/admin/companies/${companyId}/fas_link_credential`, reqParams)
      .then(({ resource }) => {
        showToastrMessage('component.toastr.settings.updated');
        this.setState({
          isLoading: false,
          formValues: {
            ...formValues,
            [name]: resource[name],
          }
        });
      },
      (error) => {

        this.setState({ isLoading: false });
        return handleFormErrors(error);
      });
  }

  render() {
    const { passwordHint, isLoading, formValues, active, connected } = this.state;
    const isDisabled = !formValues.user_name || !formValues.token_id || !formValues.application_id;

    return (
      <Panel>
        <Panel.Heading title="Prairie Systems" />
        <Panel.Body className="ph-15">
          <Preloader isActive={isLoading} />

          <div className="row clearfix">
            <section className="small-12 medium-10 large-8 column">

              <ToggleSwitcher
                isDisabled={isDisabled}
                checked={active}
                text={<FormattedMessage id="component.prairieSystems.title" />}
                description={<FormattedMessage id="component.prairieSystems.desc" />}
                onChange={() => this.toggleActive({ active: !active })}
              />

              <FormErrorsBox formName="prairie-systems-form" className="animated fadeIn" />

              <PrairieSystemsForm
                handleForm={this.handleSubmit}
                resetForm={this.resetForm}
                passwordHint={passwordHint}
                initialValues={formValues}
                active={active}
                connected={connected}
                switchToggle={this.switchToggle}
              />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

PrairieSystemsIntegration.propTypes = {
  companyId: T.number.isRequired,
  resetForm: T.func.isRequired,
};

export default connect(
  (state) => ({
    companyId: state.auth.user.current_company.id,
  }), {
    resetForm: reset,
  }
)(PrairieSystemsIntegration);
