import React from 'react';
import T from 'prop-types';
// components
import Waypoint from 'react-waypoint';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
// styles
import styles from './ActivityBox.module.scss';

const ActivityBox = ({
  className,
  isTruncated,
  isFlagged,
  isUnseen,
  hasMarker,
  markAsSeen,
  onViewFullActivity,
  children,
}) => (
  <div className={cn(styles['activity-box'], className, { [styles.truncated]: isTruncated })}>
    {hasMarker && (
      <div className={cn(styles['status-indicator'])}>
        <div className={cn({ [styles.flagged]: isFlagged, [styles.unseen]: isUnseen })} />
      </div>
    )}

    {isTruncated && (
      <div className={styles['backdrop-gradient']}>
        <button className={styles['view-full-event-btn']} onClick={onViewFullActivity}>
          <i className="fa fa-arrow-down mr-5 semibold" />
          <FormattedMessage id="general.viewFullEvent" />
        </button>
      </div>
    )}

    {children}

    {isUnseen && markAsSeen &&
      <Waypoint onEnter={markAsSeen} />}
  </div>
);

ActivityBox.propTypes = {
  className: T.string,
  isTruncated: T.bool,
  isFlagged: T.bool.isRequired,
  isUnseen: T.bool.isRequired,
  hasMarker: T.bool,
  markAsSeen: T.func, // there's no markAsSeen when activity is child or shared or in process to share
  onViewFullActivity: T.func,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default ActivityBox;
