import React from 'react';
import T from 'prop-types';
// components
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './AsyncMultiSelect.module.scss';

const cn = classnames.bind(styles);

const defaultFilterOptions = (values) => (option) => {
  const isExist = values.find((item) => item.genetic?.id === option.value);
  if (!isExist) return option;
  return null;
};

const AsyncMultiSelect = ({
  title,
  label,
  onSelect,
  onRemove,
  values = [],
  className,
  optionsPath,
  filterOption = defaultFilterOptions,
  optionKeys,
  placeholderKey,
  extractLabel,
  labelClassName,
  selectClassName,
  scrollable,
  ...props
}) => (
  <div className={cn('multi-select', className)}>
    {title && <div className={cn('select-title')}>{title}</div>}
    {label && <div className={cn('select-label')}>{label}</div>}
    <AsyncSelect
      filterOption={filterOption(values)}
      className={selectClassName}
      onChange={onSelect}
      optionsPath={optionsPath}
      optionsKeys={optionKeys}
      placeholder={placeholderKey && <FormattedMessage id={placeholderKey} />}
      {...props}
    />
    <div className={cn('selected-box', { scrollable })}>
      {values?.map((value) => (
        <div key={value.id} className={cn('selected-item')}>
          <div className={cn(labelClassName)}>
            {extractLabel(value)}
          </div>
          <i className={cn('remove-btn', 'fa fa-times')} onClick={() => onRemove(value)} />
        </div>
      ))}
    </div>
  </div>
);

AsyncMultiSelect.propTypes = {
  title: T.oneOfType([T.node, T.string]),
  label: T.oneOfType([T.node, T.string]),
  values: T.array,
  onSelect: T.func.isRequired,
  onRemove: T.func.isRequired,
  selectClassName: T.string,
  className: T.string,
  labelClassName: T.string,
  optionsPath: T.string.isRequired,
  filterOption: T.func,
  optionKeys: T.shape({ value: T.string, label: T.string }).isRequired,
  placeholderKey: T.string,
  extractLabel: T.func.isRequired
};

export default AsyncMultiSelect;
