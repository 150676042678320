import React from 'react';
import T from 'prop-types';
// components
import GrowthRate from '../GrowthRate';
// assets
import './AnalyticsPanelStats.scss';

const AnalyticsPanelStats = ({ stats, children }) => (
  <div className="stats">
    {stats.map((item, index) => (
      <div key={index} className="stat-item">
        <div className="stat-title">{item.label}</div>
        <div className="stat-number">{item.value}</div>
        {item.growthRate && <GrowthRate transparent {...item.growthRate} />}
      </div>
    ))}
    {children}
  </div>
);

AnalyticsPanelStats.propTypes = {
  stats: T.array.isRequired,
  children: T.any,
};

export default AnalyticsPanelStats;
