import React, { Fragment } from 'react';
import T from 'prop-types';
import ContentLoader from 'react-content-loader';
// utils
import times from 'lodash.times';

const rowHeight = 30;
const leftIndent = 50;
const rightIndent = 25;
const betweenLinesIndent = 25;

const getLineWidth = (width, colsCount = 5) => ((width - ((colsCount - 1) * betweenLinesIndent)) / colsCount);

const ContentPlaceholder = ({ rowsCount, columnsCount, width, ...others }) => {
  const allLinesWidth = width - leftIndent - rightIndent;
  const lineWidth = getLineWidth(allLinesWidth, columnsCount);
  const height = rowsCount * rowHeight - rowsCount + 1;
  return (
    <ContentLoader height={height} width={width} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb" {...others}>
      {times(rowsCount, (i) => (
        <Fragment key={i}>
          <circle cx="25" cy={15 + (rowHeight * i)} r="10" />
          {!!columnsCount && (columnsCount > 1) && times(rowsCount, (j) => (
            <rect
              key={`row-${i}-col-${j}`}
              x={leftIndent + (lineWidth + betweenLinesIndent) * i}
              y={10 + (rowHeight * j)}
              rx="4"
              ry="4"
              width={lineWidth}
              height="10"
            />
          ))}
          {(!columnsCount || (columnsCount === 1)) && (
            <rect x={leftIndent} y={10 + (rowHeight * i)} rx="4" ry="4" width={allLinesWidth} height="10" />
          )}
          <rect x="0" y={rowHeight + (rowHeight * i)} rx="0" ry="0" width={width} height="1" />
        </Fragment>
      ))}
    </ContentLoader>
  );
};

ContentPlaceholder.propTypes = {
  rowsCount: T.number,
  columnsCount: T.number,
  width: T.number,
};

ContentPlaceholder.defaultProps = {
  width: 1000,
  columnsCount: 5,
  rowsCount: 10,
};

export default ContentPlaceholder;
