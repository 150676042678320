import React from 'react';
import T from 'prop-types';
// components
import UserAvatar from 'components/UserAvatar';
import Preloader from 'components/Preloader';
import VerticalAligner from 'components/VerticalAligner';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import ProfilePlaceholder from '../ProfilePlaceholder';
import { ThreeDotsBtn } from 'components/Button';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// utils
import cn from 'classnames';
// styles
import './UserProfileHeader.scss';

const UserProfileHeader = ({
  user,
  isLoading,
  className,
  isSticky,
  dropdownOptions,
  showStatus,
  actualUserId,
  children,
  isEditable,
  onEditClick,
  uploadImage,
}) => {
  const { openDropdown } = useDropdownActions();

  return (
    <div className={cn('UserProfileHeader', className, { isSticky })}>
      <Preloader isActive={isLoading} />

      <ProfilePlaceholder isActive={isLoading && (user.id !== actualUserId)}>
        <div className="user-info-block">
          <UserAvatar
            uploadImage={uploadImage}
            onEditClick={onEditClick}
            isEditable={isEditable}
            user={user}
            size="80"
          />
          <div className="user-information">
            <VerticalAligner className="user-info-wrapper">
              <div className="user-name lighter inline-block">{user.full_name}</div>
              {showStatus && user.status && <StatusBadge status={user.status} />}
            </VerticalAligner>
            <div>{user.email}</div>
          </div>
        </div>
      </ProfilePlaceholder>

      <div className="user-actions">
        {children}
        {!!dropdownOptions.filter(({ hide }) => !hide).length &&
          <ThreeDotsBtn dataName="user-icon-btn" onClick={(e) => openDropdown(e, dropdownOptions)} className="ml-10" />}
      </div>
    </div>
  );
};

UserProfileHeader.propTypes = {
  user: T.object,
  className: T.string,
  isSticky: T.bool,
  isLoading: T.bool,
  children: T.any,
  dropdownOptions: T.array,
  showStatus: T.bool,
  actualUserId: T.number,
  isEditable: T.bool,
  uploadImage: T.func,
  onEditClick: T.func,
};

UserProfileHeader.defaultProps = {
  className: '',
  dropdownOptions: [],
  showStatus: true,
  isEditable: false,
};

export default UserProfileHeader;
