import React, { useState } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import PickerRow from '../../PickerRow';
import SectionTargetSelector from './SectionTargetSelector';
import { FormattedMessage } from 'react-intl';

const MultiItemsSelector = ({
  onBack,
  onSave,
  searchEntities,
  initialSelectedItems = [],
  title,
  nameKey = 'name'
}) => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  const handleSetItem = (item) => {
    setSelectedItems((prevItems) => {
      return prevItems.find(({ id }) => id === item.id)
        ? prevItems.filter(({ id }) => id !== item.id)
        : prevItems.concat(item);
    });
  };

  return (
    <SectionTargetSelector
      title={title}
      searchEntities={searchEntities}
      onBack={onBack}
      headerRightContent={(
        <Button disabled={!selectedItems.length} primary small onClick={() => onSave(selectedItems)}>
          <FormattedMessage id="general.button.done" />
        </Button>
      )}
    >
      {(resources) => resources.map((item) => (
        <PickerRow
          key={item.id}
          isSelected={!!selectedItems.find(({ id }) => id === item.id)}
          onClick={() => handleSetItem(item)}
          actionMode="checkbox"
        >
          {item[nameKey]}
        </PickerRow>
      ))}
    </SectionTargetSelector>
  );
};

MultiItemsSelector.propTypes = {
  initialSelectedItems: T.array,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
  searchEntities: T.func.isRequired,
  title: T.oneOfType([T.string, T.node]).isRequired,
  nameKey: T.string,
};

export default MultiItemsSelector;
