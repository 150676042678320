import { fetchFromAPI } from 'utils/api';

export const createDirectPost = (resource) => {
  return fetchFromAPI('/direct_posts', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateDirectPost = (activityId, resource) => {
  return fetchFromAPI(`/direct_posts/${activityId}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteDirectPost = (activityId) => {
  return fetchFromAPI(`/direct_posts/${activityId}`, { method: 'delete' })
    .catch((response) => {
      throw response;
    });
};

export const getActivity = (id) => {
  return fetchFromAPI(`/activities/${id}`);
};

export const fetchActivityUser = (activityId, userId) => {
  return fetchFromAPI(`/activities/${activityId}/users`, { params: { id: userId } })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const fetchActivityFarm = (activityId, farmId) => {
  return fetchFromAPI(`/activities/${activityId}/farms`, { params: { id: farmId } })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const fetchActivityCompany = (activityId, companyId) => {
  return fetchFromAPI(`/activities/${activityId}/companies`, { params: { id: companyId } })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const fetchActivityAsset = (activityId, params) => {
  return fetchFromAPI(`/activities/${activityId}/chunk/asset`, { params })
    .then((response) => response.resource);
};

export const deleteActivityAsset = (activityId, assetId) => {
  return fetchFromAPI(`/activities/${activityId}/assets/${assetId}`, { method: 'delete' });
};

export const createSuggestionFeedback = (activityId, resource) => {
  return fetchFromAPI(`/activities/${activityId}/ai_suggestion_feedbacks`, {
    method: 'post',
    body: JSON.stringify({ resource })
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateSuggestionFeedback = (activityId, feedbackId, resource) => {
  return fetchFromAPI(`/activities/${activityId}/ai_suggestion_feedbacks/${feedbackId}`, {
    method: 'put',
    body: JSON.stringify({ resource })
  })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const fetchActivities = (params) => {
  return fetchFromAPI('/activities', { params })
    .catch((response) => {
      throw response;
    });
};

export const searchActivities = (params) => {
  return fetchFromAPI('/activities/search', { params });
};

export const fetchFilteredActivities = (params, filter) => {
  return fetchFromAPI('/activities/filter', { method: 'POST', params, body: JSON.stringify({ filter }) })
    .catch((response) => {
      throw response;
    });
};

export const createActivityComment = (activityId, resource) => {
  return fetchFromAPI(`/activities/${activityId}/comments`, {
    method: 'post',
    body: JSON.stringify({ resource }),
  });
};

export const updateActivityComment = (activityId, commentId, resource) => {
  return fetchFromAPI(`/activities/${activityId}/comments/${commentId}`, {
    method: 'put',
    body: JSON.stringify({ resource }),
  });
};

export const markActivityAsSeen = (activityId) => {
  return fetchFromAPI(`/activities/${activityId}/views`, { method: 'post' });
};

export const flagActivity = (activityId) => {
  return fetchFromAPI(`/activities/${activityId}/flags`, { method: 'post' });
};

export const unFlagActivity = (activityId) => {
  return fetchFromAPI(`/activities/${activityId}/flags`, { method: 'delete' });
};

export const deleteActivityComment = (activityId, commentId) => {
  return fetchFromAPI(`/activities/${activityId}/comments/${commentId}`, { method: 'delete' });
};

export const getUnseenActivitiesStats = () => {
  return fetchFromAPI('/activities/unviewed_activities_stats');
};

export const getFlaggedActivitiesCount = () => {
  return fetchFromAPI('/activities/flagged_activities_count');
};
