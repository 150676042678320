import React from 'react';
import T from 'prop-types';
// styles
import cn from 'classnames';
import './ConditionRadioButtons.scss';
// translate
import { FormattedMessage } from 'react-intl';

const conditions = [
  { condition: 'good', iconClass: 'fa fa-smile' },
  { condition: 'avg', iconClass: 'fa fa-meh' },
  { condition: 'poor', iconClass: 'fa fa-frown' },
];

const ConditionRadioButtons = ({ value, onChange, className = '' }) => (
  <div className={cn('ConditionRadioButtons', className)}>
    {conditions.map(({ condition, iconClass }) => (
      <div
        className={cn('condition-type', `${condition}-condition`, { 'active': value === condition })}
        onClick={() => onChange(condition)}
        key={condition}
      >
        <i className={cn(iconClass, `${condition}-condition`)} />
        <FormattedMessage id={`general.status.${condition}`} />
      </div>
    ))}
  </div>
);

ConditionRadioButtons.propTypes = {
  value: T.string,
  onChange: T.func.isRequired,
  className: T.string,
};

export default ConditionRadioButtons;
