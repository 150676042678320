import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './TagFields.module.scss';
// assets
import magicLoopSvg from '../../../public/images/magic.svg';

const cn = classnames.bind(styles);

function removeTag(tagItem, fields, declinePredictedTag) {
  if (!tagItem.id && tagItem.prediction_image_tag_id) {
    declinePredictedTag(tagItem.prediction_image_tag_id);
  }
  if (tagItem.id) {
    fields.push({ ...tagItem, _destroy: true });
  }
}

const TagFields = ({
  fields,
  currentDiagnosis,
  declinePredictedTag,
  hasEmptyDiagnosisWithTag,
  addTag,
  setHover,
  isAssetAnImage,
}) => (
  <>
    {fields.map((field, index) => {
      const tagItem = fields.get(index);
      if (tagItem._destroy) return null;

      return (
        <div
          key={index}
          className={cn('tag')}
          onMouseEnter={() => setHover(tagItem.id || tagItem.prediction_image_tag_id || tagItem.hash)}
          onMouseLeave={() => setHover(null)}
        >
          {(tagItem.prediction_image_tag_id || tagItem.ai_suggestion)
            ? <img src={magicLoopSvg} alt="" className={cn('magic-icon')} />
            : <i className={cn('fa fa-label', 'tag-icon')} />}
          <FormattedMessage id="component.diagnoseForm.tagName" values={{ name: tagItem.asset_name }}>
            {(text) => <span className={cn('tag-name')}>{text}</span>}
          </FormattedMessage>
          <span
            className={cn('remove-tag-btn')}
            onClick={() => {
              fields.remove(index);
              removeTag(tagItem, fields, declinePredictedTag);
            }}
          >
            <i className="fa fa-times" />
          </span>
        </div>
      );
    })}
    {!hasEmptyDiagnosisWithTag && isAssetAnImage && (
      <div className={cn('tag-hint')} onClick={() => addTag(fields, currentDiagnosis.diagnose)}>
        <i className={cn('fa fa-label', 'tag-icon')} />
        <FormattedMessage id={`component.diagnoseForm.${!fields.length ? 'tagImage' : 'tagAnotherArea'}`} />
      </div>
    )}
  </>
);

TagFields.propTypes = {
  fields: T.object.isRequired,
  currentDiagnosis: T.object,
  hasEmptyDiagnosisWithTag: T.bool.isRequired,
  declinePredictedTag: T.func.isRequired,
  setHover: T.func.isRequired,
  addTag: T.func.isRequired,
  isAssetAnImage: T.bool.isRequired,
};

export default TagFields;
