import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { bytesToSize } from 'utils';
// styles
import './FilePreview.scss';

export const FilePreview = ({ asset }) => {
  const targetLink = asset.url?.large || asset.url?.original || asset.url?.origin;

  return (
    <div className="FilePreview">
      <i className="fa fa-text-document" />
      <span className="file-name">{asset.filename}</span>&nbsp;|&nbsp;
      <a href={targetLink} target="_blank" rel="noopener noreferrer" className="download-link">
        <FormattedMessage id="general.button.download" />&nbsp;({bytesToSize(asset.media_size)})
      </a>
    </div>
  );
};

FilePreview.propTypes = {
  asset: T.object.isRequired,
};

export default FilePreview;
