import React from 'react';
import T from 'prop-types';
// components
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import CompanyMiniProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyMiniProfileHeader';
import Subnavigation from 'components/Subnavigation';
import Button from 'components/Button';
import ProgressBox from 'components/Admin/ProgressBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Panel from 'components/Panel';
import ProgressChecklist from 'components/ProgressChecklist/ProgressChecklist';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './CompanyProfileBox.module.scss';
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(styles);

const CompanyProfileBox = ({
  companyId,
  company,
  isLoading,
  steps,
  navLinks,
  onCompanyDelete,
  progressData,
  children,
}) => (
  <div className={cn('company-profile', 'clearfix')}>
    <CompanyProfileHeader
      actualCompanyId={Number(companyId)}
      company={company}
      isLoading={isLoading}
      className="show-for-large"
      dropdownOptions={[
        { label: <FormattedMessage id="general.deleteCompany" />, onClick: onCompanyDelete },
      ]}
    >
      <ProgressChecklist steps={steps} />
    </CompanyProfileHeader>

    <Subnavigation
      className="small-12 column show-for-large"
      links={navLinks}
      isSticky
      stickyContent={
        <CompanyMiniProfileHeader company={company} />
      }
    />

    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={company.name} className="hide-for-large">
          <StatusBadge status={company.status} />
          <Button default wide onClick={onCompanyDelete}>
            <FormattedMessage id="general.deleteCompany" />
          </Button>
        </Panel.Heading>

        <Panel.Body noPadding className={cn('mobile-body')}>
          <Subnavigation links={navLinks} className="hide-for-large" inner />
          <div className={cn('mobile-body-box')}>
            <ProgressBox className={cn('hide-for-large', 'progress-box')} {...progressData} />
            {children}
          </div>
        </Panel.Body>
      </Panel>
    </section>
  </div>
);

CompanyProfileBox.propTypes = {
  companyId: T.oneOfType([T.string, T.number]),
  company: T.object.isRequired,
  steps: T.array.isRequired,
  navLinks: T.array.isRequired,
  onCompanyDelete: T.func.isRequired,
  progressData: T.object.isRequired,
  isLoading: T.bool.isRequired,
  children: T.object,
};

export default CompanyProfileBox;
