import React from 'react';
import T from 'prop-types';
// components
import CircleCheckbox from 'components/CircleCheckbox';
// utils
import cn from 'classnames';
// styles
import styles from '../ConditionSelector.module.scss';

const ConditionCheckBox = ({
  conditionValue,
  onConditionValueChange,
  option,
}) => {
  const isOptionChecked = option.value === conditionValue;
  return (
    <CircleCheckbox
      className={styles['checkbox-item']}
      type="radio"
      name={option.value}
      checked={isOptionChecked}
      onChange={onConditionValueChange}
      label={(
        <span className={cn(styles['checkbox-label'], { [styles['is-checked']]: isOptionChecked })}>
          {option.label}
        </span>
      )}
    />
  );
};

ConditionCheckBox.propTypes = {
  conditionValue: T.string,
  option: T.object.isRequired,
  onConditionValueChange: T.func.isRequired,
};

export default ConditionCheckBox;
