import React from 'react';
import T from 'prop-types';
// recompose
import { withProps } from 'recompose';
// components
import Waypoint from 'react-waypoint';
import Preloader from 'components/Preloader';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// utils
import cn from 'classnames';
// styles
import './MobileList.scss';

const MobileList = ({
  getDropdownOptions,
  renderRow,
  data,
  loadMoreData,
  total,
  isLoading,
  className,
}) => {
  const { openDropdown } = useDropdownActions();
  const hasMoreItems = data.length < total;
  return (
    <div className={cn('MobileList', className)}>
      <div className="mobile-table">
        {data.map((item) => (
          <div key={item.id} className="MobileListRow">

            {renderRow(item)}

            <i
              data-name={`${item.id}`}
              className="fa fa-dots-three-horizontal dropdown-icon"
              onClick={(e) => {
                openDropdown(e, getDropdownOptions(item));
              }}
            />
          </div>
        ))}
      </div>

      {hasMoreItems && !isLoading &&
        <Waypoint onEnter={loadMoreData} />}

      <Preloader isActive={isLoading} />

    </div>
  );
};

MobileList.propTypes = {
  className: T.string,
  getDropdownOptions: T.func.isRequired,
  renderRow: T.func.isRequired,
  data: T.array,
  total: T.number,
  isLoading: T.bool,
  // recompose
  loadMoreData: T.func.isRequired,
};

MobileList.defaultProps = {
  data: [],
};

export default withProps(({ fetchData, params }) => {
  return {
    loadMoreData: () => {
      fetchData({ ...params, page: params.page + 1 }, true);
    },
  };
})(MobileList);
