import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import { UnmountClosed } from 'react-collapse';
import QuestionViewRow from '../QuestionViewRow';
import CategoryCounterStatus from '../CategoryCounterStatus';
// utils
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames/bind';
// styles
import styles from './CategoryViewRow.module.scss';

const cn = classnames.bind(styles);

const countAnsweredQuestionsByType = (questions, answerValue) => questions
  .map(({ answer }) => (answer?.value === answerValue ? 1 : 0))
  .reduce((prev, next) => prev + next, 0);

const CategoryViewRow = ({ category, categoryIndex }, { router }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { location: { query: { mentionCommentId } } } = router;

  useEffect(() => {
    if (mentionCommentId) {
      const categoryComments = category.svr_questions.map(({ answer }) => answer.comments).flat();
      const isCategoryInitialyOpened = categoryComments.some(({ id }) => id === +mentionCommentId);
      setIsOpened(isCategoryInitialyOpened);
    }
  }, []);

  const onCollapseToggle = () => {
    setIsOpened(!isOpened);
  };

  const calculateAnswredQuestions = () => {
    return category.svr_questions.filter(({ answer }) => answer?.value).length;
  };

  const passedQuestionsCount = countAnsweredQuestionsByType(category.svr_questions, 'pass');
  const failedQuestionsCount = countAnsweredQuestionsByType(category.svr_questions, 'fail');
  const naQuestionsCount = countAnsweredQuestionsByType(category.svr_questions, 'not_available');
  const isCategoryFlagged = category.svr_questions.some(({ answer }) => answer?.is_flagged);

  return (
    <div className={cn('category-row', { flagged: isCategoryFlagged })}>
      {/* header */}
      <div className={cn('category-header')} onClick={onCollapseToggle}>
        <div className={cn('category-info-block')}>
          <span className={cn('semibold', 'category-name')}>
            {category.name_key ? <FormattedMessage id={category.name_key} /> : category.name}
          </span>
              &nbsp;
          <span>
            {calculateAnswredQuestions()}/{category.svr_questions.length}
          </span>
        </div>
        <div className={cn('category-counters')}>
          <CategoryCounterStatus count={passedQuestionsCount} type="pass" />
          <CategoryCounterStatus count={failedQuestionsCount} type="fail" />
          <CategoryCounterStatus count={naQuestionsCount} type="not_available" />
          <i className={cn('fa fa-angle-right-btb', 'collapse-icon', { opened: isOpened })} />
        </div>
      </div>
      {/* content */}
      <UnmountClosed hasNestedCollapse isOpened={isOpened}>
        {category.svr_questions.map((question, idx) => (
          <QuestionViewRow categoryIndex={categoryIndex} questionIndex={idx} question={question} key={idx} />
        ))}
      </UnmountClosed>
    </div>
  );
};

CategoryViewRow.contextTypes = {
  router: T.object.isRequired,
};

CategoryViewRow.propTypes = {
  category: T.object.isRequired,
  categoryIndex: T.number.isRequired,
};

export default CategoryViewRow;
