import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openViewer } from 'reducers/mediaViewer';
import { fetchAssets, resetData } from 'reducers/mediaGallery';
// components
import { FormattedMessage } from 'react-intl';
import Waypoint from 'react-waypoint';
import AssetBox from 'components/AssetBox/AssetBox';
import Preloader from 'components/Preloader';
// utils
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './BarnsheetsMedia.scss';

class BarnsheetsMedia extends Component {

  componentDidMount() {
    const { fetchAssets, reqParams } = this.props;
    const params = { pig_group_id: this.props.params.id || null, farm_id: this.props.params.farmId || null };
    fetchAssets({ ...reqParams, ...params })
      .catch((response) => {
        toastResponseErrors(response);
      });
  }

  componentWillUnmount() {
    this.props.resetData();
  }

  loadMoreAssets = () => {
    const { fetchAssets, reqParams } = this.props;
    const params = { pig_group_id: this.props.params.id || null, farm_id: this.props.params.farmId || null };
    fetchAssets({ ...reqParams, page: reqParams.page + 1, ...params })
      .catch((response) => {
        toastResponseErrors(response);
      });
  };

  getAssetsData = (resources) => {
    const allAssets = [];
    resources.forEach((item) => allAssets.push(...item.assets));

    return allAssets;
  };

  render() {
    const { openViewer, resources, isLoading, meta: { total } } = this.props;
    const hasMoreItems = resources.length < total;
    const isLoadingMore = !!resources.length && isLoading;
    const assetsData = this.getAssetsData(resources);

    return (
      <div className="BarnsheetsMedia">
        <Preloader isActive={isLoading} />

        <div className="group-images-layout">
          <div className="group-images">
            {resources.map(({ id, pig_group, assets, ...other }, index) => (
              <AssetBox
                farm={pig_group.farm}
                pigGroup={pig_group}
                key={index}
                openViewer={openViewer}
                assetsData={assetsData}
                assets={assets}
                type="barnsheetsGallery"
                {...other}
              />
            ))}
            {!resources.length && !isLoading && (
              <div className="placeholder">
                <i className="fa fa-photo" />
                <FormattedMessage id="component.nothingBox.noMedia" tagName="p" />
              </div>
            )}

            <div className={cn('waypoint', { hidden: !hasMoreItems })}>
              <Preloader isActive={isLoadingMore} />
              {hasMoreItems &&
                <Waypoint onEnter={this.loadMoreAssets} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BarnsheetsMedia.propTypes = {
  params: T.object.isRequired,
  openViewer: T.func.isRequired,
  resources: T.array.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchAssets: T.func.isRequired,
  resetData: T.func.isRequired,
};

export default connect(
  (state) => ({
    resources: state.mediaGallery.resources,
    isLoading: state.mediaGallery.isLoading,
    reqParams: state.mediaGallery.params,
    meta: state.mediaGallery.meta,
  }), { openViewer, fetchAssets, resetData }
)(BarnsheetsMedia);
