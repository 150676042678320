import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { increaseUnreadNotificationsCount } from 'reducers/sidebar';
import { fetchDataWithMerge, dismissNotification, fetchItemAfterDismiss, setReadAction } from 'reducers/notifications';
// components
import Waypoint from 'react-waypoint';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import AllCaughtUp from 'containers/Farmfeed/components/AllCaughtUp';
import NotificationItem from 'components/Notifications/NotificationItem';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import moment from 'moment';
import throttle from 'lodash.throttle';
// styles
import styles from './NotificationsList.module.scss';

class NotificationsList extends Component {

  constructor(props) {
    super(props);
    this.throttleCheck = throttle(this.sendReadIds, 2000, { leading: false });
    this.readIds = new Set();
    this.state = { isSettingRead: false };
  }

  componentDidMount() {
    const { isLoaded, fetchData, currentUser: { id } } = this.props;
    if (!isLoaded) fetchData(id);
  }

  handleDismissAction = (id, isRead) => {
    const { dismissNotification, currentUser, isLoading, fetchItemAfterDismiss, resources,
      increaseUnreadNotificationsCount } = this.props;
    if (isLoading) return false;
    return dismissNotification(currentUser.id, id)
      .then(() => {
        fetchItemAfterDismiss(currentUser.id, resources.length - 1);
        if (!isRead) increaseUnreadNotificationsCount(-1);
      })
      .catch(toastResponseErrors);
  };

  sendReadIds = () => {
    const { currentUser: { id }, increaseUnreadNotificationsCount, setReadAction } = this.props;
    this.setState({ isSettingRead: true });
    setReadAction(id, this.readIds)
      .then(() => {
        increaseUnreadNotificationsCount(-this.readIds.size);
        this.readIds.clear();
        this.setState({ isSettingRead: false });
      })
      .catch((errors) => {
        this.setState({ isSettingRead: false });
        toastResponseErrors(errors);
      });
  };

  markAsRead = (id) => {
    this.readIds.add(id);
    this.throttleCheck();
  };

  render() {
    const { fetchData, reqParams, isLoaded, currentUser, isLoading, resources, meta: { total } } = this.props;
    const { isSettingRead } = this.state;
    const hasMoreItems = total > resources.length;

    return (
      <div className={styles['notifications-list']}>
        {resources.map((item, index) => {
          const isCurrentItemToday = moment(item.created_at).isSame(new Date(), 'day');
          const isFirstItem = index === 0;
          const hasTodayDivider = isFirstItem && isCurrentItemToday;
          const hasEarlierDivider = isFirstItem
            ? !isCurrentItemToday
            : !isCurrentItemToday && moment(resources[index - 1].created_at).isSame(new Date(), 'day');
          return (
            <NotificationItem
              isDropdownView
              key={item.id}
              hasEarlierDivider={hasEarlierDivider}
              hasTodayDivider={hasTodayDivider}
              currentUser={currentUser}
              onMarkAsRead={this.markAsRead}
              onDismiss={this.handleDismissAction}
              isRead={item.read}
              item={item}
            />
          );
        })}
        {isLoading &&
          <Preloader isActive={isLoading} />}
        {!isLoading && hasMoreItems && isLoaded && !isSettingRead &&
          <Waypoint onEnter={() => fetchData(currentUser.id, { ...reqParams, page: reqParams.page + 1 })} />}
        {!isLoading && !resources.length && (
          <AllCaughtUp>
            <FormattedMessage id="general.haveSeanAll" />
          </AllCaughtUp>
        )}
      </div>
    );
  }
}

NotificationsList.propTypes = {
  resources: T.array.isRequired,
  isLoading: T.bool.isRequired,
  isLoaded: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  currentUser: T.object.isRequired,
  fetchData: T.func.isRequired,
  dismissNotification: T.func.isRequired,
  fetchItemAfterDismiss: T.func.isRequired,
  increaseUnreadNotificationsCount: T.func.isRequired,
  setReadAction: T.func.isRequired,
};

export default connect(
  (state) => ({
    resources: state.notifications.resources,
    isLoading: state.notifications.isLoading,
    isLoaded: state.notifications.isLoaded,
    reqParams: state.notifications.params,
    meta: state.notifications.meta,
  }), {
    fetchData: fetchDataWithMerge,
    dismissNotification,
    fetchItemAfterDismiss,
    setReadAction,
    increaseUnreadNotificationsCount,
  },
)(NotificationsList);
