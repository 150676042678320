import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import Subnavigation from 'components/Subnavigation';

// styles
import OfflineScreen from '../../../../../../components/OfflineScreen';
import DataTableImports from './DataTableImports/DataTableImports';

const ImportsTable = ({ isOnline, subNavLinks, selectedArchiveImports }) => {

  return (
    <div className="BarnsheetsHome clearfix">
      <Subnavigation isSticky links={subNavLinks} className="small-12 column" />
      {isOnline ? (
        <div className="small-12 column">
          <DataTableImports
            getDataUrl={`/admin/csv_imports/${selectedArchiveImports.id}`}
            getNameStore={`${selectedArchiveImports.type}ImportResult`}
            selectedArchiveImports={selectedArchiveImports}
          />
        </div>
      ) : (
        <OfflineScreen />
      )}
    </div>
  );
};

ImportsTable.propTypes = {
  isOnline: T.bool,
  subNavLinks: T.array,
  selectedArchiveImports: T.object,
};

export default connect((state) => ({
  isOnline: state.network.isOnline,
  selectedArchiveImports: state.importCSV.selectedArchiveImports,
}))(ImportsTable);
