import React from 'react';
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import groupBy from 'lodash.groupby';

export function getFilterOptions(generalPath, isAdmin) {
  const historyItem = { label: <FormattedMessage id="general.treatmentHistory" />, value: `${generalPath}/history` };
  return isAdmin
    ? [
      historyItem,
      { label: <FormattedMessage id="general.pageTitle.antibioticTracking" />, value: `${generalPath}/antibiotic` },
    ]
    : [historyItem];
}

export function prepareDiagnosesToChart(chartData) {
  const tempDiagnoses = [];
  const diagnoses = [];
  chartData.forEach(({ created_on, daily_checkup_diagnoses, pig_group }) => {
    daily_checkup_diagnoses.forEach((obj) => {
      tempDiagnoses.push({ ...obj, pig_group, date: moment(created_on).format('YYYY-MM-DD') });
    });
  });
  const diagnosesByDate = groupBy(tempDiagnoses, 'date');
  Object.keys(diagnosesByDate).forEach((key) => {
    diagnoses.push({
      start: key,
      end: key,
      diagnosesNames: diagnosesByDate[key],
    });
  });
  return diagnoses;
}

export function getTreatmentColor(item) {
  if (item.withdrawal) return '#e8e8e8';
  if (item.vaccine) return '#00aeff';
  if (item.route?.includes('topical')) return '#ffb200';
  return item.route?.includes('oral') ? '#0069a1' : '#ff5e31';
}

export function preparePeriodDates(periodName) {
  const data = periodName.split('_');
  const isCustom = periodName === 'custom';
  return {
    period_name: periodName,
    date_start: !isCustom ? moment().subtract(data[0], data[1]) : null,
    date_end: !isCustom ? moment() : null,
  };
}

export const treatmentHistoryOptions = [
  { name: <FormattedMessage id="general.timeOptions.24h" />, value: '24_hours' },
  { name: <FormattedMessage id="general.timeOptions.7days" />, value: '7_days' },
  { name: <FormattedMessage id="general.timeOptions.14days" />, value: '14_days' },
  { name: <FormattedMessage id="general.timeOptions.28days" />, value: '28_days' },
  { name: <FormattedMessage id="general.timeOptions.1month" />, value: '1_months' },
  { name: <FormattedMessage id="general.timeOptions.3months" />, value: '3_months' },
  { name: <FormattedMessage id="general.timeOptions.6months" />, value: '6_months' },
  { name: <FormattedMessage id="component.statsOptions.custom" />, value: 'custom' },
];

export const titlesByPeriod = {
  '24_hours': <FormattedMessage id="general.timeOptions.24h" />,
  '7_days': <FormattedMessage id="general.timeOptions.7days" />,
  '14_days': <FormattedMessage id="general.timeOptions.14days" />,
  '28_days': <FormattedMessage id="general.timeOptions.28days" />,
  '1_months': <FormattedMessage id="general.timeOptions.1month" />,
  '3_months': <FormattedMessage id="general.timeOptions.3months" />,
  '6_months': <FormattedMessage id="general.timeOptions.6months" />,
  'custom': <FormattedMessage id="general.customPeriod" />,
};
