import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// redux
import { connect } from 'react-redux';
import { fetchData, resetResources, setDataItem } from 'reducers/dataTable';
import { fetchCompanyProfile } from 'reducers/profiles';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import CompanyUserRolesManageTable from 'components/Admin/Companies/CompanyUserRolesManage/CompanyUserRolesManageTable';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import RolesManageFixedHeader from 'components/Admin/RolesManageFixedHeader/RolesManageFixedHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import PaginationWrapper from 'components/PaginationWrapper';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// requests
import { deleteTruckingRole, setTruckingRole } from 'endpoints/admin/truckingCompanies';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
// styles
import styles from '../../RolesManage.module.scss';

const cn = classnames.bind(styles);

const extractTruckingRoles = (users) => users.map((user) => ({
  ...user,
  ...user.trucking_roles.reduce((prev, next) => ({ ...prev, [next.name]: next.id }), {}),
}));

const headerColumns = [
  { flex: '2 0 120px', name: <FormattedMessage id="component.dataTable.headers.userName" />, sortField: 'first_name' },
  { flex: '1 1 120px', name: <FormattedMessage id="general.role.truckingAdmin" />, sortField: 'trucking_admin'  },
  { flex: '1 1 120px', name: <FormattedMessage id="general.role.driver" />, sortField: 'driver' },
];

class TruckingUsersRolesManage extends Component {

  componentDidMount() {
    const { fetchData, reqParams, fetchCompanyProfile, params: { id } } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
    fetchCompanyProfile(`/admin/trucking_companies/${id}`).catch(toastResponseErrors);
  }

  componentWillUnmount() {
    this.props.resetResources();
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  handleCheckBoxChanged = (user, role_name, checked) => {
    const { params: { id: companyId } } = this.props;
    const paramsData = { role_name, role_id: user[role_name], id: user.id, company_id: companyId };
    if (checked) return this.createCompanyUserRole(paramsData, user);
    return this.removeCompanyUserRole(paramsData);
  };

  createCompanyUserRole(paramsData, { id }) {
    const { params: { id: companyId }, setDataItem } = this.props;
    return setTruckingRole(companyId, id, paramsData)
      .then(setDataItem)
      .catch(toastResponseErrors);
  }

  removeCompanyUserRole({ role_id, id, company_id }) {
    const { setDataItem } = this.props;
    return deleteTruckingRole(company_id, id, role_id)
      .then(setDataItem)
      .catch(toastResponseErrors);
  }

  render() {
    const { resources, profileCompany, isLoading, meta, user, reqParams: { page, per_page, sort },
      params: { id }, onPageChange, onPerPageChange, isCompanyLoading } = this.props;
    const { roles_map: { admin } } = user;


    const rolesColumns = [
      {
        name: 'trucking_admin',
        labelKey: 'truckingAdmin',
        fieldPath: 'trucking_admin'
      },
      {
        name: 'driver',
        labelKey: 'driver',
        fieldPath: 'driver'
      },
    ];

    const userRolesLink = `/admin/trucking-companies/${id}/user-roles`;

    const remapedResourses = extractTruckingRoles(resources);

    return (
      <div className={cn('roles-manage')}>
        <CompanyProfileHeader
          company={profileCompany}
          isLoading={isCompanyLoading}
          actualCompanyId={Number(id)}
          avatarType="trucking-company"
          className="show-for-large"
          hasLocation
          isSticky
        >
          <Link to={userRolesLink} className="button primary show-for-large wider">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </CompanyProfileHeader>

        <RolesManageFixedHeader
          className="show-for-large"
          panelTitle={<FormattedMessage id="general.userRoles" />}
          isEmptyTable={!remapedResourses.length}
          sort={sort}
          headerColumns={headerColumns}
        />

        <section className={cn('roles-manage-section')}>
          <div className="small-12 column">
            <Panel>
              <Panel.Heading title={<FormattedMessage id="general.userRoles" />} className="hide-for-large" />

              <Panel.Body className={cn('roles-manage-body')}>

                <Preloader isActive={isLoading} />

                <PaginationWrapper
                  onlyBottom
                  totalItems={meta.total}
                  currentPage={page}
                  onPageChange={onPageChange}
                  perPage={per_page}
                  onPerPageChange={onPerPageChange}
                >
                  <CompanyUserRolesManageTable
                    data={remapedResourses}
                    company={profileCompany}
                    handleCheckBoxChanged={this.handleCheckBoxChanged}
                    handleRadioChanged={this.handleRadioChanged}
                    isAdmin={admin}
                    isLoading={isLoading}
                    user={user}
                    rolesColumns={rolesColumns}
                  />
                </PaginationWrapper>
              </Panel.Body>
            </Panel>
          </div>
        </section>

        <StickyFooter className="hide-for-large">
          <Link to={userRolesLink} className="button primary big-button">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </StickyFooter>
      </div>
    );
  }
}

TruckingUsersRolesManage.propTypes = {
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  resetResources: T.func.isRequired,
  fetchCompanyProfile: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  profileCompany: T.object.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  user: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isCompanyLoading: T.bool.isRequired,
  params: T.object
};

const enhance = compose(
  connect(
    (state) => ({
      profileCompany: state.profiles.company.data,
      isCompanyLoading: state.profiles.company.isLoading,
      user: state.auth.user,
      resources: state.dataTable.truckingRolesManage.resources,
      reqParams: state.dataTable.truckingRolesManage.params,
      meta: state.dataTable.truckingRolesManage.meta,
      isLoading: state.dataTable.truckingRolesManage.isLoading,
    }), (dispatch, { params: { id } }) => ({
      fetchData: (query) => dispatch(fetchData(`/admin/trucking_companies/${id}/users`, 'truckingRolesManage', query)),
      setDataItem: (data) => dispatch(setDataItem(data, 'truckingRolesManage')),
      resetResources: () => dispatch(resetResources('truckingRolesManage')),
      fetchCompanyProfile: (path) => dispatch(fetchCompanyProfile(path)),
    })
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(TruckingUsersRolesManage);
