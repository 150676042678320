import React from 'react';
import T from 'prop-types';
// components
import FormattedTemperature from 'components/FormattedTemperature';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import inRange from 'lodash.inrange';
// styles
import styles from './RecommendedSetPoint.module.scss';

const cn = classnames.bind(styles);

const RecommendedSetPoint = ({ className, value }) => (
  <div
    className={cn(className, 'recommended-set-point', {
      'grayscale': value < 50,
      'darker-blue': inRange(value, 50, 59.99),
      'lighter-blue': inRange(value, 60, 69.99),
      'yellow': inRange(value, 70, 79.99),
      'pink': value >= 80,
    })}
  >
    <div className={cn('label')}>
      <FormattedMessage id="general.recommendedSetPoint" />
    </div>
    <div className={cn('value-block')}>
      <span className={cn('value')}>
        {value ? <FormattedTemperature value={value} /> : <FormattedMessage id="general.notAvail" />}
      </span>
    </div>
  </div>
);

RecommendedSetPoint.propTypes = {
  value: T.number,
  className: T.string,
};

RecommendedSetPoint.contextTypes = {
  router: T.object.isRequired,
};

export default RecommendedSetPoint;
