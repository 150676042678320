import { API_URL } from 'constants.js';
import { getAuthData, setAuthData, clearAuthData } from './authData';
import { setLSDeviceToken } from './localStorageHelper';
import map from 'lodash.map';
import isEmpty from 'lodash.isempty';
import { browserHistory } from 'react-router';
import { clearOfflineDB } from 'db/offline-db';

const jsonHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const formDataHeaders = {
  'Accept': '*/*',
};

const handleResponseError = (response) => Promise.reject(response.json());

const querify = (attrName, attrValue, prevObjectKeys = []) => {
  const key = isEmpty(prevObjectKeys)
    ? attrName
    : prevObjectKeys.map((objectKey, index) => {
      return index ? `[${objectKey}]` : objectKey;
    }).join('') + `[${attrName}]`;

  if (typeof attrValue === 'object' && !(attrValue instanceof Array)) {
    return map(attrValue, (value, name) => querify(name, value, prevObjectKeys.concat(attrName))).join('&');
  }

  if (attrValue instanceof Array) {
    return map(attrValue, (value) => (key + '[]=' + value)).join('&');
  }

  return key + '=' + attrValue;
};

const getQueryString = (params) => map(params, (value, key) => querify(key, value)).join('&');

const fetchFromAPI = (endpoint, {
  headers,
  authRequest,
  noAuthHeaders,
  blob,
  ...options
} = {}) => {
  let fetchUrl = `${API_URL}${endpoint}`;

  if (options.params) {
    fetchUrl += endpoint.indexOf('?') === -1 ? '?' : '&';
    fetchUrl += getQueryString(options.params);
    delete options.params; // eslint-disable-line no-param-reassign
  }

  const defaultHeaders =
    options.body instanceof FormData
      ? formDataHeaders
      : jsonHeaders;

  return fetch(fetchUrl, {
    headers: {
      ...defaultHeaders,
      ...getAuthData(noAuthHeaders),
      ...headers,
    },
    ...options,
  })
    .then((response) => {
      if (response.status === 401) {
        const isEmptyAuthData = Object.values(getAuthData()).every((val) => !val);

        if (!isEmptyAuthData) clearAuthData();
        clearOfflineDB();
        setLSDeviceToken('');
        browserHistory.push('/login');
      }

      if (!response.ok) {
        return handleResponseError(response);
      }

      if (authRequest) {
        const authData = {
          'uid': response.headers.get('uid'),
          'access-token': response.headers.get('access-token'),
          'client': response.headers.get('client'),
          'expiry': response.headers.get('expiry'),
        };
        setAuthData(authData);
      }

      if (blob) {
        return response.blob();
      }

      return response.json();
    })
    .catch((response) => {
      if (['AbortError', 'TypeError'].includes(response?.name)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          error: response.name + ': ' + response.message,
          isAbortError: response.name === 'AbortError',
          isTypeError: response.name === 'TypeError',
        });
      }
      // no response.then for DELETE requests
      return response.then?.((errors) => Promise.reject(errors));
    });
};

export {
  fetchFromAPI,
};
