import React from 'react';
import T from 'prop-types';
// components
import MessageBox from 'components/MessageBox';
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import { withdrawalParams } from 'utils';

function WithdrawalWarningBox({ treatmentProduct, checkup }) {
  const { value, period } = withdrawalParams(treatmentProduct);
  const date = (<strong>{moment(checkup.created_on).add(value, period).format('MMM DD, YYYY')}</strong>);

  return (
    <MessageBox type="warning">
      <FormattedMessage
        id="container.dailyCheckup.medications.withdrawalWarning"
        values={{ name: <strong>{treatmentProduct.name}</strong>, value, period: period.slice(0, -1), date }}
      />
    </MessageBox>
  );
}

WithdrawalWarningBox.propTypes = {
  treatmentProduct: T.object.isRequired,
  checkup: T.object.isRequired,
};

export default WithdrawalWarningBox;
