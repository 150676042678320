import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setFilesData, setImportAction } from 'reducers/importCSV';
// components
import { FormattedMessage } from 'react-intl';
import CSVUploader from 'components/CSVImport/CSVUploader';
import CSVImportPage from './CSVImportPage';
// utils
import Papa from 'papaparse';
import { getBackPathname } from 'utils/csvImportHelper';
// constants
import { oneObjectMode, multipleObjectsMode, importObjectsTypes } from 'constants/csvImport';

const CSVImportUpload = ({
  filesByTypeInStore,
  importActionByType,
  parsedFilesInStore,
  setFilesData,
  setImportAction,
  location,
}, { router }) => {
  const [{ filesByType, parsedFiles }, setState] = useState({
    filesByType: filesByTypeInStore,
    parsedFiles: parsedFilesInStore,
  });
  const { query } = location;
  const backPathName = getBackPathname(query?.from, query?.entityId);
  const selectedCards = importObjectsTypes.filter(({ type }) => (query?.cards || []).includes(type));

  const uploadNewFile = (data, itemType, selectedFile) => {
    setState((prevState) => ({
      ...prevState,
      filesByType: {
        ...prevState.filesByType,
        [itemType]: selectedFile,
      },
      parsedFiles: [
        ...prevState.parsedFiles,
        { rows: data, type: itemType },
      ],
    }));
  };

  const deleteUploadedFile = (fileType, fileId) => {
    setState((prevState) => ({
      ...prevState,
      filesByType: {
        ...prevState.filesByType,
        [fileType]: undefined,
      },
      parsedFiles: prevState.parsedFiles.filter((item) => item.type !== fileType && item.id !== fileId),
    }));
  };

  const fileSelectedHandler = (selectedFile, itemType) => {
    if (selectedFile) {
      const { file } = selectedFile;
      Papa.parse(file, {
        header: true,
        preview: 5,
        trimHeaders: true,
        skipEmptyLines: true,
        complete({ data }) {
          uploadNewFile(data, itemType, selectedFile);
        }
      });
    }
  };

  const onBackBtnClick = () => {
    setFilesData([], {});
    router.push({
      pathname: '/csv-import/select-mode',
      query: {
        from: query?.from,
        entityId: query?.entityId,
        mode: selectedCards.length > 1 ? multipleObjectsMode : oneObjectMode,
      },
    });
  };

  const onNextBtnClick = () => {
    setFilesData(parsedFiles, filesByType);
    router.push({
      pathname: '/csv-import/match-columns',
      query: { from: query.from, entityId: query?.entityId },
    });
  };

  const isNextDisabled = selectedCards.some((card) => !filesByType[card.type]);

  return (
    <CSVImportPage
      activeStep={1}
      onBackBtnClick={onBackBtnClick}
      onNextBtnClick={onNextBtnClick}
      isNextDisabled={isNextDisabled}
      closePathName={backPathName}
    >
      <div className="bodyCSV">
        <div className="wrapperInfoBodyCSV">
          <FormattedMessage id="general.csvImport.upload.title">
            {(title) => <div className="step-title">{title}</div>}
          </FormattedMessage>
          <FormattedMessage id="general.csvImport.upload.infoText">
            {(desc) => <div className="step-description">{desc}</div>}
          </FormattedMessage>
        </div>
        <div className="wrapperCardsCSV">
          <div className="uploadContainer">
            {selectedCards.map((item) => (
              <CSVUploader
                key={item.type}
                onSelectFile={fileSelectedHandler}
                uploadedFile={filesByType[item.type]}
                deleteUploadedFile={deleteUploadedFile}
                setImportAction={(isCreating) => setImportAction(isCreating, item.type)}
                importAction={importActionByType[item.type]}
                item={item}
              />
            ))}
          </div>
        </div>
      </div>
    </CSVImportPage>
  );
};

CSVImportUpload.contextTypes = {
  router: T.object.isRequired,
};

CSVImportUpload.propTypes = {
  setFilesData: T.func.isRequired,
  setImportAction: T.func.isRequired,
  location: T.object.isRequired,
  filesByTypeInStore: T.object.isRequired,
  importActionByType: T.object.isRequired,
  parsedFilesInStore: T.array.isRequired,
};

export default connect(
  (state) => ({
    filesByTypeInStore: state.importCSV.filesByType,
    parsedFilesInStore: state.importCSV.parsedFiles,
    importActionByType: state.importCSV.importActionByType,
  }), {
    setFilesData,
    setImportAction,
  }
)(CSVImportUpload);
