import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
import { isTablet, isMobile  } from 'react-device-detect';
// styles
import './ContentOverlay.scss';

const ContentOverlay = ({ visible, className, onClick }) => (
  <div
    className={cn('ContentOverlay', { [className]: !!className, isMobile: isMobile && !isTablet, visible })}
    onClick={onClick}
  />
);

ContentOverlay.defaultProps = {
  visible: false,
};

ContentOverlay.propTypes = {
  visible: T.bool,
  className: T.string,
  onClick: T.func,
};

export default ContentOverlay;
