import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ActivityBox from './ActivityBox';
import CongratsBox from './CongratsBox';
import EncouragementCountBox from './EncouragementCountBox/EncouragementCountBox';
// utils
import { renderEncourageIcon } from 'utils';
import classnames from 'classnames/bind';
// hoc
import withActivityController from './hoc/withActivityController';
// styles
import styles from './EncouragementActivity.module.scss';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'additional_data'];

const diagnosisCountersKeys = [
  { countKey: 'clinical_count', textKey: 'clinicalDiagnoses' },
  { countKey: 'lab_confirmed_count', textKey: 'labConfirmDiagnoses' },
  { countKey: 'presumed_count', textKey: 'presumedDiagnoses' },
  { countKey: 'gross_only_count', textKey: 'grossOnlyDiagnoses' },
];

const mediaCountersKeys = [
  { countKey: 'images_count', textKey: 'imagesUpload' },
  { countKey: 'videos_count', textKey: 'videosUpload' },
  { countKey: 'audios_count', textKey: 'audiosUpload' },
];

function getEncouragementCounters(variety) {
  const isDiagnosisEncouragement = variety.includes('diagnosis_encouragement');
  const isMediaEncouragement = variety.includes('media_encouragement');
  if (isDiagnosisEncouragement) return diagnosisCountersKeys;
  if (isMediaEncouragement) return mediaCountersKeys;
  return [];
}

const EncouragementActivity = ({ activity, markAsSeen }) => {
  const { additional_data, additional_data: { milestone }, variety, user, flagged, is_viewed } = activity;
  const isDiagnosisEncouragement = variety.includes('diagnosis_encouragement');
  const isMediaEncouragement = variety.includes('media_encouragement');
  const counters = getEncouragementCounters(variety);
  return (
    <ActivityBox isFlagged={flagged} isUnseen={!is_viewed} markAsSeen={markAsSeen}>
      <CongratsBox>
        <div className={cn('congrats-icon-box')}>
          {renderEncourageIcon(milestone, isDiagnosisEncouragement)}
        </div>

        <div className={cn('greeting-block')}>
          <span className={cn('header-text')}>
            <FormattedMessage
              id="general.greatWork"
              values={{
                doctor: isDiagnosisEncouragement ? 'Dr.' : '',
                name: isDiagnosisEncouragement ? user.last_name : user.first_name,
              }}
            />
          </span>
          <span className={cn('text')}>
            {isDiagnosisEncouragement && (
              <FormattedMessage
                id="general.submittedDiagnosis"
                values={{
                  sum: <FormattedMessage
                    tagName="b"
                    id="general.submittedDiagnosisSum"
                    values={{ value: milestone }}
                  />,
                }}
              />
            )}
            {isMediaEncouragement && (
              <FormattedMessage
                id="general.submittedMedia"
                values={{
                  sum: <FormattedMessage
                    tagName="b"
                    id="general.submittedMediaSum"
                    values={{ value: milestone }}
                  />,
                }}
              />
            )}
          </span>
        </div>

        <div className={cn('count-boxes-list')}>
          {counters.map(({ countKey, textKey }) => (
            <EncouragementCountBox
              key={countKey}
              count={additional_data[countKey]}
              message={`general.${textKey}`}
            />
          ))}
        </div>

      </CongratsBox>
    </ActivityBox>
  );
};

EncouragementActivity.propTypes = {
  activity: T.object.isRequired,
  markAsSeen: T.func,
};

export default withActivityController(EncouragementActivity, { affectRerenderKeys });
