import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
// styles
import styles from './Input.module.scss';

export const InputView = ({ className, children, placeholder = <FormattedMessage id="general.notAvail" /> }) => (
  <div className={cn(styles['input-view'], { [className]: !!className })}>{children || placeholder}</div>
);

InputView.propTypes = {
  className: T.string,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  placeholder: T.oneOfType([T.node, T.string]),
};

export default InputView;
