import React from 'react';
import T from 'prop-types';
// components
import SearchBox from 'components/SearchBox';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SearchList.module.scss';

const cn = classnames.bind(styles);

const SearchList = ({ className, onSearchChange, children }) => (
  <div className={cn('search-list', className)}>
    <div className={cn('search-box-wrapper')}>
      <SearchBox className={cn('search-box')} onChange={onSearchChange} />
    </div>
    <div className={cn('list')}>
      {children}
    </div>
  </div>
);

SearchList.propTypes = {
  className: T.string,
  onSearchChange: T.func.isRequired,
  children: T.node.isRequired,
};

export default SearchList;
