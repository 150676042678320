import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/Button';
import ConditionCheckBox from './ConditionItems/ConditionCheckBox';
import InputConditionCheckBox from './ConditionItems/InputConditionCheckBox';
import MobileListPickerComponent from 'components/MobileListPicker/MobileListPickerComponent';
// utils
import pickBy from 'lodash.pickby';
// styles
import styles from './ConditionSelector.module.scss';

const TextConditionSelector = ({ conditions, inputPlaceholderId, onBack, onSave, selectedSection, title }) => {
  const selectedValue = selectedSection.options?.[0]?.value || '';
  const [{ condition, value }, setState] = useState({ condition: selectedSection.condition, value: selectedValue });
  const { formatMessage } = useIntl();
  const isWrongText = condition === 'any' ? false : !value;
  const isAlreadySelected = condition === selectedSection.condition && selectedValue === value;
  const isDoneDisabled = !condition || isWrongText || isAlreadySelected;

  const handleSave = () => {
    onSave(pickBy({
      condition,
      id: selectedSection.id,
      sectionIndex: selectedSection.sectionIndex,
      type: selectedSection.type,
      options: condition !== 'any' ? [{ key: 'text', value }] : undefined,
    }));
  };

  const handleChangeCondition = ({ target: { name } }) => {
    setState({ condition: name, value: '' });
  };

  const handleChangeValue = ({ target: { value } }) => {
    setState((prevState) => ({ ...prevState, value }));
  };

  return (
    <MobileListPickerComponent
      title={title}
      onBack={onBack}
      headerRightContent={(
        <Button disabled={isDoneDisabled} primary small onClick={handleSave}>
          <FormattedMessage id="general.button.done" />
        </Button>
      )}
    >
      <div className={styles.checkboxes}>
        {conditions.map((option) => (
          <Fragment key={option.value}>
            {option.value !== 'any' && (
              <InputConditionCheckBox
                conditionInputValue={value}
                conditionValue={condition}
                option={option}
                onConditionInputValueChange={handleChangeValue}
                onConditionValueChange={handleChangeCondition}
                inputPlaceholder={formatMessage({ id: inputPlaceholderId })}
              />
            )}
            {option.value === 'any' && (
              <ConditionCheckBox
                conditionValue={condition}
                option={option}
                onConditionValueChange={handleChangeCondition}
              />
            )}
          </Fragment>
        ))}
      </div>
    </MobileListPickerComponent>
  );
};

TextConditionSelector.propTypes = {
  conditions: T.array.isRequired,
  selectedSection: T.object.isRequired,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
  inputPlaceholderId: T.string.isRequired,
  title: T.string.isRequired,
};

export default TextConditionSelector;
