import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { compose, withProps } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import Link from 'react-router/lib/Link';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesLabel from 'components/RolesLabel/RolesLabel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import sum from 'lodash.sum';
import cn from 'classnames';
// translate
import { FormattedMessage } from 'react-intl';

class MyProfileFarmRoles extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, role: filter });
  };

  renderFarmNameColumn = ({ name, farm_type }) => (
    <CustomColumn>
      <FarmTitleBox name={name} type={farm_type} />
    </CustomColumn>
  );

  renderLocationColumn = ({ city, state }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.location" />}>
      {`${city}, ${state}`}
    </CustomColumn>
  );

  renderFarmIdColumn = ({ external_link_id }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmId" />}>
      {external_link_id || 'N/A'}
    </CustomColumn>
  );

  renderUserRoleColumn = ({ is_owner, is_manager, is_caretaker, is_vet }) => (
    <CustomColumn flexRow>
      <span className="hide-for-large semibold">
        <FormattedMessage id="general.farmRoles" />&nbsp;•&nbsp;
      </span>
      <RolesLabel
        roles={[
          { name: <FormattedMessage id="general.role.farmOwner" />, value: is_owner, shortcut: 'o' },
          { name: <FormattedMessage id="general.role.farmManager" />, value: is_manager, shortcut: 'm' },
          { name: <FormattedMessage id="general.role.farmCaregiver" />, value: is_caretaker, shortcut: 'c' },
          { name: <FormattedMessage id="general.role.veterinarian" />, value: is_vet, shortcut: 'v' },
        ]}
      />
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id }) => (
    <div className="collapsible-value button-column">
      <Link to={`/admin/farms/${id}/user-roles`} className="button small light-gray wide">
        <FormattedMessage id="general.button.edit" />
      </Link>
    </div>
  );

  renderExpandable = ({ id }) => (
    <div>
      <Link to={`/admin/farms/${id}/user-roles`} className="button small light">
        <i className="fa fa-pencil mr-5" /><FormattedMessage id="general.button.edit" />
      </Link>
    </div>
  );

  render() {
    const { resources, meta: { stats = {}, total }, isLoading, reqParams: { page, per_page, role, search, sort },
      isAuthUserAdmin, onSearchChange, onPageChange, onPerPageChange, onSortChange } = this.props;
    const { isTablet } = this.context;
    const statsSum = sum(Object.values(stats));
    const tableFilters = [
      { label: <FormattedMessage id="general.allRoles" />, value: '', count: statsSum },
      { label: <FormattedMessage id="general.role.farmOwner" />, value: 'owner' },
      { label: <FormattedMessage id="general.role.caregiver" />, value: 'caretaker' },
      { label: <FormattedMessage id="general.role.farmManager" />, value: 'manager' },
      { label: <FormattedMessage id="general.role.veterinarian" />, value: 'vet' },
    ];
    const isEmptyTable = !resources.length && !role && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '2 1 160px',
        renderer: this.renderFarmNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="component.dataTable.headers.location" />, flex: '1 1 100px',
        renderer: this.renderLocationColumn, sortKey: 'city' },
      { label: <FormattedMessage id="component.dataTable.headers.farmId" />, flex: '1 1 100px',
        renderer: this.renderFarmIdColumn, sortKey: 'external_link_id' },
      { label: <FormattedMessage id="general.farmRoles" />, flex: '1 1 140px',
        renderer: this.renderUserRoleColumn, sortKey: 'role_name' },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 120px',
        renderer: this.renderStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true, hide: !isAuthUserAdmin,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true,
        hide: !isTablet || !isAuthUserAdmin, hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.farmRoles" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              onFilterChange={this.onFilterChange}
              stats={stats}
              activeFilter={role}
              className="ph-10"
            />
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet && isAuthUserAdmin}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="myFarmsRoles"
            rowKey="role_id" // todo: id is not unique for roles table now
          />

          <NothingBox
            itemsName="roles"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
            filter={role}
          >
            <FormattedMessage tagName="h1" id="container.myProfile.farmRoles.empty" />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

MyProfileFarmRoles.propTypes = {
  fetchData: T.func.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  isAuthUserAdmin: T.bool.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
};

MyProfileFarmRoles.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.myFarmsRoles.resources,
      reqParams: state.dataTable.myFarmsRoles.params,
      meta: state.dataTable.myFarmsRoles.meta,
      isLoading: state.dataTable.myFarmsRoles.isLoading,
      isAuthUserAdmin: state.auth.user.roles_map.admin,
      currentUserId: state.auth.user.id,
    }), { fetchData },
  ),
  withProps(({ currentUserId: id, fetchData }) => ({
    fetchData: (query) => fetchData(`/admin/users/${id}/farm_roles/profile_index`, 'myFarmsRoles', query),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(MyProfileFarmRoles);
