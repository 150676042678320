import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import Subnavigation from 'components/Subnavigation';

// styles
import OfflineScreen from '../../../../../components/OfflineScreen';
import DataTableImports from './DataTableImports/DataTableImports';
import { withRouter } from 'react-router';
import Preloader from '../../../../../components/Preloader';

const ImportsTable = ({ isOnline, subNavLinks, params: { type, name }, typeIdImports }) => {

  if (!typeIdImports)  return  <Preloader isActive />;
  const importId =  typeIdImports[type];
  const nameStore = name === 'fas' ? `${type}Fas` : `${type}MetaFarms`;
  return (
    <div className="BarnsheetsHome clearfix">
      <Subnavigation isSticky links={subNavLinks} className="small-12 column" />
      {/* {childrenTable()} */}
      {isOnline ? (
        <div className="small-12 column">
          <DataTableImports
            getDataUrl={`/admin/csv_imports/${importId}`}
            getNameStore={nameStore}
            importId={importId}
          />
        </div>
      ) : (
        <OfflineScreen />
      )}
    </div>
  );
};

ImportsTable.propTypes = {
  isOnline: T.bool,
  subNavLinks: T.array,
  typeIdImports: T.object,
  params: T.object,
};

export default withRouter(connect((state) => ({
  isOnline: state.network.isOnline,
  typeIdImports: state.importCSV.typeIdImports.resources,
}))(ImportsTable));
