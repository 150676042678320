import { fetchFromAPI } from 'utils/api';
import { tableObjectStoreGetAll } from 'db/offline-db';
import {
  farmWhenAllCheckupsSubmitted,
  getFarmOffline,
  mergeFarmsOffline,
  updateFarmOffline,
  useOfflinePaginationAndSearch,
} from 'utils/offlineHelper';

export const getCheckupFarms = (params) => {
  return fetchFromAPI('/daily_checkups/farms', { params })
    .then((response) => {
      mergeFarmsOffline(response.resources);
      return response;
    })
    .catch((response) => {
      throw response;
    });
};

export const getCheckupFarmsOffline = (params) => {
  return tableObjectStoreGetAll('farms')
    .then((resources) => {
      const searchedFarmsByRoles = resources.sort(({ is_caretaker }) => (!is_caretaker ? 1 : -1));
      return useOfflinePaginationAndSearch(params, searchedFarmsByRoles);
    })
    .catch((response) => {
      throw response;
    });
};

export const setFarmCheckupsAllGood = (farmId) => {
  return fetchFromAPI(`/daily_checkups/farms/${farmId}/all_good`, { method: 'post' })
    .then((response) => {
      updateFarmOffline(response.resource);
      return response;
    })
    .catch((response) => {
      throw response;
    });
};

export const setFarmCheckupsAllGoodOffline = (farmId) => (
  getFarmOffline(farmId)
    .then((farm) => {
      const allGoodFarm = farmWhenAllCheckupsSubmitted(farm);
      updateFarmOffline(allGoodFarm);
      return { resource: allGoodFarm };
    })
    .catch((response) => {
      throw response;
    })
);
