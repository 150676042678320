import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import FarmTypeLabel from 'components/FarmTypeLabel';
import AllGoodConfirmationModal from
  'components/DailyCheckups/modals/AllGoodConfirmationModal/AllGoodConfirmationModal';
import Preloader from 'components/Preloader';
// utils
import cn from 'classnames';
// other
import { getStatusIcon } from './helper';
import './DailyCheckupsFarmRow.scss';

class DailyCheckupsFarmRow extends Component {

  state = {
    isLoading: false,
  };

  setLoading = (loading) => {
    this.setState({ isLoading: loading });
  };

  confirmAllGood = () => {
    const { farm, closeModal, onSetAllGood } = this.props;
    this.setLoading(true);
    onSetAllGood(farm.id)
      .then(() => this.setLoading(false))
      .catch(() => this.setLoading(false));
    closeModal();
  };

  allGoodClick = () => {
    const { openModal, farm } = this.props;
    openModal(
      <AllGoodConfirmationModal
        type="farm"
        name={farm.name}
        onConfirm={this.confirmAllGood}
      />
    );
  };

  toFarmGroupsPage = () => {
    const { farm: { id } } = this.props;
    const { router } = this.context;
    router.push(`/daily-checkup/farms/${id}`);
  };

  render() {
    const { farm, currentCompanyName, farm: { open_pig_groups_count, pending_pig_groups_count, pending_checkup_count,
      dc_progress_related_groups_count, dc_progress_status: status, federal_premise_id }, canSetAllGood } = this.props;
    const { isLoading } = this.state;
    const statusIcon = getStatusIcon(status);
    const hasGroups = Boolean(dc_progress_related_groups_count + pending_pig_groups_count);
    const showAllGoodButton = (status === 'new') && !!open_pig_groups_count && !pending_pig_groups_count;

    const allGoodButton = (
      <Button light disabled={isLoading} onClick={this.allGoodClick}>
        <i className="fa fa-thumbs-up mr-5" />
        <FormattedMessage id="container.checkupIndex.row.allGood" />
      </Button>
    );
    const startButton = (
      <Button disabled={isLoading} primary onClick={this.toFarmGroupsPage}>
        <FormattedMessage id="container.checkupIndex.row.start" />
      </Button>
    );
    const continueButton = (
      <Button secondary onClick={this.toFarmGroupsPage}>
        <FormattedMessage id="container.checkupIndex.row.continue" />
      </Button>
    );
    const updateButton = (
      <Button default onClick={this.toFarmGroupsPage}>
        <FormattedMessage id="container.checkupIndex.row.update" />
      </Button>
    );
    return (
      <div className={cn('DailyCheckupsFarmRow', { 'is-new': status === 'new', 'no-groups': !hasGroups })}>

        <div className="row-content">
          <Preloader isActive={isLoading} />
          <div className="icon-section">{statusIcon}</div>

          <div className="row-section farm-info">
            <div className="farm-name">
              <span className="hide-for-large mr-10">{statusIcon}</span>
              <span>
                <span className="mr-10">{farm.name}</span>
                <FarmTypeLabel farmType={farm.farm_type} />
              </span>
            </div>
            <div className="farm-premise">ID &bull; {federal_premise_id || 'N/A'}</div>
          </div>

          <div className="section-divider" />
          <div className="row-section farm-progress">
            {(dc_progress_related_groups_count > 0) && (
              <ProgressBar
                progress={farm.progress}
                inline={false}
                className="farm-progress-bar"
                description={<FormattedMessage id="general.progress.onTime" />}
              />
            )}
            {(open_pig_groups_count === 0) && (pending_pig_groups_count > 0) &&
              <span className="status">{pending_pig_groups_count} scheduled group(s)</span>}
            {!hasGroups && (
              <span className="status">
                <FormattedMessage
                  id="component.dailyCheckupsFarmRow.noGroups"
                  values={{ company: <span className="color-primary">{currentCompanyName}</span> }}
                />
              </span>
            )}
          </div>


          <div className="section-divider divider-2" />
          <div className="row-section pending-checkups status">
            {pending_checkup_count === 0
              ? <FormattedMessage id="general.status.upToDate" />
              : (
                <FormattedMessage
                  id="component.dailyCheckupsFarmRow.pendingCheckups"
                  values={{ pendingCount: Number(pending_checkup_count), openCount: Number(open_pig_groups_count) }}
                />
              )}
          </div>

          {/* ROW BUTTONS: DEFAULT */}
          <div className="row-section buttons-section">
            {showAllGoodButton && canSetAllGood && allGoodButton}
            {(status === 'new') && hasGroups && startButton}
            {(status !== 'new' && (pending_checkup_count > 0 || status === 'incomplete')) && continueButton}
            {(!['new', 'incomplete'].includes(status) && pending_checkup_count === 0) && updateButton}
          </div>

        </div>
      </div>
    );
  }
}

DailyCheckupsFarmRow.contextTypes = {
  router: T.object,
};

DailyCheckupsFarmRow.propTypes = {
  farm: T.object.isRequired,
  currentCompanyName: T.string.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  canSetAllGood: T.bool.isRequired,
  onSetAllGood: T.func.isRequired,
};

export default DailyCheckupsFarmRow;
