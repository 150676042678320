import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './ProfilePlaceholder.module.scss';

const cn = classnames.bind(styles);

const ProfilePlaceholder = ({ isActive, children }) => {
  if (!isActive) return children;

  return (
    <div className={cn('profile-placeholder')}>
      <div className={cn('avatar-placeholder')} />
      <div className={cn('text-block')}>
        <div className={cn('text-line')} />
        <div className={cn('text-line', 'half')} />
      </div>
    </div>
  );
};

ProfilePlaceholder.propTypes = {
  isActive: T.bool,
  children: T.any,
};

export default ProfilePlaceholder;
