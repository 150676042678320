import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import BarnSheetsTreatmentsProfile from '../components/BarnSheetsTreatmentsProfile';
import BarnSheetsTreatmentsAntibiotic from '../components/BarnSheetsTreatmentsAntibiotic';
// endpoints
import { getFarmTreatmentsCSV } from 'endpoints/antibioticTracking';
// utils
import download from 'downloadjs';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getFilterOptions } from '../utils/barnSheetsTreatments';
import { antibioticStatsOptions } from 'constants.js';

const BarnSheetsFarmTreatmentsAntibiotic = ({
  currentUser,
  farm,
  isLoading,
  location,
  params: { farmId },
}) => {
  const [state, setState] = useState({ period_name: 'year_to_date', date_start: null, date_end: null });
  const { period_name, date_start, date_end } = state;
  const { roles_map: { admin } } = currentUser;

  const handleChangeDates = ({ startDate, endDate }) => {
    setState((prevState) => ({ ...prevState, date_start: startDate, date_end: endDate }));
  };

  const handleSelectPeriod = (option) => {
    if (!option) return;
    const { value } = option;
    if (value !== period_name) {
      const dates = value !== 'custom' ? { date_start: null, date_end: null } : {};
      setState((prevState) => ({ ...prevState, period_name: value, ...dates }));
    }
  };

  const handleDownloadCSV = (e) => {
    e.stopPropagation();
    const formattedDateStart = date_start ? date_start.format('YYYY-MM-DD') : null;
    const formattedDateEnd = date_end ? date_end.format('YYYY-MM-DD') : null;
    const params = { period_name, date_start: formattedDateStart, date_end: formattedDateEnd };
    const periodTitle = (period_name && (period_name !== 'custom')) ? `-period-${period_name}` : '';
    const datesTitle = date_start && date_end
      ? `-date-start-${formattedDateStart}-date-end-${formattedDateEnd}` : '';
    getFarmTreatmentsCSV(farmId, params)
      .then((blob) => download(blob, `farm-${farm.name}-antibiotic-usage${periodTitle + datesTitle}`, 'text/csv'))
      .catch(toastResponseErrors);
  };

  const filterOptions = getFilterOptions(`/barnsheets/farms/${farmId}/treatments`, admin);

  return (
    <BarnSheetsTreatmentsProfile
      dateEnd={date_end}
      dateStart={date_start}
      filterOptions={filterOptions}
      onChangeDates={handleChangeDates}
      onSelectPeriod={handleSelectPeriod}
      options={antibioticStatsOptions}
      pathname={location.pathname}
      periodName={period_name}
      buttonsContent={(
        <Button
          primary
          onClick={handleDownloadCSV}
          disabled={(period_name === 'custom' && (!date_start || !date_end)) || isLoading}
        >
          <FormattedMessage id="general.button.download" />
        </Button>
      )}
    >
      <BarnSheetsTreatmentsAntibiotic queryParams={state} farm={farm} paramsFarmId={farmId} />
    </BarnSheetsTreatmentsProfile>
  );
};

BarnSheetsFarmTreatmentsAntibiotic.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsFarmTreatmentsAntibiotic.propTypes = {
  params: T.object.isRequired,
  location: T.object.isRequired,
  farm: T.object.isRequired,
  currentUser: T.object.isRequired,
  isLoading: T.bool.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    farm: state.profiles.farm.data,
    isLoading: state.profiles.farm.isLoading,
  })
)(BarnSheetsFarmTreatmentsAntibiotic);
