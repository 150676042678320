import React from 'react';
import T from 'prop-types';
// components
import Amchart from 'components/Amchart';
import ChartPlaceholder from 'components/Amchart/ChartPlaceholder';
import { defaultSerialChartConfig } from 'components/Amchart/chartConfig';
import GrowthRate from 'components/GrowthRate/GrowthRate';
import GraphLegend from 'components/GraphLegend/GraphLegend';
import Preloader from 'components/Preloader';
// hooks
import { useIntl } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import isNull from 'lodash.isnull';
// styles
import styles from './AntibioticChart.module.scss';

const cn = classnames.bind(styles);

const AntibioticChart = ({
  data,
  graphData,
  labels,
  title,
  growsData,
  categoryTitle,
  categoryField,
  isLoading,
  valueAxesTitleKey = 'general.grams',
}) => {
  const { formatMessage } = useIntl();
  const serialChartConfig = {
    ...defaultSerialChartConfig,
    categoryAxis: {
      ...defaultSerialChartConfig.categoryAxis,
      title: categoryTitle,
      titleFontSize: 11,
    },
    valueAxes: [{
      axisAlpha: 0,
      gridColor: '#A3A3A3',
      gridAlpha: 0.3,
      color: '#A3A3A3',
      title: formatMessage({ id: valueAxesTitleKey }).toUpperCase(),
      titleFontSize: 11,
    }],
    categoryField,
    dataProvider: data,
    graphs: graphData,
  };
  const chartStyle = { height: '330px' };
  return (
    <div className={cn('AntibioticChart')}>
      <Preloader isActive={isLoading} />
      {(data && data.length)
        ? (
          <>
            <div className={cn('caption-block')}>
              <div>
                <div className={cn('graph-title')}>{title}</div>
                <div className={cn('label-block')}>
                  {labels.map(({ color, text }, index) => (
                    <GraphLegend key={index} backgroundColor={color} text={text} />
                  ))}
                </div>
              </div>
              <div>
                <div className={cn('average-amount')}>
                  <span className={cn('item-value')}> {isNull(growsData.value) ? 'N/A' : growsData.value}</span>
                  {(!isNull(growsData.value)) && (
                    <span className={cn('grow-span')}>
                      <GrowthRate className="ml-5" small value={Number(growsData.grows)} />
                    </span>
                  )}
                </div>
                <div className={cn('grows-label')}>{growsData.growsLabel}</div>
              </div>
            </div>
            <Amchart style={chartStyle} config={serialChartConfig} />
          </>
        )
        : <ChartPlaceholder chartStyle={chartStyle} />}
    </div>
  );
};

AntibioticChart.propTypes = {
  isLoading: T.bool,
  data: T.array,
  graphData: T.array,
  labels: T.array,
  categoryTitle: T.string,
  categoryField: T.string,
  title: T.node,
  growsData: T.object,
  valueAxesTitleKey: T.string,
};

export default AntibioticChart;
