import React from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import LayoutMinimal from 'components/LayoutMinimal';
// styles
import './SetPasswordMessage.scss';

const templates = {
  defaultOption: {
    message: <FormattedMessage id="container.login.contactSupport" />,
    linkText: <FormattedMessage id="container.login.returnToLogin" />,
    link: '/login',
  },
  letter: {
    message: <FormattedMessage id="container.login.reset.emailSent" />,
    linkText: <FormattedMessage id="container.login.returnToLogin" />,
    link: '/login',
  },
  reset: {
    message: <FormattedMessage id="container.login.reset.resetDone" />,
    linkText: <FormattedMessage id="container.login.reset.loginLink" />,
    link: '/login',
  }
};

const SetPasswordMessage = (props, { router: { location: { query } } }) => {
  const { message, linkText, link } = templates[query.type || 'defaultOption'];

  return (
    <LayoutMinimal containerClass="login">
      <div className="SetPasswordMessage">
        <FormattedMessage id="container.login.reset.success" tagName="h1" />
        <i className="checked-icon fa fa-check" />
        <div className="success-message">{message}</div>
        <Link to={link} className="color-primary login-link">
          {linkText}
        </Link>
      </div>
    </LayoutMinimal>
  );
};

SetPasswordMessage.contextTypes = {
  router: T.object,
};

export default SetPasswordMessage;
