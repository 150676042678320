import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  all: {
    total: 0,
    n: 0,
    wf: 0,
    f: 0,
    su: 0,
    m: 0,
    ff: 0,
    updated_at: '',
  },
  isLoading: false,
};

const FETCH_PIGS_UNDER_CARE = 'pigsUnderCare/FETCH_PIGS_UNDER_CARE';
const [FETCH_PIGS_UNDER_CARE_PENDING, FETCH_PIGS_UNDER_CARE_REJECTED] = [
  `${FETCH_PIGS_UNDER_CARE}_PENDING`, `${FETCH_PIGS_UNDER_CARE}_REJECTED`
];

const SET_PIGS_UNDER_CARE = 'pigsUnderCare/SET_PIGS_UNDER_CARE';
const CLEAR_PIGS_UNDER_CARE = 'pigsUnderCare/CLEAR_PIGS_UNDER_CARE';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPigsUnderCareData = (company_id = null) => (dispatch) => {
  dispatch(createAction(FETCH_PIGS_UNDER_CARE_PENDING)());
  return fetchFromAPI('/admin/analytics/pigs', { params: { company_id } })
    .then((response) => dispatch(createAction(SET_PIGS_UNDER_CARE)({ ...response.resource, company_id })))
    .catch((response) => {
      dispatch(createAction(FETCH_PIGS_UNDER_CARE_REJECTED)(response));
      throw response;
    });
};

export const setPigsUnderCareData = (data, company_id = null) => createAction(SET_PIGS_UNDER_CARE)(
  { ...data, company_id }
);

export const clearPigsUnderCareData = createAction(CLEAR_PIGS_UNDER_CARE);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_PIGS_UNDER_CARE_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [SET_PIGS_UNDER_CARE]: (state, { payload }) => {
    const key = payload.company_id || 'all';
    const data = {
      total: payload.total,
      n: payload.n,
      wf: payload.wf,
      f: payload.f,
      su: payload.su,
      m: payload.m,
      ff: payload.ff,
      updated_at: payload.updated_at,
    };

    return {
      ...state,
      [key]: data,
      isLoading: false,
    };
  },
  [FETCH_PIGS_UNDER_CARE_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [CLEAR_PIGS_UNDER_CARE]: () => ({
    ...initialState,
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
