import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as OfflineIcon } from 'assets/svg/offline.svg';
// utils
import classNames from 'classnames/bind';
// styles
import styles from './OfflineScreen.module.scss';

const cn = classNames.bind(styles);

const OfflineContainer = ({ customContent, isOnline, children }) => {
  return isOnline ? children : (
    <div className={cn('offline-section', { screen: !customContent })}>
      <div className={cn('offline-content')}>
        <OfflineIcon width={140} className={cn('offline-icon')} />
        <div className={cn('label-text')}><FormattedMessage id="component.offlineScreen.label" /></div>
        <div className={cn('sub-label-text')}><FormattedMessage id="component.offlineScreen.subLabel" /></div>
        <Link className={cn('link')} to="/daily-checkup">
          <FormattedMessage id="component.offlineScreen.completeDC" />
        </Link>
      </div>
      {customContent}
    </div>
  );
};

OfflineContainer.propTypes = {
  isOnline: T.bool.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  customContent: T.oneOfType([T.arrayOf(T.node), T.node, T.string]),
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  })
)(OfflineContainer);
