import React from 'react';
import T from 'prop-types';
// components
import Translation from 'components/Translation';
// utils
import { renderMentionsText } from 'utils';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
// assets
import './Section.scss';
// translate
import { FormattedMessage } from 'react-intl';

const Notes = ({ value, empty, withoutLabel, className }) => {
  const { comment } = value || {};
  const isNone = empty || isEmpty(value);

  return (
    <div className="Sections">
      {!withoutLabel
        && (
        <div className="panel-heading gray semibold">
          <FormattedMessage id="general.notes" />
        </div>
        )}
      <div className={cn('panel-body notes', { 'light-yellow-bg': !isNone }, { [className]: !!className })}>
        {
          isNone
            ? <span className="none"><FormattedMessage id="general.none" /></span>
            : <Translation text={comment}>{renderMentionsText(comment)}</Translation>
        }
      </div>
    </div>
  );
};

Notes.propTypes = {
  value: T.object,
  className: T.string,
  empty: T.bool,
  withoutLabel: T.bool,
};

Notes.defaultProps = {
  empty: false,
  withoutLabel: false,
};

export default Notes;
