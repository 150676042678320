import React, { Component } from 'react';
import T from 'prop-types';
// recompose, redux
import { connect } from 'react-redux';
// components
import FarmFeedSearchInput from 'components/FarmFeedSearch/FarmFeedSearchInput';
import DatesFilter from 'components/FarmFeed/SearchFilters/DatesFilter';
import FilterItemContainer from '../FilterItemContainer/FilterItemContainer';
import FiltersListMobile from './FiltersListMobile';
import DateRangePickerMobile from 'components/DatePicker/DateRangePicker/DateRangePickerMobile';
import Button, { TransparentBtn } from 'components/Button';
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';
import classnames from 'classnames/bind';
// styles
import styles from './SearchFilters.module.scss';

const cn = classnames.bind(styles);

const backendDateFormat = 'YYYY-MM-DD';
const clearBtnStyle = { fontWeight: 'normal', marginLeft: 'auto' };

const initialState = {
  dates: { date_start: null, date_end: null },
  farm: {},
  company: {},
  group: {},
  activeFilter: '',
};

class SearchFilters extends Component {

  state = initialState;

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { searchParams: { filters, search } } = this.props;
    const { searchParams: { filters: newFilters, search: newSearch } } = nextProps;
    if ((search !== newSearch) || (!isEqual(filters, newFilters))) {
      this.setState(initialState);
    }
  }

  openDatesPicker = () => {
    const { openPortal } = this.props;
    const { dates: { date_start, date_end } } = this.state;
    openPortal(
      <DateRangePickerMobile
        initialStartDate={date_start}
        initialEndDate={date_end}
        onChange={this.onDatesFilterChange}
        isOutsideRange={(day) => (day.isAfter(moment(), 'day'))}
      />
    );
  };

  openOtherFilters = () => {
    const { openPortal, searchParams, fetchData } = this.props;
    const initialData = pick(this.state, ['company', 'farm', 'group']);
    openPortal(
      <FiltersListMobile
        onChange={this.onFilterChange}
        onReset={this.onClearFilters}
        syncState={this.syncState}
        initialParams={searchParams}
        initialData={initialData}
        fetchData={fetchData}
      />
    );
  };

  onDatesFilterChange = ({ startDate, endDate }, e) => {
    if (e) e.stopPropagation();
    const { fetchData, farmFeedParams } = this.props;
    const date_start = startDate ? moment(startDate).format(backendDateFormat) : null;
    const date_end = endDate ? moment(endDate).format(backendDateFormat) : null;
    if (date_start && date_end) {
      fetchData({ ...farmFeedParams, page: 1, date_start, date_end });
    }
    if (!date_start && !date_end) {
      fetchData({ ...farmFeedParams, page: 1, date_start, date_end });
    }
    this.setState({ dates: { date_start: startDate, date_end: endDate } });
  };

  onFarmFilterChange = (farm) => {
    const { fetchData, farmFeedParams } = this.props;
    const { filters } = farmFeedParams;
    let filtersData = { farm_id: null, pig_group_id: null };
    if (farm.id) {
      filtersData = { farm_id: farm.id, pig_group_id: filters.pig_group_id || null };
    }
    fetchData({
      ...farmFeedParams,
      page: 1,
      filters: { ...filters, ...filtersData },
    });
    this.setState((prevState) => ({ farm, group: farm.id ? prevState.group : {} }));
  };

  onGroupFilterChange = (group) => {
    const { fetchData, farmFeedParams } = this.props;
    fetchData({
      ...farmFeedParams,
      page: 1,
      filters: { ...farmFeedParams.filters, pig_group_id: group.id || null },
    });
    this.setState({ group });
  };

  onCompanyFilterChange = (company) => {
    const { fetchData, farmFeedParams } = this.props;
    const { filters } = farmFeedParams;
    let filtersData = { company_id: null, farm_id: null, pig_group_id: null };
    if (company.id) {
      filtersData = {
        company_id: company.id,
        farm_id: filters.farm_id || null,
        pig_group_id: filters.pig_group_id || null,
      };
    }
    fetchData({
      ...farmFeedParams,
      page: 1,
      filters: { ...filters, ...filtersData },
    });
    const { farm, group } = this.state;
    this.setState({
      company,
      farm: company.id ? farm : {},
      group: company.id ? group : {},
    });
  };

  onClearFilters = () => {
    const { fetchData, searchParams, farmFeedParams: { per_page } } = this.props;
    fetchData({ ...searchParams, page: 1, per_page });
    this.setState(initialState);
  };

  onFilterChange = (data, type) => {
    switch (type) {
      case 'company':
        return this.onCompanyFilterChange(data);
      case 'farm':
        return this.onFarmFilterChange(data);
      case 'group':
        return this.onGroupFilterChange(data);
      default:
        return null;
    }
  };

  onActiveFilterChange = (activeFilter) => (this.setState({ activeFilter }));

  syncState = (newState) => (this.setState((prevState) => ({ ...prevState, ...newState })));

  clearOtherFilters = (e) => {
    e.stopPropagation();
    const { fetchData, farmFeedParams, searchParams } = this.props;
    const { date_start, date_end } = farmFeedParams;
    fetchData({ ...farmFeedParams, ...searchParams, date_start, date_end, page: 1 });
    this.setState({ company: {}, farm: {}, group: {} });
  };

  isFilterDisabled = (type) => {
    const { farm, group } = this.state;
    const { searchParams: { filters: { company_id, farm_id, pig_group_id } } } = this.props;
    switch (type) {
      case 'company':
        return Boolean(farm_id || pig_group_id || company_id) || !isEmpty(farm) || !isEmpty(group);
      case 'farm':
        return Boolean(farm_id || pig_group_id) || !isEmpty(group);
      default:
        return false;
    }
  };

  getReqParams = (type) => {
    const { farm, company } = this.state;
    const { searchParams: { filters: { company_id, farm_id } } } = this.props;
    switch (type) {
      case 'farm':
        return { manager_id: company.id || company_id || null };
      case 'group':
        return { farm_id: farm.id || farm_id || null };
      default:
        return {};
    }
  };

  isClearBtnShow = () => {
    const { dates: { date_start, date_end }, farm, company, group } = this.state;
    return date_start || date_end || !isEmpty(farm) || !isEmpty(company) || !isEmpty(group);
  };

  formatDate() {
    const { dates: { date_start, date_end } } = this.state;
    const hasDatesQuery = Boolean(date_start && date_end);

    if (!hasDatesQuery) return <FormattedMessage id="general.dates" />;

    const isSameMonth = moment(date_start).isSame(date_end, 'month');
    const isSameYear = moment(date_start).isSame(date_end, 'year');
    const formatStartDate = isSameYear ? 'MMM DD' : 'MMM DD, YYYY';
    let formatEndDate = '';
    if (isSameYear && isSameMonth) formatEndDate = 'DD';
    if (isSameYear && !isSameMonth) formatEndDate = 'MMM DD';
    if (!isSameYear) formatEndDate = 'MMM DD, YYYY';
    return moment(date_start).format(formatStartDate) + '-' + moment(date_end).format(formatEndDate);
  }

  formatOtherFiltersLabel() {
    const { farm, company, group } = this.state;
    let filtersCount = 0;
    if (!isEmpty(farm)) filtersCount += 1;
    if (!isEmpty(company)) filtersCount += 1;
    if (!isEmpty(group)) filtersCount += 1;
    return <FormattedMessage id="general.filtersCount" values={{ count: filtersCount }} />;
  }

  renderDatesFilters() {
    const { isMobileView } = this.props;
    const { dates: { date_start, date_end } } = this.state;
    const hasDatesQuery = Boolean(date_start && date_end);

    if (isMobileView) {
      return (
        <div className={cn('filter-item', { 'active': hasDatesQuery })} onClick={this.openDatesPicker}>
          {hasDatesQuery ? this.formatDate() : <FormattedMessage id="general.dates" />}
          {hasDatesQuery &&
          <i className={cn('fa fa-times', 'clear-dates-icon')} onClick={(e) => this.onDatesFilterChange({}, e)} />}
        </div>
      );
    }

    return (
      <DatesFilter
        startDate={date_start}
        startDatePlaceholderText="From Date"
        endDate={date_end}
        endDatePlaceholderText="To Date"
        singlePlaceholder="Dates"
        onOpen={() => this.onActiveFilterChange('dates')}
        onDatesChange={this.onDatesFilterChange}
        numberOfMonths={2}
        isOutsideRange={(day) => (day.isAfter(moment(), 'day'))}
      />
    );
  }

  renderEntityFilters() {
    const { searchParams: { filters: { pig_group_id } }, isMobileView } = this.props;
    const { activeFilter, farm, company, group } = this.state;
    const hasFiltersQuery = Boolean(!isEmpty(farm) || !isEmpty(company) || !isEmpty(group));

    if (isMobileView) {
      return !pig_group_id && (
        <div className={cn('filter-item', { 'active': hasFiltersQuery })} onClick={this.openOtherFilters}>
          {this.formatOtherFiltersLabel()}
          {hasFiltersQuery &&
            <i className={cn('fa fa-times', 'clear-dates-icon')} onClick={this.clearOtherFilters} />}
        </div>
      );
    }

    return (
      ['company', 'farm', 'group'].map((type) => (
        <FilterItemContainer
          key={type}
          filterType={type}
          reqParams={this.getReqParams(type)}
          value={this.state[type]}
          onOpen={this.onActiveFilterChange}
          onChange={(data) => this.onFilterChange(data, type)}
          isOpened={activeFilter === type}
          isDisabled={this.isFilterDisabled(type)}
        />
      ))
    );
  }

  renderClearBtn() {
    const { isMobileView } = this.props;
    const showClearBtn = this.isClearBtnShow();

    if (!showClearBtn) return null;

    if (isMobileView) {
      return (
        <Button default className={cn('clear-filters-btn')} onClick={this.onClearFilters}>
          <FormattedMessage id="general.button.clear" />
        </Button>
      );
    }

    return (
      <TransparentBtn style={clearBtnStyle} onClick={this.onClearFilters}>
        <FormattedMessage id="general.button.clearAll" />
      </TransparentBtn>
    );
  }

  render() {
    const { isMobileView } = this.props;
    return (
      <div className={cn('search-filters-header')}>
        {isMobileView && (
          <FarmFeedSearchInput />
        )}
        <div className={cn('search-filters')}>
          {this.renderDatesFilters()}
          {this.renderEntityFilters()}
          {this.renderClearBtn()}
        </div>
      </div>
    );
  }
}

SearchFilters.propTypes = {
  isMobileView: T.bool.isRequired,
  fetchData: T.func.isRequired,
  openPortal: T.func.isRequired,
  searchParams: T.object.isRequired,
  farmFeedParams: T.shape({
    filters: T.object.isRequired,
    search: T.string.isRequired,
    date_start: T.string,
    date_end: T.string,
    page: T.number,
    per_page: T.number,
  }),
};

export default connect((state) => ({
  searchParams: state.farmFeedSearch.params,
}))(SearchFilters);
