import { handleActions, createAction } from 'redux-actions';
// utils
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: {
    isLoading: false,
    data: null,
  },
};

const FETCH_SINGLE_CHEKIN = 'checkins/FETCH_SINGLE_CHEKIN';
const [FETCH_SINGLE_CHEKIN_PENDING, FETCH_SINGLE_CHEKIN_FULFILLED, FETCH_SINGLE_CHEKIN_REJECTED] = [
  `${FETCH_SINGLE_CHEKIN}_PENDING`,
  `${FETCH_SINGLE_CHEKIN}_FULFILLED`,
  `${FETCH_SINGLE_CHEKIN}_REJECTED`
];

// ------------------------------------
// Action creators
// ------------------------------------

export const fetchSingleCheckin = (id) => createAction(FETCH_SINGLE_CHEKIN)(
  fetchFromAPI(`/check_ins/${id}`)
);


// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_SINGLE_CHEKIN
  [FETCH_SINGLE_CHEKIN_PENDING]: (state) => ({
    ...state,
    ...state.resource,
    isLoading: true,
  }),
  [FETCH_SINGLE_CHEKIN_FULFILLED]: (state, action) => ({
    ...state,
    data: action.payload.resource,
    isLoading: false,
  }),
  [FETCH_SINGLE_CHEKIN_REJECTED]: (state, action) => ({
    ...state,
    error: action.payload.errors,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
