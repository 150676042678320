import { fetchFromAPI } from 'utils/api';

export const createUserFilter = (resource) => {
  return fetchFromAPI('/user_filters', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateUserFilter = (id, resource) => {
  return fetchFromAPI(`/user_filters/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const deleteUserFilter = (id) => {
  return fetchFromAPI(`/user_filters/${id}`, { method: 'delete' })
    .catch((response) => {
      throw response;
    });
};

export const getUserFilters = (group_name) => {
  return fetchFromAPI('/user_filters', { params: { group_name } })
    .then((response) => response.resources)
    .catch((response) => {
      throw response;
    });
};
