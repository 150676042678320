import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ConditionCheckBox from './ConditionCheckBox';
import Input from 'components/Input';
// styles
import styles from '../ConditionSelector.module.scss';

const InputRangeConditionCheckBox = ({
  conditionValue,
  inputEndValue,
  inputStartValue,
  inputStep,
  inputLabel,
  onInputValueChange,
  onConditionValueChange,
  option,
  maxInputValue,
}) => {
  const isOptionChecked = option.value === conditionValue;
  return (
    <Fragment>
      <ConditionCheckBox
        option={option}
        conditionValue={conditionValue}
        onConditionValueChange={onConditionValueChange}
      />
      {isOptionChecked && (
        <>
          <div className={styles['input-row']}>
            <FormattedMessage id="general.from">
              {(text) => <span className={styles['from-or-to']}>{text}</span>}
            </FormattedMessage>
            <Input
              name="valueStart"
              type="number"
              min="0"
              max={maxInputValue}
              step={inputStep}
              placeholder={0}
              className="mb-5"
              value={inputStartValue}
              onChange={onInputValueChange}
            />
            <div className={styles['input-row-label']}>{inputLabel}</div>
          </div>
          <div className={styles['input-row']}>
            <FormattedMessage id="general.to">
              {(text) => <span className={styles['from-or-to']}>{text}</span>}
            </FormattedMessage>
            <Input
              name="valueEnd"
              type="number"
              min="0"
              max={maxInputValue}
              step={inputStep}
              placeholder={0}
              className="mb-5"
              value={inputEndValue}
              onChange={onInputValueChange}
            />
            <div className={styles['input-row-label']}>{inputLabel}</div>
          </div>
        </>
      )}
    </Fragment>
  );
};

InputRangeConditionCheckBox.propTypes = {
  conditionValue: T.string,
  inputStartValue: T.string,
  inputEndValue: T.string,
  option: T.object.isRequired,
  onConditionValueChange: T.func.isRequired,
  onInputValueChange: T.func.isRequired,
  inputLabel: T.oneOfType([T.string, T.node]),
  inputStep: T.number,
  maxInputValue: T.number,
};

export default InputRangeConditionCheckBox;
