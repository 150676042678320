// utils
import intersection from 'lodash.intersection';
import { createSelector } from 'reselect';

const mainFeedVarieties = [
  'health_issue',
  'media',
  'efficacy',
  'checked_in',
  'direct',
  'shared',
  'birthday_congrats',
  'diagnosis_encouragement',
  'media_encouragement',
  'low_mortality',
  'withdrawal_warning',
  'water_usage_warning',
  'svr_issue',
];

export const newActivitiesDefaultSelector = createSelector(
  (state) => state.farmfeed.newActivities.resources,
  (newActivities) => newActivities.filter((activity) => intersection(activity.variety, mainFeedVarieties).length),
);
