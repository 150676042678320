import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
// utils
import cn from 'classnames';
// styles
import styles from './UserFilterMobileModal.module.scss';

const UserFilterMobileModal = ({
  children,
  isLeftBtnHidden = false,
  isRightBtnHidden = false,
  isLoading,
  leftBtnLabel = <FormattedMessage id="general.button.cancel" />,
  onLeftBtnClick,
  onRightBtnClick,
  rightBtnLabel = <FormattedMessage id="general.button.save" />,
}) => (
  <div className={styles['user-filter-modal']}>
    <Preloader isActive={isLoading} isCentered />
    <div className={styles.body}>
      {children}
    </div>
    <div className={styles.buttons}>
      {!isLeftBtnHidden && (
        <button
          disabled={isLoading}
          className={cn(styles.btn, styles['left-btn'])}
          onClick={onLeftBtnClick}
        >
          {leftBtnLabel}
        </button>
      )}
      {!isRightBtnHidden && (
        <button
          disabled={isLoading}
          className={cn(styles.btn, styles['right-btn'])}
          onClick={onRightBtnClick}
        >
          {rightBtnLabel}
        </button>
      )}
    </div>
  </div>
);

UserFilterMobileModal.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isLoading: T.bool.isRequired,
  isLeftBtnHidden: T.bool,
  isRightBtnHidden: T.bool,
  leftBtnLabel: T.oneOfType([T.node, T.string]),
  rightBtnLabel: T.oneOfType([T.node, T.string]),
  onLeftBtnClick: T.func.isRequired,
  onRightBtnClick: T.func.isRequired,
};

export default UserFilterMobileModal;
