import { useState, useRef } from 'react';
import useDidMountEffect from 'hooks/useDidMountEffect';

function usePortalState(component) {
  const [state, setState] = useState({ isVisible: false, content: null });
  const timersRef = useRef({ mountTimer: null, unMountTimer: null });
  const isComponentExist = !!component;

  useDidMountEffect(() => {
    if (timersRef.current.mountTimer) clearTimeout(timersRef.current.mountTimer);
    if (timersRef.current.unMountTimer) clearTimeout(timersRef.current.unMountTimer);
    if (component) {
      setState((prevState) => ({ ...prevState, content: component }));
      timersRef.current.mountTimer = setTimeout(() => {
        setState((prevState) => ({ ...prevState, isVisible: true }));
        clearTimeout(timersRef.current.mountTimer);
        timersRef.current.mountTimer = null;
      }, 100);
    } else {
      setState((prevState) => ({ ...prevState, isVisible: false }));
      timersRef.current.unMountTimer = setTimeout(() => {
        setState((prevState) => ({ ...prevState, content: null }));
        clearTimeout(timersRef.current.unMountTimer);
        timersRef.current.unMountTimer = null;
      }, 300);
    }
  }, [isComponentExist]);

  return [state, setState];
}

export default usePortalState;
