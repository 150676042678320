import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import Waypoint from 'components/Waypoint/Waypoint';
import CommitmentDetails from 'components/CommitmentDetails';
import { FormattedMessage } from 'react-intl';
// utils
import { formatTravelTime } from 'utils';
import classnames from 'classnames/bind';
import { getCommitmentAddressEntity, getCommitmentAddressType } from 'utils/commitmentsHelper';
// styles
import styles from '../CommitmentProfile.module.scss';

const cn = classnames.bind(styles);

const TruckingInfoSection = ({ commitments, loadType }) => (
  <div>
    {commitments.map((commitment, idx) => (
      <Waypoint type={commitment.commitment_type} isEnd={idx === commitments.length - 1} key={idx}>
        <div className="semibold">{getCommitmentAddressEntity(commitment)?.name || 'N/A'}</div>
        <CommitmentDetails commitment={commitment} type={getCommitmentAddressType(commitment, loadType)} />
        {/* est. travel time */}
        {idx !== commitments.length - 1 && (
          <Fragment>
            <div className={cn('divider', 'mb-10', 'mt-10')} />
            <div className={cn('travel-info')}>
              <i className={cn('fa fa-ep-truck', 'icon', 'mr-10')} />
              <FormattedMessage
                id="load.estTravelTime"
                values={{
                  value: formatTravelTime(commitment.travel_distance, commitment.travel_seconds),
                  primary: (text) => (
                    <span className={cn('ml-5', { primary: !!text })}>{text || 'N/A'}</span>
                  ),
                }}
              />
            </div>
            <div className={cn('divider', 'mb-10', 'mt-10')} />
          </Fragment>
        )}
      </Waypoint>
    ))}
  </div>
);

TruckingInfoSection.propTypes = {
  commitments: T.arrayOf(T.object).isRequired,
  loadType: T.string.isRequired,
};

export default TruckingInfoSection;
