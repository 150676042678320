import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import Waypoint from 'components/Waypoint/Waypoint';
import { FormattedMessage } from 'react-intl';
import PhoneNumberLink from 'components/PhoneNumberLink';
import InfoRow from 'components/InfoRow/InfoRow';
import { CommitmentStatusBadge } from 'components/StatusBadge/StatusBadge';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
import { getFullAddress, formatTravelTime } from 'utils';
import { renderCommitmentDetails } from './constants';
import { getCommitmentAddressEntity } from 'utils/commitmentsHelper';
import get from 'lodash.get';
// styles
import styles from './CommitmentCard.module.scss';

const cn = classnames.bind(styles);

const TruckingInfo = ({ load: { commitments, load_type }, commitment }) => (
  <div className={cn('trucking-info')}>
    <div className={cn('confirmation-status')}>
      <InfoRow
        label={<span className="semibold"><FormattedMessage id="general.confirmationStatus" /></span>}
        value={<CommitmentStatusBadge status={commitment.status} />}
      />
    </div>

    {commitments.map((commitment, idx) => {
      const addressEntity = getCommitmentAddressEntity(commitment);
      const schedulingContact = addressEntity?.logistics_contact;
      const schedulingContactPhone = get(schedulingContact, 'contact_phone');

      return (
        <Waypoint type={commitment.commitment_type} isEnd={idx === commitments.length - 1} key={idx}>
          {/* name and arrival time (first line) */}
          <div className="semibold">
            <InfoRow
              label={commitment.arrive_at ? moment(commitment.arrive_at).format('hh:mm A') : 'N/A'}
              value={addressEntity?.name}
            />
          </div>
          {/* address (second line) */}
          <div className={cn('subtitle')}>
            {schedulingContact && (
              <Fragment>
                <span>{get(schedulingContact, 'full_name')}</span>,&nbsp;
                {schedulingContactPhone ? <PhoneNumberLink mobileOnly phone={schedulingContactPhone} /> : 'N/A'}
              </Fragment>
            )}
            <InfoRow
              label={<FormattedMessage id="general.address" />}
              value={getFullAddress({ ...addressEntity, countryCode: addressEntity.country_code })}
            />
            {/* group/barn/dock time info */}
            {renderCommitmentDetails(commitment, load_type)}
          </div>
          {/* est.travel time  */}
          {commitments.length - 1 !== idx && (
          <Fragment>
            <div className={cn('hide-for-mobile')}>
              <div className={cn('divider', 'mt-10', 'mb-10')} />
              <div className={cn('time-info')}>
                <i className={cn('fa fa-ep-truck', 'truck', 'mr-10')} />
                <FormattedMessage
                  id="load.estTravelTime"
                  values={{
                    value: formatTravelTime(commitment.travel_distance, commitment.travel_seconds),
                    primary: (text) => (
                      <span className={cn('ml-5', { primary: !!text })}>{text || 'N/A'}</span>
                    ),
                  }}
                />
              </div>
              <div className={cn('divider', 'mt-10', 'mb-10')} />
            </div>
            <div className={cn('travel-time', 'mt-10 mb-10', 'show-for-mobile-only')}>
              <FormattedMessage
                id="load.estTravelTime"
                values={{
                  value: formatTravelTime(commitment.travel_distance, commitment.travel_seconds),
                  primary: (text) => (
                    <span className={cn('ml-5', { primary: !!text })}>{text || 'N/A'}</span>
                  ),
                }}
              />
            </div>
          </Fragment>
          )}
        </Waypoint>
      );
    })}
  </div>
);

TruckingInfo.propTypes = {
  load: T.object.isRequired,
  commitment: T.object.isRequired,
};


export default TruckingInfo;
