import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
// styles, assets
import styles from './StepperCSV.module.scss';
import checkStep from '../../../../public/images/check-step.svg';

const steps = [
  { label: <FormattedMessage id="general.type" /> },
  { label: <FormattedMessage id="general.upload" /> },
  { label: <FormattedMessage id="general.map" /> },
  { label: <FormattedMessage id="general.pageTitle.results" /> },
];

const getStepper = (activeStep) => {
  return (steps.map((step, index) => (
    <div key={index} className={styles.stepCSV}>
      <div className={cn(styles.icon, (activeStep >= index) && styles.checkStep)}>
        {(activeStep > index || activeStep === 3) && <img src={checkStep} alt="check" />}
        <div className={cn(styles.divider, (activeStep > index) && styles.checkStepDivider)} />
      </div>
      <div className={styles.stepLabel}>
        {step.label}
      </div>
    </div>
  )));
};

const StepperCSV = ({ activeStep }) => (
  <div className={styles.StepperCSV}>
    {getStepper(activeStep)}
  </div>
);


StepperCSV.propTypes = {
  activeStep: T.number.isRequired,
};

export default StepperCSV;
