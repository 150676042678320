import React, { Component } from 'react';
import T from 'prop-types';
// components
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// utils
import { connect } from 'react-redux';
import getValue from 'lodash.get';
import { renderMentionsText } from 'utils';
import { fetchTranslate } from 'utils/fetchTranslation';
import unescape from 'lodash.unescape';
import classnames from 'classnames/bind';
// styles
import style from './Translation.module.scss';
// translate
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(style);

class Translation extends Component {

  state = {
    translatedText: '',
    toTranslate: '',
    hideTranslation: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { editMode } = this.props;
    const { toTranslate } = this.state;
    if (editMode && !nextProps.editMode && nextProps.text !== toTranslate) {
      this.translateText();
    }
  }

  translateText = () => {
    const { currentUser: { language }, text } = this.props;

    fetchTranslate(language, text)
      .then((response) => {
        this.setState({
          translatedText: unescape(getValue(response, 'resource.data.translations[0].translatedText', '')),
          toTranslate: text,
        });
      })
      .catch((error) => {
        return error.then(({ error }) => toastResponseErrors({ error: error.message || 'Error to translate' }));
      });
  };

  showTranslation = () => {
    this.setState(({ hideTranslation }) => ({ hideTranslation: !hideTranslation }));
  };

  render() {
    const { children, text, editMode } = this.props;
    const { translatedText, hideTranslation } = this.state;
    const buttonMethod = () => {
      if (!translatedText) return this.translateText;
      return this.showTranslation;
    };

    return (
      !editMode
        ? (
          <span className={cn('Translation')}>
            <span>{children}</span>
            {translatedText && !hideTranslation && (
              <div className={cn('translation-text')}>
                {renderMentionsText(translatedText)}
                <span className={cn('google-translate')}>&nbsp;&mdash;
                  <FormattedMessage id="general.translatedByGoogle" />
                </span>
              </div>
            )}
            {text && (
              <div className={cn('translation-btn')} onClick={buttonMethod()}>
                <FormattedMessage id={`general.${(hideTranslation || !translatedText) ? 'see' : 'hide'}Translation`} />
              </div>
            )}
          </span>
        )
        : <span>{children}</span>
    );
  }
}

Translation.propTypes = {
  children: T.any,
  text: T.string,
  currentUser: T.object,
  editMode: T.bool,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  }),
)(Translation);
