import React, { useState } from 'react';
import T from 'prop-types';
// components
import CommitmentRow from './CommitmentRow';
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
// utils
import cn from 'classnames';
// styles
import styles from './CommitmentPicker.module.scss';

const CommitmentPicker = ({ className, commitments, handleChange, isLoading = false }) => {
  const [search, setSearch] = useState('');

  const onCommitmentAssign = (id) => {
    const updatedCommitments = commitments.map((commitment) => {
      return commitment.id === id ? { ...commitment, assigned: !commitment.assigned } : commitment;
    });
    handleChange(updatedCommitments);
  };

  const filteredCommitments = search
    ? commitments.filter(({ loading: { name } }) => name.toLowerCase().includes(search.toLocaleLowerCase()))
    : commitments;

  return (
    <div className={cn({ [className]: !!className })}>
      {!!commitments.length && <SearchBox onChange={setSearch} wide />}
      <div className="mt-10">
        <div className={styles['commitments-list']}>
          {filteredCommitments.map((commitment) => (
            <CommitmentRow
              key={commitment.id}
              onClick={() => onCommitmentAssign(commitment.id)}
              isSelected={commitment.assigned}
              commitment={commitment}
            />
          ))}
          <NothingBox display={!filteredCommitments.length} isLoading={isLoading}>
            <FormattedMessage tagName="h1" id="general.nothingBox.noLoads" />
          </NothingBox>
        </div>
      </div>
    </div>
  );
};

CommitmentPicker.propTypes = {
  handleChange: T.func.isRequired,
  commitments: T.arrayOf(T.object).isRequired,
  className: T.string,
  isLoading: T.bool,
};

export default CommitmentPicker;
