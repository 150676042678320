import React from 'react';
import T from 'prop-types';
// redux-form
import { connect } from 'react-redux';
import { formValueSelector, change, reduxForm } from 'redux-form';
// components
import AdminFormFields from 'components/ReduxFormFields/AdminFormFields/AdminFormFields';
import Button from 'components/Button';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
// constants
import { companyTenantRoles } from 'constants.js';

const CreateAdminForm = ({ handleSubmit, pristine, submitting, status, ...props }) => {
  const disabledBtn = pristine || submitting || status === 'already_admin' || !props.email || !props.role;
  return (
    <form onSubmit={handleSubmit}>

      <AdminFormFields
        hasLanguageField
        path="/invites/admins"
        roles={companyTenantRoles}
        rolesTitle={<FormattedMessage id="component.userFieldsMethods.userType" />}
        {...props}
      />

      <div className="row margin-top-2">
        <div className="small-12 column">
          <Button primary className="wider" type="submit" disabled={disabledBtn}>
            <FormattedMessage id={`general.button.${status === 'found' ? 'inviteAdmin' : 'createAdmin'}`} />
          </Button>
          <Link to="/admin/my-company/admins" className="button btn-link margin-left-1">
            <FormattedMessage id="general.button.cancel" />
          </Link>
        </div>
      </div>
    </form>
  );
};

const selector = formValueSelector('create-admin');

const formComponent = reduxForm({
  form: 'create-admin',
})(CreateAdminForm);

CreateAdminForm.propTypes = {
  handleSubmit: T.func.isRequired,
  change: T.func.isRequired,
  email: T.string,
  status: T.string,
  first_name: T.string,
  last_name: T.string,
  pristine: T.bool,
  submitting: T.bool,
  role: T.string,
};

export default connect((state) => ({
  email: selector(state, 'email'),
  status: selector(state, 'user_status'),
  role: selector(state, 'user_role'),
  first_name: selector(state, 'first_name'),
  last_name: selector(state, 'last_name'),
}), { change })(formComponent);
