import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchUserProfile } from 'reducers/profiles';
// components
import EditSuperAdminForm from 'components/SuperAdmin/SuperAdmin/EditSuperAdminForm';
import FormErrorsBox from 'components/FormErrorsBox';
import UserProfileHeader from 'components/ProfileHeader/UserProfileHeader/UserProfileHeader';
import Preloader from 'components/Preloader';
// endpoints
import { updateSuperAdmin } from 'endpoints/superAdmin';
// utils
import { toastr } from 'react-redux-toastr';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import pick from 'lodash.pick';
// styles
import './SuperAdminEdit.scss';

class SuperAdminEdit extends Component {

  componentDidMount() {
    const { fetchUserProfile, params: { id } } = this.props;
    fetchUserProfile(`/admin/admin_users/${id}`).catch(toastResponseErrors);
  }

  handleFormSubmit = (data) => {
    const { params: { id } } = this.props;
    const resource = {
      ...pick(data, ['first_name', 'last_name']),
      email: (data.email || '').toLowerCase(),
    };
    return updateSuperAdmin(id, resource)
      .then(() => {
        this.context.router.push('/super-admin/admin-users');
        toastr.success('', 'Super Admin was successfully updated', { icon: <i className="fa fa-thumbs-up" /> });
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { user, isLoading, params: { id } } = this.props;
    const initialValues = user?.id === +id ? user : null;
    const customErrors = [
      { key: 'email', error: 'This user already exists in your system. Please choose a different email.' },
    ];
    return (
      <div className="small-12 column SuperAdminEdit">
        <UserProfileHeader
          actualUserId={Number(id)}
          className="admin-profile-header"
          isLoading={isLoading}
          showStatus
          user={user}
        />
        <section>
          <div className="panel overflow-visible">
            <div className="panel-heading column-on-mobile">
              <h1 className="lighter">Edit Super Admin</h1>
            </div>
            <div className="panel-body">
              <section>
                <div className="row">
                  <div className="small-12 medium-10 large-8 column">
                    <Preloader isActive={isLoading} />
                    <FormErrorsBox formName="edit-super-admin" customErrors={customErrors} />
                    <EditSuperAdminForm initialValues={initialValues} onSubmit={this.handleFormSubmit} />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

SuperAdminEdit.contextTypes = {
  router: T.object.isRequired,
};

SuperAdminEdit.propTypes = {
  params: T.object.isRequired,
  user: T.object.isRequired,
  isLoading: T.bool.isRequired,
  fetchUserProfile: T.func.isRequired,
};

export default connect(
  (state) => ({
    user: state.profiles.user.data,
    isLoading: state.profiles.user.isLoading,
  }), { fetchUserProfile }
)(SuperAdminEdit);
