import React, { Component } from 'react';
import T from 'prop-types';
// recompose, redux
import { branch, renderNothing, compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
// components
import Collapse from 'components/Collapse/Collapse';
import UserPanel from 'components/UserPanel/UserPanel';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import Translation from 'components/Translation';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
import { createSelector } from 'reselect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './DiagnosesCollapse.module.scss';

const cn = classnames.bind(styles);

const diagnosisTypeLabels = {
  lab_confirmed: <FormattedMessage id="general.labConfirmDiagnosis" />,
  clinical: <FormattedMessage id="general.clinicalDiagnosis" />,
  presumed: <FormattedMessage id="general.presumedDiagnosis" />,
  gross_only: <FormattedMessage id="general.grossOnlyDiagnosis" />,
};

class DiagnosisCollapse extends Component {

  state = {
    isLoaded: false,
  };

  toggleCollapse = () => {
    const { isLoaded } = this.state;
    const { checkupId, fetchCheckupDiagnoses } = this.props;
    if (!isLoaded) {
      fetchCheckupDiagnoses(checkupId)
        .then(() => this.setState({ isLoaded: true }))
        .catch(toastResponseErrors);
    }
  };

  renderUserName = (user) => {
    const { currentUser } = this.props;
    if (user.id === currentUser.id) return <FormattedMessage id="general.you" />;
    return user.name;
  };

  render() {
    const { className, diagnosisCount, titleClassName, renderDiagnosisOptions, isDiagnosesLoading, data } = this.props;
    const list = data ? data.diagnosesList : [];
    return (
      <Collapse
        className={className}
        titleClassName={titleClassName}
        title={<FormattedMessage tagName="b" id="general.diagnosesWithCount" values={{ count: diagnosisCount }} />}
        onToggle={this.toggleCollapse}
        isLoading={isDiagnosesLoading}
      >
        <div className={cn('diagnoses-body')}>
          {list.map(({ id, created_by, modified_by, created_at, diagnosis, diagnosis_type, note }) => (
            <UserPanel
              key={id}
              user={modified_by || created_by}
              label={<FormattedMessage id="general.diagnosedPigs" />}
              customTimestamp={(
                <TimeAgo
                  date={created_at}
                  className={cn('time-ago')}
                />
              )}
              customUserName={this.renderUserName(modified_by || created_by)}
              userImageSize={35}
              className={cn('diagnosis-panel')}
              dropdownOptions={renderDiagnosisOptions(id)}
            >
              <div className={cn('diagnosis-info-row')}>
                <span className="semibold">{diagnosis.name}</span>
                <span>{diagnosisTypeLabels[diagnosis_type]}</span>
              </div>
              {note && (
                <div className={cn('diagnosis-note-row')}>
                  <span className="semibold"><FormattedMessage id="general.notes" />: </span>
                  <Translation text={note}>
                    {note}
                  </Translation>
                </div>
              )}
            </UserPanel>
          ))}
        </div>
      </Collapse>
    );
  }
}

DiagnosisCollapse.defaultProps = {
  renderDiagnosisOptions: () => [],
};

DiagnosisCollapse.propTypes = {
  currentUser: T.object.isRequired,
  renderDiagnosisOptions: T.func,
  fetchCheckupDiagnoses: T.func,
  data: T.object,
  isDiagnosesLoading: T.bool,
  checkupId: T.number.isRequired,
  diagnosisCount: T.number,
  className: T.string,
  titleClassName: T.string,
};

const checkupDiagnosesSelector = createSelector(
  (state) => state.dailyCheckup.checkupDiagnoses.checkups,
  (state, props) => props.checkupId,
  (checkups, id) => checkups.find(({ checkupId }) => (checkupId === id)),
);

const enhance = compose(
  connect(
    (state, props) => ({
      data: checkupDiagnosesSelector(state, props),
      isDiagnosesLoading: state.dailyCheckup.checkupDiagnoses.isLoading,
    }), {
      fetchCheckupDiagnoses,
    },
  ),
  branch(
    ({ diagnosisCount }) => !diagnosisCount,
    renderNothing,
  ),
);

export default enhance(DiagnosisCollapse);
