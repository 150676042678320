import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import MortalityReasonForm from './components/MortalityReasonForm';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
// utils
import handleFormErrors from 'utils/handleFormErrors';
import { showToastrMessage } from 'utils';
// endpoints
import { createMortalityReason } from 'endpoints/admin/healthVariables';

const initialValues = { name: [{ lang: 'en' }] };

class CreateMortalityReason extends Component {

  createNewMortalityReason = (data) => {
    const { router } = this.context;
    const resource = {
      code: data.code,
      name: data.name.reduce((prev, curr) => ({ ...prev, [curr.lang]: curr.value.trim() }), {}),
    };

    return createMortalityReason(resource)
      .then(() => {
        showToastrMessage('component.toastr.reason.created');
        router.push('/admin/health-variables/mortality-reasons');
      })
      .catch(handleFormErrors);
  };

  render() {
    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="container.admin.healthVariables.reasons.add" />} />
          <Panel.Body noPadding>
            <div className="row">
              <section className="small-12 medium-10 large-8 column">
                <FormErrorsBox
                  formName="create-mortality-reason"
                />
                <MortalityReasonForm
                  onSubmit={this.createNewMortalityReason}
                  initialValues={initialValues}
                />
              </section>
            </div>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

CreateMortalityReason.contextTypes = {
  router: T.object,
};

export default CreateMortalityReason;
