import groupColumns from './groups';
import userColumns from './users';
import barnColumns from './barns';
import farmColumns from './farms';

export default function getTableColumns(type, status) {
  const columns = {
    groups: groupColumns(status),
    users: userColumns(status),
    barns: barnColumns(status),
    farms: farmColumns(status),
    companies: farmColumns(status),
  };
  return columns[type];
}
