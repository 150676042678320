import React from 'react';
import T from 'prop-types';
// components
import Collapse from 'react-collapse';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './PreferenceCollapse.module.scss';

const cn = classnames.bind(styles);

const PreferenceCollapse = ({ title, subtitle, icon, isOpened, isDisabled, onToggle, className, children }) => (
  <div className={cn('preference-collapse', { [className]: !!className, 'disabled': isDisabled })}>
    <div className={cn('collapse-title-box')}>
      {icon &&
        <div className={cn('icon-column', 'hide-for-large')}>{icon}</div>}
      <div className={cn('title-column')}>
        <div className={cn('title')}>
          {icon && <div className={cn('icon-column', 'show-for-large')}>{icon}</div>}
          {title}
        </div>
        {!isOpened && subtitle &&
          <div className={cn('subtitle')}>{subtitle}</div>}
      </div>
      <div className={cn('edit-btn-column')}>
        <button className={cn('edit-btn')} onClick={onToggle} disabled={isDisabled}>
          <FormattedMessage id={`general.${isOpened ? 'close' : 'button.edit'}`} />
        </button>
      </div>
    </div>

    <Collapse isOpened={isOpened}>
      {children}
    </Collapse>
  </div>
);

PreferenceCollapse.propTypes = {
  title: T.oneOfType([T.object, T.string]).isRequired,
  subtitle: T.oneOfType([T.object, T.string, T.number]),
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  icon: T.node,
  className: T.string,
  isOpened: T.bool.isRequired,
  isDisabled: T.bool,
  onToggle: T.func.isRequired,
};

PreferenceCollapse.defaultProps = {
  isDisabled: false,
  subtitle: null,
};

export default PreferenceCollapse;
