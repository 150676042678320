import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
const OPEN_PORTAL = 'portal/OPEN_PORTAL';
const CLOSE_PORTAL = 'portal/CLOSE_PORTAL';

const initialState = {
  component: null,
  options: {},
};

// ------------------------------------
// Actions
// ------------------------------------
export const openPortal = createAction(OPEN_PORTAL, (component, options = {}) => ({ component, options }));

export const closePortal = createAction(CLOSE_PORTAL);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [OPEN_PORTAL]: (state, { payload }) => payload,
  [CLOSE_PORTAL]: (state) => ({
    ...initialState,
    options: state.options,
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
