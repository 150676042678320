import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import InputLabel from 'components/Input/InputLabel';
import Input from 'components/Input';
import CircleCheckbox from 'components/CircleCheckbox';
import UserFilterMobileModal from './UserFilterMobileModal';
// utils
import cn from 'classnames';
// styles
import styles from './UserFilterMobileModal.module.scss';

const SaveSegmentConfirmationModal = ({ initialName = '', isSaving, onSave, onCancel }) => {
  const [{ name, isUpdating }, setState] = useState({ name: initialName, isUpdating: !!initialName });
  const { formatMessage } = useIntl();
  const nameLabel = formatMessage({ id: 'general.name' });

  const handleChangeName = (e) => {
    const { value } = e.target;
    setState((prevState) => ({ ...prevState, name: value }));
  };

  const handleChangeCheckBox = ({ target: { name } }) => {
    setState((prevState) => ({
      ...prevState,
      isUpdating: name === 'existing-segment',
    }));
  };

  return (
    <UserFilterMobileModal
      isRightBtnHidden={!name}
      isLoading={isSaving}
      onLeftBtnClick={onCancel}
      onRightBtnClick={() => onSave(name, isUpdating)}
    >
      {!!initialName && (
        <Fragment>
          <CircleCheckbox
            className={cn(styles['segment-checkbox'], { [styles['not-checked']]: !isUpdating })}
            type="radio"
            name="existing-segment"
            checked={isUpdating}
            onChange={handleChangeCheckBox}
            label={(
              <FormattedMessage tagName="b" id="component.saveFilterModal.updateExistingSegment" />
            )}
          />
          <CircleCheckbox
            className={cn(styles['segment-checkbox'], { [styles['not-checked']]: isUpdating })}
            type="radio"
            name="new-segment"
            checked={!isUpdating}
            onChange={handleChangeCheckBox}
            label={(
              <FormattedMessage tagName="b" id="component.saveFilterModal.createNewSegment" />
            )}
          />
        </Fragment>
      )}
      {!initialName && (
        <InputLabel>
          {nameLabel}
        </InputLabel>
      )}
      <Input disabled={isSaving} value={name} placeholder={nameLabel} onChange={handleChangeName} />
    </UserFilterMobileModal>
  );
};

SaveSegmentConfirmationModal.propTypes = {
  initialName: T.string,
  isSaving: T.bool.isRequired,
  onCancel: T.func.isRequired,
  onSave: T.func.isRequired,
};

export default SaveSegmentConfirmationModal;
