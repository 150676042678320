import React from 'react';
import T from 'prop-types';
import Hint from 'components/HintTooltip';
import TruncateMarkup from 'react-truncate-markup';
import cn from 'classnames';

const TruncateHint = ({ name, showName = '', className = '', showBelow, hintClassName, children }) => {
  return (
    <span className={cn('position-relative', className)}>
      <TruncateMarkup
        lines={1}
        ellipsis={<span>&nbsp;...</span>}
      >
        <span>{showName || name}</span>
      </TruncateMarkup>
      <Hint className={hintClassName} attachToBody showBelow={showBelow} arrowed>{children || name}</Hint>
    </span>
  );
};

TruncateHint.propTypes = {
  name: T.string,
  className: T.string,
  hintClassName: T.string,
  showBelow: T.bool,
  showName: T.oneOfType([T.string, T.node]),
  children: T.oneOfType([T.string, T.node]),
};

export default TruncateHint;
