import React, { Component, Fragment } from 'react';
import T from 'prop-types';
// components
import { formValueSelector, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import CreateUserFormFields from 'components/ReduxFormFields/UserFormFields/UserFormFields';
import CircleCheckbox from 'components/CircleCheckbox';
import Button from 'components/Button';
import Field from 'components/Field';
import NameWithAddrressForm from './NameWithAddrressForm';
import PhoneInputField from 'components/ReduxFormFields/PhoneInputField/PhoneInputField';
// redux
import { connect } from 'react-redux';
// utils
import cn from 'classnames';

class TruckingCompanyForm extends Component {

  state = {
    isFormValid: false,
  };

  setFormValidity = (isValid) => this.setState({ isFormValid: isValid });

  toggleUserWant = () => {
    this.props.change('address-form', 'has_user', !this.props.hasUser);
  };

  render() {
    const { isFormValid } = this.state;
    const { onSubmit, name, email, hasUser, addressObj, canCreateUser, isEditForm,
      hasLocationDefining, initialValues } = this.props;
    const allowSubmit = !!name && (!!hasUser === !!email) && !!addressObj && !!addressObj.city;

    return (
      <NameWithAddrressForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        hasLocationDefining={hasLocationDefining}
        nameLabelKey="general.truckingCompanyName"
      >
        {({ handleSubmit, isSubmitting }) => (
          <Fragment>
            {/* TRUCKING COMPANY PHONE NUMBER */}
            <div className="row margin-top-1">
              <div className="small-12 column">
                <label>
                  <Field
                    label={<FormattedMessage id="component.userProfile.phone" />}
                    name="phone"
                    component={PhoneInputField}
                  />
                </label>
              </div>
            </div>
            {/* TOTAL GOOSE TRAILERS */}
            <div className="row">
              <div className="small-12 column">
                <label>
                  <Field
                    label={<FormattedMessage id="general.totalGooseTrailers" />}
                    placeholderKey="general.totalGooseTrailers"
                    name="goose_trailer_count"
                    maxLength={5}
                  />
                </label>
              </div>
            </div>

            {/* TOTAL SEMI TRAILERS */}
            <div className="row">
              <div className="small-12 column">
                <label>
                  <Field
                    label={<FormattedMessage id="general.totalSemiTrailers" />}
                    placeholderKey="general.totalSemiTrailers"
                    name="semi_trailer_count"
                    maxLength={5}
                  />
                </label>
              </div>
            </div>

            {canCreateUser && (
              <div className="checkbox-container">

                <div className={cn('checkbox-row', { 'selected': hasUser })}>
                  <div className="row">
                    <div className="small-12 column">
                      <CircleCheckbox
                        value="false"
                        onClick={this.toggleUserWant}
                        checked={hasUser}
                        label={<FormattedMessage id="component.truckingCompanyForm.createWithAdmin" />}
                      />
                    </div>
                  </div>

                  {/* USER FORM */}
                  {hasUser && (
                    <div className="row">
                      <div className="small-12 medium-12 column mt-20">
                        <CreateUserFormFields
                          wrap="user"
                          setFormValidity={this.setFormValidity}
                          {...this.props}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* BUTTON */}
            <div className="button-submit">
              <Button
                onClick={handleSubmit}
                primary
                disabled={isSubmitting || !allowSubmit || (hasUser && !isFormValid)}
              >
                {isEditForm
                  ? <FormattedMessage id="general.button.saveChanges" />
                  : <FormattedMessage id={`general.button.createTruckingCompany${hasUser ? 'AndUser' : ''}`} />
                }
              </Button>
              {!isEditForm && (
                <Link to="/admin/trucking-companies" className="button btn-link margin-left-1">
                  <FormattedMessage id="general.button.cancel" />
                </Link>
              )}
            </div>
          </Fragment>
        )}
      </NameWithAddrressForm>
    );
  }
}

TruckingCompanyForm.propTypes = {
  onSubmit: T.func.isRequired,
  change: T.func.isRequired,
  hasUser: T.bool,
  initialValues: T.object,
  canCreateUser: T.bool,
  isEditForm: T.bool,
  name: T.string,
  hasLocationDefining: T.bool,
  email: T.string,
  addressObj: T.object,
};

const selector = formValueSelector('address-form');
export default connect(
  (state) => ({
    name: selector(state, 'name'),
    addressObj: selector(state, 'addressObj'),
    hasUser: selector(state, 'has_user'),
    email: selector(state, 'user[email]'),
  }), {
    change,
  }
)(TruckingCompanyForm);
