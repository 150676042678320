import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { openPortal } from 'reducers/portal';
// components
import { DateRangePicker as ReactDatesPicker } from 'react-dates';
// utils
import cn from 'classnames';
import withOutsideClick from 'react-onclickoutside';
// constants
import { START_DATE, END_DATE } from 'react-dates/constants';
// styles
import 'react-dates/lib/css/_datepicker.css';
import './DatesFilter.scss';

class DatesFilter extends Component {

  constructor() {
    super();
    this.state = {
      focused: false,
      focusedInput: null,
    };
  }

  setFocus = (e) => {
    if (!this.state.focused) {
      const focusedInput = (e.target.name === 'endDate') ? 'endDate' : 'startDate';
      this.setState({ focused: true, focusedInput });
      this.props.onOpen();
    }
  };

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
    this.props.onOpen();
  };

  handleClickOutside = () => {
    this.setState({ focused: false });
  };

  render() {
    const { className, startDate, endDate, onDatesChange, isOutsideRange, startDatePlaceholderText,
      endDatePlaceholderText, singlePlaceholder, minimumNights } = this.props;
    const { focused, focusedInput } = this.state;
    const isInitialState = !focused && !startDate && !endDate;

    return (
      <div
        onClick={this.setFocus}
        className={cn('DatesFilter', {
          [className]: !!className,
          focused,
          'hasValues': startDate || endDate,
          'initialState': isInitialState,
        })}
      >
        <ReactDatesPicker
          readOnly
          startDate={startDate}
          startDatePlaceholderText={isInitialState ? singlePlaceholder : startDatePlaceholderText}
          endDatePlaceholderText={endDatePlaceholderText}
          endDate={endDate}                   // momentPropTypes.momentObj or null,
          startDateId={START_DATE}
          endDateId={END_DATE}
          onDatesChange={onDatesChange}       // PropTypes.func.isRequired,
          focusedInput={focusedInput}         // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={this.onFocusChange}
          isOutsideRange={isOutsideRange}
          hideKeyboardShortcutsPanel
          showClearDates
          reopenPickerOnClearDates
          displayFormat="MMM DD, YYYY"
          customArrowIcon={<i className="fa fa-minus" />}
          customInputIcon={<i className="fa fa-angle-down" />}
          minimumNights={minimumNights}
        />
      </div>
    );
  }
}

DatesFilter.defaultProps = {
  className: '',
  minimumNights: 0,
};

DatesFilter.propTypes = {
  className: T.string,
  startDate: T.oneOfType([T.object, T.string]),
  endDate: T.oneOfType([T.object, T.string]),
  singlePlaceholder: T.oneOfType([T.string, T.object]),
  startDatePlaceholderText: T.oneOfType([T.string, T.object]),
  endDatePlaceholderText: T.oneOfType([T.string, T.object]),
  onDatesChange: T.func.isRequired,
  onOpen: T.func.isRequired,
  isOutsideRange: T.func,
  minimumNights: T.number,
};

export default compose(
  connect(null, { openPortal }),
  withOutsideClick,
)(DatesFilter);
