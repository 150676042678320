import clamp from 'lodash.clamp';

const xOrds = ['e', 'w'];
const yOrds = ['n', 's'];
const xyOrds = ['nw', 'ne', 'se', 'sw'];
const xInversedOrds = ['nw', 'sw', 'w'];
const yInversedOrds = ['nw', 'n', 'ne'];

export const minWidth = 5; // 5% from image
export const getMinHeight = (pxWidth, pxHeigth) => ((pxWidth / pxHeigth) * minWidth);

export function getTagStyles(ord, startedStyles, currentStyles, startedPagePos, currentPagePos, imageZone) {
  const { startedWidth, startedTop, startedLeft, startedHeight } = startedStyles;
  const { startedPageX, startedPageY } = startedPagePos;
  const { currentPageX, currentPageY } = currentPagePos;

  const xInversed = xInversedOrds.includes(ord);
  const yInversed = yInversedOrds.includes(ord);

  const coordsDiffX = !xInversed ? (currentPageX - startedPageX) : (startedPageX - currentPageX);
  const coordsDiffY = !yInversed ? (currentPageY - startedPageY) : (startedPageY - currentPageY);

  const cannotMoveLeft = (coordsDiffX >= 0) && (currentStyles.left === 0);
  const cannotMoveRight = (coordsDiffX >= 0) && (currentStyles.left + currentStyles.width === 100);
  const cannotMoveTop = (coordsDiffY >= 0) && (currentStyles.top === 0);
  const cannotMoveBottom = (coordsDiffY >= 0) && (currentStyles.top + currentStyles.height === 100);

  const cannotMoveLeftW = cannotMoveLeft && (ord === 'w');
  const cannotMoveRightE = cannotMoveRight && (ord === 'e');
  const cannotMoveTopN = cannotMoveTop && (ord === 'n');
  const cannotMoveBottomS = cannotMoveBottom && (ord === 's');

  if (cannotMoveLeftW || cannotMoveRightE || cannotMoveTopN || cannotMoveBottomS) return currentStyles;

  const widthDiff = (coordsDiffX / imageZone.offsetWidth) * 100;
  const heightDiff = (coordsDiffY / imageZone.offsetHeight) * 100;

  const minHeight = getMinHeight(imageZone.offsetWidth, imageZone.offsetHeight);

  const newWidth = clamp(startedWidth + widthDiff, minWidth, 100);
  const newLeft = xInversed ? startedLeft + startedWidth - newWidth : currentStyles.left;
  const newHeight = clamp(startedHeight + heightDiff, minHeight, 100);
  const newTop = yInversed
    ? clamp(startedTop + startedHeight - newHeight, 0, 100 - newHeight)
    : currentStyles.top;

  const left = clamp(newLeft, 0, 100 - newWidth);
  const top = clamp(newTop, 0, 100 - newHeight);

  if (xyOrds.includes(ord)) {

    const height = cannotMoveTop || cannotMoveBottom ? currentStyles.height : newHeight;
    const top = clamp(newTop, 0, 100 - height);

    const width = cannotMoveLeft || cannotMoveRight ? currentStyles.width : newWidth;
    const left = clamp(newLeft, 0, 100 - width);

    return { width, left, height, top };
  }

  if (xOrds.includes(ord)) {
    return { ...currentStyles, width: newWidth, left };
  }

  if (yOrds.includes(ord)) {
    return { ...currentStyles, height: newHeight, top };
  }
  return {};
}

export function getTooltipStyles(tooltipOffset, triangleOffset) {
  if (!tooltipOffset && !triangleOffset) return null;
  if (tooltipOffset && !triangleOffset) return { left: tooltipOffset };
  return { left: tooltipOffset, '--triangleOffset': triangleOffset + 'px' };
}

export function getTooltipSize(tooltipNode) {
  const { marginRight, marginLeft, marginBottom, marginTop } = window.getComputedStyle(tooltipNode || {});
  const { offsetHeight, offsetWidth } = tooltipNode;
  return {
    tooltipWidth: offsetWidth + parseInt(marginLeft, 10) + parseInt(marginRight, 10),
    tooltipHeight: offsetHeight + parseInt(marginTop, 10) + parseInt(marginBottom, 10),
  };
}

function getTriangleOffset(cuttingRight, tipOffset, tipW, tagW) {
  if (cuttingRight) {
    return (tipOffset < 0) ? -tipOffset + tipW / 2 : null;
  }
  return (tipOffset > tagW) ? -tipOffset + tipW + tipW / 2 : null;
}

export function getTooltipPosition(tooltipSize, tagSize, availAreas) {
  const { tooltipWidth, tooltipHeight } = tooltipSize;
  const { tagWidth, tagHeight } = tagSize;
  const { availTopArea, availLeftArea, availBottomArea, availRightArea } = availAreas;

  // check vertical position (when tooltip positioning top/bottom)

  const widthIndent = (tagWidth - tooltipWidth) / 2; // distance between tooltip left side & tag left side
  const cuttingLeftV = (availLeftArea + widthIndent) < 0;
  const cuttingRightV = (availRightArea + widthIndent) < 0;
  const cuttingTopV = (availTopArea - tooltipHeight) < 0;
  const cuttingBottomV = (availBottomArea - tooltipHeight) < 0;

  if (!cuttingLeftV && !cuttingRightV && !cuttingTopV) return { position: 'top' };
  if (!cuttingLeftV && !cuttingRightV && !cuttingBottomV) return { position: 'bottom' };

  // check horizontal position (when tooltip positioning left/right)

  const heightIndent = (tagHeight - tooltipHeight) / 2; // distance between tooltip top side & tag top side
  const cuttingLeftH = (availLeftArea - tooltipWidth) < 0;
  const cuttingRightH = (availRightArea - tooltipWidth) < 0;
  const cuttingTopH = (availTopArea + heightIndent) < 0;
  const cuttingBottomH = (availBottomArea + heightIndent) < 0;

  if (!cuttingTopH && !cuttingBottomH && !cuttingLeftH) return { position: 'left' };
  if (!cuttingTopH && !cuttingBottomH && !cuttingRightH) return { position: 'right' };

  const fitVertically = (tagWidth + availRightArea + availLeftArea) > tooltipWidth;

  if (fitVertically) {
    // if fit vertically tip can be cut right OR left
    // 15px - left/right indent
    const tooltipOffset = cuttingRightV
      ? tagWidth + availRightArea - tooltipWidth / 2 - 15
      : -availLeftArea + tooltipWidth / 2 + 15;

    const triangleOffset = getTriangleOffset(cuttingRightV, tooltipOffset, tooltipWidth, tagWidth);

    if (!cuttingTopV) {
      return { position: 'top', tooltipOffset, triangleOffset };
    }

    if (!cuttingBottomV) {
      return { position: 'bottom', tooltipOffset, triangleOffset };
    }
  }

  // 15px - top/right indent
  const tooltipOffset = availRightArea < 0
    ? tagWidth + availRightArea - tooltipWidth - 15
    : tagWidth - tooltipWidth - 15;

  // TODO: need to add ability to set top tooltip offset
  return { position: 'default', tooltipOffset };
}
