import React, { PureComponent } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateSource } from 'reducers/sources/sourceProfile';
// components
import { FormattedMessage } from 'react-intl';
import SourceEditForm from './components/SourceEditForm';
import SourceCommingledEditForm from './components/SourceCommingledEditForm';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
// utils
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import handleFormErrors from 'utils/handleFormErrors';
import { showToastrMessage } from 'utils';

const addressFields = ['address', 'city', 'state', 'country_code', 'zipcode', 'lat', 'lng'];

class SourceEdit extends PureComponent {

  getInitialFormValues = () => {
    const { source, params: { id } } = this.props;
    if (!isEmpty(source) && parseInt(id, 10) === source.id) {
      return {
        farm_attributes: {
          addressObj: pick(source, addressFields),
          name: source.name,
          manager_id: source.manager_id,
          federal_premise_id: source.prem_id,
          farm_type: source.source_type,
        },
      };
    }
    return null;
  };

  getInitialCommingledFormValues = () => {
    const { source, params: { id } } = this.props;
    if (!isEmpty(source) && parseInt(id, 10) === source.id) {
      return {
        name: source.name,
        sources: source.commingled_children.map((item) => ({ value: item.id, label: item.name })),
      };
    }

    return null;
  };

  updateSource = (data) => {
    const { source } = this.props;
    const { router } = this.context;
    const { updateSource, params: { id } } = this.props;
    const sendData = source.source_type === 'co'
      ? { name: data.name }
      : { farm_attributes: { ...data.farm_attributes, ...data.farm_attributes.addressObj } };
    return updateSource(id, sendData)
      .then(() => {
        showToastrMessage('component.toastr.source.updated');
        router.push('/sources');
      })
      .catch(handleFormErrors);
  };

  render() {
    const { source, isLoading } = this.props;

    if (!source) return <Preloader isActive={isLoading} />;

    const initialValues = source.source_type === 'co'
      ? this.getInitialCommingledFormValues()
      : this.getInitialFormValues();

    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="component.sourceEdit.editExternalSource" />} />
          <Panel.Body noPadding>
            <Preloader isActive={isLoading} />
            <div className="row pt-20">
              {source.source_type === 'co'
                ? <SourceCommingledEditForm onSubmit={this.updateSource} initialValues={initialValues} />
                : <SourceEditForm onSubmit={this.updateSource} initialValues={initialValues} />
              }
            </div>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

SourceEdit.contextTypes = {
  router: T.object.isRequired,
};

SourceEdit.propTypes = {
  updateSource: T.func.isRequired,
  params: T.object.isRequired,
  source: T.object,
  isLoading: T.bool,
};

export default connect(
  (state) => ({
    source: state.sources.profile.resource,
    isLoading: state.sources.profile.isLoading,
  }),
  { updateSource }
)(SourceEdit);
