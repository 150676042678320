import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { createSharedholderGroup } from 'reducers/admin/shareholderGroups/groupShow';
// components
import { FormattedMessage } from 'react-intl';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
import ShareholderGroupForm from 'containers/Admin/ShareholderGroups/components/ShareholderGroupForm';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const customErrors = [
  { key: 'name', error: <FormattedMessage id="general.customErrors.shGroup" /> },
];

class ShareholderGroupCreate extends Component {

  createNewGroup = (data) => {
    const { router } = this.context;
    const { createSharedholderGroup } = this.props;

    return createSharedholderGroup(data)
      .then(({ value: { id } }) => {
        showToastrMessage('component.toastr.shGroup.created');
        router.push(`/admin/shareholder-groups/${id}`);
      })
      .catch(toastResponseErrors);
  };

  render() {
    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.createShareholderGroup" />} />
          <Panel.Body>
            <div className="row">
              <div className="small-12 medium-10 large-8 column">
                <FormErrorsBox
                  excludedFields={['user']}
                  formName="shareholder-group-form"
                  customErrors={customErrors}
                />
                <ShareholderGroupForm onSubmit={this.createNewGroup} />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}


ShareholderGroupCreate.contextTypes = {
  router: T.object,
};

ShareholderGroupCreate.propTypes = {
  createSharedholderGroup: T.func.isRequired,
};

export default connect(null, { createSharedholderGroup })(ShareholderGroupCreate);
