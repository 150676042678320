import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeDropdown } from 'reducers/dropdownLayout';
// components
import { Link } from 'react-router';
import onClickOutside from 'react-onclickoutside';
import DownloadLink from 'components/DownloadLink';
// utils
import classnames from 'classnames/bind';
// assets
import styles from './DropdownLayout.scss';

const cn = classnames.bind(styles);

function getLink(item, closeDropdown, isMobile) {
  if (item.url) {
    return !item.downloadBtn
      ? (
        <Link to={item.url} className={item.listItemClass}>
          {item.icon && (isMobile || item.desktopIcon) &&
            <i className={cn(`fa fa-${item.icon}`, 'item-icon')} />}
          {item.label}
        </Link>
      )
      : <DownloadLink className={item.listItemClass} endpoint={item.url}>{item.label}</DownloadLink>;
  }
  return (
    <a
      className={cn(item.listItemClass, { 'description-item': item.isDescription })}
      onClick={(data) => {
        if (item.onClick) {
          item.onClick(data);
        }
        closeDropdown();
      }}
    >
      {item.icon && (isMobile || item.desktopIcon) &&
        <i className={cn(`fa fa-${item.icon}`, 'item-icon')} />}
      {item.label}
    </a>
  );
}

class DropdownLayout extends Component {

  state = {
    dropdownHeigth: 0,
    dropdownWidth: 0,
    scrollTop: 0,
  };

  componentDidMount() {
    this.bodybag = document.getElementById('bodybag');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { ignoreScroll, isOpen } = this.props;
    if (ignoreScroll !== nextProps.ignoreScroll) {
      const actionType = nextProps.ignoreScroll ? 'addEventListener' : 'removeEventListener';
      this.bodybag[actionType]('scroll', this.handleScrollEvent);
    }
    if (isOpen !== nextProps.isOpen) {
      this.setState({ scrollTop: this.bodybag.scrollTop });
    }
  }

  componentDidUpdate() {
    this.updateDropdownCoords();
  }

  handleScrollEvent = () => this.setState({ scrollTop: this.bodybag.scrollTop });

  updateDropdownCoords = () => {
    if (this.dropdownLayout) {
      const { height, width } = this.dropdownLayout.getBoundingClientRect();
      const { dropdownHeigth } = this.state;
      if (height !== dropdownHeigth) this.setState({ dropdownHeigth: height, dropdownWidth: width });
    }
  };

  handleClickOutside = (e) => {
    const { closeDropdown, isOpen, IdKey } = this.props;
    if (isOpen && (IdKey !== e.target.dataset.name)) closeDropdown();
  };

  getStyles = () => {
    const { targetCoords, isOpen } = this.props;
    const { dropdownHeigth, dropdownWidth, scrollTop } = this.state;
    const { isMobile, isTablet } = this.context;

    if (!this.bodybag || !isOpen) return {};

    const { right, height, y } = this.bodybag.getBoundingClientRect();

    const isOpenAbove = ((targetCoords.bottom - y) + dropdownHeigth) > height;

    // targetCoords.top - y: bodybag top offset - mainHeader height (y)
    // width + x: bodybag width + sidebar width (x)

    if (isMobile && !isTablet) {
      const { marginRight } = window.getComputedStyle(this.dropdownLayout || {});
      const triangleOffset = (right - targetCoords.right - parseInt(marginRight, 10)) + 'px';
      this.dropdownLayout.style.setProperty('--triangleOffset', triangleOffset);
      const isLandscape = window.screen.availWidth > window.screen.availHeight;

      return {
        isLandscape,
        isOpenAbove,
        top: ((targetCoords[`${isOpenAbove ? 'top' : 'bottom'}`] - y) + scrollTop) + 'px',
        transform: isOpenAbove ? 'translateY(-100%)' : null,
      };
    }

    const rightOffset = right - targetCoords.right;

    return {
      isOpenAbove,
      top: ((targetCoords[`${isOpenAbove ? 'top' : 'bottom'}`] - y) + scrollTop) + 'px',
      right: rightOffset < 0 ? (rightOffset + dropdownWidth) : rightOffset + 'px',
      transform: isOpenAbove ? 'translateY(-100%)' : null,
    };
  };

  render() {
    const { isOpen, options, closeDropdown, ignoreScroll } = this.props;
    const { isMobile, isTablet } = this.context;
    const { isLandscape, isOpenAbove, ...styles } = this.getStyles();

    return (
      <div
        ref={(ref) => { this.dropdownLayout = ref; }}
        style={styles}
        className={cn('dropdown-layout', {
          'stack-order': ignoreScroll && isOpen,
          isOpen,
          isOpenAbove,
          isLandscape,
          isMobile: isMobile && !isTablet,
        })}
      >
        <ul className={cn('list-ul')}>
          {options.map((item, index) => !item.hide && (
            <li key={index} className={cn('list-item-li')}>
              {getLink(item, closeDropdown, isMobile && !isTablet)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

DropdownLayout.propTypes = {
  isOpen: T.bool.isRequired,
  options: T.array.isRequired,
  targetCoords: T.object.isRequired,
  IdKey: T.string.isRequired,
  closeDropdown: T.func.isRequired,
  ignoreScroll: T.bool.isRequired,
};

DropdownLayout.contextTypes = {
  isMobile: T.bool.isRequired,
  isTablet: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isOpen: state.dropdownLayout.isOpen,
    options: state.dropdownLayout.options,
    targetCoords: state.dropdownLayout.targetCoords,
    IdKey: state.dropdownLayout.IdKey,
    ignoreScroll: state.dropdownLayout.ignoreScroll,
  }), { closeDropdown }
)(onClickOutside(DropdownLayout));
