import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
// styles
import './PrairiesSystemsHome.scss';
import Subnavigation from '../../Subnavigation';
import { FormattedMessage } from 'react-intl';
import OfflineScreen from '../../OfflineScreen';

// TODO: remove comments when API will be ready

const subNavLinks = [
  { label: <FormattedMessage id="general.apiMonitoring.pushResults" />,
    link: '/monitoring/prairiesystems/pushresults' },
  { label: <FormattedMessage id="general.apiMonitoring.pullResults" />,
    link: '/monitoring/prairiesystems/pullresults' },
];

const PrairiesSystemsHome = ({ isOnline, children }) => (
  <div className="BarnsheetsHome clearfix">
    <Subnavigation
      isSticky
      links={subNavLinks}
      className="small-12 column"
    />
    {isOnline
      ? (
        <div className="small-12 column">
          { children }
        </div>
      )
      : <OfflineScreen />}
  </div>
);

PrairiesSystemsHome.propTypes = {
  children: T.object.isRequired,
  isOnline: T.bool,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
    active: state.dataTable.metaFarms.meta.active,
  })
)(PrairiesSystemsHome);
