import React, { Component } from 'react';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import CardLink from './CardLink';
import ImportTable from './ImportTable/ImportTable';
// styles, assets
import './Import.scss';
import uploadPreparationImg from '../../../../public/images/upload-preparetion.svg';
import csvFileImg from '../../../../public/images/csv-file.svg';
import questions from '../../../../public/images/questions.svg';

const cardsInfo = [
  {
    title: 'general.imports.questionsForSpreadsheet.title',
    infoText: 'general.imports.questionsForSpreadsheet.infoText',
    textLink: 'general.imports.questionsForSpreadsheet.link',
    link: '/imports',
    imageCard: uploadPreparationImg,
  },
  {
    title: 'general.imports.downloadCSV.title',
    infoText: 'general.imports.downloadCSV.infoText',
    textLink: 'general.imports.downloadCSV.link',
    link: '/imports/templates',
    imageCard: csvFileImg,
  },
  {
    title: 'general.imports.questions.title',
    infoText: 'general.imports.questions.infoText',
    textLink: 'general.imports.questions.link',
    link: '/imports',
    imageCard: questions,
  },

];

// eslint-disable-next-line react/prefer-stateless-function
class  AdminImports extends Component  {
  render() {
    return (
      <div className="adminImports">
        <div className="wrapperStartBtn">
          <Link to="/imports/choose-import" className="button small primary wider">
            <FormattedMessage id="general.imports" />
          </Link>
        </div>
        <div className="wrapperCards">
          {cardsInfo.map((item, index) => {
            return  (
              <CardLink
                key={index}
                title={item.title}
                infoText={item.infoText}
                textLink={item.textLink}
                link={item.link}
                imageCard={item.imageCard}
              />
            );
          })}
        </div>
        <ImportTable />
      </div>

    );
  }

}

export default AdminImports;
