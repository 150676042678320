import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: {
    data: {},
    loading: false,
  },
};

const SET_COMPANY_DATA = 'companies/SET_COMPANY_DATA';
const SET_RESOURCE_LOADING = 'companies/SET_RESOURCE_LOADING';

// ------------------------------------
// Actions
// ------------------------------------
export const setCompanyData = createAction(SET_COMPANY_DATA);
export const setResourceLoading = createAction(SET_RESOURCE_LOADING);

// ------------------------------------
// Action creators
// ------------------------------------

// admin action creators

export const createCompany = (data) => (dispatch) => {
  dispatch(setResourceLoading(true));
  const reqParams = { method: 'POST', body: JSON.stringify({ resource: data }) };
  return fetchFromAPI('/admin/companies', reqParams)
    .then((response) => {
      dispatch(setCompanyData(response.resource));
      dispatch(setResourceLoading(false));
      return response.resource;
    })
    .catch((error) => {
      dispatch(setResourceLoading(false));
      throw error;
    });
};

export const deleteCompany = (id) => (dispatch) => {
  dispatch(setResourceLoading(true));
  return fetchFromAPI(`/admin/companies/${id}`, { method: 'DELETE' })
    .then(() => {
      dispatch(setResourceLoading(false));
      return true;
    })
    .catch((error) => {
      dispatch(setResourceLoading(false));
      throw error;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [SET_COMPANY_DATA]: (state, action) => ({
    resource: { ...state.resource, data: action.payload },
  }),
  [SET_RESOURCE_LOADING]: (state, action) => ({
    resource: {
      ...state.resource,
      loading: action.payload,
    },
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
