import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
// components
import NothingBox from 'components/NothingBox';
import Avatar from 'components/Avatar/Avatar';
import CircleCheckbox from 'components/CircleCheckbox';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';
// styles
import './UsersCompanyRolesManageTable.scss';

class UsersCompanyRolesManageTable extends Component {

  state = {
    markers: {},
  };

  setMarkerValue = (key, value) => {
    this.setState((prevState) => {
      return { markers: { ...prevState.markers, [key]: value } };
    });
  };

  getMarkerValue = (key) => {
    return this.state.markers[key] || false;
  };

  getMarkerKey = (company, name) => {
    return `${name}_${company.id}`;
  };

  onChangeOwner = (company) => (e) => {
    const { openModal, handleRoleChanged, user: { full_name } } = this.props;
    const { name: target_name, checked } = e.target;
    const { id, name: company_name, is_owner, primary_contact, primary_contact_name } = company;

    if (is_owner || !primary_contact) {
      return handleRoleChanged(id, target_name, checked);
    }

    return openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.changePrimary.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.changePrimary.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.changePrimary.warning" />}
        handleConfirm={() => handleRoleChanged(id, target_name, checked)}
      >
        <FormattedMessage
          id="component.modal.changePrimary.text"
          values={{
            primaryContact: <strong>{primary_contact_name || get(company, 'primary_contact.name', '')}</strong>,
            companyName: <strong>{company_name}</strong>,
            newPrimaryContact: <strong>{full_name}</strong>,
          }}
          tagName="p"
        />
      </ConfirmationModal>
    );
  };

  handleRoleChange = (company) => (e) => {
    const { name, checked } = e.target;
    const markerKey = this.getMarkerKey(company, name);
    this.setMarkerValue(markerKey, true);
    this.props.handleRoleChanged(company.id, name, checked)
      .then(() => { this.setMarkerValue(markerKey, false); })
      .catch(() => { this.setMarkerValue(markerKey, false); });
  };

  isDisabledCompanyRoleCheckbox = (company, roleName) => {
    const markerKey = this.getMarkerKey(company, roleName);
    return this.getMarkerValue(markerKey);
  };

  render() {
    const { data, search, filter, isLoading, authUser, user: { disabled } } = this.props;

    return (
      <div className="UsersCompanyRolesManageTable">
        <div className="flex-data-table">

          {data.map((company, idx) => {
            const { id, name, is_owner, is_manager, is_caretaker, is_common, is_vet } = company;
            const isCurrentCompany = id === authUser.current_company.id;
            return (
              <div className="table-item" key={idx}>
                {/* Company Name */}
                <div className="table-item-block column-company-name">
                  <div className="collapsible-value">
                    <div className="value-amount title">
                      <div className="user-info">
                        <Avatar type="company" avatarSize={35} iconSize={16} />
                        <div className="info truncate">
                          <div className="name truncate black email">
                            &nbsp;&nbsp;{name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Primary Role */}
                <div className={cn('table-item-block column-company-role', { 'checked': is_owner, disabled })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.primary" />
                          </span>
                        )}
                        name="owner"
                        onChange={this.onChangeOwner(company)}
                        checked={is_owner}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                {/* Manager Role */}
                <div className={cn('table-item-block column-company-role', { 'checked': is_manager, disabled })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.manager" />
                          </span>
                        )}
                        name="manager"
                        onChange={this.handleRoleChange(company)}
                        checked={is_manager}
                        disabled={disabled || this.isDisabledCompanyRoleCheckbox(company, 'manager')}
                      />
                    </div>
                  </div>
                </div>
                {/* Caregiver */}
                <div className={cn('table-item-block column-company-role', { 'checked': is_caretaker, disabled })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.caregiver" />
                          </span>
                        )}
                        name="caretaker"
                        onChange={this.handleRoleChange(company)}
                        checked={is_caretaker}
                        disabled={disabled || this.isDisabledCompanyRoleCheckbox(company, 'caretaker')}
                      />
                    </div>
                  </div>
                </div>
                {/* Veterinarian */}
                <div className={cn('table-item-block column-company-role', { 'checked': is_vet, disabled })}>
                  <div className="collapsible-value with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.veterinarian" />
                          </span>
                        )}
                        name="vet"
                        onChange={this.handleRoleChange(company)}
                        checked={is_vet}
                        disabled={disabled || this.isDisabledCompanyRoleCheckbox(company, 'vet')}
                      />
                    </div>
                  </div>
                </div>
                {/* Common User */}
                <div
                  className={cn('table-item-block column-company-role', { 'checked': is_common,
                    'disabled': isCurrentCompany || disabled })}
                >
                  <div className="collapsible-value no-border with-checkbox">
                    <div className="value-amount flex align-center">
                      <CircleCheckbox
                        className="roles-manage"
                        label={(
                          <span className="semibold hide-for-large">
                            <FormattedMessage id="general.role.common" />
                          </span>
                        )}
                        name="common_user"
                        onChange={this.handleRoleChange(company)}
                        checked={is_common}
                        disabled={
                          isCurrentCompany || disabled || this.isDisabledCompanyRoleCheckbox(company, 'manager')
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <NothingBox
          itemsName="roles"
          display={!data.length}
          isLoading={isLoading}
          search={search}
          filter={filter}
        >
          <FormattedMessage tagName="h1" id="general.nothingBox.noRoles" />
        </NothingBox>
      </div>
    );
  }
}

UsersCompanyRolesManageTable.propTypes = {
  data: T.array,
  user: T.object,
  authUser: T.object,
  handleRoleChanged: T.func,
  openModal: T.func,
  search: T.string,
  filter: T.oneOfType([T.string, T.object]),
  isLoading: T.bool,
};

export default connect(
  null,
  { openModal }
)(UsersCompanyRolesManageTable);
