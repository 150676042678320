import React from 'react';
import T from 'prop-types';
// redux, recompose
import { Field, Fields, reduxForm } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import DatePickerField from 'components/ReduxFormFields/DatePickerField/DatePickerField';
import NumberInputField from 'components/ReduxFormFields/NumberInputField/NumberInputField';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
import DestinationField from 'components/ReduxFormFields/DestinationField/DestinationField';
import WeightField from 'components/ReduxFormFields/WeightField/WeightField';
// utils
import classnames from 'classnames/bind';
import moment from 'moment/moment';
// styles
import styles from './TransferForm.module.scss';

const cn = classnames.bind(styles);

const movementTypes = [
  { value: 'transfer', label: <FormattedMessage id="general.transferOut" /> },
  { value: 'sale', label: <FormattedMessage id="general.sale" /> },
];

const TransferForm = ({
  handleSubmit,
  isCommitmentBasedForm = false,
  isSameDay = true,
  minDeliveryDate,
}) => {
  const normalizeNumber = (value) => (Math.abs(parseInt(value, 10)));

  const isOutsideDeliveryDateRange = (day) => {
    const isAfterDay = day.isAfter(moment(), 'day');
    if (!minDeliveryDate) return isAfterDay;
    return isAfterDay || day.isBefore(minDeliveryDate, 'day');
  };

  return (
    <form onSubmit={handleSubmit} className={cn('transfer-form')}>
      <div className="small-12 column">
        <Field
          label={<FormattedMessage id="general.movementDate" />}
          name="delivery_date"
          component={DatePickerField}
          className={cn('transfer-field', 'mb-15')}
          isOutsideRange={isOutsideDeliveryDateRange}
        />
        <Field
          horizontal
          label={<FormattedMessage id="general.movementType" />}
          name="migration_type"
          valueKey="value"
          labelKey="label"
          options={movementTypes}
          component={SelectField}
          placeholder={<FormattedMessage id="general.addMovementType" />}
          className="mb-15"
          disabled={isCommitmentBasedForm}
        />
        {!isCommitmentBasedForm && (
          <Fields
            names={['destination.type', 'destination.data']}
            component={DestinationField}
            className={cn('transfer-field')}
          />
        )}
        <Field
          label={<FormattedMessage id="general.headMoved" />}
          name="amount"
          component={NumberInputField}
          normalize={normalizeNumber}
          className={cn('transfer-field', 'mb-15')}
        />
        <Field
          label={<FormattedMessage id="general.estAvgWeight" />}
          name="estimated_weight"
          component={WeightField}
          className={cn('transfer-field', 'mb-15')}
          disabled={!isSameDay}
        />
      </div>
    </form>
  );
};

TransferForm.propTypes = {
  handleSubmit: T.func.isRequired,
  minDeliveryDate: T.object,
  isSameDay: T.bool,
  isCommitmentBasedForm: T.bool,
};

export default reduxForm({ form: 'group-movement', enableReinitialize: true })(TransferForm);
