import React from 'react';
import T from 'prop-types';
// components
import ReactTooltip from 'rc-tooltip';
// utils
import cn from 'classnames';
// styles
import './FarmFormTooltip.scss';

const FarmFormTooltip = ({ className, overlay }, { isMobile }) => (
  <div className={cn('farm-form-tooltip', { [className]: !!className })}>
    <ReactTooltip
      mouseEnterDelay={0.2}
      placement={isMobile ? 'topRight' : 'top'}
      overlayClassName="tooltip-farm-message"
      overlay={overlay}
    >
      <i className="fa fa-question-circle-bts tooltip-icon" />
    </ReactTooltip>
  </div>
);

FarmFormTooltip.contextTypes = {
  isMobile: T.bool.isRequired,
};

FarmFormTooltip.propTypes = {
  overlay: T.node.isRequired,
  className: T.string,
};

export default FarmFormTooltip;
