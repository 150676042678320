import React from 'react';
import T from 'prop-types';
// components
import CircleCheckbox from 'components/CircleCheckbox';
// utils
import cn from 'classnames';
import './CheckboxColumn.scss';

const CheckboxColumn = ({
  noBottomBorder,
  label,
  name,
  onChange,
  checked,
  className,
}) => (
  <div
    className={cn('CheckboxColumn collapsible-value', className, {
      'no-bottom-border': noBottomBorder,
    })}
  >
    <div className={cn('value-amount')}>
      <CircleCheckbox
        className="circle-checkbox-column"
        label={<span className="hide-for-large semibold">{label}</span>}
        name={name}
        onChange={onChange}
        checked={checked}
      />
    </div>
  </div>
);

CheckboxColumn.propTypes = {
  noBottomBorder: T.bool,
  label: T.oneOfType([T.string, T.object]),
  name: T.string,
  checked: T.bool,
  onChange: T.func,
  className: T.string,
};

export default CheckboxColumn;
