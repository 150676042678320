import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import ImageTagTooltip from './ImageTagTooltip';
import PredictedTagDialog from './PredictedTagDialog';
import ImageTag from './ImageTag';

const PredictedImageTagEdit = ({ tag, onUpdatePosition, isHoveredTag }) => (
  <ImageTag tag={tag} onUpdatePosition={onUpdatePosition} isEditable={!!tag.diagnosis} hasHover>
    {({ isMouseOver }) => (
      <ImageTagTooltip
        className="pv-5 ph-10"
        isVisible={isHoveredTag || isMouseOver}
      >
        <PredictedTagDialog
          diagnosesCount={tag.diagnosis_ids.length}
          label={tag.diagnosis ? (tag.diagnosis.common_name || tag.diagnosis.name) : tag.label}
          percent={tag.ai_suggestion_percent}
          isSubLabelHidden={!!tag.diagnosis}
        />
      </ImageTagTooltip>
    )}
  </ImageTag>
);

PredictedImageTagEdit.propTypes = {
  tag: T.object,
  onUpdatePosition: T.func.isRequired,
  isHoveredTag: T.bool.isRequired,
};

export default connect(
  (state, { tag }) => ({
    isHoveredTag: state.dailyCheckup.checkupDiagnoses.hoverTag === (tag.prediction_image_tag_id || tag.hash),
  })
)(PredictedImageTagEdit);
