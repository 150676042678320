import { fetchFromAPI } from 'utils/api';

export const deleteUserFromAdmin = (userId) => {
  return fetchFromAPI(`/admin/users/${userId}`, { method: 'delete' });
};

export const reinviteUser = (id) => {
  return fetchFromAPI(`/users/${id}/reinvite`, { method: 'put' })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
