import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// utils
import moment from 'moment';
import { getAvgWeightBasedOnCommitment } from 'utils/commitmentsHelper';

const formatCutLevel = (cutLevel) => {
  switch (cutLevel) {
    case 1: return <FormattedMessage id="general.cutLevel.first" />;
    case 2: return <FormattedMessage id="general.cutLevel.middle" />;
    case 3: return <FormattedMessage id="general.cutLevel.last" />;
    default: return 'N/A';
  }
};

export const getSaleInfo = ({ arrive_at, cut_level, head_count }) => [
  {
    label: <FormattedMessage id="general.loadDate" />,
    value: arrive_at ? moment(arrive_at).format('MM/DD/YYYY') : 'N/A'
  },
  {
    label: <FormattedMessage id="general.loadTimeOnSite" />,
    value: arrive_at ? moment(arrive_at).format('hh:mm A') : 'N/A'
  },
  { label: <FormattedMessage id="general.cutLevel" />, value: cut_level ? formatCutLevel(cut_level) : 'N/A' },
  { label: <FormattedMessage id="general.targetHeadCount" />, value: head_count || 'N/A' },
];

export const getArrivalInfo = ({ head_count, arrive_at }) => [
  {
    label: <FormattedMessage id="general.deliveryDate" />,
    value: arrive_at ? moment(arrive_at).format('MM/DD/YYYY') : 'N/A'
  },
  {
    label: <FormattedMessage id="general.deliveryTimeOnSite" />,
    value: arrive_at ? moment(arrive_at).format('hh:mm A') : 'N/A',
  },
  { label: <FormattedMessage id="general.targetHeadCount" />, value: head_count || 'N/A' },
];

export const getTransferInfo = (commitment) => {
  const avgWeight = getAvgWeightBasedOnCommitment(commitment);
  return [
    {
      label: commitment.commitment_type === 'source' ?
        <FormattedMessage id="general.loadDate" /> :
        <FormattedMessage id="general.deliveryDate" />,
      value: commitment.arrive_at ? moment(commitment.arrive_at).format('MM/DD/YYYY') : 'N/A'
    },
    {
      label: commitment.commitment_type === 'source' ?
        <FormattedMessage id="general.loadTimeOnSite" /> :
        <FormattedMessage id="general.deliveryTimeOnSite" />,
      value: commitment.arrive_at ? moment(commitment.arrive_at).format('hh:mm A') : 'N/A'
    },
    { label: <FormattedMessage id="general.targetHeadCount" />, value: commitment.head_count || 'N/A' },
    { label: <FormattedMessage id="general.estAvgWeight" />, value: <FormattedWeight value={avgWeight} /> },
  ];
};
