import React from 'react';
import T from 'prop-types';
// components
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Avatar from 'components/Avatar/Avatar';
import VerticalAligner from 'components/VerticalAligner';
// styles
import './CompanyProfileHeader.scss';

const CompanyMiniProfileHeader = ({ avatarType = 'company', company, className }) => (
  <VerticalAligner className={`CompanyMiniProfileHeader ${className}`}>
    <Avatar type={avatarType} avatarSize={35} iconSize={16} />
    <h1 className="inline-block company-name">{company.name}</h1>
    <StatusBadge status={company.status} small />
    <div className="vertical-divider" />
  </VerticalAligner>
);

CompanyMiniProfileHeader.propTypes = {
  company: T.object,
  className: T.string,
  avatarType: T.oneOf(['company', 'trucking-company', 'packing-company']),
};

CompanyMiniProfileHeader.defaultProps = {
  className: '',
};

export default CompanyMiniProfileHeader;
