import React, { useEffect } from 'react';
import T from 'prop-types';
// hooks
import useDataTableController from 'hooks/useDataTableController';
import { useDropdownActions } from 'hooks/useDropdownContext';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/DataTable/DataTable';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Button from 'components/Button';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel/Panel';
import VerticalAligner from 'components/VerticalAligner';
import PigGroupMarketingBadge from 'components/PigGroupMarketingBadge/PigGroupMarketingBadge';
// utils
import cn from 'classnames';
import moment from 'moment';
import { getMedType, formatDosageType } from 'utils';
import { isTablet } from 'react-device-detect';
// styles
import './TreatmentsHistoryTable.scss';

function getRedirectValue(tableId) {
  switch (tableId) {
    case 'barnsheetsFarmTreatments':
      return 'farm-treatments';
    case 'barnsheetsGroupTreatments':
      return 'group-treatments';
    default:
      return undefined;
  }
}

const TreatmentsHistoryTable = ({
  dateEnd,
  dateStart,
  enableEditMode,
  fetchData,
  isAdmin,
  isGroupLevel = false,
  isLoading,
  measurementSystem,
  meta,
  pigGroupId,
  reqParams,
  resources,
  tableId,
}, {
  router,
}) => {
  const { onPageChange, onPerPageChange, onSortChange } = useDataTableController(fetchData, reqParams);
  const { openDropdown } = useDropdownActions();
  const { page, per_page, search, sort } = reqParams;
  const { total } = meta;

  useEffect(() => {
    if (dateStart && dateEnd) {
      fetchData({
        ...reqParams,
        date_start: dateStart.format('YYYY-MM-DD'),
        date_end: dateEnd.format('YYYY-MM-DD'),
      });
    }
  }, [dateStart, dateEnd]);

  const handleEditCheckupTreatments = (dcId, groupId) => {
    enableEditMode(groupId || pigGroupId).then(() => {
      router.push({
        pathname: `/daily-checkup/${dcId}/report-treatments`,
        query: {
          redirect: getRedirectValue(tableId),
          editMode: true,
        },
      });
    });
  };

  const handleOpenDropdown = (e, dcId, groupId) => {
    const options = [
      { label: <FormattedMessage id="general.editData" />, onClick: () => handleEditCheckupTreatments(dcId, groupId) },
    ];
    openDropdown(e, options);
  };

  const renderNameColumn = (rowData) => {
    const { treatment_product, daily_checkup_id, pig_group } = rowData;
    const name = treatment_product?.name;
    const route = treatment_product?.route;
    return (
      <CustomColumn
        className="treatment-name"
        label={<FormattedMessage id="component.dataTable.headers.productName" />}
      >
        <div className="product-name-column">
          {route &&
            <i className={`fa fa-${getMedType({ route })} icon show-for-large`} />
          }
          {isAdmin
            ? (
              <Link
                onClick={() => handleEditCheckupTreatments(daily_checkup_id, pig_group?.id)}
                className="color-primary"
              >
                <span className="semibold">{name}</span>
              </Link>
            )
            : <span className="semibold color-primary">{name}</span>}
        </div>
      </CustomColumn>
    );
  };

  const renderGroupNameColumn = (rowData) => {
    const { pig_group } = rowData;
    if (!pig_group) return null;
    const { id, name } = pig_group;
    return (
      <CustomColumn>
        <div className="group-id-column">
          <VerticalAligner>
            <span className="hide-for-large semibold">
              <FormattedMessage id="component.dataTable.headers.groupId" />&nbsp;•&nbsp;
            </span>
            <Link to={`/barnsheets/groups/${id}`} className="color-primary">
              {name}
            </Link>
            <PigGroupMarketingBadge
              className="ml-5"
              size="small"
              pigGroup={pig_group}
              isMinimized
              isHintAttachedToBody
            />
          </VerticalAligner>
        </div>
      </CustomColumn>
    );
  };

  const renderTypeColumn = (rowData) => {
    const { treatment_product } = rowData;
    return (
      <CustomColumn label={<FormattedMessage id="general.type" />}>
        <FormattedMessage id={`container.checkupEdit.reportMedications.${getMedType(treatment_product)}`} />
      </CustomColumn>
    );
  };

  const renderDoseColumn = (rowData) => {
    const { quantity, treatment_product } = rowData;
    const dosage_type = formatDosageType(treatment_product.dosage_type, measurementSystem);

    return (
      <CustomColumn label={<FormattedMessage id="general.dosage" />}>
        {(!Number(quantity) ? '-' : `${quantity} ${dosage_type}`) }
      </CustomColumn>
    );
  };

  const renderHeadTreatedColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="container.checkupEdit.reportMedications.headTreated" />}>
      {rowData.head_count}
    </CustomColumn>
  );

  const renderTreatmentDateColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.treatmentDate" />}>
      {moment(rowData.created_on).format('MM/DD/YYYY')}
    </CustomColumn>
  );

  const renderTreatmentDaysColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.daysOn" />}>
      {rowData.days_on || '-'}
    </CustomColumn>
  );

  const renderTreatmentWdExportsColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.wdPeriodExports" />}>
      {rowData.withdrawal_period_days_export
        ? <FormattedMessage id="general.daysWithCount" values={{ value: rowData.withdrawal_period_days_export }} />
        : 'N/A'}
    </CustomColumn>
  );

  const renderTreatmentWdExportsDateColumn = (rowData) => {
    const { withdrawal_period_end_date_export } = rowData;
    return (
      <CustomColumn label={<FormattedMessage id="general.wdPeriodExportsDate" />} noBottomBorder>
        {withdrawal_period_end_date_export ? moment(withdrawal_period_end_date_export).format('MM/DD/YYYY') : '-'}
      </CustomColumn>
    );
  };

  const renderActionsColumn = (rowData, { rowIndex }) => {
    const { daily_checkup_id, pig_group } = rowData;
    return (
      <div className="collapsible-value button-column actions">
        {isAdmin && (
          <i
            data-name={`${daily_checkup_id}-${rowIndex}`}
            className="fa fa-dots-three-horizontal show-for-large"
            onClick={(e) => handleOpenDropdown(e, daily_checkup_id, pig_group?.id)}
          />
        )}
        {isAdmin && (
          <Button
            className="hide-for-large"
            light
            onClick={() => handleEditCheckupTreatments(daily_checkup_id, pig_group?.id)}
          >
            <FormattedMessage id="general.editData" />
          </Button>
        )}
      </div>
    );
  };

  const renderExpandable = (rowData) => {
    const { daily_checkup_id, pig_group } = rowData;
    return (
      isAdmin && (
        <div>
          <Button light small onClick={() => handleEditCheckupTreatments(daily_checkup_id, pig_group?.id)}>
            <i className="fa fa-edit mr-5" /><FormattedMessage id="general.editData" />
          </Button>
        </div>
      )
    );
  };

  // for group level we don't need group name column
  const groupColumn = !isGroupLevel
    ? [{
      label: <FormattedMessage id="component.dataTable.headers.groupId" />,
      flex: '1 1 130px',
      renderer: renderGroupNameColumn
    }] : [];

  const columns = [
    { label: <FormattedMessage id="component.dataTable.headers.productName" />, sortKey: 'treatment_product_name',
      flex: isGroupLevel ? '1 1 170px' : '1 1 140px', renderer: renderNameColumn },
    ...groupColumn,
    { label: <FormattedMessage id="general.type" />, flex: '1 1 80px', renderer: renderTypeColumn },
    { label: <FormattedMessage id="general.dosage" />, flex: '1 1 80px', renderer: renderDoseColumn },
    { label: <FormattedMessage id="container.checkupEdit.reportMedications.headTreated" />, flex: '1 1 100px',
      renderer: renderHeadTreatedColumn },
    { label: <FormattedMessage id="general.daysOn" />, flex: '1 1 80px', renderer: renderTreatmentDaysColumn },
    { label: <FormattedMessage id="general.treatmentDate" />, flex: '1 1 120px', renderer: renderTreatmentDateColumn },
    { label: <FormattedMessage id="general.wdPeriodExports" />, flex: '1 1 120px',
      renderer: renderTreatmentWdExportsColumn, sortKey: 'withdrawal_period_days_export' },
    { label: <FormattedMessage id="general.wdPeriodExportsDate" />, flex: '1 1 120px',
      renderer: renderTreatmentWdExportsDateColumn, sortKey: 'withdrawal_period_end_date_export' },
    { label: '', flex: '0 0 50px', renderer: renderActionsColumn, fixed: true,
      className: cn({ 'hide-for-large': isTablet }) },
    { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  const paginationProps = {
    totalItems: total,
    currentPage: page,
    perPage: per_page,
    onPageChange,
    onPerPageChange,
  };

  return (
    <Panel className="treatments-history-table">
      <Panel.Heading title={<FormattedMessage id="general.treatments" />} />

      <Panel.Body noPadding>
        <Preloader isActive={isLoading} />

        <DataTable
          data={resources}
          columns={columns}
          sort={sort}
          onSortChange={onSortChange}
          paginationProps={paginationProps}
          isExpandable={isTablet}
          renderExpandable={renderExpandable}
          scrollable
          tableKey={tableId}
        />

        <NothingBox
          itemsName="medications"
          display={!resources.length}
          isLoading={isLoading}
          search={search}
        >
          <h2 className="lighter"><FormattedMessage id="component.nothingBox.noData" /></h2>
        </NothingBox>
      </Panel.Body>
    </Panel>
  );
};

TreatmentsHistoryTable.contextTypes = {
  router: T.object.isRequired,
};

TreatmentsHistoryTable.propTypes = {
  dateEnd: T.object,
  dateStart: T.object,
  resources: T.array.isRequired,
  isAdmin: T.bool.isRequired,
  isGroupLevel: T.bool,
  isLoading: T.bool.isRequired,
  measurementSystem: T.string.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  tableId: T.string.isRequired,
  pigGroupId: T.number,
  fetchData: T.func.isRequired,
  enableEditMode: T.func.isRequired,
};

export default TreatmentsHistoryTable;
