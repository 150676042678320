import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './PlaceholderShape.module.scss';

const cn = classnames.bind(styles);

const PlaceholderShape = ({ width, height, borderRadius, darker, hasShine, centered, className }) => {
  const style = { width, height, borderRadius, minHeight: 5, minWidth: 5 };
  return (
    <div style={style} className={cn('shape', className, { darker, hasShine, centered })} />
  );
};

PlaceholderShape.propTypes = {
  width: T.oneOfType([T.number, T.string]),
  height: T.oneOfType([T.number, T.string]),
  borderRadius: T.oneOfType([T.number, T.string]),
  className: T.string,
  darker: T.bool,
  hasShine: T.bool,
  centered: T.bool,
};

PlaceholderShape.defaultProps = {
  width: '100%',
  height: '100%',
  borderRadius: 5,
};

export default PlaceholderShape;
