import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fetchData } from 'reducers/dataTable';
import { fetchCompanyProfile } from 'reducers/profiles';
// hoc
import withDropdownActions from 'hoc/withDropdownActions';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import Avatar from 'components/Avatar/Avatar';
import PaginationWrapper from 'components/PaginationWrapper';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import TimeAgo from 'components/TimeAgo/TimeAgo';
import DataTable from 'components/DataTable/DataTable';
import { CustomColumn, ArrowColumn } from 'components/DataTable/Columns';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import Subnavigation from 'components/Subnavigation';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// utils
import cn from 'classnames';
import { isTablet, isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { timeFormatter } from 'utils/checkinsHelper';

const tableFilters = [
  { label: <FormattedMessage id="general.allFarms" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
];

const subnavItems = [
  { tab: 'users', name: <FormattedMessage id="general.users" />, path: '/checkins/users' },
  { tab: 'farms', name: <FormattedMessage id="general.farms" />, path: '/checkins/farms' },
];

class FarmsCheckins extends Component {

  componentDidMount() {
    const { params, fetchData, fetchCompanyProfile, user: { current_company: { id } } } = this.props;
    fetchData({ ...params, sort: 'last_checked_in desc' });
    fetchCompanyProfile(`/admin/companies/${id}`).catch(toastResponseErrors);
  }

  onStatusChange = (status) => {
    const { params, fetchData } = this.props;
    const active = status && status === 'active';
    fetchData({ ...params, page: 1, status, active });
  };

  renderFarmNameColumn = ({ name, farm_type }) => (
    <CustomColumn>
      <FarmTitleBox name={name} type={farm_type} />
    </CustomColumn>
  );

  renderLocationColumn = ({ address, city, state }) => (
    <CustomColumn>
      {`${address ? address + ',' : ''} ${city} ${state}`}
    </CustomColumn>
  );

  renderTotalCheckinsColumn = ({ check_ins_count }) => (
    <CustomColumn textLeft>
      {check_ins_count || 0}
    </CustomColumn>
  );

  renderLastCheckinColumn = ({ last_checked_in }) => (
    <CustomColumn>
      {last_checked_in
        ? <TimeAgo date={last_checked_in} formatter={timeFormatter} />
        : 'N/A'}
    </CustomColumn>
  );

  renderUserStatusColumn = ({ active }) => (
    <CustomColumn>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderTabletButtons = ({ id, last_check_in }) => (
    <div className="collapsable-buttons-row show-for-large">
      <Link to={`/checkins/farms/${id}`} className="button small light">
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.button.view" />
      </Link>
      {!!last_check_in && (
        <Link to={`/checkins/${last_check_in?.id}`} className="button small light">
          <i className="fa fa-eye mr-5" />
          <FormattedMessage id="general.button.viewOnMap" />
        </Link>
      )}
      <Link to={`/barnsheets/farms/${id}`} className="button small light">
        <i className="fa fa-eye mr-5" />
        <FormattedMessage id="general.button.viewFarmBarnsheetsHistory" />
      </Link>
      {this.props.user.roles_map.admin && (
        <Link to={`/admin/farms/${id}`} className="button small light">
          <i className="fa fa-gear mr-5" />
          <FormattedMessage id="general.manageFarm" />
        </Link>
      )}
    </div>
  );

  renderButtons = ({ id, last_check_in }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        wide
        buttonType="small light-gray"
        label={<FormattedMessage id="general.button.view" />}
        url={`/checkins/farms/${id}`}
        dropDownData={[
          {
            label: <FormattedMessage id="general.button.viewOnMap" />,
            url: `/checkins/${last_check_in?.id}`,
            hide: !last_check_in,
          }, {
            label: <FormattedMessage id="general.button.viewFarmBarnsheetsHistory" />,
            url: `/barnsheets/farms/${id}`,
          }, {
            label: <FormattedMessage id="general.manageFarm" />,
            url: `/admin/farms/${id}`,
            hide: !this.props.user.roles_map.admin,
          },
        ]}
      />
    </div>
  );

  renderMobileTable() {
    const { resources, isLoading, reqParams: { search } } = this.props;

    return (
      <div>
        <div className="CheckinsTable">
          <div className="mobile-table">
            {resources.map(({ id, name, last_check_in }) => (
              <div className="mobile-row" key={id}>
                <Avatar type="farm" iconSize="16" avatarSize="35" />
                <div className="info">
                  <div className="name">{name}</div>
                  <div className="description">
                    {last_check_in
                      ? (
                        <span>{(last_check_in?.user?.full_name)} •&nbsp;
                          <TimeAgo date={last_check_in?.created_at} formatter={timeFormatter} />
                        </span>
                      )
                      : 'N/A'}
                  </div>
                </div>
                <i
                  data-name={`${id}-${name}`}
                  className="fa fa-dots-three-horizontal dropdown-icon"
                  onClick={(e) => this.openDropdown(e, id, last_check_in)}
                />
              </div>
            ))}
          </div>

          <NothingBox
            itemsName="users_roles"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noFarmsCheckins">
              {(text) => <h2 className="lighter">{text}</h2>}
            </FormattedMessage>
          </NothingBox>
        </div>
      </div>
    );
  }

  openDropdown = (e, id, last_check_in) => {
    const { openDropdown, user: { roles_map } } = this.props;
    const options = [
      { label: <FormattedMessage id="general.button.viewFarmOnMap" />,
        url: `/checkins/${last_check_in?.id}`, hide: !last_check_in },
      { label: <FormattedMessage id="general.button.viewFarmCheckInHistory" />,
        url: `/checkins/farms/${id}` },
      { label: <FormattedMessage id="general.button.viewFarmBarnsheetsHistory" />,
        url: `/barnsheets/farms/${id}` },
      { label: <FormattedMessage id="general.manageFarm" />, url: `/admin/farms/${id}`, hide: !roles_map.admin },
    ];
    openDropdown(e, options);
  };

  renderPanelTitle = () => (
    <h1 className="lighter show-for-large"><FormattedMessage id="general.farms" /></h1>
  );

  render() {
    const { router } = this.context;
    const { resources, meta: { total, stats }, reqParams: { page, per_page, search, status, sort }, isLoading,
      onSortChange, onPageChange, onPerPageChange, onSearchChange, company, isCompanyLoading,
      user: { current_company: { id } } } = this.props;

    const columns = [
      {
        label: <FormattedMessage id="component.dataTable.headers.farmName" />,
        flex: '2 1 200px',
        renderer: this.renderFarmNameColumn,
        sortKey: 'name',
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.location" />,
        flex: '2 1 180px',
        renderer: this.renderLocationColumn,
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.checkIns" />,
        flex: '1 1 125px',
        renderer: this.renderTotalCheckinsColumn,
        sortKey: 'check_ins_count',
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.checkInsLast" />,
        sortKey: 'last_checked_in',
        flex: '1 1 160px',
        renderer: this.renderLastCheckinColumn,
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.status" />,
        sortKey: 'active',
        flex: '1 1 100px',
        renderer: this.renderUserStatusColumn,
      },
      {
        flex: '0 0 120px',
        renderer: this.renderButtons,
        hide: isTablet,
        fixed: true,
      },
      {
        flex: '0 0 40px',
        renderer: () => <ArrowColumn />,
        hide: !isTablet,
        fixed: true,
        hasPinnedIcon: true,
      },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <div>
        <CompanyProfileHeader company={company} isLoading={isCompanyLoading} actualCompanyId={id} />
        <Subnavigation
          className="small-12 column"
          isActive={(item) => item.tab === 'farms'}
          items={subnavItems}
          onSelect={(item) => { router.push(item.path); }}
        />

        <section className={cn('FarmsCheckins CheckinsPanelSection', { isMobile })}>

          <Panel className="checkins-panel">
            <Panel.Heading className="checkins-panel-header" renderTitle={this.renderPanelTitle}>
              <SearchBox initialValue={search} onChange={onSearchChange} />
            </Panel.Heading>

            <Panel.Body noPadding className="checkins-panel-body show-for-large">
              <Preloader isActive={isLoading} />

              <TableFilter
                filters={tableFilters}
                onFilterChange={this.onStatusChange}
                activeFilter={status}
                stats={stats}
                className="mh-10"
              />

              <DataTable
                data={resources}
                columns={columns}
                sort={sort}
                onSortChange={onSortChange}
                paginationProps={paginationProps}
                isExpandable={isTablet}
                renderExpandable={this.renderTabletButtons}
                scrollable
                tableKey="farmsCheckins"
              />

              <NothingBox
                itemsName="farms"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <FormattedMessage tagName="h1" id="component.nothingBox.noFarmsCheckins">
                  {(text) => <h2 className="lighter">{text}</h2>}
                </FormattedMessage>
              </NothingBox>
            </Panel.Body>

            <Panel.Body noPadding className="checkins-panel-body hide-for-large">
              <Preloader isActive={isLoading} />

              <PaginationWrapper
                onlyBottom
                totalItems={total}
                currentPage={page}
                onPageChange={onPageChange}
                perPage={per_page}
                onPerPageChange={onPerPageChange}
              >
                {this.renderMobileTable()}
              </PaginationWrapper>
            </Panel.Body>
          </Panel>

        </section>
      </div>
    );
  }
}

FarmsCheckins.propTypes = {
  resources: T.array.isRequired,
  isLoading: T.bool.isRequired,
  isCompanyLoading: T.bool.isRequired,
  params: T.object.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  openDropdown: T.func.isRequired,
  fetchCompanyProfile: T.func.isRequired,
  company: T.object.isRequired,
  user: T.object.isRequired,
};

FarmsCheckins.contextTypes = {
  router: T.object,
};

const enhance = compose(
  connect((state) => ({
    meta: state.dataTable.farmsCheckins.meta,
    resources: state.dataTable.farmsCheckins.resources,
    reqParams: state.dataTable.farmsCheckins.params,
    isLoading: state.dataTable.farmsCheckins.isLoading,
    company: state.profiles.company.data,
    isCompanyLoading: state.profiles.company.isLoading,
    user: state.auth.user,
  }), {
    fetchCompanyProfile,
    fetchData: (query) => fetchData('/check_ins/farms', 'farmsCheckins', query),
  }),
  withDropdownActions,
  withDataTableController('fetchData', 'reqParams')
);

export default enhance(FarmsCheckins);
