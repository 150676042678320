import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
import { updateCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import CompanyEditForm from './components/CompanyEditForm';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

class CompanyEdit extends Component {

  updateCompanyName = (data) => {
    const { updateCompanyProfile, params: { id } } = this.props;
    return updateCompanyProfile(`/admin/companies/${id}`, { method: 'put', body: JSON.stringify({ resource: data }) })
      .then(() => {
        showToastrMessage('component.toastr.company.updated');
      })
      .catch(toastResponseErrors);
  };

  render() {
    const { company: { name } } = this.props;
    let initialValues;
    if (name) initialValues = { name };

    return (
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.companyInformation" />} />
        <Panel.Body noPadding>
          <div className="row">
            <section className="small-12 column">
              <CompanyEditForm onSubmit={this.updateCompanyName} initialValues={initialValues} />
            </section>
          </div>
        </Panel.Body>
      </Panel>
    );
  }
}

CompanyEdit.propTypes = {
  updateCompanyProfile: T.func.isRequired,
  params: T.object,
  company: T.object,
};

export default connect((state) => ({
  company: state.profiles.company.data,
}), { updateCompanyProfile })(CompanyEdit);
