import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserAvatar from 'components/UserAvatar';
import UserFarmfeedTooltip from 'components/EntityFarmfeedTooltip/UserFarmfeedTooltip';
import Popover from 'components/Popover';
import Button from 'components/Button';
// utils
import cn from 'classnames';
// assets
import './FarmFeedAddComment.scss';

const FarmFeedAddComment = ({
  children,
  activityComment,
  activity,
  isPostDisabled,
  commentOnPost,
  currentUser,
  commentOnEnter,
}) => {
  const renderUserData = (
    <div className="avatar">
      <UserAvatar user={currentUser} size={activityComment ? 30 : 40} />
    </div>
  );

  const isAdmin = currentUser.roles_map.admin;

  return (
    <div className="FarmFeedAddComment">
      <div className="body">
        <div className="add-comment-form">
          {activity && (
            <Popover
              content={<UserFarmfeedTooltip activityId={activity.id} userId={currentUser.id} isAdmin={isAdmin} />}
              placement="topLeft"
            >
              {renderUserData}
            </Popover>
          )}
          {!activity && renderUserData}
          <div className="comment-text" onKeyPress={commentOnEnter}>
            {children}
          </div>
          <div className="post-button hide-for-large">
            <i
              className={cn('fa fa-paper-plane post-comment-button', { 'profile-button': !activityComment })}
              onClick={commentOnPost}
            />
          </div>
          <div className="show-for-large">
            <Button primary className="desktop-post-button" onClick={commentOnPost} disabled={isPostDisabled}>
              <FormattedMessage id="general.button.post" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

FarmFeedAddComment.propTypes = {
  activityComment: T.bool,
  activity: T.object,
  currentUser: T.object.isRequired,
  isPostDisabled: T.bool,
  children: T.any.isRequired,
  commentOnPost: T.func.isRequired,
  commentOnEnter: T.func.isRequired,
};

export default FarmFeedAddComment;
