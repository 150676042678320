import { useState, useCallback } from 'react';

export default function useLoading(func) {
  const [isLoading, setIsLoading] = useState(false);

  const wrappedFunction = useCallback(async () => {
    if (typeof func !== 'function') return;
    setIsLoading(true);
    try {
      await func();
    } finally {
      setIsLoading(false);
    }
  }, [func, setIsLoading]);

  return [wrappedFunction, isLoading];
}
