import React from 'react';
import T from 'prop-types';
// components
import UserAvatar from 'components/UserAvatar';
import ImageTag from './ImageTag';
import ImageTagTooltip from './ImageTagTooltip';
// utils
import classnames from 'classnames/bind';
import { formatDateAt } from 'utils';
// styles
import styles from './ImageTagView.module.scss';

const cn = classnames.bind(styles);

const ImageTagView = ({ tag, onDeleteTag, onEditTag, isAdmin }, { isMobile }) => (
  <ImageTag tag={tag} hasHover hasCoordsUpdate>
    {({ isMouseOver, tooltipOffset, triangleOffset, position }) => (
      <ImageTagTooltip
        tooltipOffset={tooltipOffset}
        triangleOffset={triangleOffset}
        isVisible={isMouseOver}
        position={position}
      >
        <div className={cn('user-info-block')}>
          <UserAvatar className="mr-5" size={30} user={tag.created_by} />
          <div className={cn('user-info')}>
            <span className={cn('user-name')}>{tag.created_by.full_name}</span>
            <span className={cn('created-at')}>{formatDateAt(tag.created_at)}</span>
          </div>
        </div>
        <div className={cn('diagnosis-line')}>
          <i className={cn('fa fa-label', 'tag-label-icon')} />
          <span className={cn('diagnosis-name')}>{tag.diagnosis.common_name || tag.diagnosis.name}</span>
          {isAdmin && !isMobile &&
            <i className={cn('fa fa-trash-o', 'del-tag-icon')} onClick={() => onDeleteTag(tag)} />}
          {isAdmin && !isMobile &&
            <i className={cn('fa fa-pencil-mdc', 'edit-tag-icon')} onClick={onEditTag} />}
        </div>
      </ImageTagTooltip>
    )}
  </ImageTag>
);

ImageTagView.contextTypes = {
  isMobile: T.bool,
};

ImageTagView.propTypes = {
  tag: T.object,
  onDeleteTag: T.func,
  onEditTag: T.func,
  isAdmin: T.bool,
};

export default ImageTagView;
