import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCommitment, setCommitment, fetchCommitmentHealthInfo } from 'reducers/commitment';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import SubNavigation from 'components/Subnavigation';
// utils
import isEmpty from 'lodash.isempty';
import { getNavLinks } from './utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
// styles
import styles from './CommitmentPage.module.scss';

const cn = classnames.bind(styles);

const CommitmentPageContainer = ({
  children,
  commitment,
  fetchCommitment,
  fetchCommitmentHealthInfo,
  healthInfo,
  isLoading,
  location: { query },
  params: { id: loadId },
  router: { push },
  setBreadcrumbs,
  setCommitment,
  setPageOptions,
}) => {

  useEffect(() => {
    if (loadId) {
      fetchCommitmentHealthInfo(loadId).catch(toastResponseErrors);
      fetchCommitment(loadId)
        .then(({ value: commitment }) => {
          const backLink = query?.from === 'start-group-page'
            ? `/daily-checkup/groups/${commitment.pig_group_id}/create-shipment?mode=select-load`
            : '/my-schedule';
          setBreadcrumbs([
            { label: <FormattedMessage id="general.pageTitle.mySchedule" />, path: '/my-schedule' },
            { label: commitment.loading.name, useLabelAsMobileTitle: true },
          ]);
          setPageOptions({ backLink });
        })
        .catch((err) => {
          toastResponseErrors(err);
          push('/my-schedule');
        });
    }
  }, [loadId]);

  useEffect(() => {
    return () => {
      setPageOptions({ backLink: '' });
      setCommitment(null);
    };
  }, []);

  if (isLoading || isEmpty(commitment)) return <Preloader isActive />;

  return (
    <div className={styles['load-page-content']}>
      <SubNavigation
        className="small-12 column"
        links={getNavLinks(commitment, healthInfo.length)}
        isSticky
        isMobile
      />
      <div className="row centered">
        <section className={cn('profile-body')}>
          <Preloader isActive={isLoading} />
          {children}
        </section>
      </div>
    </div>
  );
};

CommitmentPageContainer.propTypes = {
  children: T.node.isRequired,
  fetchCommitment: T.func.isRequired,
  commitment: T.object.isRequired,
  fetchCommitmentHealthInfo: T.func.isRequired,
  healthInfo: T.array.isRequired,
  isLoading: T.bool.isRequired,
  setCommitment: T.func.isRequired,
  router: T.object.isRequired,
  params: T.object.isRequired,
  location: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
};

export default connect(
  (state) => ({
    commitment: state.commitment.data,
    healthInfo: state.commitment.healthInfo,
    isLoading: state.commitment.isLoading,
  }), {
    fetchCommitment,
    setBreadcrumbs,
    setCommitment,
    setPageOptions,
    fetchCommitmentHealthInfo
  }
)(CommitmentPageContainer);
