import React, { PureComponent } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { markAsSeen } from 'reducers/farmfeed';
import { fetchActivity, clearActivity } from 'reducers/activityShow';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import FarmFeedActivityPlaceholder from 'components/FarmFeed/FarmFeedActivityPlaceholder';
import ActivitySwitcher from 'components/Activities/ActivitySwitcher';
import MessageBox from 'components/MessageBox';
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
// utils
import isEmpty from 'lodash.isempty';

class ActivityShow extends PureComponent {

  componentDidMount() {
    const { fetchActivity, params: { id }, setBreadcrumbs } = this.props;
    fetchActivity(id);
    setBreadcrumbs();
  }

  componentDidUpdate({ params: prevParams }) {
    const { fetchActivity, params: { id } } = this.props;
    if (prevParams.id !== id) {
      fetchActivity(id);
    }
  }

  componentWillUnmount() {
    this.props.clearActivity();
  }

  markAsSeen = () => {
    const { markAsSeen, currentActivity } = this.props;
    markAsSeen(currentActivity.id);
  };

  render() {
    const { currentUser, isLoading, currentActivity } = this.props;
    const isNullState = !isLoading && isEmpty(currentActivity);

    return (
      <div className="row centered FarmFeedProfile">
        <div className="feed-container">
          <Preloader isActive={isLoading} />
          {isLoading && isEmpty(currentActivity) && (
            <div className="animated fadeIn">
              <FarmFeedActivityPlaceholder />
            </div>
          )}

          {isNullState && (
            <div className="animated fadeIn">
              <div className="not-authorized-alert">
                <MessageBox type="alert">
                  <FormattedMessage id="container.farmfeed.noAccess" />
                </MessageBox>
              </div>
              <FarmFeedActivityPlaceholder />
            </div>
          )}

          {currentActivity &&
            <ActivitySwitcher currentUser={currentUser} activity={currentActivity} markAsSeen={this.markAsSeen} />}
        </div>
      </div>
    );
  }
}

ActivityShow.propTypes = {
  params: T.object.isRequired,
  currentUser: T.object.isRequired,
  isLoading: T.bool,
  currentActivity: T.object,
  fetchActivity: T.func.isRequired,
  clearActivity: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  markAsSeen: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    isLoading: state.activityShow.isLoading,
    currentActivity: state.activityShow.resource,
  }), {
    fetchActivity,
    clearActivity,
    setBreadcrumbs,
    markAsSeen,
  }
)(ActivityShow);
