import React from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import { ThreeDotsBtn } from 'components/Button/Button';
import FarmTypeLabel from 'components/FarmTypeLabel';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import VerticalAligner from 'components/VerticalAligner';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import Preloader from 'components/Preloader';
import ProfilePlaceholder from '../ProfilePlaceholder';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// styles
import './FarmProfileHeader.scss';
// utils
import cn from 'classnames';

const renderFarmAddress = (farm) => {
  const { address, city, state, country_code, zipcode, external_link_id } = farm;
  return (
    <div>
      <span>{address}, </span>
      <span>{city}, </span>
      {state && <span>{state} </span>}
      <span>{country_code === 'US' ? 'USA' : country_code} </span>
      {zipcode && <span>{zipcode} </span>}
      <span>ID: {external_link_id || '-'}</span>
    </div>
  );
};

const FarmProfileHeader = ({
  farm,
  farmInfo,
  actualFarmId,
  className,
  isSticky,
  children,
  dropdownOptions,
  stats,
  isLoading,
  checkinBtn,
}) => {
  const { openDropdown } = useDropdownActions();

  return (
    <div className={cn('FarmProfileHeader', className, { isSticky })}>
      <Preloader isActive={isLoading} />

      <ProfilePlaceholder isActive={isLoading && (farm.id !== actualFarmId)}>
        <div className="farm-info-block">
          <Avatar type="farm" avatarSize={50} iconSize={24} />

          <div className="farm-information">
            <VerticalAligner className="farm-info-wrapper">
              <h1 className="farm-name lighter inline-block mr-5">{farm.name}</h1>
              <FarmTypeLabel farmType={farm.farm_type} className="mr-5" />
              {typeof farm.active === 'boolean' &&
                <StatusBadge status={farm.active ? 'active' : 'disabled'} />}
            </VerticalAligner>
            {farmInfo || renderFarmAddress(farm)}
          </div>
        </div>
      </ProfilePlaceholder>

      <div className="farm-actions">
        {children}
        {!!stats.length &&
          <ProfileHeaderStats stats={stats} className="mr-10" />}
        {checkinBtn}
        {!!dropdownOptions.filter(({ hide }) => !hide).length &&
          <ThreeDotsBtn dataName="farm-icon-btn" onClick={(e) => openDropdown(e, dropdownOptions)} className="ml-10" />}
      </div>
    </div>
  );
};

FarmProfileHeader.propTypes = {
  farm: T.object,
  className: T.string,
  isSticky: T.bool,
  children: T.any,
  isLoading: T.bool,
  dropdownOptions: T.array,
  stats: T.array,
  farmInfo: T.node,
  actualFarmId: T.number,
  checkinBtn: T.node,
};

FarmProfileHeader.defaultProps = {
  className: '',
  children: null,
  dropdownOptions: [],
  stats: [],
  farm: {},
};

export default FarmProfileHeader;
