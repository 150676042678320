import React, { useState } from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { isActivityMinimized, saveActivityToStorage } from 'utils/activityHelper';

export default function useActivityController(props) {
  const { activity, isChildActivity, currentUser: { roles_map: { admin } }, setActivityFlag } = props;
  const [isMinimized, setIsMinimized] = useState(isActivityMinimized(activity));
  const [isTruncated, setIsTruncated] = useState(isChildActivity);

  const onToggleMinimized = (e) => {
    e.preventDefault();
    saveActivityToStorage(activity, isMinimized);
    setIsMinimized((prevIsMinimized) => !prevIsMinimized);
  };

  const { id, pig_group, farm, user, flagged } = activity;
  const pigGroupId = pig_group?.id;
  const sourceId = pig_group?.external_source_id;
  const farmId = farm?.id;
  const userId = user?.id;

  return {
    isMinimized,
    isTruncated,
    onViewFullActivity: () => setIsTruncated(false),
    onToggleMinimized,
    defaultDropdownOptions: [
      { label: <FormattedMessage id={`container.farmfeed.row.${flagged ? 'unflagEvent' : 'flagEvent'}`} />,
        onClick: () => setActivityFlag(!flagged, id), icon: 'bookmark', hide: !isMinimized,
      },
      { label: <FormattedMessage id={`container.farmfeed.row.${isMinimized ? 'maximize' : 'minimize'}Event`} />,
        onClick: onToggleMinimized, icon: `${isMinimized ? 'plus' : 'minus'}-circle`,
      },
      { label: <FormattedMessage id="general.viewBarnSheet" />, url: `/barnsheets/groups/${pigGroupId}`,
        icon: 'ulist', hide: !pigGroupId },
      { label: <FormattedMessage id="general.viewSourceProfile" />, url: `/sources/${sourceId}`, icon: 'merge',
        hide: !admin || !sourceId },
      { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${pigGroupId}`, icon: 'gear',
        hide: !admin || !pigGroupId },
      { label: <FormattedMessage id="general.manageFarm" />, url: `/admin/farms/${farmId}`, icon: 'gear',
        hide: !admin || !farmId },
      { label: <FormattedMessage id="general.manageUser" />, url: `/admin/users/${userId}`, icon: 'gear',
        hide: !admin || !userId },
    ],
  };
}
