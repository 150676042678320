import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import FarmAutosuggest from 'components/FarmAutosuggest/FarmAutosuggest';
import FieldWrapper from '../FieldWrapper';

const FarmAutosuggestField = ({ input, label, meta, handleChange, setFarmAttributes, isFarmSet }) => (
  <FieldWrapper meta={meta} label={label}>
    <FormattedMessage id="general.typeAName">
      {(placeholderText) => (
        <FarmAutosuggest
          value={input.value}
          onChange={handleChange}
          onFarmSet={setFarmAttributes}
          placeholderText={placeholderText}
          isFarmSet={isFarmSet}
        />
      )}
    </FormattedMessage>
  </FieldWrapper>
);

FarmAutosuggestField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.object, T.string]),
  handleChange: T.func.isRequired,
  setFarmAttributes: T.func.isRequired,
  isFarmSet: T.bool.isRequired,
};

export default FarmAutosuggestField;
