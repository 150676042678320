import React from 'react';
import T from 'prop-types';
// components
import { Facebook } from 'react-content-loader';
import IntlProvider from 'components/IntlProvider/IntlProvider';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import TextLabel from 'components/TextLabel/TextLabel';
// utils
import classNames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
// styles
import styles from './FarmfeedTooltip.module.scss';

const cn = classNames.bind(styles);

const FarmfeedTooltip = ({
  data,
  type,
  tooltipData: { headerData, dataLines },
  getAvatar,
  isAdmin,
  onManageBtnClick,
  manageBtnLabel,
}) => (
  <IntlProvider>
    <div className={cn('tooltip-content')}>
      <div className={cn('placeholder', { 'active': isEmpty(data) })}>
        <Facebook />
      </div>
      <div className={cn('info-row')}>
        {getAvatar(data)}
        <div className={cn('row-header')}>
          <div className={cn('name-row')}>
            <b className={cn('name')}>{data.full_name || data.name}</b>
            {type === 'treatment' && <StatusBadge status={data.active ? 'active' : 'disabled'} />}
            {type === 'company' && <StatusBadge status={data.status} />}
            {type === 'farm' && <TextLabel className={cn('farm-label')}>{data.farm_type}</TextLabel>}
          </div>
          <div className={cn('name-details')}>
            {headerData.map(({ key, label }, idx) => (
              <div key={idx}>{key ? label(data[key]) : label(data)}</div>
            ))}
          </div>
        </div>
      </div>
      {((type !== 'user') || isAdmin) && dataLines.map(({ key, label, getValueUnit }, index) => (
        <div
          key={key}
          className={cn('data-line-row', { 'last-line': dataLines.length === index + 1, 'mt-15': !index })}
        >
          <span className={cn('line-value')}>
            {getValueUnit ? getValueUnit(data[key]) : data[key] }
          </span>
          <span className={cn('line-label')}>
            {label}
          </span>
        </div>
      ))}
      {isAdmin && (
        <div className={cn('manage-activity-row')}>
          <div className={cn('manage-btn')} onClick={onManageBtnClick}>
            <i className={cn('fa fa-gear', 'icon')} />
            {manageBtnLabel}
          </div>
        </div>
      )}
    </div>
  </IntlProvider>
);

FarmfeedTooltip.propTypes = {
  data: T.object.isRequired,
  type: T.string.isRequired,
  tooltipData: T.object.isRequired,
  getAvatar: T.func.isRequired,
  onManageBtnClick: T.func.isRequired,
  manageBtnLabel: T.node.isRequired,
  isAdmin: T.bool.isRequired,
};

export default FarmfeedTooltip;
