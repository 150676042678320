import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCheckup, makeCheckupCorrection } from 'reducers/dailyCheckup/checkupEdit';
import { setCurrentGroup } from 'reducers/barnsheets/barnsheetsGroupCheckups';
import { openModal } from 'reducers/modals';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import ExpiredCheckupModal from 'components/DailyCheckups/modals/ExpiredCheckupModal/ExpiredCheckupModal';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
// utils
import once from 'lodash.once';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { currentUtcOffsetTime } from 'utils/timeHelper';
import { isFakeId } from 'utils/offlineHelper';

class DailyCheckupContainer extends Component {

  state = {
    pathname: '',
  };

  componentDidMount() {
    const { router } = this.context;
    const { fetchCheckup, params, setCurrentGroup, location: { query }, route, isOnline } = this.props;
    const inEditMode = query.editMode === 'true';
    if (isOnline && Number.isNaN(parseInt(params.id, 10))) {
      router.push('/daily-checkup');
      return;
    }
    router.setRouteLeaveHook(route, this.routerWillLeave);
    fetchCheckup(params.id, inEditMode)
      .then((resource) => {
        setCurrentGroup(resource.pig_group);
      })
      .catch(toastResponseErrors);
  }

  componentDidUpdate(prevProps, prevState) {
    const { params, checkup } = this.props;
    if ((checkup?.id || '').toString() === params.id) {
      this.setBreadcrumbs(checkup);
    }

    const { pathname, query = '' } = this.state;
    if (pathname !== prevState.pathname) {
      this.context.router.push({ pathname, query });
    }
  }

  componentWillUnmount() {
    this.props.setBreadcrumbs();
  }

  setBreadcrumbs = once((checkup) => {
    this.props.setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.checkups" />, path: '/daily-checkup' },
      { label: checkup.pig_group.farm.name, path: `/daily-checkup/farms/${checkup.pig_group.farm.id}` },
      { label: checkup.pig_group.name, path: `/daily-checkup/${checkup.id}`, useLabelAsMobileTitle: true },
    ]);
  });

  routerWillLeave = ({ pathname, query }) => {
    const { checkup, params, openModal } = this.props;
    const { router } = this.context;
    const isEditMode = (query.editMode === 'true') && checkup.pig_group?.in_edit_mode;
    const ignoreLeaveHook = router.location.query?.force;
    const fromFakeCheckup = isFakeId(params.id);
    if (checkup.submitted || ignoreLeaveHook || isEditMode || fromFakeCheckup) return true;
    // it is for Ipad safari bug issue with react router prompt
    if (this.confirm) {
      this.confirm = false;
      return true;
    }

    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="container.dailyCheckup.leaveDC.title" />}
        handleConfirm={() => { this.setState({ pathname, query }); this.confirm = true; }}
        actionBtnLabel={<FormattedMessage id="general.button.yes" />}
        actionBtnProps={{ primary: true }}
      >
        <FormattedMessage tagName="p" id="container.dailyCheckup.leaveDC.text" />
      </ConfirmationModal>
    );
    return false;
  };

  validateCheckup = (checkup) => {
    const { openModal, location: { query } } = this.props;
    const isEditMode = (query.editMode === 'true') && checkup.pig_group?.in_edit_mode;
    const farmUtcOffset = checkup.pig_group?.farm?.utc_offset;
    const isCheckupExpired = !currentUtcOffsetTime(farmUtcOffset).isSame(checkup.created_on, 'day');
    if (isCheckupExpired && !isEditMode) {
      openModal(<ExpiredCheckupModal />);
      return false;
    }
    return true;
  };

  getCheckupEditUrl = () => {
    const { checkup, location: { query } } = this.props;
    const isEditMode = (query.editMode === 'true') && checkup.pig_group?.in_edit_mode;
    return isEditMode
      ? `/barnsheets/daily-checkup/${checkup.id}?editMode=true`
      : `/daily-checkup/${checkup.id}`;
  };

  render() {
    const { children, checkup, location: { query }, makeCheckupCorrection, isLoading } = this.props;
    const isEditMode = (query.editMode === 'true') && checkup?.pig_group?.in_edit_mode;
    const style = { height: '100%' };

    const backLink = this.getCheckupEditUrl(checkup);

    const childrenWithProps = React.Children.map(children,
      (child) => React.cloneElement(child, {
        validateCheckup: this.validateCheckup,
        makeCheckupCorrection,
        isEditMode,
        backLink,
        checkup,
        isLoading,
      }));

    return (
      <div style={style}>
        {isEditMode &&
          <FarmProfileHeader farm={checkup.pig_group.farm} className="show-for-large" />}
        {childrenWithProps}
      </div>
    );
  }
}

DailyCheckupContainer.contextTypes = {
  router: T.object,
};

DailyCheckupContainer.propTypes = {
  location: T.object.isRequired,
  route: T.object.isRequired,
  params: T.object.isRequired,
  openModal: T.func.isRequired,
  fetchCheckup: T.func.isRequired,
  setCurrentGroup: T.func.isRequired,
  makeCheckupCorrection: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  checkup: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isOnline: T.bool.isRequired,
  children: T.any,
};

export default connect(
  (state) => ({
    checkup: state.dailyCheckup.checkupEdit.resource,
    isLoading: state.dailyCheckup.checkupEdit.isLoading,
    isOnline: state.network.isOnline,
  }), {
    fetchCheckup,
    openModal,
    setCurrentGroup,
    makeCheckupCorrection,
    setBreadcrumbs,
  },
)(DailyCheckupContainer);
