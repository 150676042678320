import React from 'react';
import T from 'prop-types';
// utils
import { useIntl } from 'react-intl';
import classnames from 'classnames/bind';
// styles
import styles from './CategoryStepper.module.scss';

const cn = classnames.bind(styles);

const getSVRSteps = (categories, formatMessageFunc) => categories.map((category) => ({
  name: category.name_key ? formatMessageFunc({ id: category.name_key }) : category.name,
  filled: category.svr_questions.every(({ answer }) => answer?.value),
  touched: category.svr_questions.some(({ answer }) => answer?.value)
}));

const CategoryStepper = ({ categories, className }) => {
  const { formatMessage } = useIntl();
  const svrSteps = getSVRSteps(categories, formatMessage);

  return (
    <div className={cn('category-stepper', className)}>
      {svrSteps.map(({ name, filled, touched }, idx) => (
        <div key={idx} className={cn('step')}>
          <div className={cn('circle-wrapper', { filled })}>
            <div className={cn('step-circle', { filled, touched })}>
              {filled ?
                <i className={cn('fa fa-check')} /> :
                null
            }
            </div>
            <div className={cn('name')}>
              {name}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

CategoryStepper.propTypes = {
  categories: T.array.isRequired,
  className: T.string,
};

export default CategoryStepper;
