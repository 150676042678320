import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// router
import { withRouter } from 'react-router';
// components
import MentionsInput from 'components/MentionsInput';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import UserAvatar from 'components/UserAvatar';
import CommentText from './CommentText';
// hooks
import useCommentWithMentionsState from 'hooks/useCommentWithMentionsState';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SVRComment.module.scss';

const cn = classnames.bind(styles);

const svrQuestionMentionRoles = ['owner', 'caretaker', 'manager', 'vet'];

const SVRComment = ({
  comment: commentData,
  handleEdit,
  avatarSize = 35,
  onDelete,
  currentUser,
  className,
  params,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const { comment, mentions, setComment, setMentions } = useCommentWithMentionsState(commentData.comment);

  useEffect(() => {
    setComment(commentData.comment);
  }, [commentData.comment]);

  const handleToggleEdit = () => {
    setComment(isEdit ? comment : commentData.comment);
    setIsEdit(!isEdit);
  };

  const handleSave = () => {
    handleEdit({
      comment,
      comment_mentions_attributes: mentions.map(({ id: user_id }) => ({ user_id }))
    });
    handleToggleEdit();
  };

  const isAuthor = currentUser.id === commentData.user.id;
  const isAdmin = currentUser.roles_map.admin;
  const isActionable = handleEdit && onDelete && (isAuthor || isAdmin);

  return (
    <div className={cn('svr-comment', className)}>
      <UserAvatar size={avatarSize} className="mt-5" user={commentData.user} />
      <div className={cn('comment-body')}>

        {!isEdit && (
          <CommentText
            commentData={commentData}
            isActionable={isActionable}
            onDelete={onDelete}
            onEdit={handleToggleEdit}
          />
        )}

        {isEdit && (
          <div className={cn('edit-section')}>
            <div className="comment-edit">
              <MentionsInput
                comment={comment}
                disabledIds={mentions.map((mention) => mention.id)}
                entityType="farm"
                entityId={params.farmId}
                inputType="square"
                isEditCommentMode
                mentionRoles={svrQuestionMentionRoles}
                onCommentChange={setComment}
                onMentionsChange={setMentions}
                placeholder=""
              />
            </div>
            <div className={cn('btn-group')}>
              <Button className={cn('btn')} small onClick={handleToggleEdit}>
                <FormattedMessage id="general.button.cancel" />
              </Button>
              <Button onClick={handleSave} disabled={!comment} className={cn('btn')} small primary>
                <FormattedMessage id="general.button.save" />
              </Button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

SVRComment.propTypes = {
  comment: T.object.isRequired,
  avatarSize: T.number,
  className: T.string,
  handleEdit: T.func,
  onDelete: T.func,
  currentUser: T.object.isRequired,
  params: T.object.isRequired,
};

export default withRouter(
  connect((state) => ({
    currentUser: state.auth.user,
  }))(SVRComment)
);
