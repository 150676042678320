import { handleActions, createAction } from 'redux-actions';
// utils
import { getUserNickname, getCachedMentionsKey } from 'utils';
// requests
import { searchUsers } from 'endpoints/users';

const initialState = {};

const CACHE_FETCHED_MENTIONS = 'mentions/CACHE_FETCHED_MENTIONS';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchMentions = (id, type, having_roles) => (dispatch, getState) => {
  const { isOnline } = getState().network;
  if (!isOnline) return null;
  return searchUsers({ [`${type}_id`]: id, without_pagination: true, having_roles })
    .then((response) => {
      const mentions = response.resources.map(
        (user) => ({
          id: String(user.id),
          display: '@' + getUserNickname(user),
          user,
        })
      );
      dispatch(createAction(CACHE_FETCHED_MENTIONS)({ having_roles, mentions, type, id }));
    });
};

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({
  [CACHE_FETCHED_MENTIONS]: (state, { payload }) => {
    const { having_roles, mentions, type, id } = payload;
    const key = getCachedMentionsKey(type, id, having_roles);
    return {
      ...state,
      [key]: mentions,
    };
  },

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
