import { combineReducers } from 'redux';
import groupShow from './pigGroupShow';
import groupMigrationShow from './pigGroupMigrationShow';

const pigGroupsReducer = combineReducers({
  groupShow,
  groupMigrationShow,
});

export default pigGroupsReducer;
