import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fetchCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import UsersCheckinsTable from './UsersCheckinsTable';
import ModeSwitcher from '../components/ModeSwitcher/ModeSwitcher';
import UsersCheckinsMap from './UsersCheckinsMap';
import Subnavigation from 'components/Subnavigation';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
// assets
import './UsersCheckins.scss';

const subnavItems = [
  { tab: 'users', name: <FormattedMessage id="general.users" />, path: '/checkins/users' },
  { tab: 'farms', name: <FormattedMessage id="general.farms" />, path: '/checkins/farms' },
];

class UsersCheckins extends Component {

  componentDidMount() {
    const { fetchCompanyProfile, currentCompanyId } = this.props;
    fetchCompanyProfile(`/admin/companies/${currentCompanyId}`).catch(toastResponseErrors);
  }

  render() {
    const { company, location, isLoading, currentCompanyId } = this.props;
    const { isMobile, router } = this.context;
    const isMapMode = location.pathname.includes('map');

    return (
      <div className={cn('UsersCheckins', { isMobile, 'CheckinsSection': isMapMode })}>
        <CompanyProfileHeader company={company} isLoading={isLoading} actualCompanyId={currentCompanyId}>
          <ModeSwitcher
            tableLink="/checkins/users"
            mapLink="/checkins/users/map"
          />
        </CompanyProfileHeader>
        <Subnavigation
          className="small-12 column"
          isActive={(item) => item.tab === 'users'}
          items={subnavItems}
          onSelect={(item) => router.push(item.path)}
        />
        {isMapMode
          ? <UsersCheckinsMap />
          : <UsersCheckinsTable />}
      </div>
    );
  }
}

UsersCheckins.propTypes = {
  company: T.object.isRequired,
  isLoading: T.bool.isRequired,
  currentCompanyId: T.number.isRequired,
  fetchCompanyProfile: T.func.isRequired,
  location: T.object.isRequired,
};

UsersCheckins.contextTypes = {
  isMobile: T.bool,
  router: T.object,
};

const enhance = compose(
  connect(
    (state) => ({
      company: state.profiles.company.data,
      isLoading: state.profiles.company.isLoading,
      currentCompanyId: state.auth.user.current_company.id,
    }), {
      fetchCompanyProfile,
    }
  )
);

export default enhance(UsersCheckins);
