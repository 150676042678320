import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import { CommitmentStatusBadge } from 'components/StatusBadge/StatusBadge';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// styles
import styles from './CommitmentRow.module.scss';

const cn = classnames.bind(styles);

const CommitmentRow = ({
  commitment: { loading, arrive_at, head_count, status },
  onClick,
  isSelected = false
}) => (
  <div
    onClick={status === 'pending' ? onClick : undefined}
    className={cn('row-body', { selected: isSelected, disabled: status !== 'pending' })}
  >
    <div className={cn('column', 'semibold')}>
      {isSelected && <i className={cn('fa fa-checkbox-marked-circle', 'icon')} />}
      {loading.name}
      <CommitmentStatusBadge className="mh-10" small status={status} />
    </div>
    <div className={cn('column')}>
      <FormattedMessage id="general.headCount" values={{ head_count: head_count || 'N/A' }} />
    </div>
    <div className={cn('column')}>
      <FormattedMessage
        id="general.arriveAt"
        values={{ arrive: arrive_at ? moment(arrive_at).format('MM/DD/YYYY') : 'N/A' }}
      />
    </div>
  </div>
);

CommitmentRow.propTypes = {
  commitment: T.object.isRequired,
  isSelected: T.bool,
  onClick: T.func.isRequired,
};

export default CommitmentRow;
