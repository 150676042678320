import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// utils
import { formatMortality } from 'utils';
import { farmTypes } from 'constants.js';

export const generalInfo = [
  {
    label: <FormattedMessage id="general.farmManagementProvider" />,
    renderer: (farm, tenantName) => (farm.manager?.name || tenantName),
  },
  {
    label: <FormattedMessage id="general.farm" />,
    renderer: (farm) => farm.name,
  },
  {
    label: <FormattedMessage id="general.federalFarmId" />,
    renderer: (farm) => farm.federal_premise_id || 'N/A',
  },
  {
    label: <FormattedMessage id="general.farmType" />,
    renderer: ({ farm_type }) => (farmTypes.find((type) => type.value === farm_type)?.label || 'N/A')
  },
  {
    label: <FormattedMessage id="general.barns" />,
    renderer: (farm) => farm.barns_count || 0,
  },
  {
    label: <FormattedMessage id="general.openGroups" />,
    renderer: (farm) => farm.open_groups_count || 0,
  },
  {
    label: <FormattedMessage id="general.role.farmOwner" />,
    renderer: (farm) => farm.owner?.full_name || 'N/A',
  },
  {
    label: <FormattedMessage id="general.caregivers" />,
    renderer: ({ caregivers }) => (caregivers?.length ? caregivers.join(', ') : 'N/A')
  },
  {
    label: <FormattedMessage id="general.farmCompliance" />,
    renderer: (farm) => (farm.progress ? farm.progress + '%' : 'N/A'),
  },
];

export const groupInfo = [
  {
    label: <FormattedMessage id="general.currentInventory" />,
    renderer: (group) => group.pigs ?? 'N/A',
  },
  {
    label: <FormattedMessage id="general.currentPercentMortality" />,
    renderer: ({ mortality_rate }) => formatMortality(mortality_rate),
  },
  {
    label: <FormattedMessage id="general.estAvgWt" />,
    renderer: (group) => <FormattedWeight value={group.estimated_weight} />,
  },
];
