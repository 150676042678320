import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// utils
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
// styles
import drBotLogo from './drbot-logo.png';
import './Modal.scss';

const Modal = ({
  title,
  closeModal,
  children,
  className = '',
  noCloseButton,
  drBot,
  onClose,
}) => {
  return (
    <div className={cn('modal', { drBot, isMobile, [className]: !!className })}>
      {!noCloseButton &&
        <i className="fa fa-times close-button" onClick={onClose || closeModal} />
      }
      {title && (
        <section className="modal-header flex">
          <span className="title">
            {drBot && <img src={drBotLogo} alt="" />}
            {title}
          </span>
        </section>
      )}
      {children}
    </div>
  );
};

Modal.propTypes = {
  title: T.oneOfType([T.string, T.object]),
  children: T.any.isRequired,
  className: T.string,
  noCloseButton: T.bool,
  drBot: T.bool,
  onClose: T.func,
  closeModal: T.func,
};

export default connect(null, { closeModal })(Modal);
