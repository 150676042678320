import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router';
import Button from 'components/Button';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import InputLabel from 'components/Input/InputLabel';
import InputView from 'components/Input/InputView';
import { ReactComponent as AngleLeftIcon } from 'assets/svg/angle-left-btb.svg';
// api
import { fetchVirtualVisit } from 'endpoints/virtualVisits';
// utils
import moment from 'moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const VirtualVisitView = ({
  currentUser,
  params: { id },
  router,
  setBreadcrumbs,
}) => {
  const [{ isLoading, virtualVisit }, setState] = useState({ isLoading: false, virtualVisit: null });
  const { formatMessage } = useIntl();
  const isPastDate = virtualVisit?.schedule_at ? moment(virtualVisit.schedule_at).isBefore(moment()) : true;
  const isUserCanEdit = virtualVisit?.creator?.id === currentUser.id && !isPastDate;

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    fetchVirtualVisit(id)
      .then((resource) => {
        setState({ virtualVisit: resource, isLoading: false });
        setBreadcrumbs([
          { label: formatMessage({ id: 'general.virtualVisits' }), path: '/virtual-visits' },
          { label: `#${resource.token}`, mobileTitle: formatMessage({ id: 'general.virtualVisits' }) },
        ]);
      })
      .catch((errors) => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
        toastResponseErrors(errors);
      });
  }, [id]);

  return (
    <Panel className="pv-20 ph-15">
      <Panel.Heading
        title={<FormattedMessage id="general.virtualVisitName" values={{ name: virtualVisit?.token || null }} />}
      >
        {isUserCanEdit && (
          <Button small onClick={() => router.push(`/virtual-visits/${id}/edit`)}>
            <FormattedMessage id="general.button.edit" />
          </Button>
        )}
      </Panel.Heading>
      <Panel.Body>
        <Preloader isActive={isLoading} />
        <div className="row">
          <div className="small-12 medium-6 column">
            <InputLabel>
              <FormattedMessage id="general.farmName" />
            </InputLabel>
            <InputView className="mb-5">
              {virtualVisit?.farm.name}
            </InputView>
            <InputLabel>
              <FormattedMessage id="general.creator" />
            </InputLabel>
            <InputView className="mb-5">
              {virtualVisit?.creator.full_name}
            </InputView>
            <InputLabel>
              <FormattedMessage id="general.invitee" />
            </InputLabel>
            <InputView className="mb-5">
              {virtualVisit?.invitee.full_name}
            </InputView>
            <InputLabel>
              <FormattedMessage id="general.scheduleTime" />
            </InputLabel>
            <InputView className="mb-15">
              {virtualVisit?.schedule_at ? moment(virtualVisit.schedule_at).format('MM/DD/YYYY, hh:mm A') : null}
            </InputView>
            <Link to="/virtual-visits" className="button small">
              <AngleLeftIcon height={10} className="mr-5" />
              <FormattedMessage id="general.button.back" />
            </Link>
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

VirtualVisitView.propTypes = {
  router: T.object.isRequired,
  params: T.object.isRequired,
  currentUser: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
  }), { setBreadcrumbs }
)(VirtualVisitView);
