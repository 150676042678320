import { createAction, handleActions } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
const OPEN_DROPDOWN = 'dropdownLayout/OPEN_DROPDOWN';
const CLOSE_DROPDOWN = 'dropdownLayout/CLOSE_DROPDOWN';

const initialState = {
  isOpen: false,
  options: [],
  targetCoords: {},
  IdKey: '',
  ignoreScroll: false,
};

// ------------------------------------
// Actions
// ------------------------------------
export const openDropdown = createAction(OPEN_DROPDOWN,
  (options, targetCoords = {}, IdKey = '', ignoreScroll = false) => ({ options, targetCoords, IdKey, ignoreScroll }));
export const closeDropdown = createAction(CLOSE_DROPDOWN);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [OPEN_DROPDOWN]: (state, { payload }) => ({
    isOpen: !state.isOpen,
    ...payload,
  }),
  [CLOSE_DROPDOWN]: () => ({
    ...initialState,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
