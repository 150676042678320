import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setAdvancedSearchVisibility } from 'reducers/layout';
import { createUserFilterAction, updateUserFilterAction } from 'reducers/userFilters';
// components
import UserFilter from 'components/UserFilter';
import UserFilterMobileHeader from 'components/UserFilterMobile/UserFilterMobileHeader';
// utils, constants
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { userFilterGroupNames, allOrAnyEventsOptions } from 'utils/constants/userFilters';
// styles
import styles from './FarmFeedUserFilter.module.scss';

const cn = classnames.bind(styles);

const FarmFeedUserFilter = ({
  availFilterSections,
  createUserFilterAction,
  currentFilter,
  isFilterVisible,
  isMobileView,
  setActivitiesFilter,
  setAdvancedSearchVisibility,
  openMobileFilters,
  updateUserFilterAction,
}) => {
  const sectionsCount = currentFilter?.sections?.length || 0;

  useEffect(() => {
    if (!isFilterVisible && !!currentFilter) {
      setAdvancedSearchVisibility(true);
    }
  }, []);

  const handleSaveFilter = (resource, isUpdating) => {
    if (isUpdating) {
      return updateUserFilterAction(currentFilter?.id, userFilterGroupNames.activities, resource).catch((errors) => {
        toastResponseErrors(errors);
        throw errors;
      });
    }
    return createUserFilterAction({ ...resource, group_name: userFilterGroupNames.activities }).catch((errors) => {
      toastResponseErrors(errors);
      throw errors;
    });
  };

  const handleChangeSections = (filter) => {
    setActivitiesFilter(filter.sections.length ? filter : {});
  };

  if (isMobileView && sectionsCount > 0) {
    return (
      <UserFilterMobileHeader
        onClick={openMobileFilters}
        onClose={() => setActivitiesFilter({})}
        sectionsCount={sectionsCount}
        segmentName={currentFilter.name}
      />
    );
  }

  return (
    <div className={cn('farmfeed-filter', 'show-for-large', { opened: isFilterVisible })}>
      <UserFilter
        availFilterSections={availFilterSections}
        onSectionsChange={handleChangeSections}
        isVisible={isFilterVisible}
        initialFilter={currentFilter}
        listOptions={allOrAnyEventsOptions}
        onSave={handleSaveFilter}
        filterGroupName={userFilterGroupNames.activities}
      />
    </div>
  );
};

FarmFeedUserFilter.propTypes = {
  availFilterSections: T.array.isRequired,
  isFilterVisible: T.bool.isRequired,
  isMobileView: T.bool.isRequired,
  createUserFilterAction: T.func.isRequired,
  currentFilter: T.object,
  setActivitiesFilter: T.func.isRequired,
  setAdvancedSearchVisibility: T.func.isRequired,
  openMobileFilters: T.func.isRequired,
  updateUserFilterAction: T.func.isRequired,
};

export default connect(
  (state) => ({
    isFilterVisible: state.layout.showAdvancedSearch,
  }), {
    createUserFilterAction,
    setAdvancedSearchVisibility,
    updateUserFilterAction,
  }
)(FarmFeedUserFilter);
