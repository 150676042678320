import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import './PaginationDots.scss';

const PaginationDots = ({ assetsLength, currentIndex }) => {
  const formatPager = () => {
    if (assetsLength < 7) {
      return Array.from(new Array(assetsLength)).map((_, i) => ({ page: i }));
    }
    let shift = 2;
    if (currentIndex < 2) shift = currentIndex;
    if (currentIndex + 2 > assetsLength - 1) shift = 5 - (assetsLength - currentIndex);
    return Array.from(new Array(5)).map((_, i) => ({ page: (currentIndex + i) - shift }));
  };

  const pager = formatPager();
  const isCycling = assetsLength > 6;

  return (
    <div className="PaginationDots">
      {isCycling && pager[0].page > 0 && (
        <div className="page-dot small" key="0" />
      )}
      {pager.map(({ page }) => (
        <div className={cn('page-dot', { 'active': currentIndex === page })} key={page} />
      ))}
      {isCycling && pager[4].page + 1 < assetsLength && (
        <div className="page-dot small" key={assetsLength - 1} />
      )}
    </div>
  );
};

PaginationDots.propTypes = {
  assetsLength: T.number.isRequired,
  currentIndex: T.number.isRequired,
};

export default PaginationDots;
