import React from 'react';
import T from 'prop-types';
// hoc
import withSoundOnMount from 'hoc/withSoundOnMount';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal/Modal';
// assets
import magicLoopSvg from '../../../../../public/images/magic_2_looped.svg';
// styles
import './AISuggestionModal.scss';

const AISuggestionModal = ({ onMarkAsSeen }) => (
  <Modal noCloseButton className="AISuggestionModal">
    <section className="suggestion-modal-body">
      <img className="magic-loop-image" src={magicLoopSvg} alt="AI Suggestion pop-up" />
      <div className="text">
        <FormattedMessage id="component.modal.AISuggestion.text" />
      </div>
    </section>
    <section className="suggestion-modal-footer">
      <Button primary className="got-it-btn" onClick={onMarkAsSeen}>
        <FormattedMessage id="general.button.gotIt" />
      </Button>
    </section>
  </Modal>
);

AISuggestionModal.propTypes = {
  onMarkAsSeen: T.func.isRequired,
};

export default withSoundOnMount('/static/sounds/ai_suggestion_sound.wav')(AISuggestionModal);
