import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import LayoutMinimal from 'components/LayoutMinimal';
import Button from 'components/Button';

const AcceptInvite = ({
  isLoading,
  params: { token },
  location,
  router,
  user,
}) => {
  const name = (user?.first_name || user?.full_name || '').trim();
  return (
    <LayoutMinimal>
      <Preloader isActive={isLoading} isCentered />
      <FormattedMessage tagName="h1" id="container.invitations.greeting" values={{ user: <b>{name}</b> }} />
      <FormattedMessage
        tagName="h1"
        id="container.invitations.doYouAccept"
        values={{
          tenant: <b>{location.query?.resource_name}</b>,
          app: <b>EveryPig</b>,
        }}
      />
      <Button
        disabled={isLoading}
        primary
        className="lm-button"
        onClick={() => router.push(`/invitations/${token}/set-password`)}
      >
        <FormattedMessage id="container.invitations.accept" />
      </Button>
    </LayoutMinimal>
  );
};

AcceptInvite.propTypes = {
  isLoading: T.bool.isRequired,
  params: T.object.isRequired,
  user: T.object.isRequired,
  location: T.object.isRequired,
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    user: state.invitations.user,
    isLoading: state.invitations.isLoading,
  })
)(AcceptInvite);
