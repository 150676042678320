import React, { useState } from 'react';
import T from 'prop-types';
// components
import { DayPickerRangeController as DayPicker } from 'react-dates';
import { START_DATE, VERTICAL_ORIENTATION } from 'react-dates/constants';
import DatePickerMobileBox from 'components/DatePicker/DatePickerMobileBox';
// utils
import cn from 'classnames';
import moment from 'moment';
// styles
import '../styles/DatePickerMobile.scss';

const DateRangePickerMobile = ({
  className,
  closePortal,
  isOutsideRange,
  initialEndDate,
  initialStartDate,
  minimumNights = 0,
  onChange,
}) => {
  const [{ endDate, focusedInput, startDate }, setState] = useState({
    startDate: initialStartDate || null,
    endDate: initialEndDate || null,
    focusedInput: START_DATE,
  });

  const handleChangeDates = ({ startDate, endDate }) => {
    setState((prevState) => ({ ...prevState, startDate, endDate }));
  };

  const handleChangeFocus = (focusedInput) => {
    // Force the focusedInput to always be truthy so that dates are always selectable
    setState((prevState) => ({ ...prevState, focusedInput: !focusedInput ? START_DATE : focusedInput }));
  };

  const handleClearDates = () => {
    setState({ startDate: null, endDate: null, focusedInput: START_DATE });
  };

  const handleSubmitDates = () => {
    onChange({ startDate, endDate });
    closePortal();
  };

  const initialVisibleMonth = () => moment().subtract(2, 'weeks');

  return (
    <DatePickerMobileBox
      className={cn('DatePickerMobile', { [className]: !!className })}
      endDate={endDate}
      focusedInput={focusedInput}
      onClose={closePortal}
      onClearDates={handleClearDates}
      onSubmit={handleSubmitDates}
      startDate={startDate}
    >
      <DayPicker
        startDate={startDate}
        endDate={endDate}
        focusedInput={focusedInput}
        onDatesChange={handleChangeDates}
        onFocusChange={handleChangeFocus}
        numberOfMonths={3}
        orientation={VERTICAL_ORIENTATION}
        isOutsideRange={isOutsideRange}
        initialVisibleMonth={initialVisibleMonth}
        daySize={42}
        minimumNights={minimumNights}
      />
    </DatePickerMobileBox>
  );
};

DateRangePickerMobile.propTypes = {
  className: T.string,
  initialStartDate: T.object,
  initialEndDate: T.object,
  onChange: T.func,
  isOutsideRange: T.func,
  closePortal: T.func,
  minimumNights: T.number,
};

export default DateRangePickerMobile;
