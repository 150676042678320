import { fetchFromAPI } from 'utils/api';

export const getDisease = (id) => {
  return fetchFromAPI(`/admin/diagnoses/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateDisease = (id, resource) => {
  return fetchFromAPI(`/admin/diagnoses/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const createMortalityReason = (resource) => {
  return fetchFromAPI('/admin/mortality_reasons', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getMortalityReason = (id) => {
  return fetchFromAPI(`/admin/mortality_reasons/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateMortalityReason = (id, resource) => {
  return fetchFromAPI(`/admin/mortality_reasons/${id}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};
