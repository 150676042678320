import React from 'react';
import T from 'prop-types';
// components
import MobileHeader from 'components/MobileHeader/MobileHeader';
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames/bind';
import { authFrontendV2Url } from 'utils/urlBuilder';
// styles
import styles from './SystemSwitcher.module.scss';

const SystemSwitcher = ({ goBack }) => (
  <div className={styles['system-switcher']}>
    <MobileHeader
      title={<FormattedMessage id="component.mobileMenu.switchApp" />}
      backLink={goBack}
      backIcon="fa fa-angle-left-btb"
    />
    <div className={styles.list}>
      <div className={styles['switch-app-row']}>
        <i className={cn('fa fa-industry', styles['left-icon'])} />
        <div className={styles['app-name']}>
          <FormattedMessage id="general.production" />
          <i className={cn('fa fa-check', styles['check-icon'])} />
        </div>
      </div>
      <div
        className={styles['switch-app-row']}
        onClick={() => { window.location.href = authFrontendV2Url('trucking'); }}
      >
        <i className={cn('fa fa-ep-truck', styles['left-icon'])} />
        <div className={styles['app-name']}><FormattedMessage id="component.sidebar.trucking" /></div>
      </div>
    </div>
  </div>
);

SystemSwitcher.propTypes = {
  goBack: T.func.isRequired,
};

export default SystemSwitcher;
