import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import OfflineScreen from 'components/OfflineScreen';
// utils
import { FormattedMessage } from 'react-intl';
// styles
import './ApiMonitoring.scss';
import { setPageOptions } from '../../reducers/layout';
import { setBreadcrumbs } from '../../reducers/breadcrumbs';

const links = [
  { link: '/monitoring/metafarms', label: <FormattedMessage id="component.metafarms.link" /> },
  { link: '/monitoring/prairiesystems', label: <FormattedMessage id="component.prairieSystems.link" /> },
];

class ApiMonitoring extends Component {
  componentDidMount() {
    const { setPageOptions, setBreadcrumbs } = this.props;
    setPageOptions({ links });
    setBreadcrumbs();
  }

  componentWillUnmount() {
    this.props.setPageOptions({ links: null });
  }

  render() {
    const { isOnline, children } = this.props;
    return (
      <div className="wrapperApi">
        <div className="BarnsheetsHome clearfix">
          {isOnline
            ? (
              <div className="small-12 column">
                {children}
              </div>
            )
            : <OfflineScreen />}
        </div>
      </div>
    );
  }
}

ApiMonitoring.propTypes = {
  children: T.object.isRequired,
  isOnline: T.bool,
  setPageOptions: T.func,
  setBreadcrumbs: T.func,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  }),
  { setPageOptions, setBreadcrumbs }
)(ApiMonitoring);
