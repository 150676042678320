import React from 'react';
import T from 'prop-types';
// components
import AmCharts from '@amcharts/amcharts3-react';
// assets
import './Amchart.scss';

const Amchart = ({ config, style, bindRef }) => (
  <div className="Amchart">
    <AmCharts.React
      style={style}
      options={config}
      ref={bindRef}
    />
  </div>
);

Amchart.propTypes = {
  config: T.object.isRequired,
  style: T.object.isRequired,
  bindRef: T.func,
};

export default Amchart;
