import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { formValueSelector, reduxForm } from 'redux-form';
import Button from 'components/Button';
// import RulesFields from 'components/ReduxFormFields/RulesFields/RulesFields';
import LabeledInput from 'components/LabeledInput/LabeledInput';
import ToggleSwitcher from 'components/ToggleSwitcher';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import Field from 'components/Field';
// utils
import cn from 'classnames';
// assets
import './MedicationSettings.scss';

const WithdrawalInput = ({ input, meta: { touched, error }, label }) => (
  <LabeledInput
    type="number"
    step={1}
    min={0}
    max={1000}
    placeholder="0"
    isLabelBoxTransparent
    alignRight={false}
    isBolded={false}
    input={input}
    onChange={(e) => {
      if (e.target.validity.valid) input.onChange(e);
    }}
    label={label}
    className={cn({ 'has-error': touched && error })}
  />
);

WithdrawalInput.propTypes = {
  input: T.shape({
    onChange: T.func,
    value: T.any,
  }),
  meta: T.shape({
    touched: T.bool,
    error: T.any,
  }),
  label: T.object.isRequired,
};

const MedicationSettings = ({
  handleSubmit,
  pristine,
  submitting,
  isLoading,
  // alerts,
  // rules,
  withdrawal_period_export = 'days', // hours, days, weeks, months
  withdrawal_period_us = 'days', // hours, days, weeks, months
  notes,
}) => {
  const normalize = (value) => (value === '' ? value : Number(value));
  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.settings" />} />
        <Panel.Body>
          <div className="row">
            <div className="small-12 medium-12 large-12 column">
              <Preloader isActive={isLoading} />
              <div className="MedicationSettings">
                <FormErrorsBox formName="medication-settings" />
                <form onSubmit={handleSubmit}>
                  <span className="field-label"><FormattedMessage id="general.withdrawalPeriod" /></span>
                  <div className="withdrawal-inputs small-12 medium-8 large-8">
                    <div className="us-input">
                      <label>
                        <FormattedMessage id="general.usa">
                          {(text) => <span className="field-label">{text}</span>}
                        </FormattedMessage>
                        <Field
                          name="withdrawal_value_us"
                          label={<FormattedMessage id={`general.${withdrawal_period_us}`} />}
                          component={WithdrawalInput}
                          normalize={normalize}
                        />
                      </label>
                    </div>

                    <div className="export-input">
                      <label>
                        <FormattedMessage id="general.exports">
                          {(text) => <span className="field-label">{text}</span>}
                        </FormattedMessage>
                        <Field
                          name="withdrawal_value_export"
                          label={<FormattedMessage id={`general.${withdrawal_period_export}`} />}
                          component={WithdrawalInput}
                          normalize={normalize}
                        />
                      </label>
                    </div>
                  </div>

                  {/* Notes */}
                  <div className="row mt-15">
                    <div className="small-12 medium-8 large-8 column">
                      <div className="notes-field">
                        <span className="note-characters">
                          {notes?.length || 0}/128 <FormattedMessage id="general.characters" />
                        </span>
                        <label>
                          <Field
                            label={<FormattedMessage id="general.notes" />}
                            placeholderKey="general.notes"
                            className="note-input"
                            name="notes"
                            maxLength={128}
                          />
                          <FormattedMessage id="general.notesTip">
                            {(text) => <span className="notesTip">{text}</span>}
                          </FormattedMessage>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row"> */}
                  {/* <div className="column"> */}
                  {/* <Field */}
                  {/* type="checkbox" */}
                  {/* name="alerts" */}
                  {/* text={<FormattedMessage id="general.usageAlerts" />} */}
                  {/* description={<FormattedMessage id="general.alertsDesc" />} */}
                  {/* component={ToggleSwitcher} */}
                  {/* /> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* {(alerts || !!(rules && rules.length)) && */}
                  {/* <div className="wrapper"> */}
                  {/* <div className="rules-wrapper"> */}
                  {/* <FieldArray */}
                  {/* name="rules" */}
                  {/* alerts={alerts} */}
                  {/* component={RulesFields} */}
                  {/* /> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* } */}
                  <div className="row">
                    <div className="column">
                      <Field
                        type="checkbox"
                        name="common"
                        text={<FormattedMessage id="general.commonMed" />}
                        description={<FormattedMessage id="general.commonMedDesc" />}
                        component={ToggleSwitcher}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="column">
                      <Field
                        type="checkbox"
                        name="efficacy_survey"
                        text={<FormattedMessage id="general.vetBotEfficacy" />}
                        description={<FormattedMessage id="general.efficacyDesc" />}
                        component={ToggleSwitcher}
                      />
                    </div>
                  </div>

                  {/* Button */}
                  <div className="row mt-20 mb-20">
                    <div className="small-12 column">
                      <Button primary type="submit" disabled={pristine || submitting}>
                        <FormattedMessage id="general.button.saveChanges" />
                      </Button>
                      <Link to="/admin/health-variables/medications" className="button btn-link margin-left-1">
                        <FormattedMessage id="general.button.cancel" />
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </section>
  );
};

MedicationSettings.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
  isLoading: T.bool.isRequired,
  // alerts: T.bool,
  // rules: T.array,
  notes: T.string,
  withdrawal_period_us: T.string,
  withdrawal_period_export: T.string,
};

const selector = formValueSelector('medication-settings');

export default reduxForm({
  form: 'medication-settings',
  enableReinitialize: true,
})(connect(
  (state) => ({
    // alerts: selector(state, 'alerts'),
    // rules: selector(state, 'rules'),
    notes: selector(state, 'notes'),
    withdrawal_period_export: selector(state, 'withdrawal_period_export'),
    withdrawal_period_us: selector(state, 'withdrawal_period_us'),
  })
)(MedicationSettings));
