import React, { useState } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
// components
import SyncNote from 'components/SyncNote';
import { FormattedMessage } from 'react-intl';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
// styles
import styles from './VaccineSelect.module.scss';

const cn = classnames.bind(styles);
const VACCINE_PATH = '/treatment_products';
const VACCINE_PARAMS = { vaccine_only: true };

const noteTextareaProps = { rows: 4 };

const vaccineRenderer = (item) => (
  <div className={cn('vaccine-line')}>
    <i className={cn('fa fa-lg fa-injectable mr-15', 'vaccine')} />
    <span className={cn('vaccine-label')}>{item.name}</span>
  </div>
);

const formatOption = (item) => ({ value: item.id, label: vaccineRenderer(item), item });

const VaccineSelect = ({
  note,
  treatment_product,
  onSave,
  onDelete,
  isEdit,
  setIsEdit,
  onCancel,
  selectedVaccines
}) => {
  const initialVaccine = !isEmpty(treatment_product) ? formatOption(treatment_product) : null;
  const [vaccine, setVaccine] = useState(initialVaccine);
  const [comment, setComment] = useState(note || '');

  const optionsMapper = (options) => options.map(({ value, ...rest }) => ({
    ...rest,
    value,
    disabled: selectedVaccines.includes(value) && value !== get(vaccine, 'value'),
  }));

  const onSelectChange = (value) => {
    if (!isEdit && (value.value !== vaccine.value)) setIsEdit(true);
    setVaccine(value);
  };

  const onCancelClick = () => {
    setIsEdit(false);
    setVaccine(initialVaccine);
    setComment(note || '');
    onCancel();
  };

  const onSaveClick = (newComment) => onSave({ notes: newComment, vaccineId: vaccine.value });


  return (
    <div className={cn('vaccine-form')}>
      <div className={cn('form-header')}>
        <div className={cn('vaccine-select-line')}>
          <span className={cn('vaccine-title')}>
            <FormattedMessage id="general.vaccine" />
          </span>
          <div className={cn('small-12 xlarge-8 mr-15')}>
            <AsyncSelect
              onChange={onSelectChange}
              optionsPath={VACCINE_PATH}
              requestParams={VACCINE_PARAMS}
              selected={vaccine}
              optionsKeys={{ value: 'id', label: 'name' }}
              placeholder={<FormattedMessage id="general.selectVaccine" />}
              labelRenderer={vaccineRenderer}
              optionsMapper={optionsMapper}
            />
          </div>
          {!isEdit && <i className={cn('fa fa-trash-o', 'remove-btn')} onClick={onDelete} />}
        </div>
        <SyncNote
          componentProps={noteTextareaProps}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          comment={comment}
          onCancel={onCancelClick}
          onSave={onSaveClick}
          isSaveDisabled={!vaccine}
        />
      </div>
    </div>
  );
};

VaccineSelect.propTypes = {
  note: T.string,
  treatment_product: T.object,
  onSave: T.func,
  onDelete: T.func,
  isEdit: T.bool,
  setIsEdit: T.func,
  onCancel: T.func,
  selectedVaccines: T.array,
};

export default VaccineSelect;
