import React, { useState } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// components
import Preloader from '../Preloader';
import InputRange from 'components/InputRange/InputRange';
import { FormattedMessage } from 'react-intl';
// styles
import styles from './ImageCropperModal.module.scss';

const cn = classnames.bind(styles);

const ImageCropperMobile = ({
  rotateTo,
  isLoading,
  onSave,
  renderCropper,
  closeModal,
  onRotate,
  removeImage,
  uploadImage,
  isEditMode,
}) => {

  const [isViewMode, toggleMode] = useState(!isEditMode);

  const handleUpload = (e) => {
    toggleMode(!isViewMode);
    uploadImage(e);
  };

  return (
    <>
      {isLoading && <div className={cn('loading-mask')} />}
      <Preloader isActive={isLoading} className={cn('cropper-preloader')} />
      <div className={cn('mobile-header', { 'view-mode': isViewMode })}>
        <i className="close-btn fa fa-times" onClick={closeModal} />
        {!isViewMode && (
          <>
            <span className={cn('header-title')}>
              <FormattedMessage id="component.imageCropperModal.editPhoto" />
            </span>
            <span className={cn('save-btn')} onClick={onSave}>
              <FormattedMessage id="general.button.save" />
            </span>
          </>
        )}
      </div>
      {renderCropper(isViewMode)}
      <div className={cn('edit-panel')}>
        {isViewMode ? (
          <>
            <i className="fa fa-pencil" onClick={() => toggleMode(!isViewMode)} />
            <label htmlFor="image-upload" className={cn('image-label')}>
              <i className="fa fa-cloud-upload" />
              <input onChange={handleUpload} type="file" id="image-upload" accept="image/png, image/jpeg" />
            </label>
            <i className="fa fa-trash-o" onClick={removeImage} />
          </>
        ) : (
          <>
            <InputRange
              from={-180}
              to={180}
              step={1}
              label={<FormattedMessage id="component.imageCropperModal.straighten" />}
              onChange={rotateTo}
            />
            <i className="rotate-btn fa fa-undo ml-30" onClick={onRotate} />
          </>
        )}
      </div>
    </>
  );
};

ImageCropperMobile.propTypes = {
  rotateTo: T.func.isRequired,
  isLoading: T.bool.isRequired,
  onSave: T.func.isRequired,
  renderCropper: T.func.isRequired,
  closeModal: T.func.isRequired,
  onRotate: T.func.isRequired,
  uploadImage: T.func.isRequired,
  removeImage: T.func.isRequired,
  isEditMode: T.bool,
};

export default ImageCropperMobile;
