import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: {},
  isLoading: false,
  logs: [],
};

const FETCH_CURRENT_CHECKUP = 'prairie_systems/FETCH_CURRENT_CHECKUP';
const [FETCH_CURRENT_CHECKUP_PENDING, FETCH_CURRENT_CHECKUP_FULFILLED, FETCH_CURRENT_CHECKUP_REJECTED] = [
  `${FETCH_CURRENT_CHECKUP}_PENDING`,
  `${FETCH_CURRENT_CHECKUP}_FULFILLED`,
  `${FETCH_CURRENT_CHECKUP}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCurrentPrairieSystems = () => createAction(FETCH_CURRENT_CHECKUP)(
  fetchFromAPI('prairie_systems/push_results ', { method: 'GET' })
);
export const fetchCurrentPrairieSystemsID = (id) => createAction(FETCH_CURRENT_CHECKUP)(
  fetchFromAPI(`prairie_systems/push_result/:${id}`, { method: 'GET' })
);
export const fetchCurrentPrairieSystemsRepeat = (id) => createAction(FETCH_CURRENT_CHECKUP)(
  fetchFromAPI(`prairie_systems/push_result/:${id}/rerun`, { method: 'POST' })
);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_CURRENT_CHECKUP
  [FETCH_CURRENT_CHECKUP_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_CURRENT_CHECKUP_FULFILLED]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      logs: action.payload.resource.logs,
      isLoading: false,
    };
  },
  [FETCH_CURRENT_CHECKUP_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
