import { combineReducers } from 'redux';
import siteVisitReportsFarms from './svrFarms';
import siteVisitReports from './svrs';

const svrReducer = combineReducers({
  farms: siteVisitReportsFarms,
  siteVisitReports,
});

export default svrReducer;
