import React from 'react';
import T from 'prop-types';
// components
import Dropdown from 'components/Dropdown';
import Link from 'react-router/lib/Link';
// utils
import classnames from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
// assets
import './ResponsiveDropdown.scss';

const ResponsiveDropdown = ({
  onDropdownToggle,
  options,
  trigger,
  closeOnClick = true,
  className,
}) => {
  const onlyMobile = isMobile && !isTablet;
  const defaultTrigger = (
    <button className="button light default-trigger">
      <i className="fa fa-dots-three-horizontal" />
    </button>
  );
  const visibleItems = options.filter((option) => !option.hide);

  return (
    !!visibleItems.length && (
    <div className={classnames('ResponsiveDropdown', className)}>
      <Dropdown
        closeOnClick={closeOnClick}
        withBackdrop={onlyMobile}
        trigger={trigger || defaultTrigger}
        onToggle={onDropdownToggle}
        className={classnames({ 'responsive-dropdown-container': onlyMobile })}
        dropdownClassName={classnames({ 'responsive-dropdown': onlyMobile })}
        stopPropagation
      >
        <ul className="dropdown-menu">
          {options.map((option, index) => (
            !option.hide && (
              <li key={index} onClick={option.onClick} className={option.className}>
                <Link to={option.url}>
                  {onlyMobile && (
                    option.icon ? <i className={`fa fa-${option.icon}`} /> : <i />
                  )}
                  <span>{typeof option.label === 'function' ? option.label() : option.label}</span>
                </Link>
              </li>
            )
          ))}
        </ul>
      </Dropdown>
    </div>
    )
  );
};

ResponsiveDropdown.propTypes = {
  className: T.string,
  trigger: T.object,
  closeOnClick: T.bool,
  options: T.array.isRequired,
  onDropdownToggle: T.func,
};

export default ResponsiveDropdown;
