import React from 'react';
import T from 'prop-types';
// components
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import MobileHeader from 'components/MobileHeader/MobileHeader';
// utils
import cn from 'classnames';
// styles
import styles from './MobileListPickerComponent.module.scss';

const MobileListPickerComponent = ({
  children,
  isLoading = false,
  isSearchHidden = false,
  headerLeftIcon = 'fa fa-angle-left-btb',
  headerLeftUrl,
  headerRightContent,
  onBack,
  onSearchChange,
  placeholder,
  search = '',
  title,
}) => {
  const isSearchAvail = !isSearchHidden && !!onSearchChange;
  return (
    <div className={styles['picker-component']}>
      <MobileHeader
        title={title}
        backLink={headerLeftUrl || onBack}
        backIcon={headerLeftIcon}
      >
        {headerRightContent}
      </MobileHeader>

      <div className={cn(styles['mobile-picker-data'], { [styles['has-search']]: isSearchAvail })}>
        {isSearchAvail && (
          <div className={styles['mobile-picker-search']}>
            <SearchBox
              initialValue={search}
              onChange={onSearchChange}
              className={styles['mobile-picker-search-box']}
            />
          </div>
        )}
        <div className={cn(styles['resources-list'], { [styles['has-placeholder']]: !!placeholder })}>
          <Preloader isActive={isLoading} />
          {children}
          {placeholder}
        </div>
      </div>
    </div>
  );
};

MobileListPickerComponent.propTypes = {
  isLoading: T.bool,
  isSearchHidden: T.bool,
  onSearchChange: T.func,
  search: T.string,
  title: T.oneOfType([T.string, T.node]).isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
  placeholder: T.oneOfType([T.arrayOf(T.node), T.node]),
  headerRightContent: T.node,
  headerLeftIcon: T.string,
  headerLeftUrl: T.string,
  onBack: T.func,
};

export default MobileListPickerComponent;
