import React, { Component } from 'react';
import T from 'prop-types';
// components
import AnalyticsPanel from 'components/Analytics/AnalyticsPanel';
import Amchart from 'components/Amchart';
import GraphLegend from '../../GraphLegend/GraphLegend';
import { FormattedMessage } from 'react-intl';
// api
import { getAnalyticsDailyMortality } from 'endpoints/admin/analytics';
// utils
import { getArrowClass, getRateClass } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import values from 'lodash.values';
import isNull from 'lodash.isnull';
import moment from 'moment';
// constants
import { dailyMortalityBalloonHTML } from 'components/Amchart/GraphItemBalloon/GraphItemBalloon';
import { defaultSerialChartConfig } from 'components/Amchart/chartConfig';
import { rangeOptions } from 'constants.js';

class DailyMortalityPanel extends Component {

  state = {
    data: {
      graphDates: [],
      rangeType: '7_days',
      isLoading: false,
    },
  };

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data: { rangeType } } = this.state;
    if (nextProps.companyId !== this.props.companyId) this.getData(rangeType, nextProps.companyId);
  }

  getData = (type = '7_days', companyId) => {
    const company_id = (isNull(companyId) || companyId) ? companyId : this.props.companyId;
    this.setState(({ data }) => ({
      data: {
        ...data,
        isLoading: true,
      },
    }));
    getAnalyticsDailyMortality({ type, company_id })
      .then((response) => {
        const graphDates = values(response.resource).map((value) => {
          const { mortality, mortality_was, mortality_change, date, date_was } = value;
          return {
            mortality,
            mortality_was,
            mortality_change: Math.abs(mortality_change).toFixed(3) + '%',
            blockClass: getRateClass(-mortality_change),
            iconClass: getArrowClass(mortality_change),
            date: moment(date).format('DD MMM'),
            tooltip_date: moment(date).format('ddd DD MMM'),
            tooltip_date_was: moment(date_was).format('ddd DD MMM'),
          };
        });
        this.setState({
          data: {
            graphDates,
            rangeType: type,
            isLoading: false,
          },
        });
      })
      .catch((response) => {
        this.setState(({ data }) => ({
          data: {
            ...data,
            isLoading: false,
          },
        }));
        toastResponseErrors(response);
      });
  };

  render() {
    const { data, data: { rangeType } } = this.state;
    const { formatMessage } = this.context;

    const serialChartConfig = {
      ...defaultSerialChartConfig,
      dataProvider: data.graphDates,
      graphs: [
        {
          balloonText: dailyMortalityBalloonHTML,
          balloonColor: '#A3A3A3',
          bullet: 'round',
          lineColor: '#FF5E3A',
          lineThickness: 2,
          id: 'AmGraph-1',
          title: formatMessage({ id: 'general.dailyMortality' }),
          type: 'smoothedLine',
          valueField: 'mortality',
        }, {
          showBalloon: false,
          bullet: 'round',
          lineColor: '#2170D1',
          id: 'AmGraph-2',
          title: formatMessage({ id: 'general.dailyMortality' }),
          type: 'smoothedLine',
          dashLength: 8,
          valueField: 'mortality_was',
        },
      ],
    };
    const amChartStyle = { height: '330px' };

    return (
      <AnalyticsPanel
        titleKey="dailyMortality"
        panelData={data}
        fullRow
        getRangeData={this.getData}
        rangeOptions={rangeOptions.filter((option) => !['today', 'yesterday'].includes(option))}
      >
        <div className="legend-block in-row mt-10">
          <GraphLegend
            backgroundColor="#FF5E30"
            text={<FormattedMessage id={`component.analyticsPanel.rangeLabel.current.${rangeType}`} />}
          />
          <GraphLegend
            backgroundColor="#2170D1"
            text={<FormattedMessage id={`component.analyticsPanel.rangeLabel.${rangeType}`} />}
          />
        </div>
        <div className="chart-block">
          {!!data.graphDates.length &&
            <Amchart style={amChartStyle} config={serialChartConfig} />
          }
        </div>
      </AnalyticsPanel>
    );
  }
}

DailyMortalityPanel.propTypes = {
  companyId: T.number,
};

DailyMortalityPanel.contextTypes = {
  formatMessage: T.func,
};

export default DailyMortalityPanel;
