import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import Button from 'components/Button';
// utils
import sum from 'lodash.sum';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import handleFormErrors from 'utils/handleFormErrors';
import { showToastrMessage } from 'utils';
// endpoints
import { updateMortalityReason } from 'endpoints/admin/healthVariables';

class MortalityReasons extends Component {

  componentDidMount() {
    const { reqParams, fetchData } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, status: filter });
  };

  disableItem = (id, status) => () => {
    const { setDataItem } = this.props;

    return updateMortalityReason(id, { active: status })
      .then((resource) => {
        setDataItem(resource);
        showToastrMessage('component.toastr.status.changed');
      })
      .catch(handleFormErrors);
  };

  renderNameColumn = ({ name }) => (
    <CustomColumn>
      <span className="semibold">{name}</span>
    </CustomColumn>
  );

  renderLanguagesColumn = ({ translations_count }) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.languagesSupported" />}>
      {translations_count}
    </CustomColumn>
  );

  renderCodeColumn = ({ code }) => (
    <CustomColumn label={<FormattedMessage id="container.admin.healthVariables.reasons.code" />}>
      {`${('0'.repeat(Math.max(3 - String(code).length, 0)))}${code}`}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id, active }, { rowIndex }) => {
    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${id}-${rowIndex}`}
          label={<FormattedMessage id="general.manage" />}
          buttonType="compact light-gray"
          url={`/admin/health-variables/mortality-reasons/${id}/edit`}
          customClass="show-for-large"
          dropDownData={[{
            label: <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}`} />,
            onClick: this.disableItem(id, !active),
          }]}
          wide
        />
        <Link to={`/admin/health-variables/mortality-reasons/${id}/edit`} className="button light hide-for-large">
          <FormattedMessage id="general.manage" />
        </Link>
        <Button default onClick={this.disableItem(id, !active)} className="hide-for-large">
          <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}`} />
        </Button>
      </div>
    );
  };

  renderExpandable = ({ id, active }) => (
    <div>
      <Link to={`/admin/health-variables/mortality-reasons/${id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" /><FormattedMessage id="general.manage" />
      </Link>
      <Button default onClick={this.disableItem(id, !active)} small light>
        <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}`} />
      </Button>
    </div>
  );

  render() {
    const { isLoading, meta: { stats = {}, total }, data, reqParams: { page, per_page, status, search, sort },
      onSearchChange, onPageChange, onPerPageChange, onSortChange } = this.props;
    const { isTablet } = this.context;
    const { active, disabled } = stats;
    const statsSum = sum(Object.values(stats));

    const tableFilters = [
      { label: <FormattedMessage id="container.admin.healthVariables.reasons.all" />, value: '', count: statsSum },
      { label: <FormattedMessage id="general.status.active" />, value: 'active', count: active },
      { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled', count: disabled },
    ];
    const isEmptyTable = !data.length && !status && !search;

    const columns = [
      { label: <FormattedMessage id="container.admin.healthVariables.reasons.reason" />, flex: '2 0 150px',
        renderer: this.renderNameColumn, sortKey: 'name' },
      { label: <FormattedMessage id="component.dataTable.headers.languagesSupported" />, flex: '1 0 120px',
        renderer: this.renderLanguagesColumn,
        sortKey: 'translations_count' },
      { label: <FormattedMessage id="container.admin.healthVariables.reasons.code" />, flex: '1 0 80px',
        renderer: this.renderCodeColumn, sortKey: 'code' },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 0 100px',
        renderer: this.renderStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 150px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true }];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel className="MortalityReasons">
        <Panel.Heading title={<FormattedMessage id="general.mortalityReasonsBL" />}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={status}
              onFilterChange={this.onFilterChange}
              stats={stats}
              className="ph-10"
            >
              <Link to="/admin/health-variables/mortality-reasons/create" className="button small primary wider">
                <FormattedMessage id="container.admin.healthVariables.reasons.add" />
              </Link>
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={data}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="adminMortalityReasons"
          />

          <NothingBox
            itemsName="mortalityReasons"
            display={!data.length}
            isLoading={isLoading}
            search={search}
            filter={status}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.noReason" />
            <FormattedMessage tagName="p" id="component.nothingBox.addReason" />
            <Link to="/admin/health-variables/mortality-reasons/create" className="button primary mt-10">
              <FormattedMessage id="container.admin.healthVariables.reasons.add" />
            </Link>
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

MortalityReasons.propTypes = {
  data: T.array.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  setDataItem: T.func.isRequired,
};

MortalityReasons.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      data: state.dataTable.adminMortalityReasons.resources,
      isLoading: state.dataTable.adminMortalityReasons.isLoading,
      reqParams: state.dataTable.adminMortalityReasons.params,
      meta: state.dataTable.adminMortalityReasons.meta,
    }), {
      fetchData: (query) => fetchData('/admin/mortality_reasons', 'adminMortalityReasons', query),
      setDataItem: (data) => setDataItem(data, 'adminMortalityReasons'),
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(MortalityReasons);
