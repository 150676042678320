import React from 'react';
import T from 'prop-types';
// components
import FieldWrapper from '../FieldWrapper';
import TimeRange from 'components/TimeRange';

const TimeField = ({
  input,
  isDisabled = false,
  label,
  meta,
  required,
  horizontal,
}) => (
  <FieldWrapper
    meta={meta}
    label={label}
    required={!input.value && required}
    horizontal={horizontal}
  >
    <TimeRange
      isDisabled={isDisabled}
      hourValue={input.value?.hourValue}
      minuteValue={input.value?.minuteValue}
      onChange={input.onChange}
    />
  </FieldWrapper>
);

TimeField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]).isRequired,
  isDisabled: T.bool,
  required: T.bool,
  horizontal: T.bool,
};

export default TimeField;
