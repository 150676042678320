import React from 'react';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as OfflineIcon } from 'assets/svg/offline.svg';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './OfflineScreen.module.scss';

const cn = classnames.bind(styles);

const OfflineScreen = () => (
  <div className={cn('offline-section')}>
    <div className={cn('offline-content')}>
      <OfflineIcon width={140} className={cn('offline-icon')} />
      <div className={cn('label-text')}><FormattedMessage id="component.offlineScreen.label" /></div>
      <div className={cn('sub-label-text')}><FormattedMessage id="component.offlineScreen.subLabel" /></div>
      <Link className={cn('link')} to="/daily-checkup">
        <FormattedMessage id="component.offlineScreen.completeDC" />
      </Link>
    </div>
  </div>
);

export default OfflineScreen;
