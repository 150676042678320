import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal, closeModal } from 'reducers/modals';
// components
import GroupField from './GroupField';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import SourcesSelector from '../../components/SourcesSelector';
// utils
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { getDefaultPigGroupSources, formatPigGroupSources, preparePigGroupSources } from 'utils/pigGroupsHelper';
// styles
import styles from './Section.module.scss';

const cn = classnames.bind(styles);

class SourceSection extends Component {

  state = {
    data: getDefaultPigGroupSources(),
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== this.props.data) {
      this.setState({ data: data?.length ? formatPigGroupSources(data) : getDefaultPigGroupSources() });
    }
  }

  onChange = (updatedData) => {
    this.setState({
      data: updatedData
    });
  };

  onSaveSources = () => {
    const { data } = this.state;
    return this.props.onSave({ source_entities_attributes: preparePigGroupSources(data) });
  };

  renderFieldLabel = () => {
    const { data } = this.state;
    if (data.length === 1) {
      const [source] = data;
      const sourceName = source.entity_type === 'external'
        ? source.external_source?.label
        : source.source_pig_group?.label;
      return (
        <FormattedMessage
          id="component.groupCreate.sourceLabel"
          values={{ name: <b>{sourceName || 'N/A'}</b> }}
        />
      );
    }
    return <FormattedMessage id="component.groupCreate.sourcesLabel" values={{ count: data.length }} />;
  };

  isSameExternalSources = () => {
    const { data: newSources = [] } = this.state;
    const { data: oldSources = [] } = this.props;
    if (!oldSources.length && newSources.length === 1) {
      const [stateSource] = newSources;
      return isEmpty(stateSource.external_source) && isEmpty(stateSource.source_pig_group);
    }
    if (newSources.length !== oldSources.length) return false;
    return isEqual(formatPigGroupSources(oldSources), newSources);
  };

  handleClose = () => {
    const { onClose, data } = this.props;
    this.setState({
      data: data?.length ? formatPigGroupSources(data) : getDefaultPigGroupSources(),
    }, onClose);
  };

  render() {
    const { className, isDisabled, isOpened, onOpen } = this.props;
    const { data } = this.state;
    const isDataBlank = (data.length === 1 && (
      (data[0].entity_type === 'external' && !data[0].external_source?.value) ||
      (data[0].entity_type === 'internal' && !data[0].source_pig_group?.value)
    )) || !data.length;

    return (
      <GroupField
        className={className}
        label={this.renderFieldLabel()}
        title={<FormattedMessage id="general.source" />}
        btnLabel={<FormattedMessage id="component.groupCreate.setSource" />}
        hint={<FormattedMessage id="component.groupCreate.sourceHint" />}
        isSaved={!isDataBlank}
        onOpen={onOpen}
        isOpened={isOpened}
        isDisabled={isDisabled}
      >
        <SourcesSelector
          className={cn('field-body')}
          onChange={this.onChange}
          sources={data}
        >
          <div className="pv-10">
            <Button
              primary
              disabled={this.isSameExternalSources()}
              onClick={this.onSaveSources}
            >
              <FormattedMessage id="general.button.save" />
            </Button>
            <Button className="btn-link ml-5" onClick={this.handleClose}>
              <FormattedMessage id="general.button.cancel" />
            </Button>
          </div>
        </SourcesSelector>
      </GroupField>
    );
  }
}

SourceSection.defaultProps = {
  data: [],
};

SourceSection.propTypes = {
  className: T.string,
  data: T.array,
  onSave: T.func.isRequired,
  onClose: T.func.isRequired,
  onOpen: T.func.isRequired,
  isOpened: T.bool,
  isDisabled: T.bool,
};

export default connect(null, { openModal, closeModal })(SourceSection);
