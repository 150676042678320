import React, { useCallback } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, change } from 'redux-form';
// components
import AsyncSelectField from 'components/ReduxFormFields/AsyncSelectField';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import Field from 'components/Field';
import DateField from 'components/ReduxFormFields/DatePickerField/DateField';
import TimeField from 'components/ReduxFormFields/TimeField';
// api
import { searchFarms } from 'endpoints/farms';
import { searchUsers } from 'endpoints/users';
// utils
import cn from 'classnames';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
// styles
import styles from './VirtualVisitForm.module.scss';

const userOptionsKeys = { value: 'id', label: 'full_name' };

const VirtualVisitForm = ({
  change,
  farm,
  handleSubmit,
  isDisabled = false,
  pristine,
  scheduleDate,
  scheduleTime,
  submitting,
  submitButtonLabel = <FormattedMessage id="general.button.create" />,
  user,
}) => {
  const isSomeFieldEmpty = !farm?.value || !user?.value || !scheduleDate || !scheduleTime?.hourValue
    || !scheduleTime?.minuteValue;

  const handleChangeFarm = (option) => {
    change('farm', option);
    if (user) change('user', null);
  };

  const getFarmUsersWithRoles = useCallback((params) => {
    return searchUsers({
      ...params,
      farm_id: farm?.value,
      having_roles: ['vet', 'owner', 'caretaker', 'manager'],
    });
  }, [farm?.value]);

  return (
    <form onSubmit={handleSubmit} className={styles['virtual-visit-form']}>
      <div className={cn('row', { [styles['farm-row']]: isMobile })}>
        <div className="small-12 medium-6 column">
          <label>
            <Field
              isDisabled={isDisabled}
              placeholderKey="general.select_farm"
              label={<FormattedMessage id="general.select_farm" />}
              name="farm"
              component={AsyncSelectField}
              optionsGetter={searchFarms}
              onChange={handleChangeFarm}
            />
          </label>
        </div>
      </div>

      <div className={cn('row', { [styles['user-row']]: isMobile })}>
        <div className="small-12 medium-6 column">
          <label>
            <Field
              isDisabled={!farm?.value || isDisabled}
              label={<FormattedMessage id="general.selectContactAtFarm" />}
              placeholderKey="general.selectUser"
              name="user"
              component={AsyncSelectField}
              optionsGetter={getFarmUsersWithRoles}
              optionsKeys={userOptionsKeys}
            />
          </label>
        </div>
      </div>

      <div className="row">
        <div className="small-12 medium-6 column">
          <label>
            <Field
              isDisabled={isDisabled}
              label={<FormattedMessage id="general.selectScheduleDate" />}
              name="schedule_date"
              component={DateField}
              isOutsideRange={(day) => (day.isBefore(moment(), 'day'))}
            />
          </label>
        </div>
      </div>

      <div className="row mt-15">
        <div className="small-12 medium-6 column">
          <Field
            label={<FormattedMessage id="general.scheduleTime" />}
            name="schedule_time"
            component={TimeField}
            isDisabled={isDisabled}
          />
        </div>
      </div>

      <div className="row mt-20">
        <div className="small-12 column">
          <Button primary type="submit" disabled={pristine || submitting || isSomeFieldEmpty}>
            {submitButtonLabel}
          </Button>
          <Link to="/virtual-visits" className="button btn-link ml-10">
            <FormattedMessage id="general.button.cancel" />
          </Link>
        </div>
      </div>
    </form>
  );
};

VirtualVisitForm.propTypes = {
  farm: T.object,
  user: T.object,
  scheduleDate: T.object,
  scheduleTime: T.object,
  handleSubmit: T.func.isRequired,
  change: T.func.isRequired,
  pristine: T.bool.isRequired,
  submitting: T.bool.isRequired,
  isDisabled: T.bool,
  submitButtonLabel: T.oneOfType([T.node, T.string]),
};

const selector = formValueSelector('virtual-visit-form');

export default connect(
  (state) => ({
    farm: selector(state, 'farm'),
    user: selector(state, 'user'),
    scheduleDate: selector(state, 'schedule_date'),
    scheduleTime: selector(state, 'schedule_time'),
  }), { change }
)(reduxForm({ form: 'virtual-visit-form', enableReinitialize: true })(VirtualVisitForm));
