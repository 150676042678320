import React from 'react';
import T from 'prop-types';
import styles from './Input.module.scss';

const InputLabel = ({ children }) => (
  <span className={styles['input-label']}>{children}</span>
);

InputLabel.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default InputLabel;
