import React from 'react';
import T from 'prop-types';
// components
import NothingBox from 'components/NothingBox';
import Panel from 'components/Panel';
import { FormattedMessage } from 'react-intl';

const GeneticInfo = ({ genetic: { description = '' } }) => (
  <Panel>
    <Panel.Heading title={<FormattedMessage id="general.description" />}>
    </Panel.Heading>
    <Panel.Body>
      <NothingBox display={!description}>
        <h2 className="lighter semibold">
          <FormattedMessage id="general.nothingBox.noDescription" />
        </h2>
      </NothingBox>
      {description}
    </Panel.Body>
  </Panel>
);

GeneticInfo.propTypes = {
  genetic: T.object.isRequired,
};

export default GeneticInfo;
