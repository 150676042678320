import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Avatar from 'components/Avatar/Avatar';
import FarmfeedTooltip from './FarmfeedTooltip';
// endpoints
import { getTreatment } from 'endpoints/treatmentProducts';
// utils
import { getMedType } from 'utils';
//  constants
import { treatmentData } from 'components/EntityFarmfeedTooltip/constants';

const TreatmentFarmFeedTooltip = ({ treatmentId, isAdmin }, { router }) => {
  const [treatment, setTreatment] = useState({});

  useEffect(() => {
    getTreatment(treatmentId).then(setTreatment);
  }, []);

  return (
    <FarmfeedTooltip
      type="treatment"
      data={treatment}
      tooltipData={treatmentData}
      getAvatar={(data) => <Avatar type={getMedType(data)} avatarSize={55} iconSize={36} />}
      isAdmin={isAdmin}
      onManageBtnClick={() => router.push(`/admin/health-variables/medications/${treatmentId}`)}
      manageBtnLabel={<FormattedMessage id="general.manageProduct" />}
    />
  );
};

TreatmentFarmFeedTooltip.contextTypes = {
  router: T.object.isRequired,
};

TreatmentFarmFeedTooltip.propTypes = {
  treatmentId: T.number.isRequired,
  isAdmin: T.bool.isRequired,
};

export default TreatmentFarmFeedTooltip;
