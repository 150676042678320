import React from 'react';
import T from 'prop-types';
// components
import ResponsiveDropdown from 'components/ResponsiveDropdown';
// utils
import get from 'lodash.get';
import classnames from 'classnames/bind';
import moment from 'moment';
// translate
import { FormattedMessage } from 'react-intl';
// styles
import styles from './GroupMarketingPanel.module.scss';

const cn = classnames.bind(styles);

const GroupMarketingPanel = ({ user, dropdownOptions, timestamp, className }) => (
  <div className={cn('GroupMarketingPanel', className)}>
    <span className={cn('logo')}>
      <span className="fa fa-ep-truck" />
    </span>
    <div className={cn('description')}>
      <FormattedMessage
        tagName="div"
        id="component.groupFeed.groupMarketingPanel.text"
        values={{
          userName: <b>{get(user, 'full_name', 'unknown')}</b>,
          marketing: <FormattedMessage id="component.groupFeed.groupMarketingPanel.marketing" tagName="b" />,
        }}
      />
      <div className={cn('timestamp')}>{moment(timestamp).format('MMM D [at] hh:mm a')}</div>
    </div>
    <div className={cn('actions')}>
      {!!dropdownOptions.length && (
        <ResponsiveDropdown
          options={dropdownOptions}
          className={cn('dropdown')}
          trigger={<i className="fa fa-dots-three-horizontal" />}
        />
      )}
    </div>
  </div>
);

GroupMarketingPanel.defaultProps = {
  dropdownOptions: [],
};

GroupMarketingPanel.propTypes = {
  user: T.object,
  dropdownOptions: T.array,
  timestamp: T.string,
  className: T.string,
};

export default GroupMarketingPanel;
