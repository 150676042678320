import React from 'react';
import moment from 'moment';
import { getUserNickname } from 'utils';
import { getLSMinimizedActivities, setLSMinimizedActivities } from 'utils/localStorageHelper';
import { FormattedMessage } from 'react-intl';

const farmRoles = ['vet', 'owner', 'caretaker', 'manager'];
const shortFarmRoles = ['owner', 'caretaker'];

export function isActivityMinimized(activity) {
  const minimizedActivities = getLSMinimizedActivities();
  const minimized_at = minimizedActivities[activity.id];
  if (!minimized_at) return false;
  return moment(activity.updated_at).isSameOrBefore(minimized_at);
}

export function saveActivityToStorage(activity, minimized) {

  // 1. get minimized activites from local storage
  const minimizedActivities = getLSMinimizedActivities();

  // 2. update minimized activities
  if (minimized) {
    delete minimizedActivities[activity.id];
  } else {
    minimizedActivities[activity.id] = new Date();
  }

  // 3. set minimized activites to local storage
  setLSMinimizedActivities(JSON.stringify(minimizedActivities));
}

export function getSuggestionBadgeTitle(additionalData) {
  const hasTreatments = !!additionalData?.treatments?.length;
  const hasMortality = !!additionalData?.mortality;
  const hasDiagnosis = !!additionalData?.diagnosis?.length;

  if (hasTreatments && hasMortality && hasDiagnosis) {
    return <FormattedMessage id="general.actionDiagnosisAndTreatmentSuggestion" />;
  }
  if (hasTreatments && hasMortality) {
    return <FormattedMessage id="general.actionAndTreatmentSuggestion" />;
  }
  if (hasTreatments && hasDiagnosis) {
    return <FormattedMessage id="general.treatmentAndDiagnosisSuggestion" />;
  }
  if (hasMortality && hasDiagnosis) {
    return <FormattedMessage id="general.treatmentAndDiagnosisSuggestion" />;
  }
  if (hasTreatments) {
    return <FormattedMessage id="general.treatmentSuggestion" />;
  }
  if (hasMortality) {
    return <FormattedMessage id="general.actionSuggestion" />;
  }
  if (hasDiagnosis) {
    return <FormattedMessage id="general.diagnosisSuggestion" />;
  }
  return null;
}

export function getActivityMentionRoles(activity) {
  return activity.variety.includes('low_mortality')
    ? shortFarmRoles
    : farmRoles;
}

export function getActivityMentionEntityData(activity, authUser) {
  return {
    mentionEntityId: activity.farm?.id || activity.company?.id || authUser.current_company.id,
    mentionEntityType: activity.farm?.id ? 'farm' : 'company',
  };
}

export function getActivityCustomMentions(activity, authUser) {
  if (!activity.variety.includes('direct') || !activity.mentions?.length) return undefined;
  const mentions = [...activity.mentions, activity.user];
  return mentions
    .filter((user) => user.id !== authUser.id)
    .map((user) => ({ id: String(user.id), display: '@' + getUserNickname(user), user }));
}

export function getActivityMentionsInputData(activity, authUser) {
  const entityId = activity.farm?.id || activity.company?.id || authUser.current_company.id;
  const entityType = activity.farm?.id ? 'farm' : 'company';
  return {
    customMentionsList: getActivityCustomMentions(activity, authUser),
    entityId,
    entityType,
    mentionRoles: entityType === 'farm' ? getActivityMentionRoles(activity) : undefined,
  };
}
