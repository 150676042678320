import React from 'react';
import T from 'prop-types';
// utils
import isNumber from 'lodash.isnumber';
import cn from 'classnames';
// assets
import './Section.scss';

const Header = ({ label, value, withoutIcon, actionButton, highlightEmpty }) => (
  <div className={cn('Header pt-10 pb-10 panel-heading semibold', { highlightEmpty })}>
    <span className="show-for-large">
      <span>
        {label}
        {(isNumber(value) || typeof value === 'string') && ` • ${value}`}
        {typeof value === 'object' && <span>&nbsp;•&nbsp;{value}</span>}
      </span>
    </span>
    <span className="with-icon hide-for-large">
      {!withoutIcon && <span className="icon fa fa-check-circle hide-for-large" />}
      <span>
        {label}
        {(isNumber(value) || typeof value === 'string') && ` • ${value}`}
        {typeof value === 'object' && <span>&nbsp;•&nbsp;{value}</span>}
      </span>
    </span>
    {actionButton}
  </div>
);

Header.propTypes = {
  label: T.oneOfType([T.node, T.string]).isRequired,
  value: T.oneOfType([T.number, T.string, T.node]),
  withoutIcon: T.bool,
  actionButton: T.node,
  highlightEmpty: T.bool,
};

Header.defaultProps = {
  withoutIcon: false,
  actionButton: null,
};

export default Header;
