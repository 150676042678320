import React from 'react';
// styles
import './FarmFeedExpiredMessage.scss';
// translate
import { FormattedMessage } from 'react-intl';

const FarmFeedExpiredMessage = () => (
  <div className="row centered">
    <div className="FarmFeedExpiredMessage">
      <i className="fa attention-icon fa-exclamation-triangle-bts" />
      <div className="bolded-text">
        <FormattedMessage id="container.farmfeed.expiredMessage.trialHasExpired" />
      </div>
      <div className="text">
        <FormattedMessage id="container.farmfeed.expiredMessage.pleaseUpgrade" />
      </div>
      <div className="text">
        <FormattedMessage id="container.farmfeed.expiredMessage.contactUs" />
      </div>
      <a className="support-link" href="mailto:support@everypig.com">support@everypig.com</a>
    </div>
  </div>
);

export default FarmFeedExpiredMessage;
