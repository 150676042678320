import React from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import VerticalAligner from 'components/VerticalAligner';
// utils
import cn from 'classnames';
// styles
import './EveryPigHeader.scss';

const EveryPigHeader = ({ className, isSticky, children }) => (
  <div className={cn('EveryPigHeader', className, { isSticky })}>
    <VerticalAligner>
      <Avatar type="everypig" avatarSize={50} iconSize={35} />
      <h1 className="lighter everypig-title">EveryPig</h1>
    </VerticalAligner>
    <div className="profile-header-actions">
      {children}
    </div>
  </div>
);

EveryPigHeader.propTypes = {
  className: T.string,
  isSticky: T.bool,
  children: T.any,
};

EveryPigHeader.defaultProps = {
  className: '',
};

export default EveryPigHeader;
