import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Input from 'components/Input';
import InputLabel from 'components/Input/InputLabel';
import Button from 'components/Button';
// utils
import createAddressInfo from 'utils/createAddressInfo';

const CoordsSelect = ({ lat, lng, changeValue, countriesData }) => {
  const [coords, setCoords] = useState({ lat, lng });

  useEffect(() => {
    setCoords({ lat, lng });
  }, [lat, lng]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoords((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    createAddressInfo(
      countriesData,
      'fromLatLng',
      [coords.lat, coords.lng],
      (addressInfo) => changeValue('addressObj', addressInfo)
    );
  };

  return (
    <>
      <FormattedMessage id="general.coords.lat">
        {(label) => (
          <label>
            <InputLabel>
              {label}
            </InputLabel>
            <Input
              type="number"
              name="lat"
              placeholder={label}
              value={coords.lat || ''}
              onChange={handleChange}
            />
          </label>
        )}
      </FormattedMessage>
      <FormattedMessage id="general.coords.lng">
        {(label) => (
          <label>
            <InputLabel>
              {label}
            </InputLabel>
            <Input
              type="number"
              name="lng"
              placeholder={label}
              value={coords.lng || ''}
              onChange={handleChange}
            />
          </label>
        )}
      </FormattedMessage>
      <Button
        primary
        onClick={handleSubmit}
        type="button"
      >
        <FormattedMessage id="general.find" />
      </Button>
    </>
  );
};

CoordsSelect.propTypes = {
  lat: T.oneOfType([T.string, T.number]),
  lng: T.oneOfType([T.string, T.number]),
  countriesData: T.array,
  changeValue: T.func,
};

export default CoordsSelect;
