import { useState, useEffect } from 'react';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

export default function useFetchById(fetcher, id, options = {}) {
  const [state, setState] = useState({ isLoading: false, data: null });

  useEffect(() => {
    if (id) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      fetcher(id)
        .then((entity) => {
          setState({ data: entity, isLoading: false });
          options.onSuccess?.(entity);
        })
        .catch((errors) => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
          options.onError?.(errors);
          toastResponseErrors(errors);
        });
    }
    if (!id && !!state.data) {
      setState({ isLoading: false, data: null });
    }
  }, [id, setState]);

  return [state, setState];
}
