import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import VerticalAligner from 'components/VerticalAligner';
import Panel from 'components/Panel';
import Button from 'components/Button';
import Input from 'components/Input';
import InputLabel from 'components/Input/InputLabel';
import Preloader from 'components/Preloader';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// utils
import classnames from 'classnames/bind';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// health variables api
import { updateDisease, getDisease } from 'endpoints/admin/healthVariables';
// style
import styles from './UpdateDisease.scss';

const cn = classnames.bind(styles);

const labels = {
  source: <FormattedMessage id="general.source" />,
  description: <FormattedMessage id="general.description" />,
  control: <FormattedMessage id="component.diagnosis.control" />,
  clinicalSigns: <FormattedMessage id="component.diagnosis.clinicalSigns" />,
  information: <FormattedMessage id="general.information" />,
  editName: (name) => (
    <FormattedMessage id="general.button.edit">
      {(val) => `${val} ${name}`}
    </FormattedMessage>

  )
};

class UpdateDisease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      disease: {},
      initialCommonName: '', // for disabling submit button
      isLoading: false,
    };
  }

  componentDidMount() {
    const { params: { id } } = this.props;
    this.setState({ isLoading: true });
    getDisease(id)
      .then((disease) => {
        this.setState({ disease, initialCommonName: disease.common_name, isLoading: false });
      })
      .catch((errors) => {
        toastResponseErrors(errors);
        this.setState({ isLoading: false });
        this.context.router.push('/farmfeed');
      });
  }

  disableItem = (id, status) => () => {
    this.setState({ isLoading: true });
    updateDisease(id, { active: status })
      .then((disease) => {
        this.setState({ disease, isLoading: false });
        showToastrMessage('component.toastr.status.changed');
      })
      .catch((errors) => {
        toastResponseErrors(errors);
        this.setState({ isLoading: false });
      });
  };

  renderInfoTabs = (data, activeTab, onClick) => (
    <div className="InfoTabs">
      <div className="InfoTabs__headings">
        {data.map(({ id, name }) => (
          <div
            key={`Info-tab-${id}`}
            onClick={() => onClick(id)}
            className={cn('InfoTab__heading', { 'InfoTab__active': activeTab === id })}
          >
            {name}
          </div>
        ))}
      </div>
      <div className="InfoTabs_content">
        {data.find(({ id }) => id === activeTab).content}
      </div>
    </div>
  );

  updateCommonName = () => {
    const { params: { id } } = this.props;
    const { disease: { common_name } } = this.state;
    const { router } = this.context;
    return updateDisease(id, { common_name })
      .then(() => {
        showToastrMessage('component.toastr.disease.updated');
        router.push('/admin/health-variables/diseases');
      })
      .catch(toastResponseErrors);
  };

  onCommonNameChange = (e) => {
    const { value } = e.target;
    this.setState(({ disease }) => ({ disease: { ...disease, common_name: value } }));
  };

  switchTab = (activeTab) => this.setState({ activeTab });

  render() {
    const { router } = this.context;
    const { activeTab, initialCommonName, disease: { name, active, description, common_name, clinical_signs,
      control, url, code }, isLoading } = this.state;
    const { params: { id } } = this.props;

    const infoData = [
      { id: 0, name: labels.description, content: description },
      { id: 1, name: labels.clinicalSigns, content: clinical_signs },
      { id: 2, name: labels.control, content: control },
    ];

    return (
      <div className="UpdateDiagnosis">
        <Preloader isActive={isLoading} />
        <div className="diagnosis-header">
          <VerticalAligner>
            <span className="diagnosis-name">{name}</span>
            <StatusBadge status={active ? 'active' : 'disabled'} />
          </VerticalAligner>
          <Button onClick={this.disableItem(id, !active)} disabled={isLoading} className="small light-gray">
            <FormattedMessage id={`general.button.${active ? 'disable' : 'enable'}`} />
          </Button>
        </div>
        <section className="small-12 column">
          <Panel>
            <Panel.Heading title={labels.editName(name)} />
            <Panel.Body noPadding>
              <div className="row">
                <section className="small-12 medium-10 large-8 column">
                  <div className="field-small">
                    <InputLabel>
                      <FormattedMessage id="component.diagnosis.diseaseId" />
                    </InputLabel>
                    <Input value={code || ''} disabled />
                  </div>
                  <div>
                    <InputLabel>
                      <FormattedMessage id="component.diagnosis.diseaseName" />
                    </InputLabel>
                    <Input value={name || ''} disabled />
                  </div>
                  <div>
                    <InputLabel>
                      <FormattedMessage id="general.commonName" />
                    </InputLabel>
                    <Input value={common_name || ''} onChange={this.onCommonNameChange} />
                  </div>
                  <Panel className="sub-panel">
                    <Panel.Heading className="sub-heading" title={labels.information} />
                    <Panel.Body noPadding>
                      {this.renderInfoTabs(infoData, activeTab, this.switchTab)}
                      <div className="InfoSource">
                        <span className="bold">
                          {labels.source}:&nbsp;
                        </span>
                        <a className="InfoSource__link" href={url} rel="noopener noreferrer" target="_blank">
                          <FormattedMessage id="component.updateDiagnosis.sourceDesc" />
                        </a>
                      </div>
                    </Panel.Body>
                  </Panel>
                  <Button
                    disabled={isLoading || !common_name || (initialCommonName === common_name)}
                    primary
                    onClick={this.updateCommonName}
                  >
                    <FormattedMessage id="general.button.saveChanges" />
                  </Button>
                  <Button transparent onClick={() => router.push('/admin/health-variables/diseases')}>
                    <FormattedMessage id="general.button.cancel" />
                  </Button>
                </section>
              </div>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

UpdateDisease.contextTypes = {
  router: T.object.isRequired,
};

UpdateDisease.propTypes = {
  params: T.object.isRequired,
};

export default UpdateDisease;
