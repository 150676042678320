import React from 'react';

import T from 'prop-types';

import linkImage from '../../../../../public/images/importsLink.svg';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';

import  styles from './CardLink.module.scss';

const CardLink = ({ title, infoText, textLink, link, imageCard }) =>    {
  return (
    <div className={styles.cardLink}>
      <img src={imageCard} alt="imgGrey" />
      <div className={styles.infoCard}>
        <h2><FormattedMessage id={title} /></h2>
        <FormattedMessage id={infoText} />
        <Link to={link} className={styles.links}>
          <FormattedMessage id={textLink} />
          <img src={linkImage} alt="linkImg" />
        </Link>
      </div>
    </div>
  );
};


CardLink.propTypes = {
  title: T.string,
  infoText: T.string,
  textLink: T.string,
  link: T.string,
  imageCard: T.string,
};


export default CardLink;
