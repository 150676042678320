import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MobilePortalOverlay.module.scss';

const cn = classnames.bind(styles);

const MobilePortalOverlay = ({ isHidden, children }) => (
  <div className={cn('mobile-portal-overlay', { isHidden })}>
    {children}
  </div>
);

MobilePortalOverlay.propTypes = {
  isHidden: T.bool.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default MobilePortalOverlay;
