import React from 'react';
import T from 'prop-types';
// components
import SelectField from './SelectField';

const formattedStates = (states) => {
  const formattedStates = states.map((state) => {
    const abbreviation = Object.keys(state)[0];
    return { abbreviation, name: state[abbreviation] };
  });
  return formattedStates.filter(({ abbreviation }) => !!abbreviation);
};

const StatesSelectField = ({ states, ...props }) => (
  <SelectField {...props} options={formattedStates(states)} valueKey="abbreviation" labelKey="name" />
);

StatesSelectField.propTypes = {
  states: T.array.isRequired,
};

export default StatesSelectField;
