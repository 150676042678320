import { createAction, handleActions } from 'redux-actions';
// endpoints
import {
  getCheckupDiagnoses,
  updateCheckupDiagnoses as updateDiagnoses,
} from 'endpoints/dailyCheckups';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  checkups: [],
  isLoading: false,
  hoverTag: null,
  predictedTags: [],
};

const FETCH_CHECKUP_DIAGNOSES = 'checkupDiagnoses/FETCH_CHECKUP_DIAGNOSES';
const FETCH_CHECKUP_DIAGNOSES_PENDING = 'checkupDiagnoses/FETCH_CHECKUP_DIAGNOSES_PENDING';
const FETCH_CHECKUP_DIAGNOSES_FULFILLED = 'checkupDiagnoses/FETCH_CHECKUP_DIAGNOSES_FULFILLED';
const FETCH_CHECKUP_DIAGNOSES_REJECTED = 'checkupDiagnoses/FETCH_CHECKUP_DIAGNOSES_REJECTED';

const UPDATE_CHECKUP_DIAGNOSIS = 'checkupDiagnoses/UPDATE_CHECKUP_DIAGNOSIS';
const UPDATE_CHECKUP_DIAGNOSIS_PENDING = 'checkupDiagnoses/UPDATE_CHECKUP_DIAGNOSIS_PENDING';
const UPDATE_CHECKUP_DIAGNOSIS_FULFILLED = 'checkupDiagnoses/UPDATE_CHECKUP_DIAGNOSIS_FULFILLED';
const UPDATE_CHECKUP_DIAGNOSIS_REJECTED = 'checkupDiagnoses/UPDATE_CHECKUP_DIAGNOSIS_REJECTED';

const SET_HOVER_TAG = 'checkupDiagnoses/SET_HOVER_TAG';
const SET_PREDICTED_TAGS = 'checkupDiagnoses/SET_PREDICTED_TAGS';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchCheckupDiagnoses = (checkupId) => createAction(FETCH_CHECKUP_DIAGNOSES)(
  getCheckupDiagnoses(checkupId)
    .then((response) => ({ ...response, checkupId }))
);

export const updateCheckupDiagnoses = (checkupId, data, declinedTagIds = []) => createAction(UPDATE_CHECKUP_DIAGNOSIS)(
  updateDiagnoses(checkupId, { daily_checkup_diagnoses: data, declined_predicted_image_tag_ids: declinedTagIds })
    .then((response) => ({ ...response, checkupId, declinedTagIds }))
);

export const setPredictedTags = (predictedTags, checkupId) => createAction(SET_PREDICTED_TAGS)({
  predictedTags, checkupId
});

export const setHoverTag = (hash) => createAction(SET_HOVER_TAG)({ hash });

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_FARMS
  [FETCH_CHECKUP_DIAGNOSES_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_CHECKUP_DIAGNOSES_FULFILLED]: (state, { payload: { checkupId, resources, meta } }) => {
    const checkup = state.checkups.find((resource) => resource.checkupId === checkupId);
    let checkups = [];
    if (checkup) {
      const checkupIndex = state.checkups.indexOf(checkup);
      checkups = [
        ...state.checkups.slice(0, checkupIndex),
        { ...checkup, diagnosesList: resources },
        ...state.checkups.slice(checkupIndex + 1),
      ];
    }
    if (!checkup) {
      checkups = [
        ...state.checkups,
        { checkupId, diagnosesList: resources },
      ];
    }
    return {
      ...state,
      checkups,
      meta,
      isLoading: false,
    };
  },
  [FETCH_CHECKUP_DIAGNOSES_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [UPDATE_CHECKUP_DIAGNOSIS_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [UPDATE_CHECKUP_DIAGNOSIS_FULFILLED]: (state, { payload: { declinedTagIds, checkupId, resources, meta } }) => {
    const checkup = state.checkups.find((resource) => resource.checkupId === checkupId);
    const checkupIndex = state.checkups.indexOf(checkup);
    const checkups = [
      ...state.checkups.slice(0, checkupIndex),
      { ...checkup, diagnosesList: resources },
      ...state.checkups.slice(checkupIndex + 1),
    ];
    return {
      ...state,
      checkups,
      meta,
      predictedTags: state.predictedTags.filter(({ id }) => !declinedTagIds.includes(id)),
      isLoading: false,
    };
  },
  [UPDATE_CHECKUP_DIAGNOSIS_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [SET_HOVER_TAG]: (state, { payload: { hash } }) => ({
    ...state,
    hoverTag: hash,
  }),
  [SET_PREDICTED_TAGS]: (state, { payload: { predictedTags } }) => ({
    ...state,
    predictedTags,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
