import React, { Component, Fragment } from 'react';
import T from 'prop-types';
// components
import GeneticHeader from 'components/ProfileHeader/GeneticHeader/GeneticHeader';
import Subnavigation from 'components/Subnavigation';
import Preloader from 'components/Preloader';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import { showToastrMessage } from 'utils';
// api
import { fetchGenetic, updateGenetic } from 'endpoints/admin/genetics';
// translations
import { FormattedMessage } from 'react-intl';

class GeneticProfile extends Component {

  state = {
    genetic: {},
    isLoading: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { params: { id }, router: { push } } = this.props;
    this.setState({ isLoading: true });
    fetchGenetic(id).then(({ resource: genetic }) => {
      this.setState({ isLoading: false, genetic });
    }).catch((err) => {
      toastResponseErrors(err);
      push('/admin/health-variables-genetics');
    });
  }

  getInitialValues = () => {
    const { params: { id } } = this.props;
    const { medication } = this.state;
    if (!isEmpty(medication) && parseInt(id, 10) === medication.id) {
      return pick(medication, ['withdrawal_period_export', 'withdrawal_value_export', 'withdrawal_value_us', 'notes',
        'withdrawal_period_us', 'common', 'efficacy_survey']);
    }
    return null;
  };

  changeGeneticStatus = () => {
    const { genetic } = this.state;
    this.setState({ isLoading: true });
    updateGenetic(genetic.id, { active: !genetic.active })
      .then(({ resource }) => {
        this.setState({ genetic: resource, isLoading: false });
        showToastrMessage(`component.toastr.genetic.${genetic.active ? 'deactivated' : 'activated'}`);
      })
      .catch((err) => {
        toastResponseErrors(err);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { genetic, isLoading } = this.state;
    const { params: { id }, children } = this.props;
    const links = [
      { label: <FormattedMessage id="general.pageTitle.sourceGroups" />,
        link: `/admin/health-variables/genetics/${id}/sourced-groups` },
      { label: <FormattedMessage id="general.geneticInfo" />,
        link: `/admin/health-variables/genetics/${id}/info` },
    ];

    const childrenWithProps = React.Children.map(children,
      (child) => child && React.cloneElement(child, {
        genetic,
      }));

    return (
      <div>
        <Preloader isActive={isLoading} />
        {!isEmpty(genetic) && (
          <Fragment>
            <GeneticHeader handleClick={this.changeGeneticStatus} genetic={genetic} />
            <Subnavigation links={links} className="small-12 column" />
            <section className="small-12 column">
              {childrenWithProps}
            </section>
          </Fragment>
        )}
      </div>
    );
  }
}

GeneticProfile.propTypes = {
  params: T.object,
  children: T.node,
  router: T.object.isRequired,
};

export default GeneticProfile;
