import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
import { enableEditMode } from 'reducers/barnsheets/barnsheetsGroupCheckups';
// components
import TreatmentsPanel from '../components/TreatmentsPanel';
import TreatmentsHistoryTable from '../components/TreatmentsHistoryTable';
import BarnSheetsTreatmentsProfile from '../components/BarnSheetsTreatmentsProfile';
// utils
import { getFilterOptions, preparePeriodDates, treatmentHistoryOptions } from '../utils/barnSheetsTreatments';

const BarnSheetsGroupTreatmentsHistory = ({
  currentUser,
  enableEditMode,
  fetchData,
  isLoading,
  location,
  measurementSystem,
  meta,
  params,
  pigGroup,
  resources,
  reqParams,
}) => {
  const [{ period_name, date_start, date_end }, setState] = useState(preparePeriodDates('28_days'));
  const { roles_map: { admin } } = currentUser;
  const { id: groupId } = params;

  const handleChangeDates = ({ startDate, endDate }) => {
    setState((prevState) => ({ ...prevState, date_start: startDate, date_end: endDate }));
  };

  const handleSelectPeriod = (option) => {
    if (!option) return;
    const { value } = option;
    if (value !== period_name) {
      setState(preparePeriodDates(value));
    }
  };

  const filterOptions = getFilterOptions(`/barnsheets/groups/${groupId}/treatments`, admin);

  return (
    <BarnSheetsTreatmentsProfile
      dateEnd={date_end}
      dateStart={date_start}
      filterOptions={filterOptions}
      pathname={location.pathname}
      options={treatmentHistoryOptions}
      onSelectPeriod={handleSelectPeriod}
      onChangeDates={handleChangeDates}
      periodName={period_name}
    >
      <div className="BarnsheetsGroupTreatments">
        <div className="small-12 column">
          <TreatmentsPanel
            dateEnd={date_end}
            dateStart={date_start}
            closeDate={pigGroup && pigGroup.closed_on}
            measurementSystem={measurementSystem}
            path={`/pig_groups/${groupId}/treatment_history`}
            periodName={period_name}
          />
        </div>

        <TreatmentsHistoryTable
          dateEnd={date_end}
          dateStart={date_start}
          enableEditMode={enableEditMode}
          fetchData={fetchData}
          isAdmin={admin}
          isGroupLevel
          pigGroupId={pigGroup?.id}
          isLoading={isLoading}
          measurementSystem={measurementSystem}
          meta={meta}
          reqParams={reqParams}
          resources={resources}
          tableId="barnsheetsGroupTreatments"
        />
      </div>
    </BarnSheetsTreatmentsProfile>
  );
};

BarnSheetsGroupTreatmentsHistory.propTypes = {
  resources: T.array.isRequired,
  isLoading: T.bool.isRequired,
  params: T.object.isRequired,
  location: T.object.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  fetchData: T.func.isRequired,
  enableEditMode: T.func.isRequired,
  currentUser: T.object.isRequired,
  pigGroup: T.object,
  measurementSystem: T.string.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    pigGroup: state.barnsheets.groupCheckups.pigGroup,
    resources: state.dataTable.barnsheetsGroupTreatments.resources,
    isLoading: state.dataTable.barnsheetsGroupTreatments.isLoading,
    meta: state.dataTable.barnsheetsGroupTreatments.meta,
    reqParams: state.dataTable.barnsheetsGroupTreatments.params,
    measurementSystem: state.auth.user.current_company.measurement_system,
  }), (dispatch, { params }) => (
    {
      enableEditMode: (...args) => dispatch(enableEditMode(...args)),
      fetchData: (query) => dispatch(fetchData(`/pig_groups/${params.id}/daily_checkup_treatment_products`,
        'barnsheetsGroupTreatments', query)),
    }
  )
)(BarnSheetsGroupTreatmentsHistory);
