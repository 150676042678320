/* eslint-disable react/prop-types */
import React, { Component, createRef } from 'react';

export default function withMenuPositionCheck(WrappedComponent) {
  return class extends Component {

    menuNode = createRef();

    getSnapshotBeforeUpdate(prevProps) {
      const { isOpened } = this.props;
      const { isOpened: isOpenedPrev } = prevProps;
      if ((isOpenedPrev !== isOpened) && isOpened) {
        const menu = this.menuNode.current;
        if (!menu) return null;
        const { left, width } = menu.getBoundingClientRect();
        return parseInt(window.innerWidth - left - width, 10);
      }
      return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if ((snapshot !== null)) {
        const menu = this.menuNode.current;
        const currentLeft = parseInt(menu.style.left, 10);
        if (snapshot < 0) {
          menu.style.left = snapshot - 10 + 'px'; // 10px - for right indent from screen
          return;
        }
        if ((snapshot + currentLeft) > 0) menu.style.left = null;
      }
    }

    render() {
      return <WrappedComponent menuNode={this.menuNode} {...this.props} />;
    }
  };
}
