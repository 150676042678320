import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeDropdown } from 'reducers/dropdownLayout';
// components
import TenantAssetBox from './TenantAssetBox';
import Waypoint from 'react-waypoint';
import { FormattedMessage } from 'react-intl';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
// utils
import classnames from 'classnames/bind';
import times from 'lodash.times';
import { getFileUploadState, getResourceName } from 'utils/tenantAssetsHelper';
// styles
import styles from './TenantAssetsBoxesContainer.module.scss';

const cn = classnames.bind(styles);

class TenantAssetsBoxesContainer extends Component {

  state = {
    activeElement: null,
  };

  componentDidMount() {
    if (!this.context.isMobile) window.addEventListener('keyup', this.handleOnKeyUp);
  }

  componentWillUnmount() {
    if (!this.context.isMobile) window.removeEventListener('keyup', this.handleOnKeyUp);
  }

  handleOnKeyUp = (e) => {
    e.preventDefault();
    const { closeDropdown, onOpenDropdown, resources, isDropdownOpen } = this.props;
    const { activeElement } = this.state;

    // esc & option click
    if ([27, 18].includes(e.keyCode) && isDropdownOpen) closeDropdown();

    if (!activeElement || isDropdownOpen) return;

    // go right
    if (e.keyCode === 39) {
      const nextElement = activeElement.nextElementSibling;
      this.setState((prevState) => {
        if (!nextElement) return null;
        return { ...prevState, activeElement: nextElement };
      });
    }
    // go left
    if (e.keyCode === 37) {
      const prevElement = activeElement.previousElementSibling;
      this.setState((prevState) => {
        if (!prevElement) return null;
        return { ...prevState, activeElement: prevElement };
      });
    }
    // option click
    if (e.keyCode === 18) {
      const { assetId: activeId } = activeElement.dataset;
      const asset = resources.find(({ id }) => Number(activeId) === id);
      onOpenDropdown(asset)(null, activeElement, true);
    }
  };

  openDropdownMenu = (e) => {
    e.preventDefault();
    if (this.context.isMobile) return;

    const target = e.target.closest('div[data-asset-id]');
    if (!target) return;

    const { resources, onOpenDropdown } = this.props;
    const { assetId } = target.dataset;
    const asset = resources.find(({ id }) => (id === Number(assetId)));
    onOpenDropdown(asset)(null, target, true);
    this.setState({ activeElement: target });
  };

  handleFocus = (e) => {
    e.preventDefault();
    const target = e.target.closest('div[data-asset-id]');

    if (!this.context.isMobile) {
      this.setState((prevState) => {
        if (prevState.activeElement === target) return null;
        return { ...prevState, activeElement: target };
      });
    }

    if (!target) return;
    const { resources, onPreviewAsset } = this.props;
    const { assetId } = target.dataset;
    const asset = resources.find(({ id }) => (id === Number(assetId)));
    onPreviewAsset(asset);
  };

  render() {
    const { resources, search, onOpenDropdown, onMarkItemAsSeen, onLoadMore, isLoading, hasMoreResources } = this.props;
    const { activeElement } = this.state;
    const activeId = activeElement ? Number(activeElement.dataset.assetId) : null;

    return (
      <div
        className={cn('assets-block-list')}
        onDoubleClick={this.openDropdownMenu}
        onContextMenu={this.openDropdownMenu}
        onClick={this.handleFocus}
      >
        {isLoading && !resources.length && (
          times(5, (i) => <TenantAssetBox key={i} assetId={i + 1} isViewed isUploaded />)
        )}
        {resources.map((asset, index) => {
          const { id, url = {}, content_type } = asset || {};
          const name = getResourceName(asset);
          const backgroundImage = content_type === 'CompanyImage' && url.small ? `url(${url.small})` : '';
          const { uploaded, style } = getFileUploadState(asset);

          return (
            <TenantAssetBox
              key={`${id || `index-${index}`}`}
              name={name}
              assetId={asset.id}
              dataName={`${id}-${index}`}
              backgroundImage={backgroundImage}
              contentType={content_type}
              isFocused={asset.id === activeId}
              isViewed={asset.is_viewed}
              isUploaded={uploaded}
              progressStyle={style}
              openDropdown={onOpenDropdown(asset)}
              markAsSeen={onMarkItemAsSeen(asset)}
            />
          );
        })}
        <NothingBox
          itemsName="tenant_resources"
          display={!resources.length}
          isLoading={isLoading}
          search={search}
        >
          <h2 className="lighter semibold">
            <FormattedMessage id="component.nothingBox.noResources" />
          </h2>
        </NothingBox>
        {hasMoreResources && (
          <>
            {!isLoading && (
              <div className={cn('load-more-intersection')}>
                <Waypoint onEnter={onLoadMore} />
              </div>
            )}
            <Preloader className={cn('load-more-preloader')} isActive={isLoading} />
          </>
        )}
      </div>
    );
  }
}

TenantAssetsBoxesContainer.contextTypes = {
  isMobile: T.bool.isRequired,
};

TenantAssetsBoxesContainer.propTypes = {
  search: T.string,
  resources: T.array.isRequired,
  isLoading: T.bool.isRequired,
  hasMoreResources: T.bool.isRequired,
  isDropdownOpen: T.bool.isRequired,
  onLoadMore: T.func.isRequired,
  onOpenDropdown: T.func.isRequired,
  onMarkItemAsSeen: T.func.isRequired,
  onPreviewAsset: T.func.isRequired,
  closeDropdown: T.func.isRequired,
};

export default connect(
  (state) => ({
    isDropdownOpen: state.dropdownLayout.isOpen,
  }), { closeDropdown }
)(TenantAssetsBoxesContainer);
