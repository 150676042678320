import React, { useState } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// api
import { declineCommitment } from 'endpoints/commitments';
// components
import Modal from 'components/Modal/Modal';
import Preloader from 'components/Preloader';
import RadioSelectGroup from 'components/RadioSelectGroup/RadioSelectGroup';
import Button from 'components/Button';
// styles
import styles from './LoadModal.module.scss';
// translation
import { FormattedMessage } from 'react-intl';

const cn = classnames.bind(styles);

const reasons = [
  {
    value: 'scheduling_conflict',
    label: <FormattedMessage id="component.declineLoadModal.reasons.schedulingConflict" />
  },
  {
    value: 'other',
    label: <FormattedMessage id="component.declineLoadModal.reasons.other" />
  },
];

const DeclineCommitmentModal = ({ onSubmit, commitment, closeModal }) => {

  const [isLoading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [note, setNote] = useState('');
  const [reason, setReason] = useState('');

  const handleDecline = () => {
    setLoading(true);
    const { id } = commitment;
    const data = { reason, comment: note };
    return declineCommitment(id, data)
      .then((updatedCommitment) => {
        onSubmit(updatedCommitment);
        setCurrentStep(currentStep + 1);
      })
      .catch(toastResponseErrors)
      .finally(() => setLoading(false));
  };

  const declineReasonStep = () => (
    <div className={cn('step-wrap')}>
      <div className={cn('body-wrap')}>
        <div className={cn('title')}>
          <FormattedMessage id="component.declineLoadModal.selectReason" />
        </div>
        <RadioSelectGroup
          className={cn('radio-group')}
          options={reasons}
          selected={reason}
          onChange={setReason}
        />
      </div>
      <div className={cn('button-wrap')}>
        <Button
          className={cn('button', 'btn-back')}
          rounded
          light
          onClick={closeModal}
        >
          <i className={cn('icon', 'fa fa-arrow-right')} />
          <FormattedMessage id="general.button.back" />
        </Button>
        <Button
          className={cn('button', 'btn-next')}
          primary
          onClick={() => setCurrentStep(currentStep + 1)}
          disabled={!reason.length}
        >
          <FormattedMessage id="general.button.next" />
          <i className={cn('icon', 'fa fa-arrow-right')} />
        </Button>
      </div>
    </div>
  );

  const enterNoteStep = () => (
    <div className={cn('step-wrap')}>
      <Preloader isActive={isLoading} />
      <div className={cn('body-wrap')}>
        <div className={cn('title')}>
          <FormattedMessage id="component.declineLoadModal.enterNote" />
        </div>
        <FormattedMessage id="component.declineLoadModal.notePlaceholder">
          {(placeholder) => (
            <textarea
              className={cn('text-area', 'mt-40')}
              value={note}
              onChange={({ target: { value } }) => setNote(value)}
              placeholder={placeholder}
            />
          )}
        </FormattedMessage>
      </div>
      <div className={cn('button-wrap')}>
        <Button className={cn('button', 'btn-back')} light onClick={() => setCurrentStep(currentStep - 1)}>
          <i className={cn('icon', 'fa fa-arrow-right')} />
          <FormattedMessage id="general.button.back" />
        </Button>
        <Button disabled={!note.length} className={cn('button', 'btn-next')} primary onClick={handleDecline}>
          <FormattedMessage id="general.button.finish" />
          <i className={cn('icon', 'fa fa-arrow-right')} />
        </Button>
      </div>
    </div>
  );

  const finalStep = () => (
    <div className={cn('final-step')}>
      <div className={cn('body-wrap')}>
        <div className={cn('icon-wrap')}>
          <i className={cn('truck', 'fa fa-ep-truck')} />
          <i className={cn('overflow-icon', 'fa fa-times', 'decline')} />
        </div>
        <p className={cn('description')}>
          <FormattedMessage id="component.declineLoadModal.loadDeclined" values={{ name: commitment.loading.name }} />
        </p>
      </div>
      <Button wide light className={cn('ok-btn')} onClick={closeModal}>
        <FormattedMessage id="general.button.ok" />
      </Button>
    </div>
  );

  const steps = [
    declineReasonStep,
    enterNoteStep,
    finalStep,
  ];

  return (
    <Modal>
      {steps[currentStep]()}
    </Modal>
  );
};

DeclineCommitmentModal.propTypes = {
  onSubmit: T.func.isRequired,
  commitment: T.object.isRequired,
  closeModal: T.func.isRequired,
};

export default connect(null, { closeModal })(DeclineCommitmentModal);
