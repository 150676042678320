import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// components
import Input from 'components/Input';
// assets
import styles from './IconInput.module.scss';

const cn = classnames.bind(styles);

const IconInput = ({ icon, iconLeft, ...others }) => (
  <div className={cn('input-wrapper', { iconLeft })}>
    <Input {...others} />
    <div className={cn('input-wrapper-icon')}>{icon}</div>
  </div>
);

IconInput.propTypes = {
  icon: T.node.isRequired,
  iconLeft: T.bool,
};

IconInput.defaultProps = {
  iconLeft: false,
};

export default IconInput;
