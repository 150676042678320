import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames/bind';
import styles from './DiagnoseButton.module.scss';

const cn = classnames.bind(styles);

const DiagnoseButton = ({ className, onClick, primary }) => (
  <div className={cn('add-diagnose', className, { primary })} onClick={onClick}>
    <i className={`${cn('diagnose-plus')} fa fa-diagnosis`} />
    <FormattedMessage id="general.diagnose" />
  </div>
);

DiagnoseButton.propTypes = {
  onClick: T.func.isRequired,
  primary: T.bool,
  className: T.string,
};

export default DiagnoseButton;
