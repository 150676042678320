import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Avatar from '../Avatar/Avatar';
import FarmfeedTooltip from './FarmfeedTooltip';
// endpoints
import { fetchActivityCompany } from 'endpoints/farmfeed';
//  constants
import { companyData } from './constants';

const CompanyFarmFeedTooltip = ({ activityId, companyId, isAdmin }, { router }) => {
  const [activityCompany, setActivityCompany] = useState({});

  useEffect(() => {
    fetchActivityCompany(activityId, companyId).then(setActivityCompany);
  }, []);

  return (
    <FarmfeedTooltip
      type="company"
      data={activityCompany}
      tooltipData={companyData}
      getAvatar={() => <Avatar type="company" avatarSize={55} iconSize={25} />}
      isAdmin={isAdmin}
      onManageBtnClick={() => router.push(`/admin/companies/${companyId}`)}
      manageBtnLabel={<FormattedMessage id="general.manageCompany" />}
    />
  );
};

CompanyFarmFeedTooltip.contextTypes = {
  router: T.object.isRequired,
};

CompanyFarmFeedTooltip.propTypes = {
  activityId: T.number.isRequired,
  companyId: T.number.isRequired,
  isAdmin: T.bool.isRequired,
};

export default CompanyFarmFeedTooltip;
