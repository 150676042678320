import React from 'react';
import T from 'prop-types';
// components
import UserAvatar from 'components/UserAvatar';
import CommentText from './CommentText';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SVRComment.module.scss';

const cn = classnames.bind(styles);

const SVRCommentView = ({
  comment: commentData,
  avatarSize = 35,
  className,
}) => (
  <div className={cn('svr-comment', className)}>
    <UserAvatar size={avatarSize} className="mt-5" user={commentData.user} />
    <div className={cn('comment-body')}>
      <CommentText commentData={commentData} />
    </div>
  </div>
);

SVRCommentView.propTypes = {
  comment: T.object.isRequired,
  avatarSize: T.number,
  className: T.string,
};

export default SVRCommentView;
