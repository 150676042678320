import { fetchFromAPI } from 'utils/api';

export const fetchUser = (userId) =>  {
  return fetchFromAPI(`/users/${userId}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const updateUser = (userId, resource, options = {}) =>  {
  return fetchFromAPI(`/users/${userId}`, { method: 'put', body: JSON.stringify({ resource, ...options }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getUserSetting = (userId) =>  {
  return fetchFromAPI(`/users/${userId}/setting`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const putUserSetting = (userId, resource) =>  {
  return fetchFromAPI(`/users/${userId}/setting`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const searchUsers = (params) => {
  return fetchFromAPI('/users/search', { params });
};
