import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Button from 'components/Button';
import FormField from 'components/ReduxFormFields/FormField/FormField';
import CompositeAddressField from 'components/ReduxFormFields/CompositeAddressField/CompositeAddressField';
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect';
import FormErrorsBox from 'components/FormErrorsBox';
// utils
import classnames from 'classnames/bind';
// constants
import { destinationTypes } from '../../../constants';
// assets
import styles from './DestinationForm.module.scss';

const cn = classnames.bind(styles);

const customErrors = [{
  key: 'name',
  error: <FormattedMessage id="component.destinationForm.errors.name" />,
}];

const formFields = ['name', 'address', 'city', 'state', 'zipcode', 'destination_type', 'prem_id', 'country_code'];
const requiredFields = ['name'];

const checkRequiredFieldsFilled = (values = {}) => (
  requiredFields.every(((field) => !!values[field]))
);

const DestinationForm = ({ handleSubmit, pristine, submitting, isEditMode, formValues }) => (
  <div>
    <div className="row">
      <div className="small-12 column">
        <FormErrorsBox formName="destination-form" customErrors={customErrors} />
      </div>
    </div>

    <form onSubmit={handleSubmit} className="DestinationForm">
      {/* NAME */}
      <div className="row">
        <div className="small-12 column">
          <label>
            <FormattedMessage id="general.typeAName">
              {(placeholder) => (
                <Field
                  label={<FormattedMessage id="general.destinationName" />}
                  name="name"
                  component={FormField}
                  type="text"
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
          </label>
        </div>
      </div>

      {/* DEST_ID */}
      <div className="row">
        <div className="small-12 column">
          <label>
            <FormattedMessage id="general.destinationID">
              {(label) => (
                <Field
                  label={label}
                  name="prem_id"
                  component={FormField}
                  type="text"
                  placeholder={label}
                />
              )}
            </FormattedMessage>
          </label>
        </div>
      </div>

      {formValues.country_code &&
        <CompositeAddressField countryCode={formValues.country_code} />}

      {/* TYPE */}
      <div className="row">
        <div className="small-12 column">
          <FormattedMessage id="general.destinationType">
            {(text) => <label className={cn('type-input-label')}>{text}</label>}
          </FormattedMessage>
          <label>
            <Field
              name="destination_type"
              value=""
              component={(props) => (
                <ResponsiveSelect
                  grey
                  className={cn('type-select')}
                  placeholder={<FormattedMessage id="general.placeholder.chooseType" />}
                  options={destinationTypes}
                  {...props}
                />
              )}
            />
          </label>
        </div>
      </div>

      {/* BUTTON */}
      <div className="row mt-20 mb-20">
        <div className="small-12 column">
          <Button primary type="submit" disabled={pristine || submitting || !checkRequiredFieldsFilled(formValues)}>
            {isEditMode
              ? <FormattedMessage id="general.button.saveChanges" />
              : <FormattedMessage id="general.createDestination" />}
          </Button>
          <Link to="/destinations" className="button btn-link margin-left-1">
            <FormattedMessage id="general.button.cancel" />
          </Link>
        </div>
      </div>
    </form>
  </div>
);

DestinationForm.propTypes = {
  handleSubmit: T.func.isRequired,
  pristine: T.bool,
  isEditMode: T.bool,
  submitting: T.bool,
  formValues: T.object,
};

const selector = formValueSelector('destination-form');
const enhance = compose(
  connect(
    (state) => ({
      formValues: selector(state, ...formFields),
    }),
  ),
  reduxForm({ form: 'destination-form' }),
);

export default enhance(DestinationForm);
