import React from 'react';
import T from 'prop-types';
// recompose
import { branch, renderNothing } from 'recompose';
// utils
import classnames from 'classnames/bind';
// translate
import { FormattedMessage } from 'react-intl';
// styles
import styles from './GroupSourceBlock.module.scss';
import Link from 'react-router/lib/Link';

const cn = classnames.bind(styles);

const GroupSourceBlock = ({ isAdmin, source, className }) => (
  <div className={cn('group-source-block', className)}>
    <div className={cn('block-title')}>
      <FormattedMessage id="general.source" />
    </div>
    <div className={cn('source-name-row')}>
      {isAdmin
        ? <Link to={`/sources/${source.id}/overview`} className={cn('color-primary')}>{source.name}</Link>
        : <span>{source.name}</span>}
      <span className={cn('source-badge', { 'commingled': source.source_type === 'co' })}>
        {source.source_type === 'co'
          ? <FormattedMessage id="general.sourceTypes.commingled" />
          : <FormattedMessage id="general.sourceTypes.externalOrigin" />}
      </span>
    </div>
    {source.commingled_children && source.commingled_children.map((item) => (
      <div key={item.id} className={cn('source-name-row', 'pl-30')}>
        <span>{item.name}</span>
        <span className={cn('source-badge')}>
          <FormattedMessage id="general.sourceTypes.externalOrigin" />
        </span>
      </div>
    ))}
  </div>
);

GroupSourceBlock.defaultProps = {
  source: {},
};

GroupSourceBlock.propTypes = {
  source: T.object,
  className: T.string,
  isAdmin: T.bool,
};

export default branch(
  ({ source }) => !source,
  renderNothing,
)(GroupSourceBlock);
