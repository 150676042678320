import React from 'react';
import T from 'prop-types';
// components
import Select from 'react-virtualized-select';
// utils
import cn from 'classnames';
// styles
import 'react-select/dist/react-select.css';
import './ReactSelect.scss';

const VirtualizedSelect = ({ className, noInputMargin, ...others }, { isIOS }) => (
  <Select {...others} className={cn('ReactSelect', className, { isIOS, noInputMargin })} />
);

VirtualizedSelect.contextTypes = {
  isIOS: T.bool,
};

VirtualizedSelect.propTypes = {
  className: T.string,
  noInputMargin: T.bool,
};

export default VirtualizedSelect;
