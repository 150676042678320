import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './ActivityHeader.module.scss';

const ActivityHeaderBadge = ({ className, iconClassName, renderIcon, isCentered, children }) => (
  <div className={cn(styles['header-badge'], { [className]: !!className, [styles.centered]: isCentered })}>
    {renderIcon(iconClassName)}
    {children}
  </div>
);

ActivityHeaderBadge.propTypes = {
  className: T.string,
  isCentered: T.bool,
  iconClassName: T.string,
  renderIcon: T.func,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

ActivityHeaderBadge.defaultProps = {
  renderIcon: (iconClass) => <i className={cn(iconClass, styles.icon)} />,
  iconClassName: 'fa fa-exclamation-triangle',
};

export default ActivityHeaderBadge;
