import React from 'react';
import T from 'prop-types';
// recompose
import { pure } from 'recompose';
// components
import Dropdown from 'components/Dropdown/Dropdown2';
import FlexTableItem from 'components/FlexTable/FlexTableItem';
// utils
import cn from 'classnames';
// styles
import './DataTableHeader.scss';

const DataTableHeader = ({ isPinnedHeader, columns, hasDesktopViewOnly, onPinnedIconClick, handleSort }) => (
  <div className={cn('table-row', { 'show-for-large': !hasDesktopViewOnly, isPinnedHeader })}>
    {columns.map(({
      label, flex, fixed, className, sortKey, hide, description, hasPinnedIcon, textRight, textCenter
    }, index) => !hide && (
      <FlexTableItem
        key={index}
        className={cn(className, { 'desktop-view': hasDesktopViewOnly })}
        flex={flex}
        fixed={fixed}
      >
        <div className="collapsible-column-name">
          <div className={cn('sort-column', { 'text-right': textRight }, { 'text-center': textCenter })}>
            <div>
              <span>
                <span className={cn({ 'allow-sort-column': sortKey })} onClick={() => handleSort(sortKey)}>
                  {label}
                </span>
                {description && (
                  <Dropdown
                    idKey={`${sortKey}-col`}
                    iconClassName="fa fa-info-circle desc-icon"
                    options={[{ label: description, isDescription: true }]}
                  />
                )}
              </span>
              {hasPinnedIcon && (
                <i
                  className={`pin-icon fa fa-push-pin${isPinnedHeader ? '-bts' : ''}`}
                  onClick={onPinnedIconClick}
                />
              )}
            </div>
          </div>
        </div>
      </FlexTableItem>
    ))}
  </div>
);

DataTableHeader.propTypes = {
  hasDesktopViewOnly: T.bool,
  isPinnedHeader: T.bool,
  columns: T.array.isRequired,
  onPinnedIconClick: T.func.isRequired,
  handleSort: T.func.isRequired,
};

export default pure(DataTableHeader);
