import round from 'lodash.round';

// imperial system - lbs, metric system - kg, on back-end always lbs

export function convertLbsToKg(lbsValue, placeholder = null) {
  if (!lbsValue && lbsValue !== 0) {
    return placeholder;
  }
  return round(lbsValue * 0.45359237, 0);
}

export function convertKgToLbs(kgValue, placeholder = null) {
  if (!kgValue && kgValue !== 0) {
    return placeholder;
  }
  return round(kgValue / 0.45359237, 2);
}

export function formatBackEndWeightToSystem(
  lbsValue,
  measurementSystem,
  placeholder = null
) {
  if (!lbsValue && lbsValue !== 0) {
    return placeholder;
  }
  return measurementSystem === 'imperial' ? round(lbsValue, 2) : convertLbsToKg(lbsValue);
}

export function formatWeightToBackEndFormat(weightValue, currentMeasurementSystem) {
  return currentMeasurementSystem === 'imperial' ? weightValue : convertKgToLbs(weightValue);
}
