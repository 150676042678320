import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closePortal } from 'reducers/portal';
// components
import MobileHeader from 'components/MobileHeader/MobileHeader';
import Button from 'components/Button';
// translate
import { FormattedMessage } from 'react-intl';
import SearchBox from 'components/SearchBox';
// styles
import cn from 'classnames';
import './CountryPickerMobile.scss';

class CountryPickerMobile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      limit: props.limit || 20,
    };
  }

  closePortal = () => {
    const { closePortal } = this.props;
    closePortal();
  };

  onSearchChange = (search) => {
    this.setState({ search });
  };

  handleScroll = ({ target }) => {
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      this.setState(({ limit }) => ({
        limit: limit + this.props.limit,
      }));
    }
  };

  handleSelect = (country) => () => {
    const { closePortal, onSelect } = this.props;
    onSelect(country)();
    closePortal();
  };

  render() {
    const { search, limit } = this.state;
    const { title, countries, selectedCountry } = this.props;
    const filteredCountries = search
      ? countries.filter((country) => country.country_name.toLowerCase().includes(search.toLowerCase()))
      : countries;

    return (
      <div className="CountryPickerMobile">
        <MobileHeader title={title} backLink={this.closePortal} />
        <div className="search-box-wrapper">
          <SearchBox initialValue={search} onChange={this.onSearchChange} className="full-width" />
        </div>
        <div className="countries-list-wrapper" onScroll={this.handleScroll}>
          {filteredCountries.slice(0, limit).map((country, idx) => (
            <div
              key={idx}
              className={cn('select-item country', { 'selected': country.country_code === selectedCountry })}
              onClick={this.handleSelect(country)}
            >
              <div className={`flag-icon flag-icon-${country.country_code.toLowerCase()}`} />
              <div className="country-name">{country.country_name}</div>
              <div className="country-code">{country.dialling_code}</div>
              <Button className="select-btn" small primary>
                <FormattedMessage id="general.button.select" />
              </Button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

CountryPickerMobile.propTypes = {
  title: T.string,
  selectedCountry: T.string,
  onSelect: T.func.isRequired,
  countries: T.array.isRequired,
  closePortal: T.func.isRequired,
  limit: T.number,
};

export default connect(null, { closePortal })(CountryPickerMobile);
