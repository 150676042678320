import { handleActions, createAction } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  resource: null,
  isLoading: false,
};

// ------------------------------------
// Action Types
// ------------------------------------
const FETCH_EFFICACY_PENDING = 'feedback/FETCH_EFFICACY_PENDING';
const FETCH_EFFICACY_FULFILLED = 'feedback/FETCH_EFFICACY_FULFILLED';
const FETCH_EFFICACY_REJECTED = 'feedback/FETCH_EFFICACY_REJECTED';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchEfficacy = (id) => (dispatch) => {
  dispatch(createAction(FETCH_EFFICACY_PENDING)());

  return fetchFromAPI(`/treatment_efficacies/${id}`)
    .then((response) => {
      dispatch(createAction(FETCH_EFFICACY_FULFILLED)(response));
    })
    .catch((response) => {
      dispatch(createAction(FETCH_EFFICACY_REJECTED)(response));
      throw response;
    });
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_EFFICACY_PENDING]: (state) => ({
    ...state,
    isLoading: true
  }),
  [FETCH_EFFICACY_FULFILLED]: (state, action) => ({
    resource: action.payload.resource,
    isLoading: false
  }),
  [FETCH_EFFICACY_REJECTED]: (state, action) => ({
    ...action.payload,
    resource: null,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
