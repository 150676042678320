import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import SuggestionItem from '../SuggestionItem/SuggestionItem';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './FarmFeedAutoSuggestMobile.module.scss';

const cn = classnames.bind(styles);

const FarmFeedAutoSuggestMobile = ({
  closePortal,
  getSuggestions,
  onSelect,
}) => {
  const [{ suggestions, isLoading }, setState] = useState({ suggestions: [], isLoading: false });

  const handleChangeSearch = (search) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getSuggestions(encodeURIComponent(search))
      .then(({ pig_groups, users, farms, companies }) => {
        const suggestions = [...pig_groups, ...users, ...farms, ...companies];
        if (search) {
          suggestions.push({ text: search, type: 'search' });
        }
        setState({ suggestions, isLoading: false });
      })
      .catch(() => {
        setState({ suggestions: [], isLoading: false });
      });
  };

  useEffect(() => {
    handleChangeSearch('');
  }, []);

  const handleSelectSuggestion = (suggestion) => {
    onSelect(suggestion);
    closePortal();
  };

  return (
    <div className={cn('activity-search-mobile')}>
      <div className={cn('search-block')}>
        <i className={cn('fa fa-times', 'close-icon')} onClick={() => closePortal()} />
        <SearchBox
          className={cn('search-input')}
          placeholderKey="general.searchSomething"
          initialValue=""
          onChange={handleChangeSearch}
          isClearIconHidden
          isSearchIconHidden
        />
      </div>
      <div className={cn('search-suggestions')}>
        <Preloader isActive={isLoading} />
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            className={cn('suggestion-item', {
              'first-item': !index,
              'divider': suggestions[index + 1] && (suggestion.type !== suggestions[index + 1].type),
            })}
            onClick={() => handleSelectSuggestion(suggestion)}
          >
            <SuggestionItem suggestion={suggestion} />
          </div>
        ))}
      </div>
    </div>
  );
};

FarmFeedAutoSuggestMobile.propTypes = {
  getSuggestions: T.func.isRequired,
  onSelect: T.func.isRequired,
  // added in RootPortal
  closePortal: T.func,
};

export default FarmFeedAutoSuggestMobile;
