import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// assets
import styles from './Input.module.scss';

const cn = classnames.bind(styles);

const Input = ({ className, type, getReference, hasNoMargin, alignRight, bolded, hasError, ...others }) => (
  <input
    ref={getReference}
    type={type}
    className={cn('Input', className, {
      'no-margin': hasNoMargin,
      'align-right': alignRight,
      'has-error': hasError,
      bolded,
    })}
    {...others}
  />
);

Input.propTypes = {
  className: T.string,
  type: T.string,
  getReference: T.func,
  hasNoMargin: T.bool,
  alignRight: T.bool,
  bolded: T.bool,
  hasError: T.bool,
};

Input.defaultProps = {
  className: '',
  type: 'text',
  hasNoMargin: false,
  alignRight: false,
  bolded: false,
  hasError: false,
};

export default Input;
