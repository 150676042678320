import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { setSharedholderGroup } from 'reducers/admin/shareholderGroups/groupShow';
import { fetchData } from 'reducers/dataTable';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import FarmTitleBox from 'components/FarmTitleBox/FarmTitleBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
// utils
import isEmpty from 'lodash.isempty';
import sum from 'lodash.sum';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
// shareholder groups API
import { revokeFarmRole } from 'endpoints/admin/shareholderGroups';

const labels = {
  dataTable: {
    location: <FormattedMessage id="component.dataTable.headers.location" />,
    farmName: <FormattedMessage id="component.dataTable.headers.farmName" />,
    farmId: <FormattedMessage id="component.dataTable.headers.farmId" />,
    status: <FormattedMessage id="component.dataTable.headers.status" />,
    farmStatus: <FormattedMessage id="component.dataTable.headers.farmStatus" />,
  },
  buttons: {
    revokeAccess: <FormattedMessage id="general.button.revokeAccess" />,
    edit: <FormattedMessage id="general.button.edit" />,
    editFarm: <FormattedMessage id="general.editFarm" />,
    manageRoles: <FormattedMessage id="general.manageRoles" />,
    assignFarms: <FormattedMessage id="general.assignFarms" />,
  },
  allFarms: <FormattedMessage id="general.allFarms" />,
  farmAssignments: <FormattedMessage id="general.farmAssignments" />,
  active: <FormattedMessage id="general.status.active" />,
  disabled: <FormattedMessage id="general.status.disabled" />,
};

class ShareholderGroupFarmRoles extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  revokeRole = (farmId) => () => {
    const { fetchData, setSharedholderGroup, reqParams, params: { id } } = this.props;
    revokeFarmRole(id, farmId)
      .then((resource) => {
        showToastrMessage('component.toastr.role.revoked');
        setSharedholderGroup(resource);
        fetchData(reqParams);
      })
      .catch(toastResponseErrors);
  };

  renderFarmNameColumn = ({ name, farm_type }) => (
    <CustomColumn>
      <FarmTitleBox name={name} type={farm_type} />
    </CustomColumn>
  );

  renderLocationColumn = ({ city, state }) => (
    <CustomColumn label={labels.dataTable.location}>
      {`${city}, ${state}`}
    </CustomColumn>
  );

  renderFarmIdColumn = ({ external_link_id }) => (
    <CustomColumn label={labels.dataTable.farmId}>
      {external_link_id || 'N/A'}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.farmStatus}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        wide
        buttonType="small light-gray"
        label={labels.buttons.revokeAccess}
        onClick={this.revokeRole(id)}
        dropDownData={[
          { label: labels.buttons.editFarm, url: `/admin/farms/${id}/edit` },
        ]}
        customClass="show-for-large"
      />
      <Link to={`/admin/farms/${id}/edit`} className="button wide hide-for-large">
        {labels.buttons.edit}
      </Link>
      <Button darkGrey onClick={this.revokeRole(id)} className="hide-for-large">
        {labels.buttons.revokeAccess}
      </Button>
    </div>
  );

  renderExpandable = ({ id }) => (
    <div>
      <Link to={`/admin/farms/${id}/edit`} className="button small light">
        <i className="fa fa-pencil mr-5" />
        {labels.buttons.editFarm}
      </Link>
      <Button small light onClick={this.revokeRole(id)}>
        <i className="fa fa-times-circle mr-5" />
        {labels.buttons.revokeAccess}
      </Button>
    </div>
  );

  render() {
    const { shareholderGroupFarms, meta: { stats = {}, total }, isLoading, reqParams: { page, per_page, status, search,
      sort }, onStatusChange, onSearchChange, onPageChange, onPerPageChange, onSortChange,
    params: { id } } = this.props;

    const { isTablet } = this.context;
    const statsSum = sum(Object.values(stats));
    const filtersData = [
      { label: labels.allFarms, value: '', count: statsSum },
      { label: labels.active, value: 'active' },
      { label: labels.disabled, value: 'disabled' },
    ];
    const isEmptyTable = !shareholderGroupFarms.length && !status && !search;

    const columns = [
      { label: labels.dataTable.farmName, flex: '2 1 150px', renderer: this.renderFarmNameColumn, sortKey: 'name' },
      { label: labels.dataTable.location, flex: '1 1 100px', renderer: this.renderLocationColumn, sortKey: 'city' },
      { label: labels.dataTable.farmId, flex: '1 1 100px', renderer: this.renderFarmIdColumn,
        sortKey: 'external_link_id' },
      { label: labels.dataTable.status, flex: '1 1 100px', renderer: this.renderStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 180px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <Panel>
        <Panel.Heading title={labels.farmAssignments}>
          {!isEmptyTable &&
            <SearchBox initialValue={search} onChange={onSearchChange} />}
        </Panel.Heading>

        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={filtersData}
              onFilterChange={onStatusChange}
              stats={stats}
              activeFilter={status}
              className="ph-10"
            >
              {!isEmpty(shareholderGroupFarms) && (
                <Link to={`/admin/shareholder-groups/${id}/assign-farms`} className="button small primary wider">
                  {labels.buttons.manageRoles}
                </Link>
              )}
            </TableFilter>
          )}

          <Preloader isActive={isLoading} />

          <DataTable
            data={shareholderGroupFarms}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            scrollable
            tableKey="adminShareholderGroupFarms"
          />

          <NothingBox
            itemsName="farm_assignments"
            display={!shareholderGroupFarms.length}
            isLoading={isLoading}
            search={search}
            filter={status}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.shGroupHasntFarms" />
            <Link to={`/admin/shareholder-groups/${id}/assign-farms`} className="button primary mt-10">
              {labels.buttons.assignFarms}
            </Link>
            <RolesPlaceholder />
          </NothingBox>
        </Panel.Body>
      </Panel>
    );
  }
}

ShareholderGroupFarmRoles.contextTypes = {
  isTablet: T.bool.isRequired,
};

ShareholderGroupFarmRoles.propTypes = {
  params: T.object.isRequired,
  shareholderGroupFarms: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  fetchData: T.func.isRequired,
  setSharedholderGroup: T.func.isRequired,
};

const enhance = compose(
  connect((state) => ({
    shareholderGroupFarms: state.dataTable.adminShareholderGroupFarms.resources,
    isLoading: state.dataTable.adminShareholderGroupFarms.isLoading,
    reqParams: state.dataTable.adminShareholderGroupFarms.params,
    meta: state.dataTable.adminShareholderGroupFarms.meta,
    user: state.users.resource.data,
  }), (dispatch, { params: { id } }) => ({
    fetchData: (query) => dispatch(fetchData(`/admin/shareholder_groups/${id}/farms/profile_index`,
      'adminShareholderGroupFarms', query)),
    setSharedholderGroup: (data) => dispatch(setSharedholderGroup(data)),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(ShareholderGroupFarmRoles);
