import React from 'react';
import T from 'prop-types';
import Button from '../Button';
import cn from 'classnames';
import './MobileRow.scss';
import { FormattedMessage } from 'react-intl';

const MobileBarnRow = ({ barn, actionBtnType, disabledRow, onRowClick, selectedRow }) => (
  <div
    onClick={onRowClick}
    className={cn('MobileRow', {
      'no-events': disabledRow,
      'select-row': actionBtnType === 'button',
    })}
  >
    <div className={cn({ 'disable-for-select': disabledRow }, 'row-info')}>
      <span>{barn.name}</span>
    </div>
    {actionBtnType === 'button' && (
      <Button disabled={selectedRow} className="select-btn" small primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    )}
    {actionBtnType === 'checkbox' && (
      <div className={cn('icon', { 'selected': selectedRow })}>
        <i className="checked fa fa-check-circle-bts" />
        <i className="unchecked fa fa-radio-unchecked" />
      </div>
    )}
  </div>
);

MobileBarnRow.propTypes = {
  actionBtnType: T.string.isRequired,
  barn: T.object.isRequired,
  disabledRow: T.bool.isRequired,
  onRowClick: T.func.isRequired,
  selectedRow: T.bool.isRequired,
};

export default MobileBarnRow;
