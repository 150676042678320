import React from 'react';

import styles from './ClosedIntegration.module.scss';
import Button from '../../Button';
import { FormattedMessage } from 'react-intl';
import T from 'prop-types';
import { Link } from 'react-router';
import  noReadyLoads from '../../../../public/images/no-ready-groups-loads-white.svg';

const ClosedIntegration = ({ link }) => {
  const redirectUrl = `/admin/preferences/integrations/${link}`;
  return (
    <div className={styles.wrapperActivate}>
      <div className={styles.wrapperBody} />
      <img className={styles.imageNoReady} src={noReadyLoads} alt="no-ready-loads" />
      <div className={styles.textInfo}>
        <FormattedMessage id="general.notActivation" />
      </div>
      <Link to={redirectUrl}>
        <Button primary small>
          <FormattedMessage id="general.activate" />
        </Button>
      </Link>
    </div>
  );

};

ClosedIntegration.propTypes = {
  link: T.string.isRequired
};

export default ClosedIntegration;
