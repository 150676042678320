import { fetchFromAPI } from 'utils/api';

export const updateAdminTreatmentProduct = (id, resource) => {
  return fetchFromAPI(`/admin/treatment_products/${id}`, { method: 'PUT', body: JSON.stringify({ resource }) })
    .then(({ resource }) => resource)
    .catch((response) => {
      throw response;
    });
};

export const getAdminTreatmentProduct = (id) => {
  return fetchFromAPI(`/admin/treatment_products/${id}`)
    .then(({ resource }) => resource)
    .catch((response) => {
      throw response;
    });
};
