import Geocode from 'react-geocode';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// constants
import { GOOGLE_MAPS_API_KEY } from 'constants.js';

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

function parseAddress(geocodeResponse) {
  const { address_components, geometry } = geocodeResponse.results[0];
  const addressObj = {};
  address_components.forEach((addressComponent) => {
    addressComponent.types.forEach((type) => {
      addressObj[type] = addressComponent.short_name;
    });
  });
  return {
    latlng: geometry.location,
    strNumber: addressObj.street_number,
    route: addressObj.route,
    city: addressObj.locality || addressObj.administrative_area_level_2, // level 2 for Brazil
    region: addressObj.administrative_area_level_1,
    countryKey: addressObj.country,
    zipcode: addressObj.postal_code,
  };
}

const call = (method) => async (args) => {
  try {
    const result = await Geocode[method](...args);
    return parseAddress(result);
  } catch (err) {
    toastResponseErrors({ error: err.message  });
    return { error: true };
  }
};


export default {
  fromAddress: call('fromAddress'),
  fromLatLng: call('fromLatLng'),
};
