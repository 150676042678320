import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import VerticalAligner from 'components/VerticalAligner';
import MortalityReasonForm from './components/MortalityReasonForm';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// utils
import isEmpty from 'lodash.isempty';
import handleFormErrors from 'utils/handleFormErrors';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
// endpoints
import { getMortalityReason, updateMortalityReason } from 'endpoints/admin/healthVariables';
// styles
import './UpdateMortalityReason.scss';

class UpdateMortalityReason extends Component {

  state = {
    mortalityReason: {},
    isLoading: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { params: { id } } = this.props;
    this.setState({ isLoading: true });

    return getMortalityReason(id)
      .then((mortalityReason) => {
        this.setState({ isLoading: false, mortalityReason });
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        toastResponseErrors(errors);
        this.context.router.push('/admin/health-variables/mortality-reasons');
      });
  };

  getInitialValues = () => {
    const { params: { id } } = this.props;
    const { mortalityReason } = this.state;
    if (!isEmpty(mortalityReason) && parseInt(id, 10) === mortalityReason.id) {
      const name = Object.keys(mortalityReason.translations)
        .map((key) => ({ lang: key, value: mortalityReason.translations[key] }));

      return { ...mortalityReason, name };
    }
    return {};
  };

  onSubmit = (data) => {
    const dataValues = {
      code: data.code,
      name: data.name.reduce((prev, curr) => ({ ...prev, [curr.lang]: curr.value.trim() }), {}),
    };
    const { router } = this.context;
    const { params: { id } } = this.props;
    this.setState({ isLoading: true });
    updateMortalityReason(id, dataValues)
      .then(() => {
        this.setState({ isLoading: false });
        showToastrMessage('component.toastr.reason.updated');
        router.push('/admin/health-variables/mortality-reasons');
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        return handleFormErrors(errors);
      });
  };

  disableItem = (id, status) => () => {
    this.setState({ isLoading: true });

    return updateMortalityReason(id, { active: status })
      .then(() => {
        this.setState((prevState) => ({
          isLoading: false,
          mortalityReason: {
            ...prevState.mortalityReason,
            active: status,
          },
        }));
        showToastrMessage('component.toastr.status.changed');
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        return handleFormErrors(errors);
      });
  };

  render() {
    const { mortalityReason, isLoading } = this.state;
    const initialValues = this.getInitialValues();

    return (
      <div className="UpdateMortalityReason">
        <div className="reason-header">
          <VerticalAligner>
            <span className="reason-name">{mortalityReason.name}</span>
            <StatusBadge status={mortalityReason.active ? 'active' : 'disabled'} />
          </VerticalAligner>
          <Button onClick={this.disableItem(mortalityReason.id, !mortalityReason.active)} className="small light-gray">
            <FormattedMessage id={`general.button.${mortalityReason.active ? 'disable' : 'enable'}`} />
          </Button>
        </div>
        <section className="small-12 column">
          <Panel>
            <Panel.Heading title={<FormattedMessage id="container.admin.healthVariables.reasons.edit" />} />
            <Panel.Body noPadding>
              <div className="row">
                <section className="small-12 medium-10 large-8 column">
                  <Preloader isActive={isLoading} />
                  <FormErrorsBox formName="create-mortality-reason" />
                  <MortalityReasonForm edit onSubmit={this.onSubmit} initialValues={initialValues} />
                </section>
              </div>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

UpdateMortalityReason.contextTypes = {
  router: T.object,
};

UpdateMortalityReason.propTypes = {
  params: T.object,
};

export default UpdateMortalityReason;
