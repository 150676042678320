import React from 'react';
import T from 'prop-types';
// redux, actions
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import CreateFarmForm from './components/CreateFarmForm';
import FormErrorsBox from 'components/FormErrorsBox';
import Panel from 'components/Panel';
// api
import { createAdminFarm } from 'endpoints/admin/farms';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
// constants
import { customErrors, requiredFarmFields, requiredSUFarmFields } from 'constants/farms';

const CreateFarm = ({ currentCompany, router }) => {
  const createNewFarm = (data) => {
    const { farm_type } = data;
    const entity = pick(data, farm_type === 'su' ? requiredSUFarmFields : requiredFarmFields);

    if (data.has_user) {
      const phoneData = !isEmpty(data.telephone)
        ? { phone: data.telephone.phone, country_code: data.telephone.countryCode }
        : {};
      entity.user = { ...data.user, ...phoneData };
    }

    return createAdminFarm({ ...entity, ...data.addressObj })
      .then(({ id }) => {
        router.push(`/admin/farms/${id}/user-roles`);
        if (entity.user_role) {
          showToastrMessage('component.toastr.userInvite.successCreated');
        }
        showToastrMessage('component.toastr.farm.created');
      })
      .catch((response) => {
        toastResponseErrors(response, {
          external_link_id: `Error: ${response?.errors?.external_link_id || 'Unknown'}`
        });
      });
  };

  const initialValues = {
    manager_id: currentCompany.id,
    utc_offset: parseInt(moment().format('Z'), 10),
    user: {
      language: currentCompany.language || 'en',
    },
    has_user: false,
    external: false,
    assign_external_source: false,
    auto_refresh_utc_offset: true,
    loose_sow_housed: false,
  };

  return (
    <section className="small-12 column">
      <Panel>
        <Panel.Heading title={<FormattedMessage id="general.createFarm" />} />
        <Panel.Body noPadding>
          <FormErrorsBox
            excludedFields={['user']}
            formName="create-farm"
            customErrors={customErrors}
          />
          <CreateFarmForm onSubmit={createNewFarm} initialValues={initialValues} hasLocationDefining />
        </Panel.Body>
      </Panel>
    </section>
  );
};

CreateFarm.propTypes = {
  currentCompany: T.object,
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    currentCompany: state.auth.user.current_company,
  })
)(CreateFarm);
