import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import ActivityAuthorLine from './ActivityAuthorLine/ActivityAuthorLine';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityBox from './ActivityBox';
import ActivityHeader from './ActivityHeader';
import ActivityMentions from './ActivityMentions';
import ActivitySwitcher from './ActivitySwitcher';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
// utils
import classnames from 'classnames/bind';
// hoc
import withActivityController from './hoc/withActivityController';
// styles
import styles from './SharedActivity.module.scss';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments'];

const SharedActivity = ({
  className,
  activity,
  currentUser,
  markAsSeen,
  openDropdown,
  setActivityFlag,
  isMinimized,
  toggleMinimized,
  defaultDropdownOptions,
}) => {
  const { id, flagged, is_viewed, company, farm, user, mentions, message } = activity;

  return (
    <ActivityBox className={className} isFlagged={flagged} isUnseen={!is_viewed} markAsSeen={markAsSeen}>
      {!isMinimized && <LastCommentInfo activity={activity} currentUser={currentUser} />}
      <ActivityHeader
        activityId={id}
        onOpenDropdown={(e) => openDropdown(e, defaultDropdownOptions)}
        options={defaultDropdownOptions}
        onDoubleClick={toggleMinimized}
      >
        <ActivityAuthorLine
          activity={activity}
          currentUser={currentUser}
          minimized={isMinimized}
        >
          <FormattedMessage
            tagName="div"
            id="container.farmfeed.row.reason.shared"
            values={{
              ownPost: currentUser.id === user.id ? 'yes' : 'no',
              name: user.full_name,
              b: (msg) => <b>{msg}</b>,
            }}
          />
        </ActivityAuthorLine>
      </ActivityHeader>

      <ActivityBodyCollapse isClosed={isMinimized}>
        {message &&
          <div className={cn('shared-activity-message')}>{message}</div>}

        <ActivityMentions
          className="ph-20"
          company={company}
          farm={farm}
          users={mentions}
          isAdmin={currentUser.roles_map.admin}
        />

        <ActivitySwitcher activity={activity.original_activity} currentUser={currentUser} isChildActivity />

        <ActivityActionsBar
          activity={activity}
          currentUser={currentUser}
          openDropdown={openDropdown}
          setActivityFlag={setActivityFlag}
        />

        <FarmFeedRowComments activity={activity} />
        <FarmFeedRowAddComment activity={activity} />
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

SharedActivity.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  markAsSeen: T.func,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
  // from hoc
  isMinimized: T.bool.isRequired,
  toggleMinimized: T.func.isRequired,
  defaultDropdownOptions: T.array.isRequired,
};

export default withActivityController(SharedActivity, { affectRerenderKeys });
