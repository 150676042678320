import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
// utils
import cn from 'classnames';
// styles
import styles from './OutdatedScheduleModal.module.scss';

const OutdatedScheduleModal = ({ closeModal, onLeave, onRefresh }) => (
  <Modal className={styles['schedule-updated-modal']} noCloseButton>
    <FormattedMessage id="general.loadScheduleUpdated">
      {(msg) => <p className={styles.text}>{msg}</p>}
    </FormattedMessage>
    <div className={styles.buttons}>
      <button
        className={cn(styles.btn, styles['leave-btn'])}
        onClick={() => {
          closeModal();
          onLeave();
        }}
      >
        <FormattedMessage id="general.leaveItForLater" />
      </button>
      <button
        className={cn(styles.btn, styles['refresh-btn'])}
        onClick={() => {
          closeModal();
          onRefresh();
        }}
      >
        <FormattedMessage id="general.refresh" />
      </button>
    </div>
  </Modal>
);

OutdatedScheduleModal.propTypes = {
  closeModal: T.func.isRequired,
  onLeave: T.func.isRequired,
  onRefresh: T.func.isRequired,
};

export default connect(null, { closeModal })(OutdatedScheduleModal);
