import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// components
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// utils
import classNames from 'classnames/bind';
// styles
import styles from './OfflineSubmitAlert.module.scss';
import drBotImage from '../../../../../public/images/dr-bot.svg';

const cn = classNames.bind(styles);

const OfflineSubmitAlert = ({ closeModal }) => (
  <div className={cn('offline-submit-alert')}>
    <div className={cn('offline-submit-body')}>
      <div className={cn('offline-bot-doc')}>
        <img className={cn('offline-bot-doc-image')} src={drBotImage} alt="" />
        <br />
        <FormattedMessage id="general.drBot">
          {(text) => <div className={cn('offline-bot-doc-name')}>{text}</div>}
        </FormattedMessage>
      </div>
      <FormattedMessage id="component.modal.offlineSubmitAlert.text">
        {(text) => <div className={cn('offline-bot-message')}>{text}</div>}
      </FormattedMessage>
    </div>
    <Button className={cn('bottom-btn')} onClick={() => closeModal()} primary>
      <FormattedMessage id="general.button.gotIt" />
    </Button>
  </div>
);

OfflineSubmitAlert.propTypes = {
  closeModal: T.func.isRequired,
};

export default connect(null, { closeModal })(OfflineSubmitAlert);
