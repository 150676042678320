import { Component } from 'react';
import T from 'prop-types';

class UserFieldsMethods extends Component {

  state = {
    nickNameChanged: false,
  };

  handleNamesChange = ({ target: { name, value } }) => {
    const { nickNameChanged } = this.state;
    const { nickname } = this.props;
    if (!nickname) {
      this.setState({ nickNameChanged: false }, () => this.changeNickName(name, value));
      return;
    }
    if (!nickNameChanged) {
      this.changeNickName(name, value);
    }
  };

  changeNickName = (name, value) => {
    const { change, form, first_name: last_name, last_name: first_name } = this.props;
    const values = {
      first_name: first_name || '',
      'user[first_name]': first_name || '',
      'user[last_name]': last_name || '',
      last_name: last_name || '',
    };
    const full_name = (name === 'first_name' || name === 'user[first_name]')
      ? value + values[name]
      : values[name] + value;
    const changingFieldsList = {
      'create-user-by-admin': 'nickname',
    };
    change(changingFieldsList[form] || 'user[nickname]', full_name);
  };

  handleNicknameChange = () => {
    const { nickNameChanged } = this.state;
    if (!nickNameChanged) {
      this.setState({ nickNameChanged: true });
    }
  };
}

UserFieldsMethods.propTypes = {
  change: T.func.isRequired,
  first_name: T.string,
  last_name: T.string,
  nickname: T.string,
  form: T.string,
};

export default UserFieldsMethods;
