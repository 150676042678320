import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import OfflineScreen from 'components/OfflineScreen';

const CSVImportContainer = ({ children, isOnline }) => (
  <>{isOnline ? children : <OfflineScreen />}</>
);

CSVImportContainer.propTypes = {
  isOnline: T.bool.isRequired,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  })
)(CSVImportContainer);
