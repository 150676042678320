import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { deleteCompany } from 'reducers/admin/companies';
import { openModal } from 'reducers/modals';
import { fetchCompanyProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import CompanyProfileBox from 'components/Admin/CompanyProfileBox';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';

const navLinks = [
  { link: '/admin/my-company/admins', label: <FormattedMessage id="general.admins" /> },
  { link: '/admin/my-company/user-roles', label: <FormattedMessage id="general.userRoles" /> },
  { link: '/admin/my-company/managed-farms', label: <FormattedMessage id="general.managedFarms" /> },
  { link: '/admin/my-company/owned-groups', label: <FormattedMessage id="general.ownedGroups" /> },
  { link: '/admin/my-company/edit', label: <FormattedMessage id="general.companyInformation" /> },
];

const labels = {
  setup: <FormattedMessage id="container.companyProfile.setup" />,
  setupProgress: <FormattedMessage id="container.companyProfile.setupProgress" />,
  setupComplete: <FormattedMessage id="general.setupComplete" />,
  basicInfoSaved: <FormattedMessage id="container.companyProfile.basicInfoSaved" />,
  assignPrimary: <FormattedMessage id="container.companyProfile.assignPrimary" />,
  primaryContact: <FormattedMessage id="general.role.primary" />,
};

class MyCompanyProfile extends Component {

  componentDidMount() {
    const { fetchCompanyProfile, authUser: { current_company: { id } } } = this.props;
    fetchCompanyProfile(`/admin/companies/${id}`).catch(toastResponseErrors);
  }

  progressData = () => {
    const { company: { is_pig_group_assigned, primary_contact_id } } = this.props;
    return {
      closeable: !!primary_contact_id && is_pig_group_assigned,
      headerText: labels.setupProgress,
      steps: [
        { text: labels.basicInfoSaved, done: true },
        { text: labels.assignPrimary, url: '/admin/my-company/assign-user-roles', done: !!primary_contact_id },
      ],
    };
  };

  deleteCompany = () => {
    const { router } = this.context;
    const { deleteCompany, authUser: { current_company } } = this.props;
    return deleteCompany(current_company.id)
      .then(() => {
        showToastrMessage('component.toastr.company.deleted');
        router.push('/admin/companies');
      })
      .catch(toastResponseErrors);
  };

  deleteCompanyConfirm = () => {
    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.deleteCompany" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteCompany.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.deleteCompany.warning" />}
        handleConfirm={this.deleteCompany}
      >
        <FormattedMessage id="component.modal.deleteCompany.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  render() {
    const { children, company, isLoading, authUser: { current_company } } = this.props;
    const steps = [
      { label: labels.setup, icon: 'fa fa-check', completed: true },
      { label: labels.primaryContact, icon: 'fa fa-user-male', completed: !!company.primary_contact_id },
      { label: labels.setupComplete, icon: 'fa fa-trophy', completed: !!company.primary_contact_id },
    ];

    return (
      <CompanyProfileBox
        companyId={current_company.id}
        company={company}
        isLoading={isLoading}
        steps={steps}
        navLinks={navLinks}
        onCompanyDelete={this.deleteCompanyConfirm}
        progressData={this.progressData()}
      >
        {children}
      </CompanyProfileBox>
    );
  }
}

MyCompanyProfile.contextTypes = {
  router: T.object,
};

MyCompanyProfile.propTypes = {
  deleteCompany: T.func.isRequired,
  fetchCompanyProfile: T.func.isRequired,
  openModal: T.func.isRequired,
  company: T.object.isRequired,
  isLoading: T.bool.isRequired,
  authUser: T.object.isRequired,
  children: T.object.isRequired,
};

export default connect(
  (state) => ({
    company: state.profiles.company.data,
    isLoading: state.profiles.company.isLoading,
    authUser: state.auth.user,
  }), {
    deleteCompany,
    openModal,
    fetchCompanyProfile,
  }
)(MyCompanyProfile);
