import React, { Component } from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
import getValue from 'lodash.get';
import omit from 'lodash.omit';
// translations
import { FormattedMessage } from 'react-intl';
// assets
import styles from './InputFile.module.scss';

const cn = classnames.bind(styles);

class InputFile extends Component {

  getReference = (ref) => {
    const { getRef } = this.props;
    this.input = ref;
    if (getRef) getRef(ref);
  };

  render() {
    const { className, label, explanatoryText, explanatorySubText, ...others } = this.props;

    return (
      <div className={cn('InputFile', className)}>
        <div className={cn('label-block')}>{label}</div>
        <div className={cn('input-field')}>
          <input
            value={getValue(this.input, 'files.0.name', '')}
            readOnly
            onClick={() => this.input.click()}
          />
          <div className={cn('label-box')} onClick={() => this.input.click()}>
            <FormattedMessage id="general.button.browse" />
          </div>
        </div>
        {explanatoryText && (
          <div className={cn('explanatory-text')}>
            {explanatoryText}
          </div>
        )}
        {explanatorySubText && (
          <div className={cn('explanatory-subtext')}>
            {explanatorySubText}
          </div>
        )}
        <input
          className={cn('file-type')}
          {...omit(others, ['getRef'])}
          ref={this.getReference}
          type="file"
        />
      </div>
    );
  }
}

InputFile.propTypes = {
  className: T.string,
  getRef: T.func,
  label: T.any,
  onChange: T.func,
  explanatoryText: T.node,
  explanatorySubText: T.node,
};

InputFile.defaultProps = {
  className: '',
  explanatorySubText: <FormattedMessage tagName="i" id="general.errorWillRemove" />,
};

export default InputFile;
