import React, { Component } from 'react';
import T from 'prop-types';
// components
import RolesFields from 'components/Admin/RolesFields';
import EmailCheckIcon from 'components/Admin/EmailCheckIcon';
import MessageBox from 'components/MessageBox';
import Field from 'components/Field';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
import { FormattedMessage } from 'react-intl';
import FormErrorsBox from 'components/FormErrorsBox';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import debounce from 'lodash.debounce';
import { langOptions } from 'utils/localizationHelper';
// styles
import './AdminFormFields.scss';

class AdminFormFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: false,
      status: null,
      isLoading: false,
    };
    this.checkEmailDebounced = debounce(this.checkEmail, 300);
  }

  validateEmail = (value) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(value);
  };

  checkEmail = (value) => {
    const { path } = this.props;
    if (this.validateEmail(value)) {
      this.setState({ isLoading: true });
      fetchFromAPI(path, { params: { email: value } })
        .then((response) => {
          const { status } = response.meta;
          this.setUserStatus(status);
          setTimeout(() => this.setState({ isLoading: false }), 500);
        })
        .catch((response) => {
          toastResponseErrors(response);
          this.setState({ isLoading: false });
        });
    } else {
      this.setUserStatus(null);
    }
  };

  setUserStatus = (status) => {
    const { setFormValidity, change } = this.props;
    this.setState({ status });
    change('user_status', status);
    if (setFormValidity) {
      const isValid = ['not_found', 'found', 'member'].includes(status);
      setFormValidity(isValid);
    }
  };

  emailOnChange = (event, value) => {
    const userEmail = value.toLowerCase();
    this.setState({ isValidEmail: this.validateEmail(userEmail) });
    this.checkEmailDebounced(userEmail);
  };

  render() {
    const { email, customErrors, roles, rolesTitle, form, hasLanguageField } = this.props;
    const { isValidEmail, status, isLoading } = this.state;

    const showForm = isValidEmail && (status !== 'already_admin');
    const showOnlyRoles = isValidEmail && ['found', 'member'].includes(status);

    return (
      <div className="CreateAdminFormFields">
        <Field name="user_status" component="input" type="hidden" />
        {customErrors && customErrors.length && (
        <div className="row">
          <div className="small-12 column">
            <FormErrorsBox
              includedFields={['user']}
              formName={form}
              customErrors={customErrors}
            />
          </div>
        </div>
        )}
        <div className="row">
          <div className="small-12 column">
            <label>
              <Field
                name="email"
                type="email"
                placeholderKey="general.typeEmail"
                label={<FormattedMessage id="general.emailAddress" />}
                onChange={this.emailOnChange}
                icon={(
                  <EmailCheckIcon
                    isLoading={isLoading}
                    hasWarning={!!email && !isValidEmail}
                    wasChecked={!!['not_found', 'found', 'member'].includes(status)}
                  />
                )}
              />
            </label>
            {email && !isValidEmail && (
              <MessageBox>
                <FormattedMessage
                  id="component.userFormFields.invalidEmail"
                  values={{
                    title: <FormattedMessage tagName="b" id="component.userFormFields.invalidEmail.title" />,
                  }}
                />
              </MessageBox>
            )}
            {isValidEmail && status === 'already_admin' && (
              <MessageBox type="alert">
                <FormattedMessage id="general.customErrors.adminEmail" />
              </MessageBox>
            )}
            {isValidEmail && ['found', 'member'].includes(status) && (
              <MessageBox type="success">
                <FormattedMessage
                  id="component.userFormFields.goodNews"
                  values={{
                    title: <FormattedMessage tagName="b" id="component.userFormFields.goodNews.title" />,
                  }}
                />
              </MessageBox>
            )}
          </div>
        </div>
        <div className={cn('animated fadeIn', { 'hide': !showForm })}>
          <div className={cn('animated fadeIn row', { 'hide': showOnlyRoles })}>
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  name="first_name"
                  label={<FormattedMessage id="component.userProfile.firstName" />}
                  placeholderKey="component.userProfile.firstName"
                />
              </label>
            </div>
            <div className="small-12 medium-6 column">
              <label>
                <Field
                  name="last_name"
                  label={<FormattedMessage id="component.userProfile.lastName" />}
                  placeholderKey="component.userProfile.lastName"
                />
              </label>
            </div>
          </div>

          {hasLanguageField && (
            <div className={cn('animated fadeIn row relative-displaying', { 'hide': showOnlyRoles })}>
              <div className="small-12 medium-6 column">
                <Field
                  name="language"
                  label={<FormattedMessage id="general.pageTitle.language" />}
                  component={SelectField}
                  options={langOptions}
                  valueKey="value"
                  labelKey="label"
                />
              </div>
            </div>
          )}

          {!!roles.length && (
            <RolesFields roles={roles} title={rolesTitle} />
          )}

        </div>
      </div>
    );
  }
}

AdminFormFields.defaultProps = {
  roles: [],
};

AdminFormFields.propTypes = {
  setFormValidity: T.func,
  change: T.func,
  path: T.string,
  customErrors: T.array,
  roles: T.array,
  rolesTitle: T.node,
  email: T.string,
  form: T.string,
  hasLanguageField: T.bool,
};

export default AdminFormFields;
