import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Subnavigation from 'components/Subnavigation';

const navLinks = [
  { label: <FormattedMessage id="general.pageTitle.antibioticTracking" />, link: '/treatments/antibiotic' },
  { label: <FormattedMessage id="general.pageTitle.treatmentFeedback" />, link: '/treatments/feedback' },
];

const Treatments = ({ children }) => (
  <div>
    <Subnavigation isSticky links={navLinks} className="small-12 column" />
    {children}
  </div>
);

Treatments.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};

export default Treatments;
