import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { setTableParams } from 'reducers/tableParams';
// components
import { FormattedMessage } from 'react-intl';
import CommitmentCard from 'components/CommitmentCard/CommitmentCard';
import DateLine from 'components/DateLine/DateLine';
import NothingBox from 'components/NothingBox';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import PaginationWrapper from 'components/PaginationWrapper';
// api
import { getPigGroupCommitments } from 'endpoints/pigGroups';
// hooks, utils
import { useDropdownActions } from 'hooks/useDropdownContext';
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import moment from 'moment';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

const PigGroupCommitmentsPanel = ({
  getCount,
  openModal,
  optionsGetter,
  panelLink,
  pigGroupId,
  setTableParams,
  tableName,
  tableParams,
  title,
}) => {
  const {
    data: { resources, isLoading, meta: { total } },
    fetchData,
    onPageChange,
    onPerPageChange,
    setDataByIndex,
  } = useDataTable((reqParams) => getPigGroupCommitments(pigGroupId, reqParams), {
    callback: (response) => getCount?.(response?.meta?.total || 0),
    setTableParams: (params) => setTableParams(tableName, params),
    tableParams,
  });
  const { openDropdown } = useDropdownActions();
  const { page, per_page } = tableParams;

  useEffect(() => {
    fetchData({ ...tableParams, page: 1 });
  }, []);

  return (
    <div className="row centered pv-20">
      <div className="small-12 column">
        <Panel>
          <Panel.Heading title={title}>
            {panelLink}
          </Panel.Heading>
          <Panel.Body className="ph-20">
            <Preloader isActive={isLoading} />
            {resources.map((commitment, idx) => {
              const options = optionsGetter(commitment, openModal, setDataByIndex, idx);
              return (
                <Fragment key={commitment.id}>
                  {(idx === 0 || (formatDate(commitment.arrive_at) !== formatDate(resources[idx - 1].arrive_at))) && (
                    <DateLine date={formatDate(commitment.arrive_at)} />
                  )}
                  <CommitmentCard
                    className="mt-10 mb-10"
                    onDropdownOpen={(e) => openDropdown(e, options)}
                    commitment={commitment}
                    options={options}
                    mobileOptions={options.filter((option) => option.showOnMobile)}
                    openModal={openModal}
                  />
                </Fragment>
              );
            })}
            <NothingBox display={!resources.length} isLoading={isLoading}>
              <h2 className="lighter semibold">
                <FormattedMessage id="general.nothingBox.noLoads" />
              </h2>
            </NothingBox>
            {!!resources.length && (
              <PaginationWrapper
                onlyBottom
                totalItems={total}
                currentPage={page}
                onPageChange={onPageChange}
                perPage={per_page}
                onPerPageChange={onPerPageChange}
              />
            )}
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
};

PigGroupCommitmentsPanel.propTypes = {
  openModal: T.func.isRequired,
  tableName: T.string.isRequired,
  tableParams: T.object.isRequired,
  setTableParams: T.func.isRequired,
  optionsGetter: T.func.isRequired,
  getCount: T.func,
  panelLink: T.node,
  title: T.oneOfType([T.string, T.node]).isRequired,
  pigGroupId: T.oneOfType([T.string, T.number]).isRequired,
};

export default connect(
  (state, props) => ({
    tableParams: tableParamsSelector(state, props.tableName),
  }), {
    setTableParams,
    openModal,
  },
)(PigGroupCommitmentsPanel);
