import React from 'react';
import T from 'prop-types';
// utils
import renderIf from 'utils/renderIf';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
// styles
import './NothingBox.scss';

const NothingBox = ({ display, isLoading, children, search, filter, itemsName }) => (
  renderIf(display && !isLoading)(
    <div className={cn('nothing-box no-border animated fadeIn', { 'fadeOut': isLoading })}>
      <div className="fa fa-info-circle nothing-box-icon" />
      {renderIf(search)(
        <FormattedMessage tagName="h1" id={`component.nothingBox.search.${itemsName}`} />
      )}
      {renderIf(!search && filter)(
        <FormattedMessage
          tagName="h1"
          id={`component.nothingBox.filter.${itemsName}.${filter}`}
        />
      )}
      {renderIf(!search && !filter)(
        children
      )}
    </div>
  )
);

NothingBox.propTypes = {
  display: T.bool,
  isLoading: T.bool,
  children: T.any,
  filter: T.oneOfType([T.string, T.node]),
  search: T.string,
  itemsName: T.string,
};

NothingBox.defaultProps = {
  display: false,
  itemsName: 'items',
};

export default NothingBox;
