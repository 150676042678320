import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setSearch, fetchList } from 'reducers/mobileListPicker';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import MobileListPickerComponent from './MobileListPickerComponent';

class MobileListPicker extends Component {

  state = {
    alreadySelectedData: this.props.selectedData,
  };

  goBack = () => {
    const { closePortal, backLinkOptions } = this.props;
    if (backLinkOptions.onClick) backLinkOptions.onClick();
    if (closePortal) closePortal();
  };

  onRightButtonClick = () => {
    const { rightButtonOptions, closePortal } = this.props;
    if (rightButtonOptions.onClick) rightButtonOptions.onClick();
    if (closePortal) closePortal();
  };

  onSearchChange = (search) => {
    const { endpoint, setSearch, fetchList, searchKey } = this.props;
    if (!endpoint) return setSearch(search);
    return fetchList(endpoint, { [searchKey]: search });
  };

  isRowSelected = (row) => {
    const { selectedData, idKey } = this.props;
    return !!selectedData.find((item) => item[idKey] === row.id);
  };

  isRowDisabled = (row) => {
    const { idKey } = this.props;
    const { alreadySelectedData } = this.state;
    return !!alreadySelectedData.find((item) => item[idKey] === row.id);
  };

  isRightButtonDisabled = () => {
    const { selectedData, rightButtonOptions } = this.props;
    if (rightButtonOptions && typeof rightButtonOptions.disableOnEmptyList === 'boolean') return !selectedData.length;
    const { alreadySelectedData } = this.state;
    return selectedData.length <= alreadySelectedData.length;
  };

  handleResources = () => {
    const { endpoint, resources, nameKey, params: { search } } = this.props;
    if (endpoint) return resources;
    return resources.filter((resource) => resource[nameKey].toLowerCase().includes(search.toLowerCase()));
  };

  render() {
    const { rightButtonOptions, title, backLinkOptions, rowRenderer, mode, isLoading, params: { search } } = this.props;
    const rightButtonDisabled = this.isRightButtonDisabled();
    const resources = this.handleResources();

    return (
      <MobileListPickerComponent
        title={title}
        headerLeftIcon={backLinkOptions.icon}
        headerLeftUrl={backLinkOptions.url}
        headerRightContent={rightButtonOptions && (
          <Button disabled={rightButtonDisabled} primary small onClick={this.onRightButtonClick}>
            <FormattedMessage id={`general.button.${rightButtonOptions.label}`} />
          </Button>
        )}
        onBack={this.goBack}
        onSearchChange={this.onSearchChange}
        search={search}
        isLoading={isLoading}
      >
        {resources.map((resource) => (
          rowRenderer(resource, mode, {
            isSelected: this.isRowSelected,
            isDisabled: this.isRowDisabled,
          })
        ))}
      </MobileListPickerComponent>
    );
  }
}

MobileListPicker.contextTypes = {
  router: T.object.isRequired,
};

MobileListPicker.defaultProps = {
  backLinkOptions: {},
  mode: 'select',
  idKey: 'id',
  nameKey: 'name',
  searchKey: 'search',
};

MobileListPicker.propTypes = {
  backLinkOptions: T.object,
  title: T.oneOfType([T.string, T.node]),
  rightButtonOptions: T.object,
  idKey: T.string,
  nameKey: T.string,
  endpoint: T.string,
  mode: T.string,
  rowRenderer: T.func.isRequired,
  closePortal: T.func,
  // connect
  resources: T.array.isRequired,
  isLoading: T.bool.isRequired,
  selectedData: T.array.isRequired,
  params: T.object.isRequired,
  setSearch: T.func.isRequired,
  fetchList: T.func.isRequired,
  searchKey: T.string,
};

export default connect(
  (state) => ({
    resources: state.mobileListPicker.dataList.resources,
    isLoading: state.mobileListPicker.dataList.isLoading,
    params: state.mobileListPicker.dataList.params,
    selectedData: state.mobileListPicker.selected,
  }), { setSearch, fetchList }
)(MobileListPicker);
