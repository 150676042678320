/* eslint-disable react/prop-types */
import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import { CustomColumn } from 'components/DataTable/Columns/index';
// utils
import moment from 'moment';
import cn from 'classnames';

const renderGroupIDColumn = ({ name, error }) => {
  return (
    <CustomColumn
      label={<FormattedMessage id="component.dataTable.headers.groupId" />}
      className={cn({ 'highlighted': error === 'name' })}
    >
      {name || '-'}
    </CustomColumn>
  );
};

const renderFarmColumn = ({ farm_name, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.dataTable.headers.farmName" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {farm_name || '-'}
  </CustomColumn>
);

const renderStartDateColumn = (status) => ({ start_date, created_on, error }) => {
  const dateValue = status === 'create' ? created_on : start_date;
  return (
    <CustomColumn
      label={<FormattedMessage id="general.creationDate" />}
      className={cn({ 'highlighted': error === 'start_date' })}
    >
      {dateValue ? moment(dateValue).format('MM/DD/YYYY') : '-'}
    </CustomColumn>
  );
};

const groupColumns = (status) => ([
  { label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 140px',
    renderer: renderGroupIDColumn, sortKey: 'name' },
  { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
    renderer: renderFarmColumn, sortKey: 'farm_name' },
  { label: <FormattedMessage id="general.creationDate" />, flex: '1 1 90px',
    renderer: renderStartDateColumn(status), sortKey: 'phone' },
]);

export default groupColumns;
