import { handleActions, createAction } from 'redux-actions';
// utils
import pickBy from 'lodash.pickby';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------

const defaultParams = {
  page: 1,
  per_page: 25,
  search: '',
};

const initialState = {
  isLoading: false,
  resources: [],
  params: defaultParams,
  meta: {},
};

const FETCH_MOBILE_CHECKIN = 'checkins/FETCH_MOBILE_CHECKIN';
const [FETCH_MOBILE_CHECKIN_PENDING, FETCH_MOBILE_CHECKIN_FULFILLED, FETCH_MOBILE_CHECKIN_REJECTED] = [
  `${FETCH_MOBILE_CHECKIN}_PENDING`,
  `${FETCH_MOBILE_CHECKIN}_FULFILLED`,
  `${FETCH_MOBILE_CHECKIN}_REJECTED`
];

// ------------------------------------
// Action creators
// ------------------------------------

export const fetchMobileCheckin = (params, isLoadMore = false) => createAction(FETCH_MOBILE_CHECKIN)(
  fetchFromAPI('/check_ins/farms', { params: pickBy(params) })
    .then((response) => ({ ...response, params, isLoadMore }))
);

// ------------------------------------
// Reducer
// ------------------------------------

export default handleActions({

  // FETCH_MOBILE_CHECKIN
  [FETCH_MOBILE_CHECKIN_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_MOBILE_CHECKIN_FULFILLED]: (state, action) => {
    const { payload } = action;
    const actualState = payload.isLoadMore ? state : initialState;
    delete payload.isLoadMore;

    return {
      ...actualState,
      ...payload,
      resources: [
        ...actualState.resources,
        ...payload.resources,
      ],
      isLoading: false,
    };
  },
  [FETCH_MOBILE_CHECKIN_REJECTED]: (state, action) => ({
    ...state,
    error: action.payload.errors,
    isLoading: false,
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),

}, initialState);
