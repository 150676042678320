import React from 'react';
import T from 'prop-types';
// components
import ActivityMentions from 'components/Activities/ActivityMentions';
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import Avatar from 'components/Avatar/Avatar';
import { FormattedMessage } from 'react-intl';
// styles
import './FarmFeedFarmSelect.scss';

const FarmFeedFarmSelect = ({ onChange, selectedFarm }) => {
  let farmInput = null;

  const optionRenderer = (option) => (
    <div className="farm-suggestion-item">
      <Avatar type="farm" avatarSize={40} iconSize={16} />
      <div className="semibold">{option.label}</div>
    </div>
  );

  return (
    <div className="FarmFeedFarmSelect">
      <div className="farm-line">
        <ActivityMentions farm={selectedFarm.item} />
      </div>
      <div className="farm-search">
        <div onClick={() => farmInput.focus()} className="select-badge">
          <FormattedMessage id="general.farms" />
        </div>
        <AsyncSelect
          arrowRenderer={null}
          optionRenderer={optionRenderer}
          selected={selectedFarm}
          placeholder={<FormattedMessage id="general.searchBy.farms" />}
          noInputMargin
          className="farm-select"
          optionsPath="/farms/search"
          optionsKeys={{ value: 'id', label: 'name' }}
          onChange={onChange}
          clearable
          getRef={(input) => { farmInput = input; }}
          openOnFocus
        />
      </div>
    </div>
  );
};

FarmFeedFarmSelect.propTypes = {
  onChange: T.func,
  selectedFarm: T.object,
};

export default FarmFeedFarmSelect;
