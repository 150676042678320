import React from 'react';
import T from 'prop-types';
import Button from 'components/Button';
import cn from 'classnames';
import './MobileRow.scss';
import { FormattedMessage } from 'react-intl';

const MobileSymptomRow = ({ name, actionBtnType, disabledRow, onRowClick, selectedRow }) => (
  <div
    className={cn('MobileRow', { 'no-events': disabledRow, 'select-row': actionBtnType === 'button' })}
    onClick={onRowClick}
  >
    <div className={cn({ 'disable-for-select': disabledRow })}>
      {name}
    </div>
    {actionBtnType === 'button' && (
      <Button disabled={selectedRow} className="select-btn" small primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    )}
    {actionBtnType === 'checkbox' && (
      <div className={cn('icon', { 'selected': selectedRow })}>
        <i className="checked fa fa-check-circle-bts" />
        <i className="unchecked fa fa-radio-unchecked" />
      </div>
    )}
  </div>
);

MobileSymptomRow.propTypes = {
  actionBtnType: T.string.isRequired,
  name: T.string.isRequired,
  disabledRow: T.bool.isRequired,
  onRowClick: T.func.isRequired,
  selectedRow: T.bool.isRequired,
};

export default MobileSymptomRow;
