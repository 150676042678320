import React from 'react';
import T from 'prop-types';
// components
import { ReactComponent as ServicesIcon } from 'assets/svg/circles-grid.svg';
import { ReactComponent as BeamerIcon } from 'assets/svg/beamer-icon.svg';
import { FormattedMessage } from 'react-intl';
import Dropdown from 'components/Dropdown';
// utils
import useBeamerUserUpdate from 'utils/hooks/useBeamerUserUpdate';
import { IntercomAPI } from 'react-intercom';
// constants
import { INTERCOM_APP_ID, BEAMER_APP_ID } from 'utils/constants';
// styles
import styles from './ServicesDropdown.module.scss';

const services = [
  {
    id: 'Intercom',
    label: <FormattedMessage id="general.support" />,
    icon: <i className="fa fa-ep-question-circle" />,
    onClick: () => IntercomAPI('show'),
    isHidden: !INTERCOM_APP_ID,
  },
  {
    id: 'Beamer',
    label: <FormattedMessage id="general.whatIsNew" />,
    icon: <BeamerIcon height={24} />,
    isHidden: !BEAMER_APP_ID,
  },
];

const ServicesDropdown = ({ className, user }) => {
  useBeamerUserUpdate(user);

  if (services.every(({ isHidden }) => isHidden)) {
    return null;
  }

  return (
    <div className={className}>
      <Dropdown
        className={styles['help-dropdown']}
        closeOnClick
        trigger={<ServicesIcon className={styles['services-icon']} height={24} />}
      >
        <div className={styles['help-menu']}>
          {services.map(({ label, isHidden, icon, id, onClick }, index) => (isHidden ? null : (
            <div id={id} onClick={onClick} className={styles['help-menu-option']} key={index}>
              <span className={styles['option-icon']}>{icon}</span>
              <span className={styles['option-label']}>{label}</span>
            </div>
          )))}
        </div>
      </Dropdown>
    </div>
  );
};

ServicesDropdown.propTypes = {
  user: T.object,
};

ServicesDropdown.propTypes = {
  className: T.string.isRequired,
};

export default ServicesDropdown;
