import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// assets
import './ProgressChecklist.scss';

const ProgressChecklist = ({ steps, className }) => (
  <div className={cn('ProgressChecklist', className)}>
    {steps.map((step, index) => (
      <div key={index} className={cn('step', { 'completed': step.completed })}>
        <div className="icon">
          <i className={step.completed ? 'fa fa-check' : step.icon} />
          <div className="divider" />
        </div>
        <div className="step-label">
          {step.label}
        </div>
      </div>
    ))}
  </div>
);

ProgressChecklist.defaultProps = {
  className: '',
};

ProgressChecklist.propTypes = {
  steps: T.array.isRequired,
  className: T.string,
};

export default ProgressChecklist;
