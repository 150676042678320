import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import SVRComment from 'components/SVRComment';
// utils
import { animateScroll } from 'react-scroll';
import classnames from 'classnames/bind';
// styles
import styles from './QuestionViewRow.module.scss';

const cn = classnames.bind(styles);

const labelKeys = {
  fail: 'general.fail',
  pass: 'general.pass',
  not_available: 'general.notAvail',
};

const QuestionViewRow = ({ question }, { router: { location: { query: { mentionCommentId } } } }) => {
  const mentionedCommentRef = useRef(null);

  useEffect(() => {
    // TODO: make a research about scroller.scrollTo(elem id)
    if (mentionedCommentRef?.current) {
      const { y, height } = mentionedCommentRef.current.getBoundingClientRect();
      animateScroll.scrollTo(y - height, { containerId: 'svr-content-wrapper' });
    }
  }, [mentionedCommentRef]);

  return (
    <div className={cn('question-row', { flagged: question.answer?.is_flagged })}>
      <div className={cn('header')}>
        {/* info */}
        <div className={cn('info-column')}>
          <div className={cn('icon', question?.answer?.value)}>
            {question?.answer?.value === 'fail' ?
              <i className="fa fa-times" /> :
              <i className="fa fa-check" />
          }
          </div>
          <span className={cn('question')}>
            {question.label}
          </span>
        </div>
        <div className={cn('status', question?.answer?.value)}>
          {question?.answer?.value === 'pass' && <i className="fa fa-check mr-10" />}
          {question?.answer?.value === 'fail' && <i className="fa fa-times mr-10" />}
          <FormattedMessage id={labelKeys[question.answer.value]} />
        </div>
      </div>
      {!!question?.answer?.comments?.length && (
      <div className={cn('comments')}>
        {question?.answer?.comments?.map((comment, idx) => (
          <div
            className={cn('comment-wrapper', { isMention: +mentionCommentId === comment.id })}
            ref={+mentionCommentId === comment.id ? mentionedCommentRef : undefined}
            key={idx}
          >
            <SVRComment className={idx === 0 ? 'pt-25' : ''} comment={comment}  />
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

QuestionViewRow.contextTypes = {
  router: T.object.isRequired
};

QuestionViewRow.propTypes = {
  question: T.object.isRequired
};

export default QuestionViewRow;
