import { barnsCardName, farmsCardName, groupsCardName, usersCardName } from 'constants/csvImport';

export function getCardPropsIfOneSelected(currentCard, selectedCard) {
  const isSelected = selectedCard.type === currentCard.type;
  const isSuggested = currentCard.type === farmsCardName;
  if (selectedCard.type === usersCardName) {
    return {
      isSelected,
      isSuggested,
      isDisabled: [barnsCardName, groupsCardName].includes(currentCard.type),
    };
  }
  if (selectedCard.type === groupsCardName) {
    return {
      isSelected,
      isSuggested,
      isDisabled: [barnsCardName, usersCardName].includes(currentCard.type),
    };
  }
  if (selectedCard.type === barnsCardName) {
    return {
      isSelected,
      isSuggested,
      isDisabled: [groupsCardName, usersCardName].includes(currentCard.type),
    };
  }
  return {
    isSelected,
  };
}

export function getCardProps(currentCard, selectedCards) {
  if (selectedCards.length === 2) {
    const isSelected = !!selectedCards.find(({ type }) => type === currentCard.type);
    return {
      isSelected,
      isDisabled: !isSelected,
    };
  }
  if (selectedCards.length === 1) {
    return getCardPropsIfOneSelected(currentCard, selectedCards[0]);
  }
  return {};
}

export function getBackPathname(type, entityId) {
  switch (type) {
    case usersCardName:
      return '/admin/users';
    case barnsCardName:
      return `/admin/farms/${entityId}/barns`;
    case groupsCardName:
      return '/groups';
    case farmsCardName:
      return '/admin/farms';
    default:
      return '/admin/users';
  }
}
