import React from 'react';
import T from 'prop-types';
// redux-form
import { connect } from 'react-redux';
import { formValueSelector, change, reduxForm } from 'redux-form';
// components
import CreateAdminFormFields from 'components/ReduxFormFields/AdminFormFields/AdminFormFields';
import Button from 'components/Button';
import { Link } from 'react-router';

const SuperAdminForm = ({ handleSubmit, pristine, submitting, status, ...props }) => {
  const disabledBtn = pristine || submitting || status === 'already_admin' || !props.email;

  return (
    <form onSubmit={handleSubmit}>

      <CreateAdminFormFields path="/invites/super_admins" {...props} />

      <div className="row margin-top-2">
        <div className="small-12 column">
          <Button primary className="wider" type="submit" disabled={disabledBtn}>
            {status === 'found' ? 'Invite User' : 'Create Super Admin'}
          </Button>
          <Link to="/super-admin/admin-users" className="button btn-link margin-left-1">Cancel</Link>
        </div>
      </div>
    </form>
  );
};

const selector = formValueSelector('create-super-admin');
const formComponent = reduxForm({
  form: 'create-super-admin',
})(SuperAdminForm);

SuperAdminForm.propTypes = {
  handleSubmit: T.func.isRequired,
  change: T.func.isRequired,
  email: T.string,
  status: T.string,
  first_name: T.string,
  last_name: T.string,
  pristine: T.bool,
  submitting: T.bool,
};

export default connect((state) => ({
  email: selector(state, 'email'),
  status: selector(state, 'user_status'),
  first_name: selector(state, 'first_name'),
  last_name: selector(state, 'last_name'),
}), { change })(formComponent);
