import React from 'react';
import T from 'prop-types';
// components
import Link from 'components/Link';
// styles
import cn from 'classnames';

const SidebarItem = ({ item }, { isTablet }) => (
  <li className={cn({ hide: item.hide })}>
    <Link
      to={item.link}
      activeClassName="active"
      className={cn({
        'active': item.isActive,
        'not-highlighted': item.isNotHighlighted,
        'action-required': item.action_required,
      })}
      onClick={item.onClick}
    >
      {item.icon && <span className={`item-icon ${item.icon}`} />}
      {item.iconComponent}
      <span className="item-name">{item.name}</span>
      <span className={cn('sidebar-tooltip', { isTablet, showTooltip: item?.showTooltip })}>
        {item.name}
      </span>
      {Boolean(item.counter) && (
        <span className={cn('counter-badge', { 'yellow-theme': item.counterTheme === 'yellow' })}>
          {item.counter}
        </span>
      )}
      {item.filterIndex && (
        <span className="user-filter-badge">
          {item.filterIndex}
        </span>
      )}
    </Link>
  </li>
);

SidebarItem.propTypes = {
  item: T.object.isRequired,
};

SidebarItem.contextTypes = {
  isTablet: T.bool.isRequired,
};

export default SidebarItem;
