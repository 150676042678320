import { handleActions, createAction } from 'redux-actions';
import {
  getSource,
  updateSource as updateExternalSource,
  enableSource as enableExternalSource,
  disableSource as disableExternalSource
} from 'endpoints/sources';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  resource: null,
  isLoading: false,
};

// ------------------------------------
// Action Types
// ------------------------------------

const FETCH_SOURCE = 'sourceProfile/FETCH_SOURCE';
const [FETCH_SOURCE_PENDING, FETCH_SOURCE_FULFILLED, FETCH_SOURCE_REJECTED] = [
  `${FETCH_SOURCE}_PENDING`,
  `${FETCH_SOURCE}_FULFILLED`,
  `${FETCH_SOURCE}_REJECTED`,
];

const UPDATE_SOURCE = 'sourceProfile/UPDATE_SOURCE';
const [UPDATE_SOURCE_PENDING, UPDATE_SOURCE_FULFILLED, UPDATE_SOURCE_REJECTED] = [
  `${UPDATE_SOURCE}_PENDING`,
  `${UPDATE_SOURCE}_FULFILLED`,
  `${UPDATE_SOURCE}_REJECTED`,
];

const ENABLE_SOURCE = 'sourceProfile/ENABLE_SOURCE';
const [ENABLE_SOURCE_PENDING, ENABLE_SOURCE_FULFILLED, ENABLE_SOURCE_REJECTED] = [
  `${ENABLE_SOURCE}_PENDING`,
  `${ENABLE_SOURCE}_FULFILLED`,
  `${ENABLE_SOURCE}_REJECTED`,
];

const DISABLE_SOURCE = 'sourceProfile/DISABLE_SOURCE';
const [DISABLE_SOURCE_PENDING, DISABLE_SOURCE_FULFILLED, DISABLE_SOURCE_REJECTED] = [
  `${DISABLE_SOURCE}_PENDING`,
  `${DISABLE_SOURCE}_FULFILLED`,
  `${DISABLE_SOURCE}_REJECTED`,
];

// ------------------------------------
// Actions
// ------------------------------------
export const fetchSource = (id) => createAction(FETCH_SOURCE)(getSource(id));

export const updateSource = (id, data) => createAction(UPDATE_SOURCE)(updateExternalSource(id, data));

export const enableSource = (id) => createAction(ENABLE_SOURCE)(enableExternalSource(id));

export const disableSource = (id) => createAction(DISABLE_SOURCE)(disableExternalSource(id));

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // FETCH_SOURCE
  [FETCH_SOURCE_PENDING]: (state) => ({
    ...state,
    ...initialState,
    isLoading: true,
  }),
  [FETCH_SOURCE_FULFILLED]: (state, action) => ({
    resource: action.payload,
    isLoading: false,
  }),
  [FETCH_SOURCE_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  // UPDATE_SOURCE
  [UPDATE_SOURCE_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [UPDATE_SOURCE_FULFILLED]: (state, action) => ({
    resource: action.payload,
    isLoading: false,
  }),
  [UPDATE_SOURCE_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  // ENABLE_SOURCE
  [ENABLE_SOURCE_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [ENABLE_SOURCE_FULFILLED]: (state, action) => ({
    resource: action.payload,
    isLoading: false,
  }),
  [ENABLE_SOURCE_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  // DISABLE_SOURCE
  [DISABLE_SOURCE_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [DISABLE_SOURCE_FULFILLED]: (state, action) => ({
    resource: action.payload,
    isLoading: false,
  }),
  [DISABLE_SOURCE_REJECTED]: (state) => ({
    ...state,
    isLoading: false,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
