import React from 'react';
import T from 'prop-types';
// components
import FieldWrapper from '../FieldWrapper';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';
// hooks
import { useIntl } from 'react-intl';

const DatePickerField = ({
  input,
  isDisabled = false,
  label,
  meta,
  required,
  horizontal,
  ...others
}) => {
  const { formatMessage } = useIntl();
  return (
    <FieldWrapper
      meta={meta}
      label={label}
      required={!input.value && required}
      horizontal={horizontal}
    >
      <SingleDatePicker
        {...input}
        {...others}
        date={input.value || null}
        disabled={isDisabled}
        onDateChange={input.onChange}
        inputLabel="MM/DD/YYYY"
        placeholder={formatMessage({ id: 'general.selectDate' })}
      />
    </FieldWrapper>
  );
};

DatePickerField.propTypes = {
  input: T.object.isRequired,
  meta: T.object.isRequired,
  label: T.oneOfType([T.string, T.node]).isRequired,
  isDisabled: T.bool,
  required: T.bool,
  horizontal: T.bool,
};

export default DatePickerField;
