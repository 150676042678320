import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose, withProps } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { fetchCompanyProfile, setCompanyProfile } from 'reducers/profiles';
import { fetchData, resetResources, setDataItem } from 'reducers/dataTable';
// components
import { Link } from 'react-router';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import CompanyFarmsRolesManageTable from
  'components/Admin/Companies/CompanyFarmRolesManage/CompanyFarmsRolesManageTable';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import RolesManageFixedHeader from 'components/Admin/RolesManageFixedHeader/RolesManageFixedHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import TableFilter from 'components/TableFilter';
import PaginationWrapper from 'components/PaginationWrapper';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
import { fetchFromAPI } from 'utils/api';
// styles
import styles from '../RolesManage.module.scss';
import { showToastrMessage } from 'utils';

const cn = classnames.bind(styles);

const tableFilters = [
  { label: <FormattedMessage id="general.allFarms" />, value: '' },
  { label: <FormattedMessage id="general.activeFarms" />, value: 'active' },
  { label: <FormattedMessage id="general.disabledFarms" />, value: 'disabled' },
];

const headerColumns = [
  { flex: '1 1 120px', name: <FormattedMessage id="component.dataTable.headers.farmName" />, sortField: 'name' },
  { flex: '1 1 120px', name: <FormattedMessage id="general.role.managementCo" />, sortField: 'is_company_manager' },
];

class CompanyFarmsManage extends Component {

  componentDidMount() {
    const { fetchData, reqParams, fetchCompanyProfile } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
    fetchCompanyProfile().catch(toastResponseErrors);
  }

  componentWillUnmount() {
    this.props.resetResources();
  }

  onFilterChange = (filter) => {
    const { fetchData, reqParams } = this.props;
    let activeValue = '';
    if (filter) activeValue = filter === 'active';
    fetchData({ ...reqParams, page: 1, active: activeValue, filter });
  };

  handleRoleChanged = (farmId, checked) => {
    if (checked) return this.createUserFarmRole(farmId);
    return this.removeUserFarmRole(farmId);
  };

  createUserFarmRole = (farmId) => {
    const { id, company, setCompanyProfile, setDataItem } = this.props;
    fetchFromAPI(`/admin/companies/${id}/farm_roles`, { method: 'post', body: JSON.stringify({ id: farmId }) })
      .then(({ resource }) => {
        setDataItem(resource);
        if (!company.is_farm_assigned) {
          setCompanyProfile({
            ...company,
            is_farm_assigned: true,
            status: company.primary_contact_id ? 'active' : 'incomplete',
          });
        }
      })
      .catch(toastResponseErrors);
  };

  removeUserFarmRole = (farmId) => {
    const { id, fetchCompanyProfile, setDataItem } = this.props;
    fetchFromAPI(`/admin/companies/${id}/farm_roles/${farmId}`, { method: 'delete' })
      .then(({ resource }) => {
        setDataItem(resource);
        fetchCompanyProfile();
      })
      .catch(toastResponseErrors);
  };

  revokeAllAccess = () => {
    const { fetchData, reqParams, id, company, setCompanyProfile } = this.props;
    fetchFromAPI(`/admin/companies/${id}/farm_roles/destroy_all`, { method: 'delete' })
      .then(() => {
        showToastrMessage('component.toastr.roles.revoked');
        setCompanyProfile({ ...company, is_farm_assigned: false, status: 'incomplete' });
        fetchData(reqParams);
      });
  };

  render() {
    const { company, isCompanyLoading, resources, isLoading, meta, reqParams: { page, per_page, filter, search, sort },
      id, onSearchChange, onSortChange, onPageChange, onPerPageChange, route: { type } } = this.props;
    const activeFilter = filter && tableFilters.find(({ value }) => value === filter).label;

    // check, if current page is MyCompanyFarmsManage then link to My Company , else - Other Companies
    const managedFarmsLink = type === 'myCompanyFarmsManage' ?
      '/admin/my-company/managed-farms' :
      `/admin/companies/${id}/managed-farms`;
    return (
      <div className={cn('roles-manage')}>
        <CompanyProfileHeader
          company={company}
          isLoading={isCompanyLoading}
          actualCompanyId={id}
          isSticky
        >
          {type === 'companyFarmsManage' && (
            <Button light className="mr-10 show-for-large" onClick={this.revokeAllAccess}>
              <i className="fa fa-times-circle mr-5" />
              <FormattedMessage id="general.button.revokeAllRoles" />
            </Button>
          )}

          <Link to={managedFarmsLink} className="button primary wider show-for-large">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </CompanyProfileHeader>

        <RolesManageFixedHeader
          className="show-for-large"
          panelTitle={<FormattedMessage id="general.managedFarms" />}
          isEmptyTable={!resources.length}
          search={search}
          onSearchChange={onSearchChange}
          sort={sort}
          onSortChange={onSortChange}
          headerColumns={headerColumns}
        />

        <section className={cn('roles-manage-section')}>
          <div className="small-12 column">
            <Panel>
              <Panel.Heading title={<FormattedMessage id="general.managedFarms" />} className="hide-for-large">
                <SearchBox initialValue={search} onChange={onSearchChange} />
              </Panel.Heading>

              <Panel.Body className={cn('roles-manage-body')}>
                <TableFilter
                  filters={tableFilters}
                  onFilterChange={this.onFilterChange}
                  activeFilter={filter}
                  className="hide-for-large"
                />

                <Preloader isActive={isLoading} />

                <PaginationWrapper
                  onlyBottom
                  totalItems={meta.total}
                  currentPage={page}
                  onPageChange={onPageChange}
                  perPage={per_page}
                  onPerPageChange={onPerPageChange}
                >
                  <CompanyFarmsRolesManageTable
                    isCompanyTenant={type === 'myCompanyFarmsManage'}
                    data={resources}
                    isLoading={isLoading}
                    handleRoleChanged={this.handleRoleChanged}
                    company={company}
                    search={search}
                    filter={activeFilter}
                  />
                </PaginationWrapper>
              </Panel.Body>
            </Panel>
          </div>
        </section>

        <StickyFooter className="hide-for-large">
          <Link to={managedFarmsLink} className="button primary big-button">
            <FormattedMessage id="general.button.IamDone" />
          </Link>
        </StickyFooter>
      </div>
    );
  }
}

CompanyFarmsManage.propTypes = {
  fetchData: T.func.isRequired,
  setCompanyProfile: T.func.isRequired,
  fetchCompanyProfile: T.func.isRequired,
  setDataItem: T.func.isRequired,
  resetResources: T.func.isRequired,
  route: T.object.isRequired,
  company: T.object.isRequired,
  isCompanyLoading: T.bool.isRequired,
  resources: T.array.isRequired,
  meta: T.object.isRequired,
  reqParams: T.object.isRequired,
  isLoading: T.bool.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  id: T.number.isRequired
};

const enhance = compose(
  connect((state, { route: { type }, params: { id } }) => ({
    resources: state.dataTable[type].resources,
    reqParams: state.dataTable[type].params,
    meta: state.dataTable[type].meta,
    isLoading: state.dataTable[type].isLoading,
    company: state.profiles.company.data,
    isCompanyLoading: state.profiles.company.isLoading,
    id: +(id || state.auth.user.current_company.id)
  }), {
    fetchData,
    fetchCompanyProfile,
    setCompanyProfile,
    resetResources,
    setDataItem,
  }),
  withProps(({ id, route: { type, }, fetchData, fetchCompanyProfile,
    resetResources, setCompanyProfile, setDataItem }) => ({
    setDataItem: (data) => setDataItem(data, type),
    setCompanyProfile: (data) => setCompanyProfile(data),
    resetResources: () => resetResources(type),
    fetchData: (query) => fetchData(`/admin/companies/${id}/farm_roles/profile_index`, type, query),
    fetchCompanyProfile: () => fetchCompanyProfile(`/admin/companies/${id}`),
  })),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(CompanyFarmsManage);
