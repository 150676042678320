import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage, useIntl } from 'react-intl';
// utils
import cn from 'classnames';
import moment from 'moment';
// styles
import './TenantNotificationBox.scss';

const renderExpirationMessage = (isTenantDeactivated, isExpired, daysToExpire) => {
  if (isExpired) return (
    <div>
      <i className="fa attention-icon fa-exclamation-triangle-bts" />
      <FormattedMessage id="general.yourTrialHasExpired" values={{ b: (text) => <b>{text}</b> }} />
    </div>
  );

  if (isTenantDeactivated) return (
    <div>
      <i className="fa attention-icon fa-exclamation-triangle-bts" />
      <FormattedMessage id="general.status.deactivated" />
    </div>
  );

  return (
    <div>
      <FormattedMessage
        id="general.yourTrialExpires"
        values={{
          supportLink: <a className="support-link" href="mailto:support@everypig.com">support@everypig.com</a>,
          daysCount: daysToExpire,
          b: (text) => <b>{text}</b>,
        }}
      />
    </div>
  );
};

const TenantNotificationBox = ({ onClose, tenant }) => {
  const { formatMessage } = useIntl();
  const { expires_at, is_expired, tenant_status } = tenant;
  const daysToExpire = expires_at
    ? moment(expires_at).diff(new Date(), 'days')
    : formatMessage({ id: 'general.notAvail' });
  const isExpired = is_expired || daysToExpire < 0;
  const isTenantDeactivated = tenant_status === 'deactivated';
  return (
    <div
      className={cn('TenantNotificationBox', {
        'is-expired': isExpired,
        'deactivated': isTenantDeactivated,
      })}
    >
      {renderExpirationMessage(isTenantDeactivated, isExpired, daysToExpire)}
      <i onClick={onClose} className="close-icon fa fa-times" />
    </div>
  );
};

TenantNotificationBox.propTypes = {
  tenant: T.object.isRequired,
  onClose: T.func.isRequired,
};

export default TenantNotificationBox;
