import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { closeModal } from 'reducers/modals';
// hoc
import withSoundOnMount from 'hoc/withSoundOnMount';
// components
import Modal from 'components/Modal';
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import MessageBox from 'components/MessageBox';
// style
import './ExpiredCheckupModal.scss';

const ExpiredCheckupModal = ({ closeModal }) => (
  <Modal className="ExpiredCheckupModal" title={<FormattedMessage id="component.modal.expiredDC.title" />}>
    <section className="modal-body">
      <MessageBox type="alert">
        <FormattedMessage id="component.modal.expiredDC.warning" />
      </MessageBox>
      <FormattedMessage id="component.modal.expiredDC.text" tagName="p" />
    </section>
    <section className="modal-footer flex sticky-on-mobile">
      <Link to="/daily-checkup" className="button primary" onClick={closeModal}>OK</Link>
    </section>
  </Modal>
);

ExpiredCheckupModal.propTypes = {
  closeModal: T.func.isRequired,
};

const enhance = compose(
  connect(null, { closeModal }),
  withSoundOnMount('/static/sounds/dr_bot_sound.wav'),
);

export default enhance(ExpiredCheckupModal);
