import { useEffect } from 'react';
// utils, constants
import { BEAMER_APP_ID } from '../constants';
import { getUserRoles, userRoleLabels } from '../rolesHelper';
import isEmpty from 'lodash.isempty';

export default function useBeamerUserUpdate(user) {
  useEffect(() => {
    if (BEAMER_APP_ID && !isEmpty(user)) {
      window.Beamer?.update?.({
        filter: getUserRoles(user).map((key) => userRoleLabels[key]).join(';'),
        user_email: user.email,
        user_id: user.id,
        user_firstname: user.first_name,
        user_lastname: user.last_name,
      });
    }
  }, []);
}
