import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserAvatar from 'components/UserAvatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import IntlProvider from 'components/IntlProvider/IntlProvider';
import TimeAgo from 'react-timeago';
import { Link } from 'react-router';
// compose
import { compose, withHandlers } from 'recompose';
// utils
import cn from 'classnames';
// assets
import './UserMarkerTooltip.scss';

const UserMarkerTooltip = ({
  user,
  farm,
  visible,
  onTooltipClose,
  zoomIn,
  onMouseEnter,
  onMouseLeave
}) => (
  <IntlProvider>
    <div
      className={cn('UserMarkerTooltip', { visible })}
      onMouseEnter={visible ? onMouseEnter : null}
      onMouseLeave={visible ? onMouseLeave : null}
    >
      <div className="user-main-info">
        <UserAvatar user={user} size="36" />
        <div>
          <Link to={null} className="user-name">{user.full_name}</Link>
          <StatusBadge status={user.active ? 'active' : 'disabled'} />
          <div className="user-nickname">@{user.nickname}</div>
        </div>
        <i className="fa fa-times" onClick={onTooltipClose} />
      </div>
      <div className="user-additional-info">
        {!!farm && (
          <section>
            <span className="name"><FormattedMessage id="general.farm" /> • </span>
            <span className="value">{farm.name}</span>
          </section>
        )}
        <section>
          <span className="name"><FormattedMessage id="general.checkinTime" /> • </span>
          <span className="value"><TimeAgo date={user.checked_in || user.last_checked_in} /></span>
        </section>
        <div className="user-info-bottom-container">
          <div>
            <span className="name"><FormattedMessage id="general.totalCheckInsHere" /> • </span>
            <span className="value">{user.total_check_ins_at_farm || 0}</span>
          </div>
          <div className="zoom-btn" onClick={zoomIn}>
            <FormattedMessage id="general.zoomMap" />
          </div>
        </div>
      </div>
    </div>
  </IntlProvider>
);

UserMarkerTooltip.propTypes = {
  user: T.object.isRequired,
  farm: T.object.isRequired,
  visible: T.bool,
  onTooltipClose: T.func.isRequired,
  zoomIn: T.func.isRequired,
  onMouseEnter: T.func,
  onMouseLeave: T.func,
};

const enhance = compose(
  withHandlers({
    zoomIn: ({ zoomIn, user }) => () => {
      zoomIn(user);
    },
    onMouseEnter: ({ user, onMouseEnter, farm }) => () => {
      if (onMouseEnter) onMouseEnter({ id: user.id, type: 'user', parentId: farm.id });
    }
  })
);

export default enhance(UserMarkerTooltip);
