import React, { PureComponent } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchData, setDataItem } from 'reducers/dataTable';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// components
import Link from 'react-router/lib/Link';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import DropdownButton from 'components/DropdownButton';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import Subnavigation from 'components/Subnavigation/Subnavigation';
import OfflineScreen from 'components/OfflineScreen';
// utils
import cn from 'classnames';
import { isTablet } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// destinations API
import { disableDestination, enableDestination } from 'endpoints/destinations';
// translation
import { FormattedMessage } from 'react-intl';
// constants
import { destinationTypes } from '../../constants';

const labels = {
  dataTable: {
    destinationName: <FormattedMessage id="general.destinationName" />,
    type: <FormattedMessage id="general.type" />,
    location: <FormattedMessage id="general.location" />,
    status: <FormattedMessage id="component.dataTable.headers.status" />,
  },
  buttons: {
    createDestination: <FormattedMessage id="general.createDestination" />,
    edit: <FormattedMessage id="general.button.edit" />,
    activate: <FormattedMessage id="general.activate" />,
    activateDestination: <FormattedMessage id="general.activateDestination" />,
    deactivate: <FormattedMessage id="general.deactivate" />,
    deactivateDestination: <FormattedMessage id="general.deactivateDestination" />,
  },
  allDestinations: <FormattedMessage id="general.allDestinations" />,
  externalDestinations: <FormattedMessage id="general.pageTitle.externalDestinations" />,
  activeStatus: <FormattedMessage id="general.status.active" />,
  disabledStatus: <FormattedMessage id="general.status.disabled" />,
  other: <FormattedMessage id="general.other" />,
};

class Destinations extends PureComponent {

  componentDidMount = () => {
    const { fetchData, setPageOptions, setBreadcrumbs, isOnline, params } = this.props;
    if (isOnline) fetchData(params);
    setPageOptions({ mobileTitle: 'externalDestinations' });
    setBreadcrumbs();
  };

  componentDidUpdate(prevProps) {
    const { isOnline, fetchData } = this.props;
    if (isOnline && isOnline !== prevProps.isOnline) fetchData();
  }

  componentWillUnmount = () => {
    const { setPageOptions } = this.props;
    setPageOptions({ mobileTitle: '' });
  };

  onFilterChange = (filter) => {
    const { fetchData, params, isOnline } = this.props;
    const value = filter?.value;
    if (isOnline) fetchData({ ...params, page: 1, type: value, active: value && (value === 'active') });
  };

  enableDestination = (id) => () => {
    const { setDataItem } = this.props;
    enableDestination(id)
      .then(setDataItem)
      .catch(toastResponseErrors);
  };

  disableDestination = (id) => () => {
    const { setDataItem } = this.props;
    disableDestination(id)
      .then(setDataItem)
      .catch(toastResponseErrors);
  };

  renderNameColumn = ({ name }) => (
    <CustomColumn>
      <span className="semibold">{name}</span>
    </CustomColumn>
  );

  renderLocationColumn = (destination) => (
    <CustomColumn label={labels.dataTable.location}>
      {destination.city && <span>{destination.city}, </span>}
      {destination.state && <span>{destination.state}, </span>}
      <span>{destination.country_code === 'US' ? 'USA' : destination.country_code}</span>
    </CustomColumn>
  );

  renderTypeColumn = ({ destination_type }) => (
    <CustomColumn label={labels.dataTable.type}>
      {destinationTypes.find(({ value }) => value === destination_type)?.label || labels.other}
    </CustomColumn>
  );

  renderStatusColumn = (destination) => (
    <CustomColumn noBottomBorder label={labels.dataTable.status}>
      <StatusBadge status={destination.status} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id, active }, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${id}-${rowIndex}`}
        customClass="show-for-large"
        wide
        buttonType="small light-gray"
        label={labels.buttons.edit}
        url={`/destinations/${id}/edit`}
        dropDownData={[
          { label: labels.buttons.activateDestination, onClick: this.enableDestination(id), hide: active },
          { label: labels.buttons.deactivateDestination, onClick: this.disableDestination(id), hide: !active },
        ]}
      />
      <Link to={`/sources/${id}/edit`} className="button light hide-for-large">{labels.buttons.edit}</Link>
      {!!active && (
        <Button default onClick={this.disableDestination(id)} className="hide-for-large">
          {labels.buttons.deactivate}
        </Button>
      )}
      {!active && (
        <Button default onClick={this.enableDestination(id)} className="hide-for-large">
          {labels.buttons.activate}
        </Button>
      )}
    </div>
  );

  renderExpandable = ({ id }) => (
    <div>
      <Link className="button light small" to={`/destinations/${id}/edit`}>
        <i className="fa fa-edit mr-5" />
        {labels.buttons.edit}
      </Link>
    </div>
  );

  stopRedirect = (e) => {
    if (!this.props.isOnline) e.preventDefault();
  };

  render() {
    const { resources, isLoading, meta: { total, stats }, params: { page, per_page, search, sort, type },
      onSearchChange, onSortChange, onPageChange, onPerPageChange, isOnline } = this.props;

    const columns = [
      { label: labels.dataTable.destinationName, flex: '1 1 160px', renderer: this.renderNameColumn, sortKey: 'name' },
      { label: labels.dataTable.location, flex: '1 1 160px', renderer: this.renderLocationColumn, sortKey: 'city' },
      { label: labels.dataTable.type, flex: '1 1 100px', renderer: this.renderTypeColumn },
      { label: labels.dataTable.status, flex: '1 1 80px', renderer: this.renderStatusColumn, sortKey: 'active' },
      { label: '', flex: '0 0 120px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      totalItems: total,
      currentPage: page,
      perPage: per_page,
      onPageChange,
      onPerPageChange,
    };

    const tableFilters = [
      { label: labels.activeStatus, value: 'active', stats: stats.active },
      { label: labels.disabledStatus, value: 'disabled', stats: stats.disabled },
    ];

    return (
      <div className="clearfix">
        <Subnavigation
          items={tableFilters}
          onSelect={this.onFilterChange}
          defaultItem={labels.allDestinations}
          idKey="value"
          labelKey="label"
          currentId={type}
          className="small-12 column"
          isSticky
        >
          <Link onClick={this.stopRedirect} to="/destinations/create" className="button small primary wider">
            {labels.buttons.createDestination}
          </Link>
        </Subnavigation>

        {isOnline
          ? (
            <section className="small-12 column">
              <Panel>
                <Panel.Heading title={labels.externalDestinations}>
                  <SearchBox initialValue={search} onChange={onSearchChange} />
                </Panel.Heading>

                <Panel.Body noPadding>
                  <Preloader isActive={isLoading} />

                  <DataTable
                    data={resources}
                    columns={columns}
                    sort={sort}
                    onSortChange={onSortChange}
                    paginationProps={paginationProps}
                    isExpandable={isTablet}
                    renderExpandable={this.renderExpandable}
                    tableKey="adminDestinations"
                  />

                  <NothingBox
                    itemsName="external_destinations"
                    display={!resources.length}
                    isLoading={isLoading}
                    search={search}
                  >
                    <FormattedMessage tagName="h1" id="component.nothingBox.noDestinations" />
                    <FormattedMessage tagName="p" id="component.destinations.addDestinations" />
                  </NothingBox>
                </Panel.Body>

              </Panel>
            </section>
          )
          : <OfflineScreen />}
      </div>
    );
  }
}

Destinations.propTypes = {
  resources: T.array,
  isLoading: T.bool,
  params: T.object,
  meta: T.object,
  fetchData: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  setDataItem: T.func.isRequired,
  isOnline: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.adminDestinations.resources,
      isLoading: state.dataTable.adminDestinations.isLoading,
      params: state.dataTable.adminDestinations.params,
      meta: state.dataTable.adminDestinations.meta,
      isOnline: state.network.isOnline,
    }), {
      fetchData: (query) => fetchData('/admin/external_destinations', 'adminDestinations', query),
      setDataItem: (data) => setDataItem(data, 'adminDestinations'),
      setPageOptions,
      setBreadcrumbs,
    }
  ),
  withDataTableController(),
);

export default enhance(Destinations);
