import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';
// utils
import classnames from 'classnames/bind';
import debounce from 'lodash.debounce';
// groups api
import { checkPigGroupName } from 'endpoints/pigGroups';
// assets
import styles from './CustomGroupNameModal.module.scss';
import loaderSvg from '../../../../images/loader.svg';

const cn = classnames.bind(styles);

const labels = {
  customId: <FormattedMessage id="component.groupCreate.customGroupId" />,
};

class CustomGroupNameModal extends Component {

  state = {
    name: this.props.initialName || '',
    isCorrectName: false,
    isChecking: false,
  };

  componentDidMount() {
    const { initialName } = this.props;
    if (initialName) this.validatePigGroupName(initialName);
  }

  onGroupNameChange = ({ target: { value } }) => {
    this.setState({ name: value });
    this.validatePigGroupName(value);
  };

  onGroupNameSave = () => {
    const { name } = this.state;
    const { onSubmit, onClose } = this.props;
    onSubmit({ name });
    onClose();
  };

  validatePigGroupName = debounce((name) => {
    const { initialName } = this.props;
    if (initialName === name) return this.setState({ isCorrectName: true });
    if (!name || (name.length < 5)) return this.setState({ isCorrectName: false });
    this.setState({ isChecking: true });
    return checkPigGroupName(name)
      .then(() => this.setState({ isCorrectName: false, isChecking: false }))
      .catch(() => this.setState({ isCorrectName: true, isChecking: false }));
  }, 300);

  renderGroupNameIcon = (isCorrect, isChecking) => {
    if (isChecking) {
      return <div className={cn('loader-svg')} style={{ backgroundImage: 'url(' + loaderSvg + ')' }} />;
    }
    if (!isCorrect) {
      return <i className={cn('fa fa-exclamation-triangle-bts', 'check-icon-warning')} />;
    }
    return <i className={cn('fa fa-check', 'check-icon-success')} />;
  };

  render() {
    const { onClose } = this.props;
    const { name, isCorrectName, isChecking } = this.state;
    return (
      <Modal title={labels.customId}>
        <section className="modal-body">
          <span className={cn('custom-name-label')}>{labels.customId}</span>
          <div className={cn('custom-name')}>
            <FormattedMessage id="general.typeCustomName">
              {(placeholder) => (
                <Input
                  type="text"
                  placeholder={placeholder}
                  value={name}
                  onChange={this.onGroupNameChange}
                />
              )}
            </FormattedMessage>
            <div className={cn('input-icon')}>
              {this.renderGroupNameIcon(isCorrectName, isChecking)}
            </div>
          </div>
        </section>
        <section className="modal-footer text-right">
          <Button className="margin-right-1" onClick={() => onClose()}>
            <FormattedMessage id="general.button.cancel" />
          </Button>
          <Button primary disabled={!isCorrectName} onClick={this.onGroupNameSave}>
            <FormattedMessage id="general.button.save" />
          </Button>
        </section>
      </Modal>
    );
  }
}

CustomGroupNameModal.propTypes = {
  onClose: T.func.isRequired,
  onSubmit: T.func.isRequired,
  initialName: T.string,
};

export default CustomGroupNameModal;
