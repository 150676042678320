import React from 'react';
import T from 'prop-types';
import cn from 'classnames';
import styles from './ScrollHint.module.scss';

export const ScrollHint = ({ label, visible, offsetTop, onClick }) => {
  const style = { top: offsetTop };
  return (
    <div style={style} className={cn(styles['scroll-hint'], { [styles.visible]: visible })} onClick={onClick}>
      <span className="mr-10">{label}</span>
      <i className="fa fa-arrow-right semibold" />
    </div>
  );
};

ScrollHint.propTypes = {
  visible: T.bool,
  offsetTop: T.number,
  label: T.oneOfType([T.string, T.node]).isRequired,
  onClick: T.func,
};

ScrollHint.defaultProps = {
  visible: false,
  onClick: null,
};

export default ScrollHint;
