import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserAvatar from '../UserAvatar';
import FarmfeedTooltip from './FarmfeedTooltip';
// endpoints
import { fetchActivityUser } from 'endpoints/farmfeed';
//  constants
import { userData } from './constants';

const UserFarmFeedTooltip = ({ activityId, isAdmin, userId }, { router }) => {
  const [activityUser, setActivityUser] = useState({});

  useEffect(() => {
    fetchActivityUser(activityId, userId).then(setActivityUser);
  }, []);

  return (
    <FarmfeedTooltip
      type="user"
      data={activityUser}
      tooltipData={userData}
      getAvatar={(data) => <UserAvatar user={data} size={55} />}
      isAdmin={isAdmin}
      onManageBtnClick={() => router.push(`/admin/users/${userId}`)}
      manageBtnLabel={<FormattedMessage id="general.manageUser" />}
    />
  );
};

UserFarmFeedTooltip.contextTypes = {
  router: T.object.isRequired,
};

UserFarmFeedTooltip.propTypes = {
  activityId: T.number.isRequired,
  userId: T.number.isRequired,
  isAdmin: T.bool.isRequired,
};

export default UserFarmFeedTooltip;
