import React, { useState } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Modal from 'components/Modal/Modal';
import DrBotAvatar from 'components/DrBotAvatar/DrBotAvatar';
// utils
import { FormattedMessage } from 'react-intl';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
// endpoints
import { makePigGroupMarketing } from 'endpoints/pigGroups';
// style
import './ReportPigGroupMarketingModal.scss';

const ReportPigGroupMarketingModal = ({ closeModal, pigGroup }) => {
  const [isLoading, setIsLoading] = useState(false);

  const reportMarketing = () => {
    setIsLoading(true);
    makePigGroupMarketing(pigGroup.id)
      .then(() => {
        closeModal();
        showToastrMessage('component.toastr.group.markAsMarketing');
      })
      .catch(toastResponseErrors)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal className="ReportPigGroupMarketingModal">
      <section className="modal-body">
        <Preloader isActive={isLoading} />
        <div className="avatar-wrapper"><DrBotAvatar /></div>
        <div className="content-wrapper">
          <FormattedMessage
            id="container.dailyCheckup.reportMovements.reportMarketing"
            values={{ name: <b>{pigGroup.name}</b> }}
          />
        </div>
      </section>
      <section className="modal-footer">
        <Button className="button light" onClick={closeModal}>
          <FormattedMessage id="general.button.no" />
        </Button>
        <Button className="button primary" onClick={reportMarketing}>
          <FormattedMessage id="general.button.yes" />
        </Button>
      </section>
    </Modal>
  );
};

ReportPigGroupMarketingModal.propTypes = {
  closeModal: T.func.isRequired,
  pigGroup: T.object.isRequired,
};

export default ReportPigGroupMarketingModal;
