import React from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import { GroupStatusBadge } from 'components/StatusBadge/StatusBadge';
import PigGroupMarketingBadge from 'components/PigGroupMarketingBadge/PigGroupMarketingBadge';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// styles
import styles from './Lines.module.scss';

const cn = classnames.bind(styles);

export const FarmLine = ({ name, onClick, isDisabled }) => (
  <div className={cn('line', { isDisabled })} onClick={!isDisabled ? onClick : null}>
    <Avatar avatarSize={30} iconSize={13} type="farm" />
    <span className={cn('name')}>{name}</span>
  </div>
);

export const PigGroupLine = ({ name, onClick, isDisabled, pigGroup }) => (
  <div className={cn('line', { isDisabled })} onClick={!isDisabled ? onClick : null}>
    <Avatar avatarSize={30} iconSize={13} type="pig_group" />
    <span className={cn('name')}>{name}</span>
    <PigGroupMarketingBadge className={cn('badge')} isMinimized pigGroup={pigGroup} size="small" />
    <GroupStatusBadge
      className={cn('status')}
      status={pigGroup.status}
      textValues={{ date: pigGroup.closed_on ? moment(pigGroup.closed_on).format('MM/DD/YY') : null }}
    />
  </div>
);

export const CompanyLine = ({ name, onClick, isDisabled }) => (
  <div className={cn('line', { isDisabled })} onClick={!isDisabled ? onClick : null}>
    <Avatar avatarSize={30} iconSize={13} type="company" />
    <span className={cn('name')}>{name}</span>
  </div>
);

const propTypes = {
  name: T.string.isRequired,
  onClick: T.func.isRequired,
  isDisabled: T.bool,
};

FarmLine.propTypes = propTypes;
CompanyLine.propTypes = propTypes;

PigGroupLine.propTypes = {
  ...propTypes,
  pigGroup: T.object.isRequired,
};
