import React, { useMemo, createContext, useState, useContext } from 'react';
import T from 'prop-types';

// initial state
const initialState = {
  options: [],
  isOpened: false,
  targetCoords: {},
  idKey: '',
  isScrollIgnored: false,
};

const DropdownActionsContext = createContext({ openDropdown: () => null, closeDropdown: () => null });
const DropdownContext = createContext(initialState);

export const useDropdownActions = () => useContext(DropdownActionsContext);

export const useDropdown = () => useContext(DropdownContext);

export const DropdownProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const openDropdown = (e, options, { isScrollIgnored = false, customTarget, customCoords } = {}) => {
    e?.stopPropagation();
    const target = customTarget || e.target;
    const targetCoords = customCoords || target?.getBoundingClientRect() || {};
    const idKey = target?.dataset.name || '';
    setState((prevState) => ({
      options,
      isScrollIgnored,
      targetCoords,
      idKey,
      isOpened: !prevState.isOpened,
    }));
  };

  const closeDropdown = () => {
    setState((prevState) => ({ ...prevState, isOpened: false, isScrollIgnored: false, targetCoords: {}, idKey: '' }));
  };

  const actions = useMemo(() => ({ openDropdown, closeDropdown }), [setState]);

  return (
    <DropdownContext.Provider value={state}>
      <DropdownActionsContext.Provider value={actions}>
        {children}
      </DropdownActionsContext.Provider>
    </DropdownContext.Provider>
  );
};

DropdownProvider.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
};
