import { fetchFromAPI } from 'utils/api';

export const fetchFarm = (id) => {
  return fetchFromAPI(`/farms/${id}`)
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const getFarmBarns = (id) => {
  return fetchFromAPI(`/farms/${id}/barns`)
    .then((response) => response.resources)
    .catch(() => []);
};

export const getFarmPigMigrations = (id, params) => {
  return fetchFromAPI(`/farms/${id}/pig_migrations`, { params })
    .catch((response) => {
      throw response;
    });
};

export const makeFarmCheckIn = (farmId) => {
  return fetchFromAPI(`/farms/${farmId}/check_ins`, { method: 'post' })
    .then((response) => response.resource);
};

export const getFarmSiteVisitReports = (farmId, params) => {
  return fetchFromAPI(`/farms/${farmId}/svrs`, { params });
};

export const searchFarms = (params) => {
  return fetchFromAPI('/farms/search', { params });
};
