import React from 'react';
import T from 'prop-types';
// utils
import cn from 'classnames';
// styles
import styles from './FiltersSearchInput.module.scss';

const FiltersSearchInput = ({ onClick, onClose, inputIcon = 'fa fa-arrow-left', value = '' }) => (
  <div className={styles['search-input']} onClick={onClick}>
    <i className={cn(inputIcon, styles['input-icon'])} />
    <span className={cn(styles['search-value'], 'nowrap-ellipsis')}>{value}</span>
    <i className={cn('fa fa-times', styles['close-icon'])} onClick={onClose} />
  </div>
);

FiltersSearchInput.propTypes = {
  inputIcon: T.string,
  onClick: T.func.isRequired,
  onClose: T.func.isRequired,
  value: T.oneOfType([T.string, T.node]),
};

export default FiltersSearchInput;
