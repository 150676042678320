import React from 'react';
import T from 'prop-types';
// redux
import { updateCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
import { openModal } from 'reducers/modals';
import { openPortal } from 'reducers/portal';
import { connect } from 'react-redux';
// components
import BarnSheetsDiagnoses from '../components/BarnSheetsDiagnoses';
// requests
import { fetchBarnSheetsFarmDiagnoses } from 'endpoints/barnsheets/farms';
// utils
import { isUserCanDiagnose } from 'utils';

const BarnSheetsFarmDiagnoses = ({
  currentUser,
  farm,
  location,
  openModal,
  openPortal,
  params: { farmId },
  updateCheckupDiagnoses,
}) => {
  const fetchDiagnoses = (reqParams) => {
    return fetchBarnSheetsFarmDiagnoses(farmId, reqParams);
  };
  const canDiagnose = isUserCanDiagnose(currentUser, farm, farm?.manager_id);

  return (
    <BarnSheetsDiagnoses
      currentUser={currentUser}
      farm={farm}
      fetchDiagnoses={fetchDiagnoses}
      isUserCanDiagnose={canDiagnose}
      openModal={openModal}
      openPortal={openPortal}
      pathname={location.pathname}
      updateCheckupDiagnoses={updateCheckupDiagnoses}
    />
  );
};

BarnSheetsFarmDiagnoses.propTypes = {
  updateCheckupDiagnoses: T.func,
  openModal: T.func,
  openPortal: T.func,
  params: T.object,
  location: T.object,
  currentUser: T.oneOfType([T.node, T.object]),
  farm: T.object.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    farm: state.profiles.farm.data,
  }), {
    updateCheckupDiagnoses,
    openModal,
    openPortal,
  },
)(BarnSheetsFarmDiagnoses);
