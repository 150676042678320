import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { createPasswordByToken } from 'reducers/auth';
// component
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutMinimal from 'components/LayoutMinimal';
import Input from 'components/Input';
import Button from 'components/Button';
import StepDots from '../components/StepDots';
import PasswordStrength from '../components/PasswordStrength';
// utils
import { passwordRules } from 'utils';
import { setLSLanguage } from 'utils/localStorageHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { toastr } from 'react-redux-toastr';
// styles
import './CompleteRegistration.scss';

const CompleteRegistrationPassword = ({
  createPasswordByToken,
  isLoading,
  params,
  router,
}) => {
  const [{ password, passwordStrength, isValidPassword }, setState] = useState({
    password: '',
    passwordStrength: '',
    isValidPassword: false,
  });
  const { formatMessage } = useIntl();
  const { token } = params;

  const handleChangePassword = (e) => {
    const { value } = e.target;
    const isRestricted = ['password', '123456', 'abcdef'].includes(value);
    const passwordStrength = Object.keys(passwordRules)
      .map((rule) => passwordRules[rule].test(value) && rule)
      .filter((rule) => !!rule)
      .join(' ');
    setState({
      password: value,
      passwordStrength,
      isValidPassword: /^(?=.{6,})/.test(value) && !isRestricted,
    });
  };

  const handleCreatePassword = () => {
    createPasswordByToken({ password }, token)
      .then((invitedUser) => {
        setLSLanguage(invitedUser.language);
        router.push(`/invitations/${invitedUser.id}/set-language`);
      })
      .catch(toastResponseErrors);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isValidPassword) {
        handleCreatePassword();
      } else {
        toastr.warning('', formatMessage({ id: 'component.toastr.invitations.notSecurePassword' }),
          { icon: <i className="fa fa-exclamation-triangle-bts" /> });
      }
    }
  };

  return (
    <LayoutMinimal containerClass="CompleteRegistration">
      <FormattedMessage tagName="h1" id="container.invitations.createPassword">
        {(text) => <h1 className="title">{text}</h1>}
      </FormattedMessage>
      <form onKeyPress={handleEnterKey}>
        <label>
          <span className="tag">
            <FormattedMessage id="container.invitations.createPassword" />
          </span>
          <Input
            autoFocus
            className="input-info"
            type="password"
            value={password}
            onChange={handleChangePassword}
            autoComplete="off"
          />
        </label>
        <PasswordStrength
          value={passwordStrength}
          description={<FormattedMessage id="container.invitations.passwordValidation" />}
        />
        <Button
          primary
          type="button"
          className="lm-button next"
          onClick={handleCreatePassword}
          disabled={!isValidPassword || isLoading}
        >
          <FormattedMessage id="general.button.next" />
        </Button>
      </form>
      <StepDots dotsCount={6} activeStep={1} />
    </LayoutMinimal>
  );
};

CompleteRegistrationPassword.propTypes = {
  createPasswordByToken: T.func.isRequired,
  params: T.object.isRequired,
  router: T.object.isRequired,
  isLoading: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isLoading: state.auth.isLoading,
  }), {
    createPasswordByToken,
  }
)(CompleteRegistrationPassword);
