import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import ImageTag from './ImageTag';
import ImageTagTooltip from './ImageTagTooltip';
import DiseasesList from './DiseasesList';
// styles
import styles from './ImageTagEdit.module.scss';
// assets
import magicLoopSvg from '../../../../public/images/magic.svg';

const ImageTagEdit = ({ tag, onDeleteTag, onAddTag, isHoveredTag, onUpdatePosition }) => (
  <ImageTag tag={tag} onUpdatePosition={onUpdatePosition} isEditable>
    {() => (
      <>
        {!tag.diagnosis &&
          <DiseasesList onItemSelect={(item) => onAddTag(tag, item)} />}
        {!!tag.diagnosis && (
          <ImageTagTooltip
            className={styles['tag-edit-tooltip']}
            isVisible
            isActive={isHoveredTag}
          >
            <div className={styles['diagnosis-line']}>
              {tag.ai_suggestion && <img src={magicLoopSvg} alt="" className="mr-10" />}
              <span className={styles.name}>
                {tag.diagnosis.label}&nbsp;
              </span>
              <span onClick={() => onDeleteTag(tag)}>
                <i className={`fa fa-times ${styles['del-tag-icon']}`} />
              </span>
            </div>
          </ImageTagTooltip>
        )}
      </>
    )}
  </ImageTag>
);

ImageTagEdit.propTypes = {
  tag: T.object.isRequired,
  onAddTag: T.func.isRequired,
  onDeleteTag: T.func.isRequired,
  onUpdatePosition: T.func.isRequired,
  isHoveredTag: T.bool.isRequired,
};

export default connect(
  (state, props) => ({
    isHoveredTag: state.dailyCheckup.checkupDiagnoses.hoverTag === (props.tag.id || props.tag.hash),
  })
)(ImageTagEdit);
