import React from 'react';
import T from 'prop-types';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MediaViewerZoomPanel.module.scss';

const cn = classnames.bind(styles);

const MediaViewerZoomPanel = ({ activeZoom, onZoomInClick, onZoomOutClick, onFitToPageClick }) => (
  <div className={cn('zoom-panel')}>
    <div className={cn('zoom-panel-buttons')}>
      <div onClick={onZoomInClick} className={cn('btn-zoom', { 'active': activeZoom === 'zoomIn' })}>
        <i className="fa fa-search-plus" />
      </div>
      <div onClick={onZoomOutClick} className={cn('btn-zoom', { 'active': activeZoom === 'zoomOut' })}>
        <i className="fa fa-search-minus" />
      </div>
      <div onClick={onFitToPageClick} className={cn('btn-zoom', { 'active': activeZoom === 'fitToPage' })}>
        <i className="fa fa-maximize" />
      </div>
    </div>
  </div>
);

MediaViewerZoomPanel.propTypes = {
  activeZoom: T.string,
  onZoomInClick: T.func.isRequired,
  onZoomOutClick: T.func.isRequired,
  onFitToPageClick: T.func.isRequired,
};

export default MediaViewerZoomPanel;
